import { CollectionUtils } from "andculturecode-javascript-core";
import { DateTime } from "luxon";
import { DateUtils } from "utilities/date-utils";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { EventRecord } from "models/view-models/events/event-record";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useMemo } from "react";
import "./event-day-block.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayBlockProps {
    attendances?: EventDayAttendanceRecord[];
    event: EventRecord;
    eventDay: EventDayRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-day-block";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayBlock: React.FC<EventDayBlockProps> = (props: EventDayBlockProps): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const isLearner = globalState?.currentIdentity?.role?.roleType === RoleType.Learner;

    let firstSessionName: string = "";
    let firstSessionTime: string = "";
    const hasSessions: boolean = useMemo(
        (): boolean => CollectionUtils.hasValues(props.eventDay.eventSessions),
        [props.eventDay.eventSessions]
    );
    const blockRowSpan: number = hasSessions ? props.eventDay.eventSessions!.length : 1;
    const additionalSessions: EventSessionRecord[] =
        hasSessions && props.eventDay.eventSessions!.length > 1
            ? props.eventDay.eventSessions!.slice(1)
            : [];

    if (hasSessions) {
        firstSessionName = t("session1");
        firstSessionTime =
            props.eventDay.eventSessions![0].formatStartAndEndTime() +
            DateTime.fromObject({}, { zone: props.event.timeZone }).offsetNameShort;
    }

    const displayAttendanceIcon = () => {
        if (props.eventDay?.status !== EventDayStatus.Completed) {
            return (
                <Icon
                    type={Icons.CircleUnchecked}
                    size={IconSizes.Large}
                    cssClassName="untracked"
                />
            );
        }

        const currentDay: EventDayAttendanceRecord | undefined = props.attendances?.find(
            (attendance) => attendance.eventDayId === props.eventDay.id
        );

        if (currentDay != null && currentDay.attended) {
            return (
                <Icon
                    type={Icons.CheckmarkOvalOutline}
                    size={IconSizes.Large}
                    cssClassName="attended"
                />
            );
        } else {
            return <Icon type={Icons.Cancel} size={IconSizes.Large} cssClassName="not-attended" />;
        }
    };

    return (
        <>
            <tr>
                <td className="day" rowSpan={blockRowSpan}>
                    {DateUtils.formatDateCustom(
                        props.eventDay.eventDate().toString(),
                        t("shortDateFormat")
                    )}
                </td>
                {isLearner && (
                    <td className="attendance" rowSpan={blockRowSpan}>
                        {displayAttendanceIcon()}
                    </td>
                )}
                <td className="name" rowSpan={blockRowSpan}>
                    {props.eventDay.name}
                </td>
                {props.event.hasAllDaySessions && (
                    <td colSpan={2} className="all-day">
                        <em>
                            <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                                {t("pleaseContactTheHostForStartAndEndTimes")}
                            </Paragraph>
                        </em>
                    </td>
                )}
                {!props.event.hasAllDaySessions && (
                    <>
                        <td className="session">{firstSessionName}</td>
                        <td className="time">{firstSessionTime}</td>
                    </>
                )}
            </tr>
            {additionalSessions.map(
                (session: EventSessionRecord, idx): JSX.Element => (
                    <tr key={session.id} className={`${CSS_CLASS_NAME}__additional-rows`}>
                        <td className="session">{t("sessionIdx", { idx: idx + 2 })}</td>
                        <td className="time">
                            {session.formatStartAndEndTime()}{" "}
                            {
                                DateTime.fromObject({}, { zone: props.event.timeZone })
                                    .offsetNameShort
                            }
                        </td>
                    </tr>
                )
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayBlock };

// #endregion Exports
