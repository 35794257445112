import { BulkContractEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-contract-enrollment-summary-record";
import {
    CountEnrollmentsQueryParams,
    EnrollmentService,
    WithdrawEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { CreateBulkEnrollmentDtoRecord } from "models/view-models/enrollments/create-bulk-enrollment-params-record";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { EncodingType } from "models/enumerations/encoding/encoding-type";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EventEnrollmentSummariesService,
    ListEventEnrollmentSummariesPathParams,
    ListEventEnrollmentSummariesQueryParams,
} from "utilities/services/enrollments/event-enrollment-summaries-service";
import { EventEnrollmentSummaryRecord } from "models/view-models/enrollments/event-enrollment-summary-record";
import { FileUtils } from "utilities/files/file-utils";
import { IltContractEnrollmentService } from "utilities/services/enrollments/ilt-contract-enrollment-service";
import { NumberUtils } from "utilities/number-utils";
import { ResultErrorRecord, ResultRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

interface useILTContractEnrollmentsHook {
    enrollmentSummaries: EventEnrollmentSummaryRecord[];
    createEnrollment: (createEnrollmentDto?: CreateEnrollmentDtoRecord) => Promise<boolean>;
    createBulkEnrollments: (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ) => Promise<ResultRecord<BulkContractEnrollmentSummaryRecord>>;
    withdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    usedEnrollmentCount: number;
    rowCount: number;
}

interface useILTContractEnrollmentsHookOptions {
    contractId?: number | string;
    productId?: number | string;
    eventId?: number | string;
    eventProductVersionId: number | string;
    includeProduct?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

export function useILTContractEnrollments(
    props: useILTContractEnrollmentsHookOptions
): useILTContractEnrollmentsHook {
    const encodingType: EncodingType = FileUtils.getEncodingType();
    const [rowCount, setRowCount] = useState(0);
    const searchText = props.searchText;
    const skip = props.skip;
    const take = props.take;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentSummaries, setEnrollmentSummaries] = useState<EventEnrollmentSummaryRecord[]>(
        []
    );
    const [usedEnrollmentCount, setUsedEnrollmentCount] = useState(0);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();

    const contractId = NumberUtils.parseInt(props.contractId) ?? 0;
    const productId = NumberUtils.parseInt(props.productId) ?? 0;
    const eventId = NumberUtils.parseInt(props.eventId) ?? 0;
    const eventProductVersionId = NumberUtils.parseInt(props.eventProductVersionId) ?? 0;

    const { create: enrollmentCreate } = IltContractEnrollmentService.useCreate();
    const { create: bulkEnrollmentCreate } = IltContractEnrollmentService.useBulkCreate();
    const { patch: withdrawEnrollmentApi } = EnrollmentService.useWithdrawEnrollment();
    const { list: listEventEnrollmentSummaryApi } = EventEnrollmentSummariesService.useList();
    const { get: countEnrollmentsApi } = EnrollmentService.useCount();

    const fetchData = useCallback(async () => {
        if (isNaN(eventId) || eventId < 1) {
            return;
        }

        try {
            (async function getEnrollmentSummaries() {
                const listEventEnrollmentSummariesPathParams: ListEventEnrollmentSummariesPathParams =
                    {
                        eventId: eventId,
                    };
                const listEventEnrollmentSummariesQueryParams: ListEventEnrollmentSummariesQueryParams =
                    { skip: skip, take: take };

                const countEnrollmentsQueryParams: CountEnrollmentsQueryParams = {
                    contractId: contractId,
                    isWithdrawn: false,
                };

                const [enrollmentSummaryResult, enrollmentCountResult] = await Promise.all([
                    listEventEnrollmentSummaryApi(
                        listEventEnrollmentSummariesPathParams,
                        listEventEnrollmentSummariesQueryParams
                    ),
                    countEnrollmentsApi({}, countEnrollmentsQueryParams),
                ]);

                const {
                    result: resultEnrollmentSummary,
                    resultObjects,
                    rowCount,
                } = enrollmentSummaryResult;

                const { result: resultEnrollmentCount } = enrollmentCountResult;

                setIsLoading(false);

                if (resultEnrollmentSummary?.hasErrors() || resultEnrollmentCount?.hasErrors()) {
                    const { errors: enrollmentSummaryErrors = [] } = resultEnrollmentSummary ?? {};
                    const { errors: enrollmentCountErrors = [] } = resultEnrollmentCount ?? {};

                    setErrors([...enrollmentSummaryErrors, ...enrollmentCountErrors]);
                    return;
                }

                setRowCount(rowCount);
                setEnrollmentSummaries(resultObjects);
                setUsedEnrollmentCount(resultEnrollmentCount?.resultObject ?? 0);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollments"));
            setEnrollmentSummaries([]);
            setUsedEnrollmentCount(0);
        }
    }, [contractId, countEnrollmentsApi, eventId, listEventEnrollmentSummaryApi, skip, take]);

    const createEnrollment = async (
        createEnrollmentDto?: CreateEnrollmentDtoRecord
    ): Promise<boolean> => {
        try {
            if (
                contractId <= 0 ||
                eventId <= 0 ||
                productId <= 0 ||
                eventProductVersionId <= 0 ||
                createEnrollmentDto === undefined
            ) {
                throw new Error(t("creatingAnEnrollmentFailed"));
            }

            createEnrollmentDto = createEnrollmentDto.with({
                productId: productId,
                eventId: eventId,
                eventProductVersionId: eventProductVersionId,
            });

            const createEnrollmentResponse = await enrollmentCreate(
                contractId,
                createEnrollmentDto
            );
            const createEnrollmentResult = createEnrollmentResponse?.result;

            if (
                createEnrollmentResult?.resultObject == null ||
                createEnrollmentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemCreatingANewEnrollment"));
            }

            // Retrieve the data.
            fetchData();

            return true;
        } catch (error) {
            if (typeof error === "string") {
                const errorMessage = error === "" ? t("failedToCreateANewEnrollment") : error;
                ToastManager.error(errorMessage);
            } else if (error instanceof ResultRecord) {
                const { errors } = error;
                errors?.forEach((error) => {
                    ToastManager.error(error.message);
                });
            }
            return false;
        }
    };

    const createBulkEnrollments = async (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ): Promise<ResultRecord<BulkContractEnrollmentSummaryRecord>> => {
        let result = new ResultRecord<BulkContractEnrollmentSummaryRecord>();

        if (
            contractId === undefined ||
            contractId <= 0 ||
            productId === undefined ||
            productId <= 0 ||
            eventId === undefined ||
            eventId <= 0 ||
            eventProductVersionId === undefined ||
            eventProductVersionId <= 0 ||
            createBulkEnrollmentDto === undefined
        ) {
            result.addError("", t("contractIDProductIDAndFileAreNeeded"));
            return result;
        }

        createBulkEnrollmentDto = createBulkEnrollmentDto.with({
            productId: productId,
            eventId: eventId,
            eventProductVersionId: eventProductVersionId,
        });

        try {
            const createBulkEnrollmentResponse = await bulkEnrollmentCreate(
                contractId,
                encodingType,
                createBulkEnrollmentDto
            );
            const createBulkEnrollmentResult = createBulkEnrollmentResponse?.result;

            if (createBulkEnrollmentResult?.resultObject == null) {
                throw new Error();
            }

            result = createBulkEnrollmentResult;
        } catch (error) {
            try {
                result = error as ResultRecord<BulkContractEnrollmentSummaryRecord>;
            } catch (error: any) {
                result.addError("", error.toString());
                ToastManager.error(t("thereWasProblemSavingBulkEnrollments"));
            }
        }

        // Retrieve the data.
        fetchData();

        return result;
    };

    const withdrawEnrollment = async (enrollment: EnrollmentRecord): Promise<boolean> => {
        try {
            const pathParams: WithdrawEnrollmentPathParams = {
                id: enrollment.id!,
            };

            const withdrawEnrollmentResponse = await withdrawEnrollmentApi(pathParams);
            const withdrawResult = withdrawEnrollmentResponse?.result;
            if (withdrawResult?.resultObject == null || withdrawResult.hasErrors()) {
                throw new Error();
            }

            fetchData();
            ToastManager.success(t("withdrawEnrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueWithdrawingAnEnrollment"));
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchText, skip, take, contractId, productId, listEventEnrollmentSummaryApi, fetchData]);

    return {
        errors,
        isLoading,
        enrollmentSummaries,
        createBulkEnrollments,
        createEnrollment,
        withdrawEnrollment,
        rowCount,
        usedEnrollmentCount,
    };
}
