import { Record } from "immutable";
import { CreateEnrollmentDto } from "models/interfaces/enrollments/create-enrollment-dto";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CreateEnrollmentDto = {
    email: "",
    eventId: undefined,
    eventProductVersionId: undefined,
    firstName: "",
    lastName: "",
    productId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CreateEnrollmentDtoRecord extends Record(defaultValues) implements CreateEnrollmentDto {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<CreateEnrollmentDto>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for CreateEnrollmentDto properties, create a new CreateEnrollmentDtoRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<CreateEnrollmentDto>} values The values to overwrite on this instance.
     * @returns A CreateEnrollmentDtoRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<CreateEnrollmentDto>): CreateEnrollmentDtoRecord {
        return new CreateEnrollmentDtoRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateEnrollmentDtoRecord };

// #endregion Exports
