import React from "react";
import { useAnnouncement } from "utilities/contexts/use-announcement-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";
import {
    PatchUserAnnouncementServiceParams,
    UserAnnouncementPatchRoutes,
    UserAnnouncementService,
} from "utilities/services/announcements/user-announcement-service";

// -------------------------------------------------------------------------------------------------
// #region Interface
// -------------------------------------------------------------------------------------------------

interface AnnouncementProps {
    title: string;
    className?: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interface

// -------------------------------------------------------------------------------------------------
// #region Contstants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-announcements-panel-content";

// #endregion Contstants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Announcement: React.FC<AnnouncementProps> = ({ title, setIsOpen }) => {
    const { record: globalState } = useGlobalState();
    const { patch: markAsReadApi } = UserAnnouncementService.usePatch();
    const truncateString = (str: string) => {
        const maxLength = 90;
        if (str.length > maxLength) {
            let end = Math.min(str.lastIndexOf(" ", 93), 85);
            return str.slice(0, end) + "...";
        }
        return str;
    };
    const { announcement: announcementData, setIsRead } = useAnnouncement();
    const announcementId = announcementData.id;
    const announcementDate = announcementData.createdOn;
    const formattedAnnouncementDate = DateUtils.formatDate(true, announcementDate ?? "");

    const userId = globalState.currentIdentity?.userId();

    const handleAnnouncementOpen = async () => {
        if (announcementId == null || userId == null || userId <= 0) {
            return;
        }

        setIsOpen(true);
        const patchNotificationServiceParams: PatchUserAnnouncementServiceParams = {
            announcementId: announcementId,
            userId: userId,
            patchRoute: UserAnnouncementPatchRoutes.MarkAsRead,
        };

        const patchNotificationResponse = await markAsReadApi(patchNotificationServiceParams);

        const patchNotificationResult = patchNotificationResponse?.result;

        if (
            patchNotificationResult == null ||
            patchNotificationResult?.resultObject == null ||
            patchNotificationResult.hasErrors()
        ) {
            // If it fails, it can fail quietly.
            return;
        }

        setIsRead();
    };

    return (
        <div className={`${CSS_CLASS_NAME}__announcement`}>
            <div className={`${CSS_CLASS_NAME}__announcement__time`}>
                {formattedAnnouncementDate}
            </div>
            <div className={`${CSS_CLASS_NAME}__announcement__details`}>
                {truncateString(title)}
            </div>
            <button
                className={`${CSS_CLASS_NAME}__announcement__button`}
                onClick={handleAnnouncementOpen}>
                {t("viewAnnouncement")}
            </button>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default Announcement;

// #endregion Exports
