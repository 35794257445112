import { AENProductCard } from "../aen-product-card/aen-product-card";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { ProductRecord } from "models/view-models/products/product-record";
import { t } from "utilities/localization/t";
import "./aen-product-card-selection-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENProductCardSelectionListProps {
    products: ProductRecord[];
    onProductSelection: (product: ProductRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-product-card-selection-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENProductCardSelectionList: React.FC<AENProductCardSelectionListProps> = ({
    products,
    onProductSelection,
}) => {
    return (
        <>
            {CollectionUtils.hasValues(products) ? (
                <div className={CSS_CLASS_NAME}>
                    {products.map((product) => (
                        <AENProductCard
                            key={product.id}
                            product={product}
                            onProductSelection={onProductSelection}
                        />
                    ))}
                </div>
            ) : (
                <EmptyText>{t("noProductResultsFound")}</EmptyText>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENProductCardSelectionList };

// #endregion Exports
