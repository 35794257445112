import { useState } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { AddCourseModalCourse } from "components/units/unit-courses/unit-add-course-modal/unit-manage-add-courses";
import { t } from "utilities/localization/t";
import "./add-course-modal-course-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------
interface AddCourseModalCourseListProps {
    addCourseModalCourses: AddCourseModalCourse[];
    handleAdd: (course: CourseRecord) => void;
    handleRemove: (course: CourseRecord) => void;
    filterCourses: (searchText: string) => void;
    debounceTime: number;
    searchTextPlaceholder: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "add-course-modal-course-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddCourseModalCourseList: React.FC<AddCourseModalCourseListProps> = (
    props: AddCourseModalCourseListProps
) => {
    const [searchText, setSearchText] = useState<string>("");

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    return (
        <>
            <SearchTextInput
                debounce={props.debounceTime}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={props.filterCourses}
                id="courseSearch"
                placeholder={props.searchTextPlaceholder}
                searchTextInputValue={searchText}
            />
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="course-name">{t("courseName")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="action">{t("action")}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.addCourseModalCourses.map((addCourseModalCourse) => (
                        <tr key={addCourseModalCourse.course.id}>
                            <td className="course-name">{addCourseModalCourse.course.name}</td>
                            <td className="id">{addCourseModalCourse.course.id}</td>
                            <td className="action">
                                {!addCourseModalCourse.addedToQueue && (
                                    <ButtonIcon
                                        buttonSize={ButtonSize.Small}
                                        buttonStyle={ButtonStyle.Secondary}
                                        iconType={Icons.Plus}
                                        onClick={() => props.handleAdd(addCourseModalCourse.course)}
                                        text={t("add")}
                                    />
                                )}
                                {addCourseModalCourse.addedToQueue && (
                                    <ButtonIcon
                                        buttonSize={ButtonSize.Small}
                                        buttonStyle={ButtonStyle.Primary}
                                        iconType={Icons.Minus}
                                        onClick={() =>
                                            props.handleRemove(addCourseModalCourse.course)
                                        }
                                        text={t("remove")}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </DataTable>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddCourseModalCourseList };

// #endregion Exports
