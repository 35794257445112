import React, { useMemo } from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { RouteUtils } from "utilities/route-utils";
import { Tab } from "components/tabs/tabs/tab/tab";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { TrainingType } from "models/enumerations/courses/training-type";
import { UserTrainingDetailExpiration } from "./user-training-detail-expiration/user-training-detail-expiration";
import { UserTrainingDetailHistory } from "./user-training-detail-history/user-training-detail-history";
import { UserTrainingDetailIltProgress } from "./user-training-detail-ilt-progress/user-training-detail-ilt-progress";
import { UserTrainingDetailOllProgress } from "./user-training-detail-oll-progress/user-training-detail-oll-progress";
import { UserTrainingDetailOverview } from "./user-training-detail-overview/user-training-detail-overview";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams, useSearchParams } from "react-router-dom";
import { t } from "utilities/localization/t";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { DateUtils } from "utilities/date-utils";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useEvent } from "utilities/hooks/models/events/use-event";
import "./user-training-detail-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailPage: React.FC<UserTrainingDetailPageProps> = validatePageAccess(
    AccessControlKeys.UserTrainingDetailPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { id: userIdParam, enrollmentId: enrollmentIdParam } = useParams();
    const userId = useMemo(() => NumberUtils.parseInt(userIdParam) ?? 0, [userIdParam]);
    const enrollmentId = useMemo(
        () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
        [enrollmentIdParam]
    );

    const { enrollment } = useEnrollment({
        enrollmentId,
        includeCertificate: true,
        includeProduct: true,
        includeCreatedBy: true,
        includeWithdrawnBy: true,
    });

    const { event } = useEvent({
        eventId: enrollment.eventId ?? 0,
        includeProduct: true,
        includeProvider: true,
        includeEventSessions: true,
    });

    const [searchParams] = useSearchParams();
    const tabIndexQuery = searchParams.get("tabIndex") ?? "";
    const tabIndex = useMemo(() => NumberUtils.parseInt(tabIndexQuery) ?? 0, [tabIndexQuery]);
    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <UserTrainingDetailOverview
                    contractId={enrollment.contractId}
                    event={event}
                    product={enrollment.product}
                />
            ),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("trainingOverview"),
            },
        },
        {
            contents: (
                <>
                    {enrollment.product?.type === TrainingType.OnlineLearning ? (
                        <UserTrainingDetailOllProgress enrollmentId={enrollmentId} />
                    ) : (
                        <UserTrainingDetailIltProgress enrollmentId={enrollmentId} />
                    )}
                </>
            ),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("progress"),
            },
        },
        {
            contents: <UserTrainingDetailHistory enrollment={enrollment} />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("enrollmentHistory"),
            },
        },
        {
            contents: <UserTrainingDetailExpiration enrollmentId={enrollmentId} />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("trainingAccessExpiration"),
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <ButtonIcon
                                linkPath={RouteUtils.replacePathParams(
                                    sitemap.admin.userManagement.users.trainings.default,
                                    { id: userId }
                                )}
                                buttonSize={ButtonSize.Small}
                                buttonStyle={ButtonStyle.Secondary}
                                buttonType={ButtonType.Link}
                                iconType={Icons.ChevronLeft}
                                text={t("backToAllTrainings")}
                            />
                            {enrollment.eventId != null && enrollment.eventId > 0 && (
                                <Button
                                    linkPath={RouteUtils.replacePathParams(
                                        sitemap.admin.event.manage.details,
                                        { id: enrollment.eventId }
                                    )}
                                    size={ButtonSize.Small}
                                    style={ButtonStyle.Primary}
                                    text={t("goToEvent")}
                                    type={ButtonType.Link}
                                />
                            )}
                        </div>
                        <div className={`${CSS_CLASS_NAME}__details`}>
                            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                {enrollment?.product?.name ?? ""}
                            </Heading>
                            {enrollment.product != null && (
                                <div className={`${CSS_CLASS_NAME}__badges`}>
                                    <ProductBadge
                                        product={enrollment.product}
                                        event={event}
                                        includeIcon={false}
                                        upperCase={false}
                                    />
                                    {enrollment.completedOn != null && (
                                        <Badge
                                            style={BadgeStyle.Light}
                                            text={t("completionDateVariable", {
                                                completionDate: DateUtils.formatDateCustom(
                                                    enrollment.completedOn?.toString(),
                                                    "MM/DD/YYYY"
                                                ),
                                            })}
                                        />
                                    )}
                                    {enrollment.certificate?.expirationDate != null && (
                                        <Badge
                                            style={BadgeStyle.Light}
                                            text={t("certificateExpirationDateVariable", {
                                                certificateExpirationDate:
                                                    DateUtils.formatDateCustom(
                                                        enrollment.certificate?.expirationDate?.toString(),
                                                        "MM/DD/YYYY"
                                                    ),
                                            })}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <TabbedContainer tabs={tabs} currentTabIndex={tabIndex} />
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailPage };

// #endregion Exports
