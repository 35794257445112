import React, { useEffect, useMemo, useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input/input";
import { Paragraph } from "../../../../../components/typography/paragraph/paragraph";
import { Card } from "../../../../../components/card/card";
import { CountryConstants } from "constants/country-constants";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { FormSelect } from "components/form/form-select/form-select";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { sitemap } from "../../../../../sitemap";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { OrganizationService } from "utilities/services/organizations/organization-service";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { SelectOption } from "components/form/inputs/select/select";
import { Organization } from "models/interfaces/organizations/organization";
import { allCountries } from "country-region-data";
import { E164Number } from "libphonenumber-js";
import { ToastManager } from "utilities/toast/toast-manager";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { StringUtils } from "utilities/string-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useOrganizations } from "utilities/hooks/models/events/use-organizations";
import { useTimeZoneOptions } from "utilities/hooks/use-timezone-options";
import "./user-contracts-new-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsNewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-new-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsNewPage: React.FC<UserContractsNewPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsNewPage
)((): JSX.Element => {
    const [emailError, setEmailError] = useState("");
    const [organization, setOrganization] = useState<OrganizationRecord>(new OrganizationRecord());
    const [stateRegionConstants, setStateRegionConstants] = useState<SelectOption[]>([]);
    const { create: createOrganizationApi } = OrganizationService.useCreate();
    const { timeZoneOptions: timeZones } = useTimeZoneOptions();
    const navigate = useNavigate();

    // -------------------------------------------------------------------------------------------------
    // #region Validate Unique Organization Details
    // -------------------------------------------------------------------------------------------------

    const [netSuiteIdError, setNetSuiteIdError] = useState<string>("");
    const [netSuiteIdToCheck, setNetSuiteIdToCheck] = useState<string>("");

    const { organizations: organizationsWithNetSuiteId, isLoading: netSuiteIdCheckInProgress } =
        useOrganizations({
            nfpaId: netSuiteIdToCheck,
            searchCriteriaReady: StringUtils.hasValue(netSuiteIdToCheck),
            skip: 0,
            take: 1,
        });

    const onNetSuitIdBlur = (): void => {
        if (StringUtils.hasValue(organization.nfpaId)) {
            setNetSuiteIdToCheck(organization.nfpaId);
        }
    };

    useEffect(() => {
        if (organizationsWithNetSuiteId.length > 0) {
            setNetSuiteIdError(t("anOrganizationAlreadyExistsWithThisNetSuiteCustomerId"));
        } else {
            setNetSuiteIdError("");
        }
    }, [organizationsWithNetSuiteId]);

    // #endregion Validate Unique Organization Details

    const validateEmail = (email?: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
        } else {
            setEmailError("");
        }
    };

    const validatePhone = (phone?: string): boolean => {
        return !StringUtils.hasValue(phone) || isPossiblePhoneNumber(phone);
    };

    const createOrganization = async (): Promise<boolean> => {
        try {
            const createOrganizationResponse = await createOrganizationApi(organization);
            const createOrganizationResult = createOrganizationResponse.result;

            if (
                createOrganizationResult?.resultObject == null ||
                createOrganizationResult.hasErrors()
            ) {
                throw new Error();
            }

            navigate(sitemap.admin.userManagement.contracts.list);
        } catch {
            ToastManager.error(t("thereWasAnIssueAddingTheOrganization"));
            return false;
        }

        return true;
    };

    useEffect(() => {
        const stateRegionSelectOptions: SelectOption[] = [];

        if (organization.country) {
            const stateRegions = allCountries.find((c) => c[0] === organization.country);

            stateRegions![2].forEach((region) =>
                stateRegionSelectOptions.push({ text: region[0], value: region[0] })
            );
        }

        setStateRegionConstants(stateRegionSelectOptions);
    }, [organization.country]);

    const updateOrganization = (values: Partial<Organization>): void => {
        setOrganization(organization.with(values));
    };

    const handleTimeZoneChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ timeZone: event.target.value });
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ name: event.target.value });
    };

    const handleNetSuiteCustomerIDChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ nfpaId: event.target.value });
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ country: event.target.value, state: undefined });
    };

    const handleAddrOneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ addressLineOne: event.target.value });
    };

    const handleAddrTwoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ addressLineTwo: event.target.value });
    };

    const handleLocationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ locationName: event.target.value });
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ city: event.target.value });
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ state: event.target.value });
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ zipCode: event.target.value });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        validateEmail(event.target.value);
        updateOrganization({ email: event.target.value });
    };

    const handlePhoneChange = (value?: E164Number | undefined): void => {
        const valueToSet = value === undefined ? "" : value;
        updateOrganization({ phone: valueToSet });
    };

    const organizationIsValid = useMemo(() => {
        if (
            !StringUtils.hasValue(organization.name) ||
            !StringUtils.hasValue(organization.nfpaId) ||
            StringUtils.hasValue(netSuiteIdError) ||
            netSuiteIdCheckInProgress
        ) {
            return false;
        }

        // Optional Field Validation
        if (StringUtils.hasValue(emailError) || !validatePhone(organization.phone)) {
            return false;
        }

        return true;
    }, [
        emailError,
        netSuiteIdCheckInProgress,
        netSuiteIdError,
        organization.name,
        organization.nfpaId,
        organization.phone,
    ]);

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <SkipNavContent>
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("newContractOrganization")}
                    </Heading>
                    <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("basicInformation")}
                        </Heading>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormTextInput
                                ariaLabelledBy={t("organizationName")}
                                autoFocus={true}
                                formFieldName="organizationName"
                                id="organizationName"
                                label={t("organizationName")}
                                maxLength={150}
                                onChange={handleNameChange}
                                placeholder={t("enterOrganizationName")}
                                required={true}
                                type={InputTypes.Text}
                                value={organization.name}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("netsuiteCustomerID")}
                                formFieldName="netsuiteCustomerID"
                                id="netsuiteCustomerID"
                                label={t("netsuiteCustomerID")}
                                maxLength={60}
                                errorMessage={netSuiteIdError}
                                onBlur={onNetSuitIdBlur}
                                onChange={handleNetSuiteCustomerIDChange}
                                required={true}
                                placeholder={t("enterNetSuiteCustomerID")}
                                type={InputTypes.Text}
                                value={organization.nfpaId}
                            />
                        </div>
                    </Card>
                    <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("defaultEventInformation")}
                        </Heading>
                        <Paragraph>
                            {t("thePointOfContactForThisOrganizationInCaseLearnersHaveQuestions")}
                        </Paragraph>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("defaultEventContactInformation")}
                        </Heading>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormTextInput
                                ariaLabelledBy={t("email")}
                                errorMessage={emailError}
                                formFieldName="email"
                                id="email"
                                label={t("email")}
                                maxLength={250}
                                onChange={handleEmailChange}
                                placeholder={t("enterEmail")}
                                type={InputTypes.Email}
                                value={organization.email}
                            />
                            <FormPhoneInput
                                ariaLabelledBy={t("phone")}
                                autoFocus={false}
                                formFieldName="phone"
                                id="phone"
                                label={t("phone")}
                                onChange={handlePhoneChange}
                                placeholder="(000) 000-0000"
                                type={InputTypes.Phone}
                                value={organization.phone}
                            />
                        </div>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("defaultEventLocation")}
                        </Heading>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormTextInput
                                ariaLabelledBy={t("locationName")}
                                formFieldName="locationName"
                                id="locationName"
                                label={t("locationName")}
                                maxLength={100}
                                onChange={handleLocationNameChange}
                                placeholder={t("enterLocationName")}
                                type={InputTypes.Text}
                                value={organization.locationName}
                            />
                            <FormSelect
                                ariaLabelledBy={t("country")}
                                formFieldName="country"
                                id="country"
                                label={t("country")}
                                onChange={handleCountryChange}
                                options={CountryConstants}
                                placeholder={t("selectOption")}
                                value={organization.country}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormTextInput
                                ariaLabelledBy={t("addressLine1")}
                                formFieldName="addressLine1"
                                id="addressLine1"
                                label={t("addressLine1")}
                                maxLength={100}
                                onChange={handleAddrOneChange}
                                placeholder={t("enterAddressLine1")}
                                type={InputTypes.Text}
                                value={organization.addressLineOne}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("addressLine2")}
                                formFieldName="addressLine2"
                                id="addressLine2"
                                label={t("addressLine2")}
                                maxLength={100}
                                onChange={handleAddrTwoChange}
                                placeholder={t("enterAddressLine2")}
                                type={InputTypes.Text}
                                value={organization.addressLineTwo}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("citySlashTown")}
                                formFieldName="cityTown"
                                id="cityTown"
                                label={t("citySlashTown")}
                                maxLength={100}
                                onChange={handleCityChange}
                                placeholder={t("enterCitySlashTown")}
                                type={InputTypes.Text}
                                value={organization.city}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormSelect
                                ariaLabelledBy={t("stateSlashProvinceSlashRegion")}
                                disabled={organization.country == null}
                                formFieldName="stateProvinceRegion"
                                id="stateProvinceRegion"
                                label={t("stateSlashProvinceSlashRegion")}
                                onChange={handleStateChange}
                                options={stateRegionConstants}
                                placeholder={t("selectOption")}
                                value={organization.state}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("zipCodeSlashPostalCode")}
                                formFieldName="zipPostal"
                                id="zipPostal"
                                label={t("zipCodeSlashPostalCode")}
                                maxLength={100}
                                onChange={handleZipCodeChange}
                                placeholder={t("enterZipcodeSlashPostalCode")}
                                type={InputTypes.Text}
                                value={organization.zipCode}
                            />
                        </div>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("defaultEventTimezone")}
                        </Heading>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormSelect
                                ariaLabelledBy={t("timezone")}
                                formFieldName="timeZone"
                                id="timeZone"
                                label={t("timezone")}
                                placeholder={t("selectTimeZone")}
                                onChange={handleTimeZoneChange}
                                options={timeZones}
                                value={organization.timeZone}
                            />
                        </div>
                    </Card>
                </SkipNavContent>
            </div>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                <Button
                    linkPath={sitemap.admin.userManagement.contracts.list}
                    style={ButtonStyle.Secondary}
                    text={t("cancel")}
                    type={ButtonType.Link}
                />
                <Button
                    disabled={!organizationIsValid}
                    onClick={createOrganization}
                    style={ButtonStyle.Primary}
                    text={t("addOrganization")}
                />
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsNewPage };

// #endregion Exports
