import React, { useMemo, useState } from "react";
import { AenApplicationAttachmentForm } from "./aen-application-attachment-form/aen-application-attachment-form";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ApplicationFileRecord } from "models/view-models/aen-applications/application-file-record";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { StringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationAddFileModalProps {
    aenApplicationType: AenApplicationType;
    handleSaveAttachment: (attachment: ApplicationFileRecord) => Promise<boolean>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationAddFileModal: React.FC<AenApplicationAddFileModalProps> = ({
    aenApplicationType,
    handleSaveAttachment,
    open,
    setOpen,
}): JSX.Element => {
    const initialAttachment = useMemo(
        () => new ApplicationFileRecord({ applicationType: aenApplicationType }),
        [aenApplicationType]
    );
    const [attachment, setAttachment] = useState<ApplicationFileRecord>(initialAttachment);
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dirty, setDirty] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: !(
                StringUtils.hasValue(attachment.file?.fileName) &&
                StringUtils.hasValue(attachment.description) &&
                attachment.fileId !== undefined &&
                !addButtonDisabled
            ),
            onClick: () => onAttachmentSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setAttachment(initialAttachment);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
        }
    };

    const onAttachmentSave = async (): Promise<void> => {
        setAddButtonDisabled(true);
        if (await handleSaveAttachment(attachment)) {
            setDirty(false);
            setAttachment(initialAttachment);
            setOpen(false);
            setAddButtonDisabled(false);
        } else {
            return;
        }
    };

    const onAttachmentChanged = (attachment: ApplicationFileRecord): void => {
        setDirty(true);
        setAttachment(attachment);
    };

    return (
        <Modal
            isOpen={open}
            onModalClose={onModalClose}
            title={t("addFile")}
            actions={modalActionArray}
            modalStyle={""}>
            <AenApplicationAttachmentForm
                attachment={attachment}
                aenApplicationType={aenApplicationType}
                onAttachmentChanged={onAttachmentChanged}
            />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationAddFileModal };

// #endregion Exports
