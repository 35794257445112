import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { CompletionTab } from "components/tabs/tabs/completion-tab/completion-tab";
import { NumberedTab } from "components/tabs/tabs/numbered-tab/numbered-tab";
import { Tab } from "components/tabs/tabs/tab/tab";
import { BadgeSampler } from "pages/admin/admin-sandbox-page/badge-sampler/badge-sampler";
import { ButtonSampler } from "pages/admin/admin-sandbox-page/button-sampler/button-sampler";
import React from "react";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./admin-sandbox-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminSandboxPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-sandbox-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminSandboxPage: React.FC<AdminSandboxPageProps> = validatePageAccess(
    AccessControlKeys.AdminSandboxPage
)((): JSX.Element => {
    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: <ButtonSampler />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "Buttons",
            },
        },
        {
            contents: <BadgeSampler />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "Badges",
            },
        },
        {
            contents: <h1>Completed Tab Sample</h1>,
            tabComponent: CompletionTab,
            tabDisplayDetails: {
                isComplete: true,
                tabName: "Complete",
            },
        },
        {
            contents: <h1>Incomplete Tab Sample</h1>,
            tabComponent: CompletionTab,
            tabDisplayDetails: {
                isComplete: false,
                tabName: "Incomplete",
            },
        },
        {
            contents: <h1>Numbered Tab Sample</h1>,
            tabComponent: NumberedTab,
            tabDisplayDetails: {
                tabName: "Numbered",
                value: 5,
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <h1>Sandbox</h1>
            <TabbedContainer tabs={tabs} />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminSandboxPage };

// #endregion Exports
