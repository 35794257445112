import React from "react";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface WithdrawEnrollmentModalProps {
    actions: ModalAction[];
    isOpen: boolean;
    onClose: (close: boolean) => void;
    enrollmentName: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const WithdrawEnrollmentModal: React.FC<WithdrawEnrollmentModalProps> = ({
    actions,
    isOpen,
    onClose,
    enrollmentName,
}: WithdrawEnrollmentModalProps): JSX.Element => {
    return (
        <Modal
            actions={actions}
            isOpen={isOpen}
            modalStyle={""}
            onModalClose={onClose}
            title={t("withdrawALearner")}>
            <Paragraph size={ParagraphSize.Large}>
                {t("areYouSureYouWantToWithdrawThisLearner")}
            </Paragraph>
            <br />
            <Paragraph style={ParagraphStyle.Light}>
                {t("youAreWithdrawingEnrollmentName", { enrollmentName: enrollmentName })} <br />
                {t("thisWillImmediatelyRemoveThemFromThisTraining")}
            </Paragraph>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { WithdrawEnrollmentModal };

// #endregion Exports
