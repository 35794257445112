import React from "react";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { Bubble, BubbleStyle } from "components/bubble/bubble";
import { t } from "utilities/localization/t";
import "./topic-bubble.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TopicBubbleProps {
    topic: Topic;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEFAULT_BUTTON_STYLE: BubbleStyle = BubbleStyle.Blue;
const CSS_CLASS_NAME = "topic-bubble";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TopicBubble: React.FC<React.PropsWithChildren<TopicBubbleProps>> = (
    props: React.PropsWithChildren<TopicBubbleProps>
) => {
    const getBubbleStyle = (topic: Topic): BubbleStyle => {
        let style: BubbleStyle = DEFAULT_BUTTON_STYLE;
        switch (topic) {
            case Topic.BuildingLifeSafety: {
                style = BubbleStyle.TopicGreen;
                break;
            }
            case Topic.CertificationPrep: {
                style = BubbleStyle.TopicBrown;
                break;
            }
            case Topic.Electrical: {
                style = BubbleStyle.TopicRed;
                break;
            }
            case Topic.EmergencyResponse: {
                style = BubbleStyle.TopicDarkBlue;
                break;
            }
            case Topic.FireProtection: {
                style = BubbleStyle.TopicGray;
                break;
            }
            case Topic.IndustrialHazards: {
                style = BubbleStyle.TopicLightBlue;
                break;
            }
            case Topic.EmergingTechnology: {
                style = BubbleStyle.White;
                break;
            }
            case Topic.PublicEducation: {
                style = BubbleStyle.TopicBeige;
                break;
            }
            case Topic.Wildfire: {
                style = BubbleStyle.TopicOrange;
                break;
            }
            default: {
                style = BubbleStyle.Blue;
                break;
            }
        }

        return style;
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <Bubble text={t(TopicDisplayNames[props.topic])} style={getBubbleStyle(props.topic)} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TopicBubble };

// #endregion Exports
