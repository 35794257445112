import React from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenuFilePreview } from "components/context-menu/context-menu-file-preview/context-menu-file-preview";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { FileUtils } from "utilities/files/file-utils";
import { Icons } from "components/icons/constants/icons";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { t } from "utilities/localization/t";
import { Paragraph } from "components/typography/paragraph/paragraph";
import "./online-product-content-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface OnlineProductContentListProps {
    confirmProductContentDeletion: (productContent: ProductContentRecord, index: number) => void;
    disableDownArrowButtons: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => boolean;
    disableUpArrowButtons: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => boolean;
    handleContentToEdit: (productContent: ProductContentRecord, selectedIndex: number) => void;
    handleDownArrowClick: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => Promise<void>;
    handleUpArrowClick: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => Promise<void>;
    hasProductContents: boolean;
    noDataCellClassName: string;
    productContents?: ProductContentRecord[];
    isTrainTheTrainerType?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "online-product-content-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OnlineProductContentList: React.FC<OnlineProductContentListProps> = ({
    confirmProductContentDeletion,
    disableDownArrowButtons,
    disableUpArrowButtons,
    handleContentToEdit,
    handleDownArrowClick,
    handleUpArrowClick,
    hasProductContents,
    noDataCellClassName,
    productContents,
    isTrainTheTrainerType = false,
}) => {
    const sortedProductContents = productContents?.sort(
        (a: ProductContentRecord, b: ProductContentRecord) => a.sortOrder! - b.sortOrder!
    );
    return (
        <>
            <div className={`${CSS_CLASS_NAME}__table-wrapper`}>
                {hasProductContents && (
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th className="arrows" aria-labelledby={t("arrowColumn")}>
                                    <span className="sr-only">{t("changeOrder")}</span>
                                </th>
                                <th className="order" aria-labelledby={t("sortOrderColumn")}>
                                    {t("order")}
                                </th>
                                <th className="name" aria-labelledby={t("nameColumn")}>
                                    {t("name")}
                                </th>
                                <th
                                    className="description"
                                    aria-labelledby={t("descriptionColumn")}>
                                    {t("description")}
                                </th>
                                <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                    {t("fileName")}
                                </th>
                                <th className="action" aria-labelledby={t("menuColumn")}>
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedProductContents &&
                                sortedProductContents.map((productContent, sortedIdx) => (
                                    <tr key={sortedIdx}>
                                        <td className="arrows">
                                            <ButtonIcon
                                                ariaLabelledBy={t(
                                                    "moveContentProductContentContentNameUp",
                                                    {
                                                        productContentContentName:
                                                            productContent.content?.name,
                                                    }
                                                )}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-up"
                                                disabled={disableUpArrowButtons(
                                                    productContent,
                                                    productContent.access!
                                                )}
                                                iconType={Icons.ArrowUpward}
                                                onClick={() =>
                                                    handleUpArrowClick(
                                                        productContent,
                                                        productContent.access!
                                                    )
                                                }
                                            />
                                            <ButtonIcon
                                                ariaLabelledBy={t(
                                                    "moveContentProductContentContentNameDown",
                                                    {
                                                        productContentContentName:
                                                            productContent.content?.name,
                                                    }
                                                )}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-down"
                                                disabled={disableDownArrowButtons(
                                                    productContent,
                                                    productContent.access!
                                                )}
                                                iconType={Icons.ArrowDownward}
                                                onClick={() =>
                                                    handleDownArrowClick(
                                                        productContent,
                                                        productContent.access!
                                                    )
                                                }
                                            />
                                        </td>
                                        <td className={`order ${noDataCellClassName}`}>
                                            {productContent.sortOrder}
                                        </td>
                                        <td className="name">{productContent.content?.name}</td>
                                        <td className="description">
                                            <Paragraph>
                                                {productContent.content?.description}
                                            </Paragraph>
                                        </td>
                                        <td className="file-name">
                                            {productContent.content?.file?.fileName}
                                        </td>
                                        <td className="action">
                                            <ContextMenu>
                                                <ContextMenuFilePreview
                                                    hrefPath={FileUtils.fileUrl(
                                                        productContent.content?.fileId!
                                                    )}
                                                    displayName={t("preview")}
                                                />
                                                <ContextMenuButton
                                                    disabled={isTrainTheTrainerType}
                                                    onClick={() =>
                                                        handleContentToEdit(
                                                            productContent,
                                                            productContents!.indexOf(productContent)
                                                        )
                                                    }
                                                    displayName={t("replaceContent")}
                                                />
                                                <ContextMenuButton
                                                    disabled={isTrainTheTrainerType}
                                                    onClick={() =>
                                                        confirmProductContentDeletion(
                                                            productContent,
                                                            productContents!.indexOf(productContent)
                                                        )
                                                    }
                                                    displayName={t("delete")}
                                                />
                                            </ContextMenu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                )}
                {!hasProductContents && <EmptyText table>{t("noContentAdded")}</EmptyText>}
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OnlineProductContentList };

// #endregion Exports
