import { EnrollmentUnit } from "models/interfaces/enrollments/enrollment-unit";
import { Record } from "immutable";

// -----------------------------------------------------------------------------------------
// #region Default Values
// -----------------------------------------------------------------------------------------

const defaultValues: EnrollmentUnit = {
    name: "",
    courseCount: 0,
    optional: true,
    sortOrder: undefined,
    locked: false,
    percentComplete: 0,
    unitId: 0,
};

// #endregion Default Values

// -----------------------------------------------------------------------------------------
// #region Record
// -----------------------------------------------------------------------------------------

class EnrollmentUnitRecord extends Record(defaultValues) implements EnrollmentUnit {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<EnrollmentUnit>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Given a set of values for Enrollment properties, create a new EnrollmentRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EnrollmentUnit>} values The values to overwrite on this instance.
     * @returns A EnrollmentRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EnrollmentUnit>): EnrollmentUnitRecord {
        return new EnrollmentUnitRecord(Object.assign(this.toJS(), values));
    }
    // #endregion Public Methods
}

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { EnrollmentUnitRecord };
// #endregion Exports
