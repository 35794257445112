import React from "react";
import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";
import "./form-radio-button.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormRadioButtonProps<TValue extends string | string[] | number>
    extends FormFieldProps,
        InputProperties {
    checked: boolean;
    onChange?: () => void;
    value?: TValue;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-radio-input";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormRadioButton: React.FC<FormRadioButtonProps<string | string[] | number>> = (
    props: FormRadioButtonProps<string | string[] | number>
) => {
    return (
        <button
            className={`${CSS_CLASS_NAME} ${props.checked ? "-selected" : ""}`}
            onClick={props.onChange}
            tabIndex={props.tabIndex == null ? 0 : props.tabIndex}>
            <input
                autoFocus={props.autoFocus}
                checked={props.checked}
                disabled={props.disabled}
                onChange={() => {
                    /* Add onChange to to suppress React Warnings. Click is handled on div. */
                }}
                type="radio"
                value={props.value}
                tabIndex={props.tabIndex == null ? 0 : props.tabIndex}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </button>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormRadioButton };

// #endregion Exports
