import { PatchServiceHook } from "utilities/types/services/patch-service-hook";
import { ServiceFactory } from "utilities/services/service-factory";
import {
    ServiceHookFactory as AndcultureCodeReactServiceHookFactory,
    useCancellablePromise,
} from "andculturecode-javascript-react";
import { useCallback } from "react";
import { ServiceResponse } from "andculturecode-javascript-core";
import { PatchService } from "utilities/types/services/patch-service";

// -------------------------------------------------------------------------------------------------
// #region Factory Methods
// -------------------------------------------------------------------------------------------------

const usePatch = <TRecord, TPathParams = undefined, TPatchParams = undefined>(
    recordType: { new (): TRecord },
    resourceEndpoint: string
): PatchServiceHook<TRecord, TPathParams, TPatchParams> => {
    const usePatch = () => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch = ServiceFactory.patch<TRecord, TPathParams, TPatchParams>(
            recordType,
            resourceEndpoint
        );

        const patch: PatchService<TRecord, TPathParams, TPatchParams> = useCallback(
            (pathParams?: TPathParams, values?: TPatchParams): Promise<ServiceResponse<TRecord>> =>
                cancellablePromise(servicePatch(pathParams, values)),
            [cancellablePromise, servicePatch]
        );

        return { patch: patch };
    };

    return usePatch;
};

// #endregion Factory Methods

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ServiceHookFactory = {
    ...AndcultureCodeReactServiceHookFactory,
    usePatch: usePatch,
};

export { ServiceHookFactory };

// #endregion Exports
