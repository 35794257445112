import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnumStatusBadgeProps<T extends string | number | symbol> {
    cssClassName?: string;
    displayNames: Map<T, TranslatedCopy>;
    statusMap: Map<T, Status>;
    value: T;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnumStatusBadge = <T extends string | number | symbol>(
    props: EnumStatusBadgeProps<T>
): JSX.Element => {
    return (
        <StatusBadge
            cssClassName={props.cssClassName}
            status={props.statusMap[props.value]}
            text={props.displayNames[props.value]}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnumStatusBadge };

// #endregion Exports
