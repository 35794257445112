import React from "react";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { t } from "utilities/localization/t";
import "./event-provider-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProviderListProps {
    provider?: ProviderRecord;
    event: EventActiveRecord;
    onReplace: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-provider-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProviderList: React.FC<EventProviderListProps> = ({
    provider,
    event,
    onReplace,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="name">{t("name")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="action" aria-labelledby={t("menuColumn")}>
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {provider == null && (
                        <tr className={"-no-results-row"}>
                            <td colSpan={3}>
                                <EmptyText spacing={EmptyTextSpacing.None}>
                                    {t("noProviderSelected")}
                                </EmptyText>
                            </td>
                        </tr>
                    )}
                    {provider != null && (
                        <tr key={provider?.id}>
                            <td className="name">{provider?.name}</td>
                            <td className="id">{provider?.id}</td>
                            <td className="action">
                                <ContextMenu>
                                    <ContextMenuButton
                                        onClick={onReplace}
                                        displayName={t("replace")}
                                        disabled={
                                            event.organizationId != null ||
                                            event.status === PublishStatus.Live
                                        }
                                    />
                                </ContextMenu>
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProviderList };

// #endregion Exports
