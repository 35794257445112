import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Process } from "utilities/types/processes/process";
import { ProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { ProcessStatusDetermination } from "utilities/interfaces/processes/process-status-determination";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import { ReactNode } from "react";
import { StringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import { useLocation } from "react-router-dom";
import { useRequestNavigation } from "utilities/contexts/navigation/use-navigation-request-context";
import "./process-navigation-footer.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProcessNavigationFooterProps<T> {
    cssClassName?: string;
    footerComponent?: ReactNode;
    process?: Process<T>;
    processNavigator: ProcessNavigator<T>;
    processStatusDetermination?: ProcessStatusDetermination;
    saveAndExitPath: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "process-navigation-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProcessNavigationFooter = <T,>(
    props: React.PropsWithChildren<ProcessNavigationFooterProps<T>>
): JSX.Element => {
    const requestNavigation = useRequestNavigation();
    const { canGoToNext, canGoToPrevious, goToNext, goToPrevious } = props.processNavigator;
    const steps: ProcessStep[] = props.process ? Object.values(props.process) : [];
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    const previousDisabled: boolean = !canGoToPrevious;
    const nextDisabled: boolean = !canGoToNext;

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const cssClassName: string = cssClassNames.join(" ");

    const location = useLocation();
    const currentStep = steps.find((step) => step.path === location.pathname);
    const requireNavigationRequestNext =
        currentStep?.requestNavigationConfig == null
            ? () => goToNext()
            : () =>
                  requestNavigation({
                      onNavigationApproved: (): void => {
                          goToNext();
                      },
                      requestingComponent:
                          currentStep.requestNavigationConfig?.requestingComponents ?? "",
                      componentsAllowedToResolve:
                          currentStep.requestNavigationConfig?.componentsAllowedToResolve ?? [],
                  });

    const requireNavigationRequestPrevious =
        currentStep?.requestNavigationConfig == null
            ? () => goToPrevious()
            : () =>
                  requestNavigation({
                      onNavigationApproved: (): void => {
                          goToPrevious();
                      },
                      requestingComponent:
                          currentStep.requestNavigationConfig?.requestingComponents ?? "",
                      componentsAllowedToResolve:
                          currentStep.requestNavigationConfig?.componentsAllowedToResolve ?? [],
                  });

    return (
        <footer className={cssClassName}>
            <div className={`${CSS_CLASS_NAME}__sidebar-actions`}>{props.children}</div>
            <div className={`${CSS_CLASS_NAME}__nav`}>
                <div className={`${CSS_CLASS_NAME}__footer-actions`}>{props.footerComponent}</div>

                <div className={`${CSS_CLASS_NAME}__nav__actions`}>
                    <ButtonIcon
                        ariaLabelledBy={t("previousPage")}
                        buttonStyle={ButtonStyle.Secondary}
                        disabled={previousDisabled}
                        iconSize={IconSizes.Large}
                        iconType={Icons.ChevronLeft}
                        onClick={requireNavigationRequestPrevious}
                    />
                    <ButtonIcon
                        ariaLabelledBy={t("nextPage")}
                        buttonStyle={ButtonStyle.Secondary}
                        disabled={nextDisabled}
                        iconSize={IconSizes.Large}
                        iconType={Icons.ChevronRight}
                        onClick={requireNavigationRequestNext}
                    />
                </div>
            </div>
        </footer>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProcessNavigationFooter };

// #endregion Exports
