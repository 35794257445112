import React from "react";
import {
    AddBulkEnrollmentModalStage,
    AddBulkEnrollmentModalStageStatusMap,
} from "models/enumerations/enrollments/add-bulk-enrollment-modal-stage";
import { AddBulkEnrollmentSummary } from "../add-bulk-enrollment-summary/add-bulk-enrollment-summary";
import { BulkContractEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-contract-enrollment-summary-record";
import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { ResultRecord } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import "./add-bulk-enrollment-status-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddBulkEnrollmentStatusFormProps {
    bulkEnrollmentResult: ResultRecord<
        BulkEnrollmentSummaryRecord | BulkContractEnrollmentSummaryRecord
    >;
    stage: AddBulkEnrollmentModalStage;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-bulk-enrollment-status-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentStatusForm: React.FC<AddBulkEnrollmentStatusFormProps> = ({
    bulkEnrollmentResult,
    stage,
}) => {
    const showEnrollmentSummary = () => {
        if (stage === AddBulkEnrollmentModalStage.Complete) {
            return (
                <AddBulkEnrollmentSummary
                    bulkEnrollmentRecord={bulkEnrollmentResult.resultObject}
                />
            );
        } else if (stage === AddBulkEnrollmentModalStage.Errored) {
            return (
                <div>
                    <h6>{t("errorMessageColon")}</h6>
                    {bulkEnrollmentResult.errors?.map((err, idx) => (
                        <div key={idx}>{err.message}</div>
                    ))}
                </div>
            );
        }
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <h6>{t("importStatus")}</h6>
            <div className={`${CSS_CLASS_NAME}__message`}>
                {t(AddBulkEnrollmentModalStageStatusMap[stage])}
            </div>
            {showEnrollmentSummary()}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentStatusForm };

// #endregion Exports
