import React from "react";
import { ActivationModalUnitCourseList } from "components/units/unit-courses/activation-modal-unit-course-list/activation-modal-unit-course-list";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { ProductRecord } from "models/view-models/products/product-record";
import {
    ProductCourseType,
    ProductCourseTypeDisplayNames,
} from "models/enumerations/courses/product-course-type";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { TopicBubble } from "components/bubble/topic-bubble";
import "./product-activation-courses-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationCoursesSectionProps {
    product: ProductRecord;
    productVersion: ProductVersionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-courses-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationCoursesSection: React.FC<ProductActivationCoursesSectionProps> = ({
    product,
    productVersion,
}): JSX.Element => {
    const isInstructorLed = product.type === TrainingType.InstructorLedTraining;

    return (
        <div className={`${CSS_CLASS_NAME}__section`}>
            {isInstructorLed && (
                <>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("assessments")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="order">{t("order")}</th>
                                <th className="name">{t("name")}</th>
                                <th className="file-name">{t("fileName")}</th>
                                <th className="optional">{t("optional")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productVersion.productScormPackages?.map((productScormPackage) => (
                                <tr key={productScormPackage.id}>
                                    <td className="order">{productScormPackage.sortOrder}</td>
                                    <td className="name">
                                        {productScormPackage?.scormPackage?.name}
                                    </td>
                                    <td className="file-name">
                                        {productScormPackage?.scormPackage?.file?.fileName}
                                    </td>
                                    <td className="optional">
                                        <CheckboxInput
                                            checked={productScormPackage.optional}
                                            disabled={true}
                                            id={`product-scorm-package-${productScormPackage.id}-optional`}
                                            label={t("optional")}
                                            onChange={() => {}}
                                            size={CheckboxSize.Small}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </>
            )}
            {!isInstructorLed && product.onlineLearningType === ProductCourseType.LearningPath && (
                <>
                    <div className={`${CSS_CLASS_NAME}__courses__header`}>
                        <div className={`${CSS_CLASS_NAME}__courses__header__title`}>
                            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                {t("courses")}
                            </Heading>
                            <Badge
                                style={BadgeStyle.Inverted}
                                text={t(ProductCourseTypeDisplayNames[product.onlineLearningType])}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__courses__header__toggle`}>
                            <ToggleLabel
                                checked={productVersion.completeUnitsInOrder}
                                disabled={true}
                                id="complete-courses"
                                label={t("completeUnitsInOrder")}
                            />
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__courses`}>
                        {productVersion.units?.map((productUnit, index) => (
                            <div className="unit" key={`product-unit-${index}`}>
                                <div className="unit__header">
                                    <div className="unit__header__details">
                                        <Heading priority={HeadingPriority.H3}>
                                            {index + 1}. {productUnit.name}
                                        </Heading>
                                    </div>

                                    <div className="unit__header__actions">
                                        <div className="unit__header__actions__badges">
                                            {productUnit.optional && (
                                                <Badge
                                                    style={BadgeStyle.Inverted}
                                                    text={t("optional")}
                                                />
                                            )}

                                            {productUnit.completeUnitBeforeMovingForward && (
                                                <Badge style={BadgeStyle.Inverted}>
                                                    <span>{t("unitAbbreviated")}</span>{" "}
                                                    <Icon type={Icons.AllCompleted} />
                                                </Badge>
                                            )}

                                            {productUnit.completeCoursesInOrder && (
                                                <Badge style={BadgeStyle.Inverted}>
                                                    <span>{t("courseAbbreviated")}</span>{" "}
                                                    <Icon type={Icons.Checklist} />
                                                </Badge>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="unit__wrapper">
                                    <ActivationModalUnitCourseList
                                        unitCourses={productUnit.unitCourses}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {!isInstructorLed && product.onlineLearningType === ProductCourseType.CourseSeries && (
                <>
                    <div className={`${CSS_CLASS_NAME}__courses__header`}>
                        <div className={`${CSS_CLASS_NAME}__courses__header__title`}>
                            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                {t("courses")}
                            </Heading>
                            <Badge
                                style={BadgeStyle.Inverted}
                                text={t(ProductCourseTypeDisplayNames[product.onlineLearningType])}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__courses__header__toggle`}>
                            <ToggleLabel
                                checked={productVersion.units![0].completeCoursesInOrder}
                                disabled={true}
                                id="complete-courses"
                                label={t("completeCoursesInOrder")}
                            />
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__courses`}>
                        <DataTable cssClassName={CSS_CLASS_NAME}>
                            <thead>
                                <tr>
                                    <th className="order">{t("order")}</th>
                                    <th className="name">{t("name")}</th>
                                    <th className="topic">{t("topic")}</th>
                                    <th className="id">{t("id")}</th>
                                    <th className="optional">{t("optional")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productVersion.units &&
                                    productVersion.units[0] &&
                                    productVersion.units[0].unitCourses &&
                                    productVersion.units[0].unitCourses.map((unitCourse) => (
                                        <tr key={unitCourse.id}>
                                            <td className="order">{unitCourse.sortOrder}</td>
                                            <td className="name">{unitCourse.course?.name}</td>
                                            <td className="topic">
                                                {unitCourse.course?.topic != null && (
                                                    <TopicBubble topic={unitCourse.course?.topic} />
                                                )}
                                            </td>
                                            <td className="id">{unitCourse.course?.id}</td>
                                            <td className="optional">
                                                <CheckboxInput
                                                    checked={unitCourse.optional}
                                                    disabled={true}
                                                    id={`unit-course-${unitCourse.id}-optional`}
                                                    label={t("optional")}
                                                    onChange={() => {}}
                                                    size={CheckboxSize.Small}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </DataTable>
                    </div>
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationCoursesSection };

// #endregion Exports
