import React from "react";
import { ActionLinkCard, ActionLinkCardProps } from "../action-link-card";
import "./action-link-card-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ActionLinkCardListProps {
    links: ActionLinkCardProps[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "action-link-card-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ActionLinkCardList: React.FC<ActionLinkCardListProps> = (props: ActionLinkCardListProps) => {
    return (
        <div className={CSS_CLASS_NAME}>
            {props.links.map((link, index) => (
                <ActionLinkCard
                    key={`action-link-${index}`}
                    completionPercentage={link.completionPercentage}
                    description={link.description}
                    icon={link.icon}
                    status={link.status}
                    topics={link.topics}
                    trainingButtonLocked={link.trainingButtonLocked}
                    trainingButtonPath={link.trainingButtonPath}
                    trainingButtonText={link.trainingButtonText}
                    trainingType={link.trainingType}
                    type={link.type}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ActionLinkCardList;

// #endregion Exports
