import React from "react";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { t } from "utilities/localization/t";
import "./announcement-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AnnouncementListProps {
    announcements: AnnouncementRecord[];
    onArchiveAnnouncement: (announcement: AnnouncementRecord) => void;
    onEditAnnouncement: (announcement: AnnouncementRecord) => void;
    onPreviewAnnouncement: (announcement: AnnouncementRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "announcement-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementList: React.FC<AnnouncementListProps> = ({
    announcements,
    onArchiveAnnouncement,
    onEditAnnouncement,
    onPreviewAnnouncement,
}): JSX.Element => {
    const hasContextColumn = announcements.some((announcement) => announcement.archivedOn == null);

    return (
        <DataTable cssClassName={`${CSS_CLASS_NAME}__table`}>
            <thead>
                <tr>
                    <th className="status">{t("status")}</th>
                    <th className="announcement-name">{t("announcementName")}</th>
                    <th className="last-change">{t("lastChange")}</th>
                    <th className="changed-by">{t("changedBy")}</th>
                    {hasContextColumn ? (
                        <th className="action">
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    ) : null}
                </tr>
            </thead>
            <tbody>
                {announcements.map((announcement) => (
                    <tr key={announcement.id}>
                        <td className="status">
                            {announcement.archivedOn != null ? (
                                <Badge style={BadgeStyle.Default} text={t("archived")} />
                            ) : (
                                <Badge style={BadgeStyle.Success} text={t("live")} />
                            )}
                        </td>
                        <td className="announcement-name">{announcement.englishTitle}</td>
                        <td className="last-change">{announcement.getLastChangeDate()}</td>
                        <td className="changed-by">
                            {announcement.getLastChangedByName()}
                            <div className={`${CSS_CLASS_NAME}__changed-by-id`}>
                                {announcement.getLastChangedById()}
                            </div>
                        </td>
                        {announcement.archivedOn == null ? (
                            <td className="action">
                                <ContextMenu>
                                    <ContextMenuButton
                                        displayName={t("edit")}
                                        onClick={() => {
                                            onEditAnnouncement(announcement);
                                        }}
                                    />
                                    {/* TODO V1.1 > Uncomment when preview is ready
                                    <ContextMenuButton
                                        displayName={t("preview")}
                                        onClick={() => {
                                            onPreviewAnnouncement(announcement);
                                        }}
                                    />
                                    */}
                                    <ContextMenuButton
                                        displayName={t("archive")}
                                        onClick={() => {
                                            onArchiveAnnouncement(announcement);
                                        }}
                                    />
                                </ContextMenu>
                            </td>
                        ) : null}
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnnouncementList };

// #endregion Exports
