import { ReportAllTrainings } from "components/reports/report-all-trainings/report-all-trainings";
import { ReportAllUsers } from "components/reports/report-all-users/report-all-users";
import { ReportByTheNumbers } from "components/reports/report-by-the-numbers/report-by-the-numbers";
import { ReportEnrollment } from "components/reports/report-enrollment/report-enrollment";
import { ReportEvaluation } from "components/reports/report-evaluation/report-evaluation";
import { ReportTrainingMaterials } from "components/reports/report-training-materials/report-training-materials";
import { ReportTranscript } from "components/reports/report-transcript/report-transcript";
import { ReportsBaseProps } from "components/reports/reports-base-props";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type ReportSlug = keyof typeof REPORT_MAP;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

// TODO Needs to map to the key in the DB.
export const REPORT_MAP = {
    "by-the-numbers": ReportByTheNumbers,
    "training-transcript": ReportTranscript,
    "training-materials-outline": ReportTrainingMaterials,
    "all-trainings": ReportAllTrainings,
    evaluation: ReportEvaluation,
    "all-users": ReportAllUsers,
    enrollment: ReportEnrollment,
} as const;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportContainer: React.FC<ReportsBaseProps & { slug?: string }> = ({ slug, ...props }) => {
    if (!isKeyOfReportMap(slug)) {
        return null;
    }

    const WrappedReportComponent = REPORT_MAP[slug];
    return <WrappedReportComponent {...props} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

function isKeyOfReportMap(slug?: string): slug is ReportSlug {
    if (!slug || !(slug in REPORT_MAP)) {
        return false;
    }

    return true;
}

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportContainer };

// #endregion Exports
