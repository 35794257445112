import { useCallback, useMemo, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ALL_COMPONENTS: AllComponents = "*";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type AllComponents = "*";
type ListenerConfiguration = string[] | AllComponents;
type NavigationRequestListenerFunction = (
    listeningComponent: string,
    requestingComponents?: string[]
) => void;
export type RequestNavigationFunction = (request: NavigationRequest) => void;
export type ResolveNavigationRequestFunction = (
    resolvingComponent: string,
    requestingComponent: string
) => (() => void) | null;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface NavigationRequest {
    componentsAllowedToResolve?: string[];
    onNavigationApproved: () => void;
    requestingComponent: string;
}

interface NavigationRequestListenerConfigurations {
    [listeningComponent: string]: ListenerConfiguration;
}

interface NavigationRequestListeners {
    [listeningComponent: string]: NavigationRequest[];
}

export interface NavigationRequestManager {
    listenForNavigationRequests: NavigationRequestListenerFunction;
    navigationRequestListeners: NavigationRequestListeners;
    requestNavigation: RequestNavigationFunction;
    resolveNavigationRequest: ResolveNavigationRequestFunction;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useNavigationRequests = (): NavigationRequestManager => {
    const [requests, setRequests] = useState<NavigationRequest[]>([]);
    const [listeners, setListeners] = useState<NavigationRequestListenerConfigurations>({});

    const listenerConfigurationIsUpdated = useCallback(
        (
            existingConfiguration: ListenerConfiguration,
            incomingConfiguration: ListenerConfiguration
        ): boolean => {
            if (existingConfiguration == null) {
                return false;
            }

            if (
                typeof existingConfiguration === "string" &&
                existingConfiguration === incomingConfiguration
            ) {
                return true;
            }

            existingConfiguration = existingConfiguration as string[];
            incomingConfiguration = incomingConfiguration as string[];

            const sameLength = existingConfiguration.length === incomingConfiguration.length;
            const existingIsSubsetOfIncoming = existingConfiguration.every((component) =>
                incomingConfiguration.includes(component)
            );
            const bijection = sameLength && existingIsSubsetOfIncoming;

            return bijection;
        },
        []
    );

    const listenForNavigationRequests: NavigationRequestListenerFunction = useCallback(
        (listeningComponent: string, requestingComponents?: string[]): void => {
            const existingConfiguration = listeners[listeningComponent];
            var incomingConfiguration = requestingComponents ?? ALL_COMPONENTS;

            if (listenerConfigurationIsUpdated(existingConfiguration, incomingConfiguration)) {
                return;
            }

            setListeners(
                (
                    listeners: NavigationRequestListenerConfigurations
                ): NavigationRequestListenerConfigurations => ({
                    ...listeners,
                    [listeningComponent]: requestingComponents ?? ALL_COMPONENTS,
                })
            );
        },
        [listenerConfigurationIsUpdated, listeners]
    );

    const requestNavigation: RequestNavigationFunction = useCallback(
        (request: NavigationRequest): void => {
            const componentAlreadyRequestedNavigation = requests.some(
                (existingRequest: NavigationRequest): boolean =>
                    existingRequest.requestingComponent === request.requestingComponent
            );

            if (componentAlreadyRequestedNavigation) {
                return;
            }

            setRequests((requests) => [...requests, request]);
        },
        [requests]
    );

    const resolveNavigationRequest: ResolveNavigationRequestFunction = useCallback(
        (resolvingComponent: string, requestingComponent: string): (() => void) | null => {
            const navigationRequest = requests.find(
                (request: NavigationRequest): boolean =>
                    request.requestingComponent === requestingComponent &&
                    (request.componentsAllowedToResolve == null ||
                        request.componentsAllowedToResolve.includes(resolvingComponent))
            );

            if (navigationRequest == null) {
                return null;
            }

            setRequests((requests: NavigationRequest[]): NavigationRequest[] =>
                requests.filter((request) => request !== navigationRequest)
            );

            return navigationRequest.onNavigationApproved;
        },
        [requests]
    );

    const getNavigationRequestsForListeningComponent = useCallback(
        (
            listeningComponent: string,
            requestingComponents: ListenerConfiguration
        ): NavigationRequest[] => {
            const allowedRequests: NavigationRequest[] = requests.filter(
                (request: NavigationRequest): boolean =>
                    request.componentsAllowedToResolve == null ||
                    request.componentsAllowedToResolve.includes(listeningComponent)
            );

            if (requestingComponents == null) {
                return allowedRequests;
            }

            const requestedAllowedRequests: NavigationRequest[] = allowedRequests.filter(
                (request: NavigationRequest): boolean =>
                    requestingComponents.includes(request.requestingComponent)
            );

            return requestedAllowedRequests;
        },
        [requests]
    );

    const listenerConfigurationToRequests = useCallback(
        ([listeningComponent, requestingComponents]: [string, ListenerConfiguration]): [
            string,
            NavigationRequest[]
        ] => [
            listeningComponent,
            getNavigationRequestsForListeningComponent(
                listeningComponent,
                requestingComponents ?? ALL_COMPONENTS
            ),
        ],
        [getNavigationRequestsForListeningComponent]
    );

    const navigationRequestListeners: NavigationRequestListeners = useMemo(
        (): NavigationRequestListeners =>
            Object.entries(listeners)
                .map(listenerConfigurationToRequests)
                .reduce(
                    (listeners: NavigationRequestListeners, [listeningComponent, requests]) => ({
                        ...listeners,
                        [listeningComponent]: requests,
                    }),
                    {} as NavigationRequestListeners
                ),
        [listenerConfigurationToRequests, listeners]
    );

    const manager: NavigationRequestManager = useMemo(
        (): NavigationRequestManager => ({
            listenForNavigationRequests: listenForNavigationRequests,
            navigationRequestListeners: navigationRequestListeners,
            requestNavigation: requestNavigation,
            resolveNavigationRequest: resolveNavigationRequest,
        }),
        [
            listenForNavigationRequests,
            navigationRequestListeners,
            requestNavigation,
            resolveNavigationRequest,
        ]
    );

    return manager;
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useNavigationRequests };

// #endregion Exports
