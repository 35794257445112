import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import {
    GetProductVersionPathParams,
    GetProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { ProductAssessmentManager } from "components/products/product-assesment/product-assessment-manager";
import { ProductContentManager } from "components/products/product-content/product-content-manager";
import { ProductMaterialsChangeHistory } from "components/products/product-version/product-materials-change-history/product-materials-change-history";
import { ProductVersionArchiveRecord } from "models/view-models/products/product-version-archive-record";
import { RouteUtils } from "utilities/route-utils";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ProductAchievements } from "components/products/product-achievements/product-achievements";
import { ProductEvaluation } from "components/products/product-evaluation/product-evaluation";
import "./edit-assessment-product-version-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditAssessmentProductVersionMaterialsProps {
    productVersionArchiveData: ProductVersionArchiveRecord[];
    editMode: boolean;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditAssessmentProductVersionMaterials: React.FC<
    EditAssessmentProductVersionMaterialsProps
> = ({ productVersionArchiveData, editMode, setDirty }): JSX.Element => {
    const navigate = useNavigate();
    const { record: product, setRecord: setProduct } = useProduct();
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const { versionId } = useParams();
    const productVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const { get: getProductVersion } = ProductVersionService.useGet();
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const isInstructorLedTraining = product.type! === TrainingType.InstructorLedTraining;

    const fetchData = useCallback(async () => {
        try {
            const pathParams: GetProductVersionPathParams = {
                id: productVersionId,
            };
            const queryParams: GetProductVersionQueryParams = {
                includeUnit: false,
                includeContent: true,
                includeEvaluationTemplate: true,
                includeProductScormPackage: true,
                includeEnrollmentCounts: false,
            };

            const getResponse = await getProductVersion(pathParams, queryParams);
            const getResult = getResponse?.result;
            const editProductVersion = getResult?.resultObject;
            editProductVersion.sortAllChildren();

            setProductVersion(editProductVersion);
        } catch (error) {}
    }, [productVersionId, getProductVersion, setProductVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <ProductAssessmentManager
                    productVersion={productVersion}
                    setProductVersion={setProductVersion}
                    product={product}
                    editMode={editMode}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("assessments"),
                iconType: productVersion.assessmentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
        {
            contents: (
                <ProductContentManager
                    editMode={editMode}
                    product={product}
                    productVersion={productVersion}
                    setProductVersion={setProductVersion}
                    setDirty={setDirty}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("content"),
                iconType: productVersion.contentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
    ];

    if (isInstructorLedTraining) {
        tabs.push(
            {
                contents: (
                    <ProductAchievements
                        product={product}
                        editMode={editMode}
                        setDirty={setDirty}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("achievements"),
                    iconType: productVersion.achievementIsComplete(product.audienceType)
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductEvaluation
                        product={product}
                        setProduct={setProduct}
                        editMode={editMode}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("evaluation"),
                    iconType: product.evaluationTemplateIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            }
        );
    }

    tabs.push({
        contents: (
            <ProductMaterialsChangeHistory productVersionArchiveData={productVersionArchiveData} />
        ),
        tabComponent: IconTab,
        tabDisplayDetails: {
            tabName: t("changeHistory"),
        },
    });

    const onModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                    id: product.id,
                })
            )
        );
    };

    return (
        <div>
            <TabbedContainer tabs={tabs} />
            <CancelChangesModal
                open={showCancelCreateVersionModal}
                setOpen={setShowCancelCreateVersionModal}
                onModalClose={onModalClose}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditAssessmentProductVersionMaterials };

// #endregion Exports
