import { CollectionUtils as CollectionUtilsCore } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

const deduplicate = <T>(array: T[], equals?: (a: T, b: T) => boolean): T[] => {
    const equalityCheck = equals ?? _defaultEqualityCheck;

    return array.reduce((dedupedArray: T[], current: T): T[] => {
        const elementExists: boolean = dedupedArray.some((value: T): boolean =>
            equalityCheck(current, value)
        );

        if (elementExists) {
            return dedupedArray;
        }

        return [...dedupedArray, current];
    }, []);
};

const groupBy = <T, K extends keyof T>(array: T[], key: K) => {
    const map = new Map<T[K], T[]>();

    array.forEach((item) => {
        const itemKey = item[key];

        if (!map.has(itemKey)) {
            map.set(
                itemKey,
                array.filter((i) => i[key] === item[key])
            );
        }
    });

    return map;
};

const sortBySortOrder = <T extends { sortOrder?: number }>(a: T, b: T) =>
    (a.sortOrder ?? 0) - (b.sortOrder ?? 0);

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Private Helper Functions
// -------------------------------------------------------------------------------------------------

const _defaultEqualityCheck = <T>(a: T, b: T): boolean => a === b;

// #endregion Private Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const CollectionUtils = {
    ...CollectionUtilsCore,
    deduplicate: deduplicate,
    groupBy: groupBy,
    sortBySortOrder: sortBySortOrder,
};

export { CollectionUtils };

// #endregion Exports
