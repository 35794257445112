import { CourseVersionArchiveRecord } from "models/view-models/courses/course-version-archive-record";
import { CourseVersionArchiveService } from "utilities/services/courses/course-version-archive-service";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseCourseVersionArchiveHook {
    courseVersionArchive: CourseVersionArchiveRecord[];
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseCourseVersionArchiveHookOptions {
    courseId: number;
    courseVersionId: number;
    includeCreatedBy?: boolean;
}

export function useCourseVersionArchive(
    props: UseCourseVersionArchiveHookOptions
): UseCourseVersionArchiveHook {
    const [isLoading, setIsLoading] = useState(true);
    const [courseVersionArchive, setCourseVersionArchive] = useState<CourseVersionArchiveRecord[]>(
        []
    );
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [refresh, setRefresh] = useState(false);
    const { list: listCourseVersionArchive } = CourseVersionArchiveService.useList();
    const courseId = props.courseId;
    const courseVersionId = props.courseVersionId;
    const includeCreatedBy = props.includeCreatedBy ?? false;

    const fetchData = useCallback(async () => {
        try {
            (async function getCourseVersionArchive() {
                if (courseId === undefined) return;
                const { result, resultObjects } = await listCourseVersionArchive({
                    courseId,
                    courseVersionId,
                    includeCreatedBy,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setCourseVersionArchive(resultObjects);
            })();
        } catch {
            ToastManager.error("There was a problem returning course version archive.");
        }
    }, [courseId, courseVersionId, includeCreatedBy, listCourseVersionArchive]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        courseVersionArchive,
        setRefresh,
    };
}
