import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import {
    CreateScormPackageParams,
    ScormPackageResourceEndpointPathParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import { Modal, ModalAction } from "components/modal/modal";
import { ScormPackageDetailsForm } from "../../../scorm-packages/scorm-package-details-form/scorm-package-details-form";
import { ScormPackageRecord } from "../../../../models/view-models/scorm-packages/scorm-package-record";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { t } from "utilities/localization/t";
import "./zip-package-modal.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ZipPackageModalProps {
    open: boolean;
    replacing?: boolean;
    scormPackage?: ScormPackageRecord;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deferSave: boolean;
    courseVersion: CourseVersionRecord;
    setCourseVersion: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ZipPackageModal: React.FC<ZipPackageModalProps> = ({
    open,
    replacing,
    scormPackage,
    setOpen,
    deferSave,
    courseVersion,
    setCourseVersion,
}): JSX.Element => {
    const initialContent = useMemo(() => scormPackage ?? new ScormPackageRecord(), [scormPackage]);
    const [zipPackage, setZipPackage] = useState(initialContent);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dirty, setDirty] = useState(false);
    const { create: apiScormPackageCreate } = ScormPackageService.useCreate();
    const { update: updateScormPackageApi } = ScormPackageService.useUpdate();

    useEffect(() => {
        setZipPackage(initialContent);
    }, [initialContent]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("savePackage"),
            disabled: !StringUtils.hasValue(zipPackage?.name) || zipPackage?.fileId == null,
            onClick: () => {
                scormPackage?.id === undefined
                    ? saveCourseScormPackage()
                    : updateCourseScormPackage();
                setOpen(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                setOpen(false);
                resetData();
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
            resetData();
        }
    };

    const saveCourseScormPackage = async (): Promise<boolean> => {
        try {
            if (courseVersion.versionId == null) {
                throw new Error();
            }

            let scormPackageCreateParams: CreateScormPackageParams = {
                courseId: courseVersion.courseId!,
                fileId: zipPackage?.fileId!,
                name: zipPackage?.name!,
                isDraft: true,
            };

            if (deferSave) {
                scormPackageCreateParams.draftVersionId = courseVersion.versionId;
            } else {
                scormPackageCreateParams.courseVersionId = courseVersion.id;
                scormPackageCreateParams.isDraft = false;
            }

            const createScormPackageResponse = await apiScormPackageCreate(
                scormPackageCreateParams
            );
            const createScormPackageResult = createScormPackageResponse?.result;
            if (
                createScormPackageResult?.resultObject == null ||
                createScormPackageResult.hasErrors()
            ) {
                throw new Error(t("createScormPackageFailed"));
            }
            setCourseVersion(
                courseVersion.with({
                    scormPackage: createScormPackageResult?.resultObject.with({
                        file: zipPackage.file,
                    }),
                })
            );

            setZipPackage(createScormPackageResult?.resultObject.with({ file: zipPackage.file }));
        } catch {
            ToastManager.error(t("thereWasAProblemSavingTheZipPackage"));
            return false;
        }
        return true;
    };

    const updateCourseScormPackage = async (): Promise<boolean> => {
        try {
            const updateScormPackagePathParams: ScormPackageResourceEndpointPathParams = {
                id: zipPackage.id!,
            };
            setZipPackage(zipPackage.with({ status: ScormPackageImportStatus.Running }));

            const updateScormPackageResponse = await updateScormPackageApi(
                zipPackage,
                updateScormPackagePathParams
            );
            const updateResult = updateScormPackageResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            setCourseVersion(
                courseVersion.with({
                    scormPackage: updateResult?.resultObject.with({
                        hasValidAssessment: null,
                        status: ScormPackageImportStatus.Running,
                        file: zipPackage.file,
                    }),
                })
            );
            setZipPackage(
                updateResult?.resultObject.with({
                    hasValidAssessment: null,
                    status: ScormPackageImportStatus.Running,
                    file: zipPackage.file,
                })
            );
        } catch {
            ToastManager.error(t("thereWasAnIssueReplacingTheZipPackage"));
            return false;
        }
        return true;
    };

    const resetData = (): void => {
        if (scormPackage) {
            setZipPackage(initialContent);
        } else {
            setZipPackage(new ScormPackageRecord());
        }
    };

    const handleCourseScormChange = (zipPackage: ScormPackageRecord): void => {
        setZipPackage(zipPackage);
        setDirty(true);
    };

    return (
        <>
            <Modal
                cssClassName={CSS_CLASS_NAME}
                isOpen={open}
                onModalClose={onModalClose}
                title={replacing ? t("replacePackage") : t("addPackage")}
                actions={modalActionArray}
                modalStyle={""}>
                <ScormPackageDetailsForm
                    scormPackage={zipPackage}
                    onScormPackageChange={handleCourseScormChange}
                />
                <Modal
                    isOpen={showConfirmationModal}
                    onModalClose={() => {}}
                    actions={confirmationActionArray}
                    modalStyle={"-inverted"}>
                    {t("youHaveUnsavedChanges")}
                    <br></br> {t("areYouSureYouWantToExit")}
                </Modal>
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ZipPackageModal };

// #endregion Exports
