import axios from "axios";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "eventsessions";
const recordType: typeof EventSessionRecord = EventSessionRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type DeleteEventSessionService = (
    params: DeleteEventSessionParams
) => Promise<ServiceResponse<EventSessionRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DeleteEventSessionParams {
    eventSessionIds: number[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventSessionService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useBulkDelete: (): { bulkDelete: DeleteEventSessionService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: DeleteEventSessionService = (
            params: DeleteEventSessionParams
        ): Promise<ServiceResponse<EventSessionRecord>> => {
            return axios
                .delete(baseEndpoint, { data: params })
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function bulkDelete(
            params: DeleteEventSessionParams
        ): Promise<ServiceResponse<EventSessionRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<EventSessionRecord>
            >;
        }

        return { bulkDelete: useCallback(bulkDelete, [cancellablePromise]) };
    },
    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventSessionService };

// #endregion Exports
