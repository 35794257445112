import { Record } from "immutable";
import { Group } from "models/interfaces/groups/group";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Group = {
    createdById: undefined,
    createdOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    name: "",
    description: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class GroupRecord extends Record(defaultValues) implements Group {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Group>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public with(values: Partial<Group>): GroupRecord {
        return new GroupRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupRecord };

// #endregion Exports
