import { Entity } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum SearchSelectionModalActionType {
    Initialize,
    Reset,
    SearchResults,
    Select,
    Unselect,
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Types & Interfaces
// -------------------------------------------------------------------------------------------------

type SearchSelectionModalAction<TValue> =
    | {
          type: SearchSelectionModalActionType.Initialize;
          selected: TValue[];
      }
    | { type: SearchSelectionModalActionType.Reset }
    | { type: SearchSelectionModalActionType.SearchResults; results: TValue[] }
    | { type: SearchSelectionModalActionType.Select; id: string }
    | { type: SearchSelectionModalActionType.Unselect; id: string };

export type SearchSelectionModalState<TValue> = {
    initialized: boolean;
    searchResults: TValue[];
    selected: TValue[];
};

export type SearchSelectionModalReducer<TValue extends Entity> = (
    prevState: SearchSelectionModalState<TValue>,
    action: SearchSelectionModalAction<TValue>
) => SearchSelectionModalState<TValue>;

// #endregion Types & Interfaces

// -------------------------------------------------------------------------------------------------
// #region State & Reducer
// -------------------------------------------------------------------------------------------------

const searchSelectionModalDefaultState = {
    initialized: false,
    searchResults: [],
    selected: [],
};

const searchSelectionModalReducer = <TValue extends Entity>(
    prevState: SearchSelectionModalState<TValue>,
    action: SearchSelectionModalAction<TValue>
): SearchSelectionModalState<TValue> => {
    switch (action.type) {
        case SearchSelectionModalActionType.Initialize:
            if (prevState.initialized) {
                return prevState;
            }

            return {
                ...prevState,
                initialized: true,
                selected: action.selected,
            };
        case SearchSelectionModalActionType.Reset:
            return { ...searchSelectionModalDefaultState };
        case SearchSelectionModalActionType.SearchResults:
            return {
                ...prevState,
                searchResults: action.results,
            };
        case SearchSelectionModalActionType.Select:
            if (prevState.selected.some((val) => `${val.id}` === action.id)) {
                return prevState;
            }

            return {
                ...prevState,
                selected: [
                    ...prevState.selected,
                    prevState.searchResults.find((val) => `${val.id}` === action.id)!,
                ],
            };
        case SearchSelectionModalActionType.Unselect:
            return {
                ...prevState,
                selected: prevState.selected.filter((val) => `${val.id}` !== action.id),
            };
        default:
            return prevState;
    }
};

// #endregion State & Reducer

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { searchSelectionModalDefaultState, searchSelectionModalReducer };

// #endregion Exports
