import React, { useCallback } from "react";
import { StringUtils } from "utilities/string-utils";
import { Button } from "components/buttons/button/button";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icons } from "components/icons/constants/icons";
import { ToastManager } from "utilities/toast/toast-manager";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./registration-launch-button.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface RegistrationLaunchButtonProps {
    disabled?: boolean;
    onRegistrationChange?: (registration: RegistrationRecord) => void;
    enrollmentId?: number;
    enrollmentUnitCourseId?: number;
    enrollmentScormPackageAssessmentId?: number;
    registrationId?: number;
    scormPackageId?: number;
    text?: string;
    onLaunchButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const COURSE_LOAD_ERROR: TranslatedCopy = "thereWasAnIssueLaunchingThisCourse";
const CSS_CLASS_NAME: string = "registration-launch-button";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RegistrationLaunchButton: React.FC<RegistrationLaunchButtonProps> = (
    props: RegistrationLaunchButtonProps
) => {
    const { get: getLaunchLink } = RegistrationService.useGetLaunchLink();
    const { record: globalState } = useGlobalState();
    const { create: registrationCreate } = RegistrationService.useCreate();
    const buttonText = StringUtils.hasValue(props.text) ? props.text : t("launchCourse");

    const fetchRegistrationLaunchLink = useCallback(
        async (registrationId: number): Promise<string | undefined> => {
            try {
                if (registrationId == null) {
                    return;
                }

                const getResponse = await getLaunchLink({ id: registrationId });
                const getResult = getResponse?.result;

                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    ToastManager.error(t(COURSE_LOAD_ERROR));
                    return;
                }

                return getResult.resultObject;
            } catch {
                ToastManager.error(t(COURSE_LOAD_ERROR));
                return;
            }
        },
        [getLaunchLink]
    );

    const handleLaunchCourseClick = async () => {
        try {
            const userId = globalState?.currentIdentity?.user?.id;
            const scormPackageId = props.scormPackageId;
            const enrollmentId = props.enrollmentId;

            if (
                userId == null ||
                scormPackageId == null ||
                enrollmentId == null ||
                (props.enrollmentUnitCourseId == null &&
                    props.enrollmentScormPackageAssessmentId == null)
            ) {
                return;
            }

            const registration = new RegistrationRecord({
                enrollmentId: enrollmentId,
                enrollmentUnitCourseId: props.enrollmentUnitCourseId,
                enrollmentScormPackageAssessmentId: props.enrollmentScormPackageAssessmentId,
                scormPackageId: scormPackageId,
                userId: userId,
                skipExternalRegistration: false,
            });

            const createRegistrationResponse = await registrationCreate(registration);
            const createRegistrationResult = createRegistrationResponse?.result;

            if (
                createRegistrationResult?.resultObject == null ||
                createRegistrationResult.hasErrors()
            ) {
                ToastManager.error(t(COURSE_LOAD_ERROR));
                return;
            }

            const newRegistration = createRegistrationResult?.resultObject;

            const launchLink = await fetchRegistrationLaunchLink(newRegistration.id);
            if (launchLink) {
                window.open(launchLink);
            }

            if (newRegistration && props.onRegistrationChange) {
                props.onRegistrationChange(newRegistration!);
            }

            //reload registrations
            props.onLaunchButtonClicked(true);
        } catch (error: unknown) {
            ToastManager.error(t(COURSE_LOAD_ERROR));
            return;
        }
    };

    if (props.disabled) {
        return (
            <ButtonIcon
                iconType={Icons.Lock}
                text={buttonText}
                disabled={true}
                cssClassName={`${CSS_CLASS_NAME}`}
            />
        );
    }

    return (
        <Button
            text={buttonText}
            onClick={handleLaunchCourseClick}
            cssClassName={`${CSS_CLASS_NAME}`}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RegistrationLaunchButton };

// #endregion Exports
