import React from "react";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { RecordUtils } from "andculturecode-javascript-core";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./product-create-assessment-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCreateAssessmentFormProps {
    scormPackage: ScormPackageRecord;
    onScormPackageChanged: (scormPackage: ScormPackageRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-create-assessment-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCreateAssessmentForm: React.FC<ProductCreateAssessmentFormProps> = (props) => {
    const handleFileChange = (file: FileRecord): void => {
        handleSetFileId({
            file: RecordUtils.ensureRecord(file, FileRecord),
            fileId: file.id,
        });
    };

    const handleSetFileId = (values: Partial<ScormPackageRecord>): void => {
        props.onScormPackageChanged(props.scormPackage.with(values));
    };

    const validateScormFile = (file: File): boolean => {
        const fileExtensionValid: boolean = file.name.endsWith(".zip");
        const fileTypeValid: boolean =
            file.type.startsWith("application/") &&
            (file.type.includes("zip") || file.type.includes("compressed"));

        return fileExtensionValid && fileTypeValid;
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("youMustUploadAZipFile"));
    };

    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onScormPackageChanged(props.scormPackage.with({ name: e.target.value }));
    };

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <FormTextInput
                    formFieldName="packageName"
                    id="packageName"
                    label={t("name")}
                    maxLength={60}
                    onChange={handleSetName}
                    placeholder={t("enterName")}
                    required={true}
                    type={InputTypes.Text}
                    value={props.scormPackage.name}
                />
                <FormFileInput
                    buttonText={t("chooseFile")}
                    fileIsValid={validateScormFile}
                    formFieldName="scormFile"
                    id="scormFile"
                    label={t("fileUpload")}
                    onFileInvalid={handleInvalidFile}
                    onFileUpload={handleFileChange}
                    placeholder={t("selectFile")}
                    storageContainer={StorageContainers.ScormPackages}
                    value={props.scormPackage.fileId}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCreateAssessmentForm };

// #endregion Exports
