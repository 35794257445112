import React, { useMemo } from "react";
import { NumberUtils } from "utilities/number-utils";
import { useParams } from "react-router-dom";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { useProductContents } from "utilities/hooks/models/products/use-product-contents";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { LearnerContentItem } from "components/learner/learner-content-item/learner-content-item";
import { t } from "utilities/localization/t";
import "./learner-online-learning-content-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningContentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-content-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningContentPage: React.FC<LearnerOnlineLearningContentPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningContentPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { productId: productIdParam } = useParams();
        const productId = useMemo(
            () => NumberUtils.parseInt(productIdParam) ?? 0,
            [productIdParam]
        );
        const { contents } = useProductContents({ productId: productId, includeContent: true });

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Content} size={IconSizes.Large} />
                    <Heading size={HeadingSize.Small}>{t("content")}</Heading>
                </div>

                <div className={`${CSS_CLASS_NAME}__content-section`}>
                    {contents.map(
                        (contentItem: ProductContentRecord): JSX.Element => (
                            <LearnerContentItem
                                contentItem={contentItem.content}
                                key={contentItem.id}
                            />
                        )
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__content-footer`}>
                    <Paragraph style={ParagraphStyle.Light}>
                        {t("AdditionalResourcesMayBeFoundWithinEachCourse")}
                    </Paragraph>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningContentPage };

// #endregion Exports
