import { CreateInstructorApplicationEnrollmentDto } from "models/interfaces/enrollments/create-instructor-application-enrollment-dto";
import {
    InstructorApplicationEnrollmentPathParams,
    InstructorApplicationEnrollmentService,
    WithdrawInstructorApplicationEnrollmentPathParams,
} from "utilities/services/enrollments/instructor-application-enrollment-service";
import { InstructorApplicationEnrollmentRecord } from "models/view-models/enrollments/instructor-application-enrollment-record";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

interface useInstructorApplicationEnrollmentsHook {
    addEnrollment: (
        createInstructorApplicationEnrollmentDto: CreateInstructorApplicationEnrollmentDto
    ) => Promise<boolean>;
    errors?: ResultErrorRecord[];
    instructorApplicationEnrollments: InstructorApplicationEnrollmentRecord[];
    isLoading: boolean;
    withdrawEnrollment: (
        instructorApplicationEnrollment: InstructorApplicationEnrollmentRecord
    ) => Promise<boolean>;
}

interface useInstructorApplicationEnrollmentsHookOptions {
    instructorApplicationId: number;
}

export function useInstructorApplicationEnrollments(
    props: useInstructorApplicationEnrollmentsHookOptions
): useInstructorApplicationEnrollmentsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [instructorApplicationEnrollments, setInstructorApplicationEnrollments] = useState<
        InstructorApplicationEnrollmentRecord[]
    >([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const instructorApplicationId = props.instructorApplicationId;

    const { list: listInstructorApplicationEnrollmentApi } =
        InstructorApplicationEnrollmentService.useList();
    const { create: instructorApplicationEnrollmentCreate } =
        InstructorApplicationEnrollmentService.useCreate();
    const { patch: withdrawEnrollmentApi } =
        InstructorApplicationEnrollmentService.useWithdrawEnrollment();

    const fetchData = useCallback(async () => {
        try {
            (async function getEnrollments() {
                const listInstructorApplicationEnrollmentsPathParams: InstructorApplicationEnrollmentPathParams =
                    {
                        instructorApplicationId: instructorApplicationId,
                    };

                const { result, resultObjects } = await listInstructorApplicationEnrollmentApi(
                    listInstructorApplicationEnrollmentsPathParams
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setInstructorApplicationEnrollments(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningTheInstructorApplicationEnrollments"));
        }
    }, [instructorApplicationId, listInstructorApplicationEnrollmentApi]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const addEnrollment = async (
        createInstructorApplicationEnrollmentDto: CreateInstructorApplicationEnrollmentDto
    ): Promise<boolean> => {
        try {
            if (
                createInstructorApplicationEnrollmentDto?.productId <= 0 ||
                createInstructorApplicationEnrollmentDto === undefined ||
                instructorApplicationId <= 0
            ) {
                throw new Error(t("creatingAnEnrollmentFailed"));
            }
            const createInstructorApplicationEnrollmentPathParams: InstructorApplicationEnrollmentPathParams =
                {
                    instructorApplicationId: instructorApplicationId,
                };

            const createEnrollmentResponse = await instructorApplicationEnrollmentCreate(
                createInstructorApplicationEnrollmentDto,
                createInstructorApplicationEnrollmentPathParams
            );

            const createEnrollmentResult = createEnrollmentResponse?.result;

            if (
                createEnrollmentResult?.resultObject == null ||
                createEnrollmentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemCreatingANewEnrollment"));
            }
            fetchData();
            ToastManager.success(t("enrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("failedToCreateANewEnrollment"));
            return false;
        }
    };

    const withdrawEnrollment = async (
        instructorApplicationEnrollment: InstructorApplicationEnrollmentRecord
    ): Promise<boolean> => {
        try {
            const pathParams: WithdrawInstructorApplicationEnrollmentPathParams = {
                instructorApplicationId: instructorApplicationId,
                instructorApplicationEnrollmentId: instructorApplicationEnrollment.id!,
            };

            const withdrawEnrollmentResponse = await withdrawEnrollmentApi(pathParams);
            const withdrawResult = withdrawEnrollmentResponse?.result;
            if (withdrawResult?.resultObject == null || withdrawResult.hasErrors()) {
                throw new Error();
            }

            fetchData();
            ToastManager.success(t("withdrawEnrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueWithdrawingAnEnrollment"));
            return false;
        }
    };

    return {
        addEnrollment,
        errors,
        isLoading,
        instructorApplicationEnrollments,
        withdrawEnrollment,
    };
}
