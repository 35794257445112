

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

import { TranslatedCopy } from "utilities/interfaces/culture-resources";

enum Status {
    Default,
    Error,
    Inverted,
    Neutral,
    Success,
    Warning,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const StatusTranslationKeyMap: Record<Status, TranslatedCopy> = {
    [Status.Default]: "default",
    [Status.Error]: "error",
    [Status.Inverted]: "inverted",
    [Status.Neutral]: "neutral",
    [Status.Success]: "success",
    [Status.Warning]: "warning",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Status, StatusTranslationKeyMap };

// #endregion Exports
