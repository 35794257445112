import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderBanner } from "components/header-banner/header-banner";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Icons } from "components/icons/constants/icons";
import { CalendarMonthView } from "../../../components/calendar-month-view/calendar-month-view";
import moment from "moment";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { QuickLinkProps } from "../../../components/quick-link/quick-link";
import QuickLinkList from "../../../components/quick-link/quick-link-list/quick-link-list";
import { ActionLinkCardProps } from "../../../components/action-card/action-link-card";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useCalendarEvents } from "utilities/hooks/models/events/use-calendar-events";
import { RouteUtils } from "utilities/route-utils";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import {
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { useNavigate } from "react-router-dom";
import { ToastManager } from "utilities/toast/toast-manager";
import { ProviderStatusCard } from "components/providers/provider-status-card/provider-status-card";
import "./provider-dashboard-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderDashboardPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-dashboard-page";

const QUICK_LINKS: QuickLinkProps[] = [
    {
        icon: Icons.Calendar,
        path: sitemap.admin.event.list,
        text: "events",
    },
    {
        icon: Icons.AenManager,
        path: sitemap.aenProvider.educationNetworkHub.organization,
        text: "aenHub",
    },
    {
        icon: Icons.Support,
        path: sitemap.authenticated.support,
        text: "support",
    },
    {
        icon: Icons.Products,
        path: sitemap.aenProvider.productCatalog.list,
        text: "enProductCatalog",
    },
    /* TODO: NFPA-9433: Instructor Directory
    {
        icon: Icons.Teaching,
        path: "/",
        text: "enInstructorDirectory",
    },*/
    {
        icon: Icons.Reporting,
        path: sitemap.admin.reports.list,
        text: "reports",
    },
];

const ACTION_LINKS: ActionLinkCardProps[] = [
    {
        description:
            "Resource Name Lorem ipsum dolor sit amet coectetur. Mauris facilisi lorem ipsum.",
        icon: Icons.AenManager,
        trainingButtonPath: "/",
        trainingButtonText: "viewTraining",
        type: "AEN RESOURCE",
    },
    {
        description:
            "Resource Name Lorem ipsum dolor sit amet coectetur. Mauris facilisi lorem ipsum.",
        icon: Icons.AenManager,
        trainingButtonPath: "/",
        trainingButtonText: "viewTraining",
        type: "AEN RESOURCE",
    },
    {
        description:
            "Resource Name Lorem ipsum dolor sit amet coectetur. Mauris facilisi lorem ipsum.",
        icon: Icons.AenManager,
        trainingButtonPath: "/",
        trainingButtonText: "viewTraining",
        type: "AEN RESOURCE",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderDashboardPage: React.FC<ProviderDashboardPageProps> = validatePageAccess(
    AccessControlKeys.ProviderDashboardPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const navigate = useNavigate();
    const providerId = useMemo(
        () => globalState?.currentIdentity?.user?.providerId ?? -1,
        [globalState?.currentIdentity?.user?.providerId]
    );
    const { get: getProvider } = ProviderService.useGet();
    const [associatedProvider, setAssociatedProvider] = useState<ProviderRecord>();

    const canEditEvents = associatedProvider?.isActive;

    const userFirstName = useMemo(
        () => globalState?.currentIdentity?.user?.firstName,
        [globalState?.currentIdentity?.user?.firstName]
    );

    const [calendarMonth, setCalendarMonth] = useState<moment.Moment>(moment().startOf("month"));

    const { events: calendarEvents } = useCalendarEvents({
        calendarIsHidden:
            globalState.currentIdentity?.user?.providerId == null ||
            globalState.currentIdentity?.user?.providerId <= 0,
        month: calendarMonth.toDate().getMonth(),
        providerId: globalState?.currentIdentity?.user?.providerId ?? 0,
        year: calendarMonth.toDate().getFullYear(),
    });

    const handleNextMonthClick = () => setCalendarMonth(calendarMonth.clone().add(1, "month"));
    const handlePreviousMonthClick = () =>
        setCalendarMonth(calendarMonth.clone().subtract(1, "month"));

    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const fetchProvider = useCallback(async () => {
        try {
            const pathParams: ProviderResourcePathParams = {
                id: providerId,
            };

            const providerResponse = await getProvider(pathParams);
            const providerResult = providerResponse?.result;

            if (providerResult?.resultObject == null || providerResult.hasErrors()) {
                throw new Error();
            }
            setAssociatedProvider(providerResult.resultObject);
        } catch {
            ToastManager.error(t("problemLoadingProvider"));
        }
    }, [getProvider, providerId]);

    useEffect(() => {
        fetchProvider();
    }, [fetchProvider]);

    return (
        <>
            <HeaderBanner
                title={t("twelcomeUserFirstName", {
                    twelcome: t("welcome"),
                    userFirstName: userFirstName,
                })}
            />
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <ProviderStatusCard provider={associatedProvider} />
                    </div>
                    {/*TODO: NFPA-9435: Hiding AEN Admin Provider banners until we develop them.
                    <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__notifications`}>
                        <Banner
                            style={BannerStyle.Success}
                            cssClassName={`${CSS_CLASS_NAME}__banner`}>
                            <div className={`${CSS_CLASS_NAME}__banner__message`}>
                                <Icon type={Icons.Calendar} size={IconSizes.Small} />
                                <Paragraph size={ParagraphSize.XSmall}>
                                    You Have a New Training Starting March 27th for Training Name
                                    Lorem Ipsum Dolor Sit Amet
                                </Paragraph>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__banner__actions`}>
                                <Anchor path="" text={t("viewTraining")} />
                                <button type="button" onClick={() => {}}>
                                    <span className="sr-only">{t("close")}</span>
                                    <Icon
                                        cssClassName="close"
                                        type={Icons.Close}
                                        size={IconSizes.Medium}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </Banner>

                    </div>
                    */}
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("eventCalendar")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <CalendarMonthView
                            events={calendarEvents}
                            onNextMonthClick={handleNextMonthClick}
                            getEventDisplayName={(event) => event.product?.name ?? event.name}
                            onPreviousMonthClick={handlePreviousMonthClick}
                            onEditEventClick={
                                canEditEvents
                                    ? (eventId: number) =>
                                          navigate(
                                              RouteUtils.localizePath(
                                                  RouteUtils.replacePathParams(
                                                      sitemap.admin.event.edit.contact,
                                                      { id: eventId }
                                                  )
                                              )
                                          )
                                    : undefined
                            }
                            onViewEventClick={(eventId: number) =>
                                navigate(
                                    RouteUtils.localizePath(
                                        RouteUtils.replacePathParams(viewEventRoutes.details, {
                                            id: eventId,
                                        })
                                    )
                                )
                            }
                        />
                    </div>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("quickLinks")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <QuickLinkList links={QUICK_LINKS} />
                    </div>
                    {/*TODO: NFPA-9434: Hiding EN Resources Trainings Until Further Notice
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("educationNetworkResources")}
                    </Heading>

                     <div className={`${CSS_CLASS_NAME}__section`}>
                        <ActionLinkCardList links={ACTION_LINKS} />
                    </div> */}
                </div>
            </SkipNavContent>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderDashboardPage };

// #endregion Exports
