import React from "react";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-live-virtual-location-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventLiveVirtualLocationFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-live-virtual-location-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventLiveVirtualLocationForm: React.FC<EventLiveVirtualLocationFormProps> = ({
    event,
}): JSX.Element => {
    const handleMeetingUrlChange = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
        event.updateActiveRecord({ meetingUrl: changeEvent.target.value });
    };

    const handleAdditionalDetailsChange = (
        changeEvent: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        event.updateActiveRecord({ additionalAddressDetails: changeEvent.target.value });
    };

    const handleBlur = (): void => {
        if (event.isDraft()) {
            event.save();
        }
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <FormTextInput
                ariaLabelledBy={t("meetingUrl")}
                autoFocus={false}
                formFieldName="meetingURL"
                id="meetingURL"
                label={t("meetingUrl")}
                maxLength={2083}
                onBlur={handleBlur}
                onChange={handleMeetingUrlChange}
                placeholder={t("enterMeetingUrl")}
                required={true}
                type={InputTypes.Text}
                value={event.meetingUrl}
            />
            <FormTextArea
                ariaLabelledBy={t("additionalDetails")}
                cssClassName={`${CSS_CLASS_NAME}__additional-details`}
                formFieldName="additionalDetails"
                id="additionalDetails"
                label={t("additionalDetails")}
                onBlur={handleBlur}
                onChange={handleAdditionalDetailsChange}
                placeholder={t("enterAdditionalDetails")}
                required={false}
                rows={6}
                value={event.additionalAddressDetails}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventLiveVirtualLocationForm };

// #endregion Exports
