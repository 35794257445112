import React, { useMemo } from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { DateUtils } from "utilities/date-utils";
import { Donut } from "components/donut/donut";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./assignment-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssignmentCardProps {
    enrollment: EnrollmentRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "assignment-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getTrainingPath = (enrollment: EnrollmentRecord) => {
    if (enrollment.id == null) {
        return "";
    }

    if (enrollment.eventId != null && enrollment.eventId > 0) {
        return getEventTrainingPath(enrollment.id, enrollment.eventId);
    }

    return getOLLTrainingPath(enrollment.id, enrollment.productId);
};

const getEventTrainingPath = (enrollmentId: number, eventId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.event.details, {
        id: eventId,
        enrollmentId: enrollmentId,
    });
};

const getOLLTrainingPath = (enrollmentId: number, productId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.onlineLearning.details, {
        productId: productId,
        enrollmentId: enrollmentId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssignmentCard: React.FC<AssignmentCardProps> = (props: AssignmentCardProps): JSX.Element => {
    const { enrollment } = props;

    const trainingPath = useMemo(() => {
        return getTrainingPath(enrollment);
    }, [enrollment]);

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__status`}>
                <div className={`${CSS_CLASS_NAME}__product-type-badge`}>
                    {enrollment.product != null && (
                        <ProductBadge
                            product={enrollment.product}
                            event={enrollment.event}
                            includeIcon={true}
                            upperCase={true}
                        />
                    )}
                </div>
                <Donut includeLabel={true} percent={enrollment.percentComplete ?? 0} />
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <h3>{enrollment.product?.name}</h3>
                <Paragraph style={ParagraphStyle.Label}>
                    {enrollment.event != null && (
                        <>
                            {DateUtils.formatDateCustom(
                                enrollment.event?.startDate()?.toString(),
                                t("shortDateFormat")
                            )}
                            {" - "}
                            {DateUtils.formatDateCustom(
                                enrollment.event?.endDate()?.toString(),
                                t("shortDateFormat")
                            )}
                        </>
                    )}
                    {enrollment.event == null && (
                        <>
                            {enrollment.expirationDate &&
                                t("expExpirationDate", {
                                    expirationDate: DateUtils.formatDateCustom(
                                        enrollment.expirationDate?.toString(),
                                        t("shortDateFormat")
                                    ),
                                })}
                        </>
                    )}
                </Paragraph>
            </div>
            <Button
                linkPath={trainingPath}
                size={ButtonSize.Medium}
                style={ButtonStyle.Primary}
                text={`${
                    enrollment.percentComplete != null && enrollment.percentComplete > 0
                        ? t("resumeTraining")
                        : t("viewTraining")
                }`}
                type={ButtonType.Link}
            />
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssignmentCard };

// #endregion Exports
