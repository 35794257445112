import { AenApplicationActivityLogRecord } from "models/view-models/aen-applications/aen-application-activity-log-record";
import { DataTable } from "components/tables/data-table/data-table";
import { t } from "utilities/localization/t";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { useState } from "react";
import { Modal, ModalAction } from "components/modal/modal";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import "./aen-application-log-list.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AenApplicationsLogListProps {
    logs?: AenApplicationActivityLogRecord[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-application-log-list";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AenApplicationLogList: React.FC<AenApplicationsLogListProps> = ({ logs }) => {
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [messageModalLog, setMessageModalLog] = useState<AenApplicationActivityLogRecord>();

    const handleDisplayMessageClick = (log: AenApplicationActivityLogRecord) => {
        setMessageModalLog(log);
        setShowMessageModal(true);
    };

    const onModalClose = () => {
        setMessageModalLog(undefined);
        setShowMessageModal(false);
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("close"),
            onClick: onModalClose,
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="date" aria-labelledby="date">
                            {t("date")}
                        </th>
                        <th className="activity" aria-labelledby="activity">
                            {t("activity")}
                        </th>
                        <th className="activity-by" aria-labelledby="activity-by">
                            {t("activityBy")}
                        </th>
                        <th className="notes" aria-labelledby="notes">
                            {t("notes")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {logs?.map((log) => (
                        <tr key={log.id}>
                            <td>{log.getLogDate()}</td>
                            <td>
                                {log.activity}
                                {log.activity && log.sendMessage && <br />}
                                {log.sendMessage && (
                                    <>
                                        <Button
                                            cssClassName={`${CSS_CLASS_NAME}__message__sent`}
                                            size={ButtonSize.None}
                                            style={ButtonStyle.Link}
                                            onClick={() => handleDisplayMessageClick(log)}>
                                            {t("messageSentToApplicant")}
                                        </Button>
                                    </>
                                )}
                            </td>
                            <td>{log?.getFirstAndLastName()}</td>
                            <td>
                                <span>{log?.notes}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </DataTable>
            <Modal
                isOpen={showMessageModal}
                onModalClose={setShowMessageModal}
                title={t("messageToApplicant")}
                actions={modalActionArray}
                modalStyle={""}>
                <>
                    <FormFieldReadonly label={t("emailSubject")}>
                        {messageModalLog?.messageSubject}
                    </FormFieldReadonly>

                    <FormFieldReadonly label={t("emailBody")}>
                        {messageModalLog?.messageBody}
                    </FormFieldReadonly>
                </>
            </Modal>
        </>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AenApplicationLogList };

// #endregion Exports
