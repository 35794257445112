import { FileRecord } from "../files/file-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { ScormPackage } from "models/interfaces/scorm-packages/scorm-package";
import { ScormPackageAssessmentRecord } from "./scorm-package-assessment-record";
import { StringUtils } from "utilities/string-utils";
import { ProductScormPackageRecord } from "../products/product-scorm-package-record";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ScormPackage = {
    course: undefined,
    courseId: undefined,
    courseVersion: undefined,
    courseVersionId: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    externalScormCourseId: "",
    externalScormVersionId: undefined,
    file: undefined,
    fileId: undefined,
    hasValidAssessment: undefined,
    id: undefined,
    name: undefined,
    productScormPackage: undefined,
    providerImportJobId: undefined,
    requirePassed: false,
    scormPackageAssessment: undefined,
    status: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ScormPackageRecord extends Record(defaultValues) implements ScormPackage {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ScormPackage>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.scormPackageAssessment != null) {
            params.scormPackageAssessment = RecordUtils.ensureRecord(
                params.scormPackageAssessment,
                ScormPackageAssessmentRecord
            );
        }

        if (params.file != null) {
            params.file = RecordUtils.ensureRecord(params.file, FileRecord);
        }

        if (params.productScormPackage != null) {
            params.productScormPackage = RecordUtils.ensureRecord(
                params.productScormPackage,
                ProductScormPackageRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if this record has all the data it needs to be considered
     * complete.
     *
     * @param {number}courseId The ID for the Course record this ScormPackage should be associated with.
     * @returns {boolean} Whether or not this record has all of its data.
     */
    public isComplete(courseId: number): boolean {
        return (
            this.courseId === courseId &&
            this.fileId != null &&
            StringUtils.hasValue(this.name) &&
            this.status === ScormPackageImportStatus.Complete
        );
    }

    public with(values: Partial<ScormPackage>): ScormPackageRecord {
        return new ScormPackageRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageRecord };

// #endregion Exports
