import { CollectionUtils, ResultRecord } from "andculturecode-javascript-core";
import { EventContentListItem } from "components/events/event-content-list/event-content-list-item/event-content-list-item";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { useCallback, useEffect, useState } from "react";
import {
    EventContentService,
    ListEventContentsQueryParams,
} from "utilities/services/events/event-content-service";
import { EventService, GetEventPathParams } from "utilities/services/events/event-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { t } from "utilities/localization/t";
import "./event-content-list.scss";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventContentListProps {
    contentColumns?: number;
    eventId: number;
    productContentAccess: ProductContentAccess;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-content-list";
const PRODUCT_NOT_ASSIGNED_ERROR_KEY = "EventContentsController.Error.ProductNotAssigned";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventContentList: React.FC<EventContentListProps> = (
    props: EventContentListProps
): JSX.Element | null => {
    const { get: getEvent } = EventService.useGet();
    const { list } = EventContentService.useList();
    const [contents, setContents] = useState<ProductContentRecord[]>([]);

    const fetchData = useCallback(async () => {
        const queryParams: ListEventContentsQueryParams = {
            productContentAccess: props.productContentAccess,
        };

        try {
            const listResponse = await list({ id: props.eventId }, queryParams);

            if (
                listResponse?.resultObjects == null ||
                listResponse.results == null ||
                listResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            const productContents = listResponse.resultObjects.sort(
                (a, b) => a.sortOrder! - b.sortOrder!
            );

            setContents(productContents);
        } catch (error) {
            if (typeof error === "string") {
                ToastManager.error(t("thereWasAnIssueLoadingEventContent"));
                setContents([]);
            } else if (error instanceof ResultRecord) {
                const { errors } = error;
                if (errors?.some((error) => error.key === PRODUCT_NOT_ASSIGNED_ERROR_KEY)) {
                    setContents([]);
                }
            }
            return false;
        }
    }, [list, props.eventId, props.productContentAccess]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchEvent = useCallback(async () => {
        const pathParams: GetEventPathParams = {
            id: props.eventId,
        };

        try {
            const getResponse = await getEvent(pathParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheEvent"));
        }
    }, [props.eventId, getEvent]);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    const gridCols = (props.contentColumns ?? 0) > 0 ? Math.floor(props.contentColumns!) : 1;

    return (
        <div className={CSS_CLASS_NAME}>
            {props.title && (
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {props.title}
                </Heading>
            )}
            {CollectionUtils.isEmpty(contents) ? (
                <EmptyText spacing={EmptyTextSpacing.Small}>{t("noContentAdded")}</EmptyText>
            ) : (
                <div className={`${CSS_CLASS_NAME}__content-section grid-cols-${gridCols}`}>
                    <>
                        {contents.map(
                            (contentItem: ProductContentRecord): JSX.Element => (
                                <EventContentListItem
                                    contentItem={contentItem.content}
                                    key={contentItem.id}
                                />
                            )
                        )}
                    </>
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContentList };

// #endregion Exports
