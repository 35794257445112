import React from "react";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ApplicationFileRecord } from "models/view-models/aen-applications/application-file-record";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import {
    InstructorApplicationFileType,
    InstructorApplicationFileTypeDisplayNames,
} from "models/enumerations/aen-applications/instructor-application-file-type";
import {
    ProviderApplicationFileType,
    ProviderApplicationFileTypeDisplayNames,
} from "models/enumerations/aen-applications/provider-application-file-type";
import { RecordUtils } from "andculturecode-javascript-core";
import { SelectOption } from "components/form/inputs/select/select";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./aen-application-attachment-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationAttachmentFormProps {
    attachment: ApplicationFileRecord;
    aenApplicationType: AenApplicationType;
    onAttachmentChanged: (attachment: ApplicationFileRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-application-attachment-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationAttachmentForm: React.FC<AenApplicationAttachmentFormProps> = ({
    attachment,
    aenApplicationType,
    onAttachmentChanged,
}) => {
    const updateAttachment = (values: Partial<ApplicationFileRecord>) => {
        onAttachmentChanged(attachment.with(values));
    };

    const handleSetFileId = (file: FileRecord) => {
        onAttachmentChanged(
            attachment.with({
                file: RecordUtils.ensureRecord(file, FileRecord),
                fileId: file.id,
            })
        );
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("theSelectedFileTypeIsNotPermitted"));
    };

    const handleFileTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateAttachment(
            EnumUtils.enumToObjectPartial(
                e.target.value,
                (value) => ({ type: Number(value) }),
                aenApplicationType === AenApplicationType.Instructor
                    ? InstructorApplicationFileType
                    : ProviderApplicationFileType
            )
        );
    };

    const handleSetDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateAttachment({ description: e.target.value });
    };

    const instructorApplicationFileTypeSelectOption: SelectOption[] =
        EnumUtils.numericEnumToSelectOptions(
            InstructorApplicationFileType,
            InstructorApplicationFileTypeDisplayNames
        );
    const providerApplicationFileTypeSelectOption: SelectOption[] =
        EnumUtils.numericEnumToSelectOptions(
            ProviderApplicationFileType,
            ProviderApplicationFileTypeDisplayNames
        );

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <FormSelect
                    ariaLabelledBy={t("fileTypeSelection")}
                    formFieldName="fileType"
                    id="fileType"
                    label={t("fileType")}
                    onChange={handleFileTypeChange}
                    options={
                        aenApplicationType === AenApplicationType.Instructor
                            ? instructorApplicationFileTypeSelectOption
                            : providerApplicationFileTypeSelectOption
                    }
                    required={true}
                    value={attachment.type?.toString()}
                />
                <FormTextArea
                    ariaLabelledBy={t("description")}
                    formFieldName="attachmentDescription"
                    id="attachmentDescription"
                    label={t("description")}
                    maxLength={1000}
                    onChange={handleSetDescription}
                    placeholder={t("enterDescription")}
                    rows={5}
                    value={attachment.description}
                />
                <FormFileInput
                    ariaLabelledBy={t("chooseFile")}
                    buttonText={t("chooseFile")}
                    formFieldName="file"
                    id="file"
                    label={t("fileUpload")}
                    onFileInvalid={handleInvalidFile}
                    onFileUpload={handleSetFileId}
                    placeholder={t("selectFile")}
                    required={true}
                    storageContainer={StorageContainers.FileUpload}
                    value={attachment.fileId}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationAttachmentForm };

// #endregion Exports
