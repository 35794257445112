import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum TrainingType {
    InstructorLedTraining = 0,
    OnlineLearning = 1,
    TrainTheTrainer = 2,
    InstructorAssessment = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const TrainingTypeDisplayNames: Map<TrainingType, TranslatedCopy> = {
    [TrainingType.InstructorLedTraining]: "instructorLedTraining",
    [TrainingType.OnlineLearning]: "onlineLearning",
    [TrainingType.TrainTheTrainer]: "trainTheTrainer",
    [TrainingType.InstructorAssessment]: "instructorAssessment",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TrainingType, TrainingTypeDisplayNames };

// #endregion Exports
