import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContentRecord } from "models/view-models/contents/content-record";
import {
    ContentService,
    UpdateContentPathParams,
} from "utilities/services/contents/content-service";
import { ContextMenu } from "../../../context-menu/context-menu/context-menu";
import { ContextMenuButton } from "../../../context-menu/context-menu-button/context-menu-button";
import { ContextMenuFilePreview } from "components/context-menu/context-menu-file-preview/context-menu-file-preview";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { CourseCreateContentModal } from "../course-create-content-modal/course-create-content-modal";
import {
    CourseContentService,
    UpdateCourseContentPathParams,
} from "utilities/services/courses/course-content-service";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "../../../empty-text/empty-text";
import { FileUtils } from "utilities/files/file-utils";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NumberUtils } from "utilities/number-utils";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useParams } from "react-router-dom";
import "./course-content-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseContentListProps {
    courseContents: CourseContentRecord[];
    courseId?: number;
    fetchCourseContents: () => Promise<void>;
    deferSave: boolean;
    courseVersion: CourseVersionRecord;
    setCourseVersion: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "content-list-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseContentList: React.FC<CourseContentListProps> = ({
    courseContents,
    courseId,
    courseVersion,
    deferSave,
    fetchCourseContents,
    setCourseVersion,
    setDirty,
}) => {
    const { versionId } = useParams();
    const courseVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const [showModal, setShowModal] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const { update: updateCourseContentApi } = CourseContentService.useUpdate();
    const { update: updateContentApi } = ContentService.useUpdate();
    const { delete: deleteApi } = CourseContentService.useDelete();
    const [courseContentToDelete, setCourseContentToDelete] = useState<CourseContentRecord>(
        new CourseContentRecord()
    );
    const [
        showCourseContentDeletionConfirmationModal,
        setShowCourseContentDeletionConfirmationModal,
    ] = useState(false);
    const [courseContentToEdit, setCourseContentToEdit] = useState<CourseContentRecord>(
        new CourseContentRecord()
    );
    const [selectedCourseContentIndex, setSelectedCourseContentIndex] = useState<number>(0);

    courseContents?.sort(
        (a: CourseContentRecord, b: CourseContentRecord) => a.sortOrder! - b.sortOrder!
    );

    //FOR REPLACING CONTENT
    const [tempCourseContents, setTempCourseContents] =
        useState<CourseContentRecord[]>(courseContents);
    useEffect(() => {
        setTempCourseContents(courseContents);
    }, [courseContents]);

    const [courseContentWithUpdates, setCourseContentWithUpdates] =
        useState<CourseContentRecord>(courseContentToEdit);
    useEffect(() => {
        if (deferSave) {
            setCourseContentWithUpdates(courseContentToEdit);
        }
    }, [courseContentToEdit, deferSave]);

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowCourseContentDeletionConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleCourseContentDelete(),
            style: ButtonStyle.Destructive,
        },
    ];

    const handleContentToEdit = (
        courseContent: CourseContentRecord,
        courseContentIndex: number
    ) => {
        setCourseContentToEdit(courseContent);
        setSelectedCourseContentIndex(courseContentIndex);
        setShowModal(true);
    };

    const confirmCourseContentDeletion = (courseContent: CourseContentRecord) => {
        setShowCourseContentDeletionConfirmationModal(true);
        setCourseContentToDelete(courseContent);
    };

    const handleCourseContentDelete = async (): Promise<void> => {
        const higherSortOrderCourseContentsToUpdate = courseContents?.slice(
            courseContentToDelete.sortOrder
        );

        if (deferSave) {
            setDirty && setDirty(true);
            //Here you want to remove it from the latestVersion
            let updateCourseContentsList = courseContents.slice();
            if (updateCourseContentsList !== undefined) {
                const item = updateCourseContentsList.find(
                    (x) => x.contentId === courseContentToDelete.contentId!
                );
                if (item !== undefined) {
                    const index = updateCourseContentsList.indexOf(item, 0);
                    if (index > -1) {
                        updateCourseContentsList.splice(index, 1);

                        //update sort order of higher sort order course contents
                        for (var i = index; i < updateCourseContentsList.length; i++) {
                            updateCourseContentsList[i] = new CourseContentRecord({
                                content: updateCourseContentsList[i].content,
                                contentId: updateCourseContentsList[i].content?.id,
                                courseId: updateCourseContentsList[i].courseId,
                                courseVersionId: courseVersionId,
                                id: updateCourseContentsList[i].id,
                                sortOrder: i + 1,
                            });
                        }
                    }

                    setCourseVersion(
                        courseVersion.with({ courseContents: updateCourseContentsList })
                    );
                }
            }
        } else {
            try {
                await deleteApi(courseContentToDelete.id!);
                setShowCourseContentDeletionConfirmationModal(false);

                const apiCalls: Promise<boolean>[] = [];

                higherSortOrderCourseContentsToUpdate?.forEach((cc) =>
                    apiCalls.push(moveUpCourseContent(cc))
                );

                await Promise.all(apiCalls);

                fetchCourseContents();
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingTheCourseContent"));
            }
        }
        setShowCourseContentDeletionConfirmationModal(false);
    };

    const courseContentIsFirst = useCallback(
        (courseContent: CourseContentRecord): boolean => {
            return (
                CollectionUtils.isEmpty(courseContents) ||
                courseContent.sortOrder === Math.min(...courseContents.map((u) => u.sortOrder!))
            );
        },
        [courseContents]
    );

    const courseContentIsLast = useCallback(
        (courseContent: CourseContentRecord): boolean => {
            return (
                CollectionUtils.isEmpty(courseContents) ||
                courseContent.sortOrder === Math.max(...courseContents.map((u) => u.sortOrder!))
            );
        },
        [courseContents]
    );

    const disableUpArrowButtons = useCallback(
        (courseContent: CourseContentRecord) => {
            return updateInProgress || courseContentIsFirst(courseContent);
        },
        [courseContentIsFirst, updateInProgress]
    );

    const disableDownArrowButtons = useCallback(
        (courseContent: CourseContentRecord) => {
            return updateInProgress || courseContentIsLast(courseContent);
        },
        [updateInProgress, courseContentIsLast]
    );

    const moveDownCourseContent = async (courseContent: CourseContentRecord): Promise<boolean> => {
        return updateCourseContentSortOrder(courseContent, courseContent.sortOrder! + 1);
    };

    const moveUpCourseContent = (courseContent: CourseContentRecord): Promise<boolean> => {
        return updateCourseContentSortOrder(courseContent, courseContent.sortOrder! - 1);
    };

    const updateCourseContentSortOrder = async (
        courseContent: CourseContentRecord,
        sortOrder: number
    ): Promise<boolean> => {
        const updatedCourseContents = courseContent.with({
            sortOrder: sortOrder,
        });

        return await updateCourseContent(updatedCourseContents);
    };

    const handleContentDetailsChange = async (content: ContentRecord): Promise<boolean> => {
        setShowModal(false);
        try {
            const updateContentPathParams: UpdateContentPathParams = {
                id: content.id!,
            };

            const updateContentResponse = await updateContentApi(content, updateContentPathParams);

            const updateResult = updateContentResponse.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            fetchCourseContents();
        } catch {
            ToastManager.error(t("thereWasAProblemUpdatingTheContent"));
            return false;
        }
        return true;
    };

    const updateCourseContent = async (
        coursesContentWithChanges: CourseContentRecord
    ): Promise<boolean> => {
        setUpdateInProgress(true);

        const updateCourseContentPathParams: UpdateCourseContentPathParams = {
            id: coursesContentWithChanges.id!,
        };

        try {
            const updateCourseContentResponse = await updateCourseContentApi(
                coursesContentWithChanges,
                updateCourseContentPathParams
            );

            setUpdateInProgress(false);

            const updateResult = updateCourseContentResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheContent"));
            return false;
        }
        return true;
    };

    const getCourseContentBySortOrder = (sortOrder: number): CourseContentRecord | null => {
        if (CollectionUtils.isEmpty(courseContents)) {
            return null;
        }

        const courseContent = courseContents.find((cc) => cc.sortOrder === sortOrder);

        return courseContent == null ? null : courseContent;
    };

    const handleUpArrowClick = async (courseContent: CourseContentRecord): Promise<void> => {
        if (courseContentIsFirst(courseContent)) {
            return;
        }

        if (deferSave) {
            setDirty && setDirty(true);
            const clickedItemIndex = tempCourseContents.findIndex(
                (item) => item.contentId === courseContent.contentId
            );

            // TODO: It would be nice to move this to a function that is used by handleDownArrowClick as well
            let courseContentsWithChanges = [...tempCourseContents];
            let temp = tempCourseContents[clickedItemIndex];
            courseContentsWithChanges[clickedItemIndex] = tempCourseContents[clickedItemIndex - 1];
            courseContentsWithChanges[clickedItemIndex - 1] = temp;
            for (var i = 0; i < courseContentsWithChanges.length; i++) {
                courseContentsWithChanges[i] = new CourseContentRecord({
                    content: courseContentsWithChanges[i].content,
                    contentId: courseContentsWithChanges[i].content?.id,
                    courseId: courseContentsWithChanges[i].courseId,
                    courseVersionId: courseVersionId,
                    id: courseContentsWithChanges[i].id,
                    sortOrder: i + 1,
                });
            }

            setCourseVersion(courseVersion.with({ courseContents: courseContentsWithChanges }));
        } else {
            const apiCalls: Promise<boolean>[] = [moveUpCourseContent(courseContent)];

            const unitCourseToMoveDown = getCourseContentBySortOrder(courseContent.sortOrder! - 1);

            if (unitCourseToMoveDown != null) {
                apiCalls.push(moveDownCourseContent(unitCourseToMoveDown));
            }

            await Promise.all(apiCalls);

            fetchCourseContents();
        }
    };

    const handleDownArrowClick = async (courseContent: CourseContentRecord): Promise<void> => {
        if (courseContentIsLast(courseContent)) {
            return;
        }

        if (deferSave) {
            setDirty && setDirty(true);
            const clickedItemIndex = tempCourseContents.findIndex(
                (item) => item.contentId === courseContent.contentId
            );

            // TODO: It would be nice to move this to a function that is used by handleUpArrowClick as well
            let courseContentsWithChanges = [...tempCourseContents];
            let temp = tempCourseContents[clickedItemIndex];
            courseContentsWithChanges[clickedItemIndex] = tempCourseContents[clickedItemIndex + 1];
            courseContentsWithChanges[clickedItemIndex + 1] = temp;
            for (var i = 0; i < courseContentsWithChanges.length; i++) {
                courseContentsWithChanges[i] = new CourseContentRecord({
                    content: courseContentsWithChanges[i].content,
                    contentId: courseContentsWithChanges[i].content?.id,
                    courseId: courseContentsWithChanges[i].courseId,
                    courseVersionId: courseVersionId,
                    id: courseContentsWithChanges[i].id,
                    sortOrder: i + 1,
                });
            }

            setCourseVersion(courseVersion.with({ courseContents: courseContentsWithChanges }));
        } else {
            const apiCalls: Promise<boolean>[] = [moveDownCourseContent(courseContent)];

            const unitCourseToMoveUp = getCourseContentBySortOrder(courseContent.sortOrder! + 1);

            if (unitCourseToMoveUp != null) {
                apiCalls.push(moveUpCourseContent(unitCourseToMoveUp));
            }

            await Promise.all(apiCalls);

            fetchCourseContents();
        }
    };

    const hasCourseContents = courseContents != null && courseContents.length > 0;
    const noDataCellClassName = hasCourseContents ? "" : "hidden";

    return (
        <div className={`${CSS_CLASS_NAME}__table-wrapper`}>
            {CollectionUtils.hasValues(courseContents) ? (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th
                                className={`arrows ${noDataCellClassName}`}
                                aria-labelledby={t("arrowColumn")}>
                                <span className="sr-only">{t("changeOrder")}</span>
                            </th>
                            <th
                                className={`order ${noDataCellClassName}`}
                                aria-labelledby={t("sortOrderColumn")}>
                                {t("order")}
                            </th>
                            <th className="name" aria-labelledby={t("nameColumn")}>
                                {t("name")}
                            </th>
                            <th className="description" aria-labelledby={t("descriptionColumn")}>
                                {t("description")}
                            </th>
                            <th className="file" aria-labelledby={t("fileNameColumn")}>
                                {t("fileName")}
                            </th>
                            <th className="action" aria-labelledby={t("menuColumn")}>
                                <span className="sr-only">{t("action")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {hasCourseContents &&
                            (deferSave ? tempCourseContents : courseContents).map(
                                (courseContent, courseContentIndex) => (
                                    <tr
                                        key={`${courseContentIndex}-${courseContent.id}-${courseContent.content?.id}-${courseContent.courseVersionId}`}>
                                        <td className={`arrows ${noDataCellClassName}`}>
                                            <ButtonIcon
                                                ariaLabelledBy={t(
                                                    "moveContentCourseContentContentNameUp",
                                                    {
                                                        courseContentContentName:
                                                            courseContent.content?.name,
                                                    }
                                                )}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-up"
                                                disabled={disableUpArrowButtons(courseContent)}
                                                iconType={Icons.ArrowUpward}
                                                onClick={() => handleUpArrowClick(courseContent)}
                                            />
                                            <ButtonIcon
                                                ariaLabelledBy={t(
                                                    "moveContentCourseContentContentNameDown",
                                                    {
                                                        courseContentContentName:
                                                            courseContent.content?.name,
                                                    }
                                                )}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-down"
                                                disabled={disableDownArrowButtons(courseContent)}
                                                iconType={Icons.ArrowDownward}
                                                onClick={() => handleDownArrowClick(courseContent)}
                                            />
                                        </td>
                                        <td className={`sort-order ${noDataCellClassName}`}>
                                            {courseContent.sortOrder}
                                        </td>
                                        <td className="name">{courseContent.content?.name}</td>
                                        <td className="description">
                                            <Paragraph>
                                                {courseContent.content?.description}
                                            </Paragraph>
                                        </td>
                                        <td className="file">
                                            {courseContent.content?.file?.fileName}
                                        </td>
                                        <td className="action">
                                            <ContextMenu>
                                                <ContextMenuFilePreview
                                                    hrefPath={FileUtils.fileUrl(
                                                        courseContent.content?.fileId!
                                                    )}
                                                    displayName={t("preview")}
                                                />
                                                {/* TODO: Disable Replace Content and Delete button when course is active and not in edit mode */}
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        handleContentToEdit(
                                                            courseContent,
                                                            courseContentIndex
                                                        )
                                                    }
                                                    displayName={t("replaceContent")}
                                                />
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        confirmCourseContentDeletion(courseContent)
                                                    }
                                                    displayName={t("delete")}
                                                />
                                            </ContextMenu>
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </DataTable>
            ) : (
                <EmptyText table>{t("noContentAdded")}</EmptyText>
            )}
            {courseContentToEdit !== undefined && (
                <CourseCreateContentModal
                    selectedCourseContentIndex={selectedCourseContentIndex}
                    courseContent={deferSave ? courseContentWithUpdates : courseContentToEdit}
                    courseId={courseId}
                    courseVersionId={courseVersionId}
                    deferSave={deferSave}
                    open={showModal}
                    setOpen={setShowModal}
                    handleContentDetailsChange={handleContentDetailsChange}
                    courseContents={tempCourseContents}
                    setTempCourseContents={setTempCourseContents}
                    setDirty={setDirty}
                />
            )}
            <Modal
                actions={confirmationActionArray}
                isOpen={showCourseContentDeletionConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("areYouSureYouWantToRemoveCourseContentToDeleteContentName", {
                    courseContentToDeleteContentName: courseContentToDelete.content?.name,
                })}
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentList };

// #endregion Exports
