import React from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventTypeBubbleDisplayNames } from "models/enumerations/events/event-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { t } from "utilities/localization/t";
import "./event-activation-details-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationDetailsSectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-details-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationDetailsSection: React.FC<EventActivationDetailsSectionProps> = ({
    event,
}): JSX.Element => {
    return (
        <>
            <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                {t(
                    "takeAMomentToReviewYourEventBeforePublishingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney"
                )}
            </Paragraph>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("eventDetails")}
                    </Heading>
                    <Badge style={BadgeStyle.Light}>
                        <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__lock`} />
                        {t(
                            InstructorLedTrainingTypeDisplayNames[event.instructorLedTrainingType!]
                        )}{" "}
                        {t("event")}
                    </Badge>
                    <Badge style={BadgeStyle.Light}>
                        <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__lock`} />
                        {t(EventTypeBubbleDisplayNames[event.type!])}
                    </Badge>
                </div>
                <div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>{t("eventName")}</Paragraph>
                        <Paragraph>{event.name}</Paragraph>
                    </div>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationDetailsSection };

// #endregion Exports
