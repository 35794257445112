import React from "react";
import { Card } from "components/card/card";
import { EventContactForm } from "./event-contact-form/event-contact-form";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-contact-card.scss";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventContactCardProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-contact-card";
const INFORMATION_TEXT1: TranslatedCopy =
    "thisContactInformationWillBeAvailableToEventParticipantsAs";
const INFORMATION_TEXT2: TranslatedCopy = "asThePrimaryMethodToContactTheEventOrganizer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventContactCard: React.FC<EventContactCardProps> = ({ event }): JSX.Element => {
    const handleToggleChange = (checked: boolean): void => {
        const updateValues = {
            hideContactInformation: checked,
            useDefaultContactInformation: undefined,
            email: "",
            phoneNumber: "",
        };

        if (event.isDraft()) {
            event.updateAndSave(updateValues);
        } else {
            event.updateActiveRecord(updateValues);
        }
    };

    return (
        <Card>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("eventContactInformation")}
                </Heading>
                <ToggleLabel
                    checked={event.hideContactInformation ?? false}
                    id="hide-contact-information"
                    label={t("hideContactInformation")}
                    onToggle={(checked) => handleToggleChange(checked)}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__subtext`}>{t(INFORMATION_TEXT1)}</div>
            <div className={`${CSS_CLASS_NAME}__subtext`}>{t(INFORMATION_TEXT2)}</div>
            <EventContactForm event={event} />
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContactCard };

// #endregion Exports
