import { t } from "i18next";
import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";
import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { ProviderApplicationService } from "utilities/services/aen-applications/provider-application-service";

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingProviderApplications";
interface UseAenProviderApplicationsOptions {
    searchText?: string;
    status?: ProviderApplicationStatus;
    assignedToId?: number;
    skip?: number;
    take?: number;
    isReady?: boolean;
    isSearchCriteriaReady?: boolean;
    onError?: (error: Error) => void;
}
export const useAenProviderApplications = ({
    searchText,
    status,
    assignedToId,
    skip,
    take,
    isReady,
    isSearchCriteriaReady,
    onError,
}: UseAenProviderApplicationsOptions) => {
    const { list } = ProviderApplicationService.useList();
    const [providerApplications, setProviderApplications] = useState<ProviderApplicationRecord[]>(
        []
    );
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isReady) return;
        try {
            (async function getProviderApplications() {
                setIsLoading(true);
                const response = await list({
                    searchText,
                    status,
                    assignedToId,
                    skip,
                    take,
                });
                setIsLoading(false);

                if (
                    response?.resultObjects == null ||
                    response?.results == null ||
                    response.results.hasErrors()
                ) {
                    throw new Error();
                }

                setProviderApplications(response.resultObjects);
                setRowCount(response.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedToId, isReady, searchText, skip, status, take]);

    return {
        error,
        isLoading,
        providerApplications,
        rowCount,
    };
};
