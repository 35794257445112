import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { Card } from "components/card/card";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseVersionChangesModal } from "components/courses/course-version/course-version-changes-modal/course-version-changes-modal";
import { CourseVersionEditModal } from "components/courses/course-version/course-version-edit-modal/course-version-edit-modal";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    CourseVersionService,
    GetCourseVersionPathParams,
    GetCourseVersionQueryParams,
} from "utilities/services/courses/course-version-service";
import { EditCourseLayout } from "layouts/admin/edit-course-layout/edit-course-layout";
import { EditCourseVersionMaterials } from "components/courses/course-version/edit-course-version-materials/edit-course-version-materials";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { NumberUtils } from "utilities/number-utils";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
    ParagraphEmphasis,
} from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/contexts/use-course-context";
import { useCourseVersionArchive } from "utilities/hooks/models/courses/use-course-version-archives";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useParams } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./edit-course-materials-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditCourseMaterialsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-course-materials-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditCourseMaterialsPage: React.FC<EditCourseMaterialsPageProps> = validatePageAccess(
    AccessControlKeys.EditCourseMaterialsPage
)((): JSX.Element => {
    const { id, versionId } = useParams();
    const courseId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const courseVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const { record: course } = useCourse();
    const { get: getCourseVersion } = CourseVersionService.useGet();
    const { courseVersionArchive, setRefresh } = useCourseVersionArchive({
        courseId: course.id!,
        courseVersionId: courseVersionId,
        includeCreatedBy: true,
    });
    const [courseVersion, setCourseVersion] = useState<CourseVersionRecord>(
        new CourseVersionRecord()
    );
    const [courseVersionWithUpdates, setCourseVersionWithUpdates] =
        useState<CourseVersionRecord>(courseVersion);
    const [showEditCourseVersionModal, setShowEditCourseVersionModal] = useState<boolean>(false);
    const [showSaveChangesModal, setShowSaveChangesModal] = useState<boolean>(false);
    const [showCancelEditVersionModal, setShowCancelEditVersionModal] = useState<boolean>(false);
    const [deferSave, setDeferSave] = useState<boolean>(false);

    useRedirectOnForbidden(sitemap.admin.course.list);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const navigationRequests = useListenForNavigationRequests(EditCourseMaterialsPage.name, [
        Breadcrumbs.name,
        EditCourseLayout.name,
    ]);
    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (!editMode || !dirty) {
            resolveNavigationRequest(
                EditCourseMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
            return;
        }
        setNavigationRequest(navigationRequest);
    }, [editMode, dirty, navigationRequests, resolveNavigationRequest]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(
                EditCourseMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(
            EditCourseMaterialsPage.name,
            navigationRequest.requestingComponent
        );
    }, [navigationRequest, resolveNavigationRequest]);

    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    useEffect(() => {
        if (editMode) {
            setDeferSave(true);
        }
    }, [editMode]);

    useEffect(() => {
        setCourseVersionWithUpdates(courseVersion);
    }, [courseVersion]);

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            if (course !== undefined) {
                const pathParams: GetCourseVersionPathParams = {
                    id: courseVersionId,
                };
                const queryParams: GetCourseVersionQueryParams = {
                    includeContent: true,
                    includeScormPackage: true,
                    includeEnrollmentCounts: false,
                };

                const getResponse = await getCourseVersion(pathParams, queryParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                setCourseVersion(getResult.resultObject);
            }
            return;
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheCourse"));
        }
    }, [course, courseVersionId, getCourseVersion, setCourseVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onCancelChangesModalClose = () => {
        fetchData();
        setShowCancelEditVersionModal(false);
        setEditMode(false);
    };

    const saveCourseVersionChanges = () => {
        setShowSaveChangesModal(true);
    };

    return (
        <>
            {course.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedCourse")}
                </Banner>
            )}
            <div className={CSS_CLASS_NAME}>
                {courseVersion !== undefined && (
                    <div>
                        <ButtonIcon
                            cssClassName={`${CSS_CLASS_NAME}__back-button`}
                            iconType={Icons.ChevronLeft}
                            text={t("backToAllVersions")}
                            buttonStyle={ButtonStyle.Secondary}
                            buttonSize={ButtonSize.Small}
                            buttonType={ButtonType.Link}
                            linkPath={RouteUtils.localizePath(
                                RouteUtils.replacePathParams(
                                    sitemap.admin.course.edit.versionList,
                                    {
                                        id: courseId,
                                    }
                                )
                            )}
                        />
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            {!editMode && (
                                <HeaderActions
                                    headingPriority={HeadingPriority.H5}
                                    headingSize={HeadingSize.Small}
                                    title={t("versionVersionNumber", {
                                        versionNumber: courseVersion?.versionId,
                                    })}>
                                    <Button
                                        disabled={
                                            course.status === ActiveStatus.Archived ||
                                            course.status === ActiveStatus.Inactive
                                        }
                                        onClick={() => setShowEditCourseVersionModal(true)}
                                        style={ButtonStyle.Primary}
                                        text={t("editVersion")}
                                    />
                                </HeaderActions>
                            )}
                            {editMode && (
                                <HeaderActions
                                    headingPriority={HeadingPriority.H5}
                                    headingSize={HeadingSize.Small}
                                    title={t("versionVersionNumber", {
                                        versionNumber: courseVersion?.versionId,
                                    })}>
                                    <Button
                                        onClick={() => setShowCancelEditVersionModal(true)}
                                        style={ButtonStyle.Secondary}
                                        text={t("cancelEdit")}
                                    />
                                    <ButtonIcon
                                        buttonStyle={ButtonStyle.Primary}
                                        disabled={!courseVersionWithUpdates.contentIsComplete()}
                                        iconType={Icons.PublishWithChanges}
                                        onClick={() => saveCourseVersionChanges()}
                                        text={t("saveChanges")}
                                    />
                                </HeaderActions>
                            )}
                            <Card cssClassName={`${CSS_CLASS_NAME}__card`}>
                                <div>
                                    <Paragraph
                                        size={ParagraphSize.XSmall}
                                        emphasis={ParagraphEmphasis.Bold}>
                                        {t("lastChanged")}
                                    </Paragraph>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {courseVersion.getLastUpdatedDateAndTime()}
                                    </Paragraph>
                                </div>
                                <div>
                                    <Paragraph
                                        size={ParagraphSize.XSmall}
                                        emphasis={ParagraphEmphasis.Bold}>
                                        Changed By
                                    </Paragraph>
                                    <div>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {courseVersion.getLastUpdatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}>
                                            {courseVersion.getLastUpdatedBy()}
                                        </Paragraph>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                )}
                <EditCourseVersionMaterials
                    courseVersion={courseVersionWithUpdates}
                    courseVersionArchiveData={courseVersionArchive}
                    deferSave={deferSave}
                    editMode={editMode}
                    setCourseVersion={setCourseVersionWithUpdates}
                    setDirty={setDirty}
                />
                <CourseVersionEditModal
                    courseVersionNumber={courseVersion.versionId}
                    open={showEditCourseVersionModal}
                    setInEditCourseVersionMode={setEditMode}
                    setOpen={setShowEditCourseVersionModal}
                />
                <CourseVersionChangesModal
                    courseVersionBeforeChanges={courseVersion}
                    courseVersionWithUpdates={courseVersionWithUpdates}
                    fetchData={fetchData}
                    open={showSaveChangesModal}
                    setEditMode={setEditMode}
                    setOpen={setShowSaveChangesModal}
                    setRefresh={setRefresh}
                />
                <CancelChangesModal
                    onModalClose={onCancelChangesModalClose}
                    open={showCancelEditVersionModal}
                    setOpen={setShowCancelEditVersionModal}
                />
                <Modal
                    cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                    isOpen={showCancelEditOnNavigationModal}
                    onModalClose={() => {}}
                    actions={cancelEditModeOnNavigationConfirmationActions}
                    modalStyle={"-inverted"}>
                    {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
                </Modal>
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditCourseMaterialsPage };

// #endregion Exports
