import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import ReportSection from "components/reports/components/report-section/report-section";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { useCallback, useState } from "react";
import {
    ProductService,
    ListProductsQueryParams,
} from "utilities/services/products/product-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { TrainingType } from "models/enumerations/courses/training-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportProductSelectionProps {
    isRequired?: boolean;
    products: ProductRecord[];
    trainingType?: TrainingType;
    audienceType?: AudienceType;
    onProductsChanged: (products: ProductRecord[]) => void;
    includeAvailableForAENOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportProductSelection: React.FC<ReportProductSelectionProps> = ({
    isRequired = false,
    products,
    trainingType,
    audienceType,
    onProductsChanged,
    includeAvailableForAENOnly,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchProducts, setSearchProducts] = useState<ProductRecord[]>([]);

    const { list: listProducts } = ProductService.useList();
    const fetchProducts = useCallback(
        async (searchText?: string) => {
            try {
                const listProductsQueryParams: ListProductsQueryParams = {
                    applySearch: true,
                    searchText,
                    activeStatusFilter: [
                        ActiveStatus.Active,
                        ActiveStatus.Inactive,
                        ActiveStatus.Draft,
                    ],
                    trainingType: trainingType,
                    audienceType: audienceType,
                    includeAvailableForAENOnly: includeAvailableForAENOnly,
                };

                const listFilteredProductsResponse = await listProducts(listProductsQueryParams);
                const listFilteredProductsResults = listFilteredProductsResponse?.results;
                const products = listFilteredProductsResponse?.resultObjects;
                if (
                    products == null ||
                    listFilteredProductsResults == null ||
                    listFilteredProductsResults.hasErrors()
                ) {
                    throw new Error();
                }
                setSearchProducts(products);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProducts"));
                setSearchProducts([]);
            }
        },
        [includeAvailableForAENOnly, listProducts, trainingType, audienceType]
    );

    const handleRemoveProduct = useCallback(
        (product: ProductRecord) => {
            onProductsChanged(products.filter((c) => c.id !== product.id));
        },
        [products, onProductsChanged]
    );

    const handleProductSelectionChange = (selectedProducts: ProductRecord[]) => {
        onProductsChanged(selectedProducts);
    };

    const handleAddProducts = () => {
        fetchProducts();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectProducts")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddProducts}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addProducts")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(products) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="product-name">{t("productName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product.id}>
                                    <td className="product-name">{product.name}</td>
                                    <td className="id">{product.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveProduct(product)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchProducts}
                onSelectionChanged={handleProductSelectionChange}
                searchValues={searchProducts}
                selectedValues={products}
                title={t("addAProduct")}
                itemName={t("productName")}
                searchPlaceholderText={t("typeToSearchByProductNameOrID")}
                valuesDescription={t("products")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportProductSelection;

// #endregion Exports
