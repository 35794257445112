import React from "react";
import { Card } from "components/card/card";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./contract-card.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ContractCardProps {
    contract: ContractRecord;
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "contract-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContractCard: React.FC<ContractCardProps> = ({ contract, stacked }) => {
    return (
        <Card stacked={stacked} cssClassName={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__form`}>
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("nfpaContact")}</Paragraph>
                    <Paragraph>
                        {contract.nfpaAdminName} <br />
                        {contract.nfpaAdminEmail} <br />
                        {contract.formatPhoneNumber()} <br />
                    </Paragraph>
                </div>
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("contractNumber")}</Paragraph>
                    <Paragraph>{contract.contractNumber}</Paragraph>
                </div>
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("contractDate")}</Paragraph>
                    <Paragraph>{contract.getContractDate()}</Paragraph>
                </div>
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("enrollmentLimit")}</Paragraph>
                    <Paragraph>
                        {contract.noEnrollmentLimit === true ? (
                            <span className="no-limit">
                                {t("noLimitSetInvoiceWillBeIssuedFollowingTheEvent")}
                            </span>
                        ) : (
                            contract.enrollmentLimit
                        )}
                    </Paragraph>
                </div>
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Export
// -------------------------------------------------------------------------------------------------

export { ContractCard };

// #endregion Export
