import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import {
    ProviderApplicationService,
    PatchProviderApplicationPathParams,
} from "utilities/services/aen-applications/provider-application-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { useParams } from "react-router-dom";
import { PatchProviderApplicationRecord } from "models/view-models/aen-applications/patch-provider-application-record";

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingProviderApplications";

interface UseAenProviderApplicationOptions {
    isReady?: boolean;
    onError?: () => void;
}

export const useAenProviderApplication = ({
    isReady,
    onError,
}: UseAenProviderApplicationOptions) => {
    const { id } = useParams();
    const { get } = ProviderApplicationService.useGet();
    const { patch } = ProviderApplicationService.usePatch();
    const [providerApplication, setProviderApplication] = useState<ProviderApplicationRecord>(
        new ProviderApplicationRecord()
    );
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchProviderApplication = useCallback(async () => {
        try {
            (async function getProviderApplication() {
                setIsLoading(true);
                const response = await get({ id });
                setIsLoading(false);

                if (response?.resultObject == null) {
                    throw new Error();
                }
                setProviderApplication(response.resultObject);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [get, id, setError]);

    const saveApplication = useCallback(
        async (application: PatchProviderApplicationRecord) => {
            try {
                const patchProviderApplicationPathParams: PatchProviderApplicationPathParams = {
                    id: application.id ?? 0,
                };

                var patchResponse = await patch(patchProviderApplicationPathParams, application);
                var patchResult = patchResponse?.result;
                if (patchResult?.resultObject == null || patchResult.hasErrors()) {
                    throw new Error();
                }

                fetchProviderApplication();

                return true;
            } catch {
                return false;
            }
        },
        [patch, fetchProviderApplication]
    );

    useEffect(() => {
        fetchProviderApplication();
    }, [fetchProviderApplication]);

    return {
        error,
        fetchProviderApplication,
        isLoading,
        providerApplication,
        setProviderApplication,
        saveApplication,
    };
};
