import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { AuthConfigSettings } from "models/interfaces/auth-config-settings/auth-config-settings";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------
const defaultValues: AuthConfigSettings = {
    domain: "",
    tenant: "",
    clientId: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class AuthConfigSettingsRecord extends Record(defaultValues) implements AuthConfigSettings {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<AuthConfigSettings>) {
        params = params ?? Object.assign({}, defaultValues);
        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for Announcement properties, create a new AnnouncementRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Announcement>} values The values to overwrite on this instance.
     * @returns An AnnouncementRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<AuthConfigSettings>): AuthConfigSettingsRecord {
        return new AuthConfigSettingsRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AuthConfigSettingsRecord };

// #endregion Exports
