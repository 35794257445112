import React, { useMemo } from "react";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { DateUtils } from "utilities/date-utils";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { useProductUnits } from "utilities/hooks/models/products/use-product-units";
import { t } from "utilities/localization/t";
import { TopicBubble } from "components/bubble/topic-bubble";
import "./user-contract-oll-detail-info.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractOLLDetailInfoProps {
    contract: ContractRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contract-oll-detail-info";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractOLLDetailInfo: React.FC<UserContractOLLDetailInfoProps> = ({
    contract,
}): JSX.Element => {
    const { units } = useProductUnits({ productId: contract?.productId, includeUnitCourses: true });

    const topics = useMemo(() => {
        const unitCourses = units?.map((unit) => unit.unitCourses).flat();

        const topics = unitCourses
            ?.filter((uc) => uc?.course?.topic != null)
            .map((uc) => uc?.course?.topic!);

        const distinctTopics = topics
            ?.filter((topic, index) => topics.indexOf(topic) === index)
            .sort();

        return distinctTopics;
    }, [units]);

    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("basicInformation")}</h3>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__grid`}>
                <div>
                    <FormFieldReadonly label={t("nfpaContact")}>
                        {contract.nfpaAdminName}
                        <br />
                        {contract.nfpaAdminEmail}
                        <br />
                        {contract.formatPhoneNumber()}
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("enrollmentLimit")}>
                        {contract.enrollmentLimit}
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("contractDate")}>
                        {DateUtils.formatDateCustom(
                            contract.contractDate?.toString(),
                            t("shortDateFormat")
                        )}
                    </FormFieldReadonly>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <FormFieldReadonly label={t("productName")}>
                    {contract.product?.name}
                </FormFieldReadonly>
            </div>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__grid`}>
                <div className="language">
                    <FormFieldReadonly label={t("language")}>
                        {contract.product?.language != null &&
                            t(LanguageDisplayNames[contract.product?.language])}
                        {contract.product?.language == null && "--"}
                    </FormFieldReadonly>
                </div>
                <div className="topic">
                    <FormFieldReadonly label={t("topic")}>
                        {topics.map((topic, index) => (
                            <TopicBubble key={`${index}`} topic={topic} />
                        ))}
                    </FormFieldReadonly>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractOLLDetailInfo };

// #endregion Exports
