import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import {
    ContractService,
    HasDuplicateContractNumberQueryParams,
} from "utilities/services/contracts/contract-service";
import { Contract } from "models/interfaces/contracts/contract";
import { E164Number } from "libphonenumber-js";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEnrollmentCount } from "utilities/hooks/models/enrollments/use-enrollment-count";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./edit-contract-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditContractModalProps {
    contract: ContractRecord;
    handleContractDetailsChange: (contract: ContractRecord) => Promise<void>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "edit-contract-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditContractModal: React.FC<EditContractModalProps> = ({
    contract,
    handleContractDetailsChange,
    open,
    setOpen,
}): JSX.Element => {
    const initialContract = useMemo(() => contract ?? new ContractRecord(), [contract]);
    const [contractToUpdate, setContractToUpdate] = useState(initialContract);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [contractNumber, setContractNumber] = useState<string>(contract?.contractNumber ?? "");
    const [duplicateContractNumberError, setDuplicateContractNumberError] = useState("");
    const { get: hasDuplicateContractNumberApi } = ContractService.useHasDuplicateContractNumber();
    const [emailError, setEmailError] = useState<TranslatedCopy>();
    const [enrollmentCountError, setEnrollmentCountError] = useState("");

    const { count } = useEnrollmentCount({ contractId: contract.id, isWithdrawn: false });

    useEffect(() => {
        setContractToUpdate(initialContract);
    }, [initialContract]);

    useEffect(() => {
        setContractNumber(contract?.contractNumber ?? "");
    }, [contract?.contractNumber]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled:
                StringUtils.hasValue(duplicateContractNumberError) ||
                StringUtils.hasValue(emailError) ||
                StringUtils.hasValue(enrollmentCountError),
            onClick: () => onContractSaveChanges(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setContractToUpdate(initialContract);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setDirty(false);
            setContractToUpdate(initialContract);
            setOpen(false);
        }
    };

    const onContractSaveChanges = () => {
        setOpen(false);
        handleContractDetailsChange(contractToUpdate);
    };

    const updateContract = useCallback(
        (contract: Partial<ContractRecord>): void => {
            setContractToUpdate(contractToUpdate.with(contract));
        },
        [contractToUpdate]
    );

    const updateToggleOrDatePicker = (values: Partial<Contract>): void => {
        const contractRecord = contractToUpdate?.with(values);
        setContractToUpdate(contractRecord);
    };

    const handleDateChange = (date?: Date) => {
        updateToggleOrDatePicker({ contractDate: date });
    };

    const handleContractNumberChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        updateContract({ contractNumber: "" });
        setContractNumber(inputEvent.target.value);
    };

    const handleEnrollmentLimitChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        updateContract({ enrollmentLimit: Number(inputEvent.target.value) });
    };

    const handleEnrollmentLimitValidation = useCallback(() => {
        if (contractToUpdate.enrollmentLimit! < count) {
            setEnrollmentCountError(t("enrollmentLimitCannotBeLessThanTheCurrentEnrollmentCount"));
        } else if (contractToUpdate.enrollmentLimit! >= count) {
            setEnrollmentCountError("");
        }
    }, [contractToUpdate.enrollmentLimit, count]);

    const handleNFPAAdminNameChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        updateContract({ nfpaAdminName: inputEvent.target.value });
    };

    const handleEmailValidation = (): void => {
        setDirty(true);
        validateEmail(contractToUpdate?.nfpaAdminEmail);
    };

    const validateEmail = (email?: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError("setEmailError");
            return;
        }

        setEmailError(undefined);
    };

    const handleNFPAAdminEmailChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        updateContract({ nfpaAdminEmail: inputEvent.target.value });
    };

    const handleNFPAAdminPhoneChange = (value?: E164Number | undefined): void => {
        setDirty(true);
        const valueToSet = value === undefined ? "" : value;
        updateContract({ nfpaAdminPhone: valueToSet });
    };

    const hasDuplicateContractNumber = useCallback(async (): Promise<void> => {
        setDuplicateContractNumberError("");
        try {
            const hasDuplicateContractNumberPathParams = {};
            const hasDuplicateContractNumberQueryParams: HasDuplicateContractNumberQueryParams = {
                contractId: contract?.id ?? 0,
                contractNumber: contractNumber!,
            };
            const checkForDuplicateContractNumberResponse = await hasDuplicateContractNumberApi(
                hasDuplicateContractNumberPathParams,
                hasDuplicateContractNumberQueryParams
            );
            const checkForDuplicateContractNumberResult =
                checkForDuplicateContractNumberResponse?.result;

            if (
                checkForDuplicateContractNumberResult?.resultObject == null ||
                checkForDuplicateContractNumberResult.hasErrors()
            ) {
                throw new Error();
            }

            if (checkForDuplicateContractNumberResult.resultObject.valueOf() === true) {
                setDuplicateContractNumberError(t("aContractAlreadyExistsWithThisNumber"));
            } else {
                updateContract({ contractNumber: contractNumber });
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueCheckingForDuplicateContractNumbers"));
        }
    }, [contract?.id, contractNumber, hasDuplicateContractNumberApi, updateContract]);

    useEffect(() => {
        handleEnrollmentLimitValidation();
    }, [contractToUpdate.enrollmentLimit, count, handleEnrollmentLimitValidation]);

    return (
        <Modal
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editContract")}
            actions={modalActionArray}
            modalStyle={""}
            cssClassName={`${CSS_CLASS_NAME}`}>
            <h3 className={`${CSS_CLASS_NAME}__heading`}>{t("contractInformation")}</h3>
            <FormCalendarDatePicker
                placeholder="emptyDate"
                formFieldName="eventDate"
                label={t("contractDate")}
                onChange={handleDateChange}
                required={true}
                selectedDate={
                    contractToUpdate?.contractDate
                        ? new Date(contractToUpdate?.contractDate)
                        : undefined
                }
            />
            <FormTextInput
                ariaLabelledBy={t("contractNumber")}
                autoFocus={true}
                disabled={false}
                errorMessage={duplicateContractNumberError}
                formFieldName="contractNumber"
                id="contractNumber"
                label={t("contractNumber")}
                maxLength={26}
                onBlur={hasDuplicateContractNumber}
                onChange={handleContractNumberChange}
                placeholder={t("enterContractNumber")}
                required={true}
                value={contractNumber}
            />
            <FormTextInput
                ariaLabelledBy={t("enrollmentLimit")}
                autoFocus={false}
                formFieldName="enrollmentLimit"
                errorMessage={enrollmentCountError}
                id="enrollmentLimit"
                label={t("enrollmentLimit")}
                maxLength={60}
                minValue={count}
                onBlur={handleEnrollmentLimitValidation}
                onChange={handleEnrollmentLimitChange}
                placeholder={t("enterEnrollmentLimit")}
                required={true}
                type={InputTypes.Number}
                value={contractToUpdate.enrollmentLimit}
            />
            <FormTextInput
                ariaLabelledBy={t("nfpaContactName")}
                autoFocus={false}
                formFieldName="nfpaAdminName"
                id="nfpaAdminName"
                label={t("nfpaContactName")}
                maxLength={150}
                onChange={handleNFPAAdminNameChange}
                placeholder={t("enterNfpaContactName")}
                required={true}
                value={contractToUpdate.nfpaAdminName}
            />
            <FormTextInput
                ariaLabelledBy={t("nfpaContactEmail")}
                autoFocus={false}
                errorMessage={emailError}
                formFieldName="nfpaAdminEmail"
                id="nfpaAdminEmail"
                label={t("nfpaContactEmail")}
                maxLength={250}
                onBlur={handleEmailValidation}
                onChange={handleNFPAAdminEmailChange}
                placeholder={t("enterNfpaContactEmail")}
                required={true}
                type={InputTypes.Email}
                value={contractToUpdate.nfpaAdminEmail}
            />
            <FormPhoneInput
                ariaLabelledBy={t("nfpaContactPhone")}
                autoFocus={false}
                formFieldName="nfpaAdminPhone"
                id="nfpaAdminPhone"
                label={t("nfpaContactPhone")}
                onChange={handleNFPAAdminPhoneChange}
                placeholder={t("enterNfpaContactPhone")}
                required={true}
                type={InputTypes.Phone}
                value={contractToUpdate.nfpaAdminPhone}
            />
            <div className={`${CSS_CLASS_NAME}__product`}>
                <h3 className={`${CSS_CLASS_NAME}__heading`}>{t("associatedProduct")}</h3>
                <Paragraph size={ParagraphSize.Default} style={ParagraphStyle.Default}>
                    {initialContract.product?.name}
                </Paragraph>
                <div className={`${CSS_CLASS_NAME}__product-info`}>
                    {t("associatedProductCannotBeEditedAfterContractIsCreated")}
                </div>
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditContractModal };

// #endregion Exports
