import React, { useCallback, useEffect, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { InstructorDirectoryCard } from "components/instructors/instructor-directory/instructor-directory-card/instructor-directory-card";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import {
    InstructorProfileStatus,
    InstructorProfileStatusDisplayNames,
} from "models/instructors/instructor-profile-status";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SelectOption } from "components/form/inputs/select/select";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TravelType, TravelTypeDisplayNames } from "models/instructors/travel-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useInstructors } from "utilities/hooks/models/instructors/use-instructors";
import { useProducts } from "utilities/hooks/models/products/use-products";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./instructor-directory-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorDirectoryPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-directory-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 15;
const PRODUCT_STATUS_FILTER = [ActiveStatus.Active];
const PRODUCT_TYPE_FILTER = TrainingType.InstructorLedTraining;

// #endregion Constants
// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorDirectoryPage: React.FC<InstructorDirectoryPageProps> = validatePageAccess(
    AccessControlKeys.InstructorDirectoryPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.dashboard);
    const { record: globalState } = useGlobalState();
    const isAenProvider = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);
    const providerId = globalState.currentIdentity?.user?.providerId;

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<Language>();
    const [selectedTeachingMethod, setSelectedTeachingMethod] =
        useState<InstructorLedTrainingType>();
    const [selectedTravelType, setSelectedTravelType] = useState<TravelType>();
    const [productSelectOptions, setProductSelectOptions] = useState<SelectOption[]>([]);
    const [productId, setProductId] = useState<number>();
    const [selectedStatusType, setSelectedStatusType] = useState<InstructorProfileStatus>();

    const { instructors, rowCount: instructorCount } = useInstructors({
        approvedToTeachProductId: productId,
        includeAenNetwork: true,
        language: selectedLanguage,
        providerId: isAenProvider ? providerId : undefined,
        searchText: debouncedSearchText,
        status: isAenProvider ? InstructorProfileStatus.Active : selectedStatusType,
        teachingMethod: selectedTeachingMethod,
        travelType: selectedTravelType,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const { products } = useProducts({
        activeStatusFilter: PRODUCT_STATUS_FILTER,
        includeAvailableForAENOnly: isAenProvider,
        includeLatestVersion: true,
        trainingType: PRODUCT_TYPE_FILTER,
    });

    useEffect(() => {
        const productSelectOptions = products
            .map((product) => {
                return {
                    text: product.name,
                    value: product.id!.toString(),
                };
            }, [])
            .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));

        setProductSelectOptions(productSelectOptions);
    }, [products]);

    const handleSearchTriggered = useCallback((searchText: string): void => {
        setDebouncedSearchText(searchText);
    }, []);

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedLanguage(NumberUtils.parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleFilterByTeachingMethodChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setSelectedTeachingMethod(NumberUtils.parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleFilterByTravelTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedTravelType(NumberUtils.parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleFilterByProductChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setProductId(NumberUtils.parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleFilterByStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedStatusType(NumberUtils.parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handleClearAll = () => {
        setSearchText("");
        setDebouncedSearchText("");
        setSelectedLanguage(undefined);
        setSelectedTeachingMethod(undefined);
        setSelectedTravelType(undefined);
        setProductId(undefined);
        setSelectedStatusType(undefined);
        setCurrentPage(1);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner title={t("instructorDirectory")} />
            <SideContentLeftLayout
                sidebarElement={
                    <div className={`${CSS_CLASS_NAME}__sidebar`}>
                        <Paragraph
                            cssClassName="-label"
                            size={ParagraphSize.XLarge}
                            style={ParagraphStyle.Label}>
                            {t("filterResults")}
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                onSearchTextInputChange={handleSearchTextInputChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"instructorSearch"}
                                placeholder={t("searchByNameEmailOrLocation")}
                                searchTextInputValue={searchText}
                            />
                            <FormSelect
                                ariaLabelledBy={t("filterByLanguage")}
                                formFieldName={t("filterByLanguage")}
                                id="instructorFilterByLanguage"
                                label=""
                                placeholder={t("language")}
                                onChange={handleFilterByLanguageChange}
                                options={EnumUtils.numericEnumToSelectOptions(
                                    Language,
                                    LanguageDisplayNames
                                )}
                                value={selectedLanguage?.toString()}
                            />
                            <FormSelect
                                ariaLabelledBy={t("filterByTeachingMethods")}
                                formFieldName={t("filterByTeachingMethods")}
                                id="instructorFilterByTeachingMethods"
                                label=""
                                placeholder={t("filterByTeachingMethods")}
                                onChange={handleFilterByTeachingMethodChange}
                                options={EnumUtils.numericEnumToSelectOptions(
                                    InstructorLedTrainingType,
                                    InstructorLedTrainingTypeDisplayNames
                                )}
                                value={selectedTeachingMethod?.toString()}
                            />{" "}
                            <FormSelect
                                ariaLabelledBy={t("filterByTravelPreferences")}
                                formFieldName={t("filterByTravelPreferences")}
                                id="instructorFilterByTravelPreferences"
                                label=""
                                placeholder={t("filterByTravelPreferences")}
                                onChange={handleFilterByTravelTypeChange}
                                options={EnumUtils.numericEnumToSelectOptions(
                                    TravelType,
                                    TravelTypeDisplayNames
                                )}
                                value={selectedTravelType?.toString()}
                            />
                            <FormSelect
                                ariaLabelledBy={t("approvedToTeachProduct")}
                                formFieldName={t("approvedToTeachProduct")}
                                id="approvedToTeachProduct"
                                label=""
                                placeholder={t("product")}
                                onChange={handleFilterByProductChange}
                                options={productSelectOptions}
                                value={productId?.toString()}
                            />
                            {!isAenProvider && (
                                <FormSelect
                                    ariaLabelledBy={t("status")}
                                    formFieldName={t("status")}
                                    id="status"
                                    label=""
                                    placeholder={t("status")}
                                    onChange={handleFilterByStatusChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        InstructorProfileStatus,
                                        InstructorProfileStatusDisplayNames
                                    )}
                                    value={selectedStatusType?.toString()}
                                />
                            )}
                            <Button
                                cssClassName="-clear"
                                onClick={handleClearAll}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Quaternary}
                                text={t("clearAllFilters")}
                            />
                        </div>
                    </div>
                }>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            <div className={`${CSS_CLASS_NAME}__cards`}>
                                {instructors.map((instructor: InstructorRecord) => (
                                    <InstructorDirectoryCard
                                        key={instructor.id}
                                        instructor={instructor}
                                    />
                                ))}
                            </div>
                        </SkipNavContent>
                    </div>
                </div>
                <div className="footer">
                    <Pager
                        currentPage={currentPage}
                        totalPages={instructorCount / ITEMS_PER_PAGE}
                        onPageClick={onPageClick}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalItems={instructorCount}
                    />
                </div>
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorDirectoryPage };

// #endregion Exports
