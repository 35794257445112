import React, { useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import {
    ActiveStatus,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import {
    AudienceType,
    AudienceTypeDisplayNames,
} from "models/enumerations/audiences/audience-type";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseList } from "components/courses/course-lists/course-list/course-list";
import { CourseModal } from "components/courses/course-modal/course-modal";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icons } from "components/icons/constants/icons";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { t } from "utilities/localization/t";
import { useCourses } from "utilities/hooks/models/courses/use-courses";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./admin-course-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminCourseListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-course-list-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminCourseListPage: React.FC<AdminCourseListPageProps> = validatePageAccess(
    AccessControlKeys.AdminCourseListPage
)((): JSX.Element => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [audienceType, setAudienceType] = useState<AudienceType>();
    const [topic, setTopic] = useState<Topic>();
    const [language, setLanguage] = useState<Language>();
    const [status, setStatus] = useState<ActiveStatus>(ActiveStatus.Active);

    const { courses, rowCount: totalNumberOfCourses } = useCourses({
        activeStatus: status,
        applySearch: true,
        audienceType: audienceType,
        includeArchived: true,
        includeCreatedBy: true,
        includeLastModifiedBy: true,
        includeLatestVersion: true,
        language: language ?? undefined,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        topic: topic,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleSearchTriggered = (debouncedSearchText: string) => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const handleFilterByAudienceTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const audienceTypeIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(audienceTypeIndex) || audienceTypeIndex < 1) {
            setAudienceType(undefined);
        }

        setAudienceType(audienceTypeIndex);
        setCurrentPage(1);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const languageIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        setLanguage(languageIndex);
        setCurrentPage(1);
    };

    const handleFilterByTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const topic = NumberUtils.parseInt(event.target.value) ?? 0;
        setTopic(topic);
        setCurrentPage(1);
    };

    const handleFilterByStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const activeStatusIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(activeStatusIndex) || activeStatusIndex < 1) {
            setStatus(ActiveStatus.Active);
        }
        setStatus(activeStatusIndex);
        setCurrentPage(1);
    };

    const handleClearAll = () => {
        setLanguage(undefined);
        setTopic(undefined);
        setAudienceType(undefined);
        setStatus(ActiveStatus.Active);
        setSearchText("");
        setDebouncedSearchText("");
        setCurrentPage(1);
    };

    return (
        <>
            <HeaderBanner title={t("courses")}>
                <ButtonIcon
                    text={t("addNewCourse")}
                    onClick={() => setShowCourseModal(true)}
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.TertiaryAlt}
                    iconType={Icons.Plus}
                />
            </HeaderBanner>
            <div className={`${CSS_CLASS_NAME}__window`}>
                <div className={`${CSS_CLASS_NAME}__list__view`}>
                    <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                        <div className={`${CSS_CLASS_NAME}__window__sidebar__filter`}>
                            <Paragraph
                                cssClassName={`${CSS_CLASS_NAME}__window__sidebar__filter__title`}
                                size={ParagraphSize.XLarge}
                                style={ParagraphStyle.Label}>
                                {t("filterResults")}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__window__sidebar__filter__selections`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id={"courseSearch"}
                                    placeholder={t("searchByCourseNameOrID")}
                                    searchTextInputValue={searchText}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByType")}
                                    formFieldName={t("filterByType")}
                                    id="filterByType"
                                    label=""
                                    placeholder={t("filterByType")}
                                    onChange={handleFilterByAudienceTypeChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        AudienceType,
                                        AudienceTypeDisplayNames
                                    )}
                                    value={audienceType?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByLanguage")}
                                    formFieldName={t("filterByLanguage")}
                                    id="instructorFilterByLanguage"
                                    label=""
                                    placeholder={t("filterByLanguage")}
                                    onChange={handleFilterByLanguageChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        Language,
                                        LanguageDisplayNames
                                    )}
                                    value={language?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByTopic")}
                                    formFieldName={t("filterByTopic")}
                                    id="instructorFilterByTopic"
                                    label=""
                                    placeholder={t("filterByTopic")}
                                    onChange={handleFilterByTopicChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        Topic,
                                        TopicDisplayNames
                                    )}
                                    value={topic?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByStatus")}
                                    formFieldName={t("filterByStatus")}
                                    id="courseFilterByStatus"
                                    label=""
                                    placeholder={t("status")}
                                    onChange={handleFilterByStatusChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        ActiveStatus,
                                        ActiveStatusStatusDisplayNames
                                    )}
                                    value={status?.toString()}
                                />
                            </div>
                            <Button
                                onClick={handleClearAll}
                                size={ButtonSize.Medium}
                                style={ButtonStyle.Secondary}
                                text={t("clearAllFilters")}
                            />
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__window__main`}>
                        <div className={`${CSS_CLASS_NAME}__window__main__courses`}>
                            <div className={`${CSS_CLASS_NAME}__window__main__courses__content`}>
                                <SkipNavContent>
                                    <CourseList courses={courses} />
                                </SkipNavContent>
                            </div>
                        </div>
                        {CollectionUtils.hasValues(courses) && (
                            <div className={`${CSS_CLASS_NAME}__window__main__pager`}>
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={totalNumberOfCourses / ITEMS_PER_PAGE}
                                    onPageClick={onPageClick}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={totalNumberOfCourses}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <CourseModal open={showCourseModal} setOpen={setShowCourseModal} />
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminCourseListPage };

// #endregion Exports
