import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import React from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import "./admin-layout-header-profile.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderProfileProps {
    openProfileSelector: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-profile";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderProfile: React.FC<AdminLayoutHeaderProfileProps> = (
    props: AdminLayoutHeaderProfileProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__avatar`}>
                <p>{globalState.currentIdentity?.user?.initials()}</p>
            </div>
            <div>
                <p className={`${CSS_CLASS_NAME}__name`}>
                    {globalState.currentIdentity?.user?.displayName()}
                </p>
                <Button
                    cssClassName={`${CSS_CLASS_NAME}__profile-toggle`}
                    onClick={props.openProfileSelector}
                    size={ButtonSize.None}
                    style={ButtonStyle.None}
                    text={t("switchProfile")}
                />
            </div>
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderProfile };

// #endregion Exports
