import React from "react";
import { ProviderENHLayoutHeader } from "./provider-enh-layout-header/provider-enh-layout-header";
import { Outlet } from "react-router-dom";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import "./provider-enh-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderENHLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-enh-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderENHLayout: React.FC<ProviderENHLayoutProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <ProviderENHLayoutHeader />
            <div className={`${CSS_CLASS_NAME}__main`}>
                <SkipNavContent>
                    <Outlet />
                </SkipNavContent>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderENHLayout };

// #endregion Exports
