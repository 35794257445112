import { Avatar, AvatarAspect } from "components/avatar/avatar";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { InstructorInfo } from "components/instructors/instructor-info/instructor-info";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { SelectableInstructor } from "models/interfaces/instructors/selectable-instructor";
import { useState } from "react";
import { t } from "utilities/localization/t";
import "./event-instructor-card.scss";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInstructorCardProps {
    handleAdd: (instructor: InstructorRecord) => void;
    handleRemove: (instructor: InstructorRecord) => void;
    selectableInstructor: SelectableInstructor;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-instructor-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventInstructorCard: React.FC<EventInstructorCardProps> = ({
    handleAdd,
    handleRemove,
    selectableInstructor,
}) => {
    const [showModal, setShowModal] = useState(false);
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: selectableInstructor.isSelected ? t("deselect") : t("select"),
            onClick: () => {
                selectableInstructor.isSelected
                    ? handleRemove(selectableInstructor.instructor)
                    : handleAdd(selectableInstructor.instructor);
                setShowModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__detail`}>
                <Paragraph size={ParagraphSize.Small}>
                    {StringUtils.valueOrDefault(
                        selectableInstructor.instructor.getFirstAndLastNameOrEmail()
                    )}
                </Paragraph>
                {selectableInstructor.instructor.instructorProfile?.shareLocation && (
                    <>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                            {selectableInstructor.instructor.getCityStateText()}
                        </Paragraph>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                            {selectableInstructor.instructor.getCountryText()}
                        </Paragraph>
                    </>
                )}
                <div className={`${CSS_CLASS_NAME}__select-action`}>
                    {!selectableInstructor.isSelected && (
                        <ButtonIcon
                            ariaLabelledBy={t("selectInstructor")}
                            buttonSize={ButtonSize.Small}
                            buttonStyle={ButtonStyle.Secondary}
                            iconType={Icons.Plus}
                            onClick={() => handleAdd(selectableInstructor.instructor)}
                            text={t("select")}
                        />
                    )}
                    {selectableInstructor.isSelected && (
                        <ButtonIcon
                            ariaLabelledBy={t("removeInstructor")}
                            buttonSize={ButtonSize.Small}
                            buttonStyle={ButtonStyle.Primary}
                            iconType={Icons.Minus}
                            onClick={() => handleRemove(selectableInstructor.instructor)}
                            text={t("remove")}
                        />
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__image-container`}>
                <Avatar
                    avatarFileId={selectableInstructor.instructor.instructorProfile?.avatarFileId}
                    avatarAspect={AvatarAspect.Portrait}
                />
            </div>

            <div className={`${CSS_CLASS_NAME}__info-button`}>
                <ButtonIcon
                    ariaLabelledBy={t("instructorInformation")}
                    buttonSize={ButtonSize.Small}
                    buttonStyle={ButtonStyle.Tertiary}
                    iconType={Icons.Information}
                    onClick={() => setShowModal(true)}
                    text=""
                />
            </div>
            {/* InstructorInfoModal */}
            <Modal
                actions={modalActionArray}
                isOpen={showModal}
                modalStyle={""}
                onModalClose={() => setShowModal(false)}
                title={t("instructorInfo")}>
                <InstructorInfo instructor={selectableInstructor.instructor} />
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInstructorCard };

// #endregion Exports
