import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { ToastManager } from "utilities/toast/toast-manager";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { EmulationModeContext } from "./emulation-context-provider";
import { EmulationService } from "utilities/services/emulation/emulation-service";
import EmulationTokenRecord from "models/view-models/emulation/emulation-token-record";
import { useAuthentication } from "use-authentication";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

const UNABLE_TO_CREATE_TOKEN: TranslatedCopy = "unableToCreateEmulationToken";

export function useEmulationMode() {
    const { setLoadingEmulationSession, setEndingEmulationSession, token, setToken, userEmail } =
        useContext(EmulationModeContext);
    const { attemptLogin } = useAuthentication();
    const timeoutId = useRef<number | null>(null);
    const deleteToken = useCallback(
        async (tokenId: number | undefined, guestId: number | undefined) => {
            try {
                if (tokenId !== undefined) {
                    setEndingEmulationSession(true);
                    await EmulationService.delete(tokenId);
                }
            } catch (error) {
                //if there is an error deleting the emulation token,
                //we don't want an endless loop of trying to delete the token.
                setToken(null);
                console.log("error:", error);
                ToastManager.error(t("thereWasAnErrorDeletingEmulationSessionToken"));
            } finally {
                setEndingEmulationSession(false);
                try {
                    await attemptLogin(true);
                    //This will remove the emulation banner in the header.
                    setToken(null);
                    window.location.href = RouteUtils.localizePath(
                        RouteUtils.getUrl(sitemap.admin.userManagement.users.info.default, {
                            id: guestId,
                        })
                    );
                } catch (error) {
                    ToastManager.error(t("thereWasAnErrorEndingEmulationSession"));
                }

                if (timeoutId.current != null) {
                    clearTimeout(timeoutId.current);
                }
            }
        },
        [attemptLogin, setEndingEmulationSession, setToken]
    );
    const countDownToExpiration = (expiresOn: string | undefined) => {
        if (expiresOn !== undefined) {
            const expiresOnTime = Date.parse(expiresOn);
            const countdownMilliseconds = expiresOnTime - Date.now();
            return countdownMilliseconds;
        }
    };
    useEffect(() => {
        if (token != null) {
            timeoutId.current = window.setTimeout(async () => {
                await deleteToken(token?.id, token?.guestId);
            }, countDownToExpiration(token?.expiresOn));
        }
        return () => {
            if (timeoutId.current != null) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [deleteToken, token]);
    const login = async () => {
        try {
            await attemptLogin(true);
            return true;
        } catch (error) {
            ToastManager.error(t("unableToLoginAsSubscriber"));
            return false;
        }
    };
    const createToken = async (id: number | undefined) => {
        if (id == null) return ToastManager.error(t(UNABLE_TO_CREATE_TOKEN));
        try {
            setLoadingEmulationSession(true);
            const request = await EmulationService.create(
                new EmulationTokenRecord({ guestId: id })
            );
            const newToken = request.resultObject;
            const success = request.status === 200;
            if (
                success &&
                newToken != null &&
                newToken.id != null &&
                newToken.expiresOn != null &&
                newToken.guestId != null
            ) {
                setToken(newToken);
                const isLoggedIn = await login();
                if (!isLoggedIn) {
                    return deleteToken(newToken.id, id);
                }
                window.location.href = RouteUtils.localizePath(sitemap.azureLogin);
            } else {
                ToastManager.error(t(UNABLE_TO_CREATE_TOKEN));
            }
        } catch (error) {
            ToastManager.error(t(UNABLE_TO_CREATE_TOKEN));
        } finally {
            setLoadingEmulationSession(false);
        }
    };
    const isInEmulationMode = useMemo(() => token != null, [token]);
    return {
        isInEmulationMode,
        token,
        setToken,
        userEmail,
        createToken,
        deleteToken,
    };
}
