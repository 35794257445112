import { SyllabusUnitCard } from "components/learner/learner-online-learning/syllabus-unit-card/syllabus-unit-card";
import { FC } from "react";
import "./syllabus-unit-card-list.scss";
import { EnrollmentUnitRecord } from "models/view-models/enrollments/enrollment-unit-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SyllabusUnitListProp {
    productId: number;
    enrollmentUnits: EnrollmentUnitRecord[];
}

// #endregion

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "syllabus-unit-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component & Export
// -------------------------------------------------------------------------------------------------

export const SyllabusUnitCardList: FC<SyllabusUnitListProp> = ({ enrollmentUnits, productId }) => {
    return (
        <div className={CSS_CLASS_NAME}>
            {enrollmentUnits.map((enrollmentUnit, index) => {
                return (
                    <SyllabusUnitCard
                        key={`${CSS_CLASS_NAME}-${index}`}
                        productId={productId}
                        enrollmentUnit={enrollmentUnit}
                    />
                );
            })}
        </div>
    );
};

// #endregion Component & Export
