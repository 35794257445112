import { CreateInstructorApplicationEnrollmentDto } from "models/interfaces/enrollments/create-instructor-application-enrollment-dto";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CreateInstructorApplicationEnrollmentDto = {
    productId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CreateInstructorApplicationEnrollmentDtoRecord
    extends Record(defaultValues)
    implements CreateInstructorApplicationEnrollmentDto
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<CreateInstructorApplicationEnrollmentDto>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for CreateInstructorApplicationEnrollmentDto properties, create a new CreateInstructorApplicationEnrollmentDtoRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<CreateInstructorApplicationEnrollmentDto>} values The values to overwrite on this instance.
     * @returns A CreateInstructorApplicationEnrollmentDtoRecord with values from this instance and the values parameter.
     */
    public with(
        values: Partial<CreateInstructorApplicationEnrollmentDto>
    ): CreateInstructorApplicationEnrollmentDtoRecord {
        return new CreateInstructorApplicationEnrollmentDtoRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateInstructorApplicationEnrollmentDtoRecord };

// #endregion Exports
