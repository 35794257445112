import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import "./add-enrollment-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddEnrollmentModalProps {
    createEnrollmentDto?: CreateEnrollmentDtoRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveEnrollment: (createEnrollmentDto?: CreateEnrollmentDtoRecord) => Promise<boolean>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-enrollment-modal";

// #endregion Constants
// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddEnrollmentModal: React.FC<AddEnrollmentModalProps> = (
    props: AddEnrollmentModalProps
): JSX.Element => {
    let initialEnrollment = props.createEnrollmentDto ?? new CreateEnrollmentDtoRecord();
    const [createEnrollmentDto, setCreateEnrollmentDto] = useState(initialEnrollment);
    const [emailError, setEmailError] = useState("");

    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("add"),
            disabled:
                !StringUtils.isValidEmail(createEnrollmentDto.email) ||
                !StringUtils.hasValue(createEnrollmentDto.firstName) ||
                !StringUtils.hasValue(createEnrollmentDto.lastName),
            onClick: () => onEnrollmentAdd(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const resetForm = (): void => {
        setDirty(false);
        setCreateEnrollmentDto(initialEnrollment);
        resetValidationErrors();
    };

    const handleEmailValidation = (): void => {
        validateEmail(createEnrollmentDto.email ?? "");
    };

    const resetValidationErrors = () => {
        setEmailError("");
    };

    const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        onEnrollmentChanged(createEnrollmentDto.with({ email: e.target.value }));
    };

    const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        onEnrollmentChanged(createEnrollmentDto.with({ firstName: e.target.value }));
    };

    const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        onEnrollmentChanged(createEnrollmentDto.with({ lastName: e.target.value }));
    };

    const onEnrollmentChanged = (createEnrollmentDto: CreateEnrollmentDtoRecord): void => {
        setDirty(true);
        setCreateEnrollmentDto(createEnrollmentDto);
    };

    const onEnrollmentAdd = async (): Promise<void> => {
        if (await props.handleSaveEnrollment(createEnrollmentDto)) {
            setDirty(false);
            setCreateEnrollmentDto(initialEnrollment);
            props.setOpen(false);
        } else {
            return;
        }
    };

    const validateEmail = (email: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
        } else {
            setEmailError("");
        }
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("addEnrollment")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__form`}>
                <FormTextInput
                    ariaLabelledBy={t("email")}
                    autoFocus={true}
                    errorMessage={emailError}
                    formFieldName="email"
                    id="email"
                    label={t("email")}
                    onBlur={handleEmailValidation}
                    onChange={handleSetEmail}
                    placeholder={t("enterEmail")}
                    required={true}
                    type={InputTypes.Email}
                    value={createEnrollmentDto?.email}
                />
                <FormTextInput
                    ariaLabelledBy={t("firstName")}
                    formFieldName="firstName"
                    id="firstName"
                    label={t("firstName")}
                    maxLength={100}
                    onChange={handleFirstName}
                    placeholder={t("firstName")}
                    required={true}
                    type={InputTypes.Text}
                    value={createEnrollmentDto?.firstName}
                />
                <FormTextInput
                    ariaLabelledBy={t("lastName")}
                    formFieldName="lastName"
                    id="lastName"
                    label={t("lastName")}
                    maxLength={100}
                    required={true}
                    onChange={handleLastName}
                    placeholder={t("lastName")}
                    value={createEnrollmentDto?.lastName}
                    type={InputTypes.Text}
                />
            </div>

            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddEnrollmentModal };

// #endregion Exports
