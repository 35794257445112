import React from "react";
import { LogoAnchor } from "components/typography/anchors/logo-anchor/logo-anchor";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import "./application-title.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ApplicationTitleProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "application-title";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ApplicationTitle: React.FC<ApplicationTitleProps> = (): JSX.Element => (
    <section className={CSS_CLASS_NAME}>
        <div className={`${CSS_CLASS_NAME}__logo`}>
            <LogoAnchor />
        </div>
        <Breadcrumbs />
    </section>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ApplicationTitle };

// #endregion Exports
