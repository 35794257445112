import React from "react";
import { ThirdPartyLayoutHeader } from "layouts/third-party/third-party-layout/third-party-layout-header/third-party-layout-header";
import { Outlet } from "react-router-dom";
import { SkipNavLink } from "@chakra-ui/skip-nav";
import "./third-party-layout.scss";
import { t } from "utilities/localization/t";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ThirdPartyLayoutProps {
    sidebar?: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ThirdPartyLayout: React.FC<ThirdPartyLayoutProps> = (
    props: ThirdPartyLayoutProps
): JSX.Element => {
    const { isInEmulationMode } = useEmulationMode();
    const className = isInEmulationMode ? `${CSS_CLASS_NAME} -emulation-mode` : CSS_CLASS_NAME;

    return (
        <div className={className}>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <ThirdPartyLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            <div className={`${CSS_CLASS_NAME}__body`}>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ThirdPartyLayout };

// #endregion Exports
