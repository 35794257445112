import React from "react";
import { Card } from "components/card/card";
import { DataTable } from "components/tables/data-table/data-table";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import { EventDayBlock } from "./event-day-block";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventRecord } from "models/view-models/events/event-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { RoleType } from "models/enumerations/users/role-type";
import { StringUtils } from "andculturecode-javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import "./event-schedule-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleCardProps {
    cssClassName?: string;
    attendances?: EventDayAttendanceRecord[];
    event: EventRecord;
    schedule?: EventDayRecord[];
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleCard: React.FC<EventScheduleCardProps> = ({
    attendances,
    cssClassName,
    event,
    schedule,
    stacked,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const isLearner = globalState?.currentIdentity?.role?.roleType === RoleType.Learner;

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <Card cssClassName={className} stacked={stacked}>
            {!CollectionUtils.hasValues(schedule) ? (
                <EmptyText spacing={EmptyTextSpacing.None}>{t("noScheduleCreated")}</EmptyText>
            ) : (
                <>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H3}>
                        {t("schedule")}
                    </Heading>
                    <DataTable cssClassName={`${className}__table`}>
                        <thead>
                            <tr>
                                <th className="day">{t("day")}</th>
                                {isLearner && <th>{t("attendance")}</th>}
                                <th className="name">{t("name")}</th>
                                <th className="session">{t("session")}</th>
                                <th className="time">{t("time")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedule?.map((eventDay) => (
                                <EventDayBlock
                                    event={event}
                                    eventDay={eventDay}
                                    key={eventDay.id}
                                    attendances={attendances}
                                />
                            ))}
                        </tbody>
                    </DataTable>
                </>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleCard };

// #endregion Exports
