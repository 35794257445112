import { FC, PropsWithChildren } from "react";
import "./report-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ReportSectionSizes {
    Full = "",
    Half = "half",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ReportSectionProps {
    header?: JSX.Element;
    size?: ReportSectionSizes;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportSection: FC<PropsWithChildren<ReportSectionProps>> = ({
    header,
    size = ReportSectionSizes.Full,
    children,
}) => (
    <div className={`report-section ${size}`}>
        {header != null && <div className="report-section__header">{header}</div>}
        {children}
    </div>
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportSectionSizes };
export default ReportSection;

// #endregion Exports
