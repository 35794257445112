import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import {
    PatchProductPathParams,
    ProductService,
} from "utilities/services/products/product-service";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { TrainingType } from "models/enumerations/courses/training-type";
import { RouteUtils } from "utilities/route-utils";
import "./product-unpublish-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductUnpublishModalProps {
    product: ProductRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-unpublish-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductUnpublishModal: React.FC<ProductUnpublishModalProps> = ({
    product,
    open,
    setOpen,
}): JSX.Element => {
    const { patch: unpublishAPI } = ProductService.useUnpublish();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("unpublishProduct"),
            disabled: product.status === ActiveStatus.Draft,
            onClick: () => unpublishProduct(),
            style: ButtonStyle.Primary,
        },
    ];

    const unpublishProduct = async (): Promise<void> => {
        if (product.id == null) {
            return;
        }
        setOpen(false);
        try {
            const unpublishPathParams: PatchProductPathParams = {
                id: product.id,
            };

            const unpublishProductResponse = await unpublishAPI(unpublishPathParams);

            const unpublishProductResult = unpublishProductResponse?.result;

            if (
                unpublishProductResult?.resultObject == null ||
                unpublishProductResult.hasErrors()
            ) {
                throw new Error();
            }

            const productDetails =
                product.type === TrainingType.InstructorLedTraining
                    ? RouteUtils.replacePathParams(
                          sitemap.admin.product.edit.instructorLed.details,
                          {
                              id: product.id,
                          }
                      )
                    : RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                          id: product.id,
                      });

            window.location.href = RouteUtils.localizePath(productDetails);

            ToastManager.success(t("unpublishedSuccessfully"));
        } catch {
            ToastManager.error(t("failedToUnpublish"));
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("unpublishProduct")}
            actions={modalActionArray}
            modalStyle={""}>
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__header`} size={ParagraphSize.Large}>
                {
                    "You are about to unpublish this product. There may be existing learners associated with this product and should only be done if you are sure that the changes will not affect Learners."
                }
            </Paragraph>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductUnpublishModal };

// #endregion Exports
