import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ProductContentAccess {
    Everyone = 0,
    InstructorProvider = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ProductContentAccessDisplayNames: Map<ProductContentAccess, TranslatedCopy> = {
    [ProductContentAccess.InstructorProvider]: "administratorSlashInstructor",
    [ProductContentAccess.Everyone]: "participants",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductContentAccess, ProductContentAccessDisplayNames };

// #endregion Exports
