import React, { useState } from "react";
import { AudienceTypeDisplayNames } from "models/enumerations/audiences/audience-type";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonStyle } from "components/buttons/button/button";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { useProduct } from "utilities/contexts/use-product-context";
import { t } from "utilities/localization/t";
import "./product-details-changes-modal.scss";
import { ToastManager } from "utilities/toast/toast-manager";
import { ProductRecord } from "models/view-models/products/product-record";
import {
    ProductService,
    UpdateProductPathParams,
} from "utilities/services/products/product-service";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDetailsChangesModalProps {
    productDetailsWithEdits: ProductRecord;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSaveDetailsChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
    showSaveDetailsChangesModal: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-details-changes-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDetailsChangesModal: React.FC<ProductDetailsChangesModalProps> = ({
    productDetailsWithEdits,
    setEditMode,
    setShowSaveDetailsChangesModal,
    showSaveDetailsChangesModal,
    setRefresh,
}): JSX.Element => {
    const { setRecord: setProduct } = useProduct();
    const { update } = ProductService.useUpdate();

    const isInstructorLed = productDetailsWithEdits.type === TrainingType.InstructorLedTraining;
    const isInstructorAssessment =
        productDetailsWithEdits.type === TrainingType.InstructorAssessment;
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");

    const modalActionArray: ModalAction[] = [
        {
            buttonText: "Cancel",
            onClick: () => setShowSaveDetailsChangesModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: "Save Changes",
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = () => {
        setShowSaveDetailsChangesModal(false);
        setEditMode(false);
        updateProductDetails();
        setChangeLogDescription("");
    };

    const updateProductDetails = async (): Promise<boolean> => {
        try {
            var activeProduct = productDetailsWithEdits.with({
                changeLogDescription: changeLogDescription,
            });

            const savedProductImageFile = activeProduct.productImageFile;
            const updateProductPathParms: UpdateProductPathParams = {
                id: productDetailsWithEdits.id!,
            };

            const updateProductResponse = await update(activeProduct, updateProductPathParms);

            const updateResult = updateProductResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            const updatedProductResult: ProductRecord = updateResult.resultObject;
            setProduct(updatedProductResult.with({ productImageFile: savedProductImageFile }));
            setRefresh(true);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheCourseDetails"));
            return false;
        }
        return true;
    };

    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    return (
        <Modal
            actions={modalActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={showSaveDetailsChangesModal}
            onModalClose={() => setShowSaveDetailsChangesModal(false)}
            title="Save Product Details Changes">
            <div>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                    priority={HeadingPriority.H5}>
                    You Are Editing the Product Details.
                </Heading>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading-description`}
                    size={ParagraphSize.XLarge}
                    style={ParagraphStyle.Light}>
                    Take a moment to review your changes and populate the change log before saving.
                </Paragraph>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__change-log`}
                    size={ParagraphSize.XLarge}>
                    Change Log
                </Paragraph>
                <NotificationBanner
                    notificationId={0}
                    onClose={() => {}}
                    style={NotificationType.Default}
                    message="Changes will be applied to all Learners, regardless of version or completion status, and will not impact or reset a Learner's progress."
                />
                <FormTextArea
                    ariaLabelledBy="Describe Your Changes"
                    cssClassName={`${CSS_CLASS_NAME}__text-area`}
                    formFieldName="Describe Your Changes"
                    id="describeYourChanges"
                    label="Describe Your Changes"
                    maxLength={1000}
                    onChange={handleChangeLogChange}
                    placeholder="Enter Describe Your Changes"
                    required={true}
                    rows={6}
                    value={changeLogDescription}
                />
                <div className={`${CSS_CLASS_NAME}__details ${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__details__header`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("productDetails")}
                        </Heading>
                        <Badge style={BadgeStyle.Light}>
                            <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__badge`} />
                            {AudienceTypeDisplayNames[productDetailsWithEdits.audienceType]}
                        </Badge>
                        <Badge style={BadgeStyle.Light}>
                            <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__badge`} />
                            {TrainingTypeDisplayNames[productDetailsWithEdits.type!]}
                        </Badge>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__content`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>PRODUCT NAME</Paragraph>
                            <Paragraph>{productDetailsWithEdits.name}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>PRODUCT DESCRIPTION</Paragraph>
                            <Paragraph>{productDetailsWithEdits.description}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__metadata`}>
                        {(isInstructorLed || isInstructorAssessment) && (
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                                <Paragraph>
                                    {TopicDisplayNames[productDetailsWithEdits.topic!]}
                                </Paragraph>
                            </div>
                        )}
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>LANGUAGE</Paragraph>
                            <Paragraph>
                                {LanguageDisplayNames[productDetailsWithEdits.language!]}
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>PRODUCT ID</Paragraph>
                            <Paragraph>{productDetailsWithEdits.id}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>ERP PIN</Paragraph>
                            <Paragraph>{productDetailsWithEdits.erpId}</Paragraph>
                        </div>
                        {isInstructorLed && (
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>
                                    AVAILABLE FOR AEN
                                </Paragraph>
                                <Paragraph>
                                    {productDetailsWithEdits.availableForAEN ? "Yes" : "No"}
                                </Paragraph>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDetailsChangesModal };

// #endregion Exports
