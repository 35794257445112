import axios from "axios";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "announcements";
const activeEndpoint: string = `${baseEndpoint}/active`;
const archiveEndpoint: string = `${baseEndpoint}/:id/archive`;
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof AnnouncementRecord = AnnouncementRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchAnnouncementService = (
    params: PatchAnnouncementServiceParams
) => Promise<ServiceResponse<AnnouncementRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetAnnouncementPathParams {}

export interface GetAnnouncementQueryParams {
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
}

export interface ListAnnouncementsQueryParams {
    archivedOnly?: boolean;
    includeCreatedBy: boolean;
    includeLastModifiedBy: boolean;
    skip?: number;
    take?: number;
}

export interface PatchAnnouncementServiceParams {
    id: number;
}

export interface UpdateAnnouncementPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AnnouncementService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<AnnouncementRecord, ListAnnouncementsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useGet: ServiceHookFactory.useGet<
        AnnouncementRecord,
        GetAnnouncementPathParams,
        GetAnnouncementQueryParams
    >(resourceType, activeEndpoint),

    useArchive: (): { archive: PatchAnnouncementService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchAnnouncementService = (
            params: PatchAnnouncementServiceParams
        ): Promise<ServiceResponse<AnnouncementRecord>> => {
            const endPointWithPatchRouteReplaced = archiveEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: PatchAnnouncementServiceParams
        ): Promise<ServiceResponse<AnnouncementRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<AnnouncementRecord>
            >;
        }

        return { archive: useCallback(patch, [cancellablePromise]) };
    },

    useUpdate: ServiceHookFactory.useUpdate<AnnouncementRecord, UpdateAnnouncementPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnnouncementService };

// #endregion Exports
