import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelCreatingVersionModal } from "components/modal/cancel-creating-version-modal/cancel-creating-version-modal";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CreateInstructorProductVersionMaterials } from "components/products/product-version/create-instructor-product-version-materials/create-instructor-product-version-materials";
import {
    GetLatestProductVersionPathParams,
    GetLatestProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { HeaderActions } from "components/header-actions/header-actions";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useNavigate } from "react-router-dom";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./create-assessment-product-materials-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateAssessmentProductMaterialsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "create-instructor-led-product-materials-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateAssessmentProductMaterialsPage: React.FC<
    CreateAssessmentProductMaterialsPageProps
> = (): JSX.Element => {
    const { record: product } = useProduct();
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const { get: getLatestProductVersion } = ProductVersionService.useGetLatest();
    const navigate = useNavigate();
    const [showCreateVersionModal, setShowCreateVersionModal] = useState<boolean>(false);
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const [disableCreateVersionButton, setDisableCreateVersionButton] = useState<boolean>(true);
    const { create: createVersion } = ProductVersionService.useCreate();
    const [assessmentHasChanges, setAssessmentHasChanges] = useState<boolean>(false);
    const [assessmentToggleHasChanges, setAssessmentToggleHasChanges] = useState<boolean>(false);
    const [toggleClicks, setToggleClicks] = useState<number>(0);
    const versionNumber = productVersion.versionId;

    useRedirectOnForbidden(sitemap.admin.product.list);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const navigationRequests = useListenForNavigationRequests(
        CreateAssessmentProductMaterialsPage.name,
        [Breadcrumbs.name, ProductLayout.name]
    );
    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (product.status === ActiveStatus.Draft) {
            resolveNavigationRequest(
                CreateAssessmentProductMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
            return;
        }
        setNavigationRequest(navigationRequest);
    }, [navigationRequests, product.status, resolveNavigationRequest]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(
                CreateAssessmentProductMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(
            CreateAssessmentProductMaterialsPage.name,
            navigationRequest.requestingComponent
        );
    }, [navigationRequest, resolveNavigationRequest]);

    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    useEffect(() => {
        if (productVersion == null) return;
        if (product.type == null) return;
        if (
            product.type === TrainingType.InstructorLedTraining ||
            product.type === TrainingType.InstructorAssessment
        ) {
            if (productVersion.productScormPackages == null) {
                setDisableCreateVersionButton(true);
                return;
            }
            if (productVersion.assessmentIsComplete() && assessmentHasChanges) {
                setDisableCreateVersionButton(false);
            } else {
                setDisableCreateVersionButton(true);
            }
        }
    }, [assessmentHasChanges, product.audienceType, product.type, productVersion]);

    const createVersionActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowCreateVersionModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continue"),
            onClick: () => {
                setShowCreateVersionModal(false);
                handleCreateProductVersionFromLatest();
            },
            style: ButtonStyle.Primary,
        },
    ];

    const onCancelCreateVersionModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                    id: product.id,
                })
            )
        );
    };

    const handleCreateProductVersionFromLatest = async (): Promise<void> => {
        if (productVersion == null) return;
        if (product.type == null) return;
        if (product.type === TrainingType.InstructorLedTraining) {
            if (productVersion.productScormPackages == null) {
                setDisableCreateVersionButton(true);
                return;
            }

            if (productVersion.productScormPackages.length > 0) {
                setDisableCreateVersionButton(false);
            } else {
                alert(t("youCannotSaveThisVersionUntilAllSectionsAreComplete"));
                return;
            }
        }

        try {
            await createVersion(productVersion);
            navigate(
                RouteUtils.localizePath(
                    RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                        id: product.id,
                    })
                )
            );
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingTheNewVersion"));
        }
    };

    const createNewProductVersionFromLatest = useCallback(async (): Promise<void> => {
        try {
            if (product !== undefined) {
                //Get the latestProductVersion for this course:
                if (product.id == null) return;
                const pathParams: GetLatestProductVersionPathParams = {
                    id: product.id,
                };
                const queryParams: GetLatestProductVersionQueryParams = {
                    includeContent: true,
                    includeScormPackage: true,
                    includeUnit: false,
                };

                const getResponse = await getLatestProductVersion(pathParams, queryParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                const latestProductVersion: ProductVersionRecord = getResult.resultObject;
                if (latestProductVersion == null) {
                    return;
                }
                latestProductVersion.sortAllChildren();

                const newProductVersion = latestProductVersion.with({
                    id: -1,
                    numberOfInProgressLearners: 0,
                    numberOfEnrolledLearners: 0,
                    versionId: latestProductVersion.versionId + 1,
                    productScormPackages: [],
                });
                setProductVersion(newProductVersion);
                return;
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheProduct"));
        }
    }, [getLatestProductVersion, product, setProductVersion]);

    useEffect(() => {
        if (product.status === ActiveStatus.Active) {
            createNewProductVersionFromLatest();
        }
    }, [createNewProductVersionFromLatest, product.status]);

    useEffect(() => {
        if (assessmentToggleHasChanges) {
            setAssessmentHasChanges(true);
        }
    }, [assessmentToggleHasChanges]);

    return (
        <>
            {product.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedProduct")}
                </Banner>
            )}
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    {product.status !== ActiveStatus.Active && (
                        <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                            {t("productMaterials")}
                        </Heading>
                    )}
                    {product.status === ActiveStatus.Active && (
                        <HeaderActions
                            headingPriority={HeadingPriority.H5}
                            headingSize={HeadingSize.Small}
                            title={t("newVersionVersionNumber", {
                                versionNumber: productVersion.versionId,
                            })}>
                            <div className={`${CSS_CLASS_NAME}__header-buttons`}>
                                <Button
                                    onClick={() => setShowCancelCreateVersionModal(true)}
                                    style={ButtonStyle.Secondary}
                                    text={t("cancelVersion")}
                                />
                                <ButtonIcon
                                    buttonStyle={ButtonStyle.Primary}
                                    disabled={disableCreateVersionButton}
                                    iconType={Icons.PublishWithChanges}
                                    onClick={() => setShowCreateVersionModal(true)}
                                    text={t("createVersion")}
                                />
                            </div>
                        </HeaderActions>
                    )}
                </div>
                <CreateInstructorProductVersionMaterials
                    setAssessmentHasChanges={setAssessmentHasChanges}
                    setAssessmentToggleHasChanges={setAssessmentToggleHasChanges}
                    toggleClicks={toggleClicks}
                    setToggleClicks={setToggleClicks}
                />
                <Modal
                    actions={createVersionActionArray}
                    isOpen={showCreateVersionModal}
                    modalStyle={""}
                    onModalClose={() => setShowCreateVersionModal(false)}
                    title={t("createANewVersion")}>
                    <Heading
                        cssClassName={`${CSS_CLASS_NAME}__create-version-modal-title`}
                        size={HeadingSize.XSmall}>
                        {t("youveCreatedVersionVersionNumber", { versionNumber: versionNumber })}
                    </Heading>
                    <Paragraph size={ParagraphSize.Large}>
                        {t(
                            "yourNewVersionHasBeenCreatedAndTheZIPPackageIsCurrentlyProcessingOnceTheUploadIsCompleteMoveForwardToActivatingTheVersionToApplyIt"
                        )}
                    </Paragraph>
                </Modal>
                <CancelCreatingVersionModal
                    open={showCancelCreateVersionModal}
                    setOpen={setShowCancelCreateVersionModal}
                    onModalClose={onCancelCreateVersionModalClose}
                />
                <Modal
                    cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                    isOpen={showCancelEditOnNavigationModal}
                    onModalClose={() => {}}
                    actions={cancelEditModeOnNavigationConfirmationActions}
                    modalStyle={"-inverted"}>
                    {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
                </Modal>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateAssessmentProductMaterialsPage };

// #endregion Exports
