import React, { useMemo, useState } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { ButtonStyle } from "components/buttons/button/button";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { ModalAction } from "components/modal/modal";
import { RoleType } from "models/enumerations/users/role-type";
import { Status } from "utilities/enumerations/statuses";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { WithdrawEnrollmentModal } from "components/enrollments/withdraw-enrollment-modal/withdraw-enrollment-modal";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { StringUtils } from "utilities/string-utils";
import { DateUtils } from "utilities/date-utils";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import "./enrollment-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnrollmentListProps {
    enrollments?: EnrollmentRecord[];
    handleWithdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "enrollment-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnrollmentList: React.FC<EnrollmentListProps> = ({
    enrollments,
    handleWithdrawEnrollment,
}): JSX.Element => {
    const [enrollmentToWithdraw, setEnrollmentToWithdraw] = useState<EnrollmentRecord>(
        new EnrollmentRecord()
    );

    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);

    const { record: globalState } = useGlobalState();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            userRole === RoleType.AenAdministrator ||
            userRole === RoleType.ClientAdmin,
        [userRole]
    );
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                handleWithdrawEnrollment(enrollmentToWithdraw);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const withdrawEnrollment = (e: EnrollmentRecord) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    return (
        <>
            {CollectionUtils.hasValues(enrollments) ? (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className="account">
                                {t("name")}{" "}
                                <Tooltip
                                    content={
                                        <>
                                            {t("enrollmentStatusWillAppearAsInvitePending")}{" "}
                                            <i>
                                                {t(
                                                    "noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining"
                                                )}
                                            </i>
                                        </>
                                    }
                                    placement={TooltipPlacement.Top}>
                                    <span>
                                        <Icon type={Icons.Information} />
                                    </span>
                                </Tooltip>
                            </th>
                            <th className="enrollment">{t("enrollment")}</th>
                            <th className="expiration-date">{t("trainingAccessExpiration")}</th>
                            <th className="completion-date">{t("completionDate")}</th>
                            <th className="action">
                                <span className="sr-only">{t("action")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {enrollments !== undefined &&
                            enrollments.map(
                                (enrollment): JSX.Element => (
                                    <tr key={enrollment.id}>
                                        <td className="account">
                                            <span>
                                                <div className={`${CSS_CLASS_NAME}__account-name`}>
                                                    {StringUtils.hasValue(
                                                        enrollment.user?.firstName
                                                    )
                                                        ? enrollment.user?.getFirstAndLastName()
                                                        : "- -"}
                                                </div>
                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {enrollment.user?.email}
                                                </div>
                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {currentUserIsInNfpaRole
                                                        ? enrollment.user?.id
                                                        : ""}
                                                </div>
                                            </span>
                                        </td>
                                        <td className="enrollment">
                                            {enrollment?.withdrawnOn != null ? (
                                                <StatusBadge
                                                    status={Status.Error}
                                                    text="withdrawn"
                                                />
                                            ) : (
                                                <StatusBadge
                                                    status={Status.Success}
                                                    text="enrolled"
                                                />
                                            )}
                                        </td>
                                        <td className="expiration-date">
                                            <span>
                                                {enrollment.expirationDate
                                                    ? DateUtils.formatDateCustom(
                                                          enrollment.expirationDate?.toString(),
                                                          t("shortDateFormat")
                                                      )
                                                    : "--"}
                                            </span>
                                        </td>
                                        <td className="completion-date">
                                            {enrollment.completedOn == null
                                                ? `--`
                                                : DateUtils.formatDateCustom(
                                                      enrollment.completedOn.toString(),
                                                      t("shortDateFormat")
                                                  )}
                                        </td>
                                        <td className="action">
                                            {
                                                // if
                                                canModifyEnrollments && (
                                                    <ContextMenu>
                                                        <ContextMenuButton
                                                            displayName={t("withdraw")}
                                                            disabled={
                                                                enrollment?.isComplete() ||
                                                                enrollment?.withdrawnOn != null
                                                            }
                                                            onClick={() =>
                                                                withdrawEnrollment(enrollment)
                                                            }
                                                        />
                                                        <ContextMenuAnchor
                                                            hrefPath={RouteUtils.replacePathParams(
                                                                sitemap.admin.userManagement.users
                                                                    .trainings.overview,
                                                                {
                                                                    id: enrollment.userId,
                                                                    enrollmentId: enrollment.id,
                                                                }
                                                            )}
                                                            displayName={t("viewTraining")}
                                                        />
                                                    </ContextMenu>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </DataTable>
            ) : (
                <EmptyText table>{t("noLearnersEnrolled")}</EmptyText>
            )}
            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={enrollmentToWithdraw?.user?.getFirstAndLastNameOrEmail() ?? ""}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentList };

// #endregion Exports
