import { ButtonIcon, ButtonIconPosition } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductImage } from "components/product-image/product-image";
import { ProductRecord } from "models/view-models/products/product-record";
import { t } from "utilities/localization/t";
import "./aen-product-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENProductCardProps {
    product: ProductRecord;
    onProductSelection: (product: ProductRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-product-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENProductCard: React.FC<AENProductCardProps> = ({ product, onProductSelection }) => {
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__detail`}>
                <Paragraph size={ParagraphSize.Small} cssClassName={`${CSS_CLASS_NAME}__name`}>
                    {product.name}
                </Paragraph>
                <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Faded}>
                    {product.getTopicDisplay()}
                </Paragraph>

                <div className={`${CSS_CLASS_NAME}__select-action`}>
                    <ButtonIcon
                        ariaLabelledBy={t("productDetails")}
                        buttonSize={ButtonSize.Small}
                        buttonStyle={ButtonStyle.Secondary}
                        iconType={Icons.ChevronRight}
                        iconPosition={ButtonIconPosition.Right}
                        onClick={() => onProductSelection(product)}
                        text={t("productDetails")}
                    />
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__image-container`}>
                <ProductImage productImageFileId={product.productImageFileId} />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENProductCard };

// #endregion Exports
