import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEvent } from "utilities/contexts/use-event-context";
import { NumberUtils } from "utilities/number-utils";
import { EventDayAttendanceHeader } from "components/attendance/event-day-attendance-manager/event-day-attendance-header/event-day-attendance-header";
import { EventDayAttendanceList } from "components/attendance/event-day-attendance-manager/event-day-attendance-list/event-day-attendance-list";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { RouteUtils } from "utilities/route-utils";
import {
    EventDayAttendanceService,
    ListEventDaysAttendanceQueryParams,
} from "utilities/services/events/event-day-attendance-service";
import { EventDayService } from "utilities/services/events/event-day-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icons } from "components/icons/constants/icons";
import { ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import "./manage-live-virtual-event-attendance-day-page.scss";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { useProvider } from "utilities/contexts/use-provider-context";
import { RoleType } from "models/enumerations/users/role-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventAttendanceDayPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-attendance-day-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventAttendanceDayPage: React.FC<ManageLiveVirtualEventAttendanceDayPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventAttendanceDayPage)(
        (): JSX.Element => {
            const { record: globalState } = useGlobalState();
            const { record: provider } = useProvider();
            const currentUserIsInNfpaRole =
                globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
            const isAEN = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);
            const canEditAttendance =
                ((isAEN && provider?.isActive) ||
                    (currentUserIsInNfpaRole &&
                        !globalState.currentIdentity?.isCurrentlyInRole(RoleType.NfpaSupport)) ||
                    globalState.currentIdentity?.isCurrentlyInAnyRole([
                        RoleType.ClientAdmin,
                        RoleType.Instructor,
                    ])) ??
                false;

            useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
            const { id: eventIdParam, eventDayId: eventDayIdParam } = useParams();
            const eventId: number = useMemo(
                (): number => NumberUtils.parseInt(eventIdParam) ?? 0,
                [eventIdParam]
            );
            const eventDayId: number = useMemo(
                (): number => NumberUtils.parseInt(eventDayIdParam) ?? 0,
                [eventDayIdParam]
            );
            const [changedEventDayAttendanceIds, setChangedEventDayAttendanceIds] = useState<
                number[]
            >([]);

            const navigate = useNavigate();
            const { update: updateEventDayApi } = EventDayService.useUpdate();
            const { list: indexEventDayAttendancesApi } = EventDayAttendanceService.useList();
            const { attended: attendedApi } = EventDayAttendanceService.useAttended();
            const { notAttended: notAttendedApi } = EventDayAttendanceService.useNotAttended();
            const [eventDayAttendances, setEventDayAttendances] = useState<
                EventDayAttendanceRecord[]
            >([]);
            const { record: event, setRecord: setEvent } = useEvent();

            const eventDay = useMemo(
                () => event.eventDays?.find((ed) => ed.id === eventDayId),
                [event.eventDays, eventDayId]
            );

            const { viewEventRoutes } = useViewEventRoutes({
                roleType: globalState?.currentIdentity?.role?.roleType,
            });

            const fetchEventDayAttendances = useCallback(async (): Promise<void> => {
                if (eventDayId < 1) {
                    return;
                }

                try {
                    var indexEventDayAttendancesQueryParams: ListEventDaysAttendanceQueryParams = {
                        eventDayId: eventDayId,
                        includeUser: true,
                        includeLastModifiedBy: true,
                        provisionEventDayAttendances: true,
                    };

                    var indexEventDayAttendancesResponse = await indexEventDayAttendancesApi(
                        indexEventDayAttendancesQueryParams
                    );

                    if (
                        indexEventDayAttendancesResponse?.resultObjects == null ||
                        indexEventDayAttendancesResponse.results == null ||
                        indexEventDayAttendancesResponse.results.hasErrors()
                    ) {
                        throw new Error();
                    }

                    setEventDayAttendances(indexEventDayAttendancesResponse.resultObjects);
                } catch {
                    ToastManager.error(t("thereWasAnIssueLoadingAttendance"));
                    setEventDayAttendances([]);
                }
            }, [eventDayId, indexEventDayAttendancesApi]);

            const handleAttendedChange = useCallback(
                (id: number, newValue: boolean): void => {
                    const changedIndex: number = eventDayAttendances.findIndex(
                        (eda: EventDayAttendanceRecord): boolean => eda.id === id
                    );

                    if (changedIndex < 0) {
                        return;
                    }

                    if (!changedEventDayAttendanceIds.includes(id)) {
                        setChangedEventDayAttendanceIds([...changedEventDayAttendanceIds, id]);
                    }

                    const newEventDayAttendances = [...eventDayAttendances];
                    newEventDayAttendances[changedIndex] = eventDayAttendances[changedIndex].with({
                        attended: newValue,
                    });

                    setEventDayAttendances(newEventDayAttendances);
                },
                [changedEventDayAttendanceIds, eventDayAttendances]
            );

            const handleEventDayStatusChange = useCallback(
                async (eventDayStatus: EventDayStatus): Promise<void> => {
                    ToastManager.dismissAll();
                    if (eventDay == null || !eventDay.eventDayHasStarted()) {
                        return;
                    }

                    const unfinishedEnrollmentRecords = eventDayAttendances.reduce(
                        (accum, curr) => accum + (curr.hasIncompleteAttendanceRecord() ? 1 : 0),
                        0
                    );

                    if (
                        unfinishedEnrollmentRecords > 0 &&
                        eventDayStatus === EventDayStatus.Completed
                    ) {
                        ToastManager.error(
                            t("pleaseConfirmThatAllLearnersAreMarkedAsAttendedOrNoShow")
                        );
                        return;
                    }

                    const updatedEventDay = eventDay.with({ status: eventDayStatus });

                    const apiCalls = [
                        updateEventDayApi(updatedEventDay, { id: updatedEventDay.id! }),
                        attendedApi({
                            ids: eventDayAttendances
                                .filter(
                                    (eda) =>
                                        eda.attended &&
                                        changedEventDayAttendanceIds.includes(eda.id ?? 0)
                                )
                                .map((eda) => eda.id!),
                        }),
                        notAttendedApi({
                            ids: eventDayAttendances
                                .filter(
                                    (eda) =>
                                        !eda.attended &&
                                        changedEventDayAttendanceIds.includes(eda.id ?? 0)
                                )
                                .map((eda) => eda.id!),
                        }),
                    ];

                    try {
                        const responses = await Promise.all(apiCalls);

                        const failed: boolean = responses.some(
                            (r) => r?.result?.resultObject == null || r.result.hasErrors()
                        );

                        if (failed) {
                            throw new Error();
                        }

                        ToastManager.success(t("attendanceTrackedSuccessfully"));

                        const eventDays = event?.eventDays?.map((eventDay) =>
                            eventDay.id === updatedEventDay.id ? updatedEventDay : eventDay
                        );

                        if (eventDays != null) {
                            setEvent(event.with({ eventDays: eventDays }));
                        }

                        navigate(
                            RouteUtils.replacePathParams(viewEventRoutes.attendanceSummary, {
                                id: eventId,
                            })
                        );
                    } catch {
                        ToastManager.error(t("thereWasAnIssueTrackingAttendance"));
                    }
                },
                [
                    attendedApi,
                    changedEventDayAttendanceIds,
                    event,
                    eventDay,
                    eventDayAttendances,
                    eventId,
                    navigate,
                    notAttendedApi,
                    setEvent,
                    updateEventDayApi,
                    viewEventRoutes.attendanceSummary,
                ]
            );

            const attendanceSummaryPath: string = useMemo(
                () =>
                    RouteUtils.replacePathParams(viewEventRoutes.attendanceSummary, {
                        id: event.id,
                    }),
                [event.id, viewEventRoutes.attendanceSummary]
            );

            useEffect(() => {
                fetchEventDayAttendances();
            }, [fetchEventDayAttendances]);

            return (
                <div className={CSS_CLASS_NAME}>
                    <>
                        <ButtonIcon
                            linkPath={attendanceSummaryPath}
                            buttonSize={ButtonSize.Small}
                            buttonStyle={ButtonStyle.Secondary}
                            buttonType={ButtonType.Link}
                            iconType={Icons.ChevronLeft}
                            text={t("backToAllAttendance")}
                        />

                        <EventDayAttendanceHeader
                            canEditAttendance={canEditAttendance}
                            eventDay={eventDay}
                            eventId={eventId}
                            updateEventDayStatus={handleEventDayStatusChange}
                        />

                        <EventDayAttendanceList
                            attendances={eventDayAttendances}
                            canEditAttendance={canEditAttendance}
                            eventDayHasNotStarted={!eventDay?.eventDayHasStarted()}
                            onAttendedChange={handleAttendedChange}
                        />
                    </>
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventAttendanceDayPage };

// #endregion Exports
