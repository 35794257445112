import React from "react";
import { ButtonIcon, ButtonIconPosition } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";

interface AnnouncementDetailsProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    details: string;
}
const CSS_CLASS_NAME: string = "admin-layout-header-profile-selector";

const AnnouncementDetails: React.FC<AnnouncementDetailsProps> = ({
    isOpen,
    setIsOpen,
    title,
    details,
}) => {
    return isOpen ? (
        <div className={`${CSS_CLASS_NAME}__announcement`}>
            <ButtonIcon
                cssClassName={`${CSS_CLASS_NAME}__close`}
                buttonSize={ButtonSize.None}
                buttonStyle={ButtonStyle.None}
                iconSize={IconSizes.Medium}
                iconPosition={ButtonIconPosition.Right}
                iconType={Icons.SlideClose}
                onClick={() => setIsOpen(false)}
                text={t("close").toLocaleUpperCase()}
            />
            <div className={`${CSS_CLASS_NAME}__announcement__Title`}>{title}</div>
            {/* {image && (
                <img
                    className={`${CSS_CLASS_NAME}__announcement__Image`}
                    src={image}
                    alt="announcement"
                />
            )} */}
            <div
                className={`${CSS_CLASS_NAME}__announcement__Details`}
                dangerouslySetInnerHTML={{ __html: details }}
            />
        </div>
    ) : null;
};

export { AnnouncementDetails };
