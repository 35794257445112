import { GlobalState } from "models/interfaces/global-state/global-state";
import { GlobalStateRecord } from "models/view-models/global-state/global-state-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type GlobalStateUpdater = RecordUpdater<GlobalState, GlobalStateRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const {
    RecordContext: GlobalStateContext,
    useRecord: useGlobalState,
} = UseRecordContextFactory.build<GlobalState, GlobalStateRecord>(
    GlobalStateRecord
);

export { GlobalStateContext, useGlobalState };

// #endregion Exports
