import { AdminDashboardTrainingViewTypes } from "pages/admin/events/admin-events-list-page/admin-event-list-page";
import { Anchor } from "../typography/anchors/anchor/anchor";
import { Badge, BadgeStyle } from "../badges/badge/badge";
import { FC, PropsWithChildren } from "react";
import { FormSelect } from "components/form/form-select/form-select";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { SelectOption } from "components/form/inputs/select/select";
import { StringUtils } from "utilities/string-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useMatches } from "react-router-dom";
import "./header-banner.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum HeaderBannerNavPosition {
    Left,
    Right,
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface HeaderBannerNavItem {
    path: string;
    text: TranslatedCopy;
}

export interface HeaderBannerBadge {
    text: string;
    style: BadgeStyle;
}

interface HeaderBannerProps extends PropsWithChildren {
    badges?: HeaderBannerBadge[];
    cssClassName?: string;
    currentViewType?: AdminDashboardTrainingViewTypes;
    navItems?: HeaderBannerNavItem[];
    navPosition?: HeaderBannerNavPosition;
    title: string;
    viewItems?: SelectOption[];
    viewOnChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "header-banner";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HeaderBanner: FC<HeaderBannerProps> = (props: HeaderBannerProps): JSX.Element => {
    const badges = props.badges == null ? [] : props.badges;
    const navItems = props.navItems == null ? [] : props.navItems;
    const viewItems = props.viewItems == null ? [] : props.viewItems;
    const classNames: string[] = [CSS_CLASS_NAME];
    const navItemPosition =
        props.navPosition == null ? HeaderBannerNavPosition.Left : props.navPosition;

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");
    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const match = matches.find((match) => match.pathname?.toLowerCase() === path.toLowerCase());
        return match != null;
    };

    const navigationItems = () => {
        return (
            <nav className={`${CSS_CLASS_NAME}__nav__items`}>
                {navItems.map((navItem, index) => (
                    <Anchor
                        key={`banner-nav-${index}`}
                        path={navItem.path}
                        text={t(navItem.text)}
                        cssClassName={findPathMatch(navItem.path) ? "-selected" : ""}
                    />
                ))}
            </nav>
        );
    };

    const listCalendarViewItems = () => {
        return (
            <div className={`${CSS_CLASS_NAME}__view__items`}>
                <FormSelect
                    key={`banner-view-calendar-view}`}
                    cssClassName={`${CSS_CLASS_NAME}__view__items__select`}
                    ariaLabelledBy={t("trainingViewType")}
                    formFieldName={t("trainingViewType")}
                    id={"trainingViewType"}
                    label={t("trainingViewType")}
                    labelScreenReaderOnly={true}
                    onChange={(e) => props.viewOnChange!(e)}
                    options={viewItems}
                    value={props.currentViewType}
                />
            </div>
        );
    };

    return (
        <div className={className}>
            <div className={`${CSS_CLASS_NAME}__info`}>
                <div className={`${CSS_CLASS_NAME}__info__nav`}>
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {props.title}
                    </Heading>
                    {navItemPosition === HeaderBannerNavPosition.Left &&
                        navItems.length > 0 &&
                        navigationItems()}
                    {navItemPosition === HeaderBannerNavPosition.Left &&
                        viewItems.length > 0 &&
                        listCalendarViewItems()}
                </div>
                {badges.length > 0 && (
                    <div className={`${CSS_CLASS_NAME}__info__badges`}>
                        {badges.map((badge, index) => (
                            <Badge
                                key={badge.text.toLowerCase().replace(" ", "-")}
                                style={badge.style}
                                text={badge.text}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                {navItemPosition === HeaderBannerNavPosition.Right &&
                    navItems.length > 0 &&
                    navigationItems()}
                {props.children}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HeaderBanner };

// #endregion Exports
