import React, { useMemo } from "react";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { ContactCardList } from "components/training-summary/contact-card-list/contact-card-list";
import { ContractCard } from "components/third-party/contract-card/contract-card";
import { DateUtils } from "utilities/date-utils";
import { EventChecklist } from "models/interfaces/events/event-checklist";
import { EventChecklistRecord } from "models/view-models/events/event-checklist-record";
import { EventDescriptionCard } from "components/events/manage-event-details/event-description-card/event-description-card";
import { EventDetailsChecklist } from "components/events/event-details-checklist/event-details-checklist";
import { EventLocationCard } from "components/events/manage-event-details/event-location-card/event-location-card";
import { EventScheduleCard } from "components/events/manage-event-details/event-schedule-card/event-schedule-card";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { RoleType } from "models/enumerations/users/role-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEvent } from "utilities/contexts/use-event-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import {
    EventChecklistService,
    UpdateEventChecklistPathParams,
} from "utilities/services/events/event-checklist-service";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { t } from "utilities/localization/t";
import "./manage-live-virtual-event-details-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-details-page";
const NOTIFICATION_TYPES = [
    NotificationType.EventChanged,
    NotificationType.EventInstructorAssignment,
    NotificationType.IltContractCreation,
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getEventTrainingPath = (eventId?: number) => {
    if (eventId == null) {
        return undefined;
    }

    return RouteUtils.replacePathParams(sitemap.thirdPartyProvider.event.details, {
        id: eventId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventDetailsPage: React.FC<ManageLiveVirtualEventDetailsPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventDetailsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { record: event, setRecord: setEvent } = useEvent();
        const { update: updateAPI } = EventChecklistService.useUpdate();
        const { record: GlobalState } = useGlobalState();

        const currentUserIsInNfpaRole =
            GlobalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
        const isInstructor = GlobalState?.currentIdentity?.role?.roleType === RoleType.Instructor;
        const isThirdPartyProvider =
            GlobalState?.currentIdentity?.role?.roleType === RoleType.ClientAdmin;

        const userId = useMemo(
            () => GlobalState?.currentIdentity?.user?.id,
            [GlobalState?.currentIdentity?.user?.id]
        );

        const { notifications, markNotificationAsRead } = useUserNotifications({
            eventId: event?.id,
            includeEvent: true,
            notificationTypes: NOTIFICATION_TYPES,
            roleType: GlobalState?.currentIdentity?.role?.roleType,
            userId: userId,
        });

        const handleNotificationClose = (notificationId?: number) => {
            if (notificationId == null) {
                return;
            }

            markNotificationAsRead?.(notificationId);
        };

        const handleChecklistChange = async (values: Partial<EventChecklist>): Promise<void> => {
            if (event?.eventChecklist == null) {
                return;
            }

            const originalChecklist = event.eventChecklist;
            const checklistWithChanges = event.eventChecklist.with(values);

            setEvent(event.with({ eventChecklist: checklistWithChanges }));

            if (!(await updateChecklist(checklistWithChanges))) {
                setEvent(event.with({ eventChecklist: originalChecklist }));
            }
        };

        const updateChecklist = async (checklist: EventChecklistRecord): Promise<boolean> => {
            if (checklist == null) {
                ToastManager.error(t("thereWasAProblemUpdatingTheChecklist"));
                return false;
            }

            try {
                const updateChecklistPathParams: UpdateEventChecklistPathParams = {
                    id: checklist?.id!,
                };

                const updateChecklistResponse = await updateAPI(
                    checklist,
                    updateChecklistPathParams
                );

                const updateResult = updateChecklistResponse.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAProblemUpdatingTheChecklist"));
                return false;
            }
            return true;
        };

        const cancelledByText = useMemo(() => {
            if (event?.canceledById == null) {
                return undefined;
            }

            const cancelledOn = DateUtils.formatDateTime(true, event?.canceledOn?.toString(), true);
            const cancelledByName = event.canceledBy?.getFirstAndLastName();
            const cancelledById = currentUserIsInNfpaRole ? `#${event.canceledById}` : "";

            return t("cancelledonBYCancelledByNameCancelledById", {
                cancelledOn: cancelledOn,
                cancelledByName: cancelledByName,
                cancelledById: cancelledById,
            });
        }, [event.canceledById, event?.canceledOn, event.canceledBy, currentUserIsInNfpaRole]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.EventDetails} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("aboutThisTraining")}
                    </Heading>
                </div>
                {notifications != null && (
                    <div className={`${CSS_CLASS_NAME}__notifications`}>
                        {notifications.map((notification, index) => (
                            <NotificationBanner
                                key={`${notification.id}-${index}`}
                                icon={notification.getNotificationIcon()}
                                linkPath={getEventTrainingPath(notification.eventId!)}
                                linkText={t("viewEvent")}
                                message={notification.message}
                                notificationId={notification.id!}
                                onClose={() => handleNotificationClose(notification.id)}
                                style={notification.notificationType}
                            />
                        ))}
                    </div>
                )}
                {event.status === PublishStatus.Canceled && (
                    <div className={`${CSS_CLASS_NAME}__cancellation-banner`}>
                        <Banner style={BannerStyle.Error} format={BannerFormat.Default}>
                            <Icon type={Icons.CalendarBusy} size={IconSizes.Large} />
                            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                {t("thisTrainingHasBeenCanceled")}
                            </Heading>
                            <Paragraph style={ParagraphStyle.Default} size={ParagraphSize.Small}>
                                {event.cancellationMessage}
                            </Paragraph>
                            {!isInstructor && (
                                <Paragraph style={ParagraphStyle.Label} size={ParagraphSize.Small}>
                                    <strong>{cancelledByText?.toUpperCase()}</strong>
                                </Paragraph>
                            )}
                        </Banner>
                    </div>
                )}

                {isThirdPartyProvider && event?.contract != null && (
                    <div className={`${CSS_CLASS_NAME}__contract`}>
                        <Heading
                            cssClassName={`${CSS_CLASS_NAME}__heading`}
                            size={HeadingSize.XSmall}
                            priority={HeadingPriority.H5}>
                            {t("contractInformation")}
                        </Heading>
                        <ContractCard contract={event?.contract} stacked={true} />
                    </div>
                )}

                {event.status !== PublishStatus.Canceled && (
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}></Heading>
                    </div>
                )}

                <div className={`${CSS_CLASS_NAME}__details`}>
                    <div
                        className={`${CSS_CLASS_NAME}__about ${isInstructor ? "-instructor" : ""}`}>
                        <EventDescriptionCard
                            product={event?.product}
                            productVersionNumber={event?.productVersion?.versionId}
                            stacked={true}
                        />
                    </div>
                    {isInstructor && (
                        <div className={`${CSS_CLASS_NAME}__checklist`}>
                            <EventDetailsChecklist
                                event={event}
                                onChecklistChange={handleChecklistChange}
                                stacked={true}
                            />
                        </div>
                    )}
                    <div className={`${CSS_CLASS_NAME}__schedule`}>
                        <EventScheduleCard
                            event={event}
                            schedule={event.eventDays}
                            stacked={true}
                        />
                    </div>
                    {!event.hideLocationInformation && (
                        <div className={`${CSS_CLASS_NAME}__location`}>
                            <EventLocationCard event={event} stacked={true} />
                        </div>
                    )}
                    <div className={`${CSS_CLASS_NAME}__hr`}></div>
                    <div className={`${CSS_CLASS_NAME}__contact`}>
                        <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                            {t("contact")}
                        </Heading>
                        <ContactCardList
                            avatarFileId={event.instructor?.instructorProfile?.avatarFileId}
                            stacked={true}
                            showHostCard={!event.hideContactInformation}
                            showInstructorCard={true}
                            hostCompanyEmail={event.getHostEmailAddress()}
                            hostCompanyName={event.getHostSupportName()}
                            hostCompanyPhone={event.getHostPhone()}
                            instructorId={event.instructorId}
                            instructorCompanyEmail={
                                event.instructor?.instructorProfile ? event.instructor.email : ""
                            }
                            instructorCompanyName={event.instructor?.getFirstAndLastName()}
                            instructorCompanyPhone={
                                event.instructor?.instructorProfile
                                    ? event.instructor.phoneNumber
                                    : ""
                            }
                        />
                    </div>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventDetailsPage };

// #endregion Exports
