import React from "react";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { Paragraph } from "components/typography/paragraph/paragraph";
import {
    InstructorApplicationStatus,
    InstructorApplicationStatusBarLocation,
} from "models/enumerations/aen-applications/instructor-application-status";
import {
    InstructorApplicationProfileStatus,
    InstructorApplicationStatusBarTitle,
    InstructorApplicationStatusBarDescription,
} from "models/enumerations/aen-applications/instructor-application-profile-status";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import "./instructor-application-status-bar-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorApplicationStatusBarCardProps {
    statusOfCard: InstructorApplicationProfileStatus;
    applicationStatus: InstructorApplicationStatus;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "instructor-application-status-bar-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorApplicationStatusBarCard: React.FC<InstructorApplicationStatusBarCardProps> = ({
    statusOfCard,
    applicationStatus,
}): JSX.Element => {
    const isActiveCard = InstructorApplicationStatusBarLocation[applicationStatus] === statusOfCard;
    const isCompletedCard =
        InstructorApplicationStatusBarLocation[applicationStatus] > statusOfCard;

    const icon: Icons = isCompletedCard
        ? Icons.Checkmark
        : isActiveCard
        ? Icons.ArrowDownThick
        : Icons.Empty;
    const title: TranslatedCopy = InstructorApplicationStatusBarTitle[statusOfCard];
    const description: TranslatedCopy = InstructorApplicationStatusBarDescription[statusOfCard];

    return (
        <div className={`${CSS_CLASS_NAME} ${isActiveCard ? "" : "-disabled"}`}>
            <div className={`${CSS_CLASS_NAME}__icon`}>
                <Icon size={IconSizes.Large} cssClassName={`${CSS_CLASS_NAME}__svg`} type={icon} />
            </div>
            <Heading priority={HeadingPriority.H5} cssClassName={`${CSS_CLASS_NAME}__title`}>
                {t(title)}
            </Heading>
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__description`}>{t(description)}</Paragraph>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationStatusBarCard };

// #endregion Exports
