import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { Permission } from "utilities/enumerations/authorization/permission";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const NfpaAdministratorAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,
    // Pages
    [AccessControlKeys.AENProductCatalogPage]: Permission.Allow,
    [AccessControlKeys.AdminAenApplicationFiles]: Permission.Allow,
    [AccessControlKeys.AdminAenApplicationLogs]: Permission.Allow,
    [AccessControlKeys.AdminAenInstructorApplications]: Permission.Allow,
    [AccessControlKeys.AdminAenProviderApplications]: Permission.Allow,
    [AccessControlKeys.AdminAenResourcesListPage]: Permission.Allow,
    [AccessControlKeys.AdminAnnouncementsListPage]: Permission.Allow,
    [AccessControlKeys.AdminCatalogEnrollmentLogPage]: Permission.Allow,
    [AccessControlKeys.AdminCourseListPage]: Permission.Allow,
    [AccessControlKeys.AdminDashboardPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventChangeLogPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventInstructorPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventLocationPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventRegistrationPage]: Permission.Allow,
    [AccessControlKeys.AdminEditEventSchedulePage]: Permission.Allow,
    [AccessControlKeys.AdminEventListPage]: Permission.Allow,
    [AccessControlKeys.AdminProductEnrollmentPage]: Permission.Allow,
    [AccessControlKeys.AdminProductListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsDownloadsPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsSetupPage]: Permission.Allow,
    [AccessControlKeys.CreateCourseMaterialsPage]: Permission.Allow,
    [AccessControlKeys.EditCourseDetailsPage]: Permission.Allow,
    [AccessControlKeys.EditCourseMaterialsPage]: Permission.Allow,
    [AccessControlKeys.EditCourseVersionListPage]: Permission.Allow,
    [AccessControlKeys.EditAssessmentProductMaterialsPage]: Permission.Allow,
    [AccessControlKeys.InstructorApplicationAssessmentPage]: Permission.Allow,
    [AccessControlKeys.InstructorDirectoryPage]: Permission.Allow,
    [AccessControlKeys.LearnerAssessmentPage]: Permission.Allow,
    [AccessControlKeys.ManageEventAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAssessmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceDayPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceSummaryPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventContentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEnrollmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEvaluationsPage]: Permission.Allow,
    [AccessControlKeys.SendAnEmailPage]: Permission.Allow,
    [AccessControlKeys.UserBasicInfoPage]: Permission.Allow,
    [AccessControlKeys.UserContractsILLDetailPage]: Permission.Allow,
    [AccessControlKeys.UserContractsILLListPage]: Permission.Allow,
    [AccessControlKeys.UserContractsInfoPage]: Permission.Allow,
    [AccessControlKeys.UserContractsListPage]: Permission.Allow,
    [AccessControlKeys.UserContractsNewPage]: Permission.Allow,
    [AccessControlKeys.UserContractsOLLDetailPage]: Permission.Allow,
    [AccessControlKeys.UserContractsOLLListPage]: Permission.Allow,
    [AccessControlKeys.UserContractsOLLNewPage]: Permission.Allow,
    [AccessControlKeys.UserENInstructorPage]: Permission.Allow,
    [AccessControlKeys.UserListPage]: Permission.Allow,
    [AccessControlKeys.UserNewPage]: Permission.Allow,
    [AccessControlKeys.UserProviderInfoPage]: Permission.Allow,
    [AccessControlKeys.UserProvidersAssociatedUsersPage]: Permission.Allow,
    [AccessControlKeys.UserProvidersListPage]: Permission.Allow,
    [AccessControlKeys.UserProvidersNewPage]: Permission.Allow,
    [AccessControlKeys.UserTrainingAssessmentPage]: Permission.Allow,
    [AccessControlKeys.UserTrainingDetailPage]: Permission.Allow,
    [AccessControlKeys.UserTrainingsPage]: Permission.Allow,

    // Groups
    [AccessControlKeys.GroupAssociatedUsersPage]: Permission.Allow,
    [AccessControlKeys.GroupsNewPage]: Permission.Allow,
    [AccessControlKeys.GroupInfoPage]: Permission.Allow,
    [AccessControlKeys.GroupsListPage]: Permission.Allow,
    [AccessControlKeys.GroupsAssociatedUsersPage]: Permission.Allow,

    // Actions
    [AccessControlKeys.CanChangeProviderStatus]: Permission.Allow,
    [AccessControlKeys.CanDownloadCertificates]: Permission.Allow,
    [AccessControlKeys.CanDownloadEnrollmentReport]: Permission.Allow,
    [AccessControlKeys.CanModifyProducts]: Permission.Allow,
    [AccessControlKeys.CanUseAllTranscriptReportFilters]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NfpaAdministratorAuthorizationRules };

// #endregion Exports
