import React, { useState } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonStyle } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { DataTable } from "components/tables/data-table/data-table";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { Icon } from "components/icons/icon";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { AddLearnerModal } from "../../../../../../components/user-management/contracts/add-learner-modal/add-learner-modal";
import { EditLearnerModal } from "../../../../../../components/user-management/contracts/edit-learner-modal/edit-learner-modal";
import { ImportEnrollmentModal } from "../../../../../../components/user-management/contracts/import-enrollment-modal/import-enrollment-modal";
import { t } from "utilities/localization/t";
import "./user-contract-ill-detail-enrollment.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractILLDetailEnrollmentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contract-ill-detail-enrollment";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractILLDetailEnrollment: React.FC<UserContractILLDetailEnrollmentProps> = (
    props: UserContractILLDetailEnrollmentProps
): JSX.Element => {
    const [showAddLearnerModal, setShowAddLearnerModal] = useState(false);
    const [showEditLearnerModal, setShowEditLearnerModal] = useState(false);
    const [showImportEnrollmentModal, setShowImportEnrollmentModal] = useState(false);

    const onEditLearnerClick = (userId: number) => {
        setShowEditLearnerModal(true);
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <div className={`${CSS_CLASS_NAME}__header__title`}>
                        <h3>{t("enrollment")}</h3>
                        <Badge style={BadgeStyle.TransparentAlt}>40/50</Badge>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__header__actions`}>
                        <ButtonIcon
                            ariaLabelledBy={t("addLeaner")}
                            buttonStyle={ButtonStyle.Primary}
                            iconType={Icons.Plus}
                            onClick={() => setShowAddLearnerModal(true)}
                            text={t("addLeaner")}
                        />
                        <ButtonIcon
                            ariaLabelledBy={t("importCSV")}
                            buttonStyle={ButtonStyle.Primary}
                            iconType={Icons.Upload}
                            onClick={() => setShowImportEnrollmentModal(true)}
                            text={t("importCSV")}
                        />
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__table`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="account">
                                    {t("account")}{" "}
                                    <Tooltip
                                        content={
                                            "Lorem ipsum dolor sit amet consectetur. Facilisis lacus id sagittis justo. Lorem ipsum dolor sit amet consectetur. Facilisis lacus id sagittis justo."
                                        }
                                        placement={TooltipPlacement.Top}>
                                        <span>
                                            <Icon type={Icons.Information} />
                                        </span>
                                    </Tooltip>
                                </th>
                                <th className="enrollment">{t("enrollment")}</th>
                                <th className="assessments">{t("assessments")}</th>
                                <th className="evaluation">{t("evaluation")}</th>
                                <th className="attendance">{t("attendance")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Error}>{t("withdrawn")}</Badge>
                                </td>
                                <td className="assessments">&nbsp;</td>
                                <td className="evaluation">&nbsp;</td>
                                <td className="attendance">&nbsp;</td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Success}>{t("enrolled")}</Badge>
                                </td>
                                <td className="assessments">
                                    <Badge style={BadgeStyle.Default}>Not Started</Badge>
                                </td>
                                <td className="evaluation">
                                    <Badge style={BadgeStyle.Default}>Not Started</Badge>
                                </td>
                                <td className="attendance">
                                    <span>3/3</span>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Success}>{t("enrolled")}</Badge>
                                </td>
                                <td className="assessments">
                                    <Badge style={BadgeStyle.Warning}>Attempted</Badge>
                                </td>
                                <td className="evaluation">
                                    <Badge style={BadgeStyle.Warning}>In Progress</Badge>
                                </td>
                                <td className="attendance">
                                    <span>3/3</span>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Success}>{t("enrolled")}</Badge>
                                </td>
                                <td className="assessments">
                                    <Badge style={BadgeStyle.Success}>Completed</Badge>
                                </td>
                                <td className="evaluation">
                                    <Badge style={BadgeStyle.Success}>Submitted</Badge>
                                </td>
                                <td className="attendance">
                                    <span>3/3</span>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Success}>{t("enrolled")}</Badge>
                                </td>
                                <td className="assessments">
                                    <Badge style={BadgeStyle.Inverted}>Expired</Badge>
                                </td>
                                <td className="evaluation">
                                    <Badge style={BadgeStyle.Default}>Not Started</Badge>
                                </td>
                                <td className="attendance">
                                    <span>3/3</span>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                            <tr>
                                <td className="account">
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        Samantha Prestifillippo
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        SAMANTHA.PRESTIFILLIPPO@EMAILEMAIL.COM
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>12345</Paragraph>
                                </td>
                                <td className="enrollment">
                                    <Badge style={BadgeStyle.Error}>{t("withdrawn")}</Badge>
                                </td>
                                <td className="assessments">&nbsp;</td>
                                <td className="evaluation">&nbsp;</td>
                                <td className="attendance">&nbsp;</td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("editLearner")}
                                            onClick={() => onEditLearnerClick(123)}
                                        />
                                        <ContextMenuButton
                                            displayName={t("downloadCertificate")}
                                            onClick={() => {}}
                                        />
                                        <ContextMenuButton
                                            disabled={true}
                                            displayName={t("withdrawLearner")}
                                            onClick={() => {}}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            </div>
            <AddLearnerModal open={showAddLearnerModal} setOpen={setShowAddLearnerModal} />
            <EditLearnerModal open={showEditLearnerModal} setOpen={setShowEditLearnerModal} />
            <ImportEnrollmentModal
                open={showImportEnrollmentModal}
                setOpen={setShowImportEnrollmentModal}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractILLDetailEnrollment };

// #endregion Exports
