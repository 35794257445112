import React, { useMemo } from "react";
import {
    AnchorIcon,
    AnchorIconPosition,
} from "components/typography/anchors/anchor-icon/anchor-icon";
import { EvaluationManager } from "components/evaluations/evaluation-manager/evaluation-manager";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";
import { useEvent } from "utilities/contexts/use-event-context";
import { useParams } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import "./manage-event-evaluation-preview-page.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageEventEvaluationPreviewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "manage-event-evaluation-preview-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageEventEvaluationPreviewPage: React.FC<
    ManageEventEvaluationPreviewPageProps
> = (): JSX.Element => {
    const { id: eventIdParam } = useParams();
    const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
    const { record: event } = useEvent();
    const evaluationTemplateId = event.product?.evaluationTemplateId ?? 0;

    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const evaluationSummaryPath: string = useMemo(
        () =>
            RouteUtils.replacePathParams(viewEventRoutes.evaluationSummary, {
                id: eventId,
            }),
        [eventId, viewEventRoutes.evaluationSummary]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__back-link`}>
                <AnchorIcon
                    iconPosition={AnchorIconPosition.Right}
                    iconType={Icons.Return}
                    path={evaluationSummaryPath}
                    size={IconSizes.Large}
                    text={t("backToEvaluationSummary")}
                />
            </div>
            <EvaluationManager
                title={t("previewEvaluation")}
                evaluationTemplateId={evaluationTemplateId}
                readOnly={true}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageEventEvaluationPreviewPage };

// #endregion Exports
