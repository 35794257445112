import { RoleType } from "models/enumerations/users/role-type";
import React, { useEffect } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HomePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HomePage: React.FC<HomePageProps> = (): null => {
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();

    useEffect(() => {
        let path: string;

        if (!globalState.isAuthenticated()) {
            path = sitemap.public.userLogin;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.Learner) {
            path = sitemap.learner.dashboard;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.NfpaOperator) {
            path = sitemap.nfpaOperators.dashboard;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.ClientAdmin) {
            path = sitemap.thirdPartyProvider.dashboard.online;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.Instructor) {
            path = sitemap.instructor.dashboard;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.AenAdministrator) {
            path = sitemap.aenProvider.dashboard;
        } else if (globalState?.currentIdentity?.role?.roleType === RoleType.NfpaSupport) {
            path = sitemap.nfpaSupport.dashboard;
        } else {
            path = sitemap.admin.dashboard;
        }
        navigate(path);
    }, [globalState, navigate]);

    return null;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HomePage };

// #endregion Exports
