import AlertLevels from "models/enumerations/alert-levels/alert-levels";
import AlertNotice from "components/alert-notice/alert-notice";
import ProgressBar, { ProgressBarStyles } from "components/progress/progress-bar";
import logo from "assets/images/nfpa-logo.svg";
import useAutoPageRefresh from "utilities/hooks/use-auto-page-refresh";
import useMaintenanceModeMessage from "utilities/hooks/use-maintenance-mode-message";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./maintenance-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "maintenance-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MaintenancePage: React.FC<any> = (props: any): JSX.Element => {
    const { maintenanceModeMessage, hasAutoRefresh, timeBetweenRefreshes } =
        useMaintenanceModeMessage();
    const { timeUntilNextRefreshAsPercent, timeUntilNextRefresh } = useAutoPageRefresh({
        isActive: hasAutoRefresh,
        timeBetweenRefreshesInSeconds: timeBetweenRefreshes,
    });

    return (
        <div className={CSS_CLASS_NAME}>
            <header className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header-logo`}>
                    <a href={RouteUtils.localizePath(sitemap.root)}>
                        <img src={logo} alt={t("nationalFireProtectionAssociation")} />
                    </a>
                </div>
                <div className={`${CSS_CLASS_NAME}__header-text`}>
                    <Paragraph>{t("learningPortal")}</Paragraph>
                </div>
            </header>
            <div className={`${CSS_CLASS_NAME}__body`}>
                <AlertNotice
                    description={maintenanceModeMessage?.content}
                    level={AlertLevels.Error}
                    title={maintenanceModeMessage?.title}>
                    {hasAutoRefresh && (
                        <>
                            {timeUntilNextRefresh ? (
                                <Paragraph>
                                    {t("refreshingInNSeconds", {
                                        timeInSeconds: timeUntilNextRefresh,
                                    })}
                                </Paragraph>
                            ) : (
                                <Paragraph>{t("refreshing")}</Paragraph>
                            )}
                            <ProgressBar
                                style={ProgressBarStyles.Thick}
                                cssClassName="-error"
                                value={timeUntilNextRefreshAsPercent}
                            />
                        </>
                    )}
                </AlertNotice>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default MaintenancePage;

// #endregion Exports
