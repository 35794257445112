import React from "react";
import ChangesBanner from "components/changes-banner/changes-banner";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-activation-instructor-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationInstructorSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-instructor-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationInstructorSection: React.FC<EventActivationInstructorSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            {!editMode && (
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("instructor")}
                </Heading>
            )}
            {editMode && (
                <>
                    <h3>{t("instructor")}</h3>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            <DataTable>
                <thead>
                    <tr>
                        <th className="-name">{t("name")}</th>
                        <th className="-location">{t("location")}</th>
                        <th className="-availability-confirmed">{t("availabilityConfirmed")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={event.productId}>
                        <td className="-name">{event.instructor?.displayName()}</td>
                        <td className="-location">{t("someLocation").toLocaleLowerCase()}</td>
                        <td className="-availability-confirmed">
                            {event.instructorConfirmed ? t("yes") : t("no")}
                        </td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationInstructorSection };

// #endregion Exports
