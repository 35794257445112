import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentProgressStatus {
    NotStarted = 0,
    InProgress = 1,
    Complete = 2,
    Incomplete = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EnrollmentProgressStatusDisplayNames: Map<EnrollmentProgressStatus, TranslatedCopy> = {
    [EnrollmentProgressStatus.NotStarted]: "notStarted",
    [EnrollmentProgressStatus.InProgress]: "inProgress",
    [EnrollmentProgressStatus.Complete]: "completed",
    [EnrollmentProgressStatus.Incomplete]: "incomplete",
};

const EnrollmentProgressStatusStatusMap: Map<EnrollmentProgressStatus, Status> = {
    [EnrollmentProgressStatus.NotStarted]: Status.Default,
    [EnrollmentProgressStatus.InProgress]: Status.Warning,
    [EnrollmentProgressStatus.Complete]: Status.Success,
    [EnrollmentProgressStatus.Incomplete]: Status.Default,
};

// #endregion Maps
export {
    EnrollmentProgressStatus,
    EnrollmentProgressStatusDisplayNames,
    EnrollmentProgressStatusStatusMap,
};

// #endregion Exports
