import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";
import { PatchProviderApplicationRecord } from "models/view-models/aen-applications/patch-provider-application-record";
import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import { ServiceHookFactory } from "../service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "aenapplication/providers";
const resourceType: typeof ProviderApplicationRecord = ProviderApplicationRecord;
const patchResourceType: typeof PatchProviderApplicationRecord = PatchProviderApplicationRecord;
const resourceEndpoint: string = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListProviderApplicationQueryParams {
    searchText?: string;
    status?: ProviderApplicationStatus;
    assignedToId?: number;
    skip?: number;
    take?: number;
}

export interface PatchProviderApplicationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProviderApplicationService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        ProviderApplicationRecord,
        ListProviderApplicationQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatch: ServiceHookFactory.usePatch<
        PatchProviderApplicationRecord,
        PatchProviderApplicationPathParams,
        PatchProviderApplicationRecord
    >(patchResourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderApplicationService };

// #endregion Exports
