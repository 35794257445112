import React, { useCallback, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContentRecord } from "models/view-models/contents/content-record";
import {
    ContentService,
    UpdateContentPathParams,
} from "utilities/services/contents/content-service";
import { InstructorLedProductContentList } from "../instructor-led-product-content-list/instructor-led-product-content-list";
import { Modal, ModalAction } from "components/modal/modal";
import { OnlineProductContentList } from "../online-product-content-list/online-product-content-list";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import {
    ProductContentService,
    UpdateProductContentPathParams,
} from "utilities/services/products/product-content-service";
import { ProductCreateContentModal } from "../product-create-content-modal/product-create-content-modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductContentListManagerProps {
    fetchProductContents: () => Promise<void>;
    product: ProductRecord;
    productContents?: ProductContentRecord[];
    onProductVersionContentsChange: (productContents: ProductContentRecord[]) => void;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductContentListManager: React.FC<ProductContentListManagerProps> = ({
    fetchProductContents,
    product,
    productContents,
    onProductVersionContentsChange,
    setDirty,
}) => {
    const hasProductContents = productContents != null && productContents.length > 0;
    const noDataCellClassName = hasProductContents ? "" : "hidden";
    const [showModal, setShowModal] = useState(false);
    const isInstructorLed = product.type === TrainingType.InstructorLedTraining;
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const { update: updateContentApi } = ContentService.useUpdate();
    const { create: apiContentCreate } = ContentService.useCreate();
    const { update: updateProductContentApi } = ProductContentService.useUpdate();
    const { delete: deleteApi } = ProductContentService.useDelete();
    const [productContentToDelete, setProductContentToDelete] = useState<ProductContentRecord>(
        new ProductContentRecord()
    );
    const [
        showProductContentDeletionConfirmationModal,
        setShowProductContentDeletionConfirmationModal,
    ] = useState(false);
    const [productContentToEdit, setProductContentToEdit] = useState<ProductContentRecord>();
    const [selectedProductContentIndex, setSelectedProductContentIndex] = useState<number>(0);
    const deferSave = product.status === ActiveStatus.Active;

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowProductContentDeletionConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleProductContentDelete(),
            style: ButtonStyle.Destructive,
        },
    ];

    const handleContentToEdit = (
        productContent: ProductContentRecord,
        selectedProductContentIndex: number
    ) => {
        setProductContentToEdit(productContent);
        setSelectedProductContentIndex(selectedProductContentIndex);
        setShowModal(true);
    };

    const confirmProductContentDeletion = (productContent: ProductContentRecord, index: number) => {
        setShowProductContentDeletionConfirmationModal(true);
        setProductContentToDelete(productContent);
    };

    const handleProductContentDelete = async (): Promise<void> => {
        if (deferSave) {
            setDirty && setDirty(true);
            //Remove the content from the productVersion:
            if (productContents == null) return;
            const contentToDelete = productContents?.find((pc) => pc === productContentToDelete);
            if (contentToDelete == null) return;
            const deletedContentIndex = productContents?.indexOf(contentToDelete);
            const removedSortOrder = contentToDelete.sortOrder;

            if (productContents?.length > 0) {
                productContents?.splice(deletedContentIndex, 1);
                onProductVersionContentsChange(productContents);
                //Resort:
                const updateProductContents = productContents?.map((pc, idx) => {
                    if (pc.sortOrder! > removedSortOrder!) {
                        return pc.with({ sortOrder: pc.sortOrder! - 1 });
                    }
                    return pc;
                });
                sortProductContents(updateProductContents);
                setShowProductContentDeletionConfirmationModal(false);
            }
            return;
        }

        const higherSortOrderProductContentsToUpdate = productContents?.slice(
            productContentToDelete.sortOrder
        );

        try {
            await deleteApi(productContentToDelete.id!);
            setShowProductContentDeletionConfirmationModal(false);

            const apiCalls: Promise<boolean>[] = [];

            higherSortOrderProductContentsToUpdate?.forEach((pc) =>
                apiCalls.push(moveUpProductContent(pc))
            );

            await Promise.all(apiCalls);

            fetchProductContents();
        } catch {
            ToastManager.error(t("thereWasAnIssueDeletingTheProductContent"));
        }
    };

    const productContentIsFirst = useCallback(
        (productContent: ProductContentRecord, access: ProductContentAccess): boolean => {
            if (CollectionUtils.isEmpty(productContents)) {
                return true;
            }
            if (!isInstructorLed) {
                return (
                    CollectionUtils.isEmpty(productContents) ||
                    productContent.sortOrder ===
                        Math.min(...productContents.map((u) => u.sortOrder!))
                );
            } else {
                const filterByAccessType = productContents?.filter((pc) => pc.access === access);

                return (
                    productContent.sortOrder ===
                    Math.min(...filterByAccessType.map((u) => u.sortOrder!))
                );
            }
        },
        [isInstructorLed, productContents]
    );

    const productContentIsLast = useCallback(
        (productContent: ProductContentRecord, access: ProductContentAccess): boolean => {
            if (CollectionUtils.isEmpty(productContents)) {
                return true;
            }

            if (!isInstructorLed) {
                return (
                    CollectionUtils.isEmpty(productContents) ||
                    productContent.sortOrder ===
                        Math.max(...productContents.map((u) => u.sortOrder!))
                );
            } else {
                const filterByAccessType = productContents?.filter((pc) => pc.access === access);

                return (
                    productContent.sortOrder ===
                    Math.max(...filterByAccessType.map((u) => u.sortOrder!))
                );
            }
        },
        [isInstructorLed, productContents]
    );

    const disableUpArrowButtons = useCallback(
        (productContent: ProductContentRecord, access: ProductContentAccess) => {
            return updateInProgress || productContentIsFirst(productContent, access);
        },
        [productContentIsFirst, updateInProgress]
    );

    const disableDownArrowButtons = useCallback(
        (productContent: ProductContentRecord, access: ProductContentAccess) => {
            return updateInProgress || productContentIsLast(productContent, access);
        },
        [updateInProgress, productContentIsLast]
    );

    const sortProductContents = (productContents: ProductContentRecord[]) => {
        if (isInstructorLed) {
            productContents
                ?.filter((pc) => pc.access === ProductContentAccess.InstructorProvider)
                .sort(
                    (a: ProductContentRecord, b: ProductContentRecord) =>
                        a.sortOrder! - b.sortOrder!
                );
            productContents
                ?.filter((pc) => pc.access === ProductContentAccess.Everyone)
                .sort(
                    (a: ProductContentRecord, b: ProductContentRecord) =>
                        a.sortOrder! - b.sortOrder!
                );
        } else {
            productContents.sort((a, b) => a.sortOrder! - b.sortOrder!);
        }
    };
    const moveDownProductContent = async (
        productContent: ProductContentRecord
    ): Promise<boolean> => {
        if (deferSave) {
            setDirty && setDirty(true);
            //swap the sort order of the productContent with the one above it:
            if (productContents == null) return Promise.resolve(false);

            //get the index of productContent
            const selectedProductContentRow = productContents.find((pc) => pc === productContent);
            if (selectedProductContentRow == null) return Promise.resolve(false);
            const selectedProductContentIndex = productContents?.indexOf(selectedProductContentRow);
            if (selectedProductContentIndex == null) return Promise.resolve(false);

            const swapWith = getProductContentBySortOrder(
                selectedProductContentRow.sortOrder! + 1,
                productContent.access!
            );
            const swapWithIndex = productContents.findIndex((pc) => pc === swapWith);
            const currentSortOrder = selectedProductContentRow.sortOrder;

            const updatedProductContents: ProductContentRecord[] = productContents.map(
                (pc, idx) => {
                    if (idx === selectedProductContentIndex) {
                        return pc.with({ sortOrder: currentSortOrder! + 1 });
                    }
                    if (idx === swapWithIndex) {
                        return pc.with({ sortOrder: currentSortOrder! });
                    }
                    return pc;
                }
            );
            //sortProductContents(updatedProductContents);
            onProductVersionContentsChange(updatedProductContents);
            return Promise.resolve(true);
        }
        return updateProductContentSortOrder(productContent, productContent.sortOrder! + 1);
    };

    const moveUpProductContent = (productContent: ProductContentRecord): Promise<boolean> => {
        if (deferSave) {
            setDirty && setDirty(true);
            //swap the sort order of the productContent with the one above it:
            if (productContents == null) return Promise.resolve(false);

            //get the index of productContent
            const selectedProductContentRow = productContents.find((pc) => pc === productContent);
            if (selectedProductContentRow == null) return Promise.resolve(false);
            const selectedProductContentIndex = productContents?.indexOf(selectedProductContentRow);
            if (selectedProductContentIndex == null) return Promise.resolve(false);

            const swapWith = getProductContentBySortOrder(
                selectedProductContentRow.sortOrder! - 1,
                productContent.access!
            );
            const swapWithIndex = productContents.findIndex((pc) => pc === swapWith);
            const currentSortOrder = selectedProductContentRow.sortOrder;

            const updatedProductContents: ProductContentRecord[] = productContents.map(
                (pc, idx) => {
                    if (idx === selectedProductContentIndex) {
                        return pc.with({ sortOrder: currentSortOrder! - 1 });
                    }
                    if (idx === swapWithIndex) {
                        return pc.with({ sortOrder: currentSortOrder! });
                    }
                    return pc;
                }
            );
            //updatedProductContents.sort((a, b) => a.sortOrder! - b.sortOrder!);
            onProductVersionContentsChange(updatedProductContents);
            return Promise.resolve(true);
        }

        return updateProductContentSortOrder(productContent, productContent.sortOrder! - 1);
    };

    const updateProductContentSortOrder = async (
        productContent: ProductContentRecord,
        sortOrder: number
    ): Promise<boolean> => {
        const updatedProductContents = productContent.with({
            sortOrder: sortOrder,
        });

        return await updateProductContent(updatedProductContents);
    };

    const updateProductContent = async (
        productContentWithChanges: ProductContentRecord
    ): Promise<boolean> => {
        setUpdateInProgress(true);

        const updateProductContentPathParams: UpdateProductContentPathParams = {
            id: productContentWithChanges.id!,
        };

        try {
            const updateProductContentResponse = await updateProductContentApi(
                productContentWithChanges,
                updateProductContentPathParams
            );

            setUpdateInProgress(false);

            const updateResult = updateProductContentResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheContent"));
            return false;
        }

        return true;
    };

    const getProductContentBySortOrder = (
        sortOrder: number,
        access: ProductContentAccess
    ): ProductContentRecord | undefined => {
        if (CollectionUtils.isEmpty(productContents)) {
            return undefined;
        }
        if (product.type === TrainingType.InstructorLedTraining) {
            return productContents.find((pc) => pc.sortOrder === sortOrder && pc.access === access);
        } else {
            return productContents.find((pc) => pc.sortOrder === sortOrder);
        }
    };

    const handleUpArrowClick = async (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ): Promise<void> => {
        if (productContentIsFirst(productContent, access)) {
            return;
        }

        const apiCalls: Promise<boolean>[] = [moveUpProductContent(productContent)];

        if (!deferSave) {
            const unitProductToMoveDown = getProductContentBySortOrder(
                productContent.sortOrder! - 1,
                access
            );

            if (unitProductToMoveDown != null) {
                apiCalls.push(moveDownProductContent(unitProductToMoveDown));
            }

            await Promise.all(apiCalls);
            if (!deferSave) {
                fetchProductContents();
            }
        }
    };

    const handleDownArrowClick = async (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ): Promise<void> => {
        if (productContentIsLast(productContent, access)) {
            return;
        }

        const apiCalls: Promise<boolean>[] = [moveDownProductContent(productContent)];

        if (!deferSave) {
            const unitProductToMoveUp = getProductContentBySortOrder(
                productContent.sortOrder! + 1,
                access
            );

            if (unitProductToMoveUp != null) {
                apiCalls.push(moveUpProductContent(unitProductToMoveUp));
            }

            await Promise.all(apiCalls);
            if (!deferSave) {
                fetchProductContents();
            }
        }
    };
    /*
    const getSelectedProductContents = (access: ProductContentAccess | undefined) => {
        if (productContents == null) return null;
        if (product.type === TrainingType.InstructorLedTraining) {
            if (access === ProductContentAccess.InstructorProvider) {
                const adminContent = productContents?.filter(
                    (pc) => pc.access === ProductContentAccess.InstructorProvider
                );
                return adminContent;
            } else if (access === ProductContentAccess.Everyone) {
                const participantContent = productContents?.filter(
                    (pc) => pc.access === ProductContentAccess.Everyone
                );
                return participantContent;
            } else {
                return productContents;
            }
        }
    };
    */
    const handleContentDetailsChange = async (
        content: ContentRecord,
        productContent?: ProductContentRecord
    ): Promise<boolean> => {
        setShowModal(false);
        if (productContents == null) return false;
        if (deferSave) {
            setDirty && setDirty(true);
            if (productContents == null) return false;

            const updatedProductContentRecord = productContents[selectedProductContentIndex];
            if (updatedProductContentRecord.id == null) {
                //You haven't saved this yet
                const createContentResponse = await updateContentApi(content);
                const updateProductContents = productContents?.map((pc, idx) => {
                    if (idx === selectedProductContentIndex) {
                        return pc.with({ content: content });
                    }
                    return pc;
                });
                onProductVersionContentsChange(updateProductContents!);
            } else {
                //Create a new content record:
                const createContentResponse = await apiContentCreate(content);

                const updateResult = createContentResponse.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }
                const newContent = updateResult.resultObject as ContentRecord;

                const updateProductContents = productContents?.map((pc, idx) => {
                    if (idx === selectedProductContentIndex) {
                        return pc.with({
                            id: 0,
                            contentId: newContent.id,
                            access: productContent?.access,
                            content: newContent.with({
                                file: content.file,
                            }),
                        });
                    }
                    return pc;
                });
                onProductVersionContentsChange(updateProductContents!);
            }
        } else {
            try {
                const updateContentPathParams: UpdateContentPathParams = {
                    id: content.id!,
                };

                const updateContentResponse = await updateContentApi(
                    content,
                    updateContentPathParams
                );

                const updateResult = updateContentResponse.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }

                fetchProductContents();
            } catch {
                ToastManager.error(t("thereWasAProblemUpdatingTheContent"));
                return false;
            }
        }
        return true;
    };

    return (
        <>
            {!isInstructorLed && (
                <OnlineProductContentList
                    confirmProductContentDeletion={confirmProductContentDeletion}
                    disableDownArrowButtons={disableDownArrowButtons}
                    disableUpArrowButtons={disableUpArrowButtons}
                    handleContentToEdit={handleContentToEdit}
                    handleDownArrowClick={handleDownArrowClick}
                    handleUpArrowClick={handleUpArrowClick}
                    hasProductContents={hasProductContents}
                    noDataCellClassName={noDataCellClassName}
                    productContents={productContents}
                    isTrainTheTrainerType={product.type === TrainingType.TrainTheTrainer}
                />
            )}
            {isInstructorLed && (
                <InstructorLedProductContentList
                    confirmProductContentDeletion={confirmProductContentDeletion}
                    disableDownArrowButtons={disableDownArrowButtons}
                    disableUpArrowButtons={disableUpArrowButtons}
                    handleContentToEdit={handleContentToEdit}
                    handleDownArrowClick={handleDownArrowClick}
                    handleUpArrowClick={handleUpArrowClick}
                    hasProductContents={hasProductContents}
                    noDataCellClassName={noDataCellClassName}
                    productContents={productContents}
                />
            )}
            {productContentToEdit !== undefined && (
                <ProductCreateContentModal
                    content={productContentToEdit?.content}
                    editing={true}
                    product={product}
                    productContent={productContentToEdit}
                    productId={product.id}
                    open={showModal}
                    setOpen={setShowModal}
                    handleContentDetailsChange={handleContentDetailsChange}
                />
            )}
            <Modal
                actions={confirmationActionArray}
                isOpen={showProductContentDeletionConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("areYouSureYouWantToRemoveProductContentToDeleteContentName", {
                    productContentToDeleteContentName: productContentToDelete.content?.name,
                })}
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductContentListManager };

// #endregion Exports
