import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import React from "react";
import { E164Number } from "libphonenumber-js/core";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";
import { InputTypes } from "components/form/enumerations/input-types";
import { StringUtils } from "utilities/string-utils";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./form-phone-input.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormPhoneInputProps extends FormFieldProps, InputProperties {
    errorMessage?: string;
    errorMessages?: string[];
    hasErrors?: boolean;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange: (value?: E164Number) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-phone-input";
const ERROR_CLASS_NAME: string = "-error";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormPhoneInput: React.FC<FormPhoneInputProps> = (props: FormPhoneInputProps) => {
    const { readOnly } = useReadOnly();

    const type = props.type ?? InputTypes.Text;
    const classNames: string[] = [CSS_CLASS_NAME];

    const isValid = (value: any): boolean => {
        return !StringUtils.hasValue(props.value) || isPossiblePhoneNumber(props.value);
    };

    if (StringUtils.hasValue(props.errorMessage) || !isValid(props.value)) {
        classNames.push(ERROR_CLASS_NAME);
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <FormField
            cssClassName={className}
            errorMessage={isValid(props.value) ? undefined : t("pleaseEnterAValidPhoneNumber")}
            errorMessages={props.errorMessages}
            formFieldName={props.formFieldName}
            label={props.label}
            required={props.required}>
            <PhoneInput
                autoFocus={props.autoFocus}
                className={`${CSS_CLASS_NAME}__input`}
                disabled={props.disabled || readOnly}
                id={props.formFieldName}
                name={props.formFieldName}
                onBlur={props.onBlur}
                onChange={props.onChange}
                placeholder={props.placeholder}
                required={props.required}
                type={type}
                value={props.value}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormPhoneInput };

// #endregion Exports
