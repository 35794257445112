import React from "react";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { NumberUtils } from "utilities/number-utils";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import RadioButton from "components/form/inputs/radio-button-input/radio-button/radio-button";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-registration-form-en.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventRegistrationFormEnProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-registration-form-en";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventRegistrationFormEn: React.FC<EventRegistrationFormEnProps> = (
    props: EventRegistrationFormEnProps
): JSX.Element => {
    const handlePublishOnCalendarToggleChange = (publishOnCalendar: boolean): void => {
        const values = {
            publishOnNFPACalendar: publishOnCalendar,
            maxRegistrations: publishOnCalendar ? props.event.maxRegistrations : undefined,
            registrationUrl: publishOnCalendar ? props.event.registrationUrl : "",
            additionalRegistrationRequirements: publishOnCalendar
                ? props.event.additionalRegistrationRequirements
                : "",
        };

        if (props.event.isDraft()) {
            props.event.updateAndSave(values);
        } else {
            props.event.updateActiveRecord(values);
        }
    };

    const handleAddlRegReqsChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        props.event.updateActiveRecord({ additionalRegistrationRequirements: event.target.value });
    };

    const handleRegistrationUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ registrationUrl: event.target.value });
    };

    const handleMaxRegistrationsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({
            maxRegistrations: NumberUtils.parseInt(event.target.value),
        });
    };

    const handleBlur = (): void => {
        if (props.event.isDraft()) {
            props.event.save();
        }
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <h5>{`${t("wouldYouLikeThisEventPublishedInTheNFPACalendar")} *`}</h5>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <RadioButton
                        ariaLabelledBy="radio-button-no"
                        autoFocus={
                            props.event.publishOnNFPACalendar == null
                                ? false
                                : !props.event.publishOnNFPACalendar
                        }
                        checked={
                            props.event.publishOnNFPACalendar == null
                                ? false
                                : !props.event.publishOnNFPACalendar
                        }
                        label={t("no")}
                        formFieldName={t("no")}
                        id="radio-button-no"
                        onChange={() => handlePublishOnCalendarToggleChange(false)}
                    />
                    <RadioButton
                        ariaLabelledBy="radio-button-yes"
                        autoFocus={
                            props.event.publishOnNFPACalendar == null
                                ? false
                                : props.event.publishOnNFPACalendar
                        }
                        checked={
                            props.event.publishOnNFPACalendar == null
                                ? false
                                : props.event.publishOnNFPACalendar
                        }
                        label={t("yes")}
                        formFieldName={t("yes")}
                        id="radio-button-yes"
                        onChange={() => handlePublishOnCalendarToggleChange(true)}
                    />
                </div>
                {!props.event.publishOnNFPACalendar && (
                    <div>
                        <h5>{t("registrationInformation")}</h5>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.Large}>
                            {t("thisRegistrationEventWillNotBeIncludedOnTheNFPACalendarWebsite")}
                        </Paragraph>
                    </div>
                )}
                {props.event.publishOnNFPACalendar && (
                    <div>
                        <h5>{t("registrationInformation")}</h5>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.Large}>
                            {t(
                                "thisRegistrationEventWillBePublishedAdvertisedOnTheNFPACalendarWebsiteAndWillBeVisibleToTheGeneralPublic"
                            )}
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__form`}>
                            <div className={`${CSS_CLASS_NAME}__form-column`}>
                                <FormTextInput
                                    ariaLabelledBy={t("maximumRegistrationLimit")}
                                    autoFocus={false}
                                    disabled={props.event.hasNoRegistrationLimit}
                                    formFieldName="maxRegistrations"
                                    id="maxRegistrations"
                                    label={t("maximumRegistrationLimit")}
                                    maxLength={32}
                                    onBlur={handleBlur}
                                    onChange={handleMaxRegistrationsChange}
                                    placeholder={t("enterMaximumRegistrationLimit")}
                                    type={InputTypes.Text}
                                    value={props.event.maxRegistrations}
                                />
                                <FormTextArea
                                    ariaLabelledBy={t("additionalRequirements")}
                                    autoFocus={false}
                                    disabled={props.event.hasNoRegistrationLimit}
                                    formFieldName="additionalRegistrationRequirements"
                                    id="additionalRegistrationRequirements"
                                    label={t("additionalRequirements")}
                                    onBlur={handleBlur}
                                    onChange={handleAddlRegReqsChange}
                                    placeholder={t("enterAdditionalRequirements")}
                                    type={InputTypes.Text}
                                    value={props.event.additionalRegistrationRequirements ?? ""}
                                />
                            </div>
                            <FormTextInput
                                required={props.event.publishOnNFPACalendar}
                                ariaLabelledBy={t("registrationURL")}
                                autoFocus={false}
                                formFieldName="maxRegistrations"
                                id="registrationUrl"
                                label={t("registrationURL")}
                                maxLength={2083}
                                onBlur={handleBlur}
                                onChange={handleRegistrationUrlChange}
                                placeholder={t("enterRegistrationURL")}
                                type={InputTypes.Text}
                                value={props.event.registrationUrl}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventRegistrationFormEn };

// #endregion Exports
