import React, { useCallback } from "react";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ReportsSidebar } from "components/reports/components/reports-sidebar/reports-sidebar";
import { ReportsHeader } from "components/reports/components/reports-header/reports-header";
import { Card } from "components/card/card";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { NavLink } from "react-router-dom";
import { useReportingContext } from "utilities/contexts/providers/reporting-context-provider";
import { t } from "utilities/localization/t";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { RouteUtils } from "utilities/route-utils";
import "./admin-reports-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminReportsListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-reports-list-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminReportsListPage: React.FC<AdminReportsListPageProps> = validatePageAccess(
    AccessControlKeys.AdminReportsListPage
)((): JSX.Element => {
    const { reports } = useReportingContext();
    useRedirectOnForbidden(sitemap.public.noAccess);

    const buildReportSetupPath = useCallback((reportKey: string): string => {
        const pathParams = { slug: reportKey };

        return RouteUtils.replacePathParams(sitemap.admin.reports.setup, pathParams);
    }, []);

    return (
        <div className={CSS_CLASS_NAME}>
            <ReportsHeader />
            <SideContentLeftLayout sidebarElement={<ReportsSidebar />}>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            <div className={CSS_CLASS_NAME}>
                                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                    {t("iWantToRunAReport")}
                                </Heading>
                                <div className={`${CSS_CLASS_NAME}__reports`}>
                                    {reports.map((report) => (
                                        <NavLink
                                            key={`report-${report.id}`}
                                            to={buildReportSetupPath(report.key)}>
                                            <Card>
                                                <Icon type={Icons.Reporting} />
                                                <Paragraph>{report.name}</Paragraph>
                                                <Paragraph style={ParagraphStyle.Light}>
                                                    {report.description}
                                                </Paragraph>
                                            </Card>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        </SkipNavContent>
                    </div>
                </div>
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminReportsListPage };

// #endregion Exports
