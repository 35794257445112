import { DateTime } from "luxon";
import { DateUtils } from "utilities/date-utils";
import { Organization } from "models/interfaces/organizations/organization";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Organization = {
    addressLineOne: undefined,
    addressLineTwo: undefined,
    city: undefined,
    country: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    email: undefined,
    events: undefined,
    id: undefined,
    locationName: undefined,
    name: "",
    nfpaId: undefined,
    phone: undefined,
    state: undefined,
    timeZone: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    zipCode: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class OrganizationRecord extends Record(defaultValues) implements Organization {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Organization>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Return formatted created on date
     * Ex: 12/31/2020
     * @returns Return formatted created on date
     */
    public formatCreatedOnDate(): string {
        return DateUtils.formatDate(true, this.createdOn);
    }

    /**
     * Return formatted phone number
     * Ex: (234) 567 - 9225
     * @returns Return formatted phone number
     */
    public formatPhoneNumber(): string {
        if (this.phone && this.phone.length === 12) {
            let format = this.phone.split("");
            format.splice(2, 0, "(");
            format.splice(6, 0, ") ");
            format.splice(10, 0, "-");

            return format.join("");
        } else if (this.phone && this.phone.length === 10) {
            let format = this.phone.split("");
            format.splice(0, 2);
            format.splice(3, 0, ") ");
            format.splice(7, 0, "-");

            return "(" + format.join("");
        }
        return "";
    }

    /**
     * Return formatted time zone
     * Ex: Eastern Standard Time (EST)
     * @returns Return formatted time zone
     */
    public formatTimeZone(): string {
        if (this.timeZone) {
            const timeZoneName = DateTime.fromObject({}, { zone: this.timeZone });
            return `${timeZoneName.offsetNameLong} (${timeZoneName.offsetNameShort})`;
        }
        return "";
    }

    /**
     * Given a set of values for Organization properties, create a new OrganizationRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Organization>} values The values to overwrite on this instance.
     * @returns A OrganizationRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Organization>): OrganizationRecord {
        return new OrganizationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OrganizationRecord };

// #endregion Exports
