import { t } from "./localization/t";
import { StringUtils } from "./string-utils";

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

/**
 * Return formatted phone number
 * Ex: (234) 567 - 9225
 * @returns Return formatted phone number
 */
const formatPhoneNumber = (value?: string): string => {
    if (value && value.length === 12) {
        let format = value.split("");
        format.splice(2, 0, "(");
        format.splice(6, 0, ") ");
        format.splice(10, 0, "-");

        return format.join("");
    } else if (value && value.length === 10) {
        let format = value.split("");
        format.splice(0, 2);
        format.splice(3, 0, ") ");
        format.splice(7, 0, "-");

        return "(" + format.join("");
    }
    return StringUtils.valueOrDefault(value, t("doubleDash"));
};

// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const PhoneNumberUtils = {
    formatPhoneNumber: formatPhoneNumber,
};

export { PhoneNumberUtils };

// #endregion Exports
