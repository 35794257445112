import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum PublishStatus {
    Completed = 0,
    Canceled = 1,
    Draft = 2,
    Live = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const PublishStatusDisplayNames: Map<PublishStatus, TranslatedCopy> = {
    [PublishStatus.Completed]: "completed",
    [PublishStatus.Canceled]: "canceled",
    [PublishStatus.Draft]: "draft",
    [PublishStatus.Live]: "live",
};

const PublishStatusMap: Map<PublishStatus, Status> = {
    [PublishStatus.Completed]: Status.Success,
    [PublishStatus.Canceled]: Status.Error,
    [PublishStatus.Draft]: Status.Default,
    [PublishStatus.Live]: Status.Success,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { PublishStatus, PublishStatusDisplayNames, PublishStatusMap };

// #endregion Exports
