import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventType } from "models/enumerations/events/event-type";
import { FormRadioButton } from "components/form/form-radio-button/form-radio-button";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import "./event-activation-registration-section.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationRegistrationSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-registration-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationRegistrationSection: React.FC<EventActivationRegistrationSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            {event.type === EventType.NFPACatalog && !editMode && (
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("registration")}
                    </Heading>
                    <ToggleLabel
                        checked={event.hasNoRegistrationLimit}
                        disabled={true}
                        id="had-no-registration-limits"
                        label={t("thisEVENTHASNOREGISTRATIONLIMITS")}
                    />
                </div>
            )}
            {event.type === EventType.NFPACatalog && editMode && (
                <>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <h3>{t("registration")}</h3>
                        <ToggleLabel
                            checked={event.hasNoRegistrationLimit}
                            disabled={true}
                            id="had-no-registration-limits"
                            label={t("thisEVENTHASNOREGISTRATIONLIMITS")}
                        />
                    </div>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            {event.type !== EventType.NFPACatalog && !editMode && (
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("registration")}
                </Heading>
            )}
            {event.type !== EventType.NFPACatalog && editMode && (
                <>
                    <h3>{t("registration")}</h3>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            {event.type === EventType.NFPACatalog && (
                <div className={`${CSS_CLASS_NAME}__catalog-container`}>
                    <div className={`${CSS_CLASS_NAME}__catalog-container-item`}>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("maximumRegistration").toLocaleUpperCase()}
                        </Paragraph>
                        <Paragraph>{event.maxRegistrations}</Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__catalog-container-item`}>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("additionalRequirements")}
                        </Paragraph>
                        <Paragraph>{event.additionalRegistrationRequirements}</Paragraph>
                    </div>
                </div>
            )}
            {event.type === EventType.Contract && (
                <Paragraph>
                    {t(
                        "registrationInformationDoesNotApplyForContractEventsToViewMoreInformationRegardingTheContractVisitTheEventDetailsTab"
                    )}
                </Paragraph>
            )}
            {event.type === EventType.EducationNetwork && (
                <div>
                    <Paragraph>{t("wouldYouLikeThisEventPublishedInTheNFPACalendar")}</Paragraph>
                    <div className={`${CSS_CLASS_NAME}__toggle-container`}>
                        <FormRadioButton
                            ariaLabelledBy="radio-button-no"
                            checked={!event.publishOnNFPACalendar}
                            disabled={true}
                            label={t("no")}
                            formFieldName={t("no")}
                            id="radio-button-no"
                            tabIndex={-1}
                        />
                        <FormRadioButton
                            ariaLabelledBy="radio-button-yes"
                            checked={event.publishOnNFPACalendar!}
                            disabled={true}
                            label={t("yes")}
                            formFieldName={t("yes")}
                            id="radio-button-yes"
                            tabIndex={-1}
                        />
                    </div>
                    {event.publishOnNFPACalendar && (
                        <div className={`${CSS_CLASS_NAME}__details`}>
                            <div className={`${CSS_CLASS_NAME}__details-row`}>
                                <div className={`${CSS_CLASS_NAME}__container-item`}>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {t("maximumRegistration").toLocaleUpperCase()}
                                    </Paragraph>
                                    <Paragraph>{event.maxRegistrations}</Paragraph>
                                </div>
                                <div className={`${CSS_CLASS_NAME}__container-item`}>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {t("registrationLink").toLocaleUpperCase()}
                                    </Paragraph>
                                    <Paragraph>{event.registrationUrl}</Paragraph>
                                </div>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__details-row`}>
                                <div className={`${CSS_CLASS_NAME}__container-item`}>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {t("additionalRequirements").toLocaleUpperCase()}
                                    </Paragraph>
                                    <Paragraph>
                                        {event.additionalRegistrationRequirements}
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationRegistrationSection };

// #endregion Exports
