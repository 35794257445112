import React from "react";
import "./quick-link-list.scss";
import QuickLink, { QuickLinkProps } from "../quick-link";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface QuickLinkListProps {
    links: QuickLinkProps[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "quick-link-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const QuickLinkList: React.FC<QuickLinkListProps> = (props: QuickLinkListProps) => {
    return (
        <div className={CSS_CLASS_NAME}>
            {props.links.map((link, index) => (
                <QuickLink
                    key={`quick-link-${index}`}
                    ariaLabel={link.ariaLabel}
                    icon={link.icon}
                    path={link.path}
                    pathType={link.pathType}
                    text={link.text}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default QuickLinkList;

// #endregion Exports
