import React, { useCallback, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import {
    ActiveStatus,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icons } from "components/icons/constants/icons";
import { InputTypes } from "components/form/enumerations/input-types";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductList } from "components/products/product-lists/product-list/product-list";
import { ProductModal } from "components/products/product-modal/product-modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductService } from "utilities/services/products/product-service";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useProducts } from "utilities/hooks/models/products/use-products";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import "./admin-product-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminProductListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-product-list-page";
const ITEMS_PER_PAGE = 20;
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminProductListPage: React.FC<AdminProductListPageProps> = validatePageAccess(
    AccessControlKeys.AdminProductListPage
)((): JSX.Element => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [showProductModal, setShowProductModal] = useState(false);
    const [associatedProducts, setAssociatedProducts] = useState<ProductRecord[]>([]);
    const [productType, setProductType] = useState<TrainingType>();
    const [language, setLanguage] = useState<Language>();
    const [topic, setTopic] = useState<Topic>();
    const [availableForAEN, setAvailableForAEN] = useState<boolean>(false);
    const [status, setStatus] = useState<ActiveStatus[]>([ActiveStatus.Active]);
    const { list: listProducts } = ProductService.useList();
    const canModifyProducts = useHasAccess(AccessControlKeys.CanModifyProducts);

    const { products, rowCount: totalNumberOfProducts } = useProducts({
        activeStatusFilter: status,
        applySearch: true,
        includeAvailableForAENOnly: !!availableForAEN,
        includeArchived: true,
        includeCreatedBy: true,
        includeLatestVersion: true,
        includeLastModifiedBy: true,
        language: language ?? undefined,
        searchText: debouncedSearchText,
        skip: (currentPage! - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        topic: topic,
        trainingType: productType,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const handleModalOpen = () => {
        setShowProductModal(true);
        fetchAssociatedProductList();
    };

    const fetchAssociatedProductList = useCallback(async (): Promise<void> => {
        try {
            const listProductsResponse = await listProducts({
                activeStatusFilter: [ActiveStatus.Active, ActiveStatus.Draft],
                trainingType: TrainingType.InstructorLedTraining,
            });

            if (
                listProductsResponse?.resultObjects == null ||
                listProductsResponse.results == null ||
                listProductsResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setAssociatedProducts(listProductsResponse.resultObjects);
        } catch {
            ToastManager.error(t("problemLoadingAssociatedProductsList"));
            setAssociatedProducts([]);
        }
    }, [listProducts]);

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleFilterByProductTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const productTypeIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(productTypeIndex) || productTypeIndex < 1) {
            setProductType(undefined);
        }

        setProductType(productTypeIndex);
        setCurrentPage(1);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const languageIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        setLanguage(languageIndex);
        setCurrentPage(1);
    };

    const handleFilterByTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const topic = NumberUtils.parseInt(event.target.value) ?? 0;
        setTopic(topic);
        setCurrentPage(1);
    };

    const handleSetAvailableForAEN = (value: boolean): void => {
        setAvailableForAEN(value);
        setCurrentPage(1);
    };

    const handleFilterByStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const activeStatusIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(activeStatusIndex) || activeStatusIndex < 1) {
            setStatus([ActiveStatus.Active]);
        }
        setStatus([activeStatusIndex]);
        setCurrentPage(1);
    };

    const handleClearAll = () => {
        setLanguage(undefined);
        setTopic(undefined);
        setAvailableForAEN(false);
        setProductType(undefined);
        setStatus([ActiveStatus.Active]);
        setSearchText("");
        setDebouncedSearchText("");
        setCurrentPage(1);
    };

    return (
        <>
            <HeaderBanner title={t("products")}>
                {canModifyProducts && (
                    <ButtonIcon
                        text={t("addNewProduct")}
                        onClick={() => handleModalOpen()}
                        buttonSize={ButtonSize.Medium}
                        buttonStyle={ButtonStyle.TertiaryAlt}
                        iconType={Icons.Plus}
                    />
                )}
            </HeaderBanner>
            <div className={`${CSS_CLASS_NAME}__window`}>
                <div className={`${CSS_CLASS_NAME}__list__view`}>
                    <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                        <div className={`${CSS_CLASS_NAME}__window__sidebar__filter`}>
                            <Paragraph
                                cssClassName={`${CSS_CLASS_NAME}__window__sidebar__filter__title`}
                                size={ParagraphSize.XLarge}
                                style={ParagraphStyle.Label}>
                                {t("filterResults")}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__window__sidebar__filter__selections`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id={"productSearch"}
                                    placeholder={t("searchByProductNameOrID")}
                                    searchTextInputValue={searchText}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByType")}
                                    formFieldName={t("filterByType")}
                                    id="filterByType"
                                    label=""
                                    placeholder={t("filterByType")}
                                    onChange={handleFilterByProductTypeChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        TrainingType,
                                        TrainingTypeDisplayNames
                                    )}
                                    value={productType?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByLanguage")}
                                    formFieldName={t("filterByLanguage")}
                                    id="instructorFilterByLanguage"
                                    label=""
                                    placeholder={t("filterByLanguage")}
                                    onChange={handleFilterByLanguageChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        Language,
                                        LanguageDisplayNames
                                    )}
                                    value={language?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByTopic")}
                                    formFieldName={t("filterByTopic")}
                                    id="instructorFilterByTopic"
                                    label=""
                                    placeholder={t("filterByTopic")}
                                    onChange={handleFilterByTopicChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        Topic,
                                        TopicDisplayNames
                                    )}
                                    value={topic?.toString()}
                                />
                                <FormSelect
                                    ariaLabelledBy={t("filterByStatus")}
                                    formFieldName={t("filterByStatus")}
                                    id="productFilterByStatus"
                                    label=""
                                    placeholder={t("status")}
                                    onChange={handleFilterByStatusChange}
                                    options={EnumUtils.numericEnumToSelectOptions(
                                        ActiveStatus,
                                        ActiveStatusStatusDisplayNames
                                    )}
                                    value={status?.toString()}
                                />
                                <FormCheckboxInput
                                    checked={availableForAEN}
                                    formFieldName="availableForAEN"
                                    id="HeaderCheckbox"
                                    label={t("availableForAen")}
                                    onChange={handleSetAvailableForAEN}
                                    type={InputTypes.Checkbox}
                                    value={availableForAEN}
                                />
                            </div>
                            <Button
                                onClick={handleClearAll}
                                size={ButtonSize.Medium}
                                style={ButtonStyle.Secondary}
                                text={t("clearAllFilters")}
                            />
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__window__main`}>
                        <div className={`${CSS_CLASS_NAME}__window__main__products`}>
                            <div className={`${CSS_CLASS_NAME}__window__main__products__content`}>
                                <SkipNavContent>
                                    <ProductList products={products} />
                                </SkipNavContent>
                            </div>
                        </div>
                        {CollectionUtils.hasValues(products) && (
                            <div className={`${CSS_CLASS_NAME}__window__main__pager`}>
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={totalNumberOfProducts / ITEMS_PER_PAGE}
                                    onPageClick={onPageClick}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={totalNumberOfProducts}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <ProductModal
                associatedProducts={associatedProducts}
                open={showProductModal}
                setOpen={setShowProductModal}
            />
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminProductListPage };

// #endregion Exports
