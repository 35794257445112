import React from "react";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { RouteUtils } from "utilities/route-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useMatches } from "react-router-dom";
import "./email-management-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EmailManagementSidebarProps {}

export interface EmailManagementSidebarNavItem {
    path: string;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "email-management-sidebar";
const navItems: EmailManagementSidebarNavItem[] = [
    {
        path: sitemap.admin.communications.sendAnEmail,
        text: "sendAnEmail",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EmailManagementSidebar: React.FC<EmailManagementSidebarProps> = (): JSX.Element => {
    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const match = matches.find((match) =>
            match.pathname?.toLowerCase().startsWith(path.toLowerCase())
        );
        return match != null;
    };

    return (
        <nav className={CSS_CLASS_NAME} aria-label={t("sidebar")}>
            {navItems.map((navItem) => (
                <Anchor
                    key={`nav-item-${navItem.text.replace(" ", "-")}`}
                    path={RouteUtils.localizePath(navItem.path)}
                    text={t(navItem.text)}
                    cssClassName={
                        findPathMatch(RouteUtils.localizePath(navItem.path)) ? "-selected" : ""
                    }
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EmailManagementSidebar };

// #endregion Exports
