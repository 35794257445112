// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const INVOICE_STATUS_TYPES = {
    NOT_INVOICED: "1",
    INVOICED: "2",
    NOT_INVOICED_AND_INVOICED: "3",
} as const;

const INVOICE_STATUS_TYPES_DESCRIPTIONS = {
    [INVOICE_STATUS_TYPES.NOT_INVOICED]: "notInvoiced",
    [INVOICE_STATUS_TYPES.INVOICED]: "invoiced",
    [INVOICE_STATUS_TYPES.NOT_INVOICED_AND_INVOICED]: "notInvoicedAndInvoiced",
} as const;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { INVOICE_STATUS_TYPES, INVOICE_STATUS_TYPES_DESCRIPTIONS };

// #endregion Exports
