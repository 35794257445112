import { useState } from "react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { EventOrganizationList } from "components/events/event-organization-selection/event-organization-list/event-organization-list";
import { EventOrganizationSelectionModal } from "components/events/event-organization-selection/event-organization-selection-modal/event-organization-selection-modal";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-organization-selection.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventOrganizationSelectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-organization-selection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventOrganizationSelection: React.FC<EventOrganizationSelectionProps> = (
    props: EventOrganizationSelectionProps
) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Card>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("organization")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__actions`}>
                        <Button
                            disabled={
                                props.event.organizationId != null ||
                                props.event.status === PublishStatus.Live
                            }
                            cssClassName={`${CSS_CLASS_NAME}__select-organization`}
                            onClick={() => setShowModal(true)}
                            style={ButtonStyle.Primary}
                            text={t("selectAnOrganization")}
                        />
                    </div>
                </div>
                <EventOrganizationList
                    organization={props.event.organization}
                    onReplace={() => setShowModal(true)}
                    disabled={props.event.status === PublishStatus.Live}
                />
            </Card>
            <EventOrganizationSelectionModal
                event={props.event}
                onOrganizationChange={props.event.patchEventOrganization}
                open={showModal}
                selectedOrganization={props.event.organization}
                setOpen={setShowModal}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventOrganizationSelection };

// #endregion Exports
