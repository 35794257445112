import React, { useCallback } from "react";
import { SeededUserLogin } from "components/seeded-user-login/seeded-user-login";
import { ToastManager } from "utilities/toast/toast-manager";
import "./user-login-page.scss";
import { LogInButton } from "components/log-in-button/log-in-button";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import { t } from "utilities/localization/t";
import { CreateAccountAnchor } from "components/create-account-anchor/create-account-anchor";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserLoginPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "user-login-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserLoginPage: React.FC<UserLoginPageProps> = (): JSX.Element => {
    const onError = useCallback(({ message }: Error) => {
        ToastManager.error(message);
    }, []);
    const { useAzureB2CForSSO } = useFeatureFlags();
    return (
        <div className={CSS_CLASS_NAME}>
            {useAzureB2CForSSO ? (
                <>
                    <Paragraph style={ParagraphStyle.Label}>{t("nfpaLearningPortal")}</Paragraph>
                    <Heading size={HeadingSize.Default} priority={HeadingPriority.H1}>
                        {t("advanceYourCareerWithTraining")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__actions`}>
                        <LogInButton cssClassName={`${CSS_CLASS_NAME}__button`} /> {t("or")}{" "}
                        <CreateAccountAnchor cssClassName={`${CSS_CLASS_NAME}__anchor`} />
                    </div>
                </>
            ) : (
                <SeededUserLogin onError={onError} />
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserLoginPage };

// #endregion Exports
