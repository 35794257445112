import React from "react";
import { useMatches } from "react-router-dom";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { sitemap } from "sitemap";
import { RouteUtils } from "utilities/route-utils";
import "./reports-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsSidebarProps {}

interface ReportsSidebarNavItem {
    path: string;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "reports-sidebar";

const navItems: ReportsSidebarNavItem[] = [
    {
        path: sitemap.admin.reports.list,
        text: "runAReport",
    },
    {
        path: sitemap.admin.reports.downloads,
        text: "reportDownloads",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportsSidebar: React.FC<ReportsSidebarProps> = (): JSX.Element => {
    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const pathWithCulture = RouteUtils.localizePath(path).toLowerCase();
        const match = matches.find((match) =>
            match.pathname?.toLowerCase().startsWith(pathWithCulture)
        );
        return match != null;
    };

    return (
        <nav className={CSS_CLASS_NAME} aria-label={t("sidebar")}>
            {navItems.map((navItem) => (
                <Anchor
                    key={`report-nav-item-${navItem.text.replace(" ", "-")}`}
                    path={navItem.path}
                    text={t(navItem.text)}
                    cssClassName={findPathMatch(navItem.path) ? "-selected" : "def"}
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportsSidebar };

// #endregion Exports
