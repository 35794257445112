import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ProductCourseType {
    CourseSeries = 0,
    LearningPath = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ProductCourseTypeDisplayNames: Map<ProductCourseType, TranslatedCopy> = {
    [ProductCourseType.CourseSeries]: "courseOrSeries",
    [ProductCourseType.LearningPath]: "learningPath",
};

const ProductCourseTypeBadgeDisplayNames: Map<ProductCourseType, TranslatedCopy> = {
    [ProductCourseType.CourseSeries]: "courseseries",
    [ProductCourseType.LearningPath]: "learningPath",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCourseType, ProductCourseTypeDisplayNames, ProductCourseTypeBadgeDisplayNames };

// #endregion Exports
