import { Culture } from "andculturecode-javascript-core";
import { LocalizationUtils as AndcultureCodeLocalizationUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Function
// -------------------------------------------------------------------------------------------------

const cultureFactory = <TResources>(code: string, translations: TResources): Culture<TResources> =>
    AndcultureCodeLocalizationUtils.cultureFactory(
        {
            code: code,
            resources: translations,
        },
        {}
    );

// #endregion Function

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { cultureFactory };

// #endregion Exports
