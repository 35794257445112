import React from "react";
import { TabProps } from "components/tabs/tabs/tab/tab";
import { Icons } from "components/icons/constants/icons";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CompletionTabProps extends TabProps {
    isComplete: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CompletionTab: React.FC<CompletionTabProps> = (props: CompletionTabProps): JSX.Element => {
    const icon: Icons = props.isComplete ? Icons.CircleChecked : Icons.CircleUnchecked;

    return <IconTab iconType={icon} {...props} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CompletionTab };

// #endregion Exports
