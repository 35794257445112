import { DateUtils } from "utilities/date-utils";
import { EventDayAttendance } from "models/interfaces/events/event-day-attendance";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventDayAttendance = {
    attended: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    eventDay: undefined,
    eventDayId: 0,
    id: 0,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventDayAttendanceRecord extends Record(defaultValues) implements EventDayAttendance {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventDayAttendance>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.eventDay != null) {
            params.eventDay = RecordUtils.ensureRecord(params.eventDay, EventDayRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return user
     * Ex: Steve Smith
     */
    public getLastUpdatedBy(): string {
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return t("notApplicableShort");
        }

        return updatedBy.getFirstAndLastName();
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getLastUpdatedDateAndTime(): string {
        const message = DateUtils.formatLastEditedDateTime(this, true, true);

        return message;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = DateUtils.formatLastEditedDateTime(this, true, false);
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return t("updatedbygetfirstandlastnameMessage", {
            updatedByGetFirstAndLastName: updatedBy.getFirstAndLastName(),
            message: message,
        });
    }

    /**
     * Convenience method to indicate if a record's attendance has not been entered
     */
    public hasIncompleteAttendanceRecord(): boolean {
        return this.attended == null;
    }

    /**
     * Given a set of values for EventDay properties, create a new EventDayRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventDay>} values The values to overwrite on this instance.
     * @returns A EventDayRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventDayAttendance>): EventDayAttendanceRecord {
        return new EventDayAttendanceRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayAttendanceRecord };

// #endregion Exports
