import { Card } from "components/card/card";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import React, { useMemo } from "react";
import { RoleType } from "models/enumerations/users/role-type";
import { FormSelect } from "components/form/form-select/form-select";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Language } from "models/enumerations/languages/language";
import { UserService, UpdateUserPathParams } from "utilities/services/users/user-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { UserRecord } from "models/view-models/users/user-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";
import { Arabic } from "cultures/arabic";
import { English } from "cultures/english";
import { Spanish } from "cultures/spanish";
import "./profile-account-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProfileAccountPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "profile-account-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProfileAccountPage: React.FC<ProfileAccountPageProps> = (): JSX.Element => {
    const { record: globalState, setRecord: setGlobalState } = useGlobalState();

    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );

    const nfpaExternalProfileURL = globalState.systemSettings!.getNfpaUrlV2(NfpaUrlPaths.MyProfile);

    const infoFooterText: string = useMemo(
        (): string => (userRole === RoleType.Learner ? t("certificateNameWarning") : ""),
        [userRole]
    );

    const { update: updateUserApi } = UserService.useUpdate();

    const updateUserPreferredLanguage = async (preferredLanguage?: Language): Promise<boolean> => {
        const userId = globalState?.currentIdentity?.user?.id;
        if (globalState?.currentIdentity?.user != null && userId == null) {
            return false;
        }

        const userWithChanges = globalState?.currentIdentity?.user!.with({
            preferredLanguage: preferredLanguage,
        });

        const updateUserPathParams: UpdateUserPathParams = {
            id: userId!,
        };

        try {
            const updateUserResponse = await updateUserApi(userWithChanges!, updateUserPathParams);

            const updateResult = updateUserResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            ToastManager.success(t("languageUpdatedSuccessfully"));
        } catch (e: any) {
            ToastManager.error(t("thereWasAnIssueChangingYourPreferredLanguage"));
            return false;
        }

        return true;
    };

    const handleLanguageChange = async (
        event: React.ChangeEvent<HTMLSelectElement>
    ): Promise<void> => {
        if (!globalState.currentIdentity?.hasUser()) {
            return;
        }

        const { language } = EnumUtils.enumToObjectPartial(
            event.target.value,
            (value) => ({ language: Number(value) }),
            Language
        );

        if (!(await updateUserPreferredLanguage(language))) {
            return;
        }

        const updatedUser: UserRecord = globalState.currentIdentity!.user!.with({
            preferredLanguage: language,
        });

        setGlobalState((currentState) =>
            currentState.setIdentity(globalState.currentIdentity!.with({ user: updatedUser }))
        );
    };

    const languageSelectOptions = useMemo(() => {
        return [
            {
                value: Language.Arabic.toString(),
                text: Arabic.resources.arabic,
            },
            {
                value: Language.English.toString(),
                text: English.resources.english,
            },
            {
                value: Language.Spanish.toString(),
                text: Spanish.resources.spanish,
            },
        ];
    }, []);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__details`}>
                <Card cssClassName={`${CSS_CLASS_NAME}__info`} flat={true}>
                    <div>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                            {t("basicInformation")}
                        </Heading>
                        <Paragraph style={ParagraphStyle.Label}>{t("name")}</Paragraph>
                        <Paragraph>
                            {globalState?.currentIdentity?.user?.firstName ??
                                t("notApplicableShort")}{" "}
                            {globalState?.currentIdentity?.user?.lastName ??
                                t("notApplicableShort")}
                        </Paragraph>
                        <Paragraph style={ParagraphStyle.Label}>{t("email")}</Paragraph>
                        <Paragraph>
                            {globalState?.currentIdentity?.user?.email ?? t("notApplicableShort")}
                        </Paragraph>
                        <Paragraph style={ParagraphStyle.Label}>{t("password")}</Paragraph>
                        <Paragraph>************</Paragraph>
                    </div>
                    <div>
                        <a href={nfpaExternalProfileURL} target="_blank" rel="noreferrer">
                            {t("manageYourProfile")}
                        </a>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__info__footer`}>
                        <Paragraph
                            size={ParagraphSize.XSmall}
                            style={ParagraphStyle.Light}>{`${infoFooterText}`}</Paragraph>
                    </div>
                </Card>
                <Card cssClassName={`${CSS_CLASS_NAME}__language`} flat={true}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                        {t("languageSelect")}
                    </Heading>
                    <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                        {t("languageSelect")}
                    </Paragraph>
                    <FormSelect
                        ariaLabelledBy={t("languageSelection")}
                        cssClassName={`${CSS_CLASS_NAME}__language`}
                        id="profileLanguageSelect"
                        label={t("userPreferredLanguage")}
                        labelScreenReaderOnly={true}
                        formFieldName="preferredLanguageSelect"
                        onChange={handleLanguageChange}
                        options={languageSelectOptions}
                        value={globalState?.currentIdentity?.user?.preferredLanguage?.toString()}
                    />
                </Card>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProfileAccountPage };

// #endregion Exports
