import React from "react";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { NumberUtils } from "utilities/number-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ProductVersion } from "models/interfaces/products/product-version";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCEUFormProps {
    productVersion: ProductVersionRecord;
    onProductVersionDetailsChange: (productVersion: ProductVersionRecord) => void;
    deferSave: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-ceus-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCEUsForm: React.FC<ProductCEUFormProps> = (
    props: ProductCEUFormProps
): JSX.Element => {
    const { update } = ProductVersionService.useUpdate();
    const updateProductVersion = (values: Partial<ProductVersion>): void => {
        props.onProductVersionDetailsChange(props.productVersion.with(values));
    };

    const handleCreditHoursChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const creditHours = NumberUtils.parseInt(event.target.value);
        if (creditHours !== undefined && creditHours < 0) {
            return;
        }
        updateProductVersion({ creditHours: creditHours });
    };

    const updateCEUs = async (): Promise<boolean> => {
        if (props.deferSave) {
            //update the productVersion
            props.onProductVersionDetailsChange(props.productVersion);
        } else {
            try {
                const updateProductVersionPathParms: UpdateProductVersionPathParams = {
                    id: props.productVersion.productId!,
                };
                const updateProductResponse = await update(
                    props.productVersion,
                    updateProductVersionPathParms
                );

                const updateResult = updateProductResponse?.result;

                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheCEUs"));
                updateProductVersion(props.productVersion!);
                return false;
            }
        }
        return true;
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                {t("ceus")}
            </Heading>
            <div className="columns">
                <div className="column">
                    <FormTextInput
                        ariaLabelledBy={t("creditHours")}
                        autoFocus={false}
                        formFieldName="creditHours"
                        id="creditHours"
                        label={t("tcredithoursTcreditHourCEU", {
                            tcreditHours: t("creditHours"),
                            tcreditHour: t("creditHour"),
                        })}
                        maxNumber={100}
                        onBlur={updateCEUs}
                        onChange={handleCreditHoursChange}
                        placeholder={t("enterCreditHours")}
                        required={true}
                        type={InputTypes.Number}
                        value={props.productVersion.creditHours}
                    />
                    <div className="hint-text">{props.productVersion.getCEUsHintText()}</div>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCEUsForm };

// #endregion Exports
