import React, { useMemo } from "react";
import { Card } from "components/card/card";
import { HeaderBanner } from "components/header-banner/header-banner";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProfileAccountPage } from "./account/profile-account-page";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import "./profile-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProfilePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "profile-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProfilePage: React.FC<ProfilePageProps> = validatePageAccess(AccessControlKeys.ProfilePage)(
    (): JSX.Element => {
        const { record: globalState } = useGlobalState();
        const userRole = useMemo(
            () => globalState?.currentIdentity?.role?.roleType,
            [globalState?.currentIdentity?.role?.roleType]
        );

        return (
            <>
                <HeaderBanner title={t("myProfile")}>
                    {userRole === RoleType.ClientAdmin && (
                        <Card cssClassName={`${CSS_CLASS_NAME}__organization-name`} tight={true}>
                            <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Label}>
                                {t("memberOf")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Inverted}>
                                {globalState?.currentIdentity?.user?.organization?.name}
                            </Paragraph>
                        </Card>
                    )}
                </HeaderBanner>
                <SkipNavContent>
                    <div className={CSS_CLASS_NAME}>
                        <ProfileAccountPage />
                    </div>
                </SkipNavContent>
            </>
        );
    }
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProfilePage };

// #endregion Exports
