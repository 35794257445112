import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { SelectOption } from "components/form/inputs/select/select";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { t } from "utilities/localization/t";
import { FormSelect } from "components/form/form-select/form-select";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { useAenUsers } from "utilities/hooks/models/aen-applications/use-aen-users";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { ToastManager } from "utilities/toast/toast-manager";
import { ConfirmationModal } from "components/modal/confirmation-modal/confirmation-modal";
import { useGetInstructorApplicationStatusOptions } from "utilities/hooks/models/aen-applications/use-get-instructor-application-status-options";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { PatchInstructorApplicationRecord } from "models/view-models/aen-applications/patch-instructor-application-record";
import { PatchInstructorApplication } from "models/interfaces/aen-applications/patch-instructor-application";
import { StringUtils } from "utilities/string-utils";
import "./edit-instructor-application-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditInstructorApplicationModalProps {
    onSave?: (application: PatchInstructorApplicationRecord) => Promise<boolean>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    instructorApplicationToEdit: InstructorApplicationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-instructor-application-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditInstructorApplicationModal: React.FC<EditInstructorApplicationModalProps> = ({
    open,
    setOpen,
    instructorApplicationToEdit,
    onSave,
}): JSX.Element => {
    const initialApplication = useMemo(() => {
        return instructorApplicationToEdit
            ? new PatchInstructorApplicationRecord({
                  id: instructorApplicationToEdit?.id,
                  assignedToId: instructorApplicationToEdit?.assignedToId,
                  status: instructorApplicationToEdit?.status,
                  sendMessage: false,
                  messageBody: "",
              })
            : new PatchInstructorApplicationRecord();
    }, [instructorApplicationToEdit]);

    const [application, setApplication] =
        useState<PatchInstructorApplicationRecord>(initialApplication);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const { users } = useAenUsers({ applicationType: AenApplicationType.Instructor });
    const statusOptions = useGetInstructorApplicationStatusOptions(initialApplication.status);
    const assignedToOptions: SelectOption[] = users
        .map(
            (user): SelectOption => ({
                text: user.getFirstAndLastName(),
                value: user.id!.toString(),
            })
        )
        .filter((option) => option.text);

    const isInvalid = useMemo(() => {
        return (
            application.sendMessage &&
            (!StringUtils.hasValue(application.messageBody) ||
                !StringUtils.hasValue(application.messageSubject))
        );
    }, [application]);

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const updateApplication = (values: Partial<PatchInstructorApplication>): void => {
        setApplication(application.with(values));
        setDirty(true);
    };

    const handleSave = async () => {
        setSaving(true);
        if (await onSave?.(application)) {
            // Perform Update Success Clean up.
            onModalClose();
            ToastManager.success(t("applicationHasBeenUpdatedSuccessfully"));
        } else {
            // Inform application of failure.
            ToastManager.error(t("thereWasAnIssueUpdatingTheApplication"));
        }
        setSaving(false);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateApplication(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ status: Number(value) }),
                InstructorApplicationStatus
            )
        );
    };

    const handleAssignmentChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateApplication({ assignedToId: parseInt(event.target.value) });
    };

    const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateApplication({ reasonForChange: e.target.value });
    };

    // #endregion Event Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Modal/Form Controls
    // -------------------------------------------------------------------------------------------------

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => handleClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled: !dirty || saving || isInvalid,
            buttonText: t("saveChanges"),
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setApplication(initialApplication);
        setDirty(false);
    };

    const onModalClose = (): void => {
        resetForm();
        setOpen(false);
        setShowConfirmationModal(false);
    };

    const handleClose = () => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            onModalClose();
        }
    };

    // #endregion Modal/Form Controls

    useEffect(() => setApplication(initialApplication), [initialApplication]);

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("updateStatus")}
            actions={modalActionArray}
            modalStyle={""}>
            <FormSelect
                cssClassName={`${CSS_CLASS_NAME}__select`}
                onChange={handleStatusChange}
                options={statusOptions}
                label={t("status")}
                value={application.status == null ? undefined : `${application.status}`}
                formFieldName={"header-action-status"}
                id={"header-action-status"}
            />
            <FormSelect
                cssClassName={`${CSS_CLASS_NAME}__select`}
                onChange={handleAssignmentChange}
                options={assignedToOptions}
                value={application.assignedToId == null ? undefined : `${application.assignedToId}`}
                label={t("assignedTo")}
                formFieldName={"header-action-assigned-to-id"}
                id={"header-action-assigned-to-id"}
            />
            <FormTextArea
                ariaLabelledBy={t("notes")}
                formFieldName="notes"
                id="notes"
                label={t("notes")}
                maxLength={1000}
                onChange={handleReasonChange}
                placeholder={t("enterDescription")}
                rows={5}
                value={application.reasonForChange}
            />

            <FormCheckboxInput
                checked={application.sendMessage}
                id="sendMessageToApplicant"
                label={t("sendMessageToApplicant")}
                onChange={(checked) => updateApplication({ sendMessage: checked })}
                formFieldName={t("sendMessageToApplicant")}
            />

            {application.sendMessage && (
                <>
                    <FormTextArea
                        ariaLabelledBy={t("emailSubject")}
                        formFieldName="emailSubject"
                        id="emailSubject"
                        label={t("emailSubject")}
                        maxLength={150}
                        onChange={(e) => updateApplication({ messageSubject: e.target.value })}
                        placeholder={t("emailSubject")}
                        rows={1}
                        value={application.messageSubject}
                    />

                    <FormTextArea
                        ariaLabelledBy={t("emailBody")}
                        formFieldName="emailBody"
                        id="emailBody"
                        label={t("emailBody")}
                        maxLength={1000}
                        onChange={(e) => updateApplication({ messageBody: e.target.value })}
                        placeholder={t("emailBody")}
                        rows={5}
                        value={application.messageBody}
                    />
                </>
            )}

            <ConfirmationModal
                open={showConfirmationModal}
                onCancel={() => setShowConfirmationModal(false)}
                onConfirm={onModalClose}
                confirmationMessage={t("areYouSureYouWantToExit")}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditInstructorApplicationModal };

// #endregion Exports
