import { useCallback } from "react";
import { IdentityRecord } from "models/view-models/identity/identity-record";
import { RoleService } from "utilities/services/roles/role-service";
import { UserService } from "utilities/services/users/user-service";
import { UserRecord } from "models/view-models/users/user-record";
import { RoleRecord } from "models/view-models/roles/role-record";
import { UserLoginRecord } from "models/view-models/users/user-login-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { AuthService } from "utilities/services/auth/auth-service";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type IdentityHook = {
    getIdentity: (
        userLogin: UserLoginRecord | undefined
    ) => Promise<IdentityRecord | undefined> | undefined;
};

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

/**
 * Custom hook providing utility functions for the current identity of the user
 */
const useIdentity = (): IdentityHook => {
    const { get: getRoleApi } = RoleService.useGet();
    const { get: getUserApi } = UserService.useGet();
    const { get: getAuth } = AuthService.useGet();

    /**
     * Build the identity record including the current User and Role
     *
     * @param  {UserLoginRecord|undefined} (userLogin
     * @returns Promise
     */
    const getHook = (userLogin: UserLoginRecord | undefined) => {
        if (userLogin == null) {
            return;
        }

        return getIdentity(userLogin);
    };

    const getIdentity = async (userLogin: UserLoginRecord): Promise<IdentityRecord | undefined> => {
        const role = await getRole(userLogin?.roleId);
        const user = await getUser(userLogin?.userId);
        const userLoginResponse = getAuth({
            id: userLogin?.id,
        });
        if (userLoginResponse == null) {
            console.log("userLoginResponse.resultObject is null");
        }
        if (user == null) {
            return;
        }

        return new IdentityRecord({ role, user, userLogin });
    };

    const getRole = async (roleId: number | undefined): Promise<RoleRecord | undefined> => {
        if (roleId == null) {
            return;
        }

        let role;
        try {
            role = (await getRoleApi({ id: roleId })).result?.resultObject;
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingUserRoles"));
        }
        return role;
    };

    const getUser = async (userId: number | undefined): Promise<UserRecord | undefined> => {
        if (userId == null) {
            return;
        }

        let user;
        try {
            user = (
                await getUserApi(
                    { id: userId },
                    { includeOrganization: true, includeProvider: true }
                )
            ).result?.resultObject;
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingUser"));
        }
        return user;
    };

    return { getIdentity: useCallback(getHook, [getRoleApi, getUserApi]) };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useIdentity };

// #endregion Exports
