import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";

export interface AenApplicationsSearchState {
    searchText: string;
    debouncedSearchText?: string;
    status?: ProviderApplicationStatus | InstructorApplicationStatus;
    assignedToId?: number;
    currentPage: number;
    hasCompletedInitialSearch?: boolean;
    searchCriteriaReady?: boolean;
}

export enum AenApplicationsSearchReduceActionType {
    InitializeSearch,
    UpdateSearch,
    UpdateDebouncedSearch,
    UpdateStatus,
    UpdateCurrentPage,
    UpdateAssignToId,
    InitializeWithDefaultAssignedToId,
    ClearSearchCriteria,
    ResetFilters,
}

export type AenApplicationsSearchReducerActions =
    | { type: AenApplicationsSearchReduceActionType.InitializeSearch; value: number }
    | { type: AenApplicationsSearchReduceActionType.UpdateSearch; value: string }
    | { type: AenApplicationsSearchReduceActionType.UpdateDebouncedSearch; value?: string }
    | {
          type: AenApplicationsSearchReduceActionType.UpdateStatus;
          value?: ProviderApplicationStatus | InstructorApplicationStatus;
      }
    | { type: AenApplicationsSearchReduceActionType.UpdateCurrentPage; value: number }
    | { type: AenApplicationsSearchReduceActionType.UpdateAssignToId; value?: number }
    | { type: AenApplicationsSearchReduceActionType.ClearSearchCriteria }
    | {
          type: AenApplicationsSearchReduceActionType.InitializeWithDefaultAssignedToId;
          value: number;
      }
    | { type: AenApplicationsSearchReduceActionType.ResetFilters };

export function aenApplicationsSearchReducer(
    state: AenApplicationsSearchState,
    action: AenApplicationsSearchReducerActions
): AenApplicationsSearchState {
    switch (action.type) {
        case AenApplicationsSearchReduceActionType.InitializeSearch:
            return {
                ...state,
                searchCriteriaReady: true,
                assignedToId: action.value,
            };
        case AenApplicationsSearchReduceActionType.ResetFilters:
            return {
                assignedToId: undefined,
                status: undefined,
                searchText: "",
                debouncedSearchText: undefined,
                currentPage: 1,
                hasCompletedInitialSearch: false,
            };

        case AenApplicationsSearchReduceActionType.UpdateSearch:
            if (state.searchText === action.value) {
                return state;
            }

            return {
                ...state,
                searchText: action.value,
            };

        case AenApplicationsSearchReduceActionType.UpdateDebouncedSearch:
            if (state.debouncedSearchText === action.value) {
                return state;
            }

            return {
                ...state,
                debouncedSearchText: action.value,
                currentPage: 1,
            };

        case AenApplicationsSearchReduceActionType.UpdateStatus:
            if (state.status === action.value) {
                return state;
            }

            return {
                ...state,
                status: action.value,
                currentPage: 1,
            };
        case AenApplicationsSearchReduceActionType.UpdateAssignToId:
            if (state.assignedToId === action.value) {
                return state;
            }

            return {
                ...state,
                assignedToId: action.value,
                currentPage: 1,
            };
        case AenApplicationsSearchReduceActionType.InitializeWithDefaultAssignedToId:
            if (state.assignedToId === action.value) {
                return state;
            }
            return {
                ...state,
                assignedToId: action.value,
                hasCompletedInitialSearch: true,
            };

        case AenApplicationsSearchReduceActionType.UpdateCurrentPage:
            return {
                ...state,
                currentPage: action.value,
            };

        case AenApplicationsSearchReduceActionType.ClearSearchCriteria:
            return {
                ...state,
                searchText: "",
                debouncedSearchText: undefined,
                status: undefined,
                currentPage: 1,
                assignedToId: undefined,
                hasCompletedInitialSearch: true,
            };
    }
}
