import { ContractSummaryRecord } from "models/view-models/contracts/contract-summary-record";
import { NumberUtils } from "utilities/number-utils";
import { OrganizationILTContractSummariesService } from "utilities/services/organizations/organization-ilt-contract-summaries-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";

interface useOrganizationILTContractSummariesHook {
    contractSummaries: ContractSummaryRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface useOrganizationILTContractSummariesHookOptions {
    organizationId?: number | string;
}

export function useOrganizationILTContractSummaries(
    props: useOrganizationILTContractSummariesHookOptions
): useOrganizationILTContractSummariesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [contractSummaries, setContractSummaries] = useState<ContractSummaryRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listContractSummariesAPI } = OrganizationILTContractSummariesService.useList();
    const organizationId = NumberUtils.parseInt(props.organizationId) ?? 0;

    useEffect(() => {
        if (isNaN(organizationId) || organizationId < 1) {
            return;
        }
        try {
            (async function getContractSummaries() {
                const { result, resultObjects } = await listContractSummariesAPI(
                    { organizationId: organizationId },
                    {}
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setContractSummaries(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningContractSummaries"));
        }
    }, [organizationId, listContractSummariesAPI]);

    return {
        errors,
        isLoading,
        contractSummaries,
    };
}
