import React from "react";
import { Menu } from "@headlessui/react";
import "./context-menu-file-preview.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContextMenuFilePreviewProps {
    ariaLabel?: string;
    displayName: string;
    download?: string;
    hrefPath: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "context-menu-anchor";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContextMenuFilePreview: React.FC<React.PropsWithChildren<ContextMenuFilePreviewProps>> = (
    props
) => {
    return (
        <Menu.Item>
            {({ active }) => (
                <a
                    aria-label={props.ariaLabel}
                    className={`${active ? "-active" : ""} ${CSS_CLASS_NAME}`}
                    href={props.hrefPath}
                    rel="noreferrer"
                    target="_blank">
                    {props.displayName}
                </a>
            )}
        </Menu.Item>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ContextMenuFilePreview };

// #endregion Exports
