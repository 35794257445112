import { Button, ButtonStyle } from "components/buttons/button/button";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { t } from "utilities/localization/t";
import "./event-day-attendance-header-actions.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayAttendanceHeaderActionsProps {
    eventDayHasNotStarted: boolean;
    updateEventDayStatus: (status: EventDayStatus) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-day-attendance-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayAttendanceHeaderActions: React.FC<EventDayAttendanceHeaderActionsProps> = (
    props: EventDayAttendanceHeaderActionsProps
): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}__actions`}>
            <Button
                disabled={props.eventDayHasNotStarted}
                onClick={() => props.updateEventDayStatus(EventDayStatus.InProgress)}
                style={ButtonStyle.Secondary}
                text={t("saveAndExit")}
            />
            <Button
                disabled={props.eventDayHasNotStarted}
                onClick={() => props.updateEventDayStatus(EventDayStatus.Completed)}
                text={t("markComplete")}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayAttendanceHeaderActions };
