import { NumberUtils } from "utilities/number-utils";
import { ProductScormPackageRecord } from "models/view-models/products/product-scorm-package-record";
import { ProductScormPackageService } from "utilities/services/products/product-scorm-package-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { useEffect, useState } from "react";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseProductAssessmentsHook {
    productAssessments: ProductScormPackageRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseProductAssessmentsHookOptions {
    productId?: number | string;
    productVersionId?: number | string;
}

export function useProductAssessments(
    props: UseProductAssessmentsHookOptions
): UseProductAssessmentsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [productAssessments, setProductAssessments] = useState<ProductScormPackageRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: getAssessments } = ProductScormPackageService.useList();
    const productId = NumberUtils.parseInt(props.productId) ?? 0;
    const productVersionId = NumberUtils.parseInt(props.productVersionId) ?? 0;

    useEffect(() => {
        if (isNaN(productId) || productId < 1) {
            return;
        }

        try {
            (async function getProductAssessments() {
                const { result, resultObjects } = await getAssessments({
                    productId,
                    productVersionId,
                    includeScormPackage: true,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                const productAssessments = resultObjects;
                productAssessments.sort((a, b) => a.sortOrder! - b.sortOrder!);

                setProductAssessments(productAssessments);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemGettingProductAssessments"));
        }
    }, [getAssessments, productId, productVersionId]);

    return {
        errors,
        isLoading,
        productAssessments,
    };
}
