import { ContractRecord } from "../contracts/contract-record";
import { EnrollmentRecord } from "../enrollments/enrollment-record";
import { EventRecord } from "../events/event-record";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { Notification } from "models/interfaces/notifications/notification";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ProductRecord } from "../products/product-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Notification = {
    contract: undefined,
    contractId: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    notificationType: NotificationType.Default,
    enrollment: undefined,
    enrollmentId: undefined,
    event: undefined,
    eventId: undefined,
    id: undefined,
    isRead: false,
    message: "",
    product: undefined,
    productId: undefined,
    recipient: undefined,
    recipientId: undefined,
    roleType: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    url: undefined,
};

// #endregion Default Values
// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class NotificationRecord extends Record(defaultValues) implements Notification {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Notification>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.recipient != null) {
            params.recipient = RecordUtils.ensureRecord(params.recipient, UserRecord);
        }

        if (params.contract != null) {
            params.contract = RecordUtils.ensureRecord(params.contract, ContractRecord);
        }

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public getNotificationIcon(): Icons {
        if (
            this.notificationType === NotificationType.OllContractCreation ||
            this.notificationType === NotificationType.OllEnrollmentCreation
        ) {
            return Icons.LibraryBooks;
        }

        if (this.notificationType === NotificationType.InstructorApplicationApproved) {
            return Icons.CircleChecked;
        }

        if (this.event?.instructorLedTrainingType === InstructorLedTrainingType.InPerson) {
            if (this.notificationType === NotificationType.EventCancelled) {
                return Icons.CalendarBusy;
            } else {
                return Icons.Calendar;
            }
        } else if (
            this.event?.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual
        ) {
            if (this.notificationType === NotificationType.EventCancelled) {
                return Icons.DesktopBusy;
            } else {
                return Icons.DesktopWithBullets;
            }
        }
        return Icons.InformationOutline;
    }

    public with(values: Partial<Notification>): NotificationRecord {
        return new NotificationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NotificationRecord };

// #endregion Exports
