import { RoleType } from "models/enumerations/users/role-type";
import { CollectionUtils } from "utilities/collection-utils";
import { ReportAllUserType } from "./report-all-user-type";
import { GroupRecord } from "models/view-models/groups/group-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportAllUsersState {
    reportType?: ReportAllUserType;
    groups: GroupRecord[];
}

type ReducerActions =
    | { type: "reportType"; reportType?: ReportAllUserType }
    | { type: "updateGroups"; groups: GroupRecord[] };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: ReportAllUsersState = {
    reportType: undefined,
    groups: [],
};

const validate = (state: ReportAllUsersState): boolean => {
    const { reportType } = state;

    if (reportType == null) {
        return false;
    }

    if (reportType === ReportAllUserType.Group) {
        return CollectionUtils.hasValues(state.groups);
    }

    return true;
};

const reducer = (state: ReportAllUsersState, action: ReducerActions): ReportAllUsersState => {
    switch (action.type) {
        case "reportType":
            const { reportType } = action;

            return {
                ...state,
                reportType,
                groups: reportType === ReportAllUserType.Group ? state.groups : [],
            };

        case "updateGroups":
            const { groups } = action;

            return { ...state, groups };

        default:
            throw new Error();
    }
};

const initializer =
    (roleType?: RoleType | null) =>
    (initialState: ReportAllUsersState): ReportAllUsersState => {
        return initialState;
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validate };
export type { ReportAllUsersState };

// #endregion Exports
