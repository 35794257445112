import { ServiceHookFactory } from "andculturecode-javascript-react";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "scormpackageassessments";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof ScormPackageAssessmentRecord = ScormPackageAssessmentRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetScormPackageAssessmentPathParams {
    id: number;
}

export interface GetScormPackageAssessmentQueryParams {
    includeQuestions?: boolean;
    includeScormPackage?: boolean;
    includeProductScormPackage?: boolean;
}

export interface ListScormPackageAssessmentsQueryParams {
    eventId?: number;
    productId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ScormPackageAssessmentService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        ScormPackageAssessmentRecord,
        GetScormPackageAssessmentPathParams,
        GetScormPackageAssessmentQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        ScormPackageAssessmentRecord,
        ListScormPackageAssessmentsQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageAssessmentService };

// #endregion Exports
