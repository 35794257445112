import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { InputTypes } from "../../../form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { FormSelect } from "components/form/form-select/form-select";
import { t } from "utilities/localization/t";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { Provider } from "models/interfaces/providers/provider";
import { SelectOption } from "components/form/inputs/select/select";
import { allCountries } from "country-region-data";
import { CountryConstants } from "constants/country-constants";
import { StringUtils } from "utilities/string-utils";
import { useProviders } from "utilities/hooks/models/providers/use-providers";
import { useTimeZoneOptions } from "utilities/hooks/use-timezone-options";
import "./edit-provider-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditProviderModalProps {
    onEditFormSave?: (provider: ProviderRecord) => Promise<boolean>;
    providerToEdit: ProviderRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-provider-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditProviderModal: React.FC<EditProviderModalProps> = ({
    open,
    setOpen,
    onEditFormSave,
    providerToEdit,
}): JSX.Element => {
    const initialProvider = useMemo(() => providerToEdit ?? new ProviderRecord(), [providerToEdit]);
    useEffect(() => {
        setProvider(initialProvider);
    }, [initialProvider]);
    const { timeZoneOptions: timeZones } = useTimeZoneOptions();

    const [provider, setProvider] = useState<ProviderRecord>(initialProvider);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [stateRegionConstants, setStateRegionConstants] = useState<SelectOption[]>([]);

    // -------------------------------------------------------------------------------------------------
    // #region Validate Unique Provider Details
    // -------------------------------------------------------------------------------------------------

    const [providerNameError, setProviderNameError] = useState<string>("");
    const [netsuiteIdError, setNetsuiteIdError] = useState<string>("");
    const [nameToCheck, setNameToCheck] = useState<string>("");
    const [netsuiteIdToCheck, setNetsuiteIdToCheck] = useState<string>("");

    const { providers: providersWithName, isLoading: nameCheckInProgress } = useProviders({
        name: nameToCheck,
        searchCriteriaReady: StringUtils.hasValue(nameToCheck),
        skip: 0,
        take: 1,
    });

    const { providers: providersWithNetsuiteId, isLoading: netsuiteIdCheckInProgress } =
        useProviders({
            netsuiteId: netsuiteIdToCheck,
            searchCriteriaReady: StringUtils.hasValue(netsuiteIdToCheck),
            skip: 0,
            take: 1,
        });

    const onProviderNameBlur = (): void => {
        if (StringUtils.hasValue(provider.name)) {
            setNameToCheck(provider.name);
        }
    };

    const onNetsuiteIdBlur = (): void => {
        if (StringUtils.hasValue(provider.netsuiteId)) {
            setNetsuiteIdToCheck(provider.netsuiteId);
        }
    };

    useEffect(() => {
        if (providersWithName.some((p) => p.id !== provider.id)) {
            setProviderNameError(t("aProviderAlreadyExistsWithThisName"));
        } else {
            setProviderNameError("");
        }
    }, [provider.id, providersWithName]);

    useEffect(() => {
        if (providersWithNetsuiteId.some((p) => p.id !== provider.id)) {
            setNetsuiteIdError(t("aProviderAlreadyExistsWithThisNetsuiteId"));
        } else {
            setNetsuiteIdError("");
        }
    }, [provider.id, providersWithNetsuiteId]);

    // #endregion Validate Unique Provider Details

    const updateProvider = (values: Partial<Provider>): void => {
        setIsDirty(true);
        setProvider(provider.with(values));
    };

    useEffect(() => {
        const stateRegionSelectOptions: SelectOption[] = [];

        if (provider.country) {
            const stateRegions = allCountries.find((c) => c[0] === provider.country);

            stateRegions![2].forEach((region) =>
                stateRegionSelectOptions.push({ text: region[0], value: region[0] })
            );
        }

        setStateRegionConstants(stateRegionSelectOptions);
    }, [provider.country]);

    const providerIsValid = useMemo(() => {
        return (
            StringUtils.isValidEmail(provider.email) &&
            StringUtils.hasValue(provider.name) &&
            providerNameError === "" &&
            netsuiteIdError === "" &&
            !nameCheckInProgress &&
            !netsuiteIdCheckInProgress
        );
    }, [
        nameCheckInProgress,
        netsuiteIdCheckInProgress,
        netsuiteIdError,
        provider.email,
        provider.name,
        providerNameError,
    ]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            onClick: () => handleSave(),
            disabled: !providerIsValid,
            style: ButtonStyle.Primary,
        },
    ];

    const cancelConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                setOpen(false);
                setShowConfirmationModal(false);
                setIsDirty(false);
                setProvider(initialProvider);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (isDirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
        }
    };

    const handleSave = async () => {
        if (await onEditFormSave?.(provider)) {
            // Perform Update Success Clean up.
            setIsDirty(false);
            setOpen(false);
            ToastManager.success(t("providerHasBeenUpdatedSuccessfully"));
        } else {
            // Inform user of failure.
            ToastManager.error(t("thereWasAnIssueUpdatingTheProvider"));
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editEnProvider")}
            actions={modalActionArray}
            modalStyle={""}
        >
            <div className={`${CSS_CLASS_NAME}__section`}>
                <Paragraph size={ParagraphSize.Large}>{t("basicInformation")}</Paragraph>
                <FormTextInput
                    ariaLabelledBy={t("organizationName")}
                    autoFocus={true}
                    errorMessage={providerNameError}
                    formFieldName="organizationName"
                    id="organizationName"
                    label={t("organizationName")}
                    maxLength={60}
                    onBlur={onProviderNameBlur}
                    onChange={(e) => updateProvider({ name: e.target.value })}
                    placeholder={t("enterOrganizationName")}
                    required={true}
                    type={InputTypes.Text}
                    value={provider.name}
                />
                <FormTextInput
                    ariaLabelledBy={t("netsuiteId")}
                    errorMessage={netsuiteIdError}
                    formFieldName="netsuiteId"
                    id="netsuiteId"
                    label={t("netsuiteId")}
                    maxLength={60}
                    onBlur={onNetsuiteIdBlur}
                    onChange={(e) => updateProvider({ netsuiteId: e.target.value })}
                    placeholder={t("enterNetsuiteId")}
                    type={InputTypes.Text}
                    value={provider.netsuiteId}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <Paragraph size={ParagraphSize.Large}>{t("defaultEventInformation")}</Paragraph>
                <Paragraph size={ParagraphSize.Default}>{t("defaultContactInformation")}</Paragraph>
                <FormTextInput
                    ariaLabelledBy={t("email")}
                    formFieldName="email"
                    id="email"
                    label={t("email")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ email: e.target.value })}
                    placeholder={t("enterEmail")}
                    required={true}
                    type={InputTypes.Email}
                    value={provider.email}
                />
                <FormTextInput
                    ariaLabelledBy={t("phone")}
                    formFieldName="phone"
                    id="phone"
                    label={t("phone")}
                    maxLength={15}
                    onChange={(e) => updateProvider({ phoneNumber: e.target.value })}
                    placeholder="+1 (000) 000-0000"
                    type={InputTypes.Phone}
                    value={provider.phoneNumber}
                />
                <Paragraph size={ParagraphSize.Default}>{t("defaultEventLocation")}</Paragraph>
                <FormTextInput
                    ariaLabelledBy={t("locationName")}
                    formFieldName="locationName"
                    id="locationName"
                    label={t("locationName")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ locationName: e.target.value })}
                    placeholder={t("enterLocationName")}
                    type={InputTypes.Text}
                    value={provider.locationName}
                />
                <FormSelect
                    ariaLabelledBy={t("country")}
                    formFieldName="country"
                    id="country"
                    label={t("country")}
                    onChange={(e) => updateProvider({ country: e.target.value })}
                    options={CountryConstants}
                    placeholder={t("selectOption")}
                    value={provider.country}
                />
                <FormTextInput
                    ariaLabelledBy={t("addressLine1")}
                    formFieldName="addressLine1"
                    id="addressLine1"
                    label={t("addressLine1")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ addressLineOne: e.target.value })}
                    placeholder={t("enterAddressLine1")}
                    type={InputTypes.Text}
                    value={provider.addressLineOne}
                />
                <FormTextInput
                    ariaLabelledBy={t("addressLine2")}
                    formFieldName="addressLine2"
                    id="addressLine2"
                    label={t("addressLine2")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ addressLineTwo: e.target.value })}
                    placeholder={t("enterAddressLine2")}
                    type={InputTypes.Text}
                    value={provider.addressLineTwo}
                />
                <FormTextInput
                    ariaLabelledBy={t("citySlashTown")}
                    formFieldName="cityTown"
                    id="cityTown"
                    label={t("citySlashTown")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ city: e.target.value })}
                    placeholder={t("enterCitySlashTown")}
                    type={InputTypes.Text}
                    value={provider.city}
                />
                <FormSelect
                    ariaLabelledBy={t("stateSlashProvinceSlashRegion")}
                    formFieldName="stateProvinceRegion"
                    id="stateProvinceRegion"
                    label={t("stateSlashProvinceSlashRegion")}
                    onChange={(e) => updateProvider({ state: e.target.value })}
                    options={stateRegionConstants}
                    placeholder={t("selectOption")}
                    value={provider.state}
                />
                <FormTextInput
                    ariaLabelledBy={t("zipCodeSlashPostalCode")}
                    formFieldName="zipPostal"
                    id="zipPostal"
                    label={t("zipCodeSlashPostalCode")}
                    maxLength={100}
                    onChange={(e) => updateProvider({ zipCode: e.target.value })}
                    placeholder={t("enterZipcodeSlashPostalCode")}
                    type={InputTypes.Text}
                    value={provider.zipCode}
                />
                <Paragraph size={ParagraphSize.Default}>{t("defaultEventTimezone")}</Paragraph>
                <FormSelect
                    ariaLabelledBy={t("timezone")}
                    formFieldName="timeZone"
                    id="timeZone"
                    label={t("timezone")}
                    onChange={(e) => updateProvider({ timeZone: e.target.value })}
                    options={timeZones}
                    placeholder={t("selectTimeZone")}
                    value={provider.timeZone}
                />
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={cancelConfirmationActions}
                modalStyle={"-inverted"}
            >
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditProviderModal };

// #endregion Exports
