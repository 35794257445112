import React from "react";
import { InstructorENHLayoutHeader } from "./instructor-enh-layout-header/instructor-enh-layout-header";
import { Outlet } from "react-router-dom";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import "./instructor-enh-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorENHLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-enh-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorENHLayout: React.FC<InstructorENHLayoutProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <InstructorENHLayoutHeader />
            <SkipNavContent>
                <div className={`${CSS_CLASS_NAME}__main`}>
                    <Outlet />
                </div>
            </SkipNavContent>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorENHLayout };

// #endregion Exports
