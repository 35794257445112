import React from "react";
import { Tab, TabProps } from "components/tabs/tabs/tab/tab";
import { NumberedBadge } from "components/badges/numbered-badge/numbered-badge";
import "./numbered-tab.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface NumberedTabProps extends TabProps {
    hideBadge?: boolean;
    value: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "numbered-tab";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NumberedTab: React.FC<NumberedTabProps> = (props: NumberedTabProps): JSX.Element => {
    return (
        <Tab
            cssClassName={CSS_CLASS_NAME}
            isActive={props.isActive}
            onClick={props.onClick}
            tabName={"emptyString"}>
            <span className={`${CSS_CLASS_NAME}__text`}>{props.tabName}</span>
            {
                // if
                props.hideBadge !== true && (
                    <NumberedBadge cssClassName={`${CSS_CLASS_NAME}__badge`} value={props.value} />
                )
            }
        </Tab>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NumberedTab };

// #endregion Exports
