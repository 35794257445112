import React, { useCallback, useEffect, useState } from "react";
import { Button } from "components/buttons/button/button";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentService,
    ListEnrollmentsQueryParams,
} from "utilities/services/enrollments/enrollment-service";
import { DataTable } from "components/tables/data-table/data-table";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { sitemap } from "sitemap";
import { RouteUtils } from "utilities/route-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./learner-enrollment-list.scss";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerEnrollmentListProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-enrollment-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerEnrollmentList: React.FC<LearnerEnrollmentListProps> = (
    props: LearnerEnrollmentListProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const loggedInUser = globalState?.currentIdentity?.user;
    const navigate = useNavigate();
    const [enrollments, setEnrollments] = useState<EnrollmentRecord[]>([]);
    const { list: listEnrollments } = EnrollmentService.useList();

    const launchEnrollment = useCallback(
        (enrollment: EnrollmentRecord) => {
            if ((enrollment.eventId ?? 0) > 0) {
                const path = RouteUtils.replacePathParams(sitemap.learner.training.event.details, {
                    id: enrollment.eventId,
                    enrollmentId: enrollment.id,
                });
                navigate(path);
            } else {
                alert(t("launchNotYetImplementedForOnlineTraining"));
            }
        },
        [navigate]
    );

    const fetchData = useCallback(
        async (loggedInUserId: number) => {
            try {
                const listEnrollmentQueryParams: ListEnrollmentsQueryParams = {
                    userId: loggedInUserId,
                    includeProduct: true,
                };

                const listResponse = await listEnrollments(listEnrollmentQueryParams);

                if (
                    listResponse?.resultObjects == null ||
                    listResponse.results == null ||
                    listResponse.results.hasErrors()
                ) {
                    throw Error();
                }

                setEnrollments(listResponse.resultObjects);
            } catch (err) {
                console.error("learner-enrollment-list", err);
                ToastManager.error(t("thereWasAnIssueLoadingEnrollments"));
            }
        },
        [listEnrollments]
    );

    const { id: userId = 0 } = loggedInUser ?? {};
    useEffect(() => {
        if (userId < 1) {
            return;
        }

        fetchData(userId);
    }, [fetchData, userId]);

    const launchOnlineLearningPath = useCallback(
        (enrollment: EnrollmentRecord) => {
            if ((enrollment.productId ?? 0) > 0) {
                const path = RouteUtils.replacePathParams(
                    sitemap.learner.training.onlineLearning.details,
                    {
                        productId: enrollment.productId,
                        enrollmentId: enrollment.id,
                    }
                );
                navigate(path);
            }
        },
        [navigate]
    );

    return (
        <>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th>{t("eventEnrollments")}</th>
                        <th className="-collapse">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {enrollments
                        .filter((e) => e.product?.type === TrainingType.InstructorLedTraining)
                        .map((enrollment) => (
                            <tr key={enrollment.id}>
                                <td className="-name">
                                    <span className={`${CSS_CLASS_NAME}__product-name`}>
                                        {enrollment.product?.name}
                                    </span>
                                </td>
                                <td className="-collapse">
                                    <Button
                                        onClick={() => launchEnrollment(enrollment)}
                                        text={t("launchTraining")}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </DataTable>

            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th>{t("onlineLearningProductEnrollments")}</th>
                        <th className="-collapse">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {enrollments
                        .filter((e) => e.product?.type === TrainingType.OnlineLearning)
                        .map((enrollment) => (
                            <tr key={enrollment.id}>
                                <td className="-name">
                                    <span className={`${CSS_CLASS_NAME}__product-name`}>
                                        {enrollment.product?.name}
                                    </span>
                                </td>
                                <td className="-collapse">
                                    <Button
                                        onClick={() => launchOnlineLearningPath(enrollment)}
                                        text={t("launchTraining")}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </DataTable>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerEnrollmentList };

// #endregion Exports
