import { ProviderRecord } from "models/view-models/providers/provider-record";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "providers";
const resourceType: typeof ProviderRecord = ProviderRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const patchIsActiveEndpoint = `${resourceEndpoint}/is-active`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

// #endregion enum

export interface ProviderResourcePathParams {
    id: number;
}
export interface ListProvidersQueryParams {
    isActive?: boolean;
    searchText?: string;
    name?: string;
    netsuiteId?: string;
    skip?: number;
    take?: number;
}

export interface PatchProviderIsActiveParams {
    isActive: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProviderService = {
    useCreate: ServiceHookFactory.useCreate<ProviderRecord>(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<ProviderRecord, ProviderResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service update calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<ProviderRecord, ProviderResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ProviderRecord, ListProvidersQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatchIsActive: ServiceHookFactory.usePatch<
        ProviderRecord,
        ProviderResourcePathParams,
        PatchProviderIsActiveParams
    >(resourceType, patchIsActiveEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderService };

// #endregion Exports
