import React, { useEffect } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { EmailManagementSidebar } from "layouts/admin/email-management-layout/email-management-sidebar/email-management-sidebar";
import { SendAnEmailForm } from "components/emails/send-an-email-form";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ToastManager } from "utilities/toast/toast-manager";
import { reducer, initialState, initializer, validator } from "components/emails/email-reducer";
import { t } from "utilities/localization/t";
import { useEmailReducer } from "utilities/hooks/use-email-reducer";
import { useGetUserDashboard } from "utilities/hooks/use-get-user-dashboard";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useSendEmailRequest } from "utilities/hooks/models/emails/use-send-email-request";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useNavigate } from "react-router-dom";
import "./send-an-email-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SendAnEmailPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "send-an-email-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SendAnEmailPage: React.FC<SendAnEmailPageProps> = validatePageAccess(
    AccessControlKeys.SendAnEmailPage
)((): JSX.Element => {
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { dashboardPath } = useGetUserDashboard({
        userRole: globalState.currentIdentity?.role?.roleType,
        language: globalState.currentIdentity?.user?.preferredLanguage,
    });

    const [payload, dispatch] = useEmailReducer(reducer, initialState, {
        initializer: initializer(),
        validate: validator(),
    });
    const { errors, email, create: createEmail, isLoading } = useSendEmailRequest();

    const handleSendEmail = () => {
        createEmail(payload);
    };

    useEffect(() => {
        if (errors == null || errors.length === 0) return;

        ToastManager.error(errors.map((e) => e.message).join(", "));
    }, [errors]);

    useEffect(() => {
        if (email?.id == null) return;

        ToastManager.success(t("yourEmailHasBeenSent"));
        navigate(dashboardPath);
    }, [dashboardPath, email, navigate]);

    return (
        <div className={CSS_CLASS_NAME}>
            <SideContentLeftLayout sidebarElement={<EmailManagementSidebar />}>
                <div className={`${CSS_CLASS_NAME}__content-wrap`}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <SendAnEmailForm payload={payload} dispatch={dispatch} />
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__footer`}>
                    <Button
                        type={ButtonType.Link}
                        linkPath={dashboardPath}
                        style={ButtonStyle.Secondary}
                        text={t("cancel")}
                    />
                    <Button
                        disabled={!payload.isValid || isLoading}
                        onClick={handleSendEmail}
                        style={ButtonStyle.Primary}
                        text={t("sendEmail")}
                    />
                </div>
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SendAnEmailPage };

// #endregion Exports
