import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { t } from "utilities/localization/t";
import "./cancel-creating-version-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CancelCreatingVersionModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onModalClose: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "cancel-creating-version-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CancelCreatingVersionModal: React.FC<CancelCreatingVersionModalProps> = ({
    open,
    onModalClose,
    setOpen,
}): JSX.Element => {
    const cancelCreatingVersionActionArray: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Transparent,
        },
        {
            buttonText: t("yesLeave"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
    ];

    return (
        <Modal
            actions={cancelCreatingVersionActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            modalStyle={"-inverted"}
            onModalClose={() => {}}>
            {t("areYouSureYouWouldLikeToLeaveWithoutCreatingANewVersion")}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CancelCreatingVersionModal };

// #endregion Exports
