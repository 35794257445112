import React from "react";
import {
    AnchorIcon,
    AnchorIconPosition,
} from "components/typography/anchors/anchor-icon/anchor-icon";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils, StringUtils } from "andculturecode-javascript-core";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { RouteObject, useMatches } from "react-router-dom";
import { useRequestNavigation } from "utilities/contexts/navigation/use-navigation-request-context";
import { t } from "utilities/localization/t";
import "./breadcrumbs.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface BreadcrumbsProps {
    cssClassName?: string;
    homePath?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "breadcrumbs";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => {
    const requestNavigation = useRequestNavigation();
    const navigate = useNavigate();

    const cssClassNames: string[] = [CSS_CLASS_NAME];

    const matches: RouteObject[] = useMatches();
    const crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match?.handle?.crumb());

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const cssClassName: string = cssClassNames.join(" ");
    const currentSlice = crumbs[crumbs.length - 1];
    const homeSlice = crumbs.find((c) => c.homeSlice === true);
    const componentsAllowedToResolve: string[] =
        homeSlice?.requireComponentsToResolveBreadcrumbNavigation ?? [];
    const navigationRequestRequired = CollectionUtils.hasValues(componentsAllowedToResolve);

    return (
        <div className={cssClassName}>
            {homeSlice != null &&
                (navigationRequestRequired ? (
                    <ButtonIcon
                        iconType={Icons.ChevronLeft}
                        iconSize={IconSizes.XLarge}
                        buttonSize={ButtonSize.Medium}
                        buttonStyle={ButtonStyle.Secondary}
                        onClick={() =>
                            requestNavigation({
                                onNavigationApproved: (): void => {
                                    navigate(homeSlice.path);
                                },
                                requestingComponent: Breadcrumbs.name,
                                componentsAllowedToResolve: componentsAllowedToResolve,
                            })
                        }
                        text={`${t("backToAll")} ${homeSlice.name}`}
                    />
                ) : (
                    <AnchorIcon
                        iconPosition={AnchorIconPosition.Left}
                        iconType={Icons.ChevronLeft}
                        path={homeSlice.path}
                        size={IconSizes.Large}
                        text={`${t("backToAll")} ${homeSlice.name}`}
                    />
                ))}

            <p>{currentSlice?.name ?? homeSlice}</p>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Breadcrumbs };
