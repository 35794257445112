import { useState } from "react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { EventProductList } from "components/events/event-product-selection/event-product-list/event-product-list";
import { EventProductSelectionModal } from "components/events/event-product-selection/event-product-selection-modal/event-product-selection-modal";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-product-selection.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductSelectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-product-selection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductSelection: React.FC<EventProductSelectionProps> = (
    props: EventProductSelectionProps
) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Card>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("associatedProduct")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__actions`}>
                        <Button
                            disabled={
                                props.event.productId != null ||
                                props.event.status === PublishStatus.Live
                            }
                            onClick={() => setShowModal(true)}
                            style={ButtonStyle.Primary}
                            text={t("selectProduct")}
                        />
                    </div>
                </div>
                <EventProductList event={props.event} onReplace={() => setShowModal(true)} />
            </Card>
            <EventProductSelectionModal
                event={props.event}
                handleProductChange={props.event.patchEventProduct}
                open={showModal}
                selectedProduct={props.event.product}
                setOpen={setShowModal}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductSelection };

// #endregion Exports
