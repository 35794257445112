import React from "react";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./toggle.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ToggleProps {
    checked?: boolean;
    disabled?: boolean;
    id: string;
    label: string;
    onToggle?: (checked: boolean) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "toggle";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Toggle: React.FC<ToggleProps> = (props) => {
    const { readOnly } = useReadOnly();

    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const className = cssClassNames.join(" ");

    const handleKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>) => {
        if (e.key !== " ") {
            return;
        }
        e.preventDefault();
        onChange(!props.checked);
    };

    const onChange = (checked: boolean) => {
        if (props.onToggle) {
            props.onToggle(checked);
        }
    };

    return (
        <div className={className}>
            <input
                checked={props.checked}
                className={`${CSS_CLASS_NAME}__input`}
                disabled={props.disabled || readOnly}
                id={props.id}
                onChange={(e) => onChange(e.target.checked)}
                tabIndex={props.disabled ? -1 : 0}
                type="checkbox"
            />
            <label
                className={`${CSS_CLASS_NAME}__label ${props.disabled ? "-disabled" : ""}`}
                htmlFor={props.id}
                onKeyDown={(e) => handleKeyPress(e)}
                tabIndex={props.disabled ? -1 : 0}>
                <span className={`${CSS_CLASS_NAME}__label__slider -${props.disabled}`} />
                <span className="sr-only">{props.label}</span>
            </label>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Toggle };

// #endregion Exports
