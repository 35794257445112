import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import {
    GetProductVersionPathParams,
    GetProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { ProductAchievements } from "components/products/product-achievements/product-achievements";
import { ProductContentManager } from "components/products/product-content/product-content-manager";
import { ProductCourseLearningPath } from "components/courses/product-course-learning-path/product-course-learning-path";
import { ProductCourseSeries } from "components/courses/product-course-series/product-course-series";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductEvaluation } from "components/products/product-evaluation/product-evaluation";
import { ProductMaterialsChangeHistory } from "components/products/product-version/product-materials-change-history/product-materials-change-history";
import { RouteUtils } from "utilities/route-utils";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { UnitRecord } from "models/view-models/units/unit-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { ProductVersionArchiveRecord } from "models/view-models/products/product-version-archive-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import { Tab } from "components/tabs/tabs/tab/tab";
import "./edit-online-product-version-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditOnlineProductVersionMaterialsProps {
    editMode: boolean;
    productVersionArchiveData: ProductVersionArchiveRecord[];
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
    setUnit: React.Dispatch<React.SetStateAction<UnitRecord>>;
    unit: UnitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditOnlineProductVersionMaterials: React.FC<EditOnlineProductVersionMaterialsProps> = ({
    editMode,
    productVersionArchiveData,
    setDirty,
    setUnit,
    unit,
}): JSX.Element => {
    const navigate = useNavigate();
    const { record: product, setRecord: setProduct } = useProduct();
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const { versionId } = useParams();
    const productVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);

    const { get: getProductVersion } = ProductVersionService.useGet();

    const fetchData = useCallback(async () => {
        try {
            const pathParams: GetProductVersionPathParams = {
                id: productVersionId,
            };
            const queryParams: GetProductVersionQueryParams = {
                includeUnit: true,
                includeContent: true,
                includeEvaluationTemplate: true,
                includeProductScormPackage: false,
            };

            const getResponse = await getProductVersion(pathParams, queryParams);
            const getResult = getResponse?.result;
            const editProductVersion = getResult?.resultObject;
            editProductVersion.sortAllChildren();

            setProductVersion(editProductVersion);
        } catch (error) {}
    }, [productVersionId, getProductVersion, setProductVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onlineLearningTabs: TabbedContainerItem<any>[] = useMemo(() => {
        return [
            {
                contents:
                    product.onlineLearningType === ProductCourseType.CourseSeries ? (
                        <ProductCourseSeries
                            createVersionMode={false}
                            editMode={editMode}
                            product={product}
                            setUnit={setUnit}
                            unit={unit}
                        />
                    ) : (
                        <ProductCourseLearningPath
                            product={product}
                            createVersionMode={false}
                            editMode={editMode}
                        />
                    ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("courses"),
                    iconType: productVersion.courseIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductContentManager
                        product={product}
                        productVersion={productVersion}
                        setProductVersion={setProductVersion}
                        editMode={editMode}
                        setDirty={setDirty}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("content"),
                    iconType: productVersion.contentIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductAchievements
                        product={product}
                        editMode={editMode}
                        setDirty={setDirty}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("achievements"),
                    iconType: productVersion.achievementIsComplete(product.audienceType)
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductEvaluation
                        product={product}
                        setProduct={setProduct}
                        editMode={editMode}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("evaluation"),
                    iconType: product.evaluationTemplateIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductMaterialsChangeHistory
                        productVersionArchiveData={productVersionArchiveData}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("changeHistory"),
                },
            },
        ];
    }, [
        product,
        productVersion,
        setProduct,
        setProductVersion,
        productVersionArchiveData,
        setDirty,
        setUnit,
        unit,
        editMode,
    ]);

    const aenTrainingTabs: TabbedContainerItem<any>[] = useMemo(() => {
        return [
            {
                contents:
                    product.onlineLearningType === ProductCourseType.CourseSeries ? (
                        <ProductCourseSeries
                            createVersionMode={false}
                            editMode={editMode}
                            product={product}
                            setUnit={setUnit}
                            unit={unit}
                        />
                    ) : (
                        <ProductCourseLearningPath
                            product={product}
                            createVersionMode={false}
                            editMode={editMode}
                        />
                    ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("courses"),
                    iconType: productVersion.courseIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductContentManager
                        product={product}
                        productVersion={productVersion}
                        setProductVersion={setProductVersion}
                        editMode={editMode}
                        setDirty={setDirty}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("content"),
                    iconType: productVersion.contentIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductMaterialsChangeHistory
                        productVersionArchiveData={productVersionArchiveData}
                    />
                ),
                tabComponent: Tab,
                tabDisplayDetails: {
                    tabName: t("changeHistory"),
                    iconType: Icons.History,
                },
            },
        ];
    }, [
        product,
        productVersion,
        setProductVersion,
        productVersionArchiveData,
        setDirty,
        setUnit,
        unit,
        editMode,
    ]);

    const onModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                    id: product.id,
                })
            )
        );
    };

    return (
        <div>
            <TabbedContainer
                tabs={
                    product.type === TrainingType.OnlineLearning
                        ? onlineLearningTabs
                        : aenTrainingTabs
                }
            />
            <CancelChangesModal
                open={showCancelCreateVersionModal}
                setOpen={setShowCancelCreateVersionModal}
                onModalClose={onModalClose}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditOnlineProductVersionMaterials };

// #endregion Exports
