import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, PatchCourseServiceParams } from "utilities/services/courses/course-service";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "../../icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import "./course-archive-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseArchiveModalProps {
    course: CourseRecord;
    open: boolean;
    productCount: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-archive-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseArchiveModal: React.FC<CourseArchiveModalProps> = ({
    course,
    open,
    productCount,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const { archive: courseArchive } = CourseService.useArchive();
    const courseIsUsedInProducts = productCount > 0;
    const productsCheck = courseIsUsedInProducts ? "-unchecked" : "-checked";
    const courseIsActive = course.status === ActiveStatus.Active;
    const courseActiveCheck = courseIsActive ? "-unchecked" : "-checked";

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("archiveCourse"),
            disabled:
                course.status === ActiveStatus.Active
                    ? courseIsUsedInProducts || courseIsActive
                    : false,
            onClick: () => archiveCourse(),
            style: ButtonStyle.Primary,
        },
    ];

    const archiveCourse = async (): Promise<boolean> => {
        if (course.id == null) {
            return false;
        }
        setOpen(false);
        try {
            const patchCourseServiceParams: PatchCourseServiceParams = {
                id: course.id,
            };

            const patchCourseResponse = await courseArchive(patchCourseServiceParams);

            const patchCourseResult = patchCourseResponse?.result;

            if (patchCourseResult?.resultObject == null || patchCourseResult.hasErrors()) {
                alert(t("archivingCourseFailed"));
            }
            navigate(sitemap.admin.course.list);
            ToastManager.success(t("courseIsNowArchived"));
        } catch {
            ToastManager.error(t("failedToArchiveCourse"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("archiveCourse")}
            actions={modalActionArray}
            modalStyle={""}>
            {(course.status === ActiveStatus.Active || course.status === ActiveStatus.Inactive) && (
                <>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__header`}
                        size={ParagraphSize.Large}>
                        {t(
                            "youAreAboutToArchiveThisCourseArchivingTheCourseWillRemoveItFromTheCourseListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOnInOrderToArchiveThisCourseYouNeedTo"
                        )}
                    </Paragraph>
                    <Paragraph size={ParagraphSize.Large}>
                        {t("inOrderToArchiveThisCourseYouNeedTo")}
                    </Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__item`}>
                            <Icon
                                cssClassName={`${CSS_CLASS_NAME}__icon ${productsCheck}`}
                                size={IconSizes.Large}
                                type={
                                    courseIsUsedInProducts
                                        ? Icons.CircleUnchecked
                                        : Icons.CircleChecked
                                }
                            />
                            {t("removeCourseFromAnyProducts")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__item`}>
                            <Icon
                                cssClassName={`${CSS_CLASS_NAME}__icon ${courseActiveCheck}`}
                                size={IconSizes.Large}
                                type={courseIsActive ? Icons.CircleUnchecked : Icons.CircleChecked}
                            />
                            {t("deactivateTheCourse")}
                        </li>
                    </ul>
                </>
            )}
            {course.status === ActiveStatus.Draft && (
                <Paragraph size={ParagraphSize.Large}>
                    You are about to archive this course. Archiving the course will remove it from
                    the course list view unless the "Include Archived" toggle button is turned on.
                </Paragraph>
            )}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseArchiveModal };

// #endregion Exports
