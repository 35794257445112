import { Dialog } from "@headlessui/react";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { t } from "utilities/localization/t";
import "../modal.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ModalHeaderProps {
    actionContent?: any;
    title: string;
    onModalClose: (close: boolean) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "modal";

// #endregion Constants
// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
    return (
        <header className={`${CSS_CLASS_NAME}__header`}>
            {props.actionContent != null && (
                <div className={`${CSS_CLASS_NAME}__header__actions`}>{props.actionContent}</div>
            )}
            <div className={`${CSS_CLASS_NAME}__header-close`}>
                <button type="button" onClick={() => props.onModalClose(false)}>
                    <span className="sr-only">{t("close")}</span>
                    <Icon
                        cssClassName={`${CSS_CLASS_NAME}__header-close-icon`}
                        type={Icons.Close}
                        size={IconSizes.Large}
                        aria-hidden="true"
                    />
                </button>
            </div>
            <div>
                <Dialog.Title as="h3" className={`${CSS_CLASS_NAME}__title`}>
                    {props.title}
                </Dialog.Title>
            </div>
        </header>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ModalHeader };

// #endregion Exports
