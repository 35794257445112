import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { FileRecord } from "../files/file-record";
import { InstructorLanguageRecord } from "models/view-models/instructors/instructor-language-record";
import { InstructorProfile } from "models/interfaces/instructors/instructor-profile";
import { InstructorTrainingTypeRecord } from "./instructor-training-type-record";
import { InstructorTravelTypeRecord } from "./instructor-travel-type-record";
import { ProviderRecord } from "../providers/provider-record";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorProfile = {
    aboutMe: undefined,
    avatarFile: undefined,
    avatarFileId: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    excludeFromAenDirectory: false,
    id: undefined,
    instructorLanguages: undefined,
    instructorTrainingTypes: undefined,
    instructorTravelTypes: undefined,
    provider: undefined,
    providerId: undefined,
    resumeFile: undefined,
    resumeFileId: undefined,
    shareLocation: true,
    status: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    userId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorProfileRecord extends Record(defaultValues) implements InstructorProfile {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorProfile>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.avatarFile != null) {
            params.avatarFile = RecordUtils.ensureRecord(params.avatarFile, FileRecord);
        }

        if (params.resumeFile != null) {
            params.resumeFile = RecordUtils.ensureRecord(params.resumeFile, FileRecord);
        }

        if (params.provider != null) {
            params.provider = RecordUtils.ensureRecord(params.provider, ProviderRecord);
        }

        if (CollectionUtils.hasValues(params.instructorTrainingTypes)) {
            params.instructorTrainingTypes = RecordUtils.ensureRecords(
                params.instructorTrainingTypes,
                InstructorTrainingTypeRecord
            );
        }

        if (CollectionUtils.hasValues(params.instructorTravelTypes)) {
            params.instructorTravelTypes = RecordUtils.ensureRecords(
                params.instructorTravelTypes,
                InstructorTravelTypeRecord
            );
        }

        if (CollectionUtils.hasValues(params.instructorLanguages)) {
            params.instructorLanguages = RecordUtils.ensureRecords(
                params.instructorLanguages,
                InstructorLanguageRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public with(values: Partial<InstructorProfile>): InstructorProfileRecord {
        return new InstructorProfileRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorProfileRecord };

// #endregion Exports
