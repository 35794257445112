import React from "react";
import "./placeholder-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PlaceholderPageProps {
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "placeholder-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PlaceholderPage: React.FC<PlaceholderPageProps> = (
    props: PlaceholderPageProps
): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h1>{props.title}</h1>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PlaceholderPage };

// #endregion Exports
