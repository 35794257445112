import { Card } from "components/card/card";
import { Icons } from "components/icons/constants/icons";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ContentRecord } from "models/view-models/contents/content-record";
import { FileUtils } from "utilities/files/file-utils";
import { t } from "utilities/localization/t";
import "./course-content-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseContentProps {
    content: ContentRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-content-card";

// #endregion Constants

const CourseContentCard: React.FC<CourseContentProps> = (
    props: CourseContentProps
): JSX.Element => {
    const { name, description, file } = props.content;
    const { fileName, id: fileId = 0 } = file ?? {};

    return (
        <Card cssClassName={CSS_CLASS_NAME} tight={true}>
            <Paragraph>{name}</Paragraph>
            <Paragraph style={ParagraphStyle.Light}>{description}</Paragraph>
            {fileName && fileId > 0 && (
                <AnchorIcon
                    ariaLabel={t("downloadButtonForName", { name: name })}
                    iconType={Icons.Download}
                    download={fileName}
                    pathType={AnchorPathType.Download}
                    path={FileUtils.fileUrl(fileId)}
                />
            )}
        </Card>
    );
};

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentCard };

// #endregion Exports
