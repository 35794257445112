import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { InstructorTrainingType } from "models/interfaces/instructors/instructor-training-type";
import { Record } from "immutable";
import { RecordUtils } from "utilities/record-utils";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorTrainingType =
    RecordUtils.auditableDefaultValuesFactory<InstructorTrainingType>({
        instructorLedTrainingType: 0,
        instructorProfileId: 0,
    });

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorTrainingTypeRecord extends Record(defaultValues) implements InstructorTrainingType {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorTrainingType>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the human readable training type.
     */
    public getTrainingTypeDisplayName(): string {
        if (this == null) {
            return "";
        }
        return t(InstructorLedTrainingTypeDisplayNames[this.instructorLedTrainingType]);
    }

    public with(values: Partial<InstructorTrainingType>): InstructorTrainingTypeRecord {
        return new InstructorTrainingTypeRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorTrainingTypeRecord };

// #endregion Exports
