import React from "react";
import { t } from "utilities/localization/t";
import "./event-registration-form-contract.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventRegistrationFormContractProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-registration-form-contract";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventRegistrationFormContract: React.FC<EventRegistrationFormContractProps> = (
    props: EventRegistrationFormContractProps
): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__text`}>
                {t(
                    "registrationInformationDoesNotApplyForContractEventsToViewMoreInformationRegardingTheContractVisitTheEventDetailsTab"
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventRegistrationFormContract };

// #endregion Exports
