import React, { useState } from "react";
import { Avatar, AvatarAspect } from "components/avatar/avatar";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { InstructorInfo } from "components/instructors/instructor-info/instructor-info";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./instructor-directory-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorDirectoryCardProps {
    instructor: InstructorRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-directory-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorDirectoryCard: React.FC<InstructorDirectoryCardProps> = ({
    instructor,
}): JSX.Element => {
    const [showModal, setShowModal] = useState(false);
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("contact"),
            onClick: () => window.open(`mailto:${instructor.email}`, "_blank"),
            style: ButtonStyle.Primary,
        },
        {
            buttonText: t("cancel"),
            onClick: () => setShowModal(false),
            style: ButtonStyle.Secondary,
        },
    ];
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__detail`}>
                <Paragraph size={ParagraphSize.Small} cssClassName="-user">
                    {instructor.displayName()}
                </Paragraph>
                <Paragraph
                    size={ParagraphSize.XSmall}
                    style={ParagraphStyle.Faded}
                    cssClassName="-user"
                >
                    {instructor.getCityStateText()}
                </Paragraph>
                <Paragraph
                    style={ParagraphStyle.Light}
                    size={ParagraphSize.XSmall}
                    cssClassName="-user"
                >
                    {instructor.getCountryText()}
                </Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__image-container`}>
                <Avatar
                    avatarFileId={instructor.instructorProfile?.avatarFileId}
                    avatarAspect={AvatarAspect.Portrait}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__info-button`}>
                <ButtonIcon
                    ariaLabelledBy={t("instructorInformation")}
                    buttonSize={ButtonSize.Small}
                    buttonStyle={ButtonStyle.Tertiary}
                    iconType={Icons.Information}
                    onClick={() => {
                        setShowModal(true);
                    }}
                    text=""
                />
            </div>
            <Modal
                actions={modalActionArray}
                isOpen={showModal}
                modalStyle={""}
                onModalClose={() => setShowModal(false)}
                title={t("instructorInfo")}
            >
                <InstructorInfo instructor={instructor} showProductsApprovedToTeach />
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorDirectoryCard };

// #endregion Exports
