import React from "react";
import { ButtonIcon, ButtonIconPosition } from "components/buttons/button-icon/button-icon";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ButtonSamplerProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ButtonSampler: React.FC<ButtonSamplerProps> = (): JSX.Element => {
    return (
        <div className="grid grid-cols-4 gap-5">
            <Button text={t("default")} style={ButtonStyle.Primary} size={ButtonSize.Medium} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button text={t("default")} style={ButtonStyle.Primary} size={ButtonSize.Small} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Primary}
                size={ButtonSize.Medium}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Primary}
                size={ButtonSize.Small}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Primary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button text={t("default")} style={ButtonStyle.Secondary} size={ButtonSize.Medium} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button text={t("default")} style={ButtonStyle.Secondary} size={ButtonSize.Small} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Secondary}
                size={ButtonSize.Medium}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Secondary}
                size={ButtonSize.Small}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button text={t("default")} style={ButtonStyle.Tertiary} size={ButtonSize.Medium} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button text={t("default")} style={ButtonStyle.Tertiary} size={ButtonSize.Small} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Tertiary}
                size={ButtonSize.Medium}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Tertiary}
                size={ButtonSize.Small}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Tertiary}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button text={t("default")} style={ButtonStyle.TertiaryAlt} size={ButtonSize.Medium} />
            <ButtonIcon
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button text={t("default")} style={ButtonStyle.TertiaryAlt} size={ButtonSize.Small} />
            <ButtonIcon
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.TertiaryAlt}
                size={ButtonSize.Medium}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.TertiaryAlt}
                size={ButtonSize.Small}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button text={t("default")} style={ButtonStyle.Destructive} size={ButtonSize.Medium} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button text={t("default")} style={ButtonStyle.Destructive} size={ButtonSize.Small} />
            <ButtonIcon
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Destructive}
                size={ButtonSize.Medium}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Medium}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />

            <Button
                text={t("default")}
                style={ButtonStyle.Destructive}
                size={ButtonSize.Small}
                disabled={true}
            />
            <ButtonIcon
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Left}
                disabled={true}
            />
            <ButtonIcon
                text={t("default")}
                buttonStyle={ButtonStyle.Destructive}
                buttonSize={ButtonSize.Small}
                iconType={Icons.Dot}
                iconPosition={ButtonIconPosition.Right}
                disabled={true}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ButtonSampler };

// #endregion Exports
