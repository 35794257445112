import React from "react";
import { Card } from "components/card/card";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { TopicBubble } from "components/bubble/topic-bubble";
import { Topic } from "models/enumerations/courses/topic";
import "./about-this-training.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AboutThisTrainingProps {
    description: string;
    name: string;
    badges?: string[];
    topics?: Topic[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "about-this-training";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AboutThisTraining: React.FC<AboutThisTrainingProps> = ({
    name,
    description,
    badges,
    topics,
}) => {
    return (
        <div className={CSS_CLASS_NAME}>
            <Card cssClassName={`${CSS_CLASS_NAME}__card`}>
                <div className={`${CSS_CLASS_NAME}__card__details`}>
                    <Paragraph size={ParagraphSize.XLarge}>{name}</Paragraph>
                    <Paragraph style={ParagraphStyle.Light}>{description}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__card__tags`}>
                    {topics?.map((topic, index) => (
                        <TopicBubble key={`about-tag-${index}`} topic={topic} />
                    ))}
                    {badges?.map((badge, index) => (
                        <Badge key={`${CSS_CLASS_NAME}-${index}`} style={BadgeStyle.Light}>
                            {badge}
                        </Badge>
                    ))}
                </div>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AboutThisTraining };

// #endregion Exports
