import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Icons } from "components/icons/constants/icons";
import { QuickLinkProps } from "components/quick-link/quick-link";
import { sitemap } from "sitemap";
import { SystemSettingsRecord } from "models/view-models/system-settings-record";
import { useMemo } from "react";

interface useLearnerQuickLinksHook {
    learnerQuickLinks: QuickLinkProps[];
}

interface useLearnerQuickLinksHookOptions {
    settings?: SystemSettingsRecord;
}

export function useLearnerQuickLinks(
    props: useLearnerQuickLinksHookOptions
): useLearnerQuickLinksHook {
    const learnerQuickLinks: QuickLinkProps[] = useMemo(() => {
        const links: QuickLinkProps[] = [
            {
                icon: Icons.Achievements,
                path: sitemap.learner.certificates,
                text: "certificates",
            },
            {
                icon: Icons.Catalog,
                path: props.settings?.ecommerceBaseUrl ?? "",
                pathType: AnchorPathType.External,
                text: "browseCatalog",
            },
            {
                icon: Icons.Support,
                path: sitemap.authenticated.support,
                text: "support",
            },
        ];
        return links;
    }, [props.settings?.ecommerceBaseUrl]);

    return {
        learnerQuickLinks,
    };
}
