import axios from "axios";
import { GroupUserRecord } from "models/view-models/groups/group-user-record";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";
import { useCancellablePromise } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "groupusers";
const resourceType: typeof GroupUserRecord = GroupUserRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateGroupUserService = (
    params: CreateGroupUserParams
) => Promise<ServiceResponse<GroupUserRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

// #endregion enum

export interface CreateGroupUserParams {
    groupId: number;
    userIds: number[];
}

export interface GroupUserResourcePathParams {
    id: number;
}
export interface ListGroupUsersRecordQueryParams {
    groupId?: number;
    searchText?: string;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const GroupUsersService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateGroupUserService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateGroupUserService = (
            params: CreateGroupUserParams
        ): Promise<ServiceResponse<GroupUserRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(params: CreateGroupUserParams): Promise<ServiceResponse<GroupUserRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<GroupUserRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<GroupUserRecord, GroupUserResourcePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<GroupUserRecord, ListGroupUsersRecordQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupUsersService };

// #endregion Exports
