import { AuditableRecord } from "models/auditable-record/auditable-record";
import { UserAnnouncement } from "models/interfaces/announcements/user-announcement";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: UserAnnouncement = {
    userId: 0,
    announcementId: 0,
    isRead: false,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class UserAnnouncementRecord extends AuditableRecord(defaultValues) {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<UserAnnouncement>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserAnnouncementRecord };

// #endregion Exports
