import React, { useMemo } from "react";
import { Transition } from "@headlessui/react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./admin-layout-header-announcements-panel.scss";
import AdminLayoutHeaderAnnouncementsPanelContent from "./admin-layout-header-announcements-panel-content/admin-layout-header-announcements-panel-content";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderAnnouncementsPanelProps {
    isOpen: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-announcements-panel";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderAnnouncementsPanel: React.FC<AdminLayoutHeaderAnnouncementsPanelProps> = (
    props: AdminLayoutHeaderAnnouncementsPanelProps
) => {
    const { record: globalState } = useGlobalState();
    const currentRoleType = useMemo(
        () => globalState.currentIdentity?.role?.roleType,
        [globalState.currentIdentity?.role?.roleType]
    );
    const isOpen: boolean = useMemo(
        (): boolean => props.isOpen && currentRoleType != null,
        [currentRoleType, props.isOpen]
    );

    return (
        <Transition.Root show={isOpen} as="div" className={CSS_CLASS_NAME}>
            <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className={`${CSS_CLASS_NAME}__overlay`} />
            </Transition.Child>
            <Transition.Child
                as="div"
                enter="transform transition ease-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <AdminLayoutHeaderAnnouncementsPanelContent isOpen={isOpen} />
            </Transition.Child>
        </Transition.Root>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AdminLayoutHeaderAnnouncementsPanel;
// #endregion Exports
