import React, { useCallback, useMemo, useState } from "react";
import { ButtonStyle, Button, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Contract } from "models/interfaces/contracts/contract";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import {
    ContractService,
    HasDuplicateContractNumberQueryParams,
} from "utilities/services/contracts/contract-service";
import { E164Number, isPossiblePhoneNumber } from "libphonenumber-js";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { NumberUtils } from "utilities/number-utils";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductSelectionCard } from "components/products/product-selection/product-selection-card";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./user-contracts-oll-new-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsOLLNewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-oll-new-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsOLLNewPage: React.FC<UserContractsOLLNewPageProps> = (): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { id: orgIdParam } = useParams();
    const orgId = useMemo(() => NumberUtils.parseInt(orgIdParam) ?? 0, [orgIdParam]);
    const navigate = useNavigate();
    const [contract, setContract] = useState<ContractRecord>(
        new ContractRecord({ organizationId: orgId })
    );
    const { create: createContractApi } = ContractService.useCreateOnlineLearning();
    const [emailError, setEmailError] = useState("");
    const [duplicateContractNumberError, setDuplicateContractNumberError] = useState("");
    const { get: hasDuplicateContractNumberApi } = ContractService.useHasDuplicateContractNumber();

    const updateContract = useCallback(
        (values: Partial<Contract>): void => {
            setContract(contract.with(values));
        },
        [contract]
    );

    const validateEmail = (email?: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
        } else {
            setEmailError("");
        }
    };

    const validatePhone = (phone?: string): boolean => {
        return !StringUtils.hasValue(phone) || isPossiblePhoneNumber(phone);
    };
    const handleContractDateChange = (date?: Date) => {
        updateContract({ contractDate: date });
    };

    const handleEnrollmentLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ enrollmentLimit: Number(event.target.value) });
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ nfpaAdminName: event.target.value });
    };

    const handleContractNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ contractNumber: event.target.value });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        validateEmail(event.target.value);
        updateContract({ nfpaAdminEmail: event.target.value });
    };

    const handlePhoneChange = (value?: E164Number | undefined): void => {
        const valueToSet = value === undefined ? "" : value;
        updateContract({ nfpaAdminPhone: valueToSet });
    };

    const handleProductChange = (product?: ProductRecord): void => {
        updateContract({ productId: product?.id, product: product });
    };

    const hasDuplicateContractNumber = useCallback(async (): Promise<void> => {
        setDuplicateContractNumberError("");
        if (contract.contractNumber == null || contract.contractNumber.length === 0) {
            setDuplicateContractNumberError("");
            return;
        }

        try {
            const hasDuplicateContractNumberPathParams = {};
            const hasDuplicateContractNumberQueryParams: HasDuplicateContractNumberQueryParams = {
                contractId: contract.id ?? 0,
                contractNumber: contract.contractNumber!,
            };
            const checkForDuplicateContractNumberResponse = await hasDuplicateContractNumberApi(
                hasDuplicateContractNumberPathParams,
                hasDuplicateContractNumberQueryParams
            );
            const checkForDuplicateContractNumberResult =
                checkForDuplicateContractNumberResponse?.result;
            if (
                checkForDuplicateContractNumberResult?.resultObject == null ||
                checkForDuplicateContractNumberResult.hasErrors()
            ) {
                throw new Error();
            }
            if (checkForDuplicateContractNumberResult.resultObject.valueOf() === true) {
                setDuplicateContractNumberError(t("aContractAlreadyExistsWithThisNumber"));
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueCheckingForDuplicateContractNumbers"));
        }
    }, [contract.contractNumber, contract.id, hasDuplicateContractNumberApi]);

    const validateContract = useCallback(
        (contract: ContractRecord): boolean => {
            // Required Fields
            if (
                contract.contractDate == null ||
                contract.organizationId == null ||
                contract.organizationId <= 0 ||
                contract.productId == null ||
                contract.productId <= 0 ||
                contract.enrollmentLimit == null ||
                contract.enrollmentLimit <= 0 ||
                !StringUtils.hasValue(contract.contractNumber) ||
                !StringUtils.hasValue(contract.nfpaAdminName) ||
                !StringUtils.hasValue(contract.nfpaAdminEmail) ||
                StringUtils.hasValue(emailError) ||
                StringUtils.hasValue(duplicateContractNumberError)
            ) {
                // ensure that phone number is valid if it is entered but allow it to be empty as well.
                if (
                    !StringUtils.isEmpty(contract.nfpaAdminPhone) &&
                    !validatePhone(contract.nfpaAdminPhone)
                ) {
                    return false;
                }

                return false;
            }

            return true;
        },
        [duplicateContractNumberError, emailError]
    );

    const contractIsValid = useMemo(() => validateContract(contract), [contract, validateContract]);

    const createContractRecord = async (): Promise<boolean> => {
        if (!contractIsValid) {
            throw new Error(t("thereWasAnIssueCreatingTheContract"));
        }

        try {
            const updateContractResponse = await createContractApi(contract);

            const updateResult = updateContractResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            navigate(onlineLearningContractsPath);
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingTheContractInformation"));
            return false;
        }
        return true;
    };

    const onlineLearningContractsPath = useMemo(
        () =>
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(
                    sitemap.admin.userManagement.contracts.onlineLearning.list,
                    { id: orgId }
                )
            ),
        [orgId]
    );
    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <h2>{t("newOnlineLearningContract")}</h2>
                        <Card stacked={true}>
                            <div className={`${CSS_CLASS_NAME}__grid`}>
                                <FormCalendarDatePicker
                                    placeholder="emptyDate"
                                    formFieldName="eventDate"
                                    label={t("contractDate")}
                                    onChange={handleContractDateChange}
                                    required={true}
                                    selectedDate={
                                        contract.contractDate
                                            ? new Date(contract.contractDate)
                                            : undefined
                                    }
                                />
                                <FormTextInput
                                    ariaLabelledBy={t("contractNumber")}
                                    errorMessage={duplicateContractNumberError}
                                    formFieldName="contractNumber"
                                    id="contractNumber"
                                    label={t("contractNumber")}
                                    maxLength={26}
                                    onBlur={hasDuplicateContractNumber}
                                    onChange={handleContractNumberChange}
                                    placeholder="00000"
                                    required={true}
                                    value={contract.contractNumber}
                                />
                                <FormTextInput
                                    ariaLabelledBy={t("enrollmentLimit")}
                                    formFieldName="enrollmentLimit"
                                    id="enrollmentLimit"
                                    label={t("enrollmentLimit")}
                                    maxLength={100}
                                    onChange={handleEnrollmentLimitChange}
                                    placeholder="00000"
                                    minValue={1}
                                    required={true}
                                    type={InputTypes.Number}
                                    value={contract.enrollmentLimit}
                                />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__grid`}>
                                <FormTextInput
                                    ariaLabelledBy={t("nfpaContactName")}
                                    formFieldName="nfpaAdminName"
                                    id="nfpaAdminName"
                                    label={t("nfpaContactName")}
                                    maxLength={150}
                                    onChange={handleNameChange}
                                    placeholder={t("nfpaContactName")}
                                    required={true}
                                    type={InputTypes.Text}
                                    value={contract.nfpaAdminName}
                                />
                                <FormTextInput
                                    ariaLabelledBy={t("nfpaContactEmail")}
                                    errorMessage={emailError}
                                    formFieldName="nfpaAdminEmail"
                                    id="nfpaAdminEmail"
                                    label={t("nfpaContactEmail")}
                                    maxLength={250}
                                    onChange={handleEmailChange}
                                    placeholder={t("nfpaContactEmail")}
                                    required={true}
                                    type={InputTypes.Email}
                                    value={contract.nfpaAdminEmail}
                                />
                                <FormPhoneInput
                                    ariaLabelledBy={t("nfpaContactPhone")}
                                    formFieldName="nfpaAdminPhone"
                                    id="nfpaAdminPhone"
                                    label={t("nfpaContactPhone")}
                                    onChange={handlePhoneChange}
                                    placeholder="(000) 000-0000"
                                    type={InputTypes.Phone}
                                    value={contract.nfpaAdminPhone}
                                />
                            </div>
                        </Card>
                        <ProductSelectionCard
                            product={contract.product}
                            onProductChange={handleProductChange}
                            trainingType={TrainingType.OnlineLearning}
                        />
                    </SkipNavContent>
                </div>
            </div>
            <div className="footer">
                <Button
                    linkPath={onlineLearningContractsPath}
                    style={ButtonStyle.Secondary}
                    text={t("cancel")}
                    type={ButtonType.Link}
                />
                <Button
                    disabled={!contractIsValid}
                    onClick={createContractRecord}
                    style={ButtonStyle.Primary}
                    text={t("createAContract")}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsOLLNewPage };

// #endregion Exports
