// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum AlertLevels {
    Error = "error",
    Info = "info",
    Success = "success",
    Warning = "warning",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AlertLevels;

// #endregion Exports
