import React from "react";
import "./donut.scss";
import { Paragraph } from "components/typography/paragraph/paragraph";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum DonutSize {
    Default = "",
    Large = "-large",
}

export enum DonutStyle {
    Default = "",
    Inverted = "-inverted",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DonutProps {
    cssClassName?: string;
    includeLabel?: boolean;
    percent: number;
    size?: DonutSize;
    style?: DonutStyle;
    subLabel?: string;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "donut";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Donut: React.FC<React.PropsWithChildren<DonutProps>> = (
    props: React.PropsWithChildren<DonutProps>
): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        classNames.push(props.cssClassName);
    }

    if (props.size) {
        classNames.push(props.size);
    }

    if (props.style) {
        classNames.push(props.style);
    }

    const className: string = classNames.join(" ");

    const percent = props.percent > 100 ? 100 : props.percent < 0 ? 0 : props.percent;
    const deg = Math.round(360 * (percent / 100));

    let chartClassName = percent > 50 ? "-over-half" : "-under-half";

    const style = {
        transform: `rotate(${deg}deg)`,
    };

    return (
        <div className={className} tabIndex={props.tabIndex}>
            <div className={`${CSS_CLASS_NAME}__wrapper`}>
                {props.includeLabel && props.size === DonutSize.Large && (
                    <Paragraph cssClassName={`${CSS_CLASS_NAME}__label`}>
                        {props.percent}%
                        {props.subLabel && (
                            <>
                                <br />
                                <span className="sublabel">{props.subLabel}</span>
                            </>
                        )}
                    </Paragraph>
                )}
                <div className={`${CSS_CLASS_NAME}__chart ${chartClassName}`}>
                    <div className={`${CSS_CLASS_NAME}__chart__side`} style={style}></div>
                    <div className={`${CSS_CLASS_NAME}__chart__side`}></div>
                </div>
                <div className={`${CSS_CLASS_NAME}__shadow`}></div>
            </div>
            {props.includeLabel && props.size !== DonutSize.Large && (
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__label`}>{props.percent}%</Paragraph>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Donut };

// #endregion Exports
