import React from "react";
import { Event } from "models/interfaces/events/event";
import { EventRegistrationForm } from "components/events/event-registration-form/event-registration-form";
import { EventRegistrationFormContract } from "components/events/event-registration-form/event-registration-form-contract/event-registration-form-contract";
import { EventRegistrationFormEn } from "components/events/event-registration-form/event-registration-form-en/event-registration-form-en";
import { EventType } from "models/enumerations/events/event-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import "./admin-edit-event-registration-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventRegistrationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-registration-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventRegistrationPage: React.FC<AdminEditEventRegistrationPageProps> =
    validatePageAccess(AccessControlKeys.AdminEditEventRegistrationPage)((): JSX.Element => {
        const event = useEventActiveRecordContext();
        useRedirectOnForbidden(sitemap.admin.event.edit.details);

        const updateEvent = (values: Partial<Event>): void => {
            if (event.isDraft()) {
                event.updateAndSave(values);
            } else {
                event.updateActiveRecord(values);
            }
        };

        const onHasNoRegistrationLimitToggle = () => {
            const newHasNoRegistrationLimit = !event.hasNoRegistrationLimit;

            if (newHasNoRegistrationLimit) {
                updateEvent({
                    hasNoRegistrationLimit: newHasNoRegistrationLimit,
                    maxRegistrations: undefined,
                    additionalRegistrationRequirements: "",
                    registrationUrl: undefined,
                });
            } else {
                updateEvent({ hasNoRegistrationLimit: newHasNoRegistrationLimit });
            }
        };

        return (
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("registration")}
                    </Heading>
                    {event.type === EventType.NFPACatalog && (
                        <ToggleLabel
                            checked={event.hasNoRegistrationLimit}
                            id="hasNoRegistrationLimit"
                            label={t("thisEventHasNoRegistrationLimit")}
                            onToggle={() => onHasNoRegistrationLimitToggle()}
                        />
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    {event.type === EventType.NFPACatalog && (
                        <EventRegistrationForm event={event} />
                    )}
                    {event.type === EventType.Contract && <EventRegistrationFormContract />}
                    {event.type === EventType.EducationNetwork && (
                        <EventRegistrationFormEn event={event} />
                    )}
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventRegistrationPage };

// #endregion Exports
