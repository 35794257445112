import React, { useEffect, useMemo } from "react";
import { AboutThisTraining } from "components/training-summary/about-this-training/about-this-training";
import { Card, CardStyle } from "components/card/card";
import { ContactCardList } from "components/training-summary/contact-card-list/contact-card-list";
import { CourseRow } from "components/training-summary/course-row/course-row";
import { DateUtils } from "utilities/date-utils";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Language } from "models/enumerations/languages/language";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { ProductCourseTypeDisplayNames } from "models/enumerations/courses/product-course-type";
import { ScrollUtils } from "utilities/scroll-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { SubmittedEvaluation } from "components/evaluations/submitted-evaluation/submitted-evaluation";
import { SummaryHeader } from "components/training-summary/summary-header/summary-header";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { UnitAccordion } from "components/training-summary/unit-accordion/unit-accordion";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import "./learner-training-summary-oll-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTrainingSummaryOLLPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-training-summary-oll-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTrainingSummaryOLLPage: React.FC<LearnerTrainingSummaryOLLPageProps> =
    validatePageAccess(AccessControlKeys.LearnerTrainingSummaryOLLPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.training.onlineLearning.details);
        const { enrollmentId: enrollmentIdParam } = useParams();
        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeCertificate: true,
            includeEvent: true,
            includeProduct: true,
            includeEvaluation: true,
            includeTrainingType: TrainingType.OnlineLearning,
        });

        const { units, enrollmentUnitCourses } = enrollment;

        const { contract } = useContract({
            contractId: enrollment.contractId,
            includeOrganization: true,
        });

        const evaluationSubmitted = useMemo(
            () => enrollment.evaluation?.id != null && enrollment.evaluation.id > 0,
            [enrollment.evaluation?.id]
        );

        const topics = useMemo(() => {
            const topics = enrollmentUnitCourses
                ?.filter((euc) => euc.unitCourse?.course?.topic != null)
                .map((euc) => euc.unitCourse?.course?.topic!);

            const distinctTopics = topics
                ?.filter((topic, index) => topics.indexOf(topic) === index)
                .sort();

            return distinctTopics;
        }, [enrollmentUnitCourses]);

        const badges = useMemo(() => {
            const badges: string[] = [];

            if (enrollment?.product?.type != null) {
                badges.push(t(TrainingTypeDisplayNames[enrollment.product.type]));
            }

            if (enrollment?.product?.onlineLearningType != null) {
                badges.push(
                    t(ProductCourseTypeDisplayNames[enrollment?.product.onlineLearningType])
                );
            }

            if (enrollment?.product?.language != null) {
                badges.push(Language[enrollment.product?.language]);
            }

            badges.push(
                t("ceusEnrollmentProductCreditHoursToString", {
                    enrollmentProductCreditHoursToString:
                        enrollment.productVersion?.getCEUs() ?? "0",
                })
            );

            return badges;
        }, [
            enrollment.product?.type,
            enrollment.product?.onlineLearningType,
            enrollment.product?.language,
            enrollment.productVersion,
        ]);

        const headerBadges = useMemo(() => {
            const badges: string[] = [];

            if (enrollment?.expirationDate != null) {
                badges.push(
                    t("trainingAccessExpDateUtilsFormatDatetrueenrollmentExpirationDateToString", {
                        DateUtilsFormatDatetrueenrollmentExpirationDateToString:
                            DateUtils.formatDate(true, enrollment.expirationDate.toString()),
                    })
                );
            }

            if (enrollment.certificate?.expirationDate != null) {
                badges.push(
                    t(
                        "certificateExpDateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString",
                        {
                            DateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString:
                                DateUtils.formatDate(
                                    true,
                                    enrollment.certificate.expirationDate.toString()
                                ),
                        }
                    )
                );
            }
            return badges;
        }, [enrollment.certificate?.expirationDate, enrollment.expirationDate]);

        useEffect(function scrollToTop() {
            ScrollUtils.scrollToTop();
        }, []);

        return (
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <h1>{t("trainingSummary")}</h1>
                    {contract.id != null && (
                        <div className={`${CSS_CLASS_NAME}__contract-banner`}>
                            <NotificationBanner
                                icon={Icons.InformationOutline}
                                notificationId={0}
                                onClose={() => {}}
                                style={NotificationType.Default}
                                message={t(
                                    "youHaveBeenAddedOnBehalfOfContractOrganizationNameYourDataMayBeSharedWithThisOrganization",
                                    { contractOrganizationName: contract.organization?.name }
                                )}
                            />
                        </div>
                    )}
                    <SummaryHeader
                        badges={headerBadges}
                        enrollmentStatus={enrollment.getStatus()}
                        icon={Icons.EventDetails}
                        title={t("aboutThisTraining")}
                    />
                    <AboutThisTraining
                        description={enrollment.product?.description ?? ""}
                        name={enrollment.product?.name ?? ""}
                        badges={badges}
                        topics={topics}
                    />
                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Assignment} title={t("syllabus")} />

                    {units.length > 1 && (
                        <>
                            {units?.map((unit, index) => (
                                <UnitAccordion
                                    key={`${unit.id}-${index}`}
                                    enrollmentUnitCourses={
                                        enrollmentUnitCourses?.filter(
                                            (euc) => euc.unitCourse?.unitId === unit.id
                                        ) ?? []
                                    }
                                    number={unit.sortOrder ?? 0}
                                    title={
                                        unit.name ??
                                        t("unitUnitSortOrder", { unitSortOrder: unit.sortOrder })
                                    }></UnitAccordion>
                            ))}
                        </>
                    )}

                    {units.length === 1 && (
                        <>
                            {enrollmentUnitCourses?.map((euc) => (
                                <Card key={euc.id} stacked={true} tight={true}>
                                    <CourseRow
                                        name={
                                            euc.unitCourse?.course?.name ??
                                            t("courseEucUnitCourseSortOrder", {
                                                eucUnitCourseSortOrder: euc.unitCourse?.sortOrder,
                                            })
                                        }
                                        number={euc.unitCourse?.sortOrder}
                                        score={euc.currentScore}
                                        required={!euc.optional}
                                        status={euc.status}
                                    />
                                </Card>
                            ))}
                        </>
                    )}

                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Evaluation} title={t("evaluation")} />
                    {!evaluationSubmitted && (
                        <Card style={CardStyle.Empty}>
                            <Icon type={Icons.EvaluationIncomplete} size={IconSizes.Large} />
                            <EmptyText spacing={EmptyTextSpacing.None}>
                                {t("theEvaluationWasNotSubmittedForThisTraining")}
                            </EmptyText>
                        </Card>
                    )}
                    {evaluationSubmitted && (
                        <SubmittedEvaluation evaluationId={enrollment.evaluation!.id!} />
                    )}

                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <SummaryHeader icon={Icons.Phone} title={t("contact")} />
                    <ContactCardList />
                </div>
            </SkipNavContent>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTrainingSummaryOLLPage };

// #endregion Exports
