import { ServiceHookFactory } from "andculturecode-javascript-react";
import { InstructorTravelTypeRecord } from "models/view-models/instructors/instructor-travel-type-record";

const baseEndpoint = "instructorTravelTypes";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType = InstructorTravelTypeRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const InstructorTravelTypeService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { InstructorTravelTypeService };

// #endregion Exports
