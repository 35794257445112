import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";
import { SelectOption } from "components/form/inputs/select/select";
import { ToastManager } from "utilities/toast/toast-manager";
import { UseAenInstructorApplications } from "./use-aen-instructor-applications";
import { useAenProviderApplications } from "./use-aen-provider-applications";
import { useEffect, useReducer } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { NumberUtils } from "utilities/number-utils";
import {
    AenApplicationsSearchReduceActionType,
    aenApplicationsSearchReducer,
} from "pages/admin/aen-applications/list-pages/aen-applications-search-reducer";
import { useAenUsers } from "./use-aen-users";

export const ITEMS_PER_PAGE = 10;

interface UseAenApplicationsOptions {
    applicationType: AenApplicationType;
}

export const useAenApplications = ({ applicationType }: UseAenApplicationsOptions) => {
    const [
        {
            searchText,
            debouncedSearchText,
            status,
            currentPage,
            assignedToId,
            hasCompletedInitialSearch,
            searchCriteriaReady,
        },
        dispatch,
    ] = useReducer(aenApplicationsSearchReducer, {
        searchText: "",
        currentPage: 1,
    });

    const { users, isLoading: isUsersLoading } = useAenUsers({ applicationType });

    const { record: globalState } = useGlobalState();

    const currentUserId = globalState.currentIdentity?.userId() ?? null;

    const getAssignedToNameById = (id: number) => {
        if (!users) return id;
        return users.find((user) => user.id === id)?.getFirstAndLastName() || id;
    };

    // Providers
    const {
        providerApplications,
        rowCount: totalProviderApplications,
        isLoading: isLoadingProviderApplications,
    } = useAenProviderApplications({
        searchText: debouncedSearchText,
        status: status as ProviderApplicationStatus,
        assignedToId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        isReady: applicationType === AenApplicationType.Provider && searchCriteriaReady,
        onError: (error) => ToastManager.error(error.message),
        isSearchCriteriaReady: !isUsersLoading,
    });

    // Providers
    const {
        instructorApplications,
        rowCount: totalInstructorApplications,
        isLoading: isLoadingInstructorApplications,
    } = UseAenInstructorApplications({
        searchText: debouncedSearchText,
        status: status as InstructorApplicationStatus,
        assignedToId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        isReady: applicationType === AenApplicationType.Instructor && searchCriteriaReady,
        onError: (error) => ToastManager.error(error.message),
        isSearchCriteriaReady: !isUsersLoading,
    });

    useEffect(() => {
        dispatch({ type: AenApplicationsSearchReduceActionType.ResetFilters });
    }, [applicationType]);

    useEffect(() => {
        if (assignedToId === undefined && currentUserId && !hasCompletedInitialSearch) {
            dispatch({
                type: AenApplicationsSearchReduceActionType.InitializeSearch,
                value: currentUserId,
            });
        }
    }, [currentUserId, assignedToId, hasCompletedInitialSearch]);

    const handleAssignedToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: AenApplicationsSearchReduceActionType.UpdateAssignToId,
            value: NumberUtils.parseInt(e.target.value),
        });
    };
    const handleSearchTextInputChange = (newSearchText: string) => {
        dispatch({
            type: AenApplicationsSearchReduceActionType.UpdateSearch,
            value: newSearchText,
        });
    };
    const handleSearchTriggered = (debouncedSearchText: string): void => {
        dispatch({
            type: AenApplicationsSearchReduceActionType.UpdateDebouncedSearch,
            value: debouncedSearchText,
        });
    };
    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: AenApplicationsSearchReduceActionType.UpdateStatus,
            value: NumberUtils.parseInt(e.target.value),
        });
    };
    const onClearFilterClick = () => {
        dispatch({ type: AenApplicationsSearchReduceActionType.ClearSearchCriteria });
    };
    const onPageClick = (pageNumber: number) => {
        dispatch({
            type: AenApplicationsSearchReduceActionType.UpdateCurrentPage,
            value: pageNumber,
        });
    };
    const assignedToOptions: SelectOption[] = users
        .map(
            (user): SelectOption => ({
                text: user.getFirstAndLastName(),
                value: user.id!.toString(),
            })
        )
        .filter((option) => option.text);

    return {
        providerApplications,
        instructorApplications,
        assignedToId,
        assignedToOptions,
        handleAssignedToChange,
        handleSearchTextInputChange,
        handleSearchTriggered,
        handleStatusChange,
        onClearFilterClick,
        searchText,
        debouncedSearchText,
        currentPage,
        onPageClick,
        totalProviderApplications,
        totalInstructorApplications,
        getAssignedToNameById,
        status,
        isLoadingInstructorApplications,
        isLoadingProviderApplications,
    };
};
