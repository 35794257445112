import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum EventChangeLogType {
    Published = 0,
    Canceled = 1,
    Contact = 2,
    Contract = 3,
    Instructor = 4,
    Location = 5,
    Name = 6,
    Registration = 7,
    Schedule = 8,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EventChangeLogTypeDisplayNames: Map<EventChangeLogType, TranslatedCopy> = {
    [EventChangeLogType.Published]: "published",
    [EventChangeLogType.Canceled]: "canceled",
    [EventChangeLogType.Contact]: "contact",
    [EventChangeLogType.Contract]: "contract",
    [EventChangeLogType.Instructor]: "instructor",
    [EventChangeLogType.Location]: "location",
    [EventChangeLogType.Name]: "name",
    [EventChangeLogType.Registration]: "registration",
    [EventChangeLogType.Schedule]: "schedule",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChangeLogType, EventChangeLogTypeDisplayNames };

// #endregion Exports
