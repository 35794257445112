import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "registrations";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const launchEndpoint: string = `${resourceEndpoint}/launch`;
const recordType: typeof RegistrationRecord = RegistrationRecord;
const resourceType: typeof RegistrationRecord = RegistrationRecord;
const launchLinkResourceType: typeof String = String;

// #endregion Constants

// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListRegistrationsQueryParams {
    includeAttempts?: boolean;
    includeScormPackage?: boolean;
    includeUser?: boolean;
    enrollmentId?: number;
    eventId?: number;
    scormPackageAssessmentId?: number;
    productId?: number;
    userId?: number;
}

export interface GetLaunchEndpointPathParams {
    id: number;
}

export interface GetRegistrationPathParams {
    id: number;
}

export interface GetRegistrationQueryParams {
    includeTraining?: boolean;
    includeUser?: boolean;
}

// #endregion Interfaces
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const RegistrationService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        RegistrationRecord,
        GetRegistrationPathParams,
        GetRegistrationQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(recordType, baseEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<RegistrationRecord, ListRegistrationsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook to obtain a SCORM Package Launch link.
     */
    useGetLaunchLink: ServiceHookFactory.useGet<String, GetLaunchEndpointPathParams>(
        launchLinkResourceType,
        launchEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RegistrationService };

// #endregion Exports
