import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { PatchInstructorApplicationRecord } from "models/view-models/aen-applications/patch-instructor-application-record";
import { ServiceHookFactory } from "../service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "aenapplication/instructors";
const resourceType: typeof InstructorApplicationRecord = InstructorApplicationRecord;
const patchResourceType: typeof PatchInstructorApplicationRecord = PatchInstructorApplicationRecord;
const resourceEndpoint: string = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListInstructorApplicationQueryParams {
    searchText?: string;
    status?: InstructorApplicationStatus;
    assignedToId?: number;
    skip?: number;
    take?: number;
}

export interface PatchInstructorApplicationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const InstructorApplicationService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        InstructorApplicationRecord,
        ListInstructorApplicationQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatch: ServiceHookFactory.usePatch<
        PatchInstructorApplicationRecord,
        PatchInstructorApplicationPathParams,
        PatchInstructorApplicationRecord
    >(patchResourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationService };

// #endregion Exports
