import { StringUtils } from "andculturecode-javascript-core";
import React from "react";
import { NavLink } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import "./anchor.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum AnchorPathType {
    Internal,
    External,
    Download,
}

export enum AnchorStyle {
    Default = "",
    Wide = "-wide",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export type AnchorProps = {
    active?: boolean;
    ariaLabel?: string;
    cssClassName?: string;
    disabled?: boolean;
    download?: string;
    path: string;
    pathType?: AnchorPathType.Internal | AnchorPathType.External | AnchorPathType.Download;
    style?: AnchorStyle;
    text?: string;
};

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ACTIVE_CLASS_NAME: string = "-active";
const CSS_CLASS_NAME: string = "anchor";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Anchor: React.FC<React.PropsWithChildren<AnchorProps>> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.active === true) {
        cssClassNames.push(ACTIVE_CLASS_NAME);
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.style) {
        cssClassNames.push(props.style);
    }

    const cssClassName: string = cssClassNames.join(" ");

    const content: JSX.Element = (
        <>
            {props.children}
            <span>{props.text}</span>
        </>
    );

    const { pathType = AnchorPathType.Internal } = props;

    // If disabled, just render a span as it shouldn't be clickable.
    if (props.disabled === true) {
        return <span className={cssClassName}>{content}</span>;
    }

    if (pathType === AnchorPathType.External) {
        return (
            <a
                aria-label={props.ariaLabel}
                className={cssClassName}
                href={props.path}
                rel="noreferrer"
                target="_blank">
                {content}
            </a>
        );
    }

    if (pathType === AnchorPathType.Download) {
        return (
            <a
                aria-label={props.ariaLabel}
                className={cssClassName}
                href={props.path}
                download={props.download ?? "#"}
                rel="noreferrer"
                target="_blank">
                {content}
            </a>
        );
    }

    const localizedPath: string = RouteUtils.localizePath(props.path);

    return (
        <NavLink aria-label={props.ariaLabel} className={cssClassName} to={localizedPath}>
            {content}
        </NavLink>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Anchor };

// #endregion Exports
