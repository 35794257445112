import { EventEnrollmentSummaryRecord } from "models/view-models/enrollments/event-enrollment-summary-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "events/:eventId/enrollments";
const resourceType: typeof EventEnrollmentSummaryRecord = EventEnrollmentSummaryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListEventEnrollmentSummariesPathParams {
    eventId: number;
}

export interface ListEventEnrollmentSummariesQueryParams {
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventEnrollmentSummariesService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EventEnrollmentSummaryRecord,
        ListEventEnrollmentSummariesPathParams,
        ListEventEnrollmentSummariesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventEnrollmentSummariesService };

// #endregion Exports
