import React from "react";
import { AenApplicationActivityLog } from "models/interfaces/aen-applications/aen-application-activity-log";
import { AenApplicationActivityLogRecord } from "models/view-models/aen-applications/aen-application-activity-log-record";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { t } from "utilities/localization/t";
import "./aen-application-add-notes-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationAddNotesFormProps {
    activityLog: AenApplicationActivityLogRecord;
    onActivityLogChanged: (log: AenApplicationActivityLogRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-application-add-notes-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationAddNotesForm: React.FC<AenApplicationAddNotesFormProps> = ({
    activityLog,
    onActivityLogChanged,
}) => {
    const updateActivityLog = (values: Partial<AenApplicationActivityLog>) => {
        onActivityLogChanged(activityLog.with(values));
    };

    const handleSetNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateActivityLog({ notes: e.target.value });
    };

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <FormTextArea
                    ariaLabelledBy={t("notes")}
                    formFieldName="notes"
                    id="notes"
                    label={t("notes")}
                    maxLength={1000}
                    onChange={handleSetNotes}
                    placeholder={t("enterDescription")}
                    rows={5}
                    value={activityLog.notes}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationAddNotesForm };

// #endregion Exports
