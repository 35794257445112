import React from "react";
import { ContentRecord } from "models/view-models/contents/content-record";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import {
    ProductContentAccess,
    ProductContentAccessDisplayNames,
} from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { RecordUtils } from "andculturecode-javascript-core";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./product-create-content-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCreateContentFormProps {
    content: ContentRecord;
    editing?: boolean;
    onContentChanged: (content: ContentRecord) => void;
    onProductContentChanged: (productContent: ProductContentRecord) => void;
    product: ProductRecord;
    productContent: ProductContentRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-create-content-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCreateContentForm: React.FC<ProductCreateContentFormProps> = (props) => {
    const isInstructorLed = props.product.type === TrainingType.InstructorLedTraining;
    const updateProductContent = (value: Partial<ProductContentRecord>): void => {
        props.onProductContentChanged(props.productContent.with(value));
    };

    const updateContent = (value: Partial<ContentRecord>): void => {
        props.onContentChanged(props.content.with(value));
    };

    const handleAccessChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProductContent(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ access: Number(value) }),
                ProductContentAccess
            )
        );
    };

    const handleSetFileId = (file: FileRecord) => {
        updateContent({
            file: RecordUtils.ensureRecord(file, FileRecord),
            fileId: file.id,
        });
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("theSelectedFileTypeIsNotPermitted"));
    };

    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateContent({ name: e.target.value });
    };

    const handleSetDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateContent({ description: e.target.value });
    };

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <FormTextInput
                    formFieldName="contentName"
                    id="contentName"
                    label={t("name")}
                    maxLength={60}
                    onChange={handleSetName}
                    placeholder={t("enterContentName")}
                    required={true}
                    type={InputTypes.Text}
                    value={props.content.name}
                />
                <FormTextArea
                    formFieldName="contentDescription"
                    id="contentDescription"
                    label={t("description")}
                    maxLength={1000}
                    onChange={handleSetDescription}
                    placeholder={t("enterContentDescription")}
                    required={true}
                    rows={5}
                    value={props.content.description}
                />
                {isInstructorLed && (
                    <FormSelect
                        ariaLabelledBy={t("contentAudience")}
                        disabled={props.editing}
                        formFieldName="contentAudience"
                        id="contentAudience"
                        label={t("contentAudience")}
                        onChange={handleAccessChange}
                        required={true}
                        options={EnumUtils.numericEnumToSelectOptions(
                            ProductContentAccess,
                            ProductContentAccessDisplayNames
                        )}
                        value={props.productContent.access?.toString()}
                    />
                )}
                <FormFileInput
                    buttonText={t("chooseFile")}
                    onFileInvalid={handleInvalidFile}
                    formFieldName="file"
                    id="file"
                    label={t("fileUpload")}
                    onFileUpload={handleSetFileId}
                    placeholder={t("selectFile")}
                    required={true}
                    storageContainer={StorageContainers.FileUpload}
                    value={props.content.fileId}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCreateContentForm };

// #endregion Exports
