import React, { useState, useCallback } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseRecord } from "models/view-models/courses/course-record";
import { Language } from "models/enumerations/languages/language";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import { UnitManageAddCourses } from "components/units/unit-courses/unit-add-course-modal/unit-manage-add-courses";
import { UnitRecord } from "models/view-models/units/unit-record";
import { t } from "utilities/localization/t";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { AudienceType } from "models/enumerations/audiences/audience-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitAddCourseModalProps {
    existingUnitCourses?: UnitCourseRecord[];
    fetchUnitCourseList: () => Promise<void>;
    handleSaveUnitCourses: (courseIds: number[], unit?: UnitRecord) => Promise<boolean>;
    language?: Language;
    onProductVersionUnitChange: (unit: UnitRecord, unitIndex?: number) => void;
    open: boolean;
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setUnit?: React.Dispatch<React.SetStateAction<UnitRecord | undefined>>;
    audienceType?: AudienceType;
    unit: UnitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitAddCourseModal: React.FC<UnitAddCourseModalProps> = (
    props: UnitAddCourseModalProps
): JSX.Element => {
    const { record: product } = useProduct();
    const { record: productVersion } = useProductVersion();
    const [coursesAvailable, setCoursesAvailable] = useState<CourseRecord[]>([]);
    const [coursesToAdd, setCoursesToAdd] = useState<CourseRecord[]>([]);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("done"),
            onClick: () => onUnitCourseSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setCoursesAvailable([]);
        setCoursesToAdd([]);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setCoursesAvailable([]);
            props.setOpen(false);
        }
    };

    const onUnitCourseSave = async (): Promise<void> => {
        const courseIds = coursesToAdd.map((c) => c.id!);
        if (courseIds.length === 0) {
            resetForm();
            props.setOpen(false);
            return;
        }
        const deferSave = product.status === ActiveStatus.Active;
        if (!productVersion) {
            return;
        }
        if (deferSave) {
            let whichUnit = props.unit;
            if (whichUnit === undefined) {
                return;
            }

            let maxSortOrder = 0;

            //find the max sort order from unitCourses
            if (whichUnit?.unitCourses === undefined || whichUnit?.unitCourses.length === 0) {
                maxSortOrder = 0;
            } else {
                const sortOrders = whichUnit?.unitCourses.map((uc) => uc.sortOrder!);
                maxSortOrder = sortOrders !== undefined ? Math.max(...sortOrders) : 0;
            }

            let whichUnitCourses = whichUnit.unitCourses;
            if (whichUnitCourses === undefined) {
                whichUnitCourses = [];
            }

            coursesToAdd.forEach((course) => {
                whichUnitCourses!.push(
                    new UnitCourseRecord({
                        unitId: whichUnit.id,
                        courseId: course.id,
                        sortOrder: (maxSortOrder += 1),
                        course: new CourseRecord({
                            id: course.id,
                            name: course.name,
                            description: course.description,
                        }),
                    })
                );
            });

            if (product.onlineLearningType === ProductCourseType.CourseSeries) {
                props.onProductVersionUnitChange(
                    props.unit.with({ unitCourses: whichUnitCourses })
                );
            } else {
                props.onProductVersionUnitChange(
                    props.unit.with({ unitCourses: whichUnitCourses }),
                    whichUnit.sortOrder! - 1
                );
            }
            props.setCourseHasChanges && props.setCourseHasChanges(true);

            //Close the modal
            resetForm();
            props.setOpen(false);
        } else {
            const saveSuccessful: boolean = await props.handleSaveUnitCourses(
                courseIds,
                props.unit
            );

            if (saveSuccessful) {
                resetForm();
                props.setOpen(false);
                props.fetchUnitCourseList();
            } else {
                return;
            }
        }
    };

    const sortByLastUpdatedDesc = (courses: CourseRecord[]) => {
        courses.sort((a, b) => {
            return b.getLastUpdatedInMilliseconds() - a.getLastUpdatedInMilliseconds();
        });
    };

    const handleAddCourseToQueue = useCallback(
        (course: CourseRecord): void => {
            const sortedCourseList = [...coursesToAdd, course];

            sortByLastUpdatedDesc(sortedCourseList);
            setCoursesToAdd(sortedCourseList);
            setDirty(true);
        },
        [coursesToAdd]
    );

    const handleRemoveCourseFromQueue = useCallback(
        (course: CourseRecord): void => {
            const filteredCourses = coursesToAdd.filter((c) => c.id !== course.id);
            sortByLastUpdatedDesc(filteredCourses);
            setCoursesToAdd(filteredCourses);

            if (filteredCourses.length === 0) {
                setDirty(false);
            }
        },
        [coursesToAdd]
    );

    const handleFetchCourses = useCallback(
        (courses: CourseRecord[]): void => {
            if (CollectionUtils.hasValues(props.existingUnitCourses)) {
                courses = courses.filter(
                    (c) => !props.existingUnitCourses?.some((epc) => epc.courseId === c.id)
                );
            }

            sortByLastUpdatedDesc(courses);
            setCoursesAvailable(courses);
        },
        [props.existingUnitCourses]
    );

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("addCourses")}
            actions={modalActionArray}
            modalStyle={""}>
            <UnitManageAddCourses
                courses={coursesAvailable}
                coursesToAdd={coursesToAdd}
                handleAddCourseToQueue={handleAddCourseToQueue}
                handleFetchCourses={handleFetchCourses}
                handleRemoveCourseFromQueue={handleRemoveCourseFromQueue}
                language={props.language}
                audienceType={props.audienceType}
                unit={props.unit!}
            />

            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitAddCourseModal };

// #endregion Exports
