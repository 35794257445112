import axios from "axios";
import { CreateInstructorApplicationEnrollmentDto } from "models/interfaces/enrollments/create-instructor-application-enrollment-dto";
import { InstructorApplicationEnrollmentRecord } from "models/view-models/enrollments/instructor-application-enrollment-record";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";
import { useCancellablePromise } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "instructorapplications/:instructorApplicationId/enrollments";
const withdrawEnrollmentEndpoint: string = `${baseEndpoint}/:instructorApplicationEnrollmentId/withdraw`;
const resourceType: typeof InstructorApplicationEnrollmentRecord =
    InstructorApplicationEnrollmentRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateEnrollmentService = (
    params: CreateInstructorApplicationEnrollmentDto,
    pathParams: InstructorApplicationEnrollmentPathParams
) => Promise<ServiceResponse<InstructorApplicationEnrollmentRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface InstructorApplicationEnrollmentPathParams {
    instructorApplicationId: number;
}

export interface ListInstructorApplicationEnrollmentsQueryParams {}

export interface WithdrawInstructorApplicationEnrollmentPathParams {
    instructorApplicationId: number;
    instructorApplicationEnrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const InstructorApplicationEnrollmentService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateEnrollmentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateEnrollmentService = (
            params: CreateInstructorApplicationEnrollmentDto,
            pathParams: InstructorApplicationEnrollmentPathParams
        ): Promise<ServiceResponse<InstructorApplicationEnrollmentRecord>> =>
            axios
                .post(
                    baseEndpoint.replace(
                        ":instructorApplicationId",
                        pathParams.instructorApplicationId.toString()
                    ),
                    params
                )
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));

        function create(
            params: CreateInstructorApplicationEnrollmentDto,
            pathParams: InstructorApplicationEnrollmentPathParams
        ): Promise<ServiceResponse<InstructorApplicationEnrollmentRecord>> {
            return cancellablePromise(serviceCreate(params, pathParams)) as Promise<
                ServiceResponse<InstructorApplicationEnrollmentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        InstructorApplicationEnrollmentRecord,
        InstructorApplicationEnrollmentPathParams,
        ListInstructorApplicationEnrollmentsQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Update an Enrollment's WithdrawnById and WithdrawnOn properties.
     */
    useWithdrawEnrollment: ServiceHookFactory.usePatch<
        InstructorApplicationEnrollmentRecord,
        WithdrawInstructorApplicationEnrollmentPathParams
    >(InstructorApplicationEnrollmentRecord, withdrawEnrollmentEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationEnrollmentService };

// #endregion Exports
