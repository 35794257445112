import React, { useMemo } from "react";
import { TextAreaProps } from "components/form/inputs/textarea/textarea";
import { TextArea } from "components/form/inputs/textarea/textarea";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import "./form-textarea.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormTextAreaProps extends FormFieldProps, TextAreaProps {
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    rows?: number;
    showCharactersRemaining?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-textarea";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormTextArea: React.FC<FormTextAreaProps> = (props: FormTextAreaProps) => {
    const charactersUsed = props.value?.length ?? 0;
    const charactersRemaining = (props.maxLength ?? 0) - charactersUsed;

    return (
        <FormField
            cssClassName={props.cssClassName}
            label={props.label}
            formFieldName={props.id}
            required={props.required}>
            <TextArea
                ariaLabelledBy={props.ariaLabelledBy}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                id={props.formFieldName}
                maxLength={props.maxLength}
                name={props.formFieldName}
                onBlur={props.onBlur}
                onChange={props.onChange}
                placeholder={props.placeholder}
                rows={props.rows}
                value={props.value}
            />
            {props.showCharactersRemaining && (
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__character-count`}
                    size={ParagraphSize.XSmall}>
                    {charactersRemaining}
                </Paragraph>
            )}
        </FormField>
    );
};

// #endregion Components

export { FormTextArea };
