import React from "react";
import { Card } from "components/card/card";
import { Button, ButtonType } from "components/buttons/button/button";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { sitemap } from "sitemap";
import "./support-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SupportCardProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "support-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SupportCard: React.FC<SupportCardProps> = (props): JSX.Element => {
    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__card-heading`}>
                <Heading priority={HeadingPriority.H3}>{t("nfpa")}</Heading>
                <Paragraph style={ParagraphStyle.Light}>
                    {t("forQuestionsAboutTheLMSContact")}
                </Paragraph>
            </div>
            <Button type={ButtonType.Link} linkPath={sitemap.authenticated.support}>
                {t("goToSupport")}
            </Button>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SupportCard };

// #endregion Exports
