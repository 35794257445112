import EventSelection from "components/event-selection/event-selection";
import GroupSelection from "components/group-selection/group-selection";
import React, { useEffect, useMemo } from "react";
import ReportContractSelection from "components/reports/components/report-contract-selection/report-contract-selection";
import ReportLearnerSelection from "components/reports/components/report-learner-selection/report-learner-selection";
import ReportOrganizationSelection from "components/reports/components/report-organization-selection/report-organization-selection";
import ReportProductSelection from "components/reports/components/report-product-selection/report-product-selection";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { EventsControllerIndexType } from "utilities/services/events/event-service";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardStyle,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import {
    initialState,
    initializer,
    reducer,
    validate,
} from "components/reports/report-transcript/report-transcript-reducer";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import "./report-transcript.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportTranscriptProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-transcript";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportTranscript: React.FC<ReportTranscriptProps> = ({
    onChange: onChangeReportCriteria,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const isClientAdmin = globalState?.currentIdentity?.isCurrentlyInRole(RoleType.ClientAdmin);
    const isAENProviderAdmin = globalState?.currentIdentity?.isCurrentlyInRole(
        RoleType.AenAdministrator
    );

    const hasAccessToAllFilters = useHasAccess(AccessControlKeys.CanUseAllTranscriptReportFilters);

    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(),
        validate,
    });

    const {
        contracts,
        events,
        groups,
        learners,
        organizations,
        products,
        displayType,
        reportType,
        dateRangeStart,
        dateRangeEnd,
        isValid,
    } = payload;

    if (isAENProviderAdmin) {
        payload.displayType = "summary";
    }

    const onDateRangeChange = (date: Array<Date | null> = []) => {
        const [start, end] = date;
        dispatch({ type: "dateRange", dateRangeStart: start, dateRangeEnd: end });
    };

    const organizationsIds = useMemo(() => {
        if (isClientAdmin) {
            return [globalState?.currentIdentity?.user?.organizationId ?? 0];
        }

        return payload.organizations.filter((id) => id != null).map((org) => org.id!);
    }, [globalState?.currentIdentity?.user?.organizationId, isClientAdmin, payload.organizations]);

    useEffect(() => {
        onChangeReportCriteria({
            reportType,
            displayType,
            dateRangeStart,
            dateRangeEnd,
            organizationIds: payload.organizations.map((org) => org.id),
            contractIds: payload.contracts.map((contract) => contract.id),
            eventIds: payload.events.map((event) => event.id),
            groupIds: payload.groups.map((group) => group.id),
            learnerIds: payload.learners.map((learner) => learner.id),
            productIds: payload.products.map((product) => product.id),
            isValid: isValid,
        });
    }, [
        dateRangeEnd,
        dateRangeStart,
        displayType,
        isValid,
        onChangeReportCriteria,
        payload.organizations,
        payload.contracts,
        payload.events,
        payload.groups,
        payload.learners,
        payload.products,
        reportType,
    ]);

    // region Translatable Constants
    const DISPLAY_OPTIONS: RadioCard[] = [
        {
            icon: Icons.Reporting,
            text: "summary",
            value: "1",
        },
        {
            icon: Icons.Reporting,
            text: "detailedBreakdown",
            value: "2",
        },
    ];

    // endregion Translatable Constants

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <h2>{t("learnerTranscriptReport")}</h2>

                            {(hasAccessToAllFilters || isAENProviderAdmin) && (
                                <>
                                    <ReportLearnerSelection
                                        users={learners}
                                        onUsersChanged={(learners) =>
                                            dispatch({ type: "updateLearners", learners: learners })
                                        }
                                    />
                                </>
                            )}
                            {hasAccessToAllFilters && (
                                <>
                                    <GroupSelection
                                        groups={groups}
                                        onGroupsChanged={(groups) =>
                                            dispatch({ type: "updateGroups", groups: groups })
                                        }
                                    />

                                    {!isClientAdmin && (
                                        <ReportOrganizationSelection
                                            organizations={organizations}
                                            onOrganizationsChanged={(organizations) =>
                                                dispatch({
                                                    type: "updateOrganizations",
                                                    organizations,
                                                })
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {(hasAccessToAllFilters || isClientAdmin) && (
                                <ReportContractSelection
                                    contracts={contracts}
                                    onContractsChanged={(contracts) =>
                                        dispatch({ type: "updateContracts", contracts })
                                    }
                                    filterByOrganizationIds={organizationsIds}
                                    filterByOrgRequired={true}
                                />
                            )}
                            <>
                                <EventSelection
                                    events={events}
                                    onEventsChanged={(events) =>
                                        dispatch({ type: "updateEvents", events: events })
                                    }
                                    type={EventsControllerIndexType.TranscriptReport}
                                />
                            </>
                            {(hasAccessToAllFilters || isAENProviderAdmin) && (
                                <>
                                    <ReportProductSelection
                                        includeAvailableForAENOnly={isAENProviderAdmin}
                                        products={products}
                                        onProductsChanged={(products) =>
                                            dispatch({ type: "updateProducts", products: products })
                                        }
                                    />
                                </>
                            )}
                            <>
                                <div className="report-section half">
                                    <Paragraph size={ParagraphSize.Large}>
                                        {t("inThisTimePeriod")}
                                    </Paragraph>
                                    <FormCalendarDateRangePicker
                                        endDate={dateRangeEnd}
                                        formFieldName="dateRange"
                                        handleEventDateRangeChange={onDateRangeChange}
                                        label={t("dateRange")}
                                        startDate={dateRangeStart}
                                    />
                                </div>
                                {!isAENProviderAdmin && (
                                    <div className="report-section">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("andDisplayedAs")}
                                        </Paragraph>
                                        <FormRadioCardList
                                            id="displayType"
                                            formFieldName="displayType"
                                            label={t("displayType")}
                                            labelScreenReaderOnly={true}
                                            iconSize={IconSizes.Base}
                                            onChange={(displayType) =>
                                                dispatch({
                                                    type: "displayType",
                                                    displayType: displayType,
                                                })
                                            }
                                            radioCards={DISPLAY_OPTIONS}
                                            required={true}
                                            style={RadioCardStyle.Horizontal}
                                            value={displayType}
                                        />
                                    </div>
                                )}
                            </>
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportTranscript };

// #endregion Exports
