import { ServiceHookFactory } from "andculturecode-javascript-react";
import { ReportDownloadRecord } from "models/view-models/reports/report-download-record";
import { ReportDownloadRequestRecord } from "models/view-models/reports/report-download-request-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "reports/downloads";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType: typeof ReportDownloadRecord = ReportDownloadRecord;
const requestResourceType: typeof ReportDownloadRequestRecord = ReportDownloadRequestRecord;

// #endregion Constants

// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListReportDownloadsQueryParams {}

export interface GetReportDownloadsPathParams {
    id: number;
}

export interface GetReportDownloadsQueryParams {
    id: number;
}

// #endregion Interfaces
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ReportDownloadService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ReportDownloadRecord, ListReportDownloadsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        ReportDownloadRecord,
        GetReportDownloadsPathParams,
        GetReportDownloadsQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(requestResourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportDownloadService };

// #endregion Exports
