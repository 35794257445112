import React, { useMemo } from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventDayCard } from "./event-day-card/event-day-card";
import { EventScheduleRecord } from "models/view-models/events/event-schedule-record";
import { t } from "utilities/localization/t";
import "./event-schedule-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleSummaryProps {
    event: EventActiveRecord;
    onEventDayEditRequest: (index?: number) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleSummary: React.FC<EventScheduleSummaryProps> = ({
    event,
    onEventDayEditRequest,
}): JSX.Element => {
    const eventSchedule = useMemo(
        () =>
            new EventScheduleRecord().with({
                eventId: event.id,
                eventDays: event.eventDays,
            }),
        [event.eventDays, event.id]
    );

    const handleEditEventDay = (index: number) => {
        onEventDayEditRequest(index);
    };

    const handleDeleteEventDay = (index: number): void => {
        event.eventDays?.splice(index, 1);

        if (event.isDraft()) {
            event.updateAndSave({ eventDays: event.eventDays });
        } else {
            event.updateActiveRecord({ eventDays: event.eventDays });
        }
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <h3>{t("scheduleSummary")}</h3>

            <div className={`${CSS_CLASS_NAME}__content`}>
                <div className={`${CSS_CLASS_NAME}__content__scroll`}>
                    {eventSchedule.eventDays !== undefined &&
                        eventSchedule.eventDays.map((eventDay, index) => {
                            return (
                                <EventDayCard
                                    allDaySessions={event.hasAllDaySessions}
                                    eventDay={eventDay}
                                    handleEditEventDay={() => handleEditEventDay(index)}
                                    handleDeleteEventDay={() => handleDeleteEventDay(index)}
                                    key={eventDay.getUniqueIdentifier()}
                                />
                            );
                        })}
                    {eventSchedule.eventDays === undefined ||
                        (eventSchedule.eventDays.length === 0 && (
                            <div className={`${CSS_CLASS_NAME}__empty-schedule-message`}>
                                {t("asYouAddDaysAndSessionsToYourEventScheduleTheyWillAppearHere")}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleSummary };

// #endregion Exports
