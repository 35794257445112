import { EventContentList } from "components/events/event-content-list/event-content-list";
import { PhysicalContentDisplay } from "components/events/event-physical-content-display/event-physical-content-display";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { NumberUtils } from "utilities/number-utils";
import { RoleType } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import "./manage-live-virtual-event-content-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-content-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventContentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventContentPage: React.FC<ManageLiveVirtualEventContentPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventContentPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { id } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
        const { record: GlobalState } = useGlobalState();
        const userRole = GlobalState?.currentIdentity?.role?.roleType;
        const showPhysicalMaterials = userRole === RoleType.AenAdministrator;

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Content} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("content")}
                    </Heading>
                </div>
                <div
                    className={
                        userRole === RoleType.AenAdministrator
                            ? `${CSS_CLASS_NAME}__content-section`
                            : `${CSS_CLASS_NAME}__content-section-non-aen`
                    }>
                    <div className={`${CSS_CLASS_NAME}__content-item`}>
                        <EventContentList
                            productContentAccess={ProductContentAccess.InstructorProvider}
                            eventId={eventId}
                            title={t("downloadsForAdministratorAndInstructor")}
                            contentColumns={showPhysicalMaterials ? 1 : 2}
                        />
                        <EventContentList
                            productContentAccess={ProductContentAccess.Everyone}
                            eventId={eventId}
                            title={t("downloadsForParticipants")}
                            contentColumns={showPhysicalMaterials ? 1 : 2}
                        />
                    </div>
                    {showPhysicalMaterials ? (
                        <div className={`${CSS_CLASS_NAME}__content-item`}>
                            <PhysicalContentDisplay />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventContentPage };

// #endregion Exports
