import { GroupRecord } from "models/view-models/groups/group-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface GroupIndexQueryParams {
    searchText?: string;
    skip?: number;
    take?: number;
}

export interface GroupResourcePathParams {
    id: number;
}

export interface GroupResourceQueryParams {}

export interface UpdateGroupPathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "groups";
const resourceType = GroupRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const GroupService = {
    /**
     * Custom hook for leveraging service Get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        GroupRecord,
        GroupResourcePathParams,
        GroupResourceQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in React components
     */
    useList: ServiceHookFactory.useList<GroupRecord, GroupIndexQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service update calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<GroupRecord, UpdateGroupPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate<GroupRecord>(resourceType, baseEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { GroupService };

// #endregion Exports
