import React from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Status, StatusTranslationKeyMap } from "utilities/enumerations/statuses";
import { StringUtils } from "utilities/string-utils";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface StatusBadgeProps {
    cssClassName?: string;
    status: Status;
    text?: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const STATUS_STYLE_MAP: Map<Status, BadgeStyle> = {
    [Status.Default]: BadgeStyle.Default,
    [Status.Error]: BadgeStyle.Error,
    [Status.Inverted]: BadgeStyle.Inverted,
    [Status.Neutral]: BadgeStyle.Neutral,
    [Status.Success]: BadgeStyle.Success,
    [Status.Warning]: BadgeStyle.Warning,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const StatusBadge: React.FC<StatusBadgeProps> = (props: StatusBadgeProps): JSX.Element => {
    const translatedCopyKey: TranslatedCopy = StringUtils.hasValue(props.text) ? props.text : StatusTranslationKeyMap[props.status];

    return (
        <Badge
            cssClassName={props.cssClassName}
            style={STATUS_STYLE_MAP[props.status]}
            text={t(translatedCopyKey)}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { StatusBadge };

// #endregion Exports
