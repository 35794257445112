import React from "react";
import { CalendarDateRangePicker } from "components/calendar-date-range-picker/calendar-date-range-picker";
import { FormField, FormFieldProps } from "components/form/form-field/form-field";
import { StringUtils } from "andculturecode-javascript-core";
import "react-datepicker/dist/react-datepicker.css";
import "./form-calendar-date-range-picker.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormCalendarDateRangePickerProps extends FormFieldProps {
    endDate: Date | null;
    formFieldName: string;
    handleEventDateRangeChange: (date?: Array<Date | null>) => void;
    label: string;
    placeholder?: string;
    startDate: Date | null;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-calendar-date-range-picker";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormCalendarDateRangePicker: React.FC<FormCalendarDateRangePickerProps> = (props) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <FormField
            cssClassName={className}
            label={props.label}
            formFieldName={props.formFieldName}
            required={props.required}>
            <CalendarDateRangePicker
                endDate={props.endDate}
                handleEventDateRangeChange={props.handleEventDateRangeChange}
                startDate={props.startDate}
                placeholder={props.placeholder}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormCalendarDateRangePicker };

// #endregion Exports
