import React, { useEffect, useReducer } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { HeaderBanner, HeaderBannerNavItem } from "components/header-banner/header-banner";
import { HeaderBannerUtils } from "utilities/header-banner-utils";
import { Icons } from "components/icons/constants/icons";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { groupSearchReducer } from "./group-search-reducer";
import { sitemap } from "../../../../../sitemap";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { CollectionUtils } from "utilities/collection-utils";
import { useGroups } from "utilities/hooks/models/groups/use-groups";
import "./groups-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupsListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "groups-list-page";
const ITEMS_PER_PAGE = 20;
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupsListPage: React.FC<GroupsListPageProps> = validatePageAccess(
    AccessControlKeys.GroupsListPage
)((): JSX.Element => {
    const navItems: HeaderBannerNavItem[] = HeaderBannerUtils.getUserManagementNavItems();

    const [{ searchText, debouncedSearchText, currentPage }, dispatch] = useReducer(
        groupSearchReducer,
        { searchText: "", currentPage: 1 }
    );

    const {
        groups,
        rowCount: groupCount,
        isLoading,
    } = useGroups({
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const handleSearchTextInputChange = (search: string) => {
        dispatch({
            type: "update_search",
            value: search,
        });
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        dispatch({
            type: "update_debounced_search",
            value: debouncedSearchText,
        });
    };

    const onClearFilterClick = () => {
        dispatch({
            type: "clear_search_criteria",
        });
    };

    const onPageClick = (pageNumber: number) => {
        dispatch({
            type: "update_currentPage",
            value: pageNumber,
        });
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.content`);
    }, [groups]);

    const getViewGroupPath = (groupId: number) => {
        return RouteUtils.replacePathParams(sitemap.admin.userManagement.groups.info, {
            id: groupId,
        });
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner navItems={navItems} title={t("userManagement")}>
                <ButtonIcon
                    text={t("addNewGroup")}
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.TertiaryAlt}
                    buttonType={ButtonType.Link}
                    iconType={Icons.Plus}
                    linkPath={sitemap.admin.userManagement.groups.new}
                />
            </HeaderBanner>
            <SideContentLeftLayout
                sidebarElement={
                    <div className={`${CSS_CLASS_NAME}__sidebar`}>
                        <Paragraph style={ParagraphStyle.Label}>{t("filterResults")}</Paragraph>
                        <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                disabled={isLoading}
                                onSearchTextInputChange={handleSearchTextInputChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"groupSearch"}
                                placeholder={t("searchByName")}
                                searchTextInputValue={searchText}
                            />
                        </div>
                        <Button
                            onClick={onClearFilterClick}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Quaternary}
                            text={t("clearAllFilters")}
                        />
                    </div>
                }>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            {CollectionUtils.hasValues(groups) ? (
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="name">{t("name")}</th>
                                            <th className="lms-id">{t("lmsID")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groups.map((group) => (
                                            <tr key={`group-row-${group.id}`}>
                                                <td className="name">{group.name}</td>
                                                <td className="lms-id">{group.id}</td>
                                                <td className="action">
                                                    <ContextMenu>
                                                        <ContextMenuAnchor
                                                            hrefPath={getViewGroupPath(
                                                                group.id ?? 0
                                                            )}
                                                            displayName={t("viewGroup")}
                                                        />
                                                    </ContextMenu>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </DataTable>
                            ) : (
                                <EmptyText table>{t("searchOrFilterToSeeResults")}</EmptyText>
                            )}
                        </SkipNavContent>
                    </div>
                </div>
                {groups != null && groups.length > 0 && (
                    <div className="footer">
                        <Pager
                            currentPage={currentPage}
                            totalPages={groupCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={groupCount}
                        />
                    </div>
                )}
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupsListPage };

// #endregion Exports
