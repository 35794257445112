import { useEffect, useState } from "react";
interface AutoRefreshOptions {
    isActive: boolean;
    timeBetweenRefreshesInSeconds: number;
}
const useAutoPageRefresh = ({ isActive, timeBetweenRefreshesInSeconds }: AutoRefreshOptions) => {
    const [timeUntilNextRefresh, setTimeUntilNextRefresh] = useState<number>(
        timeBetweenRefreshesInSeconds
    );
    const timeUntilNextRefreshAsPercent =
        (timeUntilNextRefresh / timeBetweenRefreshesInSeconds) * 100;

    useEffect(() => {
        if (isActive) {
            if (timeUntilNextRefresh > 0) {
                const intervalId: NodeJS.Timeout = setTimeout(() => {
                    setTimeUntilNextRefresh(timeUntilNextRefresh - 1);
                }, 1000);
                return () => clearTimeout(intervalId);
            } else {
                window.location.reload();
            }
        }
    }, [timeUntilNextRefresh, isActive]);
    return {
        timeUntilNextRefresh,
        timeUntilNextRefreshAsPercent,
    };
};

export default useAutoPageRefresh;
