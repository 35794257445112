import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ComponentDecorator } from "utilities/types/decorators/component-decorator";
import { useRoleHasChanged } from "utilities/hooks/aspects/identity/use-role-has-changed";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const UNAUTHORIZED_CONTENT: JSX.Element = React.createElement(React.Fragment);

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Decorator
// -------------------------------------------------------------------------------------------------

const validatePageAccess =
    <TProps extends object>(accessControlKey: AccessControlKeys): ComponentDecorator<TProps> =>
    (Page: React.FC<TProps>): React.FC<TProps> =>
    (props: TProps): JSX.Element => {
        const navigate = useNavigate();
        const { record: globalState } = useGlobalState();
        const { currentlyHasRole, previouslyHadRole, roleHasChanged } = useRoleHasChanged();
        const isAuthorized = useHasAccess(accessControlKey);
        const [renderedComponent, setRenderedComponent] =
            useState<JSX.Element>(UNAUTHORIZED_CONTENT);

        useEffect(() => {
            if (globalState.isAwaitingPotentialAuthentication()) {
                setRenderedComponent(UNAUTHORIZED_CONTENT);
                return;
            }

            const redirectIfUnauthorizedPath: string = currentlyHasRole
                ? roleHasChanged
                    ? sitemap.root
                    : sitemap.public.noAccess
                : sitemap.public.userLogin;

            if (!isAuthorized) {
                navigate(RouteUtils.localizePath(redirectIfUnauthorizedPath));
                setRenderedComponent(UNAUTHORIZED_CONTENT);
                return;
            }

            setRenderedComponent(React.createElement(Page, props));

            if (roleHasChanged && previouslyHadRole) {
                window.location.reload();
            }
        }, [
            currentlyHasRole,
            globalState,
            isAuthorized,
            navigate,
            previouslyHadRole,
            props,
            roleHasChanged,
        ]);

        return renderedComponent;
    };

// #endregion Decorator

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { validatePageAccess };

// #endregion Exports
