import { ProductVersion } from "models/interfaces/products/product-version";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type ProductVersionRecordUpdater = RecordUpdater<ProductVersion, ProductVersionRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: ProductVersionZeroContext, useRecord: useProductVersionZero } =
    UseRecordContextFactory.build<ProductVersion, ProductVersionRecord>(ProductVersionRecord);

export { ProductVersionZeroContext, useProductVersionZero };

// #endregion Exports
