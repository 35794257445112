import React from "react";
import { Transition } from "@headlessui/react";
import { AnnouncementDetails } from "./announcement-details";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import { Language } from "models/enumerations/languages/language";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Announcement from "./announcement";

interface AnnouncementManagerProps {
    isOpen: boolean;
    announcementData: AnnouncementRecord;
}

const AnnouncementManager: React.FC<AnnouncementManagerProps> = ({ isOpen, announcementData }) => {
    const [announcementIsOpen, setAnnouncementIsOpen] = React.useState(false);
    const { record: globalState } = useGlobalState();

    const language = globalState?.currentIdentity?.user!.preferredLanguage ?? Language.English;
    const [html, title] = announcementData.getPreferredBodyAndTitleOrDefaultEnglish(
        language,
        announcementData
    );
    return (
        <>
            <Announcement
                title={title ?? ""}
                isOpen={announcementIsOpen}
                setIsOpen={setAnnouncementIsOpen}
            />
            <Transition.Root show={isOpen} as={React.Fragment}>
                <Transition.Child
                    as="div"
                    enter="transition-all ease-out duration-300"
                    enterFrom="w-0"
                    enterTo="w-[320px]"
                    leave="transition-all ease-out duration-300"
                    leaveFrom="w-[320px]"
                    leaveTo="w-0"
                    className="right-[320px] -sm:right-[0] top-0 absolute overflow-x-hidden">
                    <AnnouncementDetails
                        isOpen={announcementIsOpen}
                        setIsOpen={setAnnouncementIsOpen}
                        title={title ?? ""}
                        details={html ?? ""}
                    />
                </Transition.Child>
            </Transition.Root>
        </>
    );
};

export { AnnouncementManager };
