import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum AddBulkEnrollmentModalStage {
    Complete = 0,
    Errored = 1,
    Processing = 2,
    Upload = 3,
    Preview = 4,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentModalStageStatusMap: Map<AddBulkEnrollmentModalStage, TranslatedCopy> = {
    [AddBulkEnrollmentModalStage.Complete]: "complete",
    [AddBulkEnrollmentModalStage.Errored]: "error",
    [AddBulkEnrollmentModalStage.Processing]: "processing",
    [AddBulkEnrollmentModalStage.Upload]: "emptyString",
    [AddBulkEnrollmentModalStage.Preview]: "preview",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentModalStage, AddBulkEnrollmentModalStageStatusMap };

// #endregion Exports
