import { useCallback, useEffect, useState } from "react";
import { EventProductSelectionList } from "components/events/event-product-selection/event-product-selection-modal/event-product-selection-list/event-product-selection-list";
import { EventType } from "models/enumerations/events/event-type";
import { ProductRecord } from "models/view-models/products/product-record";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import {
    ListProductsQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-manage-add-product.scss";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventManageAddProductsProps {
    event: EventActiveRecord;
    handleAddProductToQueue: (product: ProductRecord) => void;
    handleFetchProducts: (product: ProductRecord[]) => void;
    handleRemoveProductFromQueue: (product: ProductRecord) => void;
    products: ProductRecord[];
    selectedProduct?: ProductRecord;
}

export interface SelectableProduct {
    isSelected: boolean;
    product: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-manage-add-product";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "typeToSearchByProductNameOrID";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventManageAddProducts: React.FC<EventManageAddProductsProps> = (
    props: EventManageAddProductsProps
) => {
    const [searchText, setSearchText] = useState<string>("");
    const [selectableProducts, setSelectableProducts] = useState<SelectableProduct[]>([]);
    const { list: listProducts } = ProductService.useList();
    const handleFetchProducts = props.handleFetchProducts;

    const filterProducts = useCallback(
        async (searchText: string) => {
            try {
                let listProductsQueryParams: ListProductsQueryParams = {
                    searchText: searchText,
                    applySearch: true,
                    activeStatusFilter: [ActiveStatus.Active],
                    trainingType: TrainingType.InstructorLedTraining,
                };

                if (props.event.type === EventType.EducationNetwork) {
                    listProductsQueryParams = {
                        searchText: searchText,
                        applySearch: true,
                        activeStatusFilter: [ActiveStatus.Active],
                        includeAvailableForAENOnly: true,
                        trainingType: TrainingType.InstructorLedTraining,
                    };
                }

                const listFilteredProductsResponse = await listProducts(listProductsQueryParams);
                const listFilteredProductsResults = listFilteredProductsResponse?.results;
                const products = listFilteredProductsResponse?.resultObjects;
                if (
                    products == null ||
                    listFilteredProductsResults == null ||
                    listFilteredProductsResults.hasErrors()
                ) {
                    throw new Error();
                }

                products.sort((a, b) => a.name.localeCompare(b.name));

                handleFetchProducts(products);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProducts"));
                handleFetchProducts([]);
            }
        },
        [handleFetchProducts, listProducts, props.event.type]
    );

    const convertProductToSelectableProduct = useCallback(
        (products: ProductRecord[]): SelectableProduct[] => {
            return products.map(
                (p): SelectableProduct => ({
                    product: p,
                    isSelected: props.selectedProduct?.id === p.id,
                })
            );
        },
        [props.selectedProduct?.id]
    );

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    useEffect(() => {
        const selectableProducts = convertProductToSelectableProduct(props.products);
        setSelectableProducts(selectableProducts);
    }, [convertProductToSelectableProduct, props.products, props.selectedProduct]);

    return (
        <div className={CSS_CLASS_NAME}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={filterProducts}
                id={"productSearch"}
                placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                searchTextInputValue={searchText}
            />
            <EventProductSelectionList
                selectableProducts={selectableProducts}
                handleAdd={props.handleAddProductToQueue}
                handleRemove={props.handleRemoveProductFromQueue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventManageAddProducts };

// #endregion Exports
