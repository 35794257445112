import React, { useState } from "react";
import { Button, ButtonType, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { useInstructorApprovedProducts } from "utilities/hooks/models/instructors/use-instructor-approved-products";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { CollectionUtils } from "utilities/collection-utils";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    ProductApprovalStatusDisplayNames,
    ProductApprovalStatusStatusMap,
} from "models/instructors/product-approval-status";
import { Pager } from "components/pager/pager";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import "./instructor-enh-approved-to-teach-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorENHApprovedToTeachPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-enh-approved-to-teach-page";
const ITEMS_PER_PAGE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorENHApprovedToTeachPage: React.FC<
    InstructorENHApprovedToTeachPageProps
> = (): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {
        instructorApprovedProducts,
        rowCount: approvedProductsCount,
        isLoading,
    } = useInstructorApprovedProducts({
        userId: globalState.currentIdentity?.userId() ?? 0,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("approved")}
                </Heading>
            </div>
            <div className={`${CSS_CLASS_NAME}__section`}>
                {CollectionUtils.hasValues(instructorApprovedProducts) && (
                    <>
                        <DataTable>
                            <thead>
                                <tr>
                                    <th className="product">{t("product")}</th>
                                    <th className="status">{t("status")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {instructorApprovedProducts.map((approvedProduct) => (
                                    <tr key={approvedProduct.id}>
                                        <td className="product">{approvedProduct.product?.name}</td>
                                        <td className="status">
                                            {approvedProduct.status != null && (
                                                <EnumStatusBadge
                                                    displayNames={ProductApprovalStatusDisplayNames}
                                                    statusMap={ProductApprovalStatusStatusMap}
                                                    value={approvedProduct.status!}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {approvedProduct.enrollmentId != null &&
                                                approvedProduct.enrollment?.productId != null && (
                                                    <Button
                                                        style={ButtonStyle.Primary}
                                                        size={ButtonSize.Small}
                                                        type={ButtonType.Link}
                                                        linkPath={RouteUtils.replacePathParams(
                                                            sitemap.learner.training.onlineLearning
                                                                .details,
                                                            {
                                                                productId:
                                                                    approvedProduct.enrollment
                                                                        ?.productId,
                                                                enrollmentId:
                                                                    approvedProduct.enrollmentId,
                                                            }
                                                        )}
                                                        text={t("reviewTraining")}
                                                    />
                                                )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </DataTable>
                        <div className={`${CSS_CLASS_NAME}__pager`}>
                            <Pager
                                footer={true}
                                currentPage={currentPage}
                                totalPages={approvedProductsCount / ITEMS_PER_PAGE}
                                onPageClick={onPageClick}
                                itemsPerPage={ITEMS_PER_PAGE}
                                totalItems={approvedProductsCount}
                            />
                        </div>
                    </>
                )}
                {!CollectionUtils.hasValues(instructorApprovedProducts) && !isLoading && (
                    <EmptyText table spacing={EmptyTextSpacing.Small}>
                        {t("youAreCurrentlyNotApprovedToTeachAnyTrainings")}
                    </EmptyText>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorENHApprovedToTeachPage };

// #endregion Exports
