import { UserRecord } from "models/view-models/users/user-record";
import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { RoleType } from "models/enumerations/users/role-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface UserIndexQueryParams {
    excludeUsersInGroup?: boolean;
    groupId?: number;
    hasNoUserRoles?: boolean;
    includeInstructorsAssociatedWithProvider?: boolean;
    includeLastLogin?: boolean;
    includeOrganization?: boolean;
    includeProvider?: boolean;
    includeRoles?: boolean;
    providerId?: number;
    roleTypes?: RoleType[];
    searchText?: string;
    skip?: number;
    take?: number;
}

export interface UserResourcePathParams {
    id: number;
}

export interface UserResourceQueryParams {
    includeOrganization?: boolean;
    includeProvider?: boolean;
    includeRoles?: boolean;
    includeInstructorProfile?: boolean;
    syncdata?: boolean;
}

export interface UpdateUserPathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "users";
const resourceType = UserRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserService = {
    get: ServiceFactory.get<UserRecord, UserResourcePathParams>(resourceType, resourceEndpoint),
    list: ServiceFactory.list<UserRecord, UserIndexQueryParams>(resourceType, baseEndpoint),
    useGet: ServiceHookFactory.useGet<UserRecord, UserResourcePathParams, UserResourceQueryParams>(
        resourceType,
        resourceEndpoint
    ),
    useList: ServiceHookFactory.useList<UserRecord, UserIndexQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useUpdate: ServiceHookFactory.useUpdate<UserRecord, UpdateUserPathParams>(
        resourceType,
        resourceEndpoint
    ),

    useCreate: ServiceHookFactory.useCreate<UserRecord>(resourceType, baseEndpoint),

    //     /**
    //      * Custom hook for leveraging service patch calls in React components
    //      */
    //     usePatch: (): { patch: PatchUserService } => {
    //     const { cancellablePromise } = useCancellablePromise();

    //     const serviceCreate: PatchUserService = (
    //         params: PatchUserServiceParams
    //     ): Promise<ServiceResponse<UserRecord>> => {
    //         const endpointWithPatchRouteAndPathParamsReplaced =
    //             resourceEndpoint.replace(":id", params.id.toString()) +
    //             "/preferredlanguage";

    //         return axios
    //             .patch(endpointWithPatchRouteAndPathParamsReplaced, params)
    //             .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
    //     };

    //     function patch(params: PatchUserServiceParams): Promise<ServiceResponse<UserRecord>> {
    //         return cancellablePromise(serviceCreate(params)) as Promise<
    //             ServiceResponse<UserRecord>
    //         >;
    //     }

    //     return { patch: useCallback(patch, [cancellablePromise]) };
    // },
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserService };

// #endregion Exports
