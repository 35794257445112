import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum EventDayStatus {
    Completed = 0,
    InProgress = 1,
    NotStarted = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EventDayStatusDisplayNames: Map<EventDayStatus, TranslatedCopy> = {
    [EventDayStatus.Completed]: "completed",
    [EventDayStatus.InProgress]: "inHyphenProgress",
    [EventDayStatus.NotStarted]: "notStarted",
};

const EventDayStatusStatusMap: Map<EventDayStatus, Status> = {
    [EventDayStatus.Completed]: Status.Success,
    [EventDayStatus.InProgress]: Status.Warning,
    [EventDayStatus.NotStarted]: Status.Neutral,
};

const ClientAdminEventDayStatusMap: Map<EventDayStatus, Status> = {
    [EventDayStatus.Completed]: Status.Success,
    [EventDayStatus.InProgress]: Status.Warning,
    [EventDayStatus.NotStarted]: Status.Default,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    EventDayStatus,
    EventDayStatusDisplayNames,
    EventDayStatusStatusMap,
    ClientAdminEventDayStatusMap,
};

// #endregion Exports
