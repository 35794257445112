import { Record } from "immutable";
import {
    Context,
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
} from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type RecordUpdater<
    TInterface extends object,
    TRecord extends Record<TInterface>
> = Dispatch<SetStateAction<TRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface RecordContext<
    TInterface extends object,
    TRecord extends Record<TInterface>
> {
    record: TRecord;
    setRecord: RecordUpdater<TInterface, TRecord>;
}

interface UseRecordContext<
    TInterface extends object,
    TRecord extends Record<TInterface>
> {
    RecordContext: Context<RecordContext<TInterface, TRecord>>;
    useRecord: () => RecordContext<TInterface, TRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Factory Methods
// -------------------------------------------------------------------------------------------------

const build = <
    TInterface extends object,
    TRecord extends Record<TInterface>
>(recordType: {
    new (): TRecord;
}): UseRecordContext<TInterface, TRecord> => {
    const defaultRecord: TRecord = new recordType();
    const defaultUpdater: RecordUpdater<TInterface, TRecord> = () => {};

    const RecordContext = createContext<RecordContext<TInterface, TRecord>>({
        record: defaultRecord,
        setRecord: defaultUpdater,
    });
    const useRecord = (): RecordContext<TInterface, TRecord> => {
        return useContext(RecordContext);
    };

    return {
        RecordContext: RecordContext,
        useRecord: useRecord,
    };
};

// #endregion Factory Methods

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const UseRecordContextFactory = {
    build: build,
};

export { UseRecordContextFactory };

// #endregion Exports
