import React, { useMemo } from "react";
import { InputProperties } from "components/form/input-properties";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import { t } from "utilities/localization/t";
import "./select.scss";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { LocalizationUtils } from "utilities/localization-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SelectOption {
    text: TranslatedCopy | string;
    value: string;
}

export interface SelectProps extends InputProperties {
    name?: string;
    onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: SelectOption[];
    value?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const Select: React.FC<SelectProps> = (props: SelectProps) => {
    const { readOnly } = useReadOnly();
    const options = useMemo(
        (): SelectOption[] =>
            props.options.map(
                (option: SelectOption): SelectOption => ({
                    ...option,
                    text: LocalizationUtils.isTranslatedCopy(option.text)
                        ? t(option.text as TranslatedCopy)
                        : option.text,
                })
            ),
        [props.options]
    );

    const defaultValue = options.reduce(
        (acc: string, option: SelectOption): string => acc.concat(`${option.value}_`),
        "_"
    );
    const value = props.value ?? defaultValue;
    const className = value === defaultValue ? "-no-value" : "";
    const placeholder = props.placeholder != null ? props.placeholder : t("selectAnOption");

    return (
        <select
            autoFocus={props.autoFocus}
            className={className}
            disabled={props.disabled || readOnly}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            title={props.ariaLabelledBy}
            value={value}>
            <option disabled hidden value={defaultValue}>
                {placeholder}
            </option>

            {options.map((option: SelectOption) => {
                return (
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                );
            })}
        </select>
    );
};

// #endregion Components

export { Select };
