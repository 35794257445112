import { AudienceType } from "models/enumerations/audiences/audience-type";
import { CreateEnrollmentDto } from "models/interfaces/enrollments/create-enrollment-dto";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentService,
    WithdrawEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { NumberUtils } from "utilities/number-utils";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { RoleType } from "models/enumerations/users/role-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { UserEnrollmentService } from "utilities/services/enrollments/user-enrollment-service";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

export interface UseUserEnrollmentsHook {
    addEnrollment: (createEnrollmentDto: CreateEnrollmentDto) => Promise<boolean>;
    enrollments: EnrollmentRecord[];
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    rowCount: number;
    withdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
}

export interface UseUserEnrollmentsHookOptions {
    endDate?: string;
    enrollmentActiveStatus?: EnrollmentActiveStatus;
    expirationEndDate?: string;
    expirationStartDate?: string;
    includeActiveAndCompleted?: boolean;
    includeBadge?: boolean;
    includeCertificate?: boolean;
    includeEvaluation?: boolean;
    includeEvent?: boolean;
    includeEventSchedule?: boolean;
    includeProduct?: boolean;
    includeRegistrations?: boolean;
    educationNetworkOnly?: boolean;
    isWithdrawn?: boolean;
    hasCertificate?: boolean;
    preventLoadEnrollments?: boolean;
    preventLoadEvents?: boolean;
    audienceType?: AudienceType;
    trainingTypes?: TrainingType[];
    roleType?: RoleType;
    searchText?: string;
    skip?: number;
    startDate?: string;
    take?: number;
    userId?: number | string;
}

export function useUserEnrollments(props: UseUserEnrollmentsHookOptions): UseUserEnrollmentsHook {
    const endDate = props.endDate;
    const enrollmentActiveStatus = props.enrollmentActiveStatus;
    const expirationEndDate = props.expirationEndDate ?? "";
    const expirationStartDate = props.expirationStartDate ?? "";
    const includeActiveAndCompleted = props.includeActiveAndCompleted ?? false;
    const includeBadge = props.includeCertificate ?? false;
    const includeCertificate = props.includeCertificate ?? false;
    const includeEvaluation = props.includeEvaluation ?? false;
    const includeEvent = props.includeEvent ?? false;
    const includeEventSchedule = props.includeEventSchedule ?? false;
    const includeProduct = props.includeProduct ?? false;
    const includeRegistrations = props.includeRegistrations ?? false;
    const educationNetworkOnly = props.educationNetworkOnly ?? false;
    const isWithdrawn = props.isWithdrawn;
    const hasCertificate = props.hasCertificate;
    const preventLoadEnrollments = props.preventLoadEnrollments;
    const audienceType = props.audienceType;
    const trainingTypes = props.trainingTypes;
    const roleType = props.roleType;
    const searchText = props.searchText;
    const skip = props.skip;
    const startDate = props.startDate;
    const take = props.take;
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [enrollments, setEnrollments] = useState<EnrollmentRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { create: enrollmentCreate } = EnrollmentService.useCreate();
    const { list: listEnrollmentAPI } = UserEnrollmentService.useList();
    const { patch: withdrawEnrollmentApi } = EnrollmentService.useWithdrawEnrollment();

    const userId = NumberUtils.parseInt(props.userId) ?? 0;

    const fetchData = useCallback(async () => {
        if (isNaN(userId) || userId < 1) {
            return;
        }
        try {
            (async function getEnrollments() {
                const { result, resultObjects, rowCount } = await listEnrollmentAPI(
                    { userId: userId },
                    {
                        endDate: endDate,
                        enrollmentActiveStatus: enrollmentActiveStatus,
                        expirationEndDate: expirationEndDate,
                        expirationStartDate: expirationStartDate,
                        hasCertificate: hasCertificate,
                        includeActiveAndCompleted: includeActiveAndCompleted,
                        includeBadge: includeBadge,
                        includeCertificate: includeCertificate,
                        includeEvaluation: includeEvaluation,
                        includeEvent: includeEvent,
                        includeEventSchedule: includeEventSchedule,
                        includeProduct: includeProduct,
                        includeRegistrations: includeRegistrations,
                        educationNetworkOnly: educationNetworkOnly,
                        isWithdrawn: isWithdrawn,
                        audienceType: audienceType,
                        trainingTypes: trainingTypes,
                        roleType: roleType,
                        searchText: searchText,
                        skip: skip,
                        startDate: startDate,
                        take: take,
                    }
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setRowCount(rowCount);
                setEnrollments(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollments"));
        }
    }, [
        userId,
        listEnrollmentAPI,
        endDate,
        enrollmentActiveStatus,
        expirationEndDate,
        expirationStartDate,
        hasCertificate,
        includeActiveAndCompleted,
        includeBadge,
        includeCertificate,
        includeEvaluation,
        includeEvent,
        includeEventSchedule,
        includeProduct,
        includeRegistrations,
        educationNetworkOnly,
        isWithdrawn,
        audienceType,
        trainingTypes,
        roleType,
        searchText,
        skip,
        startDate,
        take,
    ]);

    const withdrawEnrollment = async (enrollment: EnrollmentRecord): Promise<boolean> => {
        try {
            const pathParams: WithdrawEnrollmentPathParams = {
                id: enrollment.id!,
            };

            const withdrawEnrollmentResponse = await withdrawEnrollmentApi(pathParams);
            const withdrawResult = withdrawEnrollmentResponse?.result;
            if (withdrawResult?.resultObject == null || withdrawResult.hasErrors()) {
                throw new Error();
            }

            const updatedEnrollments = enrollments.map((e) => {
                if (e.id === enrollment.id) {
                    return e.with({
                        withdrawnOn: withdrawResult.resultObject?.withdrawnOn,
                        withdrawnById: withdrawResult.resultObject?.withdrawnById,
                    });
                }

                return e;
            });

            if (isWithdrawn === false) {
                // The list does NOt include withdrawn, so we should refetch the list.
                fetchData();
            } else {
                // The list includes both withdrawn and active enrollments.
                // so we can simply update the list without an API call.
                setEnrollments(updatedEnrollments);
            }

            ToastManager.success(t("withdrawEnrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueWithdrawingAnEnrollment"));
            return false;
        }
    };

    const addEnrollment = async (createEnrollmentDto: CreateEnrollmentDto): Promise<boolean> => {
        try {
            if (createEnrollmentDto?.productId === 0 || createEnrollmentDto === undefined) {
                throw new Error(t("creatingAnEnrollmentFailed"));
            }
            const createEnrollmentResponse = await enrollmentCreate(createEnrollmentDto);
            const createEnrollmentResult = createEnrollmentResponse?.result;

            if (
                createEnrollmentResult?.resultObject == null ||
                createEnrollmentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemCreatingANewEnrollment"));
            }
            fetchData();
            ToastManager.success(t("enrollmentSuccessful"));
            return true;
        } catch (error) {
            if (error === "") {
                ToastManager.error(t("failedToCreateANewEnrollment"));
            } else {
                ToastManager.error(t("enrollmentForTrainingAlreadyExists"));
            }
            return false;
        }
    };

    useEffect(() => {
        if (preventLoadEnrollments !== true) {
            fetchData();
        }
    }, [searchText, skip, take, fetchData, preventLoadEnrollments]);

    return {
        addEnrollment,
        enrollments,
        errors,
        isLoading,
        rowCount,
        withdrawEnrollment,
    };
}
