import React, { useMemo } from "react";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { AchievementCard, AchievementStatus } from "components/achievements/achievement-card";
import { useParams } from "react-router-dom";
import { NumberUtils } from "utilities/number-utils";
import { useCertificate } from "utilities/hooks/models/certificates/use-certificate";
import { useBadge } from "utilities/hooks/models/badges/use-badge";
import { StringUtils } from "utilities/string-utils";
import { useEnrollmentContext } from "utilities/contexts/use-enrollment-context";
import { t } from "utilities/localization/t";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import "./learner-online-learning-achievements-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningAchievementsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-achievements-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningAchievementsPage: React.FC<LearnerOnlineLearningAchievementsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningAchievementsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { enrollmentId: enrollmentIdParam } = useParams();
        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { record: enrollment } = useEnrollmentContext();
        const { certificate } = useCertificate({ enrollmentId: enrollmentId ?? -1 });
        const { badge } = useBadge({ enrollmentId: enrollmentId });

        const certificateStatus = useMemo(() => {
            // We are no longer using a job to generate certificates, so if the certificate is not null
            // then we can report it is Complete.
            // if (certificate != null && certificate.fileId == null) {
            //     return AchievementStatus.Generating;
            // }
            return certificate == null ? AchievementStatus.NotAchieved : AchievementStatus.Complete;
        }, [certificate]);

        const badgeStatus = useMemo(() => {
            if (badge == null) {
                return AchievementStatus.NotAchieved;
            }

            if (StringUtils.hasValue(badge.url)) {
                return AchievementStatus.Complete;
            }

            return AchievementStatus.NotAchieved;
        }, [badge]);

        const handleDownloadCertificateClick = async (
            enrollmentId: number,
            certificateId: number
        ) => {
            const pathParams: DownloadCertificatePathParams = {
                enrollmentId: enrollmentId,
                id: certificateId,
            };

            const queryParams: DownloadCertificateQueryParams = {
                overwriteExisting: false,
            };

            await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
        };

        return (
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Achievements} size={IconSizes.Large} />
                    <Heading size={HeadingSize.Small}>{t("achievements")}</Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__achievement_cards`}>
                    <AchievementCard
                        achievementStatus={certificateStatus}
                        title={t("certificate")}
                        description={
                            <>
                                {t("yourCertificateOfCompletionWith")}{" "}
                                <a href="http://www.IACET.org" target="_blank">
                                    IACET
                                </a>{" "}
                                {t(
                                    "ceusWillBeAvailableForDownloadOnceYouHaveCompletedAllRequiredElementsOfTheTraining"
                                )}
                            </>
                        }
                        downloadTitle={t("downloadCertificate")}
                        onClick={() =>
                            handleDownloadCertificateClick(enrollmentId, certificate?.id ?? -1)
                        }
                        icon={Icons.Certificate}
                    />
                    {enrollment.productVersion?.hasBadge && (
                        <AchievementCard
                            achievementStatus={badgeStatus}
                            title={t("badge")}
                            description={
                                <>
                                    {t(
                                        "onceYouHaveCompletedAllRequiredElementsOfTheTrainingYouWillReceiveAnEmailFromCredlyInvitingYouToClaimYourDigitalBadgeTheBadgeWillAllowYouToEasilyShareYourAchievementWithColleaguesManagersAndYourProfessionalNetworkOnLinkedInAndOtherPlatforms"
                                    )}
                                </>
                            }
                            downloadTitle={t("goToCredly")}
                            downloadUrl={badge?.url ?? ""}
                            icon={Icons.Badge}
                        />
                    )}
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningAchievementsPage };

// #endregion Exports
