import React from "react";
import { Process } from "utilities/types/processes/process";
import { ProcessNavigationFooter } from "components/processes/navigation/process-navigation-footer/process-navigation-footer";
import { ProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductLayoutFooterProps<T> {
    cssClassName?: string;
    process: Process<T>;
    processNavigator: ProcessNavigator<T>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductLayoutFooter = <T,>(
    props: React.PropsWithChildren<ProductLayoutFooterProps<T>>
): JSX.Element => {
    return (
        <ProcessNavigationFooter
            cssClassName={props.cssClassName}
            process={props.process}
            processNavigator={props.processNavigator}
            saveAndExitPath={sitemap.admin.product.list}>
            {props.children}
        </ProcessNavigationFooter>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductLayoutFooter };

// #endregion Exports
