import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Icons } from "components/icons/constants/icons";
import { QuickLinkProps } from "components/quick-link/quick-link";
import { sitemap } from "sitemap";
import { SystemSettingsRecord } from "models/view-models/system-settings-record";
import { useMemo } from "react";

interface useInstructorQuickLinksHook {
    instructorQuickLinks: QuickLinkProps[];
}

interface useInstructorQuickLinksHookOptions {
    settings?: SystemSettingsRecord;
}

export function useInstructorQuickLinks(
    props: useInstructorQuickLinksHookOptions
): useInstructorQuickLinksHook {
    const instructorQuickLinks: QuickLinkProps[] = useMemo(() => {
        const links: QuickLinkProps[] = [
            {
                icon: Icons.Teaching,
                path: sitemap.instructor.teachingAssignments,
                text: "myTeachingAssignments",
            },
            {
                icon: Icons.AenManager,
                path: sitemap.instructor.educationNetworkHub.requiredTrainings,
                text: "educationNetworkHub",
            },
            {
                icon: Icons.Reporting,
                path: sitemap.admin.reports.list,
                text: "reports",
            },
            {
                icon: Icons.Community,
                path: props.settings?.communityBaseUrl ?? "",
                pathType: AnchorPathType.External,
                text: "community",
            },
            {
                icon: Icons.Support,
                path: sitemap.authenticated.support,
                text: "support",
            },
        ];
        return links;
    }, [props.settings?.communityBaseUrl]);

    return {
        instructorQuickLinks,
    };
}
