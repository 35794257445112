import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useIdentity } from "./use-identity";
import { useCallback, useEffect, useState } from "react";
import { GlobalStateRecord } from "models/view-models/global-state/global-state-record";

const useRefreshIdentity = () => {
    const { record: globalState, setRecord: setGlobalState } = useGlobalState();
    const { getIdentity } = useIdentity();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const updateIdentity = useCallback(async () => {
        try {
            setLoading(true);
            const identity = await getIdentity(globalState.currentIdentity?.userLogin);

            if (identity == null) {
                return;
            }

            setGlobalState((globalState: GlobalStateRecord) => globalState.setIdentity(identity));
        } finally {
            setLoaded(true);
            setLoading(false);
        }
    }, [getIdentity, globalState, setGlobalState, setLoading]);

    useEffect(() => {
        if (loaded || loading) {
            return;
        }

        updateIdentity();
    }, [loaded, loading, updateIdentity]);

    return { loading, refresh: updateIdentity };
};

export { useRefreshIdentity };
