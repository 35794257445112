import { ResultErrorRecord } from "andculturecode-javascript-core";
import { TrainingType } from "models/enumerations/courses/training-type";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { EnrollmentScormPackageAssessmentService } from "utilities/services/enrollments/enrollment-scorm-package-assessment-service";
import {
    EnrollmentExpirationPatchParams,
    EnrollmentService,
    GetEnrollmentPathParams,
    GetEnrollmentQueryParams,
    PatchEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { EnrollmentUnitCourseService } from "utilities/services/enrollments/enrollment-unit-course-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseEnrollmentHook {
    enrollment: EnrollmentRecord;
    extendExpiration: (expirationDate?: Date) => Promise<boolean>;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    setEnrollment: Dispatch<SetStateAction<EnrollmentRecord>>;
}

interface UseEnrollmentHookOptions {
    enrollmentId?: number | string;
    includeAttendance?: boolean;
    includeCertificate?: boolean;
    includeEvaluation?: boolean;
    includeProduct?: boolean;
    includeEvent?: boolean;
    includeTrainingType?: TrainingType;
    includeExpirationHistory?: boolean;
    includeCreatedBy?: boolean;
    includeWithdrawnBy?: boolean;
}

export function useEnrollment(props: UseEnrollmentHookOptions): UseEnrollmentHook {
    const includeAttendance = props.includeAttendance ?? false;
    const includeCertificate = props.includeCertificate ?? false;
    const includeEvaluation = props.includeEvaluation ?? false;
    const includeEvent = props.includeEvent ?? false;
    const includeProduct = props.includeProduct ?? false;
    const includeExpirationHistory = props.includeExpirationHistory ?? false;
    const includeCreatedBy = props.includeCreatedBy ?? false;
    const includeWithdrawnBy = props.includeWithdrawnBy ?? false;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollment, setEnrollment] = useState<EnrollmentRecord>(new EnrollmentRecord());
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { get: getEnrollmentAPI } = EnrollmentService.useGet();
    const { list: listEnrollmentUnitCourses } = EnrollmentUnitCourseService.useList();
    const { list: listEnrollmentScormPackageAssessments } =
        EnrollmentScormPackageAssessmentService.useList();
    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;
    const { patch: patchExpirationApi } = EnrollmentService.usePatchExpiration();

    const fetchData = useCallback(async () => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function getEnrollment() {
            try {
                const pathParams: GetEnrollmentPathParams = {
                    id: enrollmentId,
                };

                const trainingTypeIncludingAssessments =
                    props.includeTrainingType === TrainingType.InstructorLedTraining ||
                    props.includeTrainingType === TrainingType.InstructorAssessment;

                const queryParams: GetEnrollmentQueryParams = {
                    includeAttendance: includeAttendance,
                    includeCertificate: includeCertificate,
                    includeEvaluation: includeEvaluation,
                    includeProduct: includeProduct,
                    includeEvent: includeEvent,
                    includeExpirationHistory: includeExpirationHistory,
                    includeCreatedBy: includeCreatedBy,
                    includeWithdrawnBy: includeWithdrawnBy,
                };

                const [enrollmentResult, enrollmentAssessmentsResult] = await Promise.all([
                    getEnrollmentAPI(pathParams, queryParams),
                    props.includeTrainingType === TrainingType.OnlineLearning
                        ? listEnrollmentUnitCourses({ enrollmentId })
                        : trainingTypeIncludingAssessments
                        ? listEnrollmentScormPackageAssessments({ enrollmentId })
                        : Promise.resolve(),
                ]);

                const { result: resultEnrollment } = enrollmentResult;
                const { results: resultEnrollmentAssessmentsResult } =
                    enrollmentAssessmentsResult ?? {};

                setIsLoading(false);

                if (
                    resultEnrollment?.hasErrors() ||
                    resultEnrollmentAssessmentsResult?.hasErrors()
                ) {
                    const { errors: enrollmentErrors = [] } = resultEnrollment ?? {};
                    const { errors: enrollmentUnitCoursesErrors = [] } =
                        resultEnrollmentAssessmentsResult ?? {};

                    setErrors([...enrollmentErrors, ...enrollmentUnitCoursesErrors]);
                    return;
                }

                const enrollmentUnitCourses =
                    props.includeTrainingType === TrainingType.OnlineLearning
                        ? (resultEnrollmentAssessmentsResult?.resultObject as EnrollmentUnitCourseRecord[])
                        : [];

                const enrollmentScormPackageAssessments = trainingTypeIncludingAssessments
                    ? (resultEnrollmentAssessmentsResult?.resultObject as EnrollmentScormPackageAssessmentRecord[])
                    : [];

                enrollmentScormPackageAssessments.sort(
                    (a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)
                );

                const enrollment: EnrollmentRecord = resultEnrollment?.resultObject.with({
                    enrollmentUnitCourses,
                    enrollmentScormPackageAssessments,
                });

                setEnrollment(enrollment);
            } catch (err) {
                ToastManager.error(t("thereWasAProblemLoadingEnrollments"));
                setIsLoading(false);
                setErrors([
                    new ResultErrorRecord({
                        message: `${err}`,
                    }),
                ]);
            }
        })();
    }, [
        enrollmentId,
        includeAttendance,
        includeCertificate,
        includeEvaluation,
        includeProduct,
        includeEvent,
        includeExpirationHistory,
        includeCreatedBy,
        includeWithdrawnBy,
        getEnrollmentAPI,
        props.includeTrainingType,
        listEnrollmentUnitCourses,
        listEnrollmentScormPackageAssessments,
    ]);

    const extendExpiration = async (expirationDate?: Date): Promise<boolean> => {
        if (enrollment == null || enrollment.id == null || enrollment.id <= 0) {
            return false;
        }

        const patchPathParams: PatchEnrollmentPathParams = { id: enrollment.id };
        const enrollmentExpirationPatchParams: EnrollmentExpirationPatchParams = {
            expirationDate: expirationDate,
        };
        try {
            await patchExpirationApi(patchPathParams, enrollmentExpirationPatchParams);

            fetchData();
            return true;
        } catch {
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        enrollment,
        setEnrollment,
        extendExpiration,
    };
}
