import React from "react";
import { Toggle } from "../toggle/toggle";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./toggle-label.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum ToggleLabelDirection {
    Left = "-left",
    Right = "-right",
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ToggleLabelProps {
    checked?: boolean;
    disabled?: boolean;
    direction?: ToggleLabelDirection;
    label: string;
    id: string;
    onToggle?: (checked: boolean) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "toggle-label";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ToggleLabel: React.FC<ToggleLabelProps> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const { readOnly } = useReadOnly();

    if (props.direction) {
        cssClassNames.push(props.direction);
    }

    const className = cssClassNames.join(" ");

    return (
        <div className={className}>
            {(props.direction == null || props.direction === ToggleLabelDirection.Left) && (
                <Paragraph style={ParagraphStyle.Label}>{props.label}</Paragraph>
            )}
            <Toggle
                checked={props.checked}
                disabled={props.disabled || readOnly}
                id={props.id}
                label={props.label}
                onToggle={props.onToggle}
            />
            {props.direction === ToggleLabelDirection.Right && (
                <Paragraph style={ParagraphStyle.Label}>{props.label}</Paragraph>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ToggleLabel };

// #endregion Exports
