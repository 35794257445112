import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import "./search-selection-modal-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SearchSelectionModalListItem<TValue> {
    id: string;
    name: string;
    selected: boolean;
    value: TValue;
}

interface SearchSelectionModalListProps<TItem> {
    observerRef?: (node: any) => void;
    onItemSelected?: (value: TItem) => void;
    onItemUnselected?: (value: TItem) => void;
    items: TItem[];
    itemName: string;
}

type SearchSelectionModalListFC = <TItem, TListItem extends SearchSelectionModalListItem<TItem>>(
    ...props: Parameters<React.FC<SearchSelectionModalListProps<TListItem>>>
) => ReturnType<React.FC<SearchSelectionModalListProps<TListItem>>>;

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "search-selection-modal-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SearchSelectionModalList: SearchSelectionModalListFC = ({
    itemName,
    items,
    onItemSelected: handleAdd,
    onItemUnselected: handleRemove,
    observerRef,
}) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="item-name">{itemName}</th>
                    <th className="id">{t("id")}</th>
                    <th className="action">{t("action")}</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <tr key={item.id}>
                        <td className="item-name">{item.name}</td>
                        <td className="id">{item.id}</td>
                        <td className="action">
                            {!item.selected && handleAdd && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    iconType={Icons.Plus}
                                    onClick={() => handleAdd(item)}
                                    text={t("add")}
                                />
                            )}
                            {item.selected && handleRemove && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Minus}
                                    onClick={() => handleRemove(item)}
                                    text={t("remove")}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
            <div ref={observerRef} style={{ height: "20px" }}></div>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SearchSelectionModalList };

// #endregion Exports
