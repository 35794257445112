import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { InputTypes } from "../../../form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { t } from "utilities/localization/t";
import "./add-learner-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddLearnerModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-learner-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddLearnerModal: React.FC<AddLearnerModalProps> = ({ open, setOpen }): JSX.Element => {
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("save"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("addLeaner")}
            actions={modalActionArray}
            modalStyle={""}>
            <FormTextInput
                ariaLabelledBy={t("firstName")}
                autoFocus={true}
                formFieldName="firstName"
                id="firstName"
                label={t("firstName")}
                maxLength={100}
                onChange={() => {}}
                placeholder="First Name"
                required={true}
                type={InputTypes.Text}
            />
            <FormTextInput
                ariaLabelledBy={t("lastName")}
                formFieldName="lastName"
                id="lastName"
                label={t("lastName")}
                maxLength={100}
                onChange={() => {}}
                placeholder={t("lastName")}
                required={true}
                type={InputTypes.Text}
            />
            <FormTextInput
                ariaLabelledBy={t("email")}
                autoFocus={true}
                formFieldName="email"
                id="email"
                label={t("email")}
                maxLength={100}
                onChange={() => {}}
                placeholder={t("email")}
                required={true}
                type={InputTypes.Email}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddLearnerModal };

// #endregion Exports
