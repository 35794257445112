import { Language } from "models/enumerations/languages/language";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";

export interface AnnouncmentModalState {
    announcement: AnnouncementRecord;
    currentBodyHtml: string;
    currentBodyText: string;
    currentLanguage: Language;
    currentTitle: string;
    isDirty: boolean;
    originalAnnouncement: AnnouncementRecord;
    publishable: boolean;
}

export type AnnouncmentModalReducerActions =
    | { type: "initialize_announcement"; announcement: AnnouncementRecord }
    | { type: "update_body"; html: string; text: string }
    | { type: "update_current_language"; language: Language }
    | { type: "update_title"; title: string };

export function announcmentModalReducer(
    state: AnnouncmentModalState,
    action: AnnouncmentModalReducerActions
): AnnouncmentModalState {
    switch (action.type) {
        case "initialize_announcement":
            const { html, text, title } = action.announcement.getCurrentBodyAndTitle(
                Language.English
            );

            return {
                ...state,
                announcement: action.announcement,
                currentBodyHtml: html,
                currentBodyText: text,
                currentLanguage: Language.English,
                currentTitle: title,
                isDirty: false,
                originalAnnouncement: action.announcement,
                publishable: action.announcement.isPublishable(),
            };
        case "update_body":
            const updatedAnnouncementBody = state.announcement.withBody(
                state.currentLanguage,
                action.html
            );

            if (updatedAnnouncementBody.equals(state.announcement)) {
                return state;
            }

            return {
                ...state,
                announcement: updatedAnnouncementBody,
                currentBodyHtml: action.html,
                currentBodyText: action.text,
                isDirty: !updatedAnnouncementBody.equals(state.originalAnnouncement),
                publishable: updatedAnnouncementBody.isPublishable(),
            };
        case "update_current_language":
            const result = state.announcement.getCurrentBodyAndTitle(action.language);

            if (
                state.currentBodyHtml === result.html &&
                state.currentBodyText === result.text &&
                state.currentTitle === result.title &&
                state.currentLanguage === action.language
            ) {
                return state;
            }

            return {
                ...state,
                currentBodyHtml: result.html,
                currentBodyText: result.text,
                currentLanguage: action.language,
                currentTitle: result.title,
            };
        case "update_title":
            const updatedAnnouncementTitle = state.announcement.withTitle(
                state.currentLanguage,
                action.title
            );

            if (updatedAnnouncementTitle.equals(state.announcement)) {
                return state;
            }

            return {
                ...state,
                announcement: updatedAnnouncementTitle,
                currentTitle: action.title,
                isDirty: !updatedAnnouncementTitle.equals(state.originalAnnouncement),
                publishable: updatedAnnouncementTitle.isPublishable(),
            };
    }
}
