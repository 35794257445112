import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import { createContext, useContext } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AnnouncementContextParams {
    announcement: AnnouncementRecord;
    isRead?: boolean;
    setIsRead: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const AnnouncementContext = createContext<AnnouncementContextParams>({
    announcement: new AnnouncementRecord(),
    isRead: false,
    setIsRead: () => {},
});

const useAnnouncement = () => {
    return useContext(AnnouncementContext);
};

export { AnnouncementContext, useAnnouncement };

// #endregion Exports
