import React, { PropsWithChildren } from "react";
import { AdminLayoutHeader } from "layouts/admin/admin-layout/admin-layout-header/admin-layout-header";
import { Outlet } from "react-router-dom";
import { SkipNavLink } from "@chakra-ui/skip-nav";
import "./admin-layout.scss";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutComponent: React.FC<PropsWithChildren<AdminLayoutProps>> = ({
    children,
}): JSX.Element => {
    const { isInEmulationMode } = useEmulationMode();

    const className = isInEmulationMode ? `${CSS_CLASS_NAME} -emulation-mode` : CSS_CLASS_NAME;
    return (
        <div className={className}>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <AdminLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            <div className={`${CSS_CLASS_NAME}__body`}>
                <main>{children}</main>
            </div>
        </div>
    );
};

const AdminLayout: React.FC<AdminLayoutProps> = (props: AdminLayoutProps): JSX.Element => {
    return (
        <AdminLayoutComponent>
            <Outlet />
        </AdminLayoutComponent>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutComponent, AdminLayout };

// #endregion Exports
