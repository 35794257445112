import { useCallback, useMemo, useReducer } from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventType } from "models/enumerations/events/event-type";
import { EnumUtils } from "utilities/enumerations/enum-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseHandleEventTypeChangeHook {
    confirmationModalOpen: boolean;
    confirmEventTypeChange: () => void;
    handleEventTypeChange: (eventType: string) => void;
    rejectEventTypeChange: () => void;
}

interface EventTypeChangeConfirmationState {
    confirmationModalOpen: boolean;
    eventType?: EventType;
}

interface EventTypeChangeConfirmationAction {
    type: EventTypeChangeConfirmationStates;
    eventType?: EventType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum EventTypeChangeConfirmationStates {
    ConfirmationProvided,
    ConfirmationRejected,
    ConfirmationRequested,
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useHandleEventTypeChange = (event: EventActiveRecord): UseHandleEventTypeChangeHook => {
    const initialState: EventTypeChangeConfirmationState = useMemo(
        (): EventTypeChangeConfirmationState => ({
            confirmationModalOpen: false,
        }),
        []
    );
    const reducer = useCallback(
        (
            state: EventTypeChangeConfirmationState,
            action: EventTypeChangeConfirmationAction
        ): EventTypeChangeConfirmationState => {
            const confirmationRequested =
                action.type === EventTypeChangeConfirmationStates.ConfirmationRequested;
            const confirmationProvided =
                action.type === EventTypeChangeConfirmationStates.ConfirmationProvided;
            const newState: EventTypeChangeConfirmationState = {
                confirmationModalOpen: confirmationRequested,
            };

            if (confirmationRequested) {
                newState.eventType = action.eventType;
            }

            if (confirmationProvided) {
                event.patchEventType(state.eventType!);
            }

            return newState;
        },
        [event]
    );

    const [state, dispatch] = useReducer(reducer, initialState);

    const confirmEventTypeChange = useCallback(
        () => dispatch({ type: EventTypeChangeConfirmationStates.ConfirmationProvided }),
        []
    );
    const rejectEventTypeChange = useCallback(
        () => dispatch({ type: EventTypeChangeConfirmationStates.ConfirmationRejected }),
        []
    );

    const handleTypeChange = useCallback(
        (value: string) => {
            const incomingEventType = EnumUtils.enumToObjectPartial(
                value,
                (value) => ({ type: value }),
                EventType
            ).type!;

            if (event.type == null) {
                event.patchEventType(incomingEventType);
            } else if (event.type.toString() === incomingEventType.toString()) {
                return;
            } else {
                dispatch({
                    type: EventTypeChangeConfirmationStates.ConfirmationRequested,
                    eventType: incomingEventType,
                });
            }
        },
        [event]
    );

    return {
        confirmationModalOpen: state.confirmationModalOpen,
        confirmEventTypeChange: confirmEventTypeChange,
        handleEventTypeChange: handleTypeChange,
        rejectEventTypeChange: rejectEventTypeChange,
    };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useHandleEventTypeChange };

// #endregion Exports
