import { Badge } from "models/interfaces/badges/badge";
import { RecordUtils } from "andculturecode-javascript-core";
import { AuditableRecord } from "models/auditable-record/auditable-record";
import { EnrollmentRecord } from "../enrollments/enrollment-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Badge = {
    enrollmentId: 0,
    url: "",
    enrollment: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class BadgeRecord extends AuditableRecord(defaultValues) {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Badge>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        super(params);
    }

    // #endregion Constructor
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BadgeRecord };

// #endregion Exports
