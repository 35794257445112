import ReportSection from "components/reports/components/report-section/report-section";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EventRecord } from "models/view-models/events/event-record";
import { EventService, ListEventsQueryParams } from "utilities/services/events/event-service";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventSelectionProps extends Required<Pick<ListEventsQueryParams, "type">> {
    disableAddEvents?: boolean;
    label?: string;
    isRequired?: boolean;
    events: EventRecord[];
    onEventsChanged: (events: EventRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventSelection: React.FC<EventSelectionProps> = ({
    disableAddEvents,
    label,
    isRequired = false,
    events,
    onEventsChanged,
    type,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchEvents, setSearchEvents] = useState<EventRecord[]>([]);

    const { list: listEvents } = EventService.useList();
    const fetchEvents = useCallback(
        async (searchText?: string) => {
            try {
                const listEventsQueryParams: ListEventsQueryParams = {
                    applySearch: true,
                    excludeDraft: true,
                    searchText,
                    type,
                };

                const listFilteredEventsResponse = await listEvents(listEventsQueryParams);
                const listFilteredEventsResults = listFilteredEventsResponse?.results;
                const events = listFilteredEventsResponse?.resultObjects;
                if (
                    events == null ||
                    listFilteredEventsResults == null ||
                    listFilteredEventsResults.hasErrors()
                ) {
                    throw new Error();
                }
                setSearchEvents(events);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingEvents"));
                setSearchEvents([]);
            }
        },
        [listEvents, type]
    );

    const handleRemoveEvent = useCallback(
        (event: EventRecord) => {
            onEventsChanged(events.filter((c) => c.id !== event.id));
        },
        [events, onEventsChanged]
    );

    const handleEventSelectionChange = (selectedEvents: EventRecord[]) => {
        onEventsChanged(selectedEvents);
    };

    const handleAddEvents = () => {
        fetchEvents();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {label ? label : t("selectEvents")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            disabled={disableAddEvents}
                            onClick={handleAddEvents}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addEvents")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(events) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="event-name">{t("eventName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map((event) => (
                                <tr key={event.id}>
                                    <td className="event-name">{event.name}</td>
                                    <td className="id">{event.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveEvent(event)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchEvents}
                onSelectionChanged={handleEventSelectionChange}
                searchValues={searchEvents}
                selectedValues={events}
                title={t("addAnEvent")}
                itemName={t("eventName")}
                searchPlaceholderText={t("typeToSearchByEventNameOrID")}
                valuesDescription={t("events")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default EventSelection;

// #endregion Exports
