import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { NumberUtils } from "utilities/number-utils";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-registration-form.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventRegistrationFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-registration-form";

// #endregion Constants

const EventRegistrationForm: React.FC<EventRegistrationFormProps> = (
    props: EventRegistrationFormProps
): JSX.Element => {
    const handleAddlRegReqsChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        props.event.updateActiveRecord({ additionalRegistrationRequirements: event.target.value });
    };

    const handleMaxRegistrationsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({
            maxRegistrations: NumberUtils.parseInt(event.target.value),
        });
    };

    const handleBlur = (): void => {
        if (props.event.isDraft()) {
            props.event.save();
        }
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__column`}>
                    <FormTextInput
                        ariaLabelledBy={t("maximumRegistrationLimit")}
                        autoFocus={true}
                        disabled={props.event.hasNoRegistrationLimit}
                        formFieldName="maxRegistrations"
                        id="maxRegistrations"
                        label={t("maximumRegistrationLimit")}
                        maxLength={32}
                        onBlur={handleBlur}
                        onChange={handleMaxRegistrationsChange}
                        placeholder={t("maximumRegistrationLimit")}
                        required={false}
                        type={InputTypes.Text}
                        value={props.event.maxRegistrations}
                    />
                    <FormTextArea
                        ariaLabelledBy={t("additionalRequirements")}
                        autoFocus={false}
                        disabled={props.event.hasNoRegistrationLimit}
                        formFieldName="additionalRegistrationRequirements"
                        id="additionalRegistrationRequirements"
                        label={t("additionalRequirements")}
                        onBlur={handleBlur}
                        onChange={handleAddlRegReqsChange}
                        placeholder={t("additionalRequirements")}
                        required={false}
                        type={InputTypes.Text}
                        value={props.event.additionalRegistrationRequirements}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__column`}>&nbsp;</div>
            </div>
        </>
    );
};

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventRegistrationForm };

// #endregion Exports
