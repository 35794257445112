import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphStyle } from "../../typography/paragraph/paragraph";
import { FormSelect } from "components/form/form-select/form-select";
import { SelectOption } from "components/form/inputs/select/select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { AttemptRecord } from "../../../models/view-models/attempts/attempt-record";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";
import { NumberUtils } from "utilities/number-utils";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { t } from "utilities/localization/t";
import "./attempt-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AttemptModalProps {
    attemptToEdit?: AttemptRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onAttemptSave?: (attempt: AttemptRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "attempt-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AttemptModal: React.FC<AttemptModalProps> = ({
    attemptToEdit,
    open,
    setOpen,
    onAttemptSave,
}): JSX.Element => {
    const ATTEMPT_SUCCESS_OPTIONS: SelectOption[] = useMemo(
        () => [
            { value: AttemptSuccessStatus.Passed.toString(), text: t("passed") },
            { value: AttemptSuccessStatus.Failed.toString(), text: t("failed") },
        ],
        []
    );
    const [initialAttempt, setInitialAttempt] = useState<AttemptRecord>(
        attemptToEdit ?? new AttemptRecord()
    );
    const [attempt, setAttempt] = useState<AttemptRecord>(initialAttempt);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        const attempt = attemptToEdit ?? new AttemptRecord();

        setInitialAttempt(attempt);
        setAttempt(attempt);
    }, [attemptToEdit]);
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled:
                attempt.successStatus == null ||
                attempt.score == null ||
                !StringUtils.hasValue(attempt.manualEntryNotes) ||
                attempt.completionDate == null,
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setAttempt(initialAttempt);
        setDirty(false);
    };

    const onModalClose = (): void => {
        resetForm();
        setOpen(false);
    };

    const handleSave = async (): Promise<void> => {
        if (await onAttemptSave?.(attempt)) {
            onModalClose();
        } else {
            ToastManager.error(t("failedToSaveAttempt"));
        }
    };

    const updateAttempt = (values: Partial<AttemptRecord>): void => {
        setAttempt(attempt.with(values));
        setDirty(true);
    };

    const handleSuccessChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newSuccessStatus = EnumUtils.enumToObjectPartial(
            event.target.value,
            (value) => ({ successStatus: Number(value) }),
            AttemptSuccessStatus
        );

        updateAttempt({ successStatus: newSuccessStatus.successStatus });
    };

    const handleManualEntryNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateAttempt({ manualEntryNotes: event.target.value });
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const score = NumberUtils.parseInt(event.target.value);

        if (score !== undefined && (score < 0 || score > 100)) {
            return;
        }

        updateAttempt({ score: NumberUtils.parseInt(score) });
    };

    const handleCompletionDateChange = (date?: Date): void => {
        updateAttempt({ completionDate: date });
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={attemptToEdit == null ? t("addAttempt") : t("editAttempt")}
            actions={modalActionArray}
            modalStyle={""}>
            <Paragraph style={ParagraphStyle.Light}>
                {attemptToEdit == null
                    ? t("savingAnAttemptMayResultInAnUpdateToTheLearnersProgress")
                    : t("editingAnAttemptMayResultInAnUpdateToTheLearnersProgress")}
            </Paragraph>
            <FormTextInput
                ariaLabelledBy={t("assessmentScore")}
                formFieldName="assessmentScore"
                id="assessmentScore"
                label={t("assessmentScore")}
                maxNumber={100}
                minValue={0}
                onChange={handleScoreChange}
                value={attempt.score ?? ""}
                placeholder="00"
                required={true}
                type={InputTypes.Number}
            />
            <FormSelect
                ariaLabelledBy={t("result")}
                formFieldName="result"
                id="result"
                label={t("result")}
                onChange={handleSuccessChange}
                value={attempt.successStatus?.toString()}
                options={ATTEMPT_SUCCESS_OPTIONS}
                required={true}
            />
            <FormCalendarDatePicker
                placeholder="emptyDate"
                formFieldName="dateOfCompletion"
                label={t("dateOfCompletion")}
                required={true}
                selectedDate={attempt.completionDate ? new Date(attempt.completionDate) : undefined}
                onChange={handleCompletionDateChange}
            />
            <FormTextArea
                ariaLabelledBy={t("changeLog")}
                formFieldName="changeLog"
                id="changeLog"
                label={t("changeLog")}
                onChange={handleManualEntryNotesChange}
                placeholder={t("enterChangeLog")}
                required={true}
                rows={4}
                value={attempt.manualEntryNotes ?? ""}
                type={InputTypes.Text}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptModal };

// #endregion Exports
