import { InstructorLanguage } from "models/interfaces/instructors/instructor-language";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorLanguage =
    RecordUtils.auditableDefaultValuesFactory<InstructorLanguage>({
        language: undefined,
        instructorProfileId: 0,
    });

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorLanguageRecord extends Record(defaultValues) implements InstructorLanguage {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorLanguage>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the human readable topic.
     */
    public getLanguageDisplayName(): string {
        if (this == null || this.language == null) {
            return "";
        }

        return t(LanguageDisplayNames[this.language]);
    }

    public with(values: Partial<InstructorLanguage>): InstructorLanguageRecord {
        return new InstructorLanguageRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorLanguageRecord };

// #endregion Exports
