import { CollectionUtils, CoreUtils } from "andculturecode-javascript-core";
import { Enum } from "utilities/types/enum";
import { SelectOption } from "components/form/inputs/select/select";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

/**
 * Returns the key-value pairs of an enum as an array.
 *
 * @typeparam `T extends Enum<T>` The enum type.
 * @param {T} enumeration The enum whose entries should be converted to an array convert to an array.
 * @returns {[keyof T, T[keyof T]][]} An array of key-value pairs for the given enum.
 */
const entries = <T extends Enum>(enumeration: T): [keyof T, T[keyof T]][] =>
    keys(enumeration).map((key: keyof T): [keyof T, T[keyof T]] => [key, enumeration[key]]);

/**
 * Attempts to convert an enum value into a Partial of a type assigning the enum value to a property
 * on an object of the given type.
 *
 * @param {string} value The value to be parsed as an enum.
 * @param {(value: T[keyof T] & number) => Partial<K>} buildPartial A function that builds a Partial,
 *  setting the desired property to the parsed enum value.
 * @param {T} enumeration The enum to attempt to parse value into.
 * @returns {Partial<K>} A partial containing the given property name set to the parsed enum value
 * if the enum value could be parsed. An empty object, otherwise.
 */
const enumToObjectPartial = <T extends { [k: number]: T[number] }, K>(
    value: string,
    buildPartial: (value: T[keyof T] & number) => Partial<K>,
    enumeration: T
): Partial<K> => {
    if (EnumUtils.isNumericEnumValue<T>(value, enumeration)) {
        return buildPartial(value);
    }

    return {};
};

/**
 * Attempts to determine if a provided value of unknown type is a valid numeric value for a given
 * enum.
 *
 * @param {unknown} [value] The value of unknown type to be checked as a valid enum value.
 * @param {T} [enumeration] The enum to check against.
 * @returns {boolean} True if value is a valid numeric value within the provided enum.
 */
const isNumericEnumValue = <T extends { [k: number]: T[number] }>(
    value: unknown,
    enumeration: T
): value is T[keyof T] & number => {
    if (value == null) {
        return false;
    }

    const numericValue: number = Number(value);

    if (isNaN(numericValue)) {
        return false;
    }

    let enumValue: T[number];
    try {
        enumValue = enumeration[numericValue];
    } catch {
        return false;
    }

    return enumValue != null;
};

/**
 * Returns the keys of a given enum.
 *
 * @typeparam `T extends Enum<T>` The enum type.
 * @param {T} enumeration The enum whose keys should be returned.
 * @returns {(keyof T)[]} The keys of the given enum.
 */
const keys = <T extends Enum>(enumeration: T): (keyof T)[] =>
    Object.keys(enumeration).filter((key: string): key is keyof T & string =>
        isNaN(Number.parseInt(key))
    );

/**
 * Converts the values in a numeric enum into an array of SelectOption objects.
 *
 * @param {T} enumeration The enum to convert to SelectOption objects.
 * @param {any} enumerationDisplayNames The Display Name map to use for the option text.
 * @returns {SelectOption[]} The array of SelectOption objects to create from the enum.
 */
const numericEnumToSelectOptions = <T extends { [k: number]: T[number] }>(
    enumeration: T,
    enumerationDisplayNames?: Record<number, TranslatedCopy>
): SelectOption[] => {
    const enumPojo = CoreUtils.numericEnumToPojo(enumeration);

    if (CollectionUtils.isEmpty(enumPojo)) {
        return [];
    }

    return Object.keys(enumPojo).map((key: string): SelectOption => {
        const value = enumPojo[key as keyof T];
        const text = enumerationDisplayNames == null ? key : t(enumerationDisplayNames[value]);

        return {
            value: value,
            text: text,
        };
    });
};

/**
 * Create an object mapping the values of the given enum to the provided default value.
 *
 * @typeparam `TEnum extends Enum<TEnum>` The enum type.
 * @typeparam `TDefaultValue` The type of the default value.
 * @param enumeration The enum whose values will serve as the keys of the returned object.
 * @param defaultValue The default value to populate the returned object with.
 * @returns {Readonly<Record<TEnum[keyof TEnum], TDefaultValue>>} An object mapping the values of the given enum to the provided default value.
 */
const populateDefaultValues = <TEnum extends Enum, TDefaultValue>(
    enumeration: TEnum,
    defaultValue: TDefaultValue
): Readonly<Record<TEnum[keyof TEnum], TDefaultValue>> =>
    EnumUtils.values(enumeration).reduce(
        (
            accumulator: Record<TEnum[keyof TEnum], TDefaultValue>,
            value: TEnum[keyof TEnum]
        ): Record<TEnum[keyof TEnum], TDefaultValue> => ({
            ...accumulator,
            [value]: defaultValue,
        }),
        {} as Record<TEnum[keyof TEnum], TDefaultValue>
    );

/**
 * Returns the values of a given enum.
 *
 * @typeparam `T extends Enum<T>` The enum type.
 * @param {T} enumeration The enum whose values should be returned.
 * @returns {(T[keyof T])[]} The valuesof the given enum.
 */
const values = <T extends Enum>(enumeration: T): T[keyof T][] =>
    keys(enumeration).map((key: keyof T): T[keyof T] => enumeration[key]);

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const EnumUtils = {
    entries: entries,
    enumToArray: CoreUtils.enumToArray,
    enumToObjectPartial: enumToObjectPartial,
    getRandomEnum: CoreUtils.getRandomEnum,
    isNumericEnumValue: isNumericEnumValue,
    keys: keys,
    numericEnumToPojo: CoreUtils.numericEnumToPojo,
    numericEnumToSelectOptions: numericEnumToSelectOptions,
    populateDefaultValues: populateDefaultValues,
    values: values,
};

export { EnumUtils };

// #endregion Exports
