export interface UserSearchState {
    searchText: string;
    debouncedSearchText?: string;
    roleType?: number;
    hasNoUserRoles?: boolean;
    currentPage: number;
}

export type UserSearchReducerActions =
    | { type: "initialize_search"; value: string }
    | { type: "update_search"; value: string }
    | { type: "update_debounced_search"; value?: string }
    | { type: "update_roleFilter"; value?: number }
    | { type: "update_currentPage"; value: number }
    | { type: "clear_search_criteria" };

export function userSearchReducer(
    state: UserSearchState,
    action: UserSearchReducerActions
): UserSearchState {
    switch (action.type) {
        case "initialize_search":
            return {
                ...state,
                searchText: action.value,
                debouncedSearchText: undefined,
                roleType: undefined,
                currentPage: 1,
            };

        case "update_search":
            if (state.searchText === action.value) {
                return state;
            }

            return {
                ...state,
                searchText: action.value,
            };

        case "update_debounced_search":
            if (state.debouncedSearchText === action.value) {
                return state;
            }

            return {
                ...state,
                debouncedSearchText: action.value,
                currentPage: 1,
            };

        case "update_roleFilter":
            if (state.roleType === action.value) {
                return state;
            }

            const hasNoUserRoles = action.value === -1;

            return {
                ...state,
                roleType: action.value,
                hasNoUserRoles: hasNoUserRoles,
                currentPage: 1,
            };

        case "update_currentPage":
            return {
                ...state,
                currentPage: action.value,
            };

        case "clear_search_criteria":
            return {
                ...state,
                searchText: "",
                debouncedSearchText: undefined,
                hasNoUserRoles: undefined,
                roleType: undefined,
                currentPage: 1,
            };
    }
}
