import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import React, { useMemo, useState, useEffect } from "react";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContractSummaryRecord } from "models/view-models/contracts/contract-summary-record";
import { DataTable } from "components/tables/data-table/data-table";
import {
    HeaderBanner,
    HeaderBannerNavItem,
    HeaderBannerNavPosition,
} from "components/header-banner/header-banner";
import { HeaderBannerUtils } from "utilities/header-banner-utils";
import { Icons } from "components/icons/constants/icons";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { Pager } from "components/pager/pager";
import { QuickLinkProps } from "components/quick-link/quick-link";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { TrainingCard, TrainingCardType } from "components/third-party/training-card/training-card";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useOrganizationOLLContractSummaries } from "utilities/hooks/models/organizations/use-organization-oll-contract-summaries";
import { useOrganizationOLLPastContractSummaries } from "utilities/hooks/models/organizations/use-organization-oll-past-contract-summaries";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import "./third-party-dashboard-online-page.scss";

import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ScrollUtils } from "utilities/scroll-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ThirdPartyDashboardOnlinePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BANNER_LINK_TEXT: TranslatedCopy = "viewTraining";
const CSS_CLASS_NAME: string = "third-party-dashboard-online-page";
const ITEMS_PER_PAGE = 10;

const QUICK_LINKS: QuickLinkProps[] = [
    {
        icon: Icons.Support,
        path: sitemap.authenticated.support,
        text: "support",
    },
    {
        icon: Icons.Reporting,
        path: sitemap.admin.reports.list,
        text: "reports",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getOLLTrainingPath = (contractId?: number, productId?: number) => {
    if (contractId == null || productId == null) {
        return undefined;
    }

    return RouteUtils.replacePathParams(sitemap.thirdPartyProvider.onlineLearning.details, {
        contractId: contractId,
        productId: productId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ThirdPartyDashboardOnlinePage: React.FC<ThirdPartyDashboardOnlinePageProps> =
    validatePageAccess(AccessControlKeys.ThirdPartyDashboardOnlinePage)((): JSX.Element => {
        const [currentPage, setCurrentPage] = useState<number>(1);
        const { record: globalState } = useGlobalState();
        const userFirstName = useMemo(
            () => globalState?.currentIdentity?.user?.firstName,
            [globalState?.currentIdentity?.user?.firstName]
        );
        const organizationId = useMemo(
            () => globalState?.currentIdentity?.user?.organizationId ?? 0,
            [globalState?.currentIdentity?.user?.organizationId]
        );

        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        const { notifications, markNotificationAsRead } = useUserNotifications({
            includeContract: true,
            includeProduct: true,
            roleType: RoleType.ClientAdmin,
            userId: userId,
        });

        const ollNotifications = useMemo(
            () => notifications.filter((n) => n.productId != null),
            [notifications]
        );

        const { contractSummaries } = useOrganizationOLLContractSummaries({
            includeWithdrawn: false,
            organizationId,
        });

        const { pastContractSummaries, rowCount: totalNumberOfPastTrainings } =
            useOrganizationOLLPastContractSummaries({
                organizationId,
                skip: (currentPage - 1) * ITEMS_PER_PAGE,
                take: ITEMS_PER_PAGE,
            });

        const handleNotificationClose = (notificationId?: number) => {
            if (notificationId == null) {
                return;
            }

            markNotificationAsRead?.(notificationId);
        };

        const navItems: HeaderBannerNavItem[] = HeaderBannerUtils.getThirdPartyDashboardNavItems();

        const getTrainingUrl = (contractId?: number, productId?: number): string => {
            if (contractId == null || productId == null) {
                return "";
            }
            return RouteUtils.replacePathParams(sitemap.thirdPartyProvider.onlineLearning.details, {
                contractId,
                productId,
            });
        };

        const onPageClick = (pageNumber: number) => {
            setCurrentPage(pageNumber);
        };

        useEffect(() => {
            ScrollUtils.scrollToElementBySelector(
                `${CSS_CLASS_NAME}__completed ${CSS_CLASS_NAME}__section`
            );
        }, [pastContractSummaries]);

        return (
            <>
                <HeaderBanner
                    title={t("twelcomeUserFirstName", {
                        twelcome: t("welcome"),
                        userFirstName: userFirstName,
                    })}
                    navPosition={HeaderBannerNavPosition.Right}
                    navItems={navItems}></HeaderBanner>
                <div className={CSS_CLASS_NAME}>
                    <h2>{t("currentTrainings")}</h2>
                    <div className={`${CSS_CLASS_NAME}__notifications`}>
                        {ollNotifications != null &&
                            ollNotifications.map((notification, index) => (
                                <NotificationBanner
                                    key={`${notification.id}-${index}`}
                                    icon={notification.getNotificationIcon()}
                                    linkPath={getOLLTrainingPath(
                                        notification.contractId,
                                        notification.productId
                                    )}
                                    linkText={t(BANNER_LINK_TEXT)}
                                    message={notification.message}
                                    notificationId={notification.id!}
                                    onClose={() => handleNotificationClose(notification.id)}
                                    style={notification.notificationType}
                                />
                            ))}
                    </div>
                    {CollectionUtils.hasValues(contractSummaries) ? (
                        <div className={`${CSS_CLASS_NAME}__current ${CSS_CLASS_NAME}__section`}>
                            {contractSummaries.map(
                                (contractSummary: ContractSummaryRecord, idx) => (
                                    <TrainingCard
                                        key={`current-contract-summary-${idx}`}
                                        enrollmentCount={contractSummary.enrollmentCount ?? 0}
                                        enrollmentLimit={contractSummary.enrollmentLimit ?? 0}
                                        evaluation={contractSummary.completedEvaluationCount ?? 0}
                                        evaluationTotal={contractSummary.enrollmentCount ?? 0}
                                        name={contractSummary.product?.name ?? ""}
                                        number={
                                            contractSummary.contractNumber ??
                                            t("notApplicableShort")
                                        }
                                        syllabus={contractSummary.completedSyllabusCount ?? 0}
                                        syllabusTotal={contractSummary.enrollmentCount ?? 0}
                                        type={TrainingCardType.Online}
                                        viewTrainingUrl={getTrainingUrl(
                                            contractSummary.contractId,
                                            contractSummary.product?.id
                                        )}
                                    />
                                )
                            )}
                        </div>
                    ) : (
                        <EmptyText>{t("yourOrganizationHasNoCurrentOnlineTrainings")}</EmptyText>
                    )}
                    <h2>{t("completedTrainings")}</h2>
                    <div className={`${CSS_CLASS_NAME}__completed ${CSS_CLASS_NAME}__section`}>
                        {CollectionUtils.hasValues(pastContractSummaries) ? (
                            <>
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="contract-number">
                                                {t("contractNumber")}
                                            </th>
                                            <th className="product-name">{t("productName")}</th>
                                            <th className="enrollment">{t("enrollment")}</th>
                                            <th className="completion">{t("completion")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pastContractSummaries.map(
                                            (contractSummary: ContractSummaryRecord) => (
                                                <tr
                                                    key={`${contractSummary.contractNumber}-${contractSummary.contractId}`}>
                                                    <td className="contract-number">
                                                        {contractSummary.contractNumber}
                                                    </td>
                                                    <td className="product-name">
                                                        {contractSummary.product?.name}
                                                    </td>
                                                    <td className="enrollment">
                                                        {`${contractSummary.enrollmentCount} ${
                                                            contractSummary.enrollmentLimit == null
                                                                ? ""
                                                                : " / " +
                                                                  contractSummary.enrollmentLimit
                                                        }`}
                                                    </td>
                                                    <td className="completion">
                                                        {contractSummary.completedSyllabusCount}/
                                                        {contractSummary.enrollmentLimit}
                                                    </td>
                                                    <td className="action">
                                                        <ContextMenu>
                                                            <ContextMenuAnchor
                                                                hrefPath={RouteUtils.localizePath(
                                                                    RouteUtils.replacePathParams(
                                                                        sitemap.thirdPartyProvider
                                                                            .onlineLearning.details,
                                                                        {
                                                                            contractId:
                                                                                contractSummary.contractId,
                                                                            productId:
                                                                                contractSummary
                                                                                    .product?.id,
                                                                        }
                                                                    )
                                                                )}
                                                                displayName={t("viewTraining")}
                                                            />
                                                        </ContextMenu>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </DataTable>
                                <Pager
                                    currentPage={currentPage}
                                    onPageClick={onPageClick}
                                    totalPages={totalNumberOfPastTrainings / ITEMS_PER_PAGE}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={totalNumberOfPastTrainings}></Pager>
                            </>
                        ) : (
                            <EmptyText table>
                                {t("yourOrganizationHasNoPastOnlineTrainings")}
                            </EmptyText>
                        )}
                    </div>

                    <h2>{t("quickLinks")}</h2>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <QuickLinkList links={QUICK_LINKS} />
                    </div>
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ThirdPartyDashboardOnlinePage };

// #endregion Exports
