import { StringUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

/**
 * Gets a cookie from the client.
 *
 * @param {string} cookieName The name of the cookie to retrieve.
 * @returns {string} The cookie value, or an empty string if the cookie could not be found.
 */
const getCookie = (cookieName: string): string => {
    let name = cookieName + "=";
    const parts = document.cookie.split(";");

    for (let i = 0; i < parts.length; i++) {
        let cookie = parts[i];

        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return "";
};

/**
 * Checks to see if the client has a cookie with the given name.
 *
 * @param {string} cookieName The name of the cookie to check for.
 * @returns {boolean} True if the client has a cookie with the given name, false otherwise.
 */
const hasCookie = (cookieName: string): boolean =>
    StringUtils.hasValue(getCookie(cookieName));

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const CookieUtils = {
    getCookie: getCookie,
    hasCookie: hasCookie,
};

export { CookieUtils };

// #endregion Exports
