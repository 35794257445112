import React, { useCallback } from "react";
import { Bubble, BubbleStyle } from "components/bubble/bubble";
import { Button, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { EventRecord } from "models/view-models/events/event-record";
import { ProductScormPackageRecord } from "models/view-models/products/product-scorm-package-record";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackagePreviewButton } from "components/scorm-packages/scorm-package-preview-button/scorm-package-preview-button";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useProductAssessments } from "utilities/hooks/models/products/use-product-assessments";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import "./event-assessment-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAssessmentListProps {
    event: EventRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-assessment-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAssessmentList: React.FC<EventAssessmentListProps> = (
    props: EventAssessmentListProps
): JSX.Element => {
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const productId = props.event.productId;
    const { productAssessments } = useProductAssessments({
        productId: productId,
        productVersionId: props.event.productVersionId,
    });

    const viewResults = useCallback(
        (productScormPackage: ProductScormPackageRecord) => {
            const path = RouteUtils.replacePathParams(viewEventRoutes.assessmentResults, {
                id: props.event.id,
                assessmentId: productScormPackage.scormPackage?.scormPackageAssessment?.id,
            });
            navigate(path);
        },
        [navigate, props.event.id, viewEventRoutes.assessmentResults]
    );

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            {CollectionUtils.isEmpty(productAssessments) ? (
                <EmptyText>{t("noAssessmentsAdded")}</EmptyText>
            ) : (
                <>
                    {productAssessments.map(
                        (productAssessment: ProductScormPackageRecord): JSX.Element => (
                            <Card
                                cssClassName={`${CSS_CLASS_NAME}__card`}
                                key={productAssessment.id}
                                stacked={true}>
                                <Paragraph size={ParagraphSize.Large}>
                                    {productAssessment.scormPackage?.name}
                                </Paragraph>
                                <div className={`${CSS_CLASS_NAME}__card__actions`}>
                                    {productAssessment.optional ? (
                                        <Bubble text={t("optional")} style={BubbleStyle.Grey} />
                                    ) : (
                                        <Bubble text={t("required")} style={BubbleStyle.Blue} />
                                    )}
                                    <ScormPackagePreviewButton
                                        text={t("previewAssessment")}
                                        externalScormCourseId={
                                            productAssessment.scormPackage?.externalScormCourseId
                                        }
                                        scormStatus={productAssessment.scormPackage?.status}
                                        buttonSize={ButtonSize.Small}
                                        buttonStyle={ButtonStyle.Primary}
                                    />
                                    <Button
                                        cssClassName={`${CSS_CLASS_NAME}`}
                                        onClick={() => viewResults(productAssessment)}
                                        size={ButtonSize.Small}
                                        style={ButtonStyle.Primary}
                                        text={t("viewResults")}
                                    />
                                </div>
                            </Card>
                        )
                    )}
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAssessmentList };

// #endregion Exports
