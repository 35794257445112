import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import React, { useCallback } from "react";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { UserLoginService } from "utilities/services/users/user-login-service";
import { useAzure } from "pages/public/azure-login/use-azure";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";
import { EndEmulationSessionButton } from "pages/admin/support/end-emulation-session-button";
import { t } from "utilities/localization/t";
import "./admin-layout-header-navigation-panel-footer.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderNavigationPanelFooterProps {
    navigateTo: (path: string) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-navigation-panel-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderNavigationPanelFooter: React.FC<
    AdminLayoutHeaderNavigationPanelFooterProps
> = (props: AdminLayoutHeaderNavigationPanelFooterProps): JSX.Element => {
    const { delete: logoutApi } = UserLoginService.useDelete();
    const { logout: signOut } = useAzure();
    const { record: globalState } = useGlobalState();
    const { useAzureB2CForSSO } = useFeatureFlags();
    const { isInEmulationMode } = useEmulationMode();

    const handleLogoutClick = useCallback(async (): Promise<void> => {
        const userLoginId: number = globalState.currentIdentity?.userLoginId() ?? 0;
        try {
            if (userLoginId > 0) {
                await logoutApi(userLoginId);
            }
        } catch (error) {
            console.error(error);
        } finally {
            if (useAzureB2CForSSO) {
                await signOut();
            }
        }
        if (!useAzureB2CForSSO) {
            props.navigateTo(sitemap.public.userLogin);
        }
    }, [globalState.currentIdentity, logoutApi, signOut, props, useAzureB2CForSSO]);

    return (
        <div className={CSS_CLASS_NAME}>
            <ButtonIcon
                buttonSize={ButtonSize.None}
                buttonStyle={ButtonStyle.None}
                cssClassName={`${CSS_CLASS_NAME}__action`}
                iconSize={IconSizes.Medium}
                iconType={Icons.Settings}
                onClick={() => props.navigateTo(sitemap.test.placeholder.profile)}
                text={t("profile")}
            />
            {isInEmulationMode ? (
                <EndEmulationSessionButton
                    buttonSize={ButtonSize.None}
                    buttonStyle={ButtonStyle.None}
                    className={`${CSS_CLASS_NAME}__action`}
                    isInFooter={true}
                />
            ) : (
                <ButtonIcon
                    buttonSize={ButtonSize.None}
                    buttonStyle={ButtonStyle.None}
                    cssClassName={`${CSS_CLASS_NAME}__action`}
                    iconType={Icons.Logout}
                    onClick={handleLogoutClick}
                    text={t("logOut")}
                />
            )}
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderNavigationPanelFooter };

// #endregion Exports
