import React from "react";
import "./quick-link.scss";
import { Anchor, AnchorPathType } from "../typography/anchors/anchor/anchor";
import { Icons } from "../icons/constants/icons";
import { Icon } from "../icons/icon";
import { IconSizes } from "../icons/constants/icon-sizes";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface QuickLinkProps {
    ariaLabel?: string;
    icon: Icons;
    path: string;
    pathType?: AnchorPathType.Internal | AnchorPathType.External | AnchorPathType.Download;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "quick-link";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const QuickLink: React.FC<QuickLinkProps> = (props: QuickLinkProps) => {
    const { pathType = AnchorPathType.Internal } = props;

    return (
        <Anchor cssClassName={CSS_CLASS_NAME} path={props.path} pathType={pathType}>
            <Icon type={props.icon} size={IconSizes.Large} />
            <span className={`${CSS_CLASS_NAME}__text`}>{t(props.text)}</span>
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default QuickLink;

// #endregion Exports
