import { AenResourceRecord } from "models/view-models/aen-resources/aen-resource-record";
import { AenResourceService } from "utilities/services/aen-resources/aen-resource-service";
import { RoleType } from "models/enumerations/users/role-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingAenResources";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseAenResourcesHook {
    error?: Error;
    isLoading: boolean;
    aenResources: AenResourceRecord[];
    rowCount: number;
    addAenResource: (aenResource: AenResourceRecord) => Promise<boolean>;
    updateAenResource: (aenResource: AenResourceRecord) => Promise<boolean>;
    deleteAenResource: (aenResource: AenResourceRecord) => Promise<boolean>;
}

interface UseAenResourcesHookOptions {
    onError?: (error: Error) => void;
    roleType?: RoleType;
    searchCriteriaReady?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useAenResources({
    onError,
    roleType,
    searchText,
    skip,
    take,
    searchCriteriaReady = true,
}: UseAenResourcesHookOptions): UseAenResourcesHook {
    const { list } = AenResourceService.useList();
    const { delete: deleteApi } = AenResourceService.useDelete();
    const { update } = AenResourceService.useUpdate();
    const { create } = AenResourceService.useCreate();
    const [aenResources, setAenResources] = useState<AenResourceRecord[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = useCallback(async () => {
        if (!searchCriteriaReady) {
            setAenResources([]);
            setIsLoading(false);
            return;
        }

        try {
            (async function getAenResources() {
                setIsLoading(true);
                const aenResourcesResponse = await list({
                    searchText: searchText,
                    roleType: roleType,
                    skip: skip,
                    take: take,
                });
                setIsLoading(false);

                if (
                    aenResourcesResponse?.resultObjects == null ||
                    aenResourcesResponse?.results == null ||
                    aenResourcesResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setAenResources(aenResourcesResponse.resultObjects);
                setRowCount(aenResourcesResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, roleType, searchCriteriaReady, searchText, setError, skip, take]);

    const deleteAenResource = async (aenResource: AenResourceRecord): Promise<boolean> => {
        try {
            if (aenResource.id == null) {
                return false;
            }

            const deleteResponse = await deleteApi(aenResource.id);

            if (!deleteResponse) {
                throw new Error("");
            }

            fetchData();

            ToastManager.success(t("contentDeletedSuccessfully"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueDeletingTheContent"));
            return false;
        }
    };

    const addAenResource = async (aenResource: AenResourceRecord): Promise<boolean> => {
        try {
            if (aenResource?.id !== undefined) {
                throw new Error(t("thereWasAnErrorCreatingTheContent"));
            }
            const createAenResourceResponse = await create(aenResource);
            const createAenResourceResult = createAenResourceResponse?.result;

            if (
                createAenResourceResult?.resultObject == null ||
                createAenResourceResult.hasErrors()
            ) {
                throw new Error(t("thereWasAnErrorCreatingTheContent"));
            }

            fetchData();

            ToastManager.success(t("contentCreatedSuccessfully"));

            return true;
        } catch (error) {
            if (error === "") {
                ToastManager.error(t("thereWasAnErrorCreatingTheContent"));
            } else {
                ToastManager.error(`${error}`);
            }
            return false;
        }
    };

    const updateAenResource = async (aenResource: AenResourceRecord): Promise<boolean> => {
        try {
            if (aenResource?.id === 0 || aenResource.id === undefined) {
                throw new Error(t("thereWasAnErrorSavingTheContent"));
            }
            const updateAenResourceResponse = await update(aenResource);
            const updateAenResourceResult = updateAenResourceResponse?.result;

            if (
                updateAenResourceResult?.resultObject == null ||
                updateAenResourceResult.hasErrors()
            ) {
                throw new Error(t("thereWasAnErrorSavingTheContent"));
            }

            fetchData();

            ToastManager.success(t("contentUpdatedSuccessfully"));

            return true;
        } catch (error) {
            if (error === "") {
                ToastManager.error(t("thereWasAnErrorSavingTheContent"));
            } else {
                ToastManager.error(`${error}`);
            }
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchText, skip, take, fetchData]);

    return {
        error,
        isLoading,
        aenResources,
        rowCount,
        addAenResource,
        updateAenResource,
        deleteAenResource,
    };
}

// #endregion Hook
