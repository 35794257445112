import React, { useCallback, useState } from "react";
import { AENProductCardSelectionList } from "components/products/aen-product-card-selection/aen-product-card-selection-list/aen-product-card-selection-list";
import { AENProductInfo } from "components/products/aen-product-card-selection/aen-product-info/aen-product-info";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useProducts } from "utilities/hooks/models/products/use-products";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./aen-product-catalog-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENProductCatalogPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-product-catalog-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 10;
const STATUS_FILTER = [ActiveStatus.Active];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENProductCatalogPage: React.FC<AENProductCatalogPageProps> = validatePageAccess(
    AccessControlKeys.AENProductCatalogPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.dashboard);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<number>();
    const [selectedTopic, setSelectedTopic] = useState<number>();

    const { products, rowCount: productCount } = useProducts({
        activeStatusFilter: STATUS_FILTER,
        applySearch: true,
        includeAvailableForAENOnly: true,
        includeLatestVersion: true,
        language: selectedLanguage,
        searchProductNameText: debouncedSearchText ?? "",
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        topic: selectedTopic,
    });

    const [selectedProduct, setSelectedProduct] = useState<ProductRecord>();

    const handleProductSelection = useCallback((newProduct?: ProductRecord) => {
        setSelectedProduct(newProduct);
    }, []);

    const handleSearchTriggered = useCallback((searchText: string): void => {
        setDebouncedSearchText(searchText);
        setSelectedProduct(undefined);
    }, []);

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedLanguage(NumberUtils.parseInt(event.target.value));
        setSelectedProduct(undefined);
        setCurrentPage(1);
    };

    const handleFilterByTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedTopic(NumberUtils.parseInt(event.target.value));
        setSelectedProduct(undefined);
        setCurrentPage(1);
    };

    const handleClearAll = () => {
        setSearchText("");
        setSelectedLanguage(undefined);
        setSelectedTopic(undefined);
        setSelectedProduct(undefined);
        setCurrentPage(1);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner title={t("aenProductCatalog")} />
            <SideContentLeftLayout
                sidebarElement={
                    <div className={`${CSS_CLASS_NAME}__sidebar`}>
                        <Paragraph
                            cssClassName="-label"
                            size={ParagraphSize.XLarge}
                            style={ParagraphStyle.Label}>
                            {t("filterResults")}
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                onSearchTextInputChange={handleSearchTextInputChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"instructorSearch"}
                                placeholder={t("typeToSearchByName")}
                                searchTextInputValue={searchText}
                            />
                            <FormSelect
                                ariaLabelledBy={t("filterByTopic")}
                                formFieldName={t("filterByTopic")}
                                id="productFilterByTopic"
                                label=""
                                placeholder={t("topic")}
                                onChange={handleFilterByTopicChange}
                                options={EnumUtils.numericEnumToSelectOptions(
                                    Topic,
                                    TopicDisplayNames
                                )}
                                value={selectedTopic?.toString()}
                            />
                            <FormSelect
                                ariaLabelledBy={t("filterByLanguage")}
                                formFieldName={t("filterByLanguage")}
                                id="instructorFilterByLanguage"
                                label=""
                                placeholder={t("language")}
                                onChange={handleFilterByLanguageChange}
                                options={EnumUtils.numericEnumToSelectOptions(
                                    Language,
                                    LanguageDisplayNames
                                )}
                                value={selectedLanguage?.toString()}
                            />
                            <Button
                                cssClassName="-clear"
                                onClick={handleClearAll}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Quaternary}
                                text={t("clearAllFilters")}
                            />
                        </div>
                    </div>
                }>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            {selectedProduct == null || !selectedProduct.availableForAEN ? (
                                <AENProductCardSelectionList
                                    products={products}
                                    onProductSelection={handleProductSelection}
                                />
                            ) : (
                                <AENProductInfo
                                    product={selectedProduct}
                                    onClose={() => handleProductSelection(undefined)}
                                />
                            )}
                        </SkipNavContent>
                    </div>
                </div>
                <div className="footer">
                    <Pager
                        currentPage={currentPage}
                        totalPages={productCount / ITEMS_PER_PAGE}
                        onPageClick={onPageClick}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalItems={productCount}
                    />
                </div>
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENProductCatalogPage };

// #endregion Exports
