import React, { useCallback, useEffect, useState } from "react";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Avatar, AvatarAspect } from "components/avatar/avatar";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { FormFileInputWithButtons } from "components/form/form-file-input-with-buttons/form-file-input-with-buttons";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeCheckBoxLabels,
} from "models/enumerations/products/instructor-led-training-type";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RecordUtils, StringUtils } from "andculturecode-javascript-core";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel, ToggleLabelDirection } from "components/toggle/toggle-label/toggle-label";
import { TravelType, TravelTypeCheckBoxLabels } from "models/instructors/travel-type";
import { UserRecord } from "models/view-models/users/user-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useInstructorProfile } from "utilities/hooks/models/instructors/use-instructor-profile";
import { t } from "utilities/localization/t";
import { NfpaUrlPaths } from "constants/nfpa-urls/nfpa-url-paths";
import { FullScreenTransition } from "components/full-screen-transition/full-screen-transition";
import { useAuthentication } from "use-authentication";
import { SyncUserDataBanner } from "molecules/banners/sync-user-data-banner";
import { useRefreshIdentity } from "utilities/hooks/use-refresh-identity";
import { sitemap } from "sitemap";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./instructor-enh-profile-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorENHProfilePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-enh-profile-page";

// Create an array of all the travel types.
const LANGUAGE_OPTIONS = Object.keys(Language)
    .filter((v) => !isNaN(parseInt(v)))
    .map((value) => {
        return parseInt(value) as Language;
    });

// Create an array of all the training types used for the Teaching Methods selection.
const TRAINING_TYPE_OPTIONS = Object.keys(InstructorLedTrainingType)
    .filter((v) => !isNaN(parseInt(v)))
    .map((value) => {
        return parseInt(value) as InstructorLedTrainingType;
    });

// Create an array of all the travel types.
const TRAVEL_TYPE_OPTIONS = Object.keys(TravelType)
    .filter((v) => !isNaN(parseInt(v)))
    .map((value) => {
        return parseInt(value) as TravelType;
    });

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorENHProfilePage: React.FC<InstructorENHProfilePageProps> = validatePageAccess(
    AccessControlKeys.InstructorDashboardPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);

    const { record: globalState } = useGlobalState();
    const { loginAttemptCompleted } = useAuthentication();
    const { refresh: refreshIdentity } = useRefreshIdentity();

    const {
        instructorProfile,
        saveInstructorProfile,
        addLanguage,
        removeLanguage,
        addTrainingType,
        removeTrainingType,
        addTravelType,
        removeTravelType,
        isLoading,
    } = useInstructorProfile({
        userId: globalState.currentIdentity?.userId() ?? -1,
        includeAvatarFile: true,
        includeLanguages: true,
        includeTrainingTypes: true,
        includeTravelTypes: true,
        includeResumeFile: true,
    });

    const [profile, setProfile] = useState<InstructorProfileRecord>(instructorProfile);
    const [user, setUser] = useState<UserRecord | undefined>(globalState.currentIdentity?.user);
    const nfpaExternalProfileURL = globalState.systemSettings!.getNfpaUrlV2(NfpaUrlPaths.MyProfile);

    useEffect(() => {
        setProfile(instructorProfile);
    }, [instructorProfile]);

    useEffect(() => {
        setUser(globalState.currentIdentity?.user);
    }, [globalState.currentIdentity?.user]);

    const handleBlur = useCallback(async (): Promise<void> => {
        if (profile == null) {
            ToastManager.error(t("profileUpdateFailed"));
            return;
        }

        if (!(await saveInstructorProfile(profile))) {
            ToastManager.error(t("profileUpdateFailed"));
        }
    }, [profile, saveInstructorProfile]);

    const hasLanguage = (language: Language): boolean => {
        return (
            (profile?.instructorLanguages?.filter(
                (instructorLanguage) => instructorLanguage.language === language
            )?.length ?? 0) > 0
        );
    };

    const updateLanguage = (checked: boolean, language: Language) => {
        if (checked) {
            addLanguage(language);
        } else {
            const instructorLanguage = profile?.instructorLanguages?.find(
                (instructorLanguage) => instructorLanguage.language === language
            );
            if (instructorLanguage != null) {
                removeLanguage(instructorLanguage);
            }
        }
    };

    const hasTrainingType = (trainingType: InstructorLedTrainingType): boolean => {
        return (
            (profile?.instructorTrainingTypes?.filter(
                (instructorTrainingType) =>
                    instructorTrainingType.instructorLedTrainingType === trainingType
            )?.length ?? 0) > 0
        );
    };

    const handleUserDataSync = async () => {
        refreshIdentity();
    };

    const updateTrainingType = (checked: boolean, trainingType: InstructorLedTrainingType) => {
        if (checked) {
            addTrainingType(trainingType);
        } else {
            const instructorTrainingType = profile?.instructorTrainingTypes?.find(
                (instructorTrainingType) =>
                    instructorTrainingType.instructorLedTrainingType === trainingType
            );
            if (instructorTrainingType != null) {
                removeTrainingType(instructorTrainingType);
            }
        }
    };

    const hasTravelType = (travelType: TravelType): boolean => {
        return (
            (profile?.instructorTravelTypes?.filter(
                (instructorTravelType) => instructorTravelType.travelType === travelType
            )?.length ?? 0) > 0
        );
    };

    const updateTravelType = (checked: boolean, travelType: TravelType) => {
        if (checked) {
            addTravelType(travelType);
        } else {
            const instructorTravelType = profile?.instructorTravelTypes?.find(
                (instructorTravelType) => instructorTravelType.travelType === travelType
            );
            if (instructorTravelType != null) {
                removeTravelType(instructorTravelType);
            }
        }
    };

    const updateProfile = (values: Partial<InstructorProfileRecord>) => {
        setProfile(profile?.with(values));
    };

    const handleSetAboutMe = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateProfile({ aboutMe: e.target.value });
    };

    const handleSetAvatarFileId = (file: FileRecord) => {
        const updatedInstructorProfile = profile?.with({
            avatarFile: RecordUtils.ensureRecord(file, FileRecord),
            avatarFileId: file.id,
        });

        saveInstructorProfile(updatedInstructorProfile);
    };

    const handleAvatarDelete = () => {
        const updatedInstructorProfile = profile?.with({
            avatarFile: undefined,
            avatarFileId: undefined,
        });
        saveInstructorProfile(updatedInstructorProfile);
    };

    const handleSetResumeFileId = (file: FileRecord) => {
        const updatedInstructorProfile = profile?.with({
            resumeFile: RecordUtils.ensureRecord(file, FileRecord),
            resumeFileId: file.id,
        });
        saveInstructorProfile(updatedInstructorProfile);
    };

    const handleResumeDelete = () => {
        const updatedInstructorProfile = profile?.with({
            resumeFile: undefined,
            resumeFileId: undefined,
        });
        saveInstructorProfile(updatedInstructorProfile);
    };

    const handleShareLocationChange = () => {
        const updatedInstructorProfile = profile?.with({
            shareLocation: !profile?.shareLocation,
        });
        saveInstructorProfile(updatedInstructorProfile);
    };

    const validateAvatarFile = (file: File): boolean => {
        const fileExtensionValid: boolean = file.name.endsWith(".jpg");
        const fileSizeInKB = file.size / 1024;
        const maxFileSizeInKB = 3 * 1024;
        const fileSizeValid = fileSizeInKB < maxFileSizeInKB;

        return fileExtensionValid && fileSizeValid;
    };

    const handleInvalidProfileFile = (): void => {
        ToastManager.error(t("youMustUploadAJPGSmallerThan3MB"));
    };

    const validateResumeFile = (file: File): boolean => {
        const fileExtensionValid: boolean = file.name.endsWith(".pdf");
        const fileSizeInKB = file.size / 1024;
        const maxFileSizeInKB = 5 * 1024;
        const fileSizeValid = fileSizeInKB < maxFileSizeInKB;

        return fileExtensionValid && fileSizeValid;
    };

    const handleInvalidResumeFile = (): void => {
        ToastManager.error(t("youMustUploadAPDFSmallerThan5MB"));
    };

    const getLocationText = (location?: string): string => {
        const locationText: string = location != null ? location.toLowerCase() : "--";
        return locationText
            .split(" ")
            .map((word) => StringUtils.upperFirst(word))
            .join(" ");
    };

    const formatPhoneNumber = (phoneNumber?: string): string => {
        const numbersOnly = ("" + phoneNumber).replace(/\D/g, "");
        const match = numbersOnly.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? `+${match[1]} ` : "";
            return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        }
        return "";
    };

    return !loginAttemptCompleted ? (
        <FullScreenTransition transitionText="" />
    ) : (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("instructorProfile")}
                    </Heading>
                    <Paragraph style={ParagraphStyle.Light}>
                        {t(
                            "yourInstructorProfileCanBeViewedByNFPAStaffAsWellAsAENProvidersYourProfileIsUsedToAssistInTheSelectionOfInstructorAssignments"
                        )}
                    </Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__instructor`}>
                    <div className={`${CSS_CLASS_NAME}__instructor__about`}>
                        <Paragraph size={ParagraphSize.Large} style={ParagraphStyle.SemiBold}>
                            {t("aboutMe")}
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__avatar`}>
                            <div className={`${CSS_CLASS_NAME}__avatar__image-container`}>
                                <Avatar
                                    avatarAspect={AvatarAspect.Square}
                                    cssClassName={`${CSS_CLASS_NAME}__avatar__image`}
                                    avatarFileId={profile?.avatarFileId}
                                />
                            </div>
                            <div>
                                <FormFileInputWithButtons
                                    buttonText={
                                        instructorProfile.avatarFile
                                            ? instructorProfile.avatarFile?.fileName
                                            : t("selectAFile")
                                    }
                                    file={profile.avatarFile}
                                    fileFormat=".jpg"
                                    maxFileSizeInMB={3}
                                    formFieldName="avatar"
                                    id="avatar"
                                    label={t("uploadAProfilePicture")}
                                    fileIsValid={validateAvatarFile}
                                    onFileInvalid={handleInvalidProfileFile}
                                    onFileDelete={handleAvatarDelete}
                                    onFileUpload={handleSetAvatarFileId}
                                    placeholder={t("selectAFile")}
                                    storageContainer={StorageContainers.FileUpload}
                                />
                            </div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__about__section`}>
                            <FormTextArea
                                formFieldName="bio"
                                id="bio"
                                label={t("bio")}
                                maxLength={700}
                                showCharactersRemaining={true}
                                onBlur={handleBlur}
                                onChange={handleSetAboutMe}
                                placeholder={t("aShortDescriptionAboutYou")}
                                required={true}
                                rows={5}
                                value={profile?.aboutMe}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__resume__section`}>
                            <FormFileInputWithButtons
                                buttonText={t("selectAFile")}
                                file={profile.resumeFile}
                                fileFormat=".pdf"
                                maxFileSizeInMB={5}
                                formFieldName="resume/cvs"
                                id="resume"
                                label={t("uploadResumeOrCV")}
                                fileIsValid={validateResumeFile}
                                onFileInvalid={handleInvalidResumeFile}
                                onFileDelete={handleResumeDelete}
                                onFileUpload={handleSetResumeFileId}
                                placeholder={t("selectAFile")}
                                storageContainer={StorageContainers.FileUpload}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__about__section`}>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Bold}>
                                {t("location")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                                {t(
                                    "yourLocationInformationWillComeDirectlyFromYourNFPAProfileIfYouWouldLikeYourLocationInformationVisibleToAENProvidersPleaseMoveTheToggleToTheRightToTurnOnTheShareMyLocationInformationFeature"
                                )}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__instructor__about__row ${CSS_CLASS_NAME}__instructor__about__grid`}>
                                <FormFieldReadonly label={t("country")}>
                                    <Paragraph>{getLocationText(user?.country)}</Paragraph>
                                </FormFieldReadonly>
                                <FormFieldReadonly label={t("citySlashTown")}>
                                    <Paragraph>{getLocationText(user?.city)}</Paragraph>
                                </FormFieldReadonly>
                                <FormFieldReadonly label={t("stateSlashProvinceSlashRegion")}>
                                    <Paragraph>{getLocationText(user?.state)}</Paragraph>
                                </FormFieldReadonly>
                            </div>
                            <Anchor
                                path={nfpaExternalProfileURL}
                                pathType={AnchorPathType.External}
                                text={t("updateYourLocationOnNfpaOrg")}
                            />
                            <ToggleLabel
                                checked={instructorProfile.shareLocation}
                                direction={ToggleLabelDirection.Right}
                                id="share-location"
                                label={t("shareMyLocationInformation")}
                                onToggle={handleShareLocationChange}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__about__section`}>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Bold}>
                                {t("teachingContactInformation")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                                {t(
                                    "yourContactInformationWillComeDirectlyFromYourNFPAProfileYourContactInformationWillBeAvailableToLMSAdministratorsForSchedulingPurposesItWillAlsoBeVisibleToEnrolledLearnersForEventsInWhichYouAreTheInstructor"
                                )}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__instructor__about__row ${CSS_CLASS_NAME}__instructor__about__grid`}>
                                <FormFieldReadonly label={t("email")}>
                                    <Paragraph>{user?.email}</Paragraph>
                                </FormFieldReadonly>
                                <FormFieldReadonly label={t("phone")}>
                                    <Paragraph>{formatPhoneNumber(user?.phoneNumber)}</Paragraph>
                                </FormFieldReadonly>
                            </div>
                            <Anchor
                                path={nfpaExternalProfileURL}
                                pathType={AnchorPathType.External}
                                text={t("updateYourContactInformationOnNfpaOrg")}
                            />
                        </div>
                        <SyncUserDataBanner
                            userId={user?.id}
                            lastSync={user?.updatedOn}
                            inInstructorProfile={true}
                            onUserDataSync={handleUserDataSync}
                            bannerText={t("notSeeingLocationOrContactInformation")}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__instructor__preferences`}>
                        <Paragraph size={ParagraphSize.Large} style={ParagraphStyle.SemiBold}>
                            {t("teachingPreferences")}
                        </Paragraph>
                        <div
                            className={`${CSS_CLASS_NAME}__instructor__preferences__section -small-spacing`}>
                            <Paragraph style={ParagraphStyle.Bold}>
                                {t("teachingLanguages")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                                {t("pleaseSelectAllThatApply")}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__instructor__preferences__selections`}>
                                {LANGUAGE_OPTIONS.map((language) => {
                                    return (
                                        <CheckboxInput
                                            disabled={isLoading}
                                            key={language}
                                            checked={hasLanguage(language)}
                                            id={`language-${language}`}
                                            label={t(LanguageDisplayNames[language])}
                                            onChange={(checked) =>
                                                updateLanguage(checked, language)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__preferences__section`}>
                            <Paragraph style={ParagraphStyle.Bold}>
                                {t("teachingMethods")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                                {t("pleaseSelectAllThatApply")}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__instructor__preferences__selections`}>
                                {TRAINING_TYPE_OPTIONS.map((trainingType) => {
                                    return (
                                        <CheckboxInput
                                            disabled={isLoading}
                                            key={trainingType}
                                            checked={hasTrainingType(trainingType)}
                                            id={`training-type-${trainingType}`}
                                            label={t(
                                                InstructorLedTrainingTypeCheckBoxLabels[
                                                    trainingType
                                                ]
                                            )}
                                            onChange={(checked) =>
                                                updateTrainingType(checked, trainingType)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__instructor__preferences__section`}>
                            <Paragraph style={ParagraphStyle.Bold}>
                                {t("travelPreferences")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                                {t("pleaseSelectAllThatApply")}
                            </Paragraph>
                            <div
                                className={`${CSS_CLASS_NAME}__instructor__preferences__selections`}>
                                {TRAVEL_TYPE_OPTIONS.map((travelType) => {
                                    return (
                                        <CheckboxInput
                                            disabled={isLoading}
                                            key={travelType}
                                            checked={hasTravelType(travelType)}
                                            id={`travel-type-${travelType}`}
                                            label={t(TravelTypeCheckBoxLabels[travelType])}
                                            onChange={(checked) =>
                                                updateTravelType(checked, travelType)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorENHProfilePage };

// #endregion Exports
