import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { Registration } from "models/interfaces/registrations/registration";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { UserRecord } from "models/view-models/users/user-record";
import { EnrollmentRecord } from "../enrollments/enrollment-record";
import { EnrollmentUnitCourseRecord } from "../enrollments/enrollment-unit-course-record";
import { EnrollmentScormPackageAssessmentRecord } from "../enrollments/enrollment-scorm-package-assessment-record";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Registration = {
    attempts: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    enrollmentUnitCourse: undefined,
    enrollmentUnitCourseId: undefined,
    enrollmentScormPackageAssessment: undefined,
    enrollmentScormPackageAssessmentId: undefined,
    hasAvailableAttempts: true,
    id: undefined,
    skipExternalRegistration: false,
    scormPackage: undefined,
    scormPackageId: 0,
    updatedById: undefined,
    updatedOn: undefined,
    user: undefined,
    userId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class RegistrationRecord extends Record(defaultValues) implements Registration {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Registration>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.attempts)) {
            params.attempts = RecordUtils.ensureRecords(params.attempts, AttemptRecord);
        }

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.enrollmentUnitCourse != null) {
            params.enrollmentUnitCourse = RecordUtils.ensureRecord(
                params.enrollmentUnitCourse,
                EnrollmentUnitCourseRecord
            );
        }

        if (params.enrollmentScormPackageAssessment != null) {
            params.enrollmentScormPackageAssessment = RecordUtils.ensureRecord(
                params.enrollmentScormPackageAssessment,
                EnrollmentScormPackageAssessmentRecord
            );
        }

        if (params.scormPackage != null) {
            params.scormPackage = RecordUtils.ensureRecord(params.scormPackage, ScormPackageRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to return the last attempt associated with this registration.
     *
     * @returns {AttemptRecord} The most recent AttemptRecord.
     */
    public getLatestAttempt(): AttemptRecord | undefined {
        if (this.attempts === undefined || this.attempts === null || this.attempts.length === 0) {
            return new AttemptRecord();
        }

        const numberOfAttempts = this.attempts.length;
        const latestAttempt = this.attempts.find((a) => a.attemptNumber === numberOfAttempts);
        return latestAttempt;
    }

    /**
     * Convenience method to return whethre a passing attempt exists for this registration.
     */
    public hasPassingAttempt(): boolean {
        if (this.attempts === undefined || this.attempts === null || this.attempts.length === 0) {
            return false;
        }

        return this.attempts.some((a) => a.successStatus === AttemptSuccessStatus.Passed);
    }

    /**
     * Given a set of values for Registration properties, create a new RegistrationRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Registration>} values The values to overwrite on this instance.
     * @returns A RegistrationRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Registration>): RegistrationRecord {
        return new RegistrationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RegistrationRecord };

// #endregion Exports
