import React from "react";
import { Tab, TabProps } from "components/tabs/tabs/tab/tab";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import "./icon-tab.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface IconTabProps extends TabProps {
    iconType: Icons;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "icon-tab";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const IconTab: React.FC<IconTabProps> = (props: IconTabProps): JSX.Element => {
    return (
        <Tab
            cssClassName={CSS_CLASS_NAME}
            isActive={props.isActive}
            onClick={props.onClick}
            tabName={"emptyString"}>
            <Icon
                cssClassName={`${CSS_CLASS_NAME}__icon`}
                size={IconSizes.Medium}
                type={props.iconType}
            />
            <span>{props.tabName}</span>
        </Tab>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { IconTab };

// #endregion Exports
