import { AenResourceRecord } from "models/view-models/aen-resources/aen-resource-record";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Card } from "components/card/card";
import { FileUtils } from "utilities/files/file-utils";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./resource-content-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ResourceContentCardProps {
    resource?: AenResourceRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "resource-content-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResourceContentCard: React.FC<ResourceContentCardProps> = ({
    resource,
}): JSX.Element | null => {
    if (resource == null) {
        return null;
    }

    const { fileId } = resource;

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div>
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__name`} size={ParagraphSize.Medium}>
                    {resource.name}
                </Paragraph>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__description`}
                    style={ParagraphStyle.Faded}>
                    {resource.description}
                </Paragraph>
            </div>

            {fileId != null && (
                <AnchorIcon
                    ariaLabel={t("downloadButtonForResourceResourceName", {
                        resourceName: resource.name,
                    })}
                    cssClassName={`${CSS_CLASS_NAME}__download-icon`}
                    iconType={Icons.Download}
                    pathType={AnchorPathType.External}
                    path={FileUtils.fileUrl(fileId)}
                />
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ResourceContentCard };

// #endregion Exports
