import React, { useState } from "react";
import { AenApplicationActivityLogRecord } from "models/view-models/aen-applications/aen-application-activity-log-record";
import { AenApplicationAddNotesForm } from "./aen-application-notes-form/aen-application-add-notes-form";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationAddLogModalProps {
    handleSave: (log: AenApplicationActivityLogRecord) => Promise<boolean>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationAddLogModal: React.FC<AenApplicationAddLogModalProps> = ({
    handleSave,
    open,
    setOpen,
}): JSX.Element => {
    const [log, setLog] = useState<AenApplicationActivityLogRecord>(
        new AenApplicationActivityLogRecord()
    );
    const [saving, setSaving] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dirty, setDirty] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: saving || !StringUtils.hasValue(log.notes),
            onClick: () => onLogSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setLog(new AenApplicationActivityLogRecord());
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
        }
    };

    const onLogSave = async (): Promise<void> => {
        setSaving(true);
        if (await handleSave(log)) {
            resetForm();
            setOpen(false);
        }
        setSaving(false);
    };

    const onLogChanged = (attachment: AenApplicationActivityLogRecord): void => {
        setDirty(true);
        setLog(attachment);
    };

    return (
        <Modal
            isOpen={open}
            onModalClose={onModalClose}
            title={t("addNotes")}
            actions={modalActionArray}
            modalStyle={""}>
            <AenApplicationAddNotesForm activityLog={log} onActivityLogChanged={onLogChanged} />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationAddLogModal };

// #endregion Exports
