import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { ProductRecord } from "models/view-models/products/product-record";
import { SelectableProduct } from "models/interfaces/products/selectable-product";
import { t } from "utilities/localization/t";
import "./event-product-selection-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductSelectionListProps {
    handleAdd: (product: ProductRecord) => void;
    handleRemove: (product: ProductRecord) => void;
    selectableProducts: SelectableProduct[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-product-selection-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductSelectionList: React.FC<EventProductSelectionListProps> = (
    props: EventProductSelectionListProps
) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="name">{t("productName")}</th>
                    <th className="id">{t("id")}</th>
                    <th className="action">{t("action")}</th>
                </tr>
            </thead>
            <tbody>
                {props.selectableProducts.map((selectableProduct) => (
                    <tr key={selectableProduct.product.id}>
                        <td className="name">{selectableProduct.product.name}</td>
                        <td className="id">{selectableProduct.product.id}</td>
                        <td className="action">
                            {!selectableProduct.isSelected && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    iconType={Icons.Plus}
                                    onClick={() => props.handleAdd(selectableProduct.product)}
                                    text={t("add")}
                                />
                            )}
                            {selectableProduct.isSelected && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Minus}
                                    onClick={() => props.handleRemove(selectableProduct.product)}
                                    text={t("remove")}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductSelectionList };

// #endregion Exports
