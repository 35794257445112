import React, { useCallback, useEffect, useMemo, useState } from "react";
import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Donut, DonutSize, DonutStyle } from "components/donut/donut";
import { EventRecord } from "models/view-models/events/event-record";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import {
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocation, Location } from "react-router-dom";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./manage-event-layout-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageEventLayoutSidebarProps {
    badges?: string[];
    completionPercentage?: number;
    cssClassName?: string;
    title?: string;
    subTitle?: string;
    titleEmphasis?: ParagraphEmphasis;
    trainingType?: TrainingType;
    navItems?: SidebarNavItem[];
    event: EventRecord;
}

export interface SidebarNavItem {
    disabled?: boolean;
    displayName: string;
    iconType: Icons;
    matchPathOnStartsWith?: boolean;
    path: string;
}

export interface SidebarNavItemProps {
    disabled?: boolean;
    displayName: string;
    isActive: boolean;
    icon: Icons;
    onClick: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-event-layout-sidebar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageEventLayoutSidebar: React.FC<ManageEventLayoutSidebarProps> = (
    props: ManageEventLayoutSidebarProps
): JSX.Element => {
    const location: Location = useLocation();
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { get: getProvider } = ProviderService.useGet();
    const [associatedProvider, setAssociatedProvider] = useState<ProviderRecord>();
    const isAEN = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);
    const loggedInUserProviderId = useMemo(
        () => globalState?.currentIdentity?.user?.providerId ?? -1,
        [globalState?.currentIdentity?.user?.providerId]
    );

    const badges: string[] = props.badges == null ? [] : props.badges;
    const navItems = props.navItems ?? new Array<SidebarNavItem>();
    const trainingType = props.trainingType;
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const canEditEvents =
        ((isAEN && associatedProvider?.isActive) ||
            (currentUserIsInNfpaRole &&
                !globalState.currentIdentity?.isCurrentlyInRole(RoleType.NfpaSupport))) ??
        false;

    const stepIsActive = useCallback(
        (navItem: SidebarNavItem) => {
            return navItem.matchPathOnStartsWith === true
                ? location.pathname.startsWith(navItem.path)
                : navItem.path === location.pathname;
        },
        [location]
    );

    let sidebarNavItems: SidebarNavItemProps[] = navItems.map(
        (navItem): SidebarNavItemProps => ({
            displayName: navItem.displayName,
            disabled: navItem.disabled,
            icon: navItem.iconType,
            isActive: stepIsActive(navItem),
            onClick: () => {
                navigate(navItem.path);
            },
        })
    );

    const trainingTypeIcon = useMemo(() => {
        if (trainingType === TrainingType.InstructorLedTraining) {
            return Icons.Calendar;
        }

        if (trainingType === TrainingType.OnlineLearning) {
            return Icons.LibraryBooks;
        }

        return Icons.LibraryBooks;
    }, [trainingType]);

    const editEventPath = useMemo(() => {
        if (props.event.id == null || props.event.id <= 0) {
            return "";
        }

        const path = currentUserIsInNfpaRole
            ? sitemap.admin.event.edit.details
            : sitemap.admin.event.edit.contact;

        return RouteUtils.replacePathParams(path, { id: props.event.id });
    }, [currentUserIsInNfpaRole, props.event.id]);

    const fetchProvider = useCallback(async () => {
        try {
            const pathParams: ProviderResourcePathParams = {
                id: loggedInUserProviderId,
            };

            const providerResponse = await getProvider(pathParams);
            const providerResult = providerResponse?.result;

            if (providerResult?.resultObject == null || providerResult.hasErrors()) {
                throw new Error();
            }
            setAssociatedProvider(providerResult.resultObject);
        } catch {
            ToastManager.error(t("problemLoadingProvider"));
        }
    }, [getProvider, loggedInUserProviderId]);

    useEffect(() => {
        if (isAEN) {
            fetchProvider();
        }
    }, [fetchProvider, isAEN]);

    return (
        <aside className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__details`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    {props.completionPercentage != null && (
                        <Donut
                            includeLabel={true}
                            percent={props.completionPercentage}
                            size={DonutSize.Large}
                            style={DonutStyle.Inverted}
                            subLabel={t("complete")}
                        />
                    )}
                    {trainingType != null && (
                        <div className={`${CSS_CLASS_NAME}__training-type`}>
                            <Paragraph
                                cssClassName="line-clamp-2"
                                size={ParagraphSize.Large}
                                style={ParagraphStyle.Label}>
                                <Icon type={trainingTypeIcon} />
                                {t(TrainingTypeDisplayNames[trainingType]).toUpperCase()}
                            </Paragraph>
                        </div>
                    )}
                </div>
                {props.title && (
                    <Tooltip
                        content={props.title}
                        placement={TooltipPlacement.Top}
                        style={TooltipStyle.Secondary}>
                        <div>
                            <Paragraph
                                cssClassName="line-clamp-2"
                                emphasis={props.titleEmphasis}
                                size={ParagraphSize.Large}
                                style={ParagraphStyle.Inverted}>
                                {props.title}
                            </Paragraph>
                        </div>
                    </Tooltip>
                )}
                {props.subTitle && (
                    <Tooltip
                        content={props.subTitle}
                        placement={TooltipPlacement.Top}
                        style={TooltipStyle.Secondary}>
                        <div>
                            <Paragraph
                                cssClassName="line-clamp-2"
                                emphasis={props.titleEmphasis}
                                size={ParagraphSize.Small}
                                style={ParagraphStyle.Faded}>
                                {props.subTitle}
                            </Paragraph>
                        </div>
                    </Tooltip>
                )}
                {badges != null && badges.length > 0 && (
                    <div className={`${CSS_CLASS_NAME}__details__tags`}>
                        {badges.map((badgeText, i) => (
                            <Badge
                                key={`badge-${i}`}
                                style={BadgeStyle.Transparent}
                                text={badgeText}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__nav`}>
                {sidebarNavItems.map((item, i) => {
                    const classNames = [];

                    if (item.isActive) {
                        classNames.push("-active");
                    }

                    if (item.disabled) {
                        classNames.push("-disabled");
                    }

                    return (
                        <button
                            className={classNames.join(" ")}
                            disabled={item.disabled}
                            key={`nav-sidebar-${i}`}
                            onClick={item.onClick}
                            type="button">
                            <Icon size={IconSizes.Large} type={item.icon} />
                            <span>{item.displayName}</span>
                        </button>
                    );
                })}
            </div>

            <div className={`${CSS_CLASS_NAME}__footer`}>
                {canEditEvents && (
                    <ButtonIcon
                        linkPath={editEventPath}
                        ariaLabelledBy={t("editEvent")}
                        buttonStyle={ButtonStyle.Inverted}
                        buttonType={ButtonType.Link}
                        iconType={Icons.Edit}
                        text={t("editEvent")}
                    />
                )}
            </div>
        </aside>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageEventLayoutSidebar };

// #endregion Exports
