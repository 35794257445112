import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AenApplicationAddFileModal } from "components/aen-application/aen-application-add-file-modal/aen-application-add-file-modal";
import { AenApplicationFileList } from "components/aen-application/aen-application-file-list/aen-application-file-list";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { t } from "utilities/localization/t";
import { useAenInstructorApplicationFiles } from "utilities/hooks/models/aen-applications/use-aen-instructor-application-files";
import { useAenProviderApplicationFiles } from "utilities/hooks/models/aen-applications/use-aen-provider-application-files";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENApplicationsFilePageProps {
    applicationType: AenApplicationType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-applications-file-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENApplicationsFilePage: React.FC<AENApplicationsFilePageProps> =
    validatePageAccess<AENApplicationsFilePageProps>(AccessControlKeys.AdminAenApplicationFiles)(
        ({ applicationType }): JSX.Element => {
            const { id } = useParams();
            const aenApplicationId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
            const [showModal, setShowModal] = useState<boolean>(false);
            const { files, saveFile } =
                applicationType === AenApplicationType.Instructor
                    ? useAenInstructorApplicationFiles({
                          aenApplicationId: aenApplicationId,
                      })
                    : useAenProviderApplicationFiles({ aenApplicationId: aenApplicationId });

            return (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <HeaderActions
                            title={t("fileUploads")}
                            headingPriority={HeadingPriority.H2}
                            headingSize={HeadingSize.XSmall}>
                            <ButtonIcon
                                onClick={() => {
                                    setShowModal(true);
                                }}
                                buttonStyle={ButtonStyle.Primary}
                                text={t("addNewFile")}
                                iconType={Icons.Plus}
                            />
                        </HeaderActions>
                    </div>
                    <AenApplicationFileList aenApplicationFiles={files} />
                    <AenApplicationAddFileModal
                        aenApplicationType={applicationType}
                        handleSaveAttachment={saveFile}
                        open={showModal}
                        setOpen={setShowModal}
                    />
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENApplicationsFilePage };

// #endregion Exports
