import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EnrollmentUnitRecord } from "models/view-models/enrollments/enrollment-unit-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { EnrollmentUnitService } from "utilities/services/enrollments/enrollment-unit-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseEnrollmentUnitsHook {
    enrollmentUnits: EnrollmentUnitRecord[];
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseEnrollmentUnitsHookOptions {
    enrollmentId?: number | string;
}

export function useEnrollmentUnits(props: UseEnrollmentUnitsHookOptions): UseEnrollmentUnitsHook {
    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentUnits, setEnrollmentUnits] = useState<EnrollmentUnitRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listEnrollmentUnits } = EnrollmentUnitService.useList();

    useEffect(() => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function fetchEnrollmentUnit() {
            try {
                const { result, resultObjects } = await listEnrollmentUnits(
                    {
                        enrollmentId: enrollmentId,
                    },
                    {}
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setEnrollmentUnits(resultObjects);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingUnits"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [enrollmentId, listEnrollmentUnits]);

    return {
        errors,
        isLoading,
        enrollmentUnits,
    };
}
