import { Record } from "immutable";
import { Identity } from "models/interfaces/identity/identity";
import { UserRecord } from "models/view-models/users/user-record";
import { LocalStorageKey } from "utilities/enumerations/local-storage-keys";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import { RoleRecord } from "models/view-models/roles/role-record";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserLoginRecord } from "models/view-models/users/user-login-record";
import { RoleType, RoleTypeAuthorizationRulesMap } from "models/enumerations/users/role-type";
import { CollectionUtils } from "utilities/collection-utils";
import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Permission } from "utilities/enumerations/authorization/permission";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Identity = {
    role: undefined,
    user: undefined,
    userLogin: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class IdentityRecord extends Record(defaultValues) implements Identity {
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Identity) {
        if (params == null) {
            params = { ...defaultValues };
        }

        if (params.role != null) {
            params.role = RecordUtils.ensureRecord(params.role, RoleRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.userLogin != null) {
            params.userLogin = RecordUtils.ensureRecord(params.userLogin, UserLoginRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Check if the currently authenticated user has access to the given resource goverend by the
     * provided Access Control key.
     *
     * @param {AccessControlKeys} accessControlKey The Access Control key to check authoirzation against.
     * @returns {boolean} True if the currently authenticated user has access to the given resource, false otherwise.
     */
    public hasAccess(accessControlKey: AccessControlKeys): boolean {
        if (!this.hasUser() || !this.hasRole()) {
            return false;
        }

        const roleType: RoleType | null = this.roleType();

        if (roleType == null) {
            return false;
        }

        const authorizationRules: RoleAuthorizationRules = RoleTypeAuthorizationRulesMap[roleType];
        const permission: Permission = authorizationRules[accessControlKey];

        return permission === Permission.Allow;
    }

    // Determine if this user can modify the given roletype
    public hasAccessToModifyRole(roleType: RoleType | undefined): boolean {
        if (roleType == null || !this.hasRole()) {
            return false;
        }

        // Admin can modify Any role.
        if (this.role?.roleType === RoleType.NfpaAdministrator) {
            return true;
        }

        // Operator can modify some roles.
        if (this.role?.roleType === RoleType.NfpaOperator) {
            return (
                roleType === RoleType.NfpaOperator ||
                roleType === RoleType.Learner ||
                roleType === RoleType.ClientAdmin ||
                roleType === RoleType.NfpaSupport
            );
        }

        // Support can only modify Learner Role.
        if (this.role?.roleType === RoleType.NfpaSupport) {
            return roleType === RoleType.Learner;
        }

        return false;
    }

    public hasRole(): this is this & { role: RoleRecord } {
        return this.role != null;
    }

    public hasUser(): boolean {
        return this.user != null;
    }

    public hasUserId(): boolean {
        return this.hasUser() && this.userId() > 0;
    }

    public hasUserLogin(): boolean {
        return this.userLogin != null;
    }

    public hasUserLoginId(): boolean {
        return this.hasUserLogin() && this.userLoginId() > 0;
    }

    /**
     * Check if this IdentityRecord is authenticated in any of the given Roles.
     *
     * @param {RoleType[]} roles The set of Roles to check for authentication.
     * @returns {boolean} True if this IdentityRecord is authenticated as any Role in the provided list of Roles, false otherwise.
     */
    public isCurrentlyInAnyRole(roles: RoleType[]): boolean {
        if (CollectionUtils.isEmpty(roles)) {
            return false;
        }

        return roles.some((role: RoleType): boolean => this.isCurrentlyInRole(role));
    }

    /**
     * Check if this IdentityRecord is authenticated in the AEN Provider role.
     *
     * @returns {boolean} True if this IdentityRecord is authenticated as an AEN Provider, false otherwise.
     */
    public isCurrentlyInEnProviderRole(): boolean {
        return this.isCurrentlyInRole(RoleType.AenAdministrator);
    }

    /**
     * Check if this IdentityRecord is authenticated in any of the NFPA Roles
     *
     * @returns {boolean} True if this IdentityRecord is authenticated as any NFPA Role, false otherwise.
     */
    public isCurrentlyInNfpaRole(): boolean {
        return this.isCurrentlyInAnyRole([
            RoleType.NfpaAdministrator,
            RoleType.NfpaOperator,
            RoleType.NfpaSupport,
        ]);
    }

    /**
     * Check if this IdentityRecord is authenticated in the given Role.
     *
     * @param {RoleType} role The Role to check authentication against.
     * @returns {boolean} Whether or not this IdentityRecord is authenticated in the given Role.
     */
    public isCurrentlyInRole(role: RoleType): boolean {
        return this.role?.is(role) ?? false;
    }

    public isValid(): boolean {
        return this.hasUserId();
    }

    /**
     * Utility to return the current User's Provider ID.
     *
     * @returns {number | null} The current User's Provider ID, or null if not available.
     */
    public providerId(): number | null {
        if (
            !this.hasUser() ||
            !this.isCurrentlyInEnProviderRole() ||
            this.user?.providerId == null
        ) {
            return null;
        }

        return this.user.providerId;
    }

    /**
     * Utility method to update the identity properties in local storage
     * @returns void
     */
    public refreshLocalStorage(): void {
        LocalStorageUtils.set<IdentityRecord>(LocalStorageKey.Identity, this);
    }

    /**
     * Utility to return the Role Type of the currently authenticated User.
     *
     * @returns RoleType | null The Role Type the User is currently authenticated as, or null if not authenticated.
     */
    public roleType(): RoleType | null {
        if (!this.hasRole()) {
            return null;
        }

        return this.role.roleType ?? null;
    }

    /**
     * Utility to return the current user id
     * @returns number
     */
    public userId(): number {
        if (!this.hasUser() || this.user?.id == null) {
            return 0; // Not authenticated
        }

        return this.user.id;
    }

    /**
     * Utility to return the current user login id
     * @returns number
     */
    public userLoginId(): number {
        if (!this.hasUserLogin() || this.userLogin?.id == null) {
            return 0; // Not authenticated
        }

        return this.userLogin.id;
    }

    /**
     * Returns new object based on `this` and merges the partial values
     * @param  {Partial<Identity>} values
     * @returns Identity
     */
    public with(values: Partial<Identity>): IdentityRecord {
        return new IdentityRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { IdentityRecord };

// #endregion Exports
