import React from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { StringUtils } from "utilities/string-utils";
import "./numbered-badge.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NumberedBadgeProps {
    cssClassName?: string;
    value: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "numbered-badge";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NumberedBadge: React.FC<NumberedBadgeProps> = (props: NumberedBadgeProps): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <Badge
            cssClassName={className}
            style={BadgeStyle.Inverted}
            text={props.value.toString()}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NumberedBadge };

// #endregion Exports
