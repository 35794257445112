import React from "react";
import { Bubble, BubbleStyle } from "components/bubble/bubble";
import { Card } from "components/card/card";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "andculturecode-javascript-core";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { t } from "utilities/localization/t";
import "./event-description-card.scss";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDescriptionCardProps {
    cssClassName?: string;
    product?: ProductRecord;
    productVersionNumber?: number;
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-description-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDescriptionCard: React.FC<EventDescriptionCardProps> = ({
    cssClassName,
    product,
    productVersionNumber,
    stacked,
}): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <Card cssClassName={className} stacked={stacked}>
            {product == null ? (
                <EmptyText spacing={EmptyTextSpacing.None}>{t("noProductAssigned")}</EmptyText>
            ) : (
                <>
                    <Heading
                        cssClassName={`${className}__heading`}
                        priority={HeadingPriority.H3}
                        size={HeadingSize.XSmall}>
                        {product?.name}
                    </Heading>
                    {productVersionNumber != null && (
                        <div className={`${className}__version`}>
                            Version: {productVersionNumber}
                        </div>
                    )}
                    <div className={`${className}__description`}>{product?.description}</div>
                    {
                        //if
                        product?.topic != null && (
                            <Bubble
                                text={t(TopicDisplayNames[product?.topic])}
                                style={BubbleStyle.Orange}
                            />
                        )
                    }
                </>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDescriptionCard };

// #endregion Exports
