import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Banner } from "components/banner/banner";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import {
    NotificationType,
    NotificationTypeBannerStyleMap,
} from "models/enumerations/notifications/notification-type";
import { t } from "utilities/localization/t";
import "./notification-banner.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NotificationBannerProps {
    icon?: Icons;
    linkPath?: string;
    linkText?: string;
    message: string;
    notificationId: number;
    onClose: (notificationId?: number) => void;
    style: NotificationType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "notification-banner";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NotificationBanner: React.FC<NotificationBannerProps> = ({
    icon,
    linkPath,
    linkText,
    message,
    notificationId,
    onClose,
    style,
}): JSX.Element => {
    return (
        <Banner
            style={NotificationTypeBannerStyleMap[style]}
            cssClassName={`${CSS_CLASS_NAME}__banner`}>
            <div className={`${CSS_CLASS_NAME}__banner__content`}>
                <div className={`${CSS_CLASS_NAME}__banner__message`}>
                    {icon != null && (
                        <Icon
                            cssClassName={`${CSS_CLASS_NAME}__icon${NotificationTypeBannerStyleMap[style]}`}
                            type={icon}
                            size={IconSizes.Medium}
                            aria-hidden="true"
                        />
                    )}
                    <div className="content">
                        <div className="text">{message}</div>
                    </div>
                    {linkPath != null && (
                        <div className={`${CSS_CLASS_NAME}__banner__actions`}>
                            <Anchor
                                path={linkPath}
                                pathType={AnchorPathType.Internal}
                                text={linkText}
                            />
                        </div>
                    )}
                </div>
            </div>
            {style !== NotificationType.Default && (
                <button type="button" onClick={() => onClose(notificationId)}>
                    <span className="sr-only">{t("close")}</span>
                    <Icon
                        cssClassName="close"
                        type={Icons.Close}
                        size={IconSizes.Medium}
                        aria-hidden="true"
                    />
                </button>
            )}
        </Banner>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NotificationBanner };

// #endregion Exports
