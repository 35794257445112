import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum TravelType {
    Domestic = 0,
    International = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const TravelTypeDisplayNames: Map<TravelType, TranslatedCopy> = {
    [TravelType.Domestic]: "domestic",
    [TravelType.International]: "international",
};

const TravelTypeCheckBoxLabels: Map<TravelType, TranslatedCopy> = {
    [TravelType.Domestic]: "iAmAbleToTravelDomestically",
    [TravelType.International]: "iAmAbleToTravelInternationally",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TravelType, TravelTypeDisplayNames, TravelTypeCheckBoxLabels };

// #endregion Exports
