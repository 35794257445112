import { useState } from "react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { EventProviderList } from "components/events/event-provider-selection/event-provider-list/event-provider-list";
import { EventProviderSelectionModal } from "components/events/event-provider-selection/event-provider-selection-modal/event-provider-selection-modal";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-provider-selection.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProviderSelectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-provider-selection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProviderSelection: React.FC<EventProviderSelectionProps> = ({ event }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Card>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XSmall}>
                            {t("enProvider")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__actions`}>
                        <Button
                            disabled={event.providerId != null}
                            cssClassName={`${CSS_CLASS_NAME}__select-provider`}
                            onClick={() => setShowModal(true)}
                            style={ButtonStyle.Primary}
                            text={t("selectENProvider")}
                        />
                    </div>
                </div>
                <EventProviderList
                    provider={event.provider}
                    onReplace={() => setShowModal(true)}
                    event={event}
                />
            </Card>
            <EventProviderSelectionModal
                event={event}
                onProviderChange={event.patchEventProvider}
                open={showModal}
                selectedProvider={event.provider}
                setOpen={setShowModal}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProviderSelection };

// #endregion Exports
