import { Instructor } from "models/interfaces/instructors/instructor";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { StringUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Instructor = {
    city: undefined,
    country: undefined,
    email: undefined,
    firstName: "",
    id: 0,
    instructorProfile: undefined,
    lastName: "",
    phoneNumber: undefined,
    state: undefined,
    userId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorRecord extends Record(defaultValues) implements Instructor {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Instructor>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.instructorProfile != null) {
            params.instructorProfile = RecordUtils.ensureRecord(
                params.instructorProfile,
                InstructorProfileRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Return formatted phone number
     * Ex: +1 (234) 567 - 9225
     * @returns Return formatted phone number
     */
    public formatPhoneNumber(): string {
        if (this.phoneNumber) {
            const numbersOnly = ("" + this.phoneNumber).replace(/\D/g, "");
            const match = numbersOnly.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                const intlCode = match[1] ? `+${match[1]} ` : "";
                return [intlCode, "(", match[2], ") ", match[3], " - ", match[4]].join("");
            }
        }
        return "--";
    }

    public getCityStateText(): string {
        const state: string = this.state != null ? this.state.toLowerCase() : "";
        var stateName = state
            .split(" ")
            .map((s) => StringUtils.upperFirst(s))
            .join(" ");
        const city: string = this.city != null ? this.city.toLowerCase() : "";
        var cityName = city
            .split(" ")
            .map((c) => StringUtils.upperFirst(c))
            .join(" ");

        if (!StringUtils.hasValue(cityName) && !StringUtils.hasValue(stateName)) {
            return "--";
        } else if (!StringUtils.hasValue(cityName) && StringUtils.hasValue(stateName)) {
            return stateName;
        } else if (StringUtils.hasValue(cityName) && !StringUtils.hasValue(stateName)) {
            return cityName;
        } else {
            return `${cityName + "," ?? ""} ${stateName ?? ""}`;
        }
    }

    public getCountryText(): string {
        const country: string = this.country != null ? this.country.toLowerCase() : "";
        return country
            .split(" ")
            .map((word) => StringUtils.upperFirst(word))
            .join(" ");
    }

    public displayName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public getFirstAndLastName(): string {
        return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
    }

    public getFirstAndLastNameOrEmail(): string {
        return this.hasFirstName() || this.hasLastName()
            ? this.getFirstAndLastName()
            : this.email ?? "";
    }

    public hasFirstName(): boolean {
        return StringUtils.hasValue(this.firstName);
    }

    public hasLastName(): boolean {
        return StringUtils.hasValue(this.lastName);
    }

    public initials(): string {
        if (!this.hasFirstName() || !this.hasLastName()) {
            return "";
        }

        const firstInitial = this.hasFirstName() ? this.firstName![0] : "";
        const lastInitial = this.hasLastName() ? this.lastName![0] : "";

        return `${firstInitial}${lastInitial}`.toUpperCase();
    }

    public with(values: Partial<Instructor>): InstructorRecord {
        return new InstructorRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorRecord };

// #endregion Exports
