import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ReportDownloadRequestRecord } from "models/view-models/reports/report-download-request-record";
import { ReportRecord } from "models/view-models/reports/report-record";
import { useCallback, useState } from "react";
import { t } from "utilities/localization/t";
import { ReportDownloadService } from "utilities/services/reports/report-download-service";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseReportDownloadRequestHook {
    reportDownloadRequest?: ReportDownloadRequestRecord;
    errors?: ResultErrorRecord[];
    create(createOptions: UseReportDownloadRequestHookCreateOptions): void;
}

interface UseReportDownloadRequestHookOptions {
    id?: number;
}

interface UseReportDownloadRequestHookCreateOptions {
    report: ReportRecord;
    payload?: any;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useReportDownloadRequest({
    id,
}: UseReportDownloadRequestHookOptions = {}): UseReportDownloadRequestHook {
    const [reportDownloadRequest, setReportDownloadRequest] =
        useState<ReportDownloadRequestRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { create: createReportDownload } = ReportDownloadService.useCreate();

    const create = useCallback(
        (createOptions: UseReportDownloadRequestHookCreateOptions) => {
            const { report, payload } = createOptions;

            try {
                (async function () {
                    setReportDownloadRequest(new ReportDownloadRequestRecord());

                    const { result, resultObject } = await createReportDownload(
                        new ReportDownloadRequestRecord({
                            reportSourceId: report?.id,
                            payload: jsonEncodedString(payload),
                        })
                    );

                    if (result?.hasErrors() || resultObject == null) {
                        const { errors } = result ?? {};
                        setErrors(errors ?? []);
                        setReportDownloadRequest(undefined);
                        return;
                    }

                    setReportDownloadRequest((prev) => prev?.with(resultObject.toJS()));
                })();
            } catch {
                setErrors([
                    new ResultErrorRecord({
                        message: t("thereWasAProblemCreatingTheReportDownload"),
                    }),
                ]);
            }
        },
        [createReportDownload]
    );

    return {
        errors,
        reportDownloadRequest,
        create,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Local Functions
// -------------------------------------------------------------------------------------------------

function jsonEncodedString(payload: any): string | undefined {
    if (payload == null) {
        throw new Error(t("payloadCannotBeNullOrUndefined"));
    }

    return JSON.stringify(payload);
}

// #endregion Local Functions
