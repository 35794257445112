import React, { useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "react-router-dom";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { Group } from "models/interfaces/groups/group";
import { GroupRecord } from "models/view-models/groups/group-record";
import { GroupService } from "utilities/services/groups/group-service";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { StringUtils } from "utilities/string-utils";
import "./groups-new-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupsNewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "groups-new-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupsNewPage: React.FC<GroupsNewPageProps> = validatePageAccess(
    AccessControlKeys.GroupsNewPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.userManagement.users.list);
    const navigate = useNavigate();
    const { create: createGroupApi } = GroupService.useCreate();
    const [group, setGroup] = useState<GroupRecord>(new GroupRecord());

    const updateGroup = (values: Partial<Group>): void => {
        setGroup(group.with(values));
    };

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const handleAddGroup = async (): Promise<void> => {
        try {
            const createGroupResponse = await createGroupApi(group);
            const createGroupResult = createGroupResponse?.result;

            if (createGroupResult?.resultObject == null || createGroupResult.hasErrors()) {
                throw new Error();
            }

            navigate(RouteUtils.localizePath(sitemap.admin.userManagement.groups.list));

            ToastManager.success(t("groupHasBeenAddedSuccessfully"));
        } catch (error) {
            ToastManager.error(t("thereWasAnIssueCreatingTheGroup"));
        }
    };

    // #endregion Event Handlers

    return (
        <SkipNavContent>
            <div className={CSS_CLASS_NAME}>
                <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                    {t("newGroup")}
                </Heading>
                <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("basicInformation")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section__grid`}>
                        <FormTextInput
                            ariaLabelledBy={t("nameOfGroup")}
                            autoFocus={true}
                            formFieldName="groupName"
                            id="groupName"
                            label={t("nameOfGroup")}
                            maxLength={200}
                            onChange={(e) => updateGroup({ name: e.target.value })}
                            placeholder={t("enterGroupName")}
                            required={true}
                            type={InputTypes.Text}
                            value={group.name}
                        />
                        <FormTextArea
                            ariaLabelledBy={t("groupDescription")}
                            formFieldName="groupDescription"
                            id="groupDescription"
                            label={t("groupDescription")}
                            onChange={(e) => updateGroup({ description: e.target.value })}
                            maxLength={500}
                            placeholder={t("enterGroupDescription")}
                            type={InputTypes.Text}
                            rows={5}
                            value={group.description}
                        />
                    </div>
                </Card>
            </div>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                <Button
                    linkPath={sitemap.admin.userManagement.groups.list}
                    style={ButtonStyle.Secondary}
                    text={t("cancel")}
                    type={ButtonType.Link}
                />
                <Button
                    disabled={!StringUtils.hasValue(group.name)}
                    onClick={handleAddGroup}
                    style={ButtonStyle.Primary}
                    text={t("addGroup")}
                />
            </div>
        </SkipNavContent>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupsNewPage };

// #endregion Exports
