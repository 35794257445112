import React, { useState, useMemo, useEffect, useCallback } from "react";
import { LearnerAssessmentSummaryDetails } from "components/assessments/learner-assessment-summary/learner-assessment-summary-details/learner-assessment-summary-details";
import { LearnerAssessmentSummaryHeader } from "components/assessments/learner-assessment-summary/learner-assessment-summary-header/learner-assessment-summary-header";
import { NumberUtils } from "utilities/number-utils";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { RouteUtils } from "utilities/route-utils";
import { UserRecord } from "models/view-models/users/user-record";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEnrollmentScormPackageAssessment } from "utilities/hooks/models/enrollments/use-enrollment-scorm-package-assessment";
import { useAttempts } from "utilities/hooks/models/attempts/use-attempts";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./learner-take-a-training-assessment-results-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingAssessmentResultsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "assessment-results-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingAssessmentResultsPage: React.FC<LearnerTakeATrainingAssessmentResultsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage)(
        (): JSX.Element => {
            useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
            const navigate = useNavigate();
            const { record: GlobalState } = useGlobalState();
            const loggedInUser = GlobalState?.currentIdentity?.user;
            const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
            const { get: getRegistration } = RegistrationService.useGet();

            const {
                id: eventIdParam,
                enrollmentId: enrollmentIdParam,
                assessmentId: assessmentIdParam,
                registrationId: registrationIdParam,
            } = useParams();

            const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
            const enrollmentId = useMemo(
                () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
                [enrollmentIdParam]
            );
            const assessmentId = useMemo(
                () => NumberUtils.parseInt(assessmentIdParam) ?? 0,
                [assessmentIdParam]
            );
            const registrationId = useMemo(
                () => NumberUtils.parseInt(registrationIdParam) ?? 0,
                [registrationIdParam]
            );

            const assessmentResultsPath: string = useMemo(
                (): string =>
                    RouteUtils.replacePathParams(sitemap.learner.training.event.assessments.list, {
                        id: eventId,
                        enrollmentId: enrollmentId,
                        assessmentId: assessmentId,
                    }),
                [assessmentId, enrollmentId, eventId]
            );

            const userIsAuthorized = useCallback(
                (loggedInUser?: UserRecord, registration?: RegistrationRecord): boolean => {
                    if (
                        loggedInUser === undefined ||
                        registration === undefined ||
                        loggedInUser?.id !== registration?.userId
                    ) {
                        return false;
                    }
                    return true;
                },
                []
            );

            const forwardToLearnerAssessments = useCallback(() => {
                navigate(assessmentResultsPath);
            }, [navigate, assessmentResultsPath]);

            const fetchData = useCallback(async () => {
                if (registrationId <= 0) {
                    return;
                }

                try {
                    var getRegistrationResponse = await getRegistration({ id: registrationId });
                    var getRegistrationResult = getRegistrationResponse?.result;

                    if (
                        getRegistrationResult?.resultObject == null ||
                        getRegistrationResult.hasErrors()
                    ) {
                        throw new Error();
                    }

                    const registration = getRegistrationResult.resultObject;

                    if (userIsAuthorized(loggedInUser, registration)) {
                        setIsAuthorizedUser(true);
                    } else {
                        forwardToLearnerAssessments();
                    }
                } catch {
                    ToastManager.error(t("thereWasAnIssueLoadingTheRegistration"));
                }
            }, [
                forwardToLearnerAssessments,
                getRegistration,
                loggedInUser,
                registrationId,
                userIsAuthorized,
            ]);

            const { attempts } = useAttempts({
                registrationId: registrationId,
                includeRegistration: true,
                includeResponses: true,
            });

            useEffect(() => {
                fetchData();
            }, [fetchData]);

            const { enrollmentScormPackageAssessment } = useEnrollmentScormPackageAssessment({
                enrollmentId: enrollmentId,
                scormPackageAssessmentId: assessmentId,
            });

            const isRequired = useMemo(
                () => !enrollmentScormPackageAssessment?.optional,
                [enrollmentScormPackageAssessment?.optional]
            );

            return (
                <div className={CSS_CLASS_NAME}>
                    <LearnerAssessmentSummaryHeader
                        assessmentId={assessmentId}
                        title={t("assessmentResults")}
                        isRequired={isRequired}
                        returnTitle={t("backToAllAssessments")}
                        returnPath={sitemap.learner.training.event.assessments.list}
                        returnPathParams={{
                            id: eventId,
                            enrollmentId: enrollmentId,
                            assessmentId: assessmentId,
                        }}
                    />
                    {isAuthorizedUser && (
                        <>
                            <LearnerAssessmentSummaryDetails attempts={attempts} />
                        </>
                    )}
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingAssessmentResultsPage };

// #endregion Exports
