import { Record } from "immutable";
import { Question } from "models/interfaces/questions/question";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Question = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    interactionId: 0,
    questionText: "",
    scormPackageAssessmentId: 0,
    sortOrder: 0,
    type: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class QuestionRecord extends Record(defaultValues) implements Question {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Question>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<Question>): QuestionRecord {
        return new QuestionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { QuestionRecord };

// #endregion Exports
