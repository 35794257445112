import { EventScheduleRecord } from "models/view-models/events/event-schedule-record";
import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleLayoutProps {}

interface EditingEventScheduleContext {
    eventDayId?: number;
    eventSchedule: EventScheduleRecord;
    setEventDayId: (eventDayId: number) => void;
    setEventSchedule: (eventSchedule: EventScheduleRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const EditEventScheduleContext = createContext<EditingEventScheduleContext>({
    eventDayId: undefined,
    eventSchedule: new EventScheduleRecord(),
    setEventDayId: () => {},
    setEventSchedule: () => {},
});

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleLayout: React.FC<EventScheduleLayoutProps> = (): JSX.Element => {
    const [eventDayId, setEventDayId] = useState(0);
    const [eventSchedule, setEventSchedule] = useState<EventScheduleRecord>(
        new EventScheduleRecord()
    );

    const editingEventScheduleContext: EditingEventScheduleContext = {
        eventDayId: eventDayId,
        eventSchedule: eventSchedule,
        setEventDayId: setEventDayId,
        setEventSchedule: setEventSchedule,
    };

    return (
        <EditEventScheduleContext.Provider value={editingEventScheduleContext}>
            <Outlet />
        </EditEventScheduleContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleLayout, EditEventScheduleContext };

// #endregion Exports
