import { RecordSummaryStyle } from "components/summaries/record-summary/enums/record-summary-style";
import { RecordSummary } from "components/summaries/record-summary/record-summary";
import { RecordSummaryItem } from "components/summaries/record-summary/record-summary-entry/record-summary-entry";
import { ResponseRecord } from "models/view-models/responses/response-record";
import React from "react";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ResponseSummaryProps {
    response: ResponseRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ResponseSummary: React.FC<ResponseSummaryProps> = (
    props: ResponseSummaryProps
): JSX.Element => {
    const items: RecordSummaryItem[] = [
        {
            name: "question",
            value: props.response.question?.questionText,
        },
        {
            name: "answer",
            value: props.response.learnerResponse,
        },
        {
            name: "result",
            value: StringUtils.capitalize(props.response.result),
        },
    ];

    return <RecordSummary items={items} style={RecordSummaryStyle.Compact} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ResponseSummary };

// #endregion Exports
