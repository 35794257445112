import React, { useCallback, useMemo, useState } from "react";
import { Button, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import {
    GetScormPackagePreviewResourceEndpointPathParams,
    GetScormPackageResourceEndpointPathParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ScormPackagePreviewButtonProps {
    text: string;
    externalScormCourseId?: string;
    scormStatus?: number;
    buttonStyle?: ButtonStyle;
    buttonSize?: ButtonSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "scorm-package-preview-button";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ScormPackagePreviewButton: React.FC<ScormPackagePreviewButtonProps> = (
    props: ScormPackagePreviewButtonProps
) => {
    const { get } = ScormPackageService.useGetPreviewLaunchLink();
    const hidePreviewCourse: boolean = useMemo(
        () =>
            props.externalScormCourseId === "" &&
            props.scormStatus === ScormPackageImportStatus.Complete,
        [props.externalScormCourseId, props.scormStatus]
    );

    const getPreviewLink = useCallback(async (): Promise<string> => {
        if (props.externalScormCourseId == null) {
            return "";
        }
        const pathParams: GetScormPackagePreviewResourceEndpointPathParams = {
            id: props.externalScormCourseId,
        };

        try {
            const getResponse = await get(pathParams);
            const getResult = getResponse?.result;
            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            return getResult.resultObject;
        } catch {
            ToastManager.error(t("thereWasAnIssueGeneratingAPreviewLink"));
        }
        return "";
    }, [props.externalScormCourseId, get]);

    const handleRedirect = async (): Promise<void> => {
        if (props.externalScormCourseId == null) {
            return;
        }
        const scormPreviewLink = await getPreviewLink();
        if (scormPreviewLink.length > 0) {
            window.open(scormPreviewLink);
        }
    };

    if (hidePreviewCourse) {
        return null;
    }

    return (
        <>
            <Button
                text={props.text}
                onClick={handleRedirect}
                cssClassName={`${CSS_CLASS_NAME}`}
                size={props.buttonSize}
                style={props.buttonStyle}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackagePreviewButton };

// #endregion Exports
