import { FullScreenTransition } from "components/full-screen-transition/full-screen-transition";
import React, { useEffect } from "react";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";

const AzureLogoutPage: React.FC = () => {
    const { record: globalState, setRecord: setGlobalState } = useGlobalState();
    setGlobalState((currentState) => currentState.setUnauthenticated());
    const navigate = useNavigate();

    useEffect(() => {
        if (!globalState.isAuthenticated()) {
            navigate(sitemap.public.userLogin);
        }
    }, [globalState, navigate]);

    return <FullScreenTransition transitionText="Logging Out" />;
};

export { AzureLogoutPage };
