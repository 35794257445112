import { HeaderBannerNavItem } from "../components/header-banner/header-banner";
import { sitemap } from "../sitemap";

const getUserManagementNavItems = () => {
    const navItems: HeaderBannerNavItem[] = [
        { path: sitemap.admin.userManagement.users.list, text: "users" },
        { path: sitemap.admin.userManagement.enProviders.list, text: "enProviders" },
        { path: sitemap.admin.userManagement.contracts.list, text: "organizations" },
        { path: sitemap.admin.userManagement.groups.list, text: "groups" },
    ];

    return navItems;
};

const getThirdPartyDashboardNavItems = () => {
    const navItems: HeaderBannerNavItem[] = [
        { path: sitemap.thirdPartyProvider.dashboard.online, text: "online" },
        { path: sitemap.thirdPartyProvider.dashboard.instructor, text: "instructorLed" },
    ];

    return navItems;
};

export const HeaderBannerUtils = {
    getUserManagementNavItems,
    getThirdPartyDashboardNavItems,
};
