// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentActiveStatus {
    Inactive = 0,
    Active = 1,
}

// #endregion Enum

export { EnrollmentActiveStatus };

// #endregion Exports
