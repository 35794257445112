import { EventAttendanceSummary } from "components/events/event-schedule/event-attendance-summary/event-attendance-summary";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { NumberUtils } from "utilities/number-utils";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Card } from "components/card/card";
import "./manage-live-virtual-event-attendance-summary-page.scss";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventAttendanceSummaryPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-attendance-summary-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventAttendanceSummaryPage: React.FC<ManageLiveVirtualEventAttendanceSummaryPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventAttendanceSummaryPage)(
        (): JSX.Element => {
            useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
            const { id } = useParams();
            const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);

            return (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.Attendance} size={IconSizes.Large} />
                        <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                            {t("attendance")}
                        </Heading>
                    </div>

                    <Card>
                        <EventAttendanceSummary eventId={eventId} />
                    </Card>
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventAttendanceSummaryPage };

// #endregion Exports
