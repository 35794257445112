import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Process } from "utilities/types/processes/process";
import { ProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useCallback } from "react";
import { useLocation, Location } from "react-router-dom";
import { useRequestNavigation } from "utilities/contexts/navigation/use-navigation-request-context";
import "./process-navigation-sidebar.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ProcessNavigationSidebarProps<T> {
    process: Process<T>;
    processNavigator: ProcessNavigator<T>;
}

export interface ProcessNavItemProps {
    cssClassName?: string;
    displayName: TranslatedCopy;
    isActive: boolean;
    isComplete: boolean;
    onClick: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "process-navigation-sidebar";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ProcessNavigationSidebar = <T,>(props: ProcessNavigationSidebarProps<T>) => {
    const requestNavigation = useRequestNavigation();
    const location: Location = useLocation();
    const { goToStep } = props.processNavigator;
    const steps: [string, ProcessStep][] = Object.entries(props.process);

    const stepIsActive = useCallback(
        (step: ProcessStep) => {
            return step.path === location.pathname;
        },
        [location]
    );

    let navItems: ProcessNavItemProps[] = steps.map(
        ([key, step]: [string, ProcessStep]): ProcessNavItemProps => {
            const onClick =
                step.requestNavigationConfig == null
                    ? () => goToStep(key as keyof T)
                    : () =>
                          requestNavigation({
                              onNavigationApproved: (): void => {
                                  goToStep(key as keyof T);
                              },
                              requestingComponent:
                                  step.requestNavigationConfig?.requestingComponents ?? "",
                              componentsAllowedToResolve:
                                  step.requestNavigationConfig?.componentsAllowedToResolve ?? [],
                          });
            return {
                displayName: step.displayName,
                isActive: stepIsActive(step),
                isComplete: step.isComplete(),
                onClick: onClick,
            };
        }
    );

    return (
        <div className={CSS_CLASS_NAME}>
            {navItems.map((item, i) => {
                const classNames = [];

                if (item.isActive) {
                    classNames.push("-active");
                }

                if (item.isComplete) {
                    classNames.push("-completed");
                }

                return (
                    <button
                        className={classNames.join(" ")}
                        key={`process-sidebar-${i}`}
                        onClick={item.onClick}
                        type="button">
                        <Icon
                            size={IconSizes.Large}
                            type={item.isComplete ? Icons.CircleChecked : Icons.CircleUnchecked}
                        />
                        <span>{t(item.displayName)}</span>
                    </button>
                );
            })}
        </div>
    );
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ProcessNavigationSidebar };

// #endregion Exports
