import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum AudienceType {
    EducationNetwork = 1,
    Learner = 0,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AudienceTypeDisplayNames: Map<AudienceType, TranslatedCopy> = {
    [AudienceType.EducationNetwork]: "educationNetwork",
    [AudienceType.Learner]: "learner",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AudienceType, AudienceTypeDisplayNames };

// #endregion Exports
