import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";

enum AenDirectoryAvailability {
    No = 0,
    Yes = 1,
    Both = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AenDirectoryAvailabilityDisplayNames: Map<AenDirectoryAvailability, TranslatedCopy> = {
    [AenDirectoryAvailability.No]: "aenDirectoryAvailabilityNo",
    [AenDirectoryAvailability.Yes]: "aenDirectoryAvailabilityYes",
    [AenDirectoryAvailability.Both]: "aenDirectoryAvailabilityBoth",
};

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenDirectoryAvailability, AenDirectoryAvailabilityDisplayNames };

// #endregion Exports
