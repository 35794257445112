import { StringUtils } from "utilities/string-utils";
import { RoleType } from "models/enumerations/users/role-type";
import { ReportType } from "components/reports/report-transcript/enumerations/report-type";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { CollectionUtils } from "utilities/collection-utils";
import { EventRecord } from "models/view-models/events/event-record";
import { UserRecord } from "models/view-models/users/user-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { GroupRecord } from "models/view-models/groups/group-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportTranscriptState {
    reportType?: ReportType;
    displayType: string;
    dateRangeStart: Date | null;
    dateRangeEnd: Date | null;
    groups: GroupRecord[];
    events: EventRecord[];
    learners: UserRecord[];
    organizations: OrganizationRecord[];
    contracts: ContractRecord[];
    products: ProductRecord[];
}

type ReducerActions =
    | { type: "reportType"; reportType?: ReportType }
    | { type: "displayType"; displayType: string }
    | { type: "dateRange"; dateRangeStart: Date | null; dateRangeEnd: Date | null }
    | { type: "updateEvents"; events: EventRecord[] }
    | { type: "updateOrganizations"; organizations: OrganizationRecord[] }
    | { type: "updateContracts"; contracts: ContractRecord[] }
    | { type: "updateProducts"; products: ProductRecord[] }
    | { type: "updateGroups"; groups: GroupRecord[] }
    | { type: "updateLearners"; learners: UserRecord[] };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: ReportTranscriptState = {
    reportType: undefined,
    displayType: "",
    dateRangeStart: null,
    dateRangeEnd: null,
    contracts: [],
    events: [],
    groups: [],
    learners: [],
    organizations: [],
    products: [],
};

const validate = (state: ReportTranscriptState): boolean => {
    const { reportType, displayType } = state;

    if (!StringUtils.hasValue(displayType)) {
        return false;
    }

    if (
        CollectionUtils.hasValues(state.events) ||
        CollectionUtils.hasValues(state.learners) ||
        CollectionUtils.hasValues(state.contracts) ||
        CollectionUtils.hasValues(state.products) ||
        CollectionUtils.hasValues(state.groups) ||
        CollectionUtils.hasValues(state.organizations)
    ) {
        return true;
    }
    return false;
};

const reducer = (state: ReportTranscriptState, action: ReducerActions): ReportTranscriptState => {
    switch (action.type) {
        case "reportType":
            const { reportType } = action;

            return {
                ...state,
                displayType: "",
                reportType,
                contracts: reportType === ReportType.Organization ? state.contracts : [],
                events: reportType === ReportType.InstructorLedTrainingEvent ? state.events : [],
                groups: reportType === ReportType.Group ? state.groups : [],
                learners: reportType === ReportType.Learner ? state.learners : [],
                organizations: reportType === ReportType.Organization ? state.organizations : [],
                products: reportType === ReportType.OnlineLearningTraining ? state.products : [],
            };

        case "displayType":
            const { displayType } = action;

            return { ...state, displayType };

        case "updateEvents":
            const { events } = action;

            return { ...state, events: events };

        case "updateContracts":
            const { contracts } = action;

            return { ...state, contracts: contracts };

        case "updateOrganizations":
            const { organizations } = action;

            return {
                ...state,
                organizations: organizations,
                contracts: [],
            };

        case "updateLearners":
            const { learners } = action;

            return { ...state, learners: learners };

        case "updateGroups":
            const { groups } = action;

            return { ...state, groups: groups };

        case "updateProducts":
            const { products } = action;

            return { ...state, products: products };

        case "dateRange":
            const { dateRangeStart, dateRangeEnd } = action;

            return {
                ...state,
                dateRangeStart,
                dateRangeEnd,
            };
        default:
            throw new Error();
    }
};

const initializer =
    (roleType?: RoleType | null) =>
    (initialState: ReportTranscriptState): ReportTranscriptState => {
        // TODO NFPA-8996 sRole Logic
        // if (roleType === RoleType.Instructor || roleType === RoleType.AenAdministrator) {
        //     return {
        //         ...initialState,
        //         reportType: REPORT_TYPES.ALL_TRAININGS,
        //     };
        // }

        return initialState;
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validate };
export type { ReportTranscriptState };

// #endregion Exports
