import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum Topic {
    BuildingLifeSafety = 0,
    CertificationPrep = 1,
    Electrical = 2,
    EmergencyResponse = 3,
    FireProtection = 4,
    IndustrialHazards = 5,
    EmergingTechnology = 6,
    PublicEducation = 7,
    Wildfire = 8,
    Other = 9,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const TopicDisplayNames: Map<Topic, TranslatedCopy> = {
    [Topic.BuildingLifeSafety]: "buildingLifeSafety",
    [Topic.CertificationPrep]: "certificationPrep",
    [Topic.Electrical]: "electrical",
    [Topic.EmergencyResponse]: "emergencyResponse",
    [Topic.FireProtection]: "fireProtection",
    [Topic.IndustrialHazards]: "industrialChem",
    [Topic.EmergingTechnology]: "emergingTechnology",
    [Topic.PublicEducation]: "publicEducation",
    [Topic.Wildfire]: "wildfire",
    [Topic.Other]: "other",
};

// #endregion Mapsx

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Topic, TopicDisplayNames };

// #endregion Exports
