import React, { useCallback, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import {
    GetScormPackagePreviewResourceEndpointPathParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductScormPackageRecord } from "models/view-models/products/product-scorm-package-record";
import {
    ProductScormPackageService,
    UpdateProductScormPackagePathParams,
} from "utilities/services/products/product-scorm-package-service";
import {
    ScormPackageImportStatus,
    ScormPackageImportStatusDisplayNames,
} from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useProduct } from "utilities/contexts/use-product-context";
import "./product-assessment-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductAssessmentListProps {
    productAssessments?: ProductScormPackageRecord[];
    fetchProductScormPackageList: () => void;
    //PV TO DO:  Different.
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    onProductAssessmentUpdate: (productAssessments: ProductScormPackageRecord[]) => void;
    setAssessmentHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "product-assessment-list-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductAssessmentList: React.FC<ProductAssessmentListProps> = ({
    productAssessments,
    fetchProductScormPackageList,
    setOpen,
    onProductAssessmentUpdate,
    setAssessmentHasChanges,
}) => {
    const { update: updateProductScormPackageApi } = ProductScormPackageService.useUpdate();
    const [productScormPackageToDelete, setProductScormPackageToDelete] =
        useState<ProductScormPackageRecord>(new ProductScormPackageRecord());
    const [
        showScormPackageDeletionConfirmationModal,
        setShowScormPackageDeletionConfirmationModal,
    ] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const { delete: deleteProductScormPackage } = ProductScormPackageService.useDelete();
    const { get } = ScormPackageService.useGetPreviewLaunchLink();
    const { record: product } = useProduct();
    const deferSave = product.status === ActiveStatus.Active;
    const [assessmentIndexToDelete, setAssessmentIndexToDelete] = useState(-1);

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowScormPackageDeletionConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleProductScormPackageDelete(),
            style: ButtonStyle.Primary,
        },
    ];

    const updateProductScormPackage = async (
        productScormPackageWithChanges: ProductScormPackageRecord
    ): Promise<boolean> => {
        setUpdateInProgress(true);

        const updateProductScormPackagePathParams: UpdateProductScormPackagePathParams = {
            id: productScormPackageWithChanges.id!,
        };

        try {
            const updateProductScormPackageResponse = await updateProductScormPackageApi(
                productScormPackageWithChanges,
                updateProductScormPackagePathParams
            );

            setUpdateInProgress(false);

            const updateResult = updateProductScormPackageResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheAssessment"));
        }
        return true;
    };

    const itemIsFirst = useCallback(
        (item: ProductScormPackageRecord, items?: ProductScormPackageRecord[]): boolean => {
            return (
                CollectionUtils.isEmpty(items) ||
                item.sortOrder === Math.min(...items.map((pa) => pa.sortOrder!))
            );
        },
        []
    );

    const itemIsLast = useCallback(
        (item: ProductScormPackageRecord, items?: ProductScormPackageRecord[]): boolean => {
            return (
                CollectionUtils.isEmpty(items) ||
                item.sortOrder === Math.max(...items.map((u) => u.sortOrder!))
            );
        },
        []
    );

    const disableUpArrowButtons = useCallback(
        (item: ProductScormPackageRecord) => {
            return updateInProgress || itemIsFirst(item, productAssessments);
        },
        [updateInProgress, itemIsFirst, productAssessments]
    );

    const disableDownArrowButtons = useCallback(
        (item: ProductScormPackageRecord) => {
            return updateInProgress || itemIsLast(item, productAssessments);
        },
        [updateInProgress, itemIsLast, productAssessments]
    );

    const moveDownItem = async (item: ProductScormPackageRecord): Promise<boolean> => {
        return updateItemSortOrder(item, item.sortOrder! + 1);
    };

    const moveUpItem = (item: ProductScormPackageRecord): Promise<boolean> => {
        return updateItemSortOrder(item, item.sortOrder! - 1);
    };

    const scormPackagePreviewButtonIsEnabled = useCallback(
        (productAssessment: ProductScormPackageRecord): boolean =>
            productAssessment?.scormPackage?.status !== ScormPackageImportStatus.Complete,
        []
    );

    const updateItemSortOrder = async (
        item: ProductScormPackageRecord,
        sortOrder: number
    ): Promise<boolean> => {
        const itemWithChanges = item.with({
            sortOrder: sortOrder,
        });

        return await updateProductScormPackage(itemWithChanges);
    };

    const getItemBySortOrder = (sortOrder: number): ProductScormPackageRecord | null => {
        if (CollectionUtils.isEmpty(productAssessments)) {
            return null;
        }

        const productScormPackage = productAssessments.find((psp) => psp.sortOrder === sortOrder);

        return productScormPackage == null ? null : productScormPackage;
    };

    const handleUpArrowClick = async (item: ProductScormPackageRecord): Promise<void> => {
        if (itemIsFirst(item, productAssessments)) {
            return;
        }
        if (deferSave) {
            const assessmentToUpdateIdx = productAssessments?.findIndex((pa) => pa === item);
            if (assessmentToUpdateIdx == null) {
                return;
            }
            setAssessmentHasChanges && setAssessmentHasChanges(true);
            const updatedProductAssessment = productAssessments?.map((pa, idx) => {
                if (idx === assessmentToUpdateIdx) {
                    return pa.with({
                        sortOrder: pa.sortOrder! - 1,
                    });
                }
                if (idx === assessmentToUpdateIdx - 1) {
                    return pa.with({
                        sortOrder: pa.sortOrder! + 1,
                    });
                }
                return pa;
            });
            //resort:
            updatedProductAssessment?.sort(
                (a: ProductScormPackageRecord, b: ProductScormPackageRecord) =>
                    a.sortOrder! - b.sortOrder!
            );
            onProductAssessmentUpdate(updatedProductAssessment!);
        } else {
            const apiCalls: Promise<boolean>[] = [moveUpItem(item)];

            const itemToMoveDown = getItemBySortOrder(item.sortOrder! - 1);

            if (itemToMoveDown != null) {
                apiCalls.push(moveDownItem(itemToMoveDown));
            }

            await Promise.all(apiCalls);

            fetchProductScormPackageList();
        }
    };

    const handleDownArrowClick = async (item: ProductScormPackageRecord): Promise<void> => {
        if (itemIsLast(item, productAssessments)) {
            return;
        }

        if (deferSave) {
            const assessmentToUpdateIdx = productAssessments?.findIndex((pa) => pa === item);
            if (assessmentToUpdateIdx == null) {
                return;
            }
            setAssessmentHasChanges && setAssessmentHasChanges(true);
            const updatedProductAssessment = productAssessments?.map((pa, idx) => {
                if (idx === assessmentToUpdateIdx) {
                    return pa.with({
                        sortOrder: pa.sortOrder! + 1,
                    });
                }
                if (idx === assessmentToUpdateIdx + 1) {
                    return pa.with({
                        sortOrder: pa.sortOrder! - 1,
                    });
                }
                return pa;
            });
            //resort:
            updatedProductAssessment?.sort(
                (a: ProductScormPackageRecord, b: ProductScormPackageRecord) =>
                    a.sortOrder! - b.sortOrder!
            );
            onProductAssessmentUpdate(updatedProductAssessment!);
        } else {
            const apiCalls: Promise<boolean>[] = [moveDownItem(item)];

            const itemToMoveUp = getItemBySortOrder(item.sortOrder! + 1);

            if (itemToMoveUp != null) {
                apiCalls.push(moveUpItem(itemToMoveUp));
            }

            await Promise.all(apiCalls);

            fetchProductScormPackageList();
        }
    };

    const getPreviewLink = useCallback(
        async (externalScormCourseId: string): Promise<string> => {
            if (externalScormCourseId === "") {
                return "";
            }
            const pathParams: GetScormPackagePreviewResourceEndpointPathParams = {
                id: externalScormCourseId,
            };

            try {
                const getResponse = await get(pathParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                return getResult.resultObject;
            } catch {
                ToastManager.info(t("thePreviewIsNotAvailableYet"));
            }
            return "";
        },
        [get]
    );

    const handleRedirect = async (externalScormCourseId: string | undefined): Promise<void> => {
        if (externalScormCourseId == null) {
            return;
        }
        const scormPreviewLink = await getPreviewLink(externalScormCourseId);
        if (scormPreviewLink.length > 0) {
            window.open(scormPreviewLink);
        }
    };

    const handleProductScormPackageDelete = async (): Promise<void> => {
        if (deferSave) {
            //assessmentIndexToDelete
            if (assessmentIndexToDelete == null) {
                return;
            }
            productAssessments?.splice(assessmentIndexToDelete, 1);

            //Fix the sort order:
            const updatedAssessments = productAssessments?.map((pa, idx) => {
                if (idx >= assessmentIndexToDelete) {
                    return pa.with({ sortOrder: pa.sortOrder! - 1 });
                }
                return pa;
            });

            onProductAssessmentUpdate(updatedAssessments!);
            setAssessmentHasChanges && setAssessmentHasChanges(true);
            setShowScormPackageDeletionConfirmationModal(false);
            return;
        } else {
            const higherSortOrderCourseContentsToUpdate = productAssessments?.slice(
                productScormPackageToDelete.sortOrder
            );

            try {
                await deleteProductScormPackage(productScormPackageToDelete.id!);
                setShowScormPackageDeletionConfirmationModal(false);

                const apiCalls: Promise<boolean>[] = [];

                higherSortOrderCourseContentsToUpdate?.forEach((psp) =>
                    apiCalls.push(moveUpItem(psp))
                );

                await Promise.all(apiCalls);

                fetchProductScormPackageList();
                ToastManager.success(t("assessmentDeletedSuccessfully"));
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingTheAssessment"));
            }
        }
    };

    const handleOptionClick = async (
        productScormPackage: ProductScormPackageRecord,
        scormPackageIndex: number
    ): Promise<void> => {
        const updatedProductScormPackage = productScormPackage.with({
            optional: !productScormPackage.optional,
        });

        if (deferSave) {
            setAssessmentHasChanges && setAssessmentHasChanges(true);
            const updatedProductAssessments = productAssessments?.map((pa, idx) => {
                if (idx === scormPackageIndex) {
                    return updatedProductScormPackage;
                }
                return pa;
            });
            onProductAssessmentUpdate(updatedProductAssessments!);
        } else {
            await updateProductScormPackage(updatedProductScormPackage);
            fetchProductScormPackageList();
        }
    };

    function deleteScormPackage(idx: number): void {
        setAssessmentIndexToDelete(idx);
        setProductScormPackageToDelete(productAssessments![idx]);
        setShowScormPackageDeletionConfirmationModal(true);
    }

    return (
        <>
            {CollectionUtils.hasValues(productAssessments) && (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className="arrows">
                                <span className="sr-only">{t("changeOrder")}</span>
                            </th>
                            <th className="order">{t("order")}</th>
                            <th className="name">{t("name")}</th>
                            <th className="file-name">{t("fileName")}</th>
                            <th className="import-status">{t("importStatus")}</th>
                            <th className="optional">{t("optional")}</th>
                            <th className="action">
                                <span className="sr-only">{t("action")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {productAssessments.map((productAssessment, index) => (
                            <tr key={index}>
                                <td className="arrows">
                                    <ButtonIcon
                                        ariaLabelledBy={t(
                                            "moveAssessmentProductAssessmentScormPackageNameUp",
                                            {
                                                productAssessmentScormPackageName:
                                                    productAssessment.scormPackage?.name,
                                            }
                                        )}
                                        buttonStyle={ButtonStyle.TertiaryAlt}
                                        cssClassName="arrow-up"
                                        disabled={disableUpArrowButtons(productAssessment)}
                                        iconType={Icons.ArrowUpward}
                                        onClick={() => handleUpArrowClick(productAssessment)}
                                    />
                                    <ButtonIcon
                                        ariaLabelledBy={t(
                                            "moveAssessmentProductAssessmentScormPackageNameDown",
                                            {
                                                productAssessmentScormPackageName:
                                                    productAssessment.scormPackage?.name,
                                            }
                                        )}
                                        buttonStyle={ButtonStyle.TertiaryAlt}
                                        cssClassName="arrow-down"
                                        disabled={disableDownArrowButtons(productAssessment)}
                                        iconType={Icons.ArrowDownward}
                                        onClick={() => handleDownArrowClick(productAssessment)}
                                    />
                                </td>

                                <td className="order">{productAssessment.sortOrder}</td>
                                <td className="name">{productAssessment.scormPackage?.name}</td>
                                <td className="file-name">
                                    {productAssessment.scormPackage?.file?.fileName}
                                </td>
                                <td className="import status">
                                    {productAssessment.scormPackage?.status == null
                                        ? null
                                        : t(
                                              ScormPackageImportStatusDisplayNames[
                                                  productAssessment.scormPackage?.status
                                              ]
                                          )}
                                </td>
                                <td className="optional">
                                    <CheckboxInput
                                        checked={productAssessment.optional}
                                        disabled={updateInProgress}
                                        id={`product-assessment-${productAssessment.id}-optional`}
                                        label={t("optional")}
                                        onChange={() => handleOptionClick(productAssessment, index)}
                                        size={CheckboxSize.Small}
                                    />
                                </td>

                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            disabled={scormPackagePreviewButtonIsEnabled(
                                                productAssessment
                                            )}
                                            forceEnabled={
                                                !scormPackagePreviewButtonIsEnabled(
                                                    productAssessment
                                                )
                                            }
                                            onClick={() =>
                                                handleRedirect(
                                                    productAssessment?.scormPackage
                                                        ?.externalScormCourseId
                                                )
                                            }
                                            displayName={t("preview")}
                                        />
                                        <ContextMenuButton
                                            onClick={() => deleteScormPackage(index)}
                                            displayName={t("delete")}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            )}
            {!CollectionUtils.hasValues(productAssessments) && (
                <EmptyText table>{t("noAssessmentsAdded")}</EmptyText>
            )}
            <Modal
                actions={confirmationActionArray}
                isOpen={showScormPackageDeletionConfirmationModal}
                modalStyle={""}
                onModalClose={() => {}}
                title={t("deleteAssessment")}>
                {t("areYouSureYouWantToRemoveProductScormPackageToDeleteScormPackageName", {
                    productScormPackageToDeleteScormPackageName:
                        productScormPackageToDelete.scormPackage?.name,
                })}
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductAssessmentList };

// #endregion Exports
