import { useEffect, useState } from "react";
import { AppConstants } from "constants/app-constants";
import { Arabic } from "cultures/arabic";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ConfiguredApp } from "configured-app";
import { CookieUtils } from "utilities/cookie-utils";
import { English } from "cultures/english";
import { FullScreenTransition } from "components/full-screen-transition/full-screen-transition";
import { GlobalStateContext } from "utilities/contexts/use-global-state-context";
import { GlobalStateRecord } from "models/view-models/global-state/global-state-record";
import { initReactI18next } from "react-i18next";
import { Language } from "models/enumerations/languages/language";
import { LocalizationUtils } from "utilities/localization-utils";
import { MsalProvider } from "@azure/msal-react";
import { NavigationRequestContext } from "utilities/contexts/navigation/use-navigation-request-context";
import { RouteUtils } from "utilities/route-utils";
import { ServiceUtils } from "utilities/service-utils";
import { sitemap } from "sitemap";
import { Spanish } from "cultures/spanish";
import { useIdentity } from "utilities/hooks/use-identity";
import { useLoadSystemSettings } from "utilities/hooks/use-load-system-settings";
import { useNavigationRequests } from "utilities/hooks/navigation/use-navigation-requests";
import { UserLoginService } from "utilities/services/users/user-login-service";
import { useRunOnce } from "utilities/hooks/use-run-once";
import moment from "moment";
import MaintenancePage from "pages/errors/maintenance-page/maintenance-page";
import "assets/scss/app.scss";

// -------------------------------------------------------------------------------------------------
// #region App
// -------------------------------------------------------------------------------------------------

const App = (): JSX.Element => {
    const { getIdentity } = useIdentity();
    const { get: getUserLoginApi } = UserLoginService.useGetFromCookie();
    const [globalState, setGlobalState] = useState(GlobalStateRecord.getInitialGlobalState());
    const navigationRequestManager = useNavigationRequests();
    const [publicClientApp, setPublicClientApp] = useState<PublicClientApplication>();
    const isMaintenanceModeActive = globalState.systemSettings?.maintenanceMode?.isActive;
    const cssClassName = LocalizationUtils.currentLanguageIs(Language.Arabic) ? "-arabic" : "";
    const currentRoute = RouteUtils.getCurrentRouteDefinition();

    useRunOnce(() => {
        LocalizationUtils.initialize(initReactI18next, [English, Spanish, Arabic]);
        moment.locale(LocalizationUtils.currentCultureCode());
        ServiceUtils.configure({
            cultureCode: LocalizationUtils.currentCultureCode(),
            dispatcher: {
                setUnauthenticated() {
                    setGlobalState((globalState) => globalState.setUnauthenticated());
                },
                setForbidden(forbiddenResult) {
                    setGlobalState((globalState) =>
                        globalState.with({ forbiddenResult: forbiddenResult })
                    );
                },
            },
        });
    });

    useLoadSystemSettings(setGlobalState);

    const setRightToLeftDisplay = (isRtl: boolean) => {
        if (isRtl) {
            document.documentElement.dir = "rtl";
        }
    };

    // Detect cookie
    useEffect(() => {
        const getUserLogin = async () => {
            const userLoginResponse = await getUserLoginApi({});
            const userLoginResult = userLoginResponse?.result;

            if (userLoginResult?.resultObject == null || userLoginResult?.hasErrors()) {
                return;
            }

            const userLogin = userLoginResult.resultObject;

            const identity = await getIdentity(userLogin);

            setGlobalState((state) => state.setIdentity(identity));

            if (identity == null) {
                window.location.pathname = RouteUtils.localizePath(sitemap.public.userLogin);
            }
        };

        if (currentPageIsPublic()) {
            return;
        }

        if (globalState.isAuthenticated()) {
            return;
        }

        if (CookieUtils.hasCookie(AppConstants.AuthenticationCookieName)) {
            getUserLogin();
            return;
        }
    }, [currentRoute, getUserLoginApi, globalState, getIdentity]);

    useEffect(() => {
        updateLanguageCodeInPath(globalState.currentIdentity?.user?.preferredLanguage);
        setRightToLeftDisplay(
            globalState.currentIdentity?.user?.preferredLanguage === Language.Arabic
        );
    }, [globalState, globalState.currentIdentity?.user?.preferredLanguage]);

    useEffect(() => {
        if (globalState.azureSettings == null) {
            return;
        }

        const domain = globalState.azureSettings.domain;
        const config: Configuration = {
            auth: {
                clientId: globalState.azureSettings.clientId,
                redirectUri: `${window.location.origin}/${process.env.REACT_APP_B2C_LOGIN_REDIRECT_URI}`,
                authority: `https://${domain}/${globalState.azureSettings.tenant}.onmicrosoft.com/${process.env.REACT_APP_B2C_POLICY}`,
                knownAuthorities: [domain],
                navigateToLoginRequestUrl: true,
            },
        };

        const publicClientApp = new PublicClientApplication(config);

        publicClientApp.initialize();

        setPublicClientApp(publicClientApp);
    }, [globalState.azureSettings]);

    return (
        <span className={cssClassName}>
            {publicClientApp != null /*&& inProgress !== InteractionStatus.Startup*/ ? (
                <MsalProvider instance={publicClientApp}>
                    <GlobalStateContext.Provider
                        value={{ record: globalState, setRecord: setGlobalState }}>
                        <NavigationRequestContext.Provider value={navigationRequestManager}>
                            {globalState.systemSettingsLoaded ? (
                                isMaintenanceModeActive ? (
                                    <MaintenancePage />
                                ) : (
                                    <ConfiguredApp />
                                )
                            ) : (
                                <FullScreenTransition transitionText="" />
                            )}
                        </NavigationRequestContext.Provider>
                    </GlobalStateContext.Provider>
                </MsalProvider>
            ) : (
                <FullScreenTransition transitionText="" />
            )}
        </span>
    );

    /*
    return (
        <span className={cssClassName}>
            <MsalProvider instance={PUBLIC_CLIENT_APPLICATION}>
                <GlobalStateContext.Provider
                    value={{ record: globalState, setRecord: setGlobalState }}>
                    <NavigationRequestContext.Provider value={navigationRequestManager}>
                        {globalState.systemSettingsLoaded ? (
                            <ConfiguredApp />
                        ) : (
                            <FullScreenTransition transitionText="" />
                        )}
                    </NavigationRequestContext.Provider>
                </GlobalStateContext.Provider>
            </MsalProvider>
        </span>
    );
    */
};

// #endregion App

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const currentPageIsPublic: () => boolean = (): boolean => {
    return window.location.pathname === RouteUtils.localizePath(sitemap.public.userLogin);
};

const updateLanguageCodeInPath = (language?: Language): void => {
    const cultureCode =
        language == null
            ? LocalizationUtils.cultureCodeFromBrowser()
            : LocalizationUtils.languageCodeByLanguage(language);

    if (cultureCode === LocalizationUtils.cultureCodeFromRoute()) {
        return;
    }

    const slugs = window.location.pathname.split("/");

    if (window.location.pathname.endsWith("/azure")) {
        return;
    }
    if (window.location.pathname.endsWith("/azure-logout")) {
        return;
    }
    if (LocalizationUtils.hasCultureCodeInRoute()) {
        slugs[1] = cultureCode;
    } else {
        slugs.splice(1, 0, cultureCode);
    }

    //    if (!LocalizationUtils.hasCultureCodeInRoute()) {
    window.location.pathname = slugs.join("/");
    //  }
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { App };

// #endregion Exports
