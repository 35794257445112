import React, { useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icons } from "components/icons/constants/icons";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { t } from "utilities/localization/t";
import { RoleType } from "models/enumerations/users/role-type";
import { sitemap } from "sitemap";
import { TabbedLayout, TabbedLayoutItem } from "components/tabs/tabbed-layout/tabbed-layout";
import { Tab, TabProps } from "components/tabs/tabs/tab/tab";
import { RouteUtils } from "utilities/route-utils";
import { useLocation } from "react-router-dom";
import { useAenResources } from "utilities/hooks/models/aen-resources/use-aen-resources";
import { CollectionUtils } from "utilities/collection-utils";
import { DataTable } from "components/tables/data-table/data-table";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { Pager } from "components/pager/pager";
import { EmptyText } from "components/empty-text/empty-text";
import { Modal, ModalAction } from "components/modal/modal";
import { ToastManager } from "utilities/toast/toast-manager";
import { AenResourceRecord } from "models/view-models/aen-resources/aen-resource-record";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { AenResourceModal } from "../aenresource-content-modal/aen-resource-modal";
import { ContextMenuFilePreview } from "components/context-menu/context-menu-file-preview/context-menu-file-preview";
import { FileUtils } from "utilities/files/file-utils";
import "./aen-resources-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminAenResourcesListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-resources-list-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminAenResourcesListPage: React.FC<AdminAenResourcesListPageProps> = validatePageAccess(
    AccessControlKeys.AdminAenResourcesListPage
)((): JSX.Element => {
    const location = useLocation();
    const roleType = location.pathname.startsWith(
        RouteUtils.localizePath(sitemap.admin.aenResources.providers.list)
    )
        ? RoleType.AenAdministrator
        : RoleType.Instructor;

    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showResourceModal, setShowResourceModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [aenResourceToDelete, setAenResourceToDelete] = useState<AenResourceRecord>(
        new AenResourceRecord()
    );
    const [selectedAenResource, setSelectedAenResource] = useState<AenResourceRecord | undefined>();
    const [editMode, setEditMode] = useState(false);

    const {
        aenResources,
        rowCount: totalNumberOfResources,
        addAenResource,
        updateAenResource,
        deleteAenResource,
    } = useAenResources({
        searchText: debouncedSearchText,
        roleType,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const handleSearchTextInputChange = (search: string) => {
        setSearchText(search);
        setCurrentPage(1);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const handleModalOpenNew = () => {
        setEditMode(false);
        setSelectedAenResource(undefined);
        setShowResourceModal(true);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const deleteConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowDeleteConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleDeleteAenResource(),
            style: ButtonStyle.Destructive,
        },
    ];

    const onRemoveClick = (aenResource: AenResourceRecord) => {
        setAenResourceToDelete(aenResource);
        setShowDeleteConfirmationModal(true);
    };

    const handleDeleteAenResource = async () => {
        if (aenResourceToDelete == null || aenResourceToDelete.id == null) {
            ToastManager.error(t("thereWasAnIssueDeletingTheContent"));
            return;
        }

        await deleteAenResource(aenResourceToDelete);
        setShowDeleteConfirmationModal(false);
    };

    const handleSaveAenResource = async (aenResource: AenResourceRecord) => {
        if (aenResource == null) {
            ToastManager.error(t("therewasanissuesavingthecontent"));
            return;
        }
        if (editMode) {
            await updateAenResource(aenResource);
        } else {
            await addAenResource(aenResource);
        }
        setShowResourceModal(false);
    };

    const tabbedLayoutItems: TabbedLayoutItem<TabProps>[] = [
        {
            matchPathOnStartsWith: true,
            path: RouteUtils.localizePath(sitemap.admin.aenResources.providers.list),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "providers",
            },
        },
        {
            matchPathOnStartsWith: true,
            path: RouteUtils.localizePath(sitemap.admin.aenResources.instructors.list),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "instructors",
            },
        },
    ];

    const handleModalOpenEdit = (aenResource: AenResourceRecord, idx: number) => {
        setEditMode(true);
        setSelectedAenResource(aenResource);
        setShowResourceModal(true);
    };

    return (
        <>
            <HeaderBanner title={t("aenResources")}>
                <ButtonIcon
                    text={t("addNewContent")}
                    onClick={() => handleModalOpenNew()}
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.TertiaryAlt}
                    iconType={Icons.Plus}
                />
            </HeaderBanner>
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <TabbedLayout
                        cssClassName={`${CSS_CLASS_NAME}__tabs`}
                        tabs={tabbedLayoutItems}
                    />
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <h2>
                            {roleType === RoleType.AenAdministrator
                                ? t("contentForProviders")
                                : t("contentForInstructors")}
                        </h2>
                        <div className={`${CSS_CLASS_NAME}__search`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                onSearchTextInputChange={handleSearchTextInputChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"aenResourceSearch"}
                                placeholder={t("typeToSearchByName")}
                                searchTextInputValue={searchText}
                            />
                        </div>
                    </div>
                    {CollectionUtils.hasValues(aenResources) ? (
                        <>
                            <DataTable cssClassName={CSS_CLASS_NAME}>
                                <thead>
                                    <tr>
                                        <th className="name">{t("name")}</th>
                                        <th className="description">{t("description")}</th>
                                        <th className="file-name">{t("fileName")}</th>
                                        <th className="action">
                                            <span className="sr-only">{t("action")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aenResources.map((resource, idx) => (
                                        <tr key={resource.id}>
                                            <td className="name">
                                                <span
                                                    className={`${CSS_CLASS_NAME}__product-name -product-type`}>
                                                    {resource.name}
                                                </span>
                                            </td>
                                            <td className="description">{resource.description}</td>
                                            <td className="file-name">{resource.file?.fileName}</td>
                                            <td className="action">
                                                <ContextMenu>
                                                    <ContextMenuFilePreview
                                                        hrefPath={FileUtils.fileUrl(
                                                            resource.fileId!
                                                        )}
                                                        displayName={t("download")}
                                                    />
                                                    <ContextMenuButton
                                                        onClick={() =>
                                                            handleModalOpenEdit(resource, idx)
                                                        }
                                                        displayName={t("replaceContent")}
                                                    />
                                                    <ContextMenuButton
                                                        onClick={() => onRemoveClick(resource)}
                                                        displayName={t("delete")}
                                                    />
                                                </ContextMenu>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </DataTable>
                            <Pager
                                currentPage={currentPage}
                                onPageClick={onPageClick}
                                totalPages={totalNumberOfResources / ITEMS_PER_PAGE}
                                itemsPerPage={ITEMS_PER_PAGE}
                                totalItems={totalNumberOfResources}></Pager>
                        </>
                    ) : (
                        <EmptyText table>{t("noContentResultsFound")}</EmptyText>
                    )}
                </div>
            </SkipNavContent>
            <Modal
                isOpen={showDeleteConfirmationModal}
                onModalClose={() => {}}
                actions={deleteConfirmationActionArray}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWantToRemoveContent", { content: aenResourceToDelete.name })}
            </Modal>
            <AenResourceModal
                roleType={roleType}
                open={showResourceModal}
                setOpen={setShowResourceModal}
                aenResource={selectedAenResource}
                editing={editMode}
                saveAenResource={handleSaveAenResource}
            />
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminAenResourcesListPage };

// #endregion Exports
