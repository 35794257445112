import React, { useEffect, useState } from "react";
import { CountryConstants } from "constants/country-constants";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { SelectOption } from "components/form/inputs/select/select";
import { allCountries } from "country-region-data";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-in-person-location-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInPersonLocationFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-in-person-location-form";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const EventInPersonLocationForm: React.FC<EventInPersonLocationFormProps> = (
    props: EventInPersonLocationFormProps
): JSX.Element => {
    const [stateRegionConstants, setStateRegionConstants] = useState<SelectOption[]>([]);

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        props.event.updateActiveRecord({ country: event.target.value, state: undefined });
    };

    const handleAddrOneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ addressLineOne: event.target.value });
    };

    const handleAddrTwoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ addressLineTwo: event.target.value });
    };

    const handleAddlAddrDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        props.event.updateActiveRecord({ additionalAddressDetails: event.target.value });
    };

    const handleLocationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ locationName: event.target.value });
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ city: event.target.value });
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        props.event.updateActiveRecord({ state: event.target.value });
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.event.updateActiveRecord({ zipCode: event.target.value });
    };

    const handleBlur = (): void => {
        if (props.event.isDraft()) {
            props.event.save();
        }
    };

    useEffect(() => {
        const stateRegionSelectOptions: SelectOption[] = [];

        if (props.event.country) {
            const stateRegions = allCountries.find((c) => c[0] === props.event.country);

            stateRegions![2].forEach((region) =>
                stateRegionSelectOptions.push({ text: region[0], value: region[0] })
            );
        }

        setStateRegionConstants(stateRegionSelectOptions);
    }, [props.event.country]);

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__column`}>
                    <FormSelect
                        ariaLabelledBy={t("country")}
                        autoFocus={true}
                        cssClassName={`${CSS_CLASS_NAME}__country`}
                        formFieldName={t("country")}
                        id={t("country")}
                        label={t("country")}
                        placeholder={t("selectOption")}
                        onBlur={handleBlur}
                        onChange={handleCountryChange}
                        options={CountryConstants}
                        value={props.event.country?.toString()}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("addressLine1")}
                        autoFocus={false}
                        formFieldName="addressLineOne"
                        id="addressLineOne"
                        label={t("addressLine1")}
                        maxLength={255}
                        onBlur={handleBlur}
                        onChange={handleAddrOneChange}
                        placeholder={t("enterAddressLine1")}
                        type={InputTypes.Text}
                        value={props.event.addressLineOne}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("addressLine2")}
                        autoFocus={false}
                        formFieldName="addressLineTwo"
                        id="addressLineTwo"
                        label={t("addressLine2")}
                        maxLength={255}
                        onBlur={handleBlur}
                        onChange={handleAddrTwoChange}
                        placeholder={t("enterAddressLine2")}
                        required={false}
                        type={InputTypes.Text}
                        value={props.event.addressLineTwo}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("citySlashTown")}
                        autoFocus={false}
                        formFieldName="city"
                        id="city"
                        label={t("citySlashTown")}
                        maxLength={255}
                        onChange={handleCityChange}
                        placeholder={t("enterCitySlashTown")}
                        type={InputTypes.Text}
                        value={props.event.city}
                    />
                    <FormSelect
                        ariaLabelledBy={t("stateSlashProvinceSlashRegion")}
                        autoFocus={false}
                        cssClassName={`${CSS_CLASS_NAME}__state`}
                        disabled={props.event.country == null}
                        formFieldName={t("stateSlashProvinceSlashRegion")}
                        id={t("stateSlashProvinceSlashRegion")}
                        label={t("stateSlashProvinceSlashRegion")}
                        onBlur={handleBlur}
                        onChange={handleStateChange}
                        options={stateRegionConstants}
                        value={props.event.state?.toString()}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("zipCodeSlashPostalCode")}
                        autoFocus={false}
                        formFieldName="zipCode"
                        id="zipCode"
                        label={t("zipCodeSlashPostalCode")}
                        maxLength={10}
                        onBlur={handleBlur}
                        onChange={handleZipCodeChange}
                        placeholder={t("enterZipCodePostalCode")}
                        type={InputTypes.Text}
                        value={props.event.zipCode}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__column`}>
                    <FormTextInput
                        ariaLabelledBy={t("locationName")}
                        autoFocus={false}
                        formFieldName="locationName"
                        id="locationName"
                        label={t("locationName")}
                        maxLength={60}
                        onBlur={handleBlur}
                        onChange={handleLocationNameChange}
                        placeholder={t("enterLocationName")}
                        type={InputTypes.Text}
                        value={props.event.locationName}
                    />
                    <FormTextArea
                        ariaLabelledBy="Additional Details"
                        autoFocus={false}
                        formFieldName="additionalAddressDetails"
                        id="additionalAddressDetails"
                        label="Additional Details"
                        onBlur={handleBlur}
                        onChange={handleAddlAddrDetailsChange}
                        placeholder={t("enterAdditionalDetails")}
                        required={false}
                        type={InputTypes.Text}
                        value={props.event.additionalAddressDetails ?? ""}
                    />
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInPersonLocationForm };

// #endregion Exports
