import EventSelection from "components/event-selection/event-selection";
import GroupSelection from "components/group-selection/group-selection";
import React from "react";
import RichTextEditor from "components/rich-text/rich-text-editor";
import { EventRecord } from "models/view-models/events/event-record";
import { EventsControllerIndexType } from "utilities/services/events/event-service";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardStyle,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { GroupRecord } from "models/view-models/groups/group-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { InputTypes } from "components/form/enumerations/input-types";
import { NumberUtils } from "utilities/number-utils";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RecipientType } from "models/enumerations/emails/recipient-type";
import { ReducerActions, EmailState } from "components/emails/email-reducer";
import { t } from "utilities/localization/t";
import "./send-an-email-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SendAnEmailFormProps {
    payload: EmailState;
    dispatch: React.Dispatch<ReducerActions>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "send-an-email-form";
const RECIPIENT_TYPES: RadioCard[] = [
    {
        value: RecipientType.EventAttendees.toString(),
        text: "eventAttendees",
        icon: Icons.Reporting,
    },
    {
        value: RecipientType.Group.toString(),
        text: "aGroup",
        icon: Icons.Reporting,
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SendAnEmailForm: React.FC<SendAnEmailFormProps> = ({ payload, dispatch }): JSX.Element => {
    const handleRecipientTypeChange = (value: string) => {
        dispatch({ type: "updateRecipientType", recipientType: NumberUtils.parseInt(value) ?? 0 });
    };

    const handleChangeEvents = (events: EventRecord[]) => {
        dispatch({ type: "updateEvents", events });
    };

    const handleChangeGroups = (groups: GroupRecord[]) => {
        dispatch({ type: "updateGroups", groups });
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch({ type: "updateSubject", subject: event.target.value });
    };

    const handleBodyChange = (bodyHtml: string, bodyText: string) => {
        const bodyChanges = { bodyHtml, bodyText };
        dispatch({ type: "updateBody", bodyChanges });
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H5}>
                {t("sendAnEmail")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__container`}>
                <Paragraph size={ParagraphSize.Large}>{t("iWantToSendAnEmailTo")}</Paragraph>
                <FormRadioCardList
                    id="recipientType"
                    formFieldName="recipientType"
                    label={t("recipientType")}
                    labelScreenReaderOnly={true}
                    iconSize={IconSizes.Base}
                    onChange={handleRecipientTypeChange}
                    radioCards={RECIPIENT_TYPES}
                    required={true}
                    style={RadioCardStyle.Horizontal}
                    value={payload.recipientType.toString()}
                />
            </div>
            {payload.recipientType === RecipientType.EventAttendees && (
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <EventSelection
                        label={t("selectAnEvent")}
                        events={payload.events}
                        isRequired={true}
                        onEventsChanged={handleChangeEvents}
                        type={EventsControllerIndexType.Default}
                    />
                </div>
            )}
            {payload.recipientType === RecipientType.Group && (
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <GroupSelection
                        label={t("selectAGroup")}
                        groups={payload.groups}
                        isRequired={true}
                        onGroupsChanged={handleChangeGroups}
                    />
                </div>
            )}
            <div className={`${CSS_CLASS_NAME}__container__message`}>
                <Paragraph size={ParagraphSize.Large}>{t("whatDoYouWantToSay")}</Paragraph>
                <FormTextInput
                    ariaLabelledBy={t("emailSubject")}
                    formFieldName="emailSubject"
                    id="emailSubject"
                    label={t("emailSubject")}
                    maxLength={250}
                    onChange={handleSubjectChange}
                    placeholder={t("enterAnnouncementTitle")}
                    required={true}
                    type={InputTypes.Text}
                    value={payload.subject}
                />
                <RichTextEditor
                    defaultValue={""}
                    label={t("announcementBody")}
                    maxLength={10}
                    onChange={handleBodyChange}
                    placeholder={t("enterAnnouncementBody")}
                    required={true}
                    value={payload.bodyHtml}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SendAnEmailForm };

// #endregion Exports
