import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { t } from "utilities/localization/t";
import "./confirmation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ConfirmationModalProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    confirmationMessage: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "confirmation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    onCancel,
    onConfirm,
    confirmationMessage,
}): JSX.Element => {
    const cancelCreatingVersionActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: onCancel,
            style: ButtonStyle.Transparent,
        },
        {
            buttonText: t("confirm"),
            onClick: onConfirm,
            style: ButtonStyle.Secondary,
        },
    ];

    return (
        <Modal
            actions={cancelCreatingVersionActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            modalStyle={"-inverted"}
            onModalClose={() => {}}>
            {confirmationMessage}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ConfirmationModal };

// #endregion Exports
