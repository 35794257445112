import { Validated } from "components/reports/reports-base-props";
import { Reducer, ReducerState, Dispatch, ReducerAction, useReducer } from "react";

const validatingReducer =
    <R extends Reducer<any, any>>(reducer: R, validate?: (state: ReducerState<R>) => boolean) =>
    (state: ReducerState<R>, action: ReducerAction<R>): Validated<ReducerState<R>> => {
        const newState = reducer(state, action);
        return validateState(newState, validate);
    };

const validatingInitializer =
    <R extends Reducer<any, any>>(
        initializer?: (initialState: ReducerState<R>) => ReducerState<R>,
        validate?: (state: ReducerState<R>) => boolean
    ) =>
    (state: ReducerState<R>): Validated<ReducerState<R>> => {
        const newState = initializer?.(state) ?? state;
        return validateState(newState, validate);
    };

const validateState = <R extends Reducer<any, any>>(
    state: ReducerState<R>,
    validate?: (state: ReducerState<R>) => boolean
): Validated<ReducerState<R>> => {
    const isValid = validate?.(state) ?? true;
    const validatedState: Validated<ReducerState<R>> = { ...state, isValid };

    return validatedState;
};

/**
 * A wrapper around `useReducer` specifc to Reporting that allows for validation and onChange callbacks.
 */
export function useReportReducer<R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>,
    options: {
        initializer?: (initialState: ReducerState<R>) => ReducerState<R>;
        validate?: (state: ReducerState<R>) => boolean;
    } = {}
): [Validated<ReducerState<R>>, Dispatch<ReducerAction<R>>] {
    const { initializer, validate } = options;

    const [state, dispatch] = useReducer(
        validatingReducer(reducer, validate),
        initialState,
        validatingInitializer(initializer, validate)
    );

    return [state, dispatch];
}
