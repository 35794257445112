import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonType } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContractType } from "models/enumerations/contracts/contract-type";
import { DataTable } from "../../../../../components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useContracts } from "utilities/hooks/models/contracts/use-contracts";
import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import "./user-contracts-oll-list-page.scss";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { StringUtils } from "utilities/string-utils";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { ScrollUtils } from "utilities/scroll-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsOLLListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-oll-list-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsOLLListPage: React.FC<UserContractsOLLListPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsOLLListPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { id: orgIdParam } = useParams();
    const orgId = useMemo(() => NumberUtils.parseInt(orgIdParam) ?? 0, [orgIdParam]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const { record: globalState } = useGlobalState();

    const canModifyContracts =
        globalState?.currentIdentity?.isCurrentlyInNfpaRole() &&
        globalState?.currentIdentity?.role?.roleType !== RoleType.NfpaSupport;

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const { contracts, rowCount: totalNumberOfContracts } = useContracts({
        organizationId: orgId,
        contractType: ContractType.OnlineLearning,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        includeEnrollments: true,
        includeProduct: true,
    });

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.content`);
    }, [contracts]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <h2>{t("onlineLearningContracts")}</h2>
                            <div className={`${CSS_CLASS_NAME}__header__actions`}>
                                <div className={`${CSS_CLASS_NAME}__header__actions__search`}>
                                    <SearchTextInput
                                        debounce={DEBOUNCE_TIME}
                                        onSearchTextInputChange={handleSearchTextChange}
                                        onSearchTriggered={handleSearchTriggered}
                                        id={"filterOppName"}
                                        placeholder={t("typeToSearchByContractNumber")}
                                        searchTextInputValue={searchText}
                                    />
                                </div>
                                {canModifyContracts && (
                                    <ButtonIcon
                                        buttonType={ButtonType.Link}
                                        iconType={Icons.Plus}
                                        linkPath={RouteUtils.replacePathParams(
                                            sitemap.admin.userManagement.contracts.onlineLearning
                                                .new,
                                            { id: orgId }
                                        )}
                                        text={t("addNewContract")}
                                    />
                                )}
                            </div>
                        </div>
                        {CollectionUtils.hasValues(contracts) && (
                            <DataTable>
                                <thead>
                                    <tr>
                                        <th className="product-name">{t("productName")}</th>
                                        <th className="contract-number">{t("contractNumber")}</th>
                                        <th className="lms-id">{t("lmsID")}</th>
                                        <th className="enrollment">{t("enrollment")}</th>
                                        <th className="action">
                                            <span className="sr-only">{t("action")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contracts.map((contract) => (
                                        <tr key={contract.id}>
                                            <td className="product-name">
                                                {contract.product?.name}
                                            </td>
                                            <td className="contract-number">
                                                {contract.contractNumber}
                                            </td>
                                            <td className="lms-id">{contract.id}</td>
                                            <td className="enrollment">{`${
                                                contract.enrollments?.filter(
                                                    (e) => e.withdrawnOn == null
                                                )?.length ?? 0
                                            }/${contract.enrollmentLimit}`}</td>
                                            <td className="action">
                                                <ContextMenu>
                                                    <ContextMenuAnchor
                                                        hrefPath={RouteUtils.replacePathParams(
                                                            sitemap.admin.userManagement.contracts
                                                                .onlineLearning.detail,
                                                            { id: orgId, contractId: contract.id }
                                                        )}
                                                        displayName={t("viewContract")}
                                                    />
                                                </ContextMenu>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </DataTable>
                        )}

                        {!CollectionUtils.hasValues(contracts) &&
                            !StringUtils.hasValue(searchText) && (
                                <EmptyText table>
                                    {t(
                                        "thereAreCurrentlyNoOnlineLearningContractsForThisOrganization"
                                    )}
                                </EmptyText>
                            )}

                        {!CollectionUtils.hasValues(contracts) &&
                            StringUtils.hasValue(searchText) && (
                                <EmptyText table>{t("noContractResultsFound")}</EmptyText>
                            )}
                    </SkipNavContent>
                </div>
            </div>
            {CollectionUtils.hasValues(contracts) && (
                <div className="footer">
                    <Pager
                        footer={true}
                        currentPage={currentPage}
                        totalPages={totalNumberOfContracts / ITEMS_PER_PAGE}
                        onPageClick={onPageClick}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalItems={totalNumberOfContracts}
                    />
                </div>
            )}
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsOLLListPage };

// #endregion Exports
