import { BadgeTemplateRecord } from "models/view-models/badges/badge-template-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "badgetemplates";
const resourceEndpoint: string = `${baseEndpoint}/:badgeTemplateId`;
const resourceType: typeof BadgeTemplateRecord = BadgeTemplateRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetBadgeTemplatePathParams {
    badgeTemplateId: string;
}

export interface GetBadgeTemplateQueryParams {}

export interface ListBadgeTemplateQueryParams {
    searchText?: string;
}

export interface UpdateBadgeTemplatePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const BadgeTemplateService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<BadgeTemplateRecord, ListBadgeTemplateQueryParams>(
        resourceType,
        baseEndpoint
    ),
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        BadgeTemplateRecord,
        GetBadgeTemplatePathParams,
        GetBadgeTemplateQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BadgeTemplateService };

// #endregion Exports
