import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { Icons } from "components/icons/constants/icons";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ProductAchievements } from "components/products/product-achievements/product-achievements";
import { ProductAssessmentManager } from "components/products/product-assesment/product-assessment-manager";
import { ProductContentManager } from "components/products/product-content/product-content-manager";
import { ProductEvaluation } from "components/products/product-evaluation/product-evaluation";
import { RouteUtils } from "utilities/route-utils";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import "./create-instructor-product-version-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateInstructorProductVersionMaterialsProps {
    setAssessmentHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
    setAssessmentToggleHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleClicks: React.Dispatch<React.SetStateAction<number>>;
    toggleClicks: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

// #endregion Constants
const CSS_CLASS_NAME: string = "create-instructor-product-version-materials";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateInstructorProductVersionMaterials: React.FC<
    CreateInstructorProductVersionMaterialsProps
> = ({
    setAssessmentHasChanges,
    setAssessmentToggleHasChanges,
    setToggleClicks,
    toggleClicks,
}): JSX.Element => {
    const navigate = useNavigate();
    const { record: product, setRecord: setProduct } = useProduct();
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const inCreateVersionMode = product.status === ActiveStatus.Active;
    const isInstructorLedTraining = product.type === TrainingType.InstructorLedTraining;

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <ProductAssessmentManager
                    createVersionMode={inCreateVersionMode}
                    product={product}
                    productVersion={productVersion}
                    setProductVersion={setProductVersion}
                    setAssessmentHasChanges={setAssessmentHasChanges}
                    setAssessmentToggleHasChanges={setAssessmentToggleHasChanges}
                    setToggleClicks={setToggleClicks}
                    toggleClicks={toggleClicks}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("assessments"),
                iconType: productVersion.assessmentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
        {
            contents: (
                <ProductContentManager
                    inCreateVersionMode={inCreateVersionMode}
                    product={product}
                    productVersion={productVersion}
                    setProductVersion={setProductVersion}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("content"),
                iconType: productVersion.contentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
    ];

    if (isInstructorLedTraining) {
        tabs.push(
            {
                contents: (
                    <ProductAchievements
                        inCreateVersionMode={inCreateVersionMode}
                        product={product}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("achievements"),
                    iconType: productVersion.achievementIsComplete(product.audienceType)
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductEvaluation
                        createVersionMode={inCreateVersionMode}
                        product={product}
                        setProduct={setProduct}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("evaluation"),
                    iconType: product.evaluationTemplateIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            }
        );
    }

    const onModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                    id: product.id,
                })
            )
        );
    };

    return (
        <div>
            {product.activatedOn != null && isInstructorLedTraining && (
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t(
                            "onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatusNewlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion"
                        )}
                    />
                </div>
            )}

            <TabbedContainer tabs={tabs} />
            <CancelChangesModal
                open={showCancelCreateVersionModal}
                setOpen={setShowCancelCreateVersionModal}
                onModalClose={onModalClose}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateInstructorProductVersionMaterials };

// #endregion Exports
