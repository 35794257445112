import { AssessmentQuestionResponseSummary } from "models/interfaces/assessments/assessment-question-response-summary";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: AssessmentQuestionResponseSummary = {
    correctCount: undefined,
    correctPercentage: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    incorrectCount: undefined,
    incorrectPercentage: undefined,
    questionText: undefined,
    sortOrder: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class AssessmentQuestionResponseSummaryRecord
    extends Record(defaultValues)
    implements AssessmentQuestionResponseSummary
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the percentage
     * Ex: 88%
     */
    public getPercentageText(score: number | undefined): string {
        const scorePercentage =
            score === 0 && this.correctCount === 0 && this.incorrectCount === 0
                ? "--"
                : `${score}%`;

        return scorePercentage;
    }

    constructor(params?: Partial<AssessmentQuestionResponseSummary>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for AssessmentQuestionResponseSummary properties, create a new AssessmentQuestionResponseSummaryRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<AssessmentQuestionResponseSummary>} values The values to overwrite on this instance.
     * @returns An AssessmentQuestionResponseSummaryRecord with values from this instance and the values parameter.
     */
    public with(
        values: Partial<AssessmentQuestionResponseSummary>
    ): AssessmentQuestionResponseSummaryRecord {
        return new AssessmentQuestionResponseSummaryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentQuestionResponseSummaryRecord };

// #endregion Exports
