import { ContractSummaryRecord } from "models/view-models/contracts/contract-summary-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "organizations/:organizationId/contracts/ilt/summaries";
const pastTrainingsEndpoint: string = `${baseEndpoint}/pasttrainings`;
const resourceType: typeof ContractSummaryRecord = ContractSummaryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListOrganizationILTContractSummariesPathParams {
    organizationId: number;
    skip?: number;
    take?: number;
}

export interface ListOrganizationILTContractSummariesQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const OrganizationILTContractSummariesService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        ContractSummaryRecord,
        ListOrganizationILTContractSummariesPathParams,
        ListOrganizationILTContractSummariesQueryParams
    >(resourceType, baseEndpoint),

    usePastTrainingsList: ServiceHookFactory.useNestedList<
        ContractSummaryRecord,
        ListOrganizationILTContractSummariesPathParams,
        ListOrganizationILTContractSummariesQueryParams
    >(resourceType, pastTrainingsEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OrganizationILTContractSummariesService };

// #endregion Exports
