import React from "react";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    EnrollmentScormPackageAssessmentStatus,
    EnrollmentScormPackageAssessmentStatusDisplayNames,
    EnrollmentScormPackageAssessmentStatusMap,
} from "models/enumerations/enrollments/enrollment-scorm-package-assessment-status";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import "./assessment-row.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AssessmentRowProps {
    name: string;
    number?: number;
    required?: boolean;
    score?: number;
    status: EnrollmentScormPackageAssessmentStatus;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "assessment-row";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssessmentRow: React.FC<AssessmentRowProps> = (props: AssessmentRowProps) => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__name`}>
                <Paragraph size={props.number ? ParagraphSize.Default : ParagraphSize.Small}>
                    {props.number && <strong>{props.number}.&nbsp;</strong>}
                    {props.name}
                </Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__details`}>
                <div>
                    <Badge
                        text={props.required ? t("required") : t("optional")}
                        style={props.required ? BadgeStyle.Neutral : BadgeStyle.Default}
                    />
                </div>
                <div>
                    <EnumStatusBadge
                        displayNames={EnrollmentScormPackageAssessmentStatusDisplayNames}
                        statusMap={EnrollmentScormPackageAssessmentStatusMap}
                        value={props.status}
                    />
                </div>
                <div>
                    <Paragraph size={ParagraphSize.XSmall}>
                        <Icon type={Icons.FactCheck} />
                        <span>{props.score ? `${props.score}%` : "--"}</span>
                    </Paragraph>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentRow };

// #endregion Exports
