import React, { useMemo } from "react";
import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { FormField, FormFieldProps } from "components/form/form-field/form-field";
import { Icon } from "../../icons/icon";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Icons } from "../../icons/constants/icons";
import { InputProperties } from "components/form/input-properties";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { StringUtils } from "andculturecode-javascript-core";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./form-radio-card-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum RadioCardStyle {
    Horizontal = "horizontal",
    Vertical = "vertical",
}

export enum RadioCardGridColumns {
    Two = "two-columns",
    Three = "three-columns",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RadioCard {
    disabled?: boolean;
    icon: Icons;
    text: TranslatedCopy;
    translationData?: object;
    value: string;
}

export interface FormRadioCardListProps extends FormFieldProps, InputProperties {
    columns?: RadioCardGridColumns;
    iconSize?: IconSizes;
    onChange?: (value: string) => void;
    radioCards: RadioCard[];
    style?: RadioCardStyle;
    value?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-radio-card-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormRadioCardList: React.FC<FormRadioCardListProps> = (props: FormRadioCardListProps) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const columns = props.columns == null ? RadioCardGridColumns.Two : props.columns;

    const { readOnly } = useReadOnly();

    const isDisabled = useMemo(() => props.disabled || readOnly, [props.disabled, readOnly]);

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (isDisabled) {
        cssClassNames.push("disabled");
    }

    if (props.style) {
        cssClassNames.push(props.style);
    }

    const onChange = (value: string) => {
        if (isDisabled) {
            return;
        }

        if (props.onChange) {
            props.onChange(value);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, value: string) => {
        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        if (event.key === " ") {
            event.preventDefault();
            onChange(value);
        }
    };

    return (
        <FormField
            cssClassName={cssClassNames.join(" ")}
            label={props.label}
            labelScreenReaderOnly={props.labelScreenReaderOnly}
            required={props.required}
            formFieldName={props.formFieldName}>
            <div className={`${CSS_CLASS_NAME}__cards ${columns}`}>
                {props.radioCards.map((card) => {
                    return (
                        <div className="layered">
                            <button
                                disabled={card.disabled}
                                key={card.value}
                                onClick={() => onChange(card.value)}
                                onKeyDown={(event) => onKeyDown(event, card.value)}>
                                <label
                                    key={`${props.formFieldName}-${card.value}`}
                                    className="radio-card"
                                    htmlFor={`${props.formFieldName}-${card.value}`}>
                                    <input
                                        id={`${props.formFieldName}-${card.value}`}
                                        type="radio"
                                        name={props.formFieldName}
                                        checked={card.value === props.value}
                                        disabled={isDisabled || card.disabled}
                                        onChange={() => {}}
                                    />
                                    <div className="radio-card__details">
                                        <Icon
                                            cssClassName={`${card.disabled ? "-disabled" : ""}`}
                                            size={
                                                props.iconSize == null
                                                    ? IconSizes.Large
                                                    : props.iconSize
                                            }
                                            type={card.icon}
                                        />
                                        <span className={`${card.disabled ? "-disabled" : ""}`}>
                                            {t(card.text, card.translationData)}
                                        </span>
                                    </div>
                                </label>
                            </button>
                            {card.disabled && (
                                <div className="tool-tip">
                                    <Tooltip
                                        content={t("comingSoon")}
                                        placement={TooltipPlacement.Top}
                                        style={TooltipStyle.Secondary}>
                                        <div>
                                            <Paragraph
                                                size={ParagraphSize.Large}
                                                style={ParagraphStyle.Inverted}>
                                                {t("comingSoon")}
                                            </Paragraph>
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormRadioCardList };

// #endregion Exports
