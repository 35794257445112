import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { CourseVersion } from "models/interfaces/courses/course-version";
import { DateUtils } from "utilities/date-utils";
import { Record } from "immutable";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { StringUtils } from "utilities/string-utils";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CourseVersion = {
    changeLogDescription: "",
    courseContents: undefined,
    courseId: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    hasNoContent: false,
    id: undefined,
    numberOfEnrolledLearners: 0,
    numberOfInProgressLearners: 0,
    scormPackage: undefined,
    scormPackageId: undefined,
    status: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    versionId: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CourseVersionRecord extends Record(defaultValues) implements CourseVersion {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<CourseVersion>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.courseContents)) {
            params.courseContents = RecordUtils.ensureRecords(
                params.courseContents,
                CourseContentRecord
            );
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.scormPackage != null) {
            params.scormPackage = RecordUtils.ensureRecord(params.scormPackage, ScormPackageRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if the content on this record is complete.
     *
     * @returns {boolean} Whether or not the content is complete
     */
    public contentIsComplete(): boolean {
        return this.hasNoContent || this.hasContent();
    }

    /**
     * Convenience method to determine if the record has content.
     *
     * @returns {boolean} Whether or not the course has content
     */
    public hasContent(): boolean {
        return this.courseContents ? this.courseContents.length > 0 : false;
    }

    /**
     * Convenience method to return the date/time this record was created and the name of the user
     * who created it.
     *
     * @returns {string} A string indicating when this record was created and by whom.
     */
    public getCreatedText(): string {
        const createdOnDateTime = DateUtils.formatDateTime(false, this.createdOn);

        if (this.createdBy == null) {
            return createdOnDateTime;
        }

        return `${createdOnDateTime} by ${this.createdBy.getFirstAndLastName()}`;
    }

    /**
     * Convenience method to the return user
     * Ex: Steve Smith
     */
    public getLastUpdatedBy(): string {
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return "N/A";
        }

        return updatedBy.getFirstAndLastName();
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getLastUpdatedDateAndTime(): string {
        const message = `${DateUtils.formatLastEditedDateTime(this, true, true)}`;

        return message;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = DateUtils.formatLastEditedDate(this, false);
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return t("messageByUpdatedByGetFirstAndLastName", {
            message: message,
            updatedByGetFirstAndLastName: updatedBy.getFirstAndLastName(),
        });
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY-MM-DDTHH:MM:SS:FFFFFFF+Offset
     * 2022-10-20T17:42:30.7005991+00:00

     */
    public getLastUpdated(): string {
        return StringUtils.hasValue(this.updatedOn) ? this.updatedOn : this.createdOn ?? "";
    }

    /**
     * Convenience method to the return the last updated time in milliseconds.
     * Ex: 1666287750700 for 2022-10-20T17:42:30.7005991+00:00
     */

    public getLastUpdatedInMilliseconds(): number {
        const lastUpdated = this.getLastUpdated();
        return DateUtils.convertToMilliseconds(lastUpdated);
    }

    /**
     * Convenience method to the return the status of the course
     */
    public getStatus(): string {
        const courseStatusDisplay = this.scormPackage == null ? "Inactive" : "Active";
        return courseStatusDisplay;
    }

    /**
     * Convenience method to return the id of the user who last updated the record (or created it,
     * if it has not yet been updated)
     *
     */
    public getUpdatedOrCreatedById(): number | undefined {
        return this.updatedById ?? this.createdById;
    }

    /**
     * Convenience method to sort the Answers by the Answer.SortOrder
     */
    public sortContent(): void {
        if (!CollectionUtils.hasValues(this.courseContents)) {
            return;
        }

        this.courseContents!.sort((a?: CourseContentRecord, b?: CourseContentRecord) => {
            return (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0);
        });
    }

    /**
     * Convenience method to determine if the ScormPackage on this record is complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public scormPackageIsComplete(): boolean {
        if (this.id == null) {
            return false;
        }

        return this.scormPackage != null && this.scormPackage.isComplete(this.id);
    }

    /**
     * Given a set of values for Course properties, create a new CourseRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Course>} values The values to overwrite on this instance.
     * @returns A CourseRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<CourseVersion>): CourseVersionRecord {
        return new CourseVersionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionRecord };

// #endregion Exports
