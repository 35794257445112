import { AenApplicationActivityLog } from "models/interfaces/aen-applications/aen-application-activity-log";
import { DateUtils } from "utilities/date-utils";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: AenApplicationActivityLog = {
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    activity: undefined,
    notes: undefined,
    id: undefined,
    instructorApplicationId: undefined,
    providerApplicationId: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    sendMessage: false,
    messageBody: undefined,
    messageSubject: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class AenApplicationActivityLogRecord
    extends Record(defaultValues)
    implements AenApplicationActivityLog
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<AenApplicationActivityLog>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to get the upload user's first and last name
     * @returns {string} Randy Jenkins
     */
    public getFirstAndLastName(): string {
        return this.createdBy?.getFirstAndLastName() ?? "";
    }

    /**
     * Convenience method to get the upload date
     * @returns {string} MM/DD/YYYY formatted date string
     */
    public getLogDate(): string {
        return DateUtils.formatDateTime(true, this.createdOn, true);
    }

    public with(values: Partial<AenApplicationActivityLog>): AenApplicationActivityLogRecord {
        return new AenApplicationActivityLogRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationActivityLogRecord };

// #endregion Exports
