import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { InputTypes } from "../../../form/enumerations/input-types";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { t } from "utilities/localization/t";
import "./import-enrollment-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ImportEnrollmentModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "import-enrollment-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ImportEnrollmentModal: React.FC<ImportEnrollmentModalProps> = ({
    open,
    setOpen,
}): JSX.Element => {
    const [uploadProcessed, setUploadProcessed] = useState(false);
    const [includesHeaderRow, setIncludesHeaderRow] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: uploadProcessed ? t("done") : t("upload"),
            onClick: () => (uploadProcessed ? onModalClose() : onUploadClick()),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setUploadProcessed(false);
        setOpen(false);
    };

    const onUploadClick = (): void => {
        setUploadProcessed(true);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("importEnrollmentCSV")}
            actions={modalActionArray}
            modalStyle={""}>
            {/* View before upload has processed */}
            {!uploadProcessed && (
                <div className={`${CSS_CLASS_NAME}__upload`}>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Small}>
                            <strong>{t("enrollmentCSVRequirements")}</strong>
                            <br />
                            {t(
                                "importingACSVFileIsAnEasyWayToBulkEnrollMultipleLearnersAtOnceToGetStarted"
                            )}{" "}
                            <a href="https://www.google.com" target="_blank" rel="noreferrer">
                                {t("useThisTemplate")}
                            </a>{" "}
                        </Paragraph>
                        <ul>
                            <li>{t("fileMustBeACSV")}</li>
                            <li>{t("emailMustBeInFirstColumn")}</li>
                            <li>{t("allRowsMustHaveCompleteData")}</li>
                            <li>{t("emailsMustBeUniqueToTheIndividualRegistrant")}</li>
                            <li>{t("emailIsInAValidFormatExampleEmaildomain")}</li>
                        </ul>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <FormFileInput
                            buttonText={t("selectAFile")}
                            formFieldName="csvFile"
                            id="csvFile"
                            label={t("uploadACSVFile")}
                            onFileUpload={() => {}}
                            placeholder={t("selectAFile")}
                            storageContainer={StorageContainers.FileUpload}
                        />
                        <FormCheckboxInput
                            checked={includesHeaderRow}
                            formFieldName="includesHeaderRow"
                            id="includesHeaderRow"
                            label="This file includes a header row"
                            onChange={(newValue: boolean) => {
                                setIncludesHeaderRow(newValue);
                            }}
                            type={InputTypes.Checkbox}
                            value={includesHeaderRow}
                        />
                    </div>
                </div>
            )}

            {/* View after upload has processed */}
            {uploadProcessed && (
                <div className={`${CSS_CLASS_NAME}__summary`}>
                    <Paragraph size={ParagraphSize.Small}>
                        <strong>{t("importStatus")}</strong>
                        <br />
                        {t("complete")}
                    </Paragraph>
                    <Paragraph size={ParagraphSize.Small}>
                        <strong>{t("enrollmentImportStatus")}</strong>
                    </Paragraph>
                    <div className={`${CSS_CLASS_NAME}__summary__grid`}>
                        <div className={`${CSS_CLASS_NAME}__summary__grid__row`}>
                            <div>{t("total")}</div>
                            <div>100</div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__summary__grid__row`}>
                            <div>{t("successfullyEnrolled")}</div>
                            <div>90</div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__summary__grid__row`}>
                            <div>{t("alreadyEnrolled")}</div>
                            <div>5</div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__summary__grid__row`}>
                            <div>{t("failedToEnroll")}</div>
                            <div>5</div>
                        </div>
                    </div>
                    <Anchor
                        ariaLabel={t("downloadLinkForCSVImportSummaryFile")}
                        cssClassName={`${CSS_CLASS_NAME}__summary__download`}
                        path="https://www.google.com"
                        pathType={AnchorPathType.Download}
                        text={t("downloadCSVImportSummaryFile")}
                    />
                </div>
            )}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ImportEnrollmentModal };

// #endregion Exports
