import React, { useMemo } from "react";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { Tab } from "components/tabs/tabs/tab/tab";
import { UserInstructorHistory } from "./user-instructor-history/user-instructor-history";
import { useUser } from "utilities/contexts/use-user-context";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { useUserRoles } from "utilities/hooks/models/userroles/use-user-roles";
import { DateUtils } from "utilities/date-utils";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { UserInstructorTrainings } from "./user-instructor-trainings/user-instructor-trainings";
import { UserInstructorTeach } from "./user-instructor-teach/user-instructor-teach";
import "./user-en-instructor-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserENInstructorPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-en-instructor-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserENInstructorPage: React.FC<UserENInstructorPageProps> = validatePageAccess(
    AccessControlKeys.UserENInstructorPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { record: user } = useUser();

    const isInstructor = useMemo(
        () => user.userRoles?.some((ur) => ur.role?.roleType === RoleType.Instructor) ?? false,
        [user.userRoles]
    );

    const { userRoles: currentAndDeletedUserRoles } = useUserRoles({
        userId: isInstructor ? -1 : user.id, // only retrieve this data if the user is not an instructor
        includeDeleted: true,
        includeDeletedBy: true,
        includeRole: true,
    });

    const mostRecentlyDeletedInstructorRole = useMemo(() => {
        const deletedInstructorRoles = currentAndDeletedUserRoles?.filter(
            (ur) => ur.role?.roleType === RoleType.Instructor && ur.deletedOn != null
        );
        deletedInstructorRoles!.sort((a: UserRoleRecord, b: UserRoleRecord) => {
            return new Date(a.deletedOn!).getTime() - new Date(b.deletedOn!).getTime();
        });

        if (deletedInstructorRoles && deletedInstructorRoles.length > 0) {
            return deletedInstructorRoles[deletedInstructorRoles.length - 1];
        }
    }, [currentAndDeletedUserRoles]);

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: <UserInstructorTeach />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("approvedToTeach"),
            },
        },
        {
            contents: <UserInstructorTrainings />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("requiredTrainings"),
            },
        },
        {
            contents: <UserInstructorHistory />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("teachingHistory"),
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        {!isInstructor && mostRecentlyDeletedInstructorRole != null && (
                            <div className={`${CSS_CLASS_NAME}__banner-container`}>
                                <Banner style={BannerStyle.Light} format={BannerFormat.Box}>
                                    {t(
                                        "thisUsersInstructorRoleWasRemovedDeletedOnByFirstAndLastNameThisContentIsProvidedForHistoricalContextOnly",
                                        {
                                            deletedOn: DateUtils.formatDateCustom(
                                                mostRecentlyDeletedInstructorRole.deletedOn?.toString()
                                            ),
                                            firstAndLastName:
                                                mostRecentlyDeletedInstructorRole.deletedBy?.getFirstAndLastName(),
                                        }
                                    )}
                                </Banner>
                            </div>
                        )}
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("instructor")}
                        </Heading>
                        <TabbedContainer tabs={tabs} />
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserENInstructorPage };

// #endregion Exports
