import { useCallback, useRef } from "react";
import { InputTypes } from "components/form/enumerations/input-types";
import { TextInput } from "components/form/inputs/text-inputs/text-input/text-input";
import "./search-text-input.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SearchTextInputProps {
    autofocus?: boolean;
    readOnly?: boolean;
    debounce?: number;
    disabled?: boolean;
    id: string;
    onSearchTextInputChange: (searchText: string) => void;
    onSearchTriggered: (searchText: string) => void;
    placeholder: string;
    searchTextInputValue: string;
    title?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "search-text-input";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SearchTextInput: React.FC<SearchTextInputProps> = (
    props: SearchTextInputProps
): JSX.Element => {
    const { debounce, onSearchTextInputChange, onSearchTriggered, searchTextInputValue } = props;
    const debouncedSearchTrigger = useRef<NodeJS.Timeout | null>(null);

    const clearDebounceTimeout = useCallback(() => {
        if (debouncedSearchTrigger.current == null) {
            return;
        }

        clearTimeout(debouncedSearchTrigger.current);
        debouncedSearchTrigger.current = null;
    }, []);

    const immediatelyTriggerSearch = useCallback(() => {
        clearDebounceTimeout();
        onSearchTriggered(searchTextInputValue);
    }, [clearDebounceTimeout, onSearchTriggered, searchTextInputValue]);

    const handleInputKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key.toLowerCase() === "enter") {
                immediatelyTriggerSearch();
            }
        },
        [immediatelyTriggerSearch]
    );

    const handleSearchTextDebounce = useCallback(
        (value: string) => {
            const handleChange = (value: string) => {
                onSearchTriggered(value);
            };

            if (debouncedSearchTrigger != null) clearTimeout(debouncedSearchTrigger.current!);
            debouncedSearchTrigger.current = setTimeout(() => {
                handleChange(value);
            }, debounce);
        },
        [debounce, onSearchTriggered]
    );

    return (
        <div className={CSS_CLASS_NAME} title={props.title}>
            <TextInput
                autoFocus={props.autofocus}
                readOnly={props.readOnly}
                disabled={props.disabled}
                id={props.id}
                onChange={(ev) => {
                    onSearchTextInputChange(ev.target.value);
                    handleSearchTextDebounce(ev.target.value);
                }}
                onKeyPress={handleInputKeyPress}
                placeholder={props.placeholder}
                type={InputTypes.Text}
                value={searchTextInputValue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SearchTextInput };

// #endregion Exports
