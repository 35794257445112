import React from "react";
import { FormFieldReadonly } from "../../../../../../components/form/form-field-readonly/form-field-readonly";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";
import "./user-contract-ill-detail-info.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractILLDetailInfoProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contract-ill-detail-info";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractILLDetailInfo: React.FC<UserContractILLDetailInfoProps> = (
    props: UserContractILLDetailInfoProps
): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("basicInformation")}</h3>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__grid`}>
                <div>
                    <FormFieldReadonly label={t("nfpaContact")}>
                        Sarah Smith
                        <br />
                        s.smith@nfpa.org
                        <br />
                        (000) 000-0000
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("enrollmentLimit")}>50</FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("contractDate")}>03/12/2024</FormFieldReadonly>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__grid-half`}>
                <div>
                    <FormFieldReadonly label={t("eventName")}>
                        Lorem ipsum dolor sit amet consectetur. Nulla mi nisi mauris malesuada vitae
                        elementum. Id vitae fringilla sed quisque vel.
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("productName")}>
                        NFPA 25, Standard for the Inspection, Testing, and Maintenance of
                        Water-Based Fire Protection Systems
                    </FormFieldReadonly>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__grid`}>
                <div>
                    <FormFieldReadonly label={t("ceus")}>1</FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("language")}>English</FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("topic")}>
                        <Badge style={BadgeStyle.WarningBright}>Fire Protection</Badge>
                        <Badge style={BadgeStyle.WarningBright}>Fire Protection</Badge>
                    </FormFieldReadonly>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractILLDetailInfo };

// #endregion Exports
