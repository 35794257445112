import { EnrollmentRecord } from "./enrollment-record";
import { InstructorApplicationEnrollment } from "models/interfaces/enrollments/instructor-application-enrollment";
import { InstructorApplicationRecord } from "../aen-applications/instructor-application-record";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { InstructorAssessmentStatus } from "models/enumerations/enrollments/instructor-assessment-status";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";

// -----------------------------------------------------------------------------------------
// #region Default Values
// -----------------------------------------------------------------------------------------

const defaultValues: InstructorApplicationEnrollment = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    id: undefined,
    instructorApplication: undefined,
    instructorApplicationId: 0,
    status: InstructorAssessmentStatus.NotStarted,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -----------------------------------------------------------------------------------------
// #region Record
// -----------------------------------------------------------------------------------------

class InstructorApplicationEnrollmentRecord
    extends Record(defaultValues)
    implements InstructorApplicationEnrollment
{
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorApplicationEnrollment>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.instructorApplication != null) {
            params.instructorApplication = RecordUtils.ensureRecord(
                params.instructorApplication,
                InstructorApplicationRecord
            );
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------
    /**
     * Returns the application status
     */
    public getApplicationStatus(): InstructorApplicationStatus | undefined {
        return this.instructorApplication?.status;
    }

    /**
     * Given a set of values for InstructorApplicationEnrollmentRecord properties, create a new InstructorApplicationEnrollmentRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<InstructorApplicationEnrollment>} values The values to overwrite on this instance.
     * @returns An InstructorApplicationEnrollmentRecord with values from this instance and the values parameter.
     */
    public with(
        values: Partial<InstructorApplicationEnrollment>
    ): InstructorApplicationEnrollmentRecord {
        return new InstructorApplicationEnrollmentRecord(Object.assign(this.toJS(), values));
    }
    // #endregion Public Methods
}

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { InstructorApplicationEnrollmentRecord };

// #endregion Exports
