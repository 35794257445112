import React, { useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import {
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { CourseVersionActivationModal } from "components/courses/course-version/course-version-activation-modal/course-version-activation-modal";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { DataTable } from "components/tables/data-table/data-table";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./course-version-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseVersionListProps {
    courseVersions: CourseVersionRecord[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-version-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseVersionList: React.FC<CourseVersionListProps> = ({
    courseVersions,
    setRefresh,
}): JSX.Element => {
    const [selectedCourseVersionId, setSelectedCourseVersionId] = useState<number>(0);
    const [showVersionActivationModal, setShowVersionActivationModal] = useState<boolean>(false);

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="version">{t("version")}</th>
                            <th className="enrolled-learners">{t("enrolledLearners")}</th>
                            <th className="in-progress-learners">{t("inprogressLearners")}</th>
                            <th className="status">{t("versionStatus")}</th>
                            <th className="package-status">{t("packageImportStatus")}</th>
                            <th className="date">{t("date")}</th>
                            <th className="changed-by">{t("changedBy")}</th>
                            <th className="action">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseVersions.map((courseVersion) => (
                            <tr key={courseVersion.id}>
                                <td className="version">{courseVersion.versionId}</td>
                                <td className="enrolled-learners">
                                    {courseVersion.numberOfEnrolledLearners}
                                </td>
                                <td className="in-progress-learners">
                                    {courseVersion.numberOfInProgressLearners}
                                </td>
                                <td className="status">
                                    <EnumStatusBadge
                                        displayNames={ActiveStatusStatusDisplayNames}
                                        statusMap={ActiveStatusMap}
                                        value={courseVersion.status ?? ActiveStatus.Draft}
                                    />
                                </td>
                                <td className="package-status">
                                    {courseVersion.scormPackage?.status != null &&
                                        ScormPackageImportStatus[
                                            courseVersion.scormPackage?.status
                                        ]}
                                </td>
                                <td className="date">
                                    {courseVersion.getLastUpdatedDateAndTime()}
                                </td>
                                <td className="changed-by">
                                    <>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {courseVersion.getLastUpdatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}></Paragraph>
                                    </>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuAnchor
                                            hrefPath={RouteUtils.localizePath(
                                                RouteUtils.replacePathParams(
                                                    sitemap.admin.course.edit.materials.version
                                                        .edit,
                                                    {
                                                        id: courseVersion.courseId,
                                                        versionId: courseVersion.id,
                                                    }
                                                )
                                            )}
                                            displayName={t("editVersion")}
                                        />
                                        <ContextMenuButton
                                            displayName={t("activateVersion")}
                                            disabled={
                                                courseVersion.status !== ActiveStatus.Draft ||
                                                courseVersion.scormPackage?.status !==
                                                    ScormPackageImportStatus.Complete
                                            }
                                            onClick={() => {
                                                setSelectedCourseVersionId(courseVersion.id!);
                                                setShowVersionActivationModal(true);
                                            }}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            </div>
            <CourseVersionActivationModal
                courseVersionId={selectedCourseVersionId}
                open={showVersionActivationModal}
                setOpen={setShowVersionActivationModal}
                setRefresh={setRefresh}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionList };

// #endregion Exports
