import React from "react";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { useOrganization as useOrganizationCtx } from "utilities/contexts/use-organization-context";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "utilities/string-utils";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import "./user-contracts-info-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsInfoPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-info-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsInfoPage: React.FC<UserContractsInfoPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsInfoPage
)((): JSX.Element => {
    const { record: organization } = useOrganizationCtx();
    const locationIsEmpty = !(
        StringUtils.hasValue(organization.locationName) ||
        StringUtils.hasValue(organization.addressLineOne) ||
        StringUtils.hasValue(organization.addressLineTwo) ||
        StringUtils.hasValue(organization.city) ||
        StringUtils.hasValue(organization.state) ||
        StringUtils.hasValue(organization.zipCode) ||
        StringUtils.hasValue(organization.country)
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("contractOrganizationInformation")}
                        </Heading>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("systemInformation")}
                        </Heading>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("netsuiteCustomerID")}>
                                    {organization.nfpaId}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("lmsID")}>
                                    {organization.id}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("dateCreated")}>
                                    {organization.formatCreatedOnDate()}
                                </FormFieldReadonly>
                            </div>
                        </div>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("defaultEventInformation")}
                        </Heading>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("defaultEventContactInfo")}>
                                    {StringUtils.valueOrDefault(organization.email)}
                                    <br />
                                    {StringUtils.valueOrDefault(organization.formatPhoneNumber())}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("defaultEventLocation")}>
                                    {locationIsEmpty && <>--</>}
                                    {!locationIsEmpty && (
                                        <>
                                            {StringUtils.hasValue(organization.locationName) && (
                                                <>
                                                    {organization.locationName}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(organization.addressLineOne) && (
                                                <>
                                                    {organization.addressLineOne}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(organization.addressLineTwo) && (
                                                <>
                                                    {organization.addressLineTwo}
                                                    <br />
                                                </>
                                            )}

                                            {(organization.city ||
                                                organization.state ||
                                                organization.zipCode) && (
                                                <>
                                                    {StringUtils.hasValue(organization.city)
                                                        ? `${organization.city}
                                                ${
                                                    StringUtils.hasValue(organization.state) ||
                                                    StringUtils.hasValue(organization.zipCode)
                                                        ? ", "
                                                        : ""
                                                }`
                                                        : ""}
                                                    {StringUtils.hasValue(organization.state)
                                                        ? organization.state + " "
                                                        : ""}
                                                    {StringUtils.hasValue(organization.zipCode)
                                                        ? organization.zipCode
                                                        : ""}
                                                    <br />
                                                </>
                                            )}
                                            {StringUtils.hasValue(organization.country)
                                                ? organization.country
                                                : ""}
                                        </>
                                    )}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("defaultEventTimezone")}>
                                    {StringUtils.valueOrDefault(organization.formatTimeZone())}
                                </FormFieldReadonly>
                            </div>
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsInfoPage };

// #endregion Exports
