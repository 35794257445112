import { ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ManageAddGroupUsers } from "./manage-add-group-users";
import { Modal, ModalAction } from "components/modal/modal";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";
import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddGroupUsersModalProps {
    groupId: number;
    fetchGroupUsers: () => void;
    saveGroupUsers: (userIds: number[]) => Promise<boolean>;
    setShowAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
    showAddUserModal: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddGroupUsersModal: React.FC<AddGroupUsersModalProps> = ({
    groupId,
    showAddUserModal,
    setShowAddUserModal,
    saveGroupUsers,
    fetchGroupUsers,
}) => {
    const [usersAvailable, setUsersAvailable] = useState<UserRecord[]>([]);
    const [usersToAdd, setUsersToAdd] = useState<UserRecord[]>([]);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("addUsers"),
            disabled: CollectionUtils.isEmpty(usersToAdd),
            onClick: () => onGroupUserSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                setShowAddUserModal(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setUsersAvailable([]);
            setShowAddUserModal(false);
        }
    };

    const onGroupUserSave = async (): Promise<void> => {
        const userIds = usersToAdd.map((c) => c.id!);
        if (userIds.length === 0) {
            resetForm();
            setShowAddUserModal(false);
            return;
        }

        const saveSuccessful: boolean = await saveGroupUsers(userIds);

        if (saveSuccessful) {
            resetForm();
            setShowAddUserModal(false);
            fetchGroupUsers();
        } else {
            return;
        }
    };

    const resetForm = (): void => {
        setDirty(false);
        setUsersAvailable([]);
        setUsersToAdd([]);
    };

    const sortByName = (users: UserRecord[]) => {
        users.sort((a, b) => a.email!.localeCompare(b.email!));
    };

    const handleAddUserToQueue = useCallback(
        (user: UserRecord): void => {
            const sortedUserList = [...usersToAdd, user];

            sortByName(sortedUserList);
            setUsersToAdd(sortedUserList);
            setDirty(true);
        },
        [usersToAdd]
    );

    const handleRemoveUserFromQueue = useCallback(
        (user: UserRecord): void => {
            const filteredUsers = usersToAdd.filter((u) => u.id !== user.id);
            sortByName(filteredUsers);
            setUsersToAdd(filteredUsers);

            if (filteredUsers.length === 0) {
                setDirty(false);
            }
        },
        [usersToAdd]
    );

    return (
        <Modal
            isOpen={showAddUserModal}
            onModalClose={onModalClose}
            title={t("addUsers")}
            actions={modalActionArray}
            modalStyle={""}>
            <ManageAddGroupUsers
                groupId={groupId}
                users={usersAvailable}
                usersToAdd={usersToAdd}
                handleAddUserToQueue={handleAddUserToQueue}
                setUsersAvailable={setUsersAvailable}
                handleRemoveUserFromQueue={handleRemoveUserFromQueue}
            />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddGroupUsersModal };

// #endregion Exports
