import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum InstructorProfileStatus {
    Active = 0,
    Pending = 1,
    Inactive = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const InstructorProfileStatusDisplayNames: Map<InstructorProfileStatus, TranslatedCopy> = {
    [InstructorProfileStatus.Active]: "active",
    [InstructorProfileStatus.Pending]: "pendingApproval",
    [InstructorProfileStatus.Inactive]: "inactive",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorProfileStatus, InstructorProfileStatusDisplayNames };

// #endregion Exports
