import AenApplicationDetailPageFormField from "./aen-application-detail-page-form-field/aen-application-detail-page-form-field";
import AenApplicationDetailPageSection from "./aen-application-detail-page-section/aen-application-detail-page-section";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AenApplicationAddLogModal } from "components/aen-application/aen-application-logs/aen-application-add-file-modal/aen-application-add-activity-log-modal";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { TrainingSpaceTypeFormResponse } from "models/enumerations/aen-applications/training-space-type";
import { t } from "utilities/localization/t";
import { useAenApplicationActivityLog } from "utilities/hooks/models/aen-applications/use-aen-application-logs";
import { useProviderApplication } from "utilities/contexts/use-provider-application-context";
import { useState } from "react";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

const CSS_CLASS_NAME = "aen-application-provider-detail-page";

const AenProviderApplicationDetailPage = validatePageAccess(
    AccessControlKeys.AdminAenProviderApplications
)((): JSX.Element => {
    const { record: providerApplication } = useProviderApplication();
    const { addActivityLog } = useAenApplicationActivityLog({
        application: providerApplication,
        type: AenApplicationType.Provider,
    });

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <HeaderActions
                    title={t("applicationDetails")}
                    headingPriority={HeadingPriority.H2}
                    headingSize={HeadingSize.XSmall}>
                    <ButtonIcon
                        onClick={() => {
                            setShowModal(true);
                        }}
                        buttonStyle={ButtonStyle.Primary}
                        text={t("addNotes")}
                        iconType={Icons.Plus}
                    />
                </HeaderActions>
            </div>
            <AenApplicationDetailPageSection headingText={t("partIProviderInformation")}>
                <AenApplicationDetailPageFormField
                    label={t("providerName")}
                    value={providerApplication?.providerName}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("website")}
                    value={providerApplication?.providerWebsite}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("country")}
                    value={providerApplication?.country}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("address")}
                    value={
                        <div>
                            {providerApplication?.streetAddress}
                            <br />
                            {providerApplication?.city}, {providerApplication?.stateProvince}{" "}
                            {providerApplication?.zipcode}
                        </div>
                    }
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("name")}
                    value={providerApplication?.primaryName}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("email")}
                    value={providerApplication?.primaryEmail}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("phone")}
                    value={providerApplication?.primaryPhone}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("providerType")}
                    value={providerApplication?.providerType}
                    otherValue={providerApplication?.providerTypeOther}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("providerBusinessSegment")}
                    value={providerApplication?.providerBusinessSegment}
                    otherValue={providerApplication?.providerBusinessSegmentOther}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("description")}
                    value={providerApplication?.description}
                />
                <AenApplicationDetailPageFormField
                    label={t("whyDoYouWantToHost")}
                    value={providerApplication?.whyWantToHost}
                />
                <AenApplicationDetailPageFormField
                    label={t("intendedAudience")}
                    value={providerApplication?.intendedAudience}
                />
                <AenApplicationDetailPageFormField
                    label={t("locations")}
                    value={providerApplication?.locations}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("partIITrainingAdministration")}>
                <AenApplicationDetailPageFormField
                    label={t(
                        "isThereSomeoneElseBesidesTheProviderPrimaryContactWhoWillBeResponsibleForAdministratingNfpaTrainingEvents"
                    )}
                    value={providerApplication?.hasAdditionalAdministrator}
                />
                <AenApplicationDetailPageFormField
                    label={t("ifYesPleaseProvideFullNameAndEmail")}
                    value={providerApplication?.administratorContactInfo}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("partIIITrainingSpace")}>
                <AenApplicationDetailPageFormField
                    label={t("doYouPlanToOfferTraining")}
                    value={
                        providerApplication?.trainingSpaceType != null
                            ? t(
                                  TrainingSpaceTypeFormResponse[
                                      providerApplication.trainingSpaceType
                                  ]
                              )
                            : ""
                    }
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("partIVMarketing")}>
                <AenApplicationDetailPageFormField
                    label={t("asAProviderHowWillYouPromoteTrainingCourses")}
                    value={providerApplication?.promotionEfforts}
                    otherValue={providerApplication?.promotionEffortsOther}
                />
            </AenApplicationDetailPageSection>
            <AenApplicationDetailPageSection headingText={t("partVCertification")}>
                <AenApplicationDetailPageFormField
                    label={t("signature")}
                    value={providerApplication?.signatureName}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("title")}
                    value={providerApplication?.signatureTitle}
                    asGrid
                />
                <AenApplicationDetailPageFormField
                    label={t("date")}
                    value={providerApplication?.signatureDate?.toString()}
                    asGrid
                />
            </AenApplicationDetailPageSection>
            <AenApplicationAddLogModal
                handleSave={addActivityLog}
                open={showModal}
                setOpen={setShowModal}
            />
        </>
    );
});

export { AenProviderApplicationDetailPage };
