import { DateUtils } from "utilities/date-utils";
import { FileRecord } from "models/view-models/files/file-record";
import { ProviderApplicationFile } from "models/interfaces/aen-applications/provider-application-file";
import {
    ProviderApplicationFileType,
    ProviderApplicationFileTypeDisplayNames,
} from "models/enumerations/aen-applications/provider-application-file-type";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ProviderApplicationFile = {
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    description: undefined,
    id: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    file: undefined,
    fileId: 0,
    providerApplicationId: 0,
    type: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProviderApplicationFileRecord
    extends Record(defaultValues)
    implements ProviderApplicationFile
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ProviderApplicationFile>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.file != null) {
            RecordUtils.ensureRecord(params.file, FileRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------
    /**
     * Convenience method to the return type Enum string with spaces between each word.
     * Ex: EducationNetwork -> Training Space
     */
    public getFileTypeDisplayName(): string {
        if (this == null || this.type == null) {
            return "";
        }
        const fileType = this.type as ProviderApplicationFileType;
        return t(ProviderApplicationFileTypeDisplayNames[fileType]);
    }

    /**
     * Convenience method to get the upload user's first and last name
     * @returns {string} Randy Jenkins
     */
    public getFirstAndLastName(): string {
        return this.createdBy?.getFirstAndLastName() ?? "";
    }

    /**
     * Convenience method to get the upload date
     * @returns {string} MM/DD/YYYY formatted date string
     */
    public getUploadDate(): string {
        return DateUtils.formatDate(true, this.createdOn);
    }

    public with(values: Partial<ProviderApplicationFile>): ProviderApplicationFileRecord {
        return new ProviderApplicationFileRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderApplicationFileRecord };

// #endregion Exports
