import { ServiceHookFactory } from "andculturecode-javascript-react";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments/:enrollmentId/enrollmentscormpackageassessments";
const resourceType: typeof EnrollmentScormPackageAssessmentRecord =
    EnrollmentScormPackageAssessmentRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEnrollmentScormPackageAssessmentPathParams {
    id: number;
    enrollmentId: number;
}
export interface ListEnrollmentScormPackageAssessmentsQueryParams {
    scormPackageAssessmentId?: number;
}

export interface ListEnrollmentScormPackageAssessmentPathParams {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EnrollmentScormPackageAssessmentService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EnrollmentScormPackageAssessmentRecord,
        GetEnrollmentScormPackageAssessmentPathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EnrollmentScormPackageAssessmentRecord,
        ListEnrollmentScormPackageAssessmentPathParams,
        ListEnrollmentScormPackageAssessmentsQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentScormPackageAssessmentService };

// #endregion Exports
