import React, { useMemo, useState, useEffect } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { CollectionUtils, RouteUtils } from "andculturecode-javascript-core";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { EventRecord } from "models/view-models/events/event-record";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { sitemap } from "sitemap";
import { useEvents } from "utilities/hooks/models/events/use-events";
import { useParams } from "react-router-dom";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { ScrollUtils } from "utilities/scroll-utils";
import "./user-instructor-history.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserInstructorHistoryProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-instructor-history";
const ITEMS_PER_PAGE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserInstructorHistory: React.FC<UserInstructorHistoryProps> = (): JSX.Element => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const userId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const { events, rowCount: eventCount } = useEvents({
        instructorId: userId,
        excludeDraft: true,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const getStatus = (event: EventRecord): TranslatedCopy => {
        if (event.status === PublishStatus.Canceled) {
            return "canceled";
        }

        if (event.isLive()) {
            return "live";
        } else {
            return "completed";
        }
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.${CSS_CLASS_NAME}`);
    }, [events]);

    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("teachingHistory")}</h3>
            {CollectionUtils.hasValues(events) ? (
                <>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="product">{t("product")}</th>
                                <th className="type">{t("type")}</th>
                                <th className="host">{t("host")}</th>
                                <th className="start-date">{t("startDate")}</th>
                                <th className="status">{t("status")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {CollectionUtils.isNotEmpty(events) &&
                                events.map((event) => (
                                    <tr key={event.id}>
                                        <td className="product">{event.product?.name}</td>
                                        <td className="type">
                                            {event.instructorLedTrainingType ===
                                            InstructorLedTrainingType.LiveVirtual ? (
                                                <Badge
                                                    style={BadgeStyle.Neutral}
                                                    text={t("liveVirtual")}
                                                />
                                            ) : (
                                                <Badge
                                                    style={BadgeStyle.Dark}
                                                    text={t("inPerson")}
                                                />
                                            )}
                                        </td>
                                        <td className="host">{event.getHostName()}</td>
                                        <td className="start-date">
                                            <div className="icon-text">
                                                <span>{event.getFirstEventDateText()}</span>{" "}
                                                <Icon type={Icons.CalendarMulti} />
                                            </div>
                                        </td>
                                        <td className="status">
                                            {event.status === PublishStatus.Canceled && (
                                                <Badge
                                                    style={BadgeStyle.Error}
                                                    text={t(getStatus(event))}
                                                />
                                            )}
                                            {event.status === PublishStatus.Live && (
                                                <Badge
                                                    style={BadgeStyle.Success}
                                                    text={t(getStatus(event))}
                                                />
                                            )}
                                        </td>
                                        <td className="action">
                                            <ContextMenu>
                                                <ContextMenuAnchor
                                                    hrefPath={RouteUtils.replacePathParams(
                                                        sitemap.admin.event.manage.details,
                                                        { id: event.id }
                                                    )}
                                                    displayName={t("goToEvent")}
                                                />
                                            </ContextMenu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                    <div className={`${CSS_CLASS_NAME}__pager`}>
                        <Pager
                            currentPage={currentPage}
                            totalPages={eventCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={eventCount}
                        />
                    </div>
                </>
            ) : (
                <EmptyText table spacing={EmptyTextSpacing.Small}>
                    {t("thisInstructorCurrentlyHasNoPastTrainings")}
                </EmptyText>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserInstructorHistory };

// #endregion Exports
