// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum RecordSummaryStyle {
    Compact = "-compact",
    Default = "",
    Secondary = "-secondary",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RecordSummaryStyle };

// #endregion Exports
