import React from "react";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { StringUtils } from "andculturecode-javascript-core";
import "./anchor-icon.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum AnchorIconPosition {
    Left,
    Right,
}

enum AnchorIconStyles {
    Button = "-button",
    Default = "",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AnchorIconProps {
    disabled?: boolean;
    active?: boolean;
    ariaLabel?: string;
    cssClassName?: string;
    download?: string;
    iconPosition?: AnchorIconPosition;
    iconType: Icons;
    pathType?: AnchorPathType;
    path: string;
    size?: IconSizes;
    style?: AnchorIconStyles;
    text?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "anchor-icon";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnchorIcon: React.FC<AnchorIconProps> = (props: AnchorIconProps): JSX.Element => {
    const content: JSX.Element[] = [<Icon key="icon" size={props.size} type={props.iconType} />];
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.text)) {
        content.push(
            <span className="text" key="text">
                {props.text}
            </span>
        );
    }

    if (props.iconPosition === AnchorIconPosition.Right) {
        content.reverse();
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (StringUtils.hasValue(props.style)) {
        cssClassNames.push(props.style);
    }

    const cssClassName: string = cssClassNames.join(" ");

    return (
        <Anchor
            active={props.active}
            ariaLabel={props.ariaLabel}
            cssClassName={cssClassName}
            download={props.download}
            disabled={props.disabled}
            pathType={props.pathType}
            path={props.path}>
            {content}
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnchorIcon, AnchorIconPosition, AnchorIconStyles };

// #endregion Exports
