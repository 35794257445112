import DatePicker from "react-datepicker";
import React, { useMemo } from "react";
import { t } from "utilities/localization/t";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationUtils } from "utilities/localization-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalendarDateRangePickerProps {
    endDate: Date | null;
    handleEventDateRangeChange: (date?: Array<Date | null>) => void;
    readOnly?: boolean;
    startDate: Date | null;
    placeholder?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CalendarDateRangePicker: React.FC<CalendarDateRangePickerProps> = ({
    endDate,
    handleEventDateRangeChange,
    readOnly,
    startDate,
    placeholder,
}) => {
    const { readOnly: isCtxReadOnly } = useReadOnly();
    const disabled = useMemo(() => readOnly || isCtxReadOnly, [isCtxReadOnly, readOnly]);

    return (
        <DatePicker
            dateFormat={t("datePickerDateFormat")}
            endDate={endDate}
            isClearable={true}
            readOnly={disabled}
            onChange={handleEventDateRangeChange}
            placeholderText={placeholder ?? t("selectADateRange")}
            selectsRange={true}
            startDate={startDate}
            locale={LocalizationUtils.currentCultureCode()}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CalendarDateRangePicker };

// #endregion Exports
