import React from "react";
import { Tag } from "../tag/tag";
import { Icons } from "components/icons/constants/icons";
import "./tag-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TagItem {
    disabled?: boolean;
    locked?: boolean;
    selected?: boolean;
    text: string;
    value: string;
}

export interface TagListProps {
    cssClassName?: string;
    id: string;
    items: TagItem[];
    onTagSelected?: (selected: boolean, text: string, value: string) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "tag-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TagList: React.FC<TagListProps> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    const className = cssClassNames.join(" ");

    return (
        <div className={className}>
            {props.items.map((item, index) => (
                <Tag
                    disabled={item.disabled}
                    locked={item.locked}
                    icon={item.locked ? Icons.Lock : undefined}
                    key={`${props.id}-${index}`}
                    id={`${props.id}-${index}`}
                    onSelect={props.onTagSelected}
                    selected={item.selected}
                    text={item.text}
                    value={item.value}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TagList };

// #endregion Exports
