import React, { PropsWithChildren, createContext } from "react";
import { useReports } from "utilities/hooks/models/reports/use-reports";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type ReportingContextState = ReturnType<typeof useReports>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Context Provider
// -------------------------------------------------------------------------------------------------

const ReportingContext = createContext<ReportingContextState>({ reports: [], isLoading: true });
const ReportingContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const result = useReports();

    return <ReportingContext.Provider value={result}>{children}</ReportingContext.Provider>;
};

// #endregion Context Provider

// -------------------------------------------------------------------------------------------------
// #region Context Hook
// -------------------------------------------------------------------------------------------------

const useReportingContext = () => React.useContext(ReportingContext);

// #endregion Context Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportingContextProvider, useReportingContext };

// #endregion Exports
