import { DateUtils } from "utilities/date-utils";
import { EventRecord } from "./event-record";
import { EventScheduleException } from "models/interfaces/events/event-schedule-exception";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventScheduleException = {
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    eventId: 0,
    granted: false,
    id: undefined,
    reason: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventScheduleExceptionRecord extends Record(defaultValues) implements EventScheduleException {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventScheduleException>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = DateUtils.formatLastEditedDate(this, false);
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return t("messageByUpdatedByGetFirstAndLastName", {
            message: message,
            updatedByGetFirstAndLastName: updatedBy.getFirstAndLastName(),
        });
    }

    /**
     * Given a set of values for EventScheduleException properties, create a new EventScheduleExceptionRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventScheduleException>} values The values to overwrite on this instance.
     * @returns A EventScheduleException with values from this instance and the values parameter.
     */
    public with(values: Partial<EventScheduleException>): EventScheduleExceptionRecord {
        return new EventScheduleExceptionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionRecord };

// #endregion Exports
