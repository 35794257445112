import React, { useEffect } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import {
    ActiveStatus,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { ProductRecord } from "models/view-models/products/product-record";
import { RouteUtils } from "utilities/route-utils";
import { Status } from "utilities/enumerations/statuses";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { ScrollUtils } from "utilities/scroll-utils";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import "./product-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductListProps {
    products: ProductRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductList: React.FC<ProductListProps> = ({ products }): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const canModifyProducts = useHasAccess(AccessControlKeys.CanModifyProducts);

    const getPath = (product: ProductRecord) =>
        product.type === TrainingType.InstructorLedTraining
            ? RouteUtils.replacePathParams(sitemap.admin.product.edit.instructorLed.details, {
                  id: product.id,
              })
            : RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                  id: product.id,
              });

    const setStatusBubbleColor = (status: ActiveStatus) => {
        switch (status) {
            case ActiveStatus.Active:
                return Status.Success;
            case ActiveStatus.Inactive:
                return Status.Warning;
            case ActiveStatus.Draft:
                return Status.Default;
            case ActiveStatus.Archived:
                return Status.Inverted;
            default:
                return Status.Neutral;
        }
    };

    useEffect(() => {
        ScrollUtils.scrollToTop();
    }, [products]);

    return (
        <div className={CSS_CLASS_NAME}>
            {CollectionUtils.hasValues(products) ? (
                <>
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th className="name">{t("name")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="type">{t("type")}</th>
                                <th className="version">{t("version")}</th>
                                <th className="last-change">{t("lastChange")}</th>
                                <th className="changed-by">{t("changedBy")}</th>
                                <th className="status">{t("status")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product) => (
                                <tr key={product.id}>
                                    <td className="product-name">
                                        <span
                                            className={`${CSS_CLASS_NAME}__product-name -product-type`}>
                                            {product.name}
                                        </span>
                                    </td>
                                    <td className="id">{product.id}</td>
                                    <td className="type">
                                        {product.type == null
                                            ? t("notApplicableShort")
                                            : t(TrainingTypeDisplayNames[product.type])}
                                    </td>
                                    <td className="version">{product.latestVersionId}</td>
                                    <td className="last-change">
                                        {product.getLastUpdatedDateAndTime()}
                                    </td>
                                    <td className="changed-by">
                                        <span className="user-name">
                                            {product.getLastUpdatedBy()}
                                        </span>
                                        {currentUserIsInNfpaRole && (
                                            <span className="user-id">
                                                {product.updatedById ?? product.createdById}
                                            </span>
                                        )}
                                    </td>
                                    <td className="status">
                                        <StatusBadge
                                            status={setStatusBubbleColor(
                                                product.status ?? ActiveStatus.Draft
                                            )}
                                            text={
                                                ActiveStatusStatusDisplayNames[product.status ?? 2]
                                            }
                                        />
                                    </td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                disabled={!canModifyProducts}
                                                hrefPath={RouteUtils.replacePathParams(
                                                    getPath(product),
                                                    {
                                                        id: product.id,
                                                    }
                                                )}
                                                displayName={t("editProduct")}
                                            />
                                            {product.type === TrainingType.OnlineLearning &&
                                                product.status === ActiveStatus.Active && (
                                                    <ContextMenuAnchor
                                                        hrefPath={RouteUtils.replacePathParams(
                                                            sitemap.admin.product.enrollments,
                                                            {
                                                                id: product.id,
                                                            }
                                                        )}
                                                        displayName={t("manageEnrollments")}
                                                    />
                                                )}
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </>
            ) : (
                <EmptyText table>{t("noProductResultsFound")}</EmptyText>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductList };

// #endregion Exports
