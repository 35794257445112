import { Button, ButtonStyle } from "components/buttons/button/button";
import { t } from "utilities/localization/t";
import { useAzure } from "pages/public/azure-login/use-azure";

interface CreateAccountAnchorProps {
    cssClassName?: string;
}

const CreateAccountAnchor = (props: CreateAccountAnchorProps) => {
    const { signUp } = useAzure();
    const { cssClassName = "c-button -anchor" } = props;

    return (
        <Button cssClassName={cssClassName} onClick={signUp} style={ButtonStyle.LinkAlt}>
            {t("createAnAccount")}
        </Button>
    );
};

export { CreateAccountAnchor };
