import React from "react";
import { ApplicationTitle } from "components/typography/text/application-title/application-title";
import { StringUtils } from "andculturecode-javascript-core";
import { AdminLayoutHeaderNavigation } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-navigation";
import { AdminLayoutHeaderAnnouncements } from "layouts/admin/admin-layout-header-announcements/admin-layout-header-announcements";
import "./admin-layout-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeader: React.FC<AdminLayoutHeaderProps> = (
    props: AdminLayoutHeaderProps
): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const cssClassName: string = cssClassNames.join(" ");

    return (
        <header className={cssClassName}>
            <ApplicationTitle />
            <section>
                <AdminLayoutHeaderAnnouncements />
                <AdminLayoutHeaderNavigation />
            </section>
        </header>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeader };

// #endregion Exports
