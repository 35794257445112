import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AssignmentCard } from "components/assignment-card/assignment-card";
import { Button, ButtonStyle, ButtonSize, ButtonType } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLearnerQuickLinks } from "./use-learner-quick-links";
import { useMemo } from "react";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./my-learning-assignments-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface MyInProgressLearningAssignmentsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BANNER_LINK_TEXT: TranslatedCopy = "viewTraining";
const CSS_CLASS_NAME = "my-learning-assignments-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getTrainingPath = (enrollment: EnrollmentRecord) => {
    if (enrollment.id == null) {
        return "";
    }
    if (enrollment.eventId !== null) {
        return getEventTrainingPath(enrollment.id, enrollment.eventId!);
    } else {
        return getOLLTrainingPath(enrollment.id, enrollment.productId);
    }
};

const getOLLTrainingPath = (enrollmentId: number, productId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.onlineLearning.details, {
        productId: productId,
        enrollmentId: enrollmentId,
    });
};

const getEventTrainingPath = (enrollmentId: number, eventId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.event.details, {
        id: eventId,
        enrollmentId: enrollmentId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyInProgressLearningAssignmentsPage: React.FC<MyInProgressLearningAssignmentsPageProps> =
    validatePageAccess(AccessControlKeys.MyLearningAssignmentsPage)((): JSX.Element => {
        const { record: globalState } = useGlobalState();
        const { learnerQuickLinks } = useLearnerQuickLinks({
            settings: globalState?.systemSettings,
        });

        const learnerFirstName = useMemo(
            () => globalState?.currentIdentity?.user?.firstName,
            [globalState?.currentIdentity?.user?.firstName]
        );

        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        const { notifications, markNotificationAsRead } = useUserNotifications({
            includeEnrollment: true,
            roleType: RoleType.Learner,
            userId: userId,
        });

        const { enrollments: allActiveEnrollments } = useUserEnrollments({
            userId: userId,
            enrollmentActiveStatus: EnrollmentActiveStatus.Active,
            includeProduct: true,
            roleType: globalState?.currentIdentity?.role?.roleType,
        });

        const enrollments: EnrollmentRecord[] = useMemo(() => {
            const getExpiration = (e: EnrollmentRecord): Date | undefined => e.expirationDate;

            // Determine which secondary sort should be applied.
            const secondarySortFactory =
                (dateSelector: (e: EnrollmentRecord) => Date | undefined) =>
                (e1: EnrollmentRecord, e2: EnrollmentRecord): number => {
                    const e1Date = dateSelector(e1);
                    const e2Date = dateSelector(e2);
                    if (
                        e1Date == null ||
                        e1Date.getTime == null ||
                        e2Date == null ||
                        e2Date.getTime == null
                    ) {
                        return 1;
                    }
                    return e1Date.getTime() - e2Date.getTime();
                };

            // Apply primary and secondary sorts.
            // (Need to apply them in inverse order.)
            const sortedEnrollments = allActiveEnrollments
                .sort((e1: EnrollmentRecord, e2: EnrollmentRecord): number => {
                    const dateSelector = getExpiration;

                    return secondarySortFactory(dateSelector)(e1, e2);
                })
                .sort((e1: EnrollmentRecord, e2: EnrollmentRecord) => {
                    return new Date(e2.createdOn!).getTime() - new Date(e1.createdOn!).getTime();
                });

            return sortedEnrollments;
        }, [allActiveEnrollments]);

        const handleNotificationClose = (notificationId?: number) => {
            if (notificationId == null) {
                return;
            }

            markNotificationAsRead?.(notificationId);
        };

        const getNotificationLinkPath = (notification: NotificationRecord) => {
            if (notification.enrollment?.withdrawnOn != null) {
                return sitemap.public.noAccess;
            } else {
                return getTrainingPath(notification.enrollment!);
            }
        };

        return (
            <>
                <HeaderBanner
                    title={t("twelcomeLearnerFirstName", {
                        twelcome: t("welcome"),
                        learnerFirstName: learnerFirstName,
                    })}></HeaderBanner>
                <SkipNavContent>
                    <div className={`${CSS_CLASS_NAME}`}>
                        <div className={`${CSS_CLASS_NAME}__section`}>
                            <div className={`${CSS_CLASS_NAME}__quick__links`}>
                                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                    {t("quickLinks")}
                                </Heading>
                                <QuickLinkList links={learnerQuickLinks} />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__header`}>
                                <h2>{t("assignments")}</h2>
                                <div className={`${CSS_CLASS_NAME}__assignment-filter`}>
                                    <Button
                                        size={ButtonSize.Medium}
                                        style={ButtonStyle.Primary}
                                        text={t("inHyphenProgress")}
                                        type={ButtonType.Link}
                                    />
                                    <Button
                                        size={ButtonSize.Medium}
                                        style={ButtonStyle.Secondary}
                                        text={t("past")}
                                        linkPath={sitemap.learner.learningAssignments.past}
                                        type={ButtonType.Link}
                                    />
                                </div>
                            </div>
                            {notifications != null && (
                                <div className={`${CSS_CLASS_NAME}__notifications`}>
                                    {notifications.map((notification, index) => (
                                        <NotificationBanner
                                            key={`${notification.id}-${index}`}
                                            icon={notification.getNotificationIcon()}
                                            linkPath={
                                                notification.enrollment == null
                                                    ? ""
                                                    : getNotificationLinkPath(notification)
                                            }
                                            linkText={t(BANNER_LINK_TEXT)}
                                            message={notification.message}
                                            notificationId={notification.id!}
                                            onClose={() => handleNotificationClose(notification.id)}
                                            style={notification.notificationType}
                                        />
                                    ))}
                                </div>
                            )}
                            {CollectionUtils.hasValues(enrollments) ? (
                                <div className={`${CSS_CLASS_NAME}__assignments`}>
                                    {enrollments.map((enrollment, index) => (
                                        <AssignmentCard
                                            key={`${enrollment.id}-${index}`}
                                            enrollment={enrollment}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <EmptyText cssClassName={`${CSS_CLASS_NAME}__empty`}>
                                    {t("browseNfpaTrainingCatalog")}
                                    <a
                                        className={`${CSS_CLASS_NAME}__empty__link`}
                                        href={globalState?.systemSettings?.ecommerceBaseUrl ?? ""}
                                        target="_blank"
                                        rel="noreferrer">
                                        {t("browseCatalog")}
                                    </a>
                                </EmptyText>
                            )}
                        </div>
                    </div>
                </SkipNavContent>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MyInProgressLearningAssignmentsPage };

// #endregion Exports
