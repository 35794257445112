import React from "react";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { Select } from "../inputs/select/select";
import { InputProperties } from "../input-properties";
import { SelectOption } from "../inputs/select/select";
import "./form-select.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormSelectProps extends FormFieldProps, InputProperties {
    errorMessage?: string;
    onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: SelectOption[];
    value?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormSelect: React.FC<FormSelectProps> = (props: FormSelectProps) => {
    return (
        <FormField
            cssClassName={props.cssClassName}
            errorMessage={props.errorMessage}
            formFieldName={props.formFieldName}
            label={props.label}
            labelScreenReaderOnly={props.labelScreenReaderOnly}
            required={props.required}>
            <div className="select-wrapper">
                <Select
                    ariaLabelledBy={props.ariaLabelledBy}
                    autoFocus={props.autoFocus}
                    disabled={props.disabled}
                    id={props.formFieldName}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    options={props.options}
                    placeholder={props.placeholder}
                    value={props.value}
                />
            </div>
        </FormField>
    );
};

// #endregion Components

export { FormSelect };
