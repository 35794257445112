import React from "react";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { ScormPackagePreviewButton } from "components/scorm-packages/scorm-package-preview-button/scorm-package-preview-button";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { t } from "utilities/localization/t";
import "./event-assessment-results-scorm-package-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAssessmentResultsScormPackageCardProps {
    scormPackage?: ScormPackageRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-assessment-results-scorm-package-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAssessmentResultsScormPackageCard: React.FC<
    EventAssessmentResultsScormPackageCardProps
> = (props: EventAssessmentResultsScormPackageCardProps): JSX.Element => {
    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__container`}>
                <h3>{props.scormPackage?.name}</h3>
                <ScormPackagePreviewButton
                    text={t("previewAssessment")}
                    externalScormCourseId={props.scormPackage?.externalScormCourseId}
                    scormStatus={props.scormPackage?.status}
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.Primary}
                />
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAssessmentResultsScormPackageCard };

// #endregion Exports
