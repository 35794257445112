import { ContractSummary } from "models/interfaces/contracts/contract-summary";
import { Record } from "immutable";
import { EventRecord } from "models/view-models/events/event-record";
import { RecordUtils } from "andculturecode-javascript-core";
import { ProductRecord } from "models/view-models/products/product-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ContractSummary = {
    completedEvaluationCount: undefined,
    completedSyllabusCount: undefined,
    contractId: undefined,
    contractDate: undefined,
    contractNumber: "",
    enrollmentCount: undefined,
    enrollmentLimit: undefined,
    event: undefined,
    eventId: undefined,
    noEnrollmentLimit: false,
    product: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ContractSummaryRecord extends Record(defaultValues) implements ContractSummary {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ContractSummary>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for ContractSummary properties, create a new ContractSummaryRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<ContractSummary>} values The values to overwrite on this instance.
     * @returns An ContractSummaryRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<ContractSummary>): ContractSummaryRecord {
        return new ContractSummaryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractSummaryRecord };

// #endregion Exports
