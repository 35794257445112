import ReportSection from "components/reports/components/report-section/report-section";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { GroupRecord } from "models/view-models/groups/group-record";
import { GroupService, GroupIndexQueryParams } from "utilities/services/groups/group-service";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupSelectionProps {
    label?: string;
    isRequired?: boolean;
    groups: GroupRecord[];
    onGroupsChanged: (Groups: GroupRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupSelection: React.FC<GroupSelectionProps> = ({
    label,
    isRequired = false,
    groups,
    onGroupsChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchGroups, setSearchGroups] = useState<GroupRecord[]>([]);

    const { list: listGroups } = GroupService.useList();
    const fetchGroups = useCallback(
        async (searchText?: string) => {
            try {
                const listGroupsQueryParams: GroupIndexQueryParams = {
                    searchText,
                };

                const listFilteredGroupsResponse = await listGroups(listGroupsQueryParams);
                const listFilteredGroupsResults = listFilteredGroupsResponse?.results;
                const groups = listFilteredGroupsResponse?.resultObjects;
                if (
                    groups == null ||
                    listFilteredGroupsResults == null ||
                    listFilteredGroupsResults.hasErrors()
                ) {
                    throw new Error();
                }
                setSearchGroups(groups);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingGroups"));
                setSearchGroups([]);
            }
        },
        [listGroups]
    );

    const handleRemoveGroup = useCallback(
        (group: GroupRecord) => {
            onGroupsChanged(groups.filter((c) => c.id !== group.id));
        },
        [groups, onGroupsChanged]
    );

    const handleGroupSelectionChange = (selectedGroups: GroupRecord[]) => {
        onGroupsChanged(selectedGroups);
    };

    const handleAddGroups = () => {
        fetchGroups();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {label ? label : t("selectGroups")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddGroups}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addGroups")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(groups) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="group-name">{t("groupName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map((group) => (
                                <tr key={group.id}>
                                    <td className="group-name">{group.name}</td>
                                    <td className="id">{group.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveGroup(group)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchGroups}
                onSelectionChanged={handleGroupSelectionChange}
                searchValues={searchGroups}
                selectedValues={groups}
                title={t("addGroups")}
                itemName={t("groupName")}
                searchPlaceholderText={t("searchByName")}
                valuesDescription={t("groups")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default GroupSelection;

// #endregion Exports
