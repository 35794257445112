import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";
import AdminLayoutHeaderAnnouncementsPanel from "./admin-layout-header-announcements-panel/admin-layout-header-announcements-panel";
import { AnnouncementContext } from "utilities/contexts/use-announcement-context";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import {
    AnnouncementService,
    GetAnnouncementPathParams,
    GetAnnouncementQueryParams,
} from "utilities/services/announcements/announcement-service";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { UserAnnouncementService } from "utilities/services/announcements/user-announcement-service";
import "./admin-layout-header-announcements.scss";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-announcements";

// #endregion Constants

const AdminLayoutHeaderAnnouncements: React.FC = () => {
    const { record: globalState } = useGlobalState();
    const userId = globalState.currentIdentity?.userId() ?? -1;
    const [navigationIsOpen, setNavigationIsOpen] = useState<boolean>(false);
    const [isRead, setIsRead] = useState(true);
    const [activeAnnouncement, setActiveAnnouncement] = useState<AnnouncementRecord>(
        new AnnouncementRecord()
    );

    const ICON_CSS = useMemo(
        () => `${CSS_CLASS_NAME}__actions__open ${!isRead ? "-unread" : null}`,
        [isRead]
    );

    const { get: getActiveAnnouncement } = AnnouncementService.useGet();
    const { list: getUserAnnouncement } = UserAnnouncementService.useList();

    const fetchUserAnnouncement = useCallback(
        async (announcementId: number) => {
            if (userId <= 0 || announcementId <= 0) {
                return;
            }

            const userAnnouncementResponse = await getUserAnnouncement(
                { userId: userId, announcementId: announcementId },
                {}
            );

            const userAnnouncement =
                userAnnouncementResponse.resultObjects.length > 0
                    ? userAnnouncementResponse.resultObjects[0]
                    : null;

            setIsRead(userAnnouncement?.isRead ?? false);
        },
        [getUserAnnouncement, userId]
    );

    const fetchActiveAnnouncement = useCallback(async () => {
        const pathParams: GetAnnouncementPathParams = {};
        const queryParams: GetAnnouncementQueryParams = {
            includeCreatedBy: true,
            includeLastModifiedBy: true,
        };

        const responseData = await getActiveAnnouncement(pathParams, queryParams);
        const announcement = responseData.result?.resultObject;

        setActiveAnnouncement(announcement);

        if (announcement?.id > 0) {
            await fetchUserAnnouncement(announcement?.id);
        }
    }, [getActiveAnnouncement, fetchUserAnnouncement]);

    useEffect(() => {
        fetchActiveAnnouncement();
    }, [fetchActiveAnnouncement]);

    const handleMarkAsRead = () => {
        setIsRead(true);
    };

    const handleOpenAnnouncementsClick = useCallback((): void => {
        setNavigationIsOpen(true);
    }, []);

    const handleCloseAnnouncementsClick = useCallback((): void => {
        setNavigationIsOpen(false);
    }, []);

    return (
        <AnnouncementContext.Provider
            value={{
                announcement: activeAnnouncement,
                isRead,
                setIsRead: handleMarkAsRead,
            }}>
            <div className={`${CSS_CLASS_NAME}__actions`}>
                <Transition
                    as="span"
                    className="absolute right-0 w-full h-full"
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    show={!navigationIsOpen}>
                    <ButtonIcon
                        ariaLabelledBy="Open Announcements"
                        buttonStyle={ButtonStyle.None}
                        buttonSize={ButtonSize.None}
                        cssClassName={ICON_CSS}
                        iconType={Icons.Announcements}
                        iconSize={IconSizes.Large}
                        onClick={handleOpenAnnouncementsClick}
                    />
                </Transition>
                <Transition
                    as="span"
                    className="absolute right-0 w-full h-full"
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    show={navigationIsOpen}>
                    <ButtonIcon
                        ariaLabelledBy="Close Announcements"
                        buttonStyle={ButtonStyle.None}
                        buttonSize={ButtonSize.None}
                        cssClassName={`${CSS_CLASS_NAME}__actions__close`}
                        iconType={Icons.Close}
                        iconSize={IconSizes.Large}
                        onClick={handleCloseAnnouncementsClick}
                    />
                </Transition>
            </div>
            <AdminLayoutHeaderAnnouncementsPanel isOpen={navigationIsOpen} />
        </AnnouncementContext.Provider>
    );
};

export { AdminLayoutHeaderAnnouncements };
