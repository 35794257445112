// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

/**
 * String constants mapping to values stored in browser local storage
 */
enum LocalStorageKey {
    Identity = "Identity",
    SystemSettings = "SystemSettings",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LocalStorageKey };

// #endregion Exports
