import React, { useEffect, useMemo, useRef, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ContentRecord } from "models/view-models/contents/content-record";
import { CourseContentCard } from "components/learner/learner-online-learning/course-content-card/course-content-card";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { RegistrationLaunchButton } from "components/registrations/registration-launch-button/registration-launch-button";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "utilities/string-utils";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/hooks/models/courses/use-course";
import { useCourseVersion } from "utilities/hooks/models/courses/use-course-version";
import { useEnrollmentUnitCourse } from "utilities/hooks/models/enrollments/use-enrollment-unit-course";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useProduct } from "utilities/hooks/models/products/use-product";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useRegistration } from "utilities/hooks/models/registration/use-registration";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./learner-online-learning-unit-course-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningUnitCoursePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-unit-course-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningUnitCoursePage: React.FC<LearnerOnlineLearningUnitCoursePageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningUnitCoursePage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const navigate = useNavigate();
        const { record: globalState } = useGlobalState();
        const userRole = useMemo(() => globalState?.currentIdentity?.role?.roleType, [globalState]);
        const [startPolling, setStartPolling] = useState<boolean>(false);

        const {
            productId: productIdParam,
            enrollmentId: enrollmentIdParam,
            courseId: courseIdParam,
            unitId: unitIdParam,
        } = useParams();

        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const productId = useMemo(
            () => NumberUtils.parseInt(productIdParam) ?? 0,
            [productIdParam]
        );
        const unitId = useMemo(() => NumberUtils.parseInt(unitIdParam) ?? 0, [unitIdParam]);
        const courseId = useMemo(() => NumberUtils.parseInt(courseIdParam) ?? 0, [courseIdParam]);

        const { product } = useProduct({ productId });

        const { course } = useCourse({
            courseId,
        });

        const { topic } = course ?? {};

        const { enrollmentUnitCourse, isLoading } = useEnrollmentUnitCourse({
            enrollmentId: enrollmentId,
            courseId: courseId,
        });

        const courseVersionId = enrollmentUnitCourse?.courseVersionId ?? 0;

        const { courseVersion } = useCourseVersion({
            courseVersionId,
            includeScormPackage: true,
            includeContent: true,
        });

        courseVersion?.sortContent();

        const contents =
            courseVersion?.courseContents
                ?.map((c) => c.content)
                .filter((c): c is ContentRecord => c != null) ?? [];

        const parent =
            product?.onlineLearningType === ProductCourseType.LearningPath
                ? t("unit")
                : t("syllabus");

        const parentPath: string = useMemo(() => {
            const learnerPath =
                product?.onlineLearningType === ProductCourseType.LearningPath
                    ? sitemap.learner.training.onlineLearning.units.details
                    : sitemap.learner.training.onlineLearning.details;
            const instructorPath =
                product?.onlineLearningType === ProductCourseType.LearningPath
                    ? sitemap.instructor.aenTraining.units.details
                    : sitemap.instructor.aenTraining.details;
            return RouteUtils.replacePathParams(
                userRole === RoleType.Learner ? learnerPath : instructorPath,
                {
                    productId: productId,
                    enrollmentId: enrollmentId,
                    unitId: unitId,
                }
            );
        }, [product?.onlineLearningType, userRole, productId, enrollmentId, unitId]);

        const scormPackageId = courseVersion?.scormPackageId ?? 0;

        const { registration, fetchRegistration } = useRegistration({
            enrollmentId: enrollmentId,
            scormPackageId: scormPackageId,
            includeAttempts: true,
        });

        const intervalRef = useRef<ReturnType<typeof setInterval> | null>();

        useEffect(() => {
            if (startPolling) {
                setStartPolling(false);
                const registrationPollingInterval = setInterval(() => {
                    fetchRegistration();
                }, 10000);
                intervalRef.current = registrationPollingInterval;
            }

            if (registration?.hasPassingAttempt()) {
                clearInterval(intervalRef.current!);
                return;
            }
        }, [fetchRegistration, startPolling, registration]);

        useEffect(() => {
            return () => clearInterval(intervalRef.current!);
        }, []);

        if (!isLoading && enrollmentUnitCourse?.locked) {
            navigate(parentPath, { replace: true });
        }

        const viewResultPath: string = useMemo(() => {
            if (
                registration?.id == null ||
                registration.id <= 0 ||
                registration.attempts == null ||
                registration.attempts.length <= 0 ||
                courseVersion?.scormPackage?.scormPackageAssessment?.id == null ||
                courseVersion?.scormPackage?.scormPackageAssessment.id <= 0
            ) {
                return "";
            }

            return RouteUtils.replacePathParams(
                userRole === RoleType.Learner
                    ? sitemap.learner.training.onlineLearning.assessments.results
                    : sitemap.instructor.aenTraining.assessments.results,
                {
                    productId: productId,
                    enrollmentId: enrollmentId,
                    assessmentId: courseVersion?.scormPackage?.scormPackageAssessment.id,
                    registrationId: registration?.id,
                }
            );
        }, [
            registration?.id,
            registration?.attempts,
            courseVersion?.scormPackage?.scormPackageAssessment?.id,
            productId,
            enrollmentId,
            userRole,
        ]);

        const assessmentStatusBadge = useMemo(() => {
            if (registration?.id == null || registration.attempts == null) {
                return <Badge style={BadgeStyle.Default} text={t("notStarted")} />;
            }

            if (registration?.hasPassingAttempt()) {
                return <Badge style={BadgeStyle.Success} text={t("passed")} />;
            }

            return <Badge style={BadgeStyle.Warning} text={t("attempted")} />;
        }, [registration]);

        const bestAttempt = useMemo(() => {
            if (registration?.attempts == null) {
                return null;
            }

            const sortedAttemptsBestFirst = registration.attempts
                .filter((a): a is AttemptRecord => a != null)
                .sort((a, b) => {
                    return (a.score ?? 0) > (b.score ?? 0) ? -1 : 1;
                });

            return sortedAttemptsBestFirst[0];
        }, [registration?.attempts]);

        const courseStatusBadge = useMemo(() => {
            // No Registration Exists
            if (registration == null) {
                return <Badge style={BadgeStyle.Default} text={t("notStarted")} />;
            }

            // Course Passed
            if (registration?.hasPassingAttempt()) {
                return <Badge style={BadgeStyle.Success} text={t("complete")} />;
            }

            // Registration exists, no passed attempts
            return <Badge style={BadgeStyle.Warning} text={t("inProgress")} />;
        }, [registration]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <ButtonIcon
                        linkPath={parentPath}
                        iconType={Icons.ChevronLeft}
                        buttonSize={ButtonSize.Medium}
                        buttonStyle={ButtonStyle.Secondary}
                        text={t("backToParent", { parent: parent })}
                        buttonType={ButtonType.Link}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__details`}>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>{t("course")}</Paragraph>
                        <Paragraph size={ParagraphSize.XLarge}>{course?.name}</Paragraph>
                        <Paragraph cssClassName="version">
                            Version {courseVersion?.versionId}
                        </Paragraph>
                        <Badge
                            style={
                                !enrollmentUnitCourse?.optional
                                    ? BadgeStyle.Neutral
                                    : BadgeStyle.Default
                            }
                            text={!enrollmentUnitCourse?.optional ? t("required") : t("optional")}
                        />
                        <Paragraph style={ParagraphStyle.Label}>{t("aboutThisCourse")}</Paragraph>
                        <Paragraph size={ParagraphSize.Large} style={ParagraphStyle.Light}>
                            {course?.description}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                        {topic != null ? (
                            <Paragraph>
                                <Badge
                                    style={BadgeStyle.Warning}
                                    text={t(TopicDisplayNames[topic])}
                                />
                            </Paragraph>
                        ) : null}
                        <Paragraph style={ParagraphStyle.Label}>{t("completion")}</Paragraph>
                        <Paragraph>{courseStatusBadge}</Paragraph>

                        {scormPackageId > 0 && (
                            <RegistrationLaunchButton
                                disabled={enrollmentUnitCourse?.locked}
                                text={t("launchCourse")}
                                enrollmentId={enrollmentId}
                                enrollmentUnitCourseId={enrollmentUnitCourse?.id}
                                registrationId={registration?.id ?? 0}
                                scormPackageId={scormPackageId}
                                onLaunchButtonClicked={setStartPolling}
                            />
                        )}
                    </div>
                </div>

                {contents.length > 0 && (
                    <div className={`${CSS_CLASS_NAME}__resources`}>
                        <h3>{t("courseResources")}</h3>
                        <div className={`${CSS_CLASS_NAME}__resources__list`}>
                            {contents.map((content, index) => {
                                return (
                                    <CourseContentCard
                                        key={`course-resource-${index}`}
                                        content={content}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className={`${CSS_CLASS_NAME}__assessments`}>
                    <h3>{t("assessmentResult")}</h3>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("completionDate")}</th>
                                <th>{t("status")}</th>
                                <th>{t("highScore")}</th>
                                <th>{t("attempts")}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {(bestAttempt?.completionDate ?? bestAttempt?.createdOn) ==
                                        null
                                            ? "--"
                                            : DateUtils.formatDateCustom(
                                                  (
                                                      bestAttempt?.completionDate ??
                                                      bestAttempt?.createdOn
                                                  )?.toString(),
                                                  t("shortDateFormat")
                                              )}
                                    </Paragraph>
                                </td>
                                <td>{assessmentStatusBadge}</td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {bestAttempt?.score ?? "--"}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {registration?.attempts?.length ?? 0}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Button
                                        linkPath={viewResultPath}
                                        size={ButtonSize.Small}
                                        style={ButtonStyle.Primary}
                                        text={t("viewResults")}
                                        type={ButtonType.Link}
                                        disabled={!StringUtils.hasValue(viewResultPath)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningUnitCoursePage };

// #endregion Exports
