import React from "react";
import { EventRecord } from "models/view-models/events/event-record";
import { StringUtils } from "andculturecode-javascript-core";
import { ContactCardList } from "components/training-summary/contact-card-list/contact-card-list";
import { t } from "utilities/localization/t";
import "./learner-take-a-training-contact-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingContactCardProps {
    cssClassName?: string;
    event: EventRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-take-a-training-contact-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingContactCard: React.FC<LearnerTakeATrainingContactCardProps> = ({
    cssClassName,
    event,
}): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <div className={className}>
            <h2 className={`${className}__heading`}>{t("contact")}</h2>
            <div className={`${className}__sections`}>
                <ContactCardList
                    avatarFileId={event.instructor?.instructorProfile?.avatarFileId}
                    showHostCard={!event.hideContactInformation}
                    showInstructorCard={true}
                    hostCompanyEmail={event.getHostEmailAddress()}
                    hostCompanyName={event.getHostSupportName()}
                    hostCompanyPhone={event.getHostPhone()}
                    instructorId={event.instructorId}
                    instructorCompanyEmail={
                        event.instructor?.instructorProfile ? event.instructor.email : ""
                    }
                    instructorCompanyName={event.instructor?.getFirstAndLastName()}
                    instructorCompanyPhone={
                        event.instructor?.instructorProfile ? event.instructor.phoneNumber : ""
                    }
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingContactCard };

// #endregion Exports
