import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ContractsDetailSidebar } from "components/user-management/contracts/contracts-detail-sidebar/contracts-detail-sidebar";
import { EditOrganizationModal } from "components/user-management/contracts/edit-organization-modal/edit-organization-modal";
import { NumberUtils } from "utilities/number-utils";
import { OrganizationContext } from "utilities/contexts/use-organization-context";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { Outlet, useParams } from "react-router-dom";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import {
    GetOrganizationPathParams,
    OrganizationService,
    UpdateOrganizationPathParams,
} from "utilities/services/organizations/organization-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { ContractsDetailHeader } from "components/user-management/contracts/contracts-detail-header/contracts-detail-header";
import "./contract-organization-layout.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ContractOrganizationLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "contract-organization-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContractOrganizationLayout: React.FC<ContractOrganizationLayoutProps> = (): JSX.Element => {
    const { id: organizationIdParam } = useParams();
    const organizationId = useMemo(
        () => NumberUtils.parseInt(organizationIdParam) ?? 0,
        [organizationIdParam]
    );

    const [organization, setOrganization] = useState<OrganizationRecord>(new OrganizationRecord());
    const { update: updateOrganizationApi } = OrganizationService.useUpdate();
    const { get: getOrganizationApi } = OrganizationService.useGet();
    const [showEditContractModal, setShowEditContractModal] = useState(false);

    const handleSave = async (updatedOrganization: OrganizationRecord): Promise<Boolean> => {
        try {
            const updateOrganizationPathParams: UpdateOrganizationPathParams = {
                id: updatedOrganization.id!,
            };
            const updateResponse = await updateOrganizationApi(
                updatedOrganization,
                updateOrganizationPathParams
            );
            const updateResult = updateResponse?.result;
            if (updateResult?.resultObject == null || updateResult?.hasErrors()) {
                throw new Error();
            }
            setOrganization(updateResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheOrganization"));
            return false;
        }
        return true;
    };

    const fetchData = useCallback(async () => {
        if (organizationId <= 0) {
            return;
        }

        const pathParams: GetOrganizationPathParams = {
            id: organizationId,
        };

        try {
            const getResponse = await getOrganizationApi(pathParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }

            setOrganization(getResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheAssessment"));
        }
    }, [organizationId, getOrganizationApi]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <OrganizationContext.Provider value={{ record: organization, setRecord: setOrganization }}>
            <div className={CSS_CLASS_NAME}>
                {organization.id != null ? (
                    <>
                        <ContractsDetailHeader
                            organization={organization}
                            onEditOrganizationClick={() => setShowEditContractModal(true)}
                        />
                        <SideContentLeftLayout sidebarElement={<ContractsDetailSidebar />}>
                            <Outlet />
                        </SideContentLeftLayout>
                    </>
                ) : (
                    <Outlet />
                )}
            </div>
            <EditOrganizationModal
                onSave={handleSave}
                open={showEditContractModal}
                setOpen={setShowEditContractModal}
                organization={organization}
            />
        </OrganizationContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractOrganizationLayout };

// #endregion Exports
