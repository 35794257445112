import { Attempt } from "models/interfaces/attempts/attempt";
import { AttemptSuccessStatusDisplayNames } from "models/enumerations/attempts/attempt-success-status";
import { AttemptCompletionStatusDisplayNames } from "models/enumerations/attempts/attempt-completion-status";
import { Record } from "immutable";
import { DateUtils } from "utilities/date-utils";
import { CollectionUtils, RecordUtils, StringUtils } from "andculturecode-javascript-core";
import { RegistrationRecord } from "../registrations/registration-record";
import { ResponseRecord } from "../responses/response-record";
import { UserRecord } from "../users/user-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Attempt = {
    attemptNumber: undefined,
    completionDate: undefined,
    completionStatus: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    isManualEntry: false,
    manualEntryNotes: undefined,
    registration: undefined,
    registrationId: 0,
    responses: undefined,
    score: undefined,
    successStatus: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class AttemptRecord extends Record(defaultValues) implements Attempt {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Attempt>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.registration != null) {
            params.registration = RecordUtils.ensureRecord(params.registration, RegistrationRecord);
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        if (CollectionUtils.hasValues(params.responses)) {
            params.responses = RecordUtils.ensureRecords(params.responses, ResponseRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the Completion Status
     * Ex: Completed
     */
    public getCompletionStatusDisplay(): string {
        const completionStatusDisplay =
            this.completionStatus == null
                ? ""
                : t(AttemptCompletionStatusDisplayNames[this.completionStatus]);
        return completionStatusDisplay;
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY-MM-DDTHH:MM:SS:FFFFFFF+Offset
     * 2022-10-20T17:42:30.7005991+00:00

     */
    public getLastUpdated(): string {
        return StringUtils.hasValue(this.updatedOn) ? this.updatedOn : this.createdOn ?? "";
    }

    /**
     * Convenience method to the return the last updated time in milliseconds.
     * Ex: 1666287750700 for 2022-10-20T17:42:30.7005991+00:00
     */

    public getLastUpdatedInMilliseconds(): number {
        const lastUpdated = this.getLastUpdated();
        return DateUtils.convertToMilliseconds(lastUpdated);
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY
     */
    public getLastUpdatedText(): string {
        return DateUtils.formatLastEditedDate(this, true);
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    public getLastUpdatedDateTimeText(): string {
        return DateUtils.formatLastEditedDateTime(this, true, false);
    }

    /**
     * Convenience method to the return the text for the score
     * Ex: 88%
     */
    public getScoreText(): string {
        const score = this.score == null ? "-" : `${this.score}%`;

        return score;
    }

    /**
     * Convenience method to the return the text for the Success Status
     * Ex: Passed
     */
    public getSuccessStatusDisplay(): string {
        const successStatusDisplay =
            this.successStatus == null
                ? ""
                : t(AttemptSuccessStatusDisplayNames[this.successStatus]);
        return successStatusDisplay;
    }

    /**
     * Given a set of values for Attempt properties, create a new AttemptRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Attempt>} values The values to overwrite on this instance.
     * @returns An AttemptRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Attempt>): AttemptRecord {
        return new AttemptRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptRecord };

// #endregion Exports
