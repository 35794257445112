import React from "react";
import { LearnerLayoutHeader } from "layouts/learner/learner-layout/learner-layout-header/learner-layout-header";
import { Outlet } from "react-router-dom";
import { SkipNavLink } from "@chakra-ui/skip-nav";
import "./learner-layout.scss";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerLayoutProps {
    sidebar?: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerLayout: React.FC<LearnerLayoutProps> = (props: LearnerLayoutProps): JSX.Element => {
    const { isInEmulationMode } = useEmulationMode();
    const className = isInEmulationMode ? `${CSS_CLASS_NAME} -emulation-mode` : CSS_CLASS_NAME;
    return (
        <div className={className}>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <LearnerLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            <div className={`${CSS_CLASS_NAME}__body`}>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerLayout };

// #endregion Exports
