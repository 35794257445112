import React, { useMemo } from "react";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import {
    ThirdPartyTrainingLayoutSidebar,
    SidebarNavItem,
} from "layouts/third-party/third-party-training-layout/third-party-training-layout-sidebar/third-party-training-layout-sidebar";
import { Language } from "models/enumerations/languages/language";
import { useProduct } from "utilities/hooks/models/products/use-product";
import { ProductCourseTypeDisplayNames } from "models/enumerations/courses/product-course-type";
import "./third-party-training-layout.scss";
import { t } from "i18next";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ThirdPartyTrainingLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "third-party-training-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ThirdPartyTrainingLayout: React.FC<ThirdPartyTrainingLayoutProps> = (): JSX.Element => {
    const { contractId: contractIdParam, productId: productIdParam } = useParams();

    const contractId = useMemo(() => NumberUtils.parseInt(contractIdParam) ?? 0, [contractIdParam]);
    const productId = useMemo(() => NumberUtils.parseInt(productIdParam) ?? 0, [productIdParam]);

    const { product } = useProduct({ productId });

    ////////////////////////////////
    // Badges
    ////////////////////////////////
    const badges: string[] = useMemo(() => {
        const badges: string[] = [];
        if (product?.language != null) {
            badges.push(Language[product.language]);
        }

        if (product?.onlineLearningType != null) {
            badges.push(t(ProductCourseTypeDisplayNames[product.onlineLearningType]));
        }

        return badges;
    }, [product?.language, product?.onlineLearningType]);

    ////////////////////////////////
    // Nav Items
    ////////////////////////////////

    const navItems: SidebarNavItem[] = useMemo(() => {
        if (productId == null || productId <= 0) {
            return [];
        }

        return [
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(
                    sitemap.thirdPartyProvider.onlineLearning.details,
                    {
                        contractId: contractId,
                        productId: productId,
                    }
                ),
                displayName: t("about"),
                iconType: Icons.EventDetails,
            },
            {
                path: RouteUtils.replacePathParams(
                    sitemap.thirdPartyProvider.onlineLearning.enrollment,
                    {
                        contractId: contractId,
                        productId: productId,
                    }
                ),
                displayName: t("enrollment"),
                iconType: Icons.Enrollment,
            },
        ];
    }, [contractId, productId]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__window`}>
                <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                    <ThirdPartyTrainingLayoutSidebar
                        badges={badges}
                        trainingName={product?.name}
                        trainingType={product?.type}
                        navItems={navItems}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__window__main`}>
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__body`}>
                            <Outlet />
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ThirdPartyTrainingLayout };

// #endregion Exports
