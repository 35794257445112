import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { InstructorApplicationService } from "utilities/services/aen-applications/instructor-application-service";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { t } from "i18next";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingInstructorApplications";
interface UseAenInstructorApplicationsOptions {
    searchText?: string;
    status?: InstructorApplicationStatus;
    assignedToId?: number;
    skip?: number;
    take?: number;
    isReady?: boolean;
    isSearchCriteriaReady?: boolean;
    onError?: (error: Error) => void;
}
export const UseAenInstructorApplications = ({
    searchText,
    status,
    assignedToId,
    skip,
    take,
    isReady,
    onError,
}: UseAenInstructorApplicationsOptions) => {
    const { list } = InstructorApplicationService.useList();
    const [instructorApplications, setInstructorApplications] = useState<
        InstructorApplicationRecord[]
    >([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isReady) return;
        try {
            (async function getInstructorApplications() {
                setIsLoading(true);
                const response = await list({
                    searchText,
                    status,
                    assignedToId,
                    skip,
                    take,
                });
                setIsLoading(false);

                if (
                    response?.resultObjects == null ||
                    response?.results == null ||
                    response.results.hasErrors()
                ) {
                    throw new Error();
                }

                setInstructorApplications(response.resultObjects);
                setRowCount(response.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedToId, isReady, searchText, skip, status, take]);

    return {
        error,
        isLoading,
        instructorApplications,
        rowCount,
    };
};
