// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const REPORT_TYPES = {
    ALL_TRAININGS: "1",
    EN_INSTRUCTOR: "2",
    // EN_PROVIDER: "3",
    ORGANIZATION: "4",
    INVOICE_STATUS: "5",
} as const;

const REPORT_TYPES_DESCRIPTIONS = {
    [REPORT_TYPES.ALL_TRAININGS]: "allTrainingsOllAndILT",
    [REPORT_TYPES.EN_INSTRUCTOR]: "enInstructor",
    // [REPORT_TYPES.EN_PROVIDER]: "enProvider",
    [REPORT_TYPES.ORGANIZATION]: "organization",
    [REPORT_TYPES.INVOICE_STATUS]: "invoiceStatus",
} as const;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { REPORT_TYPES, REPORT_TYPES_DESCRIPTIONS };

// #endregion Exports
