import { ResultErrorRecord } from "andculturecode-javascript-core";
import { UnitRecord } from "models/view-models/units/unit-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { UnitService } from "utilities/services/units/unit-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseProductUnitsHook {
    units: UnitRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseProductUnitsHookOptions {
    productId?: number | string;
    includeUnitCourses?: boolean;
}

export function useProductUnits(props: UseProductUnitsHookOptions): UseProductUnitsHook {
    const includeUnitCourses = props.includeUnitCourses ?? false;
    const [isLoading, setIsLoading] = useState(true);
    const [units, setUnits] = useState<UnitRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: getUnits } = UnitService.useList();
    const productId = NumberUtils.parseInt(props.productId) ?? 0;

    useEffect(() => {
        if (isNaN(productId) || productId < 1) {
            return;
        }

        try {
            (async function getProductUnits() {
                const { result, resultObjects } = await getUnits({
                    productId,
                    includeUnitCourses,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setUnits(resultObjects.sort((a, b) => a.sortOrder! - b.sortOrder!));
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningProductUnits"));
        }
    }, [getUnits, includeUnitCourses, productId]);

    return {
        errors,
        isLoading,
        units,
    };
}
