import { useCallback, useEffect, useState } from "react";
import { EventOrganizationSelectionList } from "components/events/event-organization-selection/event-organization-selection-modal/event-organization-selection-list/event-organization-selection-list";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SelectableOrganization } from "models/interfaces/organizations/selectable-organization";
import { ToastManager } from "utilities/toast/toast-manager";
import {
    ListOrganizationsQueryParams,
    OrganizationService,
} from "utilities/services/organizations/organization-service";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventManageAddOrganizationsProps {
    event: EventActiveRecord;
    handleAddOrganizationToQueue: (organization: OrganizationRecord) => void;
    handleFetchOrganizations: (organization: OrganizationRecord[]) => void;
    handleRemoveOrganizationFromQueue: (organization: OrganizationRecord) => void;
    organizations: OrganizationRecord[];
    selectedOrganization?: OrganizationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-manage-add-organization";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "searchByOrganizationName";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventManageAddOrganizations: React.FC<EventManageAddOrganizationsProps> = ({
    event,
    handleAddOrganizationToQueue,
    handleFetchOrganizations,
    handleRemoveOrganizationFromQueue,
    organizations,
    selectedOrganization,
}) => {
    const [searchText, setSearchText] = useState<string>("");
    const [selectableOrganizations, setSelectableOrganizations] = useState<
        SelectableOrganization[]
    >([]);
    const { list: listOrganizations } = OrganizationService.useList();

    const filterOrganizations = useCallback(
        async (searchText: string) => {
            try {
                const listOrganizationsQueryParams: ListOrganizationsQueryParams = {
                    searchText: searchText,
                };

                const listFilteredOrganizationsResponse = await listOrganizations(
                    listOrganizationsQueryParams
                );
                const listFilteredOrganizationsResults = listFilteredOrganizationsResponse?.results;
                const organizations = listFilteredOrganizationsResponse?.resultObjects;
                if (
                    organizations == null ||
                    listFilteredOrganizationsResults == null ||
                    listFilteredOrganizationsResults.hasErrors()
                ) {
                    throw new Error();
                }
                handleFetchOrganizations(organizations);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingOrganizations"));
                handleFetchOrganizations([]);
            }
        },
        [handleFetchOrganizations, listOrganizations]
    );

    const convertOrganizationToSelectableOrganization = useCallback(
        (organizations: OrganizationRecord[]): SelectableOrganization[] => {
            return organizations.map(
                (p): SelectableOrganization => ({
                    organization: p,
                    isSelected: selectedOrganization?.id === p.id,
                })
            );
        },
        [selectedOrganization?.id]
    );

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    useEffect(() => {
        const selectableOrganizations = convertOrganizationToSelectableOrganization(organizations);
        setSelectableOrganizations(selectableOrganizations);
    }, [convertOrganizationToSelectableOrganization, organizations, selectedOrganization]);

    return (
        <div className={CSS_CLASS_NAME}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={filterOrganizations}
                id="organizationSearch"
                placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                searchTextInputValue={searchText}
            />
            <EventOrganizationSelectionList
                selectableOrganizations={selectableOrganizations}
                handleAdd={handleAddOrganizationToQueue}
                handleRemove={handleRemoveOrganizationFromQueue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventManageAddOrganizations };

// #endregion Exports
