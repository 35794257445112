import { Group } from "models/interfaces/groups/group";
import { GroupRecord } from "models/view-models/groups/group-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type GroupRecordUpdater = RecordUpdater<Group, GroupRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: GroupContext, useRecord: useGroup } = UseRecordContextFactory.build<
    Group,
    GroupRecord
>(GroupRecord);

export { GroupContext, useGroup };

// #endregion Exports
