import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import React from "react";
import "./bar-chart.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BarChartColumn {
    label: string;
    value: number;
    description?: string;
}

export interface BarChartProps {
    columns: BarChartColumn[];
    denominator?: number;
    orientation?: BarChartOrientation;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum BarChartOrientation {
    Horizontal = "-horizontal",
    Vertical = "-vertical",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "bar-chart";
const DEFAULT_ORIENTATION: BarChartOrientation = BarChartOrientation.Vertical;
const BAR_COLOR_CLASSES: string[] = [
    "bg-neutral-30",
    "bg-neutral-50",
    "bg-neutral-70",
    "bg-accents-blue-base",
    "bg-neutral-90",
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BarChart: React.FC<React.PropsWithChildren<BarChartProps>> = (props) => {
    const columns = props.columns;
    const totalValue =
        props.denominator ?? columns.reduce((sum, current) => sum + current.value, 0);
    const columnWidth = Math.round((1 / columns.length) * 100);
    const orientation: BarChartOrientation = props.orientation ?? DEFAULT_ORIENTATION;

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            {orientation === BarChartOrientation.Vertical && (
                <>
                    <div className={`${CSS_CLASS_NAME}__columns`}>
                        {columns.map((column, index) => {
                            const percentage =
                                totalValue === 0
                                    ? 0
                                    : Math.round((column.value / totalValue) * 100);

                            return (
                                <div
                                    className={`${CSS_CLASS_NAME}__columns__column`}
                                    key={`chart-column-${index}`}
                                    style={{ width: columnWidth + "%" }}>
                                    <div className="bar" style={{ height: percentage + "%" }}>
                                        <Paragraph style={ParagraphStyle.Label}>
                                            {percentage}%{" "}
                                            <span className="sr-only">- {column.label}</span>
                                        </Paragraph>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__column__labels`}>
                        {columns.map((column, index) => {
                            return (
                                <>
                                    <div
                                        className={`${CSS_CLASS_NAME}__column__labels__column`}
                                        key={`chart-label-${index}`}
                                        style={{ width: columnWidth + "%" }}>
                                        <Paragraph ariaHidden={true} size={ParagraphSize.Small}>
                                            {column.label}
                                        </Paragraph>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__column__description`}>
                        {columns.map((column, index) => {
                            return (
                                <>
                                    <div style={{ width: columnWidth + "%" }}>
                                        <Paragraph
                                            ariaHidden={true}
                                            cssClassName="pg-desc"
                                            size={ParagraphSize.Small}
                                            style={ParagraphStyle.Label}>
                                            {column.description}
                                        </Paragraph>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </>
            )}
            {orientation === BarChartOrientation.Horizontal && (
                <div className={`${CSS_CLASS_NAME}__rows`}>
                    {columns.map((column, index) => {
                        const percentage =
                            totalValue === 0 ? 0 : Math.round((column.value / totalValue) * 100);

                        return (
                            <div className={`${CSS_CLASS_NAME}__rows__row`}>
                                <div
                                    className={`${CSS_CLASS_NAME}__rows__row__label`}
                                    key={`chart-label-${index}`}>
                                    <Paragraph ariaHidden={true} size={ParagraphSize.Small}>
                                        {column.label}
                                    </Paragraph>
                                </div>
                                <div
                                    className={`${CSS_CLASS_NAME}__rows__row__bar-container`}
                                    key={`chart-row-${index}`}>
                                    <div
                                        className={`bar ${BAR_COLOR_CLASSES[index % 5]}`}
                                        style={{ width: percentage + "%" }}>
                                        <Paragraph style={ParagraphStyle.Label}>
                                            {percentage}%{" "}
                                            <span className="sr-only">- {column.label}</span>
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BarChart, BarChartOrientation };

// #endregion Exports
