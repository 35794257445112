import { ProductDetailsArchiveRecord } from "models/view-models/products/product-details-archive-record";
import { ProductDetailsArchiveService } from "utilities/services/products/product-details-archive-service";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseProductDetailsArchiveHook {
    productDetailsArchive: ProductDetailsArchiveRecord[];
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseProductDetailsArchiveHookOptions {
    productId?: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
}

export function useProductDetailsArchive(
    props: UseProductDetailsArchiveHookOptions
): UseProductDetailsArchiveHook {
    const [isLoading, setIsLoading] = useState(true);
    const [productDetailsArchive, setProductDetailsArchive] = useState<
        ProductDetailsArchiveRecord[]
    >([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [refresh, setRefresh] = useState(false);
    const { list: listProductDetailsArchive } = ProductDetailsArchiveService.useList();
    const productId = props.productId;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLastModifiedBy = props.includeLastModifiedBy;

    const fetchData = useCallback(async () => {
        try {
            (async function getProductDetailsArchive() {
                if (productId === undefined) return;
                const { result, resultObjects } = await listProductDetailsArchive({
                    productId,
                    includeCreatedBy,
                    includeLastModifiedBy,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setProductDetailsArchive(resultObjects);
            })();
        } catch {
            ToastManager.error("There was a problem returning product details archive.");
        }
    }, [productId, includeCreatedBy, includeLastModifiedBy, listProductDetailsArchive]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        productDetailsArchive,
        setRefresh,
    };
}
