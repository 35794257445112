import React, { useEffect, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Event } from "models/interfaces/events/event";
import { EventDayManager } from "components/events/event-schedule/event-schedule-manager/event-day-manager";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventSchedulePageHeader } from "components/events/event-schedule/event-schedule-page-header/event-schedule-page-header";
import { EventScheduleSummary } from "components/events/event-schedule/event-schedule-summary/event-schedule-summary";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { FormSelect } from "components/form/form-select/form-select";
import { useTimeZoneOptions } from "utilities/hooks/use-timezone-options";
import "./admin-edit-event-schedule-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventSchedulePageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-schedule-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventSchedulePage: React.FC<AdminEditEventSchedulePageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventSchedulePage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.event.edit.details);
    const event = useEventActiveRecordContext();
    const [currentEventDayIndex, setCurrentEventDayIndex] = useState(-1);
    const [eventDay, setEventDay] = useState<EventDayRecord>(undefined!);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { timeZoneOptions: timeZones } = useTimeZoneOptions();

    useRedirectOnForbidden(sitemap.admin.dashboard);

    const cancelConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                onHasAllDaySessionsToggle();
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const productSelected = useMemo(
        () => event.product?.id !== undefined && event.product?.id > 0,
        [event.product?.id]
    );

    const editingExistingDay = useMemo(
        () => currentEventDayIndex >= 0 && currentEventDayIndex < (event?.eventDays?.length ?? 0),
        [currentEventDayIndex, event?.eventDays?.length]
    );

    const nextAvailableDate = useMemo(() => {
        const eventDays = event.eventDays;
        if (eventDays === undefined || eventDays.length === 0) {
            return new Date();
        }

        const numberOfEventDays = eventDays.length;
        const lastEventDayIndex = numberOfEventDays - 1;
        const dayAfterLastEventDate = new Date(eventDays[lastEventDayIndex].eventDate());
        dayAfterLastEventDate.setDate(dayAfterLastEventDate.getDate() + 1);

        return dayAfterLastEventDate;
    }, [event.eventDays]);

    const isEventDayAvailable = (eventDayIndex: number, date: Date): boolean => {
        const otherEventDays = [...(event.eventDays ?? [])];
        if (eventDayIndex >= 0) {
            otherEventDays.splice(eventDayIndex, 1);
        }

        const duplicateEventsByDate = otherEventDays.filter(
            (eventDayInSchedule) =>
                new Date(eventDayInSchedule.eventDate()).toDateString() ===
                new Date(date).toDateString()
        );

        if (duplicateEventsByDate != null && duplicateEventsByDate.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleEventDayReset = () => {
        setCurrentEventDayIndex(-1);
        setEventDay(undefined!);
    };

    const onHasAllDaySessionsToggle = async (): Promise<void> => {
        const newHasAllDaySessions = !event.hasAllDaySessions;

        const eventDaysWithoutSessions = event.eventDays?.map((ed) => {
            while ((ed.eventSessions?.length ?? 0) > 0) {
                ed.eventSessions?.pop();
            }
            return ed;
        });

        const DEFAULT_TIME_ZONE = "America/Detroit";

        const eventChanges: Partial<Event> = {
            hasAllDaySessions: newHasAllDaySessions,
            eventDays: eventDaysWithoutSessions,
            timeZone: newHasAllDaySessions ? DEFAULT_TIME_ZONE : undefined, // Default
        };

        if (event.isDraft()) {
            event.updateAndSave(eventChanges);
        } else {
            event.updateActiveRecord(eventChanges);
        }
    };

    const handleEventDayEdit = (index?: number) => {
        setCurrentEventDayIndex(index ?? -1);
    };

    const handleEventDaySave = (index: number, eventDay: EventDayRecord) => {
        const updatedEventDays = [...(event.eventDays ?? [])];

        if (index < 0) {
            updatedEventDays.push(eventDay);
        } else {
            updatedEventDays[index] = eventDay;
        }

        if (event.isDraft()) {
            event.updateAndSave({ eventDays: updatedEventDays });
        } else {
            event.updateActiveRecord({ eventDays: updatedEventDays });
        }

        setEventDay(undefined!);
        handleEventDayReset();
    };

    useEffect(() => {
        if (editingExistingDay) {
            setEventDay(event?.eventDays?.[currentEventDayIndex]!);
        }
    }, [currentEventDayIndex, editingExistingDay, event, event?.eventDays, eventDay]);

    const handleTimeZoneChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        event.updateActiveRecord({ timeZone: e.target.value });
    };

    const handleBlur = (): void => {
        if (event.isDraft()) {
            event.save();
        }
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <EventSchedulePageHeader
                    eventId={event.id ?? 0}
                    exceptionGranted={event.eventScheduleException?.granted ?? false}
                    productSelected={productSelected}
                    productCEUs={event.getRecommendedCEUs() ?? 0}
                    maxCEUs={event.getMaxCEUsPermitted() ?? 0}
                />
                <div className={`${CSS_CLASS_NAME}__all-day-toggle-banner`}>
                    <Card tight={true}>
                        <div className={`${CSS_CLASS_NAME}__all-day-toggle`}>
                            <Paragraph size={ParagraphSize.Small}>
                                {t("useThisToggleToSettoAllDAyWhenSessionTimesAreUnknown")}
                            </Paragraph>
                            <ToggleLabel
                                disabled={event.productId == null}
                                checked={event.hasAllDaySessions}
                                id="event-all-day-sessions"
                                label={t("allDay")}
                                onToggle={
                                    event.hasAllDaySessions
                                        ? onHasAllDaySessionsToggle
                                        : () => setShowConfirmationModal(true)
                                }
                            />
                        </div>
                    </Card>
                </div>
            </div>

            {!event.hasAllDaySessions && (
                <div className={`${CSS_CLASS_NAME}__time-zone`}>
                    <FormSelect
                        ariaLabelledBy={t("hostTimeZone")}
                        cssClassName={`${CSS_CLASS_NAME}__time-zone-select`}
                        disabled={event.productId == null}
                        formFieldName={t("hostTimeZone")}
                        id={"timeZoneSelect"}
                        label={t("hostTimeZone")}
                        required={true}
                        onBlur={handleBlur}
                        placeholder={t("selectTimeZone")}
                        onChange={handleTimeZoneChange}
                        options={timeZones}
                        value={event.timeZone}
                    />
                </div>
            )}

            <div className={`${CSS_CLASS_NAME}__content`}>
                <div className={`${CSS_CLASS_NAME}__content__event-day-manager`}>
                    {event?.id != null && (
                        <EventDayManager
                            initialEventDay={eventDay}
                            eventId={event.id}
                            eventDayIndex={currentEventDayIndex}
                            nextAvailableDate={nextAvailableDate}
                            onEventDaySave={handleEventDaySave}
                            onReset={() => {
                                handleEventDayReset();
                            }}
                            readonly={!productSelected}
                            isDateAvailable={isEventDayAvailable}
                            includeSessions={!event.hasAllDaySessions}
                        />
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__content__schedule-sidebar`}>
                    <Card>
                        <EventScheduleSummary
                            event={event}
                            onEventDayEditRequest={handleEventDayEdit}
                        />
                    </Card>
                </div>
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={cancelConfirmationActions}
                modalStyle={"-inverted"}>
                <span>
                    {t("areYouSureYouWouldLikeToChangeYourScheduleToAllDayEvents")}
                    <br />
                    <br />
                    {t("byDoingSoEventTimesWillBeRemoved")}
                </span>
            </Modal>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventSchedulePage };

// #endregion Exports
