import { useCallback } from "react";
import { Event } from "models/interfaces/events/event";
import { EventInstructorCard } from "components/events/event-instructor/event-instructor-card-selection/event-instructor-card/event-instructor-card";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./selected-instructor-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SelectedInstructorManagerProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "selected-instructor-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SelectedInstructorManager: React.FC<SelectedInstructorManagerProps> = (
    props: SelectedInstructorManagerProps
) => {
    const updateEvent = useCallback(
        (values: Partial<Event>): void => {
            if (props.event.isDraft()) {
                props.event.updateAndSave(values);
            } else {
                props.event.updateActiveRecord(values);
            }
        },
        [props.event]
    );

    const handleDeselectInstructor = useCallback((): void => {
        updateEvent({
            instructorId: undefined,
            instructor: undefined,
            instructorConfirmed: false,
        });
    }, [updateEvent]);

    const handleSetInstructorConfirmed = useCallback(
        (checked: boolean): void => {
            updateEvent({
                instructorConfirmed: checked,
            });
        },
        [updateEvent]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__instructor-card`}>
                {(props.event.instructor === undefined || props.event.instructor === null) && (
                    <div className={`${CSS_CLASS_NAME}__no-instructor-selected-container`}>
                        <div className={`${CSS_CLASS_NAME}__no-instructor-selected`}>
                            <Paragraph style={ParagraphStyle.Light}>
                                {t("selectedInstructorWillAppearHere")}
                            </Paragraph>
                        </div>
                    </div>
                )}
                {props.event.instructor !== undefined && props.event.instructor !== null && (
                    <EventInstructorCard
                        selectableInstructor={{
                            instructor: props.event.instructor!,
                            isSelected: true,
                        }}
                        handleAdd={handleDeselectInstructor}
                        handleRemove={handleDeselectInstructor}
                    />
                )}
            </div>

            <FormCheckboxInput
                disabled={props.event.instructor === undefined || props.event.instructor === null}
                checked={props.event.instructorConfirmed}
                formFieldName="instructorConfirmedCheckbox"
                id="instructorConfirmedCheckbox"
                label={t("iHaveConfirmedThisInstructorsAvailability")}
                onChange={handleSetInstructorConfirmed}
                type={InputTypes.Checkbox}
                value={props.event.instructorConfirmed}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SelectedInstructorManager };

// #endregion Exports
