import { ServiceFactory } from "andculturecode-javascript-react";
import EmulationTokenRecord from "models/view-models/emulation/emulation-token-record";

const baseEndpoint = "/admin/support/tokens";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = EmulationTokenRecord;

const EmulationService = {
    create: ServiceFactory.create(resourceType, baseEndpoint),

    delete: ServiceFactory.delete(resourceEndpoint),
};

export { EmulationService };
