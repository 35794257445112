import React from "react";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { InputTypes } from "components/form/enumerations/input-types";
import "./event-schedule-exception-granted.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleExceptionGrantedProps {
    eventScheduleException: EventScheduleExceptionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-exception-granted";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleExceptionGranted: React.FC<EventScheduleExceptionGrantedProps> = ({
    eventScheduleException,
}) => {
    const approvedByText = t("approvedEventScheduleExceptionGetLastUpdatedText", {
        eventScheduleExceptionGetLastUpdatedText: eventScheduleException.getLastUpdatedText(),
    });
    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <p className={`${CSS_CLASS_NAME}__exception-description`}>
                    {t(
                        "iConfirmTheExceptionReasonIsValidAndorThatIHaveSpokenWithTheRequestorAndApproveThisException"
                    )}
                </p>

                <FormCheckboxInput
                    disabled={true}
                    checked={eventScheduleException.granted}
                    formFieldName="exceptionGrantedCheckbox"
                    id="exceptionGrantedCheckbox"
                    label={approvedByText}
                    onChange={() => {}}
                    type={InputTypes.Checkbox}
                    value={eventScheduleException.granted}
                />

                <FormTextArea
                    disabled={true}
                    id="exceptionReason"
                    formFieldName="exceptionReason"
                    label={t("ReasonForException")}
                    value={eventScheduleException.reason}
                    onChange={() => {}}
                    placeholder={t("ReasonForException")}
                    rows={5}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionGranted };

// #endregion Exports
