import { AenProviderAdminAuthorizationRules } from "constants/authorization/aen-provider-admin-authorization-rules";
import { InstructorAuthorizationRules } from "constants/authorization/instructor-authorization-rules";
import { LearnerAuthorizationRules } from "constants/authorization/learner-authorization-rules";
import { NfpaAdministratorAuthorizationRules } from "constants/authorization/nfpa-administrator-authorization-rules";
import { NfpaOperatorAuthorizationRules } from "constants/authorization/nfpa-operator-authorization-rules";
import { ClientAdminAuthorizationRules } from "constants/authorization/client-admin-authorization-rules";
import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { NfpaSupportAuthorizationRules } from "constants/authorization/nfpa-support-authorization-rules";
import { ReviewerAuthorizationRules } from "constants/authorization/reviewer-authorization-rules";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum RoleType {
    AenAdministrator = 2,
    Instructor = 3,
    Learner = 4,
    NfpaAdministrator = 0,
    NfpaOperator = 1,
    ClientAdmin = 5,
    NfpaSupport = 6,
    Reviewer = 7,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const RoleTypeAuthorizationRulesMap: Record<RoleType, RoleAuthorizationRules> = {
    [RoleType.AenAdministrator]: AenProviderAdminAuthorizationRules,
    [RoleType.ClientAdmin]: ClientAdminAuthorizationRules,
    [RoleType.Instructor]: InstructorAuthorizationRules,
    [RoleType.Learner]: LearnerAuthorizationRules,
    [RoleType.NfpaAdministrator]: NfpaAdministratorAuthorizationRules,
    [RoleType.NfpaOperator]: NfpaOperatorAuthorizationRules,
    [RoleType.NfpaSupport]: NfpaSupportAuthorizationRules,
    [RoleType.Reviewer]: ReviewerAuthorizationRules,
};

const RoleTypeDisplayNames: Record<RoleType, TranslatedCopy> = {
    [RoleType.AenAdministrator]: "enProviderAdmin",
    [RoleType.ClientAdmin]: "clientAdmin",
    [RoleType.Instructor]: "instructor",
    [RoleType.Learner]: "learner",
    [RoleType.NfpaAdministrator]: "nfpaAdmin",
    [RoleType.NfpaOperator]: "nfpaOperations",
    [RoleType.NfpaSupport]: "nfpaSupport",
    [RoleType.Reviewer]: "reviewer",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

/**
 * Array to group admin-associated roletypes to avoid duplication across the app
 */
const NfpaRoleTypes: RoleType[] = [
    RoleType.NfpaAdministrator,
    RoleType.NfpaOperator,
    RoleType.NfpaSupport,
];

export { NfpaRoleTypes, RoleType, RoleTypeAuthorizationRulesMap, RoleTypeDisplayNames };

// #endregion Exports
