import { ContractRecord } from "models/view-models/contracts/contract-record";
import { ContractType } from "models/enumerations/contracts/contract-type";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "contracts";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof ContractRecord = ContractRecord;
const duplicateContractNumberEndpoint: string = `${baseEndpoint}/duplicatecontractnumber`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetContractPathParams {
    id: number;
}

export interface GetContractQueryParams {
    includeEvent?: boolean;
    includeOrganization?: boolean;
    includeProduct?: boolean;
}

export interface HasDuplicateContractNumberPathParams {}

export interface HasDuplicateContractNumberQueryParams {
    contractId: number;
    contractNumber: string;
}
export interface ListContractsQueryParams {
    eventId?: number;
    organizationIds?: number[];
    contractType?: ContractType;
    includeEnrollments?: boolean;
    includeProduct?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

export interface UpdateContractPathParams {
    id: number;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ContractService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreateOnlineLearning: ServiceHookFactory.useCreate(
        resourceType,
        baseEndpoint + "/onlineLearning"
    ),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        ContractRecord,
        GetContractPathParams,
        GetContractQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ContractRecord, ListContractsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<ContractRecord, UpdateContractPathParams>(
        resourceType,
        resourceEndpoint
    ),
    /**
     * Custom hook to find duplicate contract numbers
     */
    useHasDuplicateContractNumber: ServiceHookFactory.useGet<
        Boolean,
        HasDuplicateContractNumberPathParams,
        HasDuplicateContractNumberQueryParams
    >(Boolean, duplicateContractNumberEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractService };

// #endregion Exports
