import { Record } from "immutable";
import { StringUtils } from "andculturecode-javascript-core";
import { Role } from "models/interfaces/roles/role";
import { RoleType, RoleTypeDisplayNames } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Role = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    description: undefined,
    id: 0,
    name: undefined,
    roleType: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class RoleRecord extends Record(defaultValues) implements Role {
    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Role) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }
    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Accessors
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns the role name if it has a value.
     *
     * @default ""
     * @returns {string}
     * @memberof RoleRecord
     */
    get name(): string {
        if (!this.hastype()) {
            return "";
        }

        return t(RoleTypeDisplayNames[this.roleType]);
    }

    // #endregion Public Accessors

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Returns whether or not this RoleRecord has a non-null, non-whitespace name
     *
     * @returns {boolean}
     * @memberof RoleRecord
     */
    public hasName(): boolean {
        return StringUtils.hasValue(this.name);
    }

    public hastype(): this is Role & { roleType: RoleType } {
        return this.roleType !== undefined;
    }

    /**
     * Returns whether or not this RoleRecord represents a particular Role
     *
     * @param {RoleType} role The Role to check against
     * @returns {boolean} Whether or not this RoleRecord represents the provided Role
     * @memberof RoleRecord
     */
    public is(role: RoleType): boolean {
        return this.roleType === role;
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<Role>} values
     * @returns {RoleRecord}
     * @memberof RoleRecord
     */
    public with(values: Partial<Role>): RoleRecord {
        return new RoleRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RoleRecord };

// #endregion Exports
