import { EventChangeLogRecord } from "models/view-models/events/event-change-log-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { EventChangeLogType } from "models/enumerations/events/event-change-log-type";
import { RouteUtils, ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import axios from "axios";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "events";
const resourceType: typeof EventChangeLogRecord = EventChangeLogRecord;
const resourceEndpoint = `${baseEndpoint}/:eventId/changelogs`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type BulkCreateChangeLogService = (
    pathParams: ListEventChangeLogsPathParams,
    queryParams: BulkCreateChangeLogParams
) => Promise<ServiceResponse<EventChangeLogRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListEventChangeLogsQueryParams {}

export interface ListEventChangeLogsPathParams {
    eventId: number;
}

export interface BulkCreateChangeLogParams {
    changeTypes: EventChangeLogType[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventChangeLogService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useBulkCreate: (): { bulkCreate: BulkCreateChangeLogService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: BulkCreateChangeLogService = (
            pathParams: ListEventChangeLogsPathParams,
            queryParams: BulkCreateChangeLogParams
        ): Promise<ServiceResponse<EventChangeLogRecord>> => {
            const endpointWithIdReplaced = RouteUtils.replacePathParams(resourceEndpoint, {
                eventId: pathParams.eventId.toString(),
            });
            return axios
                .post(endpointWithIdReplaced, queryParams)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function bulkCreate(
            pathParams: ListEventChangeLogsPathParams,
            queryParams: BulkCreateChangeLogParams
        ): Promise<ServiceResponse<EventChangeLogRecord>> {
            return cancellablePromise(serviceCreate(pathParams, queryParams)) as Promise<
                ServiceResponse<EventChangeLogRecord>
            >;
        }

        return { bulkCreate: useCallback(bulkCreate, [cancellablePromise]) };
    },
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EventChangeLogRecord,
        ListEventChangeLogsPathParams,
        ListEventChangeLogsQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChangeLogService };

// #endregion Exports
