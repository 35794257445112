import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./product-activation-achievements-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationAchievementsSectionProps {
    product: ProductRecord;
    productVersion: ProductVersionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-achievements-section";
const yesNoRadio: TranslatedCopy[] = ["no", "yes"];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationAchievementsSection: React.FC<ProductActivationAchievementsSectionProps> = ({
    product,
    productVersion,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <Heading
                cssClassName={`${CSS_CLASS_NAME}__title`}
                priority={HeadingPriority.H5}
                size={HeadingSize.XSmall}>
                {t("achievements")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__sections`}
                    priority={HeadingPriority.H6}
                    size={HeadingSize.XXSmall}>
                    {t("ceus")}
                </Heading>
                <Paragraph style={ParagraphStyle.Label}>{t("creditHours")}</Paragraph>
                <Paragraph>{productVersion.creditHours}</Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__sections`}
                    priority={HeadingPriority.H6}
                    size={HeadingSize.XXSmall}>
                    {t("badges")}
                </Heading>
                <Paragraph size={ParagraphSize.Small}>{t("doYouWantToAssociateABadge")}</Paragraph>
                <div className="radio-buttons">
                    {yesNoRadio.map((label, index) => (
                        <RadioButton
                            key={`yesNoBadgeRadio-${index}`}
                            checked={
                                index === 0 ? !productVersion.hasBadge : productVersion.hasBadge
                            }
                            disabled
                            formFieldName={`yesNoBadgeRadio-${index}`}
                            groupName={`yesNoBadgeRadio-${index}`}
                            id={`yesNoRadio-${index}`}
                            label={t(label)}
                            labelPosition={RadioButtonLabelPosition.Right}
                            value={index}
                        />
                    ))}
                </div>
                {productVersion.hasBadge && (
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("template")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={`${productVersion.id}${productVersion.badgeTemplateId}`}>
                                <td>{productVersion.badgeName}</td>
                            </tr>
                        </tbody>
                    </DataTable>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__sections`}
                    priority={HeadingPriority.H6}
                    size={HeadingSize.XXSmall}>
                    {t("certificateExpiration")}
                </Heading>
                <Paragraph size={ParagraphSize.Small}>
                    {t("shouldThisCertificateHaveAnExpirationDate")}
                </Paragraph>
                <div className="radio-buttons">
                    {yesNoRadio.map((label, index) => (
                        <RadioButton
                            key={`yesNoCertExpRadio-${index}`}
                            checked={
                                index === 0
                                    ? !productVersion.hasCertificateDuration
                                    : productVersion.hasCertificateDuration
                            }
                            disabled
                            formFieldName={`yesNoCertExpRadio-${index}`}
                            groupName={`yesNoCertExpRadio-${index}`}
                            id={`yesNoCertExpRadio-${index}`}
                            label={t(label)}
                            labelPosition={RadioButtonLabelPosition.Right}
                            value={index}
                        />
                    ))}
                </div>
                {productVersion.hasCertificateDuration && (
                    <div className={`${CSS_CLASS_NAME}__expiration-duration`}>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("expirationDuration")}
                        </Paragraph>
                        <Paragraph>{productVersion.certificateDuration}</Paragraph>
                    </div>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationAchievementsSection };

// #endregion Exports
