import ActionLinkCardList from "components/action-card/action-card-list/action-link-card-list";
import React, { useMemo } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActionLinkCardProps } from "components/action-card/action-link-card";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EmptyText } from "components/empty-text/empty-text";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./instructor-enh-required-trainings-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorENHRequiredTrainingsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-enh-required-trainings-page";

const TRAIN_THE_TRAINER = [TrainingType.TrainTheTrainer];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorENHRequiredTrainingsPage: React.FC<InstructorENHRequiredTrainingsPageProps> =
    validatePageAccess(AccessControlKeys.InstructorENHRequiredTrainingsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { record: globalState } = useGlobalState();
        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        const { enrollments } = useUserEnrollments({
            userId: userId,
            enrollmentActiveStatus: EnrollmentActiveStatus.Active,
            trainingTypes: TRAIN_THE_TRAINER,
            includeRegistrations: true,
            educationNetworkOnly: true,
        });

        const requiredTrainings: ActionLinkCardProps[] = useMemo(
            () =>
                enrollments.map((enrollment) => {
                    return {
                        completionPercentage: enrollment.percentComplete,
                        description: enrollment.product?.name ?? "",
                        icon: Icons.Products,
                        topics: enrollment.productVersion?.getProductTopics(),
                        trainingButtonLocked: false,
                        trainingButtonPath: RouteUtils.localizePath(
                            RouteUtils.replacePathParams(sitemap.instructor.aenTraining.details, {
                                productId: enrollment.productId,
                                enrollmentId: enrollment.id,
                            })
                        ),
                        trainingButtonText: enrollment.registrations?.some(
                            (r) => r.userId === userId
                        )
                            ? "resumeTraining"
                            : "viewTraining",
                        type: enrollment.product?.getTrainingTypeWithSpaces() ?? "",
                    };
                }),
            [enrollments, userId]
        );

        return (
            <>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("requiredTrainings")}
                        </Heading>
                    </div>
                    {CollectionUtils.isNotEmpty(enrollments) ? (
                        <div className={`${CSS_CLASS_NAME}__section`}>
                            <ActionLinkCardList links={requiredTrainings} />
                        </div>
                    ) : (
                        <EmptyText table>{t("youHaveNoRequiredTrainings")}</EmptyText>
                    )}
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorENHRequiredTrainingsPage };

// #endregion Exports
