// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum AenApplicationType {
    Provider = 0,
    Instructor = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationType };

// #endregion Exports
