import React from "react";
import { DateUtils } from "utilities/date-utils";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import "./event-session-card.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventSessionCardProps {
    sessionNumber: number;
    eventSession: EventSessionRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-session-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventSessionCard: React.FC<EventSessionCardProps> = (
    props: EventSessionCardProps
): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__session-details`}>
                <p className={`${CSS_CLASS_NAME}__session-name`}>
                    {t("sessionPropsSessionNumberToString", {
                        propsSessionNumberToString: props.sessionNumber.toString(),
                    })}
                </p>
                <p className={`${CSS_CLASS_NAME}__session-time`}>
                    {`${DateUtils.formatDateCustom(
                        props.eventSession.startTime().toString(),
                        "h:mm A"
                    )} - ${DateUtils.formatDateCustom(
                        props.eventSession.endTime().toString(),
                        "h:mm A"
                    )}`}
                </p>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventSessionCard };

// #endregion Exports
