import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "organizations";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof OrganizationRecord = OrganizationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetOrganizationPathParams {
    id: number;
}

export interface GetOrganizationQueryParams {}

export interface ListOrganizationsQueryParams {
    searchText?: string;
    nfpaId?: string;
    contractIds?: number[];
    skip?: number;
    take?: number;
}

export interface UpdateOrganizationPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const OrganizationService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        OrganizationRecord,
        GetOrganizationPathParams,
        GetOrganizationQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<OrganizationRecord, ListOrganizationsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<OrganizationRecord, UpdateOrganizationPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OrganizationService };

// #endregion Exports
