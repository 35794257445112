import axios from "axios";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { UnitRecord } from "models/view-models/units/unit-record";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "units";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof UnitRecord = UnitRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type CreateUnitService = (params: CreateUnitParams) => Promise<ServiceResponse<UnitRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CreateUnitParams {
    completeCoursesInOrder: boolean;
    completeUnitBeforeMovingForward: boolean;
    description?: string;
    name?: string;
    optional: boolean;
    productId: number;
    productVersionId: number;
    sortOrder?: number;
}
export interface GetUnitPathParams {
    id: number;
}

export interface GetUnitQueryParams {}

export interface ListUnitsQueryParams {
    includeUnitCourses?: boolean;
    productId?: number;
    productVersionId?: number;
}

export interface UpdateUnitPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UnitService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateUnitService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateUnitService = (
            params: CreateUnitParams
        ): Promise<ServiceResponse<UnitRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(params: CreateUnitParams): Promise<ServiceResponse<UnitRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<UnitRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<UnitRecord, GetUnitPathParams, GetUnitQueryParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<UnitRecord, ListUnitsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<UnitRecord, UpdateUnitPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitService };

// #endregion Exports
