// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

export enum InputTypes {
    Button = "button",
    Checkbox = "checkbox",
    Email = "email",
    Hidden = "hidden",
    Number = "number",
    Password = "password",
    Phone = "tel",
    Text = "text",
}

// #endregion Enum
