import FeatureFlags from "models/interfaces/feature-flags/feature-flags";
import { useLocation } from "react-router-dom";
import { FeatureFlagUtils } from "utilities/feature-flag-utils";
import { EnvironmentUtils } from "andculturecode-javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

export function useFeatureFlags(): Required<FeatureFlags> {
    const { search } = useLocation();
    const { record: globalState } = useGlobalState();

    const defaultFeatureFlags = globalState.getFeatureFlagsWithDefaults();

    const getUrlFeatureFlags = () => {
        if (!EnvironmentUtils.isDevelopment()) {
            return {};
        }

        return FeatureFlagUtils.fromUrl(search, Object.keys(defaultFeatureFlags));
    };

    return {
        ...defaultFeatureFlags,
        ...getUrlFeatureFlags(),
    };
}
