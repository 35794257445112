import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { OrganizationService } from "utilities/services/organizations/organization-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";

interface UseOrganizationsHook {
    organizations: OrganizationRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface UseOrganizationsHookOptions {
    searchText?: string;
    nfpaId?: string;
    skip?: number;
    take?: number;
    searchCriteriaReady?: boolean;
}

export function useOrganizations(props: UseOrganizationsHookOptions): UseOrganizationsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [organizations, setOrganizations] = useState<OrganizationRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listOrganizations } = OrganizationService.useList();
    const searchText = props.searchText;
    const skip = props.skip;
    const take = props.take;
    const searchCriteriaReady = props.searchCriteriaReady ?? true;
    const nfpaId = props.nfpaId;

    useEffect(() => {
        if (!searchCriteriaReady) {
            setOrganizations([]);
            return;
        }

        try {
            (async function getOrganizations() {
                const { result, resultObjects, rowCount } = await listOrganizations({
                    searchText,
                    skip,
                    take,
                    nfpaId,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setOrganizations(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningOrganizations"));
        }
    }, [listOrganizations, searchText, nfpaId, skip, take, searchCriteriaReady]);

    return {
        errors,
        isLoading,
        organizations,
        rowCount,
    };
}
