import { EventProviderSelectionList } from "components/events/event-provider-selection/event-provider-selection-modal/event-provider-selection-list/event-provider-selection-list";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SelectableProvider } from "models/interfaces/providers/selectable-provider";
import { useCallback, useEffect, useState } from "react";
import {
    ListProvidersQueryParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./event-manage-add-provider.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventManageAddProvidersProps {
    event: EventActiveRecord;
    handleAddProviderToQueue: (provider: ProviderRecord) => void;
    handleFetchProviders: (provider: ProviderRecord[]) => void;
    handleRemoveProviderFromQueue: (provider: ProviderRecord) => void;
    providers: ProviderRecord[];
    selectedProvider?: ProviderRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-manage-add-provider";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "searchByProviderName";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventManageAddProviders: React.FC<EventManageAddProvidersProps> = (
    props: EventManageAddProvidersProps
) => {
    const [searchText, setSearchText] = useState<string>("");
    const [selectableProviders, setSelectableProviders] = useState<SelectableProvider[]>([]);
    const { list: listProviders } = ProviderService.useList();
    const handleFetchProviders = props.handleFetchProviders;

    const filterProviders = useCallback(
        async (searchText: string) => {
            try {
                const listProvidersQueryParams: ListProvidersQueryParams = {
                    searchText: searchText,
                };

                const listFilteredProvidersResponse = await listProviders(listProvidersQueryParams);
                const listFilteredProvidersResults = listFilteredProvidersResponse?.results;
                const providers = listFilteredProvidersResponse?.resultObjects;
                if (
                    providers == null ||
                    listFilteredProvidersResults == null ||
                    listFilteredProvidersResults.hasErrors()
                ) {
                    throw new Error();
                }
                handleFetchProviders(providers);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProviders"));
                handleFetchProviders([]);
            }
        },
        [handleFetchProviders, listProviders]
    );

    const convertProviderToSelectableprovider = useCallback(
        (providers: ProviderRecord[]): SelectableProvider[] => {
            return providers.map(
                (p): SelectableProvider => ({
                    provider: p,
                    isSelected: props.selectedProvider?.id === p.id,
                })
            );
        },
        [props.selectedProvider?.id]
    );

    const handleSearchTextInputChange = (searchText: string) => {
        setSearchText(searchText);
    };

    useEffect(() => {
        const selectableProviders = convertProviderToSelectableprovider(props.providers);
        setSelectableProviders(selectableProviders);
    }, [convertProviderToSelectableprovider, props.providers, props.selectedProvider]);

    return (
        <div className={CSS_CLASS_NAME}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={filterProviders}
                id={"providerSearch"}
                placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                searchTextInputValue={searchText}
            />
            <EventProviderSelectionList
                selectableProviders={selectableProviders}
                handleAdd={props.handleAddProviderToQueue}
                handleRemove={props.handleRemoveProviderFromQueue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventManageAddProviders };

// #endregion Exports
