import { UserLoginRecord } from "models/view-models/users/user-login-record";
import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { AuthConfigSettingsRecord } from "models/view-models/authsettings/auth-settings";

export interface AuthQueryParams {}

export interface AuthPathParams {
    id?: number;
}

export interface ConfigSettingsPathParams {
    domain: string;
}

const baseEndpoint = "auth";
const resourceSettingsEndpoint = `${baseEndpoint}/getenvironmentsettingsforazure`;
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserLoginRecord;
const settingsReturnType = AuthConfigSettingsRecord;

const AuthService = {
    get: ServiceFactory.get<AuthQueryParams, AuthPathParams>(resourceType, resourceEndpoint),
    // useGetQuery: QueryHookFactory.useGetQuery<UserLoginRecord, AuthPathParams>(
    //     baseEndpoint,
    //     resourceType,
    //     resourceEndpoint
    // ),
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    useGet: ServiceHookFactory.useGet<UserLoginRecord, AuthPathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGetSettings: ServiceHookFactory.useGet<AuthConfigSettingsRecord, {}>(
        settingsReturnType,
        resourceSettingsEndpoint
    ),
};

export { AuthService };
