import { createContext, useContext } from "react";
import {
    NavigationRequest,
    NavigationRequestManager,
    RequestNavigationFunction,
    ResolveNavigationRequestFunction,
} from "utilities/hooks/navigation/use-navigation-requests";

// -------------------------------------------------------------------------------------------------
// #region Context
// -------------------------------------------------------------------------------------------------

const NavigationRequestContext = createContext<NavigationRequestManager>({
    listenForNavigationRequests: () => {},
    navigationRequestListeners: {},
    requestNavigation: () => {},
    resolveNavigationRequest: () => null,
});

// #endregion Context

// -------------------------------------------------------------------------------------------------
// #region Context Accessors
// -------------------------------------------------------------------------------------------------

const useListenForNavigationRequests = (
    listeningComponent: string,
    requestingComponents?: string[]
): NavigationRequest[] => {
    const context = useContext(NavigationRequestContext);
    context.listenForNavigationRequests(listeningComponent, requestingComponents);

    return context.navigationRequestListeners[listeningComponent];
};
const useRequestNavigation = (): RequestNavigationFunction =>
    useContext(NavigationRequestContext).requestNavigation;
const useResolveNavigationRequest = (): ResolveNavigationRequestFunction =>
    useContext(NavigationRequestContext).resolveNavigationRequest;

// #endregion Context Accessors

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    NavigationRequestContext,
    useListenForNavigationRequests,
    useRequestNavigation,
    useResolveNavigationRequest,
};

// #endregion Exports
