import "assets/scss/full-screen-transition.scss";
import * as React from "react";
import logo from "assets/images/nfpa-logo-white.svg";
import { t } from "utilities/localization/t";

export interface FullScreenTranistionProps {
    transitionText: string;
}

const CSS_BASE_CLASS_NAME = "c-full-screen-transition";

const FullScreenTransition: React.FunctionComponent<FullScreenTranistionProps> = (
    props: FullScreenTranistionProps
) => {
    return (
        <div className={CSS_BASE_CLASS_NAME}>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                <img src={logo} alt={t("nationalFireProtectionAssociation")} />
                <span className={`${CSS_BASE_CLASS_NAME}__content__loader`}></span>
                <span className={`${CSS_BASE_CLASS_NAME}__content__transition-text`}>
                    {props.transitionText}
                </span>
            </div>
        </div>
    );
};

export { FullScreenTransition };
