import { CollectionUtils } from "utilities/collection-utils";
import { EventRecord } from "models/view-models/events/event-record";
import { GroupRecord } from "models/view-models/groups/group-record";
import { RecipientType } from "models/enumerations/emails/recipient-type";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EmailState {
    recipientType: RecipientType;
    events: EventRecord[];
    groups: GroupRecord[];
    subject: string;
    bodyHtml: string;
    bodyText: string;
}

interface BodyHtmlAndText {
    bodyHtml: string;
    bodyText: string;
}

export type ReducerActions =
    | { type: "updateRecipientType"; recipientType: RecipientType }
    | { type: "updateEvents"; events: EventRecord[] }
    | { type: "updateGroups"; groups: GroupRecord[] }
    | { type: "updateSubject"; subject: string }
    | { type: "updateBody"; bodyChanges: BodyHtmlAndText };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: EmailState = {
    recipientType: RecipientType.EventAttendees,
    events: [],
    groups: [],
    subject: "",
    bodyHtml: "",
    bodyText: "",
};

const validator =
    () =>
    (state: EmailState): boolean => {
        const { recipientType, events, groups, subject, bodyHtml, bodyText } = state;

        if (
            !StringUtils.hasValue(subject) ||
            !StringUtils.hasValue(bodyHtml) ||
            !StringUtils.hasValue(bodyText)
        ) {
            return false;
        }

        if (recipientType === RecipientType.EventAttendees && !CollectionUtils.hasValues(events)) {
            return false;
        }

        if (recipientType === RecipientType.Group && !CollectionUtils.hasValues(groups)) {
            return false;
        }

        return true;
    };

const reducer = (state: EmailState, action: ReducerActions): EmailState => {
    switch (action.type) {
        case "updateRecipientType":
            const { recipientType } = action;

            return {
                ...state,
                recipientType,
                events: [],
                groups: [],
            };

        case "updateEvents":
            const { events } = action;
            return { ...state, events };

        case "updateGroups":
            const { groups } = action;
            return { ...state, groups };

        case "updateSubject":
            const { subject } = action;
            return {
                ...state,
                subject,
            };

        case "updateBody":
            const { bodyChanges } = action;
            return { ...state, bodyHtml: bodyChanges.bodyHtml, bodyText: bodyChanges.bodyText };

        default:
            throw new Error();
    }
};

const initializer =
    () =>
    (initialState: EmailState): EmailState => {
        return initialState;
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validator };
export type { EmailState };

// #endregion Exports
