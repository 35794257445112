import React, { useMemo } from "react";
import { CourseContentChangeHistory } from "components/courses/course-content/course-content-change-history/course-content-change-history";
import { CourseContentForm } from "components/courses/course-content/course-content-form";
import { CourseZipPackageForm } from "components/courses/course-zip-package/course-zip-package-form";
import { CourseVersionArchiveRecord } from "models/view-models/courses/course-version-archive-record";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { t } from "utilities/localization/t";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { Icons } from "components/icons/constants/icons";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditCourseVersionMaterialsProps {
    courseVersion: CourseVersionRecord;
    courseVersionArchiveData: CourseVersionArchiveRecord[];
    deferSave: boolean;
    editMode: boolean;
    setCourseVersion: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditCourseVersionMaterials: React.FC<EditCourseVersionMaterialsProps> = ({
    courseVersion,
    courseVersionArchiveData,
    deferSave,
    editMode,
    setCourseVersion,
    setDirty,
}): JSX.Element => {
    const scormPackage = useMemo(
        () => courseVersion.scormPackage ?? new ScormPackageRecord(),
        [courseVersion]
    );
    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <CourseZipPackageForm
                    inEditVersionMode={editMode}
                    courseVersion={courseVersion}
                    setCourseVersion={setCourseVersion}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("zipPackage"),
                iconType: scormPackage.isComplete(courseVersion.courseId!)
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
        {
            contents: (
                <CourseContentForm
                    deferSave={deferSave}
                    inEditVersionMode={editMode}
                    courseVersion={courseVersion}
                    setCourseVersion={setCourseVersion}
                    setDirty={setDirty}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("content"),
                iconType: courseVersion.contentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
        {
            contents: (
                <CourseContentChangeHistory courseVersionArchiveData={courseVersionArchiveData} />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("changeHistory"),
            },
        },
    ];

    return <TabbedContainer tabs={tabs} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditCourseVersionMaterials };

// #endregion Exports
