import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContentRecord } from "models/view-models/contents/content-record";
import { ContentService } from "utilities/services/contents/content-service";
import { CourseContentList } from "components/courses/course-content/course-content-list/course-content-list";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import {
    CourseContentService,
    CreateCourseContentParams,
} from "utilities/services/courses/course-content-service";
import { CourseCreateContentModal } from "components/courses/course-content/course-create-content-modal/course-create-content-modal";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    CourseVersionService,
    UpdateCourseVersionPathParams,
} from "utilities/services/courses/course-version-service";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/contexts/use-course-context";
import { useParams } from "react-router-dom";
import "./course-content-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseContentManagerProps {
    courseVersion: CourseVersionRecord;
    deferSave: boolean;
    inCreateVersionMode?: boolean;
    inEditVersionMode?: boolean;
    setCourseVersion: Dispatch<SetStateAction<CourseVersionRecord>>;
    setDirty?: Dispatch<SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-content-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseContentManager: React.FC<CourseContentManagerProps> = ({
    courseVersion,
    deferSave,
    inCreateVersionMode,
    inEditVersionMode,
    setCourseVersion,
    setDirty,
}) => {
    const { versionId } = useParams();
    const courseVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const [readOnly, setReadOnly] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { record: course } = useCourse();
    const { create: apiContentCreate } = ContentService.useCreate();
    const { create: apiCourseContentCreate } = CourseContentService.useCreate();
    const { update: updateCourseVersion } = CourseVersionService.useUpdate();
    const { list: listCourseContents } = CourseContentService.useList();

    useEffect(() => {
        if (
            (course.status === ActiveStatus.Active && !inEditVersionMode) ||
            course.status === ActiveStatus.Archived ||
            course.status === ActiveStatus.Inactive
        ) {
            setReadOnly(true);
        }

        if (inEditVersionMode || inCreateVersionMode) {
            setReadOnly(false);
        }
    }, [course.status, inCreateVersionMode, inEditVersionMode]);

    const fetchCourseContents = useCallback(async (): Promise<void> => {
        if (deferSave) return;
        try {
            if (courseVersion.id === undefined) {
                throw new Error();
            }
            const listCourseContentsResponse = await listCourseContents({
                courseVersionId: courseVersion.id,
                includeContent: true,
            });

            if (
                listCourseContentsResponse?.resultObjects == null ||
                listCourseContentsResponse.results == null ||
                listCourseContentsResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setCourseVersion(
                courseVersion.with({
                    courseContents: listCourseContentsResponse.resultObjects,
                })
            );
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingCourseContents"));
        }
    }, [courseVersion, deferSave, listCourseContents, setCourseVersion]);

    const onHasNoContentToggle = useCallback(async (): Promise<boolean> => {
        setDirty && setDirty(true);
        try {
            if (courseVersion !== undefined) {
                const updatedCourseVersion = courseVersion.with({
                    hasNoContent: !courseVersion.hasNoContent,
                });

                if (!deferSave) {
                    const updateCourseVersionPathParms: UpdateCourseVersionPathParams = {
                        id: updatedCourseVersion.id!,
                    };

                    const updateCourseVersionResponse = await updateCourseVersion(
                        updatedCourseVersion,
                        updateCourseVersionPathParms
                    );
                    const updateResult = updateCourseVersionResponse?.result;

                    if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                        throw new Error();
                    }
                }

                setCourseVersion(
                    courseVersion.with({
                        hasNoContent: !courseVersion.hasNoContent,
                    })
                );
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueSettingTheThisCourseHasNoContentToggle"));
            return false;
        }
        return true;
    }, [courseVersion, deferSave, setCourseVersion, setDirty, updateCourseVersion]);

    const saveContent = useCallback(
        async (content: ContentRecord): Promise<boolean> => {
            if (courseVersion == null) {
                throw new Error();
            }
            if (
                courseVersion.hasNoContent &&
                (!courseVersion.courseContents || courseVersion.courseContents.length === 0)
            ) {
                await onHasNoContentToggle();
            }

            //Go ahead and save it to the Content table.
            let createContentResult;
            try {
                const createContentResponse = await apiContentCreate(content);
                createContentResult = createContentResponse?.result;

                if (createContentResult?.resultObject == null || createContentResult.hasErrors()) {
                    throw new Error();
                }

                if (deferSave) {
                    setDirty && setDirty(true);
                    const sortOrder = courseVersion.courseContents
                        ? courseVersion.courseContents.length + 1
                        : 1;
                    const updatedCourseContents = [
                        ...courseVersion.courseContents!,
                        new CourseContentRecord({
                            contentId: createContentResult.resultObject.id,
                            courseId: courseVersion.courseId,
                            courseVersionId: courseVersion.id,
                            id: 0,
                            sortOrder: sortOrder,
                            content: createContentResult.resultObject.with({ file: content.file }),
                        }),
                    ];

                    setCourseVersion(
                        courseVersion.with({
                            courseContents: updatedCourseContents,
                        })
                    );
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueCreatingCourseContent"));
                return false;
            }

            if (!deferSave) {
                let createCourseVersionContentParams: CreateCourseContentParams = {
                    courseId: courseVersion.courseId!,
                    courseVersionId: courseVersion.id!,
                    contentId: createContentResult.resultObject.id,
                    sortOrder: 1,
                };

                if (CollectionUtils.isNotEmpty(courseVersion.courseContents)) {
                    const nextSortOrder =
                        Math.max(...courseVersion.courseContents.map((uc) => uc.sortOrder!)) + 1;
                    createCourseVersionContentParams = {
                        courseId: courseVersion.courseId!,
                        courseVersionId: courseVersion.id!,
                        contentId: createContentResult.resultObject.id,
                        sortOrder: nextSortOrder,
                    };
                }

                try {
                    const createCourseContentResponse = await apiCourseContentCreate(
                        createCourseVersionContentParams
                    );
                    const createCourseContentResult = createCourseContentResponse?.result;

                    if (
                        createCourseContentResult?.resultObject == null ||
                        createCourseContentResult.hasErrors()
                    ) {
                        return false;
                    }
                } catch (err) {
                    ToastManager.error(`There was an issue creating course content ???.`);
                    return false;
                }

                fetchCourseContents();
            }
            return true;
        },
        [
            apiContentCreate,
            apiCourseContentCreate,
            courseVersion,
            deferSave,
            fetchCourseContents,
            onHasNoContentToggle,
            setCourseVersion,
            setDirty,
        ]
    );

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly: () => setReadOnly }}>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <HeaderActions
                        title={t("content")}
                        headingPriority={HeadingPriority.H2}
                        headingSize={HeadingSize.XSmall}>
                        <ToggleLabel
                            checked={courseVersion?.hasNoContent}
                            disabled={
                                (courseVersion?.courseContents &&
                                    courseVersion.courseContents.length > 0) ||
                                readOnly
                            }
                            id="course-no-content"
                            label={t("thisCourseHasNoContent")}
                            onToggle={onHasNoContentToggle}
                        />
                        <Button
                            disabled={courseVersion?.hasNoContent || readOnly}
                            onClick={() => {
                                setShowModal(true);
                            }}
                            style={ButtonStyle.Primary}
                            text={t("addContent")}
                        />
                    </HeaderActions>
                </div>
                {course.status === ActiveStatus.Active && courseVersion.id! > 0 && (
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t("changesWillBeAppliedToAllLearnersOnThisVersion")}
                    />
                )}
                <CourseCreateContentModal
                    courseId={courseVersion?.courseId}
                    courseVersionId={courseVersionId}
                    open={showModal}
                    setOpen={setShowModal}
                    handleSaveContent={saveContent}
                    deferSave={deferSave}
                />
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <CourseContentList
                        courseContents={courseVersion.courseContents!}
                        courseId={courseVersion.courseId}
                        fetchCourseContents={fetchCourseContents}
                        deferSave={deferSave}
                        courseVersion={courseVersion}
                        setCourseVersion={setCourseVersion}
                        setDirty={setDirty}
                    />
                </div>
            </div>
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentManager };

// #endregion Exports
