import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

enum ReportAllUserType {
    All = 1,
    NfpaAdmin = 2,
    NfpaOperations = 3,
    NfpaSupport = 4,
    ClientAdmin = 5,
    EnProvider = 6,
    Instructor = 7,
    Learner = 8,
    Unassigned = 9,
    Group = 10,
    Reviewer = 11,
}

const ReportAllUserTypeDisplayNames: Map<ReportAllUserType, TranslatedCopy> = {
    [ReportAllUserType.All]: "all",
    [ReportAllUserType.NfpaAdmin]: "nfpaAdmin",
    [ReportAllUserType.NfpaOperations]: "nfpaOperations",
    [ReportAllUserType.NfpaSupport]: "nfpaSupport",
    [ReportAllUserType.ClientAdmin]: "clientAdmin",
    [ReportAllUserType.EnProvider]: "provider",
    [ReportAllUserType.Instructor]: "instructor",
    [ReportAllUserType.Learner]: "learner",
    [ReportAllUserType.Unassigned]: "unassigned",
    [ReportAllUserType.Group]: "group",
    [ReportAllUserType.Reviewer]: "reviewer",
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportAllUserType, ReportAllUserTypeDisplayNames };

// #endregion Exports
