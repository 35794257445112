import { EvaluationAnswerTemplateRecord } from "models/view-models/evaluation-templates/evaluation-answer-template-record";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { EvaluationResponse } from "models/interfaces/evaluations/evaluation-response";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EvaluationResponse = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    evaluation: undefined,
    evaluationId: 0,
    evaluationAnswerTemplate: undefined,
    evaluationAnswerTemplateId: undefined,
    evaluationQuestionTemplate: undefined,
    evaluationQuestionTemplateId: 0,
    id: undefined,
    freeResponse: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EvaluationResponseRecord extends Record(defaultValues) implements EvaluationResponse {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EvaluationResponse>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.evaluation != null) {
            params.evaluation = RecordUtils.ensureRecord(params.evaluation, EvaluationRecord);
        }

        if (params.evaluationAnswerTemplate != null) {
            params.evaluationAnswerTemplate = RecordUtils.ensureRecord(
                params.evaluationAnswerTemplate,
                EvaluationAnswerTemplateRecord
            );
        }

        if (params.evaluationQuestionTemplate != null) {
            params.evaluationQuestionTemplate = RecordUtils.ensureRecord(
                params.evaluationQuestionTemplate,
                EvaluationQuestionTemplateRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EvaluationResponse properties, create a new EvaluationResponseRecord
     * object from this instance, overwriting the properties in the values parameter with values
     * provided.
     *
     * @param {Partial<EvaluationResponse>} values The values to overwrite on this instance.
     * @returns An EvaluationResponseRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EvaluationResponse>): EvaluationResponseRecord {
        return new EvaluationResponseRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationResponseRecord };

// #endregion Exports
