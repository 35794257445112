import { Button } from "components/buttons/button/button";
import { useAzure } from "pages/public/azure-login/use-azure";
import { t } from "utilities/localization/t";

interface LogInButtonProps {
    cssClassName?: string;
    buttonText?: string;
}

const LogInButton = (props: LogInButtonProps) => {
    const { cssClassName = "c-button -anchor", buttonText } = props;
    const { login } = useAzure();

    return (
        <Button onClick={login} cssClassName={cssClassName}>
            {buttonText ? buttonText : t("signIn")}
        </Button>
    );
};

export { LogInButton };
