import { StringUtils } from "andculturecode-javascript-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useLocation } from "react-router-dom";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import { Process } from "utilities/types/processes/process";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ProcessNavigator<T> {
    canGoToNext: boolean;
    canGoToPrevious: boolean;
    canGoToStep: (step: keyof T) => boolean;
    currentStep?: ProcessStep;
    goToNext: () => void;
    goToPrevious: () => void;
    goToStep: (step: keyof T) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useProcessNavigator = <T>(process: Process<T>): ProcessNavigator<T> => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const steps = useMemo((): Array<keyof T> => Object.keys(process) as Array<keyof T>, [process]);
    const stepIndexIsInvalid = useCallback(
        (stepIndex?: number): boolean => {
            return stepIndex == null || stepIndex < 0 || stepIndex >= steps.length;
        },
        [steps]
    );
    const getStep = useCallback(
        (stepIndex?: number): ProcessStep | undefined => {
            if (stepIndexIsInvalid(stepIndex)) {
                return;
            }

            return process[steps[stepIndex!]];
        },
        [process, stepIndexIsInvalid, steps]
    );

    const [currentStepIndex, setCurrentStepIndex] = useState<number>();
    const currentStepIndexIsInvalid = useMemo(
        (): boolean => stepIndexIsInvalid(currentStepIndex),
        [currentStepIndex, stepIndexIsInvalid]
    );
    const currentStep = useMemo(
        (): ProcessStep | undefined => getStep(currentStepIndex),
        [currentStepIndex, getStep]
    );
    const nextStepIndex = useMemo((): number | undefined => {
        if (currentStepIndexIsInvalid) {
            return;
        }

        return currentStepIndex! + 1;
    }, [currentStepIndex, currentStepIndexIsInvalid]);

    const previousStepIndex = useMemo((): number | undefined => {
        if (currentStepIndexIsInvalid) {
            return;
        }

        return currentStepIndex! - 1;
    }, [currentStepIndex, currentStepIndexIsInvalid]);

    const canExitCurrentStep = useMemo((): boolean => {
        if (currentStep == null) {
            return true;
        }

        return currentStep.canExit == null || currentStep.canExit();
    }, [currentStep]);

    const canEnterStep = useCallback(
        (stepIndex?: number): boolean => {
            const step = getStep(stepIndex);

            if (step == null) {
                return false;
            }

            return step.canEnter == null || step.canEnter();
        },
        [getStep]
    );

    const canGoTo = useCallback(
        (stepIndex?: number): boolean => {
            return canExitCurrentStep && canEnterStep(stepIndex);
        },
        [canEnterStep, canExitCurrentStep]
    );

    const canGoToNext = useMemo((): boolean => canGoTo(nextStepIndex), [canGoTo, nextStepIndex]);
    const canGoToPrevious = useMemo(
        (): boolean => canGoTo(previousStepIndex),
        [canGoTo, previousStepIndex]
    );
    const canGoToStep = useCallback(
        (step: keyof T): boolean => canGoTo(steps.indexOf(step)),
        [canGoTo, steps]
    );

    const goTo = useCallback(
        async (canGoTo: boolean, stepIndex?: number): Promise<void> => {
            if (!canGoTo) {
                return;
            }

            const step = getStep(stepIndex)!;

            if (currentStep?.onExit != null) {
                await currentStep.onExit(step);
            }

            navigate(step.path);
        },
        [currentStep, getStep, navigate]
    );

    const goToNext = useCallback(
        (): Promise<void> => goTo(canGoToNext, nextStepIndex),
        [canGoToNext, goTo, nextStepIndex]
    );
    const goToPrevious = useCallback(
        (): Promise<void> => goTo(canGoToPrevious, previousStepIndex),
        [canGoToPrevious, goTo, previousStepIndex]
    );
    const goToStep = useCallback(
        (step: keyof T): Promise<void> => goTo(canGoToStep(step), steps.indexOf(step)),

        [canGoToStep, goTo, steps]
    );

    useEffect((): void => {
        if (StringUtils.isEmpty(pathname)) {
            setCurrentStepIndex(undefined);
            return;
        }

        const step = steps.find((step: keyof T): boolean => {
            return process[step].path === pathname;
        });

        if (step == null) {
            setCurrentStepIndex(undefined);
            return;
        }

        setCurrentStepIndex(steps.indexOf(step));
    }, [pathname, process, steps]);

    useEffect((): void => {
        if (currentStep?.onEnter != null) {
            currentStep.onEnter();
        }
    }, [currentStep]);

    const hookContents = useMemo(
        (): ProcessNavigator<T> => ({
            canGoToNext: canGoToNext,
            canGoToPrevious: canGoToPrevious,
            canGoToStep: canGoToStep,
            currentStep: currentStep,
            goToNext: goToNext,
            goToPrevious: goToPrevious,
            goToStep: goToStep,
        }),
        [canGoToNext, canGoToPrevious, canGoToStep, currentStep, goToNext, goToPrevious, goToStep]
    );

    return hookContents;
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useProcessNavigator };

// #endregion Exports
