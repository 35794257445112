import { Anchor } from "components/typography/anchors/anchor/anchor";
import { RouteUtils } from "utilities/route-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useParams } from "react-router-dom";
import "./aen-application-sidebar.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface AenApplicationSidebarNavItem {
    path: string;
    text: TranslatedCopy | string;
}

interface AenApplicationSidebarProps {
    navItems: AenApplicationSidebarNavItem[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-application-sidebar";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AenApplicationSidebar = ({ navItems }: AenApplicationSidebarProps) => {
    const { id } = useParams();
    return (
        <nav className={CSS_CLASS_NAME} aria-label={t("sidebar")}>
            {navItems.map((navItem, index) => (
                <Anchor
                    key={`nav-item-${index}`}
                    path={RouteUtils.replacePathParams(navItem.path, {
                        id,
                    })}
                    text={navItem.text}
                    cssClassName={`${CSS_CLASS_NAME}__link ${
                        RouteUtils.assertCurrentUrl(navItem.path, {
                            id,
                        })
                            ? "-selected"
                            : ""
                    }`}
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AenApplicationSidebar };

// #endregion Exports
