import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductActivationAchievementsSection } from "components/products/product-activation-modal/product-activation-achievements-section/product-activation-achievements-section";
import { ProductActivationContentSection } from "components/products/product-activation-modal/product-activation-content-section/product-activation-content-section";
import { ProductActivationCoursesSection } from "components/products/product-activation-modal/product-activation-courses-section/product-activation-courses-section";
import { ProductActivationEvaluationSection } from "components/products/product-activation-modal/product-activation-evaluation-section/product-activation-evaluation-section";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { useProductVersionZero } from "utilities/contexts/use-product-version-zero-context";
import "./product-materials-changes-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductMaterialsChangesModalProps {
    fetchProductVersion: () => Promise<void>;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSaveMaterialChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
    showSaveMaterialChangesModal: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-materials-changes-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductMaterialsChangesModal: React.FC<ProductMaterialsChangesModalProps> = ({
    fetchProductVersion,
    setEditMode,
    setRefresh,
    setShowSaveMaterialChangesModal,
    showSaveMaterialChangesModal,
}): JSX.Element => {
    const { record: product } = useProduct();
    const { record: productVersionWithUpdates } = useProductVersion();
    const { setRecord: setProductVersionZero } = useProductVersionZero();
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");
    const { update } = ProductVersionService.useUpdate();
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowSaveMaterialChangesModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: StringUtils.isEmpty(changeLogDescription),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = () => {
        setShowSaveMaterialChangesModal(false);
        setEditMode(false);
        updateProductVersion();
    };

    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    const updateProductVersion = async (): Promise<boolean> => {
        try {
            var productVersion = productVersionWithUpdates.with({
                changeLogDescription: changeLogDescription,
            });

            const updateProductVersionPathParms: UpdateProductVersionPathParams = {
                id: productVersionWithUpdates.id!,
                changeLogDescription: changeLogDescription,
            };

            const updateCourseVersionResponse = await update(
                productVersion,
                updateProductVersionPathParms
            );

            const updateResult = updateCourseVersionResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            if (productVersion.versionId === 0) {
                setProductVersionZero(productVersion);
            }
            setChangeLogDescription("");
            setEditMode(false);
            setRefresh(true);
            fetchProductVersion();
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheProductVersion"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            actions={modalActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={showSaveMaterialChangesModal}
            onModalClose={() => setShowSaveMaterialChangesModal(false)}
            title={t("saveChangesSummary")}>
            <div>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                    priority={HeadingPriority.H5}>
                    {t("youAreEditingTheCurrentVersion")}
                </Heading>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading-description`}
                    size={ParagraphSize.XLarge}
                    style={ParagraphStyle.Light}>
                    {t("takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving")}
                </Paragraph>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__change-log`}
                    size={ParagraphSize.XLarge}>
                    {t("changeLog")}
                </Paragraph>
                {product.type === TrainingType.InstructorLedTraining && (
                    <>
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "contentBadgeAndCertificateExpirationChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        />
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "changesToRecommendedCEUsWillOnlyBeAppliedToNewAndUnpublishedEventsAsFinalEventCEUsAreDeterminedByTheIndividualEventsSchedule"
                            )}
                        />
                    </>
                )}
                {product.type === TrainingType.OnlineLearning && (
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t("changesWillBeAppliedToAllLearnersOnThisVersion")}
                    />
                )}
                <FormTextArea
                    ariaLabelledBy={t("describeYourChanges")}
                    cssClassName={`${CSS_CLASS_NAME}__text-area`}
                    formFieldName={t("describeYourChanges")}
                    id="describeYourChanges"
                    label={t("describeYourChanges")}
                    maxLength={1000}
                    onChange={handleChangeLogChange}
                    placeholder={t("describeYourChanges")}
                    required={true}
                    rows={6}
                    value={changeLogDescription}
                />
                <ProductActivationCoursesSection
                    product={product}
                    productVersion={productVersionWithUpdates}
                />
                <ProductActivationContentSection
                    product={product}
                    productContents={productVersionWithUpdates.productContents}
                    productVersion={productVersionWithUpdates}
                />
                <ProductActivationAchievementsSection
                    product={product}
                    productVersion={productVersionWithUpdates}
                />
                <ProductActivationEvaluationSection product={product} />
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductMaterialsChangesModal };

// #endregion Exports
