import React, { useCallback, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { CollectionUtils, ResultRecord } from "andculturecode-javascript-core";
import { FormSearchSelect } from "components/form/form-search-select/form-search-select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { NfpaRoleTypes, RoleType, RoleTypeDisplayNames } from "models/enumerations/users/role-type";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RoleRecord } from "models/view-models/roles/role-record";
import { SelectOption } from "components/form/inputs/select/select";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "utilities/string-utils";
import { TagItem, TagList } from "components/tags/tag-list/tag-list";
import { ToastManager } from "utilities/toast/toast-manager";
import { User } from "models/interfaces/users/user";
import { UserRecord } from "models/view-models/users/user-record";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { UserService } from "utilities/services/users/user-service";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useOrganizations } from "utilities/hooks/models/events/use-organizations";
import { useProviders } from "utilities/hooks/models/providers/use-providers";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useRoles } from "utilities/hooks/models/roles/use-roles";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { ToggleLabel, ToggleLabelDirection } from "components/toggle/toggle-label/toggle-label";
import { InstructorProfileService } from "utilities/services/instructors/instructor-profile-service";
import { RouteUtils } from "utilities/route-utils";
import {
    InstructorProfileStatus,
    InstructorProfileStatusDisplayNames,
} from "models/instructors/instructor-profile-status";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import "./user-new-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserNewPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-new-page";
const EMAIL_ALREADY_EXISTS_KEY = "UsersController.Error.InvalidCreateAlreadyExists";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserNewPage: React.FC<UserNewPageProps> = validatePageAccess(AccessControlKeys.UserNewPage)(
    (): JSX.Element => {
        useRedirectOnForbidden(sitemap.public.noAccess);
        const navigate = useNavigate();
        const { record: globalState } = useGlobalState();

        const { create: createUserApi } = UserService.useCreate();
        const { update: updateInstructorProfileApi } = InstructorProfileService.useUpdate();

        const [user, setUser] = useState<UserRecord>(new UserRecord());
        const [emailError, setEmailError] = useState<string>("");
        const [selectedRoleTypes, setSelectedRoleTypes] = useState<TagItem[]>([]);
        const [instructorProviderId, setInstructorProviderId] = useState<number>();
        const [excludeFromDirectory, setExcludeFromDirectory] = useState(false);
        const [instructorProfileStatus, setInstructorProfileStatus] =
            useState<InstructorProfileStatus>();

        const { organizations } = useOrganizations({});
        const { providers } = useProviders({ isActive: true });
        const { roles } = useRoles({});

        // Create a list of tags for each role type in roles
        const roleTags = useMemo((): TagItem[] => {
            // We also want a specific order to them.
            const nfpaRoles = roles.filter(
                (role: RoleRecord) =>
                    NfpaRoleTypes.some((roleType: RoleType) => role.roleType === roleType) &&
                    globalState.currentIdentity?.hasAccessToModifyRole(role.roleType)
            );

            const nonNfpaRoles = roles.filter(
                (role: RoleRecord) =>
                    !NfpaRoleTypes.some((roleType: RoleType) => role.roleType === roleType) &&
                    globalState.currentIdentity?.hasAccessToModifyRole(role.roleType)
            );

            // Put the NPFA roles first, then the rest.
            return [...nfpaRoles, ...nonNfpaRoles].map((role) => ({
                text: t(RoleTypeDisplayNames[role.roleType!]),
                value: role.id!.toString(),
            }));
        }, [globalState.currentIdentity, roles]);

        const organizationOptions: SelectOption[] = organizations.map(
            (o): SelectOption => ({
                text: o.name,
                value: o.id!.toString(),
            })
        );

        const providerOptions: SelectOption[] = providers.map(
            (p): SelectOption => ({
                text: p.name,
                value: p.id!.toString(),
            })
        );

        // -------------------------------------------------------------------------------------------------
        // #region Event Handlers
        // -------------------------------------------------------------------------------------------------

        const handleEmailValidation = (): void => {
            validateEmail(user?.email ?? "");
        };

        const handleTagClick = (selected: boolean, text: string, value: string) => {
            const updatedRoles = [...selectedRoleTypes];
            if (selected) {
                updatedRoles.push({
                    selected: selected,
                    text: text,
                    value: value,
                });
            } else {
                updatedRoles.splice(
                    updatedRoles.findIndex((role) => role.value === value),
                    1
                );
            }

            setSelectedRoleTypes(updatedRoles);

            if (!selected) {
                const roleTypeForRole = roles.find((role) => role.id === Number(value))?.roleType;
                switch (roleTypeForRole) {
                    case RoleType.ClientAdmin:
                        updateUser({ organizationId: undefined });
                        break;
                    case RoleType.AenAdministrator:
                        updateUser({ providerId: undefined });
                        break;
                    case RoleType.Instructor:
                        setInstructorProviderId(undefined);
                        setInstructorProfileStatus(undefined);
                        break;
                }
            }
        };

        const updateUser = (values: Partial<User>): void => {
            setUser(user.with(values));
        };

        const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            updateUser({ email: e.target.value });
        };

        const handleENProviderAdminChange = (value: string): void => {
            const option: SelectOption = providerOptions.find(
                (op: SelectOption): boolean => op.value === value
            ) ?? {
                text: "",
                value: "",
            };

            updateUser({ providerId: Number(option.value) });
        };

        const handleInstructorENProviderChange = (value: string): void => {
            const option: SelectOption = providerOptions.find(
                (op: SelectOption): boolean => op.value === value
            ) ?? {
                text: "",
                value: "",
            };

            setInstructorProviderId(
                StringUtils.hasValue(option.value) ? Number(option.value) : undefined
            );
        };

        const handleOrganizationChange = (value: string): void => {
            const option: SelectOption = organizationOptions.find(
                (op: SelectOption): boolean => op.value === value
            ) ?? {
                text: "",
                value: "",
            };

            updateUser({ organizationId: Number(option.value) });
        };

        const handleAddUser = async () => {
            const userRoles = selectedRoleTypes.map(
                (role) => new UserRoleRecord({ roleId: Number(role.value) })
            );

            const userWithRoles = user.with({ userRoles: userRoles });

            try {
                const createdUser = await createUser(userWithRoles);

                if (createdUser == null || createdUser.id == null) {
                    throw new Error();
                }

                ToastManager.success(t("userHasBeenAddedSuccessfully"));

                if (
                    userHasRole(RoleType.Instructor, userRoles) &&
                    instructorInformationRequiresUpdate()
                ) {
                    await updateInstructorProfileForUser(createdUser);
                }

                navigate(
                    RouteUtils.replacePathParams(sitemap.admin.userManagement.users.info.default, {
                        id: createdUser.id,
                    })
                );
            } catch (error) {
                if (typeof error === "string") {
                    const errorMessage = error === "" ? t("thereWasAnIssueCreatingTheUser") : error;
                    ToastManager.error(errorMessage);
                } else if (error instanceof ResultRecord) {
                    const { errors } = error;

                    if (errors?.some((error) => error.key === EMAIL_ALREADY_EXISTS_KEY)) {
                        setEmailError(t("anAccountWithThisEmailAlreadyExists"));
                    }
                }
                return false;
            }
        };

        const userHasRole = (roleType: RoleType, userRoles: UserRoleRecord[]): boolean => {
            const roleId = roles.find((role) => role.roleType === roleType)?.id;
            return userRoles.some((role) => role.roleId === roleId);
        };

        const instructorInformationRequiresUpdate = (): boolean => {
            return (
                excludeFromDirectory !== false ||
                instructorProviderId != null ||
                instructorProfileStatus != null
            );
        };

        // #endregion Event Handlers

        // -------------------------------------------------------------------------------------------------
        // #region API Calls
        // -------------------------------------------------------------------------------------------------

        const createUser = async (newUser: UserRecord): Promise<UserRecord | undefined> => {
            try {
                const createUserResponse = await createUserApi(newUser);
                const createUserResult = createUserResponse?.result;
                if (createUserResult?.resultObject == null || createUserResult.hasErrors()) {
                    throw new Error();
                }

                return createUserResult.resultObject as UserRecord;
            } catch (error) {
                const errorResult = error as ResultRecord<UserRecord>;
                ToastManager.error(
                    t("thereWasAnIssueCreatingTheUser") +
                        "\r\n" +
                        errorResult.listErrorMessages().join("\r\n")
                );
                return;
            }
        };

        const updateInstructorProfileForUser = async (newUser: UserRecord): Promise<boolean> => {
            try {
                const instructorProfile = newUser.instructorProfile;
                if (instructorProfile?.id == null) {
                    throw new Error();
                }

                const profileWithChanges = instructorProfile.with({
                    excludeFromAenDirectory: excludeFromDirectory,
                    providerId: instructorProviderId,
                    status: instructorProfileStatus,
                });

                if (
                    !(await updateInstructorProfileApi(profileWithChanges, {
                        id: profileWithChanges.id!,
                    }))
                ) {
                    throw new Error();
                }

                return true;
            } catch (error) {
                ToastManager.error(t("instructorRoleWasAddedButSavingInstructorInformationFailed"));
                return false;
            }
        };

        // #endregion API Calls

        // -------------------------------------------------------------------------------------------------
        // #region Validation
        // -------------------------------------------------------------------------------------------------

        const validateEmail = (email: string): void => {
            if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
                setEmailError(t("pleaseEnterAValidEmailAddress"));
            } else {
                setEmailError("");
            }
        };

        const userHasClientAdminRole = useMemo(() => {
            const clientAdminRoleId = roles.find(
                (role) => role.roleType === RoleType.ClientAdmin
            )?.id;
            return selectedRoleTypes.some((role) => role.value === clientAdminRoleId?.toString());
        }, [roles, selectedRoleTypes]);

        const userHasENProviderAdminRole = useMemo(() => {
            const enProviderAdminRoleId = roles.find(
                (role) => role.roleType === RoleType.AenAdministrator
            )?.id;
            return selectedRoleTypes.some(
                (role) => role.value === enProviderAdminRoleId?.toString()
            );
        }, [roles, selectedRoleTypes]);

        const userHasInstructorRole = useMemo(() => {
            const instructorRoleId = roles.find(
                (role) => role.roleType === RoleType.Instructor
            )?.id;
            return selectedRoleTypes.some((role) => role.value === instructorRoleId?.toString());
        }, [roles, selectedRoleTypes]);

        const validateClientAdminRequirements = useCallback(() => {
            return userHasClientAdminRole ? user.organizationId != null : true;
        }, [user.organizationId, userHasClientAdminRole]);

        const validateENProviderAdminRequirements = useCallback(() => {
            return userHasENProviderAdminRole ? user.providerId != null : true;
        }, [user.providerId, userHasENProviderAdminRole]);

        const validateInstructorRequirements = useCallback(() => {
            return userHasInstructorRole ? instructorProfileStatus != null : true;
        }, [userHasInstructorRole, instructorProfileStatus]);

        const userIsValid = useMemo(() => {
            return (
                StringUtils.isValidEmail(user.email) &&
                StringUtils.hasValue(user.firstName) &&
                StringUtils.hasValue(user.lastName) &&
                CollectionUtils.hasValues(selectedRoleTypes) &&
                validateClientAdminRequirements() &&
                validateENProviderAdminRequirements() &&
                validateInstructorRequirements()
            );
        }, [
            selectedRoleTypes,
            user.email,
            user.firstName,
            user.lastName,
            validateClientAdminRequirements,
            validateENProviderAdminRequirements,
            validateInstructorRequirements,
        ]);

        // #endregion Validation

        return (
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <h1>{t("newUser")}</h1>
                    <div className={`${CSS_CLASS_NAME}__roles`}>
                        <h2>{t("userRoles")}</h2>
                        <Paragraph style={ParagraphStyle.Light}>
                            {t("selectAllRolesThatWillApplyToThisUser")}
                        </Paragraph>
                        <TagList id="roles" items={roleTags} onTagSelected={handleTagClick} />
                    </div>
                    <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                        <h3>{t("basicInformation")}</h3>
                        <div className={`${CSS_CLASS_NAME}__section__grid`}>
                            <FormTextInput
                                ariaLabelledBy={t("email")}
                                errorMessage={emailError}
                                formFieldName="email"
                                id="email"
                                label={t("email")}
                                maxLength={100}
                                onBlur={handleEmailValidation}
                                onChange={handleEmailChange}
                                placeholder={t("enterEmail")}
                                required={true}
                                type={InputTypes.Email}
                                value={user.email}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("firstName")}
                                formFieldName="firstName"
                                id="firstName"
                                label={t("firstName")}
                                maxLength={150}
                                onChange={(e) => updateUser({ firstName: e.target.value })}
                                placeholder={t("enterFirstName")}
                                required={true}
                                value={user.firstName}
                            />
                            <FormTextInput
                                ariaLabelledBy={t("lastName")}
                                formFieldName="lastName"
                                id="lastName"
                                label={t("lastName")}
                                maxLength={150}
                                onChange={(e) => updateUser({ lastName: e.target.value })}
                                placeholder={t("enterLastName")}
                                required={true}
                                value={user.lastName}
                            />
                        </div>
                    </Card>
                    {userHasClientAdminRole && (
                        <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                            <h3>{t("clientAdmin")}</h3>
                            <div className={`${CSS_CLASS_NAME}__section__details`}>
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("associatedOrganization")}
                                </Paragraph>
                                <FormSearchSelect
                                    ariaLabelledBy={t("associateThisUserToAnExistingOrganization")}
                                    formFieldName="associatedOrganization"
                                    id="associatedOrganization"
                                    label={t("associateThisUserToAnExistingOrganization")}
                                    placeholder={t("searchByNameOrId")}
                                    required={true}
                                    defaultText={t("searchByNameOrId")}
                                    onChange={handleOrganizationChange}
                                    options={organizationOptions}
                                    selectedOption={{
                                        text:
                                            organizations.find((o) => o.id === user.organizationId)
                                                ?.name ?? "",
                                        value: user.organizationId?.toString() ?? "",
                                    }}
                                />
                                <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                                    {t(
                                        "organizationsNeedToBeAddedBeforeTheyCanBeAssociatedToAUser"
                                    )}
                                </Paragraph>
                            </div>
                        </Card>
                    )}
                    {userHasENProviderAdminRole && (
                        <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                            <h3>{t("enProviderAdmin")}</h3>
                            <div className={`${CSS_CLASS_NAME}__section__details`}>
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("associatedENProvider")}
                                </Paragraph>
                                <FormSearchSelect
                                    ariaLabelledBy={t("associateThisUserToAnExistingENProvider")}
                                    defaultText={t("searchByNameOrId")}
                                    formFieldName="associatedProvider"
                                    id="associatedProvider"
                                    label={t("associateThisUserToAnExistingENProvider")}
                                    onChange={handleENProviderAdminChange}
                                    options={providerOptions}
                                    placeholder={t("searchByNameOrId")}
                                    required={true}
                                    selectedOption={{
                                        text:
                                            providers.find((p) => p.id === user.providerId)?.name ??
                                            "",
                                        value: user.providerId?.toString() ?? "",
                                    }}
                                />
                                <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                                    {t("enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser")}
                                </Paragraph>
                            </div>
                        </Card>
                    )}
                    {userHasInstructorRole &&
                        globalState.currentIdentity?.hasAccessToModifyRole(RoleType.Instructor) && (
                            <Card stacked={true} cssClassName={`${CSS_CLASS_NAME}__section`}>
                                <h3>{t("instructor")}</h3>
                                <div className={`${CSS_CLASS_NAME}__section__details`}>
                                    <FormSelect
                                        ariaLabelledBy={t("instructorStatus")}
                                        formFieldName={t("instructorStatus")}
                                        id="instructorStatus"
                                        label={t("instructorStatus")}
                                        onChange={(e) =>
                                            setInstructorProfileStatus(
                                                Number(e.target.value) as InstructorProfileStatus
                                            )
                                        }
                                        options={EnumUtils.numericEnumToSelectOptions(
                                            InstructorProfileStatus,
                                            InstructorProfileStatusDisplayNames
                                        )}
                                        required={true}
                                        value={instructorProfileStatus?.toString()}
                                    />
                                    <br />
                                    <Paragraph size={ParagraphSize.Large}>
                                        {t("optionalAenProviderAssociationForInstructor")}
                                    </Paragraph>
                                    <Paragraph
                                        size={ParagraphSize.Default}
                                        style={ParagraphStyle.Light}>
                                        {t(
                                            "SelectAnAENProviderIfTheInstructorIsAssociatedWithAnExistingAENProviderEgTheInstructorIsAnEmployeeOfTheAENProviderOrganization"
                                        )}
                                    </Paragraph>
                                    <br />
                                    <Paragraph
                                        size={ParagraphSize.Default}
                                        style={ParagraphStyle.Light}
                                        emphasis={ParagraphEmphasis.Italic}>
                                        {t(
                                            "bySelectingAnAENProviderTheInstructorCanStillBeAssignedToEventsForTheAssociatedAENProviderEvenIfTheyAreExcludedFromTheAENProviderDirectoryAndSearch"
                                        )}
                                    </Paragraph>
                                    <FormSearchSelect
                                        ariaLabelledBy={t(
                                            "associateThisUserToAnExistingENProvider"
                                        )}
                                        defaultText={t("searchByNameOrId")}
                                        formFieldName="providerAssociation"
                                        id="providerAssociation"
                                        includeEmptyOption={true}
                                        label={t("associateThisUserToAnExistingENProvider")}
                                        onChange={handleInstructorENProviderChange}
                                        options={providerOptions}
                                        placeholder={t("searchByNameOrId")}
                                        required={false}
                                        selectedOption={{
                                            text:
                                                providers.find((p) => p.id === instructorProviderId)
                                                    ?.name ?? "",
                                            value: instructorProviderId?.toString() ?? "",
                                        }}
                                    />
                                    <Paragraph
                                        size={ParagraphSize.XSmall}
                                        style={ParagraphStyle.Light}>
                                        {t(
                                            "enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser"
                                        )}
                                    </Paragraph>
                                    <ToggleLabel
                                        checked={excludeFromDirectory}
                                        direction={ToggleLabelDirection.Right}
                                        id="exclude-from-directory"
                                        label={t("excludeFromAenProviderDirectoryAndSearch")}
                                        onToggle={() =>
                                            setExcludeFromDirectory(!excludeFromDirectory)
                                        }
                                    />
                                </div>

                                {/* TODO-NotYetImplemented Teacher Approval
                            <div className={`${CSS_CLASS_NAME}__section__heading`}>
                                <Paragraph size={ParagraphSize.Large}>
                                    Required to Teach Trainings
                                </Paragraph>
                                <Button
                                    onClick={() => {}}
                                    size={ButtonSize.Small}
                                    style={ButtonStyle.Primary}
                                    text="Select Product(s)"
                                />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__section__table`}>
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th>{t("name")}</th>
                                            <th>{t("id")}</th>
                                        </tr>
                                    </thead>
                                </DataTable>
                                <EmptyText spacing={EmptyTextSpacing.Small}>
                                    No Products Selected
                                </EmptyText>
                            </div> */}
                            </Card>
                        )}
                </div>
                <div className={`${CSS_CLASS_NAME}__actions`}>
                    <Button
                        linkPath={sitemap.admin.userManagement.users.list}
                        style={ButtonStyle.Secondary}
                        text={t("cancel")}
                        type={ButtonType.Link}
                    />
                    <Button
                        disabled={!userIsValid}
                        onClick={handleAddUser}
                        style={ButtonStyle.Primary}
                        text={t("addUser")}
                    />
                </div>
            </SkipNavContent>
        );
    }
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserNewPage };

// #endregion Exports
