import React, { useState } from "react";
import { Card } from "components/card/card";
import { EventInstructorCardSelectionManager } from "components/events/event-instructor/event-instructor-card-selection/event-instructor-card-selection-manager";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { EventInstructorRefineResults } from "components/events/event-instructor/event-instructor-refine-results/event-instructor-refine-results";
import { SelectedInstructorManager } from "components/events/event-instructor/selected-instructor-manager/selected-instructor-manager";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { sitemap } from "sitemap";
import { RouteUtils } from "utilities/route-utils";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { ToggleLabel, ToggleLabelDirection } from "components/toggle/toggle-label/toggle-label";
import { EventType } from "models/enumerations/events/event-type";
import "./admin-edit-event-instructor-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventInstructorPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-instructor-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventInstructorPage: React.FC<AdminEditEventInstructorPageProps> =
    validatePageAccess(AccessControlKeys.AdminEditEventInstructorPage)((): JSX.Element => {
        const event = useEventActiveRecordContext();
        const { record: globalState } = useGlobalState();
        const currentUserIsInNfpaRole =
            globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
        const pathTemplate = currentUserIsInNfpaRole
            ? sitemap.admin.event.edit.details
            : sitemap.admin.event.edit.product;
        const path = RouteUtils.replacePathParams(pathTemplate, { id: event.id ?? 0 });
        const productSelected = event.productIsComplete();

        useRedirectOnForbidden(sitemap.admin.dashboard);

        const [includeAenNetwork, setIncludeAenNetwork] = useState<boolean | undefined>(
            event.type === EventType.EducationNetwork ? false : undefined
        );

        const handleIncludeAenNetworkChange = (value: boolean): void => {
            setIncludeAenNetwork(value);
        };

        return (
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__main`}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div className={`${CSS_CLASS_NAME}__content__header`}>
                            <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                                {t("instructor")}
                            </Heading>
                            {event.type === EventType.EducationNetwork && (
                                <ToggleLabel
                                    checked={includeAenNetwork}
                                    direction={ToggleLabelDirection.Right}
                                    id="include-aen-network"
                                    label={t("includeAenNetwork")}
                                    onToggle={() =>
                                        handleIncludeAenNetworkChange(!includeAenNetwork)
                                    }
                                />
                            )}
                        </div>
                        {!productSelected && (
                            <div
                                className={`${CSS_CLASS_NAME}__no-product-selected-message-container`}>
                                <span>
                                    {t("please")}&nbsp;
                                    <Anchor
                                        path={path}
                                        text={t("selectAProduct").toLocaleLowerCase()}
                                    />
                                    &nbsp;{t("beforeChoosingYourInstructor")}
                                </span>
                            </div>
                        )}
                        {productSelected && (
                            <EventInstructorCardSelectionManager
                                event={event}
                                includeAenNetwork={includeAenNetwork}
                            />
                        )}
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__sidebar`}>
                    <div className={`${CSS_CLASS_NAME}__sidebar__summary`}>
                        <Card>
                            <EventInstructorRefineResults disabled={!productSelected} />
                            <SelectedInstructorManager event={event} />
                        </Card>
                    </div>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventInstructorPage };

// #endregion Exports
