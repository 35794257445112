import React, { Fragment } from "react";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Menu, Transition } from "@headlessui/react";
import { t } from "utilities/localization/t";
import "./context-menu.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContextMenuProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "context-menu";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContextMenu: React.FC<React.PropsWithChildren<ContextMenuProps>> = (props) => {
    const contextMenuTransition = {
        enter: "transition ease-out duration-100",
        enterFrom: "transform opacity-0 scale-95",
        enterTo: "transform opacity-100 scale-100",
        leave: "transition ease-in duration-100",
        leaveFrom: "transform opacity-100 scale-100",
        leaveTo: "transform opacity-0 scale-95",
    };
    return (
        <Menu>
            <Menu.Button aria-label={t("menu")}>
                <Icon size={IconSizes.Large} type={Icons.MoreVertical} />
            </Menu.Button>
            <div className="context-menu-wrap">
                <Transition as={Fragment} {...contextMenuTransition}>
                    <Menu.Items className={CSS_CLASS_NAME}>{props.children}</Menu.Items>
                </Transition>
            </div>
        </Menu>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ContextMenu };

// #endregion Exports
