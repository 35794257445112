import React, { useMemo, useState } from "react";
import { AddBulkEnrollmentModal } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-modal";
import { AddEnrollmentModal } from "components/enrollments/add-enrollment-modal/add-enrollment-modal";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { EnrollmentSummaryList } from "../enrollment-list/enrollment-summary-list";
import {
    EventCertificateService,
    EventCertificateDownloadPathParams,
} from "utilities/services/certificates/event-certificate-service";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Pager } from "components/pager/pager";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { useEventEnrollments } from "utilities/hooks/models/enrollments/use-event-enrollments";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useProvider } from "utilities/contexts/use-provider-context";
import { EnrollmentReportDownloadButton } from "components/reports/components/report-download-button/enrollment-report-download-button";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./event-enrollment-manager.scss";
import { CollectionUtils } from "utilities/collection-utils";
import { EventEnrollmentSummaryRecord } from "models/view-models/enrollments/event-enrollment-summary-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventEnrollmentManagerProps {
    eventId: number;
    productId: number;
    eventProductVersionId: number;
    isCompletedEvent: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-enrollment-manager";
const ITEMS_PER_PAGE = 6;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventEnrollmentManager: React.FC<EventEnrollmentManagerProps> = ({
    eventId,
    productId,
    eventProductVersionId,
    isCompletedEvent,
}): JSX.Element => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showAddEnrollmentModal, setShowAddEnrollmentModal] = useState(false);
    const [showBulkEnrollmentModal, setShowBulkEnrollmentModal] = useState(false);
    const { record: globalState } = useGlobalState();
    const { record: provider } = useProvider();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );

    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            (userRole === RoleType.AenAdministrator && provider?.isActive && !isCompletedEvent) ||
            userRole === RoleType.ClientAdmin,
        [provider?.isActive, userRole, isCompletedEvent]
    );

    const canDownloadCertificates = useHasAccess(AccessControlKeys.CanDownloadCertificates);
    const canDownloadEnrollmentReport = useHasAccess(AccessControlKeys.CanDownloadEnrollmentReport);

    const {
        enrollmentSummaries,
        rowCount: totalNumberOfEnrollments,
        withdrawEnrollment,
        createEnrollment,
        createBulkEnrollments,
        usedEnrollmentCount,
    } = useEventEnrollments({
        eventId: eventId,
        productId: productId,
        eventProductVersionId: eventProductVersionId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const disableCertificateDownloads: boolean = useMemo(() => {
        if (CollectionUtils.isEmpty(enrollmentSummaries)) {
            return true;
        }
        return !enrollmentSummaries.some(
            (enrollmentSummary: EventEnrollmentSummaryRecord): boolean =>
                enrollmentSummary.enrollment?.evaluation?.id != null &&
                enrollmentSummary.enrollment?.evaluation?.id > 0
        );
    }, [enrollmentSummaries]);

    const handleDownloadCertificateClick = async (id: number) => {
        const pathParams: EventCertificateDownloadPathParams = {
            id: eventId,
        };

        await EventCertificateService.downloadEventCertificates(
            pathParams,
            `event-${eventId}-certificates.zip`
        );
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header__title`}>
                    <Icon type={Icons.Enrollment} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("enrollment")}
                    </Heading>
                    <Badge style={BadgeStyle.Success}>
                        {t("totalUsedEnrollmentCount", {
                            usedEnrollmentCount: usedEnrollmentCount,
                        })}
                    </Badge>
                </div>
                <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                    {canDownloadCertificates && (
                        <ButtonIcon
                            disabled={disableCertificateDownloads}
                            iconType={Icons.Download}
                            text={t("downloadCertificates")}
                            onClick={() => handleDownloadCertificateClick(eventId)}
                        />
                    )}

                    {canDownloadEnrollmentReport && (
                        <EnrollmentReportDownloadButton eventId={eventId} />
                    )}

                    {canModifyEnrollments && (
                        <>
                            <ButtonIcon
                                iconType={Icons.Plus}
                                onClick={() => setShowAddEnrollmentModal(true)}
                                text={t("addNew")}
                            />
                            <ButtonIcon
                                iconType={Icons.Upload}
                                onClick={() => setShowBulkEnrollmentModal(true)}
                                text={t("importCSV")}
                            />
                        </>
                    )}
                </div>
            </div>

            <Card cssClassName={`${CSS_CLASS_NAME}__enrollment-list`}>
                <AddEnrollmentModal
                    handleSaveEnrollment={createEnrollment}
                    open={showAddEnrollmentModal}
                    setOpen={setShowAddEnrollmentModal}
                />
                <AddBulkEnrollmentModal
                    handleSaveBulkEnrollment={createBulkEnrollments}
                    open={showBulkEnrollmentModal}
                    setOpen={setShowBulkEnrollmentModal}
                />
                <EnrollmentSummaryList
                    enrollmentSummaries={enrollmentSummaries}
                    handleWithdrawEnrollment={withdrawEnrollment}
                />
                <Pager
                    currentPage={currentPage}
                    totalPages={totalNumberOfEnrollments / ITEMS_PER_PAGE}
                    onPageClick={onPageClick}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={totalNumberOfEnrollments}
                />
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventEnrollmentManager };

// #endregion Exports
