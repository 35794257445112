import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { UnitDetailsForm } from "../unit-details-form/unit-details-form";
import { UnitRecord } from "models/view-models/units/unit-record";
import { UnitService } from "utilities/services/units/unit-service";
import { t } from "utilities/localization/t";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import "./unit-edit-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitEditModalProps {
    onProductVersionUnitChange: (unit: UnitRecord, unitIndex?: number) => void;
    onSuccess: () => void;
    open: boolean;
    product: ProductRecord;
    selectedIndex: number;
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    unit: UnitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "unit-edit-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitEditModal: React.FC<UnitEditModalProps> = (props: UnitEditModalProps): JSX.Element => {
    const { record: productVersion } = useProductVersion();
    const originalUnit = props.unit.with({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [unit, setUnit] = useState<UnitRecord | undefined>(originalUnit);
    const { update: updateUnitApi } = UnitService.useUpdate();
    const deferSave = props.product.status === ActiveStatus.Active;

    useEffect(() => {
        setUnit(props.unit);
    }, [props.unit]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("save"),
            disabled: !StringUtils.hasValue(unit?.name),
            onClick: () => onUpdateUnit(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onUnitChanged = (updatedUnit: UnitRecord) => {
        setDirty(true);
        setUnit(updatedUnit);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            resetForm();
            props.setOpen(false);
        }
    };

    const resetForm = (): void => {
        setDirty(false);
        setUnit(originalUnit);
    };

    const onUpdateUnit = async (): Promise<void> => {
        if (await updateUnit()) {
            props.onSuccess();
            resetForm();
            props.setOpen(false);
        }
    };

    const updateUnit = async (): Promise<boolean> => {
        if (unit == null) {
            return false;
        }

        if (deferSave) {
            props.setCourseHasChanges && props.setCourseHasChanges(true);
            try {
                props.onProductVersionUnitChange(unit, props.selectedIndex);
                return true;
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheUnit"));
            }
        } else {
            try {
                const updateUnitResponse = await updateUnitApi(unit);

                const updateResult = updateUnitResponse?.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }

                unit.with({
                    ...updateResult.resultObject.toJS(),
                });
                ToastManager.success(t("unitUpdatedSuccessfully"));
                // props.setProduct(props.product.with({ ...updateResult.resultObject.toJS() }));
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheUnit"));
            }
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("editUnit")}
            actions={modalActionArray}
            modalStyle={""}>
            {
                // if
                unit !== undefined && unit !== null && (
                    <UnitDetailsForm
                        unit={unit}
                        onUnitChange={onUnitChanged}
                        completeProductUnitsInOrder={productVersion?.completeUnitsInOrder}
                    />
                )
            }
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitEditModal };

// #endregion Exports
