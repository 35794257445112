import React, { useMemo } from "react";
import { HeaderBanner, HeaderBannerBadge } from "components/header-banner/header-banner";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { t } from "utilities/localization/t";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { BadgeStyle } from "components/badges/badge/badge";
import { RoleType } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./provider-detail-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderDetailHeaderProps {
    canChangeProviderStatus: boolean;
    onChangeStatusClick?: () => void;
    onEditProviderClick?: () => void;
    provider: ProviderRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "provider-detail-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderDetailHeader: React.FC<React.PropsWithChildren<ProviderDetailHeaderProps>> = ({
    canChangeProviderStatus,
    onEditProviderClick,
    onChangeStatusClick,
    provider,
}: React.PropsWithChildren<ProviderDetailHeaderProps>): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const badges: HeaderBannerBadge[] = useMemo(
        () => [
            provider.isActive
                ? { text: t("active"), style: BadgeStyle.Success }
                : { text: t("inactive"), style: BadgeStyle.Warning },
        ],
        [provider.isActive]
    );

    return (
        <>
            <HeaderBanner cssClassName={CSS_CLASS_NAME} badges={badges} title={provider.name}>
                {canChangeProviderStatus && (
                    <Button
                        text={t("changeStatus")}
                        onClick={onChangeStatusClick}
                        size={ButtonSize.Medium}
                        style={ButtonStyle.Transparent}
                    />
                )}
                {globalState.currentIdentity?.role?.roleType === RoleType.NfpaAdministrator && (
                    <Button
                        text={t("editEnProvider")}
                        onClick={onEditProviderClick}
                        size={ButtonSize.Medium}
                        style={ButtonStyle.TertiaryAlt}
                    />
                )}
            </HeaderBanner>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderDetailHeader };

// #endregion Exports
