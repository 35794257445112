import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { CalendarMonthView } from "components/calendar-month-view/calendar-month-view";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContractSummaryRecord } from "models/view-models/contracts/contract-summary-record";
import { DataTable } from "components/tables/data-table/data-table";
import {
    ClientAdminEventDayStatusMap,
    EventDayStatus,
    EventDayStatusDisplayNames,
} from "models/enumerations/events/event-day-status";
import { EmptyText } from "components/empty-text/empty-text";
import { FormSelect } from "components/form/form-select/form-select";
import {
    HeaderBanner,
    HeaderBannerNavItem,
    HeaderBannerNavPosition,
} from "components/header-banner/header-banner";
import { HeaderBannerUtils } from "utilities/header-banner-utils";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorLedTrainingTypeBadge } from "components/events/instructor-led-training-type-badge/instructor-led-training-type-badge";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { Pager } from "components/pager/pager";
import { QuickLinkProps } from "components/quick-link/quick-link";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { SelectOption } from "components/form/inputs/select/select";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { TrainingCard, TrainingCardType } from "components/third-party/training-card/training-card";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCalendarEvents } from "utilities/hooks/models/events/use-calendar-events";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useOrganizationILTContractSummaries } from "utilities/hooks/models/organizations/use-organization-ilt-contract-summaries";
import { useOrganizationILTPastContractSummaries } from "utilities/hooks/models/organizations/use-organization-ilt-past-contract-summaries";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useNavigate } from "react-router-dom";
import "./third-party-dashboard-instructor-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ThirdPartyDashboardTrainingViewTypes {
    Calendar = "1",
    Tile = "2",
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ThirdPartyDashboardInstructorPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BANNER_LINK_TEXT: TranslatedCopy = "viewTraining";
const CSS_CLASS_NAME: string = "third-party-dashboard-instructor-page";
const ITEMS_PER_PAGE = 20;

const QUICK_LINKS: QuickLinkProps[] = [
    {
        icon: Icons.Support,
        path: sitemap.authenticated.support,
        text: "support",
    },
    {
        icon: Icons.Reporting,
        path: sitemap.admin.reports.list,
        text: "reports",
    },
];

const TRAINING_VIEW_OPTIONS: SelectOption[] = [
    {
        value: ThirdPartyDashboardTrainingViewTypes.Tile,
        text: "tileView" as TranslatedCopy,
    },
    {
        value: ThirdPartyDashboardTrainingViewTypes.Calendar,
        text: "calendarView" as TranslatedCopy,
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getEventTrainingPath = (eventId?: number) => {
    if (eventId == null) {
        return undefined;
    }

    return RouteUtils.replacePathParams(sitemap.thirdPartyProvider.event.details, {
        id: eventId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ThirdPartyDashboardInstructorPage: React.FC<ThirdPartyDashboardInstructorPageProps> =
    validatePageAccess(AccessControlKeys.ThirdPartyDashboardInstructorPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.public.noAccess);
        const [currentPage, setCurrentPage] = useState<number>(1);
        const { record: globalState } = useGlobalState();
        const navigate = useNavigate();

        const [currenTrainingsViewType, setCurrenTrainingsViewType] =
            useState<ThirdPartyDashboardTrainingViewTypes>(
                ThirdPartyDashboardTrainingViewTypes.Tile
            );

        const [calendarMonth, setCalendarMonth] = useState<moment.Moment>(
            moment().startOf("month")
        );

        const { events: calendarEvents } = useCalendarEvents({
            calendarIsHidden: currenTrainingsViewType === ThirdPartyDashboardTrainingViewTypes.Tile,
            month: calendarMonth.toDate().getMonth(),
            excludeDraft: true,
            organizationId: globalState?.currentIdentity?.user?.organizationId ?? 0,
            year: calendarMonth.toDate().getFullYear(),
        });

        const handleNextMonthClick = () => setCalendarMonth(calendarMonth.clone().add(1, "month"));
        const handlePreviousMonthClick = () =>
            setCalendarMonth(calendarMonth.clone().subtract(1, "month"));

        const userFirstName = useMemo(
            () => globalState?.currentIdentity?.user?.firstName,
            [globalState?.currentIdentity?.user?.firstName]
        );

        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        const { notifications, markNotificationAsRead } = useUserNotifications({
            includeEvent: true,
            roleType: RoleType.ClientAdmin,
            userId: userId,
        });

        const iltNotifications = useMemo(
            () => notifications.filter((n) => n.eventId != null),
            [notifications]
        );

        const navItems: HeaderBannerNavItem[] = HeaderBannerUtils.getThirdPartyDashboardNavItems();

        const organizationId = useMemo(
            () => globalState?.currentIdentity?.user?.organizationId ?? 0,
            [globalState?.currentIdentity?.user?.organizationId]
        );

        const { contractSummaries: activeContractSummaries } = useOrganizationILTContractSummaries({
            organizationId,
        });

        const { pastContractSummaries, rowCount: totalNumberOfPastTrainings } =
            useOrganizationILTPastContractSummaries({
                organizationId,
                skip: (currentPage - 1) * ITEMS_PER_PAGE,
                take: ITEMS_PER_PAGE,
            });

        const handleNotificationClose = (notificationId?: number) => {
            if (notificationId == null) {
                return;
            }

            markNotificationAsRead?.(notificationId);
        };

        const getAttendanceStatus = (contractSummary: ContractSummaryRecord) => {
            var status = EventDayStatus.NotStarted;
            const attendanceIsComplete = contractSummary.event?.eventDays?.every(
                (eventDay) => eventDay.status === EventDayStatus.Completed
            );
            if (attendanceIsComplete) {
                status = EventDayStatus.Completed;
                return status;
            }

            const attendanceIsInProgress = contractSummary.event?.eventDays?.some(
                (eventDay) => eventDay.status === EventDayStatus.InProgress
            );
            if (attendanceIsInProgress) {
                status = EventDayStatus.InProgress;
            }

            return status;
        };

        const onPageClick = (pageNumber: number) => {
            setCurrentPage(pageNumber);
        };

        useEffect(() => {
            ScrollUtils.scrollToElementBySelector(
                `${CSS_CLASS_NAME}__completed ${CSS_CLASS_NAME}__section`
            );
        }, [pastContractSummaries]);

        return (
            <>
                <HeaderBanner
                    title={t("twelcomeUserFirstName", {
                        twelcome: t("welcome"),
                        userFirstName: userFirstName,
                    })}
                    navPosition={HeaderBannerNavPosition.Right}
                    navItems={navItems}></HeaderBanner>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <h2>{t("currentAndUpcomingTrainings")}</h2>
                        <FormSelect
                            ariaLabelledBy={t("trainingViewType")}
                            formFieldName={t("trainingViewType")}
                            id="TrainingViewType"
                            label={t("trainingViewType")}
                            labelScreenReaderOnly={true}
                            onChange={(e) =>
                                setCurrenTrainingsViewType(
                                    e.currentTarget.value as ThirdPartyDashboardTrainingViewTypes
                                )
                            }
                            options={TRAINING_VIEW_OPTIONS}
                            value={currenTrainingsViewType}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__notifications`}>
                        {iltNotifications != null &&
                            iltNotifications.map((notification, index) => (
                                <NotificationBanner
                                    key={`${notification.id}-${index}`}
                                    icon={notification.getNotificationIcon()}
                                    linkPath={getEventTrainingPath(notification.eventId)}
                                    linkText={t(BANNER_LINK_TEXT)}
                                    message={notification.message}
                                    notificationId={notification.id!}
                                    onClose={() => handleNotificationClose(notification.id)}
                                    style={notification.notificationType}
                                />
                            ))}
                    </div>
                    {(activeContractSummaries.length > 0 ||
                        currenTrainingsViewType ===
                            ThirdPartyDashboardTrainingViewTypes.Calendar) && (
                        <div className={`${CSS_CLASS_NAME}__section`}>
                            {currenTrainingsViewType ===
                                ThirdPartyDashboardTrainingViewTypes.Calendar && (
                                <CalendarMonthView
                                    events={calendarEvents}
                                    notifications={iltNotifications}
                                    getEventDisplayName={(event) =>
                                        event.product?.name ?? event.name
                                    }
                                    onNextMonthClick={handleNextMonthClick}
                                    onPreviousMonthClick={handlePreviousMonthClick}
                                    onViewEventClick={(eventId: number) =>
                                        navigate(
                                            RouteUtils.replacePathParams(
                                                sitemap.thirdPartyProvider.event.details,
                                                {
                                                    id: eventId,
                                                }
                                            )
                                        )
                                    }
                                />
                            )}
                            {currenTrainingsViewType ===
                                ThirdPartyDashboardTrainingViewTypes.Tile && (
                                <div className={`${CSS_CLASS_NAME}__current`}>
                                    {activeContractSummaries.map((contractSummary) => (
                                        <TrainingCard
                                            key={`contract-summary-${contractSummary.eventId}`}
                                            enrollmentCount={contractSummary.enrollmentCount ?? 0}
                                            enrollmentLimit={
                                                contractSummary.noEnrollmentLimit === true
                                                    ? undefined
                                                    : contractSummary.enrollmentLimit
                                            }
                                            evaluation={
                                                contractSummary.completedEvaluationCount ?? 0
                                            }
                                            evaluationTotal={contractSummary.enrollmentLimit ?? 0}
                                            name={contractSummary.event?.product?.name ?? ""}
                                            number={
                                                contractSummary.contractNumber ??
                                                t("notApplicableShort")
                                            }
                                            syllabus={contractSummary.completedSyllabusCount ?? 0}
                                            syllabusTotal={contractSummary.enrollmentLimit ?? 0}
                                            type={
                                                contractSummary.event?.instructorLedTrainingType ===
                                                InstructorLedTrainingType.LiveVirtual
                                                    ? TrainingCardType.LiveVirtual
                                                    : TrainingCardType.InPerson
                                            }
                                            viewTrainingUrl={RouteUtils.replacePathParams(
                                                sitemap.thirdPartyProvider.event.details,
                                                {
                                                    id: contractSummary.eventId,
                                                }
                                            )}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {activeContractSummaries.length === 0 && (
                        <EmptyText>
                            {t("yourOrganizationHasNoCurrentInstructorLedTrainings")}
                        </EmptyText>
                    )}
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <h2>{t("pastTrainings")}</h2>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__completed ${CSS_CLASS_NAME}__section`}>
                        <DataTable>
                            <thead>
                                <tr>
                                    <th className="contract-number">{t("contractNumber")}</th>
                                    <th className="product-name">{t("productName")}</th>
                                    <th className="type">{t("type")}</th>
                                    <th className="enrollment">{t("enrollment")}</th>
                                    <th className="assessment">{t("assessment")}</th>
                                    <th className="evaluation">{t("evaluation")}</th>
                                    <th className="attendance">{t("attendance")}</th>
                                    <th className="action">
                                        <span className="sr-only">{t("action")}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {pastContractSummaries.map(
                                    (contractSummary: ContractSummaryRecord) => (
                                        <tr
                                            key={`${contractSummary.contractNumber}-${contractSummary.contractId}`}>
                                            <td className="contract-number">
                                                {contractSummary.contractNumber}
                                            </td>
                                            <td className="product-name">
                                                {contractSummary.event?.product?.name}
                                            </td>
                                            <td className="type">
                                                {contractSummary.event?.instructorLedTrainingType !=
                                                    null && (
                                                    <InstructorLedTrainingTypeBadge
                                                        trainingType={
                                                            contractSummary.event
                                                                ?.instructorLedTrainingType
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td className="enrollment">{`${
                                                contractSummary.enrollmentCount
                                            } ${
                                                contractSummary.noEnrollmentLimit
                                                    ? ""
                                                    : " / " + contractSummary.enrollmentLimit
                                            }`}</td>
                                            <td className="assessment">
                                                {contractSummary.noEnrollmentLimit
                                                    ? contractSummary.completedSyllabusCount +
                                                      "/" +
                                                      contractSummary.enrollmentCount
                                                    : contractSummary.completedSyllabusCount +
                                                      "/" +
                                                      contractSummary.enrollmentLimit}
                                            </td>
                                            <td className="evaluation">
                                                {contractSummary.noEnrollmentLimit
                                                    ? contractSummary.completedEvaluationCount +
                                                      "/" +
                                                      contractSummary.enrollmentCount
                                                    : contractSummary.completedEvaluationCount +
                                                      "/" +
                                                      contractSummary.enrollmentLimit}
                                            </td>
                                            <td className="attendance">
                                                <StatusBadge
                                                    status={
                                                        ClientAdminEventDayStatusMap[
                                                            getAttendanceStatus(contractSummary)
                                                        ]
                                                    }
                                                    text={
                                                        EventDayStatusDisplayNames[
                                                            getAttendanceStatus(contractSummary)
                                                        ]
                                                    }
                                                />
                                            </td>
                                            <td className="action">
                                                <ContextMenu>
                                                    <ContextMenuAnchor
                                                        hrefPath={RouteUtils.localizePath(
                                                            RouteUtils.replacePathParams(
                                                                sitemap.thirdPartyProvider.event
                                                                    .details,
                                                                {
                                                                    id: contractSummary.eventId,
                                                                }
                                                            )
                                                        )}
                                                        displayName={t("viewTraining")}
                                                    />
                                                </ContextMenu>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </DataTable>
                        <Pager
                            currentPage={currentPage}
                            onPageClick={onPageClick}
                            totalPages={totalNumberOfPastTrainings / ITEMS_PER_PAGE}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={totalNumberOfPastTrainings}></Pager>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <h2>{t("quickLinks")}</h2>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <QuickLinkList links={QUICK_LINKS} />
                    </div>
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ThirdPartyDashboardInstructorPage };

// #endregion Exports
