import React from "react";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { t } from "utilities/localization/t";
import "./event-organization-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventOrganizationListProps {
    onReplace: () => void;
    organization?: OrganizationRecord;
    disabled?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-organization-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventOrganizationList: React.FC<EventOrganizationListProps> = ({
    onReplace,
    organization,
    disabled,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="name">{t("name")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="action" aria-labelledby={t("menuColumn")}>
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {organization == null && (
                        <tr className={"-no-results-row"}>
                            <td colSpan={3}>
                                <EmptyText spacing={EmptyTextSpacing.None}>
                                    {t("noOrganizationSelected")}
                                </EmptyText>
                            </td>
                        </tr>
                    )}
                    {organization != null && (
                        <tr key={organization?.id}>
                            <td className="name">{organization?.name}</td>
                            <td className="id">{organization?.id}</td>
                            <td className="action">
                                <ContextMenu>
                                    <ContextMenuButton
                                        onClick={onReplace}
                                        displayName={t("replace")}
                                        disabled={disabled}
                                    />
                                </ContextMenu>
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventOrganizationList };

// #endregion Exports
