import { BannerStyle } from "components/banner/banner";
import { Map } from "utilities/types/map";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum NotificationType {
    Default = 0,
    EventCancelled = 1,
    EventChanged = 2,
    EventInstructorAssignment = 3,
    OllEnrollmentCreation = 4,
    IltEnrollmentCreation = 5,
    OllContractCreation = 6,
    IltContractCreation = 7,
    InstructorApplicationApproved = 8,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const NotificationTypeBannerStyleMap: Map<NotificationType, BannerStyle> = {
    [NotificationType.Default]: BannerStyle.LightInverted,
    [NotificationType.EventCancelled]: BannerStyle.Error,
    [NotificationType.EventChanged]: BannerStyle.Warning,
    [NotificationType.EventInstructorAssignment]: BannerStyle.Success,
    [NotificationType.OllEnrollmentCreation]: BannerStyle.Success,
    [NotificationType.IltEnrollmentCreation]: BannerStyle.Success,
    [NotificationType.OllContractCreation]: BannerStyle.Success,
    [NotificationType.IltContractCreation]: BannerStyle.Success,
    [NotificationType.InstructorApplicationApproved]: BannerStyle.Neutral,
};

// #endregion Maps

export { NotificationType, NotificationTypeBannerStyleMap };

// #endregion Exports
