import { BadgeStyle } from "components/badges/badge/badge";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ReportDownloadStatus {
    PENDING,
    STARTED,
    COMPLETE,
    ERROR,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ReportDownloadStatusDisplayNames: Map<ReportDownloadStatus, TranslatedCopy> = {
    [ReportDownloadStatus.COMPLETE]: "complete",
    [ReportDownloadStatus.ERROR]: "error",
    [ReportDownloadStatus.STARTED]: "pending",
    [ReportDownloadStatus.PENDING]: "pending",
};

const ReportDownloadStatusBadgeStyle: Map<ReportDownloadStatus, BadgeStyle> = {
    [ReportDownloadStatus.COMPLETE]: BadgeStyle.Dark,
    [ReportDownloadStatus.ERROR]: BadgeStyle.Error,
    [ReportDownloadStatus.STARTED]: BadgeStyle.Default,
    [ReportDownloadStatus.PENDING]: BadgeStyle.Default,
};

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportDownloadStatus, ReportDownloadStatusDisplayNames, ReportDownloadStatusBadgeStyle };

// #endregion Exports
