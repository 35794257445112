import { ButtonIcon, ButtonIconPosition } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { UserRoleCard } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-profile-manager/admin-layout-header-profile-selector/user-role-card/user-role-card";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import React, { useState, useCallback, useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import {
    ListUserRolesQueryParams,
    UserRoleService,
} from "utilities/services/user-roles/user-role-service";
import "./admin-layout-header-profile-selector.scss";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderProfileSelectorProps {
    closeProfileSelector: () => void;
    isOpen: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-profile-selector";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderProfileSelector: React.FC<AdminLayoutHeaderProfileSelectorProps> = (
    props: AdminLayoutHeaderProfileSelectorProps
): JSX.Element | null => {
    const [userRoles, setUserRoles] = useState<UserRoleRecord[]>([]);
    const { record: globalState } = useGlobalState();
    const { list: listUserRolesApi } = UserRoleService.useList();

    const fetchData = useCallback(async () => {
        if (!globalState.currentIdentity?.hasUserId()) {
            return;
        }

        const queryParams: ListUserRolesQueryParams = {
            userId: globalState.currentIdentity.userId(),
            includeRole: true,
        };

        try {
            const response = await listUserRolesApi(queryParams);

            if (
                response?.resultObjects == null ||
                response.results == null ||
                response.results.hasErrors()
            ) {
                throw new Error();
            }

            setUserRoles(response.resultObjects);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingUserRoles"));
            setUserRoles([]);
        }
    }, [globalState.currentIdentity, listUserRolesApi]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={CSS_CLASS_NAME}>
            <ButtonIcon
                cssClassName={`${CSS_CLASS_NAME}__close`}
                buttonSize={ButtonSize.None}
                buttonStyle={ButtonStyle.None}
                iconSize={IconSizes.Medium}
                iconPosition={ButtonIconPosition.Right}
                iconType={Icons.SlideClose}
                onClick={props.closeProfileSelector}
                text={t("cancel").toLocaleUpperCase()}
            />
            <div className={`${CSS_CLASS_NAME}__user-roles`}>
                {userRoles.map(
                    (userRole: UserRoleRecord): JSX.Element => (
                        <UserRoleCard key={userRole.id} userRole={userRole} />
                    )
                )}
            </div>
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderProfileSelector };

// #endregion Exports
