import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { EventDayRecord } from "./event-day-record";
import { EventRecord } from "./event-record";
import { EventSchedule } from "models/interfaces/events/event-schedule";
import { EventSessionRecord } from "./event-session-record";
import { Record } from "immutable";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventSchedule = {
    eventId: 0,
    eventDays: [],
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventScheduleRecord extends Record(defaultValues) implements EventSchedule {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventSchedule>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (CollectionUtils.hasValues(params.eventDays)) {
            params.eventDays = RecordUtils.ensureRecords(params.eventDays, EventDayRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the creditHours for an EventSchedule.
     */
    public getCreditHours(): number {
        if (this.eventDays?.length === 0) {
            return 0;
        }

        const totalCreditHours = this.eventDays!.reduce(function (creditHours, eventDay) {
            return creditHours + eventDay?.getCreditHours();
        }, 0);

        return totalCreditHours;
    }

    /**
     * Convenience method to the return the creditHours description for an EventSchedule.
     */
    public getCreditHoursDescription(): string {
        const creditHours = this.getCreditHours();

        return StringUtils.pluralize(creditHours, t("hour"), t("hours"));
    }

    /**
     * Convenience method to sort the Event Schedule by Event Day and Session start time.
     */
    public sortEventSchedule(): void {
        if (!CollectionUtils.hasValues(this.eventDays)) {
            return;
        }

        this.eventDays!.sort((a: EventDayRecord, b: EventDayRecord) => {
            return new Date(a.eventDate()).getTime() - new Date(b.eventDate()).getTime();
        });

        this.eventDays!.forEach((eventDay: EventDayRecord) => {
            if (eventDay.eventSessions === undefined || eventDay.eventSessions.length === 0) {
                return;
            }

            eventDay.eventSessions.sort((a: EventSessionRecord, b: EventSessionRecord) => {
                return new Date(a.startTime()).getTime() - new Date(b.startTime()).getTime();
            });
        });
    }

    /**
     * Given a set of values for EventSchedule properties, create a new EventScheduleRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventSchedule>} values The values to overwrite on this instance.
     * @returns A EventScheduleRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventSchedule>): EventScheduleRecord {
        return new EventScheduleRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleRecord };

// #endregion Exports
