import React, { useMemo } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { EventDescriptionCard } from "components/events/manage-event-details/event-description-card/event-description-card";
import { EventLocationCard } from "components/events/manage-event-details/event-location-card/event-location-card";
import { EventScheduleCard } from "components/events/manage-event-details/event-schedule-card/event-schedule-card";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { LearnerChecklist } from "components/learner/learner-checklist/learner-checklist";
import { LearnerTakeATrainingContactCard } from "components/learner/learner-take-a-training/learner-take-a-training-contact-card/learner-take-a-training-contact-card";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { RoleType } from "models/enumerations/users/role-type";
import { useEvent } from "utilities/hooks/models/events/use-event";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { t } from "utilities/localization/t";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { LinkTrialCard } from "components/link-trial/link-trial-card";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./learner-take-a-training-details-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-take-a-training-details-page";
const NOTIFICATION_TYPES = [NotificationType.EventChanged, NotificationType.IltEnrollmentCreation];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingDetailsPage: React.FC<LearnerTakeATrainingDetailsPageProps> =
    validatePageAccess(AccessControlKeys.MyLearningAssignmentsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.dashboard);
        const { record: globalState } = useGlobalState();
        const { showLinkFreeTrialBanner } = useFeatureFlags();
        const { id: eventIdParam, enrollmentId: enrollmentIdParam } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeAttendance: true,
        });

        const { event } = useEvent({
            eventId,
            includeCanceledBy: true,
            includeEventSessions: true,
            includeEventScheduleException: true,
            includeInstructor: true,
            includeOrganization: true,
            includeProduct: true,
            includeProvider: true,
        });

        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        const { notifications, markNotificationAsRead } = useUserNotifications({
            eventId: eventId,
            includeEvent: true,
            notificationTypes: NOTIFICATION_TYPES,
            roleType: RoleType.Learner,
            userId: userId,
        });

        const handleNotificationClose = (notificationId?: number) => {
            if (notificationId == null) {
                return;
            }

            markNotificationAsRead?.(notificationId);
        };

        const shouldDisplayFreeTrialBanner = () => {
            return showLinkFreeTrialBanner || event?.getManualLinkTrialDetails() != null;
        };

        return (
            <>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.EventDetails} size={IconSizes.Large} />
                        <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                            {t("aboutThisTraining")}
                        </Heading>
                    </div>
                    {event.status === PublishStatus.Canceled && (
                        <div className={`${CSS_CLASS_NAME}__cancellation-banner`}>
                            <Banner style={BannerStyle.Error} format={BannerFormat.Default}>
                                <Icon type={Icons.CalendarBusy} size={IconSizes.Large} />
                                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                    {t("thisTrainingHasBeenCanceled")}
                                </Heading>
                                <Paragraph
                                    style={ParagraphStyle.Default}
                                    size={ParagraphSize.Small}>
                                    {event.cancellationMessage}
                                </Paragraph>
                            </Banner>
                        </div>
                    )}

                    {event.status !== PublishStatus.Canceled && (
                        <div className={`${CSS_CLASS_NAME}__heading`}>
                            <Heading
                                size={HeadingSize.XSmall}
                                priority={HeadingPriority.H2}></Heading>
                        </div>
                    )}
                    <div className={`${CSS_CLASS_NAME}__banners`}>
                        {event.organization?.id != null && (
                            <div className={`${CSS_CLASS_NAME}__contract-banner`}>
                                <NotificationBanner
                                    icon={Icons.InformationOutline}
                                    notificationId={0}
                                    onClose={() => {}}
                                    style={NotificationType.Default}
                                    message={t(
                                        "youHaveBeenAddedOnBehalfOfEventOrganizationNameYourDataMayBeSharedWithThisOrganization",
                                        { eventOrganizationName: event.organization?.name }
                                    )}
                                />
                            </div>
                        )}

                        {notifications != null && (
                            <div className={`${CSS_CLASS_NAME}__notifications`}>
                                {notifications.map((notification, index) => (
                                    <NotificationBanner
                                        key={`${notification.id}-${index}`}
                                        icon={notification.getNotificationIcon()}
                                        message={notification.message}
                                        notificationId={notification.id!}
                                        onClose={() => handleNotificationClose(notification.id)}
                                        style={notification.notificationType}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    {enrollment && shouldDisplayFreeTrialBanner() && event.hasLinkFreeTrial() && (
                        <div className={`${CSS_CLASS_NAME}__link-trial`}>
                            <LinkTrialCard
                                enrollment={enrollment}
                                manualTrial={event?.getManualLinkTrialDetails()}
                                trialAvailableDate={event.startDate() ?? undefined}
                            />
                        </div>
                    )}
                    <div className={`${CSS_CLASS_NAME}__details`}>
                        <div className={`${CSS_CLASS_NAME}__about`}>
                            <EventDescriptionCard
                                product={event?.product}
                                productVersionNumber={event?.productVersion?.versionId}
                                stacked={true}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__checklist`}>
                            <LearnerChecklist enrollmentId={enrollmentId} stacked={true} />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__schedule`}>
                            <EventScheduleCard
                                attendances={enrollment.attendances}
                                event={event}
                                schedule={event.eventDays}
                                stacked={true}
                            />
                        </div>
                        {!event.hideLocationInformation && (
                            <div className={`${CSS_CLASS_NAME}__location`}>
                                <EventLocationCard event={event} stacked={true} />
                            </div>
                        )}
                        <div className={`${CSS_CLASS_NAME}__hr`}></div>
                        <div className={`${CSS_CLASS_NAME}__contact`}>
                            <LearnerTakeATrainingContactCard event={event} />
                        </div>
                    </div>
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingDetailsPage };

// #endregion Exports
