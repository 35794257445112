import React, { useEffect, useMemo } from "react";
import { DataTable } from "components/tables/data-table/data-table";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ProductDetailsArchiveRecord } from "models/view-models/products/product-details-archive-record";
import { t } from "utilities/localization/t";
import "./product-details-change-history.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDetailsChangeHistoryProps {
    productDetailsArchiveData: ProductDetailsArchiveRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-details-change-history";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDetailsChangeHistory: React.FC<ProductDetailsChangeHistoryProps> = ({
    productDetailsArchiveData,
}): JSX.Element => {
    const createdLog = useMemo(
        () =>
            productDetailsArchiveData[productDetailsArchiveData.length - 1] ??
            new ProductDetailsArchiveRecord(),
        [productDetailsArchiveData]
    );

    const productDetailsChangeLog = useMemo(
        () => productDetailsArchiveData.slice(0, productDetailsArchiveData.length - 1),
        [productDetailsArchiveData]
    );

    const deactivateArchiveLog: ProductDetailsArchiveRecord[] = useMemo(() => [], []);
    useEffect(() => {
        for (var i = 0; i < productDetailsChangeLog.length; i++) {
            if (
                productDetailsChangeLog[i].changeLog?.includes("Deactivated") ||
                productDetailsChangeLog[i].changeLog?.includes("Archived")
            ) {
                deactivateArchiveLog.push(productDetailsChangeLog[i]);
                productDetailsChangeLog.splice(i, 1);
                i--;
            }
        }
    }, [productDetailsChangeLog, deactivateArchiveLog]);

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__main`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Paragraph size={ParagraphSize.Large}>{t("changeHistory")}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="change-log">{t("changeLog")}</th>
                                <th className="date">{t("date")}</th>
                                <th className="changed-by">{t("changedBy")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CollectionUtils.hasValues(deactivateArchiveLog) &&
                                deactivateArchiveLog.map((da: ProductDetailsArchiveRecord) => (
                                    <tr key={da.id}>
                                        <td className="created-log">{da.changeLog}</td>
                                        <td className="date">{da.getCreatedDateAndTime()}</td>
                                        <td className="changed-by">
                                            <>
                                                <Paragraph size={ParagraphSize.XSmall}>
                                                    {da.getCreatedBy()}
                                                </Paragraph>
                                                <Paragraph
                                                    size={ParagraphSize.XxSmall}
                                                    style={ParagraphStyle.Light}>
                                                    {da.createdById}
                                                </Paragraph>
                                            </>
                                        </td>
                                    </tr>
                                ))}
                            {productDetailsChangeLog.map((archive: ProductDetailsArchiveRecord) => (
                                <tr key={archive.id}>
                                    <td className="change-log">{archive.changeLog}</td>
                                    <td className="date">{archive.getCreatedDateAndTime()}</td>
                                    <td className="changed-by">
                                        <>
                                            <Paragraph size={ParagraphSize.XSmall}>
                                                {archive.getCreatedBy()}
                                            </Paragraph>
                                            <Paragraph
                                                size={ParagraphSize.XxSmall}
                                                style={ParagraphStyle.Light}>
                                                {archive.createdById}
                                            </Paragraph>
                                        </>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="created-log">{createdLog.changeLog}</td>
                                <td className="date">{createdLog.getCreatedDateAndTime()}</td>
                                <td className="changed-by">
                                    <>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {createdLog.getCreatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}>
                                            {createdLog.createdById}
                                        </Paragraph>
                                    </>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDetailsChangeHistory };

// #endregion Exports
