import React from "react";
import { FileInput, FileInputProps } from "components/form/inputs/file-input/file-input";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormFileInputProps extends FileInputProps, FormFieldProps, InputProperties {
    buttonText: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormFileInput: React.FC<FormFileInputProps> = (props: FormFileInputProps) => {
    return (
        <FormField
            cssClassName={props.cssClassName}
            label={props.label}
            formFieldName={props.formFieldName}
            required={props.required}>
            <FileInput {...props} />
        </FormField>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { FormFileInput };

// #endregion Exports
