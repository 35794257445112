import React, { useMemo } from "react";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import {
    EnrollmentUnitCourseStatus,
    EnrollmentUnitCourseStatusDisplayNames,
    EnrollmentUnitCourseStatusMap,
} from "models/enumerations/enrollments/enrollment-unit-course-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import "./course-row.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CourseRowProps {
    name: string;
    number?: number;
    score?: number;
    required?: boolean;
    status: EnrollmentUnitCourseStatus;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-row";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseRow: React.FC<CourseRowProps> = (props: CourseRowProps) => {
    const trainingSummaryCourseStatus = useMemo(() => {
        if (
            props.status === EnrollmentUnitCourseStatus.Complete ||
            props.status === EnrollmentUnitCourseStatus.NotStarted
        ) {
            return props.status;
        }

        return EnrollmentUnitCourseStatus.Attempted;
    }, [props.status]);
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__name`}>
                <Paragraph size={props.number ? ParagraphSize.Default : ParagraphSize.Small}>
                    {props.number && <strong>{props.number}.&nbsp;</strong>}
                    {props.name}
                </Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__details`}>
                <div className="bubble-wrap">
                    <Badge
                        text={props.required ? t("required") : t("optional")}
                        style={props.required ? BadgeStyle.Neutral : BadgeStyle.Default}
                    />
                </div>
                <div className="bubble-wrap">
                    <EnumStatusBadge
                        displayNames={EnrollmentUnitCourseStatusDisplayNames}
                        statusMap={EnrollmentUnitCourseStatusMap}
                        value={trainingSummaryCourseStatus}
                    />
                </div>
                <div className="score-wrap">
                    <Paragraph size={ParagraphSize.XSmall}>
                        <Icon type={Icons.FactCheck} />
                        <span>{props.score ? `${props.score}%` : "--"}</span>
                    </Paragraph>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseRow };

// #endregion Exports
