import React from "react";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { StringUtils } from "utilities/string-utils";
import "./badge.scss";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

enum BadgeStyle {
    Default = "",
    Error = "-error",
    Inverted = "-inverted",
    Light = "-light",
    Dark = "-dark",
    Neutral = "-neutral",
    Success = "-success",
    Transparent = "-transparent",
    TransparentAlt = "-transparent-alt",
    Warning = "-warning",
    WarningBright = "-warning-bright",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface BadgeProps {
    cssClassName?: string;
    icon?: Icons;
    style?: BadgeStyle;
    text?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "badge";
const DEFAULT_BADGE_STYLE: BadgeStyle = BadgeStyle.Default;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = (
    props: React.PropsWithChildren<BadgeProps>
): JSX.Element => {
    const style: BadgeStyle = props.style ?? DEFAULT_BADGE_STYLE;
    const classNames: string[] = [CSS_CLASS_NAME, style];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <span className={className}>
            {
                // if
                props.children != null && props.children
            }
            {
                // if
                props.children == null && props.icon != null && (
                    <>
                        <span className={`${CSS_CLASS_NAME}__icon`}>
                            <Icon type={props.icon} />
                        </span>
                    </>
                )
            }

            {
                // if
                props.children == null && <>{props.text}</>
            }
        </span>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Badge, BadgeStyle };

// #endregion Exports
