import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingTheRegistration";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseRegistrationsHook {
    error?: Error;
    isLoading: boolean;
    registrations: RegistrationRecord[];
}

interface UseRegistrationsHookOptions {
    onError?: (error: Error) => void;
    eventId?: number;
    scormPackageAssessmentId?: number;
    includeUser?: boolean;
    includeAttempts?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useRegistrations({
    onError,
    eventId,
    scormPackageAssessmentId,
    includeUser,
    includeAttempts,
}: UseRegistrationsHookOptions): UseRegistrationsHook {
    const { list } = RegistrationService.useList();
    const [registrations, setRegistrations] = useState<RegistrationRecord[]>([]);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (
            (eventId != null && eventId <= 0) ||
            (scormPackageAssessmentId != null && scormPackageAssessmentId <= 0)
        ) {
            setRegistrations([]);
            return;
        }

        try {
            (async function getUsers() {
                setIsLoading(true);
                const registrationsResponse = await list({
                    eventId: eventId,
                    scormPackageAssessmentId: scormPackageAssessmentId,
                    includeAttempts: includeAttempts,
                    includeUser: includeUser,
                });
                setIsLoading(false);

                if (
                    registrationsResponse?.resultObjects == null ||
                    registrationsResponse?.results == null ||
                    registrationsResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setRegistrations(registrationsResponse.resultObjects);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [eventId, includeAttempts, includeUser, list, setError]);

    return {
        error,
        isLoading,
        registrations,
    };
}

// #endregion Hook
