import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { FormSearchSelect } from "components/form/form-search-select/form-search-select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "../../form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";
import {
    InstructorProfileStatus,
    InstructorProfileStatusDisplayNames,
} from "models/instructors/instructor-profile-status";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { RoleRecord } from "models/view-models/roles/role-record";
import { RoleType, RoleTypeDisplayNames } from "models/enumerations/users/role-type";
import { SelectOption } from "components/form/inputs/select/select";
import { StringUtils } from "andculturecode-javascript-core";
import { TagItem, TagList } from "../../tags/tag-list/tag-list";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel, ToggleLabelDirection } from "components/toggle/toggle-label/toggle-label";
import { User } from "models/interfaces/users/user";
import { UserRecord } from "models/view-models/users/user-record";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./edit-user-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditUserModalProps {
    availableRoles: RoleRecord[];
    lockedRoleTypes?: RoleType[];
    onUserEditFormSave?: (
        user: UserRecord,
        rolesToAdd: number[],
        userRolesToRemove: number[]
    ) => Promise<boolean>;
    open: boolean;
    organizations: OrganizationRecord[];
    providerAdminProviders: ProviderRecord[];
    instructorProviders: ProviderRecord[];
    roleMessages?: string[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userToEdit: UserRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-user-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const mapUserRolesToRoleTags = (userRoles?: UserRoleRecord[]): TagItem[] | undefined => {
    return userRoles?.reduce((items, userRole) => {
        if (userRole.role == null) return items;
        return [
            ...items,
            {
                text: t(RoleTypeDisplayNames[userRole.role.roleType!]),
                value: userRole.role.id!.toString(),
            },
        ];
    }, [] as TagItem[]);
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditUserModal: React.FC<EditUserModalProps> = ({
    availableRoles,
    lockedRoleTypes,
    onUserEditFormSave,
    open,
    organizations,
    providerAdminProviders,
    instructorProviders,
    roleMessages,
    setOpen,
    userToEdit,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const initialUser = useMemo(() => userToEdit ?? new UserRecord(), [userToEdit]);
    const intitialSelectedRoleTypes = useMemo(
        () => mapUserRolesToRoleTags(initialUser.userRoles) ?? [],
        [initialUser.userRoles]
    );
    const [user, setUser] = useState<UserRecord>(initialUser);
    const [selectedRoleTypes, setSelectedRoleTypes] =
        useState<TagItem[]>(intitialSelectedRoleTypes);

    const [dirty, setDirty] = useState(false);

    const canModifyRole = useCallback(
        (roleType: RoleType, userHasRole: boolean): boolean => {
            // Learner Specific Logic
            if (roleType === RoleType.Learner && userHasRole) {
                return false;
            }

            if (lockedRoleTypes?.includes(roleType)) {
                return false;
            }

            return globalState.currentIdentity?.hasAccessToModifyRole(roleType) ?? false;
        },
        [globalState.currentIdentity, lockedRoleTypes]
    );

    const isRoleSelected = useCallback(
        (roleType: RoleType): boolean => {
            const roleTypeRoleId = availableRoles.find((role) => role.roleType === roleType)?.id;
            return selectedRoleTypes.some((role) => role.value === roleTypeRoleId?.toString());
        },
        [availableRoles, selectedRoleTypes]
    );

    const userHasClientAdminRole = useMemo(() => {
        return isRoleSelected(RoleType.ClientAdmin);
    }, [isRoleSelected]);

    const userHasENProviderAdminRole = useMemo(() => {
        return isRoleSelected(RoleType.AenAdministrator);
    }, [isRoleSelected]);

    const userHasInstructorRole = useMemo(() => {
        return isRoleSelected(RoleType.Instructor);
    }, [isRoleSelected]);

    // Create a list of tags for each role type in roles
    const roleTags = useMemo((): TagItem[] => {
        return availableRoles.map((role) => {
            const roleCurrentlySelected = isRoleSelected(role.roleType!);

            const userHasRole =
                initialUser.userRoles?.some((userRole) => userRole.role?.id === role.id) ?? false;

            const roleChangePermitted = canModifyRole(role.roleType!, userHasRole);

            return {
                text: t(RoleTypeDisplayNames[role.roleType!]),
                value: role.id!.toString(),
                selected: roleCurrentlySelected,
                disabled: !roleChangePermitted,
                locked: lockedRoleTypes?.includes(role.roleType!),
            };
        });
    }, [availableRoles, canModifyRole, initialUser.userRoles, isRoleSelected, lockedRoleTypes]);

    const organizationOptions: SelectOption[] = organizations.map(
        (o): SelectOption => ({
            text: o.name,
            value: o.id!.toString(),
        })
    );

    const getProviderDisplayName = (provider?: ProviderRecord): string => {
        if (provider == null) return "";
        return `${provider.name}${!provider.isActive ? ` (${t("inactive")})` : ""}`;
    };

    const providerAdminProviderOptions: SelectOption[] = providerAdminProviders.map(
        (p): SelectOption => ({
            text: getProviderDisplayName(p),
            value: p.id!.toString(),
        })
    );
    const instructorProviderOptions: SelectOption[] = instructorProviders.map(
        (p): SelectOption => ({
            text: getProviderDisplayName(p),
            value: p.id!.toString(),
        })
    );

    // -------------------------------------------------------------------------------------------------
    // #region Validation
    // -------------------------------------------------------------------------------------------------

    const validateClientAdminRequirements = useCallback(() => {
        return isRoleSelected(RoleType.ClientAdmin) ? user.organizationId != null : true;
    }, [isRoleSelected, user.organizationId]);

    const validateENProviderAdminRequirements = useCallback(() => {
        return userHasENProviderAdminRole ? user.providerId != null : true;
    }, [user.providerId, userHasENProviderAdminRole]);

    const validateInstructorRequirements = useCallback(() => {
        return userHasInstructorRole ? user.instructorProfile?.status != null : true;
    }, [userHasInstructorRole, user.instructorProfile?.status]);

    const userIsValid = useMemo(() => {
        return (
            StringUtils.isValidEmail(user.email) &&
            validateClientAdminRequirements() &&
            validateENProviderAdminRequirements() &&
            validateInstructorRequirements()
        );
    }, [
        user.email,
        validateClientAdminRequirements,
        validateENProviderAdminRequirements,
        validateInstructorRequirements,
    ]);

    // #endregion Validation

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const updateUser = (values: Partial<User>): void => {
        setUser(user.with(values));
        setDirty(true);
    };

    const handleOrganizationChange = (value: string): void => {
        const option: SelectOption = organizationOptions.find(
            (op: SelectOption): boolean => op.value === value
        ) ?? {
            text: "",
            value: "",
        };

        updateUser({ organizationId: Number(option.value) });
    };

    const handleENProviderAdminChange = (value: string): void => {
        const option: SelectOption = providerAdminProviderOptions.find(
            (op: SelectOption): boolean => op.value === value
        ) ?? {
            text: "",
            value: "",
        };

        updateUser({ providerId: Number(option.value) });
    };

    const handleInstructorProviderChange = (value: string): void => {
        const option: SelectOption = instructorProviderOptions.find(
            (op: SelectOption): boolean => op.value === value
        ) ?? {
            text: "",
            value: "",
        };

        const instructorProfileWithChanges = user.instructorProfile?.with({
            providerId: StringUtils.hasValue(option.value) ? Number(option.value) : undefined,
        });
        updateUser({ instructorProfile: instructorProfileWithChanges });
    };

    const handleExcludeFromDirectoryChange = (value: boolean): void => {
        const instructorProfileWithChanges = user.instructorProfile?.with({
            excludeFromAenDirectory: value,
        });
        updateUser({ instructorProfile: instructorProfileWithChanges });
    };

    const handleInstructorStatusChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const status = Number(e.target.value) as InstructorProfileStatus;
        const instructorProfileWithChanges = user.instructorProfile?.with({
            status: status,
        });
        updateUser({ instructorProfile: instructorProfileWithChanges });
    };

    const handleTagClick = (selected: boolean, text: string, value: string) => {
        const updatedRoles = [...selectedRoleTypes];
        if (selected) {
            updatedRoles.push({
                selected: selected,
                text: text,
                value: value,
            });
        } else {
            updatedRoles.splice(
                updatedRoles.findIndex((role) => role.value === value),
                1
            );
        }

        setSelectedRoleTypes(updatedRoles);

        //Clearing out the organization here causes an issue with state re-rendering
        const clientAdminRoleId = availableRoles.find(
            (role) => role.roleType === RoleType.ClientAdmin
        )?.id;

        if (value === clientAdminRoleId?.toString() && !selected) {
            updateUser({ organizationId: undefined });
        }

        const enProviderAdminRoleId = availableRoles.find(
            (role) => role.roleType === RoleType.AenAdministrator
        )?.id;

        if (value === enProviderAdminRoleId?.toString() && !selected) {
            updateUser({ providerId: undefined });
        }

        const instructorRoleId = availableRoles.find(
            (role) => role.roleType === RoleType.Instructor
        )?.id;

        if (value === instructorRoleId?.toString() && !selected) {
            updateUser({
                instructorProfile: user.instructorProfile?.with({
                    providerId: undefined,
                    excludeFromAenDirectory: false,
                }),
            });
        }

        if (value === instructorRoleId?.toString() && selected) {
            updateUser({
                instructorProfile:
                    user.instructorProfile == null
                        ? new InstructorProfileRecord()
                        : user.instructorProfile,
            });
        }
    };

    const handleSave = async () => {
        const originalRoleIds = initialUser.userRoles?.flatMap((ur) => ur.role?.id!);
        const selectedRoleIds = selectedRoleTypes.map((role) => Number(role.value));
        const rolesToAdd = selectedRoleIds?.filter((id) => !originalRoleIds?.includes(id));
        const userRoleIdsToRemove =
            initialUser.userRoles
                ?.filter((ur) => !selectedRoleIds?.includes(ur.role?.id!))
                ?.map((ur) => ur.id!) ?? [];

        if (await onUserEditFormSave?.(user, rolesToAdd, userRoleIdsToRemove)) {
            // Perform Update Success Clean up.
            onModalClose();
            ToastManager.success(t("userHasBeenUpdatedSuccessfully"));
        } else {
            // Inform user of failure.
            ToastManager.error(t("failedToUpdateUser"));
        }
    };

    // #endregion Event Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Modal/Form Controls
    // -------------------------------------------------------------------------------------------------

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled: !userIsValid,
            buttonText: t("saveChanges"),
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setUser(initialUser);
        setSelectedRoleTypes(intitialSelectedRoleTypes);
        setDirty(false);
    };

    const onModalClose = (): void => {
        resetForm();
        setOpen(false);
    };

    // #endregion Modal/Form Controls

    useEffect(() => setUser(initialUser), [initialUser]);
    useEffect(() => setSelectedRoleTypes(intitialSelectedRoleTypes), [intitialSelectedRoleTypes]);

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editUser")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__grid`}>
                <FormTextInput
                    disabled={true}
                    ariaLabelledBy={t("firstName")}
                    autoFocus={true}
                    formFieldName="firstName"
                    id="firstName"
                    label={t("firstName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("firstName")}
                    value={user.firstName}
                    type={InputTypes.Text}
                />
                <FormTextInput
                    disabled={true}
                    ariaLabelledBy={t("lastName")}
                    formFieldName="lastName"
                    id="lastName"
                    label={t("lastName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("lastName")}
                    value={user.lastName}
                    type={InputTypes.Text}
                />
            </div>
            <FormTextInput
                ariaLabelledBy={t("email")}
                disabled={true}
                formFieldName="email"
                id="email"
                label={t("email")}
                maxLength={100}
                onChange={() => {}}
                placeholder={t("email")}
                type={InputTypes.Email}
                value={user.email}
            />
            <div className={`${CSS_CLASS_NAME}__section`}>
                <Paragraph size={ParagraphSize.Small}>{t("roles")}</Paragraph>
                <TagList id="roles" items={roleTags} onTagSelected={handleTagClick} />
                {roleMessages?.map((message, index) => (
                    <Paragraph key={index} size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                        {message}
                    </Paragraph>
                ))}
            </div>
            {userHasClientAdminRole && (
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <FormSearchSelect
                        ariaLabelledBy={t("associateThisUserToAnExistingOrganization")}
                        disabled={
                            !globalState.currentIdentity?.hasAccessToModifyRole(
                                RoleType.ClientAdmin
                            ) ?? true
                        }
                        formFieldName="associatedOrganization"
                        id="associatedOrganization"
                        label={t("associateThisUserToAnExistingOrganization")}
                        placeholder={t("searchByNameOrId")}
                        required={true}
                        defaultText={t("searchByNameOrId")}
                        onChange={handleOrganizationChange}
                        options={organizationOptions}
                        selectedOption={{
                            text:
                                organizations.find((o) => o.id === user.organizationId)?.name ?? "",
                            value: user.organizationId?.toString() ?? "",
                        }}
                    />

                    <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                        {t("organizationsNeedToBeAddedBeforeTheyCanBeAssignedToAUser")}
                    </Paragraph>
                </div>
            )}
            {userHasENProviderAdminRole && (
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <FormSearchSelect
                        ariaLabelledBy={t("associatedENProvider")}
                        defaultText={t("searchByNameOrId")}
                        disabled={
                            !globalState.currentIdentity?.hasAccessToModifyRole(
                                RoleType.AenAdministrator
                            )
                        }
                        formFieldName="associatedProvider"
                        id="associatedProvider"
                        label={t("associatedENProvider")}
                        onChange={handleENProviderAdminChange}
                        options={providerAdminProviderOptions}
                        placeholder={t("searchByNameOrId")}
                        required={true}
                        selectedOption={{
                            text: getProviderDisplayName(
                                providerAdminProviders.find((o) => o.id === user.providerId)
                            ),
                            value: user.providerId?.toString() ?? "",
                        }}
                    />
                    <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                        {t("enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser")}
                    </Paragraph>
                </div>
            )}

            {userHasInstructorRole && (
                <>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <FormSelect
                            ariaLabelledBy={t("instructorStatus")}
                            formFieldName={t("instructorStatus")}
                            id="instructorStatus"
                            label={t("instructorStatus")}
                            disabled={
                                !globalState.currentIdentity?.hasAccessToModifyRole(
                                    RoleType.Instructor
                                )
                            }
                            onChange={handleInstructorStatusChange}
                            options={EnumUtils.numericEnumToSelectOptions(
                                InstructorProfileStatus,
                                InstructorProfileStatusDisplayNames
                            )}
                            required={true}
                            value={user.instructorProfile?.status?.toString()}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <FormSearchSelect
                            ariaLabelledBy={t("optionalAenProviderAssociationForInstructor")}
                            defaultText={t("searchByNameOrId")}
                            disabled={
                                !globalState.currentIdentity?.hasAccessToModifyRole(
                                    RoleType.Instructor
                                )
                            }
                            formFieldName="providerAssociation"
                            id="providerAssociation"
                            includeEmptyOption={true}
                            label={t("optionalAenProviderAssociationForInstructor")}
                            onChange={handleInstructorProviderChange}
                            options={instructorProviderOptions}
                            placeholder={t("searchByNameOrId")}
                            selectedOption={{
                                text: getProviderDisplayName(
                                    instructorProviders.find(
                                        (o) => o.id === user.instructorProfile?.providerId
                                    )
                                ),
                                value: user.instructorProfile?.providerId?.toString() ?? "",
                            }}
                        />
                        <Paragraph size={ParagraphSize.XSmall} style={ParagraphStyle.Light}>
                            {t("enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser")}
                        </Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <ToggleLabel
                            checked={user.instructorProfile?.excludeFromAenDirectory ?? false}
                            disabled={
                                !globalState.currentIdentity?.hasAccessToModifyRole(
                                    RoleType.Instructor
                                )
                            }
                            direction={ToggleLabelDirection.Right}
                            id="exclude-from-directory"
                            label={t("excludeFromAenProviderDirectoryAndSearch")}
                            onToggle={() =>
                                handleExcludeFromDirectoryChange(
                                    !user.instructorProfile?.excludeFromAenDirectory
                                )
                            }
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditUserModal };

// #endregion Exports
