import React, { useMemo } from "react";
import { Card, CardStyle } from "components/card/card";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { EvaluationManager } from "components/evaluations/evaluation-manager/evaluation-manager";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { SubmittedEvaluation } from "components/evaluations/submitted-evaluation/submitted-evaluation";
import { useEnrollmentUnits } from "utilities/hooks/models/enrollments/use-enrollment-units";
import { useParams } from "react-router-dom";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { useEnrollmentContext } from "utilities/contexts/use-enrollment-context";
import { t } from "utilities/localization/t";
import "./learner-online-learning-evaluation-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningEvaluationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-evaluation-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningEvaluationPage: React.FC<LearnerOnlineLearningEvaluationPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningEvaluationPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { enrollmentId: enrollmentIdParam } = useParams();
        const enrollmentId: number = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { record: enrollment, setRecord: setEnrollment } = useEnrollmentContext();

        const { enrollmentUnits } = useEnrollmentUnits({
            enrollmentId,
        });

        const evaluationId = enrollment?.evaluation?.id ?? 0;

        const handleEvaluationSubmission = (evaluation: EvaluationRecord) => {
            const enrollmentWithChanges = enrollment.with({
                evaluation: evaluation,
                percentComplete: 100, // for OLL Evaluation is the last step
            });
            setEnrollment(enrollmentWithChanges);
        };

        const unitsAreComplete = (): boolean => {
            return enrollmentUnits.every(
                (enrollmentUnit) => enrollmentUnit.percentComplete === 100
            );
        };

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Evaluation} size={IconSizes.Large} />
                    <Heading size={HeadingSize.Small}>{t("evaluation")}</Heading>
                </div>
                <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                    {t(
                        "nfpaIsCommittedToProvidingYouWithHighqualityContentToHelpMeetYourLearningNeedsWeAppreciateYourFeedbackYourAnswersAndCommentsWillProvideValuableInputAsWeContinueToEnhanceOurOfferings"
                    )}
                </Paragraph>
                {!unitsAreComplete() && (
                    <Card style={CardStyle.Empty}>
                        <Icon type={Icons.Lock} size={IconSizes.Large} />
                        <EmptyText spacing={EmptyTextSpacing.None}>
                            {t(
                                "theEvaluationWillBecomeAvailableFollowingTheCompletionOfTheSyllabus"
                            )}
                        </EmptyText>
                    </Card>
                )}
                {unitsAreComplete() && evaluationId > 0 && (
                    <SubmittedEvaluation evaluationId={evaluationId} />
                )}
                {unitsAreComplete() && enrollmentId > 0 && evaluationId === 0 && (
                    <EvaluationManager
                        enrollmentId={enrollment?.id!}
                        evaluationTemplateId={enrollment.product?.evaluationTemplateId!}
                        handleEvaluationSubmission={handleEvaluationSubmission}
                    />
                )}
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningEvaluationPage };

// #endregion Exports
