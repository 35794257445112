import React, { useMemo, useState } from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { EditContractModal } from "components/contracts/edit-contract-modal/edit-contract-modal";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Tab } from "components/tabs/tabs/tab/tab";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { RouteUtils } from "utilities/route-utils";
import { UserContractOLLDetailEnrollment } from "./user-contract-oll-detail-enrollment/user-contract-oll-detail-enrollment";
import { UserContractOLLDetailInfo } from "./user-contract-oll-detail-info/user-contract-oll-detail-info";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useParams } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./user-contracts-oll-detail-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsOLLDetailPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-oll-detail-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsOLLDetailPage: React.FC<UserContractsOLLDetailPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsOLLDetailPage
)((): JSX.Element => {
    const { id: orgIdParam, contractId: contractIdParam } = useParams();
    const orgId = useMemo(() => NumberUtils.parseInt(orgIdParam) ?? 0, [orgIdParam]);
    const contractId = useMemo(() => NumberUtils.parseInt(contractIdParam) ?? 0, [contractIdParam]);
    const [showEditContractModal, setShowEditContractModal] = useState(false);

    const { record: globalState } = useGlobalState();

    const canModifyContract =
        globalState?.currentIdentity?.isCurrentlyInNfpaRole() &&
        globalState?.currentIdentity?.role?.roleType !== RoleType.NfpaSupport;

    const { contract, updateContract } = useContract({
        contractId: contractId,
        includeProduct: true,
    });

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: <UserContractOLLDetailInfo contract={contract} />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("basicInformation"),
            },
        },
        {
            contents: <UserContractOLLDetailEnrollment />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("enrollment"),
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <div className={`${CSS_CLASS_NAME}__header-buttons`}>
                                <ButtonIcon
                                    linkPath={RouteUtils.replacePathParams(
                                        sitemap.admin.userManagement.contracts.onlineLearning.list,
                                        { id: orgId }
                                    )}
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    buttonType={ButtonType.Link}
                                    iconType={Icons.ChevronLeft}
                                    text={t("backToAllOnlineLearningContracts")}
                                />
                                {canModifyContract && (
                                    <Button
                                        onClick={() => setShowEditContractModal(true)}
                                        text={t("editContract")}
                                        size={ButtonSize.Small}
                                    />
                                )}
                            </div>
                            <h2>
                                {t("contractContractContractNumber", {
                                    contractContractNumber: contract.contractNumber,
                                })}
                            </h2>
                        </div>
                        <TabbedContainer tabs={tabs} />
                    </SkipNavContent>
                </div>
            </div>
            <EditContractModal
                contract={contract}
                open={showEditContractModal}
                setOpen={setShowEditContractModal}
                handleContractDetailsChange={updateContract}
            />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsOLLDetailPage };

// #endregion Exports
