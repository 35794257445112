import React, { useMemo } from "react";
import { EventScheduleFooter } from "components/events/event-schedule/event-schedule-summary/event-schedule-footer/event-schedule-footer";
import { ProcessNavigationFooter } from "components/processes/navigation/process-navigation-footer/process-navigation-footer";
import { ProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditEventLayoutFooterProps<T> {
    cssClassName?: string;
    processNavigator: ProcessNavigator<T>;
    editPermitted?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditEventLayoutFooter = <T,>(
    props: React.PropsWithChildren<EditEventLayoutFooterProps<T>>
): JSX.Element => {
    const event = useEventActiveRecordContext();
    const footerComponent = useMemo(() => {
        if (event?.id == null) {
            return <></>;
        }

        const schedulePath = RouteUtils.replacePathParams(sitemap.admin.event.edit.schedule, {
            id: event.id,
        });

        if (props.processNavigator?.currentStep?.path === schedulePath) {
            return <EventScheduleFooter editPermitted={props.editPermitted} event={event} />;
        }
        return <></>;
    }, [event, props.editPermitted, props.processNavigator?.currentStep?.path]);

    return (
        <ProcessNavigationFooter
            cssClassName={props.cssClassName}
            processNavigator={props.processNavigator}
            saveAndExitPath={sitemap.admin.event.list}
            footerComponent={footerComponent}>
            {props.children}
        </ProcessNavigationFooter>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditEventLayoutFooter };

// #endregion Exports
