import React, { useCallback, useMemo, useState } from "react";
import { Card } from "components/card/card";
import { t } from "utilities/localization/t";
import nfpaLinkColor from "assets/images/nfpa-link-color.png";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Enrollment } from "models/interfaces/enrollments/enrollment";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { ToastManager } from "utilities/toast/toast-manager";
import { EnrollmentService } from "utilities/services/enrollments/enrollment-service";
import { ClaimLinkTrialRecord } from "models/view-models/enrollments/claim-link-trial-record";
import { Modal, ModalAction } from "components/modal/modal";
import { SubscriptionCreationStatus } from "models/enumerations/enrollments/subscription-creation-status";
import { StringUtils } from "andculturecode-javascript-core";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ManualLinkTrialDetails } from "models/interfaces/link-trial/manual-link-trial-details";
import { DateUtils } from "utilities/date-utils";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import "./link-trial-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LinkTrialCardProps {
    enrollment: Enrollment;
    manualTrial?: ManualLinkTrialDetails;
    trialAvailableDate?: Date;
}
interface TrialCardInfo {
    headingText: string;
    paragraphText: string | JSX.Element;
    buttonText: string;
    buttonLinkPath?: string;
    onButtonClick?: () => void;
}

// #endregion Interfaces

// #region Enums

enum LinkTrialBannerType {
    ManualToken = 0,
    TrialNotClaimed = 1,
    TrialActive = 2,
    TrialExpired = 3,
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "link-trial-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LinkTrialCard: React.FC<LinkTrialCardProps> = ({
    enrollment,
    manualTrial,
    trialAvailableDate,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const [modalState, setModalState] = useState(false);
    const [modalText, setModalText] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [linkTrialResponse, setLinkTrialResponse] = useState<ClaimLinkTrialRecord>(
        new ClaimLinkTrialRecord({
            status: SubscriptionCreationStatus.Unknown,
            expiresOn: enrollment.linkTrialExpiresOn,
        })
    );

    const { patch: patchClaimLinkTrialApi } = EnrollmentService.usePatchClaimLinkTrial();

    const nfpaLinkUrl = globalState.systemSettings!.linkBaseUrl;
    const nfpaLinkFreeTrialUrl = useMemo(() => {
        if (!StringUtils.hasValue(globalState?.systemSettings?.linkBaseUrl)) {
            return "";
        }

        return `${
            globalState?.systemSettings?.linkBaseUrl ?? ""
        }/sign-up/register/training-contract/${manualTrial?.id}`;
    }, [globalState?.systemSettings?.linkBaseUrl, manualTrial?.id]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => {
                setModalState(false);
            },
            style: ButtonStyle.Transparent,
        },
        {
            buttonText: t("continueToLink"),
            onClick: () => {
                window.open(nfpaLinkUrl, "_blank");
                setModalState(false);
            },
            style: ButtonStyle.Secondary,
        },
    ];

    const copyTokenToClipboard = useCallback(() => {
        if (!manualTrial) {
            return;
        }
        navigator.clipboard.writeText(manualTrial?.token);
        ToastManager.info(t("copiedToClipboard"));
    }, [manualTrial]);

    const getModalText = (status: SubscriptionCreationStatus) => {
        if (status === SubscriptionCreationStatus.ValidSubscriptionOwner) {
            return t("itLooksLikeYouAlreadyHaveAnActiveLinkSubscriptionDoYouWantToGoThereNow");
        }

        if (status === SubscriptionCreationStatus.ExistingUserRoleForTrainingContractId) {
            return t("yourTrialHasAlreadyBeenClaimedDoYouWantToGoThereNow");
        }

        return t("yourTrialHasBeenClaimedSuccessfullyDoYouWantToGoThereNow");
    };

    const claimLinkFreeTrial = useCallback(async () => {
        try {
            setLoading(true);
            const response = await patchClaimLinkTrialApi({ id: enrollment.id! });
            setLoading(false);
            if (response.status === 200 && response.resultObject) {
                const claimLinkTrial = response.resultObject;

                setLinkTrialResponse(claimLinkTrial);

                setModalText(getModalText(claimLinkTrial.status));
                setModalState(true);
            }
        } catch (error) {
            ToastManager.error(t("thereWasAnIssueClaimingYourTrial"));
        }
    }, [enrollment.id, patchClaimLinkTrialApi]);

    /////////////////////////////////////////////////
    // Determine the Banner Type and Display Info
    /////////////////////////////////////////////////

    const trialNotYetAvailable = useMemo(() => {
        return trialAvailableDate != null && trialAvailableDate > new Date();
    }, [trialAvailableDate]);

    const linkTrialBannerType = useMemo(() => {
        // Note, the order of these checks is important.
        // The ManualToken check should be first, as it overrides the other checks.
        if (manualTrial) {
            return LinkTrialBannerType.ManualToken;
        }

        if (linkTrialResponse.trialExpired()) {
            return LinkTrialBannerType.TrialExpired;
        }

        if (linkTrialResponse.trialClaimed() && !linkTrialResponse.trialExpired()) {
            return LinkTrialBannerType.TrialActive;
        }

        // Finally, if we get here, we can assume this is NOT a manual token and the trial has not been claimed.
        return LinkTrialBannerType.TrialNotClaimed;
    }, [linkTrialResponse, manualTrial]);

    const cardInfo = useMemo((): TrialCardInfo => {
        switch (linkTrialBannerType) {
            case LinkTrialBannerType.ManualToken:
                return {
                    headingText: t("freeAccessForNumberDays", {
                        number: 90,
                    }).toUpperCase(),
                    paragraphText: (
                        <>
                            <span>{t("useCode")}</span>{" "}
                            <span
                                className={`${CSS_CLASS_NAME}__token`}
                                onClick={copyTokenToClipboard}>
                                {manualTrial?.token}{" "}
                                <Icon type={Icons.FileCopy} size={IconSizes.Small} />
                            </span>{" "}
                            <span>{t("toGetInstantAccessToLiNK")}</span>
                        </>
                    ),
                    buttonText: t("claimNow"),
                    buttonLinkPath: nfpaLinkFreeTrialUrl,
                };
            case LinkTrialBannerType.TrialExpired:
                return {
                    headingText: t("theresMoreToExploreWithLink").toUpperCase(),
                    paragraphText: t("joinTodayToGetInstantAccessToOver1400NfpaCodesAndStandards"),
                    buttonText: t("joinLink"),
                    buttonLinkPath: nfpaLinkUrl,
                };
            case LinkTrialBannerType.TrialActive:
                return {
                    headingText: t("numDaysOfFreeAccessRemaining", {
                        num: DateUtils.daysBetween(linkTrialResponse.expiresOn!, new Date()),
                    }).toUpperCase(),
                    paragraphText: t("youStillHaveInstantAccessToOver1400NfpaCodesAndStandards"),
                    buttonText: t("exploreLink"),
                    buttonLinkPath: nfpaLinkUrl,
                };
            case LinkTrialBannerType.TrialNotClaimed:
            default:
                return {
                    headingText: t("freeAccessForNumberDays", {
                        number: 90,
                    }).toUpperCase(),
                    paragraphText: t("newUsersGetInstantAccessToOver1400NfpaCodesAndStandards"),
                    buttonText: t("claimNow"),
                    onButtonClick: claimLinkFreeTrial,
                };
        }
    }, [
        claimLinkFreeTrial,
        copyTokenToClipboard,
        linkTrialBannerType,
        linkTrialResponse.expiresOn,
        manualTrial?.token,
        nfpaLinkFreeTrialUrl,
        nfpaLinkUrl,
    ]);

    return (
        <Card cssClassName={CSS_CLASS_NAME} stacked={false}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <img
                    className={`${CSS_CLASS_NAME}__content__image`}
                    src={nfpaLinkColor}
                    alt={t("nationalFireProtectionAssociation")}
                />
                <div className={`${CSS_CLASS_NAME}__content__text`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H3}>
                        {cardInfo.headingText}
                    </Heading>

                    <Paragraph style={ParagraphStyle.Default} size={ParagraphSize.Small}>
                        {cardInfo.paragraphText}
                    </Paragraph>
                </div>
                {StringUtils.hasValue(cardInfo.buttonLinkPath) ? (
                    <Button
                        disabled={loading || trialNotYetAvailable}
                        cssClassName={`${CSS_CLASS_NAME}__claim`}
                        type={ButtonType.Link}
                        linkPath={cardInfo.buttonLinkPath}
                        linkPathType={AnchorPathType.External}
                        text={cardInfo.buttonText}
                    />
                ) : (
                    <Tooltip
                        content={t("yourTrialWillBeAvailableOnTheFirstDayOfTheEvent")}
                        placement={TooltipPlacement.Top}>
                        <span>
                            <Button
                                disabled={loading || trialNotYetAvailable}
                                onClick={cardInfo.onButtonClick}>
                                {cardInfo.buttonText}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__content__striped__border`}></div>
            <Modal
                isOpen={modalState}
                actions={modalActionArray}
                cssClassName={`${CSS_CLASS_NAME}__modal`}>
                <div>
                    <Paragraph size={ParagraphSize.Small}>{modalText}</Paragraph>
                </div>
            </Modal>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LinkTrialCard };

// #endregion Exports
