import React, { useMemo } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { HeaderBanner } from "components/header-banner/header-banner";
import { InstructorProfileStatus } from "models/instructors/instructor-profile-status";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useInstructorProfile } from "utilities/hooks/models/instructors/use-instructor-profile";
import { InstructorPendingDashboard } from "./instructor-pending-dashboard/instructor-pending-dashboard";
import { t } from "utilities/localization/t";
import { InstructorDefaultDashboard } from "./instructor-default-dashboard/instructor-default-dashboard";
import "./instructor-dashboard-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorDashboardPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-dashboard-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorDashboardPage: React.FC<InstructorDashboardPageProps> = validatePageAccess(
    AccessControlKeys.InstructorDashboardPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const userFirstName = useMemo(
        () => globalState?.currentIdentity?.user?.firstName,
        [globalState?.currentIdentity?.user?.firstName]
    );

    const { instructorProfile } = useInstructorProfile({
        userId: globalState.currentIdentity?.userId() ?? -1,
    });

    return (
        <>
            <HeaderBanner
                title={t("twelcomeUserFirstName", {
                    twelcome: t("welcome"),
                    userFirstName: userFirstName,
                })}
            />
            <div className={CSS_CLASS_NAME}>
                {instructorProfile?.status === InstructorProfileStatus.Pending ? (
                    <InstructorPendingDashboard />
                ) : (
                    <InstructorDefaultDashboard />
                )}
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorDashboardPage };

// #endregion Exports
