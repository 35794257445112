import axios from "axios";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    ServiceFactory,
    ServiceHookFactory,
    useCancellablePromise,
} from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "courseversions";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const activateEndpoint: string = `${baseEndpoint}/:id/activate`;
const resourceType: typeof CourseVersionRecord = CourseVersionRecord;
const getLatestResourceEndpoint: string = `${baseEndpoint}/:id/latest`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchCourseVersionService = (
    params: PatchCourseVersionServiceParams,
    data: PatchCourseVersionServiceData
) => Promise<ServiceResponse<CourseVersionRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetCourseVersionPathParams {
    id: number;
}
export interface GetCourseVersionQueryParams {
    includeContent?: boolean;
    includeScormPackage?: boolean;
    includeArchive?: boolean;
    includeEnrollmentCounts?: boolean;
}

export interface GetLatestCourseVersionPathParams {
    id: number;
}
export interface GetLatestCourseVersionQueryParams {
    includeContent?: boolean;
    includeScormPackage?: boolean;
    includeArchive?: boolean;
}

export interface ListCourseVersionsQueryParams {
    courseId: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
    includeScormPackage?: boolean;
    includeEnrollmentCounts?: boolean;
}

export interface PatchCourseVersionServiceParams {
    id: number;
}

export interface PatchCourseVersionServiceData {
    changeLogDescription: string;
    applyToInProgressLearners: boolean;
}

export interface UpdateCourseVersionPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CourseVersionService = {
    /**
     * Create a Course
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update a Course
     */
    update: ServiceFactory.update<CourseVersionRecord, UpdateCourseVersionPathParams>(
        resourceType,
        resourceEndpoint
    ),

    useGetLatest: ServiceHookFactory.useGet<
        CourseVersionRecord,
        GetLatestCourseVersionPathParams,
        GetLatestCourseVersionQueryParams
    >(resourceType, getLatestResourceEndpoint),

    useActivate: (): { activate: PatchCourseVersionService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchCourseVersionService = (
            params: PatchCourseVersionServiceParams,
            data: PatchCourseVersionServiceData
        ): Promise<ServiceResponse<CourseVersionRecord>> => {
            const endPointWithPatchRouteReplaced = activateEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, data)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: PatchCourseVersionServiceParams,
            data: PatchCourseVersionServiceData
        ): Promise<ServiceResponse<CourseVersionRecord>> {
            return cancellablePromise(servicePatch(params, data)) as Promise<
                ServiceResponse<CourseVersionRecord>
            >;
        }

        return { activate: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        CourseVersionRecord,
        GetCourseVersionPathParams,
        GetCourseVersionQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<CourseVersionRecord, ListCourseVersionsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<CourseVersionRecord, UpdateCourseVersionPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionService };

// #endregion Exports
