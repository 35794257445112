import React from "react";
import { EventDay } from "models/interfaces/events/event-day";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { t } from "utilities/localization/t";
import "./event-day-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayFormProps {
    eventDay: EventDayRecord;
    onEventDayChange: (eventDay: EventDayRecord) => void;
    readOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-day-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayForm: React.FC<EventDayFormProps> = (props: EventDayFormProps) => {
    const updateEventDay = (values: Partial<EventDay>): void => {
        props.onEventDayChange(props.eventDay.with(values));
    };

    const handleNameChange = (eventDayName: React.ChangeEvent<HTMLInputElement>): void => {
        updateEventDay({ name: eventDayName.target.value });
    };

    const handleEventDateChange = (date?: Date): void => {
        if (date == null) {
            return;
        }

        updateEventDay({
            eventDayOfTheMonth: date.getDate(),
            eventMonth: date.getMonth(),
            eventYear: date.getFullYear(),
        });
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <FormTextInput
                    ariaLabelledBy={t("eventDayName")}
                    autoFocus={false}
                    cssClassName={`${CSS_CLASS_NAME}__event-day-name`}
                    disabled={props.readOnly ?? false}
                    formFieldName="eventDayName"
                    id="eventDayName"
                    label={t("name")}
                    maxLength={60}
                    onChange={handleNameChange}
                    placeholder={t("enterDayName")}
                    required={false}
                    type={InputTypes.Text}
                    value={props.eventDay?.name}
                />
                <FormCalendarDatePicker
                    cssClassName={`${CSS_CLASS_NAME}__event-day-date`}
                    readOnly={props.readOnly ?? false}
                    formFieldName="eventDate"
                    label={t("date")}
                    required={true}
                    selectedDate={props.eventDay.getEventDate()}
                    onChange={handleEventDateChange}
                />
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayForm };

// #endregion Exports
