import { useEffect, useState, useCallback } from "react";
import { InstructorApprovedProductRecord } from "models/view-models/instructors/instructor-approved-product-record";
import {
    CreateInstructorApprovedProductParams,
    InstructorApprovedProductsService,
} from "utilities/services/instructors/instructor-approved-product-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { ProductRecord } from "models/view-models/products/product-record";
import { CollectionUtils } from "utilities/collection-utils";
// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingTheInstructorsApprovedToTeachProducts";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseInstructorApprovedProductsHook {
    error?: Error;
    isLoading: boolean;
    instructorApprovedProducts: InstructorApprovedProductRecord[];
    instructorApprovedProductsAvailableForAen: InstructorApprovedProductRecord[];
    rowCount: number;
    addProductApprovals: (products: ProductRecord[]) => Promise<boolean>;
    revokeInstructorApprovedProduct: (
        instructorApprovedProduct: InstructorApprovedProductRecord
    ) => Promise<boolean>;
}

interface UseInstructorApprovedProductsHookOptions {
    onError?: (error: Error) => void;
    userId: number;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useInstructorApprovedProducts({
    onError,
    userId,
    skip,
    take,
}: UseInstructorApprovedProductsHookOptions): UseInstructorApprovedProductsHook {
    const { list } = InstructorApprovedProductsService.useList();
    const { patch: revokeApi } = InstructorApprovedProductsService.useRevoke();
    const { create: productApprovalCreateApi } = InstructorApprovedProductsService.useCreate();
    const [instructorApprovedProducts, setInstructorApprovedProducts] = useState<
        InstructorApprovedProductRecord[]
    >([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const instructorApprovedProductsAvailableForAen = instructorApprovedProducts.filter(
        (approvedProducts) => approvedProducts.product?.availableForAEN
    );

    const fetchInstructorApprovedProducts = useCallback(async (): Promise<void> => {
        if (userId < 1) {
            setInstructorApprovedProducts([]);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const instructorApprovedProductsResponse = await list({
                userId: userId,
                skip: skip,
                take: take,
            });
            setIsLoading(false);

            if (
                instructorApprovedProductsResponse?.resultObjects == null ||
                instructorApprovedProductsResponse?.results == null ||
                instructorApprovedProductsResponse.results.hasErrors()
            ) {
                setError(t(ERROR_LOADING));
                return;
            }

            setInstructorApprovedProducts(instructorApprovedProductsResponse.resultObjects);
            setRowCount(instructorApprovedProductsResponse.rowCount);
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, setError, skip, take, userId]);

    useEffect(() => {
        fetchInstructorApprovedProducts();
    }, [fetchInstructorApprovedProducts]);

    const addProductApprovals = async (products: ProductRecord[]): Promise<boolean> => {
        try {
            if (userId <= 0 || CollectionUtils.isEmpty(products?.filter((p) => p.id != null))) {
                throw new Error();
            }

            let createApprovalsParams: CreateInstructorApprovedProductParams = {
                userId: userId,
                productIds: products.filter((p) => p.id != null).map((p) => p.id!),
            };

            const createApprovalResponse = await productApprovalCreateApi(createApprovalsParams);
            const createApprovalResult = createApprovalResponse?.result;

            if (createApprovalResult?.resultObject == null || createApprovalResult.hasErrors()) {
                throw new Error(t("creatingGroupUsersFailed"));
            }

            fetchInstructorApprovedProducts();

            ToastManager.success(t("approvedToTeachProductCreatedSuccessfully"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingTheApprovedToTeachProduct"));
            return false;
        }
    };

    const revokeInstructorApprovedProduct = async (
        instructorApprovedProduct: InstructorApprovedProductRecord
    ): Promise<boolean> => {
        try {
            if (instructorApprovedProduct.id == null) {
                return false;
            }

            const revokeResponse = await revokeApi({ id: instructorApprovedProduct.id });

            if (!revokeResponse) {
                throw new Error("");
            }

            fetchInstructorApprovedProducts();

            ToastManager.success(t("approvedToTeachProductRemovedSuccessfully"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueRemovingTheApprovedToTeachProduct"));
            return false;
        }
    };

    return {
        error,
        isLoading,
        instructorApprovedProducts,
        rowCount,
        addProductApprovals,
        revokeInstructorApprovedProduct,
        instructorApprovedProductsAvailableForAen,
    };
}

// #endregion Hook
