import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorProfileStatus } from "models/instructors/instructor-profile-status";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { Language } from "models/enumerations/languages/language";
import { ServiceHookFactory } from "andculturecode-javascript-react";
import { TravelType } from "models/instructors/travel-type";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ListInstructorsQueryParams {
    approvedToTeachProductId?: number;
    country?: string;
    includeAenNetwork?: boolean;
    includeInstructorProfile?: boolean;
    language?: Language;
    onlyInstructorsWithSharedLocation?: boolean;
    providerId?: number;
    searchText?: string;
    skip?: number;
    state?: string;
    status?: InstructorProfileStatus;
    take?: number;
    teachingMethod?: InstructorLedTrainingType;
    travelType?: TravelType;
}

export interface InstructorResourcePathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "instructors";
const resourceType = InstructorRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const InstructorService = {
    useList: ServiceHookFactory.useList<InstructorRecord, ListInstructorsQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { InstructorService };

// #endregion Exports
