import "assets/scss/app.scss";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useIdentity } from "utilities/hooks/use-identity";
import { AuthService } from "utilities/services/auth/auth-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// This hook will attempt to log the user in and build the globalState.

const ERROR_AUTHENTICATING: TranslatedCopy = "thereWasAnIssueAuthenticating";

const useAuthentication = () => {
    const loadingRef = useRef(false);
    const [loginAttemptCompleted, setLoginAttemptCompleted] = useState<boolean>(false);
    const { create: createAuth } = AuthService.useCreate();
    const { setRecord: setGlobalState, record: globalState } = useGlobalState();
    const { getIdentity } = useIdentity();
    const { accounts, instance, inProgress } = useMsal();
    const cookieAlreadyCreated = useMemo(() => globalState.isAuthenticated(), [globalState]);

    const attemptLogin = useCallback(
        async (refreshIdentity = false): Promise<void> => {
            try {
                if (cookieAlreadyCreated && !refreshIdentity) return;
                const authenticateResponse = await createAuth();

                if (
                    authenticateResponse?.result == null ||
                    authenticateResponse.result.hasErrors() ||
                    authenticateResponse.resultObject == null ||
                    !authenticateResponse.resultObject.isSuccessful
                ) {
                    //change this to setError like in use-seeded-users
                    console.log(t(ERROR_AUTHENTICATING));
                    throw new Error(t(ERROR_AUTHENTICATING));
                }

                const identity = await getIdentity(authenticateResponse.resultObject);
                setGlobalState((currentState) => currentState.setIdentity(identity));
            } catch {
                //change this to setError like in use-seeded-users
                console.log(t(ERROR_AUTHENTICATING));
                throw new Error(t(ERROR_AUTHENTICATING));
            } finally {
                loadingRef.current = false;
                setLoginAttemptCompleted(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createAuth, getIdentity, setGlobalState]
    );

    const attemptLoginByAzure = useCallback(async () => {
        if (globalState.azureSettings == null) {
            loadingRef.current = false;
            return;
        }

        const azureClientId = globalState.azureSettings.clientId;

        if (inProgress === InteractionStatus.None) {
            try {
                // Not logged in yet -> let's check if we logged in somewhere else
                if (accounts.length === 0) {
                    const result = await instance.ssoSilent({
                        scopes: [azureClientId],
                    });
                    setAccessToken(result);
                    attemptLogin();
                }
                // Already logged in -> let's try to refresh our token
                else {
                    const result = await instance.acquireTokenSilent({
                        scopes: [azureClientId],
                        account: accounts[0],
                    });
                    setAccessToken(result);
                    attemptLogin();
                }
            } catch (error) {
                // Not signed in to azure
                loadingRef.current = false;
                setLoginAttemptCompleted(true);
            }
        } else {
            loadingRef.current = false;
        }
    }, [globalState.azureSettings, inProgress, accounts, instance, attemptLogin]);

    useEffect(() => {
        const handleAzureRedirect = async () => {
            const response = await instance.handleRedirectPromise();
            if (response == null) return;

            setAccessToken(response);
            attemptLogin();
        };

        if (inProgress === InteractionStatus.HandleRedirect) {
            handleAzureRedirect();
        }
    }, [instance, attemptLogin, loginAttemptCompleted, inProgress, globalState]);

    useEffect(() => {
        if (loginAttemptCompleted || loadingRef.current) return;
        loadingRef.current = true;
        attemptLoginByAzure();
    }, [attemptLoginByAzure, loginAttemptCompleted]);

    return {
        loginAttemptCompleted,
        attemptLogin,
    };
};

const setAccessToken = (result: AuthenticationResult) => {
    const B2C_COOKIE_KEY = "AccessToken";
    document.cookie = `${B2C_COOKIE_KEY}=${
        result.accessToken
    };expires=${result.expiresOn?.toUTCString()};path=/`;
};

export { useAuthentication };
