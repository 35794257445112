import React from "react";
import { useParams } from "react-router-dom";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Button, ButtonType } from "components/buttons/button/button";
import { useState } from "react";
import { useContracts } from "utilities/hooks/models/contracts/use-contracts";
import { ContractType } from "models/enumerations/contracts/contract-type";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./user-contracts-ill-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsILLListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-ill-list-page";
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsILLListPage: React.FC<UserContractsILLListPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsILLListPage
)((): JSX.Element => {
    const routeParams = useParams();
    const orgId = routeParams.id;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");

    const { contracts, rowCount: totalNumberOfContracts } = useContracts({
        organizationId: orgId,
        contractType: ContractType.InstructorLedTraining,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        includeEnrollments: true,
        includeProduct: true,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <h2>{t("instructorLedEventsContracts")}</h2>
                            <div className={`${CSS_CLASS_NAME}__header__actions`}>
                                <div className={`${CSS_CLASS_NAME}__header__actions__search`}>
                                    {/* TODO: v1.2 > Uncomment when we implement this.
                                    <Icon type={Icons.Search} size={IconSizes.Large} />
                                    <FormTextInput
                                        ariaLabelledBy={t("typeToSearchByOppNumber")}
                                        autoFocus={true}
                                        formFieldName="filterOppName"
                                        id="filterOppName"
                                        label={t("typeToSearchByOppNumber")}
                                        labelScreenReaderOnly={true}
                                        maxLength={100}
                                        onChange={() => {}}
                                        placeholder={t("typeToSearchByOppNumber")}
                                        type={InputTypes.Text}
                                        value={""}
                                    />
                                    */}
                                </div>
                                <Button
                                    linkPath={sitemap.admin.event.list}
                                    text={t("goToEvents")}
                                    type={ButtonType.Link}
                                />
                            </div>
                        </div>
                        {/* TODO: v1.2 > Uncomment when we implement this.
                        <DataTable cssClassName={`${CSS_CLASS_NAME}__table`}>
                            <thead>
                                <tr>
                                    <th className="event-name">{t("eventName")}</th>
                                    <th className="product-name">{t("productName")}</th>
                                    <th className="contract-number">{t("contractNumber")}</th>
                                    <th className="lms-id">{t("lmsID")}</th>
                                    <th className="enrollment">{t("enrollment")}</th>
                                    <th className="expiration-date">{t("expirationDate")}</th>
                                    <th className="action">
                                        <span className="sr-only">{t("action")}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="event-name">
                                        Lorem ipsum dolor sit amet lorem...
                                    </td>
                                    <td className="product-name">
                                        NFPA 25, Standard for the Inspection...
                                    </td>
                                    <td className="contract-number">12345</td>
                                    <td className="lms-id">12345</td>
                                    <td className="enrollment">40/50</td>
                                    <td className="expiration-date">03/12/2024</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewEvent")}
                                            />
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.contracts
                                                        .instructorLed.detail,
                                                    { id: orgId, contractId: 123 }
                                                )}
                                                displayName={t("viewContract")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            </tbody>
                        </DataTable>
                    */}
                    </SkipNavContent>
                </div>
            </div>
            <div className="footer">
                {/* TODO: v1.2 > Uncomment when we implement this.
                <Pager
                    footer={true}
                    currentPage={currentPage}
                    totalPages={totalNumberOfContracts / ITEMS_PER_PAGE}
                    onPageClick={onPageClick}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={totalNumberOfContracts}
                />
                */}
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsILLListPage };

// #endregion Exports
