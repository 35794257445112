import React, { useState } from "react";
import { ActivateNewVersionModal } from "../activate-new-version-modal/activate-new-version-modal";
import {
    ActiveStatus,
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    GetProductVersionPathParams,
    GetProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersions } from "utilities/hooks/models/products/use-product-versions";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./product-version-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssessmentProductVersionListProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-version-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssessmentProductVersionList: React.FC<
    AssessmentProductVersionListProps
> = (): JSX.Element => {
    const { record: product } = useProduct();
    const { productVersions, setRefresh } = useProductVersions({
        productId: product.id,
        includeCreatedBy: true,
        includeLastModifiedBy: true,
        includeProductScormPackage: true,
        includeContent: false,
        includeEnrollmentCounts: true,
        //refreshUntilComplete: true,
    });
    const [showActivateVersionModal, setShowActivateVersionModal] = useState<boolean>(false);
    const [activateProductVersion, setActivateProductVersion] = useState<
        ProductVersionRecord | undefined
    >();
    const { get: getProductVersion } = ProductVersionService.useGet();
    const getViewVersionPath = (
        productType: TrainingType,
        productId: number,
        productVersionId: number
    ) => {
        const routePath =
            productType === TrainingType.InstructorLedTraining
                ? sitemap.admin.product.edit.instructorLed.materials.version.edit
                : sitemap.admin.product.edit.assessment.materials.version.edit;

        return RouteUtils.localizePath(
            RouteUtils.replacePathParams(routePath, { id: productId, versionId: productVersionId })
        );
    };
    async function setSelectedProductVersionAsActive(productVersionId: number): Promise<void> {
        //get the product version id from the row
        const pathParams: GetProductVersionPathParams = {
            id: productVersionId,
        };
        const queryParams: GetProductVersionQueryParams = {
            //includeScormPackage: true,
            includeUnit: true,
            includeContent: true,
            includeEvaluationTemplate: true,
            includeProductScormPackage: false,
        };

        const getResponse = await getProductVersion(pathParams, queryParams);
        const getResult = getResponse?.result;
        const editProductVersion = getResult?.resultObject;
        editProductVersion.sortAllChildren();
        setActivateProductVersion(editProductVersion);
        setShowActivateVersionModal(true);
    }

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            {activateProductVersion != null && (
                <ActivateNewVersionModal
                    open={showActivateVersionModal}
                    product={product}
                    setOpen={setShowActivateVersionModal}
                    productVersion={activateProductVersion!}
                    setProductVersion={setActivateProductVersion}
                    setRefresh={setRefresh}
                />
            )}

            <div className={`${CSS_CLASS_NAME}__content`}>
                <DataTable>
                    <thead>
                        <tr>
                            <th className="version">{t("version")}</th>
                            {product.type === TrainingType.InstructorLedTraining && (
                                <th className="live-events">{t("liveEvents")}</th>
                            )}
                            {product.type === TrainingType.InstructorAssessment && (
                                <th className="live-events">{t("instructors")}</th>
                            )}
                            <th className="version-status">{t("versionStatus")}</th>
                            <th className="package-import-status">{t("packageImportStatus")}</th>
                            <th className="date">{t("date")}</th>
                            <th className="changed-by">{t("changedBy")}</th>
                            <th className="action">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productVersions.map((productVersion) => (
                            <tr key={`${productVersion.id}`}>
                                <td className="version">{productVersion.versionId}</td>
                                <td className="live-events">
                                    {productVersion.numberOfEnrolledLearners}
                                </td>
                                <td className="version-status">
                                    <EnumStatusBadge
                                        displayNames={ActiveStatusStatusDisplayNames}
                                        statusMap={ActiveStatusMap}
                                        value={productVersion.status ?? ActiveStatus.Draft}
                                    />
                                </td>
                                <td className="package-import-status">
                                    {productVersion.allAssessmentsAreComplete()
                                        ? t("complete")
                                        : t("incomplete")}
                                </td>
                                <td className="date">
                                    {productVersion.getLastUpdatedDateAndTime()}
                                </td>
                                <td className="changed-by">
                                    <>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {productVersion.getLastUpdatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}></Paragraph>
                                    </>
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuAnchor
                                            hrefPath={getViewVersionPath(
                                                product.type!,
                                                product.id!,
                                                productVersion.id!
                                            )}
                                            displayName={t("viewVersion")}
                                        />
                                        <ContextMenuButton
                                            disabled={
                                                productVersion.status === ActiveStatus.Active ||
                                                product.status === ActiveStatus.Archived ||
                                                product.status === ActiveStatus.Inactive ||
                                                !productVersion.allAssessmentsAreComplete()
                                            }
                                            displayName={t("activateVersion")}
                                            onClick={() => {
                                                setSelectedProductVersionAsActive(
                                                    productVersion.id!
                                                );
                                            }}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentProductVersionList };

// #endregion Exports
