// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const UnicodeCharacterConstants = {
    EmDash: "\u2014",
    Registered: "\u00AE",
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnicodeCharacterConstants };

// #endregion Exports
