import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import ReportSection from "components/reports/components/report-section/report-section";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { CourseRecord } from "models/view-models/courses/course-record";
import { useCallback, useState } from "react";
import { CourseService, ListCoursesQueryParams } from "utilities/services/courses/course-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportCourseSelectionProps {
    isRequired?: boolean;
    courses: CourseRecord[];
    onCoursesChanged: (Courses: CourseRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportCourseSelection: React.FC<ReportCourseSelectionProps> = ({
    isRequired = false,
    courses,
    onCoursesChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchCourses, setSearchCourses] = useState<CourseRecord[]>([]);

    const { list: listCourses } = CourseService.useList();
    const fetchCourses = useCallback(
        async (searchText?: string) => {
            try {
                const listCoursesQueryParams: ListCoursesQueryParams = {
                    applySearch: true,
                    searchText,
                };

                const listFilteredCoursesResponse = await listCourses(listCoursesQueryParams);
                const listFilteredCoursesResults = listFilteredCoursesResponse?.results;
                const courses = listFilteredCoursesResponse?.resultObjects;
                if (
                    courses == null ||
                    listFilteredCoursesResults == null ||
                    listFilteredCoursesResults.hasErrors()
                ) {
                    throw new Error();
                }
                setSearchCourses(courses);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingCourses"));
                setSearchCourses([]);
            }
        },
        [listCourses]
    );

    const handleRemoveCourse = useCallback(
        (course: CourseRecord) => {
            onCoursesChanged(courses.filter((c) => c.id !== course.id));
        },
        [courses, onCoursesChanged]
    );

    const handleCourseSelectionChange = (selectedCourses: CourseRecord[]) => {
        onCoursesChanged(selectedCourses);
    };

    const handleAddCourses = () => {
        fetchCourses();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectCourses")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddCourses}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addCourses")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(courses) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="course-name">{t("courseName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((course) => (
                                <tr key={course.id}>
                                    <td className="course-name">{course.name}</td>
                                    <td className="id">{course.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveCourse(course)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchCourses}
                onSelectionChanged={handleCourseSelectionChange}
                searchValues={searchCourses}
                selectedValues={courses}
                title={t("addCourses")}
                itemName={t("courseName")}
                searchPlaceholderText={t("typeToSearchByCourseNameOrID")}
                valuesDescription={t("courses")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportCourseSelection;

// #endregion Exports
