import React, { useCallback, useState } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { AdminLayoutHeaderNavigationPanel } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-navigation-panel/admin-layout-header-navigation-panel";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { Transition } from "@headlessui/react";
import { RouteUtils } from "utilities/route-utils";
import "./admin-layout-header-navigation.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderNavigationProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-navigation";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderNavigation: React.FC<AdminLayoutHeaderNavigationProps> = (): JSX.Element => {
    const [navigationIsOpen, setNavigationIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleOpenNavigationClick = useCallback((): void => {
        setNavigationIsOpen(true);
    }, []);

    const handleCloseNavigationClick = useCallback((): void => {
        setNavigationIsOpen(false);
    }, []);

    const navigateTo = useCallback(
        (path: string, external?: boolean): void => {
            if (external) {
                setNavigationIsOpen(true);
                window.open(path, "_blank", "noreferrer");
            } else {
                setNavigationIsOpen(false);
                navigate(RouteUtils.localizePath(path));
            }
        },
        [navigate]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <Transition
                as="span"
                className="absolute right-0 w-full h-full"
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={!navigationIsOpen}>
                <ButtonIcon
                    ariaLabelledBy="Open Menu"
                    buttonStyle={ButtonStyle.None}
                    buttonSize={ButtonSize.None}
                    cssClassName={`${CSS_CLASS_NAME}__open`}
                    iconType={Icons.Menu}
                    iconSize={IconSizes.Large}
                    onClick={handleOpenNavigationClick}
                />
            </Transition>
            <Transition
                as="span"
                className="absolute right-0 w-full h-full"
                enter="transition ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={navigationIsOpen}>
                <ButtonIcon
                    ariaLabelledBy="Close Menu"
                    buttonStyle={ButtonStyle.None}
                    buttonSize={ButtonSize.None}
                    cssClassName={`${CSS_CLASS_NAME}__close`}
                    iconType={Icons.Close}
                    iconSize={IconSizes.Large}
                    onClick={handleCloseNavigationClick}
                />
            </Transition>
            <AdminLayoutHeaderNavigationPanel isOpen={navigationIsOpen} navigateTo={navigateTo} />
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderNavigation };

// #endregion Exports
