import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEffect, useState } from "react";
import { RoleRecord } from "models/view-models/roles/role-record";
import { RoleService } from "utilities/services/roles/role-service";
import { t } from "utilities/localization/t";

interface UseRolesHook {
    roles: RoleRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseRolesHookOptions {}

export function useRoles(props: UseRolesHookOptions): UseRolesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState<RoleRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listRoles } = RoleService.useList();

    useEffect(() => {
        try {
            (async function getOrganizations() {
                const { result, resultObjects } = await listRoles({});

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRoles(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningRoles"));
        }
    }, [listRoles]);

    return {
        errors,
        isLoading,
        roles,
    };
}
