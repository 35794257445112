import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    CourseVersionService,
    GetLatestCourseVersionPathParams,
    GetLatestCourseVersionQueryParams,
} from "utilities/services/courses/course-version-service";
import { CreateCourseVersionMaterials } from "components/courses/course-version/create-course-version-materials/create-course-version-materials";
import { EditCourseLayout } from "layouts/admin/edit-course-layout/edit-course-layout";
import { HeaderActions } from "components/header-actions/header-actions";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/contexts/use-course-context";
import { useCourseVersion } from "utilities/contexts/use-course-version-context";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useNavigate } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./create-course-materials-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateCourseMaterialsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "create-course-materials-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateCourseMaterialsPage: React.FC<CreateCourseMaterialsPageProps> = validatePageAccess(
    AccessControlKeys.CreateCourseMaterialsPage
)((): JSX.Element => {
    const navigate = useNavigate();
    const { record: course } = useCourse();
    const { record: courseVersion, setRecord: setCourseVersion } = useCourseVersion();
    const { create: createVersion } = CourseVersionService.useCreate();
    const { get: getLatestCourseVersion } = CourseVersionService.useGetLatest();
    const [newCourseVersion, setNewCourseVersion] = useState<CourseVersionRecord>(
        new CourseVersionRecord()
    );
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const [showCreateVersionModal, setShowCreateVersionModal] = useState<boolean>(false);
    const [deferSave, setDeferSave] = useState<boolean>(false);

    useEffect(() => {
        if (course.status === ActiveStatus.Active) {
            setDeferSave(true);
        }
    }, [course.status]);

    useRedirectOnForbidden(sitemap.admin.course.list);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const navigationRequests = useListenForNavigationRequests(CreateCourseMaterialsPage.name, [
        Breadcrumbs.name,
        EditCourseLayout.name,
    ]);
    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (course.status === ActiveStatus.Draft) {
            resolveNavigationRequest(
                CreateCourseMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
            return;
        }
        setNavigationRequest(navigationRequest);
    }, [navigationRequests, course.status, resolveNavigationRequest]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(
                CreateCourseMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(
            CreateCourseMaterialsPage.name,
            navigationRequest.requestingComponent
        );
    }, [navigationRequest, resolveNavigationRequest]);

    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    const createVersionActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowCreateVersionModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continue"),
            onClick: () => {
                setShowCreateVersionModal(false);
                handleCreateCourseVersionFromLatest();
            },
            style: ButtonStyle.Primary,
        },
    ];

    const fetchData = useCallback(async (): Promise<void> => {
        try {
            //Get the latestCourseVersion for this course:
            if (course.id == null) return;
            const pathParams: GetLatestCourseVersionPathParams = {
                id: course.id,
            };
            const queryParams: GetLatestCourseVersionQueryParams = {
                includeContent: true,
                includeScormPackage: true,
            };

            const getResponse = await getLatestCourseVersion(pathParams, queryParams);
            const getResult = getResponse?.result;
            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            const latestCourseVersion = getResult.resultObject;

            if (course.status === ActiveStatus.Active) {
                const newCourseVersion = new CourseVersionRecord({
                    id: -1,
                    courseId: course.id,
                    versionId:
                        latestCourseVersion?.versionId == null
                            ? 0
                            : latestCourseVersion?.versionId + 1,
                    scormPackage: undefined,
                    //Bring forward the course contents from the latest version
                    courseContents: latestCourseVersion?.courseContents,
                    hasNoContent: latestCourseVersion?.hasNoContent,
                    numberOfInProgressLearners: 0,
                    numberOfEnrolledLearners: 0,
                    status: ActiveStatus.Draft,
                });
                setNewCourseVersion(newCourseVersion);
            }

            if (course.status === ActiveStatus.Draft) {
                setCourseVersion(latestCourseVersion);
            }
            return;
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheCourse"));
        }
    }, [course, getLatestCourseVersion, setCourseVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCreateCourseVersionFromLatest = async (): Promise<void> => {
        if (newCourseVersion?.scormPackage === undefined) {
            ToastManager.error(t("youMustAddAScormPackageToCreateANewVersion"));
            return;
        }
        try {
            const createCourseVersionResponse = await createVersion(newCourseVersion);
            const createCourseVersionResult = createCourseVersionResponse.result;
            if (
                createCourseVersionResult?.resultObject == null ||
                createCourseVersionResult.hasErrors()
            ) {
                throw new Error();
            }
            navigate(
                RouteUtils.localizePath(
                    RouteUtils.replacePathParams(sitemap.admin.course.edit.versionList, {
                        id: course.id,
                    })
                )
            );
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingTheNewVersion"));
        }
    };

    const onModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.course.edit.versionList, {
                    id: course.id,
                })
            )
        );
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                {(course.status === ActiveStatus.Draft || course.activatedOn == null) && (
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("courseMaterials")}
                    </Heading>
                )}

                {course.status === ActiveStatus.Active && (
                    <HeaderActions
                        headingPriority={HeadingPriority.H5}
                        headingSize={HeadingSize.Small}
                        title={t("newVersionVersionNumber", {
                            versionNumber: newCourseVersion.versionId,
                        })}>
                        <div className={`${CSS_CLASS_NAME}__header-buttons`}>
                            <Button
                                onClick={() => setShowCancelCreateVersionModal(true)}
                                style={ButtonStyle.Secondary}
                                text={t("cancelVersion")}
                            />
                            <ButtonIcon
                                buttonStyle={ButtonStyle.Primary}
                                disabled={
                                    !newCourseVersion.contentIsComplete() ||
                                    !newCourseVersion.scormPackage
                                }
                                iconType={Icons.PublishWithChanges}
                                onClick={() => setShowCreateVersionModal(true)}
                                text={t("createVersion")}
                            />
                        </div>
                    </HeaderActions>
                )}
            </div>
            <CreateCourseVersionMaterials
                courseVersion={
                    course.status === ActiveStatus.Draft ? courseVersion : newCourseVersion
                }
                deferSave={deferSave}
                setCourseVersion={
                    course.status === ActiveStatus.Draft ? setCourseVersion : setNewCourseVersion
                }
            />
            <CancelChangesModal
                open={showCancelCreateVersionModal}
                setOpen={setShowCancelCreateVersionModal}
                onModalClose={onModalClose}
            />
            <Modal
                actions={createVersionActionArray}
                isOpen={showCreateVersionModal}
                modalStyle={""}
                onModalClose={() => {}}
                title={t("createANewVersion")}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__create-version-modal-title`}
                    size={HeadingSize.XSmall}>
                    {t("youveCreatedVersionCourseVersionVersionId", {
                        courseVersionVersionId: newCourseVersion?.versionId,
                    })}
                </Heading>
                <Paragraph size={ParagraphSize.Large}>
                    {t(
                        "yourNewVersionHasBeenCreatedAndTheZIPPackageIsCurrentlyProcessingOnceTheUploadIsCompleteMoveForwardToActivatingTheVersionToApplyIt"
                    )}
                </Paragraph>
            </Modal>
            <Modal
                cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                isOpen={showCancelEditOnNavigationModal}
                onModalClose={() => {}}
                actions={cancelEditModeOnNavigationConfirmationActions}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
            </Modal>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateCourseMaterialsPage };

// #endregion Exports
