import ReportSection from "components/reports/components/report-section/report-section";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import {
    ContractService,
    ListContractsQueryParams,
} from "utilities/services/contracts/contract-service";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportContractSelectionProps {
    disableAddContracts?: boolean;
    isRequired?: boolean;
    contracts: ContractRecord[];
    onContractsChanged: (contracts: ContractRecord[]) => void;
    filterByOrganizationIds?: number[];
    filterByOrgRequired?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportContractSelection: React.FC<ReportContractSelectionProps> = ({
    disableAddContracts,
    isRequired = false,
    contracts,
    onContractsChanged,
    filterByOrganizationIds,
    filterByOrgRequired = false,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchContracts, setSearchContracts] = useState<ContractRecord[]>([]);

    const { list: listContracts } = ContractService.useList();
    const fetchContracts = useCallback(
        async (searchText?: string) => {
            try {
                const listContractsQueryParams: ListContractsQueryParams = {
                    searchText,
                    organizationIds:
                        filterByOrganizationIds == null || filterByOrganizationIds.length < 1
                            ? undefined
                            : filterByOrganizationIds,
                };

                const listFilteredContractsResponse = await listContracts(listContractsQueryParams);
                const listFilteredContractResults = listFilteredContractsResponse?.results;
                const listedContracts = listFilteredContractsResponse?.resultObjects;
                if (
                    listedContracts == null ||
                    listFilteredContractResults == null ||
                    listFilteredContractResults.hasErrors()
                ) {
                    throw new Error();
                }

                setSearchContracts(listedContracts);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingContracts"));
                setSearchContracts([]);
            }
        },
        [filterByOrganizationIds, listContracts]
    );

    const handleRemoveContract = useCallback(
        (contract: ContractRecord) => {
            onContractsChanged(contracts.filter((c) => c.id !== contract.id));
        },
        [onContractsChanged, contracts]
    );

    const handleContractSelectionChange = (selectedContracts: ContractRecord[]) => {
        onContractsChanged(selectedContracts);
    };

    const handleAddContracts = () => {
        fetchContracts();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectContracts")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            disabled={
                                (filterByOrgRequired &&
                                    !CollectionUtils.hasValues(filterByOrganizationIds)) ||
                                disableAddContracts
                            }
                            onClick={handleAddContracts}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addContracts")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(contracts) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="contract-number">{t("contractNumber")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {contracts.map((contract) => (
                                <tr key={contract.id}>
                                    <td className="contract-number">{contract.contractNumber}</td>
                                    <td className="id">{contract.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveContract(contract)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchContracts}
                onSelectionChanged={handleContractSelectionChange}
                searchValues={searchContracts}
                selectedValues={contracts}
                title={t("addAContract")}
                itemName={t("contractNumber")}
                searchPlaceholderText={t("typeToSearchByContractNameOrID")}
                valuesDescription={t("contracts")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.contractNumber!,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportContractSelection;

// #endregion Exports
