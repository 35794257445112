import axios from "axios";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "unitcourses";
const recordType: typeof UnitCourseRecord = UnitCourseRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateUnitCourseService = (
    params: CreateUnitCourseParams
) => Promise<ServiceResponse<UnitCourseRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CreateUnitCourseParams {
    courseIds: number[];
    sortOrder?: number;
    unitId: number;
}

export interface DeleteUnitCourseParams {
    courseId: number;
    unitId: number;
}

export interface ListUnitCoursesQueryParams {
    unitId: number;
    includeCourse?: boolean;
}

export interface UpdateUnitCoursePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UnitCourseService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateUnitCourseService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateUnitCourseService = (
            params: CreateUnitCourseParams
        ): Promise<ServiceResponse<UnitCourseRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function create(
            params: CreateUnitCourseParams
        ): Promise<ServiceResponse<UnitCourseRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<UnitCourseRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<UnitCourseRecord, ListUnitCoursesQueryParams>(
        recordType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<UnitCourseRecord, UpdateUnitCoursePathParams>(
        recordType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitCourseService };

// #endregion Exports
