import { AudienceType } from "models/enumerations/audiences/audience-type";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { RoleType } from "models/enumerations/users/role-type";
import { ServiceHookFactory } from "andculturecode-javascript-react";
import { TrainingType } from "models/enumerations/courses/training-type";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "users";
const resourceType: typeof EnrollmentRecord = EnrollmentRecord;
const resourceEndpoint = `${baseEndpoint}/:userId/enrollments`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEnrollmentPathParams {
    id: number;
}

export interface GetEnrollmentQueryParams {
    includeEvaluation?: boolean;
    includeEvent?: boolean;
    includeProduct?: boolean;
}

export interface ListEnrollmentsQueryParams {
    audienceType?: AudienceType;
    educationNetworkOnly?: boolean;
    endDate?: string;
    enrollmentActiveStatus?: EnrollmentActiveStatus;
    expirationEndDate?: string;
    expirationStartDate?: string;
    hasCertificate?: boolean;
    includeActiveAndCompleted?: boolean;
    includeBadge?: boolean;
    includeCertificate?: boolean;
    includeEvaluation?: boolean;
    includeEvent?: boolean;
    includeEventSchedule?: boolean;
    includeProduct?: boolean;
    includeRegistrations?: boolean;
    isWithdrawn?: boolean;
    roleType?: RoleType;
    searchText?: string;
    skip?: number;
    startDate?: string;
    take?: number;
    trainingTypes?: TrainingType[];
}

export interface ListEnrollmentsPathParams {
    userId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserEnrollmentService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EnrollmentRecord,
        GetEnrollmentPathParams,
        GetEnrollmentQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EnrollmentRecord,
        ListEnrollmentsPathParams,
        ListEnrollmentsQueryParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserEnrollmentService };

// #endregion Exports
