import { RecordUtils as AndcultureCodeRecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CONSTRUCTOR_NAME: string = "constructor";
const PRIVATE_METHOD_PREFIX: string = "_";
const WITH_METHOD_NAME: string = "with";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

const getPublicMethods = <TRecord>(recordType: { new (): TRecord }): object => {
    const record = new recordType();
    const prototype = Object.getPrototypeOf(record);

    if (prototype == null) {
        return {};
    }

    return Object.getOwnPropertyNames(prototype)
        .filter(_publicFunctionNameFilter)
        .reduce(
            (methods: object, name: string): object => ({
                ...methods,
                [name]: record[name as keyof TRecord],
            }),
            {}
        );
};

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Private Helper Functions
// -------------------------------------------------------------------------------------------------

const _publicFunctionNameFilter = (name: string): boolean =>
    !(
        name === CONSTRUCTOR_NAME ||
        name === WITH_METHOD_NAME ||
        name.startsWith(PRIVATE_METHOD_PREFIX)
    );

// #endregion Private Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const RecordUtils = {
    ...AndcultureCodeRecordUtils,
    getPublicMethods: getPublicMethods,
};

export { RecordUtils };

// #endregion Exports
