import React, { useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CreateUnitParams, UnitService } from "utilities/services/units/unit-service";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { UnitDetailsForm } from "../unit-details-form/unit-details-form";
import { UnitRecord } from "models/view-models/units/unit-record";
import { t } from "utilities/localization/t";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import "./unit-create-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitCreateModalProps {
    onSuccess: () => void;
    open: boolean;
    product?: ProductRecord;
    productUnits?: UnitRecord[];
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setProductUnits?: React.Dispatch<React.SetStateAction<UnitRecord[]>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "unit-create-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitCreateModal: React.FC<UnitCreateModalProps> = (
    props: UnitCreateModalProps
): JSX.Element => {
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const defaultUnit: UnitRecord = new UnitRecord({
        productId: props.product?.id,
        productVersionId: productVersion?.id,
    });

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [unit, setUnit] = useState(defaultUnit);
    const { create: createUnitApi } = UnitService.useCreate();
    const deferSave = props.product?.status === ActiveStatus.Active;

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("createUnit"),
            disabled: !StringUtils.hasValue(unit?.name),
            onClick: () => onCreateUnit(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onUnitChanged = (updatedUnit: UnitRecord) => {
        setDirty(true);
        setUnit(updatedUnit);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const resetForm = (): void => {
        setDirty(false);
        setUnit(defaultUnit);
    };

    const onCreateUnit = async (): Promise<void> => {
        if (deferSave) {
            if (productVersion === undefined || productVersion === null) {
                return;
            }
            const productVersionUnits = productVersion.units == null ? [] : productVersion.units;
            const unitCount = productVersionUnits.length;
            const updatedProductVersion = productVersion.with({
                units: [...productVersionUnits, unit.with({ sortOrder: unitCount + 1 })],
            });
            setProductVersion(updatedProductVersion);
            props.setCourseHasChanges && props.setCourseHasChanges(true);
            props.onSuccess();
            resetForm();
            props.setOpen(false);
        } else {
            if (await createUnit()) {
                props.onSuccess();
                resetForm();
                props.setOpen(false);
            }
        }
    };

    const createUnit = async (): Promise<boolean> => {
        if (unit == null) {
            return false;
        }

        let createUnitParams: CreateUnitParams = {
            completeCoursesInOrder: unit.completeCoursesInOrder,
            completeUnitBeforeMovingForward: unit.completeUnitBeforeMovingForward,
            description: unit.description,
            name: unit.name,
            optional: unit.optional,
            productId: props.product?.id!,
            productVersionId: productVersion?.id!,
            sortOrder: 1,
        };

        if (CollectionUtils.isNotEmpty(props.productUnits)) {
            const nextSortOrder = Math.max(...props.productUnits.map((u) => u.sortOrder!)) + 1;
            createUnitParams = {
                completeCoursesInOrder: unit.completeCoursesInOrder,
                completeUnitBeforeMovingForward: unit.completeUnitBeforeMovingForward,
                description: unit.description,
                name: unit.name,
                optional: unit.optional,
                productId: props.product?.id!,
                productVersionId: productVersion?.id!,
                sortOrder: nextSortOrder,
            };
        }

        try {
            const createUnitResponse = await createUnitApi(createUnitParams);

            const createResult = createUnitResponse?.result;
            if (createResult?.resultObject == null || createResult.hasErrors()) {
                throw new Error();
            }

            unit.with({
                ...createResult.resultObject.toJS(),
            });
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingTheUnit"));
        }

        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("createUnit")}
            actions={modalActionArray}
            modalStyle={""}>
            {
                // if
                unit !== undefined && unit !== null && (
                    <UnitDetailsForm
                        unit={unit}
                        onUnitChange={onUnitChanged}
                        completeProductUnitsInOrder={productVersion?.completeUnitsInOrder}
                    />
                )
            }
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitCreateModal };

// #endregion Exports
