import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import "./event-activation-location-section.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationLocationSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-location-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationLocationSection: React.FC<EventActivationLocationSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            {!editMode && (
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("location")}
                </Heading>
            )}
            {editMode && (
                <>
                    <h3>{t("location")}</h3>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            {event.instructorLedTrainingType === InstructorLedTrainingType.InPerson && (
                <>
                    <div className={`${CSS_CLASS_NAME}__container`}>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("country").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.country}</Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("timezone").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.timeZone}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__container`}>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("address").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>
                                {event.addressLineOne}
                                <br />
                                {event.addressLineTwo}
                                <br />
                                {event.city}, {event.state} {event.zipCode}
                            </Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("locationName").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.locationName}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__container`}>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("additionalDetails").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.additionalAddressDetails}</Paragraph>
                        </div>
                    </div>
                </>
            )}
            {event.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual && (
                <>
                    <div className={`${CSS_CLASS_NAME}__container`}>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("meetingUrl").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.meetingUrl}</Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("timezone").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.timeZone}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__container`}>
                        <div className={`${CSS_CLASS_NAME}__container-item`}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("additionalDetails").toLocaleUpperCase()}
                            </Paragraph>
                            <Paragraph>{event.additionalAddressDetails}</Paragraph>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationLocationSection };

// #endregion Exports
