import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import ReportSection from "components/reports/components/report-section/report-section";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { useCallback, useState } from "react";
import {
    OrganizationService,
    ListOrganizationsQueryParams,
} from "utilities/services/organizations/organization-service";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportOrganizationSelectionProps {
    isRequired?: boolean;
    filterByContractIds?: number[];
    organizations: OrganizationRecord[];
    onOrganizationsChanged: (organizations: OrganizationRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportOrganizationSelection: React.FC<ReportOrganizationSelectionProps> = ({
    isRequired = false,
    filterByContractIds,
    organizations,
    onOrganizationsChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchOrganizations, setSearchOrganizations] = useState<OrganizationRecord[]>([]);

    const { list: listOrganizations } = OrganizationService.useList();
    const fetchOrganizations = useCallback(
        async (searchText?: string) => {
            try {
                const listOrganizationsQueryParams: ListOrganizationsQueryParams = {
                    searchText,
                    contractIds:
                        filterByContractIds == null || filterByContractIds.length < 1
                            ? undefined
                            : filterByContractIds,
                };

                const listFilteredOrganizationsResponse = await listOrganizations(
                    listOrganizationsQueryParams
                );
                const listFilteredOrganizationResults = listFilteredOrganizationsResponse?.results;
                const listedOrganizations = listFilteredOrganizationsResponse?.resultObjects;
                if (
                    listedOrganizations == null ||
                    listFilteredOrganizationResults == null ||
                    listFilteredOrganizationResults.hasErrors()
                ) {
                    throw new Error();
                }

                setSearchOrganizations(listedOrganizations);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingOrganizations"));
                setSearchOrganizations([]);
            }
        },
        [filterByContractIds, listOrganizations]
    );

    const handleRemoveOrganization = useCallback(
        (organization: OrganizationRecord) => {
            onOrganizationsChanged(organizations.filter((c) => c.id !== organization.id));
        },
        [onOrganizationsChanged, organizations]
    );

    const handleOrganizationSelectionChange = (selectedOrganizations: OrganizationRecord[]) => {
        onOrganizationsChanged(selectedOrganizations);
    };

    const handleAddOrganizations = () => {
        fetchOrganizations();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectOrganizations")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddOrganizations}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addOrganizations")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(organizations) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="organization-name">{t("organizationName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map((organization) => (
                                <tr key={organization.id}>
                                    <td className="organization-name">{organization.name}</td>
                                    <td className="id">{organization.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() =>
                                                    handleRemoveOrganization(organization)
                                                }
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchOrganizations}
                onSelectionChanged={handleOrganizationSelectionChange}
                searchValues={searchOrganizations}
                selectedValues={organizations}
                title={t("addAnOrganization")}
                itemName={t("organizationName")}
                searchPlaceholderText={t("typeToSearchByOrganizationNameOrID")}
                valuesDescription={t("organizations")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name!,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportOrganizationSelection;

// #endregion Exports
