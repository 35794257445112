import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { useEffect, useState } from "react";
import { RoleType } from "models/enumerations/users/role-type";
import {
    UserNotificationService,
    NotificationPatchRoutes,
    ListNotificationsPathParams,
    ListNotificationsQueryParams,
    PatchNotificationServiceParams,
} from "utilities/services/notifications/user-notification-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";

interface UseUserNotificationsHook {
    notifications: NotificationRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    markNotificationAsRead?: (notificationId: number) => void;
}

interface UseUserNotificationsHookOptions {
    contractId?: number;
    enrollmentId?: number;
    eventId?: number;
    includeContract?: boolean;
    includeEnrollment?: boolean;
    includeEvent?: boolean;
    includeProduct?: boolean;
    notificationTypes?: NotificationType[];
    roleType?: RoleType;
    userId?: number;
}

export function useUserNotifications(
    props: UseUserNotificationsHookOptions
): UseUserNotificationsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [notifications, setNotifications] = useState<NotificationRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listNotifications } = UserNotificationService.useList();
    const { patch: patchNotificationAPI } = UserNotificationService.usePatch();
    const userId = props.userId ?? 0;
    const roleType = props.roleType;
    const notificationTypes = props.notificationTypes;
    const contractId = props.contractId;
    const eventId = props.eventId;
    const includeContract = props.includeContract ?? false;
    const includeEnrollment = props.includeEnrollment ?? false;
    const includeEvent = props.includeEvent ?? false;
    const includeProduct = props.includeProduct ?? false;
    const enrollmentId = props.enrollmentId;

    useEffect(() => {
        if (isNaN(userId) || userId < 1) {
            return;
        }

        try {
            (async function getNotifications() {
                const pathParams: ListNotificationsPathParams = { userId };
                const queryParams: ListNotificationsQueryParams = {
                    contractId: contractId,
                    enrollmentId: enrollmentId,
                    eventId: eventId,
                    includeContract: includeContract,
                    includeEnrollment: includeEnrollment,
                    includeEvent: includeEvent,
                    includeProduct: includeProduct,
                    notificationTypes: notificationTypes,
                    roleType: roleType,
                };

                const { result, resultObjects } = await listNotifications(pathParams, queryParams);

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setNotifications(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningNotifications"));
        }
    }, [
        userId,
        listNotifications,
        roleType,
        notificationTypes,
        contractId,
        eventId,
        includeContract,
        includeEnrollment,
        includeEvent,
        includeProduct,
        enrollmentId,
    ]);

    const markNotificationAsRead = async (notificationId: number) => {
        // Let's be optimistic about the update and update the client's state before the API call.
        const originalNotifications = [...notifications];

        const updatedNotifications = notifications.filter((n) => n.id !== notificationId);

        setNotifications(updatedNotifications);

        const patchNotificationServiceParams: PatchNotificationServiceParams = {
            id: notificationId,
            userId: userId,
            patchRoute: NotificationPatchRoutes.MarkAsRead,
        };

        const patchNotificationResponse = await patchNotificationAPI(
            patchNotificationServiceParams
        );

        const patchNotificationResult = patchNotificationResponse?.result;

        if (
            patchNotificationResult == null ||
            patchNotificationResult?.resultObject == null ||
            patchNotificationResult.hasErrors()
        ) {
            ToastManager.error(t("markingNotificationAsReadFailed"));
            setNotifications(originalNotifications);
            return;
        }
    };

    return {
        errors,
        isLoading,
        notifications,
        markNotificationAsRead,
    };
}
