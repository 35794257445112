import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as Achievements } from "assets/icons/22px/Achievements.svg";
import { ReactComponent as AenManager } from "assets/icons/24px/AenManager.svg";
import { ReactComponent as AllCompleted } from "assets/icons/16px/All-Completed.svg";
import { ReactComponent as Analytics } from "assets/icons/24px/Analytics.svg";
import { ReactComponent as Announcements } from "assets/icons/20px/Announcements.svg";
import { ReactComponent as Archive } from "assets/icons/24px/Archive.svg";
import { ReactComponent as ArrowCircleLeft } from "assets/icons/24px/ArrowCircleLeft.svg";
import { ReactComponent as ArrowCircleRight } from "assets/icons/24px/ArrowCircleRight.svg";
import { ReactComponent as ArrowDownThick } from "assets/icons/16px/ArrowDownThick.svg";
import { ReactComponent as ArrowDownward } from "assets/icons/20px/ArrowDownward.svg";
import { ReactComponent as ArrowUpward } from "assets/icons/20px/ArrowUpward.svg";
import { ReactComponent as Assessment } from "assets/icons/18px/Assessment.svg";
import { ReactComponent as Assignment } from "assets/icons/24px/Assignment.svg";
import { ReactComponent as Assistant } from "assets/icons/24px/Assistant.svg";
import { ReactComponent as Attendance } from "assets/icons/18px/Attendance.svg";
import { ReactComponent as Book16 } from "assets/icons/16px/Book.svg";
import { ReactComponent as Book24 } from "assets/icons/24px/Book.svg";
import { ReactComponent as BookmarkOutline } from "assets/icons/16px/BookmarkOutlined.svg";
import { ReactComponent as Calendar } from "assets/icons/16px/Calendar.svg";
import { ReactComponent as CalendarSingle } from "assets/icons/16px/Calendar-Single.svg";
import { ReactComponent as CalendarMulti } from "assets/icons/16px/Calendar-Multi.svg";
import { ReactComponent as CalendarBusy } from "assets/icons/16px/CalendarBusy.svg";
import { ReactComponent as Catalog } from "assets/icons/16px/Catalog.svg";
import { ReactComponent as Cancel } from "assets/icons/24px/Cancel.svg";
import { ReactComponent as ChalkboardTeacher } from "assets/icons/24px/Chalkboard-Teacher.svg";
import { ReactComponent as Chart } from "assets/icons/24px/Chart.svg";
import { ReactComponent as Checklist } from "assets/icons/16px/Checklist.svg";
import { ReactComponent as Checkmark } from "assets/icons/16px/Checkmark.svg";
import { ReactComponent as CheckmarkClipboard } from "assets/icons/16px/Checkmark-Clipboard.svg";
import { ReactComponent as CheckmarkOvalFilled } from "assets/icons/24px/Checkmark-Oval-Filled.svg";
import { ReactComponent as CheckmarkOvalOutline } from "assets/icons/24px/Checkmark-Oval-Outline.svg";
import { ReactComponent as ChevronDown16 } from "assets/icons/16px/Chevron-Down.svg";
import { ReactComponent as ChevronDown24 } from "assets/icons/24px/Chevron-Down.svg";
import { ReactComponent as ChevronDownWhite16 } from "assets/icons/16px/Chevron-Down-White.svg";
import { ReactComponent as ChevronFirst } from "assets/icons/16px/Chevron-First.svg";
import { ReactComponent as ChevronLast } from "assets/icons/16px/Chevron-Last.svg";
import { ReactComponent as ChevronLeft16 } from "assets/icons/16px/Chevron-Left.svg";
import { ReactComponent as ChevronLeft24 } from "assets/icons/24px/Chevron-Left.svg";
import { ReactComponent as ChevronRight16 } from "assets/icons/16px/Chevron-Right.svg";
import { ReactComponent as ChevronRight24 } from "assets/icons/24px/Chevron-Right.svg";
import { ReactComponent as ChevronUp16 } from "assets/icons/16px/Chevron-Up.svg";
import { ReactComponent as ChevronUp24 } from "assets/icons/24px/Chevron-Up.svg";
import { ReactComponent as CircleChecked } from "assets/icons/18px/Circle-Checked.svg";
import { ReactComponent as CircleUnchecked } from "assets/icons/18px/Circle-Unchecked.svg";
import { ReactComponent as Clock } from "assets/icons/16px/Clock.svg";
import { ReactComponent as ClockWithArrow } from "assets/icons/16px/ClockWithArrow.svg";
import { ReactComponent as Close16 } from "assets/icons/16px/Close.svg";
import { ReactComponent as Close24 } from "assets/icons/24px/Close.svg";
import { ReactComponent as Collapse16 } from "assets/icons/16px/Collapse.svg";
import { ReactComponent as Community } from "assets/icons/20px/Community.svg";
import { ReactComponent as Content } from "assets/icons/18px/Content.svg";
import { ReactComponent as Courses } from "assets/icons/22px/Courses.svg";
import { ReactComponent as DefaultAvatar24 } from "assets/icons/24px/Default-Avatar.svg";
import { ReactComponent as Delta16 } from "assets/icons/16px/Delta.svg";
import { ReactComponent as Desktop } from "assets/icons/24px/Desktop.svg";
import { ReactComponent as DesktopBusy } from "assets/icons/16px/DesktopBusy.svg";
import { ReactComponent as DesktopWithBullets } from "assets/icons/24px/DesktopWithBullets.svg";
import { ReactComponent as Document } from "assets/icons/16px/Document.svg";
import { ReactComponent as Dot16 } from "assets/icons/16px/Dot.svg";
import { ReactComponent as Download16 } from "assets/icons/16px/Download.svg";
import { ReactComponent as Download24 } from "assets/icons/24px/Download.svg";
import { ReactComponent as DownloadComplete } from "assets/icons/16px/Download-Complete.svg";
import { ReactComponent as DownloadRemove } from "assets/icons/16px/Download-Remove.svg";
import { ReactComponent as DownloadSync } from "assets/icons/16px/Download-Sync.svg";
import { ReactComponent as DragAndDrop } from "assets/icons/16px/DragAndDrop.svg";
import { ReactComponent as Edit } from "assets/icons/16px/Edit.svg";
import { ReactComponent as EditOpen } from "assets/icons/24px/EditOpen.svg";
import { ReactComponent as EditCalendar } from "assets/icons/16px/Edit-Calendar.svg";
import { ReactComponent as Email } from "assets/icons/24px/Email.svg";
import { ReactComponent as Empty } from "assets/icons/16px/Empty.svg";
import { ReactComponent as Enlarge } from "assets/icons/16px/Enlarge.svg";
import { ReactComponent as Enrollment } from "assets/icons/18px/Enrollment.svg";
import { ReactComponent as Enterprise } from "assets/icons/40px/Enterprise.svg";
import { ReactComponent as Evaluation } from "assets/icons/18px/Evaluation.svg";
import { ReactComponent as EvaluationIncomplete } from "assets/icons/16px/EvaluationIncomplete.svg";
import { ReactComponent as EventDetails } from "assets/icons/18px/EventDetails.svg";
import { ReactComponent as Events } from "assets/icons/20px/Events.svg";
import { ReactComponent as EventType } from "assets/icons/24px/EventType.svg";
import { ReactComponent as Expand16 } from "assets/icons/16px/Expand.svg";
import { ReactComponent as Eye } from "assets/icons/16px/Eye.svg";
import { ReactComponent as FactCheck } from "assets/icons/16px/FactCheck.svg";
import { ReactComponent as FileCopy } from "assets/icons/16px/FileCopy.svg";
import { ReactComponent as Folder } from "assets/icons/24px/Folder.svg";
import { ReactComponent as History } from "assets/icons/24px/History.svg";
import { ReactComponent as Home16 } from "assets/icons/16px/Home.svg";
import { ReactComponent as Home24 } from "assets/icons/24px/Home.svg";
import { ReactComponent as Individual } from "assets/icons/40px/Individual.svg";
import { ReactComponent as Information16 } from "assets/icons/16px/Information.svg";
import { ReactComponent as Information24 } from "assets/icons/24px/Information.svg";
import { ReactComponent as InformationOutline } from "assets/icons/16px/InformationOutline.svg";
import { ReactComponent as InPersonEvent } from "assets/icons/24px/InPersonEvent.svg";
import { ReactComponent as LibraryBooks } from "assets/icons/16px/LibraryBooks.svg";
import { ReactComponent as Learning } from "assets/icons/20px/Learning.svg";
import { ReactComponent as License } from "assets/icons/24px/License.svg";
import { ReactComponent as Lightbulb } from "assets/icons/16px/Lightbulb.svg";
import { ReactComponent as LiveVirtualEvent } from "assets/icons/24px/LiveVirtualEvent.svg";
import { ReactComponent as List } from "assets/icons/24px/List.svg";
import { ReactComponent as Lock } from "assets/images/icons/icon-lock.svg";
import { ReactComponent as LockPerson } from "assets/icons/32px/LockPerson.svg";
import { ReactComponent as Logo } from "assets/icons/40px/Logo.svg";
import { ReactComponent as Logout } from "assets/icons/18px/Logout.svg";
import { ReactComponent as MediaPlay } from "assets/icons/16px/MediaPlay.svg";
import { ReactComponent as MediaPlay24 } from "assets/icons/24px/MediaPlay.svg";
import { ReactComponent as Menu } from "assets/icons/16px/Menu.svg";
import { ReactComponent as Message } from "assets/icons/16px/Message.svg";
import { ReactComponent as MetaPanel16 } from "assets/icons/16px/Meta-Panel.svg";
import { ReactComponent as MetaPanel24 } from "assets/icons/24px/Meta-Panel.svg";
import { ReactComponent as Minus } from "assets/icons/16px/Minus.svg";
import { ReactComponent as MoreHorizontal16 } from "assets/icons/16px/More-Horizontal.svg";
import { ReactComponent as MoreHorizontal24 } from "assets/icons/24px/More-Horizontal.svg";
import { ReactComponent as MoreVertical16 } from "assets/icons/16px/More-Vertical.svg";
import { ReactComponent as MoreVertical24 } from "assets/icons/24px/More-Vertical.svg";
import { ReactComponent as NewMaterial16 } from "assets/icons/16px/New-Material.svg";
import { ReactComponent as Note16 } from "assets/icons/16px/Note.svg";
import { ReactComponent as OnlineLearning } from "assets/icons/24px/OnlineLearning.svg";
import { ReactComponent as OpenBook } from "assets/icons/22px/OpenBook.svg";
import { ReactComponent as Organization } from "assets/icons/20px/Organization.svg";
import { ReactComponent as PersonOutline } from "assets/icons/32px/PersonOutline.svg";
import { ReactComponent as Phone } from "assets/icons/24px/Phone.svg";
import { ReactComponent as Pin16 } from "assets/icons/16px/Pin.svg";
import { ReactComponent as Placeholder16 } from "assets/icons/16px/Placeholder.svg";
import { ReactComponent as Placeholder24 } from "assets/icons/24px/Placeholder.svg";
import { ReactComponent as Plus16 } from "assets/icons/16px/Plus.svg";
import { ReactComponent as Plus24 } from "assets/icons/24px/Plus.svg";
import { ReactComponent as PlusWithCircle } from "assets/icons/16px/PlusWithCircle.svg";
import { ReactComponent as PublishWithChanges } from "assets/icons/24px/PublishWithChanges.svg";
import { ReactComponent as Preferences } from "assets/icons/24px/Preferences.svg";
import { ReactComponent as Products } from "assets/icons/20px/Products.svg";
import { ReactComponent as Question } from "assets/icons/24px/Question.svg";
import { ReactComponent as ReferenceLink } from "assets/icons/16px/ReferenceLink.svg";
import { ReactComponent as ReferencePanel16 } from "assets/icons/16px/ReferencePanel.svg";
import { ReactComponent as ReferencePanel24 } from "assets/icons/24px/ReferencePanel.svg";
import { ReactComponent as Reporting } from "assets/icons/20px/Reporting.svg";
import { ReactComponent as Reset } from "assets/icons/24px/Reset.svg";
import { ReactComponent as Return24 } from "assets/icons/24px/Return.svg";
import { ReactComponent as ScreenShare } from "assets/icons/16px/ScreenShare.svg";
import { ReactComponent as StopScreenShare } from "assets/icons/16px/StopScreenShare.svg";
import { ReactComponent as Scroll16 } from "assets/icons/16px/Scroll.svg";
import { ReactComponent as Scroll24 } from "assets/icons/24px/Scroll.svg";
import { ReactComponent as Search } from "assets/icons/24px/Search.svg";
import { ReactComponent as Settings } from "assets/icons/24px/Settings.svg";
import { ReactComponent as Share } from "assets/icons/16px/Share.svg";
import { ReactComponent as Sitnav } from "assets/icons/24px/Sitnav.svg";
import { ReactComponent as SlideClose } from "assets/icons/20px/SlideClose.svg";
import { ReactComponent as StarFilled } from "assets/icons/16px/StarFilled.svg";
import { ReactComponent as StarOutline } from "assets/icons/16px/StarOutline.svg";
import { ReactComponent as Stopwatch } from "assets/icons/24px/Stopwatch.svg";
import { ReactComponent as Support } from "assets/icons/24px/Support.svg";
import { ReactComponent as Teaching } from "assets/icons/20px/Teaching.svg";
import { ReactComponent as Team } from "assets/icons/40px/Team.svg";
import { ReactComponent as ToggleCard } from "assets/icons/16px/Toggle-Card.svg";
import { ReactComponent as ToggleCard24 } from "assets/icons/24px/Toggle-Card.svg";
import { ReactComponent as ToggleTable } from "assets/icons/16px/Toggle-Table.svg";
import { ReactComponent as ToggleTable24 } from "assets/icons/24px/Toggle-Table.svg";
import { ReactComponent as Trashcan } from "assets/icons/16px/Trashcan.svg";
import { ReactComponent as Trashcan24 } from "assets/icons/24px/Trashcan.svg";
import { ReactComponent as Upload } from "assets/icons/16px/Upload.svg";
import { ReactComponent as UserManagement } from "assets/icons/20px/UserManagement.svg";
import { ReactComponent as Warning } from "assets/icons/24px/Warning.svg";
import { ReactComponent as WifiOffline } from "assets/icons/16px/WifiOffline.svg";
import { ReactComponent as ZoomIn } from "assets/icons/24px/ZoomIn.svg";
import { ReactComponent as ZoomOut } from "assets/icons/24px/ZoomOut.svg";
import { ReactComponent as Certificate } from "assets/icons/72px/Certificate.svg";
import { ReactComponent as Badge } from "assets/icons/72px/Badge.svg";
import { ReactComponent as CalendarMonth } from "assets/icons/20px/CalendarMonth.svg";
import { Icons } from "components/icons/constants/icons";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SvgIcon {
    key: keyof typeof Icons;
    base: FunctionComponent<SVGProps<SVGSVGElement>>;
    large: FunctionComponent<SVGProps<SVGSVGElement>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

export const SvgIcons: SvgIcon[] = [
    { key: Icons.AllCompleted, base: AllCompleted, large: AllCompleted },
    { key: Icons.Analytics, base: Analytics, large: Analytics },
    { key: Icons.Archive, base: Archive, large: Archive },
    { key: Icons.ArrowCircleLeft, base: ArrowCircleLeft, large: ArrowCircleLeft },
    { key: Icons.ArrowCircleRight, base: ArrowCircleRight, large: ArrowCircleRight },
    { key: Icons.ArrowDownThick, base: ArrowDownThick, large: ArrowDownThick },
    { key: Icons.ArrowDownward, base: ArrowDownward, large: ArrowDownward },
    { key: Icons.ArrowUpward, base: ArrowUpward, large: ArrowUpward },
    { key: Icons.Assignment, base: Assignment, large: Assignment },
    { key: Icons.Badge, base: Badge, large: Badge },
    { key: Icons.Book, base: Book16, large: Book24 },
    {
        key: Icons.BookmarkOutline,
        base: BookmarkOutline,
        large: BookmarkOutline,
    },
    { key: Icons.Calendar, base: Calendar, large: Calendar },
    { key: Icons.CalendarMonth, base: CalendarMonth, large: CalendarMonth },
    { key: Icons.CalendarSingle, base: CalendarSingle, large: CalendarSingle },
    { key: Icons.CalendarMulti, base: CalendarMulti, large: CalendarMulti },
    { key: Icons.CalendarBusy, base: CalendarBusy, large: CalendarBusy },
    { key: Icons.Catalog, base: Catalog, large: Catalog },
    { key: Icons.Cancel, base: Cancel, large: Cancel },
    { key: Icons.Checklist, base: Checklist, large: Checklist },
    { key: Icons.ChalkboardTeacher, base: ChalkboardTeacher, large: ChalkboardTeacher },
    { key: Icons.Chart, base: Chart, large: Chart },
    { key: Icons.Checkmark, base: Checkmark, large: Checkmark },
    { key: Icons.CheckmarkClipboard, base: CheckmarkClipboard, large: CheckmarkClipboard },
    {
        key: Icons.CheckmarkOvalFilled,
        base: CheckmarkOvalFilled,
        large: CheckmarkOvalFilled,
    },
    {
        key: Icons.CheckmarkOvalOutline,
        base: CheckmarkOvalOutline,
        large: CheckmarkOvalOutline,
    },
    { key: Icons.ChevronDown, base: ChevronDown16, large: ChevronDown24 },
    { key: Icons.ChevronDownWhite, base: ChevronDownWhite16, large: ChevronDownWhite16 },
    { key: Icons.ChevronLeft, base: ChevronLeft16, large: ChevronLeft24 },
    { key: Icons.ChevronFirst, base: ChevronFirst, large: ChevronFirst },
    { key: Icons.ChevronLast, base: ChevronLast, large: ChevronLast },
    { key: Icons.ChevronRight, base: ChevronRight16, large: ChevronRight24 },
    { key: Icons.ChevronUp, base: ChevronUp16, large: ChevronUp24 },
    {
        key: Icons.CircleChecked,
        base: CircleChecked,
        large: CircleChecked,
    },
    {
        key: Icons.CircleUnchecked,
        base: CircleUnchecked,
        large: CircleUnchecked,
    },
    { key: Icons.Clock, base: Clock, large: Clock },
    { key: Icons.ClockWithArrow, base: ClockWithArrow, large: ClockWithArrow },
    { key: Icons.Close, base: Close16, large: Close24 },
    { key: Icons.Collapse, base: Collapse16, large: Collapse16 },
    { key: Icons.Certificate, base: Certificate, large: Certificate },
    { key: Icons.DefaultAvatar, base: DefaultAvatar24, large: DefaultAvatar24 },
    { key: Icons.Desktop, base: Desktop, large: Desktop },
    { key: Icons.DesktopBusy, base: DesktopBusy, large: DesktopBusy },
    { key: Icons.DesktopWithBullets, base: DesktopWithBullets, large: DesktopWithBullets },
    { key: Icons.Delta, base: Delta16, large: Delta16 },
    { key: Icons.Document, base: Document, large: Document },
    {
        key: Icons.Download,
        base: Download16,
        large: Download24,
    },
    {
        key: Icons.DownloadComplete,
        base: DownloadComplete,
        large: DownloadComplete,
    },
    { key: Icons.DownloadRemove, base: DownloadRemove, large: DownloadRemove },
    { key: Icons.DownloadSync, base: DownloadSync, large: DownloadSync },
    { key: Icons.Dot, base: Dot16, large: Dot16 },
    { key: Icons.DragAndDrop, base: DragAndDrop, large: DragAndDrop },
    { key: Icons.Edit, base: Edit, large: Edit },
    { key: Icons.EditOpen, base: EditOpen, large: EditOpen },
    { key: Icons.EditCalendar, base: EditCalendar, large: EditCalendar },
    { key: Icons.Email, base: Email, large: Email },
    { key: Icons.Empty, base: Empty, large: Empty },

    { key: Icons.Enlarge, base: Enlarge, large: Enlarge },
    { key: Icons.Enterprise, base: Enterprise, large: Enterprise },
    { key: Icons.EventType, base: EventType, large: EventType },
    { key: Icons.Expand, base: Expand16, large: Expand16 },
    { key: Icons.Eye, base: Eye, large: Eye },
    { key: Icons.FactCheck, base: FactCheck, large: FactCheck },
    { key: Icons.FileCopy, base: FileCopy, large: FileCopy },
    { key: Icons.Folder, base: Folder, large: Folder },
    { key: Icons.History, base: History, large: History },
    { key: Icons.Home, base: Home16, large: Home24 },
    { key: Icons.Individual, base: Individual, large: Individual },
    { key: Icons.Information, base: Information16, large: Information24 },
    { key: Icons.InformationOutline, base: InformationOutline, large: InformationOutline },
    { key: Icons.InPersonEvent, base: InPersonEvent, large: InPersonEvent },
    { key: Icons.LibraryBooks, base: LibraryBooks, large: LibraryBooks },
    { key: Icons.License, base: License, large: License },
    { key: Icons.Lightbulb, base: Lightbulb, large: Lightbulb },
    { key: Icons.LiveVirtualEvent, base: LiveVirtualEvent, large: LiveVirtualEvent },
    { key: Icons.List, base: List, large: List },
    { key: Icons.Lock, base: Lock, large: Lock },
    { key: Icons.LockPerson, base: LockPerson, large: LockPerson },
    { key: Icons.Logo, base: Logo, large: Logo },
    { key: Icons.MediaPlay, base: MediaPlay, large: MediaPlay24 },
    { key: Icons.Message, base: Message, large: Message },
    { key: Icons.MetaPanel, base: MetaPanel16, large: MetaPanel24 },
    { key: Icons.Menu, base: Menu, large: Menu },
    { key: Icons.Minus, base: Minus, large: Minus },
    {
        key: Icons.MoreHorizontal,
        base: MoreHorizontal16,
        large: MoreHorizontal24,
    },
    { key: Icons.MoreVertical, base: MoreVertical16, large: MoreVertical24 },
    { key: Icons.NewMaterial, base: NewMaterial16, large: NewMaterial16 },
    { key: Icons.Note, base: Note16, large: Note16 },
    { key: Icons.OnlineLearning, base: OnlineLearning, large: OnlineLearning },
    { key: Icons.OpenBook, base: OpenBook, large: OpenBook },
    { key: Icons.Phone, base: Phone, large: Phone },
    { key: Icons.Pin, base: Pin16, large: Pin16 },
    { key: Icons.Placeholder, base: Placeholder16, large: Placeholder24 },
    { key: Icons.Plus, base: Plus16, large: Plus24 },
    { key: Icons.PlusWithCircle, base: PlusWithCircle, large: PlusWithCircle },
    { key: Icons.PublishWithChanges, base: PublishWithChanges, large: PublishWithChanges },
    { key: Icons.Preferences, base: Preferences, large: Preferences },
    { key: Icons.Question, base: Question, large: Question },
    { key: Icons.ReferenceLink, base: ReferenceLink, large: ReferenceLink },
    {
        key: Icons.ReferencePanel,
        base: ReferencePanel16,
        large: ReferencePanel24,
    },
    { key: Icons.Reset, base: Reset, large: Reset },
    { key: Icons.Return, base: Return24, large: Return24 },
    { key: Icons.ScreenShare, base: ScreenShare, large: ScreenShare },
    { key: Icons.StopScreenShare, base: StopScreenShare, large: StopScreenShare },
    { key: Icons.Scroll, base: Scroll16, large: Scroll24 },
    { key: Icons.Search, base: Search, large: Search },
    { key: Icons.Settings, base: Settings, large: Settings },
    { key: Icons.Share, base: Share, large: Share },
    { key: Icons.Sitnav, base: Sitnav, large: Sitnav },
    { key: Icons.StarFilled, base: StarFilled, large: StarFilled },
    { key: Icons.StarOutline, base: StarOutline, large: StarOutline },
    { key: Icons.Stopwatch, base: Stopwatch, large: Stopwatch },
    { key: Icons.Support, base: Support, large: Support },
    { key: Icons.Team, base: Team, large: Team },
    { key: Icons.Trashcan, base: Trashcan, large: Trashcan24 },
    { key: Icons.Warning, base: Warning, large: Warning },
    { key: Icons.WifiOffline, base: WifiOffline, large: WifiOffline },
    { key: Icons.ToggleCard, base: ToggleCard, large: ToggleCard24 },
    { key: Icons.ToggleTable, base: ToggleTable, large: ToggleTable24 },
    { key: Icons.ZoomIn, base: ZoomIn, large: ZoomIn },
    { key: Icons.ZoomOut, base: ZoomOut, large: ZoomOut },
    { key: Icons.Assessment, base: Assessment, large: Assessment },
    { key: Icons.Assistant, base: Assistant, large: Assistant },
    { key: Icons.Content, base: Content, large: Content },
    { key: Icons.Evaluation, base: Evaluation, large: Evaluation },
    { key: Icons.EvaluationIncomplete, base: EvaluationIncomplete, large: EvaluationIncomplete },
    { key: Icons.Attendance, base: Attendance, large: Attendance },
    { key: Icons.Enrollment, base: Enrollment, large: Enrollment },
    { key: Icons.EventDetails, base: EventDetails, large: EventDetails },
    { key: Icons.Upload, base: Upload, large: Upload },
    { key: Icons.Logout, base: Logout, large: Logout },
    { key: Icons.Achievements, base: Achievements, large: Achievements },
    { key: Icons.AenManager, base: AenManager, large: AenManager },
    { key: Icons.Announcements, base: Announcements, large: Announcements },
    { key: Icons.Community, base: Community, large: Community },
    { key: Icons.Courses, base: Courses, large: Courses },
    { key: Icons.Events, base: Events, large: Events },
    { key: Icons.Learning, base: Learning, large: Learning },
    { key: Icons.Organization, base: Organization, large: Organization },
    { key: Icons.Products, base: Products, large: Products },
    { key: Icons.Reporting, base: Reporting, large: Reporting },
    { key: Icons.Teaching, base: Teaching, large: Teaching },
    { key: Icons.UserManagement, base: UserManagement, large: UserManagement },
    { key: Icons.PersonOutline, base: PersonOutline, large: PersonOutline },
    { key: Icons.SlideClose, base: SlideClose, large: SlideClose },
];

// #endregion Constants
