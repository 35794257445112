import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";
import React, { useMemo } from "react";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./radio-button.scss";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

export enum RadioButtonLabelPosition {
    Bottom = "-label-bottom",
    Right = "",
}

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface RadioButtonProps<TValue extends string | string[] | number>
    extends FormFieldProps,
        InputProperties {
    checked: boolean;
    cssClassName?: string;
    disabled?: boolean;
    groupName?: string;
    labelPosition?: RadioButtonLabelPosition;
    onChange?: () => void;
    value?: TValue;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "radio-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RadioButton: React.FC<RadioButtonProps<string | string[] | number>> = (
    props: RadioButtonProps<string | string[] | number>
) => {
    const { readOnly } = useReadOnly();
    const isDisabled = useMemo(() => props.disabled || readOnly, [props.disabled, readOnly]);

    let cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.checked) {
        cssClassNames.push("-selected");
    }

    if (props.labelPosition) {
        cssClassNames.push(props.labelPosition);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        if (isDisabled) {
            return;
        }

        if (event.key === " ") {
            event.preventDefault();
            props.onChange?.();
        }
    };

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        props.onChange?.();
    };

    const tabIndex = isDisabled ? -1 : 0;

    return (
        <button
            className={cssClassNames.join(" ")}
            onClick={handleOnClick}
            onKeyDown={onKeyDown}
            tabIndex={props.disabled ? -1 : 0}
            aria-label={props.label}>
            <input
                checked={props.checked}
                disabled={props.disabled || readOnly}
                name={props.groupName}
                onChange={() => {
                    /* Add onChange to to suppress React Warnings. Click is handled on div. */
                }}
                tabIndex={-1}
                type="radio"
                value={props.value}
            />
            <div className="radio"></div>
            <label htmlFor={props.id}>{props.label}</label>
        </button>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default RadioButton;

// #endregion Export
