import React from "react";
import { EventContactCard } from "components/events/event-contact-card/event-contact-card";
import { EventContractForm } from "components/events/event-contract-form/event-contract-form";
import { EventDetailsForm } from "components/events/event-details-form/event-details-form";
import { EventOrganizationSelection } from "components/events/event-organization-selection/event-organization-selection";
import { EventProductSelection } from "components/events/event-product-selection/event-product-selection";
import { EventProviderSelection } from "components/events/event-provider-selection/event-provider-selection";
import { EventType } from "models/enumerations/events/event-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { EventLinkTrialForm } from "components/events/event-link-trial-form/event-link-trial-form";
import { EventInvoiceForm } from "components/events/event-invoice-form/event-invoice-form";
import "./admin-edit-event-details-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-details-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventDetailsPage: React.FC<AdminEditEventDetailsPageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventDetailsPage
)((): JSX.Element => {
    const event = useEventActiveRecordContext();

    useRedirectOnForbidden(sitemap.admin.dashboard);

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                    {t("eventDetails")}
                </Heading>
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <EventDetailsForm event={event} />
                {event.type === EventType.NFPACatalog && (
                    <>
                        <EventProductSelection event={event} />
                        <EventContactCard event={event} />
                        <EventLinkTrialForm event={event} />
                    </>
                )}
                {event.type === EventType.Contract && (
                    <>
                        <EventOrganizationSelection event={event} />
                        <EventContractForm event={event} />
                        <EventProductSelection event={event} />
                        <EventContactCard event={event} />
                        <EventLinkTrialForm event={event} />
                    </>
                )}
                {event.type === EventType.EducationNetwork && (
                    <>
                        <EventProviderSelection event={event} />
                        <EventProductSelection event={event} />
                        <EventInvoiceForm event={event} />
                        <EventContactCard event={event} />
                        <EventLinkTrialForm event={event} />
                    </>
                )}
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventDetailsPage };

// #endregion Exports
