import React, { useMemo, useReducer } from "react";
import { sitemap } from "sitemap";
import { DataTable } from "../../../../../components/tables/data-table/data-table";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { Badge } from "components/badges/badge/badge";
import { RouteUtils } from "utilities/route-utils";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useUsers } from "utilities/hooks/models/users/use-users";
import { EmptyText } from "components/empty-text/empty-text";
import { Pager } from "components/pager/pager";
import { userSearchReducer } from "../../users/user-list-page/user-search-reducer";
import { useParams } from "react-router-dom";
import { NumberUtils } from "utilities/number-utils";
import { CollectionUtils } from "utilities/collection-utils";
import "./user-providers-associated-users-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserProvidersAssociatedUsersPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-providers-associated-users-page";
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserProvidersAssociatedUsersPage: React.FC<UserProvidersAssociatedUsersPageProps> =
    validatePageAccess(AccessControlKeys.UserProvidersAssociatedUsersPage)((): JSX.Element => {
        const [{ currentPage }, dispatch] = useReducer(userSearchReducer, {
            searchText: "",
            currentPage: 1,
        });

        const { id: providerIdParam } = useParams();
        const providerId = useMemo(
            () => NumberUtils.parseInt(providerIdParam) ?? 0,
            [providerIdParam]
        );

        const { users, rowCount: userCount } = useUsers({
            providerId: providerId,
            includeRoles: true,
            includeInstructorsAssociatedWithProvider: true,
            skip: (currentPage - 1) * ITEMS_PER_PAGE,
            take: ITEMS_PER_PAGE,
        });

        const getViewUserPath = (userId: number) => {
            return RouteUtils.replacePathParams(sitemap.admin.userManagement.users.info.default, {
                id: userId,
            });
        };

        const onPageClick = (pageNumber: number) => {
            dispatch({
                type: "update_currentPage",
                value: pageNumber,
            });
        };

        return (
            <div className={CSS_CLASS_NAME}>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            <h2>{t("associatedUsers")}</h2>
                            {CollectionUtils.hasValues(users) ? (
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="id">{t("id")}</th>
                                            <th className="name">{t("name")}</th>
                                            <th className="email">{t("email")}</th>
                                            <th className="role">{t("role")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user) => (
                                            <tr key={`user-row-${user.id}`}>
                                                <td className="id">{user.id}</td>
                                                <td className="name">
                                                    {user.invitePending() ? (
                                                        <Badge text={t("invitePending")} />
                                                    ) : (
                                                        user.getFirstAndLastName()
                                                    )}
                                                </td>
                                                <td className="email">{user.email}</td>
                                                <td className="role">
                                                    <div className="user-roles">
                                                        {user.providerId != null &&
                                                            user.providerId === providerId && (
                                                                <Badge
                                                                    key={user.id}
                                                                    text={t("enProviderAdmin")}
                                                                />
                                                            )}
                                                        {user.instructorProfile != null &&
                                                            user.instructorProfile.providerId ===
                                                                providerId && (
                                                                <Badge
                                                                    key={user.id}
                                                                    text={t("instructor")}
                                                                />
                                                            )}
                                                    </div>
                                                </td>
                                                <td className="action">
                                                    <ContextMenu>
                                                        <ContextMenuAnchor
                                                            hrefPath={getViewUserPath(user.id ?? 0)}
                                                            displayName={t("viewUserInfo")}
                                                        />
                                                    </ContextMenu>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </DataTable>
                            ) : (
                                <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
                            )}
                        </SkipNavContent>
                    </div>
                </div>
                {users != null && users.length > 0 && (
                    <div className="footer">
                        <Pager
                            currentPage={currentPage}
                            totalPages={userCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={userCount}
                        />
                    </div>
                )}
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserProvidersAssociatedUsersPage };

// #endregion Exports
