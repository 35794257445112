import React from "react";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface IconSamplerProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const IconSampler: React.FC<IconSamplerProps> = (): JSX.Element => {
    const iconKeys = Object.keys(Icons);

    const downloadSVG = (icon: Icons) => {
        const svg = document.getElementsByClassName(icon)[0] as HTMLElement;
        const svgData = new XMLSerializer().serializeToString(svg);
        const blob = new Blob([svgData], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${icon}.svg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="mx-auto px-4">
            <h1>LMS Icons</h1>
            <div className="grid grid-cols-8">
                {iconKeys.map((icon) => {
                    return (
                        <span
                            className="fill-neutral-90 hover:cursor-pointer mb-2"
                            onClick={() => downloadSVG(icon as Icons)}>
                            <Icon cssClassName={icon} type={icon as Icons} size={IconSizes.Large} />
                            <span className="ml-4">{icon}</span>
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { IconSampler };

// #endregion Exports
