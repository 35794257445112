import React, { useMemo } from "react";
import { ContractCard } from "components/third-party/contract-card/contract-card";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { ProductDescriptionCard } from "components/products/product-description-card/product-description-card";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useParams } from "react-router-dom";
import { useProduct } from "utilities/hooks/models/products/use-product";
import { useProductUnits } from "utilities/hooks/models/products/use-product-units";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import "./third-party-online-learning-details-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ThirdPartyOnlineLearningDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "third-party-online-learning-details-page";
const NOTIFICATION_TYPES = [NotificationType.OllContractCreation];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ThirdPartyOnlineLearningDetailsPage: React.FC<ThirdPartyOnlineLearningDetailsPageProps> =
    validatePageAccess(AccessControlKeys.ThirdPartyOnlineLearningDetailsPage)((): JSX.Element => {
        const { productId: productIdParam, contractId: contractIdParam } = useParams();
        const productId = useMemo(
            () => NumberUtils.parseInt(productIdParam) ?? 0,
            [productIdParam]
        );
        const contractId = useMemo(
            () => NumberUtils.parseInt(contractIdParam) ?? 0,
            [contractIdParam]
        );
        const { record: GlobalState } = useGlobalState();
        const userId = useMemo(
            () => GlobalState?.currentIdentity?.user?.id,
            [GlobalState?.currentIdentity?.user?.id]
        );

        const { contract } = useContract({ contractId });
        const { product } = useProduct({ productId });
        const { units } = useProductUnits({ productId, includeUnitCourses: true });

        const { notifications, markNotificationAsRead } = useUserNotifications({
            contractId: contractId,
            notificationTypes: NOTIFICATION_TYPES,
            roleType: RoleType.ClientAdmin,
            userId: userId,
        });

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.EventDetails} size={IconSizes.Large} />
                        <Heading size={HeadingSize.Small}>{t("about")}</Heading>
                    </div>
                    {notifications != null && (
                        <div className={`${CSS_CLASS_NAME}__notifications`}>
                            {notifications.map((notification, index) => (
                                <NotificationBanner
                                    key={`${notification.id}-${index}`}
                                    icon={notification.getNotificationIcon()}
                                    message={notification.message}
                                    notificationId={notification.id!}
                                    onClose={() => markNotificationAsRead?.(notification.id!)}
                                    style={notification.notificationType}
                                />
                            ))}
                        </div>
                    )}
                </div>

                <div className={`${CSS_CLASS_NAME}__contract`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                        {t("contractInformation")}
                    </Heading>
                    <ContractCard contract={contract} stacked={true} />
                </div>
                <div className={`${CSS_CLASS_NAME}__about`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                        {t("productInformation")}
                    </Heading>
                    <ProductDescriptionCard product={product} stacked={true} units={units} />
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ThirdPartyOnlineLearningDetailsPage };

// #endregion Exports
