import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { EventRecord } from "models/view-models/events/event-record";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorLedTrainingTypeBadge } from "../instructor-led-training-type-badge/instructor-led-training-type-badge";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import {
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { ToastManager } from "utilities/toast/toast-manager";
import "./event-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventListProps {
    events: EventRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventList: React.FC<EventListProps> = ({ events }): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const { get: getProvider } = ProviderService.useGet();
    const loggedInUserProviderId = useMemo(
        () => globalState?.currentIdentity?.user?.providerId ?? -1,
        [globalState?.currentIdentity?.user?.providerId]
    );
    const [associatedProvider, setAssociatedProvider] = useState<ProviderRecord>();

    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const isAEN = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);

    const fetchProvider = useCallback(async () => {
        try {
            const pathParams: ProviderResourcePathParams = {
                id: loggedInUserProviderId,
            };

            const providerResponse = await getProvider(pathParams);
            const providerResult = providerResponse?.result;

            if (providerResult?.resultObject == null || providerResult.hasErrors()) {
                throw new Error();
            }
            setAssociatedProvider(providerResult.resultObject);
        } catch {
            ToastManager.error(t("problemLoadingProvider"));
        }
    }, [getProvider, loggedInUserProviderId]);

    useEffect(() => {
        if (isAEN) {
            fetchProvider();
        }
    }, [fetchProvider, isAEN]);

    const canEditEvents =
        ((isAEN && associatedProvider?.isActive) ||
            (currentUserIsInNfpaRole &&
                !globalState.currentIdentity?.isCurrentlyInRole(RoleType.NfpaSupport))) ??
        false;
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const getEditEventPath = (event: EventRecord) => {
        const path = currentUserIsInNfpaRole
            ? sitemap.admin.event.edit.details
            : sitemap.admin.event.edit.contact;

        return RouteUtils.replacePathParams(path, { id: event.id });
    };

    const getManageEventPath = (event: EventRecord) => {
        const manageEventRoute =
            event.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual
                ? RouteUtils.replacePathParams(viewEventRoutes.details, {
                      id: event.id,
                  })
                : RouteUtils.replacePathParams(viewEventRoutes.details, {
                      id: event.id,
                  });

        return manageEventRoute;
    };

    const getEventStatusBadge = (event: EventRecord) => {
        if (event.status === PublishStatus.Canceled) {
            return <Badge style={BadgeStyle.Error} text={t("canceled")} />;
        }

        if (event.status === PublishStatus.Draft) {
            return <Badge style={BadgeStyle.Default} text={t("draft")} />;
        }

        if (event.status === PublishStatus.Live) {
            if (event.isLive()) {
                return <Badge style={BadgeStyle.Success} text={t("live")} />;
            } else {
                return <Badge style={BadgeStyle.Success} text={t("completed")} />;
            }
        }

        return <Badge style={BadgeStyle.Default} text={t("draft")} />;
    };

    return (
        <>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="name">{t("eventName")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="product">{t("product")}</th>
                        <th className="type">{t("type")}</th>
                        <th className="start-date">{t("startDate")}</th>
                        <th className="status">{t("status")}</th>
                        <th className="action">
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event) => (
                        <tr key={event.id}>
                            <td className="name">
                                <span className={`${CSS_CLASS_NAME}__event-name`}>
                                    {event.name}
                                </span>
                            </td>
                            <td className="id">{event.id}</td>
                            <td className="product">{event.product?.name}</td>
                            <td className="type">
                                {event.instructorLedTrainingType != null && (
                                    <InstructorLedTrainingTypeBadge
                                        trainingType={event.instructorLedTrainingType}
                                    />
                                )}
                            </td>
                            <td className="start-date">
                                {event.getFirstEventDateText()}{" "}
                                {event.eventDays != null && event.eventDays.length > 1 ? (
                                    <Icon type={Icons.CalendarMulti} />
                                ) : (
                                    <Icon type={Icons.CalendarSingle} />
                                )}
                            </td>
                            <td className="status">{getEventStatusBadge(event)}</td>
                            <td className="action">
                                <ContextMenu>
                                    {canEditEvents && (
                                        <ContextMenuAnchor
                                            hrefPath={getEditEventPath(event)}
                                            displayName={t("editEvent")}
                                        />
                                    )}
                                    <ContextMenuAnchor
                                        hrefPath={getManageEventPath(event)}
                                        displayName={t("viewEvent")}
                                    />
                                </ContextMenu>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </DataTable>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventList };

// #endregion Exports
