import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { CreateBulkEnrollmentDtoRecord } from "models/view-models/enrollments/create-bulk-enrollment-params-record";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { EncodingType } from "models/enumerations/encoding/encoding-type";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentService,
    WithdrawEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { FileUtils } from "utilities/files/file-utils";
import { ResultErrorRecord, ResultRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

interface useEnrollmentsHook {
    enrollments: EnrollmentRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    createEnrollment: (createEnrollmentDto?: CreateEnrollmentDtoRecord) => Promise<boolean>;
    createBulkEnrollments: (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ) => Promise<ResultRecord<BulkEnrollmentSummaryRecord>>;
    withdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
    rowCount: number;
}

interface useEnrollmentsHookOptions {
    userId?: number;
    contractId?: number;
    eventId?: number;
    productId?: number;
    userSearchText?: string;
    includeUser?: boolean;
    skip?: number;
    take?: number;
}

export function useEnrollments(props: useEnrollmentsHookOptions): useEnrollmentsHook {
    const encodingType: EncodingType = FileUtils.getEncodingType();
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [enrollments, setEnrollments] = useState<EnrollmentRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const userId = props.userId;
    const eventId = props.eventId;
    const contractId = props.contractId;
    const productId = props.productId;
    const userSearchText = props.userSearchText;
    const skip = props.skip;
    const take = props.take;
    const includeUser = props.includeUser ?? false;

    const { create: enrollmentCreate } = EnrollmentService.useCreate();
    const { create: bulkEnrollmentCreate } = EnrollmentService.useBulkCreate();
    const { patch: withdrawEnrollmentApi } = EnrollmentService.useWithdrawEnrollment();
    const { list: listEnrollmentAPI } = EnrollmentService.useList();

    const fetchData = useCallback(async () => {
        if (
            (userId != null && userId < 1) ||
            (eventId != null && eventId < 1) ||
            (eventId != null && eventId < 1)
        ) {
            return;
        }
        try {
            (async function getEnrollments() {
                const { result, resultObjects, rowCount } = await listEnrollmentAPI({
                    userId: userId,
                    contractId: contractId,
                    eventId: eventId,
                    productId: productId,
                    userSearchText: userSearchText,
                    includeUser: includeUser,
                    skip: skip,
                    take: take,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setEnrollments(resultObjects);
                setRowCount(rowCount);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollments"));
        }
    }, [
        userId,
        eventId,
        listEnrollmentAPI,
        contractId,
        productId,
        userSearchText,
        includeUser,
        skip,
        take,
    ]);

    const createEnrollment = async (
        createEnrollmentDto?: CreateEnrollmentDtoRecord
    ): Promise<boolean> => {
        try {
            if (productId == null || productId <= 0 || createEnrollmentDto === undefined) {
                throw new Error(t("creatingAnEnrollmentFailed"));
            }

            createEnrollmentDto = createEnrollmentDto.with({ productId: productId });

            const createEnrollmentResponse = await enrollmentCreate(createEnrollmentDto);
            const createEnrollmentResult = createEnrollmentResponse?.result;

            if (
                createEnrollmentResult?.resultObject == null ||
                createEnrollmentResult.hasErrors()
            ) {
                throw new Error(t("thereWasAProblemCreatingANewEnrollment"));
            }

            // Retrieve the data.
            fetchData();

            return true;
        } catch (error) {
            if (typeof error === "string") {
                const errorMessage = error === "" ? t("failedToCreateANewEnrollment") : error;
                ToastManager.error(errorMessage);
            } else if (error instanceof ResultRecord) {
                const { errors } = error;
                errors?.forEach((error) => {
                    ToastManager.error(error.message);
                });
            }
            return false;
        }
    };

    const createBulkEnrollments = async (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ): Promise<ResultRecord<BulkEnrollmentSummaryRecord>> => {
        let result = new ResultRecord<BulkEnrollmentSummaryRecord>();

        if (productId === undefined || createBulkEnrollmentDto === undefined) {
            result.addError("", t("contractIDProductIDAndFileAreNeeded"));
            return result;
        }

        createBulkEnrollmentDto = createBulkEnrollmentDto.with({ productId: productId });

        try {
            const createBulkEnrollmentResponse = await bulkEnrollmentCreate(
                encodingType,
                createBulkEnrollmentDto
            );

            const createBulkEnrollmentResult = createBulkEnrollmentResponse?.result;

            if (createBulkEnrollmentResult?.resultObject == null) {
                throw new Error();
            }

            result = createBulkEnrollmentResult;
        } catch (error) {
            try {
                result = error as ResultRecord<BulkEnrollmentSummaryRecord>;
            } catch (error: any) {
                result.addError("", error.toString());
                ToastManager.error(t("thereWasProblemSavingBulkEnrollments"));
            }
        }

        // Retrieve the data.
        fetchData();

        return result;
    };

    const withdrawEnrollment = async (enrollment: EnrollmentRecord): Promise<boolean> => {
        try {
            const pathParams: WithdrawEnrollmentPathParams = {
                id: enrollment.id!,
            };

            const withdrawEnrollmentResponse = await withdrawEnrollmentApi(pathParams);
            const withdrawResult = withdrawEnrollmentResponse?.result;
            if (withdrawResult?.resultObject == null || withdrawResult.hasErrors()) {
                throw new Error();
            }

            fetchData();
            ToastManager.success(t("withdrawEnrollmentSuccessful"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueWithdrawingAnEnrollment"));
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        enrollments,
        createBulkEnrollments,
        createEnrollment,
        withdrawEnrollment,
        rowCount,
    };
}
