import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { Banner, BannerStyle } from "components/banner/banner";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import {
    CreateUnitCourseParams,
    UnitCourseService,
} from "utilities/services/units/unit-course-service";
import { EmptyText } from "components/empty-text/empty-text";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import {
    ListUnitsQueryParams,
    UnitService,
    UpdateUnitPathParams,
} from "utilities/services/units/unit-service";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { UnitAddCourseModal } from "components/units/unit-courses/unit-add-course-modal/unit-add-course-modal";
import { UnitCourseList } from "components/units/unit-courses/unit-course-list/unit-course-list";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import { UnitRecord } from "models/view-models/units/unit-record";
import { sitemap } from "sitemap";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { t } from "utilities/localization/t";
import "./product-course-series.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCourseSeriesProps {
    createVersionMode: boolean;
    editMode: boolean;
    product: ProductRecord;
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setCourseToggleHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleClicks?: React.Dispatch<React.SetStateAction<number>>;
    setUnit: React.Dispatch<React.SetStateAction<UnitRecord>>;
    toggleClicks?: number;
    unit: UnitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-course-series";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCourseSeries: React.FC<ProductCourseSeriesProps> = ({
    createVersionMode,
    editMode,
    product,
    setCourseHasChanges,
    setCourseToggleHasChanges,
    setToggleClicks,
    setUnit,
    toggleClicks,
    unit,
}) => {
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const initialUnit = useMemo(() => productVersion.units ?? [], [productVersion.units]);
    const [showModal, setShowModal] = useState(false);
    const { create: apiUnitCourseCreate } = UnitCourseService.useCreate();
    const { delete: deleteUnitCourseApi } = UnitCourseService.useDelete();
    const { list: listUnits } = UnitService.useList();
    const { update: updateUnitApi } = UnitService.useUpdate();
    const [readOnly, setReadOnly] = useState(false);
    const deferSave = product.status === ActiveStatus.Active;

    useEffect(() => {
        if (
            product.status === ActiveStatus.Inactive ||
            product.status === ActiveStatus.Archived ||
            (product.status === ActiveStatus.Active &&
                productVersion.status === ActiveStatus.Active) ||
            !editMode
        ) {
            setReadOnly(true);
        }
        if (
            createVersionMode ||
            (editMode && productVersion.status !== ActiveStatus.Active) ||
            product.status === ActiveStatus.Draft
        ) {
            setReadOnly(false);
        }
    }, [createVersionMode, editMode, product.activatedOn, product.status, productVersion.status]);

    useEffect(() => {
        if (initialUnit.length === 0) return;
        if (initialUnit[0].unitCourses == null) return;
        initialUnit[0].unitCourses?.sort(
            (a: UnitCourseRecord, b: UnitCourseRecord) => a.sortOrder! - b.sortOrder!
        );
        setUnit && setUnit(initialUnit[0]);
    }, [initialUnit, setUnit]);

    const onProductVersionUnitsChange = (unit: UnitRecord, unitCourses: UnitCourseRecord[]) => {
        if (productVersion == null) return;
        setUnit && setUnit(initialUnit[0].with({ unitCourses: unitCourses }));

        setProductVersion(
            productVersion.with({ units: [initialUnit[0].with({ unitCourses: unitCourses })] })
        );
    };

    const onProductUnitChange = (unit: UnitRecord) => {
        setUnit && setUnit(unit);
        setProductVersion(productVersion.with({ units: [unit] }));
    };
    /*
    const onProductVersionUnitCourseChange = (unit: UnitRecord, unitCourse: UnitCourseRecord) => {
        if (productVersion == null) return;
        if (productVersion.units == null) {
            return false;
        }
        //Add the course to the list of courses in the unit
        const allUnits = productVersion.units;
    };
    */

    const saveUnitCourses = async (courseIds: number[]): Promise<boolean> => {
        if (deferSave) {
            //You need to defer the save and just build the course structure in memory
            if (productVersion.units == null) {
                return false;
            }
            //Add the course to the list of courses in the unit
            const allUnits: UnitRecord[] =
                productVersion.units !== undefined ? [...productVersion.units!, unit!] : [unit!];
            setProductVersion(productVersion.with({ units: allUnits }));
            return true;
        } else {
            try {
                if (courseIds == null) {
                    throw new Error(t("courseWasUndefined"));
                }
                if (unit == null) {
                    throw new Error("unitWasUndefined");
                }

                let createUnitCourseParams: CreateUnitCourseParams = {
                    courseIds: courseIds,
                    unitId: unit.id!,
                };

                if (CollectionUtils.isNotEmpty(unit?.unitCourses)) {
                    const nextSortOrder =
                        Math.max(...unit.unitCourses.map((uc) => uc.sortOrder!)) + 1;
                    createUnitCourseParams = {
                        courseIds: courseIds,
                        sortOrder: nextSortOrder,
                        unitId: unit?.id!,
                    };
                }

                const createUnitCourseResponse = await apiUnitCourseCreate(createUnitCourseParams);
                const createUnitCourseResult = createUnitCourseResponse?.result;

                if (
                    createUnitCourseResult?.resultObject == null ||
                    createUnitCourseResult.hasErrors()
                ) {
                    throw new Error(t("linkingCourseToAUnitHasFailed"));
                }

                fetchUnits();
            } catch (error: any) {
                if (error === null) {
                    ToastManager.error(t("emptyString"));
                } else {
                    ToastManager.error(`${error}`);
                }
                return false;
            }
        }
        return true;
    };

    const fetchUnits = useCallback(async () => {
        try {
            if (product.id == null || product.id < 1) {
                throw new Error(t("courseProductReturnedAnUnexpectedResult"));
            }

            if (deferSave) {
                console.error("fetchUnits should never be called on an active product.");
                return;
            }

            //PV TO DO:  Do we need to add productVersionId?
            const listUnitsQueryParams: ListUnitsQueryParams = {
                productId: product.id,
                productVersionId: productVersion.id,
                includeUnitCourses: true,
            };

            let listUnitsResponse;
            listUnitsResponse = await listUnits(listUnitsQueryParams);

            const listUnitsResults = listUnitsResponse?.results;
            const units = listUnitsResponse?.resultObjects;

            if (units == null || listUnitsResults == null || listUnitsResults.hasErrors()) {
                throw new Error(t("courseProductReturnedAnUnexpectedResult"));
            } else if (units.length === 0) {
                //setProductVersion(productVersion.with({ onlineLearningType: undefined }));
                throw new Error(t("courseProductReturnedAnUnexpectedResult"));
            } else if (units.length > 1) {
                throw new Error(t("thisProductAlreadyHasMultipleUnitsLearningPath"));
            }

            const unit = units[0];
            unit.sortUnitCourses();

            setProductVersion(
                (previousProductVer: ProductVersionRecord): ProductVersionRecord =>
                    previousProductVer.with({ units: units })
            );
        } catch (error: any) {
            if (error === null) {
                ToastManager.error(t("thereWasAnIssueFetchingCourseProductData"));
            } else {
                ToastManager.error(`${error}`);
            }

            return;
        }
        return;
    }, [product.id, deferSave, productVersion.id, listUnits, setProductVersion]);

    const deleteUnitCourse = async (unitCourse: UnitCourseRecord): Promise<boolean> => {
        try {
            if (deferSave) {
                setCourseHasChanges && setCourseHasChanges(true);

                const courseToDelete = initialUnit[0].unitCourses?.find((uc) => unitCourse);
                if (courseToDelete == null) {
                    return false;
                }

                if (courseToDelete.id != null) {
                    setCourseHasChanges && setCourseHasChanges(true);
                }

                const courseToDeleteIndex = initialUnit[0].unitCourses?.indexOf(courseToDelete);
                if (courseToDeleteIndex != null) {
                    const updatedCourses = initialUnit[0].unitCourses;
                    updatedCourses?.splice(courseToDeleteIndex, 1);
                    setUnit && setUnit(initialUnit[0].with({ unitCourses: updatedCourses }));
                }
            } else {
                await deleteUnitCourseApi(unitCourse.id!);
            }

            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueDeletingTheUnitCourse"));
            return false;
        }
    };

    const onCompleteCourseInOrderToggle = useCallback(async (): Promise<boolean> => {
        const updatedCount = toggleClicks! + 1;
        setToggleClicks && setToggleClicks(updatedCount);
        try {
            if (unit == null) {
                throw new Error(t("thereWasAnIssueUpdatingTheUnitReceivedAnUnexpectedResult"));
            }

            const unitWithChanges = unit.with({
                completeCoursesInOrder: !unit.completeCoursesInOrder,
            });
            if (updatedCount % 2 === 0) {
                setCourseToggleHasChanges && setCourseToggleHasChanges(false);
            } else {
                setCourseToggleHasChanges && setCourseToggleHasChanges(true);
            }
            if (!deferSave) {
                const updateUnitPathParams: UpdateUnitPathParams = {
                    id: unit.id!,
                };

                const updateUnitResponse = await updateUnitApi(
                    unitWithChanges,
                    updateUnitPathParams
                );

                const updateResult = updateUnitResponse?.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error(t("thereWasAnIssueUpdatingTheUnitReceivedAnUnexpectedResult"));
                }
            }
            setProductVersion(
                (previousProductVersion: ProductVersionRecord): ProductVersionRecord =>
                    previousProductVersion.with({ units: [unitWithChanges] })
            );
            return true;
        } catch (error: any) {
            if (error === null) {
                ToastManager.error(t("thereWasAnIssueUpdatingTheUnit"));
            } else {
                ToastManager.error(`${error}`);
            }

            return false;
        }
    }, [
        deferSave,
        setCourseToggleHasChanges,
        setProductVersion,
        setToggleClicks,
        toggleClicks,
        unit,
        updateUnitApi,
    ]);

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            {unit !== undefined && unit !== null && (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <div className={`${CSS_CLASS_NAME}__header__heading`}>
                            <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                                {t("courses")}
                            </Heading>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__header__actions`}>
                            <ToggleLabel
                                checked={unit.completeCoursesInOrder}
                                id="complete-course-toggle"
                                label={t("completeCoursesInOrder")}
                                onToggle={onCompleteCourseInOrderToggle}
                            />
                            <Button
                                text={t("addCourses")}
                                onClick={() => setShowModal(true)}
                                style={ButtonStyle.Primary}
                            />
                        </div>
                    </div>
                    {editMode && productVersion.status === ActiveStatus.Active && (
                        <Banner style={BannerStyle.Light}>
                            <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                                {t(
                                    "changesToCoursesAreNotAvailableWhenEditingAnActiveProductVersionToChangeCourses"
                                )}{" "}
                                <Anchor
                                    cssClassName={`${CSS_CLASS_NAME}__create-new-version-link`}
                                    path={RouteUtils.localizePath(
                                        RouteUtils.replacePathParams(
                                            sitemap.admin.product.edit.online.materials.version
                                                .create,
                                            { id: product.id }
                                        )
                                    )}>
                                    {t("createANewProductVersion")}
                                </Anchor>
                                .
                            </Paragraph>
                        </Banner>
                    )}
                    <UnitAddCourseModal
                        existingUnitCourses={unit.unitCourses}
                        fetchUnitCourseList={fetchUnits}
                        handleSaveUnitCourses={saveUnitCourses}
                        language={product.language}
                        onProductVersionUnitChange={onProductUnitChange}
                        open={showModal}
                        setCourseHasChanges={setCourseHasChanges!}
                        setOpen={setShowModal}
                        audienceType={product.audienceType}
                        unit={unit!}
                    />

                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <UnitCourseList
                            deferSave={deferSave}
                            deleteUnitCourse={deleteUnitCourse}
                            fetchUnitCourseList={fetchUnits}
                            onProductUnitChange={onProductUnitChange}
                            onProductVersionUnitsChange={onProductVersionUnitsChange}
                            selectedUnitIndex={0}
                            setCourseHasChanges={setCourseHasChanges}
                            unit={unit}
                            unitCourses={unit.unitCourses}
                        />
                        {unit.unitCourses?.length === 0 && (
                            <EmptyText>{t("noCoursesAdded")}</EmptyText>
                        )}
                    </div>
                </div>
            )}
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCourseSeries };

// #endregion Exports
