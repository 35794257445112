import { ServiceHookFactory } from "andculturecode-javascript-react";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "attempts";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof AttemptRecord = AttemptRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListAttemptQueryParams {
    includeRegistration?: boolean;
    includeResponses?: boolean;
    includeLastModifiedBy?: boolean;
    registrationId?: number;
    enrollmentId?: number;
    isManualEntry?: boolean;
}

export interface UpdateAttemptPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AttemptService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<AttemptRecord, UpdateAttemptPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<AttemptRecord, ListAttemptQueryParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptService };

// #endregion Exports
