import { RoleType } from "models/enumerations/users/role-type";
import { ReportType } from "components/reports/report-enrollment/report-type";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportEnrollmentState {
    reportType?: ReportType;
    dateRangeStart: Date | null;
    dateRangeEnd: Date | null;
    eventIds?: number[];
    productIds?: number[];
    contractIds?: number[];
    groupIds?: number[];
}

type ReducerActions =
    | { type: "reportType"; reportType?: ReportType }
    | { type: "dateRange"; dateRangeStart: Date | null; dateRangeEnd: Date | null }
    | { type: "updateEventIds"; eventIds: number[] }
    | { type: "updateContractIds"; contractIds: number[] }
    | { type: "updateGroupIds"; groupIds: number[] }
    | { type: "updateProductIds"; productIds: number[] };

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Reducer
// -------------------------------------------------------------------------------------------------

const initialState: ReportEnrollmentState = {
    reportType: undefined,
    eventIds: [],
    productIds: [],
    contractIds: [],
    groupIds: [],
    dateRangeStart: null,
    dateRangeEnd: null,
};

const validate = (state: ReportEnrollmentState): boolean => {
    const { reportType } = state;

    if (reportType == null) {
        return false;
    }

    if (reportType === ReportType.InstructorLedTrainingEvent) {
        return (
            CollectionUtils.hasValues(state.eventIds) ||
            (state.dateRangeStart != null && state.dateRangeEnd != null)
        );
    }

    if (reportType === ReportType.Organization) {
        return (
            CollectionUtils.hasValues(state.contractIds) ||
            CollectionUtils.hasValues(state.eventIds)
        );
    }

    if (reportType === ReportType.OnlineLearningTraining) {
        return CollectionUtils.hasValues(state.productIds);
    }

    return true;
};

const reducer = (state: ReportEnrollmentState, action: ReducerActions): ReportEnrollmentState => {
    switch (action.type) {
        case "reportType":
            const { reportType } = action;

            return {
                ...state,
                reportType,
                eventIds:
                    reportType === ReportType.InstructorLedTrainingEvent ? state.eventIds : [],
                contractIds: reportType === ReportType.Organization ? state.contractIds : [],
                productIds:
                    reportType === ReportType.OnlineLearningTraining ? state.productIds : [],
            };

        case "updateEventIds":
            const { eventIds } = action;

            return { ...state, eventIds };

        case "updateContractIds":
            const { contractIds } = action;

            return {
                ...state,
                contractIds,
            };

        case "updateProductIds":
            const { productIds } = action;

            return { ...state, productIds };

        case "dateRange":
            const { dateRangeStart, dateRangeEnd } = action;

            return {
                ...state,
                dateRangeStart,
                dateRangeEnd,
            };
        default:
            throw new Error();
    }
};
const getReportType = (roleType: RoleType | null | undefined) => {
    if (roleType === RoleType.Instructor || roleType === RoleType.AenAdministrator) {
        return ReportType.InstructorLedTrainingEvent;
    }
    if (roleType === RoleType.ClientAdmin) {
        return ReportType.Organization;
    }

    return undefined;
};
const initializer =
    (roleType?: RoleType | null) =>
    (initialState: ReportEnrollmentState): ReportEnrollmentState => {
        return {
            ...initialState,
            reportType: getReportType(roleType),
        };
    };

// #endregion Reducer
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------
export { initialState, initializer, reducer, validate };
export type { ReportEnrollmentState };

// #endregion Exports
