import React, { useMemo } from "react";
import { Card } from "components/card/card";
import { EventRecord } from "models/view-models/events/event-record";
import { StringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { RouteUtils } from "utilities/route-utils";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import "./event-location-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventLocationCardProps {
    cssClassName?: string;
    event: EventRecord;
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-location-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventLocationCard: React.FC<EventLocationCardProps> = ({
    cssClassName,
    event,
    stacked,
}): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    const noLocationAdded = useMemo(() => {
        if (event.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual) {
            return (
                !StringUtils.hasValue(event.meetingUrl) &&
                !StringUtils.hasValue(event.additionalAddressDetails)
            );
        } else {
            return (
                !StringUtils.hasValue(event.locationName) &&
                !StringUtils.hasValue(event.addressLineOne) &&
                !StringUtils.hasValue(event.addressLineTwo) &&
                !StringUtils.hasValue(event.city) &&
                !StringUtils.hasValue(event.state) &&
                !StringUtils.hasValue(event.country) &&
                !StringUtils.hasValue(event.zipCode) &&
                !StringUtils.hasValue(event.additionalAddressDetails)
            );
        }
    }, [
        event.additionalAddressDetails,
        event.addressLineOne,
        event.addressLineTwo,
        event.city,
        event.country,
        event.instructorLedTrainingType,
        event.locationName,
        event.meetingUrl,
        event.state,
        event.zipCode,
    ]);

    const setAddress = () => {
        if (event.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual) {
            return (
                <>
                    <div className={`${className}__address`}>
                        {StringUtils.hasValue(event.meetingUrl) ? (
                            <>
                                <Paragraph style={ParagraphStyle.Label}>
                                    {t("meetingUrl")}
                                </Paragraph>
                                <Anchor
                                    text={event.meetingUrl}
                                    path={RouteUtils.getValidUrl(event.meetingUrl)}
                                    pathType={AnchorPathType.External}
                                />
                            </>
                        ) : (
                            "--"
                        )}
                    </div>
                    <div className={`${className}__details`}>
                        <Paragraph
                            cssClassName={`${className}__details__label`}
                            style={ParagraphStyle.Label}>
                            {t("additionalDetails")}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.Small}>
                            {event.additionalAddressDetails}
                        </Paragraph>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className={`${className}__address-block`}>
                        <div className={`${className}__address`}>{event.locationName}</div>
                        <div className={`${className}__address`}>{event.addressLineOne}</div>
                        <div className={`${className}__address`}>{event.addressLineTwo}</div>
                        <div className={`${className}__address`}>
                            {event.city} {event.state}, {event.zipCode}
                        </div>
                        <div className={`${className}__address`}>{event.country}</div>
                    </div>
                    <div className={`${className}__details`}>{event.additionalAddressDetails}</div>
                </>
            );
        }
    };

    return (
        <Card cssClassName={className} stacked={stacked}>
            {noLocationAdded ? (
                <EmptyText spacing={EmptyTextSpacing.None}>{t("noLocationAdded")}</EmptyText>
            ) : (
                <>
                    <Heading
                        cssClassName={`${className}__heading`}
                        priority={HeadingPriority.H3}
                        size={HeadingSize.XSmall}>
                        {t("location")}
                    </Heading>
                    <div className={`${className}__container`}>{setAddress()}</div>
                </>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventLocationCard };

// #endregion Exports
