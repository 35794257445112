import React, { useCallback, useEffect, useState } from "react";
import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import { FormSearchSelect } from "components/form/form-search-select/form-search-select";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { SelectOption } from "components/form/inputs/select/select";
import { Product } from "models/interfaces/products/product";
import { ToastManager } from "utilities/toast/toast-manager";
import { BadgeTemplateService } from "utilities/services/badge-templates/badge-template-service";
import { BadgeTemplateRecord } from "models/view-models/badges/badge-template-record";
import {
    ProductService,
    UpdateProductPathParams,
} from "utilities/services/products/product-service";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ProductVersionService } from "utilities/services/products/product-version-service";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductAssociateBadgeProps {
    productVersion: ProductVersionRecord;
    onProductVersionDetailsChange: (productVersion: ProductVersionRecord) => void;
    deferSave: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-associate-badge-form";
const yesNoRadio: TranslatedCopy[] = ["no", "yes"];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductAssociateBadgeForm: React.FC<ProductAssociateBadgeProps> = (
    props: ProductAssociateBadgeProps
): JSX.Element => {
    const { update } = ProductVersionService.useUpdate();
    const { list: listBadgeTemplates } = BadgeTemplateService.useList();
    const [badgeTemplateList, setBadgeTemplateList] = useState<BadgeTemplateRecord[]>([]);
    const [defaultSearchText, setDefaultSearchText] = useState(props.productVersion.badgeName!);
    const [searchText, setSearchText] = useState(props.productVersion.badgeName);

    const [selectedBadgeTemplateOption, setSelectedBadgeTemplateOption] = useState<SelectOption>({
        text: props.productVersion.badgeName,
        value:
            props.productVersion.badgeTemplateId === undefined
                ? ""
                : props.productVersion.badgeTemplateId,
    });

    const updateProduct = (values: ProductVersionRecord): void => {
        props.onProductVersionDetailsChange(values);
    };

    const handleShowBadgeTemplateRadioButtonChange = (value: number): void => {
        const originalProduct = props.productVersion;

        const partialBadgeTemplate = {
            hasBadge: !(value === 0),
        };

        const updatedProduct = props.productVersion.with(partialBadgeTemplate);
        updateProduct(updatedProduct);
        if (!props.deferSave) {
            updateBadgeTemplate(updatedProduct, originalProduct);
        }
    };

    const badgeTemplateOptions: SelectOption[] = badgeTemplateList.map(
        (o): SelectOption => ({
            text: o.name!,
            value: o.templateId!.toString(),
        })
    );

    const handleBadgeTemplateChange = (value: string): void => {
        let selectedOption = badgeTemplateOptions.find((bt) => bt.value === value);
        setSelectedBadgeTemplateOption(selectedOption!);

        let fallbackProduct = props.productVersion;

        let newBadgeTemplate = {
            hasBadge: true,
            badgeTemplateId: value,
            badgeName: selectedOption!.text,
        } as Partial<Product>;

        const updatedProduct = props.productVersion.with(newBadgeTemplate);

        updateProduct(updatedProduct);
        if (!props.deferSave) {
            updateBadgeTemplate(updatedProduct, fallbackProduct);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            const listResponse = await listBadgeTemplates({
                searchText,
            });

            if (
                listResponse?.resultObjects == null ||
                listResponse.results == null ||
                listResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setBadgeTemplateList(listResponse.resultObjects);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheBadgeTemplates"));
            setBadgeTemplateList([]);
        }
    }, [listBadgeTemplates, searchText]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchData();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchText, fetchData]);

    useEffect(() => {
        if (props.productVersion.badgeTemplateId == null) {
            setDefaultSearchText(t("enterSearchCriteria"));
            setSelectedBadgeTemplateOption({
                value: "",
                text: "",
            });
        } else {
            setDefaultSearchText(
                `(${props.productVersion.badgeTemplateId!}) ${props.productVersion.badgeName}`
            );
            setSelectedBadgeTemplateOption({
                value: props.productVersion.badgeTemplateId!,
                text: props.productVersion.badgeName!,
            });
        }
    }, [props.productVersion.badgeName, props.productVersion.badgeTemplateId]);

    const updateBadgeTemplate = async (
        productVersionWithChanges: ProductVersionRecord,
        fallbackProductVersion: ProductVersionRecord
    ): Promise<boolean> => {
        try {
            const updateProductPathParms: UpdateProductPathParams = {
                id: productVersionWithChanges.id!,
            };
            const updateProductResponse = await update(
                productVersionWithChanges,
                updateProductPathParms
            );

            const updateResult = updateProductResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheBadgeTemplate"));
            props.onProductVersionDetailsChange(fallbackProductVersion);
            return false;
        }
        return true;
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                {t("badges")}
            </Heading>
            <div className="columns">
                <div className="column">
                    <Paragraph>{t("doYouWantToAssociateABadge")}</Paragraph>
                    <div className="radio-buttons">
                        {yesNoRadio.map((label, index) => (
                            <RadioButton
                                key={`yesNoBadgeRadio-${index}`}
                                checked={
                                    index === 0
                                        ? !props.productVersion.hasBadge
                                        : props.productVersion.hasBadge
                                }
                                formFieldName={`yesNoBadgeRadio-${index}`}
                                groupName={`yesNoBadgeRadio-${index}`}
                                id={`yesNoRadio-${index}`}
                                label={t(label)}
                                labelPosition={RadioButtonLabelPosition.Right}
                                onChange={() => handleShowBadgeTemplateRadioButtonChange(index)}
                                value={index}
                            />
                        ))}
                    </div>
                    {props.productVersion.hasBadge && (
                        <FormSearchSelect
                            onSearchTextChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            ariaLabelledBy={t("selectABadgeToAssociateWithThisProduct")}
                            formFieldName="associatedBadge"
                            id="associatedBadge"
                            label={t("selectABadgeToAssociateWithThisProduct")}
                            placeholder={t("searchByBadgeName")}
                            required={true}
                            defaultText={defaultSearchText}
                            onChange={handleBadgeTemplateChange}
                            options={badgeTemplateOptions}
                            selectedOption={selectedBadgeTemplateOption}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductAssociateBadgeForm };

// #endregion Exports
