import { ProviderApplication } from "models/interfaces/aen-applications/provider-application";
import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import { RecordUpdater, UseRecordContextFactory } from "./use-record-context-factory";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export type ProviderApplicationRecordUpdater = RecordUpdater<
    ProviderApplication,
    ProviderApplicationRecord
>;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const { RecordContext: ProviderApplicationContext, useRecord: useProviderApplication } =
    UseRecordContextFactory.build<ProviderApplication, ProviderApplicationRecord>(
        ProviderApplicationRecord
    );

export { ProviderApplicationContext, useProviderApplication };

// #endregion Exports
