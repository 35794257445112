import React from "react";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { RecordUtils } from "andculturecode-javascript-core";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { AenResourceRecord } from "models/view-models/aen-resources/aen-resource-record";
import { t } from "utilities/localization/t";
import "./aen-resource-form.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenResourceFormProps {
    aenResource: AenResourceRecord;
    setAenResource: React.Dispatch<React.SetStateAction<AenResourceRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-resource-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenResourceForm: React.FC<AenResourceFormProps> = (props) => {
    const updateResource = (value: Partial<AenResourceRecord>): void => {
        props.setAenResource((prev) => prev.with(value));
    };

    const handleSetFileId = (file: FileRecord) => {
        updateResource({
            file: RecordUtils.ensureRecord(file, FileRecord),
            fileId: file.id,
        });
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("theSelectedFileTypeIsNotPermitted"));
    };

    const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateResource({ name: e.target.value });
    };

    const handleSetDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateResource({ description: e.target.value });
    };

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <FormTextInput
                    formFieldName="contentName"
                    id="contentName"
                    label={t("name")}
                    maxLength={60}
                    onChange={handleSetName}
                    placeholder={t("enterContentName")}
                    required={true}
                    type={InputTypes.Text}
                    value={props.aenResource.name}
                />
                <FormTextArea
                    formFieldName="contentDescription"
                    id="contentDescription"
                    label={t("description")}
                    maxLength={1000}
                    onChange={handleSetDescription}
                    placeholder={t("enterContentDescription")}
                    required={true}
                    rows={5}
                    value={props.aenResource.description}
                />
                <FormFileInput
                    buttonText={t("chooseFile")}
                    onFileInvalid={handleInvalidFile}
                    formFieldName="file"
                    id="file"
                    label={t("fileUpload")}
                    onFileUpload={handleSetFileId}
                    placeholder={t("selectFile")}
                    required={true}
                    storageContainer={StorageContainers.FileUpload}
                    value={props.aenResource.fileId}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenResourceForm };

// #endregion Exports
