import React, { useMemo } from "react";
import { Icons } from "components/icons/constants/icons";
import { RouteUtils } from "utilities/route-utils";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { t } from "utilities/localization/t";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import "./event-assessment-results-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAssessmentResultsHeaderProps {
    eventId: number;
    optional: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-assessment-results-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAssessmentResultsHeader: React.FC<EventAssessmentResultsHeaderProps> = ({
    eventId,
    optional,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const assessmentsPath: string = useMemo(
        () =>
            RouteUtils.replacePathParams(viewEventRoutes.assessments, {
                id: eventId,
            }),
        [eventId, viewEventRoutes.assessments]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <ButtonIcon
                linkPath={assessmentsPath}
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.Secondary}
                buttonType={ButtonType.Link}
                iconType={Icons.ChevronLeft}
                text={t("backToAllAssessments")}
            />
            <div className={`${CSS_CLASS_NAME}__title`}>
                <h2>{t("assessmentResults")}</h2>
                <Badge
                    text={optional ? t("optional") : t("required")}
                    style={optional ? BadgeStyle.Default : BadgeStyle.Neutral}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAssessmentResultsHeader };

// #endregion Exports
