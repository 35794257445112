import React, { useMemo, useState } from "react";
import { AddBulkEnrollmentModal } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-modal";
import { AddEnrollmentModal } from "components/enrollments/add-enrollment-modal/add-enrollment-modal";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { OllEnrollmentList } from "components/enrollments/enrollment-list/oll-enrollment-list/oll-enrollment-list";
import { RoleType } from "models/enumerations/users/role-type";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useOLLContractEnrollments } from "utilities/hooks/models/enrollments/use-oll-contract-enrollments";
import { useParams } from "react-router-dom";
import { Pager } from "components/pager/pager";
import { t } from "utilities/localization/t";
import {
    ContractCertificateService,
    ContractCertificateDownloadPathParams,
} from "utilities/services/certificates/contract-certificate-service";
import "./user-contract-oll-detail-enrollment.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractOLLDetailEnrollmentProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contract-oll-detail-enrollment";
const ITEMS_PER_PAGE = 6;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractOLLDetailEnrollment: React.FC<UserContractOLLDetailEnrollmentProps> = (
    props: UserContractOLLDetailEnrollmentProps
): JSX.Element => {
    const { contractId: contractIdParam } = useParams();
    const contractId = useMemo(() => NumberUtils.parseInt(contractIdParam) ?? 0, [contractIdParam]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showAddEnrollmentModal, setShowAddEnrollmentModal] = useState(false);
    const [showBulkEnrollmentModal, setShowBulkEnrollmentModal] = useState(false);
    const { record: globalState } = useGlobalState();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            userRole === RoleType.AenAdministrator ||
            userRole === RoleType.ClientAdmin,
        [userRole]
    );

    const { contract } = useContract({ contractId });

    const {
        enrollmentSummaries,
        rowCount: totalNumberOfEnrollments,
        createEnrollment,
        createBulkEnrollments,
        withdrawEnrollment,
        usedEnrollmentCount,
    } = useOLLContractEnrollments({
        contractId: contractId,
        productId: contract?.productId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const enrollmentSeatsAvailable: boolean = useMemo(() => {
        return contract.noEnrollmentLimit
            ? true
            : usedEnrollmentCount < (contract.enrollmentLimit ?? 0);
    }, [contract.enrollmentLimit, contract.noEnrollmentLimit, usedEnrollmentCount]);

    const handleDownloadCertificateClick = async (id: number) => {
        const pathParams: ContractCertificateDownloadPathParams = {
            id: contractId,
        };

        await ContractCertificateService.downloadContractCertificates(
            pathParams,
            `contract-${contractId}-certificates.zip`
        );
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header__title`}>
                    <h3>{t("enrollment")}</h3>
                    <Badge style={BadgeStyle.Success}>{`${t("total")}: ${
                        usedEnrollmentCount ?? 0
                    }/${contract.enrollmentLimit}`}</Badge>
                </div>
                {
                    // if
                    canModifyEnrollments && (
                        <>
                            <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                                <ButtonIcon
                                    iconType={Icons.Download}
                                    text={t("downloadCertificates")}
                                    onClick={() => handleDownloadCertificateClick(contractId)}
                                />
                                <ButtonIcon
                                    disabled={!enrollmentSeatsAvailable}
                                    iconType={Icons.Plus}
                                    onClick={() => setShowAddEnrollmentModal(true)}
                                    text={t("addNew")}
                                />
                                <ButtonIcon
                                    disabled={!enrollmentSeatsAvailable}
                                    iconType={Icons.Upload}
                                    onClick={() => setShowBulkEnrollmentModal(true)}
                                    text={t("importCSV")}
                                />
                            </div>
                        </>
                    )
                }
            </div>

            <Card cssClassName={`${CSS_CLASS_NAME}__table`}>
                <AddEnrollmentModal
                    handleSaveEnrollment={createEnrollment}
                    open={showAddEnrollmentModal}
                    setOpen={setShowAddEnrollmentModal}
                />
                <AddBulkEnrollmentModal
                    handleSaveBulkEnrollment={createBulkEnrollments}
                    open={showBulkEnrollmentModal}
                    setOpen={setShowBulkEnrollmentModal}
                />
                <OllEnrollmentList
                    enrollmentSummaries={enrollmentSummaries}
                    handleWithdrawEnrollment={withdrawEnrollment}
                />
                <Pager
                    currentPage={currentPage}
                    totalPages={totalNumberOfEnrollments / ITEMS_PER_PAGE}
                    onPageClick={onPageClick}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={totalNumberOfEnrollments}
                />
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractOLLDetailEnrollment };

// #endregion Exports
