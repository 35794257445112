import { EventRecord } from "models/view-models/events/event-record";
import { EventType } from "models/enumerations/events/event-type";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { ServiceFactory } from "utilities/services/service-factory";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "events";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof EventRecord = EventRecord;
const pastEventEndpoint: string = `${baseEndpoint}/past`;
const patchBaseEndpoint: string = `${baseEndpoint}/:id`;
const cancelEventPatchEndpoint: string = `${patchBaseEndpoint}/cancel`;
const patchEventInvoiceEndpoint: string = `${patchBaseEndpoint}/invoice`;
const patchEventOrganizationEndpoint: string = `${patchBaseEndpoint}/organization`;
const patchEventProductEndpoint: string = `${patchBaseEndpoint}/product`;
const patchEventProviderEndpoint: string = `${patchBaseEndpoint}/provider`;
const publishEventPatchEndpoint: string = `${patchBaseEndpoint}/publish`;
const patchEventTypeEndpoint: string = `${patchBaseEndpoint}/type`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Get
// -------------------------------------------------------------------------------------------------

export enum EventsControllerIndexType {
    Default,
    EvaluationsReport,
    EnrollmentsReport,
    TranscriptReport,
}

export enum EventsControllerIndexSortOrder {
    UpdatedOnDesc,
    EventStartDateAsc,
    EventEndDateDesc,
}

export interface GetEventPathParams {
    id: number;
}

export interface GetEventQueryParams {
    includeCanceledBy?: boolean;
    includeChecklist?: boolean;
    includeContract?: boolean;
    includeEventDays?: boolean;
    includeEventScheduleException?: boolean;
    includeEventSessions?: boolean;
    includeInstructor?: boolean;
    includeOrganization?: boolean;
    includeProduct?: boolean;
    includeProvider?: boolean;
}

// #endregion Get

// -------------------------------------------------------------------------------------------------
// #region List
// -------------------------------------------------------------------------------------------------

export interface ListEventsQueryParams {
    applySearch?: boolean;
    endDate?: string;
    eventSalesType?: EventType;
    excludeDraft?: boolean;
    instructorId?: number;
    instructorLedTrainingType?: InstructorLedTrainingType;
    isProvidedByNFPA?: boolean;
    organizationId?: number;
    organizationAssigned?: boolean;
    productId?: number;
    providerAssigned?: boolean;
    providerId?: number;
    searchText?: string;
    skip?: number;
    startDate?: string;
    status?: PublishStatus;
    take?: number;
    type?: EventsControllerIndexType;
    sortOrder?: EventsControllerIndexSortOrder;
}

export interface ListPastEventsQueryParams {
    applySearch?: boolean;
    currentDate?: string;
    instructorId?: number;
    searchText?: string;
    skip?: number;
    take?: number;
}

// #endregion List

// -------------------------------------------------------------------------------------------------
// #region Patch
// -------------------------------------------------------------------------------------------------

export interface PatchEventPathParams {
    id: number;
}

export interface CancelEventPatchParams {
    cancellationMessage: string;
}

export interface EventInvoicePatchParams {
    hasBeenInvoiced: boolean;
    invoiceNotes: string;
}

export interface EventOrganizationPatchParams {
    organizationId?: number;
}

export interface EventProductPatchParams {
    productId?: number;
}

export interface EventProviderPatchParams {
    providerId?: number;
}

export interface EventTypePatchParams {
    type: EventType;
}

// #endregion Patch

// -------------------------------------------------------------------------------------------------
// #region Update
// -------------------------------------------------------------------------------------------------

export interface UpdateEventPathParams {
    id: number;
}

// #endregion Update

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventService = {
    /**
     * Cancel an Event.
     */
    cancel: ServiceFactory.patch<EventRecord, PatchEventPathParams, CancelEventPatchParams>(
        EventRecord,
        cancelEventPatchEndpoint
    ),

    /**
     * Create a Course
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update a Course
     */
    update: ServiceFactory.update<EventRecord, UpdateEventPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Update an Event's Invoice Details.
     */
    patchEventInvoice: ServiceFactory.patch<
        EventRecord,
        PatchEventPathParams,
        EventInvoicePatchParams
    >(EventRecord, patchEventInvoiceEndpoint),

    /**
     * Update an Event's Organization.
     */
    patchEventOrganization: ServiceFactory.patch<
        EventRecord,
        PatchEventPathParams,
        EventOrganizationPatchParams
    >(EventRecord, patchEventOrganizationEndpoint),

    /**
     * Update an Event's Product.
     */
    patchEventProduct: ServiceFactory.patch<
        EventRecord,
        PatchEventPathParams,
        EventProductPatchParams
    >(EventRecord, patchEventProductEndpoint),

    /**
     * Update an Event's Provider.
     */
    patchEventProvider: ServiceFactory.patch<
        EventRecord,
        PatchEventPathParams,
        EventProviderPatchParams
    >(EventRecord, patchEventProviderEndpoint),

    /**
     * Update an Event's Type.
     */
    patchEventType: ServiceFactory.patch<EventRecord, PatchEventPathParams, EventTypePatchParams>(
        EventRecord,
        patchEventTypeEndpoint
    ),

    /**
     * Publish an Event.
     */
    publish: ServiceFactory.patch<EventRecord, PatchEventPathParams>(
        EventRecord,
        publishEventPatchEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<EventRecord, GetEventPathParams, GetEventQueryParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EventRecord, ListEventsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    usePastList: ServiceHookFactory.useList<EventRecord, ListPastEventsQueryParams>(
        resourceType,
        pastEventEndpoint
    ),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom Hook to cancel an Event.
     */
    useCancel: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        CancelEventPatchParams
    >(EventRecord, cancelEventPatchEndpoint),

    /**
     * Custom Hook to update an Event's Invoice.
     */
    usePatchEventInvoice: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        EventInvoicePatchParams
    >(EventRecord, patchEventInvoiceEndpoint),

    /**
     * Custom Hook to update an Event's Organization.
     */
    usePatchEventOrganization: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        EventOrganizationPatchParams
    >(EventRecord, patchEventOrganizationEndpoint),

    /**
     * Custom Hook to update an Event's Product.
     */
    usePatchEventProduct: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        EventProductPatchParams
    >(EventRecord, patchEventProductEndpoint),

    /**
     * Custom Hook to update an Event's Provider.
     */
    usePatchEventProvider: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        EventProviderPatchParams
    >(EventRecord, patchEventProviderEndpoint),

    /**
     * Custom Hook to update an Event's Type.
     */
    usePatchEventType: ServiceHookFactory.usePatch<
        EventRecord,
        PatchEventPathParams,
        EventTypePatchParams
    >(EventRecord, patchEventTypeEndpoint),

    /**
     * Custom Hook to publish an Event.
     */
    usePublish: ServiceHookFactory.usePatch<EventRecord, PatchEventPathParams>(
        EventRecord,
        publishEventPatchEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<EventRecord, UpdateEventPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventService };

// #endregion Exports
