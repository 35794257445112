import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { Product } from "models/interfaces/products/product";
import { ProductEvaluationForm } from "components/products/product-evaluation-form/product-evaluation-form";
import { ProductRecord } from "models/view-models/products/product-record";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { RecordUpdater } from "utilities/contexts/use-record-context-factory";
import { t } from "utilities/localization/t";
import "./product-evaluation.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductEvaluationProps {
    createVersionMode?: boolean;
    editMode?: boolean;
    product: ProductRecord;
    setProduct: RecordUpdater<Product, ProductRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-evaluation";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductEvaluation: React.FC<ProductEvaluationProps> = ({
    createVersionMode,
    editMode,
    product,
    setProduct,
}): JSX.Element => {
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        if (
            product.activatedOn != null ||
            product.status === ActiveStatus.Archived ||
            product.status === ActiveStatus.Inactive
        ) {
            setReadOnly(true);
        }
    }, [product.activatedOn, product.status]);

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                        {t("evaluation")}
                    </Heading>
                </div>
                {(editMode || createVersionMode) && product.activatedOn != null && (
                    <div className={`${CSS_CLASS_NAME}__banner`}>
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message="Changes to the Evaluation are not available following initial creation of the product."
                        />
                    </div>
                )}
                <ProductEvaluationForm product={product} setProduct={setProduct} />
            </div>
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductEvaluation };

// #endregion Exports
