import axios from "axios";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";
import { UserAnnouncementRecord } from "models/view-models/announcements/user-announcement-record";
import { RouteUtils } from "utilities/route-utils";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchUserAnnouncementService = (
    params: PatchUserAnnouncementServiceParams
) => Promise<ServiceResponse<UserAnnouncementRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

export enum UserAnnouncementPatchRoutes {
    MarkAsRead = "markasread",
}

// #endregion enum

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface PatchUserAnnouncementServiceParams {
    announcementId: number;
    userId: number;
    patchRoute: UserAnnouncementPatchRoutes;
}

export interface ListUserAnnouncementPathParams {
    userId: number;
    announcementId: number;
}

export interface ListUserAnnouncementQueryParams {}

const resourceType = UserAnnouncementRecord;

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "users";
const recordType: typeof UserAnnouncementRecord = UserAnnouncementRecord;
const patchBaseEndpoint = `${baseEndpoint}/:userId/announcements/:announcementId`;
const listBaseEndpoint = `${baseEndpoint}/:userId/announcements/:announcementId`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserAnnouncementService = {
    useList: ServiceHookFactory.useNestedList<
        UserAnnouncementRecord,
        ListUserAnnouncementPathParams,
        ListUserAnnouncementQueryParams
    >(resourceType, listBaseEndpoint),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatch: (): { patch: PatchUserAnnouncementService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchUserAnnouncementService = (
            params: PatchUserAnnouncementServiceParams
        ): Promise<ServiceResponse<UserAnnouncementRecord>> => {
            const endpointWithPatchRouteAndPathParamsReplaced =
                RouteUtils.getUrl(patchBaseEndpoint, params) + "/" + params.patchRoute;

            return axios
                .patch(endpointWithPatchRouteAndPathParamsReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function patch(
            params: PatchUserAnnouncementServiceParams
        ): Promise<ServiceResponse<UserAnnouncementRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<UserAnnouncementRecord>
            >;
        }

        return { patch: useCallback(patch, [cancellablePromise]) };
    },
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserAnnouncementService };

// #endregion Exports
