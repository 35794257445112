import React, { useMemo, useState } from "react";
import { CollectionUtils } from "andculturecode-javascript-core";
import { StringUtils } from "utilities/string-utils";
import { TabBar, TabDetails } from "components/tabs/tab-bars/tab-bar/tab-bar";
import { TabBehavioralProps, TabProps } from "components/tabs/tabs/tab/tab";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TabbedContainerItem<T extends TabProps> {
    contents: JSX.Element;
    tabComponent: React.FC<T>;
    tabDisplayDetails: Omit<T, keyof TabBehavioralProps>;
}

interface TabbedContainerProps {
    cssClassName?: string;
    currentTabIndex?: number;
    tabs: TabbedContainerItem<any>[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "tabbed-container";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TabbedContainer: React.FC<TabbedContainerProps> = (
    props: TabbedContainerProps
): JSX.Element | null => {
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(props.currentTabIndex ?? 0);
    const tabDetails: TabDetails<any>[] = useMemo(
        () =>
            props.tabs.map(
                (tab: TabbedContainerItem<any>, index: number): TabDetails<any> => ({
                    component: tab.tabComponent,
                    props: {
                        ...tab.tabDisplayDetails,
                        isActive: index === currentTabIndex,
                        onClick: () => setCurrentTabIndex(index),
                    },
                })
            ),
        [currentTabIndex, props.tabs]
    );

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    if (CollectionUtils.isEmpty(props.tabs)) {
        return null;
    }

    return (
        <div className={className}>
            <TabBar tabs={tabDetails} />
            {props.tabs[currentTabIndex].contents}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TabbedContainer };

// #endregion Exports
