import { Banner, BannerStyle } from "components/banner/banner";
import { EventDayAttendanceHeaderActions } from "components/attendance/event-day-attendance-manager/event-day-attendance-header/event-day-attendance-header-actions/event-day-attendance-header-actions";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import { useMemo } from "react";
import "./event-day-attendance-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayAttendanceHeaderProps {
    canEditAttendance: boolean;
    eventDay?: EventDayRecord;
    eventId: number;
    updateEventDayStatus: (status: EventDayStatus) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-day-attendance-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayAttendanceHeader: React.FC<EventDayAttendanceHeaderProps> = (
    props: EventDayAttendanceHeaderProps
): JSX.Element => {
    const eventDayHasStarted = useMemo(
        () => props.eventDay?.eventDayHasStarted(),
        [props.eventDay]
    );

    const rightSideContent: JSX.Element = useMemo((): JSX.Element => {
        if (!props.canEditAttendance) {
            return <></>;
        }

        return !eventDayHasStarted ? (
            <Banner style={BannerStyle.Neutral}>
                <p>
                    <Icon type={Icons.Information} />
                    {t("attendanceTrackingIsNotAvailableUntilTheStartOfTheEvent")}
                </p>
            </Banner>
        ) : (
            <EventDayAttendanceHeaderActions
                eventDayHasNotStarted={!eventDayHasStarted}
                updateEventDayStatus={props.updateEventDayStatus}
            />
        );
    }, [eventDayHasStarted, props.canEditAttendance, props.updateEventDayStatus]);

    const eventDateText: string = useMemo(
        (): string => (props.eventDay == null ? "--" : props.eventDay.getEventDateText()),
        [props.eventDay]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <h2>{eventDateText}</h2>
            {rightSideContent}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayAttendanceHeader };
