import { Record } from "immutable";
import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";
import { PatchProviderApplication } from "models/interfaces/aen-applications/patch-provider-application";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: PatchProviderApplication = {
    id: 0,
    status: ProviderApplicationStatus.Submitted,
    assignedToId: undefined,
    reasonForChange: "",
    sendMessage: false,
    messageSubject: "",
    messageBody: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class PatchProviderApplicationRecord
    extends Record(defaultValues)
    implements PatchProviderApplication
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<PatchProviderApplication>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<PatchProviderApplication>): PatchProviderApplicationRecord {
        return new PatchProviderApplicationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PatchProviderApplicationRecord };

// #endregion Exports
