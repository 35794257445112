import React, { useCallback, useMemo } from "react";
import { Menu } from "@headlessui/react";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./context-menu-button.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContextMenuButtonProps {
    disabled?: boolean;
    displayName: string;
    forceEnabled?: boolean;
    iconType?: Icons;
    onClick: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "context-menu-button";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContextMenuButton: React.FC<React.PropsWithChildren<ContextMenuButtonProps>> = (
    props: ContextMenuButtonProps
) => {
    const { readOnly } = useReadOnly();
    const buttonIsDisabled = useMemo(
        (): boolean =>
            !(props.forceEnabled === true) && (props.disabled === true || readOnly === true),
        [props.disabled, props.forceEnabled, readOnly]
    );

    const buildCssClassName = useCallback((active: boolean): string => {
        let cssClassName: string = CSS_CLASS_NAME;

        if (active) {
            cssClassName += " -active";
        }

        return cssClassName;
    }, []);

    const handleClick = useCallback((): void => {
        if (buttonIsDisabled) {
            return;
        }

        props.onClick();
    }, [buttonIsDisabled, props]);

    return (
        <Menu.Item>
            {({ active }) => (
                <button
                    className={buildCssClassName(active)}
                    onClick={handleClick}
                    disabled={buttonIsDisabled}>
                    {props.iconType && (
                        <Icon key="icon" size={IconSizes.Small} type={props.iconType} />
                    )}
                    <span>{props.displayName}</span>
                </button>
            )}
        </Menu.Item>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ContextMenuButton };

// #endregion Exports
