import electrical from "assets/images/electrical.png";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { DateUtils } from "utilities/date-utils";
import { NumberUtils } from "utilities/number-utils";
import { ProductContentRecord } from "./product-content-record";
import { ProductScormPackageRecord } from "./product-scorm-package-record";
import { ProductVersion } from "models/interfaces/products/product-version";
import { Record } from "immutable";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { Topic } from "models/enumerations/courses/topic";
import { UnitRecord } from "../units/unit-record";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "i18next";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ProductVersion = {
    activatedById: undefined,
    activatedOn: undefined,
    associatedProductId: undefined,
    badgeTemplateId: undefined,
    badgeName: "",
    certificateDuration: undefined,
    changeLogDescription: "",
    completeAssessmentsInOrder: false,
    completeUnitsInOrder: false,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    creditHours: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    evaluationTemplate: undefined,
    evaluationTemplateId: undefined,
    hasBadge: false,
    hasCertificateDuration: false,
    hasNoContent: false,
    isHotWork: false,
    id: undefined,
    numberOfEnrolledLearners: 0,
    numberOfInProgressLearners: 0,
    productContents: undefined,
    productId: 0,
    product: undefined,
    productScormPackages: undefined,
    status: undefined,
    units: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    versionId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProductVersionRecord extends Record(defaultValues) implements ProductVersion {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ProductVersion>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.productContents)) {
            params.productContents = RecordUtils.ensureRecords(
                params.productContents,
                ProductContentRecord
            );
        }

        if (CollectionUtils.hasValues(params.productScormPackages)) {
            params.productScormPackages = RecordUtils.ensureRecords(
                params.productScormPackages,
                ProductScormPackageRecord
            );
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (CollectionUtils.hasValues(params.units)) {
            params.units = RecordUtils.ensureRecords(params.units, UnitRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    //Create a shared function to calculate the CEUS for a product version
    public static getCEUsForProductVersion(creditHours?: number): string {
        return creditHours ? (creditHours / 10).toFixed(1) : "0";
    }

    /**
     * Convenience method to get all courses currently on this product version.
     *
     * @returns {CourseRecord[]} A list of all courses
     */

    public sortAllChildren(): void {
        if (this.productContents != null) {
            this.productContents.sort(
                (a: ProductContentRecord, b: ProductContentRecord) => a.sortOrder! - b.sortOrder!
            );
        }
        if (this.units != null) {
            this.units.sort((a: UnitRecord, b: UnitRecord) => a.sortOrder! - b.sortOrder!);
            this.units.forEach((unit: UnitRecord) => unit.sortUnitCourses());
        }
        if (this.productScormPackages != null) {
            const productScormPackages: ProductScormPackageRecord[] = this.productScormPackages;
            productScormPackages.sort((a, b) => a.sortOrder! - b.sortOrder!);
        }
    }

    /**
     * Convenience method to determine if the achievement tab on this record is complete.
     *
     * @returns {boolean} Whether or not the achievement tab is complete
     */

    public achievementIsComplete(audienceType: AudienceType): boolean {
        return (
            this.creditHours != null &&
            this.creditHours >= 0 &&
            audienceType === AudienceType.Learner &&
            ((this.hasCertificateDuration &&
                !!this.certificateDuration &&
                this.certificateDuration > 0) ||
                !this.hasCertificateDuration) &&
            ((this.hasBadge && !!this.badgeTemplateId && this.badgeTemplateId !== "") ||
                !this.hasBadge)
        );
    }

    /**
     * Convenience method to determine if the assessment tab on this record is complete.
     *
     * @returns {boolean} Whether or not the assessment tab is complete
     */
    public assessmentIsComplete(): boolean {
        return (
            this.productScormPackages !== undefined &&
            this.productScormPackages.length > 0 &&
            this.productScormPackages.every(
                (x) => x.scormPackage?.status === ScormPackageImportStatus.Complete
            )
        );
    }

    /**
     * Convenience method to determine if the assessment tab on this record is complete.
     *
     * @returns {boolean} Whether or not the assessment tab is complete
     */
    public allAssessmentsAreComplete(): boolean | undefined {
        if (this.productScormPackages == null) return undefined;
        return this.productScormPackages.every(
            (pkg) => pkg.scormPackage?.status === ScormPackageImportStatus.Complete
        );
    }

    /**
     * Convenience method to determine if the content on this record is complete.
     *
     * @returns {boolean} Whether or not the content is complete
     */
    public contentIsComplete(): boolean {
        return this.hasNoContent || this.hasContent();
    }

    /**
     * Convenience method to determine if the record has content.
     *
     * @returns {boolean} Whether or not the product has content
     */
    public hasContent(): boolean {
        return this.productContents ? this.productContents.length > 0 : false;
    }

    /**
     * Convenience method to determine if the course on this record is complete.
     *
     * @returns {boolean} Whether or not the course is complete
     */
    public courseIsComplete(): boolean {
        return (
            this.units !== undefined &&
            this.units[0] !== undefined &&
            this.allUnitsHaveUnitCourses()
        );
    }

    public allUnitsHaveUnitCourses(): boolean {
        return (
            this.units !== undefined &&
            this.units.every((unit) => CollectionUtils.isNotEmpty(unit.unitCourses))
        );
    }

    /**
     * Convenience method to return the date/time this record was created and the name of the user
     * who created it.
     *
     * @returns {string} A string indicating when this record was created and by whom.
     */
    public getCreatedText(): string {
        const createdOnDateTime = DateUtils.formatDateTime(false, this.createdOn);

        if (this.createdBy == null) {
            return createdOnDateTime;
        }

        return t("createdondatetimeByThisCreatedByGetFirstAndLastName", {
            createdOnDateTime: createdOnDateTime,
            thisCreatedByGetFirstAndLastName: this.createdBy.getFirstAndLastName(),
        });
    }

    /**
     * Convenience method to return the CEUs as a string.
     *
     * @returns {number} A number indicating the CEUs.
     */
    public getCEUs(): number {
        return this.creditHours ? this.creditHours / 10 : 0;
    }

    public getCeusDisplay(): string {
        return NumberUtils.formatCEUsFromCreditHours(this.creditHours);
    }

    /**
     * Convenience method to return the credit hours hint text.
     *
     * @returns {string} A string indicating the hint text.
     */
    public getCEUsHintText(): string {
        const ceus = this.creditHours ? (this.creditHours / 10).toFixed(1) : "0";

        if (!ceus) {
            return t("youveEnteredCEUs");
        }
        return t("youveEnteredCeusCEUs", { ceus: ceus });
    }

    /**
     * Convenience method to the return a path to a file that could be used in an <img> src.
     */
    public getProductImageSrc(): string {
        return electrical;
    }

    /**
     * Convenience method to the return user
     * Ex: Steve Smith
     */
    public getLastUpdatedBy(): string {
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return "N/A";
        }

        return updatedBy.getFirstAndLastName();
    }

    public getProductTopics(): Topic[] {
        const topics: Topic[] = [];
        if (this.units == null) {
            return topics;
        }
        this.units.forEach((unit) => {
            if (unit.unitCourses == null) {
                return;
            }
            unit.unitCourses.forEach((unitCourse) => {
                if (unitCourse.course == null) {
                    return;
                }
                if (unitCourse.course?.topic !== null) {
                    topics.push(unitCourse.course?.topic!);
                }
            });
        });
        return [...new Set(topics)];
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getLastUpdatedDateAndTime(): string {
        const message = DateUtils.formatLastEditedDateTime(this, true, true);

        return message;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = DateUtils.formatLastEditedDate(this, false);
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return t("messageByUpdatedByGetFirstAndLastName", {
            message: message,
            updatedByGetFirstAndLastName: updatedBy.getFirstAndLastName(),
        });
    }

    /**
     * Convenience method to determine if the evaluation on this record is complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public evaluationTemplateIsComplete(): boolean {
        return this.evaluationTemplateId != null && this.evaluationTemplateId !== 0;
    }

    /**
     * Convenience method to return the id of the user who last updated the record (or created it,
     * if it has not yet been updated)
     *
     */
    public getUpdatedOrCreatedById(): number | undefined {
        return this.updatedById ?? this.createdById;
    }

    /**
     * Given a set of values for Product properties, create a new ProductRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Product>} values The values to overwrite on this instance.
     * @returns A ProductRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<ProductVersion>): ProductVersionRecord {
        return new ProductVersionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductVersionRecord };

// #endregion Exports
