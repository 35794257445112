import React, { useEffect, useMemo } from "react";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardStyle,
    RadioCardGridColumns,
} from "../../form/form-radio-card-list/form-radio-card-list";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Paragraph, ParagraphSize } from "../../typography/paragraph/paragraph";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { ReportAllUserType, ReportAllUserTypeDisplayNames } from "./report-all-user-type";
import { NumberUtils } from "utilities/number-utils";
import GroupSelection from "components/group-selection/group-selection";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import { initialState, initializer, reducer, validate } from "./report-all-user-reducer";
import { GroupRecord } from "models/view-models/groups/group-record";
import "./report-all-users.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportAllUsersProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-all-users";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportAllUsers: React.FC<ReportAllUsersProps> = ({
    onChange: onChangeReportCriteria,
}): JSX.Element => {
    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(),
        validate,
    });

    const { reportType, groups, isValid } = payload;

    const groupIds = useMemo(() => groups?.map((group) => group.id), [groups]);

    const onReportTypeChange = (value: string) => {
        dispatch({ type: "reportType", reportType: NumberUtils.parseInt(value) });
    };

    const handleGroupSelectionChange = (groups: GroupRecord[]) => {
        dispatch({ type: "updateGroups", groups });
    };

    useEffect(() => {
        onChangeReportCriteria({
            reportType,
            groupIds: groupIds,
            isValid: isValid,
        });
    }, [groupIds, isValid, onChangeReportCriteria, reportType]);

    const reportTypeCards: RadioCard[] = Object.keys(ReportAllUserType)
        .filter((key) => !isNaN(Number(key)))
        .map((key) => {
            const reportTypeKey = key as unknown as ReportAllUserType;
            const text = ReportAllUserTypeDisplayNames[reportTypeKey];

            return {
                icon: Icons.Reporting,
                text: text,
                value: key.toString(),
            };
        });

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <h2>{t("allUsersReport")}</h2>
                            <div className="report-section">
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("iWantToRunAnAllUserReportByThisUserRole")}
                                </Paragraph>
                                <FormRadioCardList
                                    id="reportType"
                                    formFieldName="reportType"
                                    label={t("reportType")}
                                    labelScreenReaderOnly={true}
                                    iconSize={IconSizes.Base}
                                    onChange={onReportTypeChange}
                                    radioCards={reportTypeCards}
                                    required={true}
                                    style={RadioCardStyle.Horizontal}
                                    value={reportType?.toString()}
                                    columns={RadioCardGridColumns.Three}
                                />
                            </div>

                            {/* Groups */}
                            {reportType === ReportAllUserType.Group && (
                                <GroupSelection
                                    groups={groups}
                                    isRequired={true}
                                    onGroupsChanged={handleGroupSelectionChange}
                                />
                            )}
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportAllUsers };

// #endregion Exports
