import React from "react";
import { InputProperties } from "components/form/input-properties";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./textarea.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TextAreaProps extends InputProperties {
    maxLength?: number;
    name?: string;
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    rows?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
    const { readOnly } = useReadOnly();
    const value = props.value ?? "";

    return (
        <textarea
            autoFocus={props.autoFocus}
            disabled={props.disabled || readOnly}
            id={props.id}
            maxLength={props.maxLength}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            placeholder={props.placeholder}
            rows={props.rows}
            title={props.ariaLabelledBy}
            value={value}
        />
    );
};

// #endregion Components

export { TextArea };
