import React from "react";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Outlet } from "react-router-dom";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import "./email-management-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EmailManagementLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "email-management-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EmailManagementLayout: React.FC<EmailManagementLayoutProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner title={t("emailManager")} />
            <SkipNavContent>
                <Outlet />
            </SkipNavContent>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EmailManagementLayout };

// #endregion Exports
