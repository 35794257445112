import { PatchService } from "utilities/types/services/patch-service";
import { RouteUtils } from "utilities/route-utils";
import { ServiceFactory as AndcultureCodeReactServiceFactory } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import axios from "axios";

// -------------------------------------------------------------------------------------------------
// #region Factory Methods
// -------------------------------------------------------------------------------------------------

const patch = <TRecord extends any, TPathParams = undefined, TPatchParams = undefined>(
    recordType: { new (): TRecord },
    resourceEndpoint: string
): PatchService<TRecord, TPathParams, TPatchParams> => {
    return async (
        pathParams?: TPathParams,
        values?: TPatchParams
    ): Promise<ServiceResponse<TRecord>> =>
        await _patch<TRecord, TPathParams, TPatchParams>(
            recordType,
            resourceEndpoint,
            pathParams,
            values
        );
};

// #endregion Factory Methods

// -------------------------------------------------------------------------------------------------
// #region Private Methods
// -------------------------------------------------------------------------------------------------

const _patch = async <TRecord extends any, TPathParams = undefined, TPatchParams = undefined>(
    recordType: { new (): TRecord },
    resourceEndpoint: string,
    pathParams?: TPathParams,
    values?: TPatchParams
): Promise<ServiceResponse<TRecord>> => {
    const url: string = RouteUtils.getUrl(resourceEndpoint, pathParams);

    return await axios.patch(url, values).then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
};

// #endregion Private Methods

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ServiceFactory = {
    ...AndcultureCodeReactServiceFactory,
    patch: patch,
};

export { ServiceFactory };

// #endregion Exports
