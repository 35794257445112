import React from "react";
import { StringUtils } from "utilities/string-utils";
import "./bubble.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum BubbleStyle {
    TopicBeige = "-topic-beige",
    TopicBrown = "-topic-brown",
    TopicDarkBlue = "-topic-dark-blue",
    TopicGray = "-topic-gray",
    TopicGreen = "-topic-green",
    TopicLightBlue = "-topic-light-blue",
    TopicOrange = "-topic-orange",
    TopicRed = "-topic-red",
    TopicWhite = "-topic-white",
    Blue = "-blue",
    BlueDark = "-blue-dark",
    Brown = "-brown",
    Green = "-green",
    Grey = "-grey",
    GreyDark = "-grey-dark",
    None = "",
    Orange = "-orange",
    OrgangeLight = "-orange-light",
    Red = "-red",
    White = "-white",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BubbleProps {
    ariaLabelledBy?: string;
    cssClassName?: string;
    style?: BubbleStyle;
    text?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "bubble";
const DEFAULT_BUTTON_STYLE: BubbleStyle = BubbleStyle.Blue;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Bubble: React.FC<React.PropsWithChildren<BubbleProps>> = (
    props: React.PropsWithChildren<BubbleProps>
) => {
    const style: BubbleStyle = props.style ?? DEFAULT_BUTTON_STYLE;
    const cssClassNames: string[] = [CSS_CLASS_NAME, style];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    return (
        <div className={cssClassNames.join(" ")} title={props.ariaLabelledBy}>
            {props.children}
            {
                // if
                StringUtils.hasValue(props.text) && <span>{props.text}</span>
            }
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Bubble, BubbleStyle };

// #endregion Exports
