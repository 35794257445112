import { DEFUALT_CULTURE_CODE } from "constants/localization/culture-codes";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CULTURE_CODE_DYNAMIC_SLUG_KEY: string = "cultureCode";

const LocalizationConstants = {
    CULTURE_CODE_DYNAMIC_SLUG_KEY: CULTURE_CODE_DYNAMIC_SLUG_KEY,
    CULTURE_CODE_DYNAMIC_SLUG: `/:${CULTURE_CODE_DYNAMIC_SLUG_KEY}?`,
    DEFUALT_CULTURE_CODE: DEFUALT_CULTURE_CODE,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LocalizationConstants };

// #endregion Exports
