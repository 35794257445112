import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";
import {
    ListRegistrationsQueryParams,
    RegistrationService,
} from "utilities/services/registrations/registration-service";
import { LearnerAssessmentCard } from "../learner-assessment-card/learner-assessment-card";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./learner-assessment-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentListProps {
    enrollmentId: number;
    eventId?: number;
    productId: number;
    scormPackageAssessments: ScormPackageAssessmentRecord[];
    showViewResults?: boolean;
    showAttempts?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-assessment-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentList: React.FC<LearnerAssessmentListProps> = ({
    enrollmentId,
    eventId,
    productId,
    scormPackageAssessments,
    showViewResults,
    showAttempts,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const { list: listRegistrations } = RegistrationService.useList();

    const [registrations, setRegistrations] = useState<RegistrationRecord[]>([]);
    const [startPolling, setStartPolling] = useState<boolean>(false);

    const handleRegistrationUpdate = (registration: RegistrationRecord) => {
        if (registration === undefined) {
            return;
        }

        setRegistrations([...registrations, registration]);
    };

    const fetchData = useCallback(async () => {
        // Rqeuire a valid productId
        if (productId === undefined || productId === null) {
            return;
        }

        // Rqeuire a valid productId and UserId.
        if (
            productId < 1 ||
            globalState?.currentIdentity?.user?.id == null ||
            globalState?.currentIdentity?.user?.id < 1
        ) {
            return;
        }

        const listRegistrationsQueryParams: ListRegistrationsQueryParams = {
            includeAttempts: true,
            enrollmentId: enrollmentId,
        };

        try {
            const response = await listRegistrations(listRegistrationsQueryParams);

            const failed =
                response?.resultObjects == null ||
                response?.results == null ||
                response.results.hasErrors();

            if (failed) {
                throw new Error();
            }

            setRegistrations(response.resultObjects);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingAssessments"));
            setRegistrations([]);
        }
    }, [globalState?.currentIdentity?.user?.id, listRegistrations, enrollmentId, productId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const allRegistrationsHasPassingAttempts = useMemo(() => {
        return registrations.every((registration) =>
            registration?.attempts?.some(
                (attempt) => attempt?.successStatus === AttemptSuccessStatus.Passed
            )
        );
    }, [registrations]);

    const intervalRef = useRef<ReturnType<typeof setInterval> | null>();

    useEffect(() => {
        if (startPolling) {
            setStartPolling(false);
            const registrationPollingInterval = setInterval(() => {
                fetchData();
            }, 10000);
            intervalRef.current = registrationPollingInterval;
        }

        if (
            scormPackageAssessments.length === registrations.length &&
            allRegistrationsHasPassingAttempts
        ) {
            clearInterval(intervalRef.current!);
            return;
        }
    }, [
        allRegistrationsHasPassingAttempts,
        fetchData,
        startPolling,
        scormPackageAssessments.length,
        registrations.length,
    ]);

    useEffect(() => {
        return () => clearInterval(intervalRef.current!);
    }, []);

    const getRegistration = (scormPackageId?: number): RegistrationRecord | undefined => {
        return registrations.find((r) => r.scormPackageId === scormPackageId);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            {scormPackageAssessments.map(
                (scormPackageAssessment: ScormPackageAssessmentRecord): JSX.Element => (
                    <LearnerAssessmentCard
                        key={scormPackageAssessment.id}
                        eventId={eventId}
                        enrollmentId={enrollmentId}
                        isRequired={scormPackageAssessment.isRequiredforEnrollment()}
                        isLocked={scormPackageAssessment.isLockedforEnrollment()}
                        scormPackageAssessment={scormPackageAssessment}
                        registration={getRegistration(scormPackageAssessment.scormPackageId)}
                        onRegistrationChange={handleRegistrationUpdate}
                        onLaunchButtonClicked={setStartPolling}
                        showViewResults={showViewResults}
                        showAttempts={showAttempts}
                    />
                )
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentList };

// #endregion Exports
