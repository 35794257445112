import React, { useCallback, useMemo, useState } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Card } from "components/card/card";
import { EmptyText, EmptyTextSpacing } from "../../../../../../components/empty-text/empty-text";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { sitemap } from "sitemap";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { UpdateProgressModal } from "../../../../../../components/user-management/update-progress-modal/update-progress-modal";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { useUser } from "utilities/contexts/use-user-context";
import {
    EnrollmentUnitCourseStatusDisplayNames,
    EnrollmentUnitCourseStatusMap,
} from "models/enumerations/enrollments/enrollment-unit-course-status";
import { useEnrollmentUnitCourses } from "utilities/hooks/models/enrollments/use-enrollment-unit-courses";
import { UnitRecord } from "models/view-models/units/unit-record";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import { CollectionUtils } from "utilities/collection-utils";
import { RouteUtils } from "utilities/route-utils";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { t } from "utilities/localization/t";
import "./user-training-detail-oll-progress.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailOllProgressProps {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-oll-progress";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailOllProgress: React.FC<UserTrainingDetailOllProgressProps> = ({
    enrollmentId,
}): JSX.Element => {
    const { record: user } = useUser();
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

    const { enrollmentUnitCourses, addAttemptToEnrollmentUnitCourse } = useEnrollmentUnitCourses({
        enrollmentId,
    });

    const manualAttempts: AttemptRecord[] = useMemo((): AttemptRecord[] => {
        if (enrollmentUnitCourses == null) {
            return [];
        }
        const attempts = enrollmentUnitCourses?.reduce((attempts: AttemptRecord[], euc) => {
            const { registration } = euc;
            if (registration == null) {
                return attempts;
            }

            const { attempts: registrationAttempts } = registration;
            if (registrationAttempts == null) {
                return attempts;
            }
            return [...attempts, ...registrationAttempts];
        }, []);

        const manualAttempts = attempts?.filter((a) => a.isManualEntry) ?? [];

        return manualAttempts.sort((a1, a2) => {
            return a2.getLastUpdatedInMilliseconds() - a1.getLastUpdatedInMilliseconds();
        });
    }, [enrollmentUnitCourses]);

    const units = useMemo(() => {
        if (enrollmentUnitCourses == null) {
            return [];
        }
        return enrollmentUnitCourses
            .reduce((units: UnitRecord[], enrollmentUnitCourse: EnrollmentUnitCourseRecord) => {
                const { unitCourse } = enrollmentUnitCourse;

                if (unitCourse == null) {
                    return units;
                }

                const { course } = unitCourse;
                const foundUnitIndex = units.findIndex((unit) => unit.id === unitCourse?.unitId);

                const unit = (
                    foundUnitIndex < 0 ? unitCourse.unit : units[foundUnitIndex]
                )?.withUnitCourse(unitCourse.with({ course, enrollmentUnitCourse }));

                if (unit == null) {
                    return units;
                }

                if (foundUnitIndex < 0) {
                    return [...units, unit];
                }

                return CollectionUtils.replaceElementAt(units, foundUnitIndex, unit).sort(
                    CollectionUtils.sortBySortOrder
                );
            }, [])
            .sort(CollectionUtils.sortBySortOrder);
    }, [enrollmentUnitCourses]);

    const showUnitName = units.length > 1;

    const getCourseNameForAttempt = useCallback(
        (attempt: AttemptRecord): string => {
            const enrollmentUnitCourse = enrollmentUnitCourses?.find(
                (e) => e.registrationId === attempt.registrationId
            );
            return enrollmentUnitCourse?.unitCourse?.course?.name ?? "";
        },
        [enrollmentUnitCourses]
    );

    const [enrollmentUnitCourseToUpdate, setEnrollmentUnitCourseToUpdate] =
        useState<EnrollmentUnitCourseRecord>();

    const handleAttemptAdd = useCallback(
        async (attempt: AttemptRecord): Promise<boolean> => {
            if (enrollmentUnitCourseToUpdate == null) {
                return false;
            }

            if (!(await addAttemptToEnrollmentUnitCourse(attempt, enrollmentUnitCourseToUpdate))) {
                return false;
            }
            return true;
        },
        [addAttemptToEnrollmentUnitCourse, enrollmentUnitCourseToUpdate]
    );

    const handleUpdateProgressClick = (enrollmentUnitCourse: EnrollmentUnitCourseRecord) => {
        setEnrollmentUnitCourseToUpdate(enrollmentUnitCourse);
        setShowUpdateModal(true);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__assessments`}>
                {units.map((unit) => {
                    const eucs =
                        enrollmentUnitCourses?.filter(
                            (euc) => euc.unitCourse?.unitId === unit.id
                        ) ?? [];

                    return (
                        <div key={unit.id} className="unit">
                            {showUnitName && (
                                <div className="unit__name">
                                    <h3>{unit.name}</h3>
                                </div>
                            )}
                            {eucs?.map((enrollmentUnitCourse) => (
                                <Card key={enrollmentUnitCourse.id} cssClassName="assessment">
                                    <div className="assessment__name">
                                        <Paragraph>
                                            <strong>
                                                {enrollmentUnitCourse.unitCourse?.sortOrder}.&nbsp;
                                            </strong>
                                            {enrollmentUnitCourse.unitCourse?.course?.name ?? ""}{" "}
                                        </Paragraph>
                                    </div>
                                    <div className="assessment__details">
                                        <div>
                                            {enrollmentUnitCourse.locked && (
                                                <Paragraph size={ParagraphSize.XSmall}>
                                                    <Icon type={Icons.Lock} />
                                                </Paragraph>
                                            )}
                                        </div>
                                        <div>
                                            <Badge
                                                text={
                                                    enrollmentUnitCourse.optional
                                                        ? t("optional")
                                                        : t("required")
                                                }
                                                style={
                                                    enrollmentUnitCourse.optional
                                                        ? BadgeStyle.Default
                                                        : BadgeStyle.Neutral
                                                }
                                            />
                                        </div>
                                        <div>
                                            <EnumStatusBadge
                                                displayNames={
                                                    EnrollmentUnitCourseStatusDisplayNames
                                                }
                                                statusMap={EnrollmentUnitCourseStatusMap}
                                                value={enrollmentUnitCourse.status}
                                            />
                                        </div>
                                        <div>
                                            <Paragraph size={ParagraphSize.XSmall}>
                                                <Icon type={Icons.FactCheck} />
                                                <span>
                                                    {enrollmentUnitCourse.currentScore
                                                        ? `${enrollmentUnitCourse.currentScore}%`
                                                        : "--"}
                                                </span>
                                            </Paragraph>
                                        </div>
                                        <div>
                                            <ContextMenu>
                                                <ContextMenuButton
                                                    disabled={enrollmentUnitCourse.locked}
                                                    displayName={t("addAttempt")}
                                                    onClick={() =>
                                                        handleUpdateProgressClick(
                                                            enrollmentUnitCourse
                                                        )
                                                    }
                                                />

                                                {enrollmentUnitCourse.registration?.attempts !=
                                                    null &&
                                                    enrollmentUnitCourse.registration?.attempts
                                                        .length > 0 && (
                                                        <ContextMenuAnchor
                                                            hrefPath={RouteUtils.replacePathParams(
                                                                sitemap.admin.userManagement.users
                                                                    .trainings.assessment,
                                                                {
                                                                    id: user.id,
                                                                    enrollmentId: enrollmentId,
                                                                    registrationId:
                                                                        enrollmentUnitCourse.registrationId,
                                                                }
                                                            )}
                                                            displayName={t("viewAssessmentDetail")}
                                                        />
                                                    )}
                                            </ContextMenu>
                                        </div>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    );
                })}

                {enrollmentUnitCourses == null ||
                    (enrollmentUnitCourses.length === 0 && (
                        <EmptyText spacing={EmptyTextSpacing.Small}>
                            {t("noAssignmentsForThisTraining")}
                        </EmptyText>
                    ))}
            </div>
            {/* TODO: NFPA-8278 Remove or Implement

            <h3>Progress Change History</h3>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__history`}>
                <DataTable>
                    <thead>
                        <tr>
                            <th>{t("lastChange")}</th>
                            <th>{t("changedBy")}</th>
                            <th>Item</th>
                            <th>{t("change")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {manualAttempts?.map((attempt) => (
                            <tr>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt?.getLastUpdatedDateTimeText()}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt.createdBy?.getFirstAndLastName()}
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {attempt.createdById}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {getCourseNameForAttempt(attempt)}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt.manualEntryNotes}
                                    </Paragraph>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            </div>*/}
            <UpdateProgressModal
                statusBadge={
                    enrollmentUnitCourseToUpdate == null ? null : (
                        <EnumStatusBadge
                            displayNames={EnrollmentUnitCourseStatusDisplayNames}
                            statusMap={EnrollmentUnitCourseStatusMap}
                            value={enrollmentUnitCourseToUpdate.status}
                        />
                    )
                }
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                onAttemptSave={handleAttemptAdd}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailOllProgress };

// #endregion Exports
