import React, { useEffect, useMemo, useState } from "react";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, UpdateCoursePathParams } from "utilities/services/courses/course-service";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormRadioCardList, RadioCard } from "../../form/form-radio-card-list/form-radio-card-list";
import { FormTextInput } from "../../form/form-input/form-text-input";
import { Icons } from "../../icons/constants/icons";
import { InputTypes } from "../../form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./course-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseModalProps {
    currentCourse?: CourseRecord;
    open: boolean;
    setCourseToEdit?: React.Dispatch<React.SetStateAction<CourseRecord>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseModal: React.FC<CourseModalProps> = ({
    currentCourse,
    open,
    setCourseToEdit,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const initialCourse = useMemo(() => currentCourse ?? new CourseRecord(), [currentCourse]);
    const [course, setCourse] = useState<CourseRecord>(initialCourse);
    const { create } = CourseService.useCreate();
    const { update } = CourseService.useUpdate();
    const { useEducationNetwork } = useFeatureFlags();

    useEffect(() => {
        setCourse(initialCourse);
    }, [initialCourse]);

    const cards: RadioCard[] = [
        {
            icon: Icons.Analytics,
            text: "learner",
            value: AudienceType.Learner.toString(),
        },
    ];

    if (useEducationNetwork) {
        cards.push({
            icon: Icons.Analytics,
            text: "educationNetwork",
            value: AudienceType.EducationNetwork.toString(),
        });
    }
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: !currentCourse ? t("createCourse") : t("saveChanges"),
            disabled: !course.name || course.audienceType == null,
            onClick: () => (!currentCourse ? createCourse() : updateCourseName()),
            style: ButtonStyle.Primary,
        },
    ];

    const createCourse = async (): Promise<boolean> => {
        if (!course.name || course.audienceType === undefined) {
            alert(t("courseMustHaveANameAndType"));
            return false;
        }

        try {
            const createCourseResponse = await create(course);
            const createCourseResult = createCourseResponse.result;

            if (createCourseResult?.resultObject == null || createCourseResult.hasErrors()) {
                throw new Error();
            }

            const newCourse = course.with({
                ...createCourseResult.resultObject.toJS(),
            });

            setCourse(newCourse);

            setOpen(false);
            const path = RouteUtils.replacePathParams(sitemap.admin.course.edit.details, {
                id: newCourse.id,
                versionId: newCourse.latestCourseVersionId,
            });
            navigate(path);
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingACourse"));
            return false;
        }
        return true;
    };

    const updateCourseName = async (): Promise<boolean> => {
        try {
            const updateCoursePathParms: UpdateCoursePathParams = {
                id: currentCourse?.id!,
            };

            const updateCourseResponse = await update(course, updateCoursePathParms);

            const updateResult = updateCourseResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            setOpen(false);
            setCourseToEdit!(course);
            setCourse(course);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheCourseName"));
            return false;
        }
        return true;
    };

    const onModalClose = (): void => {
        setOpen(false);
        resetData();
    };

    const resetData = (): void => {
        if (!currentCourse) {
            setCourse(new CourseRecord());
        } else {
            setCourse(currentCourse);
        }
    };

    const handleChange = (course: CourseRecord) => {
        setCourse(course);
    };

    const updateCourse = (values: Partial<CourseRecord>) => {
        handleChange(course.with(values));
    };

    const handleCourseNameUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateCourse({ name: event.target.value });
    };

    const handleTypeChange = (value: string) => {
        updateCourse(
            EnumUtils.enumToObjectPartial(
                value,
                (value) => ({ audienceType: Number(value) }),
                AudienceType
            )
        );
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={!currentCourse ? t("newCourse") : t("editName")}
            actions={modalActionArray}
            modalStyle={""}>
            <FormTextInput
                ariaLabelledBy={t("courseName")}
                autoFocus={true}
                formFieldName="courseName"
                id="courseName"
                label={t("courseName")}
                maxLength={200}
                onChange={handleCourseNameUpdate}
                placeholder={t("enterCourseName")}
                required={true}
                type={InputTypes.Text}
                value={course.name}
            />
            <FormRadioCardList
                id="courseType"
                disabled={!!currentCourse}
                formFieldName="courseType"
                label={t("type")}
                onChange={handleTypeChange}
                radioCards={cards}
                required={true}
                value={course.audienceType?.toString()}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseModal };

// #endregion Exports
