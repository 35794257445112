import { ResultErrorRecord } from "andculturecode-javascript-core";
import { CertificateRecord } from "models/view-models/certificates/certificate-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { CertificateService } from "utilities/services/certificates/certificate-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseCertificateHook {
    certificate?: CertificateRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseCertificateHookOptions {
    enrollmentId?: number;
}

export function useCertificate(props: UseCertificateHookOptions): UseCertificateHook {
    const enrollmentId = props.enrollmentId;
    const [isLoading, setIsLoading] = useState(true);
    const [certificate, setCertificate] = useState<CertificateRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listCertificate } = CertificateService.useList();

    useEffect(() => {
        if (enrollmentId == null || enrollmentId < 1) {
            return;
        }

        (async function fetchCertificate() {
            try {
                const [certificateResponse] = await Promise.all([
                    listCertificate({
                        enrollmentId: enrollmentId,
                    }),
                ]);

                setIsLoading(false);

                if (certificateResponse.result?.hasErrors()) {
                    const { errors: certificateErrors = [] } = certificateResponse.result ?? {};
                    setErrors([...certificateErrors]);
                    return;
                }

                if (certificateResponse.resultObjects.length > 0) {
                    const certificate = certificateResponse.resultObjects[0];
                    setCertificate(certificate);
                }
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheCertificate"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [enrollmentId, listCertificate]);

    return {
        errors,
        isLoading,
        certificate,
    };
}
