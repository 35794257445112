import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { B2C_CONFIG } from "./b2c-config";
import { LocalizationUtils } from "andculturecode-javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

const useAzure = () => {
    const { instance } = useMsal();
    const { record: globalState } = useGlobalState();

    const login = useCallback(() => {
        if (globalState.azureSettings == null) return;
        //const clientId = B2C_CONFIG.CLIENT_ID;
        const clientId = globalState.azureSettings.clientId;
        instance.loginRedirect({
            scopes: [clientId],
            redirectStartPage: B2C_CONFIG.REDIRECT_URI,
        });
    }, [globalState.azureSettings, instance]);

    const logout = useCallback(async () => {
        document.cookie = "AccessToken=; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        return instance.logoutRedirect({
            postLogoutRedirectUri: B2C_CONFIG.POST_LOGOUT_REDIRECT_URI,
        });
    }, [instance]);

    const signUp = useCallback(() => {
        if (globalState.azureSettings == null) return;
        const clientId = globalState.azureSettings.clientId;
        instance.loginRedirect({
            scopes: [clientId],
            redirectStartPage: B2C_CONFIG.REDIRECT_URI,
            extraQueryParameters: {
                option: "signUp",
                ui_locales: LocalizationUtils.currentCultureCode(),
            },
        });
    }, [globalState.azureSettings, instance]);

    return {
        login,
        logout,
        signUp,
    };
};
export { useAzure };
