import React from "react";
import { FileRecord } from "models/view-models/files/file-record";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { t } from "utilities/localization/t";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import enrollmentTemplate from "assets/files/enrollment-template.csv";
import "./add-bulk-enrollment-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddBulkEnrollmentFormProps {
    handleInvalidFile: () => void;
    fileId?: number;
    onFileIdChange: (file: FileRecord) => void;
    validateCsvFile: (file: File) => boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-bulk-enrollment-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentForm: React.FC<AddBulkEnrollmentFormProps> = ({
    fileId,
    handleInvalidFile,
    onFileIdChange,
    validateCsvFile,
}) => {
    const requirementsList = [
        {
            text: t("fileMustBeACSV"),
        },
        {
            text: t("allRowsMustHaveCompleteData"),
        },
        {
            text: t("emailMustBeInFirstColumn"),
        },
        {
            text: t("emailIsInAValidFormatExampleEmaildomain"),
        },
        {
            text: t("emailsMustBeUniqueToTheIndividualRegistrant"),
        },
        {
            text: t(
                "duplicateEmailsWillBeRemovedFromTheFileImportAndWillNotBeIncludedOnTheImportSummaryReport"
            ),
        },
    ];

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <div>
                    <h6>{t("enrollmentCSVRequirements")}</h6>
                    <div className={`${CSS_CLASS_NAME}__instructions`}>
                        {t(
                            "importingACSVFileIsAnEasyWayToBulkEnrollMultipleLearnersAtOnceToGetStarted"
                        )}
                        <Anchor
                            ariaLabel={t("useThisTemplate")}
                            cssClassName={`${CSS_CLASS_NAME}__download-icon`}
                            pathType={AnchorPathType.Download}
                            download={"enrollment-template.csv"}
                            path={enrollmentTemplate}
                            text={t("useThisTemplate")}
                        />
                        <br />
                        {t(
                            "pleaseDoNotMakeChangesToTheFormatOfThisTemplateOrItWillAffectTheUploadOfTheRegistrations"
                        )}
                    </div>
                    <ul>
                        {requirementsList.map((listItem, idx) => (
                            <li className={`${CSS_CLASS_NAME}__requirements`} key={idx}>
                                <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                {listItem.text}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={`${CSS_CLASS_NAME}__file-upload`}>
                    <FormFileInput
                        buttonText={t("selectAFile")}
                        cssClassName={`${CSS_CLASS_NAME}__file-upload`}
                        fileIsValid={validateCsvFile}
                        formFieldName="bulkCSVFile"
                        id="bulkCSVFile"
                        label="Upload CSV File:"
                        onFileInvalid={handleInvalidFile}
                        onFileUpload={onFileIdChange}
                        placeholder={t("selectAFile")}
                        storageContainer={StorageContainers.FileUpload}
                        value={fileId}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentForm };

// #endregion Exports
