import { ContractSummaryRecord } from "models/view-models/contracts/contract-summary-record";
import { NumberUtils } from "utilities/number-utils";
import { OrganizationILTContractSummariesService } from "utilities/services/organizations/organization-ilt-contract-summaries-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useEffect, useState } from "react";

interface useOrganizationILTPastContractSummariesHook {
    pastContractSummaries: ContractSummaryRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface useOrganizationILTPastContractSummariesHookOptions {
    organizationId?: number | string;
    skip?: number;
    take?: number;
}

export function useOrganizationILTPastContractSummaries(
    props: useOrganizationILTPastContractSummariesHookOptions
): useOrganizationILTPastContractSummariesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [pastContractSummaries, setPastContractSummaries] = useState<ContractSummaryRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listPastContractSummariesAPI } =
        OrganizationILTContractSummariesService.usePastTrainingsList();
    const organizationId = NumberUtils.parseInt(props.organizationId) ?? 0;
    const skip = props.skip;
    const take = props.take;

    useEffect(() => {
        if (isNaN(organizationId) || organizationId < 1) {
            return;
        }
        try {
            (async function getPastContractSummaries() {
                const { result, resultObjects } = await listPastContractSummariesAPI({
                    organizationId: organizationId,
                    skip: skip,
                    take: take,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setPastContractSummaries(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningContractSummaries"));
        }
    }, [organizationId, listPastContractSummariesAPI, skip, take, rowCount]);

    return {
        errors,
        isLoading,
        pastContractSummaries,
        rowCount,
    };
}
