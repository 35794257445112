import React, { useEffect, useState } from "react";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ReportsSidebar } from "components/reports/components/reports-sidebar/reports-sidebar";
import { ReportsHeader } from "components/reports/components/reports-header/reports-header";
import { useParams } from "react-router-dom";
import { Validated } from "components/reports/reports-base-props";
import { useReport } from "utilities/hooks/models/reports/use-report";
import { ToastManager } from "utilities/toast/toast-manager";
import { useReportDownloadRequest } from "utilities/hooks/models/reports/use-report-download-request";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { ReportsFooter } from "components/reports/components/reports-footer/reports-footer";
import { ReportContainer } from "components/reports/report-container";
import "./admin-reports-setup-page.scss";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminReportsSetupPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-reports-setup-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminReportsSetupPage: React.FC<AdminReportsSetupPageProps> = validatePageAccess(
    AccessControlKeys.AdminReportsSetupPage
)((): JSX.Element => {
    const { slug } = useParams();
    const { report } = useReport({ slug });
    const { record: globalState } = useGlobalState();
    const currentRoleType = globalState.currentIdentity?.roleType();
    const [payload, setPayload] = useState<Validated>({ isValid: false });

    useRedirectOnForbidden(sitemap.admin.reports.list);

    const {
        errors,
        reportDownloadRequest,
        create: createReportDownload,
    } = useReportDownloadRequest();

    const handleReportRunClick = () => {
        if (report == null) return;

        createReportDownload({ report, payload });
    };

    useEffect(() => {
        if (errors == null || errors.length === 0) return;

        ToastManager.error(errors.map((e) => e.message).join(", "));
    }, [errors]);

    useEffect(() => {
        if (reportDownloadRequest?.id == null) return;

        ToastManager.success(
            t("yourReportIsRunningYoullReceiveANotificationWhenYourReportIsReady")
        );
    }, [reportDownloadRequest]);

    return (
        <div className={CSS_CLASS_NAME}>
            <ReportsHeader />
            <SideContentLeftLayout sidebarElement={<ReportsSidebar />}>
                <ReportContainer onChange={setPayload} roleType={currentRoleType} slug={slug} />
                <ReportsFooter
                    onRunReportClick={handleReportRunClick}
                    runReportEnabled={payload.isValid}
                />
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminReportsSetupPage };

// #endregion Exports
