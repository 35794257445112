import React, { useCallback, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { t } from "utilities/localization/t";
import { ProductManageAddEnrollment } from "components/products/product-training-enrollment-modal/product-manage-add-enrollment";
import { ProductRecord } from "models/view-models/products/product-record";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./assign-training-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssignTrainingModalProps {
    addEnrollment: (product: ProductRecord) => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssignTrainingModal: React.FC<AssignTrainingModalProps> = ({
    addEnrollment,
    open,
    setOpen,
}): JSX.Element => {
    const [dirty, setDirty] = useState(false);
    const [products, setProductsAvailable] = useState<ProductRecord[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<ProductRecord | undefined>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled: !selectedProduct,
            buttonText: t("enrollTraining"),
            onClick: () => onEnrollTraining(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setSelectedProduct(undefined);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
        }
    };

    const onEnrollTraining = () => {
        if (!selectedProduct) return;

        addEnrollment(selectedProduct);
        resetForm();
        setOpen(false);
    };

    const handleAddProductToQueue = useCallback((product: ProductRecord): void => {
        setSelectedProduct(product);
        setDirty(true);
    }, []);

    const handleRemoveProductFromQueue = useCallback((): void => {
        setSelectedProduct(undefined);
    }, []);

    const handleFetchProducts = useCallback((products: ProductRecord[]): void => {
        setProductsAvailable(products);
    }, []);

    return (
        <Modal
            actions={modalActionArray}
            isOpen={open}
            modalStyle={""}
            onModalClose={onModalClose}
            title={t("enrollNewTraining")}>
            <ProductManageAddEnrollment
                handleAddProductToQueue={handleAddProductToQueue}
                handleFetchProducts={handleFetchProducts}
                handleRemoveProductFromQueue={handleRemoveProductFromQueue}
                products={products}
                selectedProduct={selectedProduct}
                audienceType={AudienceType.EducationNetwork}
                trainingType={TrainingType.TrainTheTrainer}
            />
            <Modal
                actions={confirmationActionArray}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssignTrainingModal };

// #endregion Exports
