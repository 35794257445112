import { useCallback } from "react";
import { AddCourseModalCourseList } from "components/units/unit-courses/unit-add-course-modal/add-course-modal-course-list/add-course-modal-course-list";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, ListCoursesQueryParams } from "utilities/services/courses/course-service";
import { Language } from "models/enumerations/languages/language";
import { NumberedTab } from "components/tabs/tabs/numbered-tab/numbered-tab";
import { Tab } from "components/tabs/tabs/tab/tab";
import { UnitRecord } from "models/view-models/units/unit-record";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { SelectedCourseModalCourseList } from "components/units/unit-courses/unit-add-course-modal/selected-course-modal-course-list/selected-course-modal-course-list";
import { ToastManager } from "utilities/toast/toast-manager";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import "./unit-manage-add-courses.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitManageAddCoursesProps {
    courses: CourseRecord[];
    coursesToAdd: CourseRecord[];
    handleAddCourseToQueue: (course: CourseRecord) => void;
    handleFetchCourses: (course: CourseRecord[]) => void;
    handleRemoveCourseFromQueue: (course: CourseRecord) => void;
    language?: Language;
    audienceType?: AudienceType;
    unit: UnitRecord;
}
export interface AddCourseModalCourse {
    course: CourseRecord;
    addedToQueue: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "unit-manage-add-courses";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "typeToSearchByCourseNameOrID";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

// Convert a given course list to an AddCourseModalCourse using the queuedCourse array to determine
// whether each course in the courses array has already been added to the queue of courses to add.
const convertCourseToAddCourseModalCourse = (
    courses: CourseRecord[],
    queuedCourses: CourseRecord[]
): AddCourseModalCourse[] => {
    return courses.map(
        (c): AddCourseModalCourse => ({
            course: c,
            addedToQueue: queuedCourses?.some((queuedCourse) => queuedCourse.id === c.id),
        })
    );
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitManageAddCourses: React.FC<UnitManageAddCoursesProps> = (
    props: UnitManageAddCoursesProps
) => {
    const { list: listCourses } = CourseService.useList();
    const handleFetchCourses = props.handleFetchCourses;

    const filterCourses = useCallback(
        async (searchText: string) => {
            try {
                const listCoursesQueryParams: ListCoursesQueryParams = {
                    searchText: searchText,
                    applySearch: true,
                    language: props.language ?? undefined,
                    activeStatus: ActiveStatus.Active,
                    audienceType: props.audienceType ?? undefined,
                };

                const listFilteredCoursesResponse = await listCourses(listCoursesQueryParams);
                const listFilteredCoursesResults = listFilteredCoursesResponse?.results;
                const courses = listFilteredCoursesResponse?.resultObjects;

                if (
                    courses == null ||
                    listFilteredCoursesResults == null ||
                    listFilteredCoursesResults.hasErrors()
                ) {
                    throw new Error();
                }
                handleFetchCourses(courses);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingCourses"));
                handleFetchCourses([]);
            }
        },
        [handleFetchCourses, listCourses, props.audienceType, props.language]
    );

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <AddCourseModalCourseList
                    addCourseModalCourses={convertCourseToAddCourseModalCourse(
                        props.courses,
                        props.coursesToAdd
                    )}
                    handleAdd={props.handleAddCourseToQueue}
                    handleRemove={props.handleRemoveCourseFromQueue}
                    filterCourses={filterCourses}
                    debounceTime={DEBOUNCE_TIME}
                    searchTextPlaceholder={t(SEARCH_INPUT_PLACEHOLDER)}
                />
            ),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("courses"),
            },
        },
        {
            contents: (
                <SelectedCourseModalCourseList
                    selectedCourseModalCourses={convertCourseToAddCourseModalCourse(
                        props.coursesToAdd,
                        props.coursesToAdd
                    )}
                    handleAdd={props.handleAddCourseToQueue}
                    handleRemove={props.handleRemoveCourseFromQueue}
                />
            ),
            tabComponent: NumberedTab,
            tabDisplayDetails: {
                hideBadge: props.coursesToAdd.length <= 0,
                tabName: t("selected"),
                value: props.coursesToAdd.length,
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <TabbedContainer cssClassName={CSS_CLASS_NAME} tabs={tabs} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitManageAddCourses };

// #endregion Exports
