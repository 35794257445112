import { Checkbox, CheckboxSize } from "./checkbox";
import React from "react";
import { Icons } from "components/icons/constants/icons";
import { InputProperties } from "components/form/input-properties";
import "./checkbox-input.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CheckboxInputProps extends InputProperties {
    checked: boolean;
    checkedIcon?: Icons;
    uncheckedIcon?: Icons;
    name?: string;
    label?: string;
    onBlur?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    onChange: (newValue: boolean) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    size?: CheckboxSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const CheckboxInput: React.FC<CheckboxInputProps> = (props: CheckboxInputProps) => {
    return (
        <Checkbox
            checked={props.checked}
            checkedIcon={props.checkedIcon}
            uncheckedIcon={props.uncheckedIcon}
            onBlur={props.onBlur}
            onChange={props.onChange}
            label={props.label}
            disabled={props.disabled}
            size={props.size}
        />
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CheckboxInput };

// #endregion Exports
