import React, { useCallback, useState } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { UnitRecord } from "models/view-models/units/unit-record";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import {
    UnitCourseService,
    UpdateUnitCoursePathParams,
} from "utilities/services/units/unit-course-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import {
    GetScormPackagePreviewResourceEndpointPathParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import "./unit-course-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitCourseListProps {
    deleteUnitCourse: (unitCourse: UnitCourseRecord) => Promise<boolean>;
    fetchUnitCourseList: () => void;
    unit?: UnitRecord;
    unitIndex?: number;
    unitCourses?: UnitCourseRecord[];
    onProductVersionUnitsChange: (unit: UnitRecord, unitCourses: UnitCourseRecord[]) => void;
    onProductUnitChange: (updatedUnit: UnitRecord, selectedUnitIndex?: number) => void;
    deferSave: boolean;
    selectedUnitIndex: number;
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "unit-course-list-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitCourseList: React.FC<UnitCourseListProps> = ({
    deleteUnitCourse,
    unit,
    unitIndex,
    unitCourses,
    fetchUnitCourseList,
    onProductVersionUnitsChange,
    onProductUnitChange,
    deferSave,
    selectedUnitIndex,
    setCourseHasChanges,
}) => {
    const { get: getPreviewLinkApi } = ScormPackageService.useGetPreviewLaunchLink();
    const { update: updateUnitCourseApi } = UnitCourseService.useUpdate();

    const [unitCourseToDelete, setUnitCourseToDelete] = useState<UnitCourseRecord>(
        new UnitCourseRecord()
    );

    const [updateInProgress, setUpdateInProgress] = useState(false);

    const [showUnitCourseDeletionConfirmationModal, setShowUnitCourseDeletionConfirmationModal] =
        useState(false);

    const confirmUnitCourseDeletion = (unitCourse: UnitCourseRecord, index: number) => {
        setShowUnitCourseDeletionConfirmationModal(true);
        setUnitCourseToDelete(unitCourse);
    };

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowUnitCourseDeletionConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleUnitCourseDelete(),
            style: ButtonStyle.Destructive,
        },
    ];

    const handleUnitCourseDelete = async (): Promise<void> => {
        if (unit == null || unitCourses == null) {
            return;
        }

        if (deferSave) {
            const updatedUnitCourseIndexToDelete = unitCourses.indexOf(unitCourseToDelete);

            if (unitCourseToDelete.id != null) {
                setCourseHasChanges && setCourseHasChanges(true);
            }
            unitCourses.splice(updatedUnitCourseIndexToDelete, 1);
            const updatedUnitCourse = unitCourses.map((uc, index) => {
                return uc.with({ sortOrder: index + 1 });
            });
            onProductVersionUnitsChange(unit, updatedUnitCourse);
            setShowUnitCourseDeletionConfirmationModal(false);
        } else {
            const higherSortOrderUnitCoursesToUpdate = unitCourses?.slice(
                unitCourseToDelete.sortOrder
            );
            await deleteUnitCourse(unitCourseToDelete);
            setShowUnitCourseDeletionConfirmationModal(false);

            const apiCalls: Promise<boolean>[] = [];

            higherSortOrderUnitCoursesToUpdate?.forEach((uc) =>
                apiCalls.push(moveUpUnitCourse(uc))
            );

            await Promise.all(apiCalls);

            fetchUnitCourseList();
        }
    };

    const unitCourseIsFirst = useCallback(
        (unitCourse: UnitCourseRecord): boolean => {
            return (
                CollectionUtils.isEmpty(unitCourses) ||
                unitCourse.sortOrder === Math.min(...unitCourses.map((u) => u.sortOrder!))
            );
        },
        [unitCourses]
    );

    const unitCourseIsLast = useCallback(
        (unitCourse: UnitCourseRecord): boolean => {
            return (
                CollectionUtils.isEmpty(unitCourses) ||
                unitCourse.sortOrder === Math.max(...unitCourses.map((u) => u.sortOrder!))
            );
        },
        [unitCourses]
    );

    const disableUpArrowButtons = useCallback(
        (unitCourse: UnitCourseRecord) => {
            return updateInProgress || unitCourseIsFirst(unitCourse);
        },
        [updateInProgress, unitCourseIsFirst]
    );

    const disableDownArrowButtons = useCallback(
        (unitCourse: UnitCourseRecord) => {
            return updateInProgress || unitCourseIsLast(unitCourse);
        },
        [updateInProgress, unitCourseIsLast]
    );

    const handleUpArrowClick = async (
        unitCourse: UnitCourseRecord,
        indexOfSelectedCourse: number
    ): Promise<void> => {
        if (unitCourseIsFirst(unitCourse)) {
            return;
        }

        if (deferSave) {
            setCourseHasChanges && setCourseHasChanges(true);
            if (unit == null || unitCourses == null) return;
            const upSortOrder = unitCourse.sortOrder! - 1;
            const updateSortOfUnitCourses = unitCourses?.map((uc, idx) => {
                if (idx === indexOfSelectedCourse) {
                    return uc.with({ sortOrder: upSortOrder });
                } else if (idx === indexOfSelectedCourse - 1) {
                    return uc.with({ sortOrder: uc.sortOrder! + 1 });
                } else return uc;
            });
            updateSortOfUnitCourses.sort((a, b) => a.sortOrder! - b.sortOrder!);
            onProductVersionUnitsChange(unit, updateSortOfUnitCourses);
        } else {
            const apiCalls: Promise<boolean>[] = [moveUpUnitCourse(unitCourse)];

            const unitCourseToMoveDown = getUnitCourseBySortOrder(unitCourse.sortOrder! - 1);

            if (unitCourseToMoveDown != null) {
                apiCalls.push(moveDownUnitCourse(unitCourseToMoveDown));
            }

            await Promise.all(apiCalls);

            fetchUnitCourseList();
        }
    };

    const handleDownArrowClick = async (
        unitCourse: UnitCourseRecord,
        indexOfSelectedCourse: number
    ): Promise<void> => {
        if (unitCourseIsLast(unitCourse)) {
            return;
        }
        if (deferSave) {
            setCourseHasChanges && setCourseHasChanges(true);
            if (unit == null || unitCourses == null) return;
            const downSortOrder = unitCourse.sortOrder! + 1;
            const updateSortOfUnitCourses = unitCourses?.map((uc, idx) => {
                if (idx === indexOfSelectedCourse) {
                    return uc.with({ sortOrder: downSortOrder });
                } else if (idx === indexOfSelectedCourse + 1) {
                    return uc.with({ sortOrder: uc.sortOrder! - 1 });
                } else return uc;
            });
            updateSortOfUnitCourses.sort((a, b) => a.sortOrder! - b.sortOrder!);
            onProductVersionUnitsChange(unit, updateSortOfUnitCourses);
        } else {
            const apiCalls: Promise<boolean>[] = [moveDownUnitCourse(unitCourse)];

            const unitCourseToMoveUp = getUnitCourseBySortOrder(unitCourse.sortOrder! + 1);

            if (unitCourseToMoveUp != null) {
                apiCalls.push(moveUpUnitCourse(unitCourseToMoveUp));
            }

            await Promise.all(apiCalls);

            fetchUnitCourseList();
        }
    };

    const getUnitCourseBySortOrder = (sortOrder: number): UnitCourseRecord | null => {
        if (CollectionUtils.isEmpty(unitCourses)) {
            return null;
        }
        const unitCourse = unitCourses.find((uc) => uc.sortOrder === sortOrder);
        return unitCourse == null ? null : unitCourse;
    };

    const moveDownUnitCourse = async (unitCourse: UnitCourseRecord): Promise<boolean> => {
        return updateUnitCourseSortOrder(unitCourse, unitCourse.sortOrder! + 1);
    };

    const moveUpUnitCourse = (unitCourse: UnitCourseRecord): Promise<boolean> => {
        return updateUnitCourseSortOrder(unitCourse, unitCourse.sortOrder! - 1);
    };

    const updateUnitCourseSortOrder = async (
        unitCourse: UnitCourseRecord,
        sortOrder: number
    ): Promise<boolean> => {
        const updatedUnitCourses = unitCourse.with({
            sortOrder: sortOrder,
        });

        let updateSuccess = false;
        try {
            updateSuccess = await updateUnitCourse(updatedUnitCourses);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheSortOrder"));
        }
        return updateSuccess;
    };

    const handleOptionClick = async (
        unitCourse: UnitCourseRecord,
        index: number
    ): Promise<void> => {
        if (unitCourses == null) {
            return;
        }
        if (deferSave) {
            setCourseHasChanges && setCourseHasChanges(true);
            const updatedUnitCourse = unitCourse.with({ optional: !unitCourse.optional });

            const updatedUnitCourses = unitCourses.map((uc, idx) => {
                if (idx === index) {
                    return updatedUnitCourse;
                } else return uc;
            });
            if (unit == null) return;
            onProductUnitChange(unit.with({ unitCourses: updatedUnitCourses }), unitIndex);
        } else {
            try {
                const updatedUnitCourses = unitCourse.with({
                    optional: !unitCourse.optional,
                });
                await updateUnitCourse(updatedUnitCourses);
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheCourse"));
            }
            fetchUnitCourseList();
        }
    };

    const updateUnitCourse = async (unitCourseWithChanges: UnitCourseRecord): Promise<boolean> => {
        setUpdateInProgress(true);

        const updateUnitCoursePathParams: UpdateUnitCoursePathParams = {
            id: unitCourseWithChanges.id!,
        };

        const updateUnitCourseResponse = await updateUnitCourseApi(
            unitCourseWithChanges,
            updateUnitCoursePathParams
        );

        setUpdateInProgress(false);

        const updateResult = updateUnitCourseResponse?.result;
        if (updateResult?.resultObject == null || updateResult.hasErrors()) {
            throw new Error();
        }

        return true;
    };

    const handlePreviewClick = async (externalScormCourseId: string | undefined): Promise<void> => {
        if (externalScormCourseId == null) {
            return;
        }
        const scormPreviewLink = await getPreviewLink(externalScormCourseId);
        if (scormPreviewLink.length > 0) {
            window.open(scormPreviewLink);
        }
    };

    const getPreviewLink = useCallback(
        async (externalScormCourseId?: string): Promise<string> => {
            if (externalScormCourseId == null) {
                return "";
            }

            const pathParams: GetScormPackagePreviewResourceEndpointPathParams = {
                id: externalScormCourseId,
            };

            try {
                const getResponse = await getPreviewLinkApi(pathParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                return getResult.resultObject;
            } catch {
                ToastManager.info(t("thePreviewIsNotAvailableYet"));
            }

            return "";
        },
        [getPreviewLinkApi]
    );

    return (
        <>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="arrows">
                            <span className="sr-only">{t("changeOrder")}</span>
                        </th>
                        <th className="order">{t("order")}</th>
                        <th className="name">{t("name")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="optional">{t("optional")}</th>
                        <th className="-ellipsis">
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {unitCourses &&
                        unitCourses.map((unitCourse, idx) => (
                            <tr key={idx}>
                                <td className="arrows">
                                    <ButtonIcon
                                        ariaLabelledBy={t("moveCourseUnitCourseCourseNameUp", {
                                            unitCourseCourseName: unitCourse.course?.name,
                                        })}
                                        buttonStyle={ButtonStyle.TertiaryAlt}
                                        cssClassName="arrow-up"
                                        disabled={disableUpArrowButtons(unitCourse)}
                                        iconType={Icons.ArrowUpward}
                                        onClick={() => handleUpArrowClick(unitCourse, idx)}
                                    />
                                    <ButtonIcon
                                        ariaLabelledBy={t("moveCourseUnitCourseCourseNameDown", {
                                            unitCourseCourseName: unitCourse.course?.name,
                                        })}
                                        buttonStyle={ButtonStyle.TertiaryAlt}
                                        cssClassName="arrow-down"
                                        disabled={disableDownArrowButtons(unitCourse)}
                                        iconType={Icons.ArrowDownward}
                                        onClick={() => handleDownArrowClick(unitCourse, idx)}
                                    />
                                </td>
                                <td className="order">{unitCourse.sortOrder}</td>
                                <td className="name">{unitCourse.course?.name}</td>
                                <td className="id">{unitCourse.course?.id}</td>
                                <td className="optional">
                                    <CheckboxInput
                                        checked={unitCourse.optional}
                                        disabled={unit?.optional}
                                        id={`unit-course-${unitCourse.id}-optional`}
                                        label={t("optional")}
                                        onChange={() => handleOptionClick(unitCourse, idx)}
                                        size={CheckboxSize.Small}
                                    />
                                </td>
                                <td className="-ellipsis">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            onClick={() =>
                                                handlePreviewClick(
                                                    unitCourse.course?.latestExternalScormCourseId
                                                )
                                            }
                                            displayName={t("preview")}
                                            forceEnabled={true}
                                        />
                                        <ContextMenuButton
                                            displayName={t("remove")}
                                            onClick={() =>
                                                confirmUnitCourseDeletion(unitCourse, idx)
                                            }
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </DataTable>
            <Modal
                actions={confirmationActionArray}
                isOpen={showUnitCourseDeletionConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("areYouSureYouWantToRemoveUnitCourseToDeleteCourseName", {
                    unitCourseToDeleteCourseName: unitCourseToDelete.course?.name,
                })}
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitCourseList };

// #endregion Exports
