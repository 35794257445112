import React from "react";
import { FormField, FormFieldProps } from "components/form/form-field/form-field";
import { StringUtils } from "andculturecode-javascript-core";
import { TimePicker } from "components/time-picker/time-picker";
import "react-datepicker/dist/react-datepicker.css";
import "./form-time-picker.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormTimePickerProps extends FormFieldProps {
    readOnly?: boolean;
    formFieldName: string;
    label: string;
    selectedTime: Date | null;
    onChange: (date?: Date) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-time-picker";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormTimePicker: React.FC<FormTimePickerProps> = (props) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <FormField
            cssClassName={className}
            label={props.label}
            formFieldName={props.formFieldName}
            required={props.required}>
            <TimePicker
                readOnly={props.readOnly ?? false}
                selectedTime={props.selectedTime}
                onChange={props.onChange}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormTimePicker };

// #endregion Exports
