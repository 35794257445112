import MaintenanceModeMessage from "models/interfaces/settings/maintenance-mode-message";
import { LocalizationUtils } from "andculturecode-javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

export default function useMaintenanceModeMessage() {
    const { record: globalState } = useGlobalState();
    const currentCultureCode = LocalizationUtils.cultureCodeFromRoute() ?? "en";
    const isActive = globalState.systemSettings?.maintenanceMode?.isActive ?? false;

    const hasAutoRefresh = globalState.systemSettings?.maintenanceMode?.hasAutoRefresh ?? false;

    const timeBetweenRefreshes =
        globalState.systemSettings?.maintenanceMode?.timeBetweenRefresh ?? 45;

    const maintenanceModeMessage: MaintenanceModeMessage | undefined =
        currentCultureCode === "en"
            ? globalState.systemSettings?.maintenanceMode?.message.enUs
            : currentCultureCode === "es"
            ? globalState.systemSettings?.maintenanceMode?.message.esEs
            : globalState.systemSettings?.maintenanceMode?.message.arAr;

    return {
        maintenanceModeMessage,
        hasAutoRefresh,
        timeBetweenRefreshes,
        isActive,
    };
}
