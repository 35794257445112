import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { Evaluation } from "models/interfaces/evaluations/evaluation";
import { EvaluationResponseRecord } from "models/view-models/evaluations/evaluation-response-record";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Evaluation = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollmentId: 0,
    enrollment: undefined,
    evaluationTemplateId: 0,
    evaluationTemplate: undefined,
    id: undefined,
    responses: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EvaluationRecord extends Record(defaultValues) implements Evaluation {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Evaluation>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.responses)) {
            params.responses = RecordUtils.ensureRecords(
                params.responses,
                EvaluationResponseRecord
            );
        }

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.evaluationTemplate != null) {
            params.evaluationTemplate = RecordUtils.ensureRecord(
                params.evaluationTemplate,
                EvaluationTemplateRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to sort the Responses by the Question Order.
     */
    public sortResponsesByQuestionOrder(): void {
        if (!CollectionUtils.hasValues(this.responses)) {
            return;
        }

        this.responses!.sort((a?: EvaluationResponseRecord, b?: EvaluationResponseRecord) => {
            return (
                (a?.evaluationQuestionTemplate?.sortOrder ?? 0) -
                (b?.evaluationQuestionTemplate?.sortOrder ?? 0)
            );
        });
    }

    /**
     * Given a set of values for Evaluation properties, create a new EvaluationRecord object from
     * this instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Evaluation>} values The values to overwrite on this instance.
     * @returns An EvaluationRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Evaluation>): EvaluationRecord {
        return new EvaluationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationRecord };

// #endregion Exports
