import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Status } from "utilities/enumerations/statuses";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ProductApprovalStatus {
    TrainingRequired = 0,
    ApprovedToTeach = 1,
    Revoked = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ProductApprovalStatusDisplayNames: Map<ProductApprovalStatus, TranslatedCopy> = {
    [ProductApprovalStatus.TrainingRequired]: "trainingRequired",
    [ProductApprovalStatus.ApprovedToTeach]: "approvedToTeach",
    [ProductApprovalStatus.Revoked]: "revoked",
};

const ProductApprovalStatusStatusMap: Map<ProductApprovalStatus, Status> = {
    [ProductApprovalStatus.TrainingRequired]: Status.Warning,
    [ProductApprovalStatus.ApprovedToTeach]: Status.Success,
    [ProductApprovalStatus.Revoked]: Status.Error,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductApprovalStatus, ProductApprovalStatusDisplayNames, ProductApprovalStatusStatusMap };

// #endregion Exports
