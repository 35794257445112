import { Process } from "utilities/types/processes/process";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { EventActiveRecord } from "models/active-records/events/event-active-record";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum EditEventProcessBaseSteps {
    Schedule,
    Location,
    Registration,
    Instructor,
}

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildEditEventProcessBase = (
    eventId: number,
    event: EventActiveRecord
): Process<typeof EditEventProcessBaseSteps> => ({
    Schedule: {
        displayName: "schedule",
        isComplete: () => event.scheduleIsComplete(),
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.schedule, {
            id: eventId,
        }),
    },
    Location: {
        displayName: "location",
        isComplete: () => event.locationIsComplete(),
        onExit: async () => {},
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.location, {
            id: eventId,
        }),
    },
    Registration: {
        displayName: "registration",
        isComplete: () => event.registrationIsComplete(),
        onExit: async () => {},
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.registration, {
            id: eventId,
        }),
    },
    Instructor: {
        displayName: "instructor",
        isComplete: () => event.instructorIsComplete(),
        path: RouteUtils.appendQueryParams(
            RouteUtils.replacePathParams(sitemap.admin.event.edit.instructor, {
                id: eventId,
            }),
            { approvedToTeach: true }
        ),
    },
});

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { buildEditEventProcessBase, EditEventProcessBaseSteps };

// #endregion Exports
