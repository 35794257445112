import { AuditableRecord } from "models/auditable-record/auditable-record";
import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { Contract } from "models/interfaces/contracts/contract";
import { DateUtils } from "utilities/date-utils";
import { EnrollmentRecord } from "../enrollments/enrollment-record";
import { EventRecord } from "../events/event-record";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { ProductRecord } from "models/view-models/products/product-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Contract = {
    contractDate: undefined,
    contractNumber: undefined,
    enrollments: undefined,
    enrollmentLimit: undefined,
    event: undefined,
    eventId: undefined,
    productId: undefined,
    product: undefined,
    organizationId: undefined,
    organization: undefined,
    nfpaAdminEmail: undefined,
    nfpaAdminName: undefined,
    nfpaAdminPhone: undefined,
    noEnrollmentLimit: false,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ContractRecord extends AuditableRecord(defaultValues) {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Contract>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        if (params.organization != null) {
            params.organization = RecordUtils.ensureRecord(params.organization, OrganizationRecord);
        }

        if (CollectionUtils.hasValues(params.enrollments)) {
            params.enrollments = RecordUtils.ensureRecords(params.enrollments, EnrollmentRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the last updated date and time text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    public getContractDate(): string {
        return DateUtils.formatDate(true, this.contractDate?.toString());
    }

    /**
     * Convenience method to format the phone number
     * Ex: +0 (000) 000 - 0000
     */
    public formatPhoneNumber(): string {
        const phoneNumber = ("" + this.nfpaAdminPhone).replace(/\D/g, "");
        const formatNumber = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (formatNumber) {
            const internationalCode = formatNumber[1] ? "+1" : "";
            return [
                internationalCode,
                " (",
                formatNumber[2],
                ") ",
                formatNumber[3],
                " - ",
                formatNumber[4],
            ].join("");
        }
        return "";
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractRecord };

// #endregion Exports
