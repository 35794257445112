import React from "react";
import { Banner, BannerStyle } from "components/banner/banner";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import "./schedule-exception-granted-banner.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ScheduleExceptionGrantedBannerProps {
    maxCEUs: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "schedule-exception-granted-banner";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ScheduleExceptionGrantedBanner: React.FC<ScheduleExceptionGrantedBannerProps> = ({
    maxCEUs,
}) => {
    return (
        <div className={CSS_CLASS_NAME}>
            <Banner style={BannerStyle.Success}>
                <p>
                    <Icon type={Icons.Information} />
                    {t("youveBeenGrantedAnExceptionToExceedMaxCEUsHoursForYourEvent", {
                        maxCEUs: maxCEUs,
                    })}
                </p>
            </Banner>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScheduleExceptionGrantedBanner };

// #endregion Exports
