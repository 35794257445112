import React, { useMemo } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorLedTrainingTypeBadgeProps {
    trainingType: InstructorLedTrainingType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorLedTrainingTypeBadge: React.FC<InstructorLedTrainingTypeBadgeProps> = ({
    trainingType,
}) => {
    const typeBadgeStyle: BadgeStyle = useMemo(
        () =>
            trainingType === InstructorLedTrainingType.LiveVirtual
                ? BadgeStyle.Dark
                : BadgeStyle.Neutral,
        [trainingType]
    );

    const typeBadgeText = useMemo(
        () => InstructorLedTrainingTypeDisplayNames[trainingType],
        [trainingType]
    );

    return <Badge style={typeBadgeStyle} text={t(typeBadgeText)} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorLedTrainingTypeBadge };

// #endregion Exports
