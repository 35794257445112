import React from "react";
import { LearnerDashboard } from "components/dashboards/learner-dashboard/learner-dashboard";
import "./learner-dashboard-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerDashboardPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-dashboard-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerDashboardPage: React.FC<LearnerDashboardPageProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <LearnerDashboard />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerDashboardPage };

// #endregion Exports
