import axios from "axios";
import { CourseDetailsArchiveRecord } from "models/view-models/courses/course-details-archive-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "coursedetailsarchive";
const resourceType: typeof CourseDetailsArchiveRecord = CourseDetailsArchiveRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetCourseDetailsArchivePathParams {
    id: number;
}

export interface ListCourseDetailsArchivesQueryParams {
    courseId: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CourseDetailsArchiveService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        CourseDetailsArchiveRecord,
        ListCourseDetailsArchivesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDetailsArchiveService };

// #endregion Exports
