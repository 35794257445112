import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import ReportSection from "components/reports/components/report-section/report-section";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { useCallback, useState } from "react";
import {
    ProviderService,
    ListProvidersQueryParams,
} from "utilities/services/providers/provider-service";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportProviderSelectionProps {
    isRequired?: boolean;
    providers: ProviderRecord[];
    onProvidersChanged: (providers: ProviderRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportProviderSelection: React.FC<ReportProviderSelectionProps> = ({
    isRequired = false,
    providers,
    onProvidersChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchProviders, setSearchProviders] = useState<ProviderRecord[]>([]);

    const { list: listProviders } = ProviderService.useList();
    const fetchProviders = useCallback(
        async (searchText?: string) => {
            try {
                const listProvidersQueryParams: ListProvidersQueryParams = {
                    searchText,
                };

                const listFilteredProvidersResponse = await listProviders(listProvidersQueryParams);
                const listFilteredProviderResults = listFilteredProvidersResponse?.results;
                const listedProviders = listFilteredProvidersResponse?.resultObjects;
                if (
                    listedProviders == null ||
                    listFilteredProviderResults == null ||
                    listFilteredProviderResults.hasErrors()
                ) {
                    throw new Error();
                }

                setSearchProviders(listedProviders);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProviders"));
                setSearchProviders([]);
            }
        },
        [listProviders]
    );

    const handleRemoveProvider = useCallback(
        (provider: ProviderRecord) => {
            onProvidersChanged(providers.filter((c) => c.id !== provider.id));
        },
        [onProvidersChanged, providers]
    );

    const handleProviderSelectionChange = (selectedProviders: ProviderRecord[]) => {
        onProvidersChanged(selectedProviders);
    };

    const handleAddProviders = () => {
        fetchProviders();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectAnENProvider")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddProviders}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addEnProvider")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(providers) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="provider-number">{t("providerName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {providers.map((provider) => (
                                <tr key={provider.id}>
                                    <td className="provider-name">{provider.name}</td>
                                    <td className="id">{provider.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveProvider(provider)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchProviders}
                onSelectionChanged={handleProviderSelectionChange}
                searchValues={searchProviders}
                selectedValues={providers}
                title={t("addEnProvider")}
                itemName={t("providerName")}
                searchPlaceholderText={t("typeToSearchByProviderIdOrName")}
                valuesDescription={t("providers")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name!,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportProviderSelection;

// #endregion Exports
