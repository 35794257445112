import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Button, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { EventDayForm } from "../event-day-form/event-day-form";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventSessionForm } from "../event-session-form/event-session-form";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { ToastManager } from "utilities/toast/toast-manager";
import "./event-day-manager.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayManagerProps {
    initialEventDay?: EventDayRecord;
    eventId: number;
    eventDayIndex: number;
    onEventDaySave: (index: number, eventDay: EventDayRecord) => void;
    onReset: () => void;
    readonly: boolean;
    includeSessions: boolean;
    isDateAvailable?: (eventDayIndex: number, date: Date) => boolean;
    nextAvailableDate?: Date;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-day-manager";
const DEFAULT_SESSION_DURATION_IN_MINUTES = "60";
const DEFAULT_SESSION_START_TIME = "08:00";

// #endregion Constants
// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayManager: React.FC<EventDayManagerProps> = ({
    initialEventDay,
    eventId,
    eventDayIndex,
    onEventDaySave,
    onReset,
    readonly,
    includeSessions,
    isDateAvailable,
    nextAvailableDate,
}) => {
    const [sessionTimesAreValid, setSessionTimesAreValid] = useState(true);
    const [eventDateIsValid, setEventDateIsValid] = useState(true);
    const [eventDay, setEventDay] = useState<EventDayRecord>();

    const editingExistingEventDay = useMemo(
        () => (eventDayIndex === -1 ? false : true),
        [eventDayIndex]
    );

    // -------------------------------------------------------------------------------------------------
    // #region Default Event Day
    // -------------------------------------------------------------------------------------------------

    const getDefaultEventDay = useCallback(() => {
        const date = nextAvailableDate ?? new Date();

        return new EventDayRecord({
            eventDayOfTheMonth: date.getDate(),
            eventId: eventId,
            eventMonth: date.getMonth(),
            eventSessions: !includeSessions ? [] : [createFirstEventDaySession()],
            eventYear: date.getFullYear(),
        });
    }, [eventId, nextAvailableDate, includeSessions]);

    const createFirstEventDaySession = () => {
        const defaultStartTime = moment(DEFAULT_SESSION_START_TIME, "HH:mm").toDate();
        const defaultEndTime = moment(defaultStartTime)
            .add(DEFAULT_SESSION_DURATION_IN_MINUTES, "m")
            .toDate();

        return new EventSessionRecord({
            endHour: defaultEndTime.getHours(),
            endMinute: defaultEndTime.getMinutes(),
            startHour: defaultStartTime.getHours(),
            startMinute: defaultStartTime.getMinutes(),
        });
    };

    useEffect(() => {
        if (initialEventDay === undefined) {
            setEventDay(getDefaultEventDay());
            return;
        }

        if (
            initialEventDay.eventSessions === undefined ||
            initialEventDay.eventSessions.length === 0
        ) {
            const initialEventDayWithSession = initialEventDay.with({
                eventSessions: [createFirstEventDaySession()],
            });
            setEventDay(initialEventDayWithSession);
        } else {
            setEventDay(initialEventDay);
        }
    }, [getDefaultEventDay, initialEventDay, nextAvailableDate]);

    // -------------------------------------------------------------------------------------------------
    // #endregion Default Event Day
    // -------------------------------------------------------------------------------------------------

    // -------------------------------------------------------------------------------------------------
    // #region Validation
    // -------------------------------------------------------------------------------------------------

    const validateEventDay = useCallback(
        (eventDay: EventDayRecord) => {
            if (isDateAvailable?.(eventDayIndex, eventDay.eventDate())) {
                setEventDateIsValid(true);
            } else {
                ToastManager.warn(t("eventDaysCannotOccurOnTheSameDay"));
                setEventDateIsValid(false);
            }
        },
        [eventDayIndex, isDateAvailable]
    );

    const validateEventSessionTime = (eventSession: EventSessionRecord): boolean => {
        if (eventSession.startTime() >= eventSession.endTime()) {
            ToastManager.warn(t("sessionEndTimeMayNotBePriorToStartTime"));
            return false;
        }

        return true;
    };

    const validateEventDaySessionTimes = (eventDay: EventDayRecord) => {
        eventDay.sortEventSessionsStartTime();
        const eventSessions = eventDay.eventSessions;

        if (eventSessions === undefined || eventSessions.length === 0) {
            setSessionTimesAreValid(false);
            return;
        }

        let sessionTimesAreValid = true;
        eventSessions!.forEach((eventSession, eventSessionIndex) => {
            sessionTimesAreValid = sessionTimesAreValid && validateEventSessionTime(eventSession);

            const priorEventSession =
                eventSessionIndex > 0 ? eventSessions[eventSessionIndex - 1] : undefined;

            if (
                priorEventSession === undefined ||
                moment(eventSession.startTime()).utc().toDate() >=
                    moment(priorEventSession?.endTime()).utc().toDate()
            ) {
                // Overlap is valid.
            } else {
                sessionTimesAreValid = false;
                ToastManager.warn(t("sessionTimesMayNotOverlap"));
            }
        });

        setSessionTimesAreValid(sessionTimesAreValid);
    };

    // -------------------------------------------------------------------------------------------------
    // #endregion Validation
    // -------------------------------------------------------------------------------------------------

    const handleEventDayUpdate = useCallback(
        (eventDay: EventDayRecord) => {
            // I needed to create ensure state was updated with a deep copy of eventDay.
            // I was getting a typescript error when trying to create new EventDayRecord().with(eventDay.toJS())

            const newEventDay = eventDay.with({ name: eventDay.name });
            setEventDay(newEventDay);
            validateEventDay(newEventDay);
        },
        [validateEventDay]
    );

    const handleEventSessionUpdate = (
        eventSessionIndex: number,

        eventSession: EventSessionRecord
    ) => {
        if (eventDay === undefined) {
            return;
        }

        eventDay.eventSessions![eventSessionIndex] = eventSession;

        validateEventDaySessionTimes(eventDay);

        handleEventDayUpdate(eventDay);
    };

    const handleEventSessionDeleteClick = (
        eventSessionIndex: number,
        eventSession: EventSessionRecord
    ) => {
        if (eventDay === undefined) {
            return;
        }

        eventDay.eventSessions!.splice(eventSessionIndex, 1);
        validateEventDaySessionTimes(eventDay);
        handleEventDayUpdate(eventDay);
    };

    const handleAddSession = () => {
        const eventSessions = eventDay?.eventSessions;

        if (eventSessions === undefined || eventSessions.length === 0) {
            eventSessions!.push(createFirstEventDaySession());
        } else {
            const numberOfEventSessions = eventSessions.length;
            const lastEventSessionIndex = numberOfEventSessions - 1;
            const endTimeOfLastSession = moment(
                eventSessions[lastEventSessionIndex].endTime()
            ).toDate();

            const startTimePlusDefaultSessionPeriod = moment(endTimeOfLastSession)
                .add(DEFAULT_SESSION_DURATION_IN_MINUTES, "m")
                .toDate();

            eventSessions.push(
                new EventSessionRecord({
                    startHour: endTimeOfLastSession.getHours(),
                    startMinute: endTimeOfLastSession.getMinutes(),
                    endHour: startTimePlusDefaultSessionPeriod.getHours(),
                    endMinute: startTimePlusDefaultSessionPeriod.getMinutes(),
                })
            );
        }

        if (eventDay === undefined) {
            return;
        }

        validateEventDaySessionTimes(eventDay);
        handleEventDayUpdate(eventDay);
    };

    const handleReset = useCallback(() => {
        setEventDay(getDefaultEventDay());
        setSessionTimesAreValid(true);
        setEventDateIsValid(true);
        onReset();
    }, [getDefaultEventDay, onReset]);

    const handleCancel = () => {
        onReset();
    };

    const handleSaveClick = async () => {
        onEventDaySave(eventDayIndex, eventDay!);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <Card>
                {/* Map eventDays to the EventDayForm component. */}
                {eventDay !== undefined && (
                    <div className={`${CSS_CLASS_NAME}__day`}>
                        <div className={`${CSS_CLASS_NAME}__day-form`}>
                            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                {editingExistingEventDay ? t("editDay") : t("addADay")}
                            </Heading>
                            <EventDayForm
                                eventDay={eventDay!}
                                onEventDayChange={handleEventDayUpdate}
                                readOnly={readonly}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__session-form`}>
                            {includeSessions &&
                                eventDay?.eventSessions !== undefined &&
                                eventDay?.eventSessions.map((eventSession, sessionIndex) => {
                                    return (
                                        <div
                                            key={`${sessionIndex}`}
                                            className={`${CSS_CLASS_NAME}__session`}>
                                            <EventSessionForm
                                                eventSession={eventSession}
                                                eventSessionIndex={sessionIndex}
                                                handleEventSessionDelete={
                                                    handleEventSessionDeleteClick
                                                }
                                                onEventSessionChange={handleEventSessionUpdate}
                                                readOnly={readonly}
                                            />
                                        </div>
                                    );
                                })}
                            {includeSessions && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.TertiaryAlt}
                                    disabled={readonly}
                                    iconType={Icons.PlusWithCircle}
                                    onClick={handleAddSession}
                                    text={t("addASession")}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className={`${CSS_CLASS_NAME}__actions`}>
                    {editingExistingEventDay && (
                        <Button
                            disabled={readonly}
                            onClick={handleCancel}
                            style={ButtonStyle.Secondary}
                            text={t("cancel")}
                        />
                    )}

                    {!editingExistingEventDay && (
                        <Button
                            disabled={readonly}
                            onClick={handleReset}
                            style={ButtonStyle.Secondary}
                            text={t("reset")}
                        />
                    )}

                    <Button
                        disabled={readonly || !sessionTimesAreValid || !eventDateIsValid}
                        onClick={handleSaveClick}
                        style={ButtonStyle.Primary}
                        text={editingExistingEventDay ? t("save") : t("addToSchedule")}
                    />
                </div>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayManager };

// #endregion Exports
