import { Button, ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import {
    Paragraph,
    ParagraphStyle,
    ParagraphSize,
} from "components/typography/paragraph/paragraph";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { sitemap } from "sitemap";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "utilities/string-utils";
import { Donut } from "../../../donut/donut";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { EnrollmentUnitRecord } from "models/view-models/enrollments/enrollment-unit-record";
import { t } from "utilities/localization/t";
import "./syllabus-unit-card.scss";

interface SyllabusCardProps {
    enrollmentUnit: EnrollmentUnitRecord;
    productId: number;
}

export const SyllabusUnitCard: React.FC<SyllabusCardProps> = ({
    enrollmentUnit,
    productId,
}): JSX.Element => {
    const navigate = useNavigate();
    const { enrollmentId } = useParams();
    const { unitId: unitId, name: unitName } = enrollmentUnit;
    const locked = enrollmentUnit.locked;
    const isRequired = !enrollmentUnit.optional;

    const handleNavigation = () => {
        const path = RouteUtils.getUrl(sitemap.learner.training.onlineLearning.units.details, {
            productId,
            enrollmentId,
            unitId,
        });

        navigate(path);
    };

    return (
        <Card cssClassName="syllabus-unit-card">
            <div className="syllabus-unit-card__header">
                {isRequired ? (
                    <Donut includeLabel={true} percent={enrollmentUnit.percentComplete} />
                ) : (
                    <span></span>
                )}
                <Badge
                    style={isRequired ? BadgeStyle.Neutral : BadgeStyle.Default}
                    text={isRequired ? t("required") : t("optional")}
                />
            </div>
            <Paragraph style={ParagraphStyle.Label}>
                {t("unitEnrollmentUnitSortOrder", {
                    enrollmentUnitSortOrder: enrollmentUnit.sortOrder,
                })}
            </Paragraph>

            {StringUtils.hasValue(unitName) && (
                <Paragraph size={ParagraphSize.XLarge}>{unitName}</Paragraph>
            )}

            <div className="syllabus-unit-card__details">
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("courses")}</Paragraph>
                    <Paragraph>{enrollmentUnit.courseCount}</Paragraph>
                </div>
            </div>
            <div className="syllabus-unit-card__actions">
                <Button disabled={locked} onClick={handleNavigation} style={ButtonStyle.Primary}>
                    {locked && <Icon type={Icons.Lock} />}
                    <span>{t("viewUnit")}</span>
                </Button>
            </div>
        </Card>
    );
};
