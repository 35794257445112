import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum CatalogEnrollmentStatus {
    Complete = 0,
    Incomplete = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const CatalogEnrollmentStatusDisplayNames: Map<CatalogEnrollmentStatus, TranslatedCopy> = {
    [CatalogEnrollmentStatus.Complete]: "complete",
    [CatalogEnrollmentStatus.Incomplete]: "incomplete",
};

// #endregion Maps

export { CatalogEnrollmentStatus, CatalogEnrollmentStatusDisplayNames };

// #endregion Exports
