import axios from "axios";
import { AenApplicationActivityLogRecord } from "models/view-models/aen-applications/aen-application-activity-log-record";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { Map } from "utilities/types/map";
import { RouteUtils } from "utilities/route-utils";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse } from "andculturecode-javascript-core";
import { ServiceUtils } from "utilities/service-utils";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "aenapplication/:type/:applicationId/logs";
const resourceType: typeof AenApplicationActivityLogRecord = AenApplicationActivityLogRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Maps
// -----------------------------------------------------------------------------------------

export const AenApplicationTypePath: Map<AenApplicationType, string> = {
    [AenApplicationType.Provider]: "providers",
    [AenApplicationType.Instructor]: "instructors",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AenApplicationActivityLogPathParams {
    applicationId: number;
    type: string;
}

export interface ListAenApplicationActivityLogsQueryParams {}

export interface CreateActivityLogDto {
    notes: string;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateActivityLogService = (
    applicationId: number,
    type: AenApplicationType,
    params: CreateActivityLogDto
) => Promise<ServiceResponse<AenApplicationActivityLogRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AenApplicationActivityLogService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateActivityLogService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateActivityLogService = (
            applicationId: number,
            type: AenApplicationType,
            params: CreateActivityLogDto
        ): Promise<ServiceResponse<AenApplicationActivityLogRecord>> => {
            const endpoint = RouteUtils.replacePathParams(baseEndpoint, {
                type: AenApplicationTypePath[type],
                applicationId: applicationId.toString(),
            });

            return axios
                .post(endpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            applicationId: number,
            type: AenApplicationType,
            params: CreateActivityLogDto
        ): Promise<ServiceResponse<AenApplicationActivityLogRecord>> {
            return cancellablePromise(serviceCreate(applicationId, type, params)) as Promise<
                ServiceResponse<AenApplicationActivityLogRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        AenApplicationActivityLogRecord,
        AenApplicationActivityLogPathParams,
        ListAenApplicationActivityLogsQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationActivityLogService };

// #endregion Exports
