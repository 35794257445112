import { useCallback, useEffect, useState } from "react";
import { ProductSelectionList } from "./product-selection-list/product-selection-list";
import { ProductRecord } from "models/view-models/products/product-record";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import {
    ListProductsQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { t } from "utilities/localization/t";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import "./product-selection-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductSelectionManagerProps {
    handleAddProductToQueue: (product: ProductRecord) => void;
    handleFetchProducts: (product: ProductRecord[]) => void;
    handleRemoveProductFromQueue: (product: ProductRecord) => void;
    products: ProductRecord[];
    selectedProduct?: ProductRecord;
    trainingType?: TrainingType;
}

export interface SelectableProduct {
    isSelected: boolean;
    product: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "product-selection-manager";
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductSelectionManager: React.FC<ProductSelectionManagerProps> = (
    props: ProductSelectionManagerProps
) => {
    const [searchText, setSearchText] = useState<string>("");
    const [selectableProducts, setSelectableProducts] = useState<SelectableProduct[]>([]);
    const { list: listProducts } = ProductService.useList();
    const handleFetchProducts = props.handleFetchProducts;

    const filterProducts = useCallback(
        async (searchText: string) => {
            try {
                let listProductsQueryParams: ListProductsQueryParams = {
                    searchText: searchText,
                    applySearch: true,
                    activeStatusFilter: [ActiveStatus.Active],
                    trainingType: props.trainingType,
                };

                const listFilteredProductsResponse = await listProducts(listProductsQueryParams);
                const listFilteredProductsResults = listFilteredProductsResponse?.results;
                const products = listFilteredProductsResponse?.resultObjects;
                if (
                    products == null ||
                    listFilteredProductsResults == null ||
                    listFilteredProductsResults.hasErrors()
                ) {
                    throw new Error();
                }

                products.sort((a, b) => a.name.localeCompare(b.name));

                handleFetchProducts(products);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProducts"));
                handleFetchProducts([]);
            }
        },
        [handleFetchProducts, listProducts, props.trainingType]
    );

    const convertProductToSelectableProduct = useCallback(
        (products: ProductRecord[]): SelectableProduct[] => {
            return products.map(
                (p): SelectableProduct => ({
                    product: p,
                    isSelected: props.selectedProduct?.id === p.id,
                })
            );
        },
        [props.selectedProduct?.id]
    );

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    useEffect(() => {
        const selectableProducts = convertProductToSelectableProduct(props.products);
        setSelectableProducts(selectableProducts);
    }, [convertProductToSelectableProduct, props.products, props.selectedProduct]);

    return (
        <div className={CSS_CLASS_NAME}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={filterProducts}
                id={"productSearch"}
                placeholder={t("typeToSearchByProductNameOrId")}
                searchTextInputValue={searchText}
            />
            <ProductSelectionList
                selectableProducts={selectableProducts}
                handleAdd={props.handleAddProductToQueue}
                handleRemove={props.handleRemoveProductFromQueue}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductSelectionManager };

// #endregion Exports
