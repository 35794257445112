// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const quillToolbarOptions = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    { align: [] },
    { header: [1, 2, 3, 4, 5, 6, false] },
    { list: "bullet" },
    { list: "ordered" },
    "link",
    "image",
];

const quillFormats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "header",
    "list",
    "bullet",
    "ordered",
    "link",
    "image",
    "align",
    "script",
];

const theme = "snow";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const QuillConfig = {
    quillToolbarOptions,
    quillFormats,
    theme,
};

export default QuillConfig;

// #endregion Exports
