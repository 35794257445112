import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EnrollmentScormPackageAssessmentService } from "utilities/services/enrollments/enrollment-scorm-package-assessment-service";
import { useEffect, useState } from "react";
import { NumberUtils } from "utilities/number-utils";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseEnrollmentScormPackageAssessmentHook {
    enrollmentScormPackageAssessment?: EnrollmentScormPackageAssessmentRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseEnrollmentScormPackageAssessmentHookOptions {
    enrollmentId?: number | string;
    scormPackageAssessmentId?: number | string;
    enrollmentScormPackageAssessmentId?: number | string;
}

export function useEnrollmentScormPackageAssessment(
    props: UseEnrollmentScormPackageAssessmentHookOptions
): UseEnrollmentScormPackageAssessmentHook {
    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;
    const scormPackageAssessmentId = NumberUtils.parseInt(props.scormPackageAssessmentId);
    const enrollmentScormPackageAssessmentId =
        NumberUtils.parseInt(props.enrollmentScormPackageAssessmentId) ?? 0;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentScormPackageAssessment, setEnrollmentScormPackageAssessment] =
        useState<EnrollmentScormPackageAssessmentRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listEnrollmentScormPackageAssessment } =
        EnrollmentScormPackageAssessmentService.useList();

    useEffect(() => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function fetchEnrollmentScormPackageAssessment() {
            try {
                const [enrollmentScormPackageAssessmentResponse] = await Promise.all([
                    listEnrollmentScormPackageAssessment(
                        {
                            enrollmentId: enrollmentId,
                        },
                        { scormPackageAssessmentId }
                    ),
                ]);

                setIsLoading(false);

                if (enrollmentScormPackageAssessmentResponse.result?.hasErrors()) {
                    const { errors: enrollmentScormPackageAssessmentErrors = [] } =
                        enrollmentScormPackageAssessmentResponse.result ?? {};

                    setErrors([...enrollmentScormPackageAssessmentErrors]);
                    return;
                }

                setEnrollmentScormPackageAssessment(
                    enrollmentScormPackageAssessmentResponse.resultObjects[0]
                );
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheAssessment"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [
        enrollmentId,
        enrollmentScormPackageAssessmentId,
        listEnrollmentScormPackageAssessment,
        scormPackageAssessmentId,
    ]);

    return {
        errors,
        isLoading,
        enrollmentScormPackageAssessment: enrollmentScormPackageAssessment,
    };
}
