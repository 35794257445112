import { Process } from "utilities/types/processes/process";
import { ProductRecord } from "models/view-models/products/product-record";
import { RouteUtils } from "utilities/route-utils";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { TrainingType } from "models/enumerations/courses/training-type";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum CreateProductProcessSpecificSteps {
    Details,
    Materials,
}

export type CreateProductProcessSteps = typeof CreateProductProcessSpecificSteps;

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Private Methods
// -------------------------------------------------------------------------------------------------

const createOnlineProcess = (
    productId: number,
    product: ProductRecord,
    productVersion: ProductVersionRecord
): Process<CreateProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersion.courseIsComplete() &&
                productVersion.contentIsComplete() &&
                productVersion.achievementIsComplete(product.audienceType) &&
                product.evaluationTemplateIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.create.online.materials, {
                id: productId,
            }),
        },
    };
};

const createInstructorLedProcess = (
    productId: number,
    product: ProductRecord,
    productVersion: ProductVersionRecord
): Process<CreateProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.instructorLed.details, {
                id: productId,
            }),
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersion.assessmentIsComplete() &&
                productVersion.contentIsComplete() &&
                productVersion.achievementIsComplete(product.audienceType) &&
                product.evaluationTemplateIsComplete(),
            path: RouteUtils.replacePathParams(
                sitemap.admin.product.create.instructorLed.materials,
                {
                    id: productId,
                }
            ),
        },
    };
};

const createTrainTheTrainerProcess = (
    productId: number,
    product: ProductRecord,
    productVersion: ProductVersionRecord
): Process<CreateProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersion.courseIsComplete() && productVersion.contentIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.create.online.materials, {
                id: productId,
            }),
        },
    };
};

const createInstructorAssessmentProcess = (
    productId: number,
    product: ProductRecord,
    productVersion: ProductVersionRecord
): Process<CreateProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersion.assessmentIsComplete() && productVersion.contentIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.create.assessment.materials, {
                id: productId,
            }),
        },
    };
};
// #endregion Private Methods

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildCreateProductProcess = (
    productId: number,
    product: ProductRecord,
    productVersion: ProductVersionRecord
): Process<CreateProductProcessSteps> => {
    switch (product.type) {
        case TrainingType.InstructorLedTraining:
            return createInstructorLedProcess(productId, product, productVersion);

        case TrainingType.OnlineLearning:
            return createOnlineProcess(productId, product, productVersion);

        case TrainingType.TrainTheTrainer:
            return createTrainTheTrainerProcess(productId, product, productVersion);

        case TrainingType.InstructorAssessment:
            return createInstructorAssessmentProcess(productId, product, productVersion);

        default:
            return createOnlineProcess(productId, product, productVersion);
    }
};

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useCreateProductProcess = UseProcessFactory.build(buildCreateProductProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useCreateProductProcess };

// #endregion Exports
