import { CatalogEnrollmentErrorType } from "models/enumerations/enrollments/catalog-enrollment-log";
import { CatalogEnrollmentStatus } from "models/enumerations/enrollments/catalog-enrollment-status";

export interface CatalogEnrollmentLogFilterState {
    errorType?: CatalogEnrollmentErrorType;
    status?: CatalogEnrollmentStatus;
}

export enum CatalogEnrollmentLogFilterReduceActionType {
    UpdateErrorType,
    UpdateStatus,
    ResetFilters,
}

export const initialState: CatalogEnrollmentLogFilterState = {
    status: CatalogEnrollmentStatus.Incomplete,
    errorType: undefined,
};

export type CatalogEnrollmentLogFilterReducerActions =
    | {
          type: CatalogEnrollmentLogFilterReduceActionType.UpdateStatus;
          value?: CatalogEnrollmentStatus;
      }
    | { type: CatalogEnrollmentLogFilterReduceActionType.ResetFilters }
    | {
          type: CatalogEnrollmentLogFilterReduceActionType.UpdateErrorType;
          value?: CatalogEnrollmentErrorType;
      };

export function catalogEnrollmentLogFilterReducer(
    state: CatalogEnrollmentLogFilterState,
    action: CatalogEnrollmentLogFilterReducerActions
): CatalogEnrollmentLogFilterState {
    switch (action.type) {
        case CatalogEnrollmentLogFilterReduceActionType.ResetFilters:
            return {
                status: undefined,
            };

        case CatalogEnrollmentLogFilterReduceActionType.UpdateStatus:
            if (state.status === action.value) {
                return state;
            }

            return {
                ...state,
                status: action.value,
            };

        case CatalogEnrollmentLogFilterReduceActionType.UpdateErrorType:
            return {
                ...state,
                errorType: action.value,
            };
    }
}
