import { ServiceHookFactory } from "andculturecode-javascript-react";
import { ReportRecord } from "models/view-models/reports/report-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "reports/definitions";
const resourceEndpoint: string = "reports/definitions/:slug";
const resourceType: typeof ReportRecord = ReportRecord;

// #endregion Constants

// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListReportsQueryParams {}
export interface GetReportPathParams {
    slug: string;
}
export interface GetReportQueryParams {}

// #endregion Interfaces
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ReportService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ReportRecord, ListReportsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    useGet: ServiceHookFactory.useGet<ReportRecord, GetReportPathParams, GetReportQueryParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportService };

// #endregion Exports
