// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ExternalLinks = {
    Catalog: "https://catalog.nfpa.org/",
    Community: "https://community.nfpa.org/",
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ExternalLinks };

// #endregion Exports
