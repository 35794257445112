import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import {
    InstructorApplicationService,
    PatchInstructorApplicationPathParams,
} from "utilities/services/aen-applications/instructor-application-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { useParams } from "react-router-dom";
import { PatchInstructorApplicationRecord } from "models/view-models/aen-applications/patch-instructor-application-record";

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingInstructorApplications";

interface UseAenInstructorApplicationOptions {
    isReady?: boolean;
    onError?: () => void;
}
export const useAenInstructorApplication = ({
    isReady,
    onError,
}: UseAenInstructorApplicationOptions) => {
    const { id } = useParams();
    const { get } = InstructorApplicationService.useGet();
    const { patch } = InstructorApplicationService.usePatch();
    const [instructorApplication, setInstructorApplication] = useState<InstructorApplicationRecord>(
        new InstructorApplicationRecord()
    );
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchInstructorApplication = useCallback(async () => {
        try {
            (async function getInstructorApplication() {
                setIsLoading(true);
                const response = await get({ id });
                setIsLoading(false);

                if (response?.resultObject == null) {
                    throw new Error();
                }
                setInstructorApplication(response.resultObject);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [get, id, setError]);

    const saveApplication = useCallback(
        async (application: PatchInstructorApplicationRecord) => {
            try {
                const patchPathParams: PatchInstructorApplicationPathParams = {
                    id: application.id ?? 0,
                };

                var patchResponse = await patch(patchPathParams, application);
                var patchResult = patchResponse?.result;
                if (patchResult?.resultObject == null || patchResult.hasErrors()) {
                    throw new Error();
                }

                fetchInstructorApplication();

                return true;
            } catch {
                return false;
            }
        },
        [patch, fetchInstructorApplication]
    );

    useEffect(() => {
        fetchInstructorApplication();
    }, [fetchInstructorApplication]);

    return {
        error,
        isLoading,
        instructorApplication,
        setInstructorApplication,
        fetchInstructorApplication,
        saveApplication,
    };
};
