import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AenApplicationAddLogModal } from "components/aen-application/aen-application-logs/aen-application-add-file-modal/aen-application-add-activity-log-modal";
import { AenApplicationLogList } from "components/aen-application/aen-application-logs/aen-application-log-list/aen-application-log-list";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import { useAenApplicationActivityLog } from "utilities/hooks/models/aen-applications/use-aen-application-logs";
import { useState } from "react";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useProviderApplication } from "utilities/contexts/use-provider-application-context";
import { useInstructorApplication } from "utilities/contexts/use-instructor-application-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENApplicationsLogPageProps {
    applicationType: AenApplicationType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-applications-log-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENApplicationsLogPage: React.FC<AENApplicationsLogPageProps> =
    validatePageAccess<AENApplicationsLogPageProps>(AccessControlKeys.AdminAenApplicationLogs)(
        ({ applicationType }): JSX.Element => {
            const { record: application } =
                applicationType === AenApplicationType.Instructor
                    ? useInstructorApplication()
                    : useProviderApplication();

            const [showModal, setShowModal] = useState<boolean>(false);
            const { logs, addActivityLog } = useAenApplicationActivityLog({
                application: application,
                type: applicationType,
            });

            return (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <HeaderActions
                            title={t("activityLogs")}
                            headingPriority={HeadingPriority.H2}
                            headingSize={HeadingSize.XSmall}>
                            <ButtonIcon
                                onClick={() => {
                                    setShowModal(true);
                                }}
                                buttonStyle={ButtonStyle.Primary}
                                text={t("addNotes")}
                                iconType={Icons.Plus}
                            />
                        </HeaderActions>
                    </div>
                    <AenApplicationLogList logs={logs} />
                    <AenApplicationAddLogModal
                        handleSave={addActivityLog}
                        open={showModal}
                        setOpen={setShowModal}
                    />
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENApplicationsLogPage };

// #endregion Exports
