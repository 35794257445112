import { UserRecord } from "models/view-models/users/user-record";
import { UserService } from "utilities/services/users/user-service";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { RoleType } from "models/enumerations/users/role-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingTheUsers";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseUsersHook {
    error?: Error;
    isLoading: boolean;
    users: UserRecord[];
    rowCount: number;
}

interface UseUsersHookOptions {
    excludeUsersInGroup?: boolean;
    groupId?: number;
    onError?: (error: Error) => void;
    includeOrganization?: boolean;
    includeProvider?: boolean;
    providerId?: number;
    includeInstructorsAssociatedWithProvider?: boolean;
    includeLastLogin?: boolean;
    includeRoles?: boolean;
    searchCriteriaReady?: boolean;
    searchText?: string;
    roleTypes?: RoleType[];
    hasNoUserRoles?: boolean;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useUsers({
    excludeUsersInGroup,
    groupId,
    hasNoUserRoles,
    includeInstructorsAssociatedWithProvider,
    includeLastLogin,
    includeOrganization,
    includeProvider,
    includeRoles,
    onError,
    providerId,
    roleTypes,
    searchCriteriaReady = true,
    searchText,
    skip,
    take,
}: UseUsersHookOptions): UseUsersHook {
    const { list } = UserService.useList();
    const [users, setUsers] = useState<UserRecord[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!searchCriteriaReady) {
            setUsers([]);
            setIsLoading(false);
            return;
        }

        try {
            (async function getUsers() {
                setIsLoading(true);
                const usersResponse = await list({
                    excludeUsersInGroup: excludeUsersInGroup,
                    groupId: groupId,
                    hasNoUserRoles: hasNoUserRoles,
                    includeInstructorsAssociatedWithProvider:
                        includeInstructorsAssociatedWithProvider,
                    includeLastLogin: includeLastLogin,
                    includeOrganization: includeOrganization,
                    includeProvider: includeProvider,
                    includeRoles: includeRoles,
                    providerId: providerId,
                    roleTypes: roleTypes,
                    searchText: searchText,
                    skip: skip,
                    take: take,
                });
                setIsLoading(false);

                if (
                    usersResponse?.resultObjects == null ||
                    usersResponse?.results == null ||
                    usersResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setUsers(usersResponse.resultObjects);
                setRowCount(usersResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [
        hasNoUserRoles,
        includeOrganization,
        includeProvider,
        providerId,
        includeRoles,
        list,
        roleTypes,
        searchCriteriaReady,
        searchText,
        setError,
        skip,
        take,
        includeInstructorsAssociatedWithProvider,
        excludeUsersInGroup,
        groupId,
        includeLastLogin,
    ]);

    return {
        error,
        isLoading,
        users,
        rowCount,
    };
}

// #endregion Hook
