import { LocalizationConstants } from "constants/localization/localization-constants";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const BASE_ROUTE: string = `/:${LocalizationConstants.CULTURE_CODE_DYNAMIC_SLUG_KEY}?`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Sitemap
// -------------------------------------------------------------------------------------------------

const sitemap = {
    azureLogin: `${BASE_ROUTE}/azure`,
    azureLogout: `${BASE_ROUTE}/azure-logout`,
    admin: {
        aenResources: {
            providers: {
                list: `${BASE_ROUTE}/admin/aen-resources/providers`,
                new: `${BASE_ROUTE}/admin/aen-resources/providers/new`,
            },
            instructors: {
                list: `${BASE_ROUTE}/admin/aen-resources/instructors`,
                new: `${BASE_ROUTE}/admin/aen-resources/instructors/new`,
            },
        },
        aenApplications: {
            providers: {
                list: `${BASE_ROUTE}/admin/aen-applications/providers`,
                detail: `${BASE_ROUTE}/admin/aen-applications/providers/:id`,
                files: `${BASE_ROUTE}/admin/aen-applications/providers/:id/files`,
                logs: `${BASE_ROUTE}/admin/aen-applications/providers/:id/logs`,
            },
            instructors: {
                list: `${BASE_ROUTE}/admin/aen-applications/instructors`,
                detail: `${BASE_ROUTE}/admin/aen-applications/instructors/:id`,
                files: `${BASE_ROUTE}/admin/aen-applications/instructors/:id/files`,
                assessments: `${BASE_ROUTE}/admin/aen-applications/instructors/:id/assessments`,
                logs: `${BASE_ROUTE}/admin/aen-applications/instructors/:id/logs`,
            },
        },
        announcements: {
            list: `${BASE_ROUTE}/admin/announcements`,
        },
        catalogEnrollmentLog: {
            list: `${BASE_ROUTE}/admin/catalog-enrollment-log`,
        },
        course: {
            edit: {
                details: `${BASE_ROUTE}/admin/courses/:id/details/:versionId`,
                materials: {
                    version: {
                        create: `${BASE_ROUTE}/admin/courses/:id/materials/:versionId`,
                        edit: `${BASE_ROUTE}/admin/courses/:id/materials/version/:versionId`,
                    },
                },
                versionList: `${BASE_ROUTE}/admin/courses/:id/materials/versions`,
            },
            list: `${BASE_ROUTE}/admin/courses`,
        },
        dashboard: `${BASE_ROUTE}/admin/dashboard`,
        enrollment: {
            event: `${BASE_ROUTE}/admin/enrollments/products/:productId/events/:eventId`,
        },
        communications: {
            sendAnEmail: `${BASE_ROUTE}/admin/communications/send-an-email`,
        },
        event: {
            attendance: {
                day: `${BASE_ROUTE}/admin/events/:eventId/attendance/:eventDayId`,
                summary: `${BASE_ROUTE}/admin/events/:id/attendance`,
            },
            changeLog: `${BASE_ROUTE}/admin/events/:id/changelogs`,
            edit: {
                contact: `${BASE_ROUTE}/admin/events/:id/contact`,
                details: `${BASE_ROUTE}/admin/events/:id/details`,
                instructor: `${BASE_ROUTE}/admin/events/:id/instructor`,
                location: `${BASE_ROUTE}/admin/events/:id/location`,
                product: `${BASE_ROUTE}/admin/events/:id/product`,
                registration: `${BASE_ROUTE}/admin/events/:id/registration`,
                schedule: `${BASE_ROUTE}/admin/events/:id/schedule`,
            },
            manage: {
                details: `${BASE_ROUTE}/admin/events/manage/:id/details`,
                content: `${BASE_ROUTE}/admin/events/manage/:id/content`,
                enrollment: `${BASE_ROUTE}/admin/events/manage/:id/enrollment`,
                attendance: {
                    day: `${BASE_ROUTE}/admin/events/manage/:id/attendance/:eventDayId`,
                    summary: `${BASE_ROUTE}/admin/events/manage/:id/attendance`,
                },
                assessments: {
                    assessments: `${BASE_ROUTE}/admin/events/manage/:id/assessments`,
                    assessmentResults: `${BASE_ROUTE}/admin/events/manage/:id/assessments/:assessmentId`,
                    learnerAssessment: `${BASE_ROUTE}/admin/events/manage/:id/assessments/:assessmentId/registration/:registrationId`,
                },
                evaluations: {
                    summary: `${BASE_ROUTE}/admin/events/manage/:id/evaluation`,
                    preview: `${BASE_ROUTE}/admin/events/manage/:id/evaluation/preview`,
                },
            },
            list: `${BASE_ROUTE}/admin/events`,
        },
        instructorDirectory: `${BASE_ROUTE}/admin/instructors/directory`,
        product: {
            create: {
                online: {
                    details: `${BASE_ROUTE}/admin/products/:id/online/details`,
                    materials: `${BASE_ROUTE}/admin/products/:id/online/materials`,
                },
                instructorLed: {
                    details: `${BASE_ROUTE}/admin/products/:id/instructor-led/details`,
                    materials: `${BASE_ROUTE}/admin/products/:id/instructor-led/materials`,
                },
                assessment: {
                    details: `${BASE_ROUTE}/admin/products/:id/assessment/details`,
                    materials: `${BASE_ROUTE}/admin/products/:id/assessment/materials`,
                },
            },
            edit: {
                online: {
                    details: `${BASE_ROUTE}/admin/products/:id/online/details`,
                    materials: {
                        version: {
                            create: `${BASE_ROUTE}/admin/products/:id/online/materials/version`,
                            edit: `${BASE_ROUTE}/admin/products/:id/online/materials/version/:versionId`,
                        },
                    },
                },
                instructorLed: {
                    details: `${BASE_ROUTE}/admin/products/:id/instructor-led/details`,
                    materials: {
                        version: {
                            create: `${BASE_ROUTE}/admin/products/:id/instructor-led/materials/version`,
                            edit: `${BASE_ROUTE}/admin/products/:id/instructor-led/materials/version/:versionId`,
                        },
                    },
                },
                assessment: {
                    details: `${BASE_ROUTE}/admin/products/:id/assessment/details`,
                    materials: {
                        version: {
                            create: `${BASE_ROUTE}/admin/products/:id/assessment/materials/version`,
                            edit: `${BASE_ROUTE}/admin/products/:id/assessment/materials/version/:versionId`,
                        },
                    },
                },
                versionList: `${BASE_ROUTE}/admin/products/:id/materials/versions`,
            },
            list: `${BASE_ROUTE}/admin/products`,
            enrollments: `${BASE_ROUTE}/admin/products/:id/enrollments`,
        },
        reports: {
            downloads: `${BASE_ROUTE}/admin/reports/downloads`,
            setup: `${BASE_ROUTE}/admin/reports/run-a-report/setup/:slug`,
            list: `${BASE_ROUTE}/admin/reports/run-a-report`,
        },
        userManagement: {
            contracts: {
                info: `${BASE_ROUTE}/admin/user-management/organizations/:id/info`,
                instructorLed: {
                    detail: `${BASE_ROUTE}/admin/user-management/organizations/:id/instructor-led/:contractId/detail`,
                    list: `${BASE_ROUTE}/admin/user-management/organizations/:id/instructor-led`,
                },
                list: `${BASE_ROUTE}/admin/user-management/organizations`,
                new: `${BASE_ROUTE}/admin/user-management/organizations/new`,
                onlineLearning: {
                    detail: `${BASE_ROUTE}/admin/user-management/organizations/:id/online-learning/:contractId/detail`,
                    list: `${BASE_ROUTE}/admin/user-management/organizations/:id/online-learning`,
                    new: `${BASE_ROUTE}/admin/user-management/organizations/:id/online-learning/new`,
                },
            },
            enProviders: {
                associatedUsers: `${BASE_ROUTE}/admin/user-management/en-providers/:id/associated-users`,
                info: `${BASE_ROUTE}/admin/user-management/en-providers/:id/info`,
                list: `${BASE_ROUTE}/admin/user-management/en-providers`,
                new: `${BASE_ROUTE}/admin/user-management/en-providers/new`,
            },
            users: {
                info: {
                    default: `${BASE_ROUTE}/admin/user-management/users/:id/basic-info`,
                },
                instructor: {
                    approvedToTeach: `${BASE_ROUTE}/admin/user-management/users/:id/approved-to-teacher`,
                    default: `${BASE_ROUTE}/admin/user-management/users/:id/en-instructor`,
                    requiredTrainings: `${BASE_ROUTE}/admin/user-management/users/:id/required-trainings`,
                    teachingHistory: `${BASE_ROUTE}/admin/user-management/users/:id/teaching-history`,
                },
                list: `${BASE_ROUTE}/admin/user-management/users`,
                new: `${BASE_ROUTE}/admin/user-management/users/new`,
                trainings: {
                    default: `${BASE_ROUTE}/admin/user-management/users/:id/trainings`,
                    overview: `${BASE_ROUTE}/admin/user-management/users/:id/trainings/:enrollmentId/overview`,
                    assessment: `${BASE_ROUTE}/admin/user-management/users/:id/trainings/:enrollmentId/registration/:registrationId/assessments`,
                },
            },
            groups: {
                info: `${BASE_ROUTE}/admin/user-management/groups/:id/info`,
                list: `${BASE_ROUTE}/admin/user-management/groups`,
                new: `${BASE_ROUTE}/admin/user-management/groups/new`,
                members: `${BASE_ROUTE}/admin/user-management/groups/:id/members`,
            },
        },
    },

    instructor: {
        dashboard: `${BASE_ROUTE}/instructor/dashboard`,
        educationNetworkHub: {
            approvedToTeach: `${BASE_ROUTE}/instructor/education-network-hub/approved-to-teach`,
            requiredTrainings: `${BASE_ROUTE}/instructor/education-network-hub/required-trainings`,
            resources: `${BASE_ROUTE}/instructor/education-network-hub/resources`,
            profile: `${BASE_ROUTE}/instructor/education-network-hub/instructor-profile`,
        },
        teachingAssignments: `${BASE_ROUTE}/teaching`,
        event: {
            details: `${BASE_ROUTE}/events/:id/details`,
            content: `${BASE_ROUTE}/events/:id/content`,
            enrollment: `${BASE_ROUTE}/events/:id/enrollment`,
            attendance: {
                day: `${BASE_ROUTE}/events/:id/attendance/:eventDayId`,
                summary: `${BASE_ROUTE}/events/:id/attendance`,
            },
            assessments: {
                assessments: `${BASE_ROUTE}/events/:id/assessments`,
                assessmentResults: `${BASE_ROUTE}/events/:id/assessments/:assessmentId`,
                learnerAssessment: `${BASE_ROUTE}/events/:id/assessments/:assessmentId/registration/:registrationId`,
            },
            evaluations: {
                summary: `${BASE_ROUTE}/events/:id/evaluation`,
                preview: `${BASE_ROUTE}/events/:id/evaluation/preview`,
            },
        },
        aenTraining: {
            details: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/details`,
            content: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/content`,
            assessments: {
                results: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/details/assessments/:assessmentId/registrations/:registrationId`,
            },
            units: {
                details: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/details/units/:unitId`,
                courses: {
                    details: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/details/units/:unitId/courses/:courseId`,
                    assessments: `${BASE_ROUTE}/instructor/training/:productId/enrollment/:enrollmentId/details/units/:unitId/courses/:courseId/assessments/:assessmentId`,
                },
            },
        },
        aenInstructorAssessment: {
            details: `${BASE_ROUTE}/instructor/assessment/:productId/enrollment/:enrollmentId/details`,
            content: `${BASE_ROUTE}/instructor/assessment/:productId/enrollment/:enrollmentId/content`,
            assessments: {
                results: `${BASE_ROUTE}/instructor/assessment/:productId/enrollment/:enrollmentId/details/assessments/:assessmentId/registrations/:registrationId`,
            },
        },
    },
    learner: {
        dashboard: `${BASE_ROUTE}/learner/dashboard`,
        certificates: `${BASE_ROUTE}/learner/certificates`,
        learningAssignments: {
            inProgress: `${BASE_ROUTE}/learning/in-progress`,
            past: `${BASE_ROUTE}/learning/past`,
        },
        training: {
            event: {
                list: `${BASE_ROUTE}/learner/training/events`,
                details: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/details`,
                content: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/content`,
                assessments: {
                    list: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/assessments`,
                    results: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/assessments/:assessmentId/registrations/:registrationId`,
                },
                evaluation: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/evaluation`,
                achievements: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/achievements`,
                summary: `${BASE_ROUTE}/learner/training/event/:id/enrollment/:enrollmentId/summary`,
            },
            onlineLearning: {
                details: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/details`,
                content: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/content`,
                assessments: {
                    results: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/details/assessments/:assessmentId/registrations/:registrationId`,
                },
                units: {
                    details: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/details/units/:unitId`,
                    courses: {
                        details: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/details/units/:unitId/courses/:courseId`,
                        assessments: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/details/units/:unitId/courses/:courseId/assessments/:assessmentId`,
                    },
                },
                evaluation: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/evaluation`,
                achievements: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/achievements`,
                summary: `${BASE_ROUTE}/learner/training/:productId/enrollment/:enrollmentId/summary`,
            },
        },
    },
    thirdPartyProvider: {
        dashboard: {
            online: `${BASE_ROUTE}/third-party/dashboard/online`,
            instructor: `${BASE_ROUTE}/third-party/dashboard/instructor`,
        },
        event: {
            details: `${BASE_ROUTE}/third-party/events/:id/details`,
            content: `${BASE_ROUTE}/third-party/events/:id/content`,
            enrollment: `${BASE_ROUTE}/third-party/events/:id/enrollment`,
            attendance: {
                day: `${BASE_ROUTE}/third-party/events/:id/attendance/:eventDayId`,
                summary: `${BASE_ROUTE}/third-party/events/:id/attendance`,
            },
            assessments: {
                assessments: `${BASE_ROUTE}/third-party/events/:id/assessments`,
                assessmentResults: `${BASE_ROUTE}/third-party/events/:id/assessments/:assessmentId`,
                learnerAssessment: `${BASE_ROUTE}/third-party/events/:id/assessments/:assessmentId/registration/:registrationId`,
            },
            evaluations: {
                summary: `${BASE_ROUTE}/third-party/events/:id/evaluation`,
                preview: `${BASE_ROUTE}/third-party/events/:id/evaluation/preview`,
            },
        },
        onlineLearning: {
            details: `${BASE_ROUTE}/third-party/contract/:contractId/training/:productId/details`,
            enrollment: `${BASE_ROUTE}/third-party/contract/:contractId/training/:productId/enrollment`,
        },
    },
    nfpaOperators: {
        dashboard: `${BASE_ROUTE}/operators/dashboard`,
    },
    nfpaSupport: {
        dashboard: `${BASE_ROUTE}/nfpa-support/dashboard`,
    },
    aenProvider: {
        dashboard: `${BASE_ROUTE}/enprovider/dashboard`,
        productCatalog: {
            details: `${BASE_ROUTE}/catalog/products/:productId`,
            list: `${BASE_ROUTE}/catalog/products`,
        },
        educationNetworkHub: {
            organization: `${BASE_ROUTE}/enprovider/education-network-hub/your-organization`,
            resources: `${BASE_ROUTE}/enprovider/education-network-hub/resources`,
        },
    },
    reviewer: {
        dashboard: `${BASE_ROUTE}/reviewer/dashboard`,
    },
    public: {
        noAccess: `${BASE_ROUTE}/no-access`,
        userLogin: `${BASE_ROUTE}/user-login`,
    },
    authenticated: {
        profile: `${BASE_ROUTE}/profile`,
        support: `${BASE_ROUTE}/support`,
    },
    test: {
        placeholder: {
            achievements: `${BASE_ROUTE}/achievements`,
            announcements: `${BASE_ROUTE}/announcements`,
            community: `${BASE_ROUTE}/community`,
            learning: `${BASE_ROUTE}/learning`,
            organization: `${BASE_ROUTE}/organization`,
            profile: `${BASE_ROUTE}/profile`,
            reporting: `${BASE_ROUTE}/reporting`,
            userManagement: `${BASE_ROUTE}/user-management`,
        },
        sandbox: `${BASE_ROUTE}/admin/sandbox`,
        icons: `${BASE_ROUTE}/admin/icons`,
    },
    root: `${BASE_ROUTE}/`,
};

// #endregion Sitemap

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { sitemap };

// #endregion Exports
