import React, { useMemo, useState } from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "../../../../../../components/empty-text/empty-text";
import { Pager } from "../../../../../../components/pager/pager";
import { ContextMenu } from "../../../../../../components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { AssignTrainingModal } from "../../../../../../components/user-management/assign-training-modal/assign-training-modal";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { t } from "utilities/localization/t";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { useParams } from "react-router-dom";
import { NumberUtils } from "utilities/number-utils";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    EnrollmentProgressStatusDisplayNames,
    EnrollmentProgressStatusStatusMap,
} from "models/enumerations/enrollments/enrollment-progress-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { ModalAction } from "components/modal/modal";
import { WithdrawEnrollmentModal } from "components/enrollments/withdraw-enrollment-modal/withdraw-enrollment-modal";
import { useUser } from "utilities/contexts/use-user-context";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./user-instructor-trainings.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserInstructorTrainingsProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-instructor-trainings";
const ITEMS_PER_PAGE = 10;
const TRAIN_THE_TRAINER = [TrainingType.TrainTheTrainer];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserInstructorTrainings: React.FC<UserInstructorTrainingsProps> = (
    props: UserInstructorTrainingsProps
): JSX.Element => {
    const { id: userIdParam } = useParams();
    const userId = useMemo(() => NumberUtils.parseInt(userIdParam) ?? 0, [userIdParam]);
    const { record: user } = useUser();

    const [currentPage, setCurrentPage] = useState<number>(1);

    const enrollmentDto = new CreateEnrollmentDtoRecord({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
    });

    const [enrollmentToWithdraw, setEnrollmentToWithdraw] = useState<EnrollmentRecord>(
        new EnrollmentRecord()
    );
    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                withdrawEnrollment(enrollmentToWithdraw);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const {
        enrollments,
        rowCount: enrollmentCount,
        addEnrollment,
        withdrawEnrollment,
    } = useUserEnrollments({
        userId: userId,
        includeProduct: true,
        includeRegistrations: true,
        isWithdrawn: false,
        audienceType: AudienceType.EducationNetwork,
        trainingTypes: TRAIN_THE_TRAINER,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const [showAssignTrainingModal, setShowAssignTrainingModal] = useState<boolean>(false);

    const onShowAssignModalClick = () => {
        setShowAssignTrainingModal(true);
    };

    const requestWithdrawEnrollment = (e: EnrollmentRecord) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    const handleAddEnrollment = (product: ProductRecord) => {
        addEnrollment(enrollmentDto.with({ productId: product.id }));
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__required__header`}>
                <h3>{t("requiredTrainings")}</h3>
                <Button
                    onClick={onShowAssignModalClick}
                    size={ButtonSize.Small}
                    style={ButtonStyle.Primary}
                    text={t("assignTraining")}
                />
            </div>
            {enrollments?.length > 0 && (
                <>
                    <DataTable cssClassName="required-trainings">
                        <thead>
                            <tr>
                                <th className="training-name">{t("trainingName")}</th>
                                <th className="product">{t("product")}</th>
                                <th className="type">{t("type")}</th>
                                <th className="progress">{t("progress")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrollments.map((enrollment) => (
                                <tr key={enrollment.id}>
                                    <td className="training-name">{enrollment.product?.name}</td>
                                    <td className="product">
                                        {enrollment.product?.associatedProduct?.name}
                                    </td>
                                    <td>
                                        {enrollment.product != null && (
                                            <ProductBadge
                                                product={enrollment.product}
                                                event={enrollment.event}
                                                includeIcon={false}
                                                upperCase={false}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <EnumStatusBadge
                                            displayNames={EnrollmentProgressStatusDisplayNames}
                                            statusMap={EnrollmentProgressStatusStatusMap}
                                            value={enrollment.getProgressStatus()}
                                        />
                                    </td>
                                    <td className="-ellipsis">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                disabled={enrollment.isComplete()}
                                                onClick={() =>
                                                    requestWithdrawEnrollment(enrollment)
                                                }
                                                displayName={t("unassignTraining")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                    <div className={`${CSS_CLASS_NAME}__footer`}>
                        <Pager
                            currentPage={currentPage}
                            totalPages={enrollmentCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={enrollmentCount}
                        />
                    </div>
                </>
            )}

            {(enrollments == null || enrollments.length === 0) && (
                <EmptyText table spacing={EmptyTextSpacing.Small}>
                    {t("thisInstructorCurrentlyHasNoAssignedRequiredTrainings")}
                </EmptyText>
            )}

            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={user?.getFirstAndLastNameOrEmail() ?? ""}
            />

            <AssignTrainingModal
                addEnrollment={handleAddEnrollment}
                open={showAssignTrainingModal}
                setOpen={setShowAssignTrainingModal}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserInstructorTrainings };

// #endregion Exports
