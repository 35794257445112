import React from "react";
import { GroupRecord } from "models/view-models/groups/group-record";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { t } from "utilities/localization/t";
import "./group-detail-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupDetailHeaderProps {
    group: GroupRecord;
    onEditGroupClick?: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "group-detail-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupDetailHeader: React.FC<React.PropsWithChildren<GroupDetailHeaderProps>> = ({
    group,
    onEditGroupClick,
    children,
}): JSX.Element => {
    return (
        <>
            <HeaderBanner cssClassName={CSS_CLASS_NAME} title={group.name}>
                <Button
                    text={t("editGroup")}
                    onClick={onEditGroupClick}
                    size={ButtonSize.Medium}
                    style={ButtonStyle.TertiaryAlt}
                />
                {children}
            </HeaderBanner>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupDetailHeader };

// #endregion Exports
