import axios from "axios";
import { InstructorApplicationFileRecord } from "models/view-models/aen-applications/instructor-application-file-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "aenapplication/instructors/:instructorApplicationId/files";
const instructorFilesResourceType: typeof InstructorApplicationFileRecord =
    InstructorApplicationFileRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateInstructorApplicationFileService = (
    instructorApplicationFile: InstructorApplicationFileRecord,
    pathParams: InstructorApplicationFilePathParams
) => Promise<ServiceResponse<InstructorApplicationFileRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface InstructorApplicationFilePathParams {
    instructorApplicationId: number;
}

export interface ListInstructorApplicationFilesQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const InstructorApplicationFileService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateInstructorApplicationFileService } => {
        const { cancellablePromise } = useCancellablePromise();
        const serviceCreate: CreateInstructorApplicationFileService = (
            instructorApplicationFile: InstructorApplicationFileRecord,
            pathParams: InstructorApplicationFilePathParams
        ): Promise<ServiceResponse<InstructorApplicationFileRecord>> => {
            return axios
                .post(
                    baseEndpoint.replace(
                        ":instructorApplicationId",
                        pathParams.instructorApplicationId.toString()
                    ),
                    instructorApplicationFile
                )
                .then((r) => ServiceUtils.mapAxiosResponse(instructorFilesResourceType, r));
        };
        function create(
            instructorApplicationFile: InstructorApplicationFileRecord,
            pathParams: InstructorApplicationFilePathParams
        ): Promise<ServiceResponse<InstructorApplicationFileRecord>> {
            return cancellablePromise(
                serviceCreate(instructorApplicationFile, pathParams)
            ) as Promise<ServiceResponse<InstructorApplicationFileRecord>>;
        }
        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        InstructorApplicationFileRecord,
        InstructorApplicationFilePathParams,
        ListInstructorApplicationFilesQueryParams
    >(instructorFilesResourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationFileService };

// #endregion Exports
