import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ProviderApplicationFileType {
    Other = 0,
    TrainingSpace = 1,
    Organization = 2,
}

// #endregion Enum

// -----------------------------------------------------------------------------------------
// #region Maps
// -----------------------------------------------------------------------------------------

const ProviderApplicationFileTypeDisplayNames: Map<ProviderApplicationFileType, TranslatedCopy> = {
    [ProviderApplicationFileType.Other]: "other",
    [ProviderApplicationFileType.TrainingSpace]: "trainingSpace",
    [ProviderApplicationFileType.Organization]: "organization",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderApplicationFileType, ProviderApplicationFileTypeDisplayNames };

// #endregion Exports
