import { ServiceHookFactory } from "andculturecode-javascript-react";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "events/:id/content";
const resourceType: typeof ProductContentRecord = ProductContentRecord;

export interface ListEventContentsPathParams {
    id: number;
}

export interface ListEventContentsQueryParams {
    productContentAccess?: ProductContentAccess;
}

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventContentService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        ProductContentRecord,
        ListEventContentsPathParams,
        ListEventContentsQueryParams
    >(resourceType, baseEndpoint),
};


// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContentService };

// #endregion Exports
