import { RouteUtils } from "utilities/route-utils";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icons } from "components/icons/constants/icons";
import { DataTable } from "components/tables/data-table/data-table";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import {
    EventDayStatusDisplayNames,
    EventDayStatusStatusMap,
} from "models/enumerations/events/event-day-status";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import React, { useCallback, useEffect, useState } from "react";
import { EventDayService } from "utilities/services/events/event-day-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { t } from "utilities/localization/t";
import "./event-attendance-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAttendanceSummaryProps {
    eventId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-attendance-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAttendanceSummary: React.FC<EventAttendanceSummaryProps> = (
    props: EventAttendanceSummaryProps
): JSX.Element => {
    const { list: listEventDay } = EventDayService.useList();
    const [eventdays, setEventDay] = useState<EventDayRecord[]>([]);
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const fetchData = useCallback(async () => {
        try {
            const listEventDayAttendanceResponse = await listEventDay({
                eventId: props.eventId,
                includeAttendance: true,
            });

            if (
                listEventDayAttendanceResponse?.resultObjects == null ||
                listEventDayAttendanceResponse.results == null ||
                listEventDayAttendanceResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            const eventDays = listEventDayAttendanceResponse.resultObjects;
            eventDays!.sort((a: EventDayRecord, b: EventDayRecord) => {
                return new Date(a.eventDate()).getTime() - new Date(b.eventDate()).getTime();
            });

            setEventDay(eventDays);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheScheduleAttendance"));
            setEventDay([]);
        }
    }, [listEventDay, props.eventId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getEventDayAttendancePath = (eventDay: EventDayRecord): string => {
        return RouteUtils.replacePathParams(viewEventRoutes.attendanceDay, {
            eventDayId: eventDay.id,
            id: props.eventId,
        });
    };

    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="date">{t("date")}</th>
                    <th className="status">{t("status")}</th>
                    <th className="summary">{t("participantSummary")}</th>
                    <th className="action">
                        <span className="sr-only">{t("action")}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {eventdays.map((eventDay) => (
                    <tr key={eventDay.id}>
                        <td className="date">{eventDay.getEventDateText()}</td>
                        <td className="status">
                            <EnumStatusBadge
                                displayNames={EventDayStatusDisplayNames}
                                statusMap={EventDayStatusStatusMap}
                                value={eventDay.status!}
                            />
                        </td>
                        <td className="summary">{eventDay.getAttendanceSummary()}</td>
                        <td className="action">
                            {
                                // if
                                eventDay.eventDayHasStarted() && (
                                    <AnchorIcon
                                        ariaLabel={t("edit")}
                                        iconType={Icons.Edit}
                                        path={getEventDayAttendancePath(eventDay)}
                                    />
                                )
                            }
                            {
                                // if
                                !eventDay.eventDayHasStarted() && (
                                    <>
                                        {t("attendanceTrackingIsNotAvailableUntilTheDayOfTheEvent")}
                                    </>
                                )
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAttendanceSummary };

// #endregion Exports
