import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventActivationContactInformation } from "components/events/event-activation-modal/event-activation-contact-information/event-activation-contact-information";
import { EventActivationContractSection } from "components/events/event-activation-modal/event-activation-contract-section/event-activation-contract-section";
import { EventActivationDetailsSection } from "components/events/event-activation-modal/event-activation-details-section/event-activation-details-section";
import { EventActivationInstructorSection } from "components/events/event-activation-modal/event-activation-instructor-section/event-activation-instructor-section";
import { EventActivationLocationSection } from "components/events/event-activation-modal/event-activation-location-section/event-activation-location-section";
import { EventActivationOrganizationSection } from "components/events/event-activation-modal/event-activation-organization-section/event-activation-organization-section";
import { EventActivationProductSection } from "components/events/event-activation-modal/event-activation-product-section/event-activation-product-section";
import { EventActivationProviderSection } from "components/events/event-activation-modal/event-activation-provider-section/event-activation-provider-section";
import { EventActivationRegistrationSection } from "components/events/event-activation-modal/event-activation-registration-section/event-activation-registration-section";
import { EventActivationCEUSection } from "./event-activation-ceu-section/event-activation-ceu-section";
import { EventActivationScheduleSection } from "components/events/event-activation-modal/event-activation-schedule-section/event-activation-schedule-section";
import { EventType } from "models/enumerations/events/event-type";
import { Modal, ModalAction } from "components/modal/modal";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-activation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationModalProps {
    event: EventActiveRecord;
    onModalClose: () => void;
    open: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationModal: React.FC<EventActivationModalProps> = (
    props: EventActivationModalProps
): JSX.Element => {
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: props.onModalClose,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("publishEvent"),
            onClick: () => handlePublishingEvent(),
            style: ButtonStyle.Primary,
        },
    ];

    const handlePublishingEvent = async () => {
        if (props.event.product?.deactivatedOn === null) {
            const pathTemplate = currentUserIsInNfpaRole
                ? sitemap.admin.event.edit.details
                : sitemap.admin.event.edit.contact;
            const path = RouteUtils.replacePathParams(pathTemplate, { id: props.event.id });

            navigate(path);
            props.event.publish();

            props.onModalClose();
        } else {
            ToastManager.error(
                t(
                    "theSelectedILTProductIsNoLongerAvailablePleaseSelectANewILTProductToPublishYourEvent"
                )
            );
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={props.onModalClose}
            title={t("publishingSummary")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <EventActivationDetailsSection event={props.event} />
                {props.event.type === EventType.Contract && (
                    <>
                        <EventActivationContractSection event={props.event} />
                        <EventActivationOrganizationSection event={props.event} />
                    </>
                )}
                {props.event.type === EventType.EducationNetwork && (
                    <EventActivationProviderSection event={props.event} />
                )}
                <EventActivationProductSection event={props.event} />
                <EventActivationContactInformation event={props.event} />
                <EventActivationScheduleSection event={props.event} />
                <EventActivationCEUSection event={props.event} />
                <EventActivationLocationSection event={props.event} />
                <EventActivationRegistrationSection event={props.event} />
                <EventActivationInstructorSection event={props.event} />
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationModal };

// #endregion Exports
