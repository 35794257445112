import ReportSection from "components/reports/components/report-section/report-section";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { ToastManager } from "utilities/toast/toast-manager";
import { UserRecord } from "models/view-models/users/user-record";
import { UserService, UserIndexQueryParams } from "utilities/services/users/user-service";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportUserSelectionProps {
    isRequired?: boolean;
    users: UserRecord[];
    onUsersChanged: (Users: UserRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEFAULT_USER_TAKE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportUserSelection: React.FC<ReportUserSelectionProps> = ({
    isRequired = false,
    users,
    onUsersChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchUsers, setSearchUsers] = useState<UserRecord[]>([]);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [allowPaging, setAllowPaging] = useState(false);

    const { list: listUsers } = UserService.useList();

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const userIndexQueryParams: UserIndexQueryParams = {
                includeRoles: false,
                searchText,
                roleTypes: [RoleType.Learner],
                skip: skip * DEFAULT_USER_TAKE,
                take: DEFAULT_USER_TAKE,
            };

            const listFilteredUsersResponse = await listUsers(userIndexQueryParams);
            const listFilteredUserResults = listFilteredUsersResponse?.results;
            const listedUsers = listFilteredUsersResponse?.resultObjects;
            if (
                listedUsers == null ||
                listFilteredUserResults == null ||
                listFilteredUserResults.hasErrors()
            ) {
                throw new Error();
            }

            if (skip === 0) {
                setSearchUsers([]); //clear out the list!
            }

            setSearchUsers((prev) => [...prev, ...listedUsers]);

            if (listedUsers.length > 0) {
                setAllowPaging(true);
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingLearners"));
            setSearchUsers([]);
        }
        setLoading(false);
    }, [listUsers, searchText, skip]);

    const handleSearch = useCallback((newSearch: string) => {
        setSearchText(newSearch);
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            fetchUsers();
        }
    }, [fetchUsers, isModalOpen, searchText, skip]);

    const handleRemoveUser = useCallback(
        (User: UserRecord) => {
            onUsersChanged(users.filter((c) => c.id !== User.id));
        },
        [onUsersChanged, users]
    );

    const handleUserSelectionChange = (selectedUsers: UserRecord[]) => {
        onUsersChanged(selectedUsers);
    };

    const handleAddUsers = () => {
        //setting ismodalopen to true will trigger the useEffect to fetch users
        //DO NOT Call FetchUsers here. It will be called in the useEffect
        setSkip(0);
        setSearchUsers([]);
        setSearchText("");
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectLearners")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddUsers}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addLearners")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(users) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="user-name">{"Test" + t("learnerName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, idx) => (
                                <tr key={`${user.id}-${idx}`}>
                                    <td className="user-name">
                                        <span>{user.getFirstAndLastName()}</span>
                                        <span>
                                            <div>
                                                <Paragraph
                                                    cssClassName={`__user-email`}
                                                    size={ParagraphSize.Small}>
                                                    {user.email}
                                                </Paragraph>
                                            </div>
                                        </span>
                                    </td>
                                    <td className="id">{user.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveUser(user)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>
            <SearchSelectionModal
                isOpen={isModalOpen}
                loading={loading}
                onClose={() => {
                    setSkip(0);
                    setSearchUsers([]);
                    setIsModalOpen(false);
                    setAllowPaging(false);
                }}
                onSearch={handleSearch}
                onSelectionChanged={handleUserSelectionChange}
                searchValues={searchUsers}
                selectedValues={users}
                setSearchValues={setSearchUsers}
                setSkip={setSkip}
                title={t("addALearner")}
                itemName={t("learnerName")}
                searchPlaceholderText={t("typeToSearchByNameOrId")}
                valuesDescription={t("learners")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: `${value.getFirstAndLastNameOrEmail()}`,
                })}
                allowPaging={allowPaging}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportUserSelection;

// #endregion Exports
