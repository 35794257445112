import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./aen-application-detail-page-section.scss";

const CSS_CLASS_NAME = "aen-application-detail-page-section";

interface AenApplicationDetailPageSectionProps {
    headingText: TranslatedCopy | string;
}
const AenApplicationDetailPageSection: React.FC<
    React.PropsWithChildren<AenApplicationDetailPageSectionProps>
> = ({ headingText, children }) => {
    return (
        <section className={`${CSS_CLASS_NAME}__section`}>
            <Heading
                priority={HeadingPriority.H3}
                cssClassName={`${CSS_CLASS_NAME}__sectionHeading`}
            >
                {headingText}
            </Heading>
            {children}
        </section>
    );
};

export default AenApplicationDetailPageSection;
