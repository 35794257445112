import React from "react";
import { EventType } from "models/enumerations/events/event-type";
import "./radio-button-group.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RadioButtonGroupOption {
    id: string;
    index: number;
    salesType?: EventType;
    text: string;
}
export interface RadioButtonGroupProps {
    ariaLabelledBy?: string;
    first?: boolean;
    last?: boolean;
    onClick: () => void;
    selected: boolean;
    option: RadioButtonGroupOption;
}

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "radio-button-group";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
    return (
        <button
            className={`${CSS_CLASS_NAME} ${props.first ? "-first" : ""} ${
                props.last ? "-last" : ""
            } `}
            onClick={props.onClick}>
            <input
                className={`${CSS_CLASS_NAME}__radio`}
                checked={props.selected}
                onChange={() => {
                    /* Add onChange to to suppress React Warnings. Click is handled on div. */
                }}
                type="radio"
                value={props.option.text}
                tabIndex={-1}
            />
            <label className={`${CSS_CLASS_NAME}__text`} htmlFor={props.option.id}>
                {props.option.text}
            </label>
        </button>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RadioButtonGroup };

// #endregion Exports
