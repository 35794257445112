import React, { useCallback, useMemo, useState } from "react";
import { AdminLayoutHeaderProfileSelector } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-profile-manager/admin-layout-header-profile-selector/admin-layout-header-profile-selector";
import { AdminLayoutHeaderProfile } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-profile-manager/admin-layout-header-profile/admin-layout-header-profile";
import { Transition } from "@headlessui/react";
import "./admin-layout-header-profile-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderProfileManagerProps {
    containerIsOpen?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-profile-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderProfileManager: React.FC<AdminLayoutHeaderProfileManagerProps> = (
    props: AdminLayoutHeaderProfileManagerProps
): JSX.Element => {
    const [selectorIsOpen, setSelectorIsOpen] = useState<boolean>(false);
    const isOpen: boolean = useMemo(
        (): boolean => props.containerIsOpen === true && selectorIsOpen,
        [props.containerIsOpen, selectorIsOpen]
    );

    const closeProfileSelector = useCallback((): void => {
        setSelectorIsOpen(false);
    }, []);

    const openProfileSelector = useCallback((): void => {
        setSelectorIsOpen(true);
    }, []);

    return (
        <>
            <AdminLayoutHeaderProfile openProfileSelector={openProfileSelector} />
            <Transition.Root show={isOpen} as={React.Fragment}>
                <Transition.Child
                    as="div"
                    enter="transition-all ease-out duration-300"
                    enterFrom="w-0"
                    enterTo="w-[320px]"
                    leave="transition-all ease-out duration-300"
                    leaveFrom="w-[320px]"
                    leaveTo="w-0"
                    className={`${CSS_CLASS_NAME}__transition`}>
                    <AdminLayoutHeaderProfileSelector
                        closeProfileSelector={closeProfileSelector}
                        isOpen={selectorIsOpen}
                    />
                </Transition.Child>
            </Transition.Root>
        </>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderProfileManager };

// #endregion Exports
