import React from "react";
import { t } from "utilities/localization/t";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { Card } from "components/card/card";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import "./provider-status-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderStatusCardProps {
    provider?: ProviderRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "provider-status-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderStatusCard: React.FC<ProviderStatusCardProps> = ({ provider }): JSX.Element => {
    return (
        <Card cssClassName={`${CSS_CLASS_NAME}`}>
            <Paragraph size={ParagraphSize.Large}>
                {t("organizationStatus")}{" "}
                <Badge
                    style={provider?.isActive ? BadgeStyle.Success : BadgeStyle.Warning}
                    text={provider?.isActive ? t("active") : t("inactive")}
                />
            </Paragraph>
            <div className={`${CSS_CLASS_NAME}__columns`}>
                <div className={`${CSS_CLASS_NAME}__columns__info`}>
                    <Paragraph style={ParagraphStyle.Light}>
                        {t(
                            "theStatusAsAnActiveAenProviderIsContingentUponTheOrganizationsComplianceWithTheAenContractTimelyPaymentsAndFavorableCustomerEvaluations"
                        )}
                    </Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__columns__fields`}>
                    <FormFieldReadonly label={t("organization").toLocaleUpperCase()}>
                        <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                            {provider?.name}
                        </Paragraph>
                    </FormFieldReadonly>
                    <FormFieldReadonly label={t("yourRole")}>
                        <Paragraph size={ParagraphSize.Small} style={ParagraphStyle.Light}>
                            {t("enProviderAdmin")}
                        </Paragraph>
                    </FormFieldReadonly>
                </div>
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderStatusCard };

// #endregion Exports
