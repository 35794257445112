import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces, Types
// -------------------------------------------------------------------------------------------------

type UseErrorMessageStateHook = [Error | undefined, (message: string) => void];

interface UseErrorMessageStateHookOptions {
    onError?: (error: Error) => void;
}

// #endregion Interfaces, Types

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useErrorMessageState({
    onError,
}: UseErrorMessageStateHookOptions): UseErrorMessageStateHook {
    const [error, setError] = useState<Error>();

    return [
        error,
        useCallback(
            (message?: string) => {
                const error = new Error(message);

                setError(error);
                onError?.(error);
            },
            [onError]
        ),
    ];
}

// #endregion Hook
