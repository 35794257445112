import { ContractRecord } from "models/view-models/contracts/contract-record";
import {
    ContractService,
    GetContractPathParams,
    GetContractQueryParams,
    UpdateContractPathParams,
} from "utilities/services/contracts/contract-service";
import { NumberUtils } from "utilities/number-utils";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

interface UseContractHook {
    contract: ContractRecord;
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    updateContract: (contract: ContractRecord) => Promise<void>;
}

interface UseContractHookOptions {
    contractId?: number | string;
    includeEvent?: boolean;
    includeOrganization?: boolean;
    includeProduct?: boolean;
}

export function useContract(props: UseContractHookOptions): UseContractHook {
    const includeEvent = props.includeEvent ?? false;
    const includeProduct = props.includeProduct ?? false;
    const includeOrganization = props.includeOrganization ?? false;
    const [isLoading, setIsLoading] = useState(true);
    const [contract, setContract] = useState<ContractRecord>(new ContractRecord());
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { get: getContractAPI } = ContractService.useGet();
    const { update: updateContractAPI } = ContractService.useUpdate();
    const contractId = NumberUtils.parseInt(props.contractId) ?? 0;

    const fetchData = useCallback(async (): Promise<void> => {
        if (isNaN(contractId) || contractId < 1) {
            return;
        }

        try {
            const pathParams: GetContractPathParams = {
                id: contractId,
            };

            const queryParams: GetContractQueryParams = {
                includeEvent: includeEvent,
                includeProduct: includeProduct,
                includeOrganization: includeOrganization,
            };

            const { result: resultContract } = await getContractAPI(pathParams, queryParams);

            setIsLoading(false);

            if (resultContract?.hasErrors()) {
                const { errors: contractErrors = [] } = resultContract ?? {};
                setErrors([...contractErrors]);
                return;
            }

            const contract: ContractRecord = resultContract?.resultObject;
            setContract(contract);
        } catch (err) {
            ToastManager.error(t("thereWasAProblemLoadingTheContract"));
            setIsLoading(false);
            setErrors([
                new ResultErrorRecord({
                    message: `${err}`,
                }),
            ]);
        }
    }, [contractId, includeEvent, includeProduct, includeOrganization, getContractAPI]);

    const updateContract = async (contract: ContractRecord): Promise<void> => {
        try {
            const updateContractPathParams: UpdateContractPathParams = {
                id: contract.id!,
            };

            const updateContractResponse = await updateContractAPI(
                contract,
                updateContractPathParams
            );
            const updateResult = updateContractResponse?.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            fetchData();
        } catch {
            ToastManager.error(t("thereWasAProblemUpdatingTheContract"));
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        contract,
        updateContract,
    };
}
