import React, { useCallback, useState } from "react";
import { AddBulkEnrollmentForm } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-form/add-bulk-enrollment-form";
import { AddBulkEnrollmentModalStage } from "models/enumerations/enrollments/add-bulk-enrollment-modal-stage";
import { AddBulkEnrollmentPreview } from "./add-bulk-enrollment-preview/add-bulk-enrollment-preview";
import { AddBulkEnrollmentStatusForm } from "./add-bulk-enrollment-status-form/add-bulk-enrollment-status-form";
import { BulkContractEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-contract-enrollment-summary-record";
import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { ButtonStyle } from "components/buttons/button/button";
import { CreateBulkEnrollmentDtoRecord } from "models/view-models/enrollments/create-bulk-enrollment-params-record";
import { CollectionUtils } from "utilities/collection-utils";
import { EncodingType } from "models/enumerations/encoding/encoding-type";
import {
    EnrollmentService,
    GetCSVPreviewPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { EnrollmentUserRecord } from "models/view-models/enrollments/enrollment-user-record";
import { FileRecord } from "models/view-models/files/file-record";
import { FileUtils } from "utilities/files/file-utils";
import { Modal, ModalAction } from "components/modal/modal";
import { ResultRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./add-bulk-enrollment-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddBulkEnrollmentModalProps {
    createBulkEnrollmentDto?: CreateBulkEnrollmentDtoRecord;
    handleSaveBulkEnrollment: (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ) => Promise<ResultRecord<BulkEnrollmentSummaryRecord | BulkContractEnrollmentSummaryRecord>>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constant
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-bulk-enrollment-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentModal: React.FC<AddBulkEnrollmentModalProps> = (
    props: AddBulkEnrollmentModalProps
): JSX.Element => {
    let initialEnrollment = props.createBulkEnrollmentDto ?? new CreateBulkEnrollmentDtoRecord();
    const encoding: EncodingType = FileUtils.getEncodingType();
    const [createBulkEnrollmentDto, setCreateBulkEnrollmentDto] = useState(initialEnrollment);
    const [fileId, setFileId] = useState<number>(0);
    const [validEnrollmentUsers, setValidEnrollmentUsers] = useState<EnrollmentUserRecord[]>([]);
    const [invalidEnrollmentUsers, setInvalidEnrollmentUsers] = useState<EnrollmentUserRecord[]>(
        []
    );

    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [bulkEnrollmentResult, setBulkEnrollmentResult] = useState<
        ResultRecord<BulkEnrollmentSummaryRecord>
    >(new ResultRecord<BulkEnrollmentSummaryRecord>());
    const [currentStage, setCurrentStage] = useState(AddBulkEnrollmentModalStage.Upload);

    const { list: csvPreviewApi } = EnrollmentService.useGetCSVPreview();

    const afterUploadStageModalActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("done"),
            disabled: currentStage === AddBulkEnrollmentModalStage.Processing,
            onClick: () => {
                props.setOpen(false);
                setTimeout(() => {
                    setCurrentStage(AddBulkEnrollmentModalStage.Upload);
                }, 500);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                setShowConfirmationModal(false);
                props.setOpen(false);
                resetForm();
                setTimeout(() => {
                    setCurrentStage(AddBulkEnrollmentModalStage.Upload);
                }, 500);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const previewStageModalActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveValidAttendees"),
            onClick: () => onEnrollmentUpload(),
            style: ButtonStyle.Primary,
        },
    ];

    const uploadStageModalActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("upload"),
            onClick: () => {
                getCSVPreview();
                setCurrentStage(AddBulkEnrollmentModalStage.Preview);
            },
            disabled: createBulkEnrollmentDto == null || fileId == null || fileId === 0,
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setCreateBulkEnrollmentDto(initialEnrollment);
        setInvalidEnrollmentUsers([]);
        setValidEnrollmentUsers([]);
        setFileId(0);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onEnrollmentUpload = async (): Promise<void> => {
        setCurrentStage(AddBulkEnrollmentModalStage.Processing);

        var result = await props.handleSaveBulkEnrollment(createBulkEnrollmentDto);

        resetForm();

        const succeeded = result?.resultObject != null && !result.hasErrors();
        const nextStage = succeeded
            ? AddBulkEnrollmentModalStage.Complete
            : AddBulkEnrollmentModalStage.Errored;
        setCurrentStage(nextStage);
        setBulkEnrollmentResult(result);
    };

    const onBulkEnrollmentChanged = (
        createBulkEnrollmentDto: CreateBulkEnrollmentDtoRecord
    ): void => {
        setDirty(true);
        setCreateBulkEnrollmentDto(createBulkEnrollmentDto);
    };

    const handleSetFileId = (file: FileRecord) => {
        if (file.id === undefined) {
            return;
        }
        setFileId(file.id);
    };

    const validateCsvFile = (file: File): boolean => {
        const fileExtensionValid: boolean = file.name.endsWith(".csv");

        return fileExtensionValid;
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("youMustUploadACSVFile"));
    };

    const getCSVPreview = useCallback(async (): Promise<void> => {
        try {
            const csvPreviewPathParams: GetCSVPreviewPathParams = {
                fileId: fileId,
                encodingType: encoding,
            };
            const enrollmentUserResult = await csvPreviewApi(csvPreviewPathParams);
            if (
                enrollmentUserResult.result?.hasErrors() ||
                enrollmentUserResult.resultObjects == null
            ) {
                return;
            }

            const parsedEnrollmentUsers = enrollmentUserResult.resultObjects;

            const validUsers = parsedEnrollmentUsers
                .filter((enrollmentUser) => !CollectionUtils.hasValues(enrollmentUser.errors))
                .sort((a, b) => a.email!.localeCompare(b.email!));

            const invalidUsers = parsedEnrollmentUsers
                .filter((enrollmentUser) => CollectionUtils.hasValues(enrollmentUser.errors))
                .sort((a, b) => a.email!.localeCompare(b.email!));

            setValidEnrollmentUsers(validUsers);
            setInvalidEnrollmentUsers(invalidUsers);

            onBulkEnrollmentChanged(
                createBulkEnrollmentDto.with({
                    enrollmentUsers: validUsers,
                })
            );

            setCurrentStage(AddBulkEnrollmentModalStage.Preview);
        } catch {
            ToastManager.error(t("errorParsingCSV"));
        }
    }, [createBulkEnrollmentDto, csvPreviewApi, encoding, fileId]);

    const modalActions = () => {
        switch (currentStage) {
            case AddBulkEnrollmentModalStage.Upload:
                return uploadStageModalActions;
            case AddBulkEnrollmentModalStage.Preview:
                return previewStageModalActions;
            case AddBulkEnrollmentModalStage.Processing:
                return afterUploadStageModalActions;
            default:
                return afterUploadStageModalActions;
        }
    };

    const modalBody = () => {
        switch (currentStage) {
            case AddBulkEnrollmentModalStage.Upload:
                return (
                    <AddBulkEnrollmentForm
                        fileId={fileId}
                        handleInvalidFile={handleInvalidFile}
                        onFileIdChange={handleSetFileId}
                        validateCsvFile={validateCsvFile}
                    />
                );
            case AddBulkEnrollmentModalStage.Preview:
                return (
                    <AddBulkEnrollmentPreview
                        validEnrollmentUsers={validEnrollmentUsers}
                        invalidEnrollmentUsers={invalidEnrollmentUsers}
                    />
                );
            default:
                return (
                    <AddBulkEnrollmentStatusForm
                        bulkEnrollmentResult={bulkEnrollmentResult!}
                        stage={currentStage}
                    />
                );
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("importEnrollmentCSV")}
            actions={modalActions()}
            modalStyle={""}>
            {modalBody()}
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActions}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentModal };

// #endregion Exports
