import FeatureFlags from "models/interfaces/feature-flags/feature-flags";
import { RouteUtils } from "utilities/route-utils";

// -----------------------------------------------------------------------------------------
// #region Public Methods
// -----------------------------------------------------------------------------------------

/**
 * Get a FeatureFlag object based on any object and an array of allowed feature flag keys
 */
function fromObject(
    queryStringObject: Record<string, any>,
    allowedFeatureFlagKeys: string[]
): FeatureFlags {
    let urlFeatureFlags: FeatureFlags = {};

    for (const urlKey in queryStringObject) {
        if (allowedFeatureFlagKeys.includes(urlKey)) {
            urlFeatureFlags = {
                ...urlFeatureFlags,
                [urlKey]: isFlagTrue(queryStringObject[urlKey]),
            };
        }
    }

    return urlFeatureFlags;
}

/**
 * Get a FeatureFlag object based on the url querystring and an array of allowed feature flag keys
 */
const fromUrl = (queryString: string, allowedFeatureFlagKeys: string[]): FeatureFlags =>
    fromObject(
        RouteUtils.queryStringToObject<Record<string, any>>(queryString),
        allowedFeatureFlagKeys
    );

// #endregion Public Methods

// -----------------------------------------------------------------------------------------
// #region Private Methods
// -----------------------------------------------------------------------------------------

function isFlagTrue(value: any): boolean {
    // A url flag without an assigned value is assumed to be true
    if (value == null) {
        return true;
    }

    if (typeof value === "boolean") {
        return value;
    }

    return value === 1;
}

// #endregion Private Methods

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const FeatureFlagUtils = {
    fromUrl,
    fromObject,
};

export { FeatureFlagUtils };

// #endregion Exports
