import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { SystemSettingsRecord } from "models/view-models/system-settings-record";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SystemSettingsBasePathParams {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "systemsettings";
const key = "systemsettings";
const resourceType = SystemSettingsRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const SystemSettingsService = {
    key,

    /**
     * Retrieve the SystemSettings object
     */
    get: ServiceFactory.get<SystemSettingsRecord, SystemSettingsBasePathParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in react components
     */
    useGet: ServiceHookFactory.useGet<SystemSettingsRecord, SystemSettingsBasePathParams>(
        resourceType,
        baseEndpoint
    ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { SystemSettingsService };

// #endregion Exports
