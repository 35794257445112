import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { PatchCoursePathParams, CourseService } from "utilities/services/courses/course-service";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { CourseRecord } from "models/view-models/courses/course-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { RouteUtils } from "utilities/route-utils";
import "./course-unpublish-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseUnpublishModalProps {
    course: CourseRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-unpublish-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseUnpublishModal: React.FC<CourseUnpublishModalProps> = ({
    course,
    open,
    setOpen,
}): JSX.Element => {
    const { patch: unpublishAPI } = CourseService.useUnpublish();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("unpublishCourse"),
            disabled: course.status === ActiveStatus.Draft,
            onClick: () => unpublishCourse(),
            style: ButtonStyle.Primary,
        },
    ];

    const unpublishCourse = async (): Promise<void> => {
        if (course.id == null) {
            return;
        }
        setOpen(false);
        try {
            const unpublishPathParams: PatchCoursePathParams = {
                id: course.id,
            };

            const unpublishCourseResponse = await unpublishAPI(unpublishPathParams);

            const unpublishCourseResult = unpublishCourseResponse?.result;

            if (unpublishCourseResult?.resultObject == null || unpublishCourseResult.hasErrors()) {
                throw new Error();
            }
            const courseDetails = RouteUtils.replacePathParams(sitemap.admin.course.edit.details, {
                id: course.id,
                versionId: course.latestCourseVersionId,
            });

            window.location.href = RouteUtils.localizePath(courseDetails);

            ToastManager.success(t("unpublishedSuccessfully"));
        } catch {
            ToastManager.error(t("failedToUnpublish"));
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("unpublishCourse")}
            actions={modalActionArray}
            modalStyle={""}>
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__header`} size={ParagraphSize.Large}>
                {
                    "You are about to unpublish this course. There may be existing learners associated with this course and should only be done if you are sure that the changes will not affect Learners."
                }
            </Paragraph>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseUnpublishModal };

// #endregion Exports
