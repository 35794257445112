import { CommunicationRecord } from "models/view-models/communications/communication-record";
import { CommunicationService } from "utilities/services/communications/communication-service";
import { EventRecord } from "models/view-models/events/event-record";
import { GroupRecord } from "models/view-models/groups/group-record";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import { useCallback, useState } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseSendEmailRequestHook {
    email?: CommunicationRecord;
    errors?: ResultErrorRecord[];
    create(createOptions: UseSendEmailRequestHookCreateOptions): void;
    isLoading: boolean;
}

interface UseSendEmailRequestHookCreateOptions {
    bodyHtml?: string;
    subject?: string;
    events?: EventRecord[];
    groups?: GroupRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useSendEmailRequest(): UseSendEmailRequestHook {
    const [email, setEmail] = useState<CommunicationRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { create: sendEmail } = CommunicationService.useCreate();

    const create = useCallback(
        async (createOptions: UseSendEmailRequestHookCreateOptions) => {
            const { bodyHtml, subject, events, groups } = createOptions;
            const eventIds = events?.map((e) => e.id!);
            const groupIds = groups?.map((g) => g.id!);
            setIsLoading(true);
            try {
                const email = new CommunicationRecord({
                    body: bodyHtml,
                    subject,
                    eventIds,
                    groupIds,
                });

                const { result, resultObject } = await sendEmail(email);

                if (result?.hasErrors() || resultObject == null) {
                    throw new Error();
                }

                setEmail(email.with(resultObject.toJS()));
                setIsLoading(false);
            } catch {
                setErrors([
                    new ResultErrorRecord({
                        message: t("thereWasAProblemSendingTheEmail"),
                    }),
                ]);
                setIsLoading(false);
            }
        },
        [sendEmail]
    );

    return {
        errors,
        email,
        create,
        isLoading,
    };
}

// #endregion Hook
