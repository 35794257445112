import { Button, ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductSelectionModal } from "./product-selection-modal/product-selection-modal";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useState } from "react";
import { t } from "utilities/localization/t";
import "./product-selection-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductSelectionCardProps {
    product?: ProductRecord;
    onProductChange: (product?: ProductRecord) => void;
    trainingType?: TrainingType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-selection-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductSelectionCard: React.FC<ProductSelectionCardProps> = ({
    product,
    onProductChange,
    trainingType,
}) => {
    const [showModal, setShowModal] = useState(false);

    const handleProductChange = (product?: ProductRecord): void => {
        onProductChange(product);
    };
    return (
        <>
            <Card>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XSmall}>
                            {t("associatedProduct")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__actions`}>
                        <Button
                            disabled={product != null}
                            onClick={() => setShowModal(true)}
                            style={ButtonStyle.Primary}
                            text={t("selectProduct")}
                        />
                    </div>
                </div>

                <DataTable>
                    <thead>
                        <tr>
                            <th>{t("name")}</th>
                            <th>{t("id")}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product != null && (
                            <tr>
                                <td>{product?.name}</td>
                                <td>{product?.id}</td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            onClick={() => setShowModal(true)}
                                            displayName={t("replace")}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </DataTable>
                {product == null && (
                    <EmptyText spacing={EmptyTextSpacing.Small}>{t("noProductSelected")}</EmptyText>
                )}
            </Card>
            <ProductSelectionModal
                isContract={true}
                onProductChange={handleProductChange}
                open={showModal}
                selectedProduct={product}
                setOpen={setShowModal}
                trainingType={trainingType}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductSelectionCard };

// #endregion Exports
