import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { EventContext } from "utilities/contexts/use-event-context";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { EventTypeUserDisplayNames } from "models/enumerations/events/event-type";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import {
    ManageEventLayoutSidebar,
    SidebarNavItem,
} from "layouts/admin/manage-event/manage-event-layout-sidebar/manage-event-layout-sidebar";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, Location, useLocation, useParams } from "react-router-dom";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProviderContext } from "utilities/contexts/use-provider-context";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import {
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useEvent } from "utilities/hooks/models/events/use-event";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import "./manage-event-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageEventLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-event-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageEventLayout: React.FC<ManageEventLayoutProps> = (): JSX.Element => {
    useRedirectOnForbidden(sitemap.admin.event.list);
    const location: Location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams();
    const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);

    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });
    const isInstructor = useMemo(
        () => globalState?.currentIdentity?.role?.roleType === RoleType.Instructor,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const userId = useMemo(
        () => globalState?.currentIdentity?.user?.id,
        [globalState?.currentIdentity?.user?.id]
    );

    const { get: getProvider } = ProviderService.useGet();
    const [associatedProvider, setAssociatedProvider] = useState<ProviderRecord>(
        new ProviderRecord()
    );
    const loggedInUserProviderId = useMemo(
        () => globalState?.currentIdentity?.user?.providerId ?? -1,
        [globalState?.currentIdentity?.user?.providerId]
    );

    const fetchProvider = useCallback(async () => {
        try {
            const pathParams: ProviderResourcePathParams = {
                id: loggedInUserProviderId,
            };

            const providerResponse = await getProvider(pathParams);
            const providerResult = providerResponse?.result;

            if (providerResult?.resultObject == null || providerResult.hasErrors()) {
                throw new Error();
            }
            setAssociatedProvider(providerResult.resultObject);
        } catch {
            ToastManager.error(t("problemLoadingProvider"));
        }
    }, [getProvider, loggedInUserProviderId]);

    useEffect(() => {
        if (globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator)) {
            fetchProvider();
        }
    }, [fetchProvider, globalState.currentIdentity]);

    const { event, setEvent } = useEvent({
        eventId,
        includeCanceledBy: true,
        includeContract: true,
        includeEventSessions: true,
        includeEventScheduleException: true,
        includeInstructor: true,
        includeOrganization: true,
        includeProduct: true,
        includeProvider: true,
        includeChecklist: true,
    });

    const checklist = useMemo(() => event.eventChecklist, [event.eventChecklist]);

    const completionPercentage = useMemo(() => {
        if (!isInstructor) {
            return undefined;
        }

        let completionNumerator = 0.0;
        let completionDenominator = 0.0;
        let completionPercentage = 0.0;

        if (checklist?.assessmentResultsReviewed) {
            completionNumerator++;
        }
        if (checklist?.trainingContentReviewed) {
            completionNumerator++;
        }
        if (checklist?.evaluationResultsReviewed) {
            completionNumerator++;
        }
        completionDenominator = completionDenominator + 3;
        if (event?.eventDays != null) {
            for (var i = 0; i < event.eventDays.length; i++) {
                if (event.eventDays[i].status === EventDayStatus.Completed) completionNumerator++;
                completionDenominator++;
            }
        }

        if (completionDenominator !== 0.0 && completionNumerator !== 0.0) {
            completionPercentage = (completionNumerator / completionDenominator) * 100;
            completionPercentage = Math.round(completionPercentage);
        }
        return completionPercentage;
    }, [
        checklist?.assessmentResultsReviewed,
        checklist?.evaluationResultsReviewed,
        checklist?.trainingContentReviewed,
        event.eventDays,
        isInstructor,
    ]);

    const navItems: SidebarNavItem[] = useMemo(() => {
        if (eventId == null) {
            return [];
        }

        return [
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.details, {
                    id: eventId,
                }),

                displayName: t("about"),
                iconType: Icons.EventDetails,
            },
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.content, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("content"),
                iconType: Icons.Content,
            },
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.enrollment, {
                    id: eventId,
                }),
                disabled:
                    event.status === PublishStatus.Canceled || event.status === PublishStatus.Draft,
                displayName: t("enrollment"),
                iconType: Icons.Enrollment,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.attendanceSummary, {
                    id: eventId,
                }),
                disabled:
                    event.status === PublishStatus.Canceled || event.status === PublishStatus.Draft,
                displayName: t("attendance"),
                iconType: Icons.Attendance,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.assessments, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("assessments"),
                iconType: Icons.Assessment,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.evaluationSummary, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("evaluation"),
                iconType: Icons.Evaluation,
            },
        ];
    }, [
        event.status,
        eventId,
        viewEventRoutes.assessments,
        viewEventRoutes.attendanceSummary,
        viewEventRoutes.content,
        viewEventRoutes.details,
        viewEventRoutes.enrollment,
        viewEventRoutes.evaluationSummary,
    ]);

    const badges: string[] = [];

    // Badges
    if (event?.instructorLedTrainingType != null) {
        badges.push(t(InstructorLedTrainingTypeDisplayNames[event.instructorLedTrainingType]));
    }

    if (event?.product?.language != null) {
        badges.push(t(LanguageDisplayNames[event.product?.language]));
    }

    if (event?.getCeus() > 0) {
        badges.push(
            t("ceusEventProductCreditHoursToString", {
                eventProductCreditHoursToString: event?.getCeus(),
            })
        );
    }

    if (event.type != null) {
        badges.push(t(EventTypeUserDisplayNames[event.type!]));
    }

    useEffect(() => {
        const detailsPath = RouteUtils.replacePathParams(viewEventRoutes.details, {
            id: eventId,
        });

        const enrollmentPath = RouteUtils.replacePathParams(viewEventRoutes.enrollment, {
            id: eventId,
        });

        const attendancePath = RouteUtils.replacePathParams(viewEventRoutes.attendanceSummary, {
            id: eventId,
        });

        if (isInstructor && event.id != null && event.instructorId !== userId) {
            navigate(sitemap.public.noAccess);
        }

        if (event.status === PublishStatus.Canceled && location.pathname !== detailsPath) {
            navigate(detailsPath);
        }

        if (
            event.status === PublishStatus.Draft &&
            (location.pathname === enrollmentPath || location.pathname === attendancePath)
        ) {
            navigate(detailsPath);
        }
    }, [
        event.id,
        event.instructorId,
        event.status,
        eventId,
        isInstructor,
        location.pathname,
        navigate,
        userId,
        viewEventRoutes.attendanceSummary,
        viewEventRoutes.details,
        viewEventRoutes.enrollment,
    ]);

    const showFixedBanner = event.status === PublishStatus.Draft;

    return (
        <EventContext.Provider value={{ record: event, setRecord: setEvent }}>
            <ProviderContext.Provider
                value={{ record: associatedProvider, setRecord: setAssociatedProvider }}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__window`}>
                        <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                            <ManageEventLayoutSidebar
                                event={event}
                                badges={badges}
                                completionPercentage={completionPercentage}
                                title={event?.product?.name ?? t("noProductAssigned")}
                                subTitle={event?.name}
                                titleEmphasis={
                                    event.productId == null ? ParagraphEmphasis.Italic : undefined
                                }
                                trainingType={TrainingType.InstructorLedTraining}
                                navItems={navItems}
                            />
                        </div>
                        <div
                            className={`${CSS_CLASS_NAME}__window__main ${
                                showFixedBanner ? "-has-fixed-banner" : ""
                            }`}>
                            {" "}
                            {event.status === PublishStatus.Draft && (
                                <Banner
                                    style={BannerStyle.LightInverted}
                                    format={BannerFormat.Fixed}>
                                    <div className="content-wrap">
                                        <div className="content">
                                            <div className="text">
                                                <Paragraph
                                                    style={ParagraphStyle.Default}
                                                    size={ParagraphSize.Small}>
                                                    <strong>
                                                        {t("youAreViewingADraftEvent")}{" "}
                                                    </strong>
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </div>
                                </Banner>
                            )}
                            <SkipNavContent>
                                <div className={`${CSS_CLASS_NAME}__body`}>
                                    <Outlet />
                                </div>
                            </SkipNavContent>
                        </div>
                    </div>
                </div>
            </ProviderContext.Provider>
        </EventContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageEventLayout };

// #endregion Exports
