import React, { ReactNode, useState } from "react";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";
import { ButtonStyle } from "components/buttons/button/button";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { NumberUtils } from "utilities/number-utils";
import { SelectOption } from "components/form/inputs/select/select";
import { ToastManager } from "utilities/toast/toast-manager";
import { StringUtils } from "utilities/string-utils";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./update-progress-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UpdateProgressModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    statusBadge?: ReactNode;
    onAttemptSave?: (attempt: AttemptRecord) => void;
}

// #endregion Interfaces
// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "update-progress-modal";
const ATTEMPT_SUCCESS_OPTIONS: SelectOption[] = [
    { value: AttemptSuccessStatus.Passed.toString(), text: "passed" },
    { value: AttemptSuccessStatus.Failed.toString(), text: "failed" },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UpdateProgressModal: React.FC<UpdateProgressModalProps> = ({
    open,
    setOpen,
    statusBadge,
    onAttemptSave,
}): JSX.Element => {
    const [initialAttempt, setInitialAttempt] = useState<AttemptRecord>(new AttemptRecord({}));
    const [attempt, setAttempt] = useState<AttemptRecord>(initialAttempt);
    const [dirty, setDirty] = useState(false);

    // TODO: NFPA-8278 Remove or Implement
    // const [newStatus, setNewStatus] = useState("");

    // let statusOptions: SelectOption[] = [
    //     { text: "Not Started", value: "1" },
    //     { text: "In Progress", value: "2" },
    //     { text: "Completed", value: "3" },
    // ];

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled:
                attempt.successStatus == null ||
                attempt.score == null ||
                !StringUtils.hasValue(attempt.manualEntryNotes) ||
                attempt.completionDate == null,
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        // TODO: NFPA-8278 Remove or Implement
        //setNewStatus("");
        setAttempt(initialAttempt);
        setDirty(false);
    };

    const onModalClose = (): void => {
        resetForm();
        setOpen(false);
    };

    const handleSave = async (): Promise<void> => {
        if (await onAttemptSave?.(attempt)) {
            onModalClose();
        } else {
            ToastManager.error(t("failedToSaveAttempt"));
        }
    };

    // TODO: NFPA-8278 Remove or Implement
    //     const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     setNewStatus(e.target.value);
    // };

    const updateAttempt = (values: Partial<AttemptRecord>): void => {
        setAttempt(attempt.with(values));
        setDirty(true);
    };

    const handleSuccessChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newSuccessStatus = EnumUtils.enumToObjectPartial(
            event.target.value,
            (value) => ({ successStatus: Number(value) }),
            AttemptSuccessStatus
        );

        updateAttempt({ successStatus: newSuccessStatus.successStatus });
    };

    const handleManualEntryNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateAttempt({ manualEntryNotes: event.target.value });
    };

    const handleScoreChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const score = NumberUtils.parseInt(event.target.value);

        if (score !== undefined && (score < 0 || score > 100)) {
            return;
        }

        updateAttempt({ score: NumberUtils.parseInt(score) });
    };

    const handleCompletionDateChange = (date?: Date): void => {
        updateAttempt({ completionDate: date });
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("addAttempt")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__wrapper`}>
                {/* <FormFieldReadonly label={t("currentStatus")} labelStyle={ParagraphStyle.Default}>
                    {statusBadge}
                </FormFieldReadonly> */}
                {/* TODO: NFPA-8278 Remove or Implement
                <FormSelect
                    ariaLabelledBy={t("changeStatusTo")}
                    autoFocus={true}
                    formFieldName={t("changeStatusTo")}
                    id="changeStatusTo"
                    label={t("changeStatusTo")}
                    onChange={onStatusChange}
                    options={statusOptions}
                    required={true}
                    value={newStatus}
                />
                {newStatus === "3" && ( */}
                <div className={`${CSS_CLASS_NAME}`}>
                    {/* TODO: NFPA-8278 Remove or Implement
                    <Paragraph style={ParagraphStyle.Light}>
                        To save this status update, please enter the assessment score and result.
                    </Paragraph>
                    */}
                    <Paragraph style={ParagraphStyle.Light}>
                        {t("savingAnAttemptMayResultInAnUpdateToTheLearnersProgress")}
                    </Paragraph>

                    <FormTextInput
                        ariaLabelledBy={t("assessmentScore")}
                        formFieldName="assessmentScore"
                        id="assessmentScore"
                        label={t("assessmentScore")}
                        maxNumber={100}
                        minValue={0}
                        onChange={handleScoreChange}
                        value={attempt.score ?? ""}
                        placeholder="00"
                        required={true}
                        type={InputTypes.Number}
                    />
                    <FormSelect
                        ariaLabelledBy={t("result")}
                        formFieldName="result"
                        id="result"
                        label={t("result")}
                        onChange={handleSuccessChange}
                        value={attempt.successStatus?.toString()}
                        options={ATTEMPT_SUCCESS_OPTIONS}
                        required={true}
                    />
                    <FormCalendarDatePicker
                        placeholder="emptyDate"
                        formFieldName="dateOfCompletion"
                        label={t("dateOfCompletion")}
                        required={true}
                        selectedDate={attempt.completionDate ?? undefined}
                        onChange={handleCompletionDateChange}
                    />

                    <FormTextArea
                        ariaLabelledBy={t("changeLog")}
                        formFieldName="changeLog"
                        id="changeLog"
                        label={t("changeLog")}
                        onChange={handleManualEntryNotesChange}
                        placeholder={t("enterChangeLog")}
                        required={true}
                        rows={4}
                        value={attempt.manualEntryNotes ?? ""}
                        type={InputTypes.Text}
                    />
                </div>
                {/* TODO: NFPA-8278 Remove or Implement )}*/}
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UpdateProgressModal };

// #endregion Exports
