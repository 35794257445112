import { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useHasAccess = (accessControlKey: AccessControlKeys): boolean => {
    const { record: globalState } = useGlobalState();

    const hasAccess: boolean = useMemo(
        (): boolean => globalState.currentIdentity?.hasAccess(accessControlKey) ?? false,
        [accessControlKey, globalState.currentIdentity]
    );

    return hasAccess;
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useHasAccess };

// #endregion Exports
