import React, { useMemo } from "react";
import { EventAssessmentList } from "components/assessments/event-assessment-list/event-assessment-list";
import { useEvent } from "utilities/contexts/use-event-context";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { useReports } from "utilities/hooks/models/reports/use-reports";
import "./manage-live-virtual-event-assessment-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageLiveVirtualEventAssessmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-assessment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventAssessmentPage: React.FC<ManageLiveVirtualEventAssessmentPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventAssessmentPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { record: event } = useEvent();
        const { reports } = useReports();

        const userHasAccessToAssessmentReports = useMemo(() => {
            return reports.some((report) => report.key === "training-transcript");
        }, [reports]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.Assessment} size={IconSizes.Large} />
                        <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                            {t("assessments")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                        {userHasAccessToAssessmentReports && (
                            <Button
                                linkPath={sitemap.admin.reports.list}
                                style={ButtonStyle.Primary}
                                text={t("goToReports")}
                                type={ButtonType.Link}
                            />
                        )}
                    </div>
                </div>

                <EventAssessmentList event={event} />
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventAssessmentPage };

// #endregion Exports
