import React, { useCallback, useEffect, useState } from "react";
import { ReportDownloadService } from "utilities/services/reports/report-download-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import { ReportDownloadStatus } from "models/enumerations/reports/report-download-status";
import { FileUtils } from "utilities/files/file-utils";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ReportDownloadRecord } from "models/view-models/reports/report-download-record";
import { useReportDownloadRequest } from "utilities/hooks/models/reports/use-report-download-request";
import { useReport } from "utilities/hooks/models/reports/use-report";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import {
    initialState,
    initializer,
    reducer,
    validate,
} from "components/reports/report-enrollment/report-enrollment-reducer";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icons } from "components/icons/constants/icons";
import { t } from "utilities/localization/t";
import { RoleType } from "models/enumerations/users/role-type";
import { Paragraph } from "components/typography/paragraph/paragraph";
import "./enrollment-report-download-button.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnrollmentReportDownloadButtonProps {
    eventId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "enrollment-report-download-button";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnrollmentReportDownloadButton: React.FC<EnrollmentReportDownloadButtonProps> = ({
    eventId,
}) => {
    const [reportDownload, setReportDownload] = useState<ReportDownloadRecord>();
    const [pollReportDownloadCompletion, setPollReportDownloadCompletion] = useState(false);
    const { get: getReportDownloadAPI } = ReportDownloadService.useGet();

    const {
        errors,
        reportDownloadRequest,
        create: createReportDownload,
    } = useReportDownloadRequest();

    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(RoleType.Instructor),
        validate,
    });

    // Configure the report and payload
    const { report } = useReport({ slug: "enrollment" });

    useEffect(() => {
        dispatch({ type: "updateEventIds", eventIds: [eventId] });
    }, [dispatch, eventId, report]);

    const handleReportRunClick = () => {
        if (report == null) return;

        createReportDownload({ report, payload });
    };

    useEffect(() => {
        if (errors == null || errors.length === 0) return;

        ToastManager.error(errors.map((e) => e.message).join(", "));
    }, [errors]);

    useEffect(() => {
        if (reportDownloadRequest?.id == null) return;
        ToastManager.success(
            t("yourReportIsRunningYoullReceiveANotificationWhenYourReportIsReady")
        );

        setPollReportDownloadCompletion(true);
    }, [reportDownloadRequest]);

    useEffect(() => {
        if (
            reportDownload?.status === ReportDownloadStatus.COMPLETE &&
            reportDownload.fileId != null
        ) {
            ToastManager.info(
                <div className={`${CSS_CLASS_NAME}__report-download`}>
                    <div>
                        <Paragraph>{t("yourReportIsReady")}</Paragraph>
                    </div>
                    <div>
                        <AnchorIcon
                            iconType={Icons.Download}
                            path={FileUtils.fileUrl(reportDownload.fileId)}
                            pathType={AnchorPathType.Download}
                            ariaLabel={reportDownload.name}
                        />
                    </div>
                </div>,
                {
                    className: `c-toast ${CSS_CLASS_NAME}__report-ready-toast`,
                    autoClose: false,
                    closeOnClick: false,
                    closeButton: true,
                }
            );
        }
    }, [reportDownload]);

    // #region Download Ready Polling

    const checkDownloadStatus = useCallback(
        async (reportDownloadId: number) => {
            if (isNaN(reportDownloadId) || reportDownloadId < 1) {
                return;
            }

            try {
                (async function getReportDownload() {
                    setPollReportDownloadCompletion(false);

                    const { result } = await getReportDownloadAPI({ id: reportDownloadId });

                    if (result?.hasErrors()) {
                        return;
                    }

                    if (
                        result?.resultObject?.status === ReportDownloadStatus.COMPLETE ||
                        result?.resultObject?.status === ReportDownloadStatus.ERROR
                    ) {
                        setReportDownload(result?.resultObject);
                    } else {
                        setPollReportDownloadCompletion(true);
                    }
                })();
            } catch {
                setPollReportDownloadCompletion(true);
                ToastManager.error(t("thereWasAnIssueLoadingTheReportDownloads"));
            }
        },
        [getReportDownloadAPI]
    );

    useEffect(() => {
        if (pollReportDownloadCompletion && reportDownloadRequest?.id != null) {
            const timeoutId = setTimeout(() => {
                checkDownloadStatus(reportDownloadRequest.id!);
            }, 1000);
            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [checkDownloadStatus, pollReportDownloadCompletion, reportDownloadRequest?.id]);

    // #endregion Download Ready Polling

    return (
        <ButtonIcon
            iconType={Icons.Download}
            onClick={handleReportRunClick}
            text={t("downloadAndPrint")}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentReportDownloadButton };

// #endregion Exports
