import React, { useMemo } from "react";
import { StringUtils } from "utilities/string-utils";
import { Icon } from "../icons/icon";
import { IconSizes } from "../icons/constants/icon-sizes";
import { Icons } from "../icons/constants/icons";
import useScreenSize from "../../utilities/hooks/use-screensize";
import { t } from "utilities/localization/t";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import "./pager.scss";

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PagerProps {
    cssClassName?: string;
    currentPage: number;
    footer?: boolean;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
    onPageClick: (page: number) => void;
}

export interface Pagination {
    pages: number[];
    hasNext: boolean;
    hasPrevious: boolean;
    nextPage: number;
    previousPage: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "pager";
const MAX_DISPLAY_PAGES: number = 10;
const MAX_DISPLAY_MOBILE_PAGES: number = 3;
const MIN_DISPLAY_PAGES: number = 1;
const MAX_SCREEN_WIDTH_FOR_MAX_PAGES = 500;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Pager: React.FC<React.PropsWithChildren<PagerProps>> = (
    props: React.PropsWithChildren<PagerProps>
) => {
    const screenSize = useScreenSize();
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    let maxDisplayPages =
        screenSize.width < MAX_SCREEN_WIDTH_FOR_MAX_PAGES
            ? MAX_DISPLAY_MOBILE_PAGES
            : MAX_DISPLAY_PAGES;

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.footer) {
        cssClassNames.push("-footer");
    }

    const totalPages = useMemo(() => Math.ceil(props.totalPages), [props.totalPages]);

    // Item Counter
    let currentRangeEnd = props.itemsPerPage * props.currentPage;
    let currentRangeStart = currentRangeEnd - props.itemsPerPage + 1;
    if (currentRangeEnd > props.totalItems) {
        currentRangeEnd = props.totalItems;
    }

    const generatePagination = (): Pagination => {
        let numOfPagesToDisplay =
            totalPages > maxDisplayPages
                ? maxDisplayPages
                : Math.max(totalPages, MIN_DISPLAY_PAGES);

        let startPage = 1;
        let endPage = numOfPagesToDisplay;

        if (totalPages > numOfPagesToDisplay) {
            const middlePage = Math.floor(numOfPagesToDisplay / 2) + 1;
            startPage = Math.floor(Math.max(props.currentPage - middlePage + 1, 1));
            endPage = startPage + numOfPagesToDisplay - 1;

            if (endPage > totalPages) {
                endPage = Math.ceil(totalPages);
                startPage = endPage - numOfPagesToDisplay + 1;
            }
        }

        // Create array of page numbers to show
        const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        return {
            pages,
            hasNext: props.currentPage < totalPages,
            hasPrevious: props.currentPage > 1,
            nextPage: props.currentPage + 1,
            previousPage: props.currentPage - 1,
        } as Pagination;
    };

    const renderPages = (pagination: Pagination) => {
        return (
            <div className={`${CSS_CLASS_NAME}__pages`}>
                {pagination.pages.map((page) => (
                    <button
                        aria-label={"Page " + page}
                        className={page === props.currentPage ? "selected" : ""}
                        disabled={page > totalPages}
                        key={"pagination-" + page}
                        onClick={() => props.onPageClick(page)}>
                        {page}
                    </button>
                ))}
            </div>
        );
    };

    const pagination = generatePagination();

    let chevronStart, chevronEnd;
    if (document.documentElement.dir == "rtl") {
        chevronStart = Icons.ChevronRight;
        chevronEnd = Icons.ChevronLeft;
    } else {
        chevronStart = Icons.ChevronLeft;
        chevronEnd = Icons.ChevronRight;
    }

    return (
        <>
            <div className={cssClassNames.join(" ")}>
                <div className={`${CSS_CLASS_NAME}__wrapper`}>
                    <button
                        aria-label={t("paginationNavigationPrevious")}
                        disabled={!pagination.hasPrevious}
                        onClick={() => props.onPageClick(pagination.previousPage)}>
                        <Icon size={IconSizes.Small} type={chevronStart} />
                    </button>
                    {renderPages(pagination)}
                    <button
                        aria-label={t("paginationNavigationNext")}
                        disabled={!pagination.hasNext}
                        onClick={() => props.onPageClick(pagination.nextPage)}>
                        <Icon size={IconSizes.Small} type={chevronEnd} />
                    </button>
                </div>
                <Paragraph cssClassName="counter" style={ParagraphStyle.Label}>
                    {currentRangeStart}-{currentRangeEnd} of {props.totalItems}
                </Paragraph>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Pager };

// #endregion Exports
