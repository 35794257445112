import React from "react";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { CTACard, CTACardAction } from "../../../../components/cta-card/cta-card";
import catalogLong from "assets/images/catalog_long.jpg";
import { ButtonStyle } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { ProviderStatusCard } from "components/providers/provider-status-card/provider-status-card";
import "./provider-enh-organization-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderENHOrganizationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-enh-organization-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderENHOrganizationPage: React.FC<ProviderENHOrganizationPageProps> = validatePageAccess(
    AccessControlKeys.ProviderENHOrganizationPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const ctaCardActions: CTACardAction[] = [
        {
            onClick: () => window.open("mailto:custserv@nfpa.org", "_blank"),
            style: ButtonStyle.Primary,
            text: t("contactNFPA"),
        },
    ];
    const provider = globalState.currentIdentity?.user?.provider;

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("yourOrganization")}
                    </Heading>
                    <Paragraph style={ParagraphStyle.Light}></Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <ProviderStatusCard provider={provider} />
                    {/* <Card cssClassName={`${CSS_CLASS_NAME}__details-card`}>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("organizationStatus")}{" "}
                            <Badge
                                style={provider?.isActive ? BadgeStyle.Success : BadgeStyle.Warning}
                                text={provider?.isActive ? t("active") : t("inactive")}
                            />
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__details-card__columns`}>
                            <div className={`${CSS_CLASS_NAME}__details-card__columns__info`}>
                                <Paragraph style={ParagraphStyle.Light}>
                                    {t(
                                        "theStatusAsAnActiveAenProviderIsContingentUponTheOrganizationsComplianceWithTheAenContractTimelyPaymentsAndFavorableCustomerEvaluations"
                                    )}
                                </Paragraph>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__details-card__columns__fields`}>
                                <FormFieldReadonly label={t("organization").toLocaleUpperCase()}>
                                    <Paragraph
                                        size={ParagraphSize.Small}
                                        style={ParagraphStyle.Light}>
                                        {provider?.name}
                                    </Paragraph>
                                </FormFieldReadonly>
                                <FormFieldReadonly label={t("yourRole")}>
                                    <Paragraph
                                        size={ParagraphSize.Small}
                                        style={ParagraphStyle.Light}>
                                        {t("enProviderAdmin")}
                                    </Paragraph>
                                </FormFieldReadonly>
                            </div>
                        </div>
                    </Card> */}
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Card cssClassName={`${CSS_CLASS_NAME}__event-card`}>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("organizationDefaultEventContact")}
                        </Paragraph>
                        <Paragraph style={ParagraphStyle.Light}>
                            {t(
                                "thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizerWhenUseDefaultDuringTheEventCreationProcess"
                            )}
                        </Paragraph>
                        <div className={`${CSS_CLASS_NAME}__event-card__columns`}>
                            <FormTextInput
                                disabled={true}
                                ariaLabelledBy={t("email")}
                                formFieldName="email"
                                id="email"
                                label={t("email")}
                                maxLength={100}
                                onChange={() => {}}
                                placeholder={t("enterEmail")}
                                type={InputTypes.Text}
                                value={provider?.email}
                            />
                            <FormTextInput
                                disabled={true}
                                ariaLabelledBy={t("phone")}
                                formFieldName="phone"
                                id="phone"
                                label={t("phone")}
                                maxLength={100}
                                onChange={() => {}}
                                placeholder="(000) 000-0000"
                                type={InputTypes.Phone}
                                value={provider?.phoneNumber}
                            />
                        </div>
                    </Card>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <CTACard
                        actions={ctaCardActions}
                        imageAltText={t("nationalFireProtectionAssociationCatalog")}
                        imagePath={catalogLong}
                        title={t("needToAddOrRemoveTeamMembers")}
                        description=""
                    />
                </div>
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderENHOrganizationPage };

// #endregion Exports
