import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ReportRecord } from "models/view-models/reports/report-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { ReportService } from "utilities/services/reports/report-service";

interface UseReportsHook {
    reports: ReportRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

export function useReports(): UseReportsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState<ReportRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listReports } = ReportService.useList();

    useEffect(() => {
        try {
            (async function fetchReports() {
                const { result, resultObjects } = await listReports();

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                const reports = resultObjects;
                [reports[0], reports[1]] = [reports[1], reports[0]];
                setReports(reports);
            })();
        } catch {
            setErrors([
                new ResultErrorRecord({
                    message: t("thereWasAProblemGettingAvailableReports"),
                }),
            ]);
        }
    }, [listReports]);

    return {
        errors,
        isLoading,
        reports,
    };
}
