import { InstructorApplication } from "models/interfaces/aen-applications/instructor-application";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { RecordUpdater, UseRecordContextFactory } from "./use-record-context-factory";

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

export type InstructorApplicationRecordUpdater = RecordUpdater<
    InstructorApplication,
    InstructorApplicationRecord
>;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const { RecordContext: InstructorApplicationContext, useRecord: useInstructorApplication } =
    UseRecordContextFactory.build<InstructorApplication, InstructorApplicationRecord>(
        InstructorApplicationRecord
    );

export { InstructorApplicationContext, useInstructorApplication };

// #endregion Exports
