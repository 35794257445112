import productImage from "assets/images/default-product-image.png";
import { FileUtils } from "utilities/files/file-utils";
import { StringUtils } from "andculturecode-javascript-core";
import "./product-image.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ProductImageAspect {
    Circle = "-circle",
    Portrait = "-portrait",
    Square = "-square",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ProductImageProps {
    ariaLabelledBy?: string;
    productImageFileId?: number;
    productImageAspect?: ProductImageAspect;
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-image";
const DEFAULT_ASPECT: ProductImageAspect = ProductImageAspect.Portrait;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductImage: React.FC<ProductImageProps> = ({
    ariaLabelledBy,
    productImageFileId,
    productImageAspect,
    cssClassName,
}): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME, productImageAspect ?? DEFAULT_ASPECT];

    if (StringUtils.hasValue(cssClassName)) {
        cssClassNames.push(cssClassName);
    }

    return (
        <div
            aria-labelledby={ariaLabelledBy}
            className={cssClassNames.join(" ")}
            style={{
                backgroundImage: `url(${
                    productImageFileId ? FileUtils.fileUrl(productImageFileId) : productImage
                })`,
            }}></div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductImage, ProductImageAspect };

// #endregion Exports
