import { Record } from "immutable";
import { CreateBulkEnrollmentDto } from "models/interfaces/enrollments/create-bulk-enrollment-dto";
import { EnrollmentUserRecord } from "./enrollment-user-record";
import { CollectionUtils } from "utilities/collection-utils";
import { RecordUtils } from "utilities/record-utils";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CreateBulkEnrollmentDto = {
    contractId: undefined,
    eventId: undefined,
    eventProductVersionId: undefined,
    productId: 0,
    enrollmentUsers: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CreateBulkEnrollmentDtoRecord
    extends Record(defaultValues)
    implements CreateBulkEnrollmentDto
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<CreateBulkEnrollmentDto>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.enrollmentUsers)) {
            params.enrollmentUsers = RecordUtils.ensureRecords(
                params.enrollmentUsers,
                EnrollmentUserRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for CreateBulkEnrollmentDto properties, create a new CreateBulkEnrollmentDtoRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<CreateBulkEnrollmentDto>} values The values to overwrite on this instance.
     * @returns A CreateBulkEnrollmentDtoRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<CreateBulkEnrollmentDto>): CreateBulkEnrollmentDtoRecord {
        return new CreateBulkEnrollmentDtoRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateBulkEnrollmentDtoRecord };

// #endregion Exports
