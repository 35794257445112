import { Record } from "immutable";
import { StorageConfiguration } from "models/interfaces/storage/storage-configuration";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: StorageConfiguration = {
    accountName: "",
    token: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class StorageConfigurationRecord extends Record(defaultValues) implements StorageConfiguration {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<StorageConfiguration>) {
        params = params ?? Object.assign({}, defaultValues);
        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<StorageConfiguration>): StorageConfigurationRecord {
        return new StorageConfigurationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { StorageConfigurationRecord };

// #endregion Exports
