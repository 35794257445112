import { CreateAssessmentProductMaterialsPage } from "pages/admin/products/create-assessment-product-materials-page/create-assessment-product-materials-page";
import { EditAssessmentProductMaterialsPage } from "pages/admin/products/edit-assessment-product-materials-page/edit-assessment-product-materials-page";
import { EditProductVersionListPage } from "pages/admin/products/edit-product-version-list-page/edit-product-version-list-page";
import { Process } from "utilities/types/processes/process";
import { ProductDetailsPage } from "pages/admin/products/product-details-page/product-details-page";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { RouteUtils } from "utilities/route-utils";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";
import { TrainingType } from "models/enumerations/courses/training-type";
import { EditOnlineProductMaterialsPage } from "pages/admin/products/edit-online-product-materials-page/edit-online-product-materials-page";
import { CreateOnlineProductMaterialsPage } from "pages/admin/products/create-online-product-materials-page/create-online-product-materials-page";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum EditProcessSpecificSteps {
    Details,
    Materials,
}

export type EditProductProcessSteps = typeof EditProcessSpecificSteps;

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Private Methods
// -------------------------------------------------------------------------------------------------

const createOnlineProcess = (
    productId: number,
    product: ProductRecord,
    productVersionZero: ProductVersionRecord
): Process<EditProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditOnlineProductMaterialsPage.name,
                    CreateOnlineProductMaterialsPage.name,
                ],
            },
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersionZero.courseIsComplete() &&
                productVersionZero.contentIsComplete() &&
                productVersionZero.achievementIsComplete(product.audienceType) &&
                product.evaluationTemplateIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditOnlineProductMaterialsPage.name,
                    CreateOnlineProductMaterialsPage.name,
                ],
            },
        },
    };
};

const createInstructorLedProcess = (
    productId: number,
    product: ProductRecord,
    productVersionZero: ProductVersionRecord
): Process<EditProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.instructorLed.details, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditAssessmentProductMaterialsPage.name,
                    CreateAssessmentProductMaterialsPage.name,
                ],
            },
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersionZero.assessmentIsComplete() &&
                productVersionZero.contentIsComplete() &&
                productVersionZero.achievementIsComplete(product.audienceType) &&
                product.evaluationTemplateIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditAssessmentProductMaterialsPage.name,
                    CreateAssessmentProductMaterialsPage.name,
                ],
            },
        },
    };
};

const createInstructorAssessmentProcess = (
    productId: number,
    product: ProductRecord,
    productVersionZero: ProductVersionRecord
): Process<EditProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => true, //product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditAssessmentProductMaterialsPage.name,
                    CreateAssessmentProductMaterialsPage.name,
                ],
            },
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                //productVersionZero.assessmentIsComplete() && productVersionZero.contentIsComplete(),
                true,
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditAssessmentProductMaterialsPage.name,
                    CreateAssessmentProductMaterialsPage.name,
                ],
            },
        },
    };
};
const createTrainTheTrainerProcess = (
    productId: number,
    product: ProductRecord,
    productVersionZero: ProductVersionRecord
): Process<EditProductProcessSteps> => {
    return {
        Details: {
            displayName: "productDetails",
            isComplete: () => product != null && product.detailsAreComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditOnlineProductMaterialsPage.name,
                    CreateOnlineProductMaterialsPage.name,
                ],
            },
        },
        Materials: {
            displayName: "productMaterials",
            isComplete: () =>
                productVersionZero.courseIsComplete() && productVersionZero.contentIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                id: productId,
            }),
            requestNavigationConfig: {
                requestingComponents: ProductLayout.name,
                componentsAllowedToResolve: [
                    ProductDetailsPage.name,
                    EditProductVersionListPage.name,
                    EditOnlineProductMaterialsPage.name,
                    CreateOnlineProductMaterialsPage.name,
                ],
            },
        },
    };
};

// #endregion Private Methods

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildEditProductProcess = (
    productId: number,
    product: ProductRecord,
    productVersionZero: ProductVersionRecord
): Process<EditProductProcessSteps> => {
    switch (product.type) {
        case TrainingType.InstructorLedTraining:
            return createInstructorLedProcess(productId, product, productVersionZero);

        case TrainingType.OnlineLearning:
            return createOnlineProcess(productId, product, productVersionZero);

        case TrainingType.TrainTheTrainer:
            return createTrainTheTrainerProcess(productId, product, productVersionZero);

        case TrainingType.InstructorAssessment:
            return createInstructorAssessmentProcess(productId, product, productVersionZero);

        default:
            return createOnlineProcess(productId, product, productVersionZero);
    }
};

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useEditProductProcess = UseProcessFactory.build(buildEditProductProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useEditProductProcess };

// #endregion Exports
