import React, { useEffect } from "react";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { FileRecord } from "models/view-models/files/file-record";
import { FileService } from "utilities/services/files/file-service";
import { FileUtils } from "utilities/files/file-utils";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { useRef, useState } from "react";
import { t } from "utilities/localization/t";
import "./file-input-with-buttons.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FileInputWithButtonsProps {
    ariaLabelledBy?: string;
    buttonText: string;
    file?: FileRecord;
    fileFormat?: string;
    fileIsValid?: (file: File) => boolean;
    maxFileSizeInMB: number;
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFileDelete?: () => void;
    onFileInvalid?: (file: File) => void;
    onFileUpload: (file: FileRecord) => void;
    onFileUploadError?: () => void;
    storageContainer: StorageContainers;
    updateRecords?: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "file-input-with-buttons";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FileInputWithButtons: React.FC<FileInputWithButtonsProps> = (
    props: FileInputWithButtonsProps
) => {
    const [fileName, setFileName] = useState("");
    const [isReplacing, setIsReplacing] = useState(false);
    const [fileIsValid, setFileIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const hiddenFileInputWithButtons = useRef<HTMLInputElement>(null);
    const fileNameText = loading ? "..." : fileName;
    const { delete: deleteFileApi } = FileService.useDelete();

    useEffect(() => {
        if (props.file != null) {
            setFileIsValid(true);
            setFileName(props.file.fileName);
        } else {
            setFileIsValid(false);
            setFileName("");
        }
    }, [props.file]);

    const fileIsInvalid = (file: File): boolean => {
        // {Prevent .exe and .bat files from being uploaded}
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (fileExtension === "exe" || fileExtension === "bat") {
            return true;
        }

        if (props.fileIsValid != null) {
            return !props.fileIsValid(file);
        }

        return false;
    };

    const handleChooseFileClick = () => {
        hiddenFileInputWithButtons?.current?.click();
    };

    const handleFileReplace = () => {
        setIsReplacing(true);
        handleChooseFileClick();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onFileChange != null) {
            props.onFileChange(event);
        }

        const file = event?.target?.files?.item(0);

        if (isReplacing && file == null) {
            setLoading(false);
            return;
        }

        if (file == null) {
            setFileName("");
            setLoading(false);
            return;
        }

        if (fileIsInvalid(file)) {
            if (props.onFileInvalid != null) {
                props.onFileInvalid(file);
            }

            return;
        }

        const fileSizeInMB = file.size / 1024 / 1024;
        if (fileSizeInMB > props.maxFileSizeInMB) {
            alert(t("fileSizeIsTooLarge"));
            return;
        }

        setFileName(file.name);
        setLoading(true);

        try {
            const fileRecord = await FileUtils.uploadFile(file, props.storageContainer);
            props.onFileUpload(fileRecord);
            setLoading(false);
            setFileIsValid(true);
            if (props.updateRecords) {
                props.updateRecords();
            }
        } catch {
            handleFileUploadError();
        }
    };

    const handleFileUploadError = () => {
        if (props.onFileUploadError != null) {
            props.onFileUploadError();
        }

        setLoading(false);
        setFileName("");
    };

    const handleFileDelete = async (): Promise<void> => {
        try {
            if (props.file?.id == null) {
                return;
            }
            await deleteFileApi(props.file?.id);
            setFileIsValid(false);
            setFileName("");
            props.onFileDelete!();
        } catch {
            ToastManager.error(t("errorDeletingFile"));
        }
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <span onClick={handleChooseFileClick}>
                    {!fileIsValid && (
                        <p className={`${CSS_CLASS_NAME}__select-file`}>{props.buttonText}</p>
                    )}
                    {fileIsValid && (
                        <p
                            className={`${CSS_CLASS_NAME}__file-name`}
                            onClick={(e) => e.stopPropagation()}>
                            {fileNameText}
                        </p>
                    )}
                </span>
                <input
                    accept={props.fileFormat}
                    type="file"
                    ref={hiddenFileInputWithButtons}
                    onChange={handleFileChange}
                />
                {fileIsValid && (
                    <div className={`${CSS_CLASS_NAME}__buttons`}>
                        <Button
                            text={t("replace")}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            onClick={handleFileReplace}
                            ariaLabelledBy={t("replaceFile")}
                        />
                        <Button
                            cssClassName={`${CSS_CLASS_NAME}__delete`}
                            text={t("delete")}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            onClick={handleFileDelete}
                            ariaLabelledBy={t("deleteFile")}
                        />
                    </div>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__instruction`}>
                {t("fileFormatMustBePropsFileFormatAndLessThanPropsMaxFileSizeInMBMB", {
                    propsFileFormat: props.fileFormat,
                    propsMaxFileSizeInMB: props.maxFileSizeInMB,
                })}
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FileInputWithButtons };

// #endregion Exports
