import React from "react";
import ChangesBanner from "components/changes-banner/changes-banner";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import "./event-activation-ceu-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationCEUSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-ceu-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationCEUSection: React.FC<EventActivationCEUSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                {!editMode && (
                    <>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("ceus")}
                        </Heading>
                    </>
                )}
            </div>
            {editMode && (
                <>
                    <h3>{t("ceus")}</h3>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            <div>
                <div>
                    <Paragraph style={ParagraphStyle.Label}>{t("eventCEUS")}</Paragraph>
                    <Paragraph>{event.getCeus()}</Paragraph>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationCEUSection };

// #endregion Exports
