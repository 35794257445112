import React from "react";
import { useParams } from "react-router-dom";
import { Tab } from "components/tabs/tabs/tab/tab";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { sitemap } from "sitemap";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "../../../../../components/tabs/tabbed-container/tabbed-container";
import { UserContractILLDetailInfo } from "./user-contract-ill-detail-info/user-contract-ill-detail-info";
import { UserContractILLDetailEnrollment } from "./user-contract-ill-detail-enrollment/user-contract-ill-detail-enrollment";
import { t } from "utilities/localization/t";
import "./user-contracts-ill-detail-page.scss";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsILLDetailPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-ill-detail-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsILLDetailPage: React.FC<UserContractsILLDetailPageProps> = validatePageAccess(
    AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage
)((): JSX.Element => {
    const routeParams = useParams();
    const orgId = routeParams.id;

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: <UserContractILLDetailInfo />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("basicInformation"),
            },
        },
        {
            contents: <UserContractILLDetailEnrollment />,
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: t("enrollment"),
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <div className={`${CSS_CLASS_NAME}__header__details`}>
                                <ButtonIcon
                                    linkPath={
                                        sitemap.admin.userManagement.contracts.onlineLearning.list
                                    }
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    buttonType={ButtonType.Link}
                                    iconType={Icons.ChevronLeft}
                                    text={t("backToAllInstructorLedEventContracts")}
                                />
                                <h2>Contract #12345</h2>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__header__actions`}>
                                {/* TODO: Add the appropriate link path */}
                                <Button
                                    linkPath="/"
                                    size={ButtonSize.Small}
                                    style={ButtonStyle.Primary}
                                    text={t("goToEvent")}
                                    type={ButtonType.Link}
                                />
                            </div>
                        </div>
                        <TabbedContainer tabs={tabs} />
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsILLDetailPage };

// #endregion Exports
