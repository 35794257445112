import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";


// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum InstructorLedTrainingType {
    LiveVirtual = 0,
    InPerson = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const InstructorLedTrainingTypeDisplayNames: Map<InstructorLedTrainingType, TranslatedCopy> = {
    [InstructorLedTrainingType.LiveVirtual]: "liveVirtual",
    [InstructorLedTrainingType.InPerson]: "inPerson",
};

const InstructorLedTrainingTypeCheckBoxLabels: Map<InstructorLedTrainingType, TranslatedCopy> = {
    [InstructorLedTrainingType.LiveVirtual]: "iAmAvailableToTeachVirtually",
    [InstructorLedTrainingType.InPerson]: "iAmAvailableToTeachInPerson",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
    InstructorLedTrainingTypeCheckBoxLabels,
};

// #endregion Exports
