import { Anchor } from "components/typography/anchors/anchor/anchor";
import React from "react";
import { sitemap } from "sitemap";
import logo from "assets/images/nfpa-logo.svg";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LogoAnchorProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LogoAnchor: React.FC<LogoAnchorProps> = (): JSX.Element => {
    return (
        <Anchor path={sitemap.root}>
            <img src={logo} alt={t("nationalFireProtectionAssociation")} />
        </Anchor>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LogoAnchor };

// #endregion Exports
