import React, { useCallback, useMemo } from "react";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Icons } from "components/icons/constants/icons";
import { InputTypes } from "components/form/enumerations/input-types";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import {
    FormRadioCardListWithDescription,
    RadioCardRows,
    RadioCardWithDescription,
} from "components/form/form-radio-card-list-with-description/form-radio-card-list-with-description";
import { Event } from "models/interfaces/events/event";
import { t } from "utilities/localization/t";
import "./event-name-and-type-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventNameAndTypeFormProps {
    event: Readonly<Event>;
    onNameChange: (name: string) => void;
    onTypeChange?: (type: InstructorLedTrainingType) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-name-and-type-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventNameAndTypeForm: React.FC<EventNameAndTypeFormProps> = (
    props: EventNameAndTypeFormProps
) => {
    const cards: RadioCardWithDescription[] = useMemo(
        () => [
            {
                icon: Icons.LiveVirtualEvent,
                text: "liveVirtualEvent",
                description: "selectForAVirtualEvent",
                value: InstructorLedTrainingType.LiveVirtual.toString(),
            },
            {
                icon: Icons.InPersonEvent,
                text: "inPersonEvent",
                description: "selectForAnInPersonEvent",
                value: InstructorLedTrainingType.InPerson.toString(),
            },
        ],
        []
    );

    const eventExists = useMemo(
        () => props.event.id != null && props.event.id > 0,
        [props.event.id]
    );

    const handleNameChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            props.onNameChange(event.target.value);
        },
        [props]
    );

    const handleTypeChange = useCallback(
        (value: string) => {
            if (props.onTypeChange == null) {
                return;
            }

            const eventType = EnumUtils.enumToObjectPartial(
                value,
                (value) => ({ instructorLedTrainingType: Number(value) }),
                InstructorLedTrainingType
            ).instructorLedTrainingType!;

            props.onTypeChange(eventType);
        },
        [props]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__name`}>
                <h1 className={`${CSS_CLASS_NAME}__faux-label`}>{t("eventName")}</h1>
                <p>
                    {t("inputTheEventClassNameTheClassNameWillNotBeSeen")}{" "}
                    <i>{t("noteYouDoNotNeedToIncludeTheEventType")}</i>
                </p>
                <FormTextInput
                    ariaLabelledBy={t("eventName")}
                    autoFocus={true}
                    formFieldName="eventName"
                    id="eventName"
                    label={t("eventName")}
                    maxLength={200}
                    onChange={handleNameChange}
                    placeholder={t("enterEventName")}
                    required={true}
                    type={InputTypes.Text}
                    value={props.event.name}
                />
            </div>
            <FormRadioCardListWithDescription
                disabled={eventExists}
                formFieldName="eventInstructorLedType"
                id="eventInstructorLedType"
                label={t("eventType")}
                onChange={handleTypeChange}
                radioCards={cards}
                required={true}
                rows={RadioCardRows.Two}
                value={props.event.instructorLedTrainingType?.toString()}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventNameAndTypeForm };

// #endregion Exports
