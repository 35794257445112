import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRole } from "models/interfaces/user-roles/user-role";
import { RoleRecord } from "models/view-models/roles/role-record";
import { UserRecord } from "../users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: UserRole = {
    createdById: undefined,
    createdOn: undefined,
    createdBy: undefined,
    deletedBy: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: 0,
    role: undefined,
    roleId: 0,
    updatedById: undefined,
    updatedBy: undefined,
    updatedOn: undefined,
    userId: 0,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class UserRoleRecord extends Record(defaultValues) implements UserRole {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserRole) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.role != null) {
            params.role = RecordUtils.ensureRecord(params.role, RoleRecord);
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        if (params.deletedBy != null) {
            params.deletedBy = RecordUtils.ensureRecord(params.deletedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserRole>} values
     * @returns {UserRoleRecord}
     * @memberof UserRoleRecord
     */
    public with(values: Partial<UserRole>): UserRoleRecord {
        return new UserRoleRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserRoleRecord };

// #endregion Exports
