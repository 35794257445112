import React from "react";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ReportsSidebar } from "components/reports/components/reports-sidebar/reports-sidebar";
import { ReportsHeader } from "components/reports/components/reports-header/reports-header";
import { DataTable } from "components/tables/data-table/data-table";
import { Badge } from "components/badges/badge/badge";
import { Icons } from "components/icons/constants/icons";
import { useReportDownloads } from "utilities/hooks/models/reports/use-report-downloads";
import {
    ReportDownloadStatus,
    ReportDownloadStatusBadgeStyle,
    ReportDownloadStatusDisplayNames,
} from "models/enumerations/reports/report-download-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { DateUtils } from "utilities/date-utils";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import { FileUtils } from "utilities/files/file-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import "./admin-reports-downloads-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminReportsDownloadsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-reports-downloads-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminReportsDownloadsPage: React.FC<AdminReportsDownloadsPageProps> = validatePageAccess(
    AccessControlKeys.AdminReportsDownloadsPage
)((): JSX.Element => {
    const { errors, reportDownloads } = useReportDownloads();

    useRedirectOnForbidden(sitemap.public.noAccess);

    if (errors) {
        ToastManager.error(t("thereWasAnIssueLoadingTheReportDownloads"));
    }

    return (
        <div className={CSS_CLASS_NAME}>
            <ReportsHeader />
            <SideContentLeftLayout sidebarElement={<ReportsSidebar />}>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            <h2>{t("reportDownloads")}</h2>
                            <DataTable>
                                <thead>
                                    <tr>
                                        <th className="report-type">{t("reportType")}</th>
                                        <th className="date-ran">{t("dateRan")}</th>
                                        <th className="status">{t("status")}</th>
                                        <th className="action">
                                            <span className="sr-only">{t("action")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportDownloads.map((rd, idx) => (
                                        <tr key={`${rd.id}-${idx}`}>
                                            <td className="report-type">{rd.name}</td>
                                            <td className="date-ran">
                                                {DateUtils.formatDateTime(true, rd.createdOn)}
                                            </td>
                                            <td className="status">
                                                <Badge
                                                    style={
                                                        ReportDownloadStatusBadgeStyle[rd.status]
                                                    }>
                                                    {t(ReportDownloadStatusDisplayNames[rd.status])}
                                                </Badge>
                                            </td>
                                            <td className="action">
                                                {rd.status === ReportDownloadStatus.COMPLETE &&
                                                rd.fileId != null ? (
                                                    <AnchorIcon
                                                        iconType={Icons.Download}
                                                        path={FileUtils.fileUrl(rd.fileId)}
                                                        pathType={AnchorPathType.Download}
                                                        ariaLabel={rd.name}
                                                    />
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </DataTable>
                        </SkipNavContent>
                    </div>
                </div>
            </SideContentLeftLayout>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminReportsDownloadsPage };

// #endregion Exports
