import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InstructorLedProductVersionCreateModal } from "components/products/product-version/product-version-create-modal/instructor-led-product-version-create-modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { InstructorAssessmentProductVersionCreateModal } from "components/products/product-version/product-version-create-modal/instructor-assessment-product-version-create-modal";
import { AssessmentProductVersionList } from "components/products/product-version/product-version-list/assessment-product-version-list";
import { OnlineProductVersionCreateModal } from "components/products/product-version/product-version-create-modal/online-product-version-create-modal";
import { OnlineProductVersionList } from "components/products/product-version/product-version-list/online-product-version-list";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersions } from "utilities/hooks/models/products/use-product-versions";
import "./edit-product-version-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditProductVersionListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-product-version-list-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditProductVersionListPage: React.FC<EditProductVersionListPageProps> = (
    props
): JSX.Element => {
    const { record: product } = useProduct();
    const [showCreateOnlineProductVersionModal, setShowCreateOnlineProductVersionModal] =
        useState<boolean>(false);
    const [showCreateInstructorProductVersionModal, setShowCreateInstructorProductVersionModal] =
        useState<boolean>(false);
    const [showCreateAssessmentProductVersionModal, setShowCreateAssessmentProductVersionModal] =
        useState<boolean>(false);

    const isAssessmentTraining =
        product.type === TrainingType.InstructorLedTraining ||
        product.type === TrainingType.InstructorAssessment;

    const isOnlineTraining =
        product.type === TrainingType.OnlineLearning ||
        product.type === TrainingType.TrainTheTrainer;

    const { productVersions } = useProductVersions({
        productId: product.id,
        includeCreatedBy: true,
        includeLastModifiedBy: true,
        includeProductScormPackage: false,
        includeContent: false,
        includeEnrollmentCounts: false,
    });

    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const navigationRequests = useListenForNavigationRequests(EditProductVersionListPage.name, [
        Breadcrumbs.name,
        ProductLayout.name,
    ]);

    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        resolveNavigationRequest(
            EditProductVersionListPage.name,
            navigationRequest.requestingComponent
        );
        navigationRequest.onNavigationApproved();

        setNavigationRequest(undefined);
    }, [navigationRequest, navigationRequests, resolveNavigationRequest]);

    const showVersionCreateModal = () => {
        switch (product.type) {
            case TrainingType.OnlineLearning:
            case TrainingType.TrainTheTrainer:
                return setShowCreateOnlineProductVersionModal(true);
            case TrainingType.InstructorLedTraining:
                return setShowCreateInstructorProductVersionModal(true);
            case TrainingType.InstructorAssessment:
                return setShowCreateAssessmentProductVersionModal(true);
            default:
                return;
        }
    };

    return (
        <>
            {product.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedProduct")}
                </Banner>
            )}
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    {product.status !== ActiveStatus.Draft && (
                        <HeaderActions
                            headingPriority={HeadingPriority.H4}
                            headingSize={HeadingSize.Small}
                            title={t("productMaterials")}>
                            <Button
                                disabled={
                                    product.status === ActiveStatus.Archived ||
                                    product.status === ActiveStatus.Inactive
                                }
                                onClick={showVersionCreateModal}
                                style={ButtonStyle.Primary}
                                text={t("createNewVersion")}
                            />
                        </HeaderActions>
                    )}
                </div>

                {isOnlineTraining && <OnlineProductVersionList />}
                {isAssessmentTraining && <AssessmentProductVersionList />}

                <OnlineProductVersionCreateModal
                    newProductVersionNumber={productVersions.length}
                    open={showCreateOnlineProductVersionModal}
                    setOpen={setShowCreateOnlineProductVersionModal}
                />
                <InstructorLedProductVersionCreateModal
                    newProductVersionNumber={productVersions.length}
                    open={showCreateInstructorProductVersionModal}
                    setOpen={setShowCreateInstructorProductVersionModal}
                />
                <InstructorAssessmentProductVersionCreateModal
                    newProductVersionNumber={productVersions.length}
                    open={showCreateAssessmentProductVersionModal}
                    setOpen={setShowCreateAssessmentProductVersionModal}
                />
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditProductVersionListPage };

// #endregion Exports
