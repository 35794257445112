import React, { useCallback, useEffect, useState } from "react";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";
import {
    ScormPackageAssessmentService,
    GetScormPackageAssessmentPathParams,
    GetScormPackageAssessmentQueryParams,
} from "utilities/services/scorm-packages/scorm-package-assessment-service";
import { EventAssessmentResultsHeader } from "components/assessments/event-assessment-results/event-assessment-results-header/event-assessment-results-header";
import { EventAssessmentResultsScormPackageCard } from "components/assessments/event-assessment-results/event-assessment-results-scorm-package-card/event-assessment-results-scorm-package-card";
import { EventAssessmentResultsBreakdowns } from "components/assessments/event-assessment-results/event-assessment-results-breakdowns/event-assessment-results-breakdowns";
import { ToastManager } from "utilities/toast/toast-manager";
import "./event-assessment-results.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAssessmentResultsProps {
    eventId: number;
    scormPackageAssessmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-assessment-results";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAssessmentResults: React.FC<EventAssessmentResultsProps> = (
    props: EventAssessmentResultsProps
): JSX.Element => {
    const [scormPackageAssessment, setScormPackageAssessment] =
        useState<ScormPackageAssessmentRecord>();
    const { get } = ScormPackageAssessmentService.useGet();

    const fetchData = useCallback(async () => {
        if (props.scormPackageAssessmentId == null) {
            return;
        }

        const pathParams: GetScormPackageAssessmentPathParams = {
            id: props.scormPackageAssessmentId,
        };

        const queryParams: GetScormPackageAssessmentQueryParams = {
            includeScormPackage: true,
            includeProductScormPackage: true,
        };

        try {
            const getResponse = await get(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }

            setScormPackageAssessment(getResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingAssessmentResults"));
        }
    }, [get, props.scormPackageAssessmentId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={CSS_CLASS_NAME}>
            <EventAssessmentResultsHeader
                eventId={props.eventId}
                optional={
                    scormPackageAssessment?.scormPackage?.productScormPackage?.optional ?? false
                }
            />
            <EventAssessmentResultsScormPackageCard
                scormPackage={scormPackageAssessment?.scormPackage}
            />
            <EventAssessmentResultsBreakdowns
                eventId={props.eventId}
                scormPackageAssessmentId={props.scormPackageAssessmentId}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAssessmentResults };

// #endregion Exports
