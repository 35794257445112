import { ServiceHookFactory } from "andculturecode-javascript-react";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "evaluations";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof EvaluationRecord = EvaluationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEvaluationPathParams {
    id: number;
}

export interface GetEvaluationQueryParams {
    includeQuestions?: boolean;
    includeResponses?: boolean;
    includeTemplate?: boolean;
}

export interface ListEvaluationQueryParams {
    eventId?: number;
    includeResponses?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EvaluationService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EvaluationRecord,
        GetEvaluationPathParams,
        GetEvaluationQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EvaluationRecord, ListEvaluationQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationService };

// #endregion Exports
