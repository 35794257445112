import { CommunicationRecord } from "models/view-models/communications/communication-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "communications";
const resourceType: typeof CommunicationRecord = CommunicationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CommunicationService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CommunicationService };

// #endregion Exports
