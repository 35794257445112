import { ServiceHookFactory } from "andculturecode-javascript-react";
import { LearnerChecklistRecord } from "models/view-models/learnerchecklists/learner-checklist-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments/:id/checklist";
const resourceEndpoint: string = `${baseEndpoint}`;
const resourceType: typeof LearnerChecklistRecord = LearnerChecklistRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetLearnerChecklistPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const LearnerChecklistService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<LearnerChecklistRecord, GetLearnerChecklistPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerChecklistService };

// #endregion Exports
