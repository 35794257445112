import React, { useState, useCallback, useMemo } from "react";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ProductManageAddEnrollment } from "./product-manage-add-enrollment";
import { ProductRecord } from "models/view-models/products/product-record";
import { t } from "utilities/localization/t";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./product-training-enrollment-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductTrainingEnrollmentModalProps {
    addEnrollment: (product: ProductRecord) => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    trainingType: TrainingType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-training-enrollment-modal";

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductTrainingEnrollmentModal: React.FC<ProductTrainingEnrollmentModalProps> = (
    props: ProductTrainingEnrollmentModalProps
): JSX.Element => {
    const [dirty, setDirty] = useState(false);
    const [products, setProductsAvailable] = useState<ProductRecord[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<ProductRecord | undefined>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled: !selectedProduct,
            buttonText: t("enrollTraining"),
            onClick: () => onEnrollTraining(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setSelectedProduct(undefined);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onEnrollTraining = () => {
        if (!selectedProduct) return;

        props.addEnrollment(selectedProduct);
        resetForm();
        props.setOpen(false);
    };

    const handleAddProductToQueue = useCallback((product: ProductRecord): void => {
        setSelectedProduct(product);
        setDirty(true);
    }, []);

    const handleRemoveProductFromQueue = useCallback((): void => {
        setSelectedProduct(undefined);
    }, []);

    const handleFetchProducts = useCallback((products: ProductRecord[]): void => {
        setProductsAvailable(products);
    }, []);

    const banner = useMemo(
        () =>
            props.trainingType !== TrainingType.InstructorAssessment ? (
                <Banner style={BannerStyle.Default} format={BannerFormat.Box}>
                    <div className={`${CSS_CLASS_NAME}__banner`}>
                        <Paragraph style={ParagraphStyle.Inverted}>
                            <strong>{t("onlyAvailableForNFPAOLLTrainingProducts")}</strong>
                        </Paragraph>
                        <Paragraph style={ParagraphStyle.Inverted}>
                            {t(
                                "toEnrollInAllOtherTrainingsNavigateToTheIndividualContractOrILTEventsEnrollmentSection"
                            )}
                        </Paragraph>
                    </div>
                </Banner>
            ) : (
                <></>
            ),
        [props.trainingType]
    );

    return (
        <Modal
            actions={modalActionArray}
            isOpen={props.open}
            modalStyle={""}
            onModalClose={onModalClose}
            banner={banner}
            title={t("enrollNewTraining")}>
            <ProductManageAddEnrollment
                handleAddProductToQueue={handleAddProductToQueue}
                handleFetchProducts={handleFetchProducts}
                handleRemoveProductFromQueue={handleRemoveProductFromQueue}
                products={products}
                selectedProduct={selectedProduct}
                trainingType={props.trainingType}
            />
            <Modal
                actions={confirmationActionArray}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductTrainingEnrollmentModal };

// #endregion Exports
