import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { Permission } from "utilities/enumerations/authorization/permission";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const InstructorAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,
    [AccessControlKeys.InstructorDashboardPage]: Permission.Allow,
    [AccessControlKeys.MyTeachingAssignmentsPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsDownloadsPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsSetupPage]: Permission.Allow,
    [AccessControlKeys.InstructorENHResourcesPage]: Permission.Allow,
    [AccessControlKeys.ManageEventAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.InstructorENHRequiredTrainingsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAssessmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceDayPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceSummaryPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventContentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEnrollmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEvaluationsPage]: Permission.Allow,

    // Take a Training
    [AccessControlKeys.LearnerOnlineLearningContentPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningDetailsPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitCourseAssessmentPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitCoursePage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage]: Permission.Allow,

    // Actions
    [AccessControlKeys.CanDownloadEnrollmentReport]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorAuthorizationRules };

// #endregion Exports
