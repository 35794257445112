import React from "react";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { t } from "utilities/localization/t";

interface EndEmulationSessionButtonProps {
    disabled?: boolean;
    buttonSize?: ButtonSize;
    buttonStyle?: ButtonStyle;
    className?: string;
    isInFooter?: boolean;
}

const EndEmulationSessionButton = ({
    disabled = false,
    buttonSize = ButtonSize.Small,
    buttonStyle = ButtonStyle.Destructive,
    className = "c-emulation-mode-banner__end",
    isInFooter = false,
}: EndEmulationSessionButtonProps) => {
    const { token, deleteToken } = useEmulationMode();

    const endSessionText = isInFooter ? t("endSession").toLocaleUpperCase() : t("endSession");

    const handleEndSession = () => {
        if (token?.id != null && token.guestId != null) {
            deleteToken(token.id, token.guestId);
        }
    };

    return (
        <Button
            disabled={disabled}
            cssClassName={className}
            onClick={handleEndSession}
            size={buttonSize}
            style={buttonStyle}>
            {endSessionText}
        </Button>
    );
};

export { EndEmulationSessionButton };
