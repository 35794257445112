import React, { useEffect, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { CountryConstants } from "constants/country-constants";
import { E164Number } from "libphonenumber-js";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { SelectOption } from "components/form/inputs/select/select";
import { allCountries, CountryName, CountrySlug } from "country-region-data";
import { t } from "utilities/localization/t";
import { StringUtils } from "andculturecode-javascript-core";
import { useOrganizations } from "utilities/hooks/models/events/use-organizations";
import { useTimeZoneOptions } from "utilities/hooks/use-timezone-options";
import "./edit-organization-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditOrganizationModalProps {
    open: boolean;
    organization: OrganizationRecord;
    onSave: (organization: OrganizationRecord) => Promise<Boolean>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-organization-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditOrganizationModal: React.FC<EditOrganizationModalProps> = ({
    onSave,
    open,
    organization,
    setOpen,
}): JSX.Element => {
    const [stateRegionConstants, setStateRegionConstants] = useState<SelectOption[]>([]);
    const [editOrganization, setEditOrganization] = useState<OrganizationRecord>(organization);
    const { timeZoneOptions: timeZones } = useTimeZoneOptions();

    useEffect(() => {
        setEditOrganization(organization);
    }, [organization]);

    // -------------------------------------------------------------------------------------------------
    // #region Validate Unique Organization Details
    // -------------------------------------------------------------------------------------------------

    const [netSuiteIdError, setNetSuiteIdError] = useState<string>("");
    const [netSuiteIdToCheck, setNetSuiteIdToCheck] = useState<string>("");

    const { organizations: organizationsWithNetSuiteId, isLoading: netSuiteIdCheckInProgress } =
        useOrganizations({
            nfpaId: netSuiteIdToCheck,
            searchCriteriaReady: StringUtils.hasValue(netSuiteIdToCheck),
            skip: 0,
            take: 1,
        });

    const onNetSuitIdBlur = (): void => {
        if (StringUtils.hasValue(editOrganization.nfpaId)) {
            setNetSuiteIdToCheck(editOrganization.nfpaId);
        }
    };

    useEffect(() => {
        if (organizationsWithNetSuiteId.some((o) => o.id !== organization.id)) {
            setNetSuiteIdError(t("anOrganizationAlreadyExistsWithThisNetSuiteCustomerId"));
        } else {
            setNetSuiteIdError("");
        }
    }, [organization.id, organizationsWithNetSuiteId]);

    // #endregion Validate Unique Organization Details

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled:
                !StringUtils.hasValue(editOrganization.name) ||
                !StringUtils.hasValue(editOrganization.nfpaId) ||
                StringUtils.hasValue(netSuiteIdError) ||
                netSuiteIdCheckInProgress,
            buttonText: t("saveChanges"),
            onClick: () => saveOrganization(),
            style: ButtonStyle.Primary,
        },
    ];

    useEffect(() => {
        const stateRegionSelectOptions: SelectOption[] = [];

        if (editOrganization.country) {
            const stateRegions = allCountries.find((c) =>
                c.includes((editOrganization.country ?? "") as CountryName | CountrySlug)
            );

            stateRegions![2].forEach((region) =>
                stateRegionSelectOptions.push({ text: region[0], value: region[0] })
            );
        }

        setStateRegionConstants(stateRegionSelectOptions);
    }, [editOrganization.country]);

    const onModalClose = (): void => {
        setOpen(false);
        resetForm();
    };

    const resetForm = (): void => {
        setEditOrganization(organization);
    };

    const saveOrganization = async (): Promise<void> => {
        await onSave(editOrganization);
        onModalClose();
    };

    const updateOrganization = (values: Partial<OrganizationRecord>): void => {
        setEditOrganization(editOrganization.with(values));
    };

    const handleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ name: event.target.value });
    };

    const handleNetSuiteCustomerIDChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ nfpaId: event.target.value });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ email: event.target.value });
    };

    const handlePhoneNumberChange = (value?: E164Number | undefined): void => {
        const valueToSet = value === undefined ? "" : value;
        updateOrganization({ phone: valueToSet });
    };

    const handleLocationNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ locationName: event.target.value });
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ country: event.target.value, state: undefined });
    };

    const handleAddrOneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ addressLineOne: event.target.value });
    };

    const handleAddrTwoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ addressLineTwo: event.target.value });
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ city: event.target.value });
    };

    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ state: event.target.value });
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateOrganization({ zipCode: event.target.value });
    };

    const handleTimeZoneChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateOrganization({ timeZone: event.target.value });
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editContractOrganization")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__overflow`}>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("basicInformation")}</Paragraph>
                    <FormTextInput
                        ariaLabelledBy={t("organizationName")}
                        autoFocus={true}
                        formFieldName="organizationName"
                        id="editOrganizationName"
                        label={t("organizationName")}
                        maxLength={150}
                        onChange={handleOrganizationNameChange}
                        placeholder={t("enterOrganizationName")}
                        required={true}
                        type={InputTypes.Text}
                        value={editOrganization.name}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("netsuiteCustomerID")}
                        formFieldName="netsuiteCustomerID"
                        id="editNetsuiteCustomerID"
                        label={t("netsuiteCustomerID")}
                        maxLength={60}
                        errorMessage={netSuiteIdError}
                        onBlur={onNetSuitIdBlur}
                        onChange={handleNetSuiteCustomerIDChange}
                        placeholder={t("enterNetSuiteCustomerID")}
                        required={true}
                        type={InputTypes.Text}
                        value={editOrganization.nfpaId}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("defaultEventInformation")}</Paragraph>
                    <Paragraph size={ParagraphSize.Default}>
                        {t("defaultContactInformation")}
                    </Paragraph>
                    <FormTextInput
                        ariaLabelledBy={t("email")}
                        formFieldName="email"
                        id="editOrganizationEmail"
                        label={t("email")}
                        maxLength={250}
                        onChange={handleEmailChange}
                        placeholder={t("enterEmail")}
                        required={false}
                        type={InputTypes.Email}
                        value={editOrganization.email}
                    />
                    <FormPhoneInput
                        ariaLabelledBy={t("phone")}
                        formFieldName="phone"
                        id="editOrganizationPhone"
                        label={t("phone")}
                        onChange={handlePhoneNumberChange}
                        placeholder="(000) 000-0000"
                        required={false}
                        type={InputTypes.Phone}
                        value={editOrganization.phone}
                    />
                    <Paragraph size={ParagraphSize.Default}>{t("defaultEventLocation")}</Paragraph>
                    <FormTextInput
                        ariaLabelledBy={t("locationName")}
                        formFieldName="locationName"
                        id="editLocationName"
                        label={t("locationName")}
                        maxLength={150}
                        onChange={handleLocationNameChange}
                        placeholder={t("enterLocationName")}
                        required={false}
                        type={InputTypes.Text}
                        value={editOrganization.locationName}
                    />
                    <FormSelect
                        ariaLabelledBy={t("country")}
                        formFieldName="country"
                        id="editOrganizationCountry"
                        label={t("country")}
                        onChange={handleCountryChange}
                        options={CountryConstants}
                        placeholder={t("selectOption")}
                        value={editOrganization.country}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("addressLine1")}
                        formFieldName="addressLine1"
                        id="editAddressLine1"
                        label={t("addressLine1")}
                        maxLength={150}
                        onChange={handleAddrOneChange}
                        placeholder={t("enterAddressLine1")}
                        required={false}
                        type={InputTypes.Text}
                        value={editOrganization.addressLineOne}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("addressLine2")}
                        formFieldName="addressLine2"
                        id="editAddressLine2"
                        label={t("addressLine2")}
                        maxLength={150}
                        onChange={handleAddrTwoChange}
                        placeholder={t("enterAddressLine2")}
                        required={false}
                        type={InputTypes.Text}
                        value={editOrganization.addressLineTwo}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("citySlashTown")}
                        formFieldName="cityTown"
                        id="editOrganizationCityTown"
                        label={t("citySlashTown")}
                        maxLength={150}
                        onChange={handleCityChange}
                        placeholder={t("enterCitySlashTown")}
                        required={false}
                        type={InputTypes.Text}
                        value={editOrganization.city}
                    />
                    <FormSelect
                        ariaLabelledBy={t("stateSlashProvinceSlashRegion")}
                        disabled={editOrganization.country == null}
                        formFieldName="stateProvinceRegion"
                        id="editOrganizationStateProvinceRegion"
                        label={t("stateSlashProvinceSlashRegion")}
                        onChange={handleStateChange}
                        options={stateRegionConstants}
                        placeholder={t("selectOption")}
                        value={editOrganization.state}
                    />
                    <FormTextInput
                        ariaLabelledBy={t("zipCodeSlashPostalCode")}
                        formFieldName="zipPostal"
                        id="editOrganizationZipPostal"
                        label={t("zipCodeSlashPostalCode")}
                        maxLength={100}
                        onChange={handleZipCodeChange}
                        placeholder={t("enterZipcodeSlashPostalCode")}
                        required={false}
                        type={InputTypes.Text}
                        value={editOrganization.zipCode}
                    />
                    <Paragraph size={ParagraphSize.Default}>{t("defaultEventTimezone")}</Paragraph>
                    <FormSelect
                        ariaLabelledBy={t("timezone")}
                        formFieldName="timeZone"
                        id="editOrganizationTimeZone"
                        label={t("timezone")}
                        onChange={handleTimeZoneChange}
                        options={timeZones}
                        placeholder={t("selectTimeZone")}
                        required={false}
                        value={editOrganization.timeZone}
                    />
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditOrganizationModal };

// #endregion Exports
