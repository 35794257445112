import { ServiceHookFactory } from "andculturecode-javascript-react";
import { SeededUserRecord } from "models/view-models/users/seeded-user-record";
import { UserLoginRecord } from "models/view-models/users/user-login-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "seededusers";
const authenticationEndpoint: string = `${baseEndpoint}/authenticate`;
const createResourceType: typeof UserLoginRecord = UserLoginRecord;
const listResourceType: typeof SeededUserRecord = SeededUserRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const SeededUserService = {
    useAuthenticate: ServiceHookFactory.useCreate(
        createResourceType,
        authenticationEndpoint
    ),

    useList: ServiceHookFactory.useList(listResourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SeededUserService };

// #endregion Exports
