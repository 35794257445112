import React from "react";
import { InputTypes } from "components/form/enumerations/input-types";
import { InputProperties } from "components/form/input-properties";
import { StringUtils } from "utilities/string-utils";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./text-input.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TextInputProps extends InputProperties {
    cssClassName?: string;
    /**
     * Max number of characters the input will accept. If not set, input has no character limit.
     */
    maxLength?: number;
    /**
     * Max value of number the input will accept. If not set, input has no number value limit.
     */
    maxNumber?: number;
    minValue?: number;
    name?: string;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "text-input";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    const type = props.type ?? InputTypes.Text;
    const value = props.value ?? "";
    const classNames: string[] = [CSS_CLASS_NAME];
    const { readOnly } = useReadOnly();

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    var className: string = classNames.join(" ");

    return (
        <input
            autoFocus={props.autoFocus}
            readOnly={props.readOnly}
            className={className}
            disabled={props.disabled || readOnly}
            max={props.maxNumber}
            maxLength={props.maxLength}
            min={props.minValue}
            name={props.name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress}
            placeholder={props.placeholder}
            title={props.ariaLabelledBy}
            type={type}
            value={value}
        />
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TextInput };

// #endregion Exports
