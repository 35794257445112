import React, { useCallback, useEffect, useState } from "react";
import { Button } from "components/buttons/button/button";
import {
    GetEvaluationTemplatePathParams,
    GetEvaluationTemplateQueryParams,
    EvaluationTemplateService,
} from "utilities/services/evaluation-templates/evaluation-template-service";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EvaluationForm } from "components/evaluations/evaluation-form/evaluation-form";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { EvaluationQuestionType } from "models/enumerations/evaluations/evaluation-question-type";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { EvaluationResponseRecord } from "models/view-models/evaluations/evaluation-response-record";
import { EvaluationService } from "utilities/services/evaluations/evaluation-service";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { StringUtils } from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import "./evaluation-manager.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EvaluationManagerProps {
    enrollmentId?: number;
    evaluationTemplateId: number;
    readOnly?: boolean;
    title?: string;
    handleEvaluationSubmission?: (evaluation: EvaluationRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "evaluation-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EvaluationManager: React.FC<EvaluationManagerProps> = (
    props: EvaluationManagerProps
): JSX.Element => {
    const [responses, setResponses] = useState<EvaluationResponseRecord[]>([]);
    const [evaluationTemplate, setEvaluationTemplate] = useState<EvaluationTemplateRecord>();
    const [saving, setSaving] = useState(false);
    const { get: getEvaluationTemplate } = EvaluationTemplateService.useGet();
    const { create: apiEvaluationCreate } = EvaluationService.useCreate();
    const [valid, setValid] = useState(false);

    const questionHasValidResponse = (
        question: EvaluationQuestionTemplateRecord,
        questionResponses: EvaluationResponseRecord[]
    ): boolean => {
        if (question.type === EvaluationQuestionType.FreeResponse) {
            return (
                questionResponses.find(
                    (r) =>
                        r.evaluationQuestionTemplateId === question.id &&
                        StringUtils.hasValue(r.freeResponse)
                ) !== undefined
            );
        }

        return (
            questionResponses.find((r) => r.evaluationQuestionTemplateId === question.id) !==
            undefined
        );
    };

    const handleValidation = (questionResponses: EvaluationResponseRecord[]) => {
        let isValid = true;
        if (
            evaluationTemplate === undefined ||
            !CollectionUtils.hasValues(evaluationTemplate.questions)
        ) {
            return;
        }

        evaluationTemplate.questions.forEach((q) => {
            if (q.isRequired && !questionHasValidResponse(q, questionResponses)) {
                isValid = false;
            }
        });

        setValid(isValid);
    };

    const handleQuestionResponseUpdate = (
        question: EvaluationQuestionTemplateRecord,
        questionResponses: EvaluationResponseRecord[]
    ) => {
        const newResponses = responses.filter(
            (r) => r.evaluationQuestionTemplateId !== question.id
        );

        if (questionResponses.length > 0) {
            newResponses.push(...questionResponses);
        }
        handleValidation(newResponses);
        setResponses(newResponses);
    };

    const fetchData = useCallback(async () => {
        if (props.evaluationTemplateId === undefined || props.evaluationTemplateId <= 0) {
            return;
        }

        const pathParams: GetEvaluationTemplatePathParams = {
            id: props.evaluationTemplateId,
        };

        const queryParams: GetEvaluationTemplateQueryParams = {
            includeQuestions: true,
        };

        try {
            var getEvaluationTemplateResponse = await getEvaluationTemplate(
                pathParams,
                queryParams
            );
            var getEvaluationTemplateResult = getEvaluationTemplateResponse?.result;

            if (
                getEvaluationTemplateResult?.resultObject == null ||
                getEvaluationTemplateResult.hasErrors()
            ) {
                throw new Error();
            }

            const evaluationTemplate = getEvaluationTemplateResult.resultObject;
            evaluationTemplate.sortQuestions();
            setEvaluationTemplate(evaluationTemplate);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheEvaluation"));
        }
    }, [getEvaluationTemplate, props.evaluationTemplateId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSaveClick = async () => {
        setSaving(true);
        await handleEvaluationSave();
        setSaving(false);
    };

    const handleEvaluationSave = async (): Promise<boolean> => {
        if (
            props.enrollmentId === undefined ||
            evaluationTemplate?.id === undefined ||
            responses === undefined
        ) {
            return false;
        }

        const evaluation = new EvaluationRecord().with({
            enrollmentId: props.enrollmentId,
            evaluationTemplateId: evaluationTemplate?.id,
            responses: responses!,
        });

        let evaluationCreateResponse;
        try {
            evaluationCreateResponse = await apiEvaluationCreate(evaluation);

            const evaluationCreateResult = evaluationCreateResponse?.result;
            if (
                evaluationCreateResult?.resultObject == null ||
                evaluationCreateResult.hasErrors()
            ) {
                throw new Error();
            }

            const createdEnrollment = evaluationCreateResult?.resultObject;
            if (createdEnrollment.id > 0 && props.handleEvaluationSubmission) {
                props.handleEvaluationSubmission(createdEnrollment);
                ToastManager.success(t("evaluationSubmittedSuccessfully"));
            } else {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueSubmittingTheEvaluation"));
            return false;
        }

        return true;
    };

    return (
        <div className={CSS_CLASS_NAME}>
            {evaluationTemplate && (
                <EvaluationForm
                    evaluationTemplate={evaluationTemplate}
                    onResponseChange={handleQuestionResponseUpdate}
                    responses={responses}
                />
            )}
            {evaluationTemplate && (
                <Button
                    cssClassName={`${CSS_CLASS_NAME}__submit`}
                    disabled={props.readOnly || !valid || saving}
                    onClick={handleSaveClick}
                    text={t("submitEvaluation")}
                />
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationManager };

// #endregion Exports
