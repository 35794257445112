import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AenApplicationsListPageLayout } from "./aen-applications-list-page-layout";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { Badge } from "components/badges/badge/badge";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import {
    ITEMS_PER_PAGE,
    useAenApplications,
} from "utilities/hooks/models/aen-applications/use-aen-applications";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import {
    ProviderApplicationStatus,
    ProviderApplicationStatusBadgeStyleMap,
    ProviderApplicationStatusDisplayNames,
} from "models/enumerations/aen-applications/provider-application-status";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import React from "react";
import "./../aen-applications.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationsProviderProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------
const CSS_CLASS_NAME = "aen-applications";
const DEBOUNCE_TIME = 750;
// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationsProvider: React.FC<AenApplicationsProviderProps> = validatePageAccess(
    AccessControlKeys.AdminAenProviderApplications
)((): JSX.Element => {
    const navigate = useNavigate();

    const {
        assignedToId,
        assignedToOptions,
        currentPage,
        getAssignedToNameById,
        handleAssignedToChange,
        handleSearchTextInputChange,
        handleSearchTriggered,
        handleStatusChange,
        isLoadingProviderApplications,
        onClearFilterClick,
        onPageClick,
        providerApplications,
        searchText,
        status,
        totalProviderApplications,
    } = useAenApplications({ applicationType: AenApplicationType.Provider });

    return (
        <AenApplicationsListPageLayout
            footer={
                <Pager
                    currentPage={currentPage}
                    onPageClick={onPageClick}
                    totalPages={totalProviderApplications / ITEMS_PER_PAGE}
                    itemsPerPage={ITEMS_PER_PAGE}
                    totalItems={totalProviderApplications}></Pager>
            }
            filtersElement={
                <div className={`${CSS_CLASS_NAME}__sidebar`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("filterResults")}</Paragraph>

                    <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                        <SearchTextInput
                            debounce={DEBOUNCE_TIME}
                            disabled={isLoadingProviderApplications}
                            onSearchTextInputChange={handleSearchTextInputChange}
                            onSearchTriggered={handleSearchTriggered}
                            id={"aenApplicationsSearch"}
                            placeholder={t("searchByEmailNameOrId")}
                            searchTextInputValue={searchText}
                        />
                    </div>
                    <FormSelect
                        disabled={isLoadingProviderApplications}
                        ariaLabelledBy={t("filterByStatus")}
                        formFieldName={t("filterByStatus")}
                        id="applicationsFilterByStatus"
                        label={t("filterByStatus")}
                        onChange={handleStatusChange}
                        options={[
                            ...EnumUtils.numericEnumToSelectOptions(
                                ProviderApplicationStatus,
                                ProviderApplicationStatusDisplayNames
                            ),
                        ]}
                        value={status?.toString()}
                    />
                    <FormSelect
                        disabled={isLoadingProviderApplications}
                        ariaLabelledBy={t("filterByAssignedTo")}
                        formFieldName={t("filterByAssignedTo")}
                        id="applicationsFilterByAssignedTo"
                        label={t("filterByAssignedTo")}
                        onChange={handleAssignedToChange}
                        options={assignedToOptions}
                        value={assignedToId?.toString()}
                    />
                    <Button
                        disabled={isLoadingProviderApplications}
                        onClick={onClearFilterClick}
                        size={ButtonSize.Small}
                        style={ButtonStyle.Quaternary}
                        text={t("clearAllFilters")}
                    />
                </div>
            }>
            {CollectionUtils.hasValues(providerApplications) ? (
                <>
                    <DataTable cssClassName={`${CSS_CLASS_NAME}__table`}>
                        <thead>
                            <tr>
                                <th className="id">{t("id")}</th>
                                <th className="companyName">{t("companyName")}</th>
                                <th className="contactEmail">{t("contactEmail")}</th>
                                <th className="assignedTo">{t("assignedTo")}</th>
                                <th className="dateCreated">{t("dateCreated")}</th>
                                <th className="status">{t("status")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {providerApplications.map((application, idx) => (
                                <tr key={application.id}>
                                    <td className="id">{application.id}</td>
                                    <td className="companyName">{application.providerName}</td>
                                    <td className="contactEmail">{application.primaryEmail}</td>
                                    <td className="assignedTo">
                                        {getAssignedToNameById(application.assignedToId!)}
                                    </td>
                                    <td className="dateCreated">
                                        {DateUtils.formatDateTime(
                                            true,
                                            application?.createdOn?.toString(),
                                            true
                                        )}
                                    </td>
                                    <td className="status">
                                        <Badge
                                            style={
                                                ProviderApplicationStatusBadgeStyleMap[
                                                    application.status as ProviderApplicationStatus
                                                ]
                                            }
                                            text={t(
                                                ProviderApplicationStatusDisplayNames[
                                                    application.status as ProviderApplicationStatus
                                                ]
                                            )}
                                        />
                                    </td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                displayName={t("goToApplication")}
                                                onClick={() =>
                                                    navigate(
                                                        RouteUtils.replacePathParams(
                                                            sitemap.admin.aenApplications.providers
                                                                .detail,
                                                            {
                                                                id: application.id,
                                                            }
                                                        )
                                                    )
                                                }
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </>
            ) : (
                <EmptyText table>{t("noContentResultsFound")}</EmptyText>
            )}
        </AenApplicationsListPageLayout>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationsProvider };

// #endregion Exports
