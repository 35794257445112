import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditUserModal } from "components/user-management/edit-user-modal/edit-user-modal";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ToastManager } from "utilities/toast/toast-manager";
import { UserContext } from "utilities/contexts/use-user-context";
import { UserDetailHeader } from "components/user-management/user-detail-header/user-detail-header";
import {
    UserDetailSidebar,
    UserDetailSidebarNavItem,
} from "components/user-management/user-detail-sidebar/user-detail-sidebar";
import { UserRecord } from "models/view-models/users/user-record";
import {
    UpdateUserPathParams,
    UserResourcePathParams,
    UserResourceQueryParams,
    UserService,
} from "utilities/services/users/user-service";
import { sitemap } from "sitemap";
import { NfpaRoleTypes, RoleType } from "models/enumerations/users/role-type";
import { RoleRecord } from "models/view-models/roles/role-record";
import { useRoles } from "utilities/hooks/models/roles/use-roles";
import { useOrganizations } from "utilities/hooks/models/events/use-organizations";
import {
    ListUserRolesQueryParams,
    UserRoleService,
} from "utilities/services/user-roles/user-role-service";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { useEvents } from "utilities/hooks/models/events/use-events";
import { DateUtils } from "utilities/date-utils";
import { useOrganizationOLLContractSummaries } from "utilities/hooks/models/organizations/use-organization-oll-contract-summaries";
import { useOrganizationILTContractSummaries } from "utilities/hooks/models/organizations/use-organization-ilt-contract-summaries";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { t } from "utilities/localization/t";
import { useProviders } from "utilities/hooks/models/providers/use-providers";
import { InstructorProfileService } from "utilities/services/instructors/instructor-profile-service";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";
import "./user-management-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserManagementLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "user-management-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserManagementLayout: React.FC<UserManagementLayoutProps> = (): JSX.Element => {
    const { id: userIdParam } = useParams();
    const userId = useMemo(() => NumberUtils.parseInt(userIdParam) ?? 0, [userIdParam]);

    const { get: getUserApi } = UserService.useGet();
    const { list: listUserRoles } = UserRoleService.useList();

    const { update: updateUserApi } = UserService.useUpdate();
    const { delete: deleteUserRoleApi } = UserRoleService.useDelete();
    const { create: createUserRoleApi } = UserRoleService.useCreate();
    const { update: updateInstructorProfileApi } = InstructorProfileService.useUpdate();

    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [user, setUser] = useState<UserRecord>(new UserRecord());
    const { organizations } = useOrganizations({});
    const { providers } = useProviders({
        isActive: true,
    });

    const providerAdminProviders = useMemo(() => {
        const items = [...providers];
        if (user.provider != null && !items.some((p) => p.id === user.provider?.id)) {
            items.push(user.provider);
        }
        return items;
    }, [providers, user.provider]);

    const optionalInstructorProviders = useMemo(() => {
        const items = [...providers];
        if (
            user.instructorProfile?.provider != null &&
            !items.some((p) => p.id === user.instructorProfile?.provider?.id)
        ) {
            items.push(user.instructorProfile.provider);
        }
        return items;
    }, [providers, user.instructorProfile?.provider]);

    const { roles } = useRoles({});
    const [currentAndDeletedUserRoles, setCurrentAndDeletedUserRoles] = useState<UserRoleRecord[]>(
        []
    );

    // Determine if instructor has future events.
    const isInstructor = useMemo(
        () => user.userRoles?.some((ur) => ur.role?.roleType === RoleType.Instructor) ?? false,
        [user.userRoles]
    );

    const today = DateUtils.formatDateCustom(new Date().toString(), "YYYY-MM-DD");
    const { events: liveEvents } = useEvents({
        instructorId: isInstructor ? userId : -1,
        startDate: today,
        status: PublishStatus.Live,
    });

    // Determine if the user has any OLL or ILT contracts.
    const { contractSummaries: ollContracts } = useOrganizationOLLContractSummaries({
        includeWithdrawn: false,
        organizationId: user?.organizationId ?? -1,
    });

    const { contractSummaries: iltContracts } = useOrganizationILTContractSummaries({
        organizationId: user?.organizationId ?? -1,
    });

    const availableRoles = useMemo((): RoleRecord[] => {
        const nfpaRoles = roles.filter((role: RoleRecord) =>
            NfpaRoleTypes.some((roleType: RoleType) => role.roleType === roleType)
        );

        const nonNfpaRoles = roles.filter(
            (role: RoleRecord) =>
                !NfpaRoleTypes.some((roleType: RoleType) => role.roleType === roleType)
        );

        return [...nfpaRoles, ...nonNfpaRoles];
    }, [roles]);

    const lockedRoleTypes = useMemo((): RoleType[] => {
        const roleTypes = [];

        if (isInstructor && liveEvents?.length > 0) {
            roleTypes.push(RoleType.Instructor);
        }
        return roleTypes;
    }, [liveEvents?.length, isInstructor]);

    const roleMessages = useMemo((): string[] => {
        const roleMessages = [];

        if (isInstructor && liveEvents?.length > 0) {
            roleMessages.push(t("instructorRoleCannotBeRemovedDueToIncompleteEventAssignments"));
        }

        if ((user.organizationId != null && ollContracts?.length > 0) || iltContracts?.length > 0) {
            roleMessages.push(t("clientAdminRoleCurrentlyHasAnActiveContractInTheLMS"));
        }

        return roleMessages;
    }, [
        liveEvents?.length,
        iltContracts?.length,
        isInstructor,
        ollContracts?.length,
        user.organizationId,
    ]);

    // =================================================================================================
    // #region API Calls
    // =================================================================================================

    const fetchData = useCallback(async () => {
        if (userId <= 0) {
            return;
        }

        const pathParams: UserResourcePathParams = {
            id: userId,
        };

        const queryParams: UserResourceQueryParams = {
            includeOrganization: true,
            includeProvider: true,
            includeRoles: true,
            includeInstructorProfile: true,
        };

        const userRoleQueryParams: ListUserRolesQueryParams = {
            userId: userId,
            includeDeleted: true,
            includeRole: true,
        };

        try {
            const getResponse = await getUserApi(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            setUser(getResult.resultObject);

            const userRolesResponse = await listUserRoles(userRoleQueryParams);

            if (
                userRolesResponse?.resultObjects == null ||
                userRolesResponse?.results == null ||
                userRolesResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            const currentAndDeletedUserRoles = userRolesResponse.resultObjects;
            setCurrentAndDeletedUserRoles(currentAndDeletedUserRoles);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheUsers"));
        }
    }, [userId, getUserApi, listUserRoles]);

    const updateUser = useCallback(
        async (userToSave: UserRecord): Promise<UserRecord | null> => {
            const pathParams: UpdateUserPathParams = {
                id: userToSave.id!,
            };
            try {
                const updateUserResponse = await updateUserApi(userToSave, pathParams);
                const updateUserResult = updateUserResponse?.result;

                if (updateUserResult?.resultObject == null || updateUserResult.hasErrors()) {
                    throw new Error();
                }
                return updateUserResult.resultObject;
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingTheUsers"));
                return null;
            }
        },
        [updateUserApi]
    );

    const createUserRole = useCallback(
        async (userId: number, roleId: number): Promise<boolean> => {
            try {
                const createUserRoleResponse = await createUserRoleApi(
                    new UserRoleRecord({ userId: userId, roleId: roleId })
                );
                const createUserRoleResult = createUserRoleResponse?.result;

                if (
                    createUserRoleResult?.resultObject == null ||
                    createUserRoleResult.hasErrors()
                ) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueCreatingTheUserRole"));
                return false;
            }
            return true;
        },
        [createUserRoleApi]
    );

    const deleteUserRole = useCallback(
        async (userRoleId: number): Promise<boolean> => {
            try {
                const deleteUserRoleResponse = await deleteUserRoleApi(userRoleId);
                const deleteUserRoleResult = deleteUserRoleResponse?.result;

                if (deleteUserRoleResult?.hasErrors()) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingTheUserRole"));
                return false;
            }
            return true;
        },
        [deleteUserRoleApi]
    );

    const updateInstructorProfileForUser = useCallback(
        async (instructorProfile: InstructorProfileRecord): Promise<boolean> => {
            try {
                if (instructorProfile?.id == null) {
                    throw new Error();
                }

                if (
                    !(await updateInstructorProfileApi(instructorProfile, {
                        id: instructorProfile.id,
                    }))
                ) {
                    throw new Error();
                }

                return true;
            } catch (error) {
                ToastManager.error(t("instructorRoleWasAddedButSavingInstructorInformationFailed"));
                return false;
            }
        },
        [updateInstructorProfileApi]
    );

    const saveUserEditForm = useCallback(
        async (
            userToSave: UserRecord,
            roleIdsToAdd: number[],
            userRoleIdsToRemove: number[]
        ): Promise<boolean> => {
            let updateSuccess = true;

            const userId = userToSave.id ?? 0;

            if (userId <= 0) {
                return !updateSuccess;
            }

            const updatedUser = await updateUser(userToSave);

            if (updatedUser == null) {
                return false;
            }

            for (const roleId of roleIdsToAdd) {
                updateSuccess = updateSuccess && (await createUserRole(userId, roleId));
            }

            for (const userRoleId of userRoleIdsToRemove) {
                updateSuccess = updateSuccess && (await deleteUserRole(userRoleId));
            }

            const existingRoleTypes = user.userRoles?.flatMap((ur) => ur.role?.roleType!);
            const roleTypesToAdd = availableRoles
                .filter((r) => roleIdsToAdd.includes(r.id!))
                .flatMap((r) => r.roleType!);
            const roleTypesToRemove = user.userRoles
                ?.filter((ur) => userRoleIdsToRemove.includes(ur.id!))
                .flatMap((ur) => ur.role?.roleType!);

            const updatedRoleTypes = getUpdatedUserRoles(
                existingRoleTypes,
                roleTypesToAdd,
                roleTypesToRemove
            );

            if (
                updatedRoleTypes.includes(RoleType.Instructor) &&
                updatedUser.instructorProfile != null &&
                userToSave?.instructorProfile != null
            ) {
                const instructorProfileWithChanges = updatedUser.instructorProfile?.with({
                    excludeFromAenDirectory: userToSave.instructorProfile?.excludeFromAenDirectory,
                    providerId: userToSave.instructorProfile?.providerId,
                    status: userToSave.instructorProfile?.status,
                });

                await updateInstructorProfileForUser(instructorProfileWithChanges);
            }

            fetchData();

            return updateSuccess;
        },
        [
            availableRoles,
            createUserRole,
            deleteUserRole,
            fetchData,
            updateInstructorProfileForUser,
            updateUser,
            user.userRoles,
        ]
    );

    const getUpdatedUserRoles = (
        existingRoles?: RoleType[],
        rolesToAdd?: RoleType[],
        rolesToRemove?: RoleType[]
    ): RoleType[] => {
        return (
            existingRoles
                ?.filter((role) => !rolesToRemove?.includes(role))
                .concat(rolesToAdd ?? []) ?? []
        );
    };

    // #endregion API Calls

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const navItems = useMemo(() => {
        const items: UserDetailSidebarNavItem[] = [
            {
                path: sitemap.admin.userManagement.users.info.default,
                text: "basicInformation",
            },
        ];

        if (currentAndDeletedUserRoles?.some((ur) => ur.role?.roleType === RoleType.Learner)) {
            items.push({
                path: sitemap.admin.userManagement.users.trainings.default,
                text: "trainings",
            });
        }

        if (currentAndDeletedUserRoles?.some((ur) => ur.role?.roleType === RoleType.Instructor)) {
            items.push({
                path: sitemap.admin.userManagement.users.instructor.default,
                text: "instructor",
            });
        }

        return items;
    }, [currentAndDeletedUserRoles]);

    return (
        <UserContext.Provider value={{ record: user, setRecord: setUser }}>
            <div className={CSS_CLASS_NAME}>
                <UserDetailHeader user={user} onEditUserClick={() => setShowEditUserModal(true)} />
                <SideContentLeftLayout sidebarElement={<UserDetailSidebar navItems={navItems} />}>
                    <Outlet />
                </SideContentLeftLayout>
            </div>
            {user != null && (
                <EditUserModal
                    open={showEditUserModal}
                    setOpen={setShowEditUserModal}
                    userToEdit={user}
                    availableRoles={availableRoles}
                    lockedRoleTypes={lockedRoleTypes}
                    roleMessages={roleMessages}
                    organizations={organizations}
                    providerAdminProviders={providerAdminProviders}
                    instructorProviders={optionalInstructorProviders}
                    onUserEditFormSave={saveUserEditForm}
                />
            )}
        </UserContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserManagementLayout };

// #endregion Exports
