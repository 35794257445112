import axios from "axios";
import { ProductDetailsArchiveRecord } from "models/view-models/products/product-details-archive-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "productdetailsarchive";
const resourceType: typeof ProductDetailsArchiveRecord = ProductDetailsArchiveRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetProductDetailsArchivePathParams {
    id: number;
}

export interface ListProductDetailsArchivesQueryParams {
    productId: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductDetailsArchiveService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        ProductDetailsArchiveRecord,
        ListProductDetailsArchivesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDetailsArchiveService };

// #endregion Exports
