import React, { useCallback, useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseContent } from "models/interfaces/courses/course-content";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    CourseVersionService,
    GetCourseVersionPathParams,
    GetCourseVersionQueryParams,
    PatchCourseVersionServiceData,
    PatchCourseVersionServiceParams,
} from "utilities/services/courses/course-version-service";
import { DataTable } from "../../../tables/data-table/data-table";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { HeaderActions } from "components/header-actions/header-actions";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { Paragraph, ParagraphStyle } from "../../../typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { SelectOption } from "components/form/inputs/select/select";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./course-version-activation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseVersionActivationModalProps {
    courseVersionId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-activation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseVersionActivationModal: React.FC<CourseVersionActivationModalProps> = ({
    courseVersionId,
    open,
    setOpen,
    setRefresh,
}): JSX.Element => {
    const navigate = useNavigate();
    const { get: getCourseVersion } = CourseVersionService.useGet();
    const { activate: courseVersionPatch } = CourseVersionService.useActivate();
    const [courseVersion, setCourseVersion] = useState<CourseVersionRecord>(
        new CourseVersionRecord()
    );
    const [applyToInProgressLearners, setApplyToInProgressLearners] = useState<
        boolean | undefined
    >();
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");

    const assignmentSelectOptions: SelectOption[] = [
        { text: "Apply", value: "true" },
        { text: "Do Not Apply", value: "false" },
    ];

    const fetchData = useCallback(async (): Promise<void> => {
        if (courseVersionId > 0) {
            const pathParams: GetCourseVersionPathParams = {
                id: courseVersionId,
            };
            const queryParams: GetCourseVersionQueryParams = {
                includeContent: true,
                includeScormPackage: true,
            };

            const getResponse = await getCourseVersion(pathParams, queryParams);
            const getResult = getResponse?.result;
            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            getResult.resultObject.courseContents.sort(
                (a: CourseContentRecord, b: CourseContentRecord) => a.sortOrder! - b.sortOrder!
            );

            setCourseVersion(getResult.resultObject);
        }
        return;
    }, [courseVersionId, getCourseVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleApplyToLearnersChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        if (event.target.value === "true") {
            setApplyToInProgressLearners(true);
        } else {
            setApplyToInProgressLearners(false);
        }
    };

    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => {
                setOpen(false);
                setApplyToInProgressLearners(undefined);
                setChangeLogDescription("");
            },
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("activateVersion"),
            disabled:
                StringUtils.isEmpty(changeLogDescription) || applyToInProgressLearners == null,
            onClick: () => handleActivatingCourse(),
            style: ButtonStyle.Primary,
        },
    ];

    const handleActivatingCourse = () => {
        setOpen(false);
        activateCourseVersion(courseVersion.id!);
    };

    const activateCourseVersion = async (id: number): Promise<boolean> => {
        if (!id) {
            return false;
        }

        if (applyToInProgressLearners == null) {
            return false;
        }
        try {
            const patchCourseVersionServiceParams: PatchCourseVersionServiceParams = {
                id: id,
            };

            const activateCourseVersion: PatchCourseVersionServiceData = {
                applyToInProgressLearners: applyToInProgressLearners,
                changeLogDescription: changeLogDescription,
            };

            const patchCourseVersionResponse = await courseVersionPatch(
                patchCourseVersionServiceParams,
                activateCourseVersion
            );

            const patchCourseResult = patchCourseVersionResponse?.result;

            if (patchCourseResult?.resultObject == null || patchCourseResult.hasErrors()) {
                alert(t("activatingCourseFailed"));
            }
            setCourseVersion(courseVersion.with({ status: ActiveStatus.Active }));
            setRefresh(true);
            ToastManager.success(t("courseIsNowActive"));

            const path = RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.course.edit.versionList, {
                    id: courseVersion.courseId,
                })
            );
            navigate(path);
        } catch {
            ToastManager.error(t("failedToActivateCourse"));
            return false;
        }
        return true;
    };

    const onModalClose = (): void => {
        setOpen(false);
        setApplyToInProgressLearners(undefined);
        setChangeLogDescription("");
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("activationSummary")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                    priority={HeadingPriority.H5}>
                    {t("youAreActivatingVersionCourseVersionVersionId", {
                        courseVersionVersionId: courseVersion.versionId,
                    })}
                </Heading>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading-description`}
                    style={ParagraphStyle.Light}>
                    {t("takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving")}
                </Paragraph>
                <div className={`${CSS_CLASS_NAME}__details ${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__details__header`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("changeLog")}
                        </Heading>
                    </div>
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t(
                            "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersion"
                        )}
                    />

                    <div className={`${CSS_CLASS_NAME}__assignment`}>
                        {t(
                            "howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressInTheCourse"
                        )}
                        <FormSelect
                            ariaLabelledBy={t("selectAssignment")}
                            cssClassName={`${CSS_CLASS_NAME}__select-assignment`}
                            formFieldName={t("selectAssignment")}
                            id="selectAssignment"
                            label=""
                            onChange={handleApplyToLearnersChange}
                            placeholder={t("selectAssignment")}
                            options={assignmentSelectOptions}
                            value={applyToInProgressLearners?.toString()}
                        />
                    </div>

                    <FormTextArea
                        ariaLabelledBy={t("describeYourChanges")}
                        cssClassName={`${CSS_CLASS_NAME}__text-area`}
                        formFieldName={t("describeYourChanges")}
                        id="describeYourChanges"
                        label={t("describeYourChanges")}
                        maxLength={1000}
                        onChange={handleChangeLogChange}
                        placeholder={t("describeYourChanges")}
                        required={true}
                        rows={6}
                        value={changeLogDescription}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("zipPackage")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("name")}</th>
                                <th className="file">{t("fileName")}</th>
                                <th className="status">{t("importStatus")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{courseVersion.scormPackage?.name}</td>
                                <td className="file">
                                    {courseVersion.scormPackage?.file?.fileName}
                                </td>
                                <td className="status">
                                    {ScormPackageImportStatus[courseVersion.scormPackage?.status!]}
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <HeaderActions
                        title={t("content")}
                        headingPriority={HeadingPriority.H2}
                        headingSize={HeadingSize.XSmall}>
                        <ToggleLabel
                            checked={courseVersion?.hasNoContent}
                            disabled
                            id="course-no-content"
                            label={t("thisCourseHasNoContent")}
                        />
                    </HeaderActions>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("order")}</th>
                                <th>{t("name")}</th>
                                <th>{t("description")}</th>
                                <th>{t("fileName")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseVersion.courseContents &&
                                courseVersion.courseContents.map((courseContent: CourseContent) => (
                                    <tr key={courseContent.id}>
                                        <td>{courseContent.sortOrder}</td>
                                        <td>{courseContent.content?.name}</td>
                                        <td>{courseContent.content?.description}</td>
                                        <td>{courseContent.content?.file?.fileName}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionActivationModal };

// #endregion Exports
