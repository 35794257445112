import React, { useMemo } from "react";
import { FormField, FormFieldProps } from "components/form/form-field/form-field";
import { Icon } from "../../icons/icon";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Icons } from "../../icons/constants/icons";
import { InputProperties } from "components/form/input-properties";
import { StringUtils } from "andculturecode-javascript-core";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./form-radio-card-list-with-description.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum RadioCardStyle {
    Horizontal = "horizontal",
    Vertical = "vertical",
}

export enum RadioCardRows {
    One = "one-row",
    Two = "two-rows",
    Three = "three-rows",
}

export enum RadioCardColumns {
    One = "one-column",
    Two = "two-columns",
    Three = "three-columns",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RadioCardWithDescription {
    icon: Icons;
    text: TranslatedCopy;
    description?: TranslatedCopy;
    value: string;
}

export interface FormRadioCardListWithDescriptionProps extends FormFieldProps, InputProperties {
    columns?: RadioCardColumns;
    iconSize?: IconSizes;
    onChange?: (value: string) => void;
    radioCards: RadioCardWithDescription[];
    rows?: RadioCardRows;
    style?: RadioCardStyle;
    value?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-radio-card-list-with-description";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormRadioCardListWithDescription: React.FC<FormRadioCardListWithDescriptionProps> = (
    props: FormRadioCardListWithDescriptionProps
) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const columns = props.columns == null ? RadioCardColumns.One : props.columns;
    const rows = props.rows == null ? RadioCardRows.One : props.rows;

    const { readOnly } = useReadOnly();

    const isDisabled = useMemo(() => props.disabled || readOnly, [props.disabled, readOnly]);

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (isDisabled) {
        cssClassNames.push("disabled");
    }

    if (props.style) {
        cssClassNames.push(props.style);
    }

    const handleChange = (value: string) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
        if (isDisabled) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        handleChange(value);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, value: string) => {
        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        if (event.key === " ") {
            event.preventDefault();
            handleChange(value);
        }
    };

    return (
        <FormField
            cssClassName={cssClassNames.join(" ")}
            label={props.label}
            labelScreenReaderOnly={props.labelScreenReaderOnly}
            required={props.required}
            formFieldName={props.formFieldName}>
            <div className={`${CSS_CLASS_NAME}__cards ${columns} ${rows}`}>
                {props.radioCards.map((card) => {
                    return (
                        <button
                            key={card.value}
                            onClick={(event) => handleClick(event, card.value)}
                            onKeyDown={(event) => onKeyDown(event, card.value)}
                            tabIndex={isDisabled ? -1 : 0}>
                            <label
                                key={`${props.formFieldName}-${card.value}`}
                                className="radio-card"
                                htmlFor={`${props.formFieldName}-${card.value}`}>
                                <input
                                    id={`${props.formFieldName}-${card.value}`}
                                    type="radio"
                                    name={props.formFieldName}
                                    checked={card.value === props.value}
                                    disabled={isDisabled}
                                    onChange={() => {}}
                                />
                                <div className="radio-card__details">
                                    <div className="radio-card__icon">
                                        <Icon
                                            size={props.iconSize ?? IconSizes.Large}
                                            type={card.icon}
                                        />
                                    </div>
                                    <div className="radio-card__text">
                                        <h1 className="radio-card__title">{t(card.text)}</h1>
                                        <p className="radio-card__description">
                                            {card.description == null ? "" : t(card.description)}
                                        </p>
                                    </div>
                                </div>
                            </label>
                        </button>
                    );
                })}
            </div>
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormRadioCardListWithDescription };

// #endregion Exports
