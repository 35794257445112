import React from "react";
import "./form-label.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormLabelProps {
    label: string;
    formFieldName: string;
    required?: boolean;
    screenReaderOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormLabel: React.FC<FormLabelProps> = (props: FormLabelProps) => {
    let className = "";

    if (props.screenReaderOnly) {
        className = "sr-only";
    }

    return (
        <label
            className={className}
            htmlFor={props.formFieldName}>
            {props.label}
            {props.required && <span>{" *"}</span>}
        </label>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormLabel };
