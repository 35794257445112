import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { Permission } from "utilities/enumerations/authorization/permission";
import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";

// -------------------------------------------------------------------------------------------------
// #region Constant
// -------------------------------------------------------------------------------------------------

const DenyAllAuthorizationRules: RoleAuthorizationRules = EnumUtils.populateDefaultValues(
    AccessControlKeys,
    Permission.Deny
);

const DefaultRoleAuthorizationRules: RoleAuthorizationRules = {
    ...DenyAllAuthorizationRules,
    [AccessControlKeys.UserLoginPage]: Permission.Allow,
    [AccessControlKeys.AzureLoginPage]: Permission.Allow,
    [AccessControlKeys.NoAccessPage]: Permission.Allow,
    [AccessControlKeys.NotFoundPage]: Permission.Allow,
    [AccessControlKeys.ProfilePage]: Permission.Allow,
    [AccessControlKeys.SupportPage]: Permission.Allow,
};

// #endregion Constant

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { DefaultRoleAuthorizationRules };

// #endregion Exports
