import React, { useMemo, useReducer, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AddGroupUsersModal } from "components/user-management/groups/add-group-users-modal/add-group-users-modal";
import { Badge } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import {
    CreateGroupUserParams,
    GroupUsersService,
} from "utilities/services/groups/group-users-service";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { UseGroupUsers } from "utilities/hooks/models/groups/use-group-users";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useParams } from "react-router-dom";
import { userSearchReducer } from "../../users/user-list-page/user-search-reducer";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { Modal, ModalAction } from "components/modal/modal";
import { GroupUserRecord } from "models/view-models/groups/group-user-record";
import "./groups-associated-users-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupsAssociatedUsersPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "groups-associated-users-page";
const ITEMS_PER_PAGE = 20;
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupsAssociatedUsersPage: React.FC<GroupsAssociatedUsersPageProps> = validatePageAccess(
    AccessControlKeys.GroupsAssociatedUsersPage
)((): JSX.Element => {
    const { create: groupUsersCreateApi } = GroupUsersService.useCreate();
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [groupUserToRemove, setGroupUserToRemove] = useState<GroupUserRecord | null>(null);
    const [{ currentPage }, dispatch] = useReducer(userSearchReducer, {
        searchText: "",
        currentPage: 1,
    });

    const { id: groupIdParam } = useParams();
    const groupId = useMemo(() => NumberUtils.parseInt(groupIdParam) ?? 0, [groupIdParam]);

    const {
        groupUsers,
        rowCount: userCount,
        setRefresh,
        deleteGroupUser,
    } = UseGroupUsers({
        groupId: groupId,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
        searchText: debouncedSearchText,
    });

    const confirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                if (groupUserToRemove) {
                    deleteGroupUser(groupUserToRemove);
                }
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const removeGroupUser = (groupUser: GroupUserRecord) => {
        setGroupUserToRemove(groupUser);
        setShowConfirmationModal(true);
    };

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };
    const getViewUserPath = (userId: number) => {
        return RouteUtils.replacePathParams(sitemap.admin.userManagement.users.info.default, {
            id: userId,
        });
    };

    const onPageClick = (pageNumber: number) => {
        dispatch({
            type: "update_currentPage",
            value: pageNumber,
        });
    };

    const saveGroupUsers = async (userIds: number[]): Promise<boolean> => {
        try {
            if (userIds == null) {
                throw new Error(t("userWasUndefined"));
            }

            let createGroupUserParams: CreateGroupUserParams = {
                groupId: groupId,
                userIds: userIds,
            };

            const createGroupUserResponse = await groupUsersCreateApi(createGroupUserParams);
            const createGroupUserResult = createGroupUserResponse?.result;

            if (createGroupUserResult?.resultObject == null || createGroupUserResult.hasErrors()) {
                throw new Error(t("creatingGroupUsersFailed"));
            }
        } catch (error: any) {
            if (error === null) {
                ToastManager.error(t("emptyString"));
            } else {
                ToastManager.error(`${error}`);
            }
            return false;
        }

        return true;
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <h2>{t("associatedUsers")}</h2>
                            <div className={`${CSS_CLASS_NAME}__header__actions__search`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id={"filterOppName"}
                                    placeholder={t("typeToSearchByNameOrEmail")}
                                    searchTextInputValue={searchText}
                                />
                                <ButtonIcon
                                    onClick={() => setShowAddUserModal(true)}
                                    text={t("addNewUser")}
                                    buttonSize={ButtonSize.Medium}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Plus}
                                />
                            </div>
                        </div>
                        {CollectionUtils.hasValues(groupUsers) ? (
                            <DataTable>
                                <thead>
                                    <tr>
                                        <th className="id">{t("userId")}</th>
                                        <th className="name">{t("name")}</th>
                                        <th className="email">{t("email")}</th>
                                        <th className="action">
                                            <span className="sr-only">{t("action")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupUsers.map((groupUser) => (
                                        <>
                                            <tr key={`user-row-${groupUser.id}}`}>
                                                <td className="id">{groupUser.user?.id}</td>
                                                <td className="name">
                                                    {groupUser.user?.invitePending() ? (
                                                        <Badge text={t("invitePending")} />
                                                    ) : (
                                                        groupUser.user?.getFirstAndLastName()
                                                    )}
                                                </td>
                                                <td className="email">{groupUser.user?.email}</td>
                                                <td className="action">
                                                    <ContextMenu>
                                                        <ContextMenuAnchor
                                                            hrefPath={getViewUserPath(
                                                                groupUser.user?.id ?? 0
                                                            )}
                                                            displayName={t("viewUserInfo")}
                                                        />
                                                        <ContextMenuButton
                                                            onClick={() => {
                                                                removeGroupUser(groupUser);
                                                            }}
                                                            displayName={t("removeUser")}
                                                        />
                                                    </ContextMenu>
                                                </td>
                                            </tr>
                                            <Modal
                                                isOpen={showConfirmationModal}
                                                onModalClose={() => {}}
                                                actions={confirmationActions}
                                                modalStyle={"-inverted"}>
                                                {t(
                                                    "areYouSureYouWantToRemoveThisUserFromThisGroup",
                                                    {
                                                        user: groupUserToRemove?.user?.getFirstAndLastName(),
                                                        group: groupUser.group?.name,
                                                    }
                                                )}
                                            </Modal>
                                        </>
                                    ))}
                                </tbody>
                            </DataTable>
                        ) : (
                            <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
                        )}
                    </SkipNavContent>
                </div>
            </div>
            {groupUsers != null && groupUsers.length > 0 && (
                <div className="footer">
                    <Pager
                        currentPage={currentPage}
                        totalPages={userCount / ITEMS_PER_PAGE}
                        onPageClick={onPageClick}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalItems={userCount}
                    />
                </div>
            )}
            <AddGroupUsersModal
                fetchGroupUsers={() => setRefresh(true)}
                groupId={groupId}
                saveGroupUsers={saveGroupUsers}
                setShowAddUserModal={setShowAddUserModal}
                showAddUserModal={showAddUserModal}
            />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupsAssociatedUsersPage };

// #endregion Exports
