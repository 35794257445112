import { ProductEnrollmentSummaryRecord } from "models/view-models/enrollments/product-enrollment-summary-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "products/:productId/enrollments";
const resourceType: typeof ProductEnrollmentSummaryRecord = ProductEnrollmentSummaryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListProductEnrollmentSummariesPathParams {
    productId: number;
}

export interface ListProductEnrollmentSummariesQueryParams {
    contractId?: number;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductEnrollmentSummariesService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        ProductEnrollmentSummaryRecord,
        ListProductEnrollmentSummariesPathParams,
        ListProductEnrollmentSummariesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductEnrollmentSummariesService };

// #endregion Exports
