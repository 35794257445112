import React from "react";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Unit } from "models/interfaces/units/unit";
import { ToggleLabel, ToggleLabelDirection } from "components/toggle/toggle-label/toggle-label";
import { UnitRecord } from "models/view-models/units/unit-record";
import { t } from "utilities/localization/t";
import "./unit-details-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitDetailsFormProps {
    unit: UnitRecord;
    onUnitChange: (unit: UnitRecord) => void;
    completeProductUnitsInOrder?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "unit-details-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitDetailsForm: React.FC<UnitDetailsFormProps> = ({
    completeProductUnitsInOrder,
    onUnitChange,
    unit,
}): JSX.Element => {
    const updateUnit = (values: Partial<Unit>): void => {
        onUnitChange(unit.with(values));
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateUnit({ name: event.target.value });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateUnit({ description: event.target.value });
    };

    const handleCompleteCoursesInOrderChange = (unit: UnitRecord): void => {
        updateUnit({ completeCoursesInOrder: !unit.completeCoursesInOrder });
    };

    const handleCompleteUnitBeforeMovingForwardChange = (unit: UnitRecord): void => {
        updateUnit({ completeUnitBeforeMovingForward: !unit.completeUnitBeforeMovingForward });
    };

    const handleOptionalChange = (unit: UnitRecord): void => {
        updateUnit({
            optional: !unit.optional,
            completeCoursesInOrder: false,
            completeUnitBeforeMovingForward: false,
        });
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <h4>{t("unitDetails")}</h4>
            <FormTextInput
                ariaLabelledBy={t("unitName")}
                autoFocus={true}
                formFieldName="unitName"
                id="unitName"
                label={t("unitName")}
                maxLength={60}
                onChange={handleNameChange}
                placeholder={t("enterUnitName")}
                required={true}
                type={InputTypes.Text}
                value={unit.name}
            />
            <FormTextArea
                ariaLabelledBy={t("unitDescription")}
                formFieldName="unitDescription"
                id="unitDescription"
                label={t("unitDescription")}
                maxLength={500}
                onChange={handleDescriptionChange}
                placeholder={t("enterUnitDescription")}
                rows={6}
                value={unit.description ?? ""}
            />
            <h4 className="-section">{t("unitSettings")}</h4>
            <ToggleLabel
                checked={unit.optional}
                direction={ToggleLabelDirection.Right}
                id="unit-optional"
                label={t("thisUnitIsOptional")}
                onToggle={(checked: boolean) => handleOptionalChange(unit)}
            />
            <ToggleLabel
                checked={unit.completeUnitBeforeMovingForward && !completeProductUnitsInOrder}
                direction={ToggleLabelDirection.Right}
                disabled={unit.optional || completeProductUnitsInOrder}
                id="complete-unit"
                label={t("completeUnitBeforeMovingForward")}
                onToggle={(checked: boolean) => handleCompleteUnitBeforeMovingForwardChange(unit)}
            />
            <ToggleLabel
                checked={unit.completeCoursesInOrder}
                direction={ToggleLabelDirection.Right}
                disabled={unit.optional}
                id="complete-courses"
                label={t("completeCoursesInOrder")}
                onToggle={(checked: boolean) => handleCompleteCoursesInOrderChange(unit)}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitDetailsForm };

// #endregion Exports
