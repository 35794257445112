import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ContractType } from "models/enumerations/contracts/contract-type";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import {
    ContractService,
    ListContractsQueryParams,
} from "utilities/services/contracts/contract-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseContractHook {
    contracts: ContractRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface UseContractHookOptions {
    eventId?: number | string;
    organizationId?: number | string;
    contractType?: ContractType;
    includeEnrollments?: boolean;
    includeProduct?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

export function useContracts(props: UseContractHookOptions): UseContractHook {
    const [isLoading, setIsLoading] = useState(true);
    const [contracts, setContracts] = useState<ContractRecord[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listContractAPI } = ContractService.useList();
    const organizationId = NumberUtils.parseInt(props.organizationId) ?? 0;
    const contractType = props.contractType;
    const includeEnrollments = props.includeEnrollments ?? false;
    const includeProduct = props.includeProduct ?? false;
    const searchText = props.searchText;
    const skip = props.skip;
    const take = props.take;

    useEffect(() => {
        if (isNaN(organizationId) || organizationId < 1) {
            return;
        }

        try {
            (async function getContracts() {
                const listContractsQueryParams: ListContractsQueryParams = {
                    organizationIds: [organizationId],
                    contractType: contractType,
                    includeEnrollments: includeEnrollments,
                    includeProduct: includeProduct,
                    searchText: searchText,
                    skip: skip,
                    take: take,
                };

                const { result, resultObjects, rowCount } = await listContractAPI(
                    listContractsQueryParams
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setRowCount(rowCount);
                setContracts(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningContracts"));
        }
    }, [
        contractType,
        includeEnrollments,
        includeProduct,
        listContractAPI,
        organizationId,
        searchText,
        skip,
        take,
    ]);

    return {
        errors,
        isLoading,
        contracts,
        rowCount,
    };
}
