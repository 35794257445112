import { Icon } from "components/icons/icon";
import { t } from "utilities/localization/t";
import "./event-details-checklist-item.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventChecklistItemProps {
    isChecked?: boolean;
    label: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-details-checklist-item";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventChecklistItem: React.FC<EventChecklistItemProps> = (
    props: EventChecklistItemProps
): JSX.Element | null => {
    return (
        <div className={CSS_CLASS_NAME}>
            {
                // if
                props.isChecked && (
                    <Icon
                        cssClassName={`${CSS_CLASS_NAME}__icon checked`}
                        type={"CircleChecked"}
                        ariaLabel={t("checkmarkChecked")}
                    />
                )
            }

            {
                // if
                !props.isChecked && (
                    <Icon
                        cssClassName={`${CSS_CLASS_NAME}__icon unchecked`}
                        type={"CircleUnchecked"}
                        ariaLabel={t("checkmarkUnchecked")}
                    />
                )
            }

            <span className={`${CSS_CLASS_NAME}__label`}>{props.label}</span>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChecklistItem };

// #endregion Exports
