import React, { useEffect } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerDashboardProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerDashboard: React.FC<LearnerDashboardProps> = (): null => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(sitemap.learner.learningAssignments.inProgress);
    }, [navigate]);

    return null;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerDashboard };

// #endregion Exports
