import React, { Fragment, ReactNode, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ModalHeader } from "./modal-header/modal-header";
import { StringUtils } from "utilities/string-utils";
import "./modal.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ModalAction {
    buttonText: string;
    disabled?: boolean;
    onClick: () => void;
    style?: ButtonStyle;
}

export interface ModalProps {
    actions?: ModalAction[];
    cssClassName?: string;
    headerActionContent?: any;
    isOpen: boolean;
    modalStyle?: string;
    banner?: ReactNode;
    onModalClose?: (close: boolean) => void;
    title?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "modal";
const DEFAULT_ACTION_STYLE: ButtonStyle = ButtonStyle.Secondary;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = (props) => {
    const { modalStyle = "" } = props;
    const cancelButtonRef = useRef(null);

    const commonTransitionProps = {
        as: Fragment,
        enter: "ease-out duration-300",
        leave: "ease-in duration-200",
    };

    const fadeInTransitionProps = {
        ...commonTransitionProps,
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
    };

    const panelExpandProps = {
        ...commonTransitionProps,
        enterFrom: "scale-95",
        enterTo: "scale-100",
        leaveFrom: "scale-100",
        leaveTo: "scale-95",
    };

    const handleModalClose = (close: boolean = false) => {
        if (props.onModalClose == null) {
            return;
        }

        props.onModalClose(close);
    };

    const showHeader = () => {
        if (StringUtils.hasValue(props.title)) {
            return (
                <ModalHeader
                    actionContent={props.headerActionContent}
                    title={props.title}
                    onModalClose={handleModalClose}
                />
            );
        }
    };

    useEffect(() => {
        const onEscapePress = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                handleModalClose(true);
            }
        };

        document.body.addEventListener("keydown", onEscapePress);
        return () => {
            document.body.removeEventListener("keydown", onEscapePress);
        };
    });

    const classNames: string[] = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        classNames.push(props.cssClassName);
    }

    return (
        <Transition.Root show={props.isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={classNames.join(" ")}
                initialFocus={cancelButtonRef}
                onClose={() => {}}>
                <Transition.Child {...fadeInTransitionProps}>
                    <div className={`${CSS_CLASS_NAME}__backdrop`}>
                        <div className={`${CSS_CLASS_NAME}__container`}>
                            <Transition.Child {...panelExpandProps}>
                                <Dialog.Panel className={`${CSS_CLASS_NAME}__panel${modalStyle}`}>
                                    {showHeader()}
                                    {props.banner}
                                    <div className={`${CSS_CLASS_NAME}__body${modalStyle}`}>
                                        <div className={`${CSS_CLASS_NAME}__body__content-wrap`}>
                                            {props.children}
                                        </div>
                                    </div>
                                    {props.actions !== undefined && (
                                        <footer
                                            className={`${CSS_CLASS_NAME}__footer${modalStyle}`}>
                                            {props.actions.map((action) => (
                                                <Button
                                                    disabled={action.disabled}
                                                    key={action.buttonText}
                                                    onClick={action.onClick}
                                                    style={action.style ?? DEFAULT_ACTION_STYLE}
                                                    text={action.buttonText}
                                                />
                                            ))}
                                        </footer>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Export
// -------------------------------------------------------------------------------------------------

export { Modal };

// #endregion Export
