import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { InputTypes } from "../../../form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { StringUtils } from "utilities/string-utils";
import { GroupRecord } from "models/view-models/groups/group-record";
import { Group } from "models/interfaces/groups/group";
import "./edit-group-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditGroupModalProps {
    groupToEdit: GroupRecord;
    open: boolean;
    onEditFormSave?: (group: GroupRecord) => Promise<boolean>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-group-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditGroupModal: React.FC<EditGroupModalProps> = ({
    open,
    setOpen,
    onEditFormSave,
    groupToEdit,
}): JSX.Element => {
    const initialGroup = useMemo(() => groupToEdit ?? new GroupRecord(), [groupToEdit]);
    useEffect(() => {
        setGroup(initialGroup);
    }, [initialGroup]);

    const [group, setGroup] = useState<GroupRecord>(initialGroup);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const updateGroup = (values: Partial<Group>): void => {
        setIsDirty(true);
        setGroup(group.with(values));
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            onClick: () => handleSave(),
            disabled: !StringUtils.hasValue(group.name),
            style: ButtonStyle.Primary,
        },
    ];

    const cancelConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                setOpen(false);
                setShowConfirmationModal(false);
                setIsDirty(false);
                setGroup(initialGroup);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (isDirty) {
            setShowConfirmationModal(true);
        } else {
            setOpen(false);
        }
    };

    const handleSave = async () => {
        if (await onEditFormSave?.(group)) {
            // Perform Update Success Clean up.
            setIsDirty(false);
            setOpen(false);
            ToastManager.success(t("groupHasBeenUpdatedSuccessfully"));
        } else {
            // Inform user of failure.
            ToastManager.error(t("thereWasAnIssueUpdatingTheGroup"));
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editGroup")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <Paragraph size={ParagraphSize.Large}>{t("basicInformation")}</Paragraph>
                <FormTextInput
                    ariaLabelledBy={t("groupName")}
                    autoFocus={true}
                    formFieldName="groupName"
                    id="groupName"
                    label={t("groupName")}
                    maxLength={200}
                    onChange={(e) => updateGroup({ name: e.target.value })}
                    placeholder={t("enterGroupName")}
                    required={true}
                    type={InputTypes.Text}
                    value={group.name}
                />
                <FormTextInput
                    ariaLabelledBy={t("groupDescription")}
                    formFieldName="groupDescription"
                    id="groupDescription"
                    label={t("groupDescription")}
                    maxLength={500}
                    onChange={(e) => updateGroup({ description: e.target.value })}
                    placeholder={t("enterGroupDescription")}
                    type={InputTypes.Text}
                    value={group.description}
                />
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={cancelConfirmationActions}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditGroupModal };

// #endregion Exports
