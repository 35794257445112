import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum EventType {
    NFPACatalog = 0,
    Contract = 1,
    EducationNetwork = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EventTypeDisplayNames: Map<EventType, TranslatedCopy> = {
    [EventType.NFPACatalog]: "nfpaCatalogEvent",
    [EventType.Contract]: "contractEvent",
    [EventType.EducationNetwork]: "educationNetworkEvent",
};

const EventTypeBubbleDisplayNames: Map<EventType, TranslatedCopy> = {
    [EventType.NFPACatalog]: "nfpaCatalogEvent",
    [EventType.Contract]: "contractEvent",
    [EventType.EducationNetwork]: "enProviderEvent",
};

const EventTypeUserDisplayNames: Map<EventType, TranslatedCopy> = {
    [EventType.NFPACatalog]: "nfpaCatalog",
    [EventType.Contract]: "contract",
    [EventType.EducationNetwork]: "educationNetwork",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventType, EventTypeDisplayNames, EventTypeBubbleDisplayNames, EventTypeUserDisplayNames };

// #endregion Exports
