import React, { useState } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { Event } from "models/interfaces/events/event";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventScheduleExceptionGrantedModal } from "./event-schedule-exception-modal/event-schedule-exception-granted-modal";
import { EventScheduleExceptionModal } from "./event-schedule-exception-modal/event-schedule-exception-modal";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { Icons } from "components/icons/constants/icons";
import "./event-schedule-exception-manager.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleExceptionManagerProps {
    event: EventActiveRecord;
    readOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleExceptionManager: React.FC<EventScheduleExceptionManagerProps> = ({
    event,
    readOnly,
}) => {
    const [showModal, setShowModal] = useState(false);

    const updateEvent = (values: Partial<Event>): void => {
        if (event.isDraft()) {
            event.updateAndSave(values);
        } else {
            event.updateActiveRecord(values);
        }
    };

    const saveEventScheduleException = (eventScheduleException: EventScheduleExceptionRecord) => {
        if (eventScheduleException?.reason == null || eventScheduleException?.eventId == null) {
            throw new Error();
        }

        updateEvent({ eventScheduleException: eventScheduleException });
    };

    return (
        <>
            {event.eventScheduleException?.granted && (
                <ButtonIcon
                    onClick={() => setShowModal(true)}
                    buttonStyle={ButtonStyle.Success}
                    iconType={Icons.CheckmarkClipboard}
                    text={t("exceptionGranted")}
                />
            )}

            {!event.eventScheduleException?.granted && !readOnly && (
                <ButtonIcon
                    onClick={() => setShowModal(true)}
                    buttonStyle={ButtonStyle.Destructive}
                    iconType={Icons.EditCalendar}
                    text={t("grantException")}
                />
            )}

            {event.eventScheduleException?.granted && (
                <EventScheduleExceptionGrantedModal
                    eventScheduleException={event.eventScheduleException}
                    open={showModal}
                    setOpen={setShowModal}
                />
            )}

            {!event.eventScheduleException?.granted && (
                <EventScheduleExceptionModal
                    event={event}
                    open={showModal}
                    setOpen={setShowModal}
                    handleSaveEventScheduleException={saveEventScheduleException}
                />
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionManager };

// #endregion Exports
