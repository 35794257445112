import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentStatus {
    Complete = 0,
    Expired = 1,
    Canceled = 2,
    Withdrawn = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EnrollmentStatusDisplayNames: Map<EnrollmentStatus, TranslatedCopy> = {
    [EnrollmentStatus.Complete]: "complete",
    [EnrollmentStatus.Expired]: "incomplete",
    [EnrollmentStatus.Canceled]: "canceled",
    [EnrollmentStatus.Withdrawn]: "withdrawn",
};

const EnrollmentStatusStatusMap: Map<EnrollmentStatus, Status> = {
    [EnrollmentStatus.Complete]: Status.Success,
    [EnrollmentStatus.Expired]: Status.Default,
    [EnrollmentStatus.Canceled]: Status.Error,
    [EnrollmentStatus.Withdrawn]: Status.Error,
};

// #endregion Maps
export { EnrollmentStatus, EnrollmentStatusDisplayNames, EnrollmentStatusStatusMap };

// #endregion Exports
