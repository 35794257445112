import React from "react";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "../inputs/checkbox-input/checkbox";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";
import { InputTypes } from "components/form/enumerations/input-types";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormCheckboxInputProps extends FormFieldProps, InputProperties {
    checked: boolean;
    onBlur?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    onChange: (newValue: boolean) => void;
    size?: CheckboxSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormCheckboxInput: React.FC<FormCheckboxInputProps> = (props: FormCheckboxInputProps) => {
    const type = props.type ?? InputTypes.Text;

    return (
        <FormField
            cssClassName={props.cssClassName}
            formFieldName={props.formFieldName}
            label={""}
            required={props.required}>
            <CheckboxInput
                ariaLabelledBy={props.ariaLabelledBy}
                autoFocus={props.autoFocus}
                checked={props.checked}
                disabled={props.disabled}
                id={props.formFieldName}
                label={props.label}
                name={props.formFieldName}
                onBlur={props.onBlur}
                onChange={props.onChange}
                placeholder={props.placeholder}
                required={props.required}
                size={props.size}
                type={type}
                value={props.value}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormCheckboxInput };

// #endregion Exports
