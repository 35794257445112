import React, { useState } from "react";
import { EventProductCardSelectionManager } from "components/events/event-product-selection/event-product-card-selection/event-product-card-selection-manager";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Card } from "components/card/card";
import { EventProductRefineResults } from "components/events/event-product-selection/event-product-refine-results/event-product-refine-results";
import { EventProductSummary } from "components/events/event-product-selection/selected-product-summary/event-product-summary";
import { Modal, ModalAction } from "components/modal/modal";
import { ButtonStyle } from "components/buttons/button/button";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./admin-edit-event-product-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventProductPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-product-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventProductPage: React.FC<AdminEditEventProductPageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventProductPage
)((): JSX.Element => {
    const event = useEventActiveRecordContext();
    const [showProductRemovalConfirmationModal, setShowProductRemovalConfirmationModal] =
        useState(false);

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowProductRemovalConfirmationModal(false),
            style: ButtonStyle.Primary,
        },
        {
            buttonText: t("yesChange"),
            onClick: () => {
                event.patchEventProduct();
                setShowProductRemovalConfirmationModal(false);
            },
            style: ButtonStyle.Secondary,
        },
    ];

    const handleDeselectProduct = (): void => {
        setShowProductRemovalConfirmationModal(true);
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__main`}>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("product")}
                    </Heading>
                    <EventProductCardSelectionManager
                        event={event}
                        onDeselectProduct={handleDeselectProduct}
                    />
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__sidebar`}>
                <div className={`${CSS_CLASS_NAME}__sidebar__summary`}>
                    <Card>
                        <div className={`${CSS_CLASS_NAME}__sidebar__summary__filter`}>
                            <EventProductRefineResults />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__sidebar__summary__selection`}>
                            <EventProductSummary
                                event={event}
                                onDeselectProduct={handleDeselectProduct}
                            />
                        </div>
                    </Card>
                </div>
            </div>
            <Modal
                actions={confirmationActionArray}
                isOpen={showProductRemovalConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                <b>{t("areYouSureYouWouldLikeToChangeTheAssociatedProduct")}</b>
                <br />
                {t("byDoingSoAnyInformationEnteredInTheSchedule")}
            </Modal>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventProductPage };

// #endregion Exports
