import React, { useCallback } from "react";
import { Card } from "components/card/card";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import "./event-invoice-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInvoiceFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-invoice-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventInvoiceForm: React.FC<EventInvoiceFormProps> = ({ event }): JSX.Element => {
    const handleBlur = useCallback((): void => {
        if (event.isDraft()) {
            event.patchEventInvoice(event.hasBeenInvoiced, event.invoiceNotes);
        }
    }, [event]);

    const handleToggleChange = (checked: boolean): void => {
        if (event.isDraft()) {
            event.patchEventInvoice(checked, event.invoiceNotes);
        } else {
            event.updateActiveRecord({
                hasBeenInvoiced: checked,
            });
        }
    };

    function handleNotesChange(value: string): void {
        event.updateActiveRecord({
            invoiceNotes: value,
        });
    }

    return (
        <Card>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("eventInvoicing")}
                </Heading>
                <ToggleLabel
                    checked={event.hasBeenInvoiced ?? false}
                    id="has-been-invoiced"
                    label={t("hasbeeninvoiced")}
                    onToggle={(checked) => handleToggleChange(checked)}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__grid`}>
                <FormTextArea
                    ariaLabelledBy={t("notes")}
                    autoFocus={false}
                    formFieldName="InvoiceNotes"
                    id="InvoiceNotes"
                    rows={4}
                    label={t("notes")}
                    onBlur={handleBlur}
                    onChange={(e) => handleNotesChange(e.target.value)}
                    placeholder={t("enterNotes")}
                    required={false}
                    type={InputTypes.Text}
                    value={event.invoiceNotes}
                    disabled={!event.hasBeenInvoiced}
                    maxLength={200}
                />
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInvoiceForm };

// #endregion Exports
