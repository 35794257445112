import React, { useMemo } from "react";
import { Avatar } from "components/avatar/avatar";
import { Button, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import { sitemap } from "sitemap";
import "./instructor-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorCardProps {
    instructorId?: number;
    avatarFileId?: number;
    email: string;
    name: string;
    phoneNumber: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorCard: React.FC<InstructorCardProps> = ({
    instructorId,
    avatarFileId,
    email,
    name,
    phoneNumber,
}): JSX.Element => {
    const { record: GlobalState } = useGlobalState();
    const isInstructor = useMemo(
        () => GlobalState?.currentIdentity?.role?.roleType === RoleType.Instructor,
        [GlobalState?.currentIdentity?.role?.roleType]
    );

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__heading`}>
                <Heading priority={HeadingPriority.H3}>{t("instructor")}</Heading>
                <Paragraph style={ParagraphStyle.Light}>
                    {isInstructor
                        ? t("yourProfileAndInformationWillBeSharedWithLearnersAsAPointOfContact")
                        : t("forQuestionsAboutTheTrainingContact")}
                </Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__section`}>
                {instructorId == null ? (
                    <Paragraph style={ParagraphStyle.Label}>{t("noInstructorAssigned")}</Paragraph>
                ) : (
                    <>
                        <Avatar
                            cssClassName={`${CSS_CLASS_NAME}__section__icon`}
                            avatarFileId={avatarFileId}
                        />
                        <div className={`${CSS_CLASS_NAME}__section__info`}>
                            <Paragraph cssClassName="name" style={ParagraphStyle.Label}>
                                {name}
                            </Paragraph>
                            <Paragraph cssClassName="email" style={ParagraphStyle.Label}>
                                {email}
                            </Paragraph>
                            <Paragraph cssClassName="phone" style={ParagraphStyle.Label}>
                                {phoneNumber}
                            </Paragraph>
                        </div>
                    </>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__profile-button`}>
                {isInstructor && (
                    <Button
                        linkPath={sitemap.instructor.educationNetworkHub.profile}
                        text={t("viewYourProfile")}
                        type={ButtonType.Link}></Button>
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorCard };

// #endregion Exports
