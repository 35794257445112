import React, { ReactNode } from "react";
import { Card } from "components/card/card";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ButtonType } from "components/buttons/button/button";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import "./achievement-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

enum AchievementStatus {
    NotAchieved = 0,
    Generating = 1,
    Complete = 2,
}

interface AchievementCardProps {
    title?: string;
    achievementStatus: AchievementStatus;
    description?: ReactNode;
    downloadTitle?: string;
    downloadUrl?: string;
    onClick?: () => void;
    icon: Icons;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "achievement-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AchievementCard: React.FC<AchievementCardProps> = (props): JSX.Element => {
    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__icon`}>
                <Icon type={props.icon} size={IconSizes.XLarge} />
            </div>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>{props.title}</Heading>
            <Paragraph style={ParagraphStyle.Light}>{props.description}</Paragraph>

            {props.achievementStatus === AchievementStatus.NotAchieved && (
                <Paragraph style={ParagraphStyle.Label}>{t("inProgress")}</Paragraph>
            )}

            {props.achievementStatus === AchievementStatus.Generating && (
                <div className={`${CSS_CLASS_NAME}__generating`}>
                    <Paragraph style={ParagraphStyle.Label}>
                        <>
                            {`${props.title} ${t("generating")}`.toUpperCase()}
                            <br />
                            {t("checkBackSoon")}
                        </>
                    </Paragraph>
                </div>
            )}

            {props.achievementStatus === AchievementStatus.Complete && (
                <>
                    {props.onClick != null && (
                        <ButtonIcon
                            buttonType={ButtonType.Default}
                            iconType={Icons.Download}
                            onClick={props.onClick}
                            text={props.downloadTitle}
                        />
                    )}
                    {StringUtils.hasValue(props.downloadUrl) && (
                        <ButtonIcon
                            buttonType={ButtonType.Link}
                            iconType={Icons.Download}
                            linkPath={props.downloadUrl}
                            linkPathType={AnchorPathType.External}
                            text={props.downloadTitle}
                        />
                    )}
                </>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AchievementCard, AchievementStatus };

// #endregion Exports
