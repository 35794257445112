import React from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenuFilePreview } from "components/context-menu/context-menu-file-preview/context-menu-file-preview";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { FileUtils } from "utilities/files/file-utils";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { t } from "utilities/localization/t";
import "./instructor-led-product-content-list.scss";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorLedProductContentListProps {
    confirmProductContentDeletion: (productContent: ProductContentRecord, index: number) => void;
    disableDownArrowButtons: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => boolean;
    disableUpArrowButtons: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => boolean;
    handleContentToEdit: (productContent: ProductContentRecord, selectedIndex: number) => void;
    handleDownArrowClick: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => Promise<void>;
    handleUpArrowClick: (
        productContent: ProductContentRecord,
        access: ProductContentAccess
    ) => Promise<void>;
    hasProductContents: boolean;
    noDataCellClassName: string;
    productContents?: ProductContentRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "instructor-led-product-content-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorLedProductContentList: React.FC<InstructorLedProductContentListProps> = ({
    confirmProductContentDeletion,
    disableDownArrowButtons,
    disableUpArrowButtons,
    handleContentToEdit,
    handleDownArrowClick,
    handleUpArrowClick,
    hasProductContents,
    noDataCellClassName,
    productContents,
}) => {
    const adminContent = productContents
        ?.filter((pc) => pc.access === ProductContentAccess.InstructorProvider)
        .sort((a: ProductContentRecord, b: ProductContentRecord) => a.sortOrder! - b.sortOrder!);
    const participantContent = productContents
        ?.filter((pc) => pc.access === ProductContentAccess.Everyone)
        .sort((a: ProductContentRecord, b: ProductContentRecord) => a.sortOrder! - b.sortOrder!);

    return (
        <>
            <>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                    {t("contentForAdministatorAndInstructor")}
                </Heading>
                {CollectionUtils.hasValues(adminContent) ? (
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th
                                    className={`arrows ${noDataCellClassName}`}
                                    aria-labelledby={t("arrowColumn")}>
                                    <span className="sr-only">{t("changeOrder")}</span>
                                </th>
                                <th
                                    className={`order ${noDataCellClassName}`}
                                    aria-labelledby={t("sortOrderColumn")}>
                                    {t("order")}
                                </th>
                                <th className="name" aria-labelledby={t("nameColumn")}>
                                    {t("name")}
                                </th>
                                <th
                                    className="description"
                                    aria-labelledby={t("descriptionColumn")}>
                                    {t("description")}
                                </th>
                                <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                    {t("fileName")}
                                </th>
                                <th className="action" aria-labelledby={t("menuColumn")}>
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminContent &&
                                adminContent.map((pc: ProductContentRecord, idx: number) => (
                                    <tr key={`${idx}-${pc.id}`}>
                                        <td className="arrows">
                                            <ButtonIcon
                                                ariaLabelledBy={t("moveContentPcContentNameUp", {
                                                    pcContentName: pc.content?.name,
                                                })}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-up"
                                                disabled={disableUpArrowButtons(pc, pc.access!)}
                                                iconType={Icons.ArrowUpward}
                                                onClick={() => handleUpArrowClick(pc, pc.access!)}
                                            />
                                            <ButtonIcon
                                                ariaLabelledBy={t("moveContentPcContentNameDown", {
                                                    pcContentName: pc.content?.name,
                                                })}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-down"
                                                disabled={disableDownArrowButtons(pc, pc.access!)}
                                                iconType={Icons.ArrowDownward}
                                                onClick={() => handleDownArrowClick(pc, pc.access!)}
                                            />
                                        </td>
                                        <td className="order">{pc.sortOrder}</td>
                                        <td className="name">{pc.content?.name}</td>
                                        <td className="description">
                                            <Paragraph>{pc.content?.description}</Paragraph>
                                        </td>
                                        <td className="file-name">{pc.content?.file?.fileName}</td>
                                        <td className="action">
                                            <ContextMenu>
                                                <ContextMenuFilePreview
                                                    hrefPath={FileUtils.fileUrl(
                                                        pc.content?.fileId!
                                                    )}
                                                    displayName={t("preview")}
                                                />
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        handleContentToEdit(
                                                            pc,
                                                            productContents!.indexOf(pc)
                                                        )
                                                    }
                                                    displayName={t("replaceContent")}
                                                />
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        confirmProductContentDeletion(pc, idx)
                                                    }
                                                    displayName={t("delete")}
                                                />
                                            </ContextMenu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <EmptyText table cssClassName={`${CSS_CLASS_NAME}__empty`}>
                        {t("noContentAdded")}
                    </EmptyText>
                )}
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                    {t("contentForParticipants")}
                </Heading>
                {CollectionUtils.hasValues(participantContent) ? (
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th
                                    className={`arrows ${noDataCellClassName}`}
                                    aria-labelledby={t("arrowColumn")}>
                                    <span className="sr-only">{t("changeOrder")}</span>
                                </th>
                                <th
                                    className={`order ${noDataCellClassName}`}
                                    aria-labelledby={t("sortOrderColumn")}>
                                    {t("order")}
                                </th>
                                <th className="name" aria-labelledby={t("nameColumn")}>
                                    {t("name")}
                                </th>
                                <th
                                    className="description"
                                    aria-labelledby={t("descriptionColumn")}>
                                    {t("description")}
                                </th>
                                <th className="file-name" aria-labelledby={t("fileNameColumn")}>
                                    {t("fileName")}
                                </th>
                                <th className="action" aria-labelledby={t("menuColumn")}>
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {participantContent &&
                                participantContent.map((pc, idx) => (
                                    <tr key={`${idx}-${pc.id}`}>
                                        <td className="arrows">
                                            <ButtonIcon
                                                ariaLabelledBy={t("moveContentPcContentNameUp", {
                                                    pcContentName: pc.content?.name,
                                                })}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-up"
                                                disabled={disableUpArrowButtons(pc, pc.access!)}
                                                iconType={Icons.ArrowUpward}
                                                onClick={() => handleUpArrowClick(pc, pc.access!)}
                                            />
                                            <ButtonIcon
                                                ariaLabelledBy={t("moveContentPcContentNameDown", {
                                                    pcContentName: pc.content?.name,
                                                })}
                                                buttonStyle={ButtonStyle.TertiaryAlt}
                                                cssClassName="arrow-down"
                                                disabled={disableDownArrowButtons(pc, pc.access!)}
                                                iconType={Icons.ArrowDownward}
                                                onClick={() => handleDownArrowClick(pc, pc.access!)}
                                            />
                                        </td>
                                        <td className="order">{pc.sortOrder}</td>
                                        <td className="name">{pc.content?.name}</td>
                                        <td className="description">
                                            <Paragraph>{pc.content?.description}</Paragraph>
                                        </td>
                                        <td className="file-name">{pc.content?.file?.fileName}</td>
                                        <td className="action">
                                            <ContextMenu>
                                                <ContextMenuFilePreview
                                                    hrefPath={FileUtils.fileUrl(
                                                        pc.content?.fileId!
                                                    )}
                                                    displayName={t("preview")}
                                                />
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        handleContentToEdit(
                                                            pc,
                                                            productContents!.indexOf(pc)
                                                        )
                                                    }
                                                    displayName={t("replaceContent")}
                                                />
                                                <ContextMenuButton
                                                    onClick={() =>
                                                        confirmProductContentDeletion(pc, idx)
                                                    }
                                                    displayName={t("delete")}
                                                />
                                            </ContextMenu>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <EmptyText table cssClassName={`${CSS_CLASS_NAME}__empty`}>
                        {t("noContentAdded")}
                    </EmptyText>
                )}
            </>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorLedProductContentList };

// #endregion Exports
