import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { t } from "utilities/localization/t";
import "./event-activation-contact-information.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationContactInformationProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-contact-information";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationContactInformation: React.FC<EventActivationContactInformationProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <h3>{t("eventContactInformation")}</h3>
                {!editMode && (
                    <ToggleLabel
                        checked={event.hideContactInformation}
                        disabled={true}
                        id="hide-contact-info"
                        label={t("hideContactInformation")}
                    />
                )}
            </div>
            {!editMode && (
                <>
                    <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.Small}>
                        {t(
                            "thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizer"
                        )}
                    </Paragraph>

                    <div className={`${CSS_CLASS_NAME}__details`}>
                        {event.hideContactInformation && (
                            <Paragraph
                                style={ParagraphStyle.Light}
                                size={ParagraphSize.Small}
                                emphasis={ParagraphEmphasis.Italic}>
                                {t("contactInformationHidden")}
                            </Paragraph>
                        )}
                        {!event.hideContactInformation && !event.useDefaultContactInformation && (
                            <>
                                <div className={`${CSS_CLASS_NAME}__details-title`}>
                                    {t("customizedContact")}
                                </div>
                                <div className={`${CSS_CLASS_NAME}__details-sub`}>
                                    {t(
                                        "thisInformationIsSpecificToThisEventAndWillNotChangeOrSyncWithTheOrganizationsDefaultEventContactInformation"
                                    )}
                                </div>
                                <div className={`${CSS_CLASS_NAME}__details-contact`}>
                                    {event.getHostName()} <br />
                                    {event.getHostEmailAddress()} <br />
                                    {event.getHostPhone()}
                                </div>
                            </>
                        )}

                        {!event.hideContactInformation && event.useDefaultContactInformation && (
                            <>
                                <div className={`${CSS_CLASS_NAME}__details-title`}>
                                    {t("useDefault")}
                                </div>
                                <div className={`${CSS_CLASS_NAME}__details-sub`}>
                                    {t(
                                        "thisDataWillStayInSyncWithAnyChangesMadeToYourOrganizationsDefaultContactInfo"
                                    )}
                                </div>
                                <div className={`${CSS_CLASS_NAME}__details-contact`}>
                                    {event.getHostName()} <br />
                                    {event.getHostEmailAddress()} <br />
                                    {event.getHostPhone()}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {editMode && <ChangesBanner changed={changed!} />}
            {editMode && (
                <div className={`${CSS_CLASS_NAME}__container`}>
                    <div className={`${CSS_CLASS_NAME}__item`}>
                        <Paragraph style={ParagraphStyle.Label}>{t("email")}</Paragraph>
                        <Paragraph>{event.email}</Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__item`}>
                        <Paragraph style={ParagraphStyle.Label}>{t("phone")}</Paragraph>
                        <Paragraph>{event.phoneNumber}</Paragraph>
                    </div>
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationContactInformation };

// #endregion Exports
