import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { UserInstructorApplicationService } from "utilities/services/instructors/user-instructor-application-service";

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueReturningTheInstructorApplicationStatus";

const useUserInstructorApplication = ({ onError }: { onError: (error: Error) => void }) => {
    const { record: globalState } = useGlobalState();
    const [userInstructorApplication, setUserInstructorApplication] = useState<
        InstructorApplicationRecord | undefined
    >(undefined);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { get } = UserInstructorApplicationService.useGet();

    const fetchUserInstructorApplication = useCallback(async (): Promise<void> => {
        if (globalState?.currentIdentity?.user?.id == null) {
            setUserInstructorApplication(undefined);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const userInstructorApplicationResponse = await get({
                id: globalState?.currentIdentity?.user?.id,
            });
            setIsLoading(false);

            if (
                userInstructorApplicationResponse?.resultObject == null ||
                userInstructorApplicationResponse?.result == null ||
                userInstructorApplicationResponse.result.hasErrors()
            ) {
                setError(t(ERROR_LOADING));
                return;
            }

            setUserInstructorApplication(userInstructorApplicationResponse.resultObject);
        } catch {
            setError(t(ERROR_LOADING));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState?.currentIdentity?.user?.id]);

    useEffect(() => {
        fetchUserInstructorApplication();
    }, [fetchUserInstructorApplication]);
    return { userInstructorApplication, error, isLoading };
};

export { useUserInstructorApplication };
