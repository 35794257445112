import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum AttendanceStatus {
    NoShow = 0,
    Attended = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AttendanceStatusDisplayNames: Map<AttendanceStatus, TranslatedCopy> = {
    [AttendanceStatus.Attended]: "attended",
    [AttendanceStatus.NoShow]: "noShow",
};

// #endregion Maps
export { AttendanceStatus, AttendanceStatusDisplayNames };

// #endregion Exports
