import React from "react";
import { AssessmentQuestionBreakdownCard } from "components/assessments/assessment-question-breakdown-card/assessment-question-breakdown-card";
import { LearnerBreakdownCard } from "components/assessments/learner-breakdown-card/learner-breakdown-card";
import { RoleType } from "models/enumerations/users/role-type";
import { useEvent } from "utilities/contexts/use-event-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./event-assessment-results-breakdowns.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventAssessmentResultsBreakdownsProps {
    eventId: number;
    scormPackageAssessmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-assessment-results-breakdowns";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventAssessmentResultsBreakdowns: React.FC<EventAssessmentResultsBreakdownsProps> = (
    props: EventAssessmentResultsBreakdownsProps
): JSX.Element => {
    const { record: event, setRecord: setEvent } = useEvent();
    const { record: globalState } = useGlobalState();

    return (
        <div className={CSS_CLASS_NAME}>
            <AssessmentQuestionBreakdownCard
                event={event}
                scormPackageAssessmentId={props.scormPackageAssessmentId}
            />

            {(globalState?.currentIdentity?.isCurrentlyInNfpaRole() ?? false) && (
                <LearnerBreakdownCard
                    eventId={props.eventId}
                    scormPackageAssessmentId={props.scormPackageAssessmentId}
                />
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventAssessmentResultsBreakdowns };

// #endregion Exports
