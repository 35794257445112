import React from "react";
import backgroundImage from "assets/images/login-page-video-background.png";
import laptop from "assets/images/laptop.png";
import video from "assets/videos/loginvideo.mp4";
import videoposter from "assets/videos/loginvideo.jpg";
import "./public-layout-video-container.scss";
import { url } from "inspector";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PublicLayoutVideoContainerProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "public-layout-video-container";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicLayoutVideoContainer: React.FC<PublicLayoutVideoContainerProps> = (
    props: PublicLayoutVideoContainerProps
): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const cssClassName: string = cssClassNames.join(" ");

    return (
        <div className={`${cssClassName}`} style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="laptop-container">
                <img className={`${cssClassName}__laptopImage`} src={laptop} alt="laptop" />
                <video className={`${cssClassName}__video`} src={video} poster={videoposter} title="video" autoPlay muted loop />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PublicLayoutVideoContainer };

// #endregion Exports
