import {
    CollectionUtils,
    Culture,
    EnvironmentUtils,
    LocalizationInitOptions,
    LocalizationUtils as AndcultureCodeLocalizationUtils,
} from "andculturecode-javascript-core";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { LocalizationConstants } from "constants/localization/localization-constants";
import { StringUtils } from "utilities/string-utils";
import {
    validatingLanguageDetector,
    VALIDATING_LANGUAGE_DETECTOR_NAME,
} from "utilities/localization/validating-language-detector";
import { CultureResources, TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Language, LanguageCulture } from "models/enumerations/languages/language";
import { registerLocale } from "react-datepicker";
import ar from "date-fns/locale/ar";
import es from "date-fns/locale/es";
import { English } from "cultures/english";

// -------------------------------------------------------------------------------------------------
// #region Detector Configuration Defaults
// -------------------------------------------------------------------------------------------------

const defaultInitOptions: LocalizationInitOptions = {
    debug: EnvironmentUtils.isDevelopment(),
    detection: {
        order: [VALIDATING_LANGUAGE_DETECTOR_NAME],
    },
    escapeValue: false,
};

// #endregion Detector Configuration Defaults

// -------------------------------------------------------------------------------------------------
// #region Static Values
// -------------------------------------------------------------------------------------------------

let _cultures: Culture<CultureResources>[] = [];

// #endregion Static Values

// -------------------------------------------------------------------------------------------------
// #region Overridden Utility Methods
// -------------------------------------------------------------------------------------------------

const changeCultureCode = (cultureCode: Language | string) =>
    typeof cultureCode === "string"
        ? i18n.changeLanguage(cultureCode)
        : i18n.changeLanguage(languageCodeByLanguage(cultureCode));

const configuredCultures = (): Culture<CultureResources>[] => _cultures;

const configuredCultureCodes = (): string[] =>
    configuredCultures().map((culture: Culture<CultureResources>): string => culture.code);

const currentCultureCode = (): string => i18n.resolvedLanguage ?? i18n.language;

const defaultCultureCode = (): string => LocalizationConstants.DEFUALT_CULTURE_CODE;

const initialize = (
    module: any,
    cultures: Culture<CultureResources>[],
    options: LocalizationInitOptions = defaultInitOptions
) => {
    if (CollectionUtils.isEmpty(cultures)) {
        throw new Error(AndcultureCodeLocalizationUtils.errorCultureIsRequired);
    }

    _cultures = cultures;

    const languageDetector = new LanguageDetector();
    languageDetector.addDetector(validatingLanguageDetector);

    registerLocale("ar", ar);
    registerLocale("es", es);

    i18n.use(module)
        .use(languageDetector)
        .init({
            // Cannot set lng value when using LanguageDetector (https://stackoverflow.com/a/55143859)
            cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US
            debug: options.debug, // logs info level to console output. Helps finding issues with loading not working.
            detection: options.detection,
            fallbackLng: defaultCultureCode(), // language to use if translations in user language are not available.
            interpolation: {
                escapeValue: options.escapeValue,
            },
            resources: _culturesToResources<CultureResources>(cultures),
        });

    return i18n;
};

/**
 * Retrieve translation for given key in the currently configured language
 * @param key culture resource key
 * @param options object key/values for interpolation of dynamic values
 */
const translate = (key: string, options?: any): string => i18n.t(key, options);

/**
 * Retrieve translation for given key in the currently configured language
 * @param key culture resource key
 * @param options object key/values for interpolation of dynamic values
 */
const t = translate;

// #endregion Overridden Utility Methods

// -------------------------------------------------------------------------------------------------
// #region Utility Methods
// -------------------------------------------------------------------------------------------------

const changeCultureCodeIfNecessary = (cultureCode: string): void => {
    if (cultureCode === currentCultureCode()) {
        return;
    }

    changeCultureCode(cultureCode);
};

const cultureByLanguage = (language: Language): Culture<CultureResources> =>
    LanguageCulture[language];

const cultureCodeFromBrowser = (): string =>
    getLanguageCodeFromCultureCode(window.navigator.language);

const currentLanguageIs = (language: Language): boolean =>
    currentCultureCode() === languageCodeByLanguage(language);

const getLanguageCodeFromCultureCode = (cultureCode: string): string => {
    if (!isValidCultureCode(cultureCode)) {
        throw new Error(t("notAValidCultureCode"));
    }

    return cultureCode.split("-")[0];
};

const hasCultureCodeInRoute = (): boolean =>
    isValidCultureCode(AndcultureCodeLocalizationUtils.cultureCodeFromRoute());

const hasCultureCodeInQueryString = (): boolean =>
    isValidCultureCode(AndcultureCodeLocalizationUtils.cultureCodeFromQueryString());

const hasCultureCode = (): boolean => hasCultureCodeInRoute() || hasCultureCodeInQueryString();

const isTranslatedCopy = (str?: string): str is TranslatedCopy =>
    StringUtils.hasValue(str) && Object.keys(English.resources).includes(str);

const isValidCultureCode = (cultureCode: string): boolean =>
    StringUtils.isValidCultureCode(cultureCode);

const languageCodeByLanguage = (language: Language): string => LanguageCulture[language].code;

// #endregion Utility Methods

// -------------------------------------------------------------------------------------------------
// #region Private Methods
// -------------------------------------------------------------------------------------------------

const _culturesToResources = <TResources>(cultures: Culture<TResources>[]) => {
    const resources: any = {};

    cultures.forEach((l) => {
        resources[l.code] = { translation: l.resources };
    });

    return resources;
};

// #endregion Private Methods

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const LocalizationUtils = {
    ...AndcultureCodeLocalizationUtils,
    changeCultureCode: changeCultureCode,
    changeCultureCodeIfNecessary: changeCultureCodeIfNecessary,
    configuredCultureCodes: configuredCultureCodes,
    configuredCultures: configuredCultures,
    cultureByLanguage: cultureByLanguage,
    cultureCodeFromBrowser: cultureCodeFromBrowser,
    currentCultureCode: currentCultureCode,
    currentLanguageIs: currentLanguageIs,
    defaultCultureCode: defaultCultureCode,
    getLanguageCodeFromCultureCode: getLanguageCodeFromCultureCode,
    hasCultureCode: hasCultureCode,
    hasCultureCodeInQueryString: hasCultureCodeInQueryString,
    hasCultureCodeInRoute: hasCultureCodeInRoute,
    initialize: initialize,
    isTranslatedCopy: isTranslatedCopy,
    isValidCultureCode: isValidCultureCode,
    languageCodeByLanguage: languageCodeByLanguage,
    routeParam: LocalizationConstants.CULTURE_CODE_DYNAMIC_SLUG_KEY,
    t: t,
    translate: translate,
};

export { LocalizationUtils };

// #endregion Exports
