import React, { useEffect } from "react";
import {
    FormRadioCardList,
    RadioCard,
    RadioCardStyle,
} from "../../form/form-radio-card-list/form-radio-card-list";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Paragraph, ParagraphSize } from "../../typography/paragraph/paragraph";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { ReportSelection } from "components/reports/report-selection/report-selection";
import {
    REPORT_TYPES,
    REPORT_TYPES_DESCRIPTIONS,
} from "components/reports/report-all-trainings/report-all-trainings-constants";
import {
    initialState,
    initializer,
    reducer,
    validate,
} from "components/reports/report-all-trainings/report-all-trainings-reducer";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { StringUtils } from "utilities/string-utils";
import ReportOrganizationSelection from "components/reports/components/report-organization-selection/report-organization-selection";
import ReportInstructorSelection from "components/reports/components/report-instructor-selection/report-instructor-selection";
import ReportInvoiceStatusSelection from "../components/report-invoice-status-selection/report-invoice-status-selection";
import "./report-all-trainings.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportAllTrainingsProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-all-trainings";

const REPORT_TYPES_CARDS = Object.values(REPORT_TYPES).map(
    (key): RadioCard => ({
        icon: Icons.Reporting,
        text: REPORT_TYPES_DESCRIPTIONS[key],
        value: key,
    })
);

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportAllTrainings: React.FC<ReportAllTrainingsProps> = ({
    onChange: onChangeReportCriteria,
    roleType,
}): JSX.Element => {
    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(roleType),
        validate,
    });

    const { instructors, organizations, reportType, invoiceStatusType, dateRangeStart, dateRangeEnd } = payload;

    const onReportTypeChange = (reportType: string) => dispatch({ type: "reportType", reportType });

    const onDateRangeChange = (date: Array<Date | null> = []) => {
        const [start, end] = date;
        dispatch({ type: "dateRange", dateRangeStart: start, dateRangeEnd: end });
    };

    useEffect(() => {
        onChangeReportCriteria({
            ...payload,
            organizationIds: payload.organizations.map((organization) => organization.id),
            instructorIds: payload.instructors.map((instructor) => instructor.id),
        });
    }, [onChangeReportCriteria, payload]);

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <ReportSelection
                                title={t("allTrainingsReport")}
                                roleType={roleType}
                                adminOnly={
                                    <div className="report-section">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("iWantToRunAnAllTrainingReportBasedOn")}
                                        </Paragraph>
                                        <FormRadioCardList
                                            id="reportType"
                                            formFieldName="reportType"
                                            label={t("reportType")}
                                            labelScreenReaderOnly={true}
                                            iconSize={IconSizes.Base}
                                            onChange={onReportTypeChange}
                                            radioCards={REPORT_TYPES_CARDS}
                                            required={true}
                                            style={RadioCardStyle.Horizontal}
                                            value={reportType}
                                        />
                                    </div>
                                }
                            />

                            {reportType === REPORT_TYPES.EN_INSTRUCTOR && (
                                <ReportInstructorSelection
                                    instructors={instructors}
                                    isRequired={true}
                                    onInstructorsChanged={(instructors) =>
                                        dispatch({ type: "updateInstructors", instructors })
                                    }
                                />
                            )}

                            {reportType === REPORT_TYPES.ORGANIZATION && (
                                <ReportOrganizationSelection
                                    organizations={organizations}
                                    isRequired={true}
                                    onOrganizationsChanged={(organizations) =>
                                        dispatch({ type: "updateOrganizations", organizations })
                                    }
                                />
                            )}

                            {reportType === REPORT_TYPES.INVOICE_STATUS && (
                                <ReportInvoiceStatusSelection
                                    isRequired={true}
                                    invoiceStatusType={invoiceStatusType}
                                    onInvoiceStatusTypeChanged={(invoiceStatusType: string) =>
                                        dispatch({ type: "invoiceStatusType", invoiceStatusType })}
                                />
                            )}

                            {StringUtils.hasValue(reportType) && (
                                <div className="report-section half">
                                    <Paragraph size={ParagraphSize.Large}>
                                        {t("inThisTimePeriod")}
                                    </Paragraph>
                                    <FormCalendarDateRangePicker
                                        required={reportType === REPORT_TYPES.ALL_TRAININGS}
                                        endDate={dateRangeEnd}
                                        formFieldName="dateRange"
                                        handleEventDateRangeChange={onDateRangeChange}
                                        label={t("dateRange")}
                                        startDate={dateRangeStart}
                                    />
                                </div>
                            )}
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportAllTrainings };

// #endregion Exports
