import { NumberedResponseSummary } from "components/responses/response-summaries/numbered-response-summary/numbered-response-summary";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { ResponseRecord } from "models/view-models/responses/response-record";
import React from "react";
import "./attempt-responses-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AttemptResponsesListProps {
    attempt: AttemptRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "attempt-responses-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AttemptResponsesList: React.FC<AttemptResponsesListProps> = (
    props: AttemptResponsesListProps
): JSX.Element => {
    return (
        <>
            {props.attempt.responses && props.attempt.responses.length > 0 && (
                <div className={CSS_CLASS_NAME}>
                    <ol>
                        {props.attempt.responses.map(
                            (response: ResponseRecord, index: number): JSX.Element => (
                                <li key={response.id}>
                                    <NumberedResponseSummary index={index} response={response} />
                                </li>
                            )
                        )}
                    </ol>
                </div>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptResponsesList };

// #endregion Exports
