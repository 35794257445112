import { BadgeTemplateRecord } from "models/view-models/badges/badge-template-record";
import { BadgeTemplateService } from "utilities/services/badge-templates/badge-template-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";

interface UseBadgeTemplateHook {
    badgeTemplate?: BadgeTemplateRecord;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseBadgeTemplateHookOptions {
    badgeTemplateId?: string;
}

export function useBadgeTemplate(props: UseBadgeTemplateHookOptions): UseBadgeTemplateHook {
    const badgeTemplateId = props.badgeTemplateId;
    const [isLoading, setIsLoading] = useState(true);
    const [badgeTemplate, setBadgeTemplate] = useState<BadgeTemplateRecord>();
    const { get: getBadgeTemplate } = BadgeTemplateService.useGet();
    const [errors, setErrors] = useState<ResultErrorRecord[]>();

    useEffect(() => {
        if (badgeTemplateId === undefined || badgeTemplateId === null || badgeTemplateId === "") {
            return;
        }
        (async function fetchBadgeTemplate() {
            try {
                const [badgeTemplateResponse] = await Promise.all([
                    getBadgeTemplate({ badgeTemplateId: badgeTemplateId }),
                ]);

                setIsLoading(false);

                if (badgeTemplateResponse.result?.hasErrors()) {
                    const { errors: badgeTemplateErrors = [] } = badgeTemplateResponse.result ?? {};
                    setErrors([...badgeTemplateErrors]);
                    return;
                }

                setBadgeTemplate(badgeTemplateResponse.resultObject);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheBadgeTemplateDetails"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [badgeTemplateId, getBadgeTemplate]);

    return {
        errors,
        isLoading,
        badgeTemplate,
    };
}
