import React, { useEffect, useMemo, useState } from "react";
import { E164Number } from "libphonenumber-js";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { StringUtils } from "andculturecode-javascript-core";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import { t } from "utilities/localization/t";
import { EventType } from "models/enumerations/events/event-type";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./event-contact-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventContactFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-contact-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventContactForm: React.FC<EventContactFormProps> = ({ event }): JSX.Element => {
    const [emailError, setEmailError] = useState("");
    const { readOnly } = useReadOnly();

    useEffect(() => {
        if (readOnly) {
            setEmailError("");
        }
    }, [readOnly]);

    const handleEmailChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        event.updateActiveRecord({ email: inputEvent.target.value });
    };

    const handleEmailValidation = (): void => {
        validateEmail(event.email);
    };

    const validateEmail = (email?: string): boolean => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
            return false;
        } else {
            setEmailError("");
            handleBlur();
            return true;
        }
    };

    const handlePhoneNumberChange = (value: E164Number | undefined): void => {
        event.updateActiveRecord({ phoneNumber: value ?? "" });
    };

    const updateContactType = async (useDefaultContactInformation: boolean): Promise<void> => {
        const updateValues = {
            useDefaultContactInformation: useDefaultContactInformation,
            email: useDefaultContactInformation ? "" : event.email,
            phoneNumber: useDefaultContactInformation ? "" : event.phoneNumber,
        };

        if (event.isDraft()) {
            event.updateAndSave(updateValues);
        } else {
            event.updateActiveRecord(updateValues);
        }

        setEmailError("");
    };

    const handleBlur = (): void => {
        if (event.isDraft()) {
            event.save();
        }
    };

    const defaultContactInfoAvailable = useMemo(() => {
        return (
            event.type === EventType.NFPACatalog ||
            (event.type === EventType.EducationNetwork &&
                StringUtils.hasValue(event.provider?.name) &&
                StringUtils.hasValue(event.provider?.email)) ||
            (event.type === EventType.Contract &&
                StringUtils.hasValue(event.organization?.name) &&
                StringUtils.hasValue(event.organization?.email))
        );
    }, [event]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__default`}>
                <RadioButton
                    id="default-contact"
                    formFieldName={`default-contact`}
                    groupName="contact-info-type"
                    disabled={!defaultContactInfoAvailable || event.hideContactInformation}
                    label="Default contact"
                    labelPosition={RadioButtonLabelPosition.Bottom}
                    checked={
                        event.useDefaultContactInformation == null
                            ? false
                            : event.useDefaultContactInformation
                    }
                    onChange={() => updateContactType(true)}
                />
                <div className={`${CSS_CLASS_NAME}__default-details`}>
                    <div className={`${CSS_CLASS_NAME}__default-details-title`} id="foo">
                        {t("useDefault")}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__default-details-sub`}>
                        {t(
                            "thisDataWillStayInSyncWithAnyChangesMadeToYourOrganizationsDefaultContactInfo"
                        )}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__default-details-contact`}>
                        {event.getHostName()}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__default-details-contact`}>
                        {event.getDefaultHostEmailAddress()}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__default-details-contact`}>
                        {event.getDefaultHostPhone()}
                    </div>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__custom`}>
                <RadioButton
                    id="customized-contact"
                    formFieldName={`customized-contact`}
                    disabled={event.hideContactInformation}
                    groupName="contact-info-type"
                    label={t("customizedContact")}
                    labelPosition={RadioButtonLabelPosition.Bottom}
                    checked={
                        event.useDefaultContactInformation == null
                            ? false
                            : !event.useDefaultContactInformation
                    }
                    onChange={() => updateContactType(false)}
                />

                <div className={`${CSS_CLASS_NAME}__custom-details`}>
                    <div className={`${CSS_CLASS_NAME}__custom-details-title`}>
                        {t("customizeForThisEvent")}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__custom-details-sub`}>
                        {t(
                            "thisInformationIsSpecificToThisEventAndWillNotChangeOrSyncWithTheOrganizationsDefaultEventContactInformation"
                        )}
                    </div>
                    <FormTextInput
                        ariaLabelledBy={t("emailAddress")}
                        autoFocus={false}
                        cssClassName={`${CSS_CLASS_NAME}__input`}
                        disabled={
                            event.useDefaultContactInformation || event.hideContactInformation
                        }
                        errorMessage={emailError}
                        formFieldName="emailAddress"
                        id="emailAddress"
                        label={t("emailAddress")}
                        maxLength={250}
                        onBlur={handleEmailValidation}
                        onChange={handleEmailChange}
                        placeholder={t("emailAddress")}
                        required={true}
                        type={InputTypes.Email}
                        value={event.email}
                    />
                    <FormPhoneInput
                        ariaLabelledBy={t("phoneNumber")}
                        autoFocus={false}
                        cssClassName={`${CSS_CLASS_NAME}__input`}
                        disabled={
                            event.useDefaultContactInformation || event.hideContactInformation
                        }
                        formFieldName="phoneNumber"
                        id="phoneNumber"
                        label={t("phoneNumber")}
                        onBlur={handleBlur}
                        onChange={handlePhoneNumberChange}
                        placeholder={t("phoneNumber")}
                        required={true}
                        type={InputTypes.Phone}
                        value={event.phoneNumber}
                    />
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContactForm };

// #endregion Exports
