import React from "react";
import { StringUtils } from "utilities/string-utils";
import { Paragraph, ParagraphStyle } from "../../typography/paragraph/paragraph";
import "./form-field-readonly.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormFieldReadonlyProps {
    cssClassName?: string;
    label: string;
    labelStyle?: ParagraphStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-field-readonly";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormFieldReadonly: React.FC<React.PropsWithChildren<FormFieldReadonlyProps>> = (
    props: React.PropsWithChildren<FormFieldReadonlyProps>
) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    var className: string = classNames.join(" ");

    const labelStyle = props.labelStyle == null ? ParagraphStyle.Label : props.labelStyle;

    return (
        <div className={className}>
            <Paragraph style={labelStyle}>{props.label}</Paragraph>
            <div>{props.children}</div>
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormFieldReadonly };

// #endregion Exports
