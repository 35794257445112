import React from "react";
import { Menu } from "@headlessui/react";
import { Anchor, AnchorPathType, AnchorProps } from "components/typography/anchors/anchor/anchor";
import { useWithForwardRef } from "utilities/hooks/use-with-forwarded-ref";
import "./context-menu-anchor.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface ContextMenuAnchorProps {
    disabled?: boolean;
    displayName: string;
    hrefPath: string;
    pathType?: AnchorPathType;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "context-menu-anchor";
const DISABLED_CLASS_NAME: string = "-disabled";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContextMenuAnchor: React.FC<React.PropsWithChildren<ContextMenuAnchorProps>> = (props) => {
    const AnchorWithRef = useWithForwardRef<React.PropsWithChildren<AnchorProps>, any>(Anchor);

    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.disabled === true) {
        cssClassNames.push(DISABLED_CLASS_NAME);
    }

    return (
        <Menu.Item>
            {({ active }) => (
                <AnchorWithRef
                    disabled={props.disabled}
                    cssClassName={`${active ? "-active" : ""} ${cssClassNames.join(" ")}`}
                    path={props.hrefPath}
                    pathType={props.pathType == null ? AnchorPathType.Internal : props.pathType}>
                    {props.displayName}
                </AnchorWithRef>
            )}
        </Menu.Item>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ContextMenuAnchor };

// #endregion Exports
