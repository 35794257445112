import { Organization } from "models/interfaces/organizations/organization";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type OrganizationRecordUpdater = RecordUpdater<Organization, OrganizationRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: OrganizationContext, useRecord: useOrganization } =
    UseRecordContextFactory.build<Organization, OrganizationRecord>(OrganizationRecord);

export { OrganizationContext, useOrganization };

// #endregion Exports
