import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import {
    GetEvaluationTemplatePathParams,
    GetEvaluationTemplateQueryParams,
    EvaluationTemplateService,
} from "utilities/services/evaluation-templates/evaluation-template-service";
import {
    EvaluationService,
    ListEvaluationQueryParams,
} from "utilities/services/evaluations/evaluation-service";
import {
    Paragraph,
    ParagraphStyle,
    ParagraphSize,
} from "components/typography/paragraph/paragraph";
import { BarChart, BarChartColumn, BarChartOrientation } from "components/bar-chart/bar-chart";
import { CollectionUtils, StringUtils } from "andculturecode-javascript-core";
import { DataTable } from "components/tables/data-table/data-table";
import { EvaluationAnswerTemplateRecord } from "models/view-models/evaluation-templates/evaluation-answer-template-record";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { EvaluationQuestionType } from "models/enumerations/evaluations/evaluation-question-type";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { EvaluationResponseRecord } from "models/view-models/evaluations/evaluation-response-record";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { NumberUtils } from "utilities/number-utils";
import { RoleType } from "models/enumerations/users/role-type";
import { sitemap } from "sitemap";
import { useEvent } from "utilities/contexts/use-event-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useParams } from "react-router-dom";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { ToastManager } from "utilities/toast/toast-manager";
import { EvaluationManager } from "components/evaluations/evaluation-manager/evaluation-manager";
import { t } from "utilities/localization/t";
import { EmptyText } from "components/empty-text/empty-text";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./manage-live-virtual-event-evaluation-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

enum ResponseType {
    Chart,
    Response,
}

interface EvaluationQuestionSummary {
    question: EvaluationQuestionTemplateRecord;
    chartResponses?: Array<BarChartColumn>;
    responses?: EvaluationQuestionResponse[];
    responseType: ResponseType;
}

interface EvaluationQuestionResponse {
    number: number;
    text: string;
}

// Component Properties
// -----------------------------------

interface BarChartQuestionProps {
    totalRespondants: number;
    questionSummary: EvaluationQuestionSummary;
}

interface ResponseQuestionProps {
    isOpen?: boolean;
    onAccordionToggle: (questionId: number) => void;
    questionSummary: EvaluationQuestionSummary;
    showDetails: boolean;
}

enum View {
    Preview,
    Summary,
}

interface ManageLiveVirtualEventEvaluationsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "manage-live-virtual-event-evaluation-page";
const CSS_PARENT_CLASS_NAME: string = "manage-event-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageLiveVirtualEventEvaluationsPage: React.FC<ManageLiveVirtualEventEvaluationsPageProps> =
    validatePageAccess(AccessControlKeys.ManageLiveVirtualEventEvaluationsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.root);
        const { id: eventIdParam } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
        const { record: event } = useEvent();

        const [view, setView] = useState<View>(View.Summary);
        const [evaluations, setEvaluations] = useState<EvaluationRecord[]>([]);
        const [evaluationTemplate, setEvaluationTemplate] = useState<EvaluationTemplateRecord>();
        const { list: listEvaluations } = EvaluationService.useList();
        const { get: getEvaluationTemplate } = EvaluationTemplateService.useGet();
        const evaluationTemplateId = event?.product?.evaluationTemplateId;
        const [questionSummaries, setQuestionSummaries] = useState<EvaluationQuestionSummary[]>([]);
        const { record: GlobalState } = useGlobalState();
        const userRole = GlobalState?.currentIdentity?.role?.roleType;

        const aggregateResponses = useCallback(() => {
            if (!CollectionUtils.hasValues(evaluationTemplate?.questions)) {
                return;
            }

            const allResponses = new Array<EvaluationResponseRecord>();
            evaluations.forEach((e) => {
                const responses = e.responses;
                if (!CollectionUtils.hasValues(responses)) {
                    return;
                }

                allResponses.push(...responses);
            });

            const questionWithResponses = aggregateQuestionResponses(
                evaluationTemplate?.questions!,
                allResponses
            );
            setQuestionSummaries(questionWithResponses);
        }, [evaluationTemplate?.questions, evaluations]);

        const fetchData = useCallback(async () => {
            if ((eventId ?? 0) === 0) {
                return;
            }

            if (evaluationTemplateId === undefined || evaluationTemplateId <= 0) {
                return;
            }

            const pathParams: GetEvaluationTemplatePathParams = {
                id: evaluationTemplateId,
            };

            const queryParams: GetEvaluationTemplateQueryParams = {
                includeQuestions: true,
            };

            // Get Evaluation Responses.
            const listEvalQueryParams: ListEvaluationQueryParams = {
                eventId: eventId,
                includeResponses: true,
            };

            // async API calls.
            const apiCalls: [
                ReturnType<typeof getEvaluationTemplate>,
                ReturnType<typeof listEvaluations>
            ] = [
                getEvaluationTemplate(pathParams, queryParams),
                listEvaluations(listEvalQueryParams),
            ];

            try {
                const responses = await Promise.all(apiCalls);

                const failed =
                    responses[0].resultObject == null ||
                    responses[0].result == null ||
                    responses[0].result.hasErrors() ||
                    responses[1].resultObjects == null ||
                    responses[1].results == null ||
                    responses[1].results.hasErrors();

                if (failed) {
                    throw new Error();
                }

                const evaluationTemplate = responses[0].resultObject;
                evaluationTemplate?.sortQuestions();
                setEvaluationTemplate(evaluationTemplate);
                setEvaluations(responses[1].resultObjects);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingEvaluationDetails"));
                setEvaluations([]);
            }
        }, [evaluationTemplateId, eventId, getEvaluationTemplate, listEvaluations]);

        useEffect(() => {
            aggregateResponses();
        }, [aggregateResponses]);

        useEffect(() => {
            fetchData();
        }, [fetchData]);

        const [openQuestions, setOpenQuestions] = useState<number[]>([]);

        const onAccordionToggle = (questionId: number) => {
            if (openQuestions.includes(questionId)) {
                const index = openQuestions.indexOf(questionId, 0);

                if (index > -1) {
                    openQuestions.splice(index, 1);
                }

                setOpenQuestions([...openQuestions]);
            } else {
                openQuestions.push(questionId);
                setOpenQuestions([...openQuestions]);
            }
        };

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.Assessment} size={IconSizes.Large} />
                        <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                            {t("evaluation")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                        <Button
                            linkPath={sitemap.admin.reports.list}
                            style={ButtonStyle.Primary}
                            text={t("goToReports")}
                            type={ButtonType.Link}
                        />
                    </div>
                </div>

                <div className={`${CSS_CLASS_NAME}__intro`}>
                    <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                        {t(
                            "pleaseReviewTheSummaryEvaluationResultsFromParticipantsAsItProvidesKeyInsightsAboutTheirTrainingExperienceResultsIncludeFeedbackConcerningTheInstructorTrainingContentAndTheLearningEnvironment"
                        )}
                    </Paragraph>
                </div>

                <div className={`${CSS_PARENT_CLASS_NAME}__heading`}>
                    <div className={`${CSS_CLASS_NAME}__view-selection`}>
                        <Button
                            disabled={evaluationTemplateId == null}
                            size={ButtonSize.Medium}
                            style={
                                view === View.Summary ? ButtonStyle.Primary : ButtonStyle.Secondary
                            }
                            text={t("summary")}
                            onClick={() => setView(View.Summary)}
                        />
                        <Button
                            disabled={evaluationTemplateId == null}
                            size={ButtonSize.Medium}
                            style={
                                view === View.Preview ? ButtonStyle.Primary : ButtonStyle.Secondary
                            }
                            text={t("preview")}
                            onClick={() => setView(View.Preview)}
                        />
                    </div>
                </div>

                {evaluationTemplateId == null ? (
                    <EmptyText>{t("noEvaluationAdded")}</EmptyText>
                ) : (
                    <>
                        {view === View.Summary && (
                            <div className={`${CSS_CLASS_NAME}__summary`}>
                                {questionSummaries.map((questionSummary, index) =>
                                    questionSummary.responseType === ResponseType.Chart ? (
                                        <BarChartQuestion
                                            key={`evaluation-question-${index}`}
                                            questionSummary={questionSummary}
                                            totalRespondants={evaluations.length}
                                        />
                                    ) : (
                                        <ResponseQuestion
                                            key={`evaluation-question-${index}`}
                                            isOpen={openQuestions.includes(
                                                questionSummary.question.id!
                                            )}
                                            onAccordionToggle={onAccordionToggle}
                                            questionSummary={questionSummary}
                                            showDetails={
                                                !questionSummary.question.isResponseNFPAOnly ||
                                                userRole === RoleType.NfpaAdministrator
                                            }
                                        />
                                    )
                                )}
                            </div>
                        )}
                        {view === View.Preview && evaluationTemplateId != null && (
                            <EvaluationManager
                                title={t("previewEvaluation")}
                                evaluationTemplateId={evaluationTemplateId}
                                readOnly={true}
                            />
                        )}
                    </>
                )}
            </div>
        );
    });

const BarChartQuestion: React.FC<BarChartQuestionProps> = (
    props: BarChartQuestionProps
): JSX.Element => {
    const questionSummary = props.questionSummary;
    const columns = questionSummary.chartResponses ?? [];
    const barChartDenominator =
        questionSummary.question.type === EvaluationQuestionType.MultiSelect
            ? props.totalRespondants
            : columns.reduce((sum, current) => sum + current.value, 0);

    return (
        <Card cssClassName="summary-question chart" stacked={true}>
            <div className="summary-question__question">
                <Paragraph>{questionSummary.question.questionText}</Paragraph>
            </div>
            <div className="summary-question__chart">
                <BarChart
                    columns={columns}
                    denominator={barChartDenominator}
                    orientation={
                        questionSummary.question.type === EvaluationQuestionType.MultiSelect
                            ? BarChartOrientation.Horizontal
                            : BarChartOrientation.Vertical
                    }
                />
            </div>
        </Card>
    );
};

const ResponseQuestion: React.FC<ResponseQuestionProps> = (
    props: ResponseQuestionProps
): JSX.Element => {
    const questionSummary = props.questionSummary;
    const showDetails = props.showDetails;

    return (
        <Card cssClassName="summary-question" stacked={true}>
            <div className="summary-question__accordion">
                <div className="summary-question__accordion__title">
                    <Paragraph>{questionSummary.question.questionText}</Paragraph>
                </div>
                <div className="summary-question__accordion__responses">
                    {showDetails && (
                        <button
                            onClick={() => props.onAccordionToggle(questionSummary.question.id!)}>
                            <Paragraph style={ParagraphStyle.Label}>
                                {questionSummary.responses?.length ?? 0} RESPONSES
                            </Paragraph>
                            <Icon
                                type={props.isOpen ? Icons.ChevronUp : Icons.ChevronDown}
                                size={IconSizes.Large}
                            />
                        </button>
                    )}
                    {!showDetails && (
                        <Paragraph style={ParagraphStyle.Label}>
                            {questionSummary.responses?.length ?? 0} RESPONSES
                        </Paragraph>
                    )}
                </div>
            </div>
            {props.isOpen && (
                <div className="summary-question__responses">
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="-date">{t("number")}</th>
                                <th className="-status">{t("response")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {questionSummary?.responses?.map((response, index) => (
                                <tr key={`${questionSummary.question.id!}-response-${index}`}>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {index + 1}
                                        </Paragraph>
                                    </td>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {response.text}
                                        </Paragraph>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </div>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Methods
// -------------------------------------------------------------------------------------------------

function getChartColumnsForQuestion(
    question: EvaluationQuestionTemplateRecord,
    responses: EvaluationResponseRecord[]
): BarChartColumn[] {
    const chartColumns = new Array<BarChartColumn>();
    if (!CollectionUtils.hasValues(question.answers)) {
        return chartColumns;
    }

    question.answers.forEach((answer: EvaluationAnswerTemplateRecord) => {
        // Get the number of responses with this questionId and answerId.
        const answerResponseCount = responses.filter(
            (r) =>
                r.evaluationQuestionTemplateId === question.id &&
                r.evaluationAnswerTemplateId === answer.id
        ).length;

        let populateDescription = "";

        if (question.type === EvaluationQuestionType.NPS && answer.answerText === "1") {
            populateDescription = t("veryUNLIKELY");
        }
        if (question.type === EvaluationQuestionType.NPS && answer.answerText === "10") {
            populateDescription = t("veryLIKELY");
        }

        chartColumns.push({
            label: answer.answerText,
            value: answerResponseCount,
            description: populateDescription,
        });
    });

    return chartColumns;
}

function getFreeResponsesForQuestion(
    question: EvaluationQuestionTemplateRecord,
    responses: EvaluationResponseRecord[]
): EvaluationQuestionResponse[] {
    let freeResponses = new Array<EvaluationQuestionResponse>();

    freeResponses = responses
        .filter(
            (r) =>
                question.id === r.evaluationQuestionTemplateId &&
                StringUtils.hasValue(r.freeResponse)
        )
        .map((r) => ({ number: r.id!, text: r.freeResponse! }));

    return freeResponses;
}

function aggregateQuestionResponses(
    questions: EvaluationQuestionTemplateRecord[],
    responses: EvaluationResponseRecord[]
): EvaluationQuestionSummary[] {
    const questionSummary = new Array<EvaluationQuestionSummary>();

    questions.forEach((q: EvaluationQuestionTemplateRecord) => {
        if (q === null) {
            return;
        }

        const questionResponses = responses.filter((r) => r.evaluationQuestionTemplateId === q.id);

        if (q.type === EvaluationQuestionType.FreeResponse) {
            questionSummary.push({
                question: q,
                responseType: ResponseType.Response,
                responses: getFreeResponsesForQuestion(q, questionResponses),
            });
        } else {
            questionSummary.push({
                question: q,
                responseType: ResponseType.Chart,
                chartResponses: getChartColumnsForQuestion(q, questionResponses),
            });
        }
    });

    return questionSummary;
}

// -------------------------------------------------------------------------------------------------
// #endregion Methods
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageLiveVirtualEventEvaluationsPage };

// #endregion Exports
