import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { ButtonStyle } from "components/buttons/button/button";
import { ConfirmationModal } from "components/modal/confirmation-modal/confirmation-modal";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Modal, ModalAction } from "components/modal/modal";
import { PatchProviderApplication } from "models/interfaces/aen-applications/patch-provider-application";
import { PatchProviderApplicationRecord } from "models/view-models/aen-applications/patch-provider-application-record";
import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import {
    ProviderApplicationStatus,
    ProviderApplicationStatusDisplayNames,
} from "models/enumerations/aen-applications/provider-application-status";
import { SelectOption } from "components/form/inputs/select/select";
import { sitemap } from "sitemap";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { useAenUsers } from "utilities/hooks/models/aen-applications/use-aen-users";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import React, { useEffect, useMemo, useState } from "react";
import "./edit-provider-application-modal.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditProviderApplicationModalProps {
    onSave?: (application: PatchProviderApplicationRecord) => Promise<boolean>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    providerApplicationToEdit: ProviderApplicationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-provider-application-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditProviderApplicationModal: React.FC<EditProviderApplicationModalProps> = ({
    open,
    setOpen,
    providerApplicationToEdit,
    onSave,
}): JSX.Element => {
    const initialApplication = useMemo(() => {
        return providerApplicationToEdit
            ? new PatchProviderApplicationRecord({
                  id: providerApplicationToEdit?.id,
                  assignedToId: providerApplicationToEdit?.assignedToId,
                  status: providerApplicationToEdit?.status,
                  sendMessage: false,
                  messageBody: "",
              })
            : new PatchProviderApplicationRecord();
    }, [providerApplicationToEdit]);

    const [application, setApplication] =
        useState<PatchProviderApplicationRecord>(initialApplication);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const { users } = useAenUsers({ applicationType: AenApplicationType.Provider });
    const statusOptions: SelectOption[] = [
        ...EnumUtils.numericEnumToSelectOptions(
            ProviderApplicationStatus,
            ProviderApplicationStatusDisplayNames
        ),
    ];

    const navigate = useNavigate();

    const isInvalid = useMemo(() => {
        return (
            application.sendMessage &&
            (!StringUtils.hasValue(application.messageBody) ||
                !StringUtils.hasValue(application.messageSubject))
        );
    }, [application]);

    const assignedToOptions: SelectOption[] = users
        .map(
            (user): SelectOption => ({
                text: user.getFirstAndLastName(),
                value: user.id!.toString(),
            })
        )
        .filter((option) => option.text);

    // -------------------------------------------------------------------------------------------------
    // #region Event Handlers
    // -------------------------------------------------------------------------------------------------

    const updateApplication = (values: Partial<PatchProviderApplication>): void => {
        setApplication(application.with(values));
        setDirty(true);
    };

    const handleRedirectToProviderCreation = () => {
        navigate(sitemap.admin.userManagement.enProviders.new, {
            state: {
                email: providerApplicationToEdit.primaryEmail,
                name: providerApplicationToEdit.providerName,
            },
        });
    };

    const handleSave = async () => {
        setSaving(true);

        if (await onSave?.(application)) {
            // Perform Update Success Clean up.
            onModalClose();
            ToastManager.success(t("applicationHasBeenUpdatedSuccessfully"));
            if (application.status === ProviderApplicationStatus.Completed) {
                handleRedirectToProviderCreation();
            }
        } else {
            // Inform application of failure.
            ToastManager.error(t("thereWasAnIssueUpdatingTheApplication"));
        }
        setSaving(false);
    };

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateApplication(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ status: Number(value) }),
                ProviderApplicationStatus
            )
        );
    };

    const handleAssignmentChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateApplication({ assignedToId: parseInt(event.target.value) });
    };

    const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateApplication({ reasonForChange: e.target.value });
    };

    // #endregion Event Handlers

    // -------------------------------------------------------------------------------------------------
    // #region Modal/Form Controls
    // -------------------------------------------------------------------------------------------------

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => handleClose(),
            style: ButtonStyle.Secondary,
        },
        {
            disabled: !dirty || saving || isInvalid,
            buttonText: t("saveChanges"),
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setApplication(initialApplication);
        setDirty(false);
    };

    const onModalClose = (): void => {
        resetForm();
        setOpen(false);
        setShowConfirmationModal(false);
    };

    const handleClose = () => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            onModalClose();
        }
    };

    // #endregion Modal/Form Controls

    useEffect(() => setApplication(initialApplication), [initialApplication]);

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("updateStatus")}
            actions={modalActionArray}
            modalStyle={""}
        >
            <FormSelect
                cssClassName={`${CSS_CLASS_NAME}__select`}
                onChange={handleStatusChange}
                options={statusOptions}
                label={t("status")}
                value={application.status == null ? undefined : `${application.status}`}
                formFieldName={"header-action-status"}
                id={"header-action-status"}
            />
            <FormSelect
                cssClassName={`${CSS_CLASS_NAME}__select`}
                onChange={handleAssignmentChange}
                options={assignedToOptions}
                value={application.assignedToId == null ? undefined : `${application.assignedToId}`}
                label={t("assignedTo")}
                formFieldName={"header-action-assigned-to-id"}
                id={"header-action-assigned-to-id"}
            />
            <FormTextArea
                ariaLabelledBy={t("notes")}
                formFieldName="notes"
                id="notes"
                label={t("notes")}
                maxLength={1000}
                onChange={handleReasonChange}
                placeholder={t("enterDescription")}
                rows={5}
                value={application.reasonForChange}
            />

            <FormCheckboxInput
                checked={application.sendMessage}
                id="sendMessageToApplicant"
                label={t("sendMessageToApplicant")}
                onChange={(checked) => updateApplication({ sendMessage: checked })}
                formFieldName={t("sendMessageToApplicant")}
            />

            {application.sendMessage && (
                <>
                    <FormTextArea
                        ariaLabelledBy={t("emailSubject")}
                        formFieldName="emailSubject"
                        id="emailSubject"
                        label={t("emailSubject")}
                        maxLength={150}
                        onChange={(e) => updateApplication({ messageSubject: e.target.value })}
                        placeholder={t("emailSubject")}
                        rows={1}
                        value={application.messageSubject}
                    />

                    <FormTextArea
                        ariaLabelledBy={t("emailBody")}
                        formFieldName="emailBody"
                        id="emailBody"
                        label={t("emailBody")}
                        maxLength={1000}
                        onChange={(e) => updateApplication({ messageBody: e.target.value })}
                        placeholder={t("emailBody")}
                        rows={5}
                        value={application.messageBody}
                    />
                </>
            )}

            <ConfirmationModal
                open={showConfirmationModal}
                onCancel={() => setShowConfirmationModal(false)}
                onConfirm={onModalClose}
                confirmationMessage={t("areYouSureYouWantToExit")}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditProviderApplicationModal };

// #endregion Exports
