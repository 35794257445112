import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventType } from "models/enumerations/events/event-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import {
    FormRadioCardListWithDescription,
    RadioCardColumns,
    RadioCardStyle,
    RadioCardWithDescription,
} from "components/form/form-radio-card-list-with-description/form-radio-card-list-with-description";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { useHandleEventTypeChange } from "components/events/event-details-form/hooks/use-handle-event-type-change";
import { t } from "utilities/localization/t";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./event-details-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDetailsFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces
// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-details-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDetailsForm: React.FC<EventDetailsFormProps> = (
    props: EventDetailsFormProps
): JSX.Element => {
    const { useEducationNetwork } = useFeatureFlags();

    const {
        confirmationModalOpen,
        confirmEventTypeChange,
        handleEventTypeChange,
        rejectEventTypeChange,
    } = useHandleEventTypeChange(props.event);

    const cards: RadioCardWithDescription[] = [
        {
            icon: Icons.EventType,
            text: "nfpaCatalogEvent",
            description: "theseEventsAreOpenToAllAndRunByNFPA",
            value: EventType.NFPACatalog.toString(),
        },
        {
            icon: Icons.EventType,
            text: "contractEvent",
            description: "theseEventsAreForContractsOnly",
            value: EventType.Contract.toString(),
        },
    ];

    if (useEducationNetwork) {
        cards.push({
            icon: Icons.EventType,
            text: "educationNetworkEvent",
            description: "theseEventsAreForTheAENOnlyAndRunByTheAENProvider",
            value: EventType.EducationNetwork.toString(),
        });
    }

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: rejectEventTypeChange,
            style: ButtonStyle.Primary,
        },
        {
            buttonText: t("yesChange"),
            onClick: confirmEventTypeChange,
            style: ButtonStyle.Secondary,
        },
    ];

    return (
        <div className={`${CSS_CLASS_NAME} `}>
            <div className={`${CSS_CLASS_NAME}__type`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("thisEventIsA")}
                </Heading>
                <FormRadioCardListWithDescription
                    columns={RadioCardColumns.Three}
                    disabled={props.event.status === PublishStatus.Live}
                    formFieldName="eventSalesType"
                    id="eventSalesType"
                    label=""
                    onChange={handleEventTypeChange}
                    radioCards={cards}
                    style={RadioCardStyle.Horizontal}
                    value={props.event.type?.toString()}
                />
            </div>
            <Modal
                actions={confirmationActionArray}
                isOpen={confirmationModalOpen}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                <b>{t("areYouSureYouWouldLikeToChangeTheEventSalesType")}</b>
                {t("byDoingSoAnyInformationEnteredInTheEventsDetail")}
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDetailsForm };

// #endregion Exports
