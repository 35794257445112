import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import "./selected-course-modal-course-list.scss";
import { t } from "utilities/localization/t";
import { AddCourseModalCourse } from "../unit-manage-add-courses";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------
interface SelectedCourseModalCourseListProps {
    selectedCourseModalCourses: AddCourseModalCourse[];
    handleAdd: (course: CourseRecord) => void;
    handleRemove: (course: CourseRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "selected-course-modal-course-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SelectedCourseModalCourseList: React.FC<SelectedCourseModalCourseListProps> = (
    props: SelectedCourseModalCourseListProps
) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="course-name">{t("courseName")}</th>
                    <th className="id">{t("id")}</th>
                    <th className="action">{t("action")}</th>
                </tr>
            </thead>
            <tbody>
                {props.selectedCourseModalCourses.map((selectedCourseModalCourses) => (
                    <tr key={selectedCourseModalCourses.course.id}>
                        <td className="course-name">{selectedCourseModalCourses.course.name}</td>
                        <td className="id">{selectedCourseModalCourses.course.id}</td>
                        <td className="action">
                            {!selectedCourseModalCourses.addedToQueue && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    iconType={Icons.Plus}
                                    onClick={() =>
                                        props.handleAdd(selectedCourseModalCourses.course)
                                    }
                                    text={t("add")}
                                />
                            )}
                            {selectedCourseModalCourses.addedToQueue && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Minus}
                                    onClick={() =>
                                        props.handleRemove(selectedCourseModalCourses.course)
                                    }
                                    text={t("remove")}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SelectedCourseModalCourseList };

// #endregion Exports
