import { DateTime } from "luxon";

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

/**
 * Return formatted time zone
 * Ex: Eastern Standard Time (EST)
 * @returns Return formatted time zone
 */
const formatTimeZone = (value?: string): string => {
    if (value) {
        const timeZoneName = DateTime.fromObject({}, { zone: value });
        return `${timeZoneName.offsetNameLong} (${timeZoneName.offsetNameShort})`;
    }
    return "";
};
// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const TimeZoneUtils = {
    formatTimeZone: formatTimeZone,
};

export { TimeZoneUtils };

// #endregion Exports
