import React from "react";
import "./cta-card.scss";
import { Card } from "components/card/card";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ButtonStyle, Button } from "components/buttons/button/button";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CTACardAction {
    disabled?: boolean;
    onClick: () => void;
    style: ButtonStyle;
    text: string;
}

export interface CTACardProps {
    actions?: CTACardAction[];
    description: string;
    imageAltText?: string;
    imagePath?: string;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "cta-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CTACard: React.FC<CTACardProps> = (props: CTACardProps) => {
    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            {/* {props.imagePath && (
                <div className={`${CSS_CLASS_NAME}__image`}>
                    <img src={props.imagePath} alt={props.imageAltText} />
                </div>
            )} */}
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {props.title}
                </Heading>
                <Paragraph style={ParagraphStyle.Light}>{props.description}</Paragraph>
                {props.actions && props.actions.length > 0 && (
                    <div className={`${CSS_CLASS_NAME}__content__actions`}>
                        {props.actions.map((action, actionIndex) => (
                            <Button
                                key={`cta-card-action-${actionIndex}`}
                                disabled={action.disabled}
                                style={action.style}
                                text={action.text}
                                onClick={action.onClick}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CTACard };

// #endregion Exports
