import { AssessmentQuestionResponseSummaryRecord } from "models/view-models/assessments/assessment-question-response-summary-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "assessment/question/response";
const resourceType: typeof AssessmentQuestionResponseSummaryRecord = AssessmentQuestionResponseSummaryRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListAssessmentQuestionResponsesSummariesQueryParams {
    eventId?: number;
    productId?: number;
    scormPackageAssessmentId?: number;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const AssessmentQuestionResponseSummaryService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        AssessmentQuestionResponseSummaryRecord,
        ListAssessmentQuestionResponsesSummariesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentQuestionResponseSummaryService };

// #endregion Exports
