import React from "react";
import logo from "assets/images/nfpa-logo.png";
import { LogoAnchor } from "components/typography/anchors/logo-anchor/logo-anchor";
import "./public-layout-header.scss";
import { t } from "utilities/localization/t";
import { LogInButton } from "components/log-in-button/log-in-button";
import { Paragraph } from "components/typography/paragraph/paragraph";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PublicLayoutHeaderProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "public-layout-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicLayoutHeader: React.FC<PublicLayoutHeaderProps> = (
    props: PublicLayoutHeaderProps
): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const cssClassName: string = cssClassNames.join(" ");

    return (
        <header className={cssClassName}>
            <div className={`${CSS_CLASS_NAME}__logo`}>
                <LogoAnchor />
            </div>
            <div className={`${CSS_CLASS_NAME}__text`}>
                <Paragraph>{t("learningPortal")}</Paragraph>
            </div>
        </header>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PublicLayoutHeader };

// #endregion Exports
