import avatarSquare from "assets/images/avatar-square.png";
import { FileUtils } from "utilities/files/file-utils";
import { StringUtils } from "andculturecode-javascript-core";
import "./avatar.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum AvatarAspect {
    Circle = "-circle",
    Portrait = "-portrait",
    Square = "-square",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AvatarProps {
    avatarFileId?: number;
    avatarAspect?: AvatarAspect;
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "avatar";
const DEFAULT_ASPECT: AvatarAspect = AvatarAspect.Circle;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Avatar: React.FC<AvatarProps> = ({
    avatarFileId,
    avatarAspect,
    cssClassName,
}): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME, avatarAspect ?? DEFAULT_ASPECT];

    if (StringUtils.hasValue(cssClassName)) {
        cssClassNames.push(cssClassName);
    }

    return (
        <div
            className={cssClassNames.join(" ")}
            style={{
                backgroundImage: `url(${
                    avatarFileId ? FileUtils.fileUrl(avatarFileId) : avatarSquare
                })`,
            }}></div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Avatar, AvatarAspect };

// #endregion Exports
