import React, { useMemo } from "react";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { useEventChangeLogs } from "utilities/hooks/models/events/use-event-change-logs";
import { DataTable } from "components/tables/data-table/data-table";
import {
    EventChangeLogType,
    EventChangeLogTypeDisplayNames,
} from "models/enumerations/events/event-change-log-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { t } from "utilities/localization/t";
import "./admin-edit-event-change-log-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventChangeLogPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-change-log-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventChangeLogPage: React.FC<AdminEditEventChangeLogPageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventChangeLogPage
)((): JSX.Element => {
    const event = useEventActiveRecordContext();
    const eventId = useMemo(() => event.id, [event.id]);
    const { eventChangeLogs } = useEventChangeLogs({ eventId });
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    useRedirectOnForbidden(sitemap.admin.dashboard);

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__main`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.Small}>
                        {t("changeHistory")}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("lastChanged")}</th>
                                <th>{t("changedBy")}</th>
                                <th>{t("fieldsChanged")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventChangeLogs.map((changeLog) => (
                                <tr key={changeLog.id}>
                                    <td className="last-change">
                                        {changeLog.getChangedOnDateAndTime()}
                                    </td>
                                    <td className="changed-by">
                                        <span className="user-name">
                                            {changeLog.changedBy?.getFirstAndLastName()}
                                        </span>
                                        {currentUserIsInNfpaRole && (
                                            <>
                                                <span className="user-id">
                                                    {changeLog.changedById}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <span
                                            className={
                                                changeLog.type === EventChangeLogType.Published ||
                                                changeLog.type === EventChangeLogType.Canceled
                                                    ? "-status-change"
                                                    : "-content-change"
                                            }>
                                            {changeLog.type != null &&
                                                t(EventChangeLogTypeDisplayNames[changeLog.type])}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventChangeLogPage };

// #endregion Exports
