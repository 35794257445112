import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import React from "react";
import { t } from "utilities/localization/t";
import "./tag.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TagProps {
    cssClassName?: string;
    disabled?: boolean;
    icon?: Icons;
    id: string;
    locked?: boolean;
    onSelect?: (selected: boolean, text: string, value: string) => void;
    selected?: boolean;
    text: string;
    value: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "tag";
const DEFAULT_ICON: Icons = Icons.Checkmark;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Tag: React.FC<TagProps> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.selected) {
        cssClassNames.push("-selected");
    }

    if (props.locked) {
        cssClassNames.push("-locked");
    }

    const className = cssClassNames.join(" ");

    const icon = props.icon == null ? DEFAULT_ICON : props.icon;

    const handleKeyPress = (
        e: React.KeyboardEvent<HTMLLabelElement>,
        text: string,
        value: string
    ) => {
        if (e.key !== " " || props.disabled) {
            return;
        }
        e.preventDefault();
        onSelect(!props.selected, text, value);
    };

    const onSelect = (checked: boolean, text: string, value: string) => {
        if (props.disabled) {
            return;
        }

        if (props.onSelect) {
            props.onSelect(checked, text, value);
        }
    };

    return (
        <div className={className}>
            <Icon type={icon} />
            <input
                checked={props.selected}
                disabled={props.disabled}
                id={props.id}
                onChange={(e) => onSelect(e.target.checked, props.text, props.value)}
                type="checkbox"
            />
            <label
                aria-label={
                    props.selected
                        ? t("propstextSelected", { propsText: props.text })
                        : t("propstextNotSelected", { propsText: props.text })
                }
                htmlFor={props.id}
                onKeyDown={(e) => handleKeyPress(e, props.text, props.value)}>
                {props.text}
            </label>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Tag };

// #endregion Exports
