import { RecordUtils } from "andculturecode-javascript-core";
import { EmulationToken } from "models/interfaces/emulation/emulation-token";
import { Record } from "immutable";

const defaultValues: EmulationToken = RecordUtils.defaultValuesFactory<EmulationToken>({
    id: undefined,
    expiresOn: undefined,
    guestId: undefined,
});

export default class EmulationTokenRecord
    extends Record(defaultValues)
    implements EmulationTokenRecord
{
    constructor(params?: EmulationToken) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    public with(values: Partial<EmulationToken>): EmulationTokenRecord {
        return new EmulationTokenRecord(Object.assign(this.toJS(), values));
    }
}
