import axios from "axios";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { CourseRecord } from "models/view-models/courses/course-record";
import { Language } from "models/enumerations/languages/language";
import { useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { Topic } from "models/enumerations/courses/topic";
import { useCallback } from "react";
import { ServiceFactory } from "utilities/services/service-factory";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "courses";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const activateEndpoint: string = `${baseEndpoint}/:id/activate`;
const archiveEndpoint: string = `${resourceEndpoint}/archive`;
const unpublishEndpoint: string = `${resourceEndpoint}/unpublish`;
const deactivateEndpoint: string = `${baseEndpoint}/:id/deactivate`;
const resourceType: typeof CourseRecord = CourseRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchCourseService = (
    params: PatchCourseServiceParams
) => Promise<ServiceResponse<CourseRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetCoursePathParams {
    id: number;
}

export interface PatchCoursePathParams {
    id: number;
}

export interface GetCourseQueryParams {
    includeContent?: boolean;
    includeLatestVersion?: boolean;
    includeScormPackage?: boolean;
}

export interface ListCoursesQueryParams {
    activeStatus?: ActiveStatus;
    applySearch?: boolean;
    audienceType?: AudienceType;
    includeArchived?: boolean;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
    includeLatestVersion?: boolean;
    includeScormPackage?: boolean;
    language?: Language;
    searchText?: string;
    skip?: number;
    take?: number;
    topic?: Topic;
}

export interface PatchCourseServiceParams {
    id: number;
    //change
}

export interface UpdateCoursePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CourseService = {
    /**
     * Create a Course
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update a Course
     */
    update: ServiceFactory.update<CourseRecord, UpdateCoursePathParams>(
        resourceType,
        resourceEndpoint
    ),

    useActivate: (): { activate: PatchCourseService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchCourseService = (
            params: PatchCourseServiceParams
        ): Promise<ServiceResponse<CourseRecord>> => {
            const endPointWithPatchRouteReplaced = activateEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(params: PatchCourseServiceParams): Promise<ServiceResponse<CourseRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<CourseRecord>
            >;
        }

        return { activate: useCallback(patch, [cancellablePromise]) };
    },

    useArchive: (): { archive: PatchCourseService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchCourseService = (
            params: PatchCourseServiceParams
        ): Promise<ServiceResponse<CourseRecord>> => {
            const endPointWithPatchRouteReplaced = archiveEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(params: PatchCourseServiceParams): Promise<ServiceResponse<CourseRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<CourseRecord>
            >;
        }

        return { archive: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom Hook to cancel an Event.
     */
    useUnpublish: ServiceHookFactory.usePatch<CourseRecord, PatchCoursePathParams>(
        CourseRecord,
        unpublishEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    useDeactivate: (): { deactivate: PatchCourseService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchCourseService = (
            params: PatchCourseServiceParams
        ): Promise<ServiceResponse<CourseRecord>> => {
            const endPointWithPatchRouteReplaced = deactivateEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(params: PatchCourseServiceParams): Promise<ServiceResponse<CourseRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<CourseRecord>
            >;
        }

        return { deactivate: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<CourseRecord, GetCoursePathParams, GetCourseQueryParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<CourseRecord, ListCoursesQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<CourseRecord, UpdateCoursePathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseService };

// #endregion Exports
