import { Record } from "immutable";
import { ScormPackageStatus } from "models/interfaces/scorm-packages/scorm-package-status";

const defaultValues: ScormPackageStatus = {
    externalVersionId: undefined,
    uploadStatus: undefined,
    errorMessage: "",
    hasValidAssessment: undefined,
};

class ScormPackageStatusRecord extends Record(defaultValues) implements ScormPackageStatus {}
// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageStatusRecord };

// #endregion Exports
