import React from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { StringUtils } from "utilities/string-utils";
import { Map } from "utilities/types/map";
import { AnchorPathType } from "../../typography/anchors/anchor/anchor";
import "./button-icon.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ButtonIconPosition {
    Left = "-left",
    Right = "-right",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const iconSizeMap: Map<ButtonSize, IconSizes> = {
    [ButtonSize.Medium]: IconSizes.Medium,
    [ButtonSize.None]: IconSizes.Medium,
    [ButtonSize.Small]: IconSizes.Small,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ButtonIconProps {
    ariaLabelledBy?: string;
    buttonSize?: ButtonSize;
    buttonStyle?: ButtonStyle;
    buttonType?: ButtonType;
    cssClassName?: string;
    disabled?: boolean;
    iconPosition?: ButtonIconPosition;
    iconSize?: IconSizes;
    iconType: Icons;
    linkPath?: string;
    linkPathType?: AnchorPathType;
    onClick?: () => void;
    text?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "button-icon";
const DEFAULT_BUTTON_SIZE: ButtonSize = ButtonSize.Medium;
const DEFAULT_ICON_POSITION: ButtonIconPosition = ButtonIconPosition.Left;
const DEFAULT_ICON_SIZE: IconSizes = IconSizes.Base;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region ButtonIcon
// -------------------------------------------------------------------------------------------------

const ButtonIcon: React.FC<ButtonIconProps> = (props: ButtonIconProps): JSX.Element => {
    const buttonSize: ButtonSize = props.buttonSize ?? DEFAULT_BUTTON_SIZE;
    const iconSize: IconSizes = StringUtils.hasValue(props.text)
        ? iconSizeMap[buttonSize]
        : props.iconSize ?? DEFAULT_ICON_SIZE;

    const iconPosition: ButtonIconPosition = props.iconPosition ?? DEFAULT_ICON_POSITION;
    const content: JSX.Element[] = [<Icon key="icon" size={iconSize} type={props.iconType} />];

    if (StringUtils.hasValue(props.text)) {
        content.push(
            <span className={`${CSS_CLASS_NAME}__text`} key="text">
                {props.text}
            </span>
        );
    }

    if (iconPosition === ButtonIconPosition.Right) {
        content.reverse();
    }

    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    return (
        <Button
            ariaLabelledBy={props.ariaLabelledBy}
            cssClassName={cssClassNames.join(" ")}
            disabled={props.disabled}
            linkPath={props.linkPath}
            linkPathType={props.linkPathType}
            onClick={props.onClick}
            size={props.buttonSize}
            style={props.buttonStyle}
            type={props.buttonType}>
            {content}
        </Button>
    );
};

// #endregion ButtonIcon

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ButtonIcon, ButtonIconPosition };

// #endregion Exports
