import { List } from "immutable";
import { StringUtils as AndcultureCodeStringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

/**
 * Strips out any tags from the given string.
 *
 * @see https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
 * @param value String to have tags removed from
 */
const addSpacesToEnum = (value?: string): string => {
    if (AndcultureCodeStringUtils.isEmpty(value)) {
        return "";
    }

    return value.replace(/([A-Z])/g, " $1").trim();
};

const contains = (source?: string, substring?: string): source is string =>
    AndcultureCodeStringUtils.hasValue(source) &&
    AndcultureCodeStringUtils.hasValue(substring) &&
    source.indexOf(substring) >= 0;

/**
 * Formats an array of strings to a list format
 *
 * @param array of strings
 */

const formatToList = (array: Array<string>): string => {
    if (array.length === 0) {
        return "";
    }

    if (array.length === 1) {
        return array[0];
    }

    if (array.length === 2) {
        return array.join(" and ");
    }

    const copy = [...array];

    const last = copy.pop();

    const list = copy.join(", ");

    return t("listAndLast", { list: list, last: last });
};

/**
 * Generates an 'excerpt' based around a provided substring to ensure it is always present in the
 * output string, and the output string is less than or equal to the specified character limit.
 *
 * @param {string} fullString
 * @param {string} substring
 * @param {number} characterLimit
 */
const generateExcerpt = (fullString: string, substring: string, characterLimit: number): string => {
    // Subtract 6 from the character limit to account for ellipses before & after
    characterLimit = characterLimit - 6;
    const getCharCount = (list: List<string>): number =>
        list.filter((value: string) => value != null).join(" ").length;

    const originalWords = List(fullString.split(" ").filter((e: string) => e.trim() !== ""));

    const indexOfSubstring = originalWords.findIndex(
        (o) => o === substring || o.includes(substring)
    );

    // If the substring cannot be found, return the original string
    if (indexOfSubstring < 0) {
        return fullString;
    }

    let truncatedList = List([substring]);
    let offsetFromSubstring = 1;
    let canContinue = true;

    // Loop over the original list of words, fanning out from the first instance of the substring
    // until we have hit the character limit or we have no more words to pull from in both directions.
    while (canContinue && getCharCount(truncatedList) <= characterLimit) {
        // Determine whether we should continue based on whether the offset value from the substring
        // is within the range of the original word list count
        const indexOfNextWord = indexOfSubstring + offsetFromSubstring;
        const indexOfPreviousWord = indexOfSubstring - offsetFromSubstring;

        // Attempt to grab the next word in the original input string. If the index is invalid,
        // we'll null check the result before pushing it to our rebuilt list.
        const nextWord = originalWords.get(indexOfNextWord);

        // Make sure the calculated previous word index is not negative. A negative index is valid
        // and will start pulling values from the back of the collection which is desired behavior
        // for rebuilding the provided sentence/paragraph text in order.
        const prevWord =
            indexOfPreviousWord >= 0
                ? originalWords.get(indexOfSubstring - offsetFromSubstring)
                : null;

        // If we don't have a word on either side, we cannot continue building out the truncated list
        // of words.
        canContinue = nextWord != null || prevWord != null;

        if (!canContinue) {
            break;
        }

        let expandedWordList = List<string>();
        if (prevWord != null) {
            expandedWordList = expandedWordList.push(prevWord);
        }

        expandedWordList = expandedWordList.concat(truncatedList);

        if (nextWord != null) {
            expandedWordList = expandedWordList.push(nextWord);
        }

        if (getCharCount(expandedWordList) > characterLimit) {
            canContinue = false;
            break;
        }

        // Increment the offset to 'fan out' another word on each side for the next iteration
        offsetFromSubstring++;
        truncatedList = expandedWordList;
    }

    // Add ellipses before and after if the first and last elements of the array are do not match
    // the original array of words we are working from.
    let excerpt = truncatedList.join(" ");
    if (truncatedList.first() !== originalWords.first()) {
        excerpt = `...${excerpt}`;
    }

    if (truncatedList.last() !== originalWords.last()) {
        excerpt = `${excerpt}...`;
    }

    return excerpt;
};

/**
 * Compare equality of two strings, with the option of case-insensitivity.
 *
 * @param {string} left
 * @param {string} right
 * @param {boolean} [caseInsensitive=true] If set to true, casing is ignored, ie: "STRING" would equal "sTrInG"
 * @reference https://stackoverflow.com/a/2140723
 */
const isEqual = (left: string, right: string, caseInsensitive: boolean = true): boolean => {
    if (!caseInsensitive) {
        return left === right;
    }

    // Compare the two strings without taking into account casing. Variants of the same base character
    // are the same unless they have different accents, ie: isEqual("a", "á") returns false
    return left.localeCompare(right, undefined, { sensitivity: "accent" }) === 0;
};

/**
 * Loosely validates a phone number (US or international)
 * @reference https://stackoverflow.com/a/5933940
 */
const isValidPhoneNumber = (value?: string): boolean => {
    if (AndcultureCodeStringUtils.isEmpty(value)) {
        return false;
    }

    if (/[a-zA-Z]+/g.test(value)) {
        return false;
    }

    // Strip any non-digit characters out before testing the regex
    value = value?.replace(/[^0-9]+/g, "");

    return /[0-9]{3,15}/g.test(value);
};

/**
 * Validates a given string is at least 6 characters long and meets 3 of the following:
 * 1 uppercase letter
 * 1 lowercase letter
 * 1 number
 * 1 special character
 * @param value
 */
const isValidPassword = (value?: string): boolean =>
    value != null &&
    new RegExp(
        /(?=.{6,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
    ).test(value);

/**
 * Validates that a string is a valid URL.
 * Validates that the URL contains a scheme/protocol (i.e. "https://" or "http://")
 * and that it does not contain any whitespace characters.
 * @param value
 * @param allowedProtocols
 */
const isValidUrl = (
    value?: string,
    allowedProtocols: Array<string> = ["http", "https"]
): boolean => {
    if (StringUtils.isEmpty(value)) {
        return false;
    }

    const pattern = new RegExp(`^(${allowedProtocols.join("|")})://[^ "]+$`);
    return pattern.test(value);
};

/**
 * Joins an array of strings representing css class names into one string, each separated by a space.
 * If the array is empty, it will return an empty string.
 *
 * @default ""
 * @param {string[]} classNames
 */
const joinClassNames = (classNames: string[]): string =>
    AndcultureCodeStringUtils.join(classNames, " ");

/**
 * Builds a string that lists the amount of something and a label that may or may not be pluralized
 * based on the amount provided. This defaults to adding an 's' to the end of the label if a
 * specific plural form is not provided.
 *
 * @param {number} amount The amount of the item to build the label and determine pluralization for.
 * @param {string} label The singular form of the label.
 * @param {string | undefined} pluralForm Optional parameter to specify the plural form of the label. If not provided, the label will be pluralized by appending an 's'.
 * @returns {string} A string showing the amount of the item and either the singular or plural form of its label.
 */
const pluralize = (amount: number, label: string, pluralForm?: string): string => {
    const trimmedLabel: string = label.trim();
    const pluralFormToUse: string = AndcultureCodeStringUtils.hasValue(pluralForm)
        ? pluralForm.trim()
        : `${trimmedLabel}s`;
    const pluralizedLabel: string = Math.abs(amount) === 1 ? trimmedLabel : pluralFormToUse;

    return `${amount} ${pluralizedLabel}`;
};

/**
 * Strips out any tags from the given string.
 *
 * @see https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
 * @param value String to have tags removed from
 */
const stripHtmlTags = (value?: string): string => {
    if (AndcultureCodeStringUtils.isEmpty(value)) {
        return "";
    }

    return value!.replace(/(<([^>]+)>)/gi, "");
};

/**
 * Display the value if it has a value, or the defaultValue provided.
 */
const valueOrDefault = (value?: string, defaultValue: string = "--"): string => {
    if (AndcultureCodeStringUtils.hasValue(value)) {
        return value!;
    }
    return defaultValue;
};

// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const StringUtils = {
    ...AndcultureCodeStringUtils,
    addSpacesToEnum: addSpacesToEnum,
    contains: contains,
    formatToList: formatToList,
    generateExcerpt: generateExcerpt,
    isEqual: isEqual,
    isValidPassword: isValidPassword,
    isValidPhoneNumber: isValidPhoneNumber,
    isValidUrl: isValidUrl,
    joinClassNames: joinClassNames,
    pluralize: pluralize,
    stripHtmlTags: stripHtmlTags,
    valueOrDefault: valueOrDefault,
};

export { StringUtils };

// #endregion Exports
