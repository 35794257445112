import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorProfileStatus } from "models/instructors/instructor-profile-status";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { InstructorService } from "utilities/services/instructors/instructor-service";
import { Language } from "models/enumerations/languages/language";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { TravelType } from "models/instructors/travel-type";
import { t } from "utilities/localization/t";
import { useEffect, useState } from "react";

interface UseInstructorsHook {
    instructors: InstructorRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface UseInstructorsHookOptions {
    approvedToTeachProductId?: number;
    country?: string;
    includeAenNetwork?: boolean;
    includeInstructorProfile?: boolean;
    language?: Language;
    onlyInstructorsWithSharedLocation?: boolean;
    providerId?: number;
    searchText?: string;
    skip?: number;
    state?: string;
    status?: InstructorProfileStatus;
    take?: number;
    teachingMethod?: InstructorLedTrainingType;
    travelType?: TravelType;
}

export function useInstructors(props: UseInstructorsHookOptions): UseInstructorsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [instructors, setInstructors] = useState<InstructorRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listInstructors } = InstructorService.useList();

    const approvedToTeachProductId = props.approvedToTeachProductId;
    const country = props.country;
    const includeAenNetwork = props.includeAenNetwork;
    const includeInstructorProfile = props.includeInstructorProfile;
    const language = props.language;
    const onlyInstructorsWithSharedLocation = props.onlyInstructorsWithSharedLocation;
    const providerId = props.providerId;
    const searchText = props.searchText;
    const skip = props.skip;
    const state = props.state;
    const status = props.status;
    const take = props.take;
    const teachingMethod = props.teachingMethod;
    const travelType = props.travelType;

    useEffect(() => {
        try {
            (async function getInstructors() {
                const { result, resultObjects, rowCount } = await listInstructors({
                    approvedToTeachProductId,
                    country,
                    includeAenNetwork,
                    language,
                    onlyInstructorsWithSharedLocation,
                    providerId,
                    searchText,
                    skip,
                    state,
                    status,
                    take,
                    teachingMethod,
                    travelType,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setInstructors(resultObjects);
            })();
        } catch {
            ToastManager.error(t("failedToRetrieveInstructors"));
        }
    }, [
        searchText,
        take,
        language,
        skip,
        listInstructors,
        approvedToTeachProductId,
        country,
        includeAenNetwork,
        includeInstructorProfile,
        onlyInstructorsWithSharedLocation,
        providerId,
        state,
        status,
        teachingMethod,
        travelType,
    ]);

    return {
        errors,
        isLoading,
        instructors,
        rowCount,
    };
}
