import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum RecipientType {
    EventAttendees = 0,
    Group = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const RecipientTypeDisplayNames: Map<RecipientType, TranslatedCopy> = {
    [RecipientType.EventAttendees]: "eventAttendees",
    [RecipientType.Group]: "group",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RecipientType, RecipientTypeDisplayNames };

// #endregion Exports
