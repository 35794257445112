import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import { FormFieldProps } from "components/form/form-field/form-field";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { InputProperties } from "../input-properties";
import { SelectOption } from "../inputs/select/select";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import { StringUtils } from "utilities/string-utils";
import "./form-search-select.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormSearchSelectProps extends FormFieldProps, InputProperties {
    defaultText: string;
    includeEmptyOption?: boolean;
    onBlur?: () => void;
    onChange: (value: string) => void;
    options: SelectOption[];
    selectedOption: SelectOption;
    onSearchTextChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-search-select";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormSearchSelect: React.FC<FormSearchSelectProps> = ({
    defaultText,
    includeEmptyOption,
    onBlur,
    onChange,
    options,
    selectedOption,
    label,
    required,
    disabled,
    onSearchTextChange,
}) => {
    const { readOnly: isCtxReadOnly } = useReadOnly();
    const readOnly = useMemo(() => disabled || isCtxReadOnly, [isCtxReadOnly, disabled]);

    const [searchText, setSearchText] = useState("");

    const optionsWithEmpty: SelectOption[] = useMemo(() => {
        if (includeEmptyOption) {
            return [{ text: "", value: "" }, ...options];
        }
        return options;
    }, [includeEmptyOption, options]);

    const filter =
        searchText === ""
            ? optionsWithEmpty
            : optionsWithEmpty.filter((option) => {
                  return (
                      option.text.toLowerCase().includes(searchText.toLowerCase()) ||
                      option.value === searchText
                  );
              });

    const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            setSearchText(event.target.value);
            onSearchTextChange?.(event);
        },
        [onSearchTextChange]
    );

    const handleBlur = useCallback((): void => {
        onBlur?.();
    }, [onBlur]);

    return (
        <Combobox
            as="div"
            className={`${CSS_CLASS_NAME} ${disabled}`}
            disabled={readOnly}
            value={selectedOption.value}
            onChange={onChange}>
            <Combobox.Label className={`${CSS_CLASS_NAME}__label`}>
                {label} {required ? " *" : ""}
            </Combobox.Label>
            <div className={`${CSS_CLASS_NAME}__input-container`}>
                <Combobox.Input
                    className="-input"
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                    displayValue={() =>
                        StringUtils.hasValue(selectedOption.value)
                            ? `(${selectedOption.value}) ${selectedOption.text}`
                            : ""
                    }
                    placeholder={defaultText}
                    title={selectedOption?.text}
                />
                <Combobox.Button className="-button-icon">
                    <Icon type={Icons.Expand} />
                </Combobox.Button>

                {filter.length > 0 && (
                    <Combobox.Options className={`${CSS_CLASS_NAME}__dropdown`}>
                        {filter.map((option: SelectOption) => (
                            <Combobox.Option
                                key={option.text}
                                value={option.value}
                                className={`${CSS_CLASS_NAME}__dropdown-option ${option.value}`}>
                                {({ active, selected }) => (
                                    <>
                                        <div className={`${CSS_CLASS_NAME}__dropdown-item`}>
                                            <span className={`${CSS_CLASS_NAME}__dropdown-id`}>
                                                {`${
                                                    StringUtils.hasValue(option.value)
                                                        ? "(" + option.value + ")"
                                                        : ""
                                                }`}
                                            </span>
                                            <span
                                                className={`${CSS_CLASS_NAME}__dropdown-name`}
                                                title={option.text}>
                                                {option.text}
                                            </span>
                                            {selected && (
                                                <Icon
                                                    type={Icons.Checkmark}
                                                    aria-hidden="true"
                                                    cssClassName={`${CSS_CLASS_NAME}__dropdown-icon`}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

// #endregion Components

export { FormSearchSelect };
