import { Record } from "immutable";
import { EnrollmentUser } from "models/interfaces/enrollments/enrollment-user";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EnrollmentUser = {
    email: undefined,
    errors: undefined,
    firstName: undefined,
    lastName: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EnrollmentUserRecord extends Record(defaultValues) implements EnrollmentUser {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EnrollmentUser>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EnrollmentUser properties, create a new EnrollmentUserRecord object
     * from this instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EnrollmentUser>} values The values to overwrite on this instance.
     * @returns A EnrollmentUserRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EnrollmentUser>): EnrollmentUserRecord {
        return new EnrollmentUserRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentUserRecord };

// #endregion Exports
