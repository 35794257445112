import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";
import "./user-training-detail-history.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailHistoryProps {
    enrollment: EnrollmentRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-history";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailHistory: React.FC<UserTrainingDetailHistoryProps> = ({
    enrollment,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("enrollmentHistory")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th>{t("activity")}</th>
                        <th>{t("date")}</th>
                        <th>{t("by")}</th>
                    </tr>
                </thead>
                <tbody>
                    {enrollment.withdrawnOn != null && (
                        <tr>
                            <td>
                                <Paragraph size={ParagraphSize.XSmall}>{t("withdrawn")}</Paragraph>
                            </td>
                            <td>
                                <Paragraph size={ParagraphSize.XSmall}>
                                    {DateUtils.formatDateTime(
                                        true,
                                        enrollment.withdrawnOn.toString(),
                                        true
                                    )}
                                </Paragraph>
                            </td>
                            <td>
                                <Paragraph size={ParagraphSize.XSmall}>
                                    {enrollment.withdrawnBy?.getFirstAndLastName()}
                                </Paragraph>
                                <Paragraph style={ParagraphStyle.Label}>
                                    {enrollment.withdrawnById}
                                </Paragraph>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <Paragraph size={ParagraphSize.XSmall}>{t("enrolled")}</Paragraph>
                        </td>
                        <td>
                            <Paragraph size={ParagraphSize.XSmall}>
                                {DateUtils.formatDateTime(
                                    true,
                                    enrollment.createdOn?.toString(),
                                    true
                                )}
                            </Paragraph>
                        </td>
                        <td>
                            <Paragraph size={ParagraphSize.XSmall}>
                                {enrollment.createdBy?.getFirstAndLastName()}
                            </Paragraph>
                            <Paragraph style={ParagraphStyle.Label}>
                                {enrollment.createdById}
                            </Paragraph>
                        </td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailHistory };

// #endregion Exports
