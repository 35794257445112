import { RouteUtils } from "utilities/route-utils";
import { CourseCard } from "components/courses/course-card/course-card";
import { EnrollmentUnitCourseStatus } from "models/enumerations/enrollments/enrollment-unit-course-status";
import { useMemo } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { sitemap } from "sitemap";
import { NumberUtils } from "utilities/number-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UnitCourseCardProps {
    id: number;
    courseId: number;
    locked: boolean;
    name: string;
    status: EnrollmentUnitCourseStatus;
    required: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitCourseCard: React.FC<UnitCourseCardProps> = (props: UnitCourseCardProps): JSX.Element => {
    const {
        productId: productIdParam,
        enrollmentId: enrollmentIdParam,
        unitId: unitIdParam,
    } = useParams();

    const enrollmentId = useMemo(
        () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
        [enrollmentIdParam]
    );
    const productId = useMemo(() => NumberUtils.parseInt(productIdParam) ?? 0, [productIdParam]);
    const unitId = useMemo(() => NumberUtils.parseInt(unitIdParam) ?? 0, [unitIdParam]);

    const navigate = useNavigate();
    const coursePath: string = useMemo(
        () =>
            RouteUtils.replacePathParams(
                sitemap.learner.training.onlineLearning.units.courses.details,
                {
                    productId,
                    enrollmentId,
                    unitId,
                    courseId: props.courseId,
                }
            ),
        [productId, enrollmentId, unitId, props.courseId]
    );

    const viewCourseClick = () => {
        navigate(coursePath);
    };

    return (
        <CourseCard
            id={props.id}
            locked={props.locked}
            name={props.name}
            onViewCourseClick={viewCourseClick}
            status={props.status}
            required={props.required}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitCourseCard };

// #endregion Exports
