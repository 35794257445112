import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { ContentRecord } from "models/view-models/contents/content-record";
import { ContentService } from "utilities/services/contents/content-service";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { CourseCreateContentForm } from "components/courses/course-content/course-create-content-modal/course-create-content-form/course-create-content-form";
import { Modal, ModalAction } from "components/modal/modal";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseCreateContentModalProps {
    selectedCourseContentIndex?: number;
    courseContent?: CourseContentRecord;
    courseContents?: CourseContentRecord[];
    courseId?: number;
    courseVersionId: number;
    deferSave: boolean;
    handleContentDetailsChange?: (content: ContentRecord) => void;
    handleSaveContent?: (content: ContentRecord, courseId?: number) => Promise<boolean>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTempCourseContents?: React.Dispatch<React.SetStateAction<CourseContentRecord[]>>;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseCreateContentModal: React.FC<CourseCreateContentModalProps> = (
    props: CourseCreateContentModalProps
): JSX.Element => {
    const { create: apiContentCreate } = ContentService.useCreate();
    const { update: apiContentUpdate } = ContentService.useUpdate();
    const initialCourseContent = useMemo(
        () => props.courseContent ?? new CourseContentRecord(),
        [props.courseContent]
    );
    const initialContent = useMemo(
        () => initialCourseContent?.content ?? new ContentRecord(),
        [initialCourseContent]
    );
    const [content, setContent] = useState<ContentRecord>(initialContent);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);

    useEffect(() => {
        setContent(initialContent);
    }, [initialContent]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveContent"),
            disabled: !(
                StringUtils.hasValue(content.name) &&
                StringUtils.hasValue(content.description) &&
                content.fileId !== undefined &&
                !addButtonDisabled
            ),
            onClick: () => (!props.courseContent?.content ? onContentAdd() : onContentUpdate()), //make sure this works
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setContent(initialContent);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onContentAdd = async (): Promise<void> => {
        setAddButtonDisabled(true);
        if (await props.handleSaveContent!(content)) {
            setDirty(false);
            setContent(initialContent);
            props.setOpen(false);
            setAddButtonDisabled(false);
        } else {
            return;
        }
    };

    const onContentUpdate = () => {
        setAddButtonDisabled(true);
        if (props.deferSave && props.courseContent?.content) {
            props.setDirty && props.setDirty(true);
            createContentForUpdate();
        } else {
            props.handleContentDetailsChange!(content);
        }
    };

    const createContentForUpdate = async (): Promise<void> => {
        try {
            if (props.selectedCourseContentIndex == null) return;
            if (props.courseContents == null) return;
            let courseContentId = content.id; //Not going to work.
            let updatedContent = content;

            const selectedCourseContent = props.courseContents[props.selectedCourseContentIndex];

            if (props.courseContent?.id === 0 || props.courseContent?.id === undefined) {
                //You dont need to create a new content record if it is already new.
                courseContentId = selectedCourseContent.contentId;
                //Now we need to update the course content record in the database!
                const updateContentResponse = await apiContentUpdate(content);
                //Do we need to do anything here???
            } else {
                const createContentResponse = await apiContentCreate(content);
                const createContentResult = createContentResponse?.result;

                if (createContentResult?.resultObject == null || createContentResult.hasErrors()) {
                    throw new Error();
                }
                courseContentId = createContentResult?.resultObject.id;
                updatedContent = createContentResult?.resultObject.with({
                    file: content.file,
                });
            }

            //Create course content record with new content in memory
            const tempCourseContent = new CourseContentRecord({
                id: 0, // On Save, Tell the API to create a new CourseContentRecord
                content: updatedContent,
                contentId: courseContentId, //createContentResult?.resultObject.id,
                courseId: props.courseId,
                courseVersionId: props.courseVersionId,
                sortOrder: props.courseContent?.sortOrder ?? 0,
            });

            //Replace course content with temp course content
            props.courseContents!.splice(props.selectedCourseContentIndex, 1, tempCourseContent);
            props.setOpen(false);
            setAddButtonDisabled(false);
            props.setTempCourseContents!(props.courseContents!);
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingCourseContent"));
        }
    };

    const onContentChanged = (content: ContentRecord): void => {
        setDirty(true);
        setContent(content);
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={props.courseContent?.content == null ? t("addContent") : t("replaceContent")}
            actions={modalActionArray}
            modalStyle={""}>
            <CourseCreateContentForm content={content} onContentChanged={onContentChanged} />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseCreateContentModal };

// #endregion Exports
