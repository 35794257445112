import { LocalizationConstants } from "constants/localization/localization-constants";
import {
    NavigateFunction,
    NavigateOptions,
    Path,
    To,
    useNavigate as useReactRouterNavigate,
} from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type UseNavigateHook = () => NavigateFunction;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useNavigate: UseNavigateHook = () => {
    const navigate = useReactRouterNavigate();

    const localizeIfNecessary = (path?: string): string | undefined => {
        if (StringUtils.contains(path, LocalizationConstants.CULTURE_CODE_DYNAMIC_SLUG)) {
            return RouteUtils.localizePath(path);
        }

        return path;
    };

    const localizeStringTo = (to: string): To => localizeIfNecessary(to) ?? "";

    const localizePartialPathTo = (to: Partial<Path>): To => {
        to.pathname = localizeIfNecessary(to.pathname);

        return to;
    };

    return (to: To | number, options?: NavigateOptions): void => {
        if (typeof to === "number") {
            navigate(to);
            return;
        }

        to = typeof to === "string" ? localizeStringTo(to) : localizePartialPathTo(to);

        navigate(to, options);
    };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useNavigate };

// #endregion Exports
