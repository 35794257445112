import axios from "axios";
import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { ClaimLinkTrialRecord } from "models/view-models/enrollments/claim-link-trial-record";
import { CreateBulkEnrollmentDto } from "models/interfaces/enrollments/create-bulk-enrollment-dto";
import { CreateEnrollmentDto } from "models/interfaces/enrollments/create-enrollment-dto";
import { EncodingType } from "models/enumerations/encoding/encoding-type";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { EnrollmentUserRecord } from "models/view-models/enrollments/enrollment-user-record";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";
import { useCancellablePromise } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments";
const resourceType: typeof EnrollmentRecord = EnrollmentRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const withdrawEnrollmentEndpoint = `${baseEndpoint}/:id/withdraw`;
const countResourceEndpoint = `${baseEndpoint}/count`;
const patchBaseEndpoint: string = `${baseEndpoint}/:id`;
const patchExpirationEndpoint: string = `${patchBaseEndpoint}/expiration`;
const patchClaimLinkTrialEndpoint: string = `${patchBaseEndpoint}/claimLinkTrial`;
const csvPreviewEndpoint: string = `${baseEndpoint}/csvpreview/:encodingType/:fileId`;
const bulkEnrollmentEndpoint: string = `${baseEndpoint}/:encodingType/bulkUpload`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateEnrollmentService = (
    params: CreateEnrollmentDto
) => Promise<ServiceResponse<EnrollmentRecord>>;

type CreateBulkEnrollmentService = (
    encodingType: EncodingType,
    params: CreateBulkEnrollmentDto
) => Promise<ServiceResponse<BulkEnrollmentSummaryRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEnrollmentPathParams {
    id: number;
}

export interface GetEnrollmentQueryParams {
    includeAttendance?: boolean;
    includeCertificate?: boolean;
    includeEvaluation?: boolean;
    includeEvent?: boolean;
    includeProduct?: boolean;
    includeExpirationHistory?: boolean;
    includeCreatedBy?: boolean;
    includeWithdrawnBy?: boolean;
}

export interface GetCSVPreviewPathParams {
    encodingType: EncodingType;
    fileId: number;
}

export interface GetCSVPreviewQueryParams {
    hasHeader: boolean;
}

export interface ListEnrollmentsQueryParams {
    contractId?: number;
    eventId?: number;
    includeAttendance?: boolean;
    includeEvaluation?: boolean;
    includeEvent?: boolean;
    includeProduct?: boolean;
    includeUser?: boolean;
    includeCreatedBy?: boolean;
    includeWithdrawnBy?: boolean;
    productId?: number;
    userId?: number;
    userSearchText?: string;
    skip?: number;
    take?: number;
}

export interface WithdrawEnrollmentPathParams {
    id: number;
}

export interface CountEnrollmentsQueryParams {
    contractId?: number;
    eventId?: number;
    isWithdrawn?: boolean;
    productId?: number;
    userId?: number;
}

// -------------------------------------------------------------------------------------------------
// #region Patch
// -------------------------------------------------------------------------------------------------

export interface PatchEnrollmentPathParams {
    id: number;
}

export interface EnrollmentExpirationPatchParams {
    expirationDate?: Date;
}

// #endregion Patch

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EnrollmentService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EnrollmentRecord,
        GetEnrollmentPathParams,
        GetEnrollmentQueryParams
    >(resourceType, resourceEndpoint),

    useGetCSVPreview: ServiceHookFactory.useNestedList<
        EnrollmentUserRecord,
        GetCSVPreviewPathParams,
        GetCSVPreviewQueryParams
    >(EnrollmentUserRecord, csvPreviewEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EnrollmentRecord, ListEnrollmentsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateEnrollmentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateEnrollmentService = (
            params: CreateEnrollmentDto
        ): Promise<ServiceResponse<EnrollmentRecord>> =>
            axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));

        function create(params: CreateEnrollmentDto): Promise<ServiceResponse<EnrollmentRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<EnrollmentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useBulkCreate: (): { create: CreateBulkEnrollmentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateBulkEnrollmentService = (
            encodingType: EncodingType,
            params: CreateBulkEnrollmentDto
        ): Promise<ServiceResponse<BulkEnrollmentSummaryRecord>> => {
            const endPointWithEncodingTypeReplaced = bulkEnrollmentEndpoint.replace(
                ":encodingType",
                encodingType.toString()
            );

            return axios
                .post(endPointWithEncodingTypeReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(BulkEnrollmentSummaryRecord, r));
        };

        function create(
            encodingType: EncodingType,
            params: CreateBulkEnrollmentDto
        ): Promise<ServiceResponse<BulkEnrollmentSummaryRecord>> {
            return cancellablePromise(serviceCreate(encodingType, params)) as Promise<
                ServiceResponse<BulkEnrollmentSummaryRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Update an Enrollment's WithdrawnById and WithdrawnOn properties.
     */
    useWithdrawEnrollment: ServiceHookFactory.usePatch<
        EnrollmentRecord,
        WithdrawEnrollmentPathParams
    >(EnrollmentRecord, withdrawEnrollmentEndpoint),

    /**
     * Update an Enrollment's ExpirationDate.
     */
    usePatchExpiration: ServiceHookFactory.usePatch<
        EnrollmentRecord,
        PatchEnrollmentPathParams,
        EnrollmentExpirationPatchParams
    >(EnrollmentRecord, patchExpirationEndpoint),

    /**
     * Update an Enrollment's ExpirationDate.
     */
    usePatchClaimLinkTrial: ServiceHookFactory.usePatch<
        ClaimLinkTrialRecord,
        PatchEnrollmentPathParams
    >(ClaimLinkTrialRecord, patchClaimLinkTrialEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useCount: ServiceHookFactory.useGet<Number, {}, CountEnrollmentsQueryParams>(
        Number,
        countResourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentService };

// #endregion Exports
