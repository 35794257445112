import React, { useState } from "react";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { InputTypes } from "components/form/enumerations/input-types";
import { t } from "utilities/localization/t";
import "./event-schedule-exception-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleExceptionFormProps {
    eventScheduleException: EventScheduleExceptionRecord;
    onEventScheduleExceptionChanged: (eventScheduleException: EventScheduleExceptionRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-exception-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleExceptionForm: React.FC<EventScheduleExceptionFormProps> = ({
    eventScheduleException,
    onEventScheduleExceptionChanged,
}) => {
    const handleSetExceptionReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onEventScheduleExceptionChanged(eventScheduleException.with({ reason: e.target.value }));
    };

    const handleSetExceptionGranted = (checked: boolean) => {
        onEventScheduleExceptionChanged(eventScheduleException.with({ granted: checked }));
    };

    return (
        <React.Fragment>
            <div className={CSS_CLASS_NAME}>
                <p className={`${CSS_CLASS_NAME}__exception-description`}>
                    {t(
                        "exceptionShouldOnlyBeGrantedBasedOnAValidExceptionReasonORAfterSpeakingWithTheRequestorToFullyUnderstandTheNeedForExtraHours"
                    )}
                </p>

                <FormCheckboxInput
                    checked={eventScheduleException.granted}
                    formFieldName="exceptionGrantedCheckbox"
                    id="exceptionGrantedCheckbox"
                    label={t("IApproveAnException")}
                    onChange={handleSetExceptionGranted}
                    type={InputTypes.Checkbox}
                    value={eventScheduleException.granted}
                />

                <FormTextArea
                    id="exceptionReason"
                    formFieldName="exceptionReason"
                    label={t("ReasonForException")}
                    required={true}
                    value={eventScheduleException.reason}
                    onChange={handleSetExceptionReason}
                    placeholder={t("ReasonForException")}
                    rows={5}
                />
            </div>
        </React.Fragment>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionForm };

// #endregion Exports
