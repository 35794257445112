import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize, Button } from "components/buttons/button/button";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductImage } from "components/product-image/product-image";
import { ProductInfo } from "components/products/product-info/product-info";
import { ProductRecord } from "models/view-models/products/product-record";
import { SelectableProduct } from "models/interfaces/products/selectable-product";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { useState } from "react";
import { t } from "utilities/localization/t";
import "./event-product-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductCardProps {
    handleAdd: (product: ProductRecord) => void;
    handleRemove: (product: ProductRecord) => void;
    selectableProduct: SelectableProduct;
    selectPermitted?: boolean;
    disabled?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-product-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductCard: React.FC<EventProductCardProps> = ({
    handleAdd,
    handleRemove,
    selectableProduct,
    selectPermitted,
    disabled,
}) => {
    const [showModal, setShowModal] = useState(false);
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: selectableProduct.isSelected ? t("replace") : t("select"),
            onClick: () => {
                selectableProduct.isSelected
                    ? handleRemove(selectableProduct.product)
                    : handleAdd(selectableProduct.product);
                setShowModal(false);
            },
            disabled: !selectableProduct.isSelected && !selectPermitted,
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__detail`}>
                    <p className={`${CSS_CLASS_NAME}__name`} title={selectableProduct.product.name}>
                        {selectableProduct.product.name}
                        <br />
                        <span className={`${CSS_CLASS_NAME}__topic`}>
                            {selectableProduct.product.topic == null
                                ? null
                                : t(TopicDisplayNames[selectableProduct.product.topic])}
                        </span>
                    </p>

                    <div className={`${CSS_CLASS_NAME}__select-action`}>
                        {!selectableProduct.isSelected && (
                            <ButtonIcon
                                disabled={!selectPermitted || disabled}
                                buttonSize={ButtonSize.Small}
                                buttonStyle={ButtonStyle.Secondary}
                                iconType={Icons.Plus}
                                onClick={() => handleAdd(selectableProduct.product)}
                                text={t("select")}
                            />
                        )}
                        {selectableProduct.isSelected && (
                            <Button
                                disabled={disabled}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                onClick={() => handleRemove(selectableProduct.product)}
                                text={t("remove")}
                            />
                        )}
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__image-container`}>
                    <ProductImage
                        ariaLabelledBy={selectableProduct.product.getTopicDisplay()}
                        productImageFileId={selectableProduct.product.productImageFileId}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__info-button`}>
                    <ButtonIcon
                        ariaLabelledBy={t("productInformation")}
                        disabled={disabled}
                        buttonSize={ButtonSize.Small}
                        buttonStyle={ButtonStyle.Tertiary}
                        iconType={Icons.Information}
                        onClick={() => setShowModal(true)}
                        text=""
                    />
                </div>
            </div>
            {/* ProductInfoModal */}
            <Modal
                actions={modalActionArray}
                isOpen={showModal}
                modalStyle={""}
                onModalClose={() => setShowModal(false)}
                title={t("productInfo")}>
                <ProductInfo product={selectableProduct.product} />
            </Modal>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductCard };

// #endregion Exports
