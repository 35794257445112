import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DateUtils } from "utilities/date-utils";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventSessionCard } from "../event-session-card/event-session-card";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { t } from "utilities/localization/t";
import "./event-day-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayCardProps {
    allDaySessions: boolean;
    eventDay: EventDayRecord;
    handleEditEventDay: (eventDay: EventDayRecord) => void;
    handleDeleteEventDay: (eventDay: EventDayRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-day-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayCard: React.FC<EventDayCardProps> = (props: EventDayCardProps): JSX.Element => {
    const eventDay = props.eventDay;
    const [eventDayToDelete, setEventDayToDelete] = useState<EventDayRecord>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => {
                setEventDayToDelete(undefined);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                if (eventDayToDelete === undefined) {
                    return;
                }
                props.handleDeleteEventDay(eventDayToDelete!);
                setEventDayToDelete(undefined);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const confirmDeleteEventDay = (eventDay: EventDayRecord): void => {
        setEventDayToDelete(eventDay);
        setShowConfirmationModal(true);
    };

    const handleEditEventDay = (ed: EventDayRecord) => {
        props.handleEditEventDay(ed);
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <p className={`${CSS_CLASS_NAME}__date`}>
                {DateUtils.formatDateCustom(eventDay.eventDate().toString(), t("shortDateFormat"))}
            </p>

            <div className={`${CSS_CLASS_NAME}__event-day-options-menu`}>
                <ContextMenu>
                    <ContextMenuButton
                        onClick={() => {
                            handleEditEventDay(eventDay);
                        }}
                        displayName={t("edit")}
                        iconType={Icons.Edit}
                    />
                    <ContextMenuButton
                        onClick={() => {
                            confirmDeleteEventDay(eventDay);
                        }}
                        displayName={t("delete")}
                        iconType={Icons.Trashcan}
                    />
                </ContextMenu>
            </div>

            <p className={`${CSS_CLASS_NAME}__name`}>{eventDay.name}</p>

            {!props.allDaySessions && (
                <>
                    {/* Map Sessions. */}
                    {eventDay.eventSessions !== undefined &&
                        eventDay.eventSessions.map((eventSession, sessionIndex) => {
                            return (
                                <EventSessionCard
                                    key={eventSession.getUniqueIdentifier()}
                                    sessionNumber={sessionIndex + 1}
                                    eventSession={eventSession}
                                />
                            );
                        })}

                    <p className={`${CSS_CLASS_NAME}__credit-hours`}>
                        {eventDay.getCreditHoursDescription()}
                    </p>
                </>
            )}
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWantToRemoveEventDayToDeleteGetEventDateTextFromYourSchedule", {
                    eventDayToDeleteGetEventDateText: eventDayToDelete?.getEventDateText(),
                })}
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayCard };

// #endregion Exports
