import React from "react";
import { Card } from "components/card/card";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { InstructorApplicationEnrollmentRecord } from "models/view-models/enrollments/instructor-application-enrollment-record";
import {
    InstructorAssessmentStatusDisplayNames,
    InstructorAssessmentStatusStatusMap,
} from "models/enumerations/enrollments/instructor-assessment-status";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./instructor-assessment-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorAssessmentCardProps {
    instructorApplicationEnrollment: InstructorApplicationEnrollmentRecord;
    sortOrder: number;
    withdrawEnrollment: (e: InstructorApplicationEnrollmentRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "instructor-assessment-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorAssessmentCard: React.FC<InstructorAssessmentCardProps> = ({
    instructorApplicationEnrollment,
    sortOrder,
    withdrawEnrollment,
}): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__enrollments`}>
            <Card key={instructorApplicationEnrollment.id} cssClassName="enrollment">
                <div className="enrollment__name">
                    <Paragraph>
                        {sortOrder}.&nbsp;
                        {instructorApplicationEnrollment.enrollment?.product?.name}
                    </Paragraph>
                </div>
                <div className="enrollment__details">
                    <EnumStatusBadge
                        displayNames={InstructorAssessmentStatusDisplayNames}
                        statusMap={InstructorAssessmentStatusStatusMap}
                        value={instructorApplicationEnrollment.status}
                    />
                    <div className="action">
                        <ContextMenu>
                            <ContextMenuButton
                                displayName={t("withdraw")}
                                onClick={() => withdrawEnrollment(instructorApplicationEnrollment)}
                            />
                        </ContextMenu>
                    </div>
                </div>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorAssessmentCard };

// #endregion Exports
