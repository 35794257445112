import React, { useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AddBulkEnrollmentModal } from "components/enrollments/add-bulk-enrollment-modal/add-bulk-enrollment-modal";
import { AddEnrollmentModal } from "components/enrollments/add-enrollment-modal/add-enrollment-modal";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { HeaderBanner, HeaderBannerBadge } from "components/header-banner/header-banner";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useParams } from "react-router-dom";
import { useProduct } from "utilities/hooks/models/products/use-product";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { BadgeStyle } from "components/badges/badge/badge";
import { ProductCourseTypeDisplayNames } from "models/enumerations/courses/product-course-type";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { useEnrollments } from "utilities/hooks/models/enrollments/use-enrollments";
import { EnrollmentList } from "components/enrollments/enrollment-list/enrollment-list";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import "./admin-product-enrollment-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminProductEnrollmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-product-enrollment-page";
const ITEMS_PER_PAGE = 20;
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminProductEnrollmentPage: React.FC<AdminProductEnrollmentPageProps> = validatePageAccess(
    AccessControlKeys.AdminProductEnrollmentPage
)((): JSX.Element => {
    const { id: productIdParam } = useParams();
    const productId = useMemo(() => NumberUtils.parseInt(productIdParam) ?? 0, [productIdParam]);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showAddEnrollmentModal, setShowAddEnrollmentModal] = useState(false);
    const [showBulkEnrollmentModal, setShowBulkEnrollmentModal] = useState(false);
    const { record: globalState } = useGlobalState();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const canModifyProducts = useHasAccess(AccessControlKeys.CanModifyProducts);

    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            userRole === RoleType.NfpaSupport,
        [userRole]
    );

    const { product } = useProduct({ productId });
    const {
        enrollments,
        rowCount: totalNumberOfEnrollments,
        createEnrollment,
        createBulkEnrollments,
        withdrawEnrollment,
    } = useEnrollments({
        productId: productId ?? -1,
        userSearchText: debouncedSearchText,
        includeUser: true,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const badges: HeaderBannerBadge[] = useMemo(
        () =>
            product == null
                ? []
                : [
                      {
                          text:
                              product.type == null ? "" : t(TrainingTypeDisplayNames[product.type]),
                          style: BadgeStyle.Transparent,
                      },
                      {
                          text:
                              product.onlineLearningType == null
                                  ? ""
                                  : t(ProductCourseTypeDisplayNames[product.onlineLearningType!]),
                          style: BadgeStyle.Transparent,
                      },
                  ],
        [product]
    );

    return (
        <>
            <HeaderBanner title={product?.name ?? ""} badges={badges}>
                {canModifyProducts && (
                    <Button
                        linkPath={RouteUtils.replacePathParams(
                            sitemap.admin.product.edit.online.details,
                            {
                                id: product?.id,
                            }
                        )}
                        size={ButtonSize.Medium}
                        style={ButtonStyle.Secondary}
                        text={t("editProduct")}
                        type={ButtonType.Link}
                    />
                )}
            </HeaderBanner>
            <SkipNavContent>
                <div className={`${CSS_CLASS_NAME}`}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <div className={`${CSS_CLASS_NAME}__header__title`}>
                            <Heading size={HeadingSize.Small}>{t("enrollment")}</Heading>
                            <Icon type={Icons.Enrollment} size={IconSizes.Large} />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__header__actions`}>
                            <SearchTextInput
                                debounce={DEBOUNCE_TIME}
                                onSearchTextInputChange={handleSearchTextChange}
                                onSearchTriggered={handleSearchTriggered}
                                id={"enrollmentSearch"}
                                placeholder={t("typeToSearchByName")}
                                searchTextInputValue={searchText}
                            />
                            {
                                // if
                                canModifyEnrollments && (
                                    <>
                                        <ButtonIcon
                                            iconType={Icons.Plus}
                                            onClick={() => setShowAddEnrollmentModal(true)}
                                            text={t("addNew")}
                                        />
                                        <ButtonIcon
                                            iconType={Icons.Upload}
                                            onClick={() => setShowBulkEnrollmentModal(true)}
                                            text={t("importCSV")}
                                        />
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <Card cssClassName={`${CSS_CLASS_NAME}__table`}>
                        <AddEnrollmentModal
                            handleSaveEnrollment={createEnrollment}
                            open={showAddEnrollmentModal}
                            setOpen={setShowAddEnrollmentModal}
                        />
                        <AddBulkEnrollmentModal
                            handleSaveBulkEnrollment={createBulkEnrollments}
                            open={showBulkEnrollmentModal}
                            setOpen={setShowBulkEnrollmentModal}
                        />
                        <EnrollmentList
                            enrollments={enrollments}
                            handleWithdrawEnrollment={withdrawEnrollment}
                        />
                        <Pager
                            currentPage={currentPage}
                            totalPages={totalNumberOfEnrollments / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={totalNumberOfEnrollments}
                        />
                    </Card>
                </div>
            </SkipNavContent>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminProductEnrollmentPage };

// #endregion Exports
