import React, { useState } from "react";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import {
    FormRadioCardList,
    RadioCard,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { FormSearchSelect } from "components/form/form-search-select/form-search-select";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Icons } from "components/icons/constants/icons";
import { InputTypes } from "components/form/enumerations/input-types";
import {
    ProductCourseType,
    ProductCourseTypeDisplayNames,
} from "models/enumerations/courses/product-course-type";
import { ProductRecord } from "models/view-models/products/product-record";
import { SelectOption } from "components/form/inputs/select/select";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./product-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductFormProps {
    associatedProducts: ProductRecord[];
    currentProduct?: ProductRecord;
    product: ProductRecord;
    setProduct: React.Dispatch<React.SetStateAction<ProductRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductForm: React.FC<ProductFormProps> = ({
    associatedProducts,
    currentProduct,
    product,
    setProduct,
}) => {
    const { useEducationNetwork } = useFeatureFlags();

    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        text: product.associatedProduct?.name ?? "",
        value: product.associatedProductId?.toString() ?? "",
    });

    const associatedProductsSelectOptions: SelectOption[] = associatedProducts.map(
        (ap): SelectOption => ({
            text: ap.name,
            value: ap.id!.toString(),
        })
    );

    const cards: RadioCard[] = [
        {
            icon: Icons.Analytics,
            text: "learner",
            value: AudienceType.Learner.toString(),
        },
    ];

    if (useEducationNetwork) {
        cards.push({
            icon: Icons.Analytics,
            text: "educationNetwork",
            value: AudienceType.EducationNetwork.toString(),
        });
    }

    const handleChange = (product: ProductRecord) => {
        setProduct(product);
    };

    const updateProduct = (values: Partial<ProductRecord>) => {
        handleChange(product.with(values));
    };

    const handleProductNameUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProduct({ name: event.target.value });
    };

    const handleAudienceTypeChange = (value: string) => {
        const newAudience = Number(value) as AudienceType;
        if (newAudience === product.audienceType) {
            return;
        }

        const trainingType = getDefaultTrainingTypeForAudience(newAudience);
        const productCourseType = getDefaultProductCourseTypeForTrainingType(trainingType);

        // Reset the Associated Product Selection.
        setSelectedOption({ text: "", value: "" });

        updateProduct({
            ...EnumUtils.enumToObjectPartial(
                value,
                (value) => ({ audienceType: Number(value) }),
                AudienceType
            ),
            type: undefined,
            onlineLearningType: productCourseType,
            associatedProductId: undefined,
        });
    };

    const handleProductTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newTrainingType = Number(event.target.value) as TrainingType;
        if (newTrainingType === product.type) {
            return;
        }

        // Reset the Associated Product Selection.
        setSelectedOption({ text: "", value: "" });

        updateProduct({
            ...EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ type: Number(value) }),
                TrainingType
            ),
            onlineLearningType: undefined,
        });
    };

    const handleSyllabusTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProduct(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ onlineLearningType: Number(value) }),
                ProductCourseType
            )
        );
    };

    const handleAssociatedProductChange = (value: string): void => {
        const option: SelectOption = associatedProductsSelectOptions.find(
            (so: SelectOption): boolean => so.value === value
        ) ?? {
            text: "",
            value: "",
        };

        setSelectedOption(option);
        updateProduct({ associatedProductId: Number(option.value) });
    };

    const filterProductTypeSelectOptions = () => {
        if (product.audienceType === AudienceType.Learner) {
            return EnumUtils.numericEnumToSelectOptions(
                TrainingType,
                TrainingTypeDisplayNames
            ).slice(0, 2);
        } else {
            return EnumUtils.numericEnumToSelectOptions(
                TrainingType,
                TrainingTypeDisplayNames
            ).slice(2);
        }
    };

    const getDefaultProductCourseTypeForTrainingType = (
        trainingType?: TrainingType
    ): ProductCourseType | undefined => {
        switch (trainingType) {
            case TrainingType.TrainTheTrainer:
                return ProductCourseType.CourseSeries;
            default:
                return undefined;
        }
    };

    const getDefaultTrainingTypeForAudience = (
        audience: AudienceType
    ): TrainingType | undefined => {
        switch (audience) {
            case AudienceType.EducationNetwork:
                return TrainingType.TrainTheTrainer;
            default:
                return undefined;
        }
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <FormTextInput
                ariaLabelledBy={t("productName")}
                autoFocus={true}
                formFieldName="productName"
                id="productName"
                label={t("productName")}
                maxLength={200}
                onChange={handleProductNameUpdate}
                placeholder={t("enterProductName")}
                required={true}
                type={InputTypes.Text}
                value={product.name}
            />
            <FormRadioCardList
                id="productAudience"
                disabled={!!currentProduct}
                formFieldName="productAudience"
                label={t("productAudience")}
                onChange={handleAudienceTypeChange}
                radioCards={cards}
                required={true}
                value={product.audienceType?.toString()}
            />
            <FormSelect
                ariaLabelledBy={t("productTypeSelection")}
                disabled={!!currentProduct}
                formFieldName={t("productTypeSelection")}
                id="productTypeSelect"
                label={t("productType")}
                onChange={handleProductTypeChange}
                options={filterProductTypeSelectOptions()}
                required={true}
                value={product.type?.toString()}
            />
            {product.type === TrainingType.OnlineLearning && (
                <FormSelect
                    ariaLabelledBy="Syllabus Type Selection"
                    disabled={!!currentProduct}
                    formFieldName="Syllabus Type Selection"
                    id="syllabusTypeSelect"
                    label="Syllabus Type"
                    onChange={handleSyllabusTypeChange}
                    options={EnumUtils.numericEnumToSelectOptions(
                        ProductCourseType,
                        ProductCourseTypeDisplayNames
                    )}
                    required={true}
                    value={product.onlineLearningType?.toString()}
                />
            )}
            {(product.type === TrainingType.TrainTheTrainer ||
                product.type === TrainingType.InstructorAssessment) && (
                <>
                    <FormSearchSelect
                        ariaLabelledBy={t("associatedProductSelection")}
                        defaultText={t("selectAnAssociatedProduct")}
                        disabled={!!currentProduct}
                        formFieldName={t("associatedProduct")}
                        id="associated product select"
                        label={t("associatedProduct")}
                        onChange={handleAssociatedProductChange}
                        options={associatedProductsSelectOptions}
                        required={true}
                        selectedOption={selectedOption}
                    />
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductForm };

// #endregion Exports
