import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { ProductRecord } from "models/view-models/products/product-record";
import { Record } from "immutable";
import { Unit } from "models/interfaces/units/unit";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Unit = {
    completeCoursesInOrder: false,
    completeUnitBeforeMovingForward: false,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    description: undefined,
    id: undefined,
    name: undefined,
    optional: false,
    sortOrder: 1,
    productId: 0,
    productVersionId: 0,
    unitCourses: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class UnitRecord extends Record(defaultValues) implements Unit {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Unit>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        if (CollectionUtils.hasValues(params.unitCourses)) {
            params.unitCourses = RecordUtils.ensureRecords(params.unitCourses, UnitCourseRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public withUnitCourse(unitCourse: UnitCourseRecord): UnitRecord {
        const existingUnitCourseIndex =
            this.unitCourses?.findIndex((e) => e.id === unitCourse.id) ?? -1;

        if (this.unitCourses == null || existingUnitCourseIndex < 0) {
            return this.with({
                unitCourses: [...(this.unitCourses ?? []), unitCourse],
            });
        }

        return this.with({
            unitCourses: CollectionUtils.replaceElementAt(
                this.unitCourses,
                existingUnitCourseIndex,
                unitCourse
            ),
        });
    }

    public isLockedForEnrollment(): boolean {
        const unitCourses = this.unitCourses ?? [];

        return unitCourses.every((uc) => {
            const { locked = true } = uc.enrollmentUnitCourse ?? {};
            return locked;
        });
    }

    public sortUnitCourses(): void {
        if (!CollectionUtils.hasValues(this.unitCourses)) {
            return;
        }

        this.unitCourses.sort((a, b) => a.sortOrder! - b.sortOrder!);
    }

    /**
     * Given a set of values for Unit properties, create a new UnitRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Unit>} values The values to overwrite on this instance.
     * @returns A UnitRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Unit>): UnitRecord {
        return new UnitRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitRecord };

// #endregion Exports
