import AlertLevels from "models/enumerations/alert-levels/alert-levels";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ReactNode } from "react";
import "./alert-notice.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AlertNoticeProps {
    buttonText?: string | null;
    children?: ReactNode;
    description?: string | undefined;
    icon?: Icons;
    level: AlertLevels;
    onClick?: () => void;
    title: string | undefined;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_BASE_CLASS = "alert-notice";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AlertNotice: React.FunctionComponent<AlertNoticeProps> = (props: AlertNoticeProps) => {
    const { level, title, description, onClick, buttonText = null, children } = props;
    let modifier = "";
    let icon = Icons.Information;

    switch (level) {
        case AlertLevels.Warning:
            icon = props.icon ?? Icons.Information;
            modifier = "-warning";
            break;
        case AlertLevels.Error:
            icon = props.icon ?? Icons.Warning;
            modifier = "-error";
            break;
        default:
            icon = props.icon ?? Icons.Information;
            break;
    }

    return (
        <div className={CSS_BASE_CLASS}>
            <div className={`${CSS_BASE_CLASS} ${modifier}`}>
                <Icon type={icon} size={IconSizes.XLarge} />
                <Heading priority={HeadingPriority.H1} size={HeadingSize.XLarge}>
                    {title}
                </Heading>
                <Paragraph size={ParagraphSize.XLarge}>{description}</Paragraph>
                {children}
                {buttonText && (
                    <Button style={ButtonStyle.TertiaryAlt} onClick={onClick}>
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default AlertNotice;

// #endregion Exports
