import { useMemo } from "react";
import { Process } from "utilities/types/processes/process";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type UseProcessHook<TProcess, TDependencies extends any[]> = (
    ...dependencies: TDependencies
) => Process<TProcess>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const build = <TProcess, TDependencies extends any[]>(
    buildProcess: (...dependencies: TDependencies) => Process<TProcess>
): UseProcessHook<TProcess, TDependencies> => {
    return (...dependencies: TDependencies): Process<TProcess> => {
        const process = useMemo(() => buildProcess(...dependencies), [dependencies]);

        return process;
    };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const UseProcessFactory = {
    build: build,
};

export { UseProcessFactory };

// #endregion Exports
