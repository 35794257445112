import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

enum ReportType {
    Learner = 1,
    Organization = 2,
    InstructorLedTrainingEvent = 3,
    OnlineLearningTraining = 4,
    Group = 5,
}

const ReportTypeDisplayNames: Map<ReportType, TranslatedCopy> = {
    [ReportType.Learner]: "learner",
    [ReportType.Organization]: "organization",
    [ReportType.InstructorLedTrainingEvent]: "instructorLedTrainingEvent",
    [ReportType.OnlineLearningTraining]: "onlineLearningTraining",
    [ReportType.Group]: "group",
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportType, ReportTypeDisplayNames };

// #endregion Exports
