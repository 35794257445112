import { CatalogEnrollmentErrorType } from "models/enumerations/enrollments/catalog-enrollment-log";
import { CatalogEnrollmentLogRecord } from "models/view-models/enrollments/catalog-enrollment-log-record";
import { CatalogEnrollmentStatus } from "models/enumerations/enrollments/catalog-enrollment-status";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "catalogEnrollmentLogs";
const resourceType: typeof CatalogEnrollmentLogRecord = CatalogEnrollmentLogRecord;
const resourceEndpoint: string = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListCatalogEnrollmentLogsQueryParams {
    errorType?: CatalogEnrollmentErrorType;
    skip?: number;
    status?: CatalogEnrollmentStatus;
    take?: number;
}

export interface UpdateCatalogEnrollmentLogPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CatalogEnrollmentLogService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        CatalogEnrollmentLogRecord,
        ListCatalogEnrollmentLogsQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Update a Catalog Enrollment Log
     */
    useUpdate: ServiceHookFactory.useUpdate<
        CatalogEnrollmentLogRecord,
        UpdateCatalogEnrollmentLogPathParams
    >(resourceType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CatalogEnrollmentLogService };

// #endregion Exports
