import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ToastManager } from "utilities/toast/toast-manager";
import { GroupRecord } from "models/view-models/groups/group-record";
import {
    UpdateGroupPathParams,
    GroupResourcePathParams,
    GroupResourceQueryParams,
    GroupService,
} from "utilities/services/groups/group-service";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { GroupContext } from "utilities/contexts/use-group-context";
import { GroupDetailHeader } from "./group-detail-header/group-detail-header";
import {
    GroupDetailSidebar,
    GroupDetailSidebarNavItem,
} from "./group-detail-sidebar/group-detail-sidebar";
import { EditGroupModal } from "components/user-management/groups/edit-groups-modal/edit-group-modal";
import "./group-management-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupManagementLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "group-management-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupManagementLayout: React.FC<GroupManagementLayoutProps> = (): JSX.Element => {
    const { id: groupIdParam } = useParams();
    const groupId = useMemo(() => NumberUtils.parseInt(groupIdParam) ?? 0, [groupIdParam]);

    const { get: getGroupApi } = GroupService.useGet();

    const { update: updateGroupApi } = GroupService.useUpdate();

    const [showEditGroupModal, setShowEditGroupModal] = useState(false);
    const [group, setGroup] = useState<GroupRecord>(new GroupRecord());

    // =================================================================================================
    // #region API Calls
    // =================================================================================================

    const fetchData = useCallback(async () => {
        if (groupId <= 0) {
            return;
        }

        const pathParams: GroupResourcePathParams = {
            id: groupId,
        };

        const queryParams: GroupResourceQueryParams = {};

        try {
            const getResponse = await getGroupApi(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            setGroup(getResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheGroup"));
        }
    }, [getGroupApi, groupId]);

    const updateGroup = useCallback(
        async (groupToSave: GroupRecord): Promise<GroupRecord | null> => {
            const pathParams: UpdateGroupPathParams = {
                id: groupToSave.id!,
            };
            try {
                const updateGroupResponse = await updateGroupApi(groupToSave, pathParams);
                const updateGroupResult = updateGroupResponse?.result;

                if (updateGroupResult?.resultObject == null || updateGroupResult.hasErrors()) {
                    throw new Error();
                }
                return updateGroupResult.resultObject;
            } catch {
                ToastManager.error(t("thereWasAnIssueSavingTheGroup"));
                return null;
            }
        },
        [updateGroupApi]
    );

    const saveGroupEditForm = useCallback(
        async (groupToSave: GroupRecord): Promise<boolean> => {
            let updateSuccess = true;

            const groupId = groupToSave.id ?? 0;

            if (groupId <= 0) {
                return !updateSuccess;
            }

            const updatedGroup = await updateGroup(groupToSave);

            if (updatedGroup == null) {
                return false;
            }

            fetchData();

            return updateSuccess;
        },
        [fetchData, updateGroup]
    );

    // #endregion API Calls

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const navItems = useMemo(() => {
        const items: GroupDetailSidebarNavItem[] = [
            {
                path: sitemap.admin.userManagement.groups.info,
                text: "groupInformation",
            },
            {
                path: sitemap.admin.userManagement.groups.members,
                text: "associatedUsers",
            },
        ];

        return items;
    }, []);

    return (
        <GroupContext.Provider value={{ record: group, setRecord: setGroup }}>
            <div className={CSS_CLASS_NAME}>
                <GroupDetailHeader
                    group={group}
                    onEditGroupClick={() => setShowEditGroupModal(true)}
                />
                <SideContentLeftLayout sidebarElement={<GroupDetailSidebar navItems={navItems} />}>
                    <Outlet />
                </SideContentLeftLayout>
            </div>
            {group != null && (
                <EditGroupModal
                    open={showEditGroupModal}
                    setOpen={setShowEditGroupModal}
                    onEditFormSave={saveGroupEditForm}
                    groupToEdit={group}
                />
            )}
        </GroupContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupManagementLayout };

// #endregion Exports
