import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ScormPackageImportStatus {
    Complete = 0,
    Error = 1,
    Running = 2,
    Processing = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ScormPackageImportStatusDisplayNames: Map<ScormPackageImportStatus, TranslatedCopy> = {
    [ScormPackageImportStatus.Complete]: "complete",
    [ScormPackageImportStatus.Error]: "error",
    [ScormPackageImportStatus.Running]: "running",
    [ScormPackageImportStatus.Processing]: "processing",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageImportStatus, ScormPackageImportStatusDisplayNames };

// #endregion Exports
