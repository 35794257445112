import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Permission } from "utilities/enumerations/authorization/permission";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ClientAdminAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,

    [AccessControlKeys.AdminReportsDownloadsPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsListPage]: Permission.Allow,
    [AccessControlKeys.AdminReportsSetupPage]: Permission.Allow,
    [AccessControlKeys.ManageEventAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAssessmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceDayPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventAttendanceSummaryPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventContentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventDetailsPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEnrollmentPage]: Permission.Allow,
    [AccessControlKeys.ManageLiveVirtualEventEvaluationsPage]: Permission.Allow,
    [AccessControlKeys.ThirdPartyDashboardInstructorPage]: Permission.Allow,
    [AccessControlKeys.ThirdPartyDashboardOnlinePage]: Permission.Allow,
    [AccessControlKeys.ThirdPartyOnlineLearningDetailsPage]: Permission.Allow,
    [AccessControlKeys.ThirdPartyOnlineLearningEnrollmentPage]: Permission.Allow,

    // Actions
    [AccessControlKeys.CanDownloadCertificates]: Permission.Allow,
    [AccessControlKeys.CanDownloadEnrollmentReport]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ClientAdminAuthorizationRules };

// #endregion Exports
