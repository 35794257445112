import React from "react";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { InstructorApplicationStatusBar } from "../instructor-application-status-bar/instructor-application-status-bar";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DisplayApplicationStatusProps {
    isLoading: boolean;
    userInstructorApplication?: InstructorApplicationRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const DisplayApplicationStatus: React.FC<DisplayApplicationStatusProps> = ({
    isLoading,
    userInstructorApplication,
}): JSX.Element => {
    const displayApplicationStatus = (): JSX.Element => {
        switch (userInstructorApplication?.status) {
            case InstructorApplicationStatus.Declined:
                return (
                    <div>
                        <Paragraph>
                            {t(
                                "thankYouForTakingTheTimeToApplyToBeAnApprovedNFPAInstructorWeAppreciateYourInterestAndTheEffortYouPutIntoYourApplicationUnfortunatelyWeHaveFoundThatYourQualificationsDoNotMeetTheRequirementsAtThisTime"
                            )}
                        </Paragraph>
                        <br />
                        <Paragraph>
                            {t("forSpecificFeedbackPleaseReferToTheEmailThatWasSentToYou")}
                        </Paragraph>
                    </div>
                );
            case InstructorApplicationStatus.Completed || !userInstructorApplication:
                return (
                    <div>
                        {t("forMoreInformationOnBecomingAnNFPAInstructorPleaseVisit")}
                        <Anchor
                            path="https://www.nfpa.org/for-professionals/training-for-me/nfpa-authorized-education-network/aen-instructors"
                            pathType={AnchorPathType.External}
                            active={true}
                            text={t("aenInstructorInformationPage")}
                        />
                        .
                    </div>
                );
            case InstructorApplicationStatus.OnHold:
                return <Paragraph>{t("onHoldDescription")}</Paragraph>;
            default:
                return (
                    <InstructorApplicationStatusBar
                        isLoading={isLoading}
                        userInstructorApplication={userInstructorApplication}
                    />
                );
        }
    };

    return <>{displayApplicationStatus()}</>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { DisplayApplicationStatus };

// #endregion Exports
