import React from "react";
import { StringUtils } from "utilities/string-utils";
import { Anchor, AnchorPathType } from "../../typography/anchors/anchor/anchor";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./button.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum ButtonSize {
    Medium = "-medium",
    None = "",
    Small = "-small",
}

enum ButtonStyle {
    Destructive = "-destructive",
    Inverted = "-inverted",
    Link = "-link",
    LinkAlt = "-link-alt",
    None = "",
    Primary = "-primary",
    Quaternary = "-quaternary",
    Secondary = "-secondary",
    Success = "-success",
    Tertiary = "-tertiary",
    TertiaryAlt = "-tertiary-alt",
    Transparent = "-transparent",
}

enum ButtonType {
    Default = "button",
    Link = "link",
    Submit = "submit",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ButtonProps {
    ariaLabelledBy?: string;
    cssClassName?: string;
    disabled?: boolean;
    image?: any;
    linkPath?: string;
    linkPathType?: AnchorPathType;
    onClick?: () => void;
    size?: ButtonSize;
    style?: ButtonStyle;
    text?: string;
    type?: ButtonType;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "button";
const DEFAULT_BUTTON_SIZE: ButtonSize = ButtonSize.Medium;
const DEFAULT_BUTTON_STYLE: ButtonStyle = ButtonStyle.Primary;
const DISABLED_CLASS_NAME: string = "-disabled";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = (
    props: React.PropsWithChildren<ButtonProps>
) => {
    const { readOnly } = useReadOnly();
    const size: ButtonSize = props.size ?? DEFAULT_BUTTON_SIZE;
    const style: ButtonStyle = props.style ?? DEFAULT_BUTTON_STYLE;
    const cssClassNames: string[] = [CSS_CLASS_NAME, size, style];
    const type: ButtonType = props.type ?? ButtonType.Default;

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.disabled === true || readOnly) {
        cssClassNames.push(DISABLED_CLASS_NAME);
    }

    return type === ButtonType.Link ? (
        <Anchor
            disabled={props.disabled || readOnly}
            cssClassName={cssClassNames.join(" ")}
            path={props.linkPath ?? ""}
            pathType={props.linkPathType ?? AnchorPathType.Internal}>
            {props.children}
            {
                // if
                StringUtils.hasValue(props.text) && <span>{props.text}</span>
            }
        </Anchor>
    ) : (
        <button
            className={cssClassNames.join(" ")}
            disabled={props.disabled || readOnly}
            onClick={props.onClick}
            title={props.ariaLabelledBy}
            type={type}>
            {props.children}
            {
                // if
                StringUtils.hasValue(props.text) && <span>{props.text}</span>
            }
        </button>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Button, ButtonSize, ButtonStyle, ButtonType };

// #endregion Exports
