export interface ProviderSearchState {
    searchText: string;
    debouncedSearchText?: string;
    status?: string;
    currentPage: number;
}

export type ProviderSearchReducerActions =
    | { type: "initialize_search"; value: string }
    | { type: "update_search"; value: string }
    | { type: "update_debounced_search"; value?: string }
    | { type: "update_status"; value?: string }
    | { type: "update_currentPage"; value: number }
    | { type: "clear_search_criteria" };

export function providerSearchReducer(
    state: ProviderSearchState,
    action: ProviderSearchReducerActions
): ProviderSearchState {
    switch (action.type) {
        case "initialize_search":
            return {
                ...state,
                searchText: action.value,
                debouncedSearchText: undefined,
                currentPage: 1,
            };

        case "update_search":
            if (state.searchText === action.value) {
                return state;
            }

            return {
                ...state,
                searchText: action.value,
            };

        case "update_debounced_search":
            if (state.debouncedSearchText === action.value) {
                return state;
            }

            return {
                ...state,
                debouncedSearchText: action.value,
                currentPage: 1,
            };

        case "update_status":
            if (state.status === action.value) {
                return state;
            }

            return {
                ...state,
                status: action.value,
            };

        case "update_currentPage":
            return {
                ...state,
                currentPage: action.value,
            };

        case "clear_search_criteria":
            return {
                ...state,
                searchText: "",
                debouncedSearchText: undefined,
                status: undefined,
                currentPage: 1,
            };
    }
}
