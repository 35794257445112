import { User } from "models/interfaces/users/user";
import { UserRecord } from "models/view-models/users/user-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type UserRecordUpdater = RecordUpdater<User, UserRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: UserContext, useRecord: useUser } = UseRecordContextFactory.build<
    User,
    UserRecord
>(UserRecord);

export { UserContext, useUser };

// #endregion Exports
