import { SelectOption } from "components/form/inputs/select/select";
import { allCountries } from "country-region-data";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CountryConstants: SelectOption[] = [];

allCountries.forEach((country) => {
    CountryConstants.push({ text: country[0], value: country[0] });
});

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { CountryConstants };
// #endregion Exports
