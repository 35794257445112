import React, { useCallback, useState } from "react";
import { t } from "utilities/localization/t";
import { ProductRecord } from "models/view-models/products/product-record";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import {
    ListProductsQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import "./product-approval-selection.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductApprovalSelectionProps {
    onProductApprovalSelection: (products: ProductRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductApprovalSelection: React.FC<ProductApprovalSelectionProps> = ({
    onProductApprovalSelection,
}): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchProducts, setSearchProducts] = useState<ProductRecord[]>([]);

    const { list: listProducts } = ProductService.useList();
    const fetchProducts = useCallback(
        async (searchText?: string) => {
            try {
                const listProductsQueryParams: ListProductsQueryParams = {
                    applySearch: true,
                    searchText,
                    activeStatusFilter: [ActiveStatus.Active],
                    trainingType: TrainingType.InstructorLedTraining,
                };

                const listFilteredProductsResponse = await listProducts(listProductsQueryParams);
                const listFilteredProductsResults = listFilteredProductsResponse?.results;
                const products = listFilteredProductsResponse?.resultObjects;
                if (
                    products == null ||
                    listFilteredProductsResults == null ||
                    listFilteredProductsResults.hasErrors()
                ) {
                    throw new Error();
                }
                setSearchProducts(products);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingProducts"));
                setSearchProducts([]);
            }
        },
        [listProducts]
    );

    const handleProductSelectionChange = (selectedProducts: ProductRecord[]) => {
        onProductApprovalSelection(selectedProducts);
    };

    const handleAddProducts = () => {
        fetchProducts();
        setIsModalOpen(true);
    };

    return (
        <>
            <Button
                onClick={handleAddProducts}
                size={ButtonSize.Small}
                style={ButtonStyle.Primary}
                text={t("addProductApproval")}
            />
            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchProducts}
                onSelectionChanged={handleProductSelectionChange}
                searchValues={searchProducts}
                selectedValues={[]}
                title={t("addProductApproval")}
                itemName={t("productName")}
                doneButtonText={t("add")}
                searchPlaceholderText={t("typeToSearchByProductNameOrID")}
                valuesDescription={t("products")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: value.name,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductApprovalSelection };

// #endregion Exports
