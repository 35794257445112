import { ResultErrorRecord } from "andculturecode-javascript-core";
import { CourseRecord } from "models/view-models/courses/course-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { CourseService } from "utilities/services/courses/course-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseCourseHook {
    course?: CourseRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseCourseHookOptions {
    courseId?: number | string;
    includeContent?: boolean;
    includeLatestVersion?: boolean;
    includeScormPackage?: boolean;
}

export function useCourse(props: UseCourseHookOptions): UseCourseHook {
    const courseId = NumberUtils.parseInt(props.courseId) ?? 0;
    const includeContent = props.includeContent ?? false;
    const includeScormPackage = props.includeScormPackage ?? false;
    const includeLatestVersion = props.includeLatestVersion ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState<CourseRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { get: getCourse } = CourseService.useGet();

    useEffect(() => {
        if (isNaN(courseId) || courseId < 1) {
            return;
        }

        (async function fetchCourse() {
            try {
                const [courseResponse] = await Promise.all([
                    getCourse(
                        { id: courseId },
                        {
                            includeScormPackage: includeScormPackage,
                            includeLatestVersion: includeLatestVersion,
                        }
                    ),
                ]);

                setIsLoading(false);

                if (courseResponse.result?.hasErrors()) {
                    const { errors: courseErrors = [] } = courseResponse.result ?? {};
                    setErrors([...courseErrors]);
                    return;
                }

                setCourse(courseResponse.resultObject);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheCourseDetails"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [courseId, getCourse, includeContent, includeScormPackage, includeLatestVersion]);

    return {
        errors,
        isLoading,
        course,
    };
}
