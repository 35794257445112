import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments/:enrollmentId/enrollmentUnitCourses";
const resourceType: typeof EnrollmentUnitCourseRecord = EnrollmentUnitCourseRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEnrollmentUnitCoursePathParams {
    id: number;
    enrollmentId: number;
}
export interface ListEnrollmentUnitCoursesQueryParams {
    courseId?: number;
    scormPackageAssessmentId?: number;
}

export interface ListEnrollmentUnitCoursePathParams {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EnrollmentUnitCourseService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EnrollmentUnitCourseRecord,
        GetEnrollmentUnitCoursePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EnrollmentUnitCourseRecord,
        ListEnrollmentUnitCoursePathParams,
        ListEnrollmentUnitCoursesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentUnitCourseService };

// #endregion Exports
