// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum Icons {
    Achievements = "Achievements",
    AenManager = "AenManager",
    AllCompleted = "AllCompleted",
    Analytics = "Analytics",
    Announcements = "Announcements",
    Archive = "Archive",
    ArrowCircleLeft = "ArrowCircleLeft",
    ArrowCircleRight = "ArrowCircleRight",
    ArrowDownThick = "ArrowDownThick",
    ArrowDownward = "ArrowDownward",
    ArrowUpward = "ArrowUpward",
    Assessment = "Assessment",
    Assignment = "Assignment",
    Assistant = "Assistant",
    Attendance = "Attendance",
    Badge = "Badge",
    Book = "Book",
    // Bookmark = "Bookmark",
    BookmarkOutline = "BookmarkOutline",
    Calendar = "Calendar",
    CalendarMonth = "CalendarMonth",
    CalendarSingle = "CalendarSingle",
    CalendarMulti = "CalendarMulti",
    CalendarBusy = "CalendarBusy",
    Catalog = "Catalog",
    ChalkboardTeacher = "ChalkboardTeacher",
    Cancel = "Cancel",
    Chart = "Chart",
    Checklist = "Checklist",
    Checkmark = "Checkmark",
    CheckmarkClipboard = "CheckmarkClipboard",
    CheckmarkOvalFilled = "CheckmarkOvalFilled",
    CheckmarkOvalOutline = "CheckmarkOvalOutline",
    ChevronDown = "ChevronDown",
    ChevronDownWhite = "ChevronDownWhite",
    ChevronFirst = "ChevronFirst",
    ChevronLast = "ChevronLast",
    ChevronLeft = "ChevronLeft",
    ChevronRight = "ChevronRight",
    ChevronUp = "ChevronUp",
    CircleChecked = "CircleChecked",
    CircleUnchecked = "CircleUnchecked",
    Clock = "Clock",
    ClockWithArrow = "ClockWithArrow",
    Close = "Close",
    Collapse = "Collapse",
    Community = "Community",
    Content = "Content",
    Courses = "Courses",
    Certificate = "Certificate",
    DefaultAvatar = "DefaultAvatar",
    Delta = "Delta",
    Desktop = "Desktop",
    DesktopBusy = "DesktopBusy",
    DesktopWithBullets = "DesktopWithBullets",
    Document = "Document",
    Dot = "Dot",
    Download = "Download",
    DownloadComplete = "DownloadComplete",
    DownloadRemove = "DownloadRemove",
    DownloadSync = "DownloadSync",
    DragAndDrop = "DragAndDrop",
    Edit = "Edit",
    EditOpen = "EditOpen",
    EditCalendar = "EditCalendar",
    Email = "Email",
    Empty = "Empty",
    Enlarge = "Enlarge",
    Enrollment = "Enrollment",
    Enterprise = "Enterprise",
    Evaluation = "Evaluation",
    EvaluationIncomplete = "EvaluationIncomplete",
    EventDetails = "EventDetails",
    Events = "Events",
    EventType = "EventType",
    Expand = "Expand",
    Eye = "Eye",
    FactCheck = "FactCheck",
    FileCopy = "FileCopy",
    Folder = "Folder",
    History = "History",
    Home = "Home",
    Individual = "Individual",
    Information = "Information",
    InformationOutline = "InformationOutline",
    InPersonEvent = "InPersonEvent",
    Learning = "Learning",
    LibraryBooks = "LibraryBooks",
    License = "License",
    Lightbulb = "Lightbulb",
    List = "List",
    LiveVirtualEvent = "LiveVirtualEvent",
    Lock = "Lock",
    LockPerson = "LockPerson",
    Logo = "Logo",
    Logout = "Logout",
    MediaPlay = "MediaPlay",
    Menu = "Menu",
    Message = "Message",
    MetaPanel = "MetaPanel",
    Minus = "Minus",
    MoreHorizontal = "MoreHorizontal",
    MoreVertical = "MoreVertical",
    NewMaterial = "NewMaterial",
    Note = "Note",
    OnlineLearning = "OnlineLearning",
    OpenBook = "OpenBook",
    Organization = "Organization",
    PersonOutline = "PersonOutline",
    Phone = "Phone",
    Pin = "Pin",
    Placeholder = "Placeholder",
    Plus = "Plus",
    PlusWithCircle = "PlusWithCircle",
    PublishWithChanges = "PublishWithChanges",
    Preferences = "Preferences",
    Products = "Products",
    Question = "Question",
    ReferenceLink = "ReferenceLink",
    ReferencePanel = "ReferencePanel",
    Reporting = "Reporting",
    Reset = "Reset",
    Return = "Return",
    ScreenShare = "ScreenShare",
    StopScreenShare = "StopScreenShare",
    Scroll = "Scroll",
    Search = "Search",
    Settings = "Settings",
    Share = "Share",
    Sitnav = "Sitnav",
    SlideClose = "SlideClose",
    StarFilled = "StarFilled",
    StarOutline = "StarOutline",
    Stopwatch = "Stopwatch",
    Support = "Support",
    Teaching = "Teaching",
    Team = "Team",
    ToggleCard = "ToggleCard",
    ToggleTable = "ToggleTable",
    Trashcan = "Trashcan",
    Upload = "Upload",
    UserManagement = "UserManagement",
    Warning = "Warning",
    WifiOffline = "WifiOffline",
    ZoomIn = "ZoomIn",
    ZoomOut = "ZoomOut",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Icons };

// #endregion Exports
