import React from "react";
import { LearnerEnrollmentList } from "components/enrollments/learner-enrollments/learner-enrollment-list";
import "./learner-learning-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerLearningPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-learning-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerLearningPage: React.FC<LearnerLearningPageProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <LearnerEnrollmentList />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerLearningPage };

// #endregion Exports
