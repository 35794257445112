import React, { useMemo } from "react";
import { Accordion, AccordionStyle } from "../../accordion/accordion";
import { CourseRow } from "../course-row/course-row";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import "./unit-accordion.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

// TODO: Add properties to load the appropriate data for the accordion
export interface UnitAccordionProps {
    enrollmentUnitCourses: EnrollmentUnitCourseRecord[];
    number: number;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "unit-accordion";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UnitAccordion: React.FC<UnitAccordionProps> = ({ enrollmentUnitCourses, number, title }) => {
    const courseLabel = useMemo(() => t("courses").toLocaleUpperCase(), []);

    return (
        <Accordion
            cssClassName={CSS_CLASS_NAME}
            number={number}
            title={title}
            subTitle={courseLabel}
            style={AccordionStyle.Card}>
            {enrollmentUnitCourses.map((euc) => (
                <CourseRow
                    key={euc.id}
                    name={euc.unitCourse?.course?.name ?? ""}
                    status={euc.status}
                    required={!euc.optional}
                    score={euc.currentScore}
                />
            ))}
        </Accordion>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitAccordion };

// #endregion Exports
