import * as React from "react";

const useIsOverflow = (
    ref: any,
    isVerticalOverflow = false,
    callback?: (overflow: boolean) => void
) => {
    const [isOverflow, setIsOverflow] = React.useState(false);

    React.useEffect(() => {
        const handleResize = () => {
            const { current } = ref;
            const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;

            if (current) {
                const hasOverflow = isVerticalOverflow
                    ? scrollHeight > clientHeight
                    : scrollWidth > clientWidth;

                setIsOverflow(hasOverflow);

                if (callback) {
                    callback(hasOverflow);
                }
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [callback, ref, isVerticalOverflow]);

    return isOverflow;
};

export default useIsOverflow;
