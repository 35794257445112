import { Record } from "immutable";
import { RecordUtils, StringUtils } from "andculturecode-javascript-core";
import { EnrollmentExpirationChangeLog } from "models/interfaces/enrollments/enrollment-expiration-change-log";
import { UserRecord } from "../users/user-record";
import { DateUtils } from "utilities/date-utils";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EnrollmentExpirationChangeLog =
    RecordUtils.auditableDefaultValuesFactory<EnrollmentExpirationChangeLog>({
        createdBy: undefined,
        enrollmentId: 0,
        originalExpirationDate: undefined,
        newExpirationDate: undefined,
    });

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EnrollmentExpirationChangeLogRecord
    extends Record(defaultValues)
    implements EnrollmentExpirationChangeLog
{
    // ---------------------------------------------------------------------------------------------
    // #region Constructor
    // ---------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY-MM-DDTHH:MM:SS:FFFFFFF+Offset
     * 2022-10-20T17:42:30.7005991+00:00

     */
    public getLastUpdated(): string {
        return StringUtils.hasValue(this.updatedOn) ? this.updatedOn : this.createdOn ?? "";
    }

    /**
     * Convenience method to the return the last updated time in milliseconds.
     * Ex: 1666287750700 for 2022-10-20T17:42:30.7005991+00:00
     */

    public getLastUpdatedInMilliseconds(): number {
        const lastUpdated = this.getLastUpdated();
        return DateUtils.convertToMilliseconds(lastUpdated);
    }
    constructor(params?: Partial<EnrollmentExpirationChangeLog>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EnrollmentExpirationChangeLog properties, create a new EnrollmentExpirationChangeLogRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EnrollmentExpirationChangeLog>} values The values to overwrite on this instance.
     * @returns A EnrollmentExpirationChangeLogRecord with values from this instance and the values parameter.
     */
    public with(
        values: Partial<EnrollmentExpirationChangeLog>
    ): EnrollmentExpirationChangeLogRecord {
        return new EnrollmentExpirationChangeLogRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentExpirationChangeLogRecord };

// #endregion Exports
