// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CultureCodes = {
    ARABIC: "ar",
    ENGLISH: "en",
    SPANISH: "es",
};

const DEFUALT_CULTURE_CODE: string = CultureCodes.ENGLISH;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CultureCodes, DEFUALT_CULTURE_CODE };

// #endregion Exports
