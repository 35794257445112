import React, { useMemo, useState, useEffect } from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { HeaderBanner, HeaderBannerNavItem } from "components/header-banner/header-banner";
import { HeaderBannerUtils } from "utilities/header-banner-utils";
import { Icons } from "components/icons/constants/icons";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { sitemap } from "../../../../../sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useOrganizations } from "utilities/hooks/models/events/use-organizations";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ScrollUtils } from "utilities/scroll-utils";
import "./user-contracts-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserContractsListPageProps {}
interface SideBarElementProps {
    handleSearchTextChange: (searchText: string) => void;
    handleSearchTriggered: (debouncedSearchText: string) => void;
    searchText: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-contracts-list-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserContractsListPage: React.FC<UserContractsListPageProps> = validatePageAccess(
    AccessControlKeys.UserContractsListPage
)((): JSX.Element => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [searchText, setSearchText] = useState<string>("");
    const navItems: HeaderBannerNavItem[] = HeaderBannerUtils.getUserManagementNavItems();

    const { record: globalState } = useGlobalState();

    const canModifyOrganization =
        globalState?.currentIdentity?.isCurrentlyInNfpaRole() &&
        globalState?.currentIdentity?.role?.roleType !== RoleType.NfpaSupport;

    const { organizations, rowCount: totalNumberOfOrganizations } = useOrganizations({
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const viewOrganization = (organizationId: number) => {
        return RouteUtils.replacePathParams(sitemap.admin.userManagement.contracts.info, {
            id: organizationId,
        });
    };

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.content`);
    }, [organizations]);

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner navItems={navItems} title={t("userManagement")}>
                {canModifyOrganization && (
                    <ButtonIcon
                        text={t("addNewContractOrganization")}
                        buttonSize={ButtonSize.Medium}
                        buttonStyle={ButtonStyle.TertiaryAlt}
                        buttonType={ButtonType.Link}
                        iconType={Icons.Plus}
                        linkPath={sitemap.admin.userManagement.contracts.new}
                    />
                )}
            </HeaderBanner>
            <SideContentLeftLayout
                sidebarElement={
                    <SidebarElement
                        searchText={searchText}
                        handleSearchTextChange={handleSearchTextChange}
                        handleSearchTriggered={handleSearchTriggered}
                    />
                }>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>
                            {organizations.length > 0 && (
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="name">{t("name")}</th>
                                            <th className="lms-id">{t("lmsID")}</th>
                                            <th className="action">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organizations.map((organization: OrganizationRecord) => (
                                            <tr key={`provider-row-${organization.id}`}>
                                                <td className="name">{organization.name}</td>
                                                <td className="lms-id">{organization.id}</td>
                                                <td className="action">
                                                    <ContextMenu>
                                                        <ContextMenuAnchor
                                                            hrefPath={viewOrganization(
                                                                organization.id ?? 0
                                                            )}
                                                            displayName={t("viewOrganization")}
                                                        />
                                                    </ContextMenu>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </DataTable>
                            )}
                            {(organizations == null || organizations.length === 0) && (
                                <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
                            )}
                        </SkipNavContent>
                    </div>
                </div>
                {organizations != null && organizations.length > 0 && (
                    <div className="footer">
                        <Pager
                            currentPage={currentPage}
                            onPageClick={onPageClick}
                            totalPages={totalNumberOfOrganizations / ITEMS_PER_PAGE}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={totalNumberOfOrganizations}></Pager>
                    </div>
                )}
            </SideContentLeftLayout>
        </div>
    );
});

const SidebarElement: React.FC<SideBarElementProps> = ({
    searchText,
    handleSearchTextChange,
    handleSearchTriggered,
}): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}__sidebar`}>
            <Paragraph style={ParagraphStyle.Label}>{t("filterResults")}</Paragraph>
            <div className={`${CSS_CLASS_NAME}__sidebar__search`}>
                <SearchTextInput
                    autofocus={true}
                    debounce={DEBOUNCE_TIME}
                    onSearchTextInputChange={handleSearchTextChange}
                    onSearchTriggered={handleSearchTriggered}
                    id={"eventSearch"}
                    title={t("typeToSearchByNameOrId")}
                    placeholder={t("typeToSearchByNameOrId")}
                    searchTextInputValue={searchText}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserContractsListPage };

// #endregion Exports
