import { ResultErrorRecord } from "andculturecode-javascript-core";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseRegistrationHook {
    registration?: RegistrationRecord;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    fetchRegistration: () => void;
}

interface UseRegistrationHookOptions {
    registrationId?: number;
    scormPackageId?: number | string;
    enrollmentId?: number | string;
    userId?: number | string;
    includeAttempts?: boolean;
    includeTraining?: boolean;
    includeUser?: boolean;
}

export function useRegistration(options: UseRegistrationHookOptions): UseRegistrationHook {
    const registrationId = options.registrationId;
    const enrollmentId = NumberUtils.parseInt(options.enrollmentId) ?? 0;
    const scormPackageId = NumberUtils.parseInt(options.scormPackageId) ?? 0;
    const includeAttempts = options.includeAttempts ?? false;
    const includeTraining = options.includeTraining ?? false;
    const includeUser = options.includeUser ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [registration, setRegistration] = useState<RegistrationRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listRegistrations } = RegistrationService.useList();
    const { get: getRegistration } = RegistrationService.useGet();

    const fetchById = useCallback(
        async (registrationId: number) => {
            const { result, resultObject } = await getRegistration(
                { id: registrationId },
                { includeTraining: includeTraining, includeUser: includeUser }
            );

            setIsLoading(false);

            if (result?.hasErrors()) {
                const { errors } = result ?? {};
                setErrors(errors ?? []);
                return;
            }

            setRegistration(resultObject);
        },
        [getRegistration]
    );

    const fetchByEnrollmentIdAndScormPackageId = useCallback(
        async (enrollmentId: number, scormPackageId: number) => {
            const { result, resultObjects } = await listRegistrations({
                enrollmentId: enrollmentId,
                userId: NumberUtils.parseInt(options.userId),
                includeAttempts: includeAttempts,
            });

            setIsLoading(false);

            if (result?.hasErrors()) {
                const { errors } = result ?? {};
                setErrors(errors ?? []);
                return;
            }

            const [resultObject] = resultObjects.filter(
                (reg) => reg.scormPackageId === scormPackageId
            );

            setRegistration(resultObject);
        },
        [includeAttempts, listRegistrations, options.userId]
    );

    const fetchRegistration = useCallback(() => {
        if (
            (registrationId == null || registrationId < 1) &&
            (isNaN(enrollmentId) || enrollmentId < 1 || isNaN(scormPackageId) || scormPackageId < 1)
        ) {
            return;
        }

        try {
            if (registrationId != null && registrationId > 0) {
                fetchById(registrationId);
            } else {
                fetchByEnrollmentIdAndScormPackageId(enrollmentId, scormPackageId);
            }
        } catch {
            ToastManager.error(t("thereWasAProblemGettingRegistration"));
        }
    }, [
        enrollmentId,
        fetchByEnrollmentIdAndScormPackageId,
        fetchById,
        registrationId,
        scormPackageId,
    ]);

    useEffect(() => {
        fetchRegistration();
    }, [fetchRegistration]);

    return {
        errors,
        isLoading,
        registration,
        fetchRegistration,
    };
}
