import React from "react";
import { Outlet } from "react-router-dom";
import { AdminLayoutComponent } from "layouts/admin/admin-layout/admin-layout";
import { ReportingContextProvider } from "utilities/contexts/providers/reporting-context-provider";

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminReportingLayout: React.FC = () => {
    return (
        <AdminLayoutComponent>
            <ReportingContextProvider>
                <Outlet />
            </ReportingContextProvider>
        </AdminLayoutComponent>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminReportingLayout };

// #endregion Exports
