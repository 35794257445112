import { GlobalStateRecord } from "models/view-models/global-state/global-state-record";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { StringUtils } from "utilities/string-utils";

const RedirectToPathContext = createContext<string>(sitemap.public.noAccess);

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useRedirectOnForbidden = (redirectToPath?: string): void => {
    const navigate = useNavigate();
    const redirectToPathFromContext = useContext(RedirectToPathContext);
    const { record: globalState, setRecord: setGlobalState } = useGlobalState();
    const redirectPath: string = useMemo(
        (): string =>
            StringUtils.hasValue(redirectToPath) ? redirectToPath : redirectToPathFromContext,
        [redirectToPath, redirectToPathFromContext]
    );

    useEffect((): void => {
        if (globalState.forbiddenResult == null) {
            return;
        }

        setGlobalState(
            (currentGlobalState: GlobalStateRecord): GlobalStateRecord =>
                currentGlobalState.clearForbiddenResult()
        );

        navigate(redirectPath);
    }, [globalState.forbiddenResult, navigate, redirectPath, setGlobalState]);
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useRedirectOnForbidden };

// #endregion Exports
