import React from "react";
import { EventLiveVirtualLocationForm } from "components/events/event-live-virtual-location-form/event-live-virtual-location-form";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { EventInPersonLocationForm } from "components/events/event-in-person-location-form/event-in-person-location-form";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { Event } from "models/interfaces/events/event";
import "./admin-edit-event-location-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventLocationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-location-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventLocationPage: React.FC<AdminEditEventLocationPageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventLocationPage
)((): JSX.Element => {
    const event = useEventActiveRecordContext();

    useRedirectOnForbidden(sitemap.admin.dashboard);

    const updateEvent = (values: Partial<Event>): void => {
        if (event.isDraft()) {
            event.updateAndSave(values);
        } else {
            event.updateActiveRecord(values);
        }
    };

    const onHideLocationInformationToggle = () => {
        const newHideLocationInformation = !event.hideLocationInformation;

        if (newHideLocationInformation) {
            updateEvent({
                hideLocationInformation: newHideLocationInformation,
            });
        } else {
            updateEvent({ hideLocationInformation: newHideLocationInformation });
        }
    };
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                    {t("location")}
                </Heading>
                {event.instructorLedTrainingType === InstructorLedTrainingType.InPerson && (
                    <ToggleLabel
                        checked={event.hideLocationInformation}
                        id="hideLocationInformation"
                        label={t("hideLocationInformation")}
                        onToggle={() => onHideLocationInformationToggle()}
                    />
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                {event.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual && (
                    <EventLiveVirtualLocationForm event={event} />
                )}
                {event.instructorLedTrainingType === InstructorLedTrainingType.InPerson && (
                    <EventInPersonLocationForm event={event} />
                )}
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventLocationPage };

// #endregion Exports
