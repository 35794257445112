import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum ContractType {
    InstructorLedTraining = 0,
    OnlineLearning = 1,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ContractTypeDisplayNames: Map<ContractType, TranslatedCopy> = {
    [ContractType.InstructorLedTraining]: "instructorLedTraining",
    [ContractType.OnlineLearning]: "onlineLearning",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractType, ContractTypeDisplayNames };

// #endregion Exports
