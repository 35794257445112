import React from "react";
import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import { NumberUtils } from "utilities/number-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ProductVersion } from "models/interfaces/products/product-version";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";
import "./product-certificate-expiration-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCerfiticateExpirationProps {
    productVersion: ProductVersionRecord;
    onProductVersionDetailsChange: (product: ProductVersionRecord) => void;
    deferSave: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-certificate-expiration-form";
const yesNoRadio: TranslatedCopy[] = ["no", "yes"];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCertificateExpirationForm: React.FC<ProductCerfiticateExpirationProps> = (
    props: ProductCerfiticateExpirationProps
): JSX.Element => {
    const { update } = ProductVersionService.useUpdate();
    const updateProductVersion = (values: ProductVersionRecord): void => {
        props.onProductVersionDetailsChange(values);
    };

    const handleCertificateDurationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const duration = NumberUtils.parseInt(event.target.value);
        if (duration !== undefined && duration < 0) {
            return;
        }

        let partialProductVersion = {
            hasCertificateDuration: true,
            certificateDuration: duration,
        } as Partial<ProductVersion>;

        let updatedProduct = props.productVersion.with(partialProductVersion);
        updateProductVersion(updatedProduct);
    };

    const handleShowCertExpRadioButtonChange = (value: number): void => {
        const originalProductVersion = props.productVersion;

        const updatedProductVersion = props.productVersion.with({
            hasCertificateDuration: !(value === 0),
        });
        updateProductVersion(updatedProductVersion);
        if (!props.deferSave) {
            updateCertificateFields(updatedProductVersion, originalProductVersion);
        }
    };

    const handleToggleHotWork = (value: number): void => {
        const originalProductVersion = props.productVersion;

        const updatedProductVersion = props.productVersion.with({
            isHotWork: !props.productVersion.isHotWork,
        });
        updateProductVersion(updatedProductVersion);
        if (!props.deferSave) {
            updateCertificateFields(updatedProductVersion, originalProductVersion);
        }
    };

    const handleCertificateDurationOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const duration = NumberUtils.parseInt(event.target.value);
        let originalProductVer = props.productVersion;
        let updatedProductVer = props.productVersion.with({ certificateDuration: duration });
        updateProductVersion(updatedProductVer);
        if (!props.deferSave) {
            updateCertificateFields(updatedProductVer, originalProductVer);
        }
    };

    const updateCertificateFields = async (
        updatedProductVersion: ProductVersionRecord,
        originalProductVersion: ProductVersionRecord
    ): Promise<boolean> => {
        try {
            const updateProductVersionPathParms: UpdateProductVersionPathParams = {
                id: updatedProductVersion.id!,
            };
            const updateProductVersionResponse = await update(
                updatedProductVersion,
                updateProductVersionPathParms
            );

            const updateResult = updateProductVersionResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheCertifiateExpiration"));
            updateProductVersion(originalProductVersion!);
            return false;
        }
        return true;
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                {t("certificate")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__description`}>
                <Paragraph>
                    {t(
                        "selectNoIfTheTrainingDoesNotHaveAnExpirationDateANoWillDisplayAnNAInTheCertificateExpirationDateField"
                    )}
                </Paragraph>
                <Paragraph>
                    {t(
                        "selectYesIfTheTrainingHasAnExpirationDateAYesWillDisplayACertificateExpirationDateCalculatedBasedOnTheTrainingCompletionDateAndTheNumberOfYearsInputInTheHowLongIsTheCertificateValidField"
                    )}
                </Paragraph>
                <Paragraph>{t("ifTheCertificateIsSpecificallyForHotWorkSelectYes")}</Paragraph>
            </div>
            <div className="columns">
                <div className="column">
                    <Paragraph>{t("shouldThisCertificateHaveAnExpirationDate")}</Paragraph>
                    <div className="radio-buttons">
                        {yesNoRadio.map((label, index) => (
                            <RadioButton
                                key={`yesNoCertExpRadio-${index}`}
                                checked={
                                    index === 0
                                        ? !props.productVersion.hasCertificateDuration
                                        : props.productVersion.hasCertificateDuration
                                }
                                formFieldName={`yesNoCertExpRadio-${index}`}
                                groupName={`yesNoCertExpRadio-${index}`}
                                id={`yesNoCertExpRadio-${index}`}
                                label={t(label)}
                                labelPosition={RadioButtonLabelPosition.Right}
                                onChange={() => handleShowCertExpRadioButtonChange(index)}
                                value={index}
                            />
                        ))}
                    </div>
                    {props.productVersion.hasCertificateDuration && (
                        <FormTextInput
                            ariaLabelledBy={t("howLongIsTheCertificateValidForInYears")}
                            autoFocus={false}
                            cssClassName="certificate-duration"
                            formFieldName="CertificateDuration"
                            id="CertificateDuration"
                            label={t("howLongIsTheCertificateValidForInYears")}
                            maxNumber={100}
                            onBlur={handleCertificateDurationOnBlur}
                            onChange={handleCertificateDurationChange}
                            placeholder={t("howLongIsTheCertificateValidForInYears")}
                            required={true}
                            type={InputTypes.Number}
                            value={props.productVersion.certificateDuration}
                        />
                    )}
                    <Paragraph cssClassName="hot-work-label">
                        {t("isThisCertificateForHotWork")}
                    </Paragraph>
                    <div className="radio-buttons">
                        {yesNoRadio.map((label, index) => (
                            <RadioButton
                                key={`yesNoHotworkExpRadio-${index}`}
                                checked={
                                    index === 0
                                        ? !props.productVersion.isHotWork
                                        : props.productVersion.isHotWork
                                }
                                formFieldName={`yesNoHotworkExpRadio-${index}`}
                                groupName={`yesNoHotworkExpRadio-${index}`}
                                id={`yesNoHotworkExpRadio-${index}`}
                                label={t(label)}
                                labelPosition={RadioButtonLabelPosition.Right}
                                onChange={() => handleToggleHotWork(index)}
                                value={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCertificateExpirationForm };

// #endregion Exports
