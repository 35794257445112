import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseVersionCreateModal } from "components/courses/course-version/course-version-create-modal/course-version-create-modal";
import { CourseVersionList } from "components/courses/course-version/course-version-list/course-version-list";
import { EditCourseLayout } from "layouts/admin/edit-course-layout/edit-course-layout";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { NumberUtils } from "utilities/number-utils";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/contexts/use-course-context";
import { useCourseVersions } from "utilities/hooks/models/courses/use-course-versions";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useParams } from "react-router-dom";
import "./edit-course-version-list-page.scss";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditCourseVersionListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-course-version-list-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditCourseVersionListPage: React.FC<EditCourseVersionListPageProps> = validatePageAccess(
    AccessControlKeys.EditCourseVersionListPage
)((props): JSX.Element => {
    const { id } = useParams();
    const courseId = NumberUtils.parseInt(id) ?? 0;
    const { record: course } = useCourse();
    const [showCreateCourseVersionModal, setShowCreateCourseVersionModal] =
        useState<boolean>(false);

    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const navigationRequests = useListenForNavigationRequests(EditCourseVersionListPage.name, [
        Breadcrumbs.name,
        EditCourseLayout.name,
    ]);
    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        resolveNavigationRequest(
            EditCourseVersionListPage.name,
            navigationRequest.requestingComponent
        );
        navigationRequest.onNavigationApproved();

        setNavigationRequest(undefined);
    }, [navigationRequest, navigationRequests, resolveNavigationRequest]);

    const { courseVersions, setRefresh } = useCourseVersions({
        courseId: courseId,
        includeCreatedBy: true,
        includeLastModifiedBy: true,
        includeScormPackage: true,
        includeContent: true,
        includeEnrollmentCounts: true,
        refreshUntilComplete: true,
    });

    useEffect(() => {
        const timeoutID = window.setTimeout(async () => {
            const startStatusPolling = courseVersions.some(
                (cv) => cv.scormPackage?.status === ScormPackageImportStatus.Running
            );
            if (startStatusPolling) {
                try {
                    setRefresh(true);
                } catch {
                    ToastManager.info(t("unableToUpdateStatus"));
                }
            } else {
                setRefresh(false);
                return () => window.clearTimeout(timeoutID);
            }
        }, 1000);
    }, [courseVersions, setRefresh]);

    return (
        <>
            {course.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedCourse")}
                </Banner>
            )}
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    {course.activatedOn != null && (
                        <HeaderActions
                            headingPriority={HeadingPriority.H4}
                            headingSize={HeadingSize.Small}
                            title={t("courseMaterials")}>
                            <Button
                                disabled={
                                    course.status === ActiveStatus.Archived ||
                                    course.status === ActiveStatus.Inactive
                                }
                                onClick={() => setShowCreateCourseVersionModal(true)}
                                style={ButtonStyle.Primary}
                                text={t("createNewVersion")}
                            />
                        </HeaderActions>
                    )}
                </div>

                <CourseVersionList courseVersions={courseVersions} setRefresh={setRefresh} />
                <CourseVersionCreateModal
                    open={showCreateCourseVersionModal}
                    setOpen={setShowCreateCourseVersionModal}
                    newCourseVersionNumber={courseVersions.length}
                />
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditCourseVersionListPage };

// #endregion Exports
