import React, { useCallback, useMemo } from "react";
import { EvaluationManager } from "components/evaluations/evaluation-manager/evaluation-manager";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { NumberUtils } from "utilities/number-utils";
import { SubmittedEvaluation } from "components/evaluations/submitted-evaluation/submitted-evaluation";
import { useParams } from "react-router-dom";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import {
    EnrollmentService,
    GetEnrollmentPathParams,
} from "utilities/services/enrollments/enrollment-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEnrollmentContext } from "utilities/contexts/use-enrollment-context";
import { Card, CardStyle } from "components/card/card";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { DateUtils } from "utilities/date-utils";
import { useEvent } from "utilities/hooks/models/events/use-event";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./learner-take-a-training-evaluation-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingEvaluationPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-take-a-training-event-evaluation-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingEvaluationPage: React.FC<LearnerTakeATrainingEvaluationPageProps> =
    validatePageAccess(AccessControlKeys.LearnerTakeATrainingEvaluationPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.training.onlineLearning.details);
        const { enrollmentId: enrollmentIdParam } = useParams();
        const enrollmentId: number = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );

        const { record: enrollment, setRecord: setEnrollment } = useEnrollmentContext();
        const { get: getEnrollmentAPI } = EnrollmentService.useGet();

        const { event } = useEvent({
            eventId: enrollment.event?.id ?? 0,
            includeEventSessions: true,
        });

        const lastEventDate = useMemo(() => {
            const endDate = event?.endDate() ?? event?.eventDays?.slice(-1)[0]?.eventDate();
            return endDate == null ? undefined : new Date(endDate);
        }, [event]);

        const getPercentComplete = useCallback(async (): Promise<number | undefined> => {
            let percentComplete;
            try {
                if (enrollment.id == null) {
                    throw new Error();
                }

                const getEnrollmentPathParams: GetEnrollmentPathParams = { id: enrollment.id! };
                const getEnrollmentResponse = await getEnrollmentAPI(getEnrollmentPathParams, {});

                const { result: resultEnrollment } = getEnrollmentResponse;
                if (resultEnrollment == null || resultEnrollment?.hasErrors()) {
                    throw new Error();
                }

                percentComplete = resultEnrollment.resultObject.percentComplete;
            } catch (error) {
                ToastManager.error(
                    t(
                        "thereWasAnIssueCalculatingThePercentCompletePleaseRefreshThePageToViewThePercentComplete"
                    )
                );
            }
            return percentComplete;
        }, [enrollment.id, getEnrollmentAPI]);

        const handleEvaluationSubmission = async (evaluation: EvaluationRecord) => {
            const percentComplete = await getPercentComplete();

            const enrollmentWithChanges = enrollment.with({
                evaluation: evaluation,
                percentComplete: percentComplete ?? enrollment.percentComplete,
            });

            setEnrollment(enrollmentWithChanges);
        };

        const evaluationId = enrollment?.evaluation?.id ?? 0;

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Evaluation} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("evaluation")}
                    </Heading>
                </div>

                <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                    {t(
                        "nfpaIsCommittedToProvidingYouWithHighqualityContentToHelpMeetYourLearningNeedsWeAppreciateYourFeedbackYourAnswersAndCommentsWillProvideValuableInputAsWeContinueToEnhanceOurOfferings"
                    )}
                </Paragraph>

                {evaluationId > 0 && <SubmittedEvaluation evaluationId={evaluationId} />}
                {enrollmentId > 0 && evaluationId === 0 && enrollment.event != null && (
                    <>
                        {event?.lastDayHasStarted() ? (
                            <EvaluationManager
                                enrollmentId={enrollment?.id!}
                                evaluationTemplateId={enrollment.product?.evaluationTemplateId!}
                                handleEvaluationSubmission={handleEvaluationSubmission}
                            />
                        ) : (
                            <Card style={CardStyle.Empty}>
                                <Icon type={Icons.Lock} size={IconSizes.Large} />
                                <EmptyText spacing={EmptyTextSpacing.None}>
                                    {t("theEvaluationWillBeAvailableToCompleteOnLastEventDate", {
                                        lastEventDate:
                                            lastEventDate == null
                                                ? t("theLastDayOfTheTraining").toLocaleLowerCase()
                                                : DateUtils.formatDateCustom(
                                                      lastEventDate.toString(),
                                                      t("shortDateFormat")
                                                  ),
                                    })}
                                </EmptyText>
                            </Card>
                        )}
                    </>
                )}
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingEvaluationPage };

// #endregion Exports
