import { Record } from "immutable";
import { SubscriptionCreationStatus } from "models/enumerations/enrollments/subscription-creation-status";
import { ClaimLinkTrial } from "models/interfaces/enrollments/claim-link-trial";
import { DateUtils } from "utilities/date-utils";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ClaimLinkTrial = {
    status: SubscriptionCreationStatus.Unknown,
    expiresOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ClaimLinkTrialRecord extends Record(defaultValues) implements ClaimLinkTrial {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ClaimLinkTrial>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if the user has claimed their trial.
     * @returns {boolean} True if the user has claimed their trial, false otherwise.
     */
    public trialClaimed(): boolean {
        return (
            this.expiresOn != null &&
            this.status !== SubscriptionCreationStatus.ValidSubscriptionOwner // It's possible the expiresOn is provided for a PAID subscription, in which case the user has NOT yet claimed their trial.
        );
    }

    /**
     * Convenience method to determine if the trial has expired.
     * @returns {boolean} True if the trial has expired, false otherwise.
     */
    public trialExpired(): boolean {
        return (
            this.trialClaimed() &&
            DateUtils.convertToMilliseconds(this.expiresOn!.toString(), true) <
                DateUtils.convertToMilliseconds(new Date().toString(), true)
        );
    }

    /**
     * Given a set of values for ClaimLinkTrial properties, create a new
     * ClaimLinkTrialRecord object from this instance, overwriting the properties in the
     * values parameter with values provided.
     *
     * @param {Partial<ClaimLinkTrial>} values The values to overwrite on this instance.
     * @returns A ClaimLinkTrialRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<ClaimLinkTrial>): ClaimLinkTrialRecord {
        return new ClaimLinkTrialRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ClaimLinkTrialRecord };

// #endregion Exports
