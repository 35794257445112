import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";

import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useProduct } from "utilities/contexts/use-product-context";
import "./product-version-create-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorLedProductVersionCreateModalProps {
    newProductVersionNumber: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-version-create-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorLedProductVersionCreateModal: React.FC<
    InstructorLedProductVersionCreateModalProps
> = ({ newProductVersionNumber, open, setOpen }): JSX.Element => {
    const { record: product } = useProduct();
    const navigate = useNavigate();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToVersion"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const getNavigationPath = (productId: number) => {
        return RouteUtils.localizePath(
            RouteUtils.replacePathParams(
                sitemap.admin.product.edit.instructorLed.materials.version.create,
                {
                    id: productId,
                }
            )
        );
    };

    const onModalClose = () => {
        setOpen(false);
        navigate(getNavigationPath(product.id!));
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={setOpen}
            title={t("createANewVersion")}
            actions={modalActionArray}
            modalStyle={""}>
            <div>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading size={HeadingSize.XSmall}>
                        {t("youAreGoingToCreateVersionVersionNumber", {
                            versionNumber: newProductVersionNumber,
                        })}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("howItWorks")}</Paragraph>

                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("changesToAssessmentsAreTheTriggerForNewVersions")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "changesToTheEvaluationAreNotAvailableFollowingInitialCreationOfTheProduct"
                            )}
                        </li>
                    </ul>
                </div>
                <div>
                    <Paragraph size={ParagraphSize.Large}>{t("howChangesAreApplied")}</Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatus"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("newlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion")}
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorLedProductVersionCreateModal };

// #endregion Exports
