import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseContent } from "models/interfaces/courses/course-content";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    CourseVersionService,
    UpdateCourseVersionPathParams,
} from "utilities/services/courses/course-version-service";
import { DataTable } from "components/tables/data-table/data-table";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./course-version-changes-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseVersionChangesModalProps {
    courseVersionBeforeChanges: CourseVersionRecord;
    courseVersionWithUpdates: CourseVersionRecord;
    fetchData: () => Promise<void>;
    open: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-version-changes-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseVersionChangesModal: React.FC<CourseVersionChangesModalProps> = ({
    courseVersionBeforeChanges,
    courseVersionWithUpdates,
    fetchData,
    open,
    setEditMode,
    setOpen,
    setRefresh,
}): JSX.Element => {
    const { update } = CourseVersionService.useUpdate();
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");
    const courseContents = courseVersionWithUpdates.courseContents?.sort(
        (a, b) => a.sortOrder! - b.sortOrder!
    );
    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: StringUtils.isEmpty(changeLogDescription),
            onClick: () => handleSavingChanges(),
            style: ButtonStyle.Primary,
        },
    ];

    const handleSavingChanges = async () => {
        await updateCourseVersion();
        fetchData();
        setOpen(false);
    };

    const onModalClose = (): void => {
        setOpen(false);
    };

    const updateCourseVersion = async (): Promise<boolean> => {
        try {
            var courseVersion = courseVersionWithUpdates.with({
                changeLogDescription: changeLogDescription,
            });

            const updateCourseVersionPathParms: UpdateCourseVersionPathParams = {
                id: courseVersionWithUpdates.id!,
            };

            const updateCourseVersionResponse = await update(
                courseVersion,
                updateCourseVersionPathParms
            );

            const updateResult = updateCourseVersionResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            setChangeLogDescription("");
            setEditMode(false);
            setRefresh(true);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheCourseVersion"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("saveChangesSummary")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Heading cssClassName={`${CSS_CLASS_NAME}__heading`} priority={HeadingPriority.H2}>
                    {t("youAreEditingTheCurrentVersion")}
                </Heading>
                <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                    {t("takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving")}
                </Paragraph>
                <div className={`${CSS_CLASS_NAME}__details ${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__details`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("changeLog")}
                        </Heading>
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        />
                        <FormTextArea
                            ariaLabelledBy={t("describeYourChanges")}
                            autoFocus={false}
                            formFieldName="describeYourChanges"
                            id="describeYourChanges"
                            label={t("describeYourChanges")}
                            maxLength={1000}
                            placeholder={t("describeYourChanges")}
                            onChange={handleChangeLogChange}
                            required={true}
                            type={InputTypes.Text}
                            value={changeLogDescription}
                        />
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("zipPackage")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("name")}</th>
                                <th className="file">{t("fileName")}</th>
                                <th className="status">{t("importStatus")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{courseVersionWithUpdates.scormPackage?.name}</td>
                                <td className="file">
                                    {courseVersionWithUpdates.scormPackage?.file?.fileName}
                                </td>
                                <td className="status">
                                    {
                                        ScormPackageImportStatus[
                                            courseVersionWithUpdates.scormPackage?.status!
                                        ]
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("content")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("order")}</th>
                                <th>{t("name")}</th>
                                <th>{t("description")}</th>
                                <th>{t("fileName")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseContents?.map((courseContent: CourseContent) => (
                                <tr key={`${courseContent.id}-${courseContent.contentId}`}>
                                    <td>{courseContent.sortOrder}</td>
                                    <td>{courseContent.content?.name}</td>
                                    <td>{courseContent.content?.description}</td>
                                    <td>{courseContent.content?.file?.fileName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionChangesModal };

// #endregion Exports
