import { EnrollmentRecord } from "./enrollment-record";
import { EnrollmentScormPackageAssessment } from "models/interfaces/enrollments/enrollment-scorm-package-assessment";
import { EnrollmentScormPackageAssessmentStatus } from "models/enumerations/enrollments/enrollment-scorm-package-assessment-status";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { RegistrationRecord } from "../registrations/registration-record";
import { ScormPackageAssessmentRecord } from "models/view-models/scorm-packages/scorm-package-assessment-record";

// -----------------------------------------------------------------------------------------
// #region Default Values
// -----------------------------------------------------------------------------------------

const defaultValues: EnrollmentScormPackageAssessment = {
    currentScore: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    id: undefined,
    locked: false,
    optional: true,
    registration: undefined,
    registrationId: undefined,
    status: EnrollmentScormPackageAssessmentStatus.NotStarted,
    scormPackageAssessment: undefined,
    scormPackageAssessmentId: 0,
    sortOrder: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -----------------------------------------------------------------------------------------
// #region Record
// -----------------------------------------------------------------------------------------

class EnrollmentScormPackageAssessmentRecord
    extends Record(defaultValues)
    implements EnrollmentScormPackageAssessment
{
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<EnrollmentScormPackageAssessment>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.registration != null) {
            params.registration = RecordUtils.ensureRecord(params.registration, RegistrationRecord);
        }

        if (params.scormPackageAssessment != null) {
            params.scormPackageAssessment = RecordUtils.ensureRecord(
                params.scormPackageAssessment,
                ScormPackageAssessmentRecord
            );
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Given a set of values for EnrollmentProductScormPackage properties, create a new EnrollmentProductScormPackageRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EnrollmentScormPackageAssessment>} values The values to overwrite on this instance.
     * @returns A EnrollmentRecord with values from this instance and the values parameter.
     */
    public with(
        values: Partial<EnrollmentScormPackageAssessment>
    ): EnrollmentScormPackageAssessmentRecord {
        return new EnrollmentScormPackageAssessmentRecord(Object.assign(this.toJS(), values));
    }
    // #endregion Public Methods
}

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { EnrollmentScormPackageAssessmentRecord };

// #endregion Exports
