import DatePicker from "react-datepicker";
import React, { forwardRef, useMemo } from "react";
import moment from "moment";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import { t } from "utilities/localization/t";
import { LocalizationUtils } from "utilities/localization-utils";
import "react-datepicker/dist/react-datepicker.css";
import "./time-picker.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TimePickerProps {
    readOnly?: boolean;
    onChange: (date?: Date) => void;
    selectedTime: Date | null;
    setSelectedTime?: React.Dispatch<React.SetStateAction<Date | null>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "time-picker";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TimePicker: React.FC<TimePickerProps> = ({
    readOnly,
    onChange,
    selectedTime,
    setSelectedTime,
}) => {
    const { readOnly: isCtxReadOnly } = useReadOnly();
    const disabled = useMemo(() => readOnly || isCtxReadOnly, [isCtxReadOnly, readOnly]);

    let className = CSS_CLASS_NAME;
    if (disabled) {
        className += " -disabled";
    }

    const getFormattedTime = () =>
        selectedTime
            ? selectedTime.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
              })
            : null;

    const CustomInput = forwardRef(
        (
            {
                onClick,
            }: { onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void },
            ref
        ) => (
            <button className={`${CSS_CLASS_NAME}__text ${className}`} onClick={onClick}>
                <Icon cssClassName={`${CSS_CLASS_NAME}__clock`} type={Icons.Clock} />
                <div className={`${CSS_CLASS_NAME}__label`}>
                    {selectedTime ? getFormattedTime() : t("selectATime")}
                </div>
                <Icon
                    cssClassName={`${CSS_CLASS_NAME}__chevron`}
                    type={Icons.ChevronDown}
                    size={IconSizes.Small}
                />
            </button>
        )
    );

    return (
        <DatePicker
            customInput={React.createElement(CustomInput)}
            dateFormat="h:mm aa"
            minDate={moment().toDate()}
            onChange={(date) => onChange(date ?? undefined)}
            readOnly={disabled}
            selected={selectedTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            locale={LocalizationUtils.currentCultureCode()}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TimePicker };

// #endregion Exports
