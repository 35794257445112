import { Process } from "utilities/types/processes/process";
import { RouteUtils } from "utilities/route-utils";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";
import {
    buildEditEventProcessBase,
    EditEventProcessBaseSteps,
} from "utilities/processes/events/build-edit-event-process-base";
import { EventActiveRecord } from "models/active-records/events/event-active-record";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum NfpaRoleEditEventProcessSpecificSteps {
    Details,
}

export type NfpaRoleEditEventProcessSteps = typeof NfpaRoleEditEventProcessSpecificSteps &
    typeof EditEventProcessBaseSteps;

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildNfpaRoleEditEventProcess = (
    eventId: number,
    event: EventActiveRecord
): Process<NfpaRoleEditEventProcessSteps> => ({
    Details: {
        displayName: "eventDetails",
        isComplete: () => event.detailsAreComplete(),
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.details, {
            id: eventId,
        }),
    },
    ...buildEditEventProcessBase(eventId, event),
});

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useNfpaRoleEditEventProcess = UseProcessFactory.build(buildNfpaRoleEditEventProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useNfpaRoleEditEventProcess };

// #endregion Exports
