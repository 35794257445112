import React, { useEffect, useMemo } from "react";
import { CollectionUtils } from "andculturecode-javascript-core";
import { CourseDetailsArchiveRecord } from "models/view-models/courses/course-details-archive-record";
import { DataTable } from "components/tables/data-table/data-table";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./course-details-change-history.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseDetailsChangeHistoryProps {
    courseDetailsArchiveData: CourseDetailsArchiveRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-details-change-history";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseDetailsChangeHistory: React.FC<CourseDetailsChangeHistoryProps> = ({
    courseDetailsArchiveData,
}): JSX.Element => {
    const createdLog = useMemo(
        () =>
            courseDetailsArchiveData[courseDetailsArchiveData.length - 1] ??
            new CourseDetailsArchiveRecord(),
        [courseDetailsArchiveData]
    );

    const courseDetailsChangeLog: CourseDetailsArchiveRecord[] = useMemo(() => [], []);

    const deactivateArchiveLog: CourseDetailsArchiveRecord[] = useMemo(() => [], []);

    useEffect(() => {
        for (var i = 0; i < courseDetailsArchiveData.length - 1; i++) {
            if (
                courseDetailsArchiveData[i].changeLog?.includes("Deactivated") ||
                courseDetailsArchiveData[i].changeLog?.includes("Archived")
            ) {
                deactivateArchiveLog.push(courseDetailsArchiveData[i]);
            } else {
                courseDetailsChangeLog.push(courseDetailsArchiveData[i]);
            }
        }
    }, [courseDetailsArchiveData, courseDetailsChangeLog, deactivateArchiveLog]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__main`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Paragraph size={ParagraphSize.Large}>{t("changeHistory")}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="change-log">{t("changeLog")}</th>
                                <th className="date">{t("date")}</th>
                                <th className="changed-by">{t("changedBy")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {CollectionUtils.hasValues(deactivateArchiveLog) &&
                                deactivateArchiveLog.map((da: CourseDetailsArchiveRecord, idx) => (
                                    <tr key={`${da.id}-${idx}`}>
                                        <td className="created-log">{da.changeLog}</td>
                                        <td className="date">{da.getCreatedDateAndTime()}</td>
                                        <td className="changed-by">
                                            <>
                                                <Paragraph size={ParagraphSize.XSmall}>
                                                    {da.getCreatedBy()}
                                                </Paragraph>
                                                <Paragraph
                                                    size={ParagraphSize.XxSmall}
                                                    style={ParagraphStyle.Light}>
                                                    {da.createdById}
                                                </Paragraph>
                                            </>
                                        </td>
                                    </tr>
                                ))}
                            {courseDetailsChangeLog.map((archive: CourseDetailsArchiveRecord) => (
                                <tr key={archive.id}>
                                    <td className="change-log">{archive.changeLog}</td>
                                    <td className="date">{archive.getCreatedDateAndTime()}</td>
                                    <td className="changed-by">
                                        <>
                                            <Paragraph size={ParagraphSize.XSmall}>
                                                {archive.getCreatedBy()}
                                            </Paragraph>
                                            <Paragraph
                                                size={ParagraphSize.XxSmall}
                                                style={ParagraphStyle.Light}>
                                                {archive.createdById}
                                            </Paragraph>
                                        </>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="created-log">{createdLog.changeLog}</td>
                                <td className="date">{createdLog.getCreatedDateAndTime()}</td>
                                <td className="changed-by">
                                    <>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {createdLog.getCreatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}>
                                            {createdLog.createdById}
                                        </Paragraph>
                                    </>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDetailsChangeHistory };

// #endregion Exports
