import { CourseVersionArchiveRecord } from "models/view-models/courses/course-version-archive-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "courseversionarchive";
const resourceType: typeof CourseVersionArchiveRecord = CourseVersionArchiveRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListCourseVersionArchivesQueryParams {
    courseId: number;
    courseVersionId: number;
    includeCreatedBy?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CourseVersionArchiveService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        CourseVersionArchiveRecord,
        ListCourseVersionArchivesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionArchiveService };

// #endregion Exports
