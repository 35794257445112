import { FC, PropsWithChildren } from "react";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { StringUtils } from "utilities/string-utils";
import "./header-actions.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HeaderActionsProps extends PropsWithChildren {
    cssClassName?: string;
    headingPriority?: HeadingPriority;
    headingSize?: HeadingSize;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "header-actions";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HeaderActions: FC<HeaderActionsProps> = (props: HeaderActionsProps): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <div className={className}>
            <Heading
                priority={props.headingPriority ?? HeadingPriority.H2}
                size={props.headingSize ?? HeadingSize.Small}>
                {props.title}
            </Heading>
            <div>{props.children}</div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HeaderActions };

// #endregion Exports
