import { useEffect, useMemo, useState } from "react";
import { RoleType } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface RoleHasChangedHook {
    currentlyHasRole: boolean;
    previouslyHadRole: boolean;
    roleHasChanged: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useRoleHasChanged = (): RoleHasChangedHook => {
    const { record: globalState } = useGlobalState();
    const currentRole = useMemo(
        (): RoleType | null => globalState.currentIdentity?.roleType() ?? null,
        [globalState.currentIdentity]
    );
    const [previousRole, setPreviousRole] = useState<RoleType | null>(currentRole ?? null);

    useEffect(() => {
        if (currentRole !== previousRole) {
            setPreviousRole(currentRole);
        }
    }, [currentRole, previousRole]);

    return {
        currentlyHasRole: currentRole != null,
        previouslyHadRole: previousRole != null,
        roleHasChanged: currentRole !== previousRole,
    };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useRoleHasChanged };

// #endregion Exports
