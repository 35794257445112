import React, { useMemo } from "react";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { EventRecord } from "models/view-models/events/event-record";
import { EventType, EventTypeUserDisplayNames } from "models/enumerations/events/event-type";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { NumberUtils } from "utilities/number-utils";
import { ProductRecord } from "models/view-models/products/product-record";
import { RouteUtils } from "utilities/route-utils";
import { Topic } from "models/enumerations/courses/topic";
import { TopicBubble } from "components/bubble/topic-bubble";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import { useProductUnits } from "utilities/hooks/models/products/use-product-units";
import "./user-training-detail-overview.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailOverviewProps {
    contractId?: number;
    event?: EventRecord;
    product?: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-overview";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailOverview: React.FC<UserTrainingDetailOverviewProps> = ({
    contractId,
    event,
    product,
}): JSX.Element => {
    const { enrollmentId } = useParams();
    const enrollmentIdNumber = useMemo(
        () => NumberUtils.parseInt(enrollmentId) ?? 0,
        [enrollmentId]
    );

    const isIltTraining = event?.id != null && event?.id > 0;
    const isContractTraining = contractId != null;
    const isEducationNetworkTraining = isIltTraining && event.type === EventType.EducationNetwork;

    const { contract } = useContract({
        contractId: contractId,
        includeOrganization: true,
    });
    const { units } = useProductUnits({ productId: product?.id, includeUnitCourses: true });

    const { enrollment } = useEnrollment({
        enrollmentId: enrollmentIdNumber,
        includeProduct: true,
    });

    const getTopicBadges = () => {
        if (isIltTraining) {
            return <TopicBubble topic={event?.product?.topic!} />;
        } else {
            const unitCourses = units.map((unit) => unit.unitCourses).flat();
            const topics: Array<Topic | undefined> = unitCourses
                ?.map((uc) => uc?.course?.topic)
                .filter((topic) => topic != null);

            const distinctSortedTopics = topics
                ?.filter((topic, index) => topics.indexOf(topic) === index)
                .sort();

            return distinctSortedTopics?.map((topic, idx) => (
                <TopicBubble key={`${topic}-${idx}`} topic={topic!} />
            ));
        }
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <h4>{t("salesType")}</h4>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__sales`}>
                <div>
                    <FormFieldReadonly label={t("salesType").toLocaleUpperCase()}>
                        {contract.productId && t("contract").toLocaleUpperCase()}
                        {!isContractTraining && !isIltTraining && t("nfpaCatalog")}
                        {isIltTraining && t(EventTypeUserDisplayNames[event?.type!])}
                    </FormFieldReadonly>
                </div>
                {isContractTraining && (
                    <>
                        <div>
                            <FormFieldReadonly label={t("contractNumber").toLocaleUpperCase()}>
                                {contract.contractNumber}
                            </FormFieldReadonly>
                        </div>
                        <div>
                            <FormFieldReadonly label={t("associatedOrganization")}>
                                {isIltTraining
                                    ? event.organization?.name
                                    : contract.organization?.name}
                            </FormFieldReadonly>
                            <Button
                                linkPath={RouteUtils.replacePathParams(
                                    sitemap.admin.userManagement.contracts.info,
                                    {
                                        id: isIltTraining
                                            ? event.organizationId
                                            : contract.organizationId ?? 0,
                                    }
                                )}
                                onClick={() => {}}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                text={t("viewOrganization")}
                                type={ButtonType.Link}
                            />
                        </div>
                    </>
                )}
                {isEducationNetworkTraining && (
                    <>
                        <div>
                            <FormFieldReadonly label={t("associatedProduct").toLocaleUpperCase()}>
                                {event.provider?.name}
                            </FormFieldReadonly>
                            <Button
                                linkPath="#"
                                onClick={() => {}}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                text={t("viewAENProvider")}
                                type={ButtonType.Link}
                            />
                        </div>
                    </>
                )}
            </div>

            {isIltTraining && (
                <>
                    <h4>{t("eventInformation")}</h4>
                    <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__event`}>
                        <div>
                            <FormFieldReadonly label={t("eventName")}>
                                {event.name}
                            </FormFieldReadonly>
                            <Button
                                linkPath={RouteUtils.replacePathParams(
                                    sitemap.admin.event.manage.details,
                                    { id: event.id }
                                )}
                                onClick={() => {}}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                text={t("goToEvent")}
                                type={ButtonType.Link}
                            />
                        </div>
                        <div>
                            <FormFieldReadonly label={t("eventID").toLocaleUpperCase()}>
                                {event.id}
                            </FormFieldReadonly>
                        </div>
                        <div>
                            <FormFieldReadonly label={t("ceus")}>
                                {event.getCeusDisplay()}
                            </FormFieldReadonly>
                        </div>
                    </div>
                </>
            )}
            <h4>{t("productInformation")}</h4>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__product`}>
                <div>
                    <FormFieldReadonly label={t("productID").toLocaleUpperCase()}>
                        {product?.id}
                    </FormFieldReadonly>
                    <FormFieldReadonly label={t("topic")}>
                        <div className="badge-list">{getTopicBadges()}</div>
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("erpId")}>
                        {product?.erpId ?? "--"}
                    </FormFieldReadonly>
                    <FormFieldReadonly label={t("version")}>
                        {enrollment.productVersion?.versionId}
                    </FormFieldReadonly>
                </div>
                <div>
                    <FormFieldReadonly label={t("language")}>
                        {t(LanguageDisplayNames[product?.language!])}
                    </FormFieldReadonly>
                    {!isIltTraining && (
                        <FormFieldReadonly label={t("ceus")}>
                            {enrollment.productVersion?.getCeusDisplay() ?? "--"}
                        </FormFieldReadonly>
                    )}
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailOverview };

// #endregion Exports
