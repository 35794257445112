import React from "react";
import "./heading.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export enum HeadingSize {
    XXSmall = "-xxsmall",
    XSmall = "-xsmall",
    Small = "-small",
    Default = "-default",
    Large = "-large",
    XLarge = "-xlarge",
}

export enum HeadingPriority {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6,
}

export interface HeadingProps {
    ariaHidden?: boolean;
    cssClassName?: string;
    priority?: HeadingPriority;
    size?: HeadingSize;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "heading";
const DEFAULT_HEADING_PRIORITY: HeadingPriority = HeadingPriority.H1;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const priority = props.priority ?? DEFAULT_HEADING_PRIORITY;

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.size) {
        cssClassNames.push(props.size);
    }


    const renderHeading = () => {
        switch (priority) {
            case HeadingPriority.H2:
                return (
                    <h2
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h2>
                );
            case HeadingPriority.H3:
                return (
                    <h3
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h3>
                );
            case HeadingPriority.H4:
                return (
                    <h4
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h4>
                );
            case HeadingPriority.H5:
                return (
                    <h5
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h5>
                );
            case HeadingPriority.H6:
                return (
                    <h6
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h6>
                );
            default:
                return (
                    <h1
                        aria-hidden={props.ariaHidden}
                        className={cssClassNames.join(" ")}>
                        {props.children}
                    </h1>
                );
        }
    };

    return <>{renderHeading()}</>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Heading };

// #endregion Exports
