import axios from "axios";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import {
    ServiceFactory,
    ServiceHookFactory,
    useCancellablePromise,
} from "andculturecode-javascript-react";
import { useCallback } from "react";
import { ScormPackageStatusRecord } from "models/view-models/scorm-packages/scorm-package-status-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "scormpackages";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const scormPreviewEndpoint: string = `${resourceEndpoint}/scormpreview`;
const scormVersionPreviewEndpoint: string = `${baseEndpoint}/scormversionpreview`;
const scormPackageStatusEndpoint: string = `${resourceEndpoint}/scormpackagestatus`;
const scormPackageExistsEndpoint: string = `${resourceEndpoint}/scormpackageexists`;
const resourceType: typeof ScormPackageRecord = ScormPackageRecord;
const scormPreviewLinkResourceType: typeof String = String;
const scormStatusPollingType: typeof ScormPackageStatusRecord = ScormPackageStatusRecord;
const scormPackageExistsType: typeof Boolean = Boolean;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateScormPackageService = (
    params: CreateScormPackageParams
) => Promise<ServiceResponse<ScormPackageRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetVersionLaunchLinkQueryParams {
    externalCourseId: string;
    versionId?: number;
    isDraft: boolean;
}

export interface CreateScormPackageParams {
    courseId: number;
    courseVersionId?: number;
    fileId: number;
    name: string;
    isDraft: boolean;
    draftVersionId?: number;
}

export interface ScormPackageResourceEndpointPathParams {
    id: number;
}
export interface GetScormPackageResourceEndpointPathParams {
    id: number;
}

export interface GetScormPackageExistsEndpointPathParams {
    id: string;
}

export interface GetScormPackagePreviewResourceEndpointPathParams {
    id: string;
}
// #endregion Interfaces

// #region Service
// -------------------------------------------------------------------------------------------------

const ScormPackageService = {
    create: ServiceFactory.create(resourceType, baseEndpoint),

    update: ServiceFactory.update<ScormPackageRecord, ScormPackageResourceEndpointPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateScormPackageService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateScormPackageService = (
            params: CreateScormPackageParams
        ): Promise<ServiceResponse<ScormPackageRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            params: CreateScormPackageParams
        ): Promise<ServiceResponse<ScormPackageRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<ScormPackageRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    useUpdate: ServiceHookFactory.useUpdate<
        ScormPackageRecord,
        ScormPackageResourceEndpointPathParams
    >(resourceType, resourceEndpoint),

    useGetScormPackageStatus: ServiceHookFactory.useGet<
        ScormPackageStatusRecord,
        GetScormPackageResourceEndpointPathParams
    >(scormStatusPollingType, scormPackageStatusEndpoint),

    useGetPreviewLaunchLink: ServiceHookFactory.useGet<
        String,
        GetScormPackagePreviewResourceEndpointPathParams
    >(scormPreviewLinkResourceType, scormPreviewEndpoint),

    useGetPreviewVersionLaunchLink: ServiceHookFactory.useGet<
        String,
        GetVersionLaunchLinkQueryParams,
        GetVersionLaunchLinkQueryParams
    >(scormPreviewLinkResourceType, scormVersionPreviewEndpoint),

    useGetCheckIfScormPackageExists: ServiceHookFactory.useGet<
        Boolean,
        GetScormPackageExistsEndpointPathParams
    >(scormPackageExistsType, scormPackageExistsEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageService };

// #endregion Exports
