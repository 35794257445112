import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ProductVersionService } from "utilities/services/products/product-version-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseProductVersionsHook {
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    productVersions: ProductVersionRecord[];
    rowCount: number;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseProductVersionsHookOptions {
    productId?: number;
    includeCreatedBy: boolean;
    includeLastModifiedBy: boolean;
    includeProductScormPackage: boolean;
    includeContent: boolean;
    includeEnrollmentCounts: boolean;
    skip?: number;
    take?: number;
}

export function useProductVersions(props: UseProductVersionsHookOptions): UseProductVersionsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [productVersions, setProductVersions] = useState<ProductVersionRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const { list: listProductVersions } = ProductVersionService.useList();
    const productId = props.productId;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLastModifiedBy = props.includeLastModifiedBy;
    const includeEnrollmentCounts = props.includeEnrollmentCounts;
    const includeProductScormPackage = props.includeProductScormPackage;
    const skip = props.skip;
    const take = props.take;

    const fetchData = useCallback(async () => {
        try {
            (async function getProductVersions() {
                if (!productId || productId <= 0) {
                    return;
                }
                const { result, resultObjects, rowCount } = await listProductVersions({
                    productId,
                    skip,
                    take,
                    includeCreatedBy,
                    includeLastModifiedBy,
                    includeEnrollmentCounts,
                    includeProductScormPackage,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setProductVersions(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningProducts"));
        }
    }, [
        includeCreatedBy,
        includeEnrollmentCounts,
        includeLastModifiedBy,
        includeProductScormPackage,
        listProductVersions,
        productId,
        skip,
        take,
    ]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        productVersions,
        rowCount,
        setRefresh,
    };
}
