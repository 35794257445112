import {
    AenApplicationSidebar,
    AenApplicationSidebarNavItem,
} from "layouts/admin/aen-application-layout/aen-application-sidebar/aen-application-sidebar";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { EditProviderApplicationModal } from "pages/admin/aen-applications/edit-provider-application-modal/edit-provider-application-modal";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icons } from "components/icons/constants/icons";
import { Outlet } from "react-router-dom";
import { ProviderApplicationContext } from "utilities/contexts/use-provider-application-context";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useAenProviderApplication } from "utilities/hooks/models/aen-applications/use-aen-provider-application";
import { useMemo, useState } from "react";
import "./aen-application-layout.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AenProviderApplicationLayoutProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-application-layout";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AenProviderApplicationLayout: React.FC<AenProviderApplicationLayoutProps> = () => {
    const { providerApplication, setProviderApplication, saveApplication } =
        useAenProviderApplication({});
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const navItems = useMemo(() => {
        const items: AenApplicationSidebarNavItem[] = [
            {
                path: sitemap.admin.aenApplications.providers.detail,
                text: t("applicationDetails"),
            },
            {
                path: sitemap.admin.aenApplications.providers.files,
                text: t("files"),
            },
            {
                path: sitemap.admin.aenApplications.providers.logs,
                text: t("activityLogs"),
            },
        ];
        return items;
    }, []);

    return (
        <ProviderApplicationContext.Provider
            value={{ record: providerApplication, setRecord: setProviderApplication }}>
            <section className={CSS_CLASS_NAME}>
                <HeaderBanner title={providerApplication?.providerName ?? ""}>
                    <ButtonIcon
                        onClick={() => {
                            setShowEditModal(true);
                        }}
                        buttonStyle={ButtonStyle.Secondary}
                        text={t("updateStatus")}
                        iconType={Icons.Edit}
                    />
                </HeaderBanner>
                <SideContentLeftLayout
                    sidebarElement={<AenApplicationSidebar navItems={navItems} />}>
                    <div className="content-wrap">
                        <div className="content">
                            <SkipNavContent>
                                <Outlet />
                            </SkipNavContent>
                        </div>
                    </div>
                </SideContentLeftLayout>
            </section>
            {providerApplication != null && (
                <EditProviderApplicationModal
                    open={showEditModal}
                    setOpen={setShowEditModal}
                    providerApplicationToEdit={providerApplication}
                    onSave={saveApplication}
                />
            )}
        </ProviderApplicationContext.Provider>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AenProviderApplicationLayout };

// #endregion Exports
