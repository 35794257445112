import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { ToastManager } from "utilities/toast/toast-manager";
import { ProviderContext } from "utilities/contexts/use-provider-context";
import {
    ProviderDetailSidebar,
    ProviderDetailSidebarNavItem,
} from "components/user-management/providers/provider-detail-sidebar/provider-detail-sidebar";
import {
    PatchProviderIsActiveParams,
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { ProviderDetailHeader } from "components/user-management/providers/provider-detail-header/provider-detail-header";
import { EditProviderModal } from "components/user-management/providers/edit-provider-modal/edit-provider-modal";
import { ProviderChangeStatusModal } from "components/user-management/providers/provider-change-status-modal/provider-change-status-modal";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./provider-management-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderManagementLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-management-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderManagementLayout: React.FC<ProviderManagementLayoutProps> = (): JSX.Element => {
    const { id: providerIdParam } = useParams();
    const providerId = useMemo(() => NumberUtils.parseInt(providerIdParam) ?? 0, [providerIdParam]);

    const canChangeProviderStatus = useHasAccess(AccessControlKeys.CanChangeProviderStatus);

    const { get: getProviderApi } = ProviderService.useGet();
    const { update: updateProviderApi } = ProviderService.useUpdate();
    const { patch: updatePatchIsActiveApi } = ProviderService.usePatchIsActive();

    const [provider, setProvider] = useState<ProviderRecord>(new ProviderRecord());
    const [showEditProviderModal, setShowEditProviderModal] = useState(false);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

    // =================================================================================================
    // #region API Calls
    // =================================================================================================

    const fetchData = useCallback(async () => {
        if (providerId <= 0) {
            return;
        }

        const pathParams: ProviderResourcePathParams = {
            id: providerId,
        };

        try {
            const getResponse = await getProviderApi(pathParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            setProvider(getResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingProviders"));
        }
    }, [providerId, getProviderApi]);

    const updateProvider = useCallback(
        async (providerToSave: ProviderRecord): Promise<boolean> => {
            const pathParams: ProviderResourcePathParams = {
                id: providerToSave.id!,
            };
            try {
                const updateProviderResponse = await updateProviderApi(providerToSave, pathParams);
                const updateProviderResult = updateProviderResponse?.result;

                if (
                    updateProviderResult?.resultObject == null ||
                    updateProviderResult.hasErrors()
                ) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheProvider"));
                return false;
            }
            return true;
        },
        [updateProviderApi]
    );

    const updateProviderIsActive = useCallback(
        async (isActive: boolean): Promise<boolean> => {
            if (provider?.id == null) {
                return false;
            }

            const pathParameters: ProviderResourcePathParams = {
                id: provider.id,
            };

            const patchParameters: PatchProviderIsActiveParams = {
                isActive: isActive,
            };

            try {
                const patchProviderResponse = await updatePatchIsActiveApi(
                    pathParameters,
                    patchParameters
                );
                const patchProviderResult = patchProviderResponse?.result;

                if (patchProviderResult?.resultObject == null || patchProviderResult.hasErrors()) {
                    throw new Error();
                }

                setProvider((previousProvider) => previousProvider.with({ isActive: isActive }));
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheProvider"));
                return false;
            }
            return true;
        },
        [provider.id, updatePatchIsActiveApi]
    );

    const saveProviderEditForm = useCallback(
        async (providerToSave: ProviderRecord): Promise<boolean> => {
            let updateSuccess = true;

            const providerId = providerToSave.id ?? 0;

            if (providerId <= 0) {
                return !updateSuccess;
            }

            updateSuccess = updateSuccess && (await updateProvider(providerToSave));

            fetchData();

            return updateSuccess;
        },
        [fetchData, updateProvider]
    );

    // #endregion API Calls

    const handleModalClose = useCallback((): void => setShowChangeStatusModal(false), []);

    const handleSaveChanges = useCallback(
        (isActive: boolean): void => {
            if (isActive === provider.isActive) {
                return;
            }

            updateProviderIsActive(isActive);
        },
        [provider.isActive, updateProviderIsActive]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const navItems: ProviderDetailSidebarNavItem[] = useMemo(() => {
        return [
            {
                path: sitemap.admin.userManagement.enProviders.info,
                text: "enProviderInformation",
            },
            {
                path: sitemap.admin.userManagement.enProviders.associatedUsers,
                text: "associatedUsers",
            },
        ];
    }, []);

    return (
        <ProviderContext.Provider value={{ record: provider, setRecord: setProvider }}>
            <div className={CSS_CLASS_NAME}>
                <ProviderDetailHeader
                    canChangeProviderStatus={canChangeProviderStatus}
                    provider={provider}
                    onChangeStatusClick={() => setShowChangeStatusModal(true)}
                    onEditProviderClick={() => setShowEditProviderModal(true)}
                />
                <SideContentLeftLayout
                    sidebarElement={<ProviderDetailSidebar navItems={navItems} />}>
                    <Outlet />
                </SideContentLeftLayout>
            </div>
            {provider != null && (
                <>
                    <EditProviderModal
                        open={showEditProviderModal}
                        setOpen={setShowEditProviderModal}
                        providerToEdit={provider}
                        onEditFormSave={saveProviderEditForm}
                    />
                    <ProviderChangeStatusModal
                        onModalClose={handleModalClose}
                        onSaveChanges={handleSaveChanges}
                        open={canChangeProviderStatus && showChangeStatusModal}
                        providerIsActive={provider.isActive}
                    />
                </>
            )}
        </ProviderContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderManagementLayout };

// #endregion Exports
