import { useRef } from "react";

/**
 * Runs the callback immediately and only once
 * @param callback Callback to run
 * @param condition (Optional) When condition is true it will run the callback
 */
export const useRunOnce = (callback: Function, condition?: () => boolean): void => {
    const hasRun = useRef(false);

    if (hasRun.current || (condition && !condition())) {
        return;
    }

    callback();
    hasRun.current = true;
};
