import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { AddGroupUserModalUser } from "components/user-management/groups/add-group-users-modal/manage-add-group-users";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "utilities/localization/t";
import "./selected-group-user-modal-user-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------
interface SelectedGroupUserModalUserListProps {
    selectedGroupUserModalUsers: AddGroupUserModalUser[];
    handleAdd: (user: UserRecord) => void;
    handleRemove: (user: UserRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "selected-group-user-modal-user-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SelectedGroupUserModalUserList: React.FC<SelectedGroupUserModalUserListProps> = ({
    selectedGroupUserModalUsers,
    handleAdd,
    handleRemove,
}) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="user-name">{t("user")}</th>
                    <th className="id">{t("id")}</th>
                    <th className="action">{t("action")}</th>
                </tr>
            </thead>
            <tbody>
                {selectedGroupUserModalUsers.map((selectedGroupUserModalUser) => (
                    <tr key={selectedGroupUserModalUser.user.id}>
                        <td className="user-name">
                            <td className="user-name">
                                <span>{selectedGroupUserModalUser.user.getFirstAndLastName()}</span>
                                <span>
                                    <Tooltip
                                        content={selectedGroupUserModalUser.user.email ?? ""}
                                        placement={TooltipPlacement.Bottom}
                                        style={TooltipStyle.Secondary}>
                                        <div>
                                            <Paragraph
                                                cssClassName={`${CSS_CLASS_NAME}__user-email`}
                                                size={ParagraphSize.Small}>
                                                {selectedGroupUserModalUser.user.email}
                                            </Paragraph>
                                        </div>
                                    </Tooltip>
                                </span>
                            </td>
                        </td>
                        <td className="id">{selectedGroupUserModalUser.user.id}</td>
                        <td className="action">
                            {!selectedGroupUserModalUser.addedToQueue && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    iconType={Icons.Plus}
                                    onClick={() => handleAdd(selectedGroupUserModalUser.user)}
                                    text={t("add")}
                                />
                            )}
                            {selectedGroupUserModalUser.addedToQueue && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Minus}
                                    onClick={() => handleRemove(selectedGroupUserModalUser.user)}
                                    text={t("remove")}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SelectedGroupUserModalUserList };

// #endregion Exports
