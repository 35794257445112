import React, { useEffect, useMemo, useState } from "react";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { AttemptDetails } from "components/attempts/attempt-details/attempt-details";
import { Card } from "components/card/card";
import { NumberUtils } from "utilities/number-utils";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Select } from "components/form/inputs/select/select";
import { EmptyText, EmptyTextSpacing } from "../../empty-text/empty-text";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { AttemptModal } from "../../user-management/attempt-modal/attempt-modal";
import { t } from "utilities/localization/t";
import "./assessment-attempts-details.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssessmentAttemptsDetailsProps {
    cssClassName?: string;
    attempts?: AttemptRecord[];
    onAttemptSave?: (attempt: AttemptRecord) => void;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "assessment-attempts-details";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssessmentAttemptsDetails: React.FC<AssessmentAttemptsDetailsProps> = ({
    cssClassName,
    attempts,
    onAttemptSave,
    tabIndex,
}): JSX.Element => {
    const [showAttemptModal, setShowAttemptModal] = useState(false);
    const [modalAttempt, setModalAttempt] = useState<AttemptRecord>();
    const [selectedAttempt, setSelectedAttempt] = useState<AttemptRecord | undefined>();

    const onAttemptChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const attemptId = NumberUtils.parseInt(e.target.value) ?? 0;
        const attemptToSelect = attempts?.find((a) => a.id === attemptId);
        setSelectedAttempt(attemptToSelect);
    };

    const attemptOptions = useMemo(() => {
        if (attempts == null || attempts.length === 0) {
            return [];
        }

        return attempts
            .filter((a) => a.id != null)
            .map((attempt) => {
                return {
                    text: attempt.attemptNumber?.toString() ?? "",
                    value: attempt.id!.toString(),
                };
            });
    }, [attempts]);

    const onEditAttemptClick = () => {
        setModalAttempt(selectedAttempt);
        setShowAttemptModal(true);
    };

    const onAddAttemptClick = () => {
        setModalAttempt(undefined);
        setShowAttemptModal(true);
    };

    useEffect(() => {
        if (attempts?.length != null && attempts.length > 0) {
            setSelectedAttempt(attempts[attempts?.length - 1]);
        }
    }, [attempts, attempts?.length]);

    return (
        <>
            <Card cssClassName={CSS_CLASS_NAME} tabIndex={tabIndex}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <div className={`${CSS_CLASS_NAME}__heading__details`}>
                        <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H5}>
                            {t("attempt")}
                        </Heading>
                        <div className="select-wrapper">
                            <Select
                                id="attemptSelect"
                                ariaLabelledBy={t("selectToViewAnAttempt")}
                                onChange={onAttemptChange}
                                options={attemptOptions}
                                value={selectedAttempt?.id?.toString() ?? ""}
                            />
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__heading__actions`}>
                        {onAttemptSave != null && selectedAttempt?.isManualEntry && (
                            <Button
                                onClick={onEditAttemptClick}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                text={t("editAttempt")}
                            />
                        )}
                        {onAttemptSave != null && (
                            <Button
                                onClick={onAddAttemptClick}
                                size={ButtonSize.Small}
                                style={ButtonStyle.Primary}
                                text={t("addAttempt")}
                            />
                        )}
                    </div>
                </div>
                {selectedAttempt != null && (
                    <AttemptDetails attempt={selectedAttempt} key={selectedAttempt.id} />
                )}

                {selectedAttempt == null && (
                    <EmptyText spacing={EmptyTextSpacing.Small}>
                        {t("noAttemptDataForTheSelectedAttempt")}
                    </EmptyText>
                )}
            </Card>

            <AttemptModal
                attemptToEdit={modalAttempt}
                open={showAttemptModal}
                setOpen={setShowAttemptModal}
                onAttemptSave={onAttemptSave}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentAttemptsDetails };

// #endregion Exports
