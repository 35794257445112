import React, { useMemo, useState } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { ButtonStyle } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { ModalAction } from "components/modal/modal";
import { ProductEnrollmentSummaryRecord } from "models/view-models/enrollments/product-enrollment-summary-record";
import { RoleType } from "models/enumerations/users/role-type";
import { Status } from "utilities/enumerations/statuses";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import {
    EnrollmentUnitCourseStatusDisplayNames,
    EnrollmentUnitCourseStatusMap,
} from "models/enumerations/enrollments/enrollment-unit-course-status";
import { WithdrawEnrollmentModal } from "components/enrollments/withdraw-enrollment-modal/withdraw-enrollment-modal";
import { t } from "utilities/localization/t";
import { Badge } from "components/badges/badge/badge";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { NumberUtils } from "utilities/number-utils";
import "./oll-enrollment-list.scss";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface OllEnrollmentListProps {
    enrollmentSummaries?: ProductEnrollmentSummaryRecord[];
    handleWithdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "oll-enrollment-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OllEnrollmentList: React.FC<OllEnrollmentListProps> = ({
    enrollmentSummaries,
    handleWithdrawEnrollment,
}): JSX.Element => {
    const [enrollmentToWithdraw, setEnrollmentToWithdraw] =
        useState<ProductEnrollmentSummaryRecord>(new ProductEnrollmentSummaryRecord());

    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);

    const { record: globalState } = useGlobalState();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            userRole === RoleType.AenAdministrator ||
            userRole === RoleType.ClientAdmin,
        [userRole]
    );
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                handleWithdrawEnrollment(enrollmentToWithdraw?.enrollment!);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const withdrawEnrollment = (e: ProductEnrollmentSummaryRecord) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    const handleDownloadCertificateClick = async (
        enrollmentId?: number,
        certificateId?: number
    ) => {
        if (enrollmentId == null || certificateId == null) {
            return;
        }

        const pathParams: DownloadCertificatePathParams = {
            enrollmentId: enrollmentId,
            id: certificateId,
        };

        const queryParams: DownloadCertificateQueryParams = {
            overwriteExisting: false,
        };

        await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
    };

    return (
        <>
            {CollectionUtils.hasValues(enrollmentSummaries) ? (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className="account">
                                {t("name")}{" "}
                                <Tooltip
                                    content={
                                        <>
                                            {t("enrollmentStatusWillAppearAsInvitePending")}{" "}
                                            <i>
                                                {t(
                                                    "noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining"
                                                )}
                                            </i>
                                        </>
                                    }
                                    placement={TooltipPlacement.Top}>
                                    <span>
                                        <Icon type={Icons.Information} />
                                    </span>
                                </Tooltip>
                            </th>
                            <th className="enrollment">{t("version")}</th>
                            <th className="enrollment">{t("ceus")}</th>
                            <th className="enrollment">{t("enrollment")}</th>
                            <th className="assessment">{t("syllabus")}</th>
                            <th className="evaluation">{t("evaluation")}</th>
                            <th className="action">
                                <span className="sr-only">{t("action")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {enrollmentSummaries !== undefined &&
                            enrollmentSummaries.map(
                                (enrollmentSummary): JSX.Element => (
                                    <tr key={enrollmentSummary.enrollmentId}>
                                        <td className="account">
                                            <span>
                                                <div className={`${CSS_CLASS_NAME}__account-name`}>
                                                    {StringUtils.hasValue(
                                                        enrollmentSummary.user?.firstName
                                                    )
                                                        ? enrollmentSummary.user?.getFirstAndLastName()
                                                        : "- -"}
                                                </div>
                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {enrollmentSummary.user?.email}
                                                </div>
                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {currentUserIsInNfpaRole
                                                        ? enrollmentSummary.user?.id
                                                        : ""}
                                                </div>
                                            </span>
                                        </td>
                                        <td className="version">
                                            {enrollmentSummary.productVersionNumber}
                                        </td>
                                        <td className="ceus">
                                            {NumberUtils.formatCEUsFromCreditHours(
                                                enrollmentSummary.creditHours
                                            )}
                                        </td>
                                        <td className="enrollment">
                                            {enrollmentSummary.enrollment?.withdrawnOn != null ? (
                                                <StatusBadge
                                                    status={Status.Error}
                                                    text="withdrawn"
                                                />
                                            ) : (
                                                <StatusBadge
                                                    status={Status.Success}
                                                    text="enrolled"
                                                />
                                            )}
                                        </td>
                                        <td className="syllabus">
                                            {enrollmentSummary.enrollment?.withdrawnOn == null && (
                                                <StatusBadge
                                                    status={
                                                        EnrollmentUnitCourseStatusMap[
                                                            enrollmentSummary.status!
                                                        ]
                                                    }
                                                    text={
                                                        EnrollmentUnitCourseStatusDisplayNames[
                                                            enrollmentSummary.status!
                                                        ]
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td className="evaluation">
                                            {enrollmentSummary.enrollment?.withdrawnOn == null && (
                                                <>
                                                    {enrollmentSummary.enrollment?.evaluation?.id ==
                                                        null && (
                                                        <StatusBadge
                                                            status={Status.Default}
                                                            text="notStarted"
                                                        />
                                                    )}
                                                    {enrollmentSummary.enrollment?.evaluation?.id !=
                                                        null && (
                                                        <StatusBadge
                                                            status={Status.Success}
                                                            text="submitted"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        <td className="action">
                                            {
                                                // if
                                                canModifyEnrollments && (
                                                    <ContextMenu>
                                                        <ContextMenuButton
                                                            displayName={t("withdraw")}
                                                            disabled={
                                                                enrollmentSummary.enrollment?.isComplete() ||
                                                                enrollmentSummary.enrollment
                                                                    ?.withdrawnOn != null
                                                            }
                                                            onClick={() =>
                                                                withdrawEnrollment(
                                                                    enrollmentSummary
                                                                )
                                                            }
                                                        />
                                                        <ContextMenuButton
                                                            displayName={t("downloadCertificate")}
                                                            disabled={
                                                                enrollmentSummary.enrollment
                                                                    ?.certificate == null
                                                            }
                                                            onClick={() =>
                                                                handleDownloadCertificateClick(
                                                                    enrollmentSummary.enrollment
                                                                        ?.id,
                                                                    enrollmentSummary.enrollment
                                                                        ?.certificate?.id
                                                                )
                                                            }
                                                        />
                                                    </ContextMenu>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </DataTable>
            ) : (
                <EmptyText table>{t("noLearnersEnrolled")}</EmptyText>
            )}
            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={enrollmentToWithdraw?.user?.getFirstAndLastNameOrEmail() ?? ""}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OllEnrollmentList };

// #endregion Exports
