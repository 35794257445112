import { EnrollmentUnitRecord } from "models/view-models/enrollments/enrollment-unit-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments/:enrollmentId/enrollmentUnits";
const resourceType: typeof EnrollmentUnitRecord = EnrollmentUnitRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListEnrollmentUnitsQueryParams {}

export interface ListEnrollmentUnitPathParams {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EnrollmentUnitService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        EnrollmentUnitRecord,
        ListEnrollmentUnitPathParams,
        ListEnrollmentUnitsQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentUnitService };

// #endregion Exports
