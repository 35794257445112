import { EnumUtils } from "utilities/enumerations/enum-utils";
import { InstructorApplicationProfileStatus } from "models/enumerations/aen-applications/instructor-application-profile-status";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { InstructorApplicationStatusBarCard } from "../instructor-application-status-card/instructor-application-status-bar-card";
import React from "react";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import "./instructor-application-status-bar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorApplicationStatusBarProps {
    userInstructorApplication?: InstructorApplicationRecord;
    isLoading?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "instructor-application-status-bar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorApplicationStatusBar: React.FC<InstructorApplicationStatusBarProps> = ({
    isLoading,
    userInstructorApplication,
}): JSX.Element => {
    const applicationStatus =
        userInstructorApplication?.getApplicationStatus() ??
        InstructorApplicationStatus.PendingAssessment;
    return (
        <>
            {!isLoading && (
                <div className={`${CSS_CLASS_NAME}`}>
                    {EnumUtils.enumToArray(InstructorApplicationProfileStatus).map(
                        (status: InstructorApplicationProfileStatus) => (
                            <InstructorApplicationStatusBarCard
                                key={status}
                                statusOfCard={status}
                                applicationStatus={applicationStatus}
                            />
                        )
                    )}
                </div>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationStatusBar };

// #endregion Exports
