import { Event } from "models/interfaces/events/event";
import { EventRecord } from "models/view-models/events/event-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type EventRecordUpdater = RecordUpdater<Event, EventRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: EventContext, useRecord: useEvent } = UseRecordContextFactory.build<
    Event,
    EventRecord
>(EventRecord);

export { EventContext, useEvent };

// #endregion Exports
