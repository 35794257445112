import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentUnitCourseStatus {
    NotStarted = 0,
    InProgress = 1,
    Complete = 2,
    Expired = 3,
    Attempted = 4,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EnrollmentUnitCourseStatusDisplayNames: Map<EnrollmentUnitCourseStatus, TranslatedCopy> = {
    [EnrollmentUnitCourseStatus.NotStarted]: "notStarted",
    [EnrollmentUnitCourseStatus.InProgress]: "inProgress",
    [EnrollmentUnitCourseStatus.Complete]: "complete",
    [EnrollmentUnitCourseStatus.Expired]: "expired",
    [EnrollmentUnitCourseStatus.Attempted]: "attempted",
};

const EnrollmentUnitCourseStatusMap: Map<EnrollmentUnitCourseStatus, Status> = {
    [EnrollmentUnitCourseStatus.NotStarted]: Status.Default,
    [EnrollmentUnitCourseStatus.InProgress]: Status.Warning,
    [EnrollmentUnitCourseStatus.Complete]: Status.Success,
    [EnrollmentUnitCourseStatus.Expired]: Status.Inverted,
    [EnrollmentUnitCourseStatus.Attempted]: Status.Warning,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export {
    EnrollmentUnitCourseStatus,
    EnrollmentUnitCourseStatusDisplayNames,
    EnrollmentUnitCourseStatusMap,
};

// #endregion Exports
