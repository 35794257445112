import React from "react";
import { StringUtils } from "andculturecode-javascript-core";
import "./card.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum CardStyle {
    Default = "",
    Empty = "-empty",
}

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CardProps {
    cssClassName?: string;
    flat?: boolean;
    stacked?: boolean;
    tabIndex?: number;
    tight?: boolean;
    style?: CardStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Card: React.FC<React.PropsWithChildren<CardProps>> = (
    props: React.PropsWithChildren<CardProps>
): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    if (props.stacked) {
        classNames.push("-stacked");
    }

    if (props.style) {
        classNames.push(props.style);
    }

    if (props.flat) {
        classNames.push("-flat");
    }

    if (props.tight) {
        classNames.push("-tight");
    }

    const className: string = classNames.join(" ");

    return (
        <div className={className} tabIndex={props.tabIndex}>
            {props.children}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Card };

// #endregion Exports
