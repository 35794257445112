import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AudienceTypeDisplayNames } from "models/enumerations/audiences/audience-type";
import { Badge, BadgeStyle } from "../../badges/badge/badge";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, PatchCourseServiceParams } from "utilities/services/courses/course-service";
import { DataTable } from "../../tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize, ParagraphStyle } from "../../typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import "./course-activation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseActivationModalProps {
    course: CourseRecord;
    courseContents?: CourseContentRecord[];
    open: boolean;
    scormPackage?: ScormPackageRecord;
    setCourse: React.Dispatch<React.SetStateAction<CourseRecord>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-activation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseActivationModal: React.FC<CourseActivationModalProps> = ({
    course,
    courseContents,
    open,
    scormPackage,
    setCourse,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const { activate: coursePatch } = CourseService.useActivate();

    //const { record: courseVersion } = useCourseVersion();
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("activateCourse"),
            onClick: () => handleActivatingCourse(),
            style: ButtonStyle.Primary,
        },
    ];

    const handleActivatingCourse = () => {
        setOpen(false);
        activateCourse(course.id!);

        const path = RouteUtils.replacePathParams(sitemap.admin.course.edit.details, {
            id: course.id,
        });
        navigate(path);
    };

    const activateCourse = async (id: number): Promise<boolean> => {
        if (!id) {
            return false;
        }

        try {
            const patchCourseServiceParams: PatchCourseServiceParams = {
                id: id,
            };

            const patchCourseResponse = await coursePatch(patchCourseServiceParams);

            const patchCourseResult = patchCourseResponse?.result;

            if (patchCourseResult?.resultObject == null || patchCourseResult.hasErrors()) {
                alert(t("activatingCourseFailed"));
            }
            setCourse(course.with({ status: ActiveStatus.Active, activatedOn: new Date() }));
            ToastManager.success(t("courseIsNowActive"));
        } catch {
            ToastManager.error(t("failedToActivateCourse"));
            return false;
        }
        return true;
    };

    const onModalClose = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("activationSummary")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                    {t(
                        "takeAMomentToReviewYourCourseBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney"
                    )}
                </Paragraph>
                <div className={`${CSS_CLASS_NAME}__details ${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__details__header`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("courseDetails")}
                        </Heading>
                        <Badge
                            style={BadgeStyle.Light}
                            text={t(AudienceTypeDisplayNames[course.audienceType])}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__content`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("courseName")}</Paragraph>
                            <Paragraph>{course.name}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("courseDescription")}
                            </Paragraph>
                            <Paragraph>{course.description}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__metadata`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                            <Paragraph>{t(TopicDisplayNames[course.topic!])}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("language")}</Paragraph>
                            <Paragraph>{t(LanguageDisplayNames[course.language!])}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("courseId")}</Paragraph>
                            <Paragraph>{course.id}</Paragraph>
                        </div>
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("zipPackage")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("name")}</th>
                                <th className="file">{t("fileName")}</th>
                                <th className="status">{t("importStatus")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{scormPackage?.name}</td>
                                <td className="file">{scormPackage?.file?.fileName}</td>
                                <td className="status">
                                    {ScormPackageImportStatus[scormPackage?.status!]}
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("content")}
                    </Heading>
                    {CollectionUtils.hasValues(courseContents) ? (
                        <DataTable>
                            <thead>
                                <tr>
                                    <th>{t("order")}</th>
                                    <th>{t("name")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("fileName")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courseContents &&
                                    courseContents.map((courseContent) => (
                                        <tr key={courseContent.id}>
                                            <td>{courseContent.sortOrder}</td>
                                            <td>{courseContent.content?.name}</td>
                                            <td>{courseContent.content?.description}</td>
                                            <td>{courseContent.content?.file?.fileName}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </DataTable>
                    ) : (
                        <EmptyText table>{t("noContentAdded")}</EmptyText>
                    )}
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseActivationModal };

// #endregion Exports
