import React from "react";
import "./paragraph.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export enum ParagraphSize {
    XxSmall = "-xxsmall",
    XSmall = "-xsmall",
    Small = "-small",
    Medium = "-medium",
    Default = "",
    Large = "-large",
    XLarge = "-xlarge",
}

export enum ParagraphStyle {
    Bold = "-bold",
    DarkLabel = "-dark-label",
    Default = "",
    Faded = "-faded",
    Inverted = "-inverted",
    Label = "-label",
    Light = "-light",
    SemiBold = "-semi-bold",
}

export enum ParagraphEmphasis {
    Bold = "-bold",
    Italic = "-italic",
    Default = "",
}

export interface ParagraphProps {
    ariaHidden?: boolean;
    cssClassName?: string;
    emphasis?: ParagraphEmphasis;
    size?: ParagraphSize;
    style?: ParagraphStyle;
    title?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "paragraph";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Paragraph: React.FC<React.PropsWithChildren<ParagraphProps>> = (props) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.size) {
        cssClassNames.push(props.size);
    }

    if (props.style) {
        cssClassNames.push(props.style);
    }

    if (props.emphasis) {
        cssClassNames.push(props.emphasis);
    }

    return (
        <p aria-hidden={props.ariaHidden} className={cssClassNames.join(" ")} title={props.title}>
            {props.children}
        </p>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Paragraph };

// #endregion Exports
