import { Product } from "models/interfaces/products/product";
import { ProductRecord } from "models/view-models/products/product-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type ProductRecordUpdater = RecordUpdater<Product, ProductRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: ProductContext, useRecord: useProduct } = UseRecordContextFactory.build<
    Product,
    ProductRecord
>(ProductRecord);

export { ProductContext, useProduct };

// #endregion Exports
