import { ForwardedRef, FunctionComponent, createElement, forwardRef, useMemo } from "react";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useWithForwardRef = <TProps extends {}, TRef>(Component: FunctionComponent<TProps>) =>
    useMemo(
        () =>
            forwardRef(
                (props: TProps, _: ForwardedRef<TRef>): JSX.Element =>
                    createElement<TProps>(Component, props)
            ),
        [Component]
    );

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useWithForwardRef };

// #endregion Exports
