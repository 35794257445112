import React, { useMemo, useEffect, useCallback } from "react";
import { LearnerAssessmentSummary } from "components/assessments/learner-assessment-summary/learner-assessment-summary";
import { NumberUtils } from "utilities/number-utils";
import { RouteUtils } from "utilities/route-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./learner-assessment-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "learner-assessment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentPage: React.FC<LearnerAssessmentPageProps> = validatePageAccess(
    AccessControlKeys.LearnerAssessmentPage
)((): JSX.Element => {
    const {
        id: eventIdParam,
        assessmentId: assessmentIdParam,
        registrationId: registrationIdParam,
    } = useParams();
    const assessmentId = useMemo(
        () => NumberUtils.parseInt(assessmentIdParam) ?? 0,
        [assessmentIdParam]
    );
    const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
    const registrationId = useMemo(
        () => NumberUtils.parseInt(registrationIdParam) ?? 0,
        [registrationIdParam]
    );

    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const assessmentResultsPath: string = useMemo(
        (): string =>
            RouteUtils.replacePathParams(viewEventRoutes.assessmentResults, {
                id: eventId,
                assessmentId: assessmentId,
            }),
        [assessmentId, eventId, viewEventRoutes.assessmentResults]
    );

    const canViewAssessmentDetails: boolean = useMemo(
        (): boolean => globalState?.currentIdentity?.isCurrentlyInNfpaRole() ?? false,
        [globalState?.currentIdentity]
    );

    const CheckUnauthorizedUser = useCallback(() => {
        if (!canViewAssessmentDetails) {
            navigate(assessmentResultsPath);
        }
    }, [navigate, assessmentResultsPath, canViewAssessmentDetails]);

    useEffect(() => {
        CheckUnauthorizedUser();
    }, [CheckUnauthorizedUser]);

    return (
        <div className={CSS_CLASS_NAME}>
            <LearnerAssessmentSummary
                assessmentId={assessmentId}
                eventId={eventId}
                registrationId={registrationId}
            />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentPage };

// #endregion Exports
