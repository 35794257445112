import { EndEmulationSessionButton } from "pages/admin/support/end-emulation-session-button";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";

const BASE_CLASS = "c-emulation-mode-banner";
const USER_CLASS = `${BASE_CLASS}__user`;
const EXPIRES_CLASS = `${BASE_CLASS}__expires`;
const EXPIRES_TEXT_CLASS = `${BASE_CLASS}__expires__text`;
const EXPIRES_TIME_CLASS = `${BASE_CLASS}__expires__time`;

const EmulationModeBanner = () => {
    const { isInEmulationMode, token, userEmail } = useEmulationMode();

    if (!isInEmulationMode) return null;

    const formattedExpiration = DateUtils.formatDateCustom(token?.expiresOn, "h:mm A");

    return (
        <div className={BASE_CLASS}>
            <div className={USER_CLASS}>
                <span>{t("youAreEmulating")} </span>
                <span className={`${USER_CLASS}__email`}>
                    <strong>{userEmail}</strong>
                </span>
            </div>
            <div className={EXPIRES_CLASS}>
                <span className={EXPIRES_TEXT_CLASS}>{t("sessionExpiresAt")} </span>
                <span className={EXPIRES_TIME_CLASS}>
                    <strong>{formattedExpiration}</strong>
                </span>
                <EndEmulationSessionButton />
            </div>
        </div>
    );
};

export { EmulationModeBanner };
