import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { Card } from "components/card/card";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EditAssessmentProductVersionMaterials } from "components/products/product-version/edit-assessment-product-version-materials/edit-assessment-product-version-materials";
import {
    GetProductVersionPathParams,
    GetProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProductMaterialsChangesModal } from "components/products/product-version/product-materials-changes-modal/product-materials-changes-modal";
import { ProductVersionEditModal } from "components/products/product-version/product-version-edit-modal/product-version-edit-modal";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { useProductVersionArchive } from "utilities/hooks/models/products/use-product-version-archives";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./edit-assessment-product-materials-page.scss";
import { TrainingType } from "models/enumerations/courses/training-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditAssessmentProductMaterialsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-assessment-product-materials-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditAssessmentProductMaterialsPage: React.FC<EditAssessmentProductMaterialsPageProps> =
    validatePageAccess(AccessControlKeys.EditAssessmentProductMaterialsPage)((): JSX.Element => {
        const navigate = useNavigate();
        const { record: product } = useProduct();
        const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
        const [editMode, setEditMode] = useState(false);
        const [showEditProductVersionModal, setShowEditProductVersionModal] =
            useState<boolean>(false);
        const [showCancelEditVersionModal, setShowCancelEditVersionModal] =
            useState<boolean>(false);
        const [showSaveChangesModal, setShowSaveChangesModal] = useState<boolean>(false);

        const { id, versionId } = useParams();
        const productId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
        const productVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
        const { get: getProductVersion } = ProductVersionService.useGet();

        useRedirectOnForbidden(sitemap.admin.product.list);
        const [dirty, setDirty] = useState<boolean>(false);
        const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
        const showCancelEditOnNavigationModal = useMemo(
            (): boolean => navigationRequest != null,
            [navigationRequest]
        );
        const navigationRequests = useListenForNavigationRequests(
            EditAssessmentProductMaterialsPage.name,
            [Breadcrumbs.name, ProductLayout.name]
        );

        const resolveNavigationRequest = useResolveNavigationRequest();
        useEffect(() => {
            if (CollectionUtils.isEmpty(navigationRequests)) {
                return;
            }

            const navigationRequest = navigationRequests[0];

            if (!editMode || !dirty) {
                resolveNavigationRequest(
                    EditAssessmentProductMaterialsPage.name,
                    navigationRequest.requestingComponent
                );
                navigationRequest.onNavigationApproved();
                return;
            }
            setNavigationRequest(navigationRequest);
        }, [editMode, dirty, navigationRequests, resolveNavigationRequest]);

        const handleNavigationApproved = useCallback((): void => {
            setNavigationRequest(undefined);

            if (navigationRequest?.onNavigationApproved != null) {
                resolveNavigationRequest(
                    EditAssessmentProductMaterialsPage.name,
                    navigationRequest.requestingComponent
                );
                navigationRequest.onNavigationApproved();
            }
        }, [navigationRequest, resolveNavigationRequest]);

        const handleNavigationDenied = useCallback((): void => {
            setNavigationRequest(undefined);

            if (navigationRequest == null) {
                return;
            }

            resolveNavigationRequest(
                EditAssessmentProductMaterialsPage.name,
                navigationRequest.requestingComponent
            );
        }, [navigationRequest, resolveNavigationRequest]);

        const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
            {
                buttonText: t("noKeepEditing"),
                onClick: handleNavigationDenied,
                style: ButtonStyle.Secondary,
            },
            {
                buttonText: t("yesLeave"),
                onClick: handleNavigationApproved,
                style: ButtonStyle.Destructive,
            },
        ];

        const onCancelChangesModalClose = async () => {
            setShowCancelEditVersionModal(false);
            setDirty(false);
            //return the productVersion to its original state
            //get the edit version from the api

            fetchProductVersion();
            setEditMode(false);
        };

        const fetchProductVersion = async (): Promise<void> => {
            try {
                const pathParams: GetProductVersionPathParams = {
                    id: productVersionId,
                };
                const queryParams: GetProductVersionQueryParams = {
                    includeProductScormPackage: true,
                    includeUnit: false,
                    includeContent: true,
                    includeEvaluationTemplate: true,
                };

                const getResponse = await getProductVersion(pathParams, queryParams);
                const getResult = getResponse?.result;
                const editProductVersion = getResult?.resultObject;
                editProductVersion.sortAllChildren();
                setProductVersion(editProductVersion);
            } catch (error) {}
        };

        const { productVersionArchive, setRefresh } = useProductVersionArchive({
            productId: product.id!,
            productVersionId: productVersionId,
            includeCreatedBy: true,
        });

        const showCancelEditModal = () => {
            if (dirty && editMode) {
                setShowCancelEditVersionModal(true);
            } else {
                navigate(
                    RouteUtils.localizePath(
                        RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                            id: productId,
                        })
                    )
                );
            }
        };

        const isProductEditInComplete = () => {
            if (product.type == null) return false;
            if (product.type === TrainingType.InstructorLedTraining) {
                return (
                    !productVersion.assessmentIsComplete() ||
                    !productVersion.contentIsComplete() ||
                    !productVersion.achievementIsComplete(product.audienceType) ||
                    !product.evaluationTemplateIsComplete()
                );
            }
            if (product.type === TrainingType.InstructorAssessment) {
                return (
                    !productVersion.assessmentIsComplete() || !productVersion.contentIsComplete()
                );
            }
        };
        return (
            <>
                {product.status === ActiveStatus.Archived && (
                    <Banner
                        cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                        style={BannerStyle.Dark}>
                        {t("youAreViewingAnArchivedProduct")}
                    </Banner>
                )}
                <div className={CSS_CLASS_NAME}>
                    <ButtonIcon
                        cssClassName={`${CSS_CLASS_NAME}__back-button`}
                        iconType={Icons.ChevronLeft}
                        text={t("backToAllVersions")}
                        buttonStyle={ButtonStyle.Secondary}
                        buttonSize={ButtonSize.Small}
                        onClick={showCancelEditModal}
                    />
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        {!editMode && (
                            <HeaderActions
                                headingPriority={HeadingPriority.H5}
                                headingSize={HeadingSize.Small}
                                title={t("versionVersionNumber", {
                                    versionNumber: productVersion?.versionId,
                                })}>
                                <Button
                                    disabled={
                                        product.status === ActiveStatus.Archived ||
                                        product.status === ActiveStatus.Inactive
                                    }
                                    onClick={() => setShowEditProductVersionModal(true)}
                                    style={ButtonStyle.Primary}
                                    text={t("editVersion")}
                                />
                            </HeaderActions>
                        )}
                        {editMode && (
                            <HeaderActions
                                headingPriority={HeadingPriority.H5}
                                headingSize={HeadingSize.Small}
                                title={t("versionVersionNumber", {
                                    versionNumber: productVersion?.versionId,
                                })}>
                                <Button
                                    onClick={() => setShowCancelEditVersionModal(true)}
                                    style={ButtonStyle.Secondary}
                                    text={t("cancelEdit")}
                                />
                                <ButtonIcon
                                    buttonStyle={ButtonStyle.Primary}
                                    disabled={isProductEditInComplete()}
                                    iconType={Icons.PublishWithChanges}
                                    onClick={() => setShowSaveChangesModal(true)}
                                    text={t("saveChanges")}
                                />
                            </HeaderActions>
                        )}
                        {productVersion.versionId !== 0 && productVersion.activatedOn === null && (
                            <div className={`${CSS_CLASS_NAME}__banner`}>
                                <NotificationBanner
                                    notificationId={0}
                                    onClose={() => {}}
                                    style={NotificationType.Default}
                                    message={t(
                                        "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion"
                                    )}
                                />
                            </div>
                        )}
                        <Card cssClassName={`${CSS_CLASS_NAME}__card`}>
                            <div>
                                <Paragraph
                                    size={ParagraphSize.XSmall}
                                    style={ParagraphStyle.DarkLabel}>
                                    {t("lastChanged")}
                                </Paragraph>
                                <Paragraph size={ParagraphSize.XSmall}>
                                    {productVersion.getLastUpdatedDateAndTime()}
                                </Paragraph>
                            </div>
                            <div>
                                <Paragraph
                                    size={ParagraphSize.XSmall}
                                    style={ParagraphStyle.DarkLabel}>
                                    {t("changedBy")}
                                </Paragraph>
                                <div>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {productVersion.getLastUpdatedBy()}
                                    </Paragraph>
                                    <Paragraph
                                        cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                        size={ParagraphSize.XxSmall}
                                        style={ParagraphStyle.Light}>
                                        {productVersion.updatedById}
                                    </Paragraph>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <EditAssessmentProductVersionMaterials
                        productVersionArchiveData={productVersionArchive}
                        editMode={editMode}
                        setDirty={setDirty}
                    />
                    <ProductVersionEditModal
                        open={showEditProductVersionModal}
                        setOpen={setShowEditProductVersionModal}
                        setEditMode={setEditMode}
                    />
                    <CancelChangesModal
                        open={showCancelEditVersionModal}
                        setOpen={setShowCancelEditVersionModal}
                        onModalClose={onCancelChangesModalClose}
                    />
                    <ProductMaterialsChangesModal
                        fetchProductVersion={fetchProductVersion}
                        setEditMode={setEditMode}
                        setRefresh={setRefresh}
                        setShowSaveMaterialChangesModal={setShowSaveChangesModal}
                        showSaveMaterialChangesModal={showSaveChangesModal}
                    />
                    <Modal
                        cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                        isOpen={showCancelEditOnNavigationModal}
                        onModalClose={() => {}}
                        actions={cancelEditModeOnNavigationConfirmationActions}
                        modalStyle={"-inverted"}>
                        {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
                    </Modal>
                </div>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditAssessmentProductMaterialsPage };

// #endregion Exports
