import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService } from "utilities/services/courses/course-service";
import { Language } from "models/enumerations/languages/language";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { Topic } from "models/enumerations/courses/topic";
import { t } from "utilities/localization/t";
import { useEffect, useState } from "react";

interface UseCoursesHook {
    courses: CourseRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface UseCoursesHookOptions {
    activeStatus?: ActiveStatus;
    audienceType?: AudienceType;
    applySearch?: boolean;
    includeArchived?: boolean;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
    includeLatestVersion?: boolean;
    includeScormPackage?: boolean;
    language?: Language;
    searchText?: string;
    skip?: number;
    take?: number;
    topic?: Topic;
}

export function useCourses(props: UseCoursesHookOptions): UseCoursesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState<CourseRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listCourses } = CourseService.useList();
    const activeStatus = props.activeStatus;
    const audienceType = props.audienceType;
    const applySearch = props.applySearch;
    const includeArchived = props.includeArchived;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLastModifiedBy = props.includeLastModifiedBy;
    const includeScormPackage = props.includeScormPackage;
    const includeLatestVersion = props.includeLatestVersion;
    const language = props.language;
    const searchText = props.searchText;
    const skip = props.skip;
    const take = props.take;
    const topic = props.topic;

    useEffect(() => {
        try {
            (async function getCourses() {
                const { result, resultObjects, rowCount } = await listCourses({
                    activeStatus,
                    audienceType,
                    applySearch,
                    includeArchived,
                    includeCreatedBy,
                    includeLastModifiedBy,
                    includeScormPackage,
                    includeLatestVersion,
                    language,
                    searchText,
                    skip,
                    take,
                    topic,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setCourses(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningCourses"));
        }
    }, [
        applySearch,
        searchText,
        take,
        includeArchived,
        listCourses,
        audienceType,
        includeCreatedBy,
        includeLastModifiedBy,
        includeScormPackage,
        language,
        skip,
        activeStatus,
        includeLatestVersion,
        topic,
    ]);

    return {
        errors,
        isLoading,
        courses,
        rowCount,
    };
}
