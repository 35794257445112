import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum CatalogEnrollmentErrorType {
    Unauthorized = 0,
    InvalidData = 1,
    ProductNotfound = 2,
    AlreadyEnrolled = 3,
    Unknown = 4,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const CatalogEnrollmentErrorTypeDisplayNames: Map<CatalogEnrollmentErrorType, TranslatedCopy> = {
    [CatalogEnrollmentErrorType.Unauthorized]: "unauthorized",
    [CatalogEnrollmentErrorType.InvalidData]: "invalidData",
    [CatalogEnrollmentErrorType.ProductNotfound]: "productNotFound",
    [CatalogEnrollmentErrorType.AlreadyEnrolled]: "alreadyEnrolled",
    [CatalogEnrollmentErrorType.Unknown]: "unknown",
};

// #endregion Maps

export { CatalogEnrollmentErrorType, CatalogEnrollmentErrorTypeDisplayNames };

// #endregion Exports
