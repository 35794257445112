import React from "react";
import "./form-error-message.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormErrorMessageProps {
    errorMessage?: string;
    errorMessages?: string[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-error-message";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormErrorMessage: React.FC<FormErrorMessageProps> = (props: FormErrorMessageProps) => {
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            {// if
            props.errorMessage != null && props.errorMessage.length > 0 && (
                <label>{props.errorMessage}</label>
            )}
            {// if
            props.errorMessages != null &&
                props.errorMessages.map((s: string) => <label key={s}>{s}</label>)}
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormErrorMessage };
