import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./changes-banner.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ChangesBannerProps {
    changed: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------\

const CSS_CLASS_NAME = "changes-banner";
const CHANGES_MADE: TranslatedCopy = "changesWereMadeToThisSection";
const NO_CHANGES_MADE: TranslatedCopy = "noChangesWereMadeToThisSection";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ChangesBanner: React.FC<ChangesBannerProps> = ({ changed }) => {
    const key: TranslatedCopy = changed ? CHANGES_MADE : NO_CHANGES_MADE;

    return (
        <Banner
            cssClassName={CSS_CLASS_NAME}
            style={BannerStyle.Light}
            format={BannerFormat.Center}>
            {t(key)}
        </Banner>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ChangesBanner;

// #endregion Exports
