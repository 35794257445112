import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { EditCourseDetailsPage } from "pages/admin/courses/edit-course/edit-course-details-page/edit-course-details-page";
import { EditCourseLayout } from "layouts/admin/edit-course-layout/edit-course-layout";
import { EditCourseMaterialsPage } from "pages/admin/courses/edit-course/edit-course-materials-page/edit-course-materials-page";
import { EditCourseVersionListPage } from "pages/admin/courses/edit-course/edit-course-version-list-page/edit-course-version-list-page";
import { Process } from "utilities/types/processes/process";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "andculturecode-javascript-core";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum EditCourseProcessSteps {
    Details,
    Materials,
}

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildEditCourseProcess = (
    courseId: number,
    course: CourseRecord,
    courseVersionId: number,
    courseVersion: CourseVersionRecord
): Process<typeof EditCourseProcessSteps> => {
    return {
        Details: {
            displayName: "courseDetails",
            isComplete: () => course.detailsAreComplete() && courseVersion != null,
            path: RouteUtils.replacePathParams(sitemap.admin.course.edit.details, {
                id: courseId,
                versionId: courseVersionId,
            }),
            requestNavigationConfig: {
                requestingComponents: EditCourseLayout.name,
                componentsAllowedToResolve: [
                    EditCourseVersionListPage.name,
                    EditCourseMaterialsPage.name,
                ],
            },
        },
        Materials: {
            displayName: `courseMaterials`,
            isComplete: () =>
                StringUtils.hasValue(courseVersion.scormPackage?.name) &&
                courseVersion.scormPackage?.fileId != null &&
                courseVersion.scormPackage.status === ScormPackageImportStatus.Complete &&
                courseVersion.contentIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.course.edit.versionList, {
                id: courseId,
                versionId: courseVersionId,
            }),
            requestNavigationConfig: {
                requestingComponents: EditCourseLayout.name,
                componentsAllowedToResolve: [
                    EditCourseDetailsPage.name,
                    EditCourseVersionListPage.name,
                ],
            },
        },
    };
};

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useEditCourseProcess = UseProcessFactory.build(buildEditCourseProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useEditCourseProcess, EditCourseProcessSteps };

// #endregion Exports
