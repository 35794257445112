import React from "react";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Modal } from "components/modal/modal";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { StringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import "./achievement-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AchievementModalProps {
    productName: string;
    showAchievementModal: boolean;
    setShowAchievementModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCertificateClick?: () => void;
    badgeUrl?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "achievement-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AchievementModal: React.FC<AchievementModalProps> = ({
    productName,
    showAchievementModal,
    setShowAchievementModal,
    badgeUrl,
    onCertificateClick,
}): JSX.Element => {
    return (
        <Modal
            isOpen={showAchievementModal}
            onModalClose={() => setShowAchievementModal(false)}
            title={t("trainingAchievements")}
            modalStyle={""}
        >
            <div className={CSS_CLASS_NAME}>
                <Heading priority={HeadingPriority.H2}>{productName}</Heading>
                <div className={`${CSS_CLASS_NAME}__cards`}>
                    {onCertificateClick != null && (
                        <Card cssClassName={`${CSS_CLASS_NAME}__modal`}>
                            <div className={`${CSS_CLASS_NAME}__modal-icon`}>
                                <Icon type={Icons.Certificate} size={IconSizes.XLarge} />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__modal-description`}>
                                <Heading priority={HeadingPriority.H3}>{t("certificate")}</Heading>
                                <Paragraph style={ParagraphStyle.Light}>
                                    {t("yourCertificateOfCompletionWith")}{" "}
                                    <a href="http://www.IACET.org" target="_blank">
                                        IACET
                                    </a>{" "}
                                    {t(
                                        "ceusWillBeAvailableForDownloadOnceYouHaveCompletedAllRequiredElementsOfTheTraining"
                                    )}
                                </Paragraph>
                                {onCertificateClick && (
                                    <ButtonIcon
                                        buttonType={ButtonType.Default}
                                        iconType={Icons.Download}
                                        onClick={onCertificateClick}
                                        text={t("downloadCertificate")}
                                    />
                                )}
                            </div>
                        </Card>
                    )}
                    {StringUtils.hasValue(badgeUrl) && (
                        <Card cssClassName={`${CSS_CLASS_NAME}__modal`}>
                            <div className={`${CSS_CLASS_NAME}__modal-icon`}>
                                <Icon type={Icons.Badge} size={IconSizes.XLarge} />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__modal-description`}>
                                <Heading priority={HeadingPriority.H3}>{t("badge")}</Heading>
                                <Paragraph style={ParagraphStyle.Light}>
                                    {t(
                                        "onceYouHaveCompletedAllRequiredElementsOfTheTrainingYouWillReceiveAnEmailFromCredlyInvitingYouToClaimYourDigitalBadgeTheBadgeWillAllowYouToEasilyShareYourAchievementWithColleaguesManagersAndYourProfessionalNetworkOnLinkedInAndOtherPlatforms"
                                    )}
                                </Paragraph>
                                <ButtonIcon
                                    buttonType={ButtonType.Link}
                                    iconType={Icons.Download}
                                    linkPath={badgeUrl}
                                    linkPathType={AnchorPathType.External}
                                    text={t("goToCredly")}
                                />
                            </div>
                        </Card>
                    )}
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AchievementModal };

// #endregion Exports
