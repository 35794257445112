import { useCallback, useEffect, useState } from "react";
import { EventProductCardSelectionList } from "./event-product-card-selection-list/event-product-card-selection-list";
import { ProductRecord } from "models/view-models/products/product-record";
import { SelectableProduct } from "models/interfaces/products/selectable-product";
import {
    ListProductsQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { NumberUtils } from "utilities/number-utils";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { EventType } from "models/enumerations/events/event-type";
import "./event-product-card-selection-manager.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductCardSelectionManagerProps {
    event: EventActiveRecord;
    onDeselectProduct: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-product-card-selection-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductCardSelectionManager: React.FC<EventProductCardSelectionManagerProps> = (
    props: EventProductCardSelectionManagerProps
) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [selectableProducts, setSelectableProducts] = useState<SelectableProduct[]>([]);
    const { list: listProducts } = ProductService.useList();

    const searchText = urlParams.get("searchText");

    // Default to -1 if the language is not provided urlParms
    const languageParam = urlParams.get("language") ?? "";
    const language = NumberUtils.parseInt(languageParam) ?? -1;

    // Default to -1 if the topic is not provided urlParms
    const topicParam = urlParams.get("topic") ?? "";
    const topic = NumberUtils.parseInt(topicParam) ?? -1;

    const convertProductToSelectableProduct = useCallback(
        (products: ProductRecord[]): SelectableProduct[] => {
            if (props.event.productVersion == null) {
                return products.map(
                    (p): SelectableProduct => ({
                        product: p,
                        isSelected: props.event.product?.id === p.id,
                    })
                );
            } else {
                const creditHours = props.event.productVersion.creditHours;
                return products.map(
                    (p): SelectableProduct => ({
                        product: p.with({ creditHours: creditHours }),
                        isSelected: props.event.product?.id === p.id,
                    })
                );
            }
        },
        [props.event.product?.id, props.event.productVersion]
    );

    const handleSelectProduct = useCallback(
        (product: ProductRecord): void => {
            if (product.id == null) {
                ToastManager.error(t("thereWasAnIssueSavingTheProductSelection"));
                return;
            }

            props.event.patchEventProduct(product.id);
        },
        [props.event]
    );

    const fetchProducts = useCallback(async () => {
        const listProductsQueryParams: ListProductsQueryParams = {
            applySearch: true,
            language: language >= 0 ? language : undefined,
            activeStatusFilter: [ActiveStatus.Active],
            searchText: searchText ?? "",
            topic: topic >= 0 ? topic : undefined,
            trainingType: TrainingType.InstructorLedTraining,
            includeLatestVersion: true,
        };

        if (props.event.type === EventType.EducationNetwork) {
            listProductsQueryParams.includeAvailableForAENOnly = true;
        }

        try {
            const listFilteredProductsResponse = await listProducts(listProductsQueryParams);
            const listFilteredProductsResults = listFilteredProductsResponse?.results;
            const products = listFilteredProductsResponse?.resultObjects;
            if (
                products == null ||
                listFilteredProductsResults == null ||
                listFilteredProductsResults.hasErrors()
            ) {
                throw new Error();
            }

            products.sort((a, b) => a.name.localeCompare(b.name));
            const selectableProducts = convertProductToSelectableProduct(products);
            setSelectableProducts(selectableProducts);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingProducts"));
            setSelectableProducts([]);
        }
    }, [
        convertProductToSelectableProduct,
        language,
        listProducts,
        props.event.type,
        searchText,
        topic,
    ]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <div className={CSS_CLASS_NAME}>
            <EventProductCardSelectionList
                disabled={props.event.status === PublishStatus.Live}
                selectableProducts={selectableProducts}
                handleAdd={handleSelectProduct}
                handleRemove={props.onDeselectProduct}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductCardSelectionManager };

// #endregion Exports
