import { CourseDetailsArchive } from "models/interfaces/courses/course-details-archive";
import { DateUtils } from "utilities/date-utils";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: CourseDetailsArchive = {
    changeLog: undefined,
    courseId: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CourseDetailsArchiveRecord extends Record(defaultValues) implements CourseDetailsArchive {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<CourseDetailsArchive>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the created date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getCreatedDateAndTime(): string {
        const message = DateUtils.formatLastEditedDateTime(this, true, true);

        return message;
    }

    /**
     * Convenience method to the return user
     * Ex: Steve Smith
     */
    public getCreatedBy(): string {
        const createdBy = this.updatedBy ?? this.createdBy;

        if (createdBy == null) {
            return "N/A";
        }

        return createdBy.getFirstAndLastName();
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDetailsArchiveRecord };

// #endregion Exports
