import React, { useMemo } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { ScheduleExceptionGrantedBanner } from "components/events/event-schedule/event-schedule-exception/schedule-exception-granted-banner/schedule-exception-granted-banner";
import { sitemap } from "sitemap";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import "./event-schedule-page-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventSchedulePageHeaderProps {
    eventId: number;
    exceptionGranted: boolean;
    productSelected: boolean;
    productCEUs: number;
    maxCEUs: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-page-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventSchedulePageHeader: React.FC<EventSchedulePageHeaderProps> = ({
    eventId,
    exceptionGranted,
    productSelected,
    productCEUs,
    maxCEUs,
}): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const isAEN = globalState?.currentIdentity?.role?.roleType === RoleType.AenAdministrator;
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const pathTemplate = currentUserIsInNfpaRole
        ? sitemap.admin.event.edit.details
        : sitemap.admin.event.edit.product;
    const path = RouteUtils.replacePathParams(pathTemplate, { id: eventId });

    const toolTipContent = t("languageBarrierIsAnExampleWhereTrainingMayExtend");

    const creditHoursBanner = useMemo(() => {
        if (productSelected) {
            return (
                <span className={`${CSS_CLASS_NAME}__credit-hours-message`}>
                    {t(
                        "theRecommendedCEUsForThisEventIsProductCEUsHoursAndShouldNotExceedMaxCEUs",
                        { productCEUs: productCEUs, maxCEUs: maxCEUs }
                    )}
                </span>
            );
        } else {
            return (
                <span className={`${CSS_CLASS_NAME}__credit-hours-message`}>
                    {t("please")}&nbsp;
                    <Anchor path={path} text="select a product" />
                    &nbsp;
                    {t("beforeBuildingYourSchedule")}&nbsp;
                </span>
            );
        }
    }, [maxCEUs, path, productCEUs, productSelected]);
    return (
        <>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("schedule")}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__class-time`}>
                    <span>{creditHoursBanner}</span>
                    <Tooltip content={toolTipContent} placement={TooltipPlacement.Bottom}>
                        <span className={`${CSS_CLASS_NAME}__class-time__icon-wrapper`}>
                            <Icon
                                type={Icons.Information}
                                cssClassName={`${CSS_CLASS_NAME}__class-time__tooltip`}
                            />
                        </span>
                    </Tooltip>
                </div>
            </div>
            {isAEN && exceptionGranted && <ScheduleExceptionGrantedBanner maxCEUs={maxCEUs} />}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventSchedulePageHeader };

// #endregion Exports
