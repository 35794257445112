import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EventChangeLogRecord } from "models/view-models/events/event-change-log-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { EventChangeLogService } from "utilities/services/events/event-change-log-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseEventChangeLogsHook {
    eventChangeLogs: EventChangeLogRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseEventChangeLogsHookOptions {
    eventId?: number;
}

export function useEventChangeLogs(props: UseEventChangeLogsHookOptions): UseEventChangeLogsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [eventChangeLogs, setEventChangeLogs] = useState<EventChangeLogRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listEventChangeLogs } = EventChangeLogService.useList();
    const eventId = props.eventId ?? 0;

    useEffect(() => {
        if (eventId == null || eventId < 1) {
            return;
        }

        try {
            (async function getEventChangeLogs() {
                const { result, resultObjects } = await listEventChangeLogs({
                    eventId,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setEventChangeLogs(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEventChangeLogs"));
        }
    }, [eventId, listEventChangeLogs]);

    return {
        errors,
        isLoading,
        eventChangeLogs,
    };
}
