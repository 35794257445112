import { InstructorTravelType } from "models/interfaces/instructors/instructor-travel-type";
import { Record } from "immutable";
import { RecordUtils } from "utilities/record-utils";
import { TravelTypeDisplayNames } from "models/instructors/travel-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorTravelType =
    RecordUtils.auditableDefaultValuesFactory<InstructorTravelType>({
        travelType: 0,
        instructorProfileId: 0,
    });

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorTravelTypeRecord extends Record(defaultValues) implements InstructorTravelType {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorTravelType>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the human readable travel type.
     */
    public getTravelTypeDisplayName(): string {
        if (this == null) {
            return "";
        }
        return t(TravelTypeDisplayNames[this.travelType]);
    }

    public with(values: Partial<InstructorTravelType>): InstructorTravelTypeRecord {
        return new InstructorTravelTypeRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorTravelTypeRecord };

// #endregion Exports
