import {
    AenApplicationActivityLogPathParams,
    AenApplicationActivityLogService,
    AenApplicationTypePath,
    CreateActivityLogDto,
} from "utilities/services/aen-applications/aen-application-activity-log-service";
import { AenApplicationActivityLogRecord } from "models/view-models/aen-applications/aen-application-activity-log-record";
import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { InstructorApplicationRecord } from "models/view-models/aen-applications/instructor-application-record";
import { ProviderApplicationRecord } from "models/view-models/aen-applications/provider-application-record";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { StringUtils } from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

interface UseAenApplicationActivityLogHook {
    errors?: ResultErrorRecord[];
    logs: AenApplicationActivityLogRecord[];
    addActivityLog: (log: AenApplicationActivityLogRecord) => Promise<boolean>;
    isLoading: boolean;
}

interface UseAenApplicationActivityLogHookOptions {
    application: ProviderApplicationRecord | InstructorApplicationRecord;
    type: AenApplicationType;
}

export function useAenApplicationActivityLog(
    props: UseAenApplicationActivityLogHookOptions
): UseAenApplicationActivityLogHook {
    const { record: globalState } = useGlobalState();
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState<AenApplicationActivityLogRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listApplicationActivityLog } = AenApplicationActivityLogService.useList();
    const { create: createActivityLog } = AenApplicationActivityLogService.useCreate();

    const { application, type } = props;
    const applicationId = application.id ?? -1;

    const addActivityLog = useCallback(
        async (log: AenApplicationActivityLogRecord) => {
            try {
                if (applicationId < 1 || !StringUtils.hasValue(log.notes)) {
                    return false;
                }
                const createActivityLogDto: CreateActivityLogDto = {
                    notes: log.notes,
                };

                const { result } = await createActivityLog(
                    applicationId,
                    type,
                    createActivityLogDto
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return false;
                }

                const logWithCreator = result?.resultObject.with({
                    createdBy: globalState.currentIdentity?.user,
                });

                setLogs([logWithCreator, ...logs]);

                ToastManager.success(t("noteHasBeenAddedSuccessfully"));

                return true;
            } catch {
                ToastManager.error(t("thereWasAnIssueSavingTheNotes"));
                return false;
            }
        },

        [application, logs]
    );

    const fetchData = useCallback(async () => {
        if (isNaN(applicationId) || applicationId < 1) {
            return;
        }

        try {
            (async function getApplicationActivityLog() {
                const listApplicationActivityLogPathParams: AenApplicationActivityLogPathParams = {
                    applicationId: applicationId,
                    type: AenApplicationTypePath[type],
                };

                const { result, resultObjects } = await listApplicationActivityLog(
                    listApplicationActivityLogPathParams
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setLogs(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAnIssueReturningActivityLogs"));
        }
    }, [application, listApplicationActivityLog]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        addActivityLog,
        logs,
        isLoading,
    };
}
