import { CourseDetailsArchiveRecord } from "models/view-models/courses/course-details-archive-record";
import { CourseDetailsArchiveService } from "utilities/services/courses/course-details-archive-service";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseCourseDetailsArchiveHook {
    courseDetailsArchive: CourseDetailsArchiveRecord[];
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseCourseDetailsArchiveHookOptions {
    courseId?: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
}

export function useCourseDetailsArchive(
    props: UseCourseDetailsArchiveHookOptions
): UseCourseDetailsArchiveHook {
    const [isLoading, setIsLoading] = useState(true);
    const [courseDetailsArchive, setCourseDetailsArchive] = useState<CourseDetailsArchiveRecord[]>(
        []
    );
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [refresh, setRefresh] = useState(false);
    const { list: listCourseDetailsArchive } = CourseDetailsArchiveService.useList();
    const courseId = props.courseId;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLastModifiedBy = props.includeLastModifiedBy;

    const fetchData = useCallback(async () => {
        try {
            (async function getCourseDetailsArchive() {
                if (courseId === undefined) return;
                const { result, resultObjects } = await listCourseDetailsArchive({
                    courseId,
                    includeCreatedBy,
                    includeLastModifiedBy,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setCourseDetailsArchive(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningCourseDetailsArchive"));
        }
    }, [courseId, includeCreatedBy, includeLastModifiedBy, listCourseDetailsArchive]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        courseDetailsArchive,
        setRefresh,
    };
}
