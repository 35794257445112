import { ProductScormPackage } from "models/interfaces/products/product-scorm-package";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { ScormPackageRecord } from "../scorm-packages/scorm-package-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ProductScormPackage = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    optional: false,
    product: undefined,
    productId: 0,
    productVersionId: 0,
    scormPackage: undefined,
    scormPackageId: 0,
    sortOrder: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProductScormPackageRecord extends Record(defaultValues) implements ProductScormPackage {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ProductScormPackage>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.scormPackage != null) {
            params.scormPackage = RecordUtils.ensureRecord(params.scormPackage, ScormPackageRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if the ScormPackage on this record is complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public scormPackageIsComplete(): boolean {
        if (this.id == null) {
            return false;
        }

        return this.scormPackage != null && this.scormPackage.isComplete(this.id);
    }

    public with(values: Partial<ProductScormPackage>): ProductScormPackageRecord {
        return new ProductScormPackageRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductScormPackageRecord };

// #endregion Exports
