import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Event } from "models/interfaces/events/event";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventRecord } from "models/view-models/events/event-record";
import {
    CancelEventPatchParams,
    EventInvoicePatchParams,
    EventOrganizationPatchParams,
    EventProductPatchParams,
    EventProviderPatchParams,
    EventService,
    EventTypePatchParams,
    GetEventPathParams,
    GetEventQueryParams,
    PatchEventPathParams,
    UpdateEventPathParams,
} from "utilities/services/events/event-service";
import { ServiceResponse } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { StringUtils } from "utilities/string-utils";
import { RecordUtils } from "utilities/record-utils";
import { EventType } from "models/enumerations/events/event-type";
import { ObjectUtils } from "utilities/object-utils";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import {
    ContractService,
    UpdateContractPathParams,
} from "utilities/services/contracts/contract-service";
import { EventChangeLogType } from "models/enumerations/events/event-change-log-type";
import { EVENT_CHANGE_LOG_MAP } from "utilities/hooks/active-record/events/constants/event-change-log-map";
import { CollectionUtils } from "utilities/collection-utils";
import {
    EventActiveRecordWithChangeLog,
    EventChangeLogTypesChanged,
} from "models/active-records/events/event-active-record-with-change-log";
import {
    BulkCreateChangeLogParams,
    EventChangeLogService,
    ListEventChangeLogsPathParams,
} from "utilities/services/events/event-change-log-service";
import { EventChecklistRecord } from "models/view-models/events/event-checklist-record";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { UserRecord } from "models/view-models/users/user-record";
import {
    DeleteEventSessionParams,
    EventSessionService,
} from "utilities/services/events/event-session-service";
import { EventScheduleRecord } from "models/view-models/events/event-schedule-record";
import { EventScheduleService } from "utilities/services/events/event-schedule-service";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventDayService } from "utilities/services/events/event-day-service";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import { EventScheduleExceptionService } from "utilities/services/events/event-schedule-exception-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type EventRecordCustomFunctions = Omit<
    EventActiveRecord,
    | keyof Event
    | "cancel"
    | "discardChanges"
    | "patchEventInvoice"
    | "patchEventOrganization"
    | "patchEventProduct"
    | "patchEventProvider"
    | "patchEventType"
    | "publish"
    | "save"
    | "updateActiveRecord"
    | "updateAndSave"
>;
type EventResourcePathParams = GetEventPathParams | PatchEventPathParams | UpdateEventPathParams;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const COULD_NOT_LOAD_EVENT_ERROR_MESSAGE: TranslatedCopy = "thereWasAnIssueLoadingTheEvent";
const DEFAULT_ERROR_MESSAGE: TranslatedCopy = "anErrorOccurredUpdatingThisEvent";
const FAILED_CANCELLATION_ERROR_MESSAGE: TranslatedCopy = "anErrorOccurredCancelingThisEvent";
const FAILED_PUBLISH_ERROR_MESSAGE: TranslatedCopy = "anErrorOccurredPublishingThisEvent";
const GET_EVENT_QUERY_PARAMS: GetEventQueryParams = {
    includeCanceledBy: true,
    includeContract: true,
    includeEventSessions: true,
    includeEventScheduleException: true,
    includeInstructor: true,
    includeOrganization: true,
    includeProduct: true,
    includeProvider: true,
};
const UPDATE_CONTRACT_ERROR_MESSAGE: TranslatedCopy = "thereWasAnIssueUpdatingTheContract";
const UPDATE_SCHEDULE_EXCEPTION_ERROR_MESSAGE: TranslatedCopy =
    "thereWasAnIssueUpdatingTheScheduleException";
const DELETE_SESSION_ERROR_MESSAGE: TranslatedCopy = "thereWasAnIssueDeletingTheEventSession";
const DELETE_EVENTDAY_ERROR_MESSAGE: TranslatedCopy = "thereWasAnIssueDeletingTheEventDay";
const UPDATE_SCHEDULE_ERROR_MESSAGE: TranslatedCopy = "thereWasAnIssueUpdatingTheEventDay";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useEventActiveRecord = (eventId: number): EventActiveRecordWithChangeLog => {
    const [initialEventRecord, setInitialEventRecord] = useState<EventRecord>(new EventRecord());
    const [eventRecord, setEventRecord] = useState<EventRecord>(new EventRecord());
    const { bulkCreate: bulkCreateEventChangeLogApi } = EventChangeLogService.useBulkCreate();
    const { get: getEventApi } = EventService.useGet();
    const { patch: cancelEventApi } = EventService.useCancel();
    const { patch: patchEventInvoiceApi } = EventService.usePatchEventInvoice();
    const { patch: patchEventOrganizationApi } = EventService.usePatchEventOrganization();
    const { patch: patchEventProductApi } = EventService.usePatchEventProduct();
    const { patch: patchEventProviderApi } = EventService.usePatchEventProvider();
    const { patch: patchEventTypeApi } = EventService.usePatchEventType();
    const { patch: publishEventApi } = EventService.usePublish();
    const { bulkDelete: bulkDeleteSessionApi } = EventSessionService.useBulkDelete();
    const { delete: deleteApi } = EventDayService.useDelete();
    const { create: eventScheduleExpceptionCreateApi } = EventScheduleExceptionService.useCreate();
    const { update: updateContractApi } = ContractService.useUpdate();
    const { update: updateEventApi } = EventService.useUpdate();
    const eventPathParamsAreInvalid = useMemo(
        (): boolean => eventId == null || eventId <= 0,
        [eventId]
    );
    const recordizedEventRecord = useMemo(
        () => ({
            ...eventRecord.toJS(),
            canceledBy:
                eventRecord.canceledBy == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.canceledBy, UserRecord),
            contract:
                eventRecord.contract == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.contract, ContractRecord),
            eventScheduleException:
                eventRecord.eventScheduleException == null
                    ? undefined
                    : RecordUtils.ensureRecord(
                          eventRecord.eventScheduleException,
                          EventScheduleExceptionRecord
                      ),
            instructor:
                eventRecord.instructor == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.instructor, InstructorRecord),
            organization:
                eventRecord.organization == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.organization, OrganizationRecord),
            product:
                eventRecord.product == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.product, ProductRecord),
            provider:
                eventRecord.provider == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.provider, ProviderRecord),
            eventDays:
                eventRecord.eventDays == null
                    ? undefined
                    : RecordUtils.ensureRecords(eventRecord.eventDays ?? [], EventDayRecord),
            eventChecklist:
                eventRecord.eventChecklist == null
                    ? undefined
                    : RecordUtils.ensureRecord(eventRecord.eventChecklist, EventChecklistRecord),
        }),
        [eventRecord]
    );

    // -------------------------------------------------------------------------------------------------
    // #region Private Helper Methods
    // -------------------------------------------------------------------------------------------------

    const makeApiCall = useCallback(
        async <T>(
            serviceCall: () => Promise<ServiceResponse<T>>,
            customErrorMessage?: string
        ): Promise<T | null> => {
            const errorMessage: string = StringUtils.hasValue(customErrorMessage)
                ? customErrorMessage
                : t(DEFAULT_ERROR_MESSAGE);

            try {
                const response = await serviceCall();
                const result = response?.result;

                if (result?.resultObject == null || result.hasErrors()) {
                    throw new Error();
                }

                return result.resultObject;
            } catch {
                ToastManager.error(errorMessage);

                return null;
            }
        },
        []
    );

    const buildEventPathParams = useCallback(
        (): EventResourcePathParams => ({ id: eventId }),
        [eventId]
    );

    const discardChanges = useCallback((): void => {
        setEventRecord(new EventRecord(initialEventRecord.toJS()));
    }, [initialEventRecord]);

    const fetchData = useCallback(async (): Promise<void> => {
        if (eventPathParamsAreInvalid) {
            return;
        }

        const pathParams = buildEventPathParams();

        var updatedEventRecord = await makeApiCall(
            () => getEventApi(pathParams, GET_EVENT_QUERY_PARAMS),
            t(COULD_NOT_LOAD_EVENT_ERROR_MESSAGE)
        );

        if (updatedEventRecord == null) {
            return;
        }

        setEventRecord(
            (previousEventRecord: EventRecord): EventRecord =>
                previousEventRecord.with(updatedEventRecord!.toJS())
        );
        setInitialEventRecord(
            (previousInitialEventRecord: EventRecord): EventRecord =>
                previousInitialEventRecord.with(updatedEventRecord!.toJS())
        );
    }, [buildEventPathParams, getEventApi, makeApiCall, eventPathParamsAreInvalid]);

    const getEventChangeLogTypesChanged = useCallback(
        (incomingEvent?: EventRecord): EventChangeLogTypesChanged => {
            const updatedEvent = incomingEvent ?? eventRecord;
            const defaultEventChangeLogTypesChanged: EventChangeLogTypesChanged = {
                [EventChangeLogType.Contact]: false,
                [EventChangeLogType.Contract]: false,
                [EventChangeLogType.Instructor]: false,
                [EventChangeLogType.Location]: false,
                [EventChangeLogType.Name]: false,
                [EventChangeLogType.Registration]: false,
                [EventChangeLogType.Schedule]: false,
            };

            const changedValues = ObjectUtils.difference(
                updatedEvent.toJS(),
                initialEventRecord.toJS()
            );
            const changedTypes = Object.keys(changedValues)
                .filter((key: string): key is keyof Event => true)
                .map((key: keyof Event): EventChangeLogType | null => EVENT_CHANGE_LOG_MAP[key])
                .filter(
                    (
                        changeLogType: EventChangeLogType | null
                    ): changeLogType is EventChangeLogType => changeLogType != null
                );
            const dedupedChangedTypes = CollectionUtils.deduplicate(changedTypes);
            const eventChangeLogTypesChanged = dedupedChangedTypes.reduce(
                (
                    changedTypes: EventChangeLogTypesChanged,
                    changedType: EventChangeLogType
                ): EventChangeLogTypesChanged => ({
                    ...changedTypes,
                    [changedType]: true,
                }),
                defaultEventChangeLogTypesChanged
            );

            return eventChangeLogTypesChanged;
        },
        [eventRecord, initialEventRecord]
    );

    const makePatchApiCall = useCallback(
        async <TPatchParams = undefined>(
            serviceMethod: (
                pathParams: PatchEventPathParams,
                values?: TPatchParams
            ) => Promise<ServiceResponse<EventRecord>>,
            values?: TPatchParams,
            customErrorMessage?: string
        ): Promise<void> => {
            if (eventPathParamsAreInvalid) {
                return;
            }

            const pathParams: PatchEventPathParams = buildEventPathParams();

            await makeApiCall(() => serviceMethod(pathParams, values), customErrorMessage);

            fetchData();
        },
        [buildEventPathParams, fetchData, makeApiCall, eventPathParamsAreInvalid]
    );

    const noChangesToContract = useCallback(
        (updatedContract?: ContractRecord): boolean => {
            const contract = updatedContract ?? eventRecord.contract;

            return ObjectUtils.hasSameValues(
                contract?.toJS() ?? {},
                initialEventRecord.contract?.toJS() ?? {}
            );
        },
        [eventRecord, initialEventRecord]
    );

    const noChangesToScheduleException = useCallback(
        (updatedScheduleException?: EventScheduleExceptionRecord): boolean => {
            const scheduleException =
                updatedScheduleException ?? eventRecord.eventScheduleException;

            return ObjectUtils.hasSameValues(
                scheduleException?.toJS() ?? {},
                initialEventRecord.eventScheduleException?.toJS() ?? {}
            );
        },
        [eventRecord, initialEventRecord]
    );

    const noChangesToSchedule = useCallback(
        (updateEventDays?: EventDayRecord[]): boolean => {
            const initialEventDays = initialEventRecord.eventDays;
            const eventDays = updateEventDays ?? eventRecord.eventDays;
            // If the arrays aren't the same length, we know they're different.
            if ((initialEventDays?.length ?? 0) !== (eventDays?.length ?? 0)) {
                return false;
            }

            // If either array is null we know they're the same and can return there aren't changes.
            if (initialEventDays == null && eventDays == null) {
                return true;
            }

            ///////////////////////////////////////////////////
            // At this point, we know both arrays are non-null.
            ///////////////////////////////////////////////////

            let noChanges = true;
            for (var i = 0; i < (initialEventDays?.length ?? 0); i++) {
                const initialEventDay = initialEventDays![i];
                const eventDay = eventDays![i];

                // This checks eventDays, does it compare the eventSessions too?
                const eventDaysAreTheSame = ObjectUtils.hasSameValues(
                    eventDay.toJS() ?? {},
                    initialEventDay.toJS() ?? {}
                );
                noChanges = noChanges && eventDaysAreTheSame;
            }
            return noChanges;
        },
        [eventRecord.eventDays, initialEventRecord.eventDays]
    );

    const noChangesToInvoice = useCallback(
        (updatedEvent?: EventRecord): boolean => {
            const event = updatedEvent ?? eventRecord;

            const invoiceHasNotChanged =
                event.hasBeenInvoiced === initialEventRecord.hasBeenInvoiced &&
                event.invoiceNotes === initialEventRecord.invoiceNotes;

            return invoiceHasNotChanged;
        },
        [eventRecord, initialEventRecord]
    );

    const noChangesToEvent = useCallback(
        (updatedEvent?: EventRecord): boolean => {
            const event = updatedEvent ?? eventRecord;

            return ObjectUtils.hasSameValues(event.toJS(), initialEventRecord.toJS());
        },
        [eventRecord, initialEventRecord]
    );

    const persistChangeLogEntries = useCallback(
        (incomingEvent?: EventRecord): void => {
            const updatedEvent = incomingEvent ?? eventRecord;

            if (updatedEvent.isDraft()) {
                return;
            }

            const eventChangeLogPathParams: ListEventChangeLogsPathParams = {
                eventId: updatedEvent.id!,
            };
            const eventChangeLogTypeChanged = getEventChangeLogTypesChanged(updatedEvent);

            const changeTypes = Object.entries(eventChangeLogTypeChanged)
                .filter(([_, changed]: [string, boolean]): boolean => changed)
                .map(
                    ([key, _]: [string, boolean]): EventChangeLogType =>
                        Number(key) as EventChangeLogType
                );

            const bulkCreateEventChangeLogParams: BulkCreateChangeLogParams = {
                changeTypes: changeTypes,
            };

            makeApiCall(() =>
                bulkCreateEventChangeLogApi(
                    eventChangeLogPathParams,
                    bulkCreateEventChangeLogParams
                )
            );
        },
        [bulkCreateEventChangeLogApi, eventRecord, getEventChangeLogTypesChanged, makeApiCall]
    );

    const persistContractUpdates = useCallback(
        async (incomingContract?: ContractRecord): Promise<void> => {
            const updatedContract = incomingContract ?? eventRecord.contract;
            const pathParamsAreInvalid = updatedContract?.id == null || updatedContract.id <= 0;

            if (pathParamsAreInvalid || noChangesToContract(updatedContract)) {
                return;
            }

            const contractPathParams: UpdateContractPathParams = {
                id: updatedContract.id,
            };

            await makeApiCall(
                () => updateContractApi(updatedContract, contractPathParams),
                t(UPDATE_CONTRACT_ERROR_MESSAGE)
            );
        },
        [eventRecord.contract, noChangesToContract, makeApiCall, updateContractApi]
    );

    const persistScheduleExceptionUpdates = useCallback(
        async (incomingScheduleException?: EventScheduleExceptionRecord): Promise<void> => {
            const updatedEventScheduleException =
                incomingScheduleException ?? eventRecord.eventScheduleException;

            await makeApiCall(
                () => eventScheduleExpceptionCreateApi(updatedEventScheduleException),
                t(UPDATE_SCHEDULE_EXCEPTION_ERROR_MESSAGE)
            );
        },
        [eventRecord.eventScheduleException, makeApiCall, eventScheduleExpceptionCreateApi]
    );

    const getDeletedDays = useCallback(
        (initialEventDays?: EventDayRecord[], incomingEventDays?: EventDayRecord[]) => {
            if (initialEventDays == null || initialEventDays.length === 0) {
                return [];
            }

            const deletedDays = initialEventDays.filter(
                (initialDay) =>
                    !incomingEventDays?.some((incomingDay) => incomingDay.id === initialDay.id)
            );

            return deletedDays;
        },
        []
    );

    const getDeletedSessions = useCallback(
        (
            initialEventSessions?: EventSessionRecord[],
            incomingEventSessions?: EventSessionRecord[]
        ) => {
            if (initialEventSessions == null || initialEventSessions.length === 0) {
                return [];
            }

            const deleteEventSessions = initialEventSessions.filter(
                (initialSession) =>
                    !incomingEventSessions?.some(
                        (incomingSession) => incomingSession.id === initialSession.id
                    )
            );

            return deleteEventSessions;
        },
        []
    );

    const persistDeletedEventDays = useCallback(
        async (incomingEventDays?: EventDayRecord[]): Promise<void> => {
            // Delete EventDays with an ID that were in the initial event but are not in the updated event.
            const eventDaysToDelete = getDeletedDays(
                initialEventRecord.eventDays,
                incomingEventDays ?? eventRecord.eventDays
            );

            eventDaysToDelete.forEach(async (eventDay) => {
                try {
                    await deleteApi(eventDay.id!);
                } catch {
                    ToastManager.error(t(DELETE_EVENTDAY_ERROR_MESSAGE));
                    return false;
                }
                return true;
            });
        },
        [deleteApi, eventRecord.eventDays, getDeletedDays, initialEventRecord.eventDays]
    );

    const persistDeletedSessions = useCallback(
        async (incomingEventDays?: EventDayRecord[]): Promise<void> => {
            const initialEventSessions = initialEventRecord.eventDays
                ?.map((eventDay) => eventDay.eventSessions ?? [])
                .flat();

            const incomingEventSessions = incomingEventDays
                ?.map((eventDay) => eventDay.eventSessions ?? [])
                .flat();

            const eventSessionsToDelete = getDeletedSessions(
                initialEventSessions,
                incomingEventSessions
            );
            const eventSessionIdsToDelete = eventSessionsToDelete?.map((eventSession) => {
                return eventSession.id!;
            });

            if (eventSessionsToDelete !== undefined && eventSessionsToDelete.length > 0) {
                const deleteEventSessionParams: DeleteEventSessionParams = {
                    eventSessionIds: eventSessionIdsToDelete,
                };

                try {
                    const deleteEventSessionReponse = await bulkDeleteSessionApi(
                        deleteEventSessionParams
                    );
                    const deleteEventSessionResult = deleteEventSessionReponse?.result;

                    if (deleteEventSessionResult?.hasErrors()) {
                        throw new Error();
                    }
                } catch {
                    ToastManager.error(t(DELETE_SESSION_ERROR_MESSAGE));
                }
            }
        },
        [bulkDeleteSessionApi, getDeletedSessions, initialEventRecord.eventDays]
    );

    const persistScheduleAdditionsAndUpdates = useCallback(
        async (incomingEventDays?: EventDayRecord[]): Promise<void> => {
            const eventSchedule = new EventScheduleRecord().with({
                eventId: eventId,
                eventDays: incomingEventDays ?? eventRecord.eventDays,
            });

            try {
                const eventScheduleUpdateResponse = await EventScheduleService.create(
                    eventSchedule
                );
                const eventScheduleUpdateResult = eventScheduleUpdateResponse?.result;

                if (
                    eventScheduleUpdateResult?.resultObject == null ||
                    eventScheduleUpdateResult.hasErrors()
                ) {
                    throw new Error();
                }

                const updatedEventSchedule = new EventScheduleRecord().with(
                    eventScheduleUpdateResult.resultObject.toJS()
                );

                updatedEventSchedule.sortEventSchedule();
            } catch {
                ToastManager.error(t(UPDATE_SCHEDULE_ERROR_MESSAGE));
            }
        },
        [eventId, eventRecord.eventDays]
    );

    const persistScheduleUpdates = useCallback(
        async (incomingEventDays?: EventDayRecord[]): Promise<void> => {
            if (eventId == null) {
                return;
            }

            await persistDeletedSessions(incomingEventDays);
            await persistDeletedEventDays(incomingEventDays);
            await persistScheduleAdditionsAndUpdates(incomingEventDays);
        },
        [
            eventId,
            persistScheduleAdditionsAndUpdates,
            persistDeletedEventDays,
            persistDeletedSessions,
        ]
    );

    const patchEventInvoice = useCallback(
        (hasBeenInvoiced: boolean, invoiceNotes: string): Promise<void> => {
            const values: EventInvoicePatchParams = {
                hasBeenInvoiced: hasBeenInvoiced,
                invoiceNotes: invoiceNotes,
            };

            return makePatchApiCall(patchEventInvoiceApi, values);
        },
        [makePatchApiCall, patchEventInvoiceApi]
    );

    const persistUpdates = useCallback(
        async (incomingEvent?: EventRecord): Promise<void> => {
            const updatedEvent = incomingEvent ?? eventRecord;

            if (eventPathParamsAreInvalid || noChangesToEvent(updatedEvent)) {
                return;
            }

            if (!noChangesToInvoice(updatedEvent)) {
                await patchEventInvoice(updatedEvent.hasBeenInvoiced, updatedEvent.invoiceNotes);
            }

            if (!noChangesToContract(updatedEvent.contract)) {
                await persistContractUpdates(updatedEvent.contract);
            }

            if (!noChangesToSchedule(updatedEvent.eventDays)) {
                await persistScheduleUpdates(updatedEvent.eventDays);
            }

            if (!noChangesToScheduleException(updatedEvent.eventScheduleException)) {
                await persistScheduleExceptionUpdates(updatedEvent.eventScheduleException);
            }

            // The order of these API calls matter for notification purposed.
            // persistChangeLogEntries will initiate notifications of EventChanges to participants.
            // However, if the instructor changed, we want to make sure:
            // ORIGINAL instructor does NOT receive any notifications.
            // AND we want thew NEW instructor to only receive an Event Assignment Notification
            //  WITH the most recent event information.
            // The instructor change occurs during the Event update,
            // so do that as the last event update.
            // Then when saving the changelog entries,
            // don't send a Event Changed notification to the instructor if one of the changes is an Instructor change.
            await makeApiCall(() => updateEventApi(updatedEvent, buildEventPathParams()));
            persistChangeLogEntries(updatedEvent);

            fetchData();
        },
        [
            eventRecord,
            eventPathParamsAreInvalid,
            noChangesToEvent,
            noChangesToInvoice,
            noChangesToContract,
            noChangesToSchedule,
            noChangesToScheduleException,
            makeApiCall,
            persistChangeLogEntries,
            fetchData,
            patchEventInvoice,
            persistContractUpdates,
            persistScheduleUpdates,
            persistScheduleExceptionUpdates,
            updateEventApi,
            buildEventPathParams,
        ]
    );

    // #endregion Private Helper Methods

    // -------------------------------------------------------------------------------------------------
    // #region EventActiveRecord Properties
    // -------------------------------------------------------------------------------------------------

    const isDirty = useMemo((): boolean => !noChangesToEvent(), [noChangesToEvent]);

    // #endregion EventActiveRecord Properties

    // -------------------------------------------------------------------------------------------------
    // #region EventActiveRecord Methods
    // -------------------------------------------------------------------------------------------------

    const cancel = useCallback(
        (cancellationMessage: string): Promise<void> => {
            const values: CancelEventPatchParams = { cancellationMessage: cancellationMessage };

            return makePatchApiCall(cancelEventApi, values, t(FAILED_CANCELLATION_ERROR_MESSAGE));
        },
        [cancelEventApi, makePatchApiCall]
    );

    const patchEventOrganization = useCallback(
        (organizationId?: number): Promise<void> => {
            const values: EventOrganizationPatchParams = { organizationId: organizationId };

            return makePatchApiCall(patchEventOrganizationApi, values);
        },
        [makePatchApiCall, patchEventOrganizationApi]
    );

    const patchEventProduct = useCallback(
        (productId?: number): Promise<void> => {
            const values: EventProductPatchParams = { productId: productId };

            return makePatchApiCall(patchEventProductApi, values);
        },
        [makePatchApiCall, patchEventProductApi]
    );

    const patchEventProvider = useCallback(
        (providerId?: number): Promise<void> => {
            const values: EventProviderPatchParams = { providerId: providerId };

            return makePatchApiCall(patchEventProviderApi, values);
        },
        [makePatchApiCall, patchEventProviderApi]
    );

    const patchEventType = useCallback(
        (type: EventType): Promise<void> => {
            const values: EventTypePatchParams = { type: Number(type) };

            return makePatchApiCall(patchEventTypeApi, values);
        },
        [makePatchApiCall, patchEventTypeApi]
    );

    const publish = useCallback(
        (): Promise<void> =>
            makePatchApiCall(publishEventApi, undefined, t(FAILED_PUBLISH_ERROR_MESSAGE)),
        [makePatchApiCall, publishEventApi]
    );

    const updateActiveRecord = useCallback(
        (values: Partial<Event>): void =>
            setEventRecord(
                (previousEventRecord: EventRecord): EventRecord => previousEventRecord.with(values)
            ),
        []
    );

    const save = useCallback(async (): Promise<void> => {
        if (noChangesToEvent()) {
            return;
        }

        persistUpdates();
    }, [noChangesToEvent, persistUpdates]);

    const updateAndSave = useCallback(
        async (values: Partial<Event>): Promise<void> => {
            const updatedEventRecord = eventRecord.with(values);

            persistUpdates(updatedEventRecord);
        },
        [eventRecord, persistUpdates]
    );

    // #endregion EventActiveRecord Methods

    // -------------------------------------------------------------------------------------------------
    // #region Return Values
    // -------------------------------------------------------------------------------------------------

    const eventActiveRecordWithChangeLog: EventActiveRecordWithChangeLog = useMemo(
        (): EventActiveRecordWithChangeLog => ({
            ...(RecordUtils.getPublicMethods(EventRecord) as EventRecordCustomFunctions),
            ...recordizedEventRecord,
            cancel: cancel,
            discardChanges: discardChanges,
            isDirty: isDirty,
            eventChangeLogTypeChanged: getEventChangeLogTypesChanged(),
            patchEventInvoice: patchEventInvoice,
            patchEventOrganization: patchEventOrganization,
            patchEventProduct: patchEventProduct,
            patchEventProvider: patchEventProvider,
            patchEventType: patchEventType,
            publish: publish,
            updateActiveRecord: updateActiveRecord,
            updateAndSave: updateAndSave,
            save: save,
        }),
        [
            cancel,
            discardChanges,
            getEventChangeLogTypesChanged,
            isDirty,
            patchEventInvoice,
            patchEventOrganization,
            patchEventProduct,
            patchEventProvider,
            patchEventType,
            publish,
            recordizedEventRecord,
            save,
            updateActiveRecord,
            updateAndSave,
        ]
    );

    // #endregion Return Values

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return eventActiveRecordWithChangeLog;
};

const EventActiveRecordContext = createContext<EventActiveRecord>({} as EventActiveRecord);

const useEventActiveRecordContext = (): EventActiveRecord => {
    return useContext(EventActiveRecordContext);
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActiveRecordContext, useEventActiveRecord, useEventActiveRecordContext };

// #endregion Exports
