// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum IconSizes {
    Base = "",
    Large = "-large",
    Medium = "-medium",
    Small = "-small",
    XLarge = "-xlarge",
    XSmall = "-xsmall",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { IconSizes };

// #endregion Exports
