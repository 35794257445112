import React from "react";
import { CourseContentManager } from "components/courses/course-content/course-content-manager";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import "./course-content-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseContentFormProps {
    deferSave: boolean;
    inCreateVersionMode?: boolean;
    inEditVersionMode?: boolean;
    courseVersion: CourseVersionRecord;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
    setCourseVersion?: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-content-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseContentForm: React.FC<CourseContentFormProps> = ({
    deferSave,
    inCreateVersionMode,
    inEditVersionMode,
    courseVersion,
    setDirty,
    setCourseVersion,
}): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <CourseContentManager
                courseVersion={courseVersion}
                deferSave={deferSave}
                inCreateVersionMode={inCreateVersionMode}
                inEditVersionMode={inEditVersionMode}
                setCourseVersion={setCourseVersion!}
                setDirty={setDirty}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentForm };

// #endregion Exports
