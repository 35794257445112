import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { LocalizationUtils } from "utilities/localization-utils";

// -------------------------------------------------------------------------------------------------
// #region Function
// -------------------------------------------------------------------------------------------------

const t = (key: TranslatedCopy, options?: object): string => LocalizationUtils.t(key, options);

// #endregion Function

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { t };

// #endregion Exports
