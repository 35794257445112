import { EventProductCard } from "components/events/event-product-selection/event-product-card-selection/event-product-card/event-product-card";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import "./event-product-summary.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductSummaryProps {
    event: EventActiveRecord;
    onDeselectProduct: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-product-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductSummary: React.FC<EventProductSummaryProps> = (
    props: EventProductSummaryProps
) => {
    return (
        <div className={CSS_CLASS_NAME}>
            {(props.event.product === undefined || props.event.product === null) && (
                <div className={`${CSS_CLASS_NAME}__no-product-selected-container`}>
                    <div className={`${CSS_CLASS_NAME}__no-product-selected`}>
                        <Paragraph>
                            {t("selectedProductWillAppearHere")}
                        </Paragraph>
                    </div>
                </div>
            )}
            {props.event.product !== undefined && props.event.product !== null && (
                <EventProductCard
                    disabled={props.event.status === PublishStatus.Live}
                    selectableProduct={{
                        product: props.event.product!,
                        isSelected: true,
                    }}
                    handleAdd={() => {}}
                    handleRemove={props.onDeselectProduct}
                />
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductSummary };

// #endregion Exports
