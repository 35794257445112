import { Button, ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { t } from "i18next";
import React from "react";
import { useEmulationMode } from "utilities/contexts/emulation/use-emulation-mode";

interface EmulateUserButtonProps {
    guestId: number | undefined;
    hasNfpaRole: boolean;
}

const BASE_CLASS = "c-emulate-user-button";

const EmulateUserButton: React.FC<EmulateUserButtonProps> = (props: EmulateUserButtonProps) => {
    const { guestId, hasNfpaRole } = props;
    const { createToken, isInEmulationMode } = useEmulationMode();

    const onClick = () => {
        createToken(guestId);
    };

    const getButtonText = () => {
        if (hasNfpaRole) {
            return t("emulationNotAvailableForAdministrators");
        } else return t("emulateUser");
    };

    const isEmulateUserButtonDisabled = () => {
        return isInEmulationMode || hasNfpaRole;
    };

    return (
        <div className={BASE_CLASS}>
            <Button
                disabled={isEmulateUserButtonDisabled()}
                size={ButtonSize.Medium}
                style={ButtonStyle.Primary}
                onClick={onClick}>
                {getButtonText()}
            </Button>
        </div>
    );
};

export default EmulateUserButton;
