import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

enum ReportType {
    Course = 1,
    OnlineLearningTraining = 2,
    InstructorLedTrainingEvent = 3,
}

const ReportTypeDisplayNames: Map<ReportType, TranslatedCopy> = {
    [ReportType.Course]: "course",
    [ReportType.OnlineLearningTraining]: "onlineLearningTraining",
    [ReportType.InstructorLedTrainingEvent]: "instructorLedTrainingEvent",
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportType, ReportTypeDisplayNames };

// #endregion Exports
