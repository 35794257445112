import { AttemptResponsesList } from "components/attempts/attempt-responses-list/attempt-responses-list";
import { AttemptSummary } from "components/attempts/attempt-summary/attempt-summary";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import React from "react";
import "./attempt-details.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AttemptDetailsProps {
    attempt: AttemptRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "attempt-details";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AttemptDetails: React.FC<AttemptDetailsProps> = ({ attempt }): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <AttemptSummary attempt={attempt} />
            <AttemptResponsesList attempt={attempt} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptDetails };

// #endregion Exports
