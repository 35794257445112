import { GlobalStateRecord } from "models/view-models/global-state/global-state-record";
import { useCallback, useEffect } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { AuthService } from "utilities/services/auth/auth-service";
import { SystemSettingsService } from "utilities/services/system-settings/system-settings-service";

function useLoadSystemSettings(
    setGlobalState?: React.Dispatch<React.SetStateAction<GlobalStateRecord>>
) {
    const { get: getSystemSettings } = SystemSettingsService.useGet();
    const { get: getAzureConfigSettings } = AuthService.useGetSettings();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { record: globalState, setRecord: setGlobalStateContext } = useGlobalState();
    setGlobalState = setGlobalState ?? setGlobalStateContext;

    const loadSystemSettings = useCallback(async () => {
        const azureConfigResponse = await getAzureConfigSettings({});

        const response = await getSystemSettings({});
        setGlobalState!((prev) => {
            return prev.with({
                systemSettings: response.resultObject,
                azureSettings: azureConfigResponse.resultObject,
                systemSettingsLoaded: true,
            });
        });
    }, [getAzureConfigSettings, getSystemSettings, setGlobalState]);

    useEffect(() => {
        loadSystemSettings();
    }, [loadSystemSettings]);
}

export { useLoadSystemSettings };
