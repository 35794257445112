import React, { useMemo } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { CourseContentForm } from "components/courses/course-content/course-content-form";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { CourseZipPackageForm } from "components/courses/course-zip-package/course-zip-package-form";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { Icons } from "components/icons/constants/icons";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import {
    TabbedContainer,
    TabbedContainerItem,
} from "components/tabs/tabbed-container/tabbed-container";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/contexts/use-course-context";
import "./create-course-version-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateCourseVersionMaterialsProps {
    courseVersion: CourseVersionRecord;
    deferSave: boolean;
    setCourseVersion: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "create-course-version-materials";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateCourseVersionMaterials: React.FC<CreateCourseVersionMaterialsProps> = ({
    courseVersion,
    deferSave,
    setCourseVersion,
}): JSX.Element => {
    const { record: course } = useCourse();
    const inCreateVersionMode = course.status === ActiveStatus.Active;

    const scormPackage = useMemo(
        () => courseVersion.scormPackage ?? new ScormPackageRecord(),
        [courseVersion]
    );

    const tabs: TabbedContainerItem<any>[] = [
        {
            contents: (
                <CourseZipPackageForm
                    inCreateVersionMode={true}
                    courseVersion={courseVersion}
                    setCourseVersion={setCourseVersion}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("zipPackage"),
                iconType: scormPackage.isComplete(course.id!)
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
        {
            contents: (
                <CourseContentForm
                    deferSave={deferSave}
                    inCreateVersionMode={inCreateVersionMode}
                    courseVersion={courseVersion}
                    setCourseVersion={setCourseVersion}
                />
            ),
            tabComponent: IconTab,
            tabDisplayDetails: {
                tabName: t("content"),
                iconType: courseVersion.contentIsComplete()
                    ? Icons.CircleChecked
                    : Icons.CircleUnchecked,
            },
        },
    ];

    return (
        <div>
            {inCreateVersionMode && (
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t(
                            "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion"
                        )}
                    />
                </div>
            )}
            <TabbedContainer tabs={tabs} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateCourseVersionMaterials };

// #endregion Exports
