import { Process } from "utilities/types/processes/process";
import { RouteUtils } from "utilities/route-utils";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";
import {
    buildEditEventProcessBase,
    EditEventProcessBaseSteps,
} from "utilities/processes/events/build-edit-event-process-base";
import { EventActiveRecord } from "models/active-records/events/event-active-record";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum EnProviderEditEventProcessSpecificSteps {
    Contact,
    Product,
}

export type EnProviderEditEventProcessSteps = typeof EnProviderEditEventProcessSpecificSteps &
    typeof EditEventProcessBaseSteps;

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildEnProviderEditEventProcess = (
    eventId: number,
    event: EventActiveRecord
): Process<EnProviderEditEventProcessSteps> => ({
    Contact: {
        displayName: "eventContact",
        isComplete: () => event.contactInformationIsComplete(),
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.contact, {
            id: eventId,
        }),
    },
    Product: {
        displayName: "product",
        isComplete: () => event.productIsComplete(),
        path: RouteUtils.replacePathParams(sitemap.admin.event.edit.product, {
            id: eventId,
        }),
    },
    ...buildEditEventProcessBase(eventId, event),
});

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useEnProviderEditEventProcess = UseProcessFactory.build(buildEnProviderEditEventProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useEnProviderEditEventProcess };

// #endregion Exports
