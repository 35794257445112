import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventScheduleExceptionForm } from "./event-schedule-exception-form/event-schedule-exception-form";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { Modal, ModalAction } from "components/modal/modal";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleExceptionModalProps {
    event: EventActiveRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveEventScheduleException: (
        eventScheduleException: EventScheduleExceptionRecord
    ) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleExceptionModal: React.FC<EventScheduleExceptionModalProps> = (
    props: EventScheduleExceptionModalProps
): JSX.Element => {
    const [eventScheduleException, setEventScheduleException] = useState(
        props.event.eventScheduleException ??
            new EventScheduleExceptionRecord().with({ eventId: props.event.id })
    );

    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("save"),
            onClick: () => onEventScheduleExceptionSave(),
            style: ButtonStyle.Primary,
            disabled:
                !eventScheduleException.granted ||
                !StringUtils.hasValue(eventScheduleException.reason),
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setEventScheduleException(
            new EventScheduleExceptionRecord().with({ eventId: props.event.id })
        );
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onEventScheduleExceptionSave = () => {
        props.handleSaveEventScheduleException(eventScheduleException);
        setDirty(false);
        props.setOpen(false);
    };

    const onEventScheduleExceptionChanged = (
        eventScheduleException: EventScheduleExceptionRecord
    ): void => {
        setDirty(true);
        setEventScheduleException(eventScheduleException);
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("grantException")}
            actions={modalActionArray}
            modalStyle={""}>
            <EventScheduleExceptionForm
                eventScheduleException={eventScheduleException}
                onEventScheduleExceptionChanged={onEventScheduleExceptionChanged}
            />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionModal };

// #endregion Exports
