import { Record } from "immutable";
import { SeededUser } from "models/interfaces/users/seeded-user";

const defaultValues: SeededUser = {
    displayName: "",
    userName: "",
};

class SeededUserRecord extends Record(defaultValues) implements SeededUser {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<SeededUser>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<SeededUser>): SeededUserRecord {
        return new SeededUserRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

export { SeededUserRecord };
