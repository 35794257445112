import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { UserRoleService } from "utilities/services/user-roles/user-role-service";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingTheUserRoles";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseUserRolesHook {
    error?: Error;
    isLoading: boolean;
    userRoles: UserRoleRecord[];
}

interface UseUserRolesHookOptions {
    onError?: (error: Error) => void;
    userId?: number;
    includeDeleted?: boolean;
    includeDeletedBy?: boolean;
    includeRole?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useUserRoles({
    onError,
    userId,
    includeDeleted,
    includeDeletedBy,
    includeRole,
}: UseUserRolesHookOptions): UseUserRolesHook {
    const { list } = UserRoleService.useList();
    const [userRoles, setUserRoles] = useState<UserRoleRecord[]>([]);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (userId == null || userId <= 0) {
            return;
        }

        try {
            (async function getUsers() {
                setIsLoading(true);
                const userRolesResponse = await list({
                    userId: userId,
                    includeDeleted: includeDeleted,
                    includeRole: includeRole,
                    includeDeletedBy: includeDeletedBy,
                });
                setIsLoading(false);

                if (
                    userRolesResponse?.resultObjects == null ||
                    userRolesResponse?.results == null ||
                    userRolesResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setUserRoles(userRolesResponse.resultObjects);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, setError, userId, includeDeleted, includeRole]);

    return {
        error,
        isLoading,
        userRoles,
    };
}

// #endregion Hook
