import React from "react";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { InputProperties } from "components/form/input-properties";
import {
    FileInputWithButtons,
    FileInputWithButtonsProps,
} from "components/form/inputs/file-input-with-buttons/file-input-with-buttons";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormFileInputWithButtonsProps
    extends FileInputWithButtonsProps,
        FormFieldProps,
        InputProperties {
    buttonText: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormFileInputWithButtons: React.FC<FormFileInputWithButtonsProps> = (
    props: FormFileInputWithButtonsProps
) => {
    return (
        <FormField
            cssClassName={props.cssClassName}
            label={props.label}
            formFieldName={props.formFieldName}
            required={props.required}>
            <FileInputWithButtons {...props} />
        </FormField>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { FormFileInputWithButtons };

// #endregion Exports
