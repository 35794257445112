import { CourseRecord } from "../courses/course-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UnitCourse } from "models/interfaces/units/unit-course";
import { UnitRecord } from "models/view-models/units/unit-record";
import { EnrollmentUnitCourseRecord } from "../enrollments/enrollment-unit-course-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: UnitCourse = {
    course: undefined,
    courseId: 0,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollmentUnitCourse: undefined,
    id: undefined,
    optional: false,
    sortOrder: undefined,
    unit: undefined,
    unitId: 0,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class UnitCourseRecord extends Record(defaultValues) implements UnitCourse {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<UnitCourse>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.course != null) {
            params.course = RecordUtils.ensureRecord(params.course, CourseRecord);
        }

        if (params.unit != null) {
            params.unit = RecordUtils.ensureRecord(params.unit, UnitRecord);
        }

        if (params.enrollmentUnitCourse != null) {
            params.enrollmentUnitCourse = RecordUtils.ensureRecord(
                params.enrollmentUnitCourse,
                EnrollmentUnitCourseRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<UnitCourse>): UnitCourseRecord {
        return new UnitCourseRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UnitCourseRecord };

// #endregion Exports
