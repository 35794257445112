import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { AnnouncementModalForm } from "./announcement-modal-form/announcement-modal-form";
import { AnnouncementRecord } from "../../../models/view-models/announcements/announcement-record";
import {
    AnnouncementService,
    UpdateAnnouncementPathParams,
} from "utilities/services/announcements/announcement-service";
import { ButtonIcon } from "../../buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { Icon } from "../../icons/icon";
import { Icons } from "../../icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { Tab, TabProps } from "components/tabs/tabs/tab/tab";
import { TabBar, TabDetails } from "components/tabs/tab-bars/tab-bar/tab-bar";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { ToastManager } from "utilities/toast/toast-manager";
import { AnnouncmentModalState, announcmentModalReducer } from "./announcment-modal-reducer";
import { t } from "utilities/localization/t";
import "./announcement-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AnnouncementModalProps {
    announcement: AnnouncementRecord;
    isVisible: boolean;
    onClose: () => void;
    onPublish: () => void;
}

export interface LanguageTabState {
    dirty?: boolean;
    languageTab: (typeof LanguageDisplayNames)[Language];
}

interface AnnouncmentModalStateInitializer {
    language: Language;
    announcement: AnnouncementRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "announcement-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
    announcement: originalAnnouncement,
    isVisible,
    onClose,
    onPublish,
}): JSX.Element => {
    const [
        { announcement, currentLanguage, currentBodyHtml, currentTitle, isDirty, publishable },
        dispatch,
    ] = useReducer(
        announcmentModalReducer,
        { language: Language.English, announcement: originalAnnouncement },
        createInitialState
    );

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);

    const { create: createAnnouncement } = AnnouncementService.useCreate();
    const { update } = AnnouncementService.useUpdate();

    useEffect(() => {
        if (isVisible) {
            dispatch({
                type: "initialize_announcement",
                announcement: originalAnnouncement,
            });
        }
    }, [isVisible, originalAnnouncement]);

    const modalActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.LinkAlt,
        },
        {
            buttonText: t("publish"),
            disabled: !publishable,
            onClick: async () => {
                const publishSuccess = await (announcement.id == null
                    ? publishAnnouncement(announcement)
                    : publishUpdatedAnnouncement());

                if (publishSuccess) {
                    onClose();
                }
            },
            style: ButtonStyle.Primary,
        },
    ];

    const cancelConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                onClose();
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetConfirmationActions: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowResetConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetAllForms();
                setShowResetConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const onModalClose = (): void => {
        if (isDirty) {
            return setShowConfirmationModal(true);
        }

        onClose();
    };

    const publishAnnouncement = async (announcement: AnnouncementRecord): Promise<boolean> => {
        if (!announcement.englishTitle || !announcement.englishBody) {
            alert(t("announcementMustHaveAnEnglishTitleAndEnglishBody"));
            return false;
        }

        try {
            const createAnnouncementResponse = await createAnnouncement(announcement);
            const createAnnouncementResult = createAnnouncementResponse.result;

            if (
                createAnnouncementResult?.resultObject == null ||
                createAnnouncementResult.hasErrors()
            ) {
                throw new Error();
            }

            onPublish();
        } catch {
            ToastManager.error(t("thereWasAnIssuePublishingTheAnnouncement"));
            return false;
        }

        return true;
    };

    const publishUpdatedAnnouncement = async (): Promise<boolean> => {
        if (!publishable) {
            alert(t("announcementMustHaveAnEnglishTitleAndEnglishBody"));
            return false;
        }

        try {
            const updateAnnouncementPathParams: UpdateAnnouncementPathParams = {
                id: announcement?.id!,
            };

            const updateAnnouncementResponse = await update(
                announcement,
                updateAnnouncementPathParams
            );

            const updateResult = updateAnnouncementResponse.result;
            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }

            onPublish();
        } catch {
            ToastManager.error(t("thereWasAProblemUpdatingTheAnnouncement"));
        }

        return true;
    };

    const modalHeaderActionContent = () => {
        return (
            <ButtonIcon
                ariaLabelledBy={t("resetForAllLanguages")}
                buttonStyle={ButtonStyle.TertiaryAlt}
                cssClassName={`${CSS_CLASS_NAME}__reset`}
                iconType={Icons.Reset}
                onClick={() => handleResetAll()}
                text={t("resetALL").toLocaleUpperCase()}
            />
        );
    };

    const handleAnnouncementModalFormBodyUpdate = (html: string, text: string) =>
        dispatch({ type: "update_body", html, text });

    const handleAnnouncementModalFormTitleUpdate = (title: string) => {
        dispatch({ type: "update_title", title });
    };

    const handleResetAll = () => {
        if (isDirty) {
            return setShowResetConfirmationModal(true);
        }

        resetAllForms();
    };

    const resetAllForms = (): void => {
        dispatch({
            type: "initialize_announcement",
            announcement: new AnnouncementRecord(),
        });
    };

    const tabDetails = useTabDetails(
        [Language.English, Language.Spanish, Language.Arabic],
        currentLanguage,
        announcement,
        (language) => dispatch({ type: "update_current_language", language })
    );

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={isVisible}
            onModalClose={onModalClose}
            title={announcement == null ? t("newAnnouncement") : t("editAnnouncement")}
            actions={modalActions}
            headerActionContent={modalHeaderActionContent()}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__container`}>
                <React.StrictMode>
                    <div className={`${CSS_CLASS_NAME}__tabs`}>
                        <TabBar tabs={tabDetails} />
                        <AnnouncementModalForm
                            body={currentBodyHtml}
                            title={currentTitle}
                            onBodyUpdated={handleAnnouncementModalFormBodyUpdate}
                            onTitleUpdated={handleAnnouncementModalFormTitleUpdate}
                        />
                    </div>
                </React.StrictMode>
            </div>
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={cancelConfirmationActions}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
            <Modal
                isOpen={showResetConfirmationModal}
                onModalClose={() => {}}
                actions={resetConfirmationActions}
                modalStyle={"-inverted"}>
                {t("areYouSureYouWantToClearAllLanguageData")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

function createInitialState({
    language,
    announcement,
}: AnnouncmentModalStateInitializer): AnnouncmentModalState {
    const { html, text, title } = announcement.getCurrentBodyAndTitle(language);

    return {
        announcement: announcement,
        currentBodyHtml: html,
        currentBodyText: text,
        currentLanguage: language,
        currentTitle: title,
        isDirty: false,
        originalAnnouncement: announcement,
        publishable: announcement.isPublishable(),
    };
}

function useTabDetails(
    langaages: Language[],
    currentLanguage: Language,
    announcement: AnnouncementRecord,
    onChangeLanguage: (language: Language) => void
): TabDetails<TabProps>[] {
    const validateForm = useCallback(
        (language: Language): Icons =>
            announcement.isPublishable(language) ? Icons.CircleChecked : Icons.CircleUnchecked,
        [announcement]
    );

    const createTab = useCallback(
        (language: Language) => ({
            component: (tabProps: TabProps) => (
                <Tab {...tabProps}>
                    <Icon type={validateForm(language)} />
                    <span className="tab__label">{t(LanguageDisplayNames[language])}</span>
                </Tab>
            ),
            name: LanguageDisplayNames[language],
        }),
        [validateForm]
    );

    const tabDetails = useMemo(
        () =>
            langaages
                .map(createTab)
                .map(({ name: tabName, component }, i): TabDetails<TabProps> => {
                    return {
                        component,
                        props: {
                            isActive: currentLanguage === langaages[i],
                            onClick: () => onChangeLanguage(langaages[i]),
                            tabName,
                        },
                    };
                }),
        [createTab, currentLanguage, langaages, onChangeLanguage]
    );

    return tabDetails;
}

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnnouncementModal };

// #endregion Exports
