import "assets/scss/app.scss";
import { AppVersion } from "components/app-version/app-version";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useAuthentication } from "use-authentication";
import { ToastContainer } from "components/toasts/toast-container";
import { ToastDefaultPosition } from "utilities/toast/toast-manager";
import { routeObjects } from "routes";
import { FullScreenTransition } from "components/full-screen-transition/full-screen-transition";
import { EmulationModeContextProvider } from "utilities/contexts/emulation/emulation-context-provider";
import { EmulationModeBanner } from "molecules/banners/emulation-mode-banner";

// At this point the settings for the app have been loaded (featureFlags, etc.)
// Let's try to log them in
const ConfiguredApp = () => {
    const { loginAttemptCompleted } = useAuthentication();
    const router = createBrowserRouter(routeObjects);

    if (!loginAttemptCompleted) return <FullScreenTransition transitionText="" />;

    return (
        <>
            <AppVersion />
            <EmulationModeContextProvider>
                <EmulationModeBanner />
                <RouterProvider router={router} />
            </EmulationModeContextProvider>
            <ToastContainer
                draggable={false}
                position={ToastDefaultPosition}
                autoClose={5000}
                closeOnClick={true}
                closeButton={false}
                transition={Zoom}
                toastClassName="c-toast"
            />
        </>
    );
};

export { ConfiguredApp };
