import React from "react";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { AnnouncementManager } from "./announcement-manager";
import { t } from "utilities/localization/t";
import { useAnnouncement } from "utilities/contexts/use-announcement-context";
import "./admin-layout-header-announcements-panel-content.scss";

interface AdminLayoutHeaderAnnouncementsPanelContentProps {
    isOpen: boolean;
}

const CSS_CLASS_NAME: string = "admin-layout-header-announcements-panel-content";

const AdminLayoutHeaderAnnouncementsPanelContent: React.FC<
    AdminLayoutHeaderAnnouncementsPanelContentProps
> = ({ isOpen }) => {
    const { announcement: announcementData } = useAnnouncement();

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__title`}>
                <Icon
                    cssClassName={`${CSS_CLASS_NAME}__title__icon`}
                    size={IconSizes.Large}
                    type={Icons.Announcements}
                />

                <div className={`${CSS_CLASS_NAME}__title__text`}>{t("announcements")}</div>
            </div>
            <AnnouncementManager isOpen={isOpen} announcementData={announcementData} />
        </div>
    );
};

export default AdminLayoutHeaderAnnouncementsPanelContent;
