import { CourseRecord } from "models/view-models/courses/course-record";
import { ProcessStatusDetermination } from "utilities/interfaces/processes/process-status-determination";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useCourseProcessStatusDetermination = (
    course: CourseRecord,
    courseId?: number
): ProcessStatusDetermination => {
    const processStatusDetermination: ProcessStatusDetermination = {
        statusText: t("publishCourse"),
        canStatusOccur: false,
    };

    return processStatusDetermination;
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useCourseProcessStatusDetermination };

// #endregion Exports
