import React, { createContext, useContext } from "react";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ReadOnlyContextParams {
    readOnly?: boolean;
    setReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ReadOnlyContext = createContext<ReadOnlyContextParams>({
    readOnly: false,
    setReadOnly: () => {},
});

const useReadOnly = () => {
    return useContext(ReadOnlyContext);
};

export { ReadOnlyContext, useReadOnly };

// #endregion Exports
