import React from "react";
import { CalendarDatePicker } from "components/calendar-date-picker/calendar-date-picker";
import { FormField, FormFieldProps } from "components/form/form-field/form-field";
import { StringUtils } from "andculturecode-javascript-core";
import "react-datepicker/dist/react-datepicker.css";
import "./form-calendar-date-picker.scss";
import { CalendarIconPosition } from "../../calendar-date-picker/calendar-date-picker";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormCalendarDatePickerProps extends FormFieldProps {
    calendarIconPosition?: CalendarIconPosition;
    formFieldName: string;
    label: string;
    minDate?: Date;
    placeholder?: TranslatedCopy;
    readOnly?: boolean;
    selectedDate?: Date;
    onBlur?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    onChange: (date?: Date) => void;
    setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-calendar-date-picker";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormCalendarDatePicker: React.FC<FormCalendarDatePickerProps> = (props) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <FormField
            cssClassName={className}
            label={props.label}
            formFieldName={props.formFieldName}
            required={props.required}>
            <CalendarDatePicker
                calendarIconPosition={props.calendarIconPosition}
                minDate={props.minDate}
                placeholder={props.placeholder}
                readOnly={props.readOnly ?? false}
                selectedDate={props.selectedDate}
                setSelectedDate={props.setSelectedDate}
                onBlur={props.onBlur}
                onChange={props.onChange}
                tabIndex={props.tabIndex}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormCalendarDatePicker };

// #endregion Exports
