import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { UserRecord } from "models/view-models/users/user-record";
import { useUsers } from "../users/use-users";
import { useAenRoleTypes } from "./use-aen-roletypes";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseAenUsersHook {
    error?: Error;
    isLoading: boolean;
    users: UserRecord[];
}

interface UseAenUsersOptions {
    applicationType: AenApplicationType;
}

// -------------------------------------------------------------------------------------------------
// #endregion Interfaces
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useAenUsers = ({ applicationType }: UseAenUsersOptions): UseAenUsersHook => {
    const roleTypes = useAenRoleTypes(applicationType);

    const { users, error, isLoading } = useUsers({
        roleTypes: roleTypes,
    });

    return { users, error, isLoading };
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useAenUsers };

// #endregion Exports
