import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { FileRecord } from "models/view-models/files/file-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "files";
const resourceEndpoint: string = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const FileService = {
    create: ServiceFactory.create(FileRecord, baseEndpoint),

    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FileService };

// #endregion Exports
