import {
    AttendanceStatus,
    AttendanceStatusDisplayNames,
} from "models/enumerations/enrollments/attendance-status";
import { Card } from "components/card/card";
import { CollectionUtils } from "utilities/collection-utils";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import { UserInfoStack } from "components/users/user-info-stack";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./event-day-attendance-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDayAttendanceListProps {
    attendances: EventDayAttendanceRecord[];
    canEditAttendance: boolean;
    eventDayHasNotStarted: boolean;
    onAttendedChange: (id: number, newValue: boolean) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-day-attendance-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDayAttendanceList: React.FC<EventDayAttendanceListProps> = (
    props: EventDayAttendanceListProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    return (
        <Card>
            {CollectionUtils.hasValues(props.attendances) ? (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className="name">{t("name")}</th>
                            <th className="attendance">{t("attendance")}</th>
                            <th className="last-change">{t("lastChange")}</th>
                            <th className="by">{t("by")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.attendances.map(
                            (attendance: EventDayAttendanceRecord): JSX.Element => (
                                <tr key={attendance.id}>
                                    <td className="name">
                                        {attendance.enrollment?.user != null && (
                                            <UserInfoStack
                                                user={attendance.enrollment?.user}
                                                showUserId={currentUserIsInNfpaRole}
                                            />
                                        )}
                                    </td>
                                    <td className="attendance">
                                        <RadioButton
                                            key={AttendanceStatus.Attended}
                                            checked={!!attendance.attended}
                                            formFieldName={
                                                AttendanceStatusDisplayNames[
                                                    AttendanceStatus.Attended
                                                ]
                                            }
                                            groupName={`enrollment-${attendance.id}-attended`}
                                            id={`enrollment-${attendance.id}-attended`}
                                            label={t(
                                                AttendanceStatusDisplayNames[
                                                    AttendanceStatus.Attended
                                                ]
                                            )}
                                            labelPosition={RadioButtonLabelPosition.Right}
                                            disabled={
                                                props.eventDayHasNotStarted ||
                                                !props.canEditAttendance
                                            }
                                            onChange={() =>
                                                props.onAttendedChange(attendance.id!, true)
                                            }
                                        />
                                        <RadioButton
                                            key={AttendanceStatus.NoShow}
                                            checked={
                                                !attendance.attended && attendance.attended != null
                                            }
                                            formFieldName={
                                                AttendanceStatusDisplayNames[
                                                    AttendanceStatus.NoShow
                                                ]
                                            }
                                            groupName={`enrollment-${attendance.id}-attended`}
                                            id={`enrollment-${attendance.id}-not-attended`}
                                            label={t(
                                                AttendanceStatusDisplayNames[
                                                    AttendanceStatus.NoShow
                                                ]
                                            )}
                                            labelPosition={RadioButtonLabelPosition.Right}
                                            disabled={
                                                props.eventDayHasNotStarted ||
                                                !props.canEditAttendance
                                            }
                                            onChange={() =>
                                                props.onAttendedChange(attendance.id!, false)
                                            }
                                        />
                                    </td>
                                    <td className="last-change">
                                        <Paragraph
                                            size={ParagraphSize.Small}
                                            style={ParagraphStyle.Light}
                                        >
                                            {attendance.updatedById != null
                                                ? attendance.getLastUpdatedDateAndTime()
                                                : "--"}
                                        </Paragraph>
                                    </td>
                                    <td className="by">
                                        <Paragraph size={ParagraphSize.Small}>
                                            {attendance.updatedById != null
                                                ? attendance.getLastUpdatedBy()
                                                : "--"}
                                        </Paragraph>
                                        <Paragraph style={ParagraphStyle.Label}>
                                            {currentUserIsInNfpaRole ? attendance.updatedById : ""}
                                        </Paragraph>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </DataTable>
            ) : (
                <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayAttendanceList };
