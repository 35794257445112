import { ReportDownloadStatus } from "models/enumerations/reports/report-download-status";
import { ReportDownload } from "models/interfaces/reports/report-download";
import { AuditableRecord } from "../../auditable-record/auditable-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ReportDownload = {
    name: "Report",
    fileId: undefined,
    error: undefined,
    status: ReportDownloadStatus.PENDING,
    createdOn: undefined,
    startedOn: undefined,
    completedOn: undefined,
    reportSourceId: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ReportDownloadRecord extends AuditableRecord(defaultValues) implements ReportDownload {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ReportDownload>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportDownloadRecord };

// #endregion Exports
