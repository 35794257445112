import axios from "axios";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCancellablePromise } from "andculturecode-javascript-react";
import { InstructorApprovedProductRecord } from "models/view-models/instructors/instructor-approved-product-record";
import { useCallback } from "react";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "instructorApprovedProducts";
const resourceType: typeof InstructorApprovedProductRecord = InstructorApprovedProductRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;
const revokeEndpoint: string = `${baseEndpoint}/:id/revoke`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateInstructorApprovedProductService = (
    params: CreateInstructorApprovedProductParams
) => Promise<ServiceResponse<InstructorApprovedProductRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

// #endregion enum

export interface CreateInstructorApprovedProductParams {
    userId: number;
    productIds: number[];
}

export interface PatchInstructorApprovedProductPathParams {
    id: number;
}

export interface InstructorApprovedProductResourcePathParams {
    id: number;
}
export interface ListInstructorApprovedProductsRecordQueryParams {
    userId?: number;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const InstructorApprovedProductsService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateInstructorApprovedProductService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateInstructorApprovedProductService = (
            params: CreateInstructorApprovedProductParams
        ): Promise<ServiceResponse<InstructorApprovedProductRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            params: CreateInstructorApprovedProductParams
        ): Promise<ServiceResponse<InstructorApprovedProductRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<InstructorApprovedProductRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom Hook to revoke an instructor's approval of a product.
     */
    useRevoke: ServiceHookFactory.usePatch<
        InstructorApprovedProductRecord,
        PatchInstructorApprovedProductPathParams
    >(InstructorApprovedProductRecord, revokeEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        InstructorApprovedProductRecord,
        InstructorApprovedProductResourcePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        InstructorApprovedProductRecord,
        ListInstructorApprovedProductsRecordQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApprovedProductsService };

// #endregion Exports
