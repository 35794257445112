import React, { useMemo, useState } from "react";
import { Event } from "models/interfaces/events/event";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventCEUEditModal } from "./event-schedule-exception-manager/event-schedule-exception-modal/event-ceu-edit-modal";
import { EventScheduleExceptionManager } from "./event-schedule-exception-manager/event-schedule-exception-manager";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { RoleType } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import { EventScheduleRecord } from "models/view-models/events/event-schedule-record";
import "./event-schedule-footer.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleFooterProps {
    event: EventActiveRecord;
    editPermitted?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-schedule-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleFooter: React.FC<EventScheduleFooterProps> = (
    props: EventScheduleFooterProps
): JSX.Element => {
    const { record: GlobalState } = useGlobalState();
    const isAEN = GlobalState?.currentIdentity?.role?.roleType === RoleType.AenAdministrator;
    const event = useMemo(() => props.event, [props.event]);

    const eventSchedule = useMemo(
        () =>
            new EventScheduleRecord().with({
                eventId: event.id,
                eventDays: event.eventDays,
            }),
        [event.eventDays, event.id]
    );
    const creditHoursExceeded = useMemo(() => event.maxCreditHoursExceeded(), [event]);

    const exceptionGranted = useMemo(
        () => event.eventScheduleException?.granted,
        [event.eventScheduleException?.granted]
    );

    const exceptionClass = useMemo(() => {
        if (exceptionGranted) {
            return "-exception-granted";
        }
        if (creditHoursExceeded) {
            return "-exception-needed";
        }
        return "";
    }, [creditHoursExceeded, exceptionGranted]);

    const [showCEUEditModal, setShowCEUEditModal] = useState(false);

    const updateEvent = (values: Partial<Event>) => {
        if (event.isDraft()) {
            event.updateAndSave(values);
        } else {
            event.updateActiveRecord(values);
        }
    };

    return (
        <div className={`${CSS_CLASS_NAME}__totals `}>
            <div className={`${CSS_CLASS_NAME}__totals__class-time ${exceptionClass}`}>
                <span className="title">{t("totalClassTime")}</span>
                <span className="amount">
                    {event.hasAllDaySessions ? "--" : eventSchedule.getCreditHoursDescription()}
                </span>
            </div>
            <div className={`${CSS_CLASS_NAME}__totals__ceus ${exceptionClass}`}>
                <span className="title">
                    {t("eventCEUS")}{" "}
                    <button
                        title={t("editCEUs")}
                        disabled={!props.editPermitted}
                        onClick={() => setShowCEUEditModal(true)}>
                        <Icon type={Icons.EditOpen} />
                    </button>
                </span>
                <span className="amount">
                    {event.getCeus()}{" "}
                    {event.ceuOverride && (
                        <>
                            <span>{"( "}</span>
                            <button
                                disabled={!props.editPermitted}
                                className="p-0 underline text-xs"
                                onClick={() => updateEvent({ ceuOverride: undefined })}>
                                {t("reset")}
                            </button>
                            <span>{" )"}</span>
                        </>
                    )}
                </span>
            </div>
            <div className={`${CSS_CLASS_NAME}__totals__exception`}>
                {/* Display a warning to AEN users if they have exceeded the permitted credit hours without an exception */}
                {isAEN && creditHoursExceeded && !exceptionGranted && (
                    <div
                        className={`${CSS_CLASS_NAME}__aen-exceed-hours-message ${exceptionClass}`}>
                        <p>
                            {t("youHaveExceededTheRecommendedAmountOfClassTime")} <br />
                            {t("pleaseAdjustYourScheduleOrContactNFPAForApproval")}
                        </p>
                    </div>
                )}

                {/* Provide NFPA Admin users a way to manage exceptions for schedules that exceed permitted credit hours. */}
                {(creditHoursExceeded || exceptionGranted) && (
                    <EventScheduleExceptionManager event={event} readOnly={isAEN} />
                )}
            </div>
            <EventCEUEditModal
                event={event}
                open={showCEUEditModal}
                setOpen={setShowCEUEditModal}
                onEventCEUSave={updateEvent}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleFooter };

// #endregion Exports
