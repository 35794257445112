import { CatalogEnrollmentErrorType } from "models/enumerations/enrollments/catalog-enrollment-log";
import {
    CatalogEnrollmentLogFilterReduceActionType,
    CatalogEnrollmentLogFilterState,
    catalogEnrollmentLogFilterReducer,
    initialState,
} from "pages/admin/catalog-enrollment-logs/catalog-enrollment-log-page/catalog-enrollment-log-filter-reducer";
import { CatalogEnrollmentLogRecord } from "models/view-models/enrollments/catalog-enrollment-log-record";
import {
    CatalogEnrollmentLogService,
    UpdateCatalogEnrollmentLogPathParams,
} from "utilities/services/enrollments/catalog-enrollment-log-service";
import { CatalogEnrollmentStatus } from "models/enumerations/enrollments/catalog-enrollment-status";
import { NumberUtils } from "utilities/number-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingCatalogEnrollmentLogs";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseCatalogEnrollmentLogsHook {
    error?: Error;
    isLoading: boolean;
    catalogEnrollmentLogs: CatalogEnrollmentLogRecord[];
    setCatalogEnrollmentLogs: React.Dispatch<React.SetStateAction<CatalogEnrollmentLogRecord[]>>;
    rowCount: number;
    onClearFilterClick: () => void;
    handleStatusChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    handleErrorTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    status?: CatalogEnrollmentStatus;
    errorType?: CatalogEnrollmentErrorType;
    updateRecord: (catalogEnrollmentLog: CatalogEnrollmentLogRecord) => Promise<boolean>;
}

interface UseCatalogEnrollmentLogsHookOptions {
    onError?: (error: Error) => void;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useCatalogEnrollmentLogs({
    onError,
    skip,
    take,
}: UseCatalogEnrollmentLogsHookOptions): UseCatalogEnrollmentLogsHook {
    const [{ errorType, status }, dispatch] = useReducer(
        catalogEnrollmentLogFilterReducer,
        initialState,
        (initialState: CatalogEnrollmentLogFilterState) => initialState
    );

    const { list } = CatalogEnrollmentLogService.useList();
    const { update } = CatalogEnrollmentLogService.useUpdate();
    const [catalogEnrollmentLogs, setCatalogEnrollmentLogs] = useState<
        CatalogEnrollmentLogRecord[]
    >([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = useCallback(async () => {
        try {
            (async function getCatalogEnrollmentLogs() {
                setIsLoading(true);
                const catalogEnrollmentLogsResponse = await list({
                    errorType: errorType,
                    skip: skip,
                    status: status,
                    take: take,
                });
                setIsLoading(false);

                if (
                    catalogEnrollmentLogsResponse?.resultObjects == null ||
                    catalogEnrollmentLogsResponse?.results == null ||
                    catalogEnrollmentLogsResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }
                setCatalogEnrollmentLogs(catalogEnrollmentLogsResponse.resultObjects);
                setRowCount(catalogEnrollmentLogsResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [errorType, list, setError, skip, status, take]);

    useEffect(() => {
        fetchData();
    }, [skip, take, fetchData]);

    const onClearFilterClick = () => {
        dispatch({ type: CatalogEnrollmentLogFilterReduceActionType.ResetFilters });
    };
    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: CatalogEnrollmentLogFilterReduceActionType.UpdateStatus,
            value: NumberUtils.parseInt(e.target.value),
        });
    };
    const handleErrorTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: CatalogEnrollmentLogFilterReduceActionType.UpdateErrorType,
            value: NumberUtils.parseInt(e.target.value),
        });
    };

    const updateRecord = useCallback(
        async (catalogEnrollmentLog: CatalogEnrollmentLogRecord): Promise<boolean> => {
            try {
                const updatePathParams: UpdateCatalogEnrollmentLogPathParams = {
                    id: catalogEnrollmentLog.id!,
                };

                const updateCatalogEnrollmentLogResponse = await update(
                    catalogEnrollmentLog,
                    updatePathParams
                );
                const updateResult = updateCatalogEnrollmentLogResponse?.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }
                fetchData();
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheCatalogEnrollmentLog"));
                return false;
            }
            return true;
        },
        [fetchData, update]
    );

    return {
        error,
        isLoading,
        catalogEnrollmentLogs,
        setCatalogEnrollmentLogs,
        rowCount,
        onClearFilterClick,
        handleStatusChange,
        handleErrorTypeChange,
        status,
        errorType,
        updateRecord,
    };
}

// #endregion Hook
