import React from "react";
import { HostCard } from "../../host-card/host-card";
import { InstructorCard } from "../../instructor-card/instructor-card";
import { SupportCard } from "../../support/support-card/support-card";
import { StringUtils } from "andculturecode-javascript-core";
import "./contact-card-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ContactCardListProps {
    instructorId?: number;
    avatarFileId?: number;
    hostCompanyEmail?: string;
    hostCompanyName?: string;
    hostCompanyPhone?: string;
    instructorCompanyEmail?: string;
    instructorCompanyName?: string;
    instructorCompanyPhone?: string;
    showHostCard?: boolean;
    showInstructorCard?: boolean;
    stacked?: boolean;
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "contact-card-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContactCardList: React.FC<ContactCardListProps> = ({
    avatarFileId,
    hostCompanyEmail,
    hostCompanyName,
    hostCompanyPhone,
    instructorId,
    instructorCompanyEmail,
    instructorCompanyName,
    instructorCompanyPhone,
    showHostCard,
    showInstructorCard,
    cssClassName,
}): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    return (
        <div className={CSS_CLASS_NAME}>
            {showHostCard && (
                <HostCard
                    email={hostCompanyEmail ?? ""}
                    name={hostCompanyName ?? ""}
                    phoneNumber={hostCompanyPhone ?? ""}
                />
            )}

            {showInstructorCard && (
                <InstructorCard
                    instructorId={instructorId}
                    avatarFileId={avatarFileId}
                    email={instructorCompanyEmail ?? ""}
                    name={instructorCompanyName ?? ""}
                    phoneNumber={instructorCompanyPhone ?? ""}
                />
            )}

            <SupportCard />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContactCardList };

// #endregion Exports
