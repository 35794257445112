import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle, ButtonSize } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { Icons } from "components/icons/constants/icons";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { SelectableOrganization } from "models/interfaces/organizations/selectable-organization";
import { t } from "utilities/localization/t";
import "./event-organization-selection-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventOrganizationSelectionListProps {
    handleAdd: (organization: OrganizationRecord) => void;
    handleRemove: (organization: OrganizationRecord) => void;
    selectableOrganizations: SelectableOrganization[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-organization-selection-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventOrganizationSelectionList: React.FC<EventOrganizationSelectionListProps> = ({
    handleAdd,
    handleRemove,
    selectableOrganizations,
}) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="organization-name">{t("organizationName")}</th>
                    <th className="id">{t("id")}</th>
                    <th className="action">{t("action")}</th>
                </tr>
            </thead>
            <tbody>
                {selectableOrganizations.length === 0 && (
                    <tr>
                        <td colSpan={3} className={"-no-results-row"}>
                            {t("noOrganizationsFound")}
                        </td>
                    </tr>
                )}
                {selectableOrganizations.map((selectableOrganization) => (
                    <tr key={selectableOrganization.organization.id}>
                        <td className="organization-name">
                            {selectableOrganization.organization.name}
                        </td>
                        <td className="id">{selectableOrganization.organization.id}</td>
                        <td className="action">
                            {!selectableOrganization.isSelected && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Secondary}
                                    iconType={Icons.Plus}
                                    onClick={() => handleAdd(selectableOrganization.organization)}
                                    text={t("add")}
                                />
                            )}
                            {selectableOrganization.isSelected && (
                                <ButtonIcon
                                    buttonSize={ButtonSize.Small}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Minus}
                                    onClick={() =>
                                        handleRemove(selectableOrganization.organization)
                                    }
                                    text={t("remove")}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventOrganizationSelectionList };

// #endregion Exports
