import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { EventScheduleRecord } from "models/view-models/events/event-schedule-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "eventschedules";
const resourceEndpoint: string = `${baseEndpoint}/:eventId`;
const resourceType: typeof EventScheduleRecord = EventScheduleRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEventSchedulePathParams {
    eventId: number;
}

export interface GetEventScheduleQueryParams {}

export interface ListEventSchedulesQueryParams {
    eventId?: number;
}

export interface UpdateEventSchedulePathParams {
    eventId: number;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventScheduleService = {
    /**
     * Create an EventSchedule
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update an EventSchedule
     */
    update: ServiceFactory.update<EventScheduleRecord, UpdateEventSchedulePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EventScheduleRecord,
        GetEventSchedulePathParams,
        GetEventScheduleQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EventScheduleRecord, ListEventSchedulesQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<EventScheduleRecord, UpdateEventSchedulePathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleService };

// #endregion Exports
