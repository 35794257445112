import React from "react";
import { useMatches, useParams } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { Anchor } from "../../typography/anchors/anchor/anchor";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./user-detail-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserDetailSidebarProps {
    navItems: UserDetailSidebarNavItem[];
}

export interface UserDetailSidebarNavItem {
    path: string;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-detail-sidebar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserDetailSidebar: React.FC<UserDetailSidebarProps> = ({ navItems }): JSX.Element => {
    const routeParams = useParams();
    const userId = routeParams.id;

    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const match = matches.find((match) =>
            match.pathname?.toLowerCase().startsWith(path.toLowerCase())
        );
        return match != null;
    };

    return (
        <nav className={CSS_CLASS_NAME} aria-label={t("sidebar")}>
            {navItems.map((navItem) => (
                <Anchor
                    key={`nav-item-${navItem.text.replace(" ", "-")}`}
                    path={RouteUtils.replacePathParams(navItem.path, { id: userId })}
                    text={t(navItem.text)}
                    cssClassName={
                        findPathMatch(RouteUtils.replacePathParams(navItem.path, { id: userId }))
                            ? "-selected"
                            : ""
                    }
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserDetailSidebar };

// #endregion Exports
