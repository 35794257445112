import { EventInstructorCard } from "../event-instructor-card/event-instructor-card";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { SelectableInstructor } from "models/interfaces/instructors/selectable-instructor";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { t } from "utilities/localization/t";
import "./event-instructor-card-selection-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInstructorCardSelectionListProps {
    handleAdd: (instructor: InstructorRecord) => void;
    handleRemove: (instructor: InstructorRecord) => void;
    selectableInstructors: SelectableInstructor[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-instructor-card-selection-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventInstructorCardSelectionList: React.FC<EventInstructorCardSelectionListProps> = (
    props: EventInstructorCardSelectionListProps
) => {
    return (
        <>
            {CollectionUtils.hasValues(props.selectableInstructors) ? (
                <div className={CSS_CLASS_NAME}>
                    {props.selectableInstructors.map((selectableInstructor) => (
                        <EventInstructorCard
                            key={selectableInstructor.instructor.id}
                            selectableInstructor={selectableInstructor}
                            handleAdd={props.handleAdd}
                            handleRemove={props.handleRemove}
                        />
                    ))}
                </div>
            ) : (
                <EmptyText>{t("noInstructorResultsFound")}</EmptyText>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInstructorCardSelectionList };

// #endregion Exports
