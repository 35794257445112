import { ServiceHookFactory } from "andculturecode-javascript-react";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint: string = "userroles";
const resourceType: typeof UserRoleRecord = UserRoleRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ListUserRolesQueryParams {
    userId: number;
    includeRole?: boolean;
    includeDeleted?: boolean;
    includeDeletedBy?: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserRoleService = {
    useList: ServiceHookFactory.useList<UserRoleRecord, ListUserRolesQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate<UserRoleRecord>(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserRoleService };

// #endregion Exports
