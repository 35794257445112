import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ActiveStatus,
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { ButtonIcon, ButtonIconProps } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import {
    GetLatestProductVersionPathParams,
    GetLatestProductVersionQueryParams,
    GetProductVersionZeroPathParams,
    GetProductVersionZeroQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import {
    GetProductPathParams,
    GetProductQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { ProcessSidebar } from "components/process-sidebar/process-sidebar";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import { ProductActivationModal } from "components/products/product-activation-modal/product-activation-modal";
import { ProductArchiveModal } from "components/products/product-archive-modal/product-archive-modal";
import { ProductUnpublishModal } from "components/products/product-unpublish-modal/product-unpublish-modal";
import { ProductContext } from "utilities/contexts/use-product-context";
import { ProductCourseTypeBadgeDisplayNames } from "models/enumerations/courses/product-course-type";
import { ProductDeactivationModal } from "components/products/product-deactivation-modal/product-deactivation-modal";
import { ProductLayoutFooter } from "./product-layout-footer/product-layout-footer";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionContext } from "utilities/contexts/use-product-version-context";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useBadgeTemplate } from "utilities/hooks/models/badges/use-badge-template";
import { useProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { ProductVersionZeroContext } from "utilities/contexts/use-product-version-zero-context";
import {
    CreateProductProcessSteps,
    useCreateProductProcess,
} from "utilities/processes/products/use-create-product-process";
import {
    EditProductProcessSteps,
    useEditProductProcess,
} from "utilities/processes/products/use-edit-product-process";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./product-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductLayout: React.FC<ProductLayoutProps> = (): JSX.Element => {
    const { id } = useParams();
    const productId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const [product, setProduct] = useState<ProductRecord>(new ProductRecord());
    const [productVersion, setProductVersion] = useState<ProductVersionRecord>(
        new ProductVersionRecord()
    );
    const [productVersionZero, setProductVersionZero] = useState<ProductVersionRecord>(
        new ProductVersionRecord()
    );
    const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
    const [showUnpublishModal, setShowUnpublishModal] = useState<boolean>(false);
    const [showDeactivationModal, setShowDeactivationModal] = useState<boolean>(false);
    const { get } = ProductService.useGet();
    const { get: getLatestProductVersion } = ProductVersionService.useGetLatest();
    const { get: getProductVersionZeroApi } = ProductVersionService.useGetProductVersionZero();
    const { allowProductUnpublish } = useFeatureFlags();

    const createProductProcessSteps = useCreateProductProcess(productId, product, productVersion);
    const editProductProcessSteps = useEditProductProcess(productId, product, productVersionZero);

    const productProcess = useMemo(() => {
        return product.status === ActiveStatus.Draft
            ? createProductProcessSteps
            : editProductProcessSteps;
    }, [createProductProcessSteps, editProductProcessSteps, product.status]);

    const productProcessNavigator = useProcessNavigator<
        CreateProductProcessSteps | EditProductProcessSteps
    >(productProcess);

    const numberOfTabs: [string, ProcessStep][] = Object.entries(productProcess);

    const calculatePercentageComplete = (): number => {
        let completeCount = 0;
        numberOfTabs.forEach(([key, step]: [string, ProcessStep]) => {
            if (step.isComplete()) {
                completeCount++;
            }
        });

        return Math.floor((completeCount / numberOfTabs.length) * 100);
    };

    const setLatestProductVersion = useCallback(async (): Promise<void> => {
        if (product.id == null) {
            return;
        }
        const versionPathParams: GetLatestProductVersionPathParams = {
            id: product.id,
        };
        const versionQueryParams: GetLatestProductVersionQueryParams = {
            includeContent: true,
            includeScormPackage: true,
            includeUnit: true,
        };

        try {
            const getVersionResponse = await getLatestProductVersion(
                versionPathParams,
                versionQueryParams
            );
            const getVersionResult = getVersionResponse?.result;
            if (getVersionResult?.resultObject == null || getVersionResult.hasErrors()) {
                throw new Error();
            }
            const latestProductVersion: ProductVersionRecord = getVersionResult.resultObject;

            latestProductVersion.sortAllChildren();

            setProductVersion(latestProductVersion);
        } catch {
            ToastManager.error("thereWasAnIssueLoadingTheProductVersion");
        }
    }, [product.id, getLatestProductVersion]);

    const getProductVersionZero = useCallback(async (): Promise<void> => {
        if (product.id == null) {
            return;
        }
        const versionPathParams: GetProductVersionZeroPathParams = {
            id: product.id,
        };
        const versionQueryParams: GetProductVersionZeroQueryParams = {};

        try {
            const getVersionResponse = await getProductVersionZeroApi(
                versionPathParams,
                versionQueryParams
            );
            const getVersionResult = getVersionResponse?.result;
            if (getVersionResult?.resultObject == null || getVersionResult.hasErrors()) {
                throw new Error();
            }

            setProductVersionZero(getVersionResult.resultObject);
        } catch {
            ToastManager.error("thereWasAnIssueLoadingTheProductVersion");
        }
    }, [product.id, getProductVersionZeroApi]);

    useEffect(() => {
        getProductVersionZero();
    }, [getProductVersionZero]);

    const fetchData = useCallback(async (): Promise<void> => {
        const pathParams: GetProductPathParams = {
            id: productId,
        };

        const queryParams: GetProductQueryParams = {
            includeAssociatedProduct: true,
            includeContent: true,
            includeEvaluationTemplate: true,
            includeProductScormPackage: true,
            includeUnit: true,
            includeProductImageFile: true,
        };

        try {
            const getResponse = await get(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            const product = getResult.resultObject;
            setProduct(product);
            if (product != null && product.status === ActiveStatus.Draft) {
                setLatestProductVersion();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheProduct"));
        }
    }, [productId, get, setLatestProductVersion]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const badgeTemplate = useBadgeTemplate({ badgeTemplateId: productVersion?.badgeTemplateId });

    useEffect(() => {
        const updateBadgeName = (name: string): void => {
            setProductVersion((pv) => pv.with({ badgeName: badgeTemplate.badgeTemplate?.name }));
        };
        if (badgeTemplate.badgeTemplate !== undefined) {
            updateBadgeName(badgeTemplate.badgeTemplate?.name!);
        }
    }, [badgeTemplate.badgeTemplate]);

    const statusBadge = useMemo(
        () => (
            <EnumStatusBadge
                displayNames={ActiveStatusStatusDisplayNames}
                statusMap={ActiveStatusMap}
                value={product.status!}
            />
        ),
        [product.status]
    );

    const headerActionIcons: ButtonIconProps[] = useMemo(() => {
        const headerActionIcons: ButtonIconProps[] = [];

        if (allowProductUnpublish && product.status !== ActiveStatus.Draft) {
            headerActionIcons.push({
                ariaLabelledBy: t("unpublishProduct"),
                onClick: () => setShowUnpublishModal(true),
                iconType: Icons.Reset,
            });
        }

        headerActionIcons.push({
            ariaLabelledBy: t("archiveProduct"),
            disabled: product.status === ActiveStatus.Archived,
            onClick: () => setShowArchiveModal(true),
            iconType: Icons.Archive,
        });

        return headerActionIcons;
    }, [allowProductUnpublish, product.status]);

    return (
        <ProductContext.Provider value={{ record: product, setRecord: setProduct }}>
            <ProductVersionContext.Provider
                value={{ record: productVersion, setRecord: setProductVersion }}>
                <ProductVersionZeroContext.Provider
                    value={{ record: productVersionZero, setRecord: setProductVersionZero }}>
                    <div className={CSS_CLASS_NAME}>
                        <div className={`${CSS_CLASS_NAME}__window`}>
                            <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                                <ProcessSidebar
                                    badges={
                                        product.type === TrainingType.OnlineLearning
                                            ? [
                                                  TrainingTypeDisplayNames[product.type!],
                                                  ProductCourseTypeBadgeDisplayNames[
                                                      product.onlineLearningType!
                                                  ],
                                              ]
                                            : [TrainingTypeDisplayNames[product.type!]]
                                    }
                                    completionPercentage={calculatePercentageComplete()}
                                    title={product.name}
                                    headerActionIcons={headerActionIcons}
                                    isActive={product.status === ActiveStatus.Active}
                                    objectId={1}
                                    process={productProcess}
                                    processNavigator={productProcessNavigator}
                                    showProgressChart={true}
                                    statusBadge={statusBadge}
                                />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__window__main`}>
                                <SkipNavContent>
                                    <div className={`${CSS_CLASS_NAME}__body`}>
                                        <Outlet />
                                    </div>
                                </SkipNavContent>
                            </div>
                        </div>
                        <ProductLayoutFooter
                            cssClassName={`${CSS_CLASS_NAME}__footer`}
                            process={productProcess}
                            processNavigator={productProcessNavigator}>
                            {product.status === ActiveStatus.Draft && (
                                <ButtonIcon
                                    ariaLabelledBy={t("activateProduct")}
                                    buttonStyle={ButtonStyle.Inverted}
                                    disabled={calculatePercentageComplete() === 100 ? false : true}
                                    iconType={Icons.ScreenShare}
                                    onClick={() => {
                                        fetchData();
                                        setShowActivationModal(true);
                                    }}
                                    text={t("activateProduct")}
                                />
                            )}
                            {product.status === ActiveStatus.Active && (
                                <ButtonIcon
                                    ariaLabelledBy={t("deactivateProduct")}
                                    buttonStyle={ButtonStyle.Transparent}
                                    iconType={Icons.StopScreenShare}
                                    onClick={() => setShowDeactivationModal(true)}
                                    text={t("deactivateProduct")}
                                />
                            )}
                        </ProductLayoutFooter>
                    </div>
                    <ProductActivationModal
                        open={showActivationModal}
                        product={product}
                        productVersion={productVersion}
                        productContents={productVersion.productContents}
                        setOpen={setShowActivationModal}
                        setProduct={setProduct}
                        setProductVersionZero={setProductVersionZero}
                    />
                    <ProductArchiveModal
                        product={product}
                        open={showArchiveModal}
                        setOpen={setShowArchiveModal}
                    />
                    <ProductUnpublishModal
                        product={product}
                        open={showUnpublishModal}
                        setOpen={setShowUnpublishModal}
                    />
                    <ProductDeactivationModal
                        product={product}
                        open={showDeactivationModal}
                        setOpen={setShowDeactivationModal}
                    />
                </ProductVersionZeroContext.Provider>
            </ProductVersionContext.Provider>
        </ProductContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductLayout };

// #endregion Exports
