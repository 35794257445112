import React from "react";
import { TabProps } from "components/tabs/tabs/tab/tab";
import "./tab-bar.scss";
import { StringUtils } from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface TabDetails<T extends TabProps> {
    component: React.FC<T>;
    props: T;
}

interface TabBarProps {
    cssClassName?: string;
    tabs: TabDetails<any>[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "tab-bar";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const TabBar: React.FC<TabBarProps> = (props: TabBarProps) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <div aria-orientation="horizontal" className={className}>
            {props.tabs.map(
                ({ component: TabComponent, props }, i): JSX.Element => (
                    <TabComponent key={i} {...props} />
                )
            )}
        </div>
    );
};

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { TabBar };

// #endregion Exports
