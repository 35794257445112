import { useMemo } from "react";
import { EventType } from "models/enumerations/events/event-type";
import {
    useEvents,
    UseEventsHook,
    UseEventsHookOptions,
} from "utilities/hooks/models/events/use-events";
import moment from "moment";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ISO_DATE_INDEX: number = 0;
const ISO_DATE_TIME_SEPARATOR: string = "T";
const MAX_MONTH: number = 11;
const MIN_MONTH: number = 0;
const MIN_YEAR: number = 0;
const INVALID_OPTIONS_ERROR_MESSAGE: string = "Invalid options passed to useCalendarEvents";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseCalendarEventsHookOptions {
    calendarIsHidden?: boolean;
    eventSalesType?: EventType;
    month: number;
    excludeDraft?: boolean;
    instructorId?: number;
    organizationId?: number;
    providerId?: number;
    year: number;
}

interface UseCalendarEventsHook extends Exclude<UseEventsHook, "rowCount"> {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useCalendarEvents = (options: UseCalendarEventsHookOptions): UseCalendarEventsHook => {
    if (optionsAreNotValid(options)) {
        throw new Error(INVALID_OPTIONS_ERROR_MESSAGE);
    }

    const {
        calendarIsHidden,
        eventSalesType,
        month,
        excludeDraft,
        instructorId,
        organizationId,
        providerId,
        year,
    } = options;

    const currentMonth = moment().month(month).year(year);
    const startDate = momentToDateString(currentMonth.clone().startOf("month").startOf("week"));
    const endDate = momentToDateString(currentMonth.clone().endOf("month").endOf("week"));

    const useEventsHookOptions = useMemo(
        (): UseEventsHookOptions => ({
            endDate: endDate,
            eventSalesType: eventSalesType,
            excludeDraft: excludeDraft,
            instructorId: instructorId,
            organizationId: organizationId,
            providerId: providerId,
            preventLoadEvents: calendarIsHidden,
            startDate: startDate,
        }),
        [
            calendarIsHidden,
            endDate,
            eventSalesType,
            excludeDraft,
            instructorId,
            organizationId,
            providerId,
            startDate,
        ]
    );

    return useEvents(useEventsHookOptions);
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Helper Functions
// -------------------------------------------------------------------------------------------------

const momentToDateString = (moment: moment.Moment): string =>
    moment.toDate().toISOString().split(ISO_DATE_TIME_SEPARATOR)[ISO_DATE_INDEX];

const optionsAreNotValid = (options: UseCalendarEventsHookOptions): boolean =>
    options.month < MIN_MONTH || options.month > MAX_MONTH || options.year < MIN_YEAR;

// #endregion Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useCalendarEvents };

// #endregion Exports
