import { Record } from "immutable";
import { GlobalState } from "models/interfaces/global-state/global-state";
import { IdentityRecord } from "models/view-models/identity/identity-record";
import { LocalStorageKey } from "utilities/enumerations/local-storage-keys";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import { CookieUtils } from "utilities/cookie-utils";
import { AppConstants } from "constants/app-constants";
import { CoreUtils, ResultRecord } from "andculturecode-javascript-core";
import FeatureFlags, { DEFAULT_FEATURE_FLAGS } from "models/interfaces/feature-flags/feature-flags";
import { SystemSettingsRecord } from "models/view-models/system-settings-record";
import { RecordUtils } from "utilities/record-utils";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: GlobalState = {
    currentIdentity: undefined,
    forbiddenResult: undefined,
    systemSettings: undefined,
    azureSettings: undefined,
    systemSettingsLoaded: false,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class GlobalStateRecord extends Record(defaultValues) implements GlobalState {
    // ---------------------------------------------------------------------------------------------
    // #region Properties
    // ---------------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // ---------------------------------------------------------------------------------------------
    // #region Constructor
    // ---------------------------------------------------------------------------------------------

    constructor(params?: GlobalState) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.currentIdentity != null && !(params.currentIdentity instanceof IdentityRecord)) {
            params.currentIdentity = new IdentityRecord(params.currentIdentity);
        }

        if (params.forbiddenResult != null && !(params.forbiddenResult instanceof ResultRecord)) {
            params.forbiddenResult = new ResultRecord(params.forbiddenResult);
        }

        if (params.systemSettings != null) {
            params.systemSettings = RecordUtils.ensureRecord(
                params.systemSettings,
                SystemSettingsRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Static Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns an instance of Required<FeatureFlags>.
     * Required<T> is a Typescript utility interface which makes all
     * properties of T required (non-nullable).
     */
    public getFeatureFlagsWithDefaults(): Required<FeatureFlags> {
        if (!this.systemSettingsLoaded) {
            return { ...DEFAULT_FEATURE_FLAGS };
        }

        const featureFlags = this.systemSettings?.featureFlags ?? {};

        return CoreUtils.merge({ ...DEFAULT_FEATURE_FLAGS }, { ...featureFlags });
    }

    /**
     * Get the Global State on first render.
     */
    public static getInitialGlobalState() {
        return new GlobalStateRecord().setFromLocalStorage();
    }

    // #endregion Public Static Methods

    // ---------------------------------------------------------------------------------------------
    // #region Public Methods
    // ---------------------------------------------------------------------------------------------

    /**
     * Returns a new instance of the GlobalStateRecord with the forbidden result cleared.
     */
    public clearForbiddenResult(): GlobalStateRecord {
        return this.with({ forbiddenResult: undefined });
    }

    /**
     * Utility method for returning the SystemSettingsRecord. If the object has not been set yet,
     * it will return a new, default instance of one.
     */
    public getSystemSettings(): SystemSettingsRecord {
        if (!this.hasSystemSettings()) {
            return new SystemSettingsRecord();
        }

        return this.systemSettings!;
    }

    /**
     * Returns whether or not the systemSettings field is non-null
     */
    public hasSystemSettings(): boolean {
        return this.systemSettings != null;
    }

    /**
     * Is the current user authenticated?
     */
    public isAuthenticated(): boolean {
        if (this.currentIdentity == null) {
            return false;
        }
        return this.currentIdentity.isValid();
    }

    public isAwaitingPotentialAuthentication(): boolean {
        return (
            !this.isAuthenticated() && CookieUtils.hasCookie(AppConstants.AuthenticationCookieName)
        );
    }

    /**
     * Initialize the global state record from values in local storage.
     * @returns GlobalStateRecord
     */
    public setFromLocalStorage(): GlobalStateRecord {
        return this.with({
            currentIdentity: LocalStorageUtils.get<IdentityRecord>(
                LocalStorageKey.Identity,
                IdentityRecord
            ),
        });
    }

    /**
     * Returns a new instance of the global state record
     * with the updated identity
     */
    public setIdentity(identity?: IdentityRecord): GlobalStateRecord {
        if (identity == null || !identity.isValid()) {
            return this.setUnauthenticated();
        }
        identity.refreshLocalStorage();

        return this.with({ currentIdentity: identity });
    }

    /**
     * Returns a new instance of the global state record
     * with the user unauthenticated
     */
    public setUnauthenticated(): GlobalStateRecord {
        localStorage.removeItem(LocalStorageKey.Identity);
        return this.with({ currentIdentity: undefined });
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<GlobalState>} values
     * @returns {GlobalStateRecord}
     * @memberof GlobalStateRecord
     */
    public with(values: Partial<GlobalState>): GlobalStateRecord {
        return new GlobalStateRecord(Object.assign(this.toJS(), values));
    }

    public withFeatureFlags(values: Partial<FeatureFlags>): GlobalStateRecord {
        const newFeatureFlags: Required<FeatureFlags> = CoreUtils.merge(
            { ...this.getFeatureFlagsWithDefaults() },
            { values }
        );

        return this.with({
            systemSettings: this.getSystemSettings().with({
                featureFlags: newFeatureFlags,
            }),
        });
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GlobalStateRecord };

// #endregion Exports
