import { Anchor } from "components/typography/anchors/anchor/anchor";
import React from "react";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./not-found-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NotFoundPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "not-found-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NotFoundPage: React.FC<NotFoundPageProps> = validatePageAccess(
    AccessControlKeys.NotFoundPage
)((): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h1>{t("notFound")}</h1>
            <Anchor path={sitemap.root}>{t("returnToHomepage")}</Anchor>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NotFoundPage };

// #endregion Exports
