import axios from "axios";
import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { RoleType } from "models/enumerations/users/role-type";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchNotificationService = (
    params: PatchNotificationServiceParams
) => Promise<ServiceResponse<NotificationRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

export enum NotificationPatchRoutes {
    MarkAsRead = "markasread",
}

// #endregion enum

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface ListNotificationsPathParams {
    userId: number;
}

export interface UpdateNotificationsPathParams {
    id: number;
    userId: number;
}

export interface ListNotificationsQueryParams {
    contractId?: number;
    enrollmentId?: number;
    eventId?: number;
    includeContract?: boolean;
    includeEnrollment?: boolean;
    includeEvent?: boolean;
    includeProduct?: boolean;
    notificationTypes?: NotificationType[];
    roleType?: RoleType;
}

export interface PatchNotificationServiceParams {
    id: number;
    userId: number;
    patchRoute: NotificationPatchRoutes;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint = "users";
const recordType: typeof NotificationRecord = NotificationRecord;
const resourceType: typeof NotificationRecord = NotificationRecord;
const resourceEndpoint = `${baseEndpoint}/:userId/notifications`;
const patchBaseEndpoint = `${baseEndpoint}/:userId/notifications/:id`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserNotificationService = {
    useList: ServiceHookFactory.useNestedList<
        NotificationRecord,
        ListNotificationsPathParams,
        ListNotificationsQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatch: (): { patch: PatchNotificationService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchNotificationService = (
            params: PatchNotificationServiceParams
        ): Promise<ServiceResponse<NotificationRecord>> => {
            const endpointWithParamsReplaced = patchBaseEndpoint
                .replace(":id", params.id.toString())
                .replace(":userId", params.userId.toString());

            const endpointWithPatchRouteAndPathParamsReplaced =
                endpointWithParamsReplaced + "/" + params.patchRoute;

            return axios
                .patch(endpointWithPatchRouteAndPathParamsReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function patch(
            params: PatchNotificationServiceParams
        ): Promise<ServiceResponse<NotificationRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<NotificationRecord>
            >;
        }

        return { patch: useCallback(patch, [cancellablePromise]) };
    },
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserNotificationService };

// #endregion Exports
