import { ResultErrorRecord } from "andculturecode-javascript-core";
import { AttemptCompletionStatus } from "models/enumerations/attempts/attempt-completion-status";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { AttemptService } from "utilities/services/attempts/attempt-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseAttemptsHook {
    attempts?: AttemptRecord[];
    saveAttempt: (attempt: AttemptRecord) => Promise<boolean>;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseAttemptsHookOptions {
    enrollmentId?: number;
    registrationId?: number;
    includeRegistration?: boolean;
    includeResponses?: boolean;
    includeLastModifiedBy?: boolean;
    isManualEntry?: boolean;
}

export function useAttempts(props: UseAttemptsHookOptions): UseAttemptsHook {
    const enrollmentId = props.enrollmentId;
    const registrationId = props.registrationId;
    const includeRegistration = props.includeRegistration ?? false;
    const includeResponses = props.includeResponses ?? false;
    const includeLastModifiedBy = props.includeLastModifiedBy ?? false;
    const isManualEntry = props.isManualEntry;
    const [isLoading, setIsLoading] = useState(true);
    const [attempts, setAttempts] = useState<AttemptRecord[]>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listAttemptsApi } = AttemptService.useList();
    const { create: attemptCreateApi } = AttemptService.useCreate();
    const { update: attemptUpdateApi } = AttemptService.useUpdate();

    const fetchData = useCallback(async () => {
        if (
            (enrollmentId != null && enrollmentId < 1) ||
            (registrationId != null && registrationId < 1)
        ) {
            return;
        }

        (async function fetchAttempts() {
            try {
                const [listAttemptsResponse] = await Promise.all([
                    listAttemptsApi({
                        enrollmentId: enrollmentId,
                        registrationId: registrationId,
                        includeRegistration: includeRegistration,
                        includeResponses: includeResponses,
                        includeLastModifiedBy: includeLastModifiedBy,
                        isManualEntry: isManualEntry,
                    }),
                ]);

                setIsLoading(false);

                if (listAttemptsResponse.result?.hasErrors()) {
                    const { errors: listAttemptsErrors = [] } = listAttemptsResponse.result ?? {};

                    setErrors([...listAttemptsErrors]);
                    return;
                }

                setAttempts(listAttemptsResponse.resultObjects);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingAttempts"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [
        enrollmentId,
        includeRegistration,
        includeResponses,
        isManualEntry,
        listAttemptsApi,
        registrationId,
    ]);

    const saveAttempt = async (attempt: AttemptRecord): Promise<boolean> => {
        const attemptForEnrollmentUnitCourse = attempt.with({
            completionStatus: AttemptCompletionStatus.Completed,
            isManualEntry: true,
        });
        try {
            if (attempt.id == null || attempt.id === 0) {
                await attemptCreateApi(attemptForEnrollmentUnitCourse);
                ToastManager.success(t("attemptCreatedSuccessfully"));
            } else {
                await attemptUpdateApi(attemptForEnrollmentUnitCourse, { id: attempt.id });
                ToastManager.success(t("attemptUpdatedSuccessfully"));
            }

            fetchData();
            return true;
        } catch {
            ToastManager.error(t("attemptCreationFailed"));
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        attempts,
        saveAttempt,
    };
}
