import { Provider } from "models/interfaces/providers/provider";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type ProviderRecordUpdater = RecordUpdater<Provider, ProviderRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: ProviderContext, useRecord: useProvider } = UseRecordContextFactory.build<
    Provider,
    ProviderRecord
>(ProviderRecord);

export { ProviderContext, useProvider };

// #endregion Exports
