import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EventRecord } from "models/view-models/events/event-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { EventService } from "utilities/services/events/event-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UsePastEventsHook {
    events: EventRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

interface UsePastEventsHookOptions {
    instructorId?: number | string;
    currentDate?: string;
    applyEndDateToAllEventDays?: boolean;
    applySearchToProductName?: boolean;
    startDate?: string;
    applySearch?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

export function usePastEvents(props: UsePastEventsHookOptions): UsePastEventsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState<EventRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listPastEvents } = EventService.usePastList();
    const instructorId = NumberUtils.parseInt(props.instructorId) ?? 0;
    const currentDate = props.currentDate;
    const applySearch = props.applySearch;
    const searchText = props.searchText;
    const skip = props.skip;
    const take = props.take;

    useEffect(() => {
        if (isNaN(instructorId) || instructorId < 1) {
            return;
        }

        try {
            (async function getEvents() {
                const { result, resultObjects, rowCount } = await listPastEvents({
                    instructorId,
                    currentDate: currentDate,
                    applySearch,
                    searchText,
                    skip,
                    take,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setEvents(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEvents"));
        }
    }, [instructorId, currentDate, applySearch, searchText, skip, take, listPastEvents]);

    return {
        errors,
        isLoading,
        events,
        rowCount,
    };
}
