import { EventProductCard } from "../event-product-card/event-product-card";
import { ProductRecord } from "models/view-models/products/product-record";
import { SelectableProduct } from "models/interfaces/products/selectable-product";
import { useMemo } from "react";
import "./event-product-card-selection-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductCardSelectionListProps {
    handleAdd: (product: ProductRecord) => void;
    handleRemove: (product: ProductRecord) => void;
    selectableProducts: SelectableProduct[];
    disabled?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-product-card-selection-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductCardSelectionList: React.FC<EventProductCardSelectionListProps> = ({
    handleAdd,
    handleRemove,
    selectableProducts,
    disabled,
}) => {
    const selectionExists = useMemo(
        () => selectableProducts.find((p) => p.isSelected) != null,
        [selectableProducts]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            {selectableProducts.map((selectableProduct) => (
                <EventProductCard
                    disabled={disabled}
                    selectPermitted={!selectionExists}
                    key={selectableProduct.product.id}
                    selectableProduct={selectableProduct}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                />
            ))}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductCardSelectionList };

// #endregion Exports
