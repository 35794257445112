// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum StorageContainers {
    FileUpload = "file-upload",
    ScormPackages = "scorm-packages",
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { StorageContainers };

// #endregion Exports
