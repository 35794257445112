import { ResultErrorRecord } from "andculturecode-javascript-core";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventType } from "models/enumerations/events/event-type";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { EventRecord } from "models/view-models/events/event-record";
import { useCallback, useEffect, useState } from "react";
import {
    EventService,
    EventsControllerIndexSortOrder,
} from "utilities/services/events/event-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

export interface UseEventsHook {
    events: EventRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    rowCount: number;
}

export interface UseEventsHookOptions {
    applySearch?: boolean;
    endDate?: string;
    eventSalesType?: EventType;
    excludeDraft?: boolean;
    instructorId?: number;
    instructorLedTrainingType?: InstructorLedTrainingType;
    isProvidedByNFPA?: boolean;
    organizationAssigned?: boolean;
    organizationId?: number;
    preventLoadEvents?: boolean;
    productId?: number;
    providerAssigned?: boolean;
    providerId?: number;
    searchText?: string;
    skip?: number;
    sortOrder?: EventsControllerIndexSortOrder;
    startDate?: string;
    status?: PublishStatus;
    take?: number;
}

export function useEvents(props: UseEventsHookOptions): UseEventsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState<EventRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listEvents } = EventService.useList();
    const {
        applySearch,
        endDate,
        eventSalesType,
        excludeDraft,
        instructorId,
        instructorLedTrainingType,
        isProvidedByNFPA,
        organizationAssigned,
        organizationId,
        preventLoadEvents,
        productId,
        providerAssigned,
        providerId,
        searchText,
        skip,
        sortOrder,
        startDate,
        status,
        take,
    } = props;

    const shouldNotLoadEvents = useCallback((): boolean => {
        return (
            preventLoadEvents === true ||
            (instructorId != null && instructorId < 1) ||
            (providerId != null && providerId < 1)
        );
    }, [instructorId, preventLoadEvents, providerId]);

    useEffect(() => {
        if (shouldNotLoadEvents()) {
            return;
        }

        try {
            (async function getEvents() {
                const { result, resultObjects, rowCount } = await listEvents({
                    applySearch,
                    endDate,
                    eventSalesType,
                    excludeDraft,
                    instructorId,
                    instructorLedTrainingType,
                    isProvidedByNFPA,
                    organizationId,
                    organizationAssigned,
                    productId,
                    providerAssigned,
                    providerId,
                    searchText,
                    skip,
                    sortOrder,
                    startDate,
                    status,
                    take,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setEvents(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEvents"));
        }
    }, [
        instructorId,
        endDate,
        applySearch,
        searchText,
        skip,
        take,
        listEvents,
        productId,
        status,
        startDate,
        providerId,
        isProvidedByNFPA,
        providerAssigned,
        instructorLedTrainingType,
        eventSalesType,
        excludeDraft,
        organizationId,
        organizationAssigned,
        shouldNotLoadEvents,
        sortOrder,
    ]);

    return {
        errors,
        isLoading,
        events,
        rowCount,
    };
}
