import React, { useMemo } from "react";
import { LearnerLayoutHeader } from "layouts/learner/learner-layout/learner-layout-header/learner-layout-header";
import { Outlet } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavLink } from "@chakra-ui/skip-nav";
import { AdminLayoutHeader } from "layouts/admin/admin-layout/admin-layout-header/admin-layout-header";
import "./authenticated-layout.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AuthenticatedLayoutProps {
    sidebar?: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "authenticated-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = (
    props: AuthenticatedLayoutProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const currentRoleType = useMemo(
        () => globalState.currentIdentity?.role?.roleType,
        [globalState.currentIdentity?.role?.roleType]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            {currentRoleType === RoleType.Learner && (
                <LearnerLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            )}
            {currentRoleType !== RoleType.Learner && (
                <AdminLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            )}
            <div className={`${CSS_CLASS_NAME}__body`}>
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AuthenticatedLayout };

// #endregion Exports
