import React, { useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EmptyText } from "components/empty-text/empty-text";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ResourceContentCard } from "components/resource-content-card/resource-content-card";
import { RoleType } from "models/enumerations/users/role-type";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useAenResources } from "utilities/hooks/models/aen-resources/use-aen-resources";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./provider-enh-resources-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderENHResourcesPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-enh-resources-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderENHResourcesPage: React.FC<ProviderENHResourcesPageProps> = validatePageAccess(
    AccessControlKeys.ProviderENHResourcesPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.aenProvider.dashboard);
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1);

    const { aenResources, rowCount: totalNumberOfResources } = useAenResources({
        searchText: debouncedSearchText,
        roleType: RoleType.AenAdministrator,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const handleSearchTextInputChange = (search: string) => {
        setSearchText(search);
        setCurrentPage(1);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Heading
                        cssClassName={`${CSS_CLASS_NAME}__heading`}
                        priority={HeadingPriority.H2}
                        size={HeadingSize.XSmall}>
                        {t("resources")}
                    </Heading>
                    <Paragraph size={ParagraphSize.Large} style={ParagraphStyle.Light}></Paragraph>

                    <div className={`${CSS_CLASS_NAME}__search`}>
                        <SearchTextInput
                            debounce={DEBOUNCE_TIME}
                            onSearchTextInputChange={handleSearchTextInputChange}
                            onSearchTriggered={handleSearchTriggered}
                            id={"aenResourceSearch"}
                            placeholder={t("typeToSearchByName")}
                            searchTextInputValue={searchText}
                        />
                    </div>
                </div>
                <div>
                    {CollectionUtils.hasValues(aenResources) ? (
                        <>
                            <div className={`${CSS_CLASS_NAME}__cards`}>
                                {aenResources.map((resource) => (
                                    <ResourceContentCard resource={resource} />
                                ))}
                            </div>
                            <Pager
                                currentPage={currentPage}
                                onPageClick={onPageClick}
                                totalPages={totalNumberOfResources / ITEMS_PER_PAGE}
                                itemsPerPage={ITEMS_PER_PAGE}
                                totalItems={totalNumberOfResources}
                            />
                        </>
                    ) : (
                        <EmptyText table>{t("noResourcesFound")}</EmptyText>
                    )}
                </div>
            </div>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderENHResourcesPage };

// #endregion Exports
