import { Enrollment } from "models/interfaces/enrollments/enrollment";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type EnrollmentRecordUpdater = RecordUpdater<Enrollment, EnrollmentRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: EnrollmentContext, useRecord: useEnrollmentContext } =
    UseRecordContextFactory.build<Enrollment, EnrollmentRecord>(EnrollmentRecord);

export { EnrollmentContext, useEnrollmentContext };

// #endregion Exports
