import electrical from "assets/images/electrical.png";
import { DateUtils } from "utilities/date-utils";
import { Product } from "models/interfaces/products/product";
import { ProductVersionRecord } from "./product-version-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { StringUtils } from "utilities/string-utils";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { UserRecord } from "models/view-models/users/user-record";
import { t } from "i18next";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Product = {
    activatedById: undefined,
    activatedOn: undefined,
    associatedProductId: undefined,
    associatedProduct: undefined,
    audienceType: 0,
    availableForAEN: false,
    catalogDescription: "",
    changeLogDescription: "",
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    creditHours: 0,
    courseObjectives: "",
    deactivatedById: undefined,
    deactivatedOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    description: undefined,
    draftProductVersionId: 0,
    erpId: undefined,
    evaluationTemplate: undefined,
    evaluationTemplateId: undefined,
    id: undefined,
    intendedAudience: "",
    language: undefined,
    latestProductVersion: undefined,
    latestVersionId: 0,
    name: "",
    onlineLearningType: undefined,
    productImageFileId: 0,
    productImageFile: undefined,
    status: undefined,
    type: undefined,
    topic: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProductRecord extends Record(defaultValues) implements Product {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Product>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.associatedProduct != null) {
            params.associatedProduct = RecordUtils.ensureRecord(
                params.associatedProduct,
                ProductRecord
            );
        }

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.latestProductVersion != null) {
            params.latestProductVersion = RecordUtils.ensureRecord(
                params.latestProductVersion,
                ProductVersionRecord
            );
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to determine if the details on this record are complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public detailsAreComplete(): boolean {
        if (
            this.type === TrainingType.InstructorLedTraining ||
            this.type === TrainingType.InstructorAssessment
        ) {
            return (
                StringUtils.hasValue(this.description) &&
                this.language != null &&
                this.topic != null
            );
        } else {
            return StringUtils.hasValue(this.description) && this.language != null;
        }
    }

    /**
     * Convenience method to return the topic as an array if it is not null. Otherwise returns undefined.
     */
    public getTopics(): Topic[] | undefined {
        return this.topic != null ? [this.topic] : undefined;
    }

    /**
     * Convenience method to return the date/time this record was created and the name of the user
     * who created it.
     *
     * @returns {string} A string indicating when this record was created and by whom.
     */
    public getCreatedText(): string {
        const createdOnDateTime = DateUtils.formatDateTime(false, this.createdOn);

        if (this.createdBy == null) {
            return createdOnDateTime;
        }

        return t("createdondatetimeByThisCreatedByGetFirstAndLastName", {
            createdOnDateTime: createdOnDateTime,
            thisCreatedByGetFirstAndLastName: this.createdBy.getFirstAndLastName(),
        });
    }

    /**
     * Convenience method to the return training type Enum string with spaces between each word.
     * Ex: InstructorLedTraining -> Instructor Led Training
     */
    public getTrainingTypeWithSpaces(): string {
        if (this == null || this.type == null) {
            return "";
        }
        return t(TrainingTypeDisplayNames[this.type]);
    }

    /**
     * Convenience method to the return the human readable topic.
     */
    public getTopicDisplay(): string {
        if (this == null || this.topic == null) {
            return "N/A";
        }
        return t(TopicDisplayNames[this.topic]);
    }

    /**
     * Convenience method to the return a path to a file that could be used in an <img> src.
     */
    public getProductImageSrc(): string {
        return electrical;
    }

    /**
     * Convenience method to the return user
     * Ex: Steve Smith
     */
    public getLastUpdatedBy(): string {
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return "N/A";
        }

        return updatedBy.getFirstAndLastName();
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getLastUpdatedDateAndTime(): string {
        const message = DateUtils.formatLastEditedDateTime(this, true, true);

        return message;
    }

    /**
     * Convenience method to the return the text for the last updated date text along with the user
     * Ex: on YYYY/MM/D at 8:00 AM by User
     */
    public getLastUpdatedText(): string {
        const message = DateUtils.formatLastEditedDate(this, false);
        const updatedBy = this.updatedBy ?? this.createdBy;

        if (updatedBy == null) {
            return message;
        }

        return t("messageByUpdatedByGetFirstAndLastName", {
            message: message,
            updatedByGetFirstAndLastName: updatedBy.getFirstAndLastName(),
        });
    }

    /**
     * Convenience method to determine if the evaluation on this record is complete.
     *
     * @returns {boolean} Whether or not the details are complete
     */
    public evaluationTemplateIsComplete(): boolean {
        return this.evaluationTemplateId != null && this.evaluationTemplateId !== 0;
    }

    /**
     * Convenience method to return the id of the user who last updated the record (or created it,
     * if it has not yet been updated)
     *
     */
    public getUpdatedOrCreatedById(): number | undefined {
        return this.updatedById ?? this.createdById;
    }

    /**
     * Given a set of values for Product properties, create a new ProductRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Product>} values The values to overwrite on this instance.
     * @returns A ProductRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Product>): ProductRecord {
        return new ProductRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductRecord };

// #endregion Exports
