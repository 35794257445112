import { EnrollmentRecord } from "./enrollment-record";
import { ProductEnrollmentSummary } from "models/interfaces/enrollments/product-enrollment-summary";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "../users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ProductEnrollmentSummary = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    status: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    user: undefined,
    enrollmentFirstName: undefined,
    enrollmentLastName: undefined,
    creditHours: undefined,
    productVersionNumber: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProductEnrollmentSummaryRecord
    extends Record(defaultValues)
    implements ProductEnrollmentSummary
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ProductEnrollmentSummary>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for ProductEnrollmentSummary properties, create a new ProductEnrollmentSummaryRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<ProductEnrollmentSummary>} values The values to overwrite on this instance.
     * @returns An ProductEnrollmentSummaryRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<ProductEnrollmentSummary>): ProductEnrollmentSummaryRecord {
        return new ProductEnrollmentSummaryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductEnrollmentSummaryRecord };

// #endregion Exports
