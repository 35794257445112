import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { Outlet, Location, useLocation, useParams } from "react-router-dom";
import { EventRecord } from "models/view-models/events/event-record";
import {
    EventService,
    GetEventPathParams,
    GetEventQueryParams,
} from "utilities/services/events/event-service";
import { NumberUtils } from "utilities/number-utils";
import { EventContext } from "utilities/contexts/use-event-context";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import {
    NonAdminManageEventLayoutSidebar,
    SidebarNavItem,
} from "layouts/shared/non-admin-manage-event-layout/non-admin-manage-event-layout-sidebar/non-admin-manage-event-layout-sidebar";
import { RouteUtils } from "utilities/route-utils";
import { Icons } from "components/icons/constants/icons";
import { InstructorLedTrainingTypeDisplayNames } from "models/enumerations/products/instructor-led-training-type";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { RoleType } from "models/enumerations/users/role-type";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { t } from "utilities/localization/t";
import "./non-admin-manage-event-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NonAdminManageEventLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "non-admin-manage-event-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NonAdminManageEventLayout: React.FC<NonAdminManageEventLayoutProps> = (): JSX.Element => {
    const location: Location = useLocation();
    const navigate = useNavigate();

    const { id } = useParams();
    const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const [event, setEvent] = useState<EventRecord>(new EventRecord());
    const { get } = EventService.useGet();

    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });
    const isInstructor = useMemo(
        () => globalState?.currentIdentity?.role?.roleType === RoleType.Instructor,
        [globalState?.currentIdentity?.role?.roleType]
    );

    const fetchData = useCallback(async (): Promise<void> => {
        const pathParams: GetEventPathParams = {
            id: eventId,
        };

        const queryParams: GetEventQueryParams = {
            includeCanceledBy: true,
            includeContract: true,
            includeEventSessions: true,
            includeEventScheduleException: true,
            includeInstructor: true,
            includeOrganization: true,
            includeProduct: true,
            includeProvider: true,
            includeChecklist: true,
        };

        try {
            const getResponse = await get(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            getResult?.resultObject.eventDays?.sort(
                (a: EventDayRecord, b: EventDayRecord) =>
                    a.eventDate().valueOf() - b.eventDate().valueOf()
            );

            setEvent(getResult.resultObject);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheEvent"));
        }
    }, [eventId, get]);

    const checklist = useMemo(() => event.eventChecklist, [event.eventChecklist]);

    const completionPercentage = useMemo(() => {
        if (!isInstructor) {
            return undefined;
        }

        let completionNumerator = 0.0;
        let completionDenominator = 0.0;
        let completionPercentage = 0.0;

        if (checklist?.assessmentResultsReviewed) {
            completionNumerator++;
        }
        if (checklist?.trainingContentReviewed) {
            completionNumerator++;
        }
        if (checklist?.evaluationResultsReviewed) {
            completionNumerator++;
        }
        completionDenominator = completionDenominator + 3;
        if (event?.eventDays != null) {
            for (var i = 0; i < event.eventDays.length; i++) {
                if (event.eventDays[i].status === EventDayStatus.Completed) completionNumerator++;
                completionDenominator++;
            }
        }

        if (completionDenominator !== 0.0 && completionNumerator !== 0.0) {
            completionPercentage = (completionNumerator / completionDenominator) * 100;
            completionPercentage = Math.round(completionPercentage);
        }
        return completionPercentage;
    }, [
        checklist?.assessmentResultsReviewed,
        checklist?.evaluationResultsReviewed,
        checklist?.trainingContentReviewed,
        event.eventDays,
        isInstructor,
    ]);

    const navItems: SidebarNavItem[] = useMemo(() => {
        if (eventId == null) {
            return [];
        }

        return [
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.details, {
                    id: eventId,
                }),

                displayName: t("about"),
                iconType: Icons.EventDetails,
            },
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.content, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("content"),
                iconType: Icons.Content,
            },
            {
                path: RouteUtils.replacePathParams(viewEventRoutes.enrollment, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("enrollment"),
                iconType: Icons.Enrollment,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.attendanceSummary, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("attendance"),
                iconType: Icons.Attendance,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.assessments, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("assessments"),
                iconType: Icons.Assessment,
            },
            {
                matchPathOnStartsWith: true,
                path: RouteUtils.replacePathParams(viewEventRoutes.evaluationSummary, {
                    id: eventId,
                }),
                disabled: event.status === PublishStatus.Canceled,
                displayName: t("evaluation"),
                iconType: Icons.Evaluation,
            },
        ];
    }, [
        event.status,
        eventId,
        viewEventRoutes.assessments,
        viewEventRoutes.attendanceSummary,
        viewEventRoutes.content,
        viewEventRoutes.details,
        viewEventRoutes.enrollment,
        viewEventRoutes.evaluationSummary,
    ]);

    const badges: string[] = [];

    // Badges
    if (event?.instructorLedTrainingType != null) {
        badges.push(t(InstructorLedTrainingTypeDisplayNames[event.instructorLedTrainingType]));
    }

    if (event?.product?.language != null) {
        badges.push(t(LanguageDisplayNames[event.product?.language]));
    }

    if (event?.getCeus() > 0) {
        badges.push(
            t("ceusEventProductCreditHoursToString", {
                eventProductCreditHoursToString: event.getCeus(),
            })
        );
    }

    if (event.isProvidedByNFPA) {
        badges.push(t("nfpaEvent"));
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const detailsPath = RouteUtils.replacePathParams(viewEventRoutes.details, {
            id: eventId,
        });

        if (event.status === PublishStatus.Canceled && location.pathname !== detailsPath) {
            navigate(detailsPath);
        }
    }, [event.status, eventId, location.pathname, navigate, viewEventRoutes.details]);

    return (
        <EventContext.Provider value={{ record: event, setRecord: setEvent }}>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__window`}>
                    <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                        <NonAdminManageEventLayoutSidebar
                            event={event}
                            badges={badges}
                            completionPercentage={completionPercentage}
                            trainingName={event?.product?.name}
                            trainingType={event?.product?.type}
                            navItems={navItems}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__window__main`}>
                        <SkipNavContent>
                            <div className={`${CSS_CLASS_NAME}__body`}>
                                <Outlet />
                            </div>
                        </SkipNavContent>
                    </div>
                </div>
            </div>
        </EventContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NonAdminManageEventLayout };

// #endregion Exports
