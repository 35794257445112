import React from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { Icons } from "components/icons/constants/icons";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./training-card.scss";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export enum TrainingCardType {
    InPerson,
    LiveVirtual,
    Online,
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TrainingCardProps {
    enrollmentCount: number;
    enrollmentLimit?: number;
    evaluation: number;
    evaluationTotal: number;
    name: string;
    number: string;
    syllabus: number;
    syllabusTotal: number;
    type: TrainingCardType;
    viewTrainingUrl: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "training-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TrainingCard: React.FC<TrainingCardProps> = (props: TrainingCardProps) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];
    const className = cssClassNames.join(" ");
    let badgeStyle = BadgeStyle.Inverted;
    let badgeLabel: TranslatedCopy = "onlineLearning";
    let badgeIcon = Icons.OnlineLearning;

    switch (props.type) {
        case TrainingCardType.InPerson:
            badgeStyle = BadgeStyle.Neutral;
            badgeLabel = "inPerson";
            badgeIcon = Icons.InPersonEvent;
            break;
        case TrainingCardType.LiveVirtual:
            badgeStyle = BadgeStyle.Dark;
            badgeLabel = "liveVirtual";
            badgeIcon = Icons.LiveVirtualEvent;
            break;
    }

    return (
        <Card cssClassName={className}>
            <Badge style={badgeStyle} icon={badgeIcon} text={t(badgeLabel)}></Badge>
            <FormFieldReadonly label={`#${props.number}`}>
                <Paragraph>{props.name}</Paragraph>
            </FormFieldReadonly>
            <div className={`${CSS_CLASS_NAME}__stats`}>
                <FormFieldReadonly label={t("enrollment")}>
                    <Paragraph>
                        {`${props.enrollmentCount} ${
                            props.enrollmentLimit == null ? "" : " / " + props.enrollmentLimit
                        }`}
                    </Paragraph>
                </FormFieldReadonly>
                <FormFieldReadonly
                    label={
                        props.type === TrainingCardType.Online ? t("syllabus") : t("assessments")
                    }>
                    <Paragraph>
                        {props.enrollmentLimit == null
                            ? props.syllabus + "/" + props.enrollmentCount
                            : props.syllabus + "/" + props.syllabusTotal}
                    </Paragraph>
                </FormFieldReadonly>
                <FormFieldReadonly label={t("evaluation")}>
                    <Paragraph>
                        {props.enrollmentLimit == null
                            ? props.evaluation + "/" + props.enrollmentCount
                            : props.evaluation + "/" + props.evaluationTotal}
                    </Paragraph>
                </FormFieldReadonly>
            </div>
            <Button
                linkPath={props.viewTrainingUrl}
                text={t("viewTraining")}
                type={ButtonType.Link}
            />
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TrainingCard };

// #endregion Exports
