import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { InputTypes } from "../../../form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { t } from "utilities/localization/t";
import "./edit-learner-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditLearnerModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-learner-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditLearnerModal: React.FC<EditLearnerModalProps> = ({ open, setOpen }): JSX.Element => {
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("save"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editLearner")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__grid`}>
                <FormTextInput
                    ariaLabelledBy={t("enrolledFirstName")}
                    autoFocus={true}
                    formFieldName="enrolledFirstName"
                    id="enrolledFirstName"
                    label={t("enrolledFirstName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("firstName")}
                    type={InputTypes.Text}
                />
                <FormTextInput
                    ariaLabelledBy={t("enrolledLastName")}
                    formFieldName="enrolledLastName"
                    id="enrolledLastName"
                    label={t("enrolledLastName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("lastName")}
                    type={InputTypes.Text}
                />
            </div>
            <div className={`${CSS_CLASS_NAME}__grid`}>
                <FormTextInput
                    ariaLabelledBy={t("accountFirstName")}
                    disabled={true}
                    formFieldName="accountFirstName"
                    id="accountFirstName"
                    label={t("accountFirstName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("firstName")}
                    type={InputTypes.Text}
                />
                <FormTextInput
                    ariaLabelledBy={t("accountLastName")}
                    disabled={true}
                    formFieldName="accountLastName"
                    id="accountLastName"
                    label={t("accountLastName")}
                    maxLength={100}
                    onChange={() => {}}
                    placeholder={t("lastName")}
                    type={InputTypes.Text}
                />
            </div>
            <FormTextInput
                ariaLabelledBy={t("email")}
                autoFocus={true}
                disabled={true}
                formFieldName="email"
                id="email"
                label={t("email")}
                maxLength={100}
                onChange={() => {}}
                placeholder="Email@email.com"
                type={InputTypes.Email}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditLearnerModal };

// #endregion Exports
