import React, { useEffect, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { CalendarIconPosition } from "components/calendar-date-picker/calendar-date-picker";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./extend-expiration-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ExtendExpirationModalProps {
    currentExpirationDate?: Date;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onExpirationSave?: (expirationDate?: Date) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "extend-expiration-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ExtendExpirationModal: React.FC<ExtendExpirationModalProps> = ({
    currentExpirationDate,
    open,
    setOpen,
    onExpirationSave,
}): JSX.Element => {
    const [expirationDate, setExpirationDate] = useState<Date | undefined>(currentExpirationDate);
    useEffect(() => {
        setExpirationDate(currentExpirationDate);
    }, [currentExpirationDate]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveExpirationDate"),
            disabled: expirationDate?.toDateString() === currentExpirationDate?.toDateString(),
            onClick: () => handleSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setOpen(false);
    };

    const handleSave = async (): Promise<void> => {
        if (await onExpirationSave?.(expirationDate)) {
            onModalClose();
            ToastManager.success(t("expirationExtendedSuccessfully"));
        } else {
            ToastManager.error(t("expirationExtensionFailed"));
        }
    };

    const handleDateChange = (date?: Date): void => {
        setExpirationDate(date);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("updateExpiration")}
            actions={modalActionArray}
            modalStyle={""}>
            <Paragraph style={ParagraphStyle.Light}>
                {t(
                    "ifALearnerRequiresAdditionalTimeToCompleteTheTrainingTheExpirationDateMayBeExtended"
                )}{" "}
                <i>{t("noteTheExpirationHistoryLogWillTrackEachTimeTheExpirationDateIsUpdated")}</i>
            </Paragraph>
            <FormCalendarDatePicker
                calendarIconPosition={CalendarIconPosition.Right}
                formFieldName="expirationDate"
                label={t("newExpirationDate")}
                minDate={new Date()}
                onChange={handleDateChange}
                placeholder={"chooseDate"}
                selectedDate={expirationDate}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ExtendExpirationModal };

// #endregion Exports
