import React from "react";
import { Button, ButtonProps, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { StringUtils } from "utilities/string-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./tab.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TabBehavioralProps {
    isActive: boolean;
    onClick: () => void;
}

export interface TabProps extends TabBehavioralProps {
    cssClassName?: string;
    tabName: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ACTIVE_CLASS_NAME: string = "-active";
const CSS_CLASS_NAME: string = "tab";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Tab: React.FC<React.PropsWithChildren<TabProps>> = (
    props: React.PropsWithChildren<TabProps>
): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (props.isActive) {
        classNames.push(ACTIVE_CLASS_NAME);
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");
    const commonButtonProps: ButtonProps = {
        cssClassName: className,
        onClick: props.onClick,
        size: ButtonSize.None,
        style: ButtonStyle.None,
    };

    if (props.children == null) {
        return <Button {...commonButtonProps} text={t(props.tabName)} />;
    }

    return <Button {...commonButtonProps}>{props.children}</Button>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Tab };

// #endregion Exports
