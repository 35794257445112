import { DateTime } from "luxon";
import { TimeZoneConstants } from "constants/time-zone-constants";
import { SelectOption } from "components/form/inputs/select/select";

// -------------------------------------------------------------------------------------------------
// #region Interfaces, Types
// -------------------------------------------------------------------------------------------------

interface UseTimeZoneOptionsHook {
    timeZoneOptions: SelectOption[];
}

// #endregion Interfaces, Types

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useTimeZoneOptions(): UseTimeZoneOptionsHook {
    const timeZoneNames = Object.keys(TimeZoneConstants);
    const selectOptions: SelectOption[] = timeZoneNames
        .map((timeZoneOptions) => {
            const timeZoneName = DateTime.fromObject({}, { zone: timeZoneOptions });
            const timeZoneDisplayName = `${timeZoneName.offsetNameLong} (${timeZoneName.offsetNameShort})`;

            return { text: timeZoneDisplayName, value: timeZoneName.zoneName };
        }, [])
        .sort((a, b) => (a.text < b.text ? -1 : 1));

    const uniqueSelectOptions = [
        ...new Map(selectOptions.map((timeZone) => [timeZone.text, timeZone])).values(),
    ];

    return {
        timeZoneOptions: uniqueSelectOptions,
    };
}

// #endregion Hook
