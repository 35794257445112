import axios from "axios";
import { CertificateRecord } from "models/view-models/certificates/certificate-record";
import { RouteUtils } from "utilities/route-utils";
import { ServiceHookFactory } from "andculturecode-javascript-react";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "enrollments/:enrollmentId/certificates";
const resourceType: typeof CertificateRecord = CertificateRecord;
const downloadCertificateEndpoint: string = `${baseEndpoint}/:id/download`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListCertificatesQueryParams {}

export interface ListCertificatePathParams {
    enrollmentId: number;
}

export interface DownloadCertificatePathParams {
    enrollmentId: number;
    id: number;
}

export interface DownloadCertificateQueryParams {
    overwriteExisting: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CertificateService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        CertificateRecord,
        ListCertificatePathParams,
        ListCertificatesQueryParams
    >(resourceType, baseEndpoint),

    downloadCertificate: async (
        pathParams: DownloadCertificatePathParams,
        queryParams: DownloadCertificateQueryParams,
        downloadName: string
    ) => {
        try {
            const pathWithQueryParams = RouteUtils.getUrl(
                downloadCertificateEndpoint,
                pathParams,
                queryParams
            );

            const response = await axios.get(pathWithQueryParams, {
                responseType: "blob",
            });

            if (response.status !== 200) {
                throw new Error("Handle errors here");
            }

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = downloadName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            ToastManager.error("An error occurred while downloading the certificate.");
        }
    },
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CertificateService };

// #endregion Exports
