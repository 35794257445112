import React, { useCallback, useEffect, useState } from "react";
import { AssessmentQuestionResponseSummaryRecord } from "models/view-models/assessments/assessment-question-response-summary-record";
import {
    AssessmentQuestionResponseSummaryService,
    ListAssessmentQuestionResponsesSummariesQueryParams,
} from "utilities/services/assessments/assessment-question-response-summaries-service";
import { Card } from "components/card/card";
import { DataTable } from "components/tables/data-table/data-table";
import { EventRecord } from "models/view-models/events/event-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./assessment-question-breakdown-card.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssessmentQuestionBreakdownCardProps {
    event: EventRecord;
    scormPackageAssessmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "assessment-question-breakdown-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssessmentQuestionBreakdownCard: React.FC<AssessmentQuestionBreakdownCardProps> = (
    props: AssessmentQuestionBreakdownCardProps
): JSX.Element => {
    const { list } = AssessmentQuestionResponseSummaryService.useList();
    const [assessmentQuestionResponseSummary, setAssessmentQuestionResponseSummary] = useState<
        AssessmentQuestionResponseSummaryRecord[]
    >([]);

    const fetchPercentages = useCallback(async () => {
        if (
            props.scormPackageAssessmentId == null ||
            props.event == null ||
            props.event.id == null ||
            props.event.productId == null ||
            props.scormPackageAssessmentId < 1 ||
            props.event.id < 1 ||
            props.event.productId < 1
        ) {
            return;
        }

        const queryParams: ListAssessmentQuestionResponsesSummariesQueryParams = {
            eventId: props.event.id,
            productId: props.event.productId,
            scormPackageAssessmentId: props.scormPackageAssessmentId,
        };

        try {
            const listResponse = await list(queryParams);

            if (
                listResponse?.resultObjects == null ||
                listResponse.results == null ||
                listResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            setAssessmentQuestionResponseSummary(listResponse.resultObjects);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingQuestionBreakdowns"));
            setAssessmentQuestionResponseSummary([]);
        }
    }, [list, props.event, props.scormPackageAssessmentId]);

    useEffect(() => {
        fetchPercentages();
    }, [fetchPercentages]);

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <h3 className={`${CSS_CLASS_NAME}__title`}>{t("questionBreakdown")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th className="-index">{t("number")}</th>
                        <th className="-question-text">{t("question")}</th>
                        <th className="-correct-percentage">{t("correct")}</th>
                        <th className="-incorrect-percentage">{t("incorrect")}</th>
                    </tr>
                </thead>
                <tbody>
                    {assessmentQuestionResponseSummary.map(
                        (question: AssessmentQuestionResponseSummaryRecord): JSX.Element => (
                            <tr key={question.id}>
                                <td className="-index">{question.sortOrder}</td>
                                <td className="-question-text">{question.questionText}</td>
                                <td className="-correct-percentage">
                                    {question.getPercentageText(question.correctPercentage)}
                                </td>
                                <td className="-incorrect-percentage">
                                    {question.getPercentageText(question.incorrectPercentage)}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </DataTable>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentQuestionBreakdownCard };

// #endregion Exports
