import React from "react";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { HeaderBanner } from "components/header-banner/header-banner";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ContractsDetailHeaderProps {
    organization?: OrganizationRecord;
    onEditOrganizationClick?: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "contracts-detail-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContractsDetailHeader: React.FC<React.PropsWithChildren<ContractsDetailHeaderProps>> = (
    props: React.PropsWithChildren<ContractsDetailHeaderProps>
): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const canModifyOrganization =
        globalState?.currentIdentity?.isCurrentlyInNfpaRole() &&
        globalState?.currentIdentity?.role?.roleType !== RoleType.NfpaSupport;

    return (
        <>
            <HeaderBanner cssClassName={CSS_CLASS_NAME} title={props.organization?.name ?? ""}>
                {canModifyOrganization && (
                    <Button
                        text={t("editContractOrganization")}
                        onClick={props.onEditOrganizationClick}
                        size={ButtonSize.Medium}
                        style={ButtonStyle.TertiaryAlt}
                    />
                )}
                {props.children}
            </HeaderBanner>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractsDetailHeader };

// #endregion Exports
