import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import moment from "moment";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { EventRecord } from "models/view-models/events/event-record";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { sitemap } from "sitemap";
import { CalendarMonthView } from "components/calendar-month-view/calendar-month-view";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useEvents } from "utilities/hooks/models/events/use-events";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useMemo, useState } from "react";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { usePastEvents } from "utilities/hooks/models/events/use-past-events";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { t } from "utilities/localization/t";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventsControllerIndexSortOrder } from "utilities/services/events/event-service";
import { useCalendarEvents } from "utilities/hooks/models/events/use-calendar-events";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { EmptyText } from "components/empty-text/empty-text";
import { CollectionUtils } from "utilities/collection-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { SelectOption } from "components/form/inputs/select/select";
import { FormSelect } from "components/form/form-select/form-select";
import "./my-teaching-assignments-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface MyTeachingAssignmentsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum MyTeachingAssignmentsTrainingViewTypes {
    Calendar = "1",
    List = "2",
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "my-teaching-assignments-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;

const TRAINING_VIEW_OPTIONS: SelectOption[] = [
    {
        value: MyTeachingAssignmentsTrainingViewTypes.List,
        text: "listView" as TranslatedCopy,
    },
    {
        value: MyTeachingAssignmentsTrainingViewTypes.Calendar,
        text: "calendarView" as TranslatedCopy,
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getEventTeachingPath = (eventId: number) => {
    return RouteUtils.replacePathParams(sitemap.instructor.event.details, {
        id: eventId,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyTeachingAssignmentsPage: React.FC<MyTeachingAssignmentsPageProps> = validatePageAccess(
    AccessControlKeys.MyTeachingAssignmentsPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const userId = useMemo(
        () => globalState?.currentIdentity?.user?.id,
        [globalState?.currentIdentity?.user?.id]
    );
    const [currenTrainingsViewType, setCurrenTrainingsViewType] =
        useState<MyTeachingAssignmentsTrainingViewTypes>(
            MyTeachingAssignmentsTrainingViewTypes.Calendar
        );

    const [searchText, setSearchText] = useState<string>("");
    const [calendarMonth, setCalendarMonth] = useState<moment.Moment>(moment().startOf("month"));
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);

    const today = DateUtils.formatDateCustom(new Date().toString(), "YYYY-MM-DD");
    const { events: futureEvents } = useEvents({
        instructorId: userId ?? -1,
        startDate: today,
        status: PublishStatus.Live,
        excludeDraft: true,
        sortOrder: EventsControllerIndexSortOrder.EventStartDateAsc,
    });

    const { notifications, markNotificationAsRead } = useUserNotifications({
        includeEvent: true,
        roleType: RoleType.Instructor,
        userId: userId,
    });

    const { events: pastEvents, rowCount: totalNumberOfPastEvents } = usePastEvents({
        instructorId: userId,
        currentDate: today,
        applySearch: true,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const { events: calendarEvents } = useCalendarEvents({
        instructorId: userId,
        excludeDraft: true,
        month: calendarMonth.toDate().getMonth(),
        year: calendarMonth.toDate().getFullYear(),
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleNextMonthClick = () => setCalendarMonth(calendarMonth.clone().add(1, "month"));
    const handlePreviousMonthClick = () =>
        setCalendarMonth(calendarMonth.clone().subtract(1, "month"));

    const handleSearchTextInputChange = (searchText: string) => {
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const navigateToViewEventPath = (eventId: number) => {
        const viewEventRoute = RouteUtils.replacePathParams(viewEventRoutes.details, {
            id: eventId,
        });

        navigate(viewEventRoute);
    };

    const getInstructorLedTrainingTypeBadge = (trainingType?: InstructorLedTrainingType) => {
        if (trainingType == null) {
            return <></>;
        }

        const typeBadgeStyle: BadgeStyle =
            trainingType === InstructorLedTrainingType.LiveVirtual
                ? BadgeStyle.Dark
                : BadgeStyle.Neutral;

        const typeBadgeText = t(InstructorLedTrainingTypeDisplayNames[trainingType]);

        return <Badge style={typeBadgeStyle} text={typeBadgeText} />;
    };

    const getEventAttendanceBadge = (event: EventRecord) => {
        if (event.canceledOn != null) {
            return <Badge style={BadgeStyle.Error} text={t("canceled")} />;
        }

        if (event.eventDays == null || event.eventDays.length === 0) {
            return <></>;
        }

        const numberOfEventDays = event.eventDays.length;
        const numberOfCompleteDays = event.eventDays?.filter(
            (ed) => ed.status === EventDayStatus.Completed
        ).length;
        const numberOfNotStartedDays = event.eventDays?.filter(
            (ed) => ed.status === EventDayStatus.NotStarted
        ).length;

        if (numberOfEventDays === numberOfCompleteDays) {
            return <Badge style={BadgeStyle.Success} text={t("complete")} />;
        }

        if (numberOfEventDays === numberOfNotStartedDays) {
            return <Badge style={BadgeStyle.Default} text={t("notStarted")} />;
        }

        return <Badge style={BadgeStyle.Warning} text={t("inProgress")} />;
    };

    const markNotificationRead = (notificationId?: number) => {
        if (notificationId == null) {
            return;
        }

        markNotificationAsRead?.(notificationId);
    };

    const getNotificationLinkPath = (notification: NotificationRecord) => {
        if (notification.event?.instructorId !== userId) {
            return sitemap.public.noAccess;
        } else {
            return getEventTeachingPath(notification.eventId!);
        }
    };

    return (
        <>
            <HeaderBanner title={t("myTeachingAssignments")}></HeaderBanner>
            <SkipNavContent>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__upcoming-teachings`}>
                        {notifications != null && (
                            <div className={`${CSS_CLASS_NAME}__notifications`}>
                                {notifications.map((notification, index) => (
                                    <NotificationBanner
                                        key={`${notification.id}-${index}`}
                                        icon={notification.getNotificationIcon()}
                                        linkPath={getNotificationLinkPath(notification)}
                                        linkText={t("viewTraining")}
                                        notificationId={notification.id!}
                                        onClose={() => markNotificationRead(notification.id)}
                                        style={notification.notificationType}
                                        message={notification.message}
                                    />
                                ))}
                            </div>
                        )}

                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <h2>{t("teachingCalendar")} </h2>
                            <FormSelect
                                ariaLabelledBy="Training View Type"
                                formFieldName="TrainingViewType"
                                id="TrainingViewType"
                                label="Training View Type"
                                labelScreenReaderOnly={true}
                                onChange={(e) =>
                                    setCurrenTrainingsViewType(
                                        e.currentTarget
                                            .value as MyTeachingAssignmentsTrainingViewTypes
                                    )
                                }
                                options={TRAINING_VIEW_OPTIONS}
                                value={currenTrainingsViewType}
                            />
                        </div>

                        {currenTrainingsViewType ===
                            MyTeachingAssignmentsTrainingViewTypes.Calendar && (
                            <div className={`${CSS_CLASS_NAME}__section`}>
                                <CalendarMonthView
                                    events={calendarEvents}
                                    notifications={notifications}
                                    getEventDisplayName={(event) =>
                                        event.product?.name ?? event.name
                                    }
                                    onNextMonthClick={handleNextMonthClick}
                                    onPreviousMonthClick={handlePreviousMonthClick}
                                    onViewEventClick={(eventId: number) =>
                                        navigateToViewEventPath(eventId)
                                    }
                                />
                            </div>
                        )}

                        {currenTrainingsViewType ===
                            MyTeachingAssignmentsTrainingViewTypes.List && (
                            <>
                                {CollectionUtils.hasValues(futureEvents) ? (
                                    <DataTable cssClassName="teaching-calendar">
                                        <thead>
                                            <tr>
                                                <th className="name">{t("name")}</th>
                                                <th className="location">{t("location")}</th>
                                                <th className="type">{t("type")}</th>
                                                <th className="start-date">{t("startDate")}</th>
                                                <th className="action">
                                                    <span className="sr-only">{t("action")}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {futureEvents.map((event) => (
                                                <tr key={event.id}>
                                                    <td className="name">
                                                        <Paragraph size={ParagraphSize.XSmall}>
                                                            {event.product?.name}
                                                        </Paragraph>
                                                    </td>
                                                    <td className="location">
                                                        {event.instructorLedTrainingType ===
                                                        InstructorLedTrainingType.LiveVirtual
                                                            ? "--"
                                                            : `${event.city ?? "--"}, ${
                                                                  event.state ?? "--"
                                                              }`}
                                                    </td>
                                                    <td className="type">
                                                        {getInstructorLedTrainingTypeBadge(
                                                            event.instructorLedTrainingType
                                                        )}
                                                    </td>
                                                    <td className="start-date">
                                                        <span>
                                                            {DateUtils.formatDateCustom(
                                                                event.startDate()?.toString(),
                                                                t("shortDateFormat")
                                                            )}{" "}
                                                            {event.eventDays != null &&
                                                            event.eventDays.length > 1 ? (
                                                                <Icon type={Icons.CalendarMulti} />
                                                            ) : (
                                                                <Icon type={Icons.CalendarSingle} />
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td className="action">
                                                        <Button
                                                            text={t("viewTraining")}
                                                            onClick={() =>
                                                                navigateToViewEventPath(event.id!)
                                                            }
                                                            size={ButtonSize.Small}
                                                            style={ButtonStyle.Primary}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </DataTable>
                                ) : (
                                    <EmptyText table>{t("noResultsFoundPleaseTryAgain")}</EmptyText>
                                )}
                            </>
                        )}
                    </div>

                    <div className={`${CSS_CLASS_NAME}__past-teachings`}>
                        <div className={`${CSS_CLASS_NAME}__past-teachings__header`}>
                            <div>
                                <h2>{t("pastTrainings")}</h2>
                                <Tooltip
                                    content={t(
                                        "aPastTrainingIsATrainingEventWhereAllOfTheEventDatesareInThePast"
                                    )}
                                    placement={TooltipPlacement.Right}>
                                    <span className={`${CSS_CLASS_NAME}__icon-wrapper`}>
                                        <Icon
                                            type={Icons.Information}
                                            cssClassName={`${CSS_CLASS_NAME}__tooltip`}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__past-teachings__search`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextInputChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id="courseSearch"
                                    placeholder={t("typeToSearchByTrainingName")}
                                    searchTextInputValue={searchText}
                                />
                            </div>
                        </div>
                        <DataTable cssClassName="past-teachings">
                            <thead>
                                <tr>
                                    <th className="name">{t("name")}</th>
                                    <th className="location">{t("location")}</th>
                                    <th className="type">{t("type")}</th>
                                    <th className="start-date">{t("startDate")}</th>
                                    <th className="attendance">{t("attendance")}</th>
                                    <th className="action">
                                        <span className="sr-only">{t("action")}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {pastEvents.map((event) => (
                                    <tr key={event.id}>
                                        <td className="name">
                                            <Paragraph size={ParagraphSize.XSmall}>
                                                {event.product?.name}
                                            </Paragraph>
                                        </td>
                                        <td className="location">
                                            {event.instructorLedTrainingType ===
                                            InstructorLedTrainingType.LiveVirtual
                                                ? "--"
                                                : `${event.city ?? "--"}, ${event.state ?? "--"}`}
                                        </td>
                                        <td className="type">
                                            {getInstructorLedTrainingTypeBadge(
                                                event.instructorLedTrainingType
                                            )}
                                        </td>
                                        <td className="start-date">
                                            <span>
                                                {DateUtils.formatDateCustom(
                                                    event.startDate()?.toString(),
                                                    t("shortDateFormat")
                                                )}{" "}
                                                {event.eventDays != null &&
                                                event.eventDays.length > 1 ? (
                                                    <Icon type={Icons.CalendarMulti} />
                                                ) : (
                                                    <Icon type={Icons.CalendarSingle} />
                                                )}
                                            </span>
                                        </td>
                                        <td className="attendance">
                                            {getEventAttendanceBadge(event)}
                                        </td>
                                        <td className="action">
                                            <Button
                                                text={t("viewTraining")}
                                                onClick={() =>
                                                    navigateToViewEventPath(event.id ?? 0)
                                                }
                                                size={ButtonSize.Small}
                                                style={ButtonStyle.Primary}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </DataTable>
                        <Pager
                            currentPage={currentPage}
                            totalPages={totalNumberOfPastEvents / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={totalNumberOfPastEvents}
                        />
                    </div>
                </div>
            </SkipNavContent>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MyTeachingAssignmentsPage };

// #endregion Exports
