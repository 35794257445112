import React, { useState } from "react";
import { AudienceTypeDisplayNames } from "models/enumerations/audiences/audience-type";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { Modal, ModalAction } from "components/modal/modal";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { useCourse } from "utilities/contexts/use-course-context";
import { CourseService, UpdateCoursePathParams } from "utilities/services/courses/course-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { StringUtils } from "andculturecode-javascript-core";
import { t } from "utilities/localization/t";
import "./course-details-changes-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseDetailsChangesModalProps {
    courseDetailsWithEdits: CourseRecord;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSaveDetailsChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
    showSaveDetailsChangesModal: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-details-changes-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseDetailsChangesModal: React.FC<CourseDetailsChangesModalProps> = ({
    courseDetailsWithEdits,
    setEditMode,
    setRefresh,
    setShowSaveDetailsChangesModal,
    showSaveDetailsChangesModal,
}): JSX.Element => {
    const { setRecord: setCourse } = useCourse();
    const { update } = CourseService.useUpdate();
    const [changeLogDescription, setChangeLogDescription] = useState<string>("");

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowSaveDetailsChangesModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: StringUtils.isEmpty(changeLogDescription),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = () => {
        updateCourseDetails();
        setChangeLogDescription("");
        setShowSaveDetailsChangesModal(false);
        setEditMode(false);
    };

    const updateCourseDetails = async (): Promise<boolean> => {
        try {
            var activeCourse = courseDetailsWithEdits.with({
                changeLogDescription: changeLogDescription,
            });

            const updateCoursePathParms: UpdateCoursePathParams = {
                id: courseDetailsWithEdits.id!,
            };

            const updateCourseResponse = await update(activeCourse, updateCoursePathParms);

            const updateResult = updateCourseResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            setCourse(updateResult.resultObject);
            setRefresh(true);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheCourseDetails"));
            return false;
        }
        return true;
    };

    const handleChangeLogChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setChangeLogDescription(event.target.value);
    };

    return (
        <Modal
            actions={modalActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={showSaveDetailsChangesModal}
            onModalClose={() => setShowSaveDetailsChangesModal(false)}
            title={t("saveCourseDetailsChanges")}>
            <div>
                <Heading
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                    priority={HeadingPriority.H5}>
                    {t("youAreEditingTheCourseDetails")}
                </Heading>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__modal-heading-description`}
                    size={ParagraphSize.XLarge}
                    style={ParagraphStyle.Light}>
                    {t("takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving")}
                </Paragraph>
                <Paragraph
                    cssClassName={`${CSS_CLASS_NAME}__change-log`}
                    size={ParagraphSize.XLarge}>
                    {t("changeLog")}
                </Paragraph>
                <NotificationBanner
                    notificationId={0}
                    onClose={() => {}}
                    style={NotificationType.Default}
                    message={t(
                        "changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                    )}
                />
                <FormTextArea
                    ariaLabelledBy={t("describeYourChanges")}
                    cssClassName={`${CSS_CLASS_NAME}__text-area`}
                    formFieldName={t("describeYourChanges")}
                    id="describeYourChanges"
                    label={t("describeYourChanges")}
                    maxLength={1000}
                    onChange={handleChangeLogChange}
                    placeholder={t("describeYourChanges")}
                    required={true}
                    rows={6}
                    value={changeLogDescription}
                />
                <div className={`${CSS_CLASS_NAME}__details ${CSS_CLASS_NAME}__section`}>
                    <div className={`${CSS_CLASS_NAME}__details__header`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("courseDetails")}
                        </Heading>
                        <Badge
                            icon={Icons.Lock}
                            style={BadgeStyle.Light}
                            text={t(AudienceTypeDisplayNames[courseDetailsWithEdits.audienceType])}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__content`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("courseName")}</Paragraph>
                            <Paragraph>{courseDetailsWithEdits.name}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("courseDescription")}
                            </Paragraph>
                            <Paragraph>{courseDetailsWithEdits.description}</Paragraph>
                        </div>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details__metadata`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                            <Paragraph>
                                {t(TopicDisplayNames[courseDetailsWithEdits.topic!])}
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("language")}</Paragraph>
                            <Paragraph>
                                {t(LanguageDisplayNames[courseDetailsWithEdits.language!])}
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("courseId")}</Paragraph>
                            <Paragraph>{courseDetailsWithEdits.id}</Paragraph>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDetailsChangesModal };

// #endregion Exports
