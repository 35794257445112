import { Button } from "components/buttons/button/button";
import { useSeededUsers } from "utilities/hooks/models/seeded-user/use-seeded-users";
import { t } from "utilities/localization/t";
import "./seeded-user-login.scss";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "c-seeded-user";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SeededUserLoginProps {
    onError?: (error: Error) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SeededUserLogin: React.FC<SeededUserLoginProps> = ({ onError }) => {
    const { seededUsers, authenticate } = useSeededUsers({
        onError,
    });

    return (
        <>
            <h1 className={`${CSS_CLASS_NAME}__header`}>{t("authenticateAs")}</h1>
            <div className={`${CSS_CLASS_NAME}__login-buttons`}>
                {
                    // if
                    seededUsers &&
                        seededUsers.map(
                            (seededUser): JSX.Element => (
                                <Button
                                    key={seededUser.userName}
                                    onClick={() => authenticate(seededUser)}
                                    text={seededUser.displayName}
                                />
                            )
                        )
                }
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SeededUserLogin };

// #endregion Exports
