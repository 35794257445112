import { CollectionUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

const difference = <T extends object>(target: T, exclude: T): Partial<T> => {
    const targetEntries = _entriesToArrayOfStringifiedSingleObjects(target);
    const excludeEntries = _entriesToArrayOfStringifiedSingleObjects(exclude);

    const differentEntries = CollectionUtils.difference(targetEntries, excludeEntries);

    return _flattenAndSerializeArrayOfStringifiedObjects(differentEntries);
};

const hasDifferentValues = <T extends object>(a: T, b: T): boolean => !hasSameValues(a, b);

const hasSameValues = <T extends object>(a: T, b: T): boolean =>
    CollectionUtils.isEmpty(Object.keys(difference(a, b))) &&
    CollectionUtils.isEmpty(Object.keys(difference(b, a)));

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Private Helper Functions
// -------------------------------------------------------------------------------------------------

const _entriesToArrayOfStringifiedSingleObjects = (obj: object): string[] =>
    Object.entries(obj).map(([key, value]: [string, any]): string =>
        JSON.stringify({ [key]: value })
    );

const _flattenAndSerializeArrayOfStringifiedObjects = (objs: string[]): object =>
    objs.reduce(
        (acc: object, cur: string): object => ({
            ...acc,
            ...JSON.parse(cur),
        }),
        {}
    );

// #endregion Private Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ObjectUtils = {
    difference: difference,
    hasDifferentValues: hasDifferentValues,
    hasSameValues: hasSameValues,
};

export { ObjectUtils };

// #endregion Exports
