import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ReportRecord } from "models/view-models/reports/report-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { ReportService } from "utilities/services/reports/report-service";
import { StringUtils } from "utilities/string-utils";

interface UseReportHook {
    report?: ReportRecord;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}
interface UseReportHookOptions {
    slug?: string;
}

export function useReport({ slug }: UseReportHookOptions): UseReportHook {
    const [isLoading, setIsLoading] = useState(!StringUtils.isEmpty(slug));
    const [report, setReport] = useState<ReportRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { get: getReport } = ReportService.useGet();

    useEffect(() => {
        if (StringUtils.isEmpty(slug)) {
            return;
        }

        setIsLoading(true);

        try {
            (async function () {
                const { result, resultObject } = await getReport({ slug });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setReport(resultObject);
            })();
        } catch {
            setErrors([
                new ResultErrorRecord({
                    message: t("thereWasAProblemGettingAvailableReports"),
                }),
            ]);
        }
    }, [getReport, slug]);

    return {
        errors,
        isLoading,
        report,
    };
}
