import DatePicker from "react-datepicker";
import React, { forwardRef, useMemo } from "react";
import { DateUtils } from "utilities/date-utils";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { LocalizationUtils } from "utilities/localization-utils";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar-date-picker.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export enum CalendarIconPosition {
    Left,
    Right,
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CalendarDatePickerProps {
    calendarIconPosition?: CalendarIconPosition;
    minDate?: Date;
    placeholder?: TranslatedCopy;
    readOnly: boolean;
    selectedDate?: Date;
    onBlur?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    onChange: (date?: Date) => void;
    setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
    tabIndex?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "calendar-date-picker";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CalendarDatePicker: React.FC<CalendarDatePickerProps> = ({
    calendarIconPosition,
    minDate,
    readOnly,
    onBlur,
    onChange,
    placeholder,
    selectedDate,
    setSelectedDate,
    tabIndex,
}) => {
    const { readOnly: isCtxReadOnly } = useReadOnly();
    const disabled = useMemo(() => readOnly || isCtxReadOnly, [isCtxReadOnly, readOnly]);

    const iconPosition: CalendarIconPosition =
        calendarIconPosition == null ? CalendarIconPosition.Left : CalendarIconPosition.Right;

    const placeholderText = placeholder == null ? "selectADate" : placeholder;

    let className = CSS_CLASS_NAME;
    if (disabled) {
        className += " -disabled";
    }

    const CustomInput = forwardRef(
        (
            {
                onClick,
            }: { onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void },
            ref
        ) => (
            <button
                type="button"
                className={`${CSS_CLASS_NAME}__text ${className}`}
                onBlur={onBlur}
                onClick={onClick}
                tabIndex={tabIndex}>
                {iconPosition === CalendarIconPosition.Left && (
                    <Icon cssClassName={`${CSS_CLASS_NAME}__calendar`} type={Icons.Calendar} />
                )}
                <div className={`${CSS_CLASS_NAME}__label`}>
                    {selectedDate
                        ? DateUtils.formatDateCustom(selectedDate.toString(), t("shortDateFormat"))
                        : t(placeholderText)}
                </div>
                {iconPosition === CalendarIconPosition.Left ? (
                    <Icon
                        cssClassName={`${CSS_CLASS_NAME}__chevron`}
                        type={Icons.ChevronDown}
                        size={IconSizes.Small}
                    />
                ) : (
                    <Icon cssClassName={`${CSS_CLASS_NAME}__calendar`} type={Icons.Calendar} />
                )}
            </button>
        )
    );

    return (
        <DatePicker
            dateFormat={t("datePickerDateFormat")}
            readOnly={disabled}
            selected={selectedDate}
            onChange={(date) => onChange(date ?? undefined)}
            customInput={React.createElement(CustomInput)}
            minDate={minDate}
            locale={LocalizationUtils.currentCultureCode()}
        />
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CalendarDatePicker };

// #endregion Exports
