import { EnrollmentManager } from "components/enrollments/enrollment-manager/enrollment-manager";
import { NumberUtils } from "utilities/number-utils";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import "./admin-event-enrollment-list-page.scss";
import { useEvent } from "utilities/contexts/use-event-context";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEventEnrollmentListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-event-enrollment-list-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEventEnrollmentListPage: React.FC<
    AdminEventEnrollmentListPageProps
> = (): JSX.Element => {
    const { productId: productIdParam } = useParams();
    const { eventId: eventIdParam } = useParams();
    const productId = useMemo(() => NumberUtils.parseInt(productIdParam) ?? 0, [productIdParam]);
    const eventId = useMemo(() => NumberUtils.parseInt(eventIdParam) ?? 0, [eventIdParam]);
    const { record: event } = useEvent();

    return (
        <div className={CSS_CLASS_NAME}>
            <EnrollmentManager
                productId={productId}
                eventId={eventId}
                eventProductVersionId={event.productVersionId}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEventEnrollmentListPage };

// #endregion Exports
