import React from "react";
import { Button, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsFooterProps {
    onRunReportClick: () => void;
    runReportEnabled?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportsFooter: React.FC<ReportsFooterProps> = ({
    onRunReportClick,
    runReportEnabled,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <Button
                linkPath={sitemap.admin.reports.list}
                style={ButtonStyle.Secondary}
                text={t("cancel")}
                type={ButtonType.Link}
            />
            <Button
                onClick={onRunReportClick}
                disabled={runReportEnabled != null && !runReportEnabled}
                style={ButtonStyle.Primary}
                text={t("runReport")}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportsFooter };

// #endregion Exports
