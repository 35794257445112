import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { UserLoginRecord } from "models/view-models/users/user-login-record";
import React, { useCallback, useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useIdentity } from "utilities/hooks/use-identity";
import {
    UpdateUserLoginPathParams,
    UserLoginService,
} from "utilities/services/users/user-login-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useGetUserDashboard } from "utilities/hooks/use-get-user-dashboard";
import "./user-role-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserRoleCardProps {
    userRole: UserRoleRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "user-role-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const UserRoleCard: React.FC<UserRoleCardProps> = (
    props: UserRoleCardProps
): JSX.Element | null => {
    const { record: globalState, setRecord: setGlobalState } = useGlobalState();
    const { update: updateUserLoginApi } = UserLoginService.useUpdate();
    const { getIdentity } = useIdentity();
    const { navigateToDashboard } = useGetUserDashboard({
        userRole: globalState.currentIdentity?.role?.roleType,
        language: globalState.currentIdentity?.user?.preferredLanguage,
    });

    const handleSwitchRole = useCallback(async () => {
        if (!globalState.isAuthenticated() || !globalState.currentIdentity?.hasUserLoginId()) {
            return;
        }

        try {
            const pathParams: UpdateUserLoginPathParams = {
                id: globalState.currentIdentity?.userLoginId(),
            };

            const updatedLogin: UserLoginRecord = globalState.currentIdentity!.userLogin!.with({
                roleId: props.userRole.roleId,
            });

            const response = await updateUserLoginApi(updatedLogin, pathParams);
            const result = response?.result;

            if (result?.resultObject == null || result.hasErrors()) {
                throw new Error();
            }

            try {
                const identity = await getIdentity(result.resultObject);
                setGlobalState((currentState) => currentState.setIdentity(identity));
                ToastManager.success(
                    t("youAreLoggedInWithYourIdentityRoleNameRole", {
                        identityRoleName: identity?.role?.name,
                    })
                );

                navigateToDashboard();
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingUserInfo"));
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueSwitchingProfiles"));
            setGlobalState((currentState) => currentState.setUnauthenticated());
        }
    }, [
        getIdentity,
        globalState,
        navigateToDashboard,
        props.userRole.roleId,
        setGlobalState,
        updateUserLoginApi,
    ]);

    const isCurrentRole: boolean = useMemo((): boolean => {
        return globalState.currentIdentity?.role?.id === props.userRole.roleId;
    }, [globalState.currentIdentity?.role?.id, props.userRole.roleId]);

    const selectionContent: JSX.Element = useMemo(
        (): JSX.Element =>
            isCurrentRole ? (
                <p className={`${CSS_CLASS_NAME}__selected`}>{t("selected")}</p>
            ) : (
                <Button
                    onClick={handleSwitchRole}
                    size={ButtonSize.Medium}
                    style={ButtonStyle.Secondary}
                    text={t("select")}
                />
            ),
        [handleSwitchRole, isCurrentRole]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Icon
                    cssClassName={`${CSS_CLASS_NAME}__icon`}
                    size={IconSizes.XLarge}
                    type={Icons.PersonOutline}
                />
                <p className={`${CSS_CLASS_NAME}__name`}>
                    {props.userRole.role?.name?.toLocaleUpperCase()}
                </p>
            </div>
            {selectionContent}
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserRoleCard };

// #endregion Exports
