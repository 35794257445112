import { CollectionUtils } from "utilities/collection-utils";
import { InstructorApplication } from "models/interfaces/aen-applications/instructor-application";
import { InstructorApplicationFileRecord } from "./instructor-application-file-record";
import { InstructorApplicationStatus } from "models/enumerations/aen-applications/instructor-application-status";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorApplication = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    updatedById: undefined,
    updatedOn: undefined,

    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    stateProvince: "",
    country: "",
    zipcode: "",
    email: "",
    phone: "",
    linkedInProfile: "",
    isCurrentNfpaMember: false,
    isTechnicalCommitteeMember: false,
    whichTechnicalCommittees: "",
    isCurrentApprovedNfpaInstructor: false,
    isPreviouslyApprovedNfpaInstructor: false,
    whichCoursesPreviouslyApprovedAsInstructor: "",
    whyWantToBecomeNfpaInstructor: "",
    aenDirectoryAvailability: undefined,
    providerName: "",

    // Signature
    signatureName: "",
    signatureTitle: "",
    signatureDate: undefined,

    // Teaching Experience
    hasExperienceTrainingBasedOnNfpaCodes: false,
    describeExperienceTrainingBasedOnNfpaCodes: "",
    hasExperienceVirtualTraining: false,
    describeExperienceVirtualTraining: "",

    // Interested Topic Areas
    topicAreasInterestedInTeaching: "",
    topicAreasInterestedInTeachingOther: "",
    educationQualifications: "",
    certificationQualifications: "",
    workExperienceQualifications: "",

    // CV
    references: "",
    uploadVideo: "",

    // Status/Assignee
    status: InstructorApplicationStatus.Submitted,
    assignedToId: undefined,

    // SubmittedBy
    submittedByExternalIdentifier: "",
    submittedByNfpaWebId: undefined,
    submittedByNetSuiteId: undefined,

    // Navigation properties
    files: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorApplicationRecord extends Record(defaultValues) implements InstructorApplication {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorApplication>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.files)) {
            params.files = RecordUtils.ensureRecords(params.files, InstructorApplicationFileRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Returns the application status
     */
    public getApplicationStatus(): InstructorApplicationStatus | undefined {
        return this.status;
    }

    public with(values: Partial<InstructorApplication>): InstructorApplicationRecord {
        return new InstructorApplicationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationRecord };

// #endregion Exports
