import axios from "axios";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Language } from "models/enumerations/languages/language";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductRecord } from "models/view-models/products/product-record";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { ServiceHookFactory } from "utilities/services/service-hook-factory";
import { ServiceFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { Topic } from "models/enumerations/courses/topic";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useCallback } from "react";
import { AudienceType } from "models/enumerations/audiences/audience-type";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "products";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const archiveEndpoint: string = `${resourceEndpoint}/archive`;
const unpublishEndpoint: string = `${resourceEndpoint}/unpublish`;
const countResourceEndpoint = `${baseEndpoint}/count`;
const recordType: typeof ProductRecord = ProductRecord;
const resourceType: typeof ProductRecord = ProductRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchProductService = (
    params: PatchProductServiceParams
) => Promise<ServiceResponse<ProductRecord>>;

type ArchiveProductService = (
    params: ArchiveProductServiceParams
) => Promise<ServiceResponse<ProductRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region enum
// -------------------------------------------------------------------------------------------------

export enum PatchRoutes {
    Activate = "activate",
    Deactivate = "deactivate",
}

// #endregion enum

export interface PatchProductPathParams {
    id: number;
}

export interface ArchiveProductServiceParams {
    id: number;
}

export interface GetProductPathParams {
    id: number;
}

export interface GetProductQueryParams {
    includeAssociatedProduct?: boolean;
    includeContent?: boolean;
    includeEvaluationTemplate?: boolean;
    includeProductScormPackage?: boolean;
    includeUnit?: boolean;
    includeProductImageFile?: boolean;
}

export interface ListProductsQueryParams {
    applySearch?: boolean;
    filterActiveAndDraft?: boolean;
    activeStatusFilter: ActiveStatus[];
    includeArchived?: boolean;
    includeAvailableForAENOnly?: boolean;
    includeCreatedBy?: boolean;
    includeLatestVersion?: boolean;
    includeLastModifiedBy?: boolean;
    audienceType?: AudienceType;
    language?: Language;
    onlineLearningType?: ProductCourseType;
    searchProductNameText?: string;
    searchText?: string;
    skip?: number;
    take?: number;
    topic?: Topic;
    trainingType?: TrainingType;
}

export interface PatchProductServiceParams {
    productId: number;
    patchRoute: PatchRoutes;
}

export interface UpdateProductPathParams {
    id: number;
}

export interface CountProductsQueryParams {
    courseId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductService = {
    /**
     * Create a Product
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update a Product
     */
    update: ServiceFactory.update<ProductRecord, UpdateProductPathParams>(
        resourceType,
        resourceEndpoint
    ),

    useArchive: (): { archive: ArchiveProductService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: ArchiveProductService = (
            params: ArchiveProductServiceParams
        ): Promise<ServiceResponse<ProductRecord>> => {
            const endPointWithPatchRouteReplaced = archiveEndpoint.replace(
                ":id",
                params.id.toString()
            );
            return axios
                .patch(endPointWithPatchRouteReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: ArchiveProductServiceParams
        ): Promise<ServiceResponse<ProductRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<ProductRecord>
            >;
        }

        return { archive: useCallback(patch, [cancellablePromise]) };
    },

    useCount: ServiceHookFactory.useGet<Number, {}, CountProductsQueryParams>(
        Number,
        countResourceEndpoint
    ),

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<ProductRecord, GetProductPathParams, GetProductQueryParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ProductRecord, ListProductsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    usePatch: (): { patch: PatchProductService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchProductService = (
            params: PatchProductServiceParams
        ): Promise<ServiceResponse<ProductRecord>> => {
            const endpointWithPatchRouteAndPathParamsReplaced =
                resourceEndpoint.replace(":id", params.productId.toString()) +
                "/" +
                params.patchRoute;

            return axios
                .patch(endpointWithPatchRouteAndPathParamsReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function patch(params: PatchProductServiceParams): Promise<ServiceResponse<ProductRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<ProductRecord>
            >;
        }

        return { patch: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom Hook to cancel an Event.
     */
    useUnpublish: ServiceHookFactory.usePatch<ProductRecord, PatchProductPathParams>(
        ProductRecord,
        unpublishEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<ProductRecord, UpdateProductPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductService };

// #endregion Exports
