import { Communication } from "../../interfaces/communications/communication";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultBody = "<p><br></p>";

const defaultValues: Communication = {
    body: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    eventIds: undefined,
    groupIds: undefined,
    id: undefined,
    recipientEmails: undefined,
    subject: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class CommunicationRecord extends Record(defaultValues) implements Communication {
    // -------------------------------------------------------------------------------------------------
    // #region Static Properties
    // -------------------------------------------------------------------------------------------------

    public static DefaultBody: string = defaultBody;

    // #endregion Static Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Communication>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for Communication properties, create a new CommunicationRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Communication>} values The values to overwrite on this instance.
     * @returns An CommunicationRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Communication>): CommunicationRecord {
        return new CommunicationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CommunicationRecord };

// #endregion Exports
