import { Card } from "components/card/card";
import { Icons } from "components/icons/constants/icons";
import { AnchorIcon } from "components/typography/anchors/anchor-icon/anchor-icon";
import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ContentRecord } from "models/view-models/contents/content-record";
import { FileUtils } from "utilities/files/file-utils";
import { t } from "utilities/localization/t";
import "./event-content-list-item.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventContentListItemProps {
    contentItem?: ContentRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-content-list-item";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventContentListItem: React.FC<EventContentListItemProps> = (
    props: EventContentListItemProps
): JSX.Element | null => {
    if (props.contentItem == null) {
        return null;
    }

    const { fileId } = props.contentItem;

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div>
                <p className={`${CSS_CLASS_NAME}__name`}>{props.contentItem.name}</p>
                <p className={`${CSS_CLASS_NAME}__description`}>{props.contentItem.description}</p>
            </div>

            {fileId != null && (
                <AnchorIcon
                    ariaLabel={t("downloadButtonForPropsContentItemName", {
                        propsContentItemName: props.contentItem.name,
                    })}
                    cssClassName={`${CSS_CLASS_NAME}__download-icon`}
                    iconType={Icons.Download}
                    pathType={AnchorPathType.External}
                    path={FileUtils.fileUrl(fileId)}
                />
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContentListItem };

// #endregion Exports
