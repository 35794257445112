import React, { useState } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentStatus,
    EnrollmentStatusDisplayNames,
    EnrollmentStatusStatusMap,
} from "models/enumerations/enrollments/enrollment-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { Pager } from "components/pager/pager";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { RouteUtils } from "utilities/route-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { sitemap } from "sitemap";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { AchievementModal } from "components/achievements/achievement-modal/achievement-modal";
import { t } from "utilities/localization/t";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { StringUtils } from "utilities/string-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import "./learner-past-trainings.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerPastTrainingsProps {
    userId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-past-trainings";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getTrainingPath = (enrollment: EnrollmentRecord) => {
    if (enrollment.id == null) {
        return "";
    }

    if (enrollment.eventId != null && enrollment.eventId > 0) {
        return getEventTrainingPath(enrollment.id, enrollment.eventId);
    }

    return getOLLTrainingPath(enrollment.id, enrollment.productId);
};

const getEventTrainingPath = (enrollmentId: number, eventId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.event.details, {
        id: eventId,
        enrollmentId: enrollmentId,
    });
};

const getOLLTrainingPath = (enrollmentId: number, productId: number) => {
    return RouteUtils.replacePathParams(sitemap.learner.training.onlineLearning.details, {
        productId: productId,
        enrollmentId: enrollmentId,
    });
};

const getTrainingSummaryPath = (enrollment: EnrollmentRecord) => {
    if (enrollment.eventId != null && enrollment.eventId > 0) {
        return RouteUtils.replacePathParams(sitemap.learner.training.event.summary, {
            id: enrollment.eventId,
            enrollmentId: enrollment.id,
        });
    }

    return RouteUtils.replacePathParams(sitemap.learner.training.onlineLearning.summary, {
        productId: enrollment.productId,
        enrollmentId: enrollment.id,
    });
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerPastTrainings: React.FC<LearnerPastTrainingsProps> = ({ userId }): JSX.Element => {
    const [showAchievementModal, setShowAchievementModal] = useState(false);
    const [achievementEnrollment, setAchievementEnrollment] = useState<EnrollmentRecord>();
    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { enrollments: pastEnrollments, rowCount: totalNumberOfPastEnrollments } =
        useUserEnrollments({
            userId: userId,
            includeProduct: true,
            includeEvent: true,
            includeEvaluation: true,
            includeBadge: true,
            includeCertificate: true,
            includeEventSchedule: true,
            enrollmentActiveStatus: EnrollmentActiveStatus.Inactive,
            audienceType: AudienceType.Learner,
            searchText: debouncedSearchText,
            skip: (currentPage - 1) * ITEMS_PER_PAGE,
            take: ITEMS_PER_PAGE,
        });

    const handleSearchTextInputChange = (searchText: string) => {
        setCurrentPage(1);
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        ScrollUtils.scrollToElementBySelector(`.${CSS_CLASS_NAME}`);
    };

    const getEnrollmentStatusBadge = (enrollment: EnrollmentRecord): JSX.Element => {
        const status = enrollment.getStatus();

        if (status === EnrollmentStatus.Withdrawn) {
            return (
                <Badge style={BadgeStyle.Error}>
                    <Tooltip
                        content={t(
                            "youWereWithdrawnFromThisTrainingOnWithdrawnDateForAnyQuestionsPleaseContactNFPASupport",
                            {
                                withdrawnDate: DateUtils.formatDateCustom(
                                    enrollment.withdrawnOn?.toString(),
                                    t("shortDateFormat")
                                ),
                            }
                        )}
                        placement={TooltipPlacement.Top}>
                        <span>
                            {t(EnrollmentStatusDisplayNames[status])}
                            <Icon
                                type={Icons.InformationOutline}
                                cssClassName={`${CSS_CLASS_NAME}__withdrawn-icon`}
                            />
                        </span>
                    </Tooltip>
                </Badge>
            );
        }

        if (status === EnrollmentStatus.Expired) {
            return (
                <Badge style={BadgeStyle.Default}>
                    <Tooltip
                        content={t("thisTrainingExpiredWithIncompleteItems")}
                        placement={TooltipPlacement.Top}>
                        <span>
                            {t(EnrollmentStatusDisplayNames[status])}{" "}
                            <Icon
                                type={Icons.InformationOutline}
                                cssClassName={`${CSS_CLASS_NAME}__incomplete-icon`}
                            />
                        </span>
                    </Tooltip>
                </Badge>
            );
        }
        return (
            <EnumStatusBadge
                displayNames={EnrollmentStatusDisplayNames}
                statusMap={EnrollmentStatusStatusMap}
                value={enrollment.getStatus()}
            />
        );
    };

    const handleDownloadCertificateClick = async (enrollmentId: number, certificateId: number) => {
        const pathParams: DownloadCertificatePathParams = {
            enrollmentId: enrollmentId,
            id: certificateId,
        };

        const queryParams: DownloadCertificateQueryParams = {
            overwriteExisting: false,
        };

        await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
    };

    const handleViewAchievementsClick = (enrollment: EnrollmentRecord) => {
        setAchievementEnrollment(enrollment);
        setShowAchievementModal(true);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__search`}>
                    <SearchTextInput
                        debounce={DEBOUNCE_TIME}
                        onSearchTextInputChange={handleSearchTextInputChange}
                        onSearchTriggered={handleSearchTriggered}
                        id="courseSearch"
                        placeholder={t("typeToSearchByTrainingName")}
                        searchTextInputValue={searchText}
                    />
                </div>
            </div>
            {CollectionUtils.hasValues(pastEnrollments) && (
                <>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="name">{t("name")}</th>
                                <th className="type">{t("type")}</th>
                                <th className="expiration-date">{t("trainingAccessExpiration")}</th>
                                <th className="certificate-expiration-date">
                                    {t("certificateExpiration")}
                                </th>
                                <th className="status">{t("status")}</th>
                                <th className="completion-date">{t("completionDate")}</th>
                                <th className="ceus">{t("ceus")}</th>
                                <th className="-ellipsis">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pastEnrollments.map((enrollment, index) => (
                                <tr key={`${enrollment.id}-${index}`}>
                                    <td className="name">
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {`${enrollment.product?.name}`}
                                        </Paragraph>
                                    </td>
                                    <td className="type">
                                        {enrollment.product != null && (
                                            <ProductBadge
                                                product={enrollment.product}
                                                event={enrollment.event}
                                                includeIcon={false}
                                                upperCase={false}
                                            />
                                        )}
                                    </td>
                                    <td className="expiration-date">
                                        <span>
                                            {enrollment.expirationDate
                                                ? DateUtils.formatDateCustom(
                                                      enrollment.expirationDate?.toString(),
                                                      t("shortDateFormat")
                                                  )
                                                : "--"}
                                        </span>
                                    </td>
                                    <td className="certificate-expiration-date">
                                        <span>
                                            {enrollment.certificate?.expirationDate
                                                ? DateUtils.formatDateCustom(
                                                      enrollment.certificate?.expirationDate?.toString(),
                                                      t("shortDateFormat")
                                                  )
                                                : "--"}
                                        </span>
                                    </td>
                                    <td className="status">
                                        {getEnrollmentStatusBadge(enrollment)}
                                    </td>
                                    <td className="completion-date">
                                        {enrollment.completedOn == null
                                            ? `--`
                                            : DateUtils.formatDateCustom(
                                                  enrollment.completedOn.toString(),
                                                  t("shortDateFormat")
                                              )}
                                    </td>
                                    <td className="ceus">
                                        {enrollment.eventId == null
                                            ? enrollment.productVersion?.getCeusDisplay() ?? `--`
                                            : enrollment.event?.getCeusDisplay()}
                                    </td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                disabled={!enrollment.isComplete()}
                                                onClick={() =>
                                                    handleViewAchievementsClick(enrollment)
                                                }
                                                displayName={t("viewAchievements")}
                                            />
                                            {enrollment.hasTrainingAccess() ||
                                            enrollment.getStatus() === EnrollmentStatus.Canceled ? (
                                                <ContextMenuAnchor
                                                    hrefPath={getTrainingPath(enrollment)}
                                                    displayName={t("viewTraining")}
                                                />
                                            ) : (
                                                <ContextMenuAnchor
                                                    disabled={enrollment.withdrawnOn != null}
                                                    hrefPath={getTrainingSummaryPath(enrollment)}
                                                    displayName={t("viewSummary")}
                                                />
                                            )}
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                    <Pager
                        currentPage={currentPage}
                        totalPages={totalNumberOfPastEnrollments / ITEMS_PER_PAGE}
                        onPageClick={onPageClick}
                        itemsPerPage={ITEMS_PER_PAGE}
                        totalItems={totalNumberOfPastEnrollments}
                    />
                </>
            )}

            {!CollectionUtils.hasValues(pastEnrollments) && !StringUtils.hasValue(searchText) && (
                <EmptyText table>{t("youHaveNoPastTrainings")}</EmptyText>
            )}

            {!CollectionUtils.hasValues(pastEnrollments) && StringUtils.hasValue(searchText) && (
                <EmptyText table>{t("noTrainingResultsFound")}</EmptyText>
            )}

            <AchievementModal
                showAchievementModal={showAchievementModal}
                setShowAchievementModal={setShowAchievementModal}
                productName={achievementEnrollment?.product?.name ?? ""}
                onCertificateClick={() =>
                    handleDownloadCertificateClick(
                        achievementEnrollment!.id!,
                        achievementEnrollment!.certificate!.id!
                    )
                }
                badgeUrl={
                    achievementEnrollment?.badge?.url == null
                        ? undefined
                        : achievementEnrollment?.badge?.url!
                }
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerPastTrainings };

// #endregion Exports
