import { CollectionUtils } from "andculturecode-javascript-core";
import { DataTable } from "components/tables/data-table/data-table";
import React, { useCallback, useEffect, useState } from "react";
import { Card } from "components/card/card";
import {
    EvaluationService,
    GetEvaluationPathParams,
    GetEvaluationQueryParams,
} from "utilities/services/evaluations/evaluation-service";
import { EvaluationRecord } from "models/view-models/evaluations/evaluation-record";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { EvaluationQuestionType } from "models/enumerations/evaluations/evaluation-question-type";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./submitted-evaluation.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SubmittedEvaluationProps {
    evaluationId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "submitted-evaluation";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SubmittedEvaluation: React.FC<SubmittedEvaluationProps> = (
    props: SubmittedEvaluationProps
): JSX.Element => {
    const [evaluation, setEvaluation] = useState<EvaluationRecord>();
    const { get: getEvaluation } = EvaluationService.useGet();

    const fetchData = useCallback(async () => {
        // Get Evaluation Responses.
        const getEvalPathParams: GetEvaluationPathParams = {
            id: props.evaluationId,
        };

        const getEvalQueryParams: GetEvaluationQueryParams = {
            includeResponses: true,
            includeQuestions: true,
            includeTemplate: true,
        };

        try {
            var getEvaluationResponse = await getEvaluation(getEvalPathParams, getEvalQueryParams);
            var getEvaluationResult = getEvaluationResponse?.result;

            if (getEvaluationResult?.resultObject == null || getEvaluationResult.hasErrors()) {
                throw new Error();
            }

            const evaluation = getEvaluationResult.resultObject;

            evaluation.sortResponsesByQuestionOrder();

            setEvaluation(evaluation);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheEvaluation"));
        }
    }, [getEvaluation, props.evaluationId]);

    const getResponseDisplay = (question: EvaluationQuestionTemplateRecord): string => {
        const responsesForQuestion = evaluation?.responses?.filter(
            (r) => r.evaluationQuestionTemplateId === question.id
        );

        if (!CollectionUtils.hasValues(responsesForQuestion)) {
            return "";
        }

        if (question.type === EvaluationQuestionType.FreeResponse) {
            if (responsesForQuestion![0] === undefined || responsesForQuestion![0] === null) {
                return "";
            }

            return responsesForQuestion![0].freeResponse ?? "";
        }

        return (
            responsesForQuestion!.map((r) => r.evaluationAnswerTemplate?.answerText).join(", ") ??
            ""
        );
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={CSS_CLASS_NAME}>
            <Card>
                <h3>{t("yourResponses")}</h3>
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className={`${CSS_CLASS_NAME}__question`}>{t("question")}</th>
                            <th className={`${CSS_CLASS_NAME}__answer`}>{t("answer")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {evaluation !== undefined &&
                            CollectionUtils.hasValues(evaluation.evaluationTemplate?.questions) &&
                            evaluation.evaluationTemplate?.questions?.map(
                                (question, index) =>
                                    question && (
                                        <tr key={index}>
                                            <td className={`${CSS_CLASS_NAME}__question`}>
                                                {question.questionText}
                                            </td>
                                            <td className={`${CSS_CLASS_NAME}__answer`}>
                                                {getResponseDisplay(question)}
                                            </td>
                                        </tr>
                                    )
                            )}
                    </tbody>
                </DataTable>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SubmittedEvaluation };

// #endregion Exports
