import React from "react";
import { InputTypes } from "components/form/enumerations/input-types";
import { InputProperties } from "components/form/input-properties";
import { TextInput } from "components/form/inputs/text-inputs/text-input/text-input";
import { FormField } from "components/form/form-field/form-field";
import { FormFieldProps } from "components/form/form-field/form-field";
import { StringUtils } from "utilities/string-utils";
import "./form-text-input.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormTextInputProps extends FormFieldProps, InputProperties {
    errorMessage?: string;
    errorMessages?: string[];
    hasErrors?: boolean;
    /**
     * Max number of characters the input will accept. If not set, input has no character limit.
     */
    maxLength?: number;
    /**
     * Max value of number the input will accept. If not set, input has no number value limit.
     */
    maxNumber?: number;
    minValue?: number;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-text-input";
const ERROR_CLASS_NAME: string = "-error";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FormTextInput: React.FC<FormTextInputProps> = (props: FormTextInputProps) => {
    const type = props.type ?? InputTypes.Text;
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.errorMessage)) {
        classNames.push(ERROR_CLASS_NAME);
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <FormField
            cssClassName={className}
            errorMessage={props.errorMessage}
            errorMessages={props.errorMessages}
            formFieldName={props.formFieldName}
            label={props.label}
            labelScreenReaderOnly={props.labelScreenReaderOnly}
            required={props.required}>
            <TextInput
                ariaLabelledBy={props.ariaLabelledBy}
                autoFocus={props.autoFocus}
                cssClassName={`${CSS_CLASS_NAME}__input`}
                disabled={props.disabled}
                id={props.formFieldName}
                maxLength={props.maxLength}
                maxNumber={props.maxNumber}
                minValue={props.minValue}
                name={props.formFieldName}
                onBlur={props.onBlur}
                onChange={props.onChange}
                placeholder={props.placeholder}
                required={props.required}
                type={type}
                value={props.value}
            />
        </FormField>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormTextInput };

// #endregion Exports
