import { Map } from "utilities/types/map";
import { Status } from "utilities/enumerations/statuses";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentScormPackageAssessmentStatus {
    NotStarted = 0,
    InProgress = 1,
    Attempted = 2,
    Complete = 3,
    Incomplete = 4,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EnrollmentScormPackageAssessmentStatusDisplayNames: Map<
    EnrollmentScormPackageAssessmentStatus,
    TranslatedCopy
> = {
    [EnrollmentScormPackageAssessmentStatus.NotStarted]: "notStarted",
    [EnrollmentScormPackageAssessmentStatus.InProgress]: "inProgress",
    [EnrollmentScormPackageAssessmentStatus.Attempted]: "attempted",
    [EnrollmentScormPackageAssessmentStatus.Complete]: "complete",
    [EnrollmentScormPackageAssessmentStatus.Incomplete]: "incomplete",
};

const EnrollmentScormPackageAssessmentStatusMap: Map<
    EnrollmentScormPackageAssessmentStatus,
    Status
> = {
    [EnrollmentScormPackageAssessmentStatus.NotStarted]: Status.Default,
    [EnrollmentScormPackageAssessmentStatus.InProgress]: Status.Warning,
    [EnrollmentScormPackageAssessmentStatus.Attempted]: Status.Warning,
    [EnrollmentScormPackageAssessmentStatus.Complete]: Status.Success,
    [EnrollmentScormPackageAssessmentStatus.Incomplete]: Status.Inverted,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export {
    EnrollmentScormPackageAssessmentStatus,
    EnrollmentScormPackageAssessmentStatusDisplayNames,
    EnrollmentScormPackageAssessmentStatusMap,
};

// #endregion Exports
