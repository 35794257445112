import React, { ReactNode } from "react";
import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { StringUtils } from "utilities/string-utils";
import { Badge, BadgeStyle } from "../badges/badge/badge";
import { ButtonIconProps } from "components/buttons/button-icon/button-icon";
import { Icon } from "components/icons/icon";
import { Donut, DonutSize, DonutStyle } from "../donut/donut";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "../typography/paragraph/paragraph";
import { ProcessNavigationSidebar } from "../processes/navigation/process-navigation-sidebar/process-navigation-sidebar";
import { Process } from "../../utilities/types/processes/process";
import { ProcessNavigator } from "../../utilities/hooks/processes/use-process-navigator";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./process-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProcessSidebarProps<T> {
    badges?: TranslatedCopy[];
    completionPercentage?: number;
    cssClassName?: string;
    title?: string;
    subTitle?: string;
    titleEmphasis?: ParagraphEmphasis;
    headerActionIcons?: ButtonIconProps[];
    isActive?: boolean;
    statusBadge?: ReactNode;
    objectId: number;
    process: Process<T>;
    processNavigator: ProcessNavigator<T>;
    showActiveBadge?: boolean;
    showProgressChart?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "process-sidebar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProcessSidebar = <T,>(
    props: React.PropsWithChildren<ProcessSidebarProps<T>>
): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];
    const badges: TranslatedCopy[] = props.badges == null ? [] : props.badges;

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <aside className={className}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <div className={`${CSS_CLASS_NAME}__header__badges`}>{props.statusBadge}</div>
                <div className={`${CSS_CLASS_NAME}__header__actions`}>
                    {props.headerActionIcons?.map((actionIcon, i) => (
                        <button
                            key={`${actionIcon}-${i}`}
                            title={actionIcon.ariaLabelledBy}
                            disabled={actionIcon.disabled}
                            onClick={actionIcon.onClick}>
                            <Icon type={actionIcon.iconType} />
                        </button>
                    ))}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__details`}>
                {props.showProgressChart && (
                    <Donut
                        includeLabel={true}
                        percent={props.completionPercentage ?? 0}
                        size={DonutSize.Large}
                        style={DonutStyle.Inverted}
                        subLabel={t("complete")}
                    />
                )}
                {props.title && (
                    <Tooltip
                        content={props.title}
                        placement={TooltipPlacement.Top}
                        style={TooltipStyle.Secondary}>
                        <div>
                            <Paragraph
                                cssClassName="line-clamp-2"
                                emphasis={props.titleEmphasis}
                                size={ParagraphSize.Large}
                                style={ParagraphStyle.Inverted}>
                                {props.title}
                            </Paragraph>
                        </div>
                    </Tooltip>
                )}
                {props.subTitle && (
                    <Tooltip
                        content={props.subTitle}
                        placement={TooltipPlacement.Top}
                        style={TooltipStyle.Secondary}>
                        <div>
                            <Paragraph
                                cssClassName="line-clamp-2"
                                emphasis={props.titleEmphasis}
                                size={ParagraphSize.Small}
                                style={ParagraphStyle.Inverted}>
                                {props.subTitle}
                            </Paragraph>
                        </div>
                    </Tooltip>
                )}
                {badges.length > 0 && (
                    <div className={`${CSS_CLASS_NAME}__details__tags`}>
                        {badges.map((badgeText, i) => (
                            <Badge
                                key={`badge-${i}`}
                                style={BadgeStyle.Transparent}
                                text={t(badgeText)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__nav`}>
                <ProcessNavigationSidebar
                    process={props.process}
                    processNavigator={props.processNavigator}
                />
            </div>
        </aside>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProcessSidebar };

// #endregion Exports
