import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { Process } from "utilities/types/processes/process";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { StringUtils } from "andculturecode-javascript-core";
import { UseProcessFactory } from "utilities/hooks/processes/use-process-factory";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Steps
// -------------------------------------------------------------------------------------------------

enum CreateCourseProcessSteps {
    Details,
    Materials,
}

// #endregion Steps

// -------------------------------------------------------------------------------------------------
// #region Process
// -------------------------------------------------------------------------------------------------

const buildCreateCourseProcess = (
    courseId: number,
    course: CourseRecord,
    courseVersionId: number,
    courseVersion: CourseVersionRecord
): Process<typeof CreateCourseProcessSteps> => {
    return {
        Details: {
            displayName: "courseDetails",
            isComplete: () => course.detailsAreComplete() && courseVersion != null,
            path: RouteUtils.replacePathParams(sitemap.admin.course.edit.details, {
                id: courseId,
                versionId: courseVersionId,
            }),
        },
        Materials: {
            displayName: `courseMaterials`,
            isComplete: () =>
                StringUtils.hasValue(courseVersion.scormPackage?.name) &&
                courseVersion.scormPackage?.fileId != null &&
                courseVersion.scormPackage.status === ScormPackageImportStatus.Complete &&
                courseVersion.contentIsComplete(),
            path: RouteUtils.replacePathParams(sitemap.admin.course.edit.materials.version.create, {
                id: courseId,
                versionId: courseVersionId,
            }),
        },
    };
};

// #endregion Process

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useCreateCourseProcess = UseProcessFactory.build(buildCreateCourseProcess);

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useCreateCourseProcess, CreateCourseProcessSteps };

// #endregion Exports
