import React, { useMemo } from "react";
import { Card } from "components/card/card";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { Heading, HeadingPriority } from "components/typography/heading/heading";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import "./host-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface HostCardProps {
    email: string;
    name: string;
    phoneNumber: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "host-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const HostCard: React.FC<HostCardProps> = ({ name, email, phoneNumber }): JSX.Element => {
    const noHostAssigned = useMemo(() => {
        return (
            !StringUtils.hasValue(name) &&
            !StringUtils.hasValue(email) &&
            !StringUtils.hasValue(phoneNumber)
        );
    }, [email, name, phoneNumber]);

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__heading`}>
                <Heading priority={HeadingPriority.H3}>{t("host")}</Heading>
                <Paragraph style={ParagraphStyle.Light}>
                    {t("forQuestionsAboutTheEventContact")}
                </Paragraph>
            </div>
            <div className={`${CSS_CLASS_NAME}__section`}>
                {noHostAssigned ? (
                    <Paragraph style={ParagraphStyle.Label}>{t("noHostAssigned")}</Paragraph>
                ) : (
                    <>
                        <Paragraph style={ParagraphStyle.Label}>{name}</Paragraph>
                        <Paragraph style={ParagraphStyle.Label}>{email}</Paragraph>
                        <Paragraph style={ParagraphStyle.Label}>{phoneNumber}</Paragraph>{" "}
                    </>
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { HostCard };

// #endregion Exports
