import React from "react";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "components/empty-text/empty-text";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { TopicBubble } from "components/bubble/topic-bubble";
import { TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { PublishStatus } from "models/enumerations/publish-status/publish-status";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-product-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductListProps {
    event: EventActiveRecord;
    onReplace: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-product-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductList: React.FC<EventProductListProps> = ({ event, onReplace }): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="name">{t("name")}</th>
                        <th className="version">{t("version")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="type">{t("type")}</th>
                        <th className="language">{t("language")}</th>
                        <th className="topic">{t("topic")}</th>
                        <th className="action">
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {event.product == null && (
                        <tr className={"-no-results-row"}>
                            <td colSpan={5}>
                                <EmptyText spacing={EmptyTextSpacing.None}>
                                    {t("noProductSelected")}
                                </EmptyText>
                            </td>
                        </tr>
                    )}
                    {event.product != null && (
                        <tr key={event.product?.id}>
                            <td className="name">{event.product?.name}</td>
                            <td className="version">{event.productVersion?.versionId}</td>
                            <td className="id">{event.product?.id}</td>
                            <td className="type">
                                {event.product?.type == null
                                    ? null
                                    : t(TrainingTypeDisplayNames[event.product?.type])}
                            </td>
                            <td className="language">
                                {t(LanguageDisplayNames[event.product?.language!])}
                            </td>
                            <td className="topic">
                                <TopicBubble topic={event.product?.topic!} />
                            </td>
                            <td className="action">
                                <ContextMenu>
                                    <ContextMenuButton
                                        onClick={onReplace}
                                        displayName={t("replace")}
                                        disabled={event.status === PublishStatus.Live}
                                    />
                                </ContextMenu>
                            </td>
                        </tr>
                    )}
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductList };

// #endregion Exports
