import React, { useMemo } from "react";
import { EventContentList } from "components/events/event-content-list/event-content-list";
import { NumberUtils } from "utilities/number-utils";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { useParams } from "react-router-dom";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { t } from "utilities/localization/t";
import "./learner-take-a-training-content-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTakeATrainingContentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-take-a-training-event-content-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTakeATrainingContentPage: React.FC<LearnerTakeATrainingContentPageProps> =
    validatePageAccess(AccessControlKeys.LearnerTakeATrainingContentPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const { id } = useParams();
        const eventId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__title`}>
                    <Icon type={Icons.Content} size={IconSizes.Large} />
                    <Heading priority={HeadingPriority.H1} size={HeadingSize.Small}>
                        {t("content")}
                    </Heading>
                </div>

                <div className={`${CSS_CLASS_NAME}__content-section`}>
                    <div className={`${CSS_CLASS_NAME}__content-item`}>
                        <EventContentList
                            productContentAccess={ProductContentAccess.Everyone}
                            eventId={eventId}
                            contentColumns={2}
                            title={""}
                        />
                    </div>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTakeATrainingContentPage };

// #endregion Exports
