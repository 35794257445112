import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Banner, BannerStyle } from "components/banner/banner";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import {
    CreateUnitCourseParams,
    UnitCourseService,
} from "utilities/services/units/unit-course-service";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "../../icons/icon";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { UnitAddCourseModal } from "../../units/unit-courses/unit-add-course-modal/unit-add-course-modal";
import { UnitCourseList } from "components/units/unit-courses/unit-course-list/unit-course-list";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import { UnitCreateModal } from "components/units/unit-create-modal/unit-create-modal";
import { UnitEditModal } from "components/units/unit-edit-modal/unit-edit-modal";
import { UnitRecord } from "models/view-models/units/unit-record";
import { UnitService, UpdateUnitPathParams } from "utilities/services/units/unit-service";
import { sitemap } from "sitemap";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";
import { t } from "utilities/localization/t";
import "./product-course-learning-path.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCourseLearningPathProps {
    createVersionMode: boolean;
    editMode: boolean;
    product: ProductRecord;
    setCourseHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setCourseToggleHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleClicks?: React.Dispatch<React.SetStateAction<number>>;
    toggleClicks?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "edit-product-course-learning-path-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCourseLearningPath: React.FC<ProductCourseLearningPathProps> = ({
    createVersionMode,
    editMode,
    product,
    setCourseHasChanges,
    setCourseToggleHasChanges,
    setToggleClicks,
    toggleClicks,
}) => {
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const initialProductUnits = useMemo(() => productVersion.units ?? [], [productVersion.units]);
    const [productUnits, setProductUnits] = useState<UnitRecord[]>(initialProductUnits);
    const [showUnitCreateModal, setShowUnitCreateModal] = useState(false);
    const [showUnitEditModal, setShowUnitEditModal] = useState(false);
    const [showAddCoursesModal, setShowAddCoursesModal] = useState(false);
    const { delete: deleteUnitApi } = UnitService.useDelete();
    const [productUnitToEdit, setProductUnitToEdit] = useState<UnitRecord>();
    const [unitCoursesOnProduct, setUnitCoursesOnProduct] = useState<UnitCourseRecord[]>([]);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [showUnitDeletionConfirmationModal, setShowUnitDeletionConfirmationModal] =
        useState(false);
    const [unitToDelete, setUnitToDelete] = useState<UnitRecord>(new UnitRecord());
    const { update: updateProductVersionApi } = ProductVersionService.useUpdate();

    const { create: apiUnitCourseCreate } = UnitCourseService.useCreate();
    const { delete: deleteUnitCourseApi } = UnitCourseService.useDelete();
    const { list: listUnitsApi } = UnitService.useList();
    const { update: updateUnitApi } = UnitService.useUpdate();
    const [readOnly, setReadOnly] = useState(false);
    const [selectedUnitIndex, setSelectedUnitIndex] = useState<number>(0);

    const deferSave = product.status === ActiveStatus.Active;

    const onProductVersionUnitChange = (updatedUnit: UnitRecord, unitIndex?: number) => {
        //This unit has the new course added to it
        //now set it it to the productVersion
        const updatedUnits = productUnits.map((u, idx) => {
            if (idx === unitIndex) {
                return updatedUnit;
            }
            return u;
        });

        setProductUnits(updatedUnits);

        setProductVersion(
            productVersion.with({
                units: updatedUnits,
            })
        );
        recalculateExistingCourses();
    };

    const recalculateExistingCourses = useCallback(async () => {
        if (product == null || product.id == null || product.id < 1) {
            return;
        }

        //recalculate allUnitCoursesOnProduct
        const allUnitCoursesOnProduct: UnitCourseRecord[] = [];
        const units = productVersion.units;
        if (units == null) return;
        units.sort((a, b) => a.sortOrder! - b.sortOrder!);
        units.forEach((unit: UnitRecord) => {
            unit.sortUnitCourses();
            if (unit.unitCourses !== undefined && unit?.unitCourses?.length > 0) {
                allUnitCoursesOnProduct.push(...unit.unitCourses);
            }
        });
        setUnitCoursesOnProduct(allUnitCoursesOnProduct);
        return;
    }, [product, productVersion.units]);

    useEffect(() => {
        recalculateExistingCourses();
    }, [recalculateExistingCourses, productVersion.units]);

    useEffect(() => {
        if (
            product.status === ActiveStatus.Inactive ||
            product.status === ActiveStatus.Archived ||
            (product.status === ActiveStatus.Active &&
                productVersion.status === ActiveStatus.Active) ||
            !editMode
        ) {
            setReadOnly(true);
        }
        if (createVersionMode) {
            setReadOnly(false);
            recalculateExistingCourses();
        }
        if (
            product.status === ActiveStatus.Draft ||
            (editMode && productVersion.status !== ActiveStatus.Active)
        ) {
            setReadOnly(false);
        }
    }, [
        createVersionMode,
        editMode,
        product.activatedOn,
        product.status,
        productVersion.status,
        recalculateExistingCourses,
    ]);

    useEffect(() => {
        setProductUnits(initialProductUnits);
    }, [initialProductUnits]);

    //Do not call on deferSave
    const fetchUnits = useCallback(async () => {
        if (product == null || product.id == null || product.id < 1) {
            return;
        }
        if (deferSave) {
            console.log("fetchUnits should never be called for active products.");
            return;
        }

        let listUnitsResponse;
        try {
            listUnitsResponse = await listUnitsApi({
                includeUnitCourses: true,
                productId: product.id!,
            });

            if (
                listUnitsResponse?.resultObjects == null ||
                listUnitsResponse.results == null ||
                listUnitsResponse.results.hasErrors()
            ) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingUnits"));
            setUnitCoursesOnProduct([]);
            setProductUnits([]);
            return;
        }

        const units = listUnitsResponse.resultObjects;
        const allUnitCoursesOnProduct: UnitCourseRecord[] = [];
        units.sort((a, b) => a.sortOrder! - b.sortOrder!);
        units.forEach((unit: UnitRecord) => {
            unit.sortUnitCourses();
            if (unit.unitCourses !== undefined && unit?.unitCourses?.length > 0) {
                allUnitCoursesOnProduct.push(...unit.unitCourses);
            }
        });

        setUnitCoursesOnProduct(allUnitCoursesOnProduct);

        setProductUnits(units);
        setProductVersion(
            (previousProductVersion: ProductVersionRecord): ProductVersionRecord =>
                previousProductVersion.with({ units: units })
        );
    }, [deferSave, listUnitsApi, product, setProductVersion]);

    const updateProductVersion = async (
        productVersionWithChanges: ProductVersionRecord
    ): Promise<boolean> => {
        if (deferSave) {
            alert("You should not call the update for an active product.");
        } else {
            const updateProductVersionPathParams: UpdateProductVersionPathParams = {
                id: productVersionWithChanges.id!,
            };

            try {
                const updateProductVersionResponse = await updateProductVersionApi(
                    productVersionWithChanges,
                    updateProductVersionPathParams
                );
                const updateResult = updateProductVersionResponse?.result;
                if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                    throw new Error();
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingTheProduct"));
                return false;
            }
        }
        return true;
    };

    const onCompleteUnitsInOrderToggle = async () => {
        const updatedCount = toggleClicks! + 1;
        setToggleClicks && setToggleClicks(updatedCount);
        if (updatedCount % 2 === 0) {
            setCourseToggleHasChanges && setCourseToggleHasChanges(false);
        } else {
            setCourseToggleHasChanges && setCourseToggleHasChanges(true);
        }

        let updatedUnits: UnitRecord[] | undefined;
        if (!productVersion.completeUnitsInOrder) {
            updatedUnits = productVersion.units?.map((unit) =>
                unit.with({ completeUnitBeforeMovingForward: undefined })
            );
        }

        const productVersionWithChanges = productVersion.with({
            completeUnitsInOrder: !productVersion.completeUnitsInOrder,
            units: updatedUnits ?? productVersion.units,
        });
        setProductVersion(productVersionWithChanges);

        if (!deferSave) {
            if (await updateProductVersion(productVersionWithChanges)) {
                setProductVersion(productVersionWithChanges);
            }
        }
    };

    const handleAddCourseClick = (unit: UnitRecord, unitIndex: number) => {
        setProductUnitToEdit(unit);
        setSelectedUnitIndex(unitIndex);
        setShowAddCoursesModal(true);
    };

    const deleteUnitCourse = async (unitCourse: UnitCourseRecord): Promise<boolean> => {
        if (deferSave) {
            try {
                const deleteFromUnit = productUnits.find((pu) => pu.id === unitCourse.unitId);
                if (deleteFromUnit !== undefined && deleteFromUnit.unitCourses !== undefined) {
                    const courseToDelete = deleteFromUnit.unitCourses.find(
                        (uc) => uc.id === unitCourse.id
                    );
                    if (courseToDelete !== undefined) {
                        //Delete the unit course
                        deleteFromUnit.unitCourses.splice(
                            deleteFromUnit.unitCourses.indexOf(courseToDelete),
                            1
                        );
                        setProductVersion(productVersion.with({ units: productUnits }));
                    }
                }
                recalculateExistingCourses();
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingACourse"));
            }
        } else {
            try {
                await deleteUnitCourseApi(unitCourse.id!);
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingACourse"));
            }
            fetchUnits();
        }
        return true;
    };

    const editUnit = (unit: UnitRecord, selectedUnitIndex: number): void => {
        setProductUnitToEdit(unit);
        setSelectedUnitIndex(selectedUnitIndex);
        setShowUnitEditModal(true);
    };

    const saveUnitCourses = async (courseIds: number[], unit?: UnitRecord): Promise<boolean> => {
        if (courseIds == null) {
            throw new Error();
        }

        if (deferSave) {
            console.error("You cannot save an active product.");
            //recalculateExistingCourses();
        } else {
            let createUnitCourseParams: CreateUnitCourseParams = {
                courseIds: courseIds,
                unitId: unit?.id!,
            };

            if (CollectionUtils.isNotEmpty(unit?.unitCourses)) {
                createUnitCourseParams = {
                    courseIds: courseIds,
                    sortOrder: Math.max(...unit!.unitCourses.map((u) => u.sortOrder!)) + 1,
                    unitId: unit?.id!,
                };
            }

            try {
                const createUnitCourseResponse = await apiUnitCourseCreate(createUnitCourseParams);
                const createUnitCourseResult = createUnitCourseResponse?.result;

                if (
                    createUnitCourseResult?.resultObject == null ||
                    createUnitCourseResult.hasErrors()
                ) {
                    throw new Error();
                }

                fetchUnits();
            } catch {
                ToastManager.error(t("thereWasAnIssueAddingTheCourse"));
                return false;
            }
        }
        return true;
    };

    const confirmationDeletionActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowUnitDeletionConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesRemove"),
            onClick: () => handleUnitDelete(),
            style: ButtonStyle.Destructive,
        },
    ];

    const confirmUnitDeletion = (unit: UnitRecord) => {
        setShowUnitDeletionConfirmationModal(true);
        setUnitToDelete(unit);
    };

    const handleUnitDelete = async (): Promise<void> => {
        const higherSortOrderUnitsToUpdate = productUnits.slice(unitToDelete.sortOrder);

        if (deferSave) {
            try {
                setCourseHasChanges && setCourseHasChanges(true);

                const toDelete = productUnits.find((pu) => pu.id === unitToDelete.id);
                if (toDelete !== undefined) {
                    //Delete the unit course
                    productUnits.splice(productUnits.indexOf(toDelete), 1);
                    const updateProductUnits = productUnits?.map((pu, idx) => {
                        if (pu.sortOrder! > unitToDelete.sortOrder!) {
                            return pu.with({ sortOrder: pu.sortOrder! - 1 });
                        }
                        return pu;
                    });
                    setProductVersion(productVersion.with({ units: updateProductUnits }));
                    setShowUnitDeletionConfirmationModal(false);
                    return;
                }
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingAUnit"));
            }
        } else {
            try {
                await deleteUnitApi(unitToDelete.id!);
            } catch {
                ToastManager.error(t("thereWasAnIssueDeletingAUnit"));
                setShowUnitDeletionConfirmationModal(false);
                return;
            }

            setShowUnitDeletionConfirmationModal(false);

            const apiCalls: Promise<boolean>[] = [];

            try {
                higherSortOrderUnitsToUpdate.forEach((u) => apiCalls.push(moveUpUnit(u, -1)));
                await Promise.all(apiCalls);
            } catch {
                ToastManager.error(t("thereWasAnIssueUpdatingSortOrderAfterDeletingUnit"));
            }
            fetchUnits();
        }
    };

    const unitIsFirst = useCallback(
        (unit: UnitRecord): boolean => {
            return (
                CollectionUtils.isEmpty(productUnits) ||
                unit.sortOrder === Math.min(...productUnits.map((u) => u.sortOrder!))
            );
        },
        [productUnits]
    );

    const unitIsLast = useCallback(
        (unit: UnitRecord): boolean => {
            return (
                CollectionUtils.isEmpty(productUnits) ||
                unit.sortOrder === Math.max(...productUnits.map((u) => u.sortOrder!))
            );
        },
        [productUnits]
    );

    const disableUpArrowButtons = useCallback(
        (unit: UnitRecord) => {
            return updateInProgress || unitIsFirst(unit);
        },
        [unitIsFirst, updateInProgress]
    );

    const disableDownArrowButtons = useCallback(
        (unit: UnitRecord) => {
            return updateInProgress || unitIsLast(unit);
        },
        [updateInProgress, unitIsLast]
    );

    const moveUpUnit = async (unit: UnitRecord, index: number): Promise<boolean> => {
        return updateUnitSortOrder(unit, unit.sortOrder! - 1, index, -1);
    };

    const moveDownUnit = async (unit: UnitRecord, index: number): Promise<boolean> => {
        return updateUnitSortOrder(unit, unit.sortOrder! + 1, index, 1);
    };

    const updateUnitSortOrder = async (
        unit: UnitRecord,
        sortOrder: number,
        index: number,
        dirn: number
    ): Promise<boolean> => {
        const updatedUnits = unit.with({
            sortOrder: sortOrder,
        });

        if (deferSave) {
            //swap the sort order of the productContent with the one above it:
            if (productUnits == null) return Promise.resolve(false);

            //get the index of productContent
            //const selectedProductUnit = productUnits.find((pu) => pu === unit);
            //if (selectedProductUnit == null) return Promise.resolve(false);
            const selectedProductUnitIndex = index;
            if (selectedProductUnitIndex == null) return Promise.resolve(false);

            const updatedProductUnits: UnitRecord[] = productUnits.map((pc, idx) => {
                if (idx === selectedProductUnitIndex) {
                    return pc.with({ sortOrder: sortOrder });
                }
                if (dirn > 0) {
                    if (idx === selectedProductUnitIndex + 1) {
                        return pc.with({ sortOrder: pc.sortOrder! - 1 });
                    }
                } else {
                    if (idx === selectedProductUnitIndex - 1) {
                        return pc.with({ sortOrder: pc.sortOrder! + 1 });
                    }
                }
                return pc;
            });
            updatedProductUnits.sort((a, b) => a.sortOrder! - b.sortOrder!);
            setProductUnits(updatedProductUnits);
            setProductVersion(
                productVersion.with({
                    units: updatedProductUnits,
                })
            );
            return Promise.resolve(true);
        } else {
            return await updateUnit(updatedUnits);
        }
    };

    const updateUnit = async (unitWithChanges: UnitRecord): Promise<boolean> => {
        setUpdateInProgress(true);

        const updateUnitPathParams: UpdateUnitPathParams = {
            id: unitWithChanges.id!,
        };

        const updateUnitResponse = await updateUnitApi(unitWithChanges, updateUnitPathParams);

        setUpdateInProgress(false);

        const updateResult = updateUnitResponse?.result;
        if (updateResult?.resultObject == null || updateResult.hasErrors()) {
            throw new Error();
        }

        return true;
    };

    const getUnitBySortOrder = (sortOrder: number): UnitRecord | null => {
        if (CollectionUtils.isEmpty(productUnits)) {
            return null;
        }

        const unit = productUnits.find((pc) => pc.sortOrder === sortOrder);

        return unit == null ? null : unit;
    };

    const handleUpArrowClick = async (unit: UnitRecord, index: number): Promise<void> => {
        if (unitIsFirst(unit)) {
            return;
        }

        if (deferSave) {
            setCourseHasChanges && setCourseHasChanges(true);
            moveUpUnit(unit, index);
        } else {
            const apiCalls: Promise<boolean>[] = [moveUpUnit(unit, index)];

            const unitToMoveDown = getUnitBySortOrder(unit.sortOrder! - 1);

            if (unitToMoveDown != null) {
                apiCalls.push(moveDownUnit(unitToMoveDown, index));
            }

            await Promise.all(apiCalls);
            //if (deferSave) recalculateExistingCourses();
            fetchUnits();
        }
    };

    const handleDownArrowClick = async (unit: UnitRecord, index: number): Promise<void> => {
        if (unitIsLast(unit)) {
            return;
        }

        if (deferSave) {
            setCourseHasChanges && setCourseHasChanges(true);
            moveDownUnit(unit, index);
        } else {
            const apiCalls: Promise<boolean>[] = [moveDownUnit(unit, index)];

            const unitToMoveUp = getUnitBySortOrder(unit.sortOrder! + 1);

            if (unitToMoveUp != null) {
                apiCalls.push(moveUpUnit(unitToMoveUp, index));
            }

            await Promise.all(apiCalls);

            fetchUnits();
        }
    };

    function onProductUnitCoursesChange(unit: UnitRecord, unitCourses: UnitCourseRecord[]): void {
        const updatedUnits = productUnits.map((u) => {
            if (u === unit) {
                return u.with({ unitCourses: unitCourses });
            }
            return u;
        });
        setProductUnits(updatedUnits);

        setProductVersion(
            productVersion.with({
                units: updatedUnits,
            })
        );

        recalculateExistingCourses();
    }

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <div className={`${CSS_CLASS_NAME}__header__heading`}>
                        <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                            {t("courses")}
                        </Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__header__actions`}>
                        <ToggleLabel
                            checked={productVersion.completeUnitsInOrder}
                            id="complete-units-in-order"
                            label={t("completeUnitsInOrder")}
                            onToggle={() => onCompleteUnitsInOrderToggle()}
                        />
                        <Button
                            onClick={() => setShowUnitCreateModal(true)}
                            style={ButtonStyle.Primary}
                            text={t("addUnit")}
                        />
                    </div>
                </div>
                {editMode && productVersion.status === ActiveStatus.Active && (
                    <Banner style={BannerStyle.Light}>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                            {t(
                                "changesToCoursesAreNotAvailableWhenEditingAnActiveProductVersionToChangeCourses"
                            )}{" "}
                            <Anchor
                                cssClassName={`${CSS_CLASS_NAME}__create-new-version-link`}
                                path={RouteUtils.localizePath(
                                    RouteUtils.replacePathParams(
                                        sitemap.admin.product.edit.online.materials.version.create,
                                        { id: product.id }
                                    )
                                )}>
                                {t("createANewProductVersion")}
                            </Anchor>
                            .
                        </Paragraph>
                    </Banner>
                )}
                <div className={`${CSS_CLASS_NAME}__content`}>
                    {productUnits.length === 0 && (
                        <div className="unit -empty">
                            <div className="unit__header"></div>
                            <div className="unit__wrapper">
                                <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                                    {t("noUnitsAdded")}
                                </Heading>
                                <Paragraph>
                                    {t("createYourFirstUnitToStartOrganizingCourses")}
                                </Paragraph>
                                <Button
                                    onClick={() => setShowUnitCreateModal(true)}
                                    style={ButtonStyle.Secondary}
                                    text={t("addUnit")}
                                />
                            </div>
                        </div>
                    )}

                    {productUnits.map((productUnit, index) => (
                        <div className="unit" key={`product-unit-${index}`}>
                            <div className="unit__header">
                                <div className="unit__header__details">
                                    <div className="unit__header__details__sort">
                                        <ButtonIcon
                                            ariaLabelledBy={t("moveUnitProductUnitNameUp", {
                                                productUnitName: productUnit.name,
                                            })}
                                            buttonStyle={ButtonStyle.None}
                                            buttonSize={ButtonSize.Small}
                                            cssClassName="arrow-up"
                                            disabled={disableUpArrowButtons(productUnit)}
                                            iconType={Icons.ArrowUpward}
                                            onClick={() => handleUpArrowClick(productUnit, index)}
                                        />
                                        <ButtonIcon
                                            ariaLabelledBy={t("moveUnitProductUnitNameDown", {
                                                productUnitName: productUnit.name,
                                            })}
                                            buttonStyle={ButtonStyle.None}
                                            buttonSize={ButtonSize.Small}
                                            cssClassName="arrow-down"
                                            disabled={disableDownArrowButtons(productUnit)}
                                            iconType={Icons.ArrowDownward}
                                            onClick={() => handleDownArrowClick(productUnit, index)}
                                        />
                                    </div>
                                    <Heading
                                        priority={HeadingPriority.H2}
                                        size={HeadingSize.XXSmall}>
                                        {index + 1}. {productUnit.name}
                                    </Heading>
                                </div>
                                <div className="unit__header__actions">
                                    <div className="unit__header__actions__badges">
                                        {productUnit.optional && (
                                            <Badge
                                                style={BadgeStyle.Inverted}
                                                text={t("optional")}
                                            />
                                        )}

                                        {!productVersion.completeUnitsInOrder &&
                                            productUnit.completeUnitBeforeMovingForward && (
                                                <Badge style={BadgeStyle.Inverted}>
                                                    <span>{t("unitAbbreviated")}</span>{" "}
                                                    <Icon type={Icons.AllCompleted} />
                                                </Badge>
                                            )}

                                        {productUnit.completeCoursesInOrder && (
                                            <Badge style={BadgeStyle.Inverted}>
                                                <span>{t("courseAbbreviated")}</span>{" "}
                                                <Icon type={Icons.Checklist} />
                                            </Badge>
                                        )}
                                    </div>
                                    {productUnit.unitCourses != null &&
                                        productUnit.unitCourses.length > 0 && (
                                            <Button
                                                onClick={() =>
                                                    handleAddCourseClick(productUnit, index)
                                                }
                                                size={ButtonSize.Small}
                                                style={ButtonStyle.Primary}
                                                text={t("addCourses")}
                                            />
                                        )}
                                    <ContextMenu>
                                        <ContextMenuButton
                                            displayName={t("edit")}
                                            onClick={() => {
                                                editUnit(productUnit, index);
                                            }}
                                        />
                                        <ContextMenuButton
                                            displayName={t("remove")}
                                            onClick={() => {
                                                confirmUnitDeletion(productUnit);
                                            }}
                                        />
                                    </ContextMenu>
                                </div>
                            </div>
                            <div className="unit__wrapper">
                                {productUnit.unitCourses != null &&
                                productUnit.unitCourses.length > 0 ? (
                                    <UnitCourseList
                                        deferSave={deferSave}
                                        deleteUnitCourse={deleteUnitCourse}
                                        fetchUnitCourseList={fetchUnits}
                                        onProductUnitChange={onProductVersionUnitChange}
                                        onProductVersionUnitsChange={onProductUnitCoursesChange}
                                        selectedUnitIndex={index}
                                        setCourseHasChanges={setCourseHasChanges}
                                        unit={productUnit}
                                        unitCourses={productUnit.unitCourses}
                                        unitIndex={index}
                                    />
                                ) : (
                                    <div className="course -empty">
                                        <div className="course__wrapper">
                                            <Heading priority={HeadingPriority.H3}>
                                                {t("noCoursesAdded")}
                                            </Heading>
                                            <Paragraph>
                                                {t("addCoursesToStartBuildingOutTheUnit")}
                                            </Paragraph>

                                            <Button
                                                onClick={() =>
                                                    handleAddCourseClick(productUnit, index)
                                                }
                                                style={ButtonStyle.Secondary}
                                                text={t("addCourses")}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                actions={confirmationDeletionActionArray}
                isOpen={showUnitDeletionConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("areYouSureYouWantToRemoveUnit", { unit: unitToDelete.name })}
            </Modal>

            <UnitCreateModal
                onSuccess={fetchUnits}
                open={showUnitCreateModal}
                product={product}
                productUnits={productUnits}
                setCourseHasChanges={setCourseHasChanges!}
                setOpen={setShowUnitCreateModal}
                setProductUnits={setProductUnits}
            />

            {productUnitToEdit !== undefined && unitCoursesOnProduct !== undefined && (
                <UnitEditModal
                    onProductVersionUnitChange={onProductVersionUnitChange}
                    onSuccess={fetchUnits}
                    open={showUnitEditModal}
                    product={product}
                    selectedIndex={selectedUnitIndex}
                    setCourseHasChanges={setCourseHasChanges!}
                    setOpen={setShowUnitEditModal}
                    unit={productUnitToEdit}
                />
            )}

            {productUnitToEdit !== undefined && unitCoursesOnProduct !== undefined && (
                <UnitAddCourseModal
                    existingUnitCourses={unitCoursesOnProduct}
                    fetchUnitCourseList={fetchUnits}
                    handleSaveUnitCourses={saveUnitCourses}
                    language={product.language}
                    onProductVersionUnitChange={onProductVersionUnitChange}
                    open={showAddCoursesModal}
                    setCourseHasChanges={setCourseHasChanges!}
                    setOpen={setShowAddCoursesModal}
                    setUnit={setProductUnitToEdit}
                    audienceType={product.audienceType}
                    unit={productUnitToEdit!}
                />
            )}
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCourseLearningPath };

// #endregion Exports
