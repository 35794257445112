import React, { useState } from "react";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CreateEventModal } from "components/events/create-event-modal/create-event-modal";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { ProductImage } from "components/product-image/product-image";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import "./aen-product-info.scss";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AENProductInfoProps {
    product: ProductRecord;
    onClose: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "aen-product-info";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AENProductInfo: React.FC<AENProductInfoProps> = ({ product, onClose }): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const canEditEvents =
        ((globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator) &&
            globalState.currentIdentity.user?.provider?.isActive) ||
            (currentUserIsInNfpaRole &&
                !globalState.currentIdentity?.isCurrentlyInRole(RoleType.NfpaSupport))) ??
        false;

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__back-to-nav`}>
                <ButtonIcon
                    onClick={onClose}
                    buttonSize={ButtonSize.Small}
                    buttonStyle={ButtonStyle.Secondary}
                    iconType={Icons.ChevronLeft}
                    text={t("backToAllProducts")}
                />
            </div>

            <div className={`${CSS_CLASS_NAME}__detail`}>
                <ProductImage
                    cssClassName={`${CSS_CLASS_NAME}__image`}
                    productImageFileId={product.productImageFileId}
                />
                <Heading cssClassName={`${CSS_CLASS_NAME}__header`} size={HeadingSize.Small}>
                    {product.name}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__detail-items`}>
                    <div className={`${CSS_CLASS_NAME}__topic`}>
                        <dl>
                            <dt>{t("topic").toLocaleUpperCase()}</dt>
                            <dd>{product.getTopicDisplay()}</dd>
                        </dl>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__credits`}>
                        <dl>
                            <dt>{t("ceus")}</dt>
                            <dd>
                                {NumberUtils.formatCEUsFromCreditHours(product.creditHours) ??
                                    t("notApplicableShort")}
                            </dd>
                        </dl>
                    </div>
                </div>
                {canEditEvents && (
                    <Button
                        cssClassName={`${CSS_CLASS_NAME}__create-event-button`}
                        onClick={() => setShowNewEventModal(true)}
                        size={ButtonSize.Medium}
                        style={ButtonStyle.Primary}
                        text={t("createEvent")}
                    />
                )}
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__description`}>
                    {product.description}
                </Paragraph>
                <Paragraph cssClassName={`${CSS_CLASS_NAME}__description`}>
                    {product.catalogDescription}
                </Paragraph>
                {StringUtils.hasValue(product.intendedAudience) && (
                    <>
                        <h3 className={`${CSS_CLASS_NAME}__header`}>
                            {t("courseObjectivesInclude")}
                        </h3>
                        <div
                            className={`${CSS_CLASS_NAME}__course-objectives`}
                            dangerouslySetInnerHTML={{ __html: product.courseObjectives }}
                        />
                    </>
                )}

                {StringUtils.hasValue(product.intendedAudience) && (
                    <>
                        <h3 className={`${CSS_CLASS_NAME}__header`}>{t("whoWillBenefit")}</h3>
                        <div
                            className={`${CSS_CLASS_NAME}__who-will-benefit`}
                            dangerouslySetInnerHTML={{ __html: product.intendedAudience }}
                        />
                    </>
                )}
            </div>
            <CreateEventModal
                onModalClose={() => setShowNewEventModal(false)}
                open={showNewEventModal}
                product={product}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AENProductInfo };

// #endregion Exports
