import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CourseCard } from "components/courses/course-card/course-card";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import {
    GetProductPathParams,
    GetProductQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { LearnerChecklist } from "components/learner/learner-checklist/learner-checklist";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductDescriptionCard } from "components/products/product-description-card/product-description-card";
import { ProductRecord } from "models/view-models/products/product-record";
import { RouteUtils } from "utilities/route-utils";
import { SupportCard } from "components/support/support-card/support-card";
import { SyllabusUnitCardList } from "components/learner/learner-online-learning/syllabus-unit-list/syllabus-unit-card-list";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { useContract } from "utilities/hooks/models/contracts/use-contract";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useEnrollmentUnits } from "utilities/hooks/models/enrollments/use-enrollment-units";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import "./learner-online-learning-details-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-details-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningDetailsPage: React.FC<LearnerOnlineLearningDetailsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningDetailsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const navigate = useNavigate();
        const { productId: productIdParam, enrollmentId: enrollmentIdParam } = useParams();
        const productId = useMemo(
            () => NumberUtils.parseInt(productIdParam) ?? 0,
            [productIdParam]
        );
        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );
        const { record: globalState } = useGlobalState();
        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );
        const userRole = useMemo(() => globalState?.currentIdentity?.role?.roleType, [globalState]);

        const [product, setProduct] = useState<ProductRecord>(new ProductRecord());
        const { get: getProduct } = ProductService.useGet();

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeProduct: true,
            includeTrainingType: TrainingType.OnlineLearning,
        });

        const { units } = enrollment;

        const { enrollmentUnits } = useEnrollmentUnits({
            enrollmentId,
        });

        const { contract } = useContract({
            contractId: enrollment.contractId,
            includeOrganization: true,
        });

        const { notifications, markNotificationAsRead } = useUserNotifications({
            enrollmentId: enrollmentId ?? -1,
            roleType: RoleType.Learner,
            userId: userId,
        });

        const navigateToCourse = (unitId: number = 0, courseId: number = 0) => {
            if (unitId < 1 || courseId < 1) {
                return;
            }

            const coursePath =
                userRole === RoleType.Learner
                    ? RouteUtils.replacePathParams(
                          sitemap.learner.training.onlineLearning.units.courses.details,
                          {
                              productId,
                              enrollmentId,
                              unitId,
                              courseId,
                          }
                      )
                    : RouteUtils.replacePathParams(
                          sitemap.instructor.aenTraining.units.courses.details,
                          {
                              productId,
                              enrollmentId,
                              unitId,
                              courseId,
                          }
                      );

            navigate(coursePath);
        };

        const fetchData = useCallback(async () => {
            const pathParams: GetProductPathParams = {
                id: productId,
            };

            const queryParams: GetProductQueryParams = {};

            try {
                const getResponse = await getProduct(pathParams, queryParams);

                const getResult = getResponse?.result;
                const product = getResponse?.resultObject;

                if (product == null || getResult == null || getResult.hasErrors()) {
                    throw new Error();
                }

                setProduct(product);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingTheProduct"));
            }
        }, [getProduct, productId]);

        const renderCourseSyllabus = () => {
            if (
                product.onlineLearningType === ProductCourseType.LearningPath ||
                units?.length === 0
            ) {
                return <></>;
            }

            const unit = units[0];

            const unitCourses = [...(unit.unitCourses ?? [])].sort(
                (a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0)
            );

            const getEnrollmentUnitCourse = (
                unitCourseId: number
            ): EnrollmentUnitCourseRecord | undefined => {
                const enrollmentUnitCourse: EnrollmentUnitCourseRecord | undefined =
                    enrollment.enrollmentUnitCourses?.find((e) => e.unitCourseId === unitCourseId);

                if (enrollmentUnitCourse) {
                    return enrollmentUnitCourse;
                }

                return undefined;
            };

            return unitCourses.map((unitCourse, index) => {
                const enrollmentUnitCourse = getEnrollmentUnitCourse(unitCourse.id!);
                if (enrollmentUnitCourse == null) {
                    return <></>;
                }

                return (
                    <CourseCard
                        id={unitCourse.id ?? 0}
                        key={unitCourse.id ?? 0}
                        locked={enrollmentUnitCourse.locked}
                        name={unitCourse.course?.name ?? t("notApplicableShort")}
                        onViewCourseClick={() => navigateToCourse(unit.id, unitCourse.course?.id)}
                        status={enrollmentUnitCourse.status}
                        required={!enrollmentUnitCourse.optional}
                    />
                );
            });
        };

        useEffect(() => {
            fetchData();
        }, [fetchData]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <div className={`${CSS_CLASS_NAME}__title`}>
                        <Icon type={Icons.EventDetails} size={IconSizes.Large} />
                        <Heading size={HeadingSize.Small}>{t("aboutThisTraining")}</Heading>
                    </div>

                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}></Heading>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__banners`}>
                        {contract.id != null && (
                            <div className={`${CSS_CLASS_NAME}__contract-banner`}>
                                <NotificationBanner
                                    icon={Icons.InformationOutline}
                                    notificationId={0}
                                    onClose={() => {}}
                                    style={NotificationType.Default}
                                    message={t(
                                        "youHaveBeenAddedOnBehalfOfContractOrganizationNameYourDataMayBeSharedWithThisOrganization",
                                        { contractOrganizationName: contract.organization?.name }
                                    )}
                                />
                            </div>
                        )}
                        {notifications != null && (
                            <div className={`${CSS_CLASS_NAME}__notifications`}>
                                {notifications.map((notification, index) => (
                                    <NotificationBanner
                                        key={`${notification.id}-${index}`}
                                        icon={notification.getNotificationIcon()}
                                        message={notification.message}
                                        notificationId={notification.id!}
                                        onClose={() => markNotificationAsRead?.(notification.id!)}
                                        style={notification.notificationType}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__form`}>
                    <div className={`${CSS_CLASS_NAME}__about`}>
                        <div
                            className={`${CSS_CLASS_NAME}__details ${
                                enrollment?.product?.audienceType === AudienceType.EducationNetwork
                                    ? "col-span-3"
                                    : "col-span-2"
                            }`}>
                            <ProductDescriptionCard
                                product={product}
                                stacked={true}
                                units={units}
                            />
                        </div>
                        {product.audienceType === AudienceType.Learner && (
                            <div className={`${CSS_CLASS_NAME}__checklist`}>
                                <LearnerChecklist enrollmentId={enrollmentId} stacked={true} />
                            </div>
                        )}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__syllabus`}>
                        <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                            {t("syllabus")}
                        </Heading>

                        {product.onlineLearningType === ProductCourseType.LearningPath && (
                            <SyllabusUnitCardList
                                enrollmentUnits={enrollmentUnits}
                                productId={productId}
                            />
                        )}
                        {renderCourseSyllabus()}
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__hr`}></div>
                <div className={`${CSS_CLASS_NAME}__contact`}>
                    <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H2}>
                        {t("contact")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__contact__cards`}>
                        <SupportCard />
                    </div>
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningDetailsPage };

// #endregion Exports
