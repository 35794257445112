import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { InstructorApprovedProduct } from "models/interfaces/instructors/instructor-approved-product";
import { EnrollmentRecord } from "../enrollments/enrollment-record";
import { ProductRecord } from "../products/product-record";
import { UserRecord } from "../users/user-record";
import { ProductApprovalStatus } from "models/instructors/product-approval-status";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: InstructorApprovedProduct = {
    createdById: undefined,
    createdOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    enrollmentId: undefined,
    productId: 0,
    userId: 0,
    status: undefined,
    enrollment: undefined,
    product: undefined,
    user: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class InstructorApprovedProductRecord
    extends Record(defaultValues)
    implements InstructorApprovedProduct
{
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<InstructorApprovedProduct>) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.product != null) {
            params.product = RecordUtils.ensureRecord(params.product, ProductRecord);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public with(values: Partial<InstructorApprovedProduct>): InstructorApprovedProductRecord {
        return new InstructorApprovedProductRecord(Object.assign(this.toJS(), values));
    }

    /**
     * Returns an array of unit records based on EnrollmentUnitCourse records
     */
    public isApproved(): boolean {
        return this.status === ProductApprovalStatus.ApprovedToTeach;
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApprovedProductRecord };

// #endregion Exports
