import ActionLinkCardList from "components/action-card/action-card-list/action-link-card-list";
import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import React, { useMemo, useState } from "react";
import moment from "moment";
import { ActionLinkCardProps } from "components/action-card/action-link-card";
import { Anchor, AnchorStyle } from "components/typography/anchors/anchor/anchor";
import { CalendarMonthView } from "components/calendar-month-view/calendar-month-view";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EnrollmentActiveStatus } from "models/enumerations/enrollments/enrollment-active-status";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationRecord } from "models/view-models/notifications/notification-record";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCalendarEvents } from "utilities/hooks/models/events/use-calendar-events";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useInstructorQuickLinks } from "pages/instructor/use-instructor-quick-links";
import { useNavigate } from "react-router-dom";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { useUserNotifications } from "utilities/hooks/models/notifications/use-user-notifications";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./instructor-default-dashboard.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface InstructorDefaultDashboardProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "instructor-default-dashboard";

const getEventTeachingPath = (eventId: number) => {
    return RouteUtils.replacePathParams(sitemap.instructor.event.details, {
        id: eventId,
    });
};

const TRAIN_THE_TRAINER = [TrainingType.TrainTheTrainer];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const InstructorDefaultDashboard: React.FC<InstructorDefaultDashboardProps> = (props) => {
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const { instructorQuickLinks } = useInstructorQuickLinks({
        settings: globalState?.systemSettings,
    });

    const { useEducationNetwork } = useFeatureFlags();
    const [calendarMonth, setCalendarMonth] = useState<moment.Moment>(moment().startOf("month"));

    const userId = useMemo(
        () => globalState?.currentIdentity?.user?.id,
        [globalState?.currentIdentity?.user?.id]
    );

    const { events: calendarEvents } = useCalendarEvents({
        instructorId: userId,
        excludeDraft: true,
        month: calendarMonth.toDate().getMonth(),
        year: calendarMonth.toDate().getFullYear(),
    });

    const { enrollments } = useUserEnrollments({
        userId: userId,
        enrollmentActiveStatus: EnrollmentActiveStatus.Active,
        trainingTypes: TRAIN_THE_TRAINER,
        includeRegistrations: true,
        educationNetworkOnly: true,
    });

    const { notifications, markNotificationAsRead } = useUserNotifications({
        includeEvent: true,
        roleType: RoleType.Instructor,
        userId: userId,
    });

    const getNotificationLinkPath = (notification: NotificationRecord) => {
        if (notification.notificationType === NotificationType.InstructorApplicationApproved) {
            return sitemap.instructor.educationNetworkHub.profile;
        }
        if (notification.event?.instructorId !== userId) {
            return sitemap.public.noAccess;
        } else {
            return getEventTeachingPath(notification.eventId!);
        }
    };

    const getNotificationLinkText = (notification: NotificationRecord) => {
        if (notification.notificationType === NotificationType.InstructorApplicationApproved) {
            return t("goToAenHub");
        }
        return t("viewTraining");
    };

    const markNotificationRead = (notificationId?: number) => {
        if (notificationId == null) {
            return;
        }

        markNotificationAsRead?.(notificationId);
    };

    const handleNextMonthClick = () => setCalendarMonth(calendarMonth.clone().add(1, "month"));
    const handlePreviousMonthClick = () =>
        setCalendarMonth(calendarMonth.clone().subtract(1, "month"));

    const navigateToViewEventPath = (eventId: number) => {
        const viewEventRoute = RouteUtils.replacePathParams(viewEventRoutes.details, {
            id: eventId,
        });

        navigate(viewEventRoute);
    };

    const requiredTrainings: ActionLinkCardProps[] = useMemo(
        () =>
            enrollments
                .filter((e) => e.percentComplete !== 100)
                .slice(0, 3)
                .map((enrollment) => {
                    return {
                        completionPercentage: enrollment.percentComplete,
                        description: enrollment.product?.name ?? "",
                        icon: Icons.Products,
                        topics: enrollment.productVersion?.getProductTopics(),
                        trainingButtonLocked: false,
                        trainingButtonPath: RouteUtils.localizePath(
                            RouteUtils.replacePathParams(sitemap.instructor.aenTraining.details, {
                                productId: enrollment.productId,
                                enrollmentId: enrollment.id,
                            })
                        ),
                        trainingButtonText: enrollment.registrations?.some(
                            (r) => r.userId === userId
                        )
                            ? "resumeTraining"
                            : "viewTraining",
                        type: enrollment.product?.getTrainingTypeWithSpaces() ?? "",
                    };
                }),
        [enrollments, userId]
    );

    return (
        <>
            {notifications != null && (
                <div className={`${CSS_CLASS_NAME}__notifications`}>
                    {notifications.map((notification, index) => (
                        <NotificationBanner
                            key={`${notification.id}-${index}`}
                            icon={notification.getNotificationIcon()}
                            linkPath={getNotificationLinkPath(notification)}
                            linkText={getNotificationLinkText(notification)}
                            notificationId={notification.id!}
                            onClose={() => markNotificationRead(notification.id)}
                            style={notification.notificationType}
                            message={notification.message}
                        />
                    ))}
                </div>
            )}
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                {t("teachingCalendar")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <CalendarMonthView
                    events={calendarEvents}
                    notifications={notifications}
                    getEventDisplayName={(event) => event.product?.name ?? event.name}
                    onNextMonthClick={handleNextMonthClick}
                    onPreviousMonthClick={handlePreviousMonthClick}
                    onViewEventClick={(eventId: number) => navigateToViewEventPath(eventId)}
                />
            </div>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                {t("quickLinks")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <QuickLinkList links={instructorQuickLinks} />
            </div>

            {useEducationNetwork && CollectionUtils.hasValues(requiredTrainings) && (
                <>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("authorizedEducationNetworkTrainings")}
                        </Heading>
                        <Anchor
                            path={RouteUtils.localizePath(
                                sitemap.instructor.educationNetworkHub.requiredTrainings
                            )}
                            text="View All"
                            style={AnchorStyle.Wide}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <ActionLinkCardList links={requiredTrainings} />
                    </div>
                </>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorDefaultDashboard };

// #endregion Exports
