import React from "react";
import { Button, ButtonType } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { HeadingPriority } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RoleTypeDisplayNames } from "models/enumerations/users/role-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useGetUserDashboard } from "utilities/hooks/use-get-user-dashboard";
import "./no-access-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NoAccessPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "no-access-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NoAccessPage: React.FC<NoAccessPageProps> = validatePageAccess(
    AccessControlKeys.NoAccessPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const currentRole = globalState.currentIdentity?.role?.roleType;
    const { dashboardPath } = useGetUserDashboard({
        userRole: globalState.currentIdentity?.role?.roleType,
        language: globalState.currentIdentity?.user?.preferredLanguage,
    });

    return (
        <div className={CSS_CLASS_NAME}>
            <Icon type={Icons.LockPerson} size={IconSizes.XLarge}></Icon>
            <Heading
                cssClassName={`${CSS_CLASS_NAME}__heading`}
                size={HeadingSize.Default}
                priority={HeadingPriority.H2}>
                {t("accessLocked")}
            </Heading>
            <Paragraph size={ParagraphSize.XLarge}>
                {t(
                    "youDoNotHaveAccessToThisPageBasedOnYourNFPALMSRoleTypeDisplayNamescurrentRoleProfile",
                    { RoleTypeDisplayNamescurrentRole: t(RoleTypeDisplayNames[currentRole!]) }
                )}
            </Paragraph>
            <Button text={t("goToYourDashboard")} linkPath={dashboardPath} type={ButtonType.Link} />
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__contact`}>
                {t("ifYouBelieveThisIsAnErrorPleaseContact")}{" "}
                <a href="mailto:custserv@nfpa.org">{t("nfpaSupport")}</a>.
            </Paragraph>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NoAccessPage };

// #endregion Exports
