import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventManageAddProviders } from "./event-manage-add-provider";
import { Modal, ModalAction } from "components/modal/modal";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-provider-selection-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProviderSelectionModalProps {
    event: EventActiveRecord;
    onProviderChange: (providerId?: number) => void;
    open: boolean;
    selectedProvider?: ProviderRecord;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-provider-selection-modal";

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProviderSelectionModal: React.FC<EventProviderSelectionModalProps> = (
    props: EventProviderSelectionModalProps
): JSX.Element => {
    const [providers, setProvidersAvailable] = useState<ProviderRecord[]>([]);
    const [selectedProvider, setSelectedProvider] = useState(props.selectedProvider);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: props.selectedProvider ? t("replaceENProvider") : t("done"),
            onClick: () => onProviderSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setSelectedProvider(props.selectedProvider);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onProviderSave = () => {
        props.onProviderChange(selectedProvider?.id);
        resetForm();
        props.setOpen(false);
    };

    const handleAddProviderToQueue = useCallback((provider: ProviderRecord): void => {
        setSelectedProvider(provider);
        setDirty(true);
    }, []);

    const handleRemoveProviderFromQueue = useCallback((provider: ProviderRecord): void => {
        setSelectedProvider(undefined);
    }, []);

    const handleFetchProviders = useCallback((providers: ProviderRecord[]): void => {
        setProvidersAvailable(providers);
    }, []);

    useEffect(() => {
        setSelectedProvider(props.selectedProvider);
    }, [props.selectedProvider]);

    const banner = useMemo(() => {
        if (props.selectedProvider == null) {
            return <></>;
        }
        return (
            <Banner style={BannerStyle.Error} format={BannerFormat.Box}>
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <Paragraph style={ParagraphStyle.Default}>
                        <strong>{t("pleaseReviewBeforeReplacingYourENProvider")}</strong>
                    </Paragraph>
                    <Paragraph>
                        {t(
                            "byReplacingTheAssociatedENProviderAnyInformationEnteredForEventContactInformationWillBeReset"
                        )}
                    </Paragraph>
                </div>
            </Banner>
        );
    }, [props.selectedProvider]);

    return (
        <Modal
            actions={modalActionArray}
            banner={banner}
            isOpen={props.open}
            modalStyle={""}
            onModalClose={onModalClose}
            title={`${
                props.selectedProvider == null ? t("selectAnENProvider") : t("replaceAnENProvider")
            }`}>
            <EventManageAddProviders
                event={props.event!}
                handleAddProviderToQueue={handleAddProviderToQueue}
                handleFetchProviders={handleFetchProviders}
                handleRemoveProviderFromQueue={handleRemoveProviderFromQueue}
                providers={providers}
                selectedProvider={selectedProvider}
            />
            <Modal
                actions={confirmationActionArray}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProviderSelectionModal };

// #endregion Exports
