import React, { ChangeEvent } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Course } from "models/interfaces/courses/course";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, UpdateCoursePathParams } from "utilities/services/courses/course-service";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { RecordUpdater } from "utilities/contexts/use-record-context-factory";
import { ToastManager } from "utilities/toast/toast-manager";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { t } from "utilities/localization/t";
import { useCourse } from "utilities/hooks/models/courses/use-course";
import "./course-details-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseDetailsFormProps {
    course: CourseRecord;
    editMode: boolean;
    onCourseDetailsChange: (course: CourseRecord) => void;
    setCourse: RecordUpdater<Course, CourseRecord>;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-details-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseDetailsForm: React.FC<CourseDetailsFormProps> = (
    props: CourseDetailsFormProps
): JSX.Element => {
    const { update } = CourseService.useUpdate();
    const { course } = useCourse({
        courseId: props.course.id,
        includeLatestVersion: true,
        includeScormPackage: true,
        includeContent: true,
    });

    const updateCourse = (values: Partial<Course>): void => {
        props.onCourseDetailsChange(props.course.with(values));
        if (props.editMode) {
            props.setDirty(true);
        }
    };

    const handleCourseNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateCourse({ name: event.target.value });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateCourse({ description: event.target.value });
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateCourse(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ language: Number(value) }),
                Language
            )
        );
    };

    const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateCourse(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ topic: Number(value) }),
                Topic
            )
        );
    };

    const handleTextAreaUpdate = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>
    ) => {
        updateCourseDetails(e.target.name);
    };

    const handleSelectFieldUpdate = (e: ChangeEvent<HTMLSelectElement>) => {
        updateCourseDetails(e.target.title);
    };

    const updateCourseDetails = async (fieldName: string): Promise<boolean> => {
        try {
            if (props.course.status === ActiveStatus.Active) {
                return false;
            }

            const updateCoursePathParms: UpdateCoursePathParams = {
                id: props.course.id!,
            };

            const updateCourseResponse = await update(props.course, updateCoursePathParms);

            const updateResult = updateCourseResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheFieldName", { fieldName: fieldName }));
            props.setCourse(course!);
            return false;
        }
        return true;
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__column`}>
                <FormTextInput
                    ariaLabelledBy={t("courseName")}
                    autoFocus={true}
                    formFieldName="courseName"
                    id="courseName"
                    label={t("courseName")}
                    maxLength={200}
                    onBlur={handleTextAreaUpdate}
                    onChange={handleCourseNameChange}
                    placeholder={t("enterCourseName")}
                    required={true}
                    type={InputTypes.Text}
                    value={props.course.name}
                />
                <FormTextArea
                    ariaLabelledBy={t("courseDescription")}
                    cssClassName={`${CSS_CLASS_NAME}__col-span-2`}
                    formFieldName={t("courseDescription")}
                    id="courseDescription"
                    label={t("courseDescription")}
                    maxLength={1000}
                    onBlur={handleTextAreaUpdate}
                    onChange={handleDescriptionChange}
                    placeholder={t("enterCourseDescription")}
                    required={true}
                    rows={6}
                    value={props.course.description ?? ""}
                />
                <div className={`${CSS_CLASS_NAME}__form-field-container`}>
                    <FormTextInput
                        ariaLabelledBy={t("courseId")}
                        disabled
                        formFieldName={t("courseId")}
                        id="courseId"
                        label={t("courseId")}
                        placeholder={t("courseId")}
                        value={props.course.id}
                    />
                    <p className={`${CSS_CLASS_NAME}__input-description`}>
                        {t("courseIdIsAutomaticallyGenerated")}
                    </p>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__column`}>
                <FormSelect
                    ariaLabelledBy={t("topicSelection")}
                    formFieldName={t("courseTopicSelection")}
                    id="courseTopicSelect"
                    label={t("topic")}
                    onBlur={handleSelectFieldUpdate}
                    onChange={handleTopicChange}
                    options={EnumUtils.numericEnumToSelectOptions(Topic, TopicDisplayNames)}
                    required={true}
                    value={props.course.topic?.toString()}
                />
                <FormSelect
                    ariaLabelledBy={t("languageSelection")}
                    formFieldName={t("languageSelect")}
                    id="languageSelect"
                    label={t("language")}
                    onBlur={handleSelectFieldUpdate}
                    onChange={handleLanguageChange}
                    options={EnumUtils.numericEnumToSelectOptions(Language, LanguageDisplayNames)}
                    required={true}
                    value={props.course.language?.toString()}
                />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDetailsForm };

// #endregion Exports
