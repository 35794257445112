import { ResultErrorRecord } from "andculturecode-javascript-core";
import { BadgeRecord } from "models/view-models/badges/badge-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { BadgeService } from "utilities/services/badges/badge-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseBadgeHook {
    badge?: BadgeRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseBadgeHookOptions {
    enrollmentId?: number;
}

export function useBadge(props: UseBadgeHookOptions): UseBadgeHook {
    const enrollmentId = props.enrollmentId;
    const [isLoading, setIsLoading] = useState(true);
    const [badge, setBadge] = useState<BadgeRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listBadge } = BadgeService.useList();

    useEffect(() => {
        if (enrollmentId == null || enrollmentId < 1) {
            return;
        }

        (async function fetchBadge() {
            try {
                const [badgeResponse] = await Promise.all([
                    listBadge({
                        enrollmentId: enrollmentId,
                    }),
                ]);

                setIsLoading(false);

                if (badgeResponse.result?.hasErrors()) {
                    const { errors: badgeErrors = [] } = badgeResponse.result ?? {};
                    setErrors([...badgeErrors]);
                    return;
                }

                if (badgeResponse.resultObjects.length > 0) {
                    const badge = badgeResponse.resultObjects[0];
                    setBadge(badge);
                }
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheBadge"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [enrollmentId, listBadge]);

    return {
        errors,
        isLoading,
        badge,
    };
}
