import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./event-activation-contract-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationContractSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-contract-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationContractSection: React.FC<EventActivationContractSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("contractInformation")}</h3>
            {editMode && <ChangesBanner changed={changed!} />}
            <div className={`${CSS_CLASS_NAME}__container`}>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("contractNumber")}</Paragraph>
                    <Paragraph>{event.contract?.contractNumber}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("contractDate")}</Paragraph>
                    <Paragraph>{event.contract?.getContractDate()}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("enrollmentLimit")}</Paragraph>
                    <Paragraph>{event.contract?.enrollmentLimit}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("nfpaContactName")}</Paragraph>
                    <Paragraph>{event.contract?.nfpaAdminName}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("nfpaContactEmail")}</Paragraph>
                    <Paragraph>{event.contract?.nfpaAdminEmail}</Paragraph>
                </div>
                <div className={`${CSS_CLASS_NAME}__container-item`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("nfpaContactPhone")}</Paragraph>
                    <Paragraph>{event.contract?.formatPhoneNumber()}</Paragraph>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationContractSection };

// #endregion Exports
