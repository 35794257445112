import { PublicLayoutFooter } from "layouts/public/public-layout/public-layout-footer/public-layout-footer";
import React from "react";
import { Outlet } from "react-router-dom";
import { PublicLayoutHeader } from "./public-layout-header/public-layout-header";
import { PublicLayoutVideoContainer } from "./public-layout-video-container/public-layout-video-container";
import "./public-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PublicLayoutProps {
    sidebar?: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "public-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicLayout: React.FC<PublicLayoutProps> = (props: PublicLayoutProps): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <PublicLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            <div className={`${CSS_CLASS_NAME}__body`}>
                <main>
                    <Outlet />
                    <PublicLayoutFooter cssClassName={`${CSS_CLASS_NAME}__footer`} />
                </main>
                <PublicLayoutVideoContainer />
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PublicLayout };

// #endregion Exports
