import { ProductVersionArchiveRecord } from "models/view-models/products/product-version-archive-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "productversionarchive";
const resourceType: typeof ProductVersionArchiveRecord = ProductVersionArchiveRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListProductVersionArchivesQueryParams {
    productId: number;
    productVersionId: number;
    includeCreatedBy?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductVersionArchiveService = {
    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        ProductVersionArchiveRecord,
        ListProductVersionArchivesQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductVersionArchiveService };

// #endregion Exports
