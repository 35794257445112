import { ServiceHookFactory } from "andculturecode-javascript-react";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "evaluationtemplates";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const recordType: typeof EvaluationTemplateRecord = EvaluationTemplateRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEvaluationTemplatePathParams {
    id: number;
}

export interface GetEvaluationTemplateQueryParams {
    includeQuestions?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EvaluationTemplateService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EvaluationTemplateRecord,
        GetEvaluationTemplatePathParams,
        GetEvaluationTemplateQueryParams
    >(recordType, resourceEndpoint),

    /**
     * Custom hook for leveraging service list calls in React components
     */
    useList: ServiceHookFactory.useList(recordType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationTemplateService };

// #endregion Exports
