import React, { useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { Icons } from "components/icons/constants/icons";
import { IconTab } from "components/tabs/tabs/icon-tab/icon-tab";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ProductAchievements } from "components/products/product-achievements/product-achievements";
import { ProductContentManager } from "components/products/product-content/product-content-manager";
import { ProductCourseLearningPath } from "components/courses/product-course-learning-path/product-course-learning-path";
import { ProductCourseSeries } from "components/courses/product-course-series/product-course-series";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductEvaluation } from "components/products/product-evaluation/product-evaluation";
import { RouteUtils } from "utilities/route-utils";
import { TabbedContainer } from "components/tabs/tabbed-container/tabbed-container";
import { UnitRecord } from "models/view-models/units/unit-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./create-online-product-version-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateOnlineProductVersionMaterialsProps {
    setCourseHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
    setCourseToggleHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleClicks: React.Dispatch<React.SetStateAction<number>>;
    setUnit: React.Dispatch<React.SetStateAction<UnitRecord>>;
    toggleClicks: number;
    unit: UnitRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "create-online-product-version-materials";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateOnlineProductVersionMaterials: React.FC<CreateOnlineProductVersionMaterialsProps> = ({
    setCourseHasChanges,
    setCourseToggleHasChanges,
    setToggleClicks,
    setUnit,
    toggleClicks,
    unit,
}): JSX.Element => {
    const navigate = useNavigate();
    const { record: product, setRecord: setProduct } = useProduct();
    const [showCancelCreateVersionModal, setShowCancelCreateVersionModal] =
        useState<boolean>(false);
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const inCreateVersionMode = product.status === ActiveStatus.Active;

    const onlineLearningTabs = useMemo(() => {
        return [
            {
                contents:
                    product.onlineLearningType === ProductCourseType.CourseSeries ? (
                        <ProductCourseSeries
                            createVersionMode={inCreateVersionMode}
                            editMode={false}
                            product={product}
                            setCourseHasChanges={setCourseHasChanges}
                            setCourseToggleHasChanges={setCourseToggleHasChanges}
                            setToggleClicks={setToggleClicks}
                            setUnit={setUnit}
                            toggleClicks={toggleClicks}
                            unit={unit}
                        />
                    ) : (
                        <ProductCourseLearningPath
                            createVersionMode={inCreateVersionMode}
                            editMode={false}
                            product={product}
                            setCourseHasChanges={setCourseHasChanges}
                            setCourseToggleHasChanges={setCourseToggleHasChanges}
                            setToggleClicks={setToggleClicks}
                            toggleClicks={toggleClicks}
                        />
                    ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("courses"),
                    iconType: productVersion.courseIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductContentManager
                        inCreateVersionMode={inCreateVersionMode}
                        product={product}
                        productVersion={productVersion}
                        setProductVersion={setProductVersion}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("content"),
                    iconType: productVersion.contentIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductAchievements
                        inCreateVersionMode={inCreateVersionMode}
                        product={product}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("achievements"),
                    iconType: productVersion.achievementIsComplete(product.audienceType)
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductEvaluation
                        createVersionMode={inCreateVersionMode}
                        product={product}
                        setProduct={setProduct}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("evaluation"),
                    iconType: product.evaluationTemplateIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
        ];
    }, [
        product,
        inCreateVersionMode,
        setCourseHasChanges,
        setCourseToggleHasChanges,
        setToggleClicks,
        setUnit,
        toggleClicks,
        unit,
        productVersion,
        setProductVersion,
        setProduct,
    ]);

    const aenTrainingTabs = useMemo(() => {
        return [
            {
                contents:
                    product.onlineLearningType === ProductCourseType.CourseSeries ? (
                        <ProductCourseSeries
                            createVersionMode={inCreateVersionMode}
                            editMode={false}
                            product={product}
                            setCourseHasChanges={setCourseHasChanges}
                            setCourseToggleHasChanges={setCourseToggleHasChanges}
                            setToggleClicks={setToggleClicks}
                            setUnit={setUnit}
                            toggleClicks={toggleClicks}
                            unit={unit}
                        />
                    ) : (
                        <ProductCourseLearningPath
                            createVersionMode={inCreateVersionMode}
                            editMode={false}
                            product={product}
                            setCourseHasChanges={setCourseHasChanges}
                            setCourseToggleHasChanges={setCourseToggleHasChanges}
                            setToggleClicks={setToggleClicks}
                            toggleClicks={toggleClicks}
                        />
                    ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("courses"),
                    iconType: productVersion.courseIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
            {
                contents: (
                    <ProductContentManager
                        inCreateVersionMode={inCreateVersionMode}
                        product={product}
                        productVersion={productVersion}
                        setProductVersion={setProductVersion}
                    />
                ),
                tabComponent: IconTab,
                tabDisplayDetails: {
                    tabName: t("content"),
                    iconType: productVersion.contentIsComplete()
                        ? Icons.CircleChecked
                        : Icons.CircleUnchecked,
                },
            },
        ];
    }, [
        product,
        inCreateVersionMode,
        setCourseHasChanges,
        setCourseToggleHasChanges,
        setToggleClicks,
        setUnit,
        toggleClicks,
        unit,
        productVersion,
        setProductVersion,
    ]);

    const onModalClose = () => {
        setShowCancelCreateVersionModal(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                    id: product.id,
                })
            )
        );
    };

    return (
        <div>
            {product.activatedOn != null && (
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <NotificationBanner
                        notificationId={0}
                        onClose={() => {}}
                        style={NotificationType.Default}
                        message={t(
                            "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion"
                        )}
                    />
                </div>
            )}

            <TabbedContainer
                tabs={
                    product.type === TrainingType.OnlineLearning
                        ? onlineLearningTabs
                        : aenTrainingTabs
                }
            />
            <CancelChangesModal
                open={showCancelCreateVersionModal}
                setOpen={setShowCancelCreateVersionModal}
                onModalClose={onModalClose}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateOnlineProductVersionMaterials };

// #endregion Exports
