import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { EvaluationTemplate } from "models/interfaces/evaluation-templates/evaluation-template";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EvaluationTemplate = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    description: undefined,
    id: undefined,
    instructionText: undefined,
    name: "",
    questions: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    language: undefined,
    trainingType: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EvaluationTemplateRecord extends Record(defaultValues) implements EvaluationTemplate {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EvaluationTemplate>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.questions)) {
            params.questions = RecordUtils.ensureRecords(
                params.questions,
                EvaluationQuestionTemplateRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to sort the Questions by the Question.SortOrder.
     */
    public sortQuestions(): void {
        if (!CollectionUtils.hasValues(this.questions)) {
            return;
        }

        this.questions!.sort(
            (a?: EvaluationQuestionTemplateRecord, b?: EvaluationQuestionTemplateRecord) => {
                return (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0);
            }
        );

        this.questions!.forEach((question: EvaluationQuestionTemplateRecord) => {
            question.sortAnswers();
        });
    }

    /**
     * Given a set of values for EvaluationTemplate properties, create a new EvaluationTemplateRecord
     * object from this instance, overwriting the properties in the values parameter with values
     * provided.
     *
     * @param {Partial<EvaluationTemplate>} values The values to overwrite on this instance.
     * @returns An EvaluationTemplateRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EvaluationTemplate>): EvaluationTemplateRecord {
        return new EvaluationTemplateRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationTemplateRecord };

// #endregion Exports
