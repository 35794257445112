import React, { useEffect, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ProductAssociateBadgeForm } from "components/products/product-associate-badge-form/product-associate-badge-form";
import { ProductCEUsForm } from "components/products/product-ceus-form/product-ceus-form";
import { ProductCertificateExpirationForm } from "components/products/product-certificate-expiration-form/product-certificate-expiration-form";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import "./product-achievements.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductAchievementsProps {
    editMode?: boolean;
    inCreateVersionMode?: boolean;
    product: ProductRecord;
    setDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-achievements";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductAchievements: React.FC<ProductAchievementsProps> = ({
    editMode,
    inCreateVersionMode,
    product,
    setDirty,
}): JSX.Element => {
    const [readOnly, setReadOnly] = useState(false);
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();

    useEffect(() => {
        if (
            product.status === ActiveStatus.Inactive ||
            product.status === ActiveStatus.Archived ||
            (product.status === ActiveStatus.Active && !editMode)
        ) {
            setReadOnly(true);
        }
        if (editMode || inCreateVersionMode) {
            setReadOnly(false);
        }
    }, [editMode, inCreateVersionMode, product.status]);

    const handleProductVersionDetailsChange = (productVersion: ProductVersionRecord): void => {
        setProductVersion(productVersion);
        setDirty && setDirty(true);
    };

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                        {t("achievements")}
                    </Heading>
                </div>
                {editMode && (
                    <div className={`${CSS_CLASS_NAME}__banner`}>
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "changesToBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        />
                        {product.type === TrainingType.InstructorLedTraining && (
                            <NotificationBanner
                                notificationId={0}
                                onClose={() => {}}
                                style={NotificationType.Default}
                                message={t(
                                    "changesToCEUsWillBeAppliedToTheRecommendedClassTimeForAnEventAndWillNotImpactOrResetTheEventScheduleForPublishedOrDraftEvents"
                                )}
                            />
                        )}
                    </div>
                )}
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <div className={`${CSS_CLASS_NAME}__content__section`}>
                        <ProductCEUsForm
                            productVersion={productVersion}
                            onProductVersionDetailsChange={handleProductVersionDetailsChange}
                            deferSave={product.status === ActiveStatus.Active}
                        />
                    </div>
                    {product.audienceType === AudienceType.Learner && (
                        <>
                            <div className={`${CSS_CLASS_NAME}__content__section`}>
                                <ProductAssociateBadgeForm
                                    productVersion={productVersion}
                                    onProductVersionDetailsChange={
                                        handleProductVersionDetailsChange
                                    }
                                    deferSave={product.status === ActiveStatus.Active}
                                />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__content__section`}>
                                <ProductCertificateExpirationForm
                                    productVersion={productVersion}
                                    onProductVersionDetailsChange={
                                        handleProductVersionDetailsChange
                                    }
                                    deferSave={product.status === ActiveStatus.Active}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductAchievements };

// #endregion Exports
