import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";
import { EventDayRecord } from "models/view-models/events/event-day-record";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "eventdays";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof EventDayRecord = EventDayRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEventDayPathParams {
    id: number;
}

export interface GetEventDayQueryParams {}

export interface ListEventDaysQueryParams {
    eventId?: number;
    includeAttendance?: boolean;
}

export interface UpdateEventDayPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventDayService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EventDayRecord,
        GetEventDayPathParams,
        GetEventDayQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EventDayRecord, ListEventDaysQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<EventDayRecord, UpdateEventDayPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayService };

// #endregion Exports
