import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { HeaderBanner } from "components/header-banner/header-banner";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useMemo, useState } from "react";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { t } from "utilities/localization/t";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { DateUtils } from "utilities/date-utils";
import { Pager } from "components/pager/pager";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { Icons } from "components/icons/constants/icons";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import "./my-certificates-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface MyCertificatesPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "my-certificates-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 10;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "typeToSearchByTrainingName";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyCertificatesPage: React.FC<MyCertificatesPageProps> = validatePageAccess(
    AccessControlKeys.MyCertificatesPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();

    const learnerFirstName = useMemo(
        () => globalState?.currentIdentity?.user?.firstName,
        [globalState?.currentIdentity?.user?.firstName]
    );

    const userId = useMemo(
        () => globalState?.currentIdentity?.user?.id,
        [globalState?.currentIdentity?.user?.id]
    );

    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { enrollments, rowCount: totalNumberCertificates } = useUserEnrollments({
        userId: userId,
        hasCertificate: true,
        includeEvent: true,
        includeProduct: true,
        includeCertificate: true,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchTextInputChange = (searchText: string) => {
        setCurrentPage(1);
        setSearchText(searchText);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const handleDownloadCertificateClick = async (
        enrollmentId?: number,
        certificateId?: number
    ) => {
        if (enrollmentId == null || certificateId == null) {
            return;
        }

        const pathParams: DownloadCertificatePathParams = {
            enrollmentId: enrollmentId,
            id: certificateId,
        };

        const queryParams: DownloadCertificateQueryParams = {
            overwriteExisting: false,
        };

        await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
    };

    return (
        <>
            <HeaderBanner
                title={t("twelcomeLearnerFirstName", {
                    twelcome: t("welcome"),
                    learnerFirstName: learnerFirstName,
                })}></HeaderBanner>
            <SkipNavContent>
                <div className={`${CSS_CLASS_NAME}`}>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <div className={`${CSS_CLASS_NAME}__header__text`}>
                                <h2>{t("certificates")}</h2>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__header__search`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextInputChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id="trainingSearch"
                                    placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                                    searchTextInputValue={searchText}
                                />
                            </div>
                        </div>
                        {CollectionUtils.hasValues(enrollments) ? (
                            <>
                                <DataTable>
                                    <thead>
                                        <tr>
                                            <th className="name">{t("name")}</th>
                                            <th className="type">{t("type")}</th>
                                            <th className="certificate-expiration-date">
                                                {t("certificateExpiration")}
                                            </th>
                                            <th className="completion-date">
                                                {t("completionDate")}
                                            </th>
                                            <th className="-ellipsis">
                                                <span className="sr-only">{t("action")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enrollments.map((enrollment, index) => (
                                            <tr key={`${enrollment.id}-${index}`}>
                                                <td className="name">
                                                    <Paragraph size={ParagraphSize.XSmall}>
                                                        {`${enrollment.product?.name}`}
                                                    </Paragraph>
                                                </td>
                                                <td className="type">
                                                    {enrollment.product != null && (
                                                        <ProductBadge
                                                            product={enrollment.product}
                                                            event={enrollment.event}
                                                            includeIcon={false}
                                                            upperCase={false}
                                                        />
                                                    )}
                                                </td>
                                                <td className="certificate-expiration-date">
                                                    <span>
                                                        {enrollment.certificate?.expirationDate
                                                            ? DateUtils.formatDateCustom(
                                                                  enrollment.certificate?.expirationDate?.toString(),
                                                                  t("shortDateFormat")
                                                              )
                                                            : "--"}
                                                    </span>
                                                </td>
                                                <td className="completion-date">
                                                    {enrollment.completedOn == null
                                                        ? `--`
                                                        : DateUtils.formatDateCustom(
                                                              enrollment.completedOn.toString(),
                                                              t("shortDateFormat")
                                                          )}
                                                </td>
                                                <td className="action">
                                                    <ButtonIcon
                                                        iconType={Icons.Download}
                                                        onClick={() =>
                                                            handleDownloadCertificateClick(
                                                                enrollment?.id,
                                                                enrollment?.certificate?.id
                                                            )
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </DataTable>
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={totalNumberCertificates / ITEMS_PER_PAGE}
                                    onPageClick={onPageClick}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={totalNumberCertificates}
                                />
                            </>
                        ) : (
                            <EmptyText cssClassName={`${CSS_CLASS_NAME}__empty`}>
                                {t("noCertificateResultsFound")}
                            </EmptyText>
                        )}
                    </div>
                </div>
            </SkipNavContent>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MyCertificatesPage };

// #endregion Exports
