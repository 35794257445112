import { EvaluationAnswerTemplate } from "models/interfaces/evaluation-templates/evaluation-answer-template";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EvaluationAnswerTemplate = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    evaluationQuestionTemplate: undefined,
    evaluationQuestionTemplateId: 0,
    id: undefined,
    answerText: "",
    sortOrder: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EvaluationAnswerTemplateRecord extends Record(defaultValues)
    implements EvaluationAnswerTemplate {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EvaluationAnswerTemplate>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.evaluationQuestionTemplate != null) {
            params.evaluationQuestionTemplate = RecordUtils.ensureRecord(
                params.evaluationQuestionTemplate,
                EvaluationQuestionTemplateRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EvaluationAnswerTemplate properties, create a new
     * EvaluationAnswerTemplateRecord object from this instance, overwriting the properties in the
     * values parameter with values provided.
     *
     * @param {Partial<EvaluationAnswerTemplate>} values The values to overwrite on this instance.
     * @returns An EvaluationAnswerTemplateRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EvaluationAnswerTemplate>): EvaluationAnswerTemplateRecord {
        return new EvaluationAnswerTemplateRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationAnswerTemplateRecord };

// #endregion Exports
