import { AttemptCompletionStatus } from "models/enumerations/attempts/attempt-completion-status";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { AttemptService } from "utilities/services/attempts/attempt-service";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { EnrollmentScormPackageAssessmentService } from "utilities/services/enrollments/enrollment-scorm-package-assessment-service";
import { NumberUtils } from "utilities/number-utils";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseEnrollmentScormPackageAssessmentsHook {
    enrollmentScormPackageAssessments?: EnrollmentScormPackageAssessmentRecord[];
    addAttemptToAssessment: (
        attempt: AttemptRecord,
        enrollmentScormPackageAssessment: EnrollmentScormPackageAssessmentRecord
    ) => Promise<boolean>;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseEnrollmentScormPackageAssessmentsHookOptions {
    enrollmentId?: number | string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ATTEMPT_CREATE_ERROR: TranslatedCopy = "anErrorOccurredWhileSavingTheAttempt";
const REGISTRATION_CREATE_ERROR: TranslatedCopy = "anErrorOccurredWhileAddingTheAttempt";

// endregion Constants

export function useEnrollmentScormPackageAssessments(
    props: UseEnrollmentScormPackageAssessmentsHookOptions
): UseEnrollmentScormPackageAssessmentsHook {
    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentScormPackageAssessments, setEnrollmentScormPackageAssessments] =
        useState<EnrollmentScormPackageAssessmentRecord[]>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listEnrollmentScormPackageAssessment } =
        EnrollmentScormPackageAssessmentService.useList();

    const { create: registrationCreate } = RegistrationService.useCreate();
    const { create: attemptCreate } = AttemptService.useCreate();

    const fetchData = useCallback(async () => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function fetchEnrollmentScormPackageAssessment() {
            try {
                const [enrollmentScormPackageAssessmentResponse] = await Promise.all([
                    listEnrollmentScormPackageAssessment({
                        enrollmentId: enrollmentId,
                    }),
                ]);

                setIsLoading(false);

                if (enrollmentScormPackageAssessmentResponse.result?.hasErrors()) {
                    const { errors: enrollmentScormPackageAssessmentErrors = [] } =
                        enrollmentScormPackageAssessmentResponse.result ?? {};

                    setErrors([...enrollmentScormPackageAssessmentErrors]);
                    return;
                }

                setEnrollmentScormPackageAssessments(
                    enrollmentScormPackageAssessmentResponse.resultObjects
                );
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheAssessment"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [enrollmentId, listEnrollmentScormPackageAssessment]);

    const addAttemptToAssessment = async (
        attempt: AttemptRecord,
        assessment: EnrollmentScormPackageAssessmentRecord
    ): Promise<boolean> => {
        const registrationId = await findOrCreateRegistrationId(assessment);
        if (registrationId == null) {
            return false;
        }

        const attemptForAssessment = attempt.with({
            registrationId: registrationId,
            completionStatus: AttemptCompletionStatus.Completed,
            isManualEntry: true,
        });
        try {
            await attemptCreate(attemptForAssessment);
            ToastManager.success(t("attemptCreatedSuccessfully"));

            fetchData();
            return true;
        } catch {
            ToastManager.error(t(ATTEMPT_CREATE_ERROR));
            return false;
        }
    };

    const findOrCreateRegistrationId = async (
        assessment: EnrollmentScormPackageAssessmentRecord
    ): Promise<number | undefined> => {
        if (assessment.registration?.id != null && assessment.registration?.id > 0) {
            return assessment.registration?.id;
        } else {
            const registration = await addRegistrationForAssessment(assessment);
            if (registration == null) {
                return;
            }
            return registration.id!;
        }
    };

    const addRegistrationForAssessment = async (
        assessment: EnrollmentScormPackageAssessmentRecord
    ): Promise<RegistrationRecord | undefined> => {
        try {
            const userId = assessment.enrollment?.userId;
            const scormPackageId = assessment.scormPackageAssessment?.scormPackage?.id;
            const enrollmentId = assessment.enrollmentId;
            const enrollmentScormPackageAssessmentId = assessment.id;
            if (
                userId == null ||
                scormPackageId == null ||
                enrollmentId == null ||
                enrollmentScormPackageAssessmentId == null
            ) {
                return undefined;
            }

            const registration = new RegistrationRecord({
                enrollmentId: enrollmentId,
                enrollmentScormPackageAssessmentId: enrollmentScormPackageAssessmentId,
                scormPackageId: scormPackageId,
                userId: userId,
                skipExternalRegistration: true,
            });

            const createRegistrationResponse = await registrationCreate(registration);
            const createRegistrationResult = createRegistrationResponse?.result;

            if (
                createRegistrationResult?.resultObject == null ||
                createRegistrationResult.hasErrors()
            ) {
                ToastManager.error(t(REGISTRATION_CREATE_ERROR));
                return;
            }

            const newRegistration = createRegistrationResult?.resultObject;
            return newRegistration;
        } catch (error: unknown) {
            ToastManager.error(t(REGISTRATION_CREATE_ERROR));
            return undefined;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        enrollmentScormPackageAssessments,
        addAttemptToAssessment,
    };
}
