import React, { useCallback, useMemo } from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import {
    AttemptSuccessStatus,
    AttemptSuccessStatusDisplayNames,
    AttemptSuccessStatusStatusMap,
} from "models/enumerations/attempts/attempt-success-status";
import { Card } from "components/card/card";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { RouteUtils } from "utilities/route-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { useRegistrations } from "utilities/hooks/models/registration/use-registrations";
import { useEnrollments } from "utilities/hooks/models/enrollments/use-enrollments";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";
import { UserInfoStack } from "components/users/user-info-stack";
import "./learner-breakdown-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerBreakdownCardProps {
    eventId: number;
    scormPackageAssessmentId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-breakdown-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerBreakdownCard: React.FC<LearnerBreakdownCardProps> = (
    props: LearnerBreakdownCardProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const getLearnerAttemptDetailsPath = useCallback(
        (registrationId: number): string =>
            RouteUtils.replacePathParams(viewEventRoutes.learnerAssessment, {
                id: props.eventId,
                assessmentId: props.scormPackageAssessmentId,
                registrationId: registrationId,
            }),
        [props.eventId, props.scormPackageAssessmentId, viewEventRoutes.learnerAssessment]
    );

    const { registrations } = useRegistrations({
        eventId: props.eventId ?? 0,
        scormPackageAssessmentId: props.scormPackageAssessmentId ?? 0,
        includeAttempts: true,
        includeUser: true,
    });

    const { enrollments } = useEnrollments({ eventId: props.eventId, includeUser: true });

    const enrollmentsWithoutAttempts = useMemo(() => {
        const attemptUserIds = registrations
            .filter((r) => r.attempts != null && r.attempts.length > 0)
            .map((registration: RegistrationRecord): number => registration.userId ?? 0);

        return enrollments.filter((enrollment) => {
            return !attemptUserIds.some((id: number) => id === enrollment.userId);
        });
    }, [enrollments, registrations]);

    const getAttemptStatusBadge = (registration: RegistrationRecord) => {
        if (registration.hasPassingAttempt()) {
            return (
                <EnumStatusBadge
                    displayNames={AttemptSuccessStatusDisplayNames}
                    statusMap={AttemptSuccessStatusStatusMap}
                    value={AttemptSuccessStatus.Passed}
                />
            );
        }
        return <Badge style={BadgeStyle.Warning} text={t("attempted")} />;
    };

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <h3>{t("learnerBreakdown")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th className="name">{t("name")}</th>
                        <th className="status">{t("result")}</th>
                        <th className="action">
                            <span className="sr-only">{t("action")}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {registrations.map(
                        (registration: RegistrationRecord): JSX.Element => (
                            <tr key={registration.id}>
                                <td className="name">{registration.user?.getFirstAndLastName()}</td>
                                <td className="status">{getAttemptStatusBadge(registration)}</td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuAnchor
                                            hrefPath={getLearnerAttemptDetailsPath(
                                                registration.id!
                                            )}
                                            displayName={t("viewResults")}
                                        />
                                    </ContextMenu>
                                </td>
                            </tr>
                        )
                    )}
                    {enrollmentsWithoutAttempts.map(
                        (enrollment: EnrollmentRecord): JSX.Element => (
                            <tr key={enrollment.id}>
                                <td className="name">
                                    {enrollment.user != null && (
                                        <UserInfoStack
                                            user={enrollment.user}
                                            showUserId={currentUserIsInNfpaRole}
                                        />
                                    )}
                                </td>
                                <td className="status">
                                    <EnumStatusBadge
                                        displayNames={AttemptSuccessStatusDisplayNames}
                                        statusMap={AttemptSuccessStatusStatusMap}
                                        value={AttemptSuccessStatus.Unknown}
                                    />
                                </td>
                                <td className="action">
                                    <Icon
                                        size={IconSizes.Large}
                                        type={Icons.MoreVertical}
                                        cssClassName={`${CSS_CLASS_NAME}__disabled`}
                                    />
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </DataTable>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerBreakdownCard };

// #endregion Exports
