import moment from "moment";
import { DateUtils } from "utilities/date-utils";
import { EventDayRecord } from "./event-day-record";
import { EventSession } from "models/interfaces/events/event-session";
import { NumberUtils } from "utilities/number-utils";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventSession = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    endHour: 0,
    endMinute: 0,
    eventDay: undefined,
    eventDayId: 0,
    id: undefined,
    name: undefined,
    startHour: 0,
    startMinute: 0,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventSessionRecord extends Record(defaultValues) implements EventSession {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventSession>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.eventDay != null) {
            params.eventDay = RecordUtils.ensureRecord(params.eventDay, EventDayRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to get a Date object representing the end time of this session.
     *
     * @returns {Date} A Date object representing the end time of this session.
     */
    public endTime(): Date {
        const now = new Date();

        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            this.endHour,
            this.endMinute
        );
    }

    /**
     * Convenience method to the format the start and end time
     * Ex: 9:00 AM - 12:00 PM
     */
    public formatStartAndEndTime(): string {
        const startTime = moment(this.startTime()).format("h:mm A");
        const endTime = moment(this.endTime()).format("h:mm A");
        return `${startTime} - ${endTime} `;
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    public getLastUpdatedText(): string {
        return DateUtils.formatLastEditedDate(this, true);
    }

    /**
     * Convenience method to the return a Time
     * Without this, we were getting an error when trying to access date fields as a Date type.
     */
    public getTime(value: Date): Date {
        return new Date(value);
    }

    /**
     * Convenience method to the return the session duration in hours.
     */
    public getSessionDuration(): number {
        if (this.startTime() === undefined || this.endTime() === undefined) {
            return 0;
        }

        const durationInMilliseconds = NumberUtils.parseInt(
            new Date(this.endTime()).getTime() - new Date(this.startTime()).getTime()
        );

        if (durationInMilliseconds === undefined) {
            return 0;
        }

        return durationInMilliseconds / 60 / 60 / 1000;
    }

    /**
     * Convenience method to get a value that will be unique to the EventSessionRecord within an
     * EventDay.
     *
     * Note: This is based on the requirement that an EventSession's start and end times are unique
     * within an EventDay.
     *
     * @returns {number} A value that uniquely identifies this EventSessionRecord within an EventDay.
     */
    public getUniqueIdentifier(): string {
        return this.id?.toString() ?? `${this.startTime().getTime()}-${this.endTime().getTime()}`;
    }

    /**
     * Convenience method to get a Date object representing the start time of this session.
     *
     * @returns {Date} A Date object representing the start time of this session.
     */
    public startTime(): Date {
        const now = new Date();

        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            this.startHour,
            this.startMinute
        );
    }

    /**
     * Given a set of values for EventSession properties, create a new EventSessionRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventSession>} values The values to overwrite on this instance.
     * @returns A EventSessionRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventSession>): EventSessionRecord {
        return new EventSessionRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventSessionRecord };

// #endregion Exports
