import React from "react";
import { useMatches, useParams } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { Anchor } from "../../../typography/anchors/anchor/anchor";
import { sitemap } from "../../../../sitemap";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./contracts-detail-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ContractsDetailSidebarProps {}

interface ContractsDetailSidebarNavItem {
    path: string;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "contracts-detail-sidebar";

const navItems: ContractsDetailSidebarNavItem[] = [
    {
        path: sitemap.admin.userManagement.contracts.info,
        text: "contractOrganizationInformation",
    },
    {
        path: sitemap.admin.userManagement.contracts.onlineLearning.list,
        text: "onlineLearningContracts",
    },
    {
        path: sitemap.admin.userManagement.contracts.instructorLed.list,
        text: "instructorLedEventsContracts",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ContractsDetailSidebar: React.FC<ContractsDetailSidebarProps> = (): JSX.Element => {
    const routeParams = useParams();
    const contractId = routeParams.id;

    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const match = matches.find((match) =>
            match.pathname?.toLowerCase().startsWith(path.toLowerCase())
        );
        return match != null;
    };

    return (
        <nav className={CSS_CLASS_NAME} aria-label="Sidebar">
            {navItems.map((navItem) => (
                <Anchor
                    key={`nav-item-${navItem.text.replace(" ", "-")}`}
                    path={RouteUtils.replacePathParams(navItem.path, { id: contractId })}
                    text={t(navItem.text)}
                    cssClassName={
                        findPathMatch(
                            RouteUtils.replacePathParams(navItem.path, { id: contractId })
                        )
                            ? "-selected"
                            : ""
                    }
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractsDetailSidebar };

// #endregion Exports
