import React from "react";
import { EventScheduleExceptionGranted } from "./event-schedule-exception-granted/event-schedule-exception-granted";
import { EventScheduleExceptionRecord } from "models/view-models/events/event-schedule-exception-record";
import { Modal } from "components/modal/modal";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventScheduleExceptionGrantedModalProps {
    eventScheduleException: EventScheduleExceptionRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventScheduleExceptionGrantedModal: React.FC<EventScheduleExceptionGrantedModalProps> = (
    props: EventScheduleExceptionGrantedModalProps
): JSX.Element => {
    const onModalClose = (): void => {
        props.setOpen(false);
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("exceptionGranted")}
            modalStyle={""}>
            <EventScheduleExceptionGranted eventScheduleException={props.eventScheduleException} />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventScheduleExceptionGrantedModal };

// #endregion Exports
