import { AenApplicationType } from "models/enumerations/aen-applications/aen-application-types";
import { RoleType } from "models/enumerations/users/role-type";
import { useMemo } from "react";

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

const useAenRoleTypes = (applicationType: AenApplicationType): RoleType[] => {
    return useMemo(
        () =>
            applicationType === AenApplicationType.Provider
                ? [RoleType.NfpaAdministrator]
                : [RoleType.NfpaAdministrator, RoleType.Reviewer],
        [applicationType]
    );
};

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { useAenRoleTypes };

// #endregion Exports
