import { CourseVersion } from "models/interfaces/courses/course-version";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type CourseVersionRecordUpdater = RecordUpdater<CourseVersion, CourseVersionRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const { RecordContext: CourseVersionContext, useRecord: useCourseVersion } =
    UseRecordContextFactory.build<CourseVersion, CourseVersionRecord>(CourseVersionRecord);

export { CourseVersionContext, useCourseVersion };

// #endregion Exports
