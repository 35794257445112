import { Announcement } from "../../interfaces/announcements/announcement";
import { DateUtils } from "utilities/date-utils";
import { Language } from "models/enumerations/languages/language";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "../users/user-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultBody = "<p><br></p>";

const defaultValues: Announcement = {
    arabicBody: defaultBody,
    arabicTitle: undefined,
    archivedById: undefined,
    archivedOn: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    englishBody: defaultBody,
    englishTitle: "",
    spanishBody: defaultBody,
    spanishTitle: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class AnnouncementRecord extends Record(defaultValues) implements Announcement {
    // -------------------------------------------------------------------------------------------------
    // #region Static Properties
    // -------------------------------------------------------------------------------------------------

    public static DefaultBody: string = defaultBody;

    // #endregion Static Properties

    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Announcement>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.createdBy != null) {
            params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
        }

        if (params.updatedBy != null) {
            params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
        }
        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public getLastChangeDate() {
        const lastChangeDateTime = DateUtils.formatDateTime(
            false,
            this.updatedOn ?? this.createdOn
        );

        if (this.updatedOn == null || this.createdOn == null) {
            return lastChangeDateTime;
        }

        return lastChangeDateTime;
    }

    public getLastChangedByName() {
        return this.updatedBy?.getFirstAndLastName() ?? this.createdBy?.getFirstAndLastName();
    }

    public getLastChangedById() {
        return this.updatedById ?? this.createdById;
    }

    public getBodyByLanguage(language: Language): string {
        switch (language) {
            case Language.English:
                return this.englishBody ? this.englishBody : defaultBody;
            case Language.Spanish:
                return this.spanishBody ? this.spanishBody : defaultBody;
            case Language.Arabic:
                return this.arabicBody ? this.arabicBody : defaultBody;
        }
    }

    public getBodyTextByLanguage(language: Language): string {
        switch (language) {
            case Language.English:
                return this._getTextOnly(this.englishBody);
            case Language.Spanish:
                return this._getTextOnly(this.spanishBody ?? "");
            case Language.Arabic:
                return this._getTextOnly(this.arabicBody ?? "");
        }
    }

    public getTitleByLanguage(language: Language): string {
        switch (language) {
            case Language.English:
                return this.englishTitle ?? "";
            case Language.Spanish:
                return this.spanishTitle ?? "";
            case Language.Arabic:
                return this.arabicTitle ?? "";
        }
    }

    public getCurrentBodyAndTitle(language: Language) {
        const html = this.getBodyByLanguage(language);
        const text = this.getBodyTextByLanguage(language);
        const title = this.getTitleByLanguage(language);

        return { html, text, title };
    }

    public getPreferredBodyAndTitleOrDefaultEnglish(
        language: Language,
        announcementData: AnnouncementRecord
    ) {
        if (language === Language.English) {
            return [announcementData.englishBody, announcementData.englishTitle];
        }
        if (language === Language.Spanish && announcementData.spanishBody !== "<p><br></p>") {
            return [announcementData.spanishBody, announcementData.spanishTitle];
        }
        if (
            language === Language.Arabic &&
            announcementData.arabicBody !== "<p><br></p>" &&
            announcementData.arabicBody !== `<p class="ql-align-right"><br></p>`
        ) {
            return [announcementData.arabicBody, announcementData.arabicTitle];
        }
        return [announcementData.englishBody, announcementData.englishTitle];
    }

    public isPublishable(language = Language.English): boolean {
        if (!this.getBodyTextByLanguage(language) || !this.getTitleByLanguage(language)) {
            return false;
        }

        return true;
    }

    public withBody(language: Language, body: string): AnnouncementRecord {
        switch (language) {
            case Language.Arabic:
                return this.with({
                    arabicBody: body,
                });
            case Language.English:
                return this.with({
                    englishBody: body,
                });
            case Language.Spanish:
                return this.with({
                    spanishBody: body,
                });
        }
    }

    public withTitle(language: Language, title: string): AnnouncementRecord {
        switch (language) {
            case Language.Arabic:
                return this.with({
                    arabicTitle: title,
                });
            case Language.English:
                return this.with({
                    englishTitle: title,
                });
            case Language.Spanish:
                return this.with({
                    spanishTitle: title,
                });
        }
    }

    /**
     * Given a set of values for Announcement properties, create a new AnnouncementRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Announcement>} values The values to overwrite on this instance.
     * @returns An AnnouncementRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Announcement>): AnnouncementRecord {
        return new AnnouncementRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods

    // -------------------------------------------------------------------------------------------------
    // #region Private Methods
    // -------------------------------------------------------------------------------------------------

    private _getTextOnly(html: string): string {
        var span = document.createElement("span");
        span.innerHTML = html;
        return span.textContent ?? span.innerText;
    }

    // #endregion Private Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnnouncementRecord };

// #endregion Exports
