import { UserLoginRecord } from "models/view-models/users/user-login-record";
import { ServiceFactory, ServiceHookFactory } from "andculturecode-javascript-react";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const baseEndpoint: string = "userlogins";
const baseEndpointFromCookie: string = `${baseEndpoint}/cookie`;
const resourceType: typeof UserLoginRecord = UserLoginRecord;
const resourceEndpoint: string = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserLoginPathParams {}

export interface UpdateUserLoginPathParams {
    id: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const UserLoginService = {
    /**
     * Create a new user
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Soft delete a user entity
     */
    delete: ServiceFactory.delete(resourceEndpoint),

    /**
     * Custom hook for leveraging service create calls in react components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Attempts to locate the user's current login via the cookie.
     * When successful a UserLogin was found
     */
    useGetFromCookie: ServiceHookFactory.useGet<UserLoginRecord, UserLoginPathParams>(
        resourceType,
        baseEndpointFromCookie
    ),

    useUpdate: ServiceHookFactory.useUpdate<UserLoginRecord, UpdateUserLoginPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { UserLoginService };

// #endregion Exports
