import { EventChangeLog } from "models/interfaces/events/event-change-log";
import { EventRecord } from "models/view-models/events/event-record";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UserRecord } from "models/view-models/users/user-record";
import { DateUtils } from "utilities/date-utils";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventChangeLog = {
    id: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    changedBy: undefined,
    changedById: 0,
    changedOn: new Date(),
    event: undefined,
    eventId: 0,
    type: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventChangeLogRecord extends Record(defaultValues) implements EventChangeLog {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventChangeLog>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.changedBy != null) {
            params.changedBy = RecordUtils.ensureRecord(params.changedBy, UserRecord);
        }

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to the return the text for the changed on updated date text
     * Ex: on YYYY/MM/D at 8:00 am
     */
    public getChangedOnDateAndTime(): string {
        const message = DateUtils.formatDateTime(true, this.changedOn.toString(), true);
        return message;
    }

    /**
     * Given a set of values for EventChangeLog properties, create a new EventChangeLogRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventChangeLog>} values The values to overwrite on this instance.
     * @returns A EventChangeLogRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventChangeLog>): EventChangeLogRecord {
        return new EventChangeLogRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChangeLogRecord };

// #endregion Exports
