import React from "react";
import { useMatches, useParams } from "react-router-dom";
import { RouteUtils } from "utilities/route-utils";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import "./group-detail-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupDetailSidebarProps {
    navItems: GroupDetailSidebarNavItem[];
}

export interface GroupDetailSidebarNavItem {
    path: string;
    text: TranslatedCopy;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "group-detail-sidebar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupDetailSidebar: React.FC<GroupDetailSidebarProps> = ({ navItems }): JSX.Element => {
    const routeParams = useParams();
    const groupId = routeParams.id;

    const matches: any[] = useMatches();

    const findPathMatch = (path: string): boolean => {
        const match = matches.find((match) =>
            match.pathname?.toLowerCase().startsWith(path.toLowerCase())
        );
        return match != null;
    };

    return (
        <nav className={CSS_CLASS_NAME} aria-label={t("sidebar")}>
            {navItems.map((navItem) => (
                <Anchor
                    key={`nav-item-${navItem.text.replace(" ", "-")}`}
                    path={RouteUtils.replacePathParams(navItem.path, { id: groupId })}
                    text={t(navItem.text)}
                    cssClassName={
                        findPathMatch(RouteUtils.replacePathParams(navItem.path, { id: groupId }))
                            ? "-selected"
                            : ""
                    }
                />
            ))}
        </nav>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupDetailSidebar };

// #endregion Exports
