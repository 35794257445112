import { NumberUtils } from "utilities/number-utils";
import { ProductContentService } from "utilities/services/products/product-content-service";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductService } from "utilities/services/products/product-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";

interface UseProductHook {
    product?: ProductRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseProductHookOptions {
    productId?: number | string;
    includeContent?: boolean;
}

export function useProduct(props: UseProductHookOptions): UseProductHook {
    const productId = NumberUtils.parseInt(props.productId) ?? 0;
    const includeContent = props.includeContent ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState<ProductRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { get: getProduct } = ProductService.useGet();
    const { list: listProductContents } = ProductContentService.useList();

    useEffect(() => {
        if (isNaN(productId) || productId < 1) {
            setProduct(undefined);
            return;
        }

        (async function fetchProduct() {
            try {
                const [productResponse, productContentsResponse] = await Promise.all([
                    getProduct({ id: productId }),
                    listProductContents({ productId, includeContent }),
                ]);

                setIsLoading(false);

                if (
                    productResponse.result?.hasErrors() ||
                    productContentsResponse.result?.hasErrors()
                ) {
                    const { errors: productErrors = [] } = productResponse.result ?? {};
                    const { errors: productContentErrors = [] } =
                        productContentsResponse.result ?? {};

                    setErrors([...productErrors, ...productContentErrors]);
                    return;
                }

                setProduct(productResponse.resultObject);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingTheProductDetails"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [getProduct, includeContent, listProductContents, productId]);

    return {
        errors,
        isLoading,
        product,
    };
}
