// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EncodingType {
    UTF8 = 0,
    Windows1252 = 1,
}

// #endregion Enum

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { EncodingType };

// #endregion Exports
