import React from "react";
import { useRunOnce } from "utilities/hooks/use-run-once";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AppVersion: React.FC = () => {
    useRunOnce(() => {
        console.log(
            t("runningLMSAppVersionProcessEnvREACTAPPBUILDVERSION", {
                processEnvREACTAPPBUILDVERSION: process.env.REACT_APP_BUILD_VERSION,
            })
        );
    });

    return <></>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AppVersion };

// #endregion Exports
