import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Anchor } from "components/typography/anchors/anchor/anchor";
import { Banner, BannerStyle } from "components/banner/banner";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { CollectionUtils } from "andculturecode-javascript-core";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductAssessmentList } from "./product-assessment-list/product-assessment-list";
import { ProductCreateAssessmentModal } from "./product-assesment-create-modal/product-create-assessment-modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductScormPackageRecord } from "models/view-models/products/product-scorm-package-record";
import { ProductScormPackageService } from "utilities/services/products/product-scorm-package-service";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import {
    ProductVersionService,
    UpdateProductVersionPathParams,
} from "utilities/services/products/product-version-service";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { RouteUtils } from "utilities/route-utils";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import {
    GetScormPackageResourceEndpointPathParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { sitemap } from "sitemap";
import { useCallback, useEffect, useState } from "react";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { t } from "utilities/localization/t";
import "./product-assessment-manager.scss";
import { ScormPackageStatusRecord } from "models/view-models/scorm-packages/scorm-package-status-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductAssessmentManagerProps {
    createVersionMode?: boolean;
    editMode?: boolean;
    product: ProductRecord;
    productVersion: ProductVersionRecord;
    setProductVersion: React.Dispatch<React.SetStateAction<ProductVersionRecord>>;
    setAssessmentHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setAssessmentToggleHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setToggleClicks?: React.Dispatch<React.SetStateAction<number>>;
    toggleClicks?: number;
}

interface StatusUpdateListItem {
    productScormPackageId: number;
    status: ScormPackageImportStatus;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "product-assessment-manager";
const MAXSTATUSCHECKS = 20;

// #endregion Constants
// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductAssessmentManager: React.FC<ProductAssessmentManagerProps> = ({
    createVersionMode,
    editMode,
    product,
    productVersion,
    setProductVersion,
    setAssessmentHasChanges,
    setAssessmentToggleHasChanges,
    setToggleClicks,
    toggleClicks,
}) => {
    const [showModal, setShowModal] = useState(false);
    const { create: apiProductScormPackageCreate } = ProductScormPackageService.useCreate();
    const { list: listProductScormPackages } = ProductScormPackageService.useList();
    const { get: getScormPackageStatus } = ScormPackageService.useGetScormPackageStatus();
    const { update: updateProductVersionApi } = ProductVersionService.useUpdate();
    const [readOnly, setReadOnly] = useState(false);
    const [statusUpdateList, setStatusUpdateList] = useState<StatusUpdateListItem[]>([]);
    const [statusPollingList, setStatusPollingList] = useState<number[]>([]);

    const deferSave = product.status === ActiveStatus.Active;

    useEffect(() => {
        if (
            product.status === ActiveStatus.Inactive ||
            product.status === ActiveStatus.Archived ||
            (product.status === ActiveStatus.Active &&
                productVersion.status === ActiveStatus.Active) ||
            !editMode
        ) {
            setReadOnly(true);
        }
        if (
            createVersionMode ||
            (editMode && productVersion.status !== ActiveStatus.Active) ||
            product.status === ActiveStatus.Draft
        ) {
            setReadOnly(false);
        }
    }, [createVersionMode, editMode, product.activatedOn, product.status, productVersion.status]);

    const onCompleteAssessmentsInOrderToggle = async (productVersion: ProductVersionRecord) => {
        const productVersionWithChanges = productVersion.with({
            completeAssessmentsInOrder: !productVersion.completeAssessmentsInOrder,
        });

        if (deferSave) {
            const updatedCount = toggleClicks! + 1;
            setToggleClicks && setToggleClicks(updatedCount);

            if (updatedCount % 2 === 0) {
                setAssessmentToggleHasChanges && setAssessmentToggleHasChanges(false);
            } else {
                setAssessmentToggleHasChanges && setAssessmentToggleHasChanges(true);
            }
            setProductVersion(productVersionWithChanges);
        } else {
            if (await updateProductVersion(productVersionWithChanges)) {
                setProductVersion(productVersionWithChanges);
            }
        }
    };

    const updateProductVersion = async (
        productVersionWithChanges: ProductVersionRecord
    ): Promise<boolean> => {
        const updateProductPathParams: UpdateProductVersionPathParams = {
            id: productVersionWithChanges.id!,
        };

        const updateProductResponse = await updateProductVersionApi(
            productVersionWithChanges,
            updateProductPathParams
        );
        const updateResult = updateProductResponse?.result;
        if (updateResult?.resultObject == null || updateResult.hasErrors()) {
            throw new Error();
        }

        return true;
    };

    const fetchProductScormPackageList = useCallback(async () => {
        try {
            const listScormPackagesResponse = await listProductScormPackages({
                productId: product.id!,
                productVersionId: productVersion.id!,
                includeScormPackage: true,
            });

            const listScormPackagesResults = listScormPackagesResponse?.results;
            const productScormPackages = listScormPackagesResponse?.resultObjects;

            if (
                productScormPackages == null ||
                listScormPackagesResults == null ||
                listScormPackagesResults.hasErrors()
            ) {
                throw new Error();
            }

            productScormPackages.sort((a, b) => a.sortOrder! - b.sortOrder!);
            setProductVersion(
                (previousProductVersion: ProductVersionRecord): ProductVersionRecord =>
                    previousProductVersion.with({ productScormPackages: productScormPackages })
            );
        } catch {
            ToastManager.error(t("failedToRetrieveProductScormPackages"));
        }
    }, [listProductScormPackages, product.id, productVersion.id, setProductVersion]);

    const delay = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const getUpdatedStatus = useCallback(
        async (
            productVersionScormPackageId: number
        ): Promise<ScormPackageStatusRecord | undefined> => {
            const pathParams: GetScormPackageResourceEndpointPathParams = {
                id: productVersionScormPackageId,
            };
            try {
                const getResponse = await getScormPackageStatus(pathParams);
                const getResult = getResponse?.result;

                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                const updatedStatus = getResult.resultObject;
                return updatedStatus;
            } catch {
                ToastManager.info(t("unableToUpdateStatus"));
                return undefined;
            }
        },
        [getScormPackageStatus]
    );

    const doStatusPollingAsync = useCallback(
        async (productScormPackageId: number): Promise<void> => {
            let keepRunning = true;
            let maxRuns = 0;
            while (keepRunning) {
                await delay(1000);

                const updatedStatus = await getUpdatedStatus(productScormPackageId);
                if (updatedStatus?.uploadStatus == null) return;
                if (
                    updatedStatus.uploadStatus === ScormPackageImportStatus.Complete ||
                    updatedStatus.uploadStatus === ScormPackageImportStatus.Error
                ) {
                    //check if this id exists in the statusUpdateList
                    const statusUpdateListItem = statusUpdateList.find(
                        (item) => item.productScormPackageId === productScormPackageId
                    );
                    const updatedStatusListItem: StatusUpdateListItem = {
                        productScormPackageId: productScormPackageId,
                        status: updatedStatus.uploadStatus,
                    };

                    if (statusUpdateListItem == null) {
                        //add it
                        setStatusUpdateList((previousList) => [
                            ...previousList,
                            updatedStatusListItem,
                        ]);
                    } else {
                        //update it
                        const updatedStatusUpdateList = statusUpdateList.map((item) => {
                            if (item.productScormPackageId === productScormPackageId) {
                                return updatedStatusListItem;
                            }
                            return item;
                        });
                        setStatusUpdateList(updatedStatusUpdateList);
                    }
                }
                keepRunning =
                    updatedStatus.uploadStatus === ScormPackageImportStatus.Running ||
                    updatedStatus.uploadStatus === ScormPackageImportStatus.Processing;
                maxRuns++;
                if (maxRuns > MAXSTATUSCHECKS) keepRunning = false;
            }
        },
        [getUpdatedStatus, statusUpdateList]
    );

    const saveProductScormPackages = useCallback(
        async (
            productId: number,
            productVersionId: number,
            scormPackage: ScormPackageRecord
        ): Promise<boolean> => {
            try {
                if (productId == null) {
                    throw new Error();
                }

                const createScormPackageResponse = await ScormPackageService.create(scormPackage);
                const createScormPackageResult = createScormPackageResponse?.result;

                if (
                    createScormPackageResult?.resultObject == null ||
                    createScormPackageResult.hasErrors()
                ) {
                    throw new Error(t("createScormPackageFailed"));
                }

                const nextSortOrder = !CollectionUtils.hasValues(
                    productVersion.productScormPackages
                )
                    ? 1
                    : Math.max(...productVersion.productScormPackages.map((pa) => pa.sortOrder!)) +
                      1;

                if (deferSave) {
                    //update the productVersion:
                    const productScormPackages = [];
                    if (productVersion.productScormPackages != null) {
                        productScormPackages.push(...productVersion.productScormPackages);
                    }

                    const scormPackageRecord: ScormPackageRecord =
                        createScormPackageResult.resultObject.with({
                            file: scormPackage.file,
                        });

                    const productVersionWithChanges = productVersion.with({
                        productScormPackages: [
                            ...productScormPackages,
                            new ProductScormPackageRecord({
                                productId: productId,
                                productVersionId: productVersionId,
                                scormPackageId: scormPackageRecord.id,
                                scormPackage: scormPackageRecord,
                                sortOrder: nextSortOrder,
                            }),
                        ],
                    });
                    setProductVersion(productVersionWithChanges);
                } else {
                    const productScormPackageRecord = new ProductScormPackageRecord({
                        productId: productId,
                        productVersionId: productVersionId,
                        scormPackageId: createScormPackageResult.resultObject.id,
                        sortOrder: nextSortOrder,
                    });

                    const createProductScormPackageResponse = await apiProductScormPackageCreate(
                        productScormPackageRecord
                    );
                    const createProductScormPackageResult =
                        createProductScormPackageResponse?.result;

                    if (
                        createProductScormPackageResult?.resultObject == null ||
                        createProductScormPackageResult.hasErrors()
                    ) {
                        throw new Error(t("linkingSCORMPackageToProductFailed"));
                    }

                    fetchProductScormPackageList();
                }

                return true;
            } catch (error) {
                if (error === "") {
                    ToastManager.error(t("failedToLinkSCORMPackageToProduct"));
                } else {
                    ToastManager.error(`${error}`);
                }
                return false;
            }
        },
        [
            apiProductScormPackageCreate,
            deferSave,
            fetchProductScormPackageList,
            productVersion,
            setProductVersion,
        ]
    );

    const onProductAssessmentListUpdate = useCallback(
        async (productAssessments: ProductScormPackageRecord[]) => {
            //This unit has the new course added to it
            //now set it it to the productVersion
            setProductVersion((pv) => pv.with({ productScormPackages: productAssessments }));
        },
        [setProductVersion]
    );

    useEffect(() => {
        if (statusUpdateList.length === 0) return;
        try {
            const toUpdate = productVersion.productScormPackages?.map((ps) => {
                if (
                    ps.scormPackage?.status === ScormPackageImportStatus.Running ||
                    ps.scormPackage?.status !== ScormPackageImportStatus.Processing
                ) {
                    //look for it in the statusUpdateList:
                    const statusUpdateListItem = statusUpdateList.find(
                        (item) => item.productScormPackageId === ps.scormPackage?.id
                    );
                    if (statusUpdateListItem == null) return ps;
                    if (statusUpdateListItem.status !== ps.scormPackage?.status) {
                        return ps.with({
                            scormPackage: ps.scormPackage?.with({
                                status: statusUpdateListItem?.status,
                            }),
                        });
                    } else {
                        return ps;
                    }
                }
                return ps;
            });
            onProductAssessmentListUpdate(toUpdate!);
        } catch {
            ToastManager.info(t("unableToUpdateStatus"));
        }
        statusUpdateList.length = 0;
    }, [
        onProductAssessmentListUpdate,
        statusUpdateList,
        /*productVersion.productScormPackages- this should not trigger this effect*/
    ]);

    useEffect(() => {
        const scormPackageIds: number[] | undefined = productVersion.productScormPackages
            ?.filter(
                (ps: ProductScormPackageRecord) =>
                    ps.scormPackage?.status === ScormPackageImportStatus.Running ||
                    ps.scormPackage?.status === ScormPackageImportStatus.Processing
            )
            .map((ps: ProductScormPackageRecord) => ps.scormPackageId!);
        if (scormPackageIds == null || scormPackageIds.length === 0) return;

        //If you are already polling for status updates, don't do it again
        if (CollectionUtils.hasValues(scormPackageIds)) {
            try {
                scormPackageIds.forEach((id: number) => {
                    if (statusPollingList.includes(id)) return;
                    setStatusPollingList((previousList) => [...previousList, id]);
                    doStatusPollingAsync(id);
                });
            } catch {
                ToastManager.info(t("unableToUpdateStatus"));
            }
        }
    }, [doStatusPollingAsync, productVersion.productScormPackages, statusPollingList]);

    return (
        <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H5} size={HeadingSize.XSmall}>
                        {t("assessments")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__header__actions`}>
                        <ToggleLabel
                            checked={productVersion.completeAssessmentsInOrder}
                            id="complete-assessment-in-order"
                            label={t("completeAssessmentsInOrder")}
                            onToggle={() => onCompleteAssessmentsInOrderToggle(productVersion)}
                        />
                        <Button
                            text={t("addAssessments")}
                            onClick={() => setShowModal(true)}
                            style={ButtonStyle.Primary}
                        />
                    </div>
                </div>
                {editMode && productVersion.status === ActiveStatus.Active && (
                    <Banner style={BannerStyle.Light}>
                        <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                            {t(
                                "changesToAssessmentsAreNotAvailableWhenEditingAnActiveProductVersionToChangeAssessments"
                            )}{" "}
                            <Anchor
                                cssClassName={`${CSS_CLASS_NAME}__create-new-version-link`}
                                path={RouteUtils.localizePath(
                                    RouteUtils.replacePathParams(
                                        sitemap.admin.product.edit.instructorLed.materials.version
                                            .create,
                                        { id: product.id }
                                    )
                                )}>
                                {t("createANewProductVersion")}
                            </Anchor>
                            .
                        </Paragraph>
                    </Banner>
                )}
                <ProductCreateAssessmentModal
                    handleSaveAssessment={saveProductScormPackages}
                    open={showModal}
                    productId={product.id!}
                    productVersionId={productVersion.id!}
                    setAssessmentHasChanges={setAssessmentHasChanges!}
                    setOpen={setShowModal}
                />
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <ProductAssessmentList
                        fetchProductScormPackageList={fetchProductScormPackageList}
                        onProductAssessmentUpdate={onProductAssessmentListUpdate}
                        productAssessments={productVersion.productScormPackages}
                        setAssessmentHasChanges={setAssessmentHasChanges!}
                    />
                </div>
            </div>
        </ReadOnlyContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductAssessmentManager };

// #endregion Exports
