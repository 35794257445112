import { useCallback, useEffect, useState } from "react";
import { ToastManager } from "utilities/toast/toast-manager";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { InstructorProfileRecord } from "models/view-models/instructors/instructor-profile-record";
import {
    InstructorProfileService,
    ListInstructorProfilesQueryParams,
} from "utilities/services/instructors/instructor-profile-service";
import { InstructorTrainingTypeRecord } from "models/view-models/instructors/instructor-training-type-record";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { InstructorTrainingTypeService } from "utilities/services/instructors/instructor-training-type-service";
import { TravelType } from "models/instructors/travel-type";
import { InstructorTravelTypeRecord } from "models/view-models/instructors/instructor-travel-type-record";
import { InstructorTravelTypeService } from "utilities/services/instructors/instructor-travel-type-service";
import { Language } from "models/enumerations/languages/language";
import { InstructorLanguageRecord } from "models/view-models/instructors/instructor-language-record";
import { InstructorLanguageService } from "utilities/services/instructors/instructor-language-service";
import { t } from "utilities/localization/t";

interface UseInstructorProfileHook {
    instructorProfile: InstructorProfileRecord;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    saveInstructorProfile: (profile: InstructorProfileRecord) => Promise<boolean>;
    addLanguage: (language: Language) => Promise<boolean>;
    removeLanguage: (instructorLanguage: InstructorLanguageRecord) => Promise<boolean>;
    addTrainingType: (trainingType: InstructorLedTrainingType) => Promise<boolean>;
    removeTrainingType: (instructorTrainingType: InstructorTrainingTypeRecord) => Promise<boolean>;
    addTravelType: (travelType: TravelType) => Promise<boolean>;
    removeTravelType: (instructorTravelType: InstructorTravelTypeRecord) => Promise<boolean>;
}

interface UseInstructorProfileHookOptions {
    userId?: number;
    includeAvatarFile?: boolean;
    includeResumeFile?: boolean;
    includeLanguages?: boolean;
    includeTrainingTypes?: boolean;
    includeTravelTypes?: boolean;
}

export function useInstructorProfile(
    props: UseInstructorProfileHookOptions
): UseInstructorProfileHook {
    const userId = props.userId;
    const includeAvatarFile = props.includeAvatarFile ?? false;
    const includeLanguages = props.includeLanguages ?? false;
    const includeTrainingTypes = props.includeTrainingTypes ?? false;
    const includeTravelTypes = props.includeTravelTypes ?? false;
    const includeResumeFile = props.includeResumeFile ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [instructorProfile, setInstructorProfile] = useState<InstructorProfileRecord>(
        new InstructorProfileRecord()
    );
    const [errors, setErrors] = useState<ResultErrorRecord[]>();

    const { list: listProfileApi } = InstructorProfileService.useList();
    const { update: updateProfileApi } = InstructorProfileService.useUpdate();
    const { create: createLanguageApi } = InstructorLanguageService.useCreate();
    const { delete: deleteLanguageApi } = InstructorLanguageService.useDelete();
    const { create: createTrainingTypeApi } = InstructorTrainingTypeService.useCreate();
    const { delete: deleteTrainingTypeApi } = InstructorTrainingTypeService.useDelete();
    const { create: createTravelTypeApi } = InstructorTravelTypeService.useCreate();
    const { delete: deleteTravelTypeApi } = InstructorTravelTypeService.useDelete();

    const fetchData = useCallback(async () => {
        if (userId != null && userId < 1) {
            return;
        }

        (async function getProfile() {
            try {
                const queryParams: ListInstructorProfilesQueryParams = {
                    includeAvatarFile: includeAvatarFile,
                    includeLanguages: includeLanguages,
                    includeTrainingTypes: includeTrainingTypes,
                    includeTravelTypes: includeTravelTypes,
                    includeResumeFile: includeResumeFile,
                    userId: userId,
                };

                const listProfilesResponse = await listProfileApi(queryParams);
                setIsLoading(false);
                if (listProfilesResponse.result?.hasErrors()) {
                    const { errors: listProfileErrors = [] } = listProfilesResponse.result ?? {};

                    setErrors([...listProfileErrors]);
                    return;
                }

                const profile = listProfilesResponse.resultObjects[0];

                if (profile == null) {
                    throw new Error();
                }

                setInstructorProfile(profile);
            } catch (err) {
                ToastManager.error(t("thereWasAProblemLoadingTheProfile"));
                setIsLoading(false);
                setErrors([
                    new ResultErrorRecord({
                        message: `${err}`,
                    }),
                ]);
            }
        })();
    }, [
        includeAvatarFile,
        includeLanguages,
        includeResumeFile,
        includeTrainingTypes,
        includeTravelTypes,
        listProfileApi,
        userId,
    ]);

    const addLanguage = async (language: Language): Promise<boolean> => {
        setIsLoading(true);
        try {
            const instructorLanguage = new InstructorLanguageRecord({
                instructorProfileId: instructorProfile?.id,
                language: language,
            });

            const createLanguageResponse = await createLanguageApi(instructorLanguage);
            const createLanguageResult = createLanguageResponse.result;

            if (createLanguageResult?.resultObject == null || createLanguageResult.hasErrors()) {
                throw new Error();
            }

            const createdLanguage = createLanguageResult.resultObject;

            setInstructorProfile(
                instructorProfile?.with({
                    instructorLanguages: [
                        ...(instructorProfile?.instructorLanguages ?? []),
                        createdLanguage,
                    ],
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedAddingLanguage"));
            setIsLoading(false);
            return false;
        }
    };

    const removeLanguage = async (
        instructorLanguage: InstructorLanguageRecord
    ): Promise<boolean> => {
        setIsLoading(true);
        try {
            if (!(await deleteLanguageApi(instructorLanguage.id!))) {
                throw new Error();
            }
            const updatedLanguages = instructorProfile?.instructorLanguages?.filter(
                (itt) => itt.id !== instructorLanguage.id
            );

            setInstructorProfile(
                instructorProfile?.with({
                    instructorLanguages: updatedLanguages,
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedRemovingLanguage"));
            setIsLoading(false);
            return false;
        }
    };

    const removeTrainingType = async (
        instructorTrainingType: InstructorTrainingTypeRecord
    ): Promise<boolean> => {
        setIsLoading(true);
        try {
            if (!(await deleteTrainingTypeApi(instructorTrainingType.id!))) {
                throw new Error();
            }
            const updatedTrainingTypes = instructorProfile?.instructorTrainingTypes?.filter(
                (trainingType) => trainingType.id !== instructorTrainingType.id
            );

            setInstructorProfile(
                instructorProfile?.with({
                    instructorTrainingTypes: updatedTrainingTypes,
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedRemovingTeachingMethod"));
            setIsLoading(false);
            return false;
        }
    };

    const addTrainingType = async (trainingType: InstructorLedTrainingType): Promise<boolean> => {
        setIsLoading(true);
        try {
            const instructorTrainingType = new InstructorTrainingTypeRecord({
                instructorProfileId: instructorProfile?.id,
                instructorLedTrainingType: trainingType,
            });

            const createTrainingTypeResponse = await createTrainingTypeApi(instructorTrainingType);
            const createTrainingTypeResult = createTrainingTypeResponse.result;

            if (
                createTrainingTypeResult?.resultObject == null ||
                createTrainingTypeResult.hasErrors()
            ) {
                throw new Error();
            }

            const createdTrainingType = createTrainingTypeResult.resultObject;

            setInstructorProfile(
                instructorProfile?.with({
                    instructorTrainingTypes: [
                        ...(instructorProfile?.instructorTrainingTypes ?? []),
                        createdTrainingType,
                    ],
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedAddingTeachingMethod"));
            setIsLoading(false);
            return false;
        }
    };

    const addTravelType = async (travelType: TravelType): Promise<boolean> => {
        setIsLoading(true);
        try {
            const instructorTravelType = new InstructorTravelTypeRecord({
                instructorProfileId: instructorProfile?.id,
                travelType: travelType,
            });

            const createTravelTypeResponse = await createTravelTypeApi(instructorTravelType);
            const createTravelTypeResult = createTravelTypeResponse.result;

            if (
                createTravelTypeResult?.resultObject == null ||
                createTravelTypeResult.hasErrors()
            ) {
                throw new Error();
            }

            const createdTravelType = createTravelTypeResult.resultObject;

            setInstructorProfile(
                instructorProfile?.with({
                    instructorTravelTypes: [
                        ...(instructorProfile?.instructorTravelTypes ?? []),
                        createdTravelType,
                    ],
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedAddingTravelMethod"));
            setIsLoading(false);
            return false;
        }
    };

    const removeTravelType = async (
        instructorTravelType: InstructorTravelTypeRecord
    ): Promise<boolean> => {
        setIsLoading(true);
        try {
            if (!(await deleteTravelTypeApi(instructorTravelType.id!))) {
                throw new Error();
            }
            const updatedTravelTypes = instructorProfile?.instructorTravelTypes?.filter(
                (itt) => itt.id !== instructorTravelType.id
            );

            setInstructorProfile(
                instructorProfile?.with({
                    instructorTravelTypes: updatedTravelTypes,
                })
            );

            setIsLoading(false);
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailedRemovingTravelMethod"));
            setIsLoading(false);
            return false;
        }
    };

    const saveInstructorProfile = async (
        profileWithChanges: InstructorProfileRecord
    ): Promise<boolean> => {
        if (profileWithChanges.id == null || profileWithChanges.id === 0) {
            return false;
        }

        try {
            if (!(await updateProfileApi(profileWithChanges, { id: profileWithChanges.id! }))) {
                throw new Error();
            }

            fetchData();
            return true;
        } catch {
            ToastManager.error(t("profileUpdateFailed"));
            return false;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        instructorProfile,
        saveInstructorProfile,
        addLanguage,
        removeLanguage,
        addTrainingType,
        removeTrainingType,
        addTravelType,
        removeTravelType,
    };
}
