import { ResultErrorRecord } from "andculturecode-javascript-core";
import { AttemptCompletionStatus } from "models/enumerations/attempts/attempt-completion-status";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { useCallback, useEffect, useState } from "react";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { AttemptService } from "utilities/services/attempts/attempt-service";
import { EnrollmentUnitCourseService } from "utilities/services/enrollments/enrollment-unit-course-service";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseEnrollmentUnitCoursesHook {
    enrollmentUnitCourses?: EnrollmentUnitCourseRecord[];
    addAttemptToEnrollmentUnitCourse: (
        attempt: AttemptRecord,
        enrollmentUnitCourse: EnrollmentUnitCourseRecord
    ) => Promise<boolean>;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseEnrollmentUnitCoursesHookOptions {
    enrollmentId?: number | string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ATTEMPT_CREATE_ERROR: TranslatedCopy = "anErrorOccurredWhileSavingTheAttempt";
const REGISTRATION_CREATE_ERROR: TranslatedCopy = "anErrorOccurredWhileAddingTheAttempt";

// endregion Constants

export function useEnrollmentUnitCourses(
    props: UseEnrollmentUnitCoursesHookOptions
): UseEnrollmentUnitCoursesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const [enrollmentUnitCourses, setEnrollmentUnitCourses] =
        useState<EnrollmentUnitCourseRecord[]>();

    const { list: listEnrollmentUnitCourse } = EnrollmentUnitCourseService.useList();
    const { create: registrationCreate } = RegistrationService.useCreate();
    const { create: attemptCreate } = AttemptService.useCreate();

    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;

    const fetchData = useCallback(async () => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function fetchEnrollmentUnitCourses() {
            try {
                const [enrollmentUnitCourseResponse] = await Promise.all([
                    listEnrollmentUnitCourse({
                        enrollmentId: enrollmentId,
                    }),
                ]);

                setIsLoading(false);

                if (enrollmentUnitCourseResponse.result?.hasErrors()) {
                    const { errors: enrollmentUnitCourseErrors = [] } =
                        enrollmentUnitCourseResponse.result ?? {};

                    setErrors([...enrollmentUnitCourseErrors]);
                    return;
                }

                setEnrollmentUnitCourses(enrollmentUnitCourseResponse.resultObjects);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingCourses"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [enrollmentId, listEnrollmentUnitCourse]);

    const addAttemptToEnrollmentUnitCourse = async (
        attempt: AttemptRecord,
        enrollmentUnitCourse: EnrollmentUnitCourseRecord
    ): Promise<boolean> => {
        const registrationId = await findOrCreateRegistrationId(enrollmentUnitCourse);
        if (registrationId == null) {
            return false;
        }

        const attemptForEnrollmentUnitCourse = attempt.with({
            registrationId: registrationId,
            completionStatus: AttemptCompletionStatus.Completed,
            isManualEntry: true,
        });
        try {
            await attemptCreate(attemptForEnrollmentUnitCourse);
            ToastManager.success(t("attemptCreatedSuccessfully"));

            fetchData();
            return true;
        } catch {
            ToastManager.error(t(ATTEMPT_CREATE_ERROR));
            return false;
        }
    };

    const findOrCreateRegistrationId = async (
        enrollmentUnitCourse: EnrollmentUnitCourseRecord
    ): Promise<number | undefined> => {
        if (
            enrollmentUnitCourse.registration?.id != null &&
            enrollmentUnitCourse.registration?.id > 0
        ) {
            return enrollmentUnitCourse.registration?.id;
        } else {
            const registration = await addRegistrationForEnrollmentUnitCourse(enrollmentUnitCourse);
            if (registration == null) {
                return;
            }
            return registration.id!;
        }
    };

    const addRegistrationForEnrollmentUnitCourse = async (
        enrollmentUnitCourse: EnrollmentUnitCourseRecord
    ): Promise<RegistrationRecord | undefined> => {
        try {
            const userId = enrollmentUnitCourse.enrollment?.userId;
            const scormPackageId = enrollmentUnitCourse.courseVersion?.scormPackageId;
            const enrollmentId = enrollmentUnitCourse.enrollmentId;
            const enrollmentUnitCourseId = enrollmentUnitCourse.id;
            if (
                userId == null ||
                scormPackageId == null ||
                enrollmentId == null ||
                enrollmentUnitCourseId == null
            ) {
                return undefined;
            }

            const registration = new RegistrationRecord({
                enrollmentId: enrollmentId,
                enrollmentUnitCourseId: enrollmentUnitCourseId,
                scormPackageId: scormPackageId,
                userId: userId,
                skipExternalRegistration: true,
            });

            const createRegistrationResponse = await registrationCreate(registration);
            const createRegistrationResult = createRegistrationResponse?.result;

            if (
                createRegistrationResult?.resultObject == null ||
                createRegistrationResult.hasErrors()
            ) {
                ToastManager.error(t(REGISTRATION_CREATE_ERROR));
                return;
            }

            const newRegistration = createRegistrationResult?.resultObject;
            return newRegistration;
        } catch (error: unknown) {
            ToastManager.error(t(REGISTRATION_CREATE_ERROR));
            return undefined;
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        errors,
        isLoading,
        enrollmentUnitCourses,
        addAttemptToEnrollmentUnitCourse,
    };
}
