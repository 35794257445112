import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { NumberedBadge } from "components/badges/numbered-badge/numbered-badge";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import {
    AttemptCompletionStatus,
    AttemptCompletionStatusDisplayNames,
    AttemptCompletionStatusStatusMap,
} from "models/enumerations/attempts/attempt-completion-status";
import {
    AttemptSuccessStatus,
    AttemptSuccessStatusDisplayNames,
    AttemptSuccessStatusStatusMap,
} from "models/enumerations/attempts/attempt-success-status";
import React from "react";
import { Status } from "utilities/enumerations/statuses";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface BadgeSamplerProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const BadgeSampler: React.FC<BadgeSamplerProps> = (): JSX.Element => {
    return (
        <div className="grid grid-cols-5 gap-5">
            <Badge text={t("default")} />
            <Badge style={BadgeStyle.Error} text={t("error")} />
            <Badge style={BadgeStyle.Neutral} text={t("neutral")} />
            <Badge style={BadgeStyle.Success} text={t("success")} />
            <Badge style={BadgeStyle.Warning} text={t("warning")} />

            <StatusBadge status={Status.Error} />
            <StatusBadge status={Status.Neutral} />
            <StatusBadge status={Status.Success} />
            <StatusBadge status={Status.Warning} />
            <StatusBadge status={Status.Neutral} text="learner" />

            <NumberedBadge value={1} />
            <NumberedBadge value={10} />
            <NumberedBadge value={100} />
            <NumberedBadge value={1000} />
            <NumberedBadge value={10000} />

            <p>Attempt Completion Status</p>
            <EnumStatusBadge
                displayNames={AttemptCompletionStatusDisplayNames}
                statusMap={AttemptCompletionStatusStatusMap}
                value={AttemptCompletionStatus.Completed}
            />
            <EnumStatusBadge
                displayNames={AttemptCompletionStatusDisplayNames}
                statusMap={AttemptCompletionStatusStatusMap}
                value={AttemptCompletionStatus.Incomplete}
            />
            <EnumStatusBadge
                displayNames={AttemptCompletionStatusDisplayNames}
                statusMap={AttemptCompletionStatusStatusMap}
                value={AttemptCompletionStatus.Unknown}
            />
            <p>Placeholder</p>

            <p>Attempt Success Status</p>
            <EnumStatusBadge
                displayNames={AttemptSuccessStatusDisplayNames}
                statusMap={AttemptSuccessStatusStatusMap}
                value={AttemptSuccessStatus.Failed}
            />
            <EnumStatusBadge
                displayNames={AttemptSuccessStatusDisplayNames}
                statusMap={AttemptSuccessStatusStatusMap}
                value={AttemptSuccessStatus.Passed}
            />
            <EnumStatusBadge
                displayNames={AttemptSuccessStatusDisplayNames}
                statusMap={AttemptSuccessStatusStatusMap}
                value={AttemptSuccessStatus.Unknown}
            />
            <p>Placeholder</p>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BadgeSampler };

// #endregion Exports
