import { Culture } from "andculturecode-javascript-core";
import { Arabic } from "cultures/arabic";
import { English } from "cultures/english";
import { Spanish } from "cultures/spanish";
import { CultureResources, TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum Language {
    Arabic = 0,
    English = 1,
    Spanish = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const LanguageDisplayNames: Map<Language, TranslatedCopy> = {
    [Language.Arabic]: "arabic",
    [Language.English]: "english",
    [Language.Spanish]: "spanish",
};

const LanguageCulture: Map<Language, Culture<CultureResources>> = {
    [Language.Arabic]: Arabic,
    [Language.English]: English,
    [Language.Spanish]: Spanish,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Language, LanguageDisplayNames, LanguageCulture };

// #endregion Exports
