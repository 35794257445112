import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum AttemptCompletionStatus {
    Completed = 0,
    Incomplete = 1,
    Unknown = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AttemptCompletionStatusDisplayNames: Map<AttemptCompletionStatus, TranslatedCopy> = {
    [AttemptCompletionStatus.Completed]: "complete",
    [AttemptCompletionStatus.Incomplete]: "incomplete",
    [AttemptCompletionStatus.Unknown]: "unknown",
};

const AttemptCompletionStatusStatusMap: Map<AttemptCompletionStatus, Status> = {
    [AttemptCompletionStatus.Completed]: Status.Success,
    [AttemptCompletionStatus.Incomplete]: Status.Error,
    [AttemptCompletionStatus.Unknown]: Status.Warning,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export {
    AttemptCompletionStatus,
    AttemptCompletionStatusDisplayNames,
    AttemptCompletionStatusStatusMap,
};

// #endregion Exports
