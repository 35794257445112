import { Rfc4646LanguageCodes } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const defaultFormatter = new Intl.NumberFormat(Rfc4646LanguageCodes.EN_US);

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

const format = (num: number | Number, formatter: Intl.NumberFormat = defaultFormatter): string =>
    formatter.format(num as number);

/**
 * Attempts to parses an integer from the given input. If the input is already a number,
 * it returns that value. If the parsed value returns `NaN`, it returns `undefined`
 *
 * @param {(string | number)} [num]
 * @returns {(number | undefined)}
 */
const parseInt = (num?: string | number): number | undefined => {
    if (num == null) {
        return undefined;
    }

    if (typeof num === "number") {
        if (isNaN(num)) {
            return undefined;
        }

        return num;
    }

    const parsed = Number.parseInt(num);
    if (isNaN(parsed)) {
        return undefined;
    }

    return parsed;
};

/**
 * Convert provided credit hours to CEUs and format as a string with one decimal place.
 * @param creditHours
 * @returns
 */
const formatCEUsFromCreditHours = (creditHours?: number): string => {
    if (creditHours == null) {
        return "--";
    }

    return creditHours ? (creditHours / 10).toFixed(1) : "0";
};

// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const NumberUtils = {
    format: format,
    parseInt: parseInt,
    formatCEUsFromCreditHours: formatCEUsFromCreditHours,
};

export { NumberUtils };

// #endregion Exports
