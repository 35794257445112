import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum InstructorApplicationFileType {
    Other = 0,
    Resume = 1,
}

// #endregion Enum

// -----------------------------------------------------------------------------------------
// #region Maps
// -----------------------------------------------------------------------------------------

const InstructorApplicationFileTypeDisplayNames: Map<
    InstructorApplicationFileType,
    TranslatedCopy
> = {
    [InstructorApplicationFileType.Other]: "other",
    [InstructorApplicationFileType.Resume]: "resume",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { InstructorApplicationFileType, InstructorApplicationFileTypeDisplayNames };

// #endregion Exports
