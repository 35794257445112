import React, { useEffect, useMemo, useState } from "react";
import { Banner, BannerStyle } from "components/banner/banner";
import { CollectionUtils } from "andculturecode-javascript-core";
import { ContractRecord } from "models/view-models/contracts/contract-record";
import { EventRecord } from "models/view-models/events/event-record";
import { EventsControllerIndexType } from "utilities/services/events/event-service";
import {
    FormRadioCardList,
    RadioCardStyle,
} from "components/form/form-radio-card-list/form-radio-card-list";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { initialState, initializer, reducer, validate } from "./report-enrollment-reducer";
import { NumberUtils } from "utilities/number-utils";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { ReportSelection } from "components/reports/report-selection/report-selection";
import { ReportType, ReportTypeDisplayNames } from "./report-type";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useReportReducer } from "utilities/hooks/use-report-reducer";
import EventSelection from "components/event-selection/event-selection";
import ReportContractSelection from "../components/report-contract-selection/report-contract-selection";
import ReportOrganizationSelection from "../components/report-organization-selection/report-organization-selection";
import ReportProductSelection from "../components/report-product-selection/report-product-selection";
import "./report-enrollment.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsEnrollmentProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-enrollment";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportEnrollment: React.FC<ReportsEnrollmentProps> = ({
    onChange,
    roleType,
}): JSX.Element => {
    const [payload, dispatch] = useReportReducer(reducer, initialState, {
        initializer: initializer(roleType),
        validate,
    });

    const { reportType, dateRangeStart, dateRangeEnd } = payload;

    const { record: globalState } = useGlobalState();
    const isClientAdmin = globalState?.currentIdentity?.isCurrentlyInRole(RoleType.ClientAdmin);

    // Non-payload local data.
    const [events, setEvents] = useState<EventRecord[]>([]);
    const [organizations, setOrganizations] = useState<OrganizationRecord[]>([]);
    const [contracts, setContracts] = useState<ContractRecord[]>([]);
    const [products, setProducts] = useState<ProductRecord[]>([]);

    const organizationsIds = useMemo(() => {
        if (isClientAdmin) {
            return [globalState?.currentIdentity?.user?.organizationId ?? 0];
        }
        return organizations.filter((id) => id != null).map((org) => org.id!);
    }, [globalState?.currentIdentity?.user?.organizationId, isClientAdmin, organizations]);

    useEffect(() => {
        onChange({ ...payload });
    }, [onChange, payload]);

    const handleOrganizationsChange = (organizations: OrganizationRecord[]) => {
        setOrganizations(organizations);
        handleChangeContracts([]);
    };

    const handleChangeEvents = (newEvents: EventRecord[]) => {
        setEvents(newEvents);
        dispatch({ type: "updateEventIds", eventIds: newEvents.map((e) => e.id!) });
    };

    const handleChangeContracts = (newContracts: ContractRecord[]) => {
        setContracts(newContracts);
        dispatch({ type: "updateContractIds", contractIds: newContracts.map((c) => c.id!) });
    };

    const handleChangeProducts = (newProducts: ProductRecord[]) => {
        setProducts(newProducts);
        dispatch({ type: "updateProductIds", productIds: newProducts.map((p) => p.id!) });
    };

    const handleDateRangeChange = (date: Array<Date | null> = []) => {
        const [start, end] = date;
        dispatch({ type: "dateRange", dateRangeStart: start, dateRangeEnd: end });
    };

    // region Translateable Contants
    const REPORT_TYPE_CARDS = useMemo(
        () =>
            Object.keys(ReportType)
                .filter((key) => !isNaN(Number(key)))
                .map((key) => {
                    const reportTypeKey = key as unknown as ReportType;
                    const text = ReportTypeDisplayNames[reportTypeKey];

                    return {
                        icon: Icons.Reporting,
                        text: text,
                        value: key.toString(),
                    };
                }),
        []
    );
    // #endregion Translateable Contants
    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <ReportSelection
                                title={t("enrollmentReport")}
                                roleType={roleType}
                                adminOnly={
                                    <div className="report-section">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("iWantToRunAnEnrollmentReportBasedOn")}
                                        </Paragraph>
                                        <FormRadioCardList
                                            id="reportType"
                                            formFieldName="reportType"
                                            label={t("reportType")}
                                            labelScreenReaderOnly={true}
                                            iconSize={IconSizes.Base}
                                            onChange={(reportType) =>
                                                dispatch({
                                                    type: "reportType",
                                                    reportType: NumberUtils.parseInt(
                                                        reportType
                                                    ) as ReportType,
                                                })
                                            }
                                            radioCards={REPORT_TYPE_CARDS}
                                            required={true}
                                            style={RadioCardStyle.Horizontal}
                                            value={reportType?.toString()}
                                        />
                                    </div>
                                }
                            />

                            {/* INSTRUCTOR LED TRAINING EVENT */}
                            {reportType === ReportType.InstructorLedTrainingEvent && (
                                <>
                                    {!payload.isValid && (
                                        <Banner
                                            cssClassName={`${CSS_CLASS_NAME}__banner`}
                                            style={BannerStyle.Warning}>
                                            {t(
                                                "toRunThisReportYouMustSelectEitherAnEventsOrADateRangeYouMayAlsoSelectBoth"
                                            )}
                                        </Banner>
                                    )}
                                    <EventSelection
                                        events={events}
                                        isRequired={false}
                                        onEventsChanged={handleChangeEvents}
                                        type={EventsControllerIndexType.EnrollmentsReport}
                                    />
                                    <div className="report-section half">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("inThisTimePeriod")}
                                        </Paragraph>
                                        <FormCalendarDateRangePicker
                                            required={false}
                                            endDate={dateRangeEnd}
                                            formFieldName="dateRange"
                                            handleEventDateRangeChange={handleDateRangeChange}
                                            label={t("dateRange")}
                                            startDate={dateRangeStart}
                                        />
                                    </div>
                                </>
                            )}

                            {/* ORGANIZATION */}
                            {reportType === ReportType.Organization && (
                                <>
                                    {!isClientAdmin && (
                                        <ReportOrganizationSelection
                                            organizations={organizations}
                                            isRequired={true}
                                            onOrganizationsChanged={handleOrganizationsChange}
                                        />
                                    )}
                                    <ReportContractSelection
                                        disableAddContracts={
                                            isClientAdmin && !CollectionUtils.isEmpty(events)
                                        }
                                        contracts={contracts}
                                        isRequired={
                                            isClientAdmin ? CollectionUtils.isEmpty(events) : true
                                        }
                                        onContractsChanged={handleChangeContracts}
                                        filterByOrganizationIds={organizationsIds}
                                    />
                                    {isClientAdmin && (
                                        <>
                                            <EventSelection
                                                disableAddEvents={
                                                    !CollectionUtils.isEmpty(contracts)
                                                }
                                                events={events}
                                                isRequired={CollectionUtils.isEmpty(contracts)}
                                                onEventsChanged={handleChangeEvents}
                                                type={EventsControllerIndexType.EnrollmentsReport}
                                            />
                                            <div className="report-section half">
                                                <Paragraph size={ParagraphSize.Large}>
                                                    {t("inThisTimePeriod")}
                                                </Paragraph>
                                                <FormCalendarDateRangePicker
                                                    endDate={dateRangeEnd}
                                                    formFieldName="dateRange"
                                                    handleEventDateRangeChange={
                                                        handleDateRangeChange
                                                    }
                                                    label={t("dateRange")}
                                                    startDate={dateRangeStart}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {/* ONLINE LEARNING TRAINING */}
                            {reportType === ReportType.OnlineLearningTraining && (
                                <>
                                    <ReportProductSelection
                                        products={products}
                                        isRequired={true}
                                        trainingType={TrainingType.OnlineLearning}
                                        onProductsChanged={handleChangeProducts}
                                    />
                                    <div className="report-section half">
                                        <Paragraph size={ParagraphSize.Large}>
                                            {t("inThisTimePeriod")}
                                        </Paragraph>
                                        <FormCalendarDateRangePicker
                                            endDate={dateRangeEnd}
                                            formFieldName="dateRange"
                                            handleEventDateRangeChange={handleDateRangeChange}
                                            label={t("dateRange")}
                                            startDate={dateRangeStart}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportEnrollment };

// #endregion Exports
