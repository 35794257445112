import React from "react";
import { SvgIcon, SvgIcons } from "components/icons/constants/svg-icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import "./icon.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface IconProps {
    ariaLabel?: string;
    cssClassName?: string;
    size?: IconSizes;
    type: keyof typeof Icons;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "icon";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Icon: React.FunctionComponent<IconProps> = (props: IconProps) => {
    let cssClassNames: Array<any> = [CSS_CLASS_NAME];

    if (props.cssClassName) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.size) {
        cssClassNames.push(props.size);
    }

    const icon: SvgIcon = SvgIcons.find(
        (x: SvgIcon) => x.key === props.type
    ) as SvgIcon;

    if (icon === null || icon === undefined) {
        return <i></i>;
    }

    if (props.size === IconSizes.Large) {
        return React.createElement(icon.large, {
            "aria-hidden": true,
            "aria-label": props.ariaLabel,
            className: cssClassNames.join(" "),
            focusable: "false",
        });
    }

    return React.createElement(icon.base, {
        className: cssClassNames.join(" "),
        "aria-label": props.ariaLabel,
        focusable: "false",
    });
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Icons
// -------------------------------------------------------------------------------------------------

export { Icon };

// #endregion Icons
