import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import ReportSection from "components/reports/components/report-section/report-section";
import { SearchSelectionModal } from "components/reports/components/search-selection-modal/search-selection-modal";
import { DataTable } from "components/tables/data-table/data-table";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import { useCallback, useState } from "react";
import {
    InstructorService,
    ListInstructorsQueryParams,
} from "utilities/services/instructors/instructor-service";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { CollectionUtils } from "utilities/collection-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportInstructorSelectionProps {
    isRequired?: boolean;
    instructors: InstructorRecord[];
    onInstructorsChanged: (instructors: InstructorRecord[]) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportInstructorSelection: React.FC<ReportInstructorSelectionProps> = ({
    isRequired = false,
    instructors,
    onInstructorsChanged,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchInstructors, setSearchInstructors] = useState<InstructorRecord[]>([]);

    const { list: listInstructors } = InstructorService.useList();
    const fetchInstructors = useCallback(
        async (searchText?: string) => {
            try {
                const listInstructorsQueryParams: ListInstructorsQueryParams = {
                    searchText,
                };

                const listFilteredInstructorsResponse = await listInstructors(
                    listInstructorsQueryParams
                );
                const listFilteredInstructorResults = listFilteredInstructorsResponse?.results;
                const listedInstructors = listFilteredInstructorsResponse?.resultObjects;
                if (
                    listedInstructors == null ||
                    listFilteredInstructorResults == null ||
                    listFilteredInstructorResults.hasErrors()
                ) {
                    throw new Error();
                }

                setSearchInstructors(listedInstructors);
            } catch {
                ToastManager.error(t("thereWasAnIssueLoadingInstructors"));
                setSearchInstructors([]);
            }
        },
        [listInstructors]
    );

    const handleRemoveInstructor = useCallback(
        (instructor: InstructorRecord) => {
            onInstructorsChanged(instructors.filter((c) => c.id !== instructor.id));
        },
        [onInstructorsChanged, instructors]
    );

    const handleInstructorSelectionChange = (selectedInstructors: InstructorRecord[]) => {
        onInstructorsChanged(selectedInstructors);
    };

    const handleAddInstructors = () => {
        fetchInstructors();
        setIsModalOpen(true);
    };

    return (
        <>
            <ReportSection
                header={
                    <>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("selectInstructors")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <Button
                            onClick={handleAddInstructors}
                            size={ButtonSize.Small}
                            style={ButtonStyle.Primary}
                            text={t("addInstructors")}
                        />
                    </>
                }>
                {CollectionUtils.hasValues(instructors) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="instructor-name">{t("instructorName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {instructors.map((instructor) => (
                                <tr key={instructor.id}>
                                    <td className="instructor-name">
                                        {instructor.lastName}, {instructor.firstName}
                                    </td>
                                    <td className="id">{instructor.id}</td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                onClick={() => handleRemoveInstructor(instructor)}
                                                displayName={t("remove")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <></>
                )}
            </ReportSection>

            <SearchSelectionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSearch={fetchInstructors}
                onSelectionChanged={handleInstructorSelectionChange}
                searchValues={searchInstructors}
                selectedValues={instructors}
                title={t("addAnInstructor")}
                itemName={t("instructorName")}
                searchPlaceholderText={t("typeToSearchByInstructorName")}
                valuesDescription={t("instructors")}
                valueAdapter={(value) => ({
                    id: `${value.id}`,
                    name: `${value.lastName}, ${value.firstName}`,
                })}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportInstructorSelection;

// #endregion Exports
