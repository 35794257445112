import { ResultErrorRecord } from "andculturecode-javascript-core";
import { useCallback, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import {
    CountEnrollmentsQueryParams,
    EnrollmentService,
} from "utilities/services/enrollments/enrollment-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface useEnrollmentCountHook {
    count: number;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface useEnrollmentCountHookOptions {
    contractId?: number;
    eventId?: number;
    isWithdrawn?: boolean;
    productId?: number;
    userId?: number;
}

export function useEnrollmentCount(props: useEnrollmentCountHookOptions): useEnrollmentCountHook {
    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState<number>(0);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { get: enrollmentCountAPI } = EnrollmentService.useCount();
    const contractId = props.contractId;
    const eventId = props.eventId;
    const isWithdrawn = props.isWithdrawn;
    const productId = props.userId;
    const userId = props.userId;

    const getEnrollmentCount = useCallback(async (): Promise<void> => {
        try {
            const countEnrollmentsQueryParams: CountEnrollmentsQueryParams = {
                contractId: contractId,
                eventId: eventId,
                isWithdrawn: isWithdrawn,
                productId: productId,
                userId: userId,
            };

            const enrollmentCountResponse = await enrollmentCountAPI(
                {},
                countEnrollmentsQueryParams
            );
            const enrollmentCountResult = enrollmentCountResponse?.result;
            setIsLoading(false);
            if (enrollmentCountResult?.hasErrors()) {
                setErrors([...(enrollmentCountResult?.errors ?? [])]);
            }

            setCount(enrollmentCountResult?.resultObject ?? 0);
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollmentCount"));
            setCount(0);
        }
    }, [contractId, enrollmentCountAPI, eventId, isWithdrawn, productId, userId]);

    useEffect(() => {
        getEnrollmentCount();
    }, [getEnrollmentCount]);

    return {
        count,
        errors,
        isLoading,
    };
}
