import React from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "../buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { Donut } from "components/donut/donut";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import {
    InstructorAssessmentStatus,
    InstructorAssessmentStatusDisplayNames,
    InstructorAssessmentStatusStatusMap,
} from "models/enumerations/enrollments/instructor-assessment-status";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { Topic } from "models/enumerations/courses/topic";
import { TopicBubble } from "components/bubble/topic-bubble";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./action-link-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ActionLinkCardProps {
    completionPercentage?: number;
    description: string;
    icon: Icons;
    status?: InstructorAssessmentStatus;
    topics?: Topic[];
    trainingButtonLocked?: boolean;
    trainingButtonPath: string;
    trainingButtonText: TranslatedCopy;
    trainingType?: TrainingType;
    type: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "action-link-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ActionLinkCard: React.FC<ActionLinkCardProps> = (props: ActionLinkCardProps) => {
    const status = () =>
        props.trainingType === TrainingType.InstructorAssessment ? (
            <EnumStatusBadge
                displayNames={InstructorAssessmentStatusDisplayNames}
                statusMap={InstructorAssessmentStatusStatusMap}
                value={props.status ?? InstructorAssessmentStatus.NotStarted}
            />
        ) : (
            props.completionPercentage != null && (
                <Donut includeLabel={true} percent={props.completionPercentage} />
            )
        );

    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Badge style={BadgeStyle.Inverted}>
                    <Icon type={props.icon} size={IconSizes.Medium} />
                    <span>{props.type}</span>
                </Badge>
                {status()}
            </div>
            <Paragraph>{props.description}</Paragraph>
            {props.topics != null && (
                <div className={`${CSS_CLASS_NAME}__topics`}>
                    {props.topics.map((topic, topicIndex) => (
                        <TopicBubble topic={topic} key={topicIndex} />
                    ))}
                </div>
            )}

            {!props.trainingButtonLocked && (
                <Button
                    linkPath={props.trainingButtonPath}
                    text={t(props.trainingButtonText)}
                    type={ButtonType.Link}
                />
            )}

            {props.trainingButtonLocked && (
                <ButtonIcon
                    disabled={true}
                    iconType={Icons.Lock}
                    linkPath={props.trainingButtonPath}
                    text={t(props.trainingButtonText)}
                    buttonType={ButtonType.Link}
                />
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ActionLinkCard };

// #endregion Exports
