import { AssessmentAttemptsDetails } from "components/assessments/assessment-attempts-details/assessment-attempts-details";
import { LearnerAssessmentSummaryDetails } from "./learner-assessment-summary-details/learner-assessment-summary-details";
import { LearnerAssessmentSummaryHeader } from "./learner-assessment-summary-header/learner-assessment-summary-header";
import { StringUtils } from "andculturecode-javascript-core";
import { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { useAttempts } from "utilities/hooks/models/attempts/use-attempts";
import { useRegistration } from "utilities/hooks/models/registration/use-registration";
import { t } from "utilities/localization/t";
import "./learner-assessment-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentSummaryProps {
    assessmentId: number;
    cssClassName?: string;
    eventId: number;
    registrationId?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "learner-assessment-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentSummary: React.FC<LearnerAssessmentSummaryProps> = (
    props: LearnerAssessmentSummaryProps
): JSX.Element => {
    const registrationId = useMemo(() => props.registrationId ?? 0, [props.registrationId]);
    const { record: globalState } = useGlobalState();
    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    const { registration } = useRegistration({
        registrationId: registrationId,
        includeUser: true,
        includeTraining: true,
    });
    const { attempts } = useAttempts({
        registrationId: registrationId,
        includeRegistration: true,
        includeResponses: true,
    });

    return (
        <div className={className}>
            <LearnerAssessmentSummaryHeader
                assessmentId={props.assessmentId}
                title={t("learnerAssessment")}
                optional={registration?.enrollmentScormPackageAssessment?.optional}
                returnTitle={t("backToAssessmentResults")}
                returnPath={viewEventRoutes.assessmentResults}
                returnPathParams={{
                    id: props.eventId,
                    assessmentId: props.assessmentId,
                }}
            />
            <LearnerAssessmentSummaryDetails
                attempts={attempts}
                learnerName={registration?.user?.getFirstAndLastName()}
            />
            <AssessmentAttemptsDetails attempts={attempts} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentSummary };

// #endregion Exports
