import React from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Accordion, AccordionStyle } from "../../../components/accordion/accordion";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ButtonStyle, Button, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Paragraph } from "../../../components/typography/paragraph/paragraph";
import { RoleType } from "models/enumerations/users/role-type";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./support-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SupportPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "support-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SupportPage: React.FC<SupportPageProps> = validatePageAccess(AccessControlKeys.SupportPage)(
    (): JSX.Element => {
        const { record: globalState } = useGlobalState();
        const currentRole = globalState?.currentIdentity?.role?.roleType;

        return (
            <>
                <HeaderBanner title={t("support")} />
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <SkipNavContent>
                            {currentRole === RoleType.Learner && (
                                <>
                                    <h2>{t("learnerFAQs")}</h2>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfIHaveTroubleAccessingMyTrainingInTheLMS")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "clearYourInternetBrowsersCacheAndCookiesReloadTheLMSAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whatIfIHaveQuestionsAboutAnInstructorledEventEgStartTimeLocation"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "PleaseContactTheHostForTheEventTheHostContactInformationIsLocatedOnTheAboutPageOfTheEventUnderTheContactSection"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howDoIJoinALiveVirtualTrainingSession")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseSelectTheEventLinkOrCopyTheLinkToYourInternetBrowserTheEventLinkIsLocatedOnTheAboutPageOfTheEventUnderTheLocationSection"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfINeedToWithdrawFromAnInstructorledCourse")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "PleaseContactTheHostForTheEventTheHostContactInformationIsLocatedOnTheAboutPageOfTheEventUnderTheContactSection"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfINeedToWithdrawFromAnOnlineLearningCourse")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseContactNFPAYouCanSelectTheGoToSupportButtonOnTheAboutPageOfTheOnlineTrainingOnceOnTheSupportPagePleaseSelectTheButtonToSubmitYourRequestToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHoursYouCanAlsoCallCustomerServiceDirectlyAt"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfIHaveFeedbackAboutCourseContent")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheCourseAsWellAsHaveAnOpportunityToProvideOpenendedFeedback"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whatIfIHaveFeedbackAboutAnInstructorOrTheEnvironmentOfAnInstructorledEvent"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheInstructorAndEventEnvironmentAsWellAsHaveAnOpportunityToProvideOpenendedFeedback"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whyWasIOnlyGivenDigitalMaterialsForMyInstructorledClass"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "AllInstructorledClassesProvideAccessToDigitalMaterialsTheHostOfTheEventWillDetermineIfPhysicalMaterialsWillAlsoBeProvided"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howCanIViewMyCompletedTraining")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "CompletedTrainingCanBeViewedOnTheMyLearningDashboardForTrainingWhereAccessHasNotExpiredViewTheAssignmentsSectionOnEitherTheOnlineOrInstructorledTabsAndSelectViewTrainingForTrainingWhereAccessHasExpiredViewThePastTrainingsListAtTheBottomOfTheAssignmentsPageAndSelectViewSummary"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howCanIViewprintMyCertificatesbadges")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "LocateYourCompletedTrainingSeeFAQHowCanIViewMyCompletedTrainingUponViewingYourCompletedTrainingNavigateToTheAchievementsSectionFromTheLefthandPaneSelectTheButtonToDownloadCertificateAndorGoToCredly"
                                            )}
                                        </Paragraph>
                                        <br />
                                        {t("courseCompletionCriteria")}:
                                        <ul>
                                            <li>{t("courseCompletionCriteriaILT")}</li>
                                            <li>{t("courseCompletionCriteriaOLL")}</li>
                                        </ul>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howCanIUpdateMyProfileInformation")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "youCanUpdateYourInformationSuchAsTheSpellingOfYourNameOrYourEmailAddressByGoingToMyProfileWhichCanBeAccessedByUsingTheHamburgerIconWithinTheOnlineLearningPortalALinkWithinTheBasicInformationSectionWillDirectYouToYourNFPAAccountAt"
                                            )}
                                            &nbsp;
                                            <a
                                                href="https://www.nfpa.org"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                nfpa.org
                                            </a>
                                            ,{" "}
                                            {t(
                                                "whereYouCanProcessTheUpdatesThisWillAutomaticallyUpdateYourInformationInTheOnlineLearningPortalWithinHours"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howCanIChangeTheDefaultLanguage")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "CurrentlyYouMayChangeTheDefaultLanguageOfMyLearningFromEnglishToSpanishOrArabicOnlyYouCanDoThisInTheMyProfilePage"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                </>
                            )}
                            {currentRole === RoleType.ClientAdmin && (
                                <>
                                    <h2>{t("clientAdminFAQ")}</h2>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whoIsMyPrimaryContactAtNFPA")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t("ourPrimaryContactIsYourNFPASalesRepresentative")}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("howDoIGetAClassScheduled")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "PleaseWorkWithYourNFPASalesRepresentativeToProvideTheDetailsOfAnyTrainingYouWouldLikeScheduled"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("canIRequestAParticularInstructor")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "yesHoweverWeCannotGuaranteeTheInstructorsAvailability"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfIHaveTroubleAccessingTheLMS")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "clearYourInternetBrowsersCacheAndCookiesReloadTheLearningPortalAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAtClientAdmin"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("canIaddmoreparticipantstotheclassroster")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "YesFromTheCurrentTrainingsOnTheDashboardSelectEitherTheOnlineOrInstructorLedTabSelectViewTrainingFromTheAppropriateCourseNavigateToTheEnrollmentSectionFromTheLeftSidePaneSelectEitherTheAddNewButtonOrTheImportCSVButtonToAddParticipantsToTheRoster"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfINeedToChangeOrCancelAClass")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t("PleaseContactYourNFPASalesRepresentative")}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "canMyOrganizationOrTheApprovedInstructorChangeOrUpdateTheCourseMaterials"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "NoAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactYourSalesRepresentative"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("canIViewTheCourseEvaluations")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "YesSummaryResultsOfTheCourseEvaluationsForInstructorledTrainingAreProvidedRealTimeSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheEvaluationSectionCanBeAccessedUsingTheLefthandPane"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("canISeeHowParticipantsScoredOnTheAssessments")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "YesYouCanViewOverallAssessmentDataForAClassHoweverYouCannotSeeIndividualLearnerResultsSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheAssessmentSectionCanBeAccessedUsingTheLefthandPane"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                </>
                            )}
                            {currentRole === RoleType.AenAdministrator && (
                                <>
                                    <h2>{t("AENProviderFAQ")}</h2>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whatIfThereAreNoInstructorsAvailableOnTheDateOfMyClass"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseEnsureInstructorAvailabilityPriorToSettingUpATrainingTheLMSWillNotLetYouPublishAnEventUntilYouVerifyThatTheInstructorAvailabilityHasBeenConfirmed"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "canMyOrganizationOrTheApprovedInstructorChangeOrUpdateTheCourseMaterials"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "noAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfTheInstructorDoesntShowUpForClass")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseReferenceYourContractWithTheInstructorAndWorkDirectlyWithThemToRectifyTheIssue"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                </>
                            )}
                            {currentRole === RoleType.Instructor && (
                                <>
                                    <h2>{t("instructorsFAQs")}</h2>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whereCanIFindThePrimaryContactOfTheTrainingProvider"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheAboutSectionOfTheEventYouWillFindTheContactInformationForTheEventHost"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whatIfIHaveTroubleAccessingTheLMSOrTakingAttendance"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "clearYourInternetBrowsersCacheAndCookiesReloadTheLMSAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whatIfINeedToCancelrescheduleAnEventDate")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseContactTheProviderOfTheEventToCancelOrReschedule"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t(
                                            "whoDoIContactIfThereAreTechnologyIssuesDuringClass"
                                        )}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "pleaseContactTheProviderOfTheEventForTechnologyIssuesDuringClass"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("canIChangeOrUpdateTheCourseMaterials")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "noAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                    <Accordion
                                        cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                        title={t("whereCanIFindMyInstructorRatingForAClass")}
                                        style={AccordionStyle.Card}
                                    >
                                        <Paragraph>
                                            {t(
                                                "fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheEvaluationSectionOfTheEventYouWillFindASummaryOfTheEvaluationResultsParticipantsAreAskedToProvideFeedbackAboutTheInstructorOnTheFirstSixQuestionsTheSixthQuestionIsHowLikelyWouldYouBeToRecommendThisInstructorThisIsIndicativeOfYourOverallInstructorRating"
                                            )}
                                        </Paragraph>
                                    </Accordion>
                                </>
                            )}
                            <h2>{t("badgeFAQs")}</h2>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatIsAnOpenBadge")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "openBadgesAreWebenabledVersionsOfACredentialCertificationOrLearningOutcomeThatCanBeVerifiedInRealTimeOnline"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("howDoesMyCertificationGetDisplayedAsABadge")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "weHaveIntegratedWithCredlyToTranslateTheLearningOutcomesYouveDemonstratedIntoABadgeIssuedAndManagedThroughTheCompanyDigitalBadgingPlatformTheTechnologyCredlyUsesIsBasedOnTheOpenBadgeStandardsMaintainedByIMSGlobalThisEnablesYouToManageShareAndVerifyYourCompetenciesDigitally"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatAreTheBenefitsOfABadge")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "representingYourSkillsWithABadgeGivesYouAMeansToShareYourAbilitiesOnlineInAWayThatIsSimpleTrustedAndCanBeEasilyVerifiedInRealTimeBadgesProvideEmployersAndPeersConcreteEvidenceOfWhatYouHadToDoToEarnYourCredentialAndWhatYoureNowCapableOfCredlyAlsoOffersLaborMarketInsightsBasedOnYourSkillsYouCanSearchAndApplyForJobOpportunitiesRightThroughCredly"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatIsCredly")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "credlyIsTheEndtoendSolutionForIssuingAndManagingDigitalCredentialsCredlyWorksWithCredibleOrganizationsToProvideDigitalCredentialsToIndividualsWorldwide"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("howWillIKnowIfIveEarnedABadge")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "youWillReceiveAnEmailNotificationFromCredlyAdmincredlycomWithInstructionsForClaimingYourBadgeAndSettingUpYourAccount"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatIfIDontWantMyBadgeToBePublic")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "youCanConfigureYourPrivacySettingsInCredlyYoureInCompleteControlOfTheInformationAboutYourselfThatIsMadePublic"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("isThereAFeeToUseCredly")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>{t("noThisIsAServiceWeProvideToYouAtNoCost")}</Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatsToKeepSomeoneElseFromCopyingMyBadgeAndUsingIt")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "whileBadgesAreSimplyDigitalImageFilesTheyAreUniquelyLinkedToDataHostedOnCredlyThisLinkToVerifiedDataMakesThemMoreReliableAndSecureThanAPaperbasedCertificateItAlsoEliminatesThePossibilityOfAnyoneClaimingYourCredentialAndYourAssociatedIdentity"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whereAndHowCanIShareMyBadgeThroughCredly")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "youCanShareYourBadgeDirectlyFromCredlyToLinkedInTwitterAndFacebookOverEmailEmbeddedInAWebsiteOrInYourEmailSignature"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t(
                                    "whatAreLaborMarketInsightsAndHowCanIAccessThemThroughCredly"
                                )}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "laborMarketInsightsArePulledFromLiveJobRequisitionsBasedOnYourSkillsYouCanLearnWhichEmployersAreHiringWhatJobTitlesYouMightBeQualifiedForSalaryRangesAndMoreSearchActiveJobListingsAndEvenApplyForThemWithJustAFewClicksThroughCredlyAccessTheLaborMarketInsightsFromYourBadgeDetailsPageByClickingOnRelatedJobsOrByClickingOnTheSkillTagsAssignedToYourBadge"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t(
                                    "canIExportBadgesIssuedThroughCredlyToOtherBadgestoringPlatforms"
                                )}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t(
                                        "yesYouCanDownloadYourBadgeFromTheShareBadgePageYourDownloadedBadgeContainsOpenBadgeInfrastructureOBICompliantMetadataEmbeddedIntoTheImageThisAllowsYouToStoreYourBadgeOnOtherOBIcompliantBadgeSitesSuchAsTheBadgrBackpack"
                                    )}
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("canIImportBadgesIssuedFromOtherPlatformsIntoCredly")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>{t("notAtThisTime")}</Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("iHaveAQuestionAboutCredlyWhereCanIFindSupport")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t("youCanFindTutorialsAndAnswersToAdditionalQuestionsHere")}
                                    &nbsp;
                                    <a
                                        href="https://support.credly.com/hc/en-us"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        support.credly.com
                                    </a>
                                </Paragraph>
                            </Accordion>
                            <Accordion
                                cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                title={t("whatWillHappenToMyNFPABadgeFromBADGR")}
                                style={AccordionStyle.Card}
                            >
                                <Paragraph>
                                    {t("youCanContinueToAccessAndUseThoseBadgesThrough")}&nbsp;
                                    <a
                                        href="https://badgr.com/auth/login"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        BADGR's
                                    </a>
                                    .
                                </Paragraph>
                            </Accordion>
                            {currentRole === RoleType.Learner && (
                                <Accordion
                                    cssClassName={`${CSS_CLASS_NAME}__content__accordion`}
                                    title={t("whatAreTheDigitalBadgeTermsOfUse")}
                                    style={AccordionStyle.Card}
                                >
                                    <Paragraph>
                                        <Anchor
                                            text="https://www.nfpa.org/for-professionals/training-for-me/digital-badges#terms-of-use"
                                            path="https://www.nfpa.org/for-professionals/training-for-me/digital-badges#terms-of-use"
                                            pathType={AnchorPathType.External}
                                        />
                                    </Paragraph>
                                </Accordion>
                            )}
                            {currentRole === RoleType.Learner && (
                                <>
                                    <h2>{"Videos"}</h2>
                                    <Card>
                                        <h2>{t("accessingYourTraining")}</h2>
                                        <div className={`${CSS_CLASS_NAME}__video`}>
                                            <iframe
                                                src="https://players.brightcove.net/1640544031001/default_default/index.html?videoId=6351504689112"
                                                title={t("accessingYourTraining")}
                                                className={`${CSS_CLASS_NAME}__video-iframe`}
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                    <Card>
                                        <h2>{t("dashboardNavigationAndProfile")}</h2>
                                        <div className={`${CSS_CLASS_NAME}__video`}>
                                            <iframe
                                                src="https://players.brightcove.net/1640544031001/default_default/index.html?videoId=6351498780112"
                                                title={t("dashboardNavigationAndProfile")}
                                                className={`${CSS_CLASS_NAME}__video-iframe`}
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                    <Card>
                                        <h2>{t("launchAnOnlineLearning")}</h2>
                                        <div className={`${CSS_CLASS_NAME}__video`}>
                                            <iframe
                                                src="https://players.brightcove.net/1640544031001/default_default/index.html?videoId=6351503804112"
                                                title={t("launchAnOnlineLearning")}
                                                className={`${CSS_CLASS_NAME}__video-iframe`}
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                    <Card>
                                        <h2>{t("launchAnInstructorLedTraining")}</h2>
                                        <div className={`${CSS_CLASS_NAME}__video`}>
                                            <iframe
                                                src="https://players.brightcove.net/1640544031001/default_default/index.html?videoId=6351503134112"
                                                title={t("launchAnInstructorLedTraining")}
                                                className={`${CSS_CLASS_NAME}__video-iframe`}
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                    <Card>
                                        <h2>{t("accessingYourCertificateAndBadge")}</h2>
                                        <div className={`${CSS_CLASS_NAME}__video`}>
                                            <iframe
                                                src="https://players.brightcove.net/1640544031001/default_default/index.html?videoId=6351502764112"
                                                title={t("accessingYourCertificateAndBadge")}
                                                className={`${CSS_CLASS_NAME}__video-iframe`}
                                                allowFullScreen
                                            ></iframe>
                                        </div>
                                    </Card>
                                </>
                            )}
                        </SkipNavContent>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__sidebar`}>
                        <div className={`${CSS_CLASS_NAME}__sidebar__section`}>
                            <h2>{t("needFurtherAssistance")}</h2>
                            <Paragraph>
                                {t("needFurtherAssistanceWithTheLMSOrAccessingYourTraining")}
                                <br />
                                <br />
                                {t(
                                    "pleaseSelectTheButtonToSubmitYourQuestionToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHours"
                                )}
                            </Paragraph>
                            <Button
                                type={ButtonType.Link}
                                linkPath="mailto:custserv@nfpa.org"
                                style={ButtonStyle.Primary}
                                text={t("submitAQuestion")}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SupportPage };

// #endregion Exports
