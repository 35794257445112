import React, { useMemo, useState } from "react";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { ButtonStyle } from "components/buttons/button/button";
import { Event } from "models/interfaces/events/event";
import { EventNameAndTypeForm } from "components/events/event-name-and-type-form/event-name-and-type-form";
import { EventRecord } from "models/view-models/events/event-record";
import { EventService } from "utilities/services/events/event-service";
import { EventType } from "models/enumerations/events/event-type";
import { InstructorLedTrainingType } from "models/enumerations/products/instructor-led-training-type";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./create-event-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CreateEventModalProps {
    onModalClose: () => void;
    open: boolean;
    product?: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "create-event-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CreateEventModal: React.FC<CreateEventModalProps> = (
    props: CreateEventModalProps
): JSX.Element => {
    const navigate = useNavigate();
    const { create } = EventService.useCreate();
    const { record: globalState } = useGlobalState();
    const isEnProvider = globalState?.currentIdentity?.isCurrentlyInEnProviderRole();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const loggedInUserProviderId = globalState.currentIdentity?.providerId();
    const initialEvent = useMemo(
        () =>
            new EventRecord().with({
                providerId: isEnProvider ? loggedInUserProviderId! : undefined,
                type: isEnProvider ? EventType.EducationNetwork : undefined,
                productId: props.product?.id ? props.product.id : undefined,
            }),
        [isEnProvider, loggedInUserProviderId, props.product]
    );
    const [event, setEvent] = useState<EventRecord>(initialEvent);
    const createEventButtonDisabled = useMemo(
        () =>
            !event.name ||
            event.instructorLedTrainingType == null ||
            (isEnProvider && event.providerId == null),
        [event.instructorLedTrainingType, event.name, event.providerId, isEnProvider]
    );

    const onModalClose = (): void => {
        props.onModalClose();
        resetData();
    };

    const resetData = (): void => {
        setEvent(new EventRecord());
    };

    const createEvent = async (): Promise<boolean> => {
        if (!event.name || event.instructorLedTrainingType === undefined) {
            alert(t("eventMustHaveANameAndType"));
            return false;
        }

        try {
            const createEventResponse = await create(event);
            const createEventResult = createEventResponse.result;

            if (createEventResult?.resultObject == null || createEventResult.hasErrors()) {
                throw new Error();
            }

            const newEvent = event.with({
                ...createEventResult.resultObject.toJS(),
            });

            setEvent(newEvent);

            props.onModalClose();

            const pathTemplate = currentUserIsInNfpaRole
                ? sitemap.admin.event.edit.details
                : sitemap.admin.event.edit.contact;
            const path = RouteUtils.replacePathParams(pathTemplate, { id: newEvent.id });

            navigate(path);
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingAnEvent"));
            return false;
        }
        return true;
    };

    const updateEventRecord = (values: Partial<Event>): void => {
        setEvent((previousEvent: EventRecord): EventRecord => previousEvent.with(values));
    };

    const handleEventNameChange = (name: string): void => {
        updateEventRecord({ name: name });
    };

    const handleEventTypeChange = (type: InstructorLedTrainingType): void => {
        updateEventRecord({ instructorLedTrainingType: type });
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: onModalClose,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("createEvent"),
            disabled: createEventButtonDisabled,
            onClick: createEvent,
            style: ButtonStyle.Primary,
        },
    ];

    const banner = useMemo(() => {
        return (
            <Banner style={BannerStyle.Default} format={BannerFormat.Box}>
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <Paragraph style={ParagraphStyle.Inverted}>
                        <strong>
                            {t("youAreCreatingAnEventForPropsProductName", {
                                propsProductName: props.product?.name,
                            })}
                        </strong>
                    </Paragraph>
                    <Paragraph style={ParagraphStyle.Inverted}>
                        {t("toCreateAnEventForAnotherProductGoToEventsAndSelectNewEvent")}
                    </Paragraph>
                </div>
            </Banner>
        );
    }, []);

    return (
        <Modal
            actions={modalActionArray}
            banner={props.product ? banner : undefined}
            isOpen={props.open}
            modalStyle=""
            onModalClose={onModalClose}
            title={t("createANewEvent")}>
            <EventNameAndTypeForm
                event={event}
                onNameChange={handleEventNameChange}
                onTypeChange={handleEventTypeChange}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CreateEventModal };

// #endregion Exports
