import React from "react";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import {
    EnrollmentStatus,
    EnrollmentStatusDisplayNames,
    EnrollmentStatusStatusMap,
} from "models/enumerations/enrollments/enrollment-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import "./summary-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SummaryHeaderProps {
    badges?: string[];
    enrollmentStatus?: EnrollmentStatus;
    icon: Icons;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "summary-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SummaryHeader: React.FC<SummaryHeaderProps> = (props) => {
    const badges = props.badges ?? [];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__title`}>
                <Icon type={props.icon} size={IconSizes.Large} />
                <h2>{props.title}</h2>
            </div>
            {badges.length > 0 && (
                <div className={`${CSS_CLASS_NAME}__badges`}>
                    {props.enrollmentStatus != null && (
                        <EnumStatusBadge
                            displayNames={EnrollmentStatusDisplayNames}
                            statusMap={EnrollmentStatusStatusMap}
                            value={props.enrollmentStatus}
                        />
                    )}
                    {badges.map((badge, index) => (
                        <Badge
                            key={`${props.title.toLowerCase()}-${index}`}
                            style={BadgeStyle.Light}>
                            {badge}
                        </Badge>
                    ))}
                </div>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SummaryHeader };

// #endregion Exports
