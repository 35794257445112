import React, { useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText, EmptyTextSpacing } from "../../../../../../components/empty-text/empty-text";
import { Pager } from "components/pager/pager";
import { t } from "utilities/localization/t";
import { useInstructorApprovedProducts } from "utilities/hooks/models/instructors/use-instructor-approved-products";
import { useUser } from "utilities/contexts/use-user-context";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductApprovalSelection } from "./add-product-approval-modal/product-approval-selection";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import {
    ProductApprovalStatus,
    ProductApprovalStatusDisplayNames,
    ProductApprovalStatusStatusMap,
} from "models/instructors/product-approval-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Modal, ModalAction } from "components/modal/modal";
import { InstructorApprovedProductRecord } from "models/view-models/instructors/instructor-approved-product-record";
import { ButtonStyle } from "components/buttons/button/button";
import "./user-instructor-teach.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserInstructorTeachProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-instructor-teach";
const ITEMS_PER_PAGE = 10;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserInstructorTeach: React.FC<UserInstructorTeachProps> = (
    props: UserInstructorTeachProps
): JSX.Element => {
    const { record: user } = useUser();
    const [currentPage, setCurrentPage] = useState<number>(1);

    const {
        instructorApprovedProducts,
        rowCount: approvedProductsCount,
        addProductApprovals,
        revokeInstructorApprovedProduct: revoke,
        isLoading,
    } = useInstructorApprovedProducts({
        userId: user?.id ?? 0,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleAddApproval = async (products: ProductRecord[]) => {
        return await addProductApprovals(products);
    };

    const handleRevokeClick = (approval: InstructorApprovedProductRecord) => {
        setApprovalToRevoke(approval);
        setShowRevokeApprovalConfirmationModal(true);
    };

    const [approvalToRevoke, setApprovalToRevoke] = useState<InstructorApprovedProductRecord>(
        new InstructorApprovedProductRecord()
    );

    const [showRevokeApprovalConfirmationModal, setShowRevokeApprovalConfirmationModal] =
        useState(false);

    const revokeApprovalConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowRevokeApprovalConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("revokeApproval"),
            onClick: () => {
                revoke(approvalToRevoke);
                setShowRevokeApprovalConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__required__header`}>
                <h3>{t("approvedToTeach")}</h3>
                <ProductApprovalSelection onProductApprovalSelection={handleAddApproval} />
            </div>
            {CollectionUtils.hasValues(instructorApprovedProducts) && (
                <>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="product">{t("product")}</th>
                                <th className="status">{t("status")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {instructorApprovedProducts.map((approvedProduct) => (
                                <tr key={approvedProduct.id}>
                                    <td className="product">{approvedProduct.product?.name}</td>
                                    <td className="status">
                                        {approvedProduct.status != null && (
                                            <EnumStatusBadge
                                                displayNames={ProductApprovalStatusDisplayNames}
                                                statusMap={ProductApprovalStatusStatusMap}
                                                value={approvedProduct.status!}
                                            />
                                        )}
                                    </td>
                                    <td className="-ellipsis">
                                        <ContextMenu>
                                            <ContextMenuButton
                                                disabled={
                                                    approvedProduct.status !==
                                                    ProductApprovalStatus.ApprovedToTeach
                                                }
                                                onClick={() => handleRevokeClick(approvedProduct)}
                                                displayName={t("revokeApproval")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                    <div className={`${CSS_CLASS_NAME}__pager`}>
                        <Pager
                            footer={true}
                            currentPage={currentPage}
                            totalPages={approvedProductsCount / ITEMS_PER_PAGE}
                            onPageClick={onPageClick}
                            itemsPerPage={ITEMS_PER_PAGE}
                            totalItems={approvedProductsCount}
                        />
                    </div>
                </>
            )}

            {!CollectionUtils.hasValues(instructorApprovedProducts) && !isLoading && (
                <EmptyText table spacing={EmptyTextSpacing.Small}>
                    {t("thisInstructorCurrentlyHasNoApprovedToTeachTrainings")}
                </EmptyText>
            )}
            <Modal
                actions={revokeApprovalConfirmationActionArray}
                isOpen={showRevokeApprovalConfirmationModal}
                onModalClose={() => {}}
                title={t("revokeApproval")}>
                {t("areYouSureYouWantToRevokeApproval", {
                    instructorName: user?.getFirstAndLastName(),
                    productName: approvalToRevoke.product?.name,
                })}
            </Modal>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserInstructorTeach };

// #endregion Exports
