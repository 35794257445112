import { Map } from "utilities/types/map";
import { Status } from "utilities/enumerations/statuses";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum ActiveStatus {
    Inactive = 0,
    Active = 1,
    Draft = 2,
    Archived = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const ActiveStatusStatusDisplayNames: Map<ActiveStatus, TranslatedCopy> = {
    [ActiveStatus.Inactive]: "inactive",
    [ActiveStatus.Active]: "active",
    [ActiveStatus.Draft]: "draft",
    [ActiveStatus.Archived]: "archived",
};

const ActiveStatusMap: Map<ActiveStatus, Status> = {
    [ActiveStatus.Inactive]: Status.Warning,
    [ActiveStatus.Active]: Status.Success,
    [ActiveStatus.Draft]: Status.Default,
    [ActiveStatus.Archived]: Status.Inverted,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { ActiveStatus, ActiveStatusStatusDisplayNames, ActiveStatusMap };

// #endregion Exports
