import { StringUtils } from "andculturecode-javascript-core/dist/utilities/string-utils";
import { Card } from "components/card/card";
import { LearnerChecklistRecord } from "models/view-models/learnerchecklists/learner-checklist-record";
import { useCallback, useEffect, useState } from "react";
import {
    GetLearnerChecklistPathParams,
    LearnerChecklistService,
} from "utilities/services/learnerchecklists/learner-checklist-service";
import { LearnerChecklistItem } from "./learner-checklist-item/learner-checklist-item";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./learner-checklist.scss";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerChecklistProps {
    cssClassName?: string;
    learnerChecklist?: LearnerChecklistRecord;
    enrollmentId: number;
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-details-checklist";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerChecklist: React.FC<LearnerChecklistProps> = (
    props: LearnerChecklistProps
): JSX.Element => {
    const [learnerChecklist, setLearnerChecklist] = useState<LearnerChecklistRecord>(
        new LearnerChecklistRecord()
    );
    const { get: getLearnerChecklist } = LearnerChecklistService.useGet();

    const fetchData = useCallback(async () => {
        const pathParams: GetLearnerChecklistPathParams = {
            id: props.enrollmentId,
        };

        try {
            const getResponse = await getLearnerChecklist(pathParams);

            const getResult = getResponse?.result;
            const learnerChecklist = getResponse?.resultObject;

            if (learnerChecklist == null || getResult == null || getResult.hasErrors()) {
                throw new Error();
            }

            setLearnerChecklist(learnerChecklist);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheLearnerChecklist"));
        }
    }, [props.enrollmentId, getLearnerChecklist]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <Card cssClassName={className} stacked={props.stacked}>
            <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H3}>
                {t("checklist")}
            </Heading>
            <div className={`${className}__items`}>
                {Object.entries(learnerChecklist.checklistItems).map(
                    ([displayName, checked]: [string, boolean]): JSX.Element => (
                        <LearnerChecklistItem
                            isChecked={checked}
                            key={displayName}
                            label={displayName}
                        />
                    )
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerChecklist };

// #endregion Exports
