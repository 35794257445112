import { ResultErrorRecord } from "andculturecode-javascript-core";
import { useEffect, useState } from "react";
import { NumberUtils } from "utilities/number-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductContentService } from "utilities/services/products/product-content-service";
import { t } from "utilities/localization/t";

interface UseProductContentsHook {
    contents: ProductContentRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

interface UseProductContentsHookOptions {
    productId?: number | string;
    includeContent?: boolean;
}

export function useProductContents(props: UseProductContentsHookOptions): UseProductContentsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [contents, setContents] = useState<ProductContentRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listContents } = ProductContentService.useList();
    const productId = NumberUtils.parseInt(props.productId) ?? 0;
    const includeContent = props.includeContent ?? false;

    useEffect(() => {
        if (isNaN(productId) || productId < 1) {
            return;
        }

        try {
            (async function getProductContents() {
                const { result, resultObjects } = await listContents({
                    productId,
                    includeContent,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setContents(resultObjects.sort((a, b) => a.sortOrder! - b.sortOrder!));
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningProductTrainingContent"));
        }
    }, [includeContent, listContents, productId]);

    return {
        errors,
        isLoading,
        contents,
    };
}
