import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventType } from "models/enumerations/events/event-type";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import { t } from "utilities/localization/t";
import "./event-activation-schedule-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationScheduleSectionProps {
    changed?: boolean;
    editMode?: boolean;
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-schedule-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationScheduleSection: React.FC<EventActivationScheduleSectionProps> = ({
    changed,
    editMode,
    event,
}): JSX.Element => {
    const calculateTotalHours = () => {
        return event.eventDays?.reduce((acc, day) => acc + day.getCreditHours(), 0);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                {event.type === EventType.Contract && !editMode && (
                    <>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("schedule")}
                        </Heading>
                        <ToggleLabel
                            checked={event.hasAllDaySessions}
                            disabled={true}
                            id="all-day"
                            label={t("allDay")}
                        />
                    </>
                )}
                {event.type !== EventType.Contract && !editMode && (
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("schedule")}
                    </Heading>
                )}
            </div>
            {editMode && (
                <>
                    <h3>{t("schedule")}</h3>
                    <ChangesBanner changed={changed!} />
                </>
            )}
            {!event.hasAllDaySessions && (
                <DataTable>
                    <thead>
                        <tr>
                            <th className="-date">{t("date")}</th>
                            <th className="-day-name">{t("dayName")}</th>
                            <th className="-session">{t("session")}</th>
                            <th className="-time">{t("time")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {event.eventDays?.map((eventDay, idx) => (
                            <React.Fragment key={eventDay.id}>
                                <tr className={`${CSS_CLASS_NAME}__first-row`}>
                                    <td className="-date">
                                        {DateUtils.formatDateCustom(
                                            eventDay.eventDate().toString(),
                                            t("shortDateFormat")
                                        )}
                                    </td>
                                    <td className="-day-name">{eventDay.name}</td>
                                    <td className="-session">1</td>
                                    <td className="-time">
                                        {eventDay.eventSessions &&
                                            eventDay.eventSessions[0] != null &&
                                            eventDay.eventSessions[0].formatStartAndEndTime()}
                                    </td>
                                </tr>
                                {eventDay.eventSessions && eventDay.eventSessions?.length > 1
                                    ? eventDay.eventSessions
                                          ?.slice(1)
                                          .map((session, sessionIdx) => (
                                              <tr
                                                  key={session.id}
                                                  className={`${CSS_CLASS_NAME}__additional-rows`}>
                                                  <td></td>
                                                  <td></td>
                                                  <td className="-session">{sessionIdx + 2}</td>
                                                  <td className="-time">
                                                      {session.formatStartAndEndTime()}
                                                  </td>
                                                  <td className="-ceus">
                                                      {(session.getSessionDuration() / 10).toFixed(
                                                          1
                                                      )}
                                                  </td>
                                              </tr>
                                          ))
                                    : null}
                            </React.Fragment>
                        ))}
                        {event.eventScheduleException?.granted && !event.hasAllDaySessions && (
                            <tr className={`${CSS_CLASS_NAME}__total-row`}>
                                <td
                                    colSpan={3}
                                    className={`${CSS_CLASS_NAME}__total-row__exception-granted`}>
                                    <Icon type={Icons.CheckmarkClipboard} />
                                    <span>{t("exceptionGranted")}</span>
                                </td>
                                <td>
                                    {calculateTotalHours()} {t("hours")}
                                </td>
                            </tr>
                        )}
                        {!event.eventScheduleException?.granted && !event.hasAllDaySessions && (
                            <tr className={`${CSS_CLASS_NAME}__total-row`}>
                                <td colSpan={3}></td>
                                <td>
                                    {calculateTotalHours()} {t("hours")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </DataTable>
            )}
            {event.type === EventType.Contract && event.hasAllDaySessions && (
                <DataTable>
                    <thead>
                        <tr>
                            <th className="-date">{t("date")}</th>
                            <th className="-day-name">{t("dayName")}</th>
                            <th className="-session">{t("session")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {event.eventDays?.map((eventDay, idx) => (
                            <React.Fragment key={eventDay.id}>
                                <tr className={`${CSS_CLASS_NAME}__first-row`}>
                                    <td className="-date">
                                        {DateUtils.formatDateCustom(
                                            eventDay.eventDate().toString(),
                                            t("shortDateFormat")
                                        )}
                                    </td>
                                    <td className="-day-name">{eventDay.name}</td>
                                    <td className="-session">1</td>
                                </tr>
                                {eventDay.eventSessions!.length > 1
                                    ? eventDay.eventSessions!.slice(1).map((session) => (
                                          <tr
                                              key={session.id}
                                              className={`${CSS_CLASS_NAME}__additional-rows`}>
                                              <td></td>
                                              <td></td>
                                              <td className="-session">{idx + 2}</td>
                                          </tr>
                                      ))
                                    : null}
                            </React.Fragment>
                        ))}
                        {event.eventScheduleException?.granted && event.hasAllDaySessions && (
                            <tr className={`${CSS_CLASS_NAME}__total-row`}>
                                <td
                                    colSpan={4}
                                    className={`${CSS_CLASS_NAME}__total-row__exception-granted`}>
                                    <Icon type={Icons.CheckmarkClipboard} />
                                    <span>{t("exceptionGranted")}</span>
                                </td>
                            </tr>
                        )}
                        {!event.eventScheduleException?.granted && event.hasAllDaySessions && (
                            <tr className={`${CSS_CLASS_NAME}__total-row`}>
                                <td colSpan={4}></td>
                            </tr>
                        )}
                    </tbody>
                </DataTable>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationScheduleSection };

// #endregion Exports
