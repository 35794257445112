import { RecordUtils, StringUtils } from "andculturecode-javascript-core";
import { Record, RecordOf } from "immutable";
import { AuditableWithNavigationProperties } from "models/interfaces/auditable/auditable-with-navigation-properties";
import { UserRecord } from "models/view-models/users/user-record";
import { DateUtils } from "utilities/date-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface IAuditableRecord<TAuditable extends AuditableWithNavigationProperties> {
    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on YYYY-MM-DDTHH:MM:SS:FFFFFFF+Offset
     * 2022-10-20T17:42:30.7005991+00:00

    */
    getLastUpdated(): string;

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    getLastUpdatedText(): string;

    /**
     * Convenience method to the return the last updated time in milliseconds.
     * Ex: 1666287750700 for 2022-10-20T17:42:30.7005991+00:00
     */
    getLastUpdatedInMilliseconds(): number;

    /**
     * Given a set of values for Auditable properties, create a new AuditableRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<TAuditable>} values The values to overwrite on this instance.
     * @returns An AuditableRecord with values from this instance and the values parameter.
     */
    with: (values: Partial<TAuditable>) => this;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Types
// -----------------------------------------------------------------------------------------

type AuditableRecordConstructor<TAuditable extends AuditableWithNavigationProperties> = new (
    value?: Partial<TAuditable>
) => RecordOf<TAuditable> & IAuditableRecord<TAuditable>;

// #endregion Types

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultAuditableValues: AuditableWithNavigationProperties = {
    id: undefined,
    createdBy: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedBy: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    updatedBy: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Constants

/**
 * An AuditableRecord class factory with the provided default values.
 * @param defaultValues The default values for the Auditable properties.
 * @param name The name of the class.
 * @returns A new AuditableRecord class with the provided default values.
 */
export function AuditableRecord<TAuditable extends AuditableWithNavigationProperties>(
    defaultValues: TAuditable,
    name?: string
): AuditableRecordConstructor<TAuditable> {
    const AuditableRecordFactory = class
        extends Record<AuditableWithNavigationProperties>(
            { ...defaultAuditableValues, ...defaultValues },
            name
        )
        implements IAuditableRecord<TAuditable>
    {
        constructor(params: Partial<TAuditable> = {}) {
            if (params.createdBy != null) {
                params.createdBy = RecordUtils.ensureRecord(params.createdBy, UserRecord);
            }

            if (params.updatedBy != null) {
                params.updatedBy = RecordUtils.ensureRecord(params.updatedBy, UserRecord);
            }

            if (params.deletedBy != null) {
                params.deletedBy = RecordUtils.ensureRecord(params.deletedBy, UserRecord);
            }

            super(params);
        }

        public getLastUpdated(): string {
            return StringUtils.hasValue(this.updatedOn) ? this.updatedOn : this.createdOn ?? "";
        }

        public getLastUpdatedText(): string {
            return DateUtils.formatLastEditedDate(this, true);
        }

        public getLastUpdatedInMilliseconds(): number {
            const lastUpdated = this.getLastUpdated();
            return DateUtils.convertToMilliseconds(lastUpdated);
        }

        public with(values: Partial<TAuditable>): this {
            return this.mergeDeep(values);
        }
    };

    return AuditableRecordFactory as unknown as AuditableRecordConstructor<TAuditable>;
}
