import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { DateUtils } from "utilities/date-utils";
import { EventDay } from "models/interfaces/events/event-day";
import { EventRecord } from "./event-record";
import { EventSessionRecord } from "./event-session-record";
import { Record } from "immutable";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventDay = {
    attendeeCount: 0,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollmentCount: 0,
    event: undefined,
    eventDayOfTheMonth: 0,
    eventMonth: 0,
    eventYear: 0,
    eventId: 0,
    eventSessions: [],
    id: undefined,
    name: undefined,
    status: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventDayRecord extends Record(defaultValues) implements EventDay {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventDay>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.event != null) {
            params.event = RecordUtils.ensureRecord(params.event, EventRecord);
        }

        if (CollectionUtils.hasValues(params.eventSessions)) {
            params.eventSessions = RecordUtils.ensureRecords(
                params.eventSessions,
                EventSessionRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public eventDate(): Date {
        return new Date(this.eventYear, this.eventMonth, this.eventDayOfTheMonth);
    }

    public eventDateWithTimeSetToEndOfDay(): Date {
        return new Date(this.eventYear, this.eventMonth, this.eventDayOfTheMonth, 23, 59, 59);
    }

    /**
     * Returns whether or not the Event Day has started.
     *
     * @returns True if teh current datetime is after midnight of the event day.
     */
    public eventDayHasStarted(): boolean {
        const eventDate: Date | null = this.getEventDate();

        if (eventDate == null) {
            return false;
        }

        const startOfEventDate = new Date(
            eventDate.getFullYear(),
            eventDate.getMonth(),
            eventDate.getDate(),
            0,
            0,
            0,
            0
        );

        return startOfEventDate.getTime() < Date.now();
    }

    /**
     * Convenience method to the return the Event Date as human-readable text.
     */
    public formatEventDateText(): string {
        return this.getEventDate().toLocaleDateString("en-US");
    }

    /**
     * Convenience method to the return the Attendance Summary as human-readable text.
     */
    public getAttendanceSummary(): string {
        const attendeeCount: number = this.attendeeCount ?? 0;
        const enrollmentCount: number = this.enrollmentCount ?? 0;

        return `${attendeeCount} / ${enrollmentCount}`;
    }

    /**
     * Convenience method to the return a Date
     * Without this, we were getting an error when trying to access date fields as a Date type.
     */
    public getEventDate(): Date {
        return new Date(this.eventDate()!);
    }

    /**
     * Convenience method to the return the Event Date as human-readable text.
     */
    public getEventDateText(): string {
        return this.getEventDate().toLocaleDateString(undefined, { dateStyle: "full" });
    }

    /**
     * Convenience method to the return the text for the last updated date text
     * Ex: on MM/DD/YY at 8:00 AM
     */
    public getLastUpdatedText(): string {
        return DateUtils.formatLastEditedDate(this, true);
    }

    /**
     * Convenience method to the return the creditHours for an EventDay.
     */
    public getCreditHours(): number {
        if (this.eventSessions?.length === 0) {
            return 0;
        }

        const totalCreditHours = this.eventSessions!.reduce(function (creditHours, eventSession) {
            return creditHours + eventSession?.getSessionDuration();
        }, 0);

        return totalCreditHours;
    }

    /**
     * Convenience method to the return the creditHours description for an EventDay.
     */
    public getCreditHoursDescription(): string {
        const creditHours = this.getCreditHours();

        return StringUtils.pluralize(creditHours, t("hour"), t("hours"));
    }

    /**
     * Convenience method to the return the CEUs for an EventSchedule.
     */
    public getCEUs(): number {
        const creditHours = this.getCreditHours();
        return Number((creditHours / 10).toFixed(1));
    }

    /**
     * Convenience method to get a value that will be unique to the EventDayRecord within an Event.
     * Note: This is based on the requirement that an Event has at most one EventDay for a given
     * date.
     *
     * @returns {number} A value that uniquely identifies this EventDay Record for an Event.
     */
    public getUniqueIdentifier(): number {
        return this.id ?? this.eventDate().getTime();
    }

    public sortEventSessionsStartTime(): void {
        if (!CollectionUtils.hasValues(this.eventSessions)) {
            return;
        }

        this.eventSessions!.sort((a: EventSessionRecord, b: EventSessionRecord) => {
            return new Date(a.startTime()).getTime() - new Date(b.startTime()).getTime();
        });

        this.eventSessions!.forEach((eventSession: EventSessionRecord) => {
            if (eventSession.startTime === undefined || this.eventSessions!.length === 0) {
                return;
            }
        });
    }

    /**
     * Given a set of values for EventDay properties, create a new EventDayRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EventDay>} values The values to overwrite on this instance.
     * @returns A EventDayRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventDay>): EventDayRecord {
        return new EventDayRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayRecord };

// #endregion Exports
