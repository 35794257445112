import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventManageAddProducts } from "./event-manage-add-product";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-product-selection-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductSelectionModalProps {
    event?: EventActiveRecord;
    handleProductChange: (productId?: number) => void;
    open: boolean;
    selectedProduct?: ProductRecord;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-product-selection-modal";

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductSelectionModal: React.FC<EventProductSelectionModalProps> = (
    props: EventProductSelectionModalProps
): JSX.Element => {
    const [dirty, setDirty] = useState(false);
    const [products, setProductsAvailable] = useState<ProductRecord[]>([]);
    const [selectedProduct, setSelectedProduct] = useState(props.selectedProduct);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: props.selectedProduct ? t("replaceProduct") : t("done"),
            onClick: () => onProductSave(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setSelectedProduct(props.selectedProduct);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onProductSave = () => {
        props.handleProductChange(selectedProduct?.id);
        resetForm();
        props.setOpen(false);
    };

    const handleAddProductToQueue = useCallback((product: ProductRecord): void => {
        setSelectedProduct(product);
        setDirty(true);
    }, []);

    const handleRemoveProductFromQueue = useCallback((): void => {
        setSelectedProduct(undefined);
    }, []);

    const handleFetchProducts = useCallback((products: ProductRecord[]): void => {
        setProductsAvailable(products);
    }, []);

    useEffect(() => {
        setSelectedProduct(props.selectedProduct);
    }, [props.selectedProduct]);

    const banner = useMemo(() => {
        if (props.selectedProduct == null) {
            return <></>;
        }
        return (
            <Banner style={BannerStyle.Error} format={BannerFormat.Box}>
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <Paragraph style={ParagraphStyle.Default}>
                        <strong>{t("pleaseReviewBeforeReplacingYourProduct")}</strong>
                    </Paragraph>
                    <Paragraph>
                        {t(
                            "byReplacingTheAssociatedProductAnyInformationEnteredInTheScheduleAndInstructorSectionsWillBeReset"
                        )}
                    </Paragraph>
                </div>
            </Banner>
        );
    }, [props.selectedProduct]);

    return (
        <Modal
            actions={modalActionArray}
            isOpen={props.open}
            modalStyle={""}
            onModalClose={onModalClose}
            banner={banner}
            title={props.selectedProduct ? t("replaceProduct") : t("addAProduct")}>
            <EventManageAddProducts
                event={props.event!}
                handleAddProductToQueue={handleAddProductToQueue}
                handleFetchProducts={handleFetchProducts}
                handleRemoveProductFromQueue={handleRemoveProductFromQueue}
                products={products}
                selectedProduct={selectedProduct}
            />
            <Modal
                actions={confirmationActionArray}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductSelectionModal };

// #endregion Exports
