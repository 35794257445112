import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ReportDownloadRecord } from "models/view-models/reports/report-download-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { ReportDownloadService } from "utilities/services/reports/report-download-service";

interface UseReportDownloadsHook {
    reportDownloads: ReportDownloadRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
}

export function useReportDownloads(): UseReportDownloadsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [reportDownloads, setReportDownloads] = useState<ReportDownloadRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { list: listReportDownloads } = ReportDownloadService.useList();

    useEffect(() => {
        try {
            (async function fetchReports() {
                const { result, resultObjects } = await listReportDownloads();

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setReportDownloads(resultObjects);
            })();
        } catch {
            setErrors([
                new ResultErrorRecord({
                    message: t("thereWasAProblemGettingAvailableReportDownloads"),
                }),
            ]);
        }
    }, [listReportDownloads]);

    return {
        errors,
        isLoading,
        reportDownloads,
    };
}
