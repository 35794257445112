import { Record } from "immutable";
import { BulkEnrollmentSummary } from "models/interfaces/enrollments/bulk-enrollment-summary";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: BulkEnrollmentSummary = {
    existing: [],
    failed: [],
    successful: [],
    csvUploadResults: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class BulkEnrollmentSummaryRecord extends Record(defaultValues) implements BulkEnrollmentSummary {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<BulkEnrollmentSummary>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to get the total bulk enrollment records calculated from the resultObject successful, existing and failed properties length.
     *
     * @returns {number} total bulk enrollment records calculated from the resultObject successful, existing and failed properties length.
     */
    public calculateTotalBulkEnrollmentRecords(): number {
        return (
            (this.existing.length || 0) + (this.successful.length || 0) + (this.failed.length || 0)
        );
    }

    /**
     * Convenience method to a summary details object for the bulk contract enrollment summary.
     *
     * @returns {Array<{ name: string; value: any }>} An array of name/value pairs for the summary details.
     */
    public getSummaryDetails(): { name: string; value: any; description?: string }[] {
        return [
            {
                name: t("totalImported"),
                value: this.calculateTotalBulkEnrollmentRecords(),
            },
            {
                name: t("successfullyEnrolled"),
                value: this.successful.length,
                description: t(
                    "learnersInThisGroupWereSuccessfullyAddedToTheEnrollmentListForTheTraining"
                ),
            },
            {
                name: t("alreadyEnrolled"),
                value: this.existing.length,
                description: t(
                    "learnersInThisGroupMatchedRecordsForLearnersAlreadyOnTheEnrollmentListForTheTraining"
                ),
            },
            {
                name: t("failedToEnroll"),
                value: this.failed.length,
                description: t(
                    "learnersInThisGroupWereNotAddedToTheEnrollmentListForThisTrainingDueToAPotentialDataError"
                ),
            },
        ];
    }

    /**
     * Given a set of values for BulkEnrollmentSummary properties, create a new
     * BulkEnrollmentSummaryRecord object from this instance, overwriting the properties in the
     * values parameter with values provided.
     *
     * @param {Partial<BulkEnrollmentSummary>} values The values to overwrite on this instance.
     * @returns A BulkEnrollmentSummaryRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<BulkEnrollmentSummary>): BulkEnrollmentSummaryRecord {
        return new BulkEnrollmentSummaryRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BulkEnrollmentSummaryRecord };

// #endregion Exports
