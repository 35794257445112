import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Button } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { CalendarMonthView } from "components/calendar-month-view/calendar-month-view";
import { CollectionUtils } from "utilities/collection-utils";
import { CreateEventModal } from "components/events/create-event-modal/create-event-modal";
import { EmptyText } from "components/empty-text/empty-text";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { EventList } from "components/events/event-list/event-list";
import { EventType, EventTypeDisplayNames } from "models/enumerations/events/event-type";
import { FormCalendarDateRangePicker } from "components/form/form-calendar-date-range-picker/form-calendar-date-range-picker";
import { FormSelect } from "components/form/form-select/form-select";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Icons } from "components/icons/constants/icons";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import { NumberUtils } from "utilities/number-utils";
import { OrganizationService } from "utilities/services/organizations/organization-service";
import { Pager } from "components/pager/pager";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductService } from "utilities/services/products/product-service";
import { ProviderRecord } from "models/view-models/providers/provider-record";
import {
    ProviderResourcePathParams,
    ProviderService,
} from "utilities/services/providers/provider-service";
import {
    PublishStatus,
    PublishStatusDisplayNames,
} from "models/enumerations/publish-status/publish-status";
import {
    RadioButtonGroup,
    RadioButtonGroupOption,
} from "components/radio-button-group/radio-button-group";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SelectOption } from "components/form/inputs/select/select";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useCalendarEvents } from "utilities/hooks/models/events/use-calendar-events";
import { useEvents } from "utilities/hooks/models/events/use-events";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useViewEventRoutes } from "utilities/hooks/models/events/use-view-event-routes";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./admin-event-list-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum AdminDashboardTrainingViewTypes {
    Calendar = "1",
    List = "2",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEventListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-event-list-page";
const DEBOUNCE_TIME = 750;
const ITEMS_PER_PAGE = 20;
const TRAINING_VIEW_OPTIONS: SelectOption[] = [
    { value: AdminDashboardTrainingViewTypes.List, text: "listView" as TranslatedCopy },
    { value: AdminDashboardTrainingViewTypes.Calendar, text: "calendarView" as TranslatedCopy },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEventListPage: React.FC<AdminEventListPageProps> = validatePageAccess(
    AccessControlKeys.AdminEventListPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const [openCreateEventModal, setOpenCreateEventModal] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [calendarMonth, setCalendarMonth] = useState<moment.Moment>(moment().startOf("month"));
    const [currentTrainingsViewType, setCurrentTrainingsViewType] =
        useState<AdminDashboardTrainingViewTypes>(AdminDashboardTrainingViewTypes.List);
    const [calendarToggleIndex, setCalendarToggleIndex] = useState(0);
    const { record: globalState } = useGlobalState();
    const isAEN = globalState.currentIdentity?.isCurrentlyInRole(RoleType.AenAdministrator);
    // Set the -1 to ensure event retrieval fails if the provider ID isn't set.
    const loggedInUserProviderId = useMemo(
        () => globalState?.currentIdentity?.user?.providerId ?? -1,
        [globalState?.currentIdentity?.user?.providerId]
    );
    const { get: getProvider } = ProviderService.useGet();
    const [associatedProvider, setAssociatedProvider] = useState<ProviderRecord>();
    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;
    const canEditEvents =
        ((isAEN && associatedProvider?.isActive) ||
            (currentUserIsInNfpaRole &&
                !globalState.currentIdentity?.isCurrentlyInRole(RoleType.NfpaSupport))) ??
        false;
    const navigate = useNavigate();

    const [providers, setProviders] = useState<SelectOption[]>([]);
    const { list: listProviders } = ProviderService.useList();

    const [products, setProducts] = useState<SelectOption[]>([]);
    const { list: listProducts } = ProductService.useList();

    const [organizations, setOrganizations] = useState<SelectOption[]>([]);
    const { list: listOrganizations } = OrganizationService.useList();

    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [instructorLedTrainingType, setInstructorLedTrainingType] =
        useState<InstructorLedTrainingType>();
    const [eventSalesType, setEventSalesType] = useState<EventType>();

    const [productId, setProductId] = useState<number>();
    const [selectedProvider, setSelectedProvider] = useState<string>();
    const [selectedOrganization, setSelectedOrganization] = useState<string>();
    const [status, setStatus] = useState<PublishStatus>();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const fetchProviders = useCallback(async () => {
        try {
            const listProvidersResponse = await listProviders();

            if (
                listProvidersResponse?.resultObjects == null ||
                listProvidersResponse.results == null ||
                listProvidersResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            const providers = listProvidersResponse.resultObjects;
            const providerSelectOptions = providers.map((provider) => {
                return {
                    text: provider.name,
                    value: provider.id!.toString(),
                };
            }, []);

            providerSelectOptions.push({ text: t("unassigned"), value: "Unassigned" });

            providerSelectOptions.sort((a, b) =>
                a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1
            );

            setProviders(providerSelectOptions);
        } catch {
            ToastManager.error(t("problemLoadingProviders"));
            setProviders([]);
        }
    }, [listProviders]);

    const fetchOrgs = useCallback(async () => {
        try {
            const listOrganizationsResponse = await listOrganizations();

            if (
                listOrganizationsResponse?.resultObjects == null ||
                listOrganizationsResponse.results == null ||
                listOrganizationsResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            const organizations = listOrganizationsResponse.resultObjects;
            const organizationSelectOptions = organizations.map((org) => {
                return {
                    text: org.name,
                    value: org.id!.toString(),
                };
            }, []);

            organizationSelectOptions.push({ text: t("unassigned"), value: "Unassigned" });

            organizationSelectOptions.sort((a, b) =>
                a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1
            );

            setOrganizations(organizationSelectOptions);
        } catch {
            ToastManager.error(t("problemLoadingOrganizations"));
            setProviders([]);
        }
    }, [listOrganizations]);

    const fetchProducts = useCallback(async () => {
        try {
            const listProductsResponse = await listProducts({
                activeStatusFilter: [ActiveStatus.Active],
                trainingType: 0,
            });

            if (
                listProductsResponse?.resultObjects == null ||
                listProductsResponse.results == null ||
                listProductsResponse.results.hasErrors()
            ) {
                throw new Error();
            }

            const products = listProductsResponse.resultObjects;
            const providerSelectOptions = products
                .map((product) => {
                    return {
                        text: product.name,
                        value: product.id!.toString(),
                    };
                }, [])
                .sort((a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));

            setProducts(providerSelectOptions);
        } catch {
            ToastManager.error(t("problemLoadingProducts"));
            setProducts([]);
        }
    }, [listProducts]);

    useEffect(() => {
        if (!isAEN) {
            fetchProviders();
            fetchOrgs();
        }

        fetchProducts();
    }, [fetchOrgs, fetchProducts, fetchProviders, isAEN]);

    const fetchProvider = useCallback(async () => {
        try {
            const pathParams: ProviderResourcePathParams = {
                id: loggedInUserProviderId,
            };

            const providerResponse = await getProvider(pathParams);
            const providerResult = providerResponse?.result;

            if (providerResult?.resultObject == null || providerResult.hasErrors()) {
                throw new Error();
            }
            setAssociatedProvider(providerResult.resultObject);
        } catch {
            ToastManager.error(t("problemLoadingProvider"));
        }
    }, [getProvider, loggedInUserProviderId]);

    useEffect(() => {
        if (isAEN) {
            fetchProvider();
        }
    }, [fetchProvider, isAEN]);

    const addOneDay = (date: Date) => {
        return moment(date).add(1, "d");
    };

    const providerAssigned = useMemo(
        () => (selectedProvider === "Unassigned" ? false : undefined),
        [selectedProvider]
    );

    const providerId = useMemo(() => {
        const providerId = NumberUtils.parseInt(selectedProvider) ?? 0;
        if (isNaN(providerId) || providerId < 1) {
            return undefined;
        }
        return providerId;
    }, [selectedProvider]);

    const organizationAssigned = useMemo(
        () => (selectedOrganization === "Unassigned" ? false : undefined),
        [selectedOrganization]
    );

    const organizationId = useMemo(() => {
        const organizationId = NumberUtils.parseInt(selectedOrganization) ?? 0;
        if (isNaN(organizationId) || organizationId < 1) {
            return undefined;
        }
        return organizationId;
    }, [selectedOrganization]);

    const { events, rowCount: totalNumberOfEvents } = useEvents({
        applySearch: true,
        endDate: endDate ? addOneDay(endDate!).toISOString().split("T")[0] : "",
        eventSalesType: eventSalesType,
        instructorLedTrainingType: instructorLedTrainingType,
        organizationAssigned: organizationAssigned,
        organizationId: organizationId,
        preventLoadEvents: currentTrainingsViewType === AdminDashboardTrainingViewTypes.Calendar,
        productId: productId,
        providerAssigned: providerAssigned,
        providerId: isAEN ? loggedInUserProviderId : providerId,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        startDate: startDate?.toISOString().split("T")[0] ?? "",
        status: status,
        take: ITEMS_PER_PAGE,
    });

    const calendarToggleOptions: RadioButtonGroupOption[] = [
        {
            id: "all-events",
            index: 0,
            text: t("allEvents"),
        },
        {
            id: "contracts",
            index: 1,
            salesType: EventType.Contract,
            text: t("contracts"),
        },
        {
            id: "educationNetwork",
            index: 3,
            salesType: EventType.EducationNetwork,
            text: t("educationNetwork"),
        },
        {
            id: "nfpa",
            index: 2,
            salesType: EventType.NFPACatalog,
            text: t("nfpa"),
        },
    ];

    const { events: calendarEvents } = useCalendarEvents({
        calendarIsHidden: currentTrainingsViewType === AdminDashboardTrainingViewTypes.List,
        eventSalesType: calendarToggleOptions[calendarToggleIndex].salesType,
        month: calendarMonth.toDate().getMonth(),
        year: calendarMonth.toDate().getFullYear(),
    });

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.${CSS_CLASS_NAME}__window__main__events__content`);
    }, [events]);

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleFilterByOrganizationChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        setSelectedOrganization(event.target.value);
        setCurrentPage(1);
    };

    const handleFilterByProviderChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setSelectedProvider(event.target.value);
        setCurrentPage(1);
    };

    const handleFilterByProductChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const productId = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(productId) || productId < 1) {
            setProductId(undefined);
        }

        setProductId(productId);
        setCurrentPage(1);
    };

    const handleFilterByInstructorLedTrainingTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const instructorLedTrainingTypeIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(instructorLedTrainingTypeIndex) || instructorLedTrainingTypeIndex < 1) {
            setInstructorLedTrainingType(undefined);
        }

        setInstructorLedTrainingType(instructorLedTrainingTypeIndex);
        setCurrentPage(1);
    };

    const handleFilterByEventSalesTypeChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const eventSalesTypeIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(eventSalesTypeIndex) || eventSalesTypeIndex < 1) {
            setEventSalesType(undefined);
        }

        setEventSalesType(eventSalesTypeIndex);
        setCurrentPage(1);
    };

    const handleFilterByStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const publishactiveStatusIndex = NumberUtils.parseInt(event.target.value) ?? 0;
        if (isNaN(publishactiveStatusIndex) || publishactiveStatusIndex < 1) {
            setStatus(undefined);
        }

        setStatus(publishactiveStatusIndex);
        setCurrentPage(1);
    };

    const handleEventDateRangeChange = (date?: Array<Date | null>): void => {
        if (date) {
            setStartDate(date[0]);
            setEndDate(date[1]);
            setCurrentPage(1);
        }
    };

    const handleClearAll = () => {
        setSelectedOrganization(undefined);
        setSelectedProvider(undefined);
        setProductId(undefined);
        setInstructorLedTrainingType(undefined);
        setEventSalesType(undefined);
        setStatus(undefined);
        setStartDate(null);
        setEndDate(null);
        setSearchText("");
        setDebouncedSearchText("");
        setCurrentPage(1);
    };

    const handleCreateEventModalClose = (): void => {
        setOpenCreateEventModal(false);
    };

    const handleNextMonthClick = () => setCalendarMonth(calendarMonth.clone().add(1, "month"));
    const handlePreviousMonthClick = () =>
        setCalendarMonth(calendarMonth.clone().subtract(1, "month"));

    const handleSearchTextChange = (searchText: string): void => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const onCalendarToggle = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentTrainingsViewType(e.currentTarget.value as AdminDashboardTrainingViewTypes);
    };

    const { viewEventRoutes } = useViewEventRoutes({
        roleType: globalState?.currentIdentity?.role?.roleType,
    });

    const getEditEventPath = (eventId: number) => {
        const path = currentUserIsInNfpaRole
            ? sitemap.admin.event.edit.details
            : sitemap.admin.event.edit.contact;

        return RouteUtils.replacePathParams(path, { id: eventId });
    };

    const getViewEventPath = (eventId: number) => {
        return RouteUtils.replacePathParams(viewEventRoutes.details, {
            id: eventId,
        });
    };

    const handleEditEventClick = (eventId: number) => {
        navigate(getEditEventPath(eventId));
    };

    const handleViewEventClick = (eventId: number) => {
        navigate(getViewEventPath(eventId));
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <HeaderBanner
                    title={t("events")}
                    viewItems={TRAINING_VIEW_OPTIONS}
                    viewOnChange={onCalendarToggle}
                    currentViewType={currentTrainingsViewType}>
                    {canEditEvents && (
                        <ButtonIcon
                            text={t("addNewEvent")}
                            disabled={isAEN && loggedInUserProviderId === -1}
                            onClick={() => setOpenCreateEventModal(true)}
                            buttonSize={ButtonSize.Medium}
                            buttonStyle={ButtonStyle.TertiaryAlt}
                            iconType={Icons.Plus}
                        />
                    )}
                </HeaderBanner>

                <div className={`${CSS_CLASS_NAME}__window`}>
                    {currentTrainingsViewType === AdminDashboardTrainingViewTypes.List && (
                        <div className={`${CSS_CLASS_NAME}__list__view`}>
                            <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                                <div className={`${CSS_CLASS_NAME}__window__sidebar__filter`}>
                                    <Paragraph
                                        cssClassName={`${CSS_CLASS_NAME}__window__sidebar__filter__title`}
                                        size={ParagraphSize.XLarge}
                                        style={ParagraphStyle.Label}>
                                        {t("filterResults")}
                                    </Paragraph>

                                    <div
                                        className={`${CSS_CLASS_NAME}__window__sidebar__filter__selections`}>
                                        <SearchTextInput
                                            debounce={DEBOUNCE_TIME}
                                            onSearchTextInputChange={handleSearchTextChange}
                                            onSearchTriggered={handleSearchTriggered}
                                            id={"eventSearch"}
                                            placeholder={t("searchByEventNameOrId")}
                                            searchTextInputValue={searchText}
                                        />
                                        {!isAEN && (
                                            <>
                                                <FormSelect
                                                    ariaLabelledBy={t("filterBySalesType")}
                                                    formFieldName={t("filterBySalesType")}
                                                    id="eventFilterBySalesType"
                                                    label=""
                                                    placeholder={t("salesType")}
                                                    onChange={handleFilterByEventSalesTypeChange}
                                                    options={EnumUtils.numericEnumToSelectOptions(
                                                        EventType,
                                                        EventTypeDisplayNames
                                                    )}
                                                    value={eventSalesType?.toString()}
                                                />
                                                <FormSelect
                                                    ariaLabelledBy={t("filterByOrganization")}
                                                    formFieldName={t("filterByOrganization")}
                                                    id="eventFilterByOrganization"
                                                    label=""
                                                    placeholder={t("contractOrganization")}
                                                    onChange={handleFilterByOrganizationChange}
                                                    options={organizations}
                                                    value={selectedOrganization}
                                                />
                                                <FormSelect
                                                    ariaLabelledBy={t("filterByProvider")}
                                                    formFieldName={t("filterByProvider")}
                                                    id="eventFilterByProvider"
                                                    label=""
                                                    placeholder={t("aenProvider")}
                                                    onChange={handleFilterByProviderChange}
                                                    options={providers}
                                                    value={selectedProvider}
                                                />
                                            </>
                                        )}
                                        <FormSelect
                                            ariaLabelledBy={t("filterByStatus")}
                                            formFieldName={t("filterByStatus")}
                                            id="eventFilterByStatus"
                                            label=""
                                            placeholder={t("status")}
                                            onChange={handleFilterByStatusChange}
                                            options={EnumUtils.numericEnumToSelectOptions(
                                                PublishStatus,
                                                PublishStatusDisplayNames
                                            )}
                                            value={status?.toString()}
                                        />
                                        <FormSelect
                                            ariaLabelledBy={t("filterByEventType")}
                                            formFieldName={t("filterByEventType")}
                                            id="eventFilterByEvent Type"
                                            label=""
                                            placeholder={t("eventType")}
                                            onChange={handleFilterByInstructorLedTrainingTypeChange}
                                            options={EnumUtils.numericEnumToSelectOptions(
                                                InstructorLedTrainingType,
                                                InstructorLedTrainingTypeDisplayNames
                                            )}
                                            value={instructorLedTrainingType?.toString()}
                                        />
                                        <FormSelect
                                            ariaLabelledBy={t("filterByProduct")}
                                            formFieldName={t("filterByProduct")}
                                            id="eventFilterByProduct"
                                            label=""
                                            placeholder={t("product")}
                                            onChange={handleFilterByProductChange}
                                            options={products}
                                            value={productId?.toString()}
                                        />

                                        <FormCalendarDateRangePicker
                                            cssClassName={`${CSS_CLASS_NAME}__event-date-range`}
                                            endDate={endDate}
                                            formFieldName="filterByDateRange"
                                            handleEventDateRangeChange={handleEventDateRangeChange}
                                            label=""
                                            placeholder={t("dateRange")}
                                            startDate={startDate}
                                        />
                                    </div>
                                    <Button
                                        onClick={handleClearAll}
                                        size={ButtonSize.Medium}
                                        style={ButtonStyle.Secondary}
                                        text={t("clearAllFilters")}
                                    />
                                </div>
                            </div>
                            <div className={`${CSS_CLASS_NAME}__window__main`}>
                                <div className={`${CSS_CLASS_NAME}__window__main__events`}>
                                    <div
                                        className={`${CSS_CLASS_NAME}__window__main__events__content`}>
                                        <SkipNavContent>
                                            {CollectionUtils.hasValues(events) ? (
                                                <EventList events={events} />
                                            ) : (
                                                <EmptyText table>
                                                    {t("noEventResultsFound")}
                                                </EmptyText>
                                            )}
                                        </SkipNavContent>
                                    </div>
                                </div>
                                {CollectionUtils.hasValues(events) && (
                                    <div className={`${CSS_CLASS_NAME}__window__main__pager`}>
                                        <Pager
                                            currentPage={currentPage}
                                            totalPages={totalNumberOfEvents / ITEMS_PER_PAGE}
                                            onPageClick={onPageClick}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            totalItems={totalNumberOfEvents}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {currentTrainingsViewType === AdminDashboardTrainingViewTypes.Calendar && (
                        <div className={`${CSS_CLASS_NAME}__calendar__view`}>
                            <div>
                                {!isAEN && (
                                    <div className={`${CSS_CLASS_NAME}__calendar__toggles`}>
                                        {calendarToggleOptions.map((button, idx) => (
                                            <RadioButtonGroup
                                                key={`${button.id}-${idx}`}
                                                ariaLabelledBy={button.id}
                                                first={idx === 0}
                                                last={idx === calendarToggleOptions.length - 1}
                                                onClick={() => setCalendarToggleIndex(idx)}
                                                selected={calendarToggleIndex === idx}
                                                option={button}
                                            />
                                        ))}
                                    </div>
                                )}
                                <div className={`${CSS_CLASS_NAME}__calendar__wrapper`}>
                                    <CalendarMonthView
                                        events={calendarEvents}
                                        onEditEventClick={
                                            canEditEvents
                                                ? (eventId: number) => handleEditEventClick(eventId)
                                                : undefined
                                        }
                                        onNextMonthClick={handleNextMonthClick}
                                        onPreviousMonthClick={handlePreviousMonthClick}
                                        onViewEventClick={(eventId: number) =>
                                            handleViewEventClick(eventId)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <CreateEventModal
                onModalClose={handleCreateEventModalClose}
                open={openCreateEventModal}
            />
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEventListPage };

// #endregion Exports
