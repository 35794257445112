import catalog from "assets/images/Catalog.png";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { Button, ButtonType } from "components/buttons/button/button";
import { Card } from "components/card/card";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import "./event-physical-content-display.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PhysicalContentDisplayProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-physical-content-display";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PhysicalContentDisplay: React.FC<PhysicalContentDisplayProps> = (
    props: PhysicalContentDisplayProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();

    return (
        <div className={`${CSS_CLASS_NAME}__content-section`}>
            <Card cssClassName={`${CSS_CLASS_NAME}__material-card`}>
                <h3 className={`${CSS_CLASS_NAME}__title`}>{t("physicalMaterials")}</h3>
                <div>
                    <p className={`${CSS_CLASS_NAME}__message`}>
                        {t(
                            "ifYouWantToProvidePrintedNFPACodesStandardsToYourStudentsYouCanPurchaseThemAtADiscountedPriceFromNFPAPleaseNoteYouAreStillResponsibleForShippinghandlingAndApplicableTaxesOnOrdersPlacedFromNFPAAndYourProviderDiscountIsOnlyForPrintPublicationsUsedForTrainingClassesBooksAreNotForResaleForOrdersPlacedOutsideTheUSPleasePlanForAnAdditionalWeeksForDeliveryAndAnyAdditionalLocalAndImportTaxes"
                        )}
                    </p>
                    <p className={`${CSS_CLASS_NAME}__message`}>
                        {t(
                            "yourExclusiveDiscountCodeIsTiedToTheAdminEmailEnteredUponRegistrationForTheNFPAAuthorizedEducationNetworkIfYouWouldLikeToUseADifferentEmailPlease"
                        )}
                        <Anchor
                            cssClassName={`${CSS_CLASS_NAME}__message-link`}
                            path={"mailto:AEN@nfpa.org"}
                            text={t("contactTheAENAdministrator")}
                        />
                        {t("ToMakeTheChange")}
                    </p>
                </div>
                <Button
                    cssClassName={`${CSS_CLASS_NAME}__catalog-button`}
                    linkPath={globalState.systemSettings?.getCatalogUrl()}
                    linkPathType={AnchorPathType.External}
                    type={ButtonType.Link}>
                    {t("viewCatalog")}
                </Button>
                <div className={`${CSS_CLASS_NAME}__image-wrapper`}>
                    <img
                        src={catalog}
                        className={`${CSS_CLASS_NAME}__image`}
                        alt={t("nationalFireProtectionAssociationCatalog")}
                    />
                </div>
            </Card>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PhysicalContentDisplay };

// #endregion Exports
