import axios from "axios";
import { ProductScormPackageRecord } from "models/view-models/products/product-scorm-package-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "productscormpackage";
const recordType: typeof ProductScormPackageRecord = ProductScormPackageRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateProductScormPackageService = (
    params: CreateProductScormPackageParams
) => Promise<ServiceResponse<ProductScormPackageRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CreateProductScormPackageParams {
    productId: number;
    productVersionId: number;
    scormPackageId: number;
}

export interface ListProductScormPackageQueryParams {
    includeScormPackage?: boolean;
    productId: number;
    productVersionId: number;
}

export interface UpdateProductScormPackagePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductScormPackageService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateProductScormPackageService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateProductScormPackageService = (
            params: CreateProductScormPackageParams
        ): Promise<ServiceResponse<ProductScormPackageRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(recordType, r));
        };

        function create(
            params: CreateProductScormPackageParams
        ): Promise<ServiceResponse<ProductScormPackageRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<ProductScormPackageRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        ProductScormPackageRecord,
        ListProductScormPackageQueryParams
    >(recordType, baseEndpoint),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        ProductScormPackageRecord,
        UpdateProductScormPackagePathParams
    >(recordType, resourceEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductScormPackageService };

// #endregion Exports
