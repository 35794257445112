import { EnrollmentRecord } from "./enrollment-record";
import { EnrollmentUnitCourse } from "models/interfaces/enrollments/enrollment-unit-course";
import { Record } from "immutable";
import { RecordUtils } from "andculturecode-javascript-core";
import { UnitCourseRecord } from "../units/unit-course-record";
import { EnrollmentUnitCourseStatus } from "models/enumerations/enrollments/enrollment-unit-course-status";
import { RegistrationRecord } from "../registrations/registration-record";

// -----------------------------------------------------------------------------------------
// #region Default Values
// -----------------------------------------------------------------------------------------

const defaultValues: EnrollmentUnitCourse = {
    currentScore: undefined,
    courseVersionId: 0,
    courseVersion: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollment: undefined,
    enrollmentId: 0,
    id: undefined,
    locked: false,
    optional: true,
    registration: undefined,
    registrationId: undefined,
    status: EnrollmentUnitCourseStatus.NotStarted,
    unitCourse: undefined,
    unitCourseId: 0,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -----------------------------------------------------------------------------------------
// #region Record
// -----------------------------------------------------------------------------------------

class EnrollmentUnitCourseRecord extends Record(defaultValues) implements EnrollmentUnitCourse {
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Partial<EnrollmentUnitCourse>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.enrollment != null) {
            params.enrollment = RecordUtils.ensureRecord(params.enrollment, EnrollmentRecord);
        }

        if (params.registration != null) {
            params.registration = RecordUtils.ensureRecord(params.registration, RegistrationRecord);
        }

        if (params.unitCourse != null) {
            params.unitCourse = RecordUtils.ensureRecord(params.unitCourse, UnitCourseRecord);
        }

        super(params);
    }
    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Given a set of values for Enrollment properties, create a new EnrollmentRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<EnrollmentUnitCourse>} values The values to overwrite on this instance.
     * @returns A EnrollmentRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EnrollmentUnitCourse>): EnrollmentUnitCourseRecord {
        return new EnrollmentUnitCourseRecord(Object.assign(this.toJS(), values));
    }
    // #endregion Public Methods
}

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { EnrollmentUnitCourseRecord };
// #endregion Exports
