import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./aen-application-detail-page-form-field.scss";
import React from "react";

const CSS_CLASS_NAME = "aen-application-detail-page-form-field";

interface AenApplicationDetailPageFormFieldProps {
    label: TranslatedCopy | string;
    value: string | number | boolean | undefined | JSX.Element;
    otherValue?: unknown;
    asGrid?: boolean;
}

const AenApplicationDetailPageFormField = ({
    label,
    value,
    otherValue = null,
    asGrid = false,
}: AenApplicationDetailPageFormFieldProps) => {
    const computedValue =
        typeof value === "boolean"
            ? value
                ? t("yes")
                : t("no")
            : React.isValidElement(value)
            ? value
            : value?.toString();

    return (
        <FormFieldReadonly label={label} cssClassName={asGrid ? `${CSS_CLASS_NAME}__asGrid` : ""}>
            {computedValue}
            {otherValue && computedValue ? ", " : ""}
            {otherValue ? `${t("other")} (${otherValue})` : ""}
        </FormFieldReadonly>
    );
};

export default AenApplicationDetailPageFormField;
