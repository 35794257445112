import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { Card } from "components/card/card";
import { CollectionUtils } from "andculturecode-javascript-core";
import { EditOnlineProductVersionMaterials } from "components/products/product-version/edit-online-product-version-materials/edit-online-product-version-materials";
import {
    GetProductVersionPathParams,
    GetProductVersionQueryParams,
    ProductVersionService,
} from "utilities/services/products/product-version-service";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { NumberUtils } from "utilities/number-utils";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProductMaterialsChangesModal } from "components/products/product-version/product-materials-changes-modal/product-materials-changes-modal";
import { ProductVersionEditModal } from "components/products/product-version/product-version-edit-modal/product-version-edit-modal";
import { RouteUtils } from "utilities/route-utils";
import { UnitRecord } from "models/view-models/units/unit-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import { useProductVersionArchive } from "utilities/hooks/models/products/use-product-version-archives";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { TrainingType } from "models/enumerations/courses/training-type";
import "./edit-online-product-materials-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditOnlineProductMaterialsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-online-product-materials-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditOnlineProductMaterialsPage: React.FC<
    EditOnlineProductMaterialsPageProps
> = (): JSX.Element => {
    const navigate = useNavigate();
    const { record: product } = useProduct();
    const [editMode, setEditMode] = useState(false);
    const [showEditProductVersionModal, setShowEditProductVersionModal] = useState<boolean>(false);
    const [showCancelEditVersionModal, setShowCancelEditVersionModal] = useState<boolean>(false);
    const [showSaveChangesModal, setShowSaveChangesModal] = useState<boolean>(false);
    const { record: productVersion, setRecord: setProductVersion } = useProductVersion();
    const { id, versionId } = useParams();
    const productVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const productId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const { get: getProductVersion } = ProductVersionService.useGet();
    const initialUnit = useMemo(() => productVersion.units ?? [], [productVersion.units]);
    const [unit, setUnit] = useState<UnitRecord>(initialUnit[0]);

    useRedirectOnForbidden(sitemap.admin.product.list);
    const [dirty, setDirty] = useState<boolean>(false);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const navigationRequests = useListenForNavigationRequests(EditOnlineProductMaterialsPage.name, [
        Breadcrumbs.name,
        ProductLayout.name,
    ]);
    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (!editMode || !dirty) {
            resolveNavigationRequest(
                EditOnlineProductMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
            return;
        }
        setNavigationRequest(navigationRequest);
    }, [editMode, dirty, navigationRequests, resolveNavigationRequest]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(
                EditOnlineProductMaterialsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(
            EditOnlineProductMaterialsPage.name,
            navigationRequest.requestingComponent
        );
    }, [navigationRequest, resolveNavigationRequest]);

    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    const onCancelChangesModalClose = async () => {
        setShowCancelEditVersionModal(false);
        setDirty(false);
        //return the productVersion to its original state
        //get the edit version from the api
        fetchProductVersion();
        setEditMode(false);
    };

    const fetchProductVersion = async (): Promise<void> => {
        try {
            const pathParams: GetProductVersionPathParams = {
                id: productVersionId,
            };
            const queryParams: GetProductVersionQueryParams = {
                includeProductScormPackage: true,
                includeUnit: true,
                includeContent: true,
                includeEvaluationTemplate: true,
            };

            const getResponse = await getProductVersion(pathParams, queryParams);
            const getResult = getResponse?.result;
            const editProductVersion = getResult?.resultObject;
            editProductVersion.sortAllChildren();

            setProductVersion(editProductVersion);
        } catch (error) {}
    };

    const { productVersionArchive, setRefresh } = useProductVersionArchive({
        productId: product.id!,
        productVersionId: productVersionId,
        includeCreatedBy: true,
    });

    const showCancelEditModal = () => {
        if (dirty && editMode) {
            setShowCancelEditVersionModal(true);
        } else {
            navigate(
                RouteUtils.localizePath(
                    RouteUtils.replacePathParams(sitemap.admin.product.edit.versionList, {
                        id: productId,
                    })
                )
            );
        }
    };

    return (
        <>
            {product.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedProduct")}
                </Banner>
            )}
            <div className={CSS_CLASS_NAME}>
                <ButtonIcon
                    cssClassName={`${CSS_CLASS_NAME}__back-button`}
                    iconType={Icons.ChevronLeft}
                    text={t("backToAllVersions")}
                    buttonStyle={ButtonStyle.Secondary}
                    buttonSize={ButtonSize.Small}
                    onClick={showCancelEditModal}
                />
                <div className={`${CSS_CLASS_NAME}__header`}>
                    {!editMode && (
                        <HeaderActions
                            headingPriority={HeadingPriority.H5}
                            headingSize={HeadingSize.Small}
                            title={t("versionVersionNumber", {
                                versionNumber: productVersion?.versionId,
                            })}>
                            <Button
                                disabled={
                                    product.status === ActiveStatus.Archived ||
                                    product.status === ActiveStatus.Inactive ||
                                    product.type === TrainingType.TrainTheTrainer
                                }
                                onClick={() => setShowEditProductVersionModal(true)}
                                style={ButtonStyle.Primary}
                                text={t("editVersion")}
                            />
                        </HeaderActions>
                    )}
                    {editMode && (
                        <HeaderActions
                            headingPriority={HeadingPriority.H5}
                            headingSize={HeadingSize.Small}
                            title={t("versionVersionNumber", {
                                versionNumber: productVersion?.versionId,
                            })}>
                            <Button
                                onClick={() => setShowCancelEditVersionModal(true)}
                                style={ButtonStyle.Secondary}
                                text={t("cancelEdit")}
                            />
                            <ButtonIcon
                                buttonStyle={ButtonStyle.Primary}
                                disabled={
                                    !productVersion.courseIsComplete() ||
                                    !productVersion.contentIsComplete() ||
                                    !productVersion.achievementIsComplete(product.audienceType) ||
                                    !product.evaluationTemplateIsComplete()
                                }
                                iconType={Icons.PublishWithChanges}
                                onClick={() => setShowSaveChangesModal(true)}
                                text={t("saveChanges")}
                            />
                        </HeaderActions>
                    )}
                    {product.type === TrainingType.TrainTheTrainer && (
                        <Banner style={BannerStyle.Light}>
                            <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XSmall}>
                                {t(
                                    "youCanNotEditContentFromATrainTheTrainerProductInOrderToEditContentYouMustEditTheAssociatedProduct"
                                )}
                            </Paragraph>
                        </Banner>
                    )}
                    {productVersion.versionId !== 0 && productVersion.activatedOn === null && (
                        <div className={`${CSS_CLASS_NAME}__banner`}>
                            <NotificationBanner
                                notificationId={0}
                                onClose={() => {}}
                                style={NotificationType.Default}
                                message={t(
                                    "byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion"
                                )}
                            />
                        </div>
                    )}
                    <Card cssClassName={`${CSS_CLASS_NAME}__card`}>
                        <div>
                            <Paragraph
                                size={ParagraphSize.XSmall}
                                emphasis={ParagraphEmphasis.Bold}>
                                {t("lastChanged")}
                            </Paragraph>
                            <Paragraph size={ParagraphSize.XSmall}>
                                {productVersion.getLastUpdatedDateAndTime()}
                            </Paragraph>
                        </div>
                        <div>
                            <Paragraph
                                size={ParagraphSize.XSmall}
                                emphasis={ParagraphEmphasis.Bold}>
                                {t("changedBy")}
                            </Paragraph>
                            <div>
                                <Paragraph size={ParagraphSize.XSmall}>
                                    {productVersion.getLastUpdatedBy()}
                                </Paragraph>
                                <Paragraph
                                    cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                    size={ParagraphSize.XxSmall}
                                    style={ParagraphStyle.Light}></Paragraph>
                            </div>
                        </div>
                    </Card>
                </div>
                <EditOnlineProductVersionMaterials
                    editMode={editMode}
                    productVersionArchiveData={productVersionArchive}
                    setDirty={setDirty}
                    setUnit={setUnit}
                    unit={unit}
                />
                <ProductVersionEditModal
                    open={showEditProductVersionModal}
                    setOpen={setShowEditProductVersionModal}
                    setEditMode={setEditMode}
                />
                <CancelChangesModal
                    open={showCancelEditVersionModal}
                    setOpen={setShowCancelEditVersionModal}
                    onModalClose={onCancelChangesModalClose}
                />
                <ProductMaterialsChangesModal
                    fetchProductVersion={fetchProductVersion}
                    setEditMode={setEditMode}
                    setRefresh={setRefresh}
                    setShowSaveMaterialChangesModal={setShowSaveChangesModal}
                    showSaveMaterialChangesModal={showSaveChangesModal}
                />
                <Modal
                    cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                    isOpen={showCancelEditOnNavigationModal}
                    onModalClose={() => {}}
                    actions={cancelEditModeOnNavigationConfirmationActions}
                    modalStyle={"-inverted"}>
                    {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
                </Modal>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditOnlineProductMaterialsPage };

// #endregion Exports
