import { ServiceHookFactory } from "andculturecode-javascript-react";
import { InstructorLanguageRecord } from "models/view-models/instructors/instructor-language-record";

const baseEndpoint = "instructorLanguages";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType = InstructorLanguageRecord;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Service
// -----------------------------------------------------------------------------------------

const InstructorLanguageService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),
};

// #endregion Service

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { InstructorLanguageService };

// #endregion Exports
