import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { useMemo } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Button, ButtonStyle, ButtonSize, ButtonType } from "components/buttons/button/button";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { LearnerPastTrainings } from "components/learner/learner-past-trainings/learner-past-trainings";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLearnerQuickLinks } from "./use-learner-quick-links";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./my-learning-assignments-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface MyPastLearningAssignmentsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "my-learning-assignments-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const MyPastLearningAssignmentsPage: React.FC<MyPastLearningAssignmentsPageProps> =
    validatePageAccess(AccessControlKeys.MyLearningAssignmentsPage)((): JSX.Element => {
        const { record: globalState } = useGlobalState();
        const { learnerQuickLinks } = useLearnerQuickLinks({
            settings: globalState?.systemSettings,
        });

        const learnerFirstName = useMemo(
            () => globalState?.currentIdentity?.user?.firstName,
            [globalState?.currentIdentity?.user?.firstName]
        );
        const userId = useMemo(
            () => globalState?.currentIdentity?.user?.id,
            [globalState?.currentIdentity?.user?.id]
        );

        return (
            <>
                <HeaderBanner
                    title={t("twelcomeLearnerFirstName", {
                        twelcome: t("welcome"),
                        learnerFirstName: learnerFirstName,
                    })}></HeaderBanner>
                <SkipNavContent>
                    <div className={`${CSS_CLASS_NAME}`}>
                        <div className={`${CSS_CLASS_NAME}__section`}>
                            <div className={`${CSS_CLASS_NAME}__quick__links`}>
                                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                                    {t("quickLinks")}
                                </Heading>
                                <QuickLinkList links={learnerQuickLinks} />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__header`}>
                                <div className={`${CSS_CLASS_NAME}__header__text`}>
                                    <h2>{t("pastTrainings")}</h2>
                                    <Tooltip
                                        content={t(
                                            "displaysTrainingsWhereAccessHasExpiredOrTrainingRequirementsAreComplete"
                                        )}
                                        placement={TooltipPlacement.Bottom}>
                                        <span>
                                            <Icon
                                                type={Icons.Information}
                                                cssClassName={`${CSS_CLASS_NAME}__incomplete-icon`}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                                <div className={`${CSS_CLASS_NAME}__assignment-filter`}>
                                    <Button
                                        size={ButtonSize.Medium}
                                        style={ButtonStyle.Secondary}
                                        text={t("inHyphenProgress")}
                                        linkPath={sitemap.learner.learningAssignments.inProgress}
                                        type={ButtonType.Link}
                                    />
                                    <Button
                                        size={ButtonSize.Medium}
                                        style={ButtonStyle.Primary}
                                        text={t("past")}
                                        type={ButtonType.Link}
                                    />
                                </div>
                            </div>
                            <LearnerPastTrainings userId={userId!} />
                        </div>
                    </div>
                </SkipNavContent>
            </>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { MyPastLearningAssignmentsPage };

// #endregion Exports
