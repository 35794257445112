import axios from "axios";
import { ProductContentAccess } from "models/enumerations/products/product-content-access";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "productcontents";
const resourceType: typeof ProductContentRecord = ProductContentRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateProductContentService = (
    params: CreateProductContentParams
) => Promise<ServiceResponse<ProductContentRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CreateProductContentParams {
    access: ProductContentAccess;
    contentId: number;
    productId: number;
    productVersionId: number;
    sortOrder?: number;
}

export interface ListContentsParams {
    includeContent?: boolean;
    productId?: number;
    productVersionId?: number;
}

export interface UpdateProductContentPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProductContentService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateProductContentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateProductContentService = (
            params: CreateProductContentParams
        ): Promise<ServiceResponse<ProductContentRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            params: CreateProductContentParams
        ): Promise<ServiceResponse<ProductContentRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<ProductContentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<ProductContentRecord, ListContentsParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<ProductContentRecord, UpdateProductContentPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductContentService };

// #endregion Exports
