import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { CreateInstructorApplicationEnrollmentDtoRecord } from "models/view-models/enrollments/create-instructor-application-enrollment-dto-record";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { InstructorApplicationEnrollmentRecord } from "models/view-models/enrollments/instructor-application-enrollment-record";
import { InstructorAssessmentCard } from "components/products/instructor-assessment-card/instructor-assessment-card";
import { ModalAction } from "components/modal/modal";
import { NumberUtils } from "utilities/number-utils";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductTrainingEnrollmentModal } from "components/products/product-training-enrollment-modal/product-training-enrollment-modal";
import { TrainingType } from "models/enumerations/courses/training-type";
import { WithdrawEnrollmentModal } from "components/enrollments/withdraw-enrollment-modal/withdraw-enrollment-modal";
import { t } from "utilities/localization/t";
import { useInstructorApplicationEnrollments } from "utilities/hooks/models/enrollments/use-instructor-application-enrollments";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./assessment-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AssessmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "assessment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AssessmentPage: React.FC<AssessmentPageProps> = validatePageAccess<AssessmentPageProps>(
    AccessControlKeys.InstructorApplicationAssessmentPage
)((): JSX.Element => {
    const { id } = useParams();
    const instructorApplicationId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const [showEnrollNewTrainingModal, setShowEnrollNewTrainingModal] = useState(false);
    const enrollmentDto = new CreateInstructorApplicationEnrollmentDtoRecord({});
    const [enrollmentToWithdraw, setEnrollmentToWithdraw] =
        useState<InstructorApplicationEnrollmentRecord>(
            new InstructorApplicationEnrollmentRecord()
        );
    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);

    const { instructorApplicationEnrollments, addEnrollment, withdrawEnrollment } =
        useInstructorApplicationEnrollments({
            instructorApplicationId,
        });

    const handleAddEnrollment = (product: ProductRecord) => {
        addEnrollment(enrollmentDto.with({ productId: product.id }));
    };

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                withdrawEnrollment(enrollmentToWithdraw);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const withdrawInstructorApplicationEnrollment = (e: InstructorApplicationEnrollmentRecord) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <HeaderActions
                    title={t("assessments")}
                    headingPriority={HeadingPriority.H2}
                    headingSize={HeadingSize.XSmall}>
                    <ButtonIcon
                        onClick={() => setShowEnrollNewTrainingModal(true)}
                        buttonStyle={ButtonStyle.Primary}
                        text={t("addAssessment")}
                        iconType={Icons.Plus}
                    />
                </HeaderActions>
            </div>
            {instructorApplicationEnrollments.map(
                (instructorApplicationEnrollment: InstructorApplicationEnrollmentRecord, idx) => (
                    <InstructorAssessmentCard
                        key={instructorApplicationEnrollment.id}
                        instructorApplicationEnrollment={instructorApplicationEnrollment}
                        sortOrder={idx + 1}
                        withdrawEnrollment={withdrawInstructorApplicationEnrollment}
                    />
                )
            )}
            <ProductTrainingEnrollmentModal
                addEnrollment={handleAddEnrollment}
                open={showEnrollNewTrainingModal}
                setOpen={setShowEnrollNewTrainingModal}
                trainingType={TrainingType.InstructorAssessment}
            />
            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={
                    enrollmentToWithdraw?.enrollment?.user?.getFirstAndLastNameOrEmail() ?? ""
                }
            />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AssessmentPage };

// #endregion Exports
