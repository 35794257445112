import { GroupRecord } from "models/view-models/groups/group-record";
import { GroupService } from "utilities/services/groups/group-service";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingGroups";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseGroupsHook {
    error?: Error;
    isLoading: boolean;
    groups: GroupRecord[];
    rowCount: number;
}

interface UseGroupsHookOptions {
    onError?: (error: Error) => void;
    searchText?: string;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useGroups({
    onError,
    searchText,
    skip,
    take,
}: UseGroupsHookOptions): UseGroupsHook {
    const { list } = GroupService.useList();
    const [groups, setGroups] = useState<GroupRecord[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        try {
            (async function getGroups() {
                setIsLoading(true);

                const groupsResponse = await list({
                    searchText: searchText,
                    skip: skip,
                    take: take,
                });

                setIsLoading(false);

                if (
                    groupsResponse?.resultObjects == null ||
                    groupsResponse?.results == null ||
                    groupsResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setGroups(groupsResponse.resultObjects);
                setRowCount(groupsResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, searchText, setError, skip, take]);

    return {
        error,
        isLoading,
        groups,
        rowCount,
    };
}

// #endregion Hook
