import axios from "axios";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "events/:id/certificates";
const downloadEventCertificateEndpoint: string = `${baseEndpoint}/download`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface EventCertificateDownloadPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventCertificateService = {
    downloadEventCertificates: async (
        pathParams: EventCertificateDownloadPathParams,
        downloadName: string
    ) => {
        try {
            const pathWithQueryParams = RouteUtils.getUrl(
                downloadEventCertificateEndpoint,
                pathParams
            );

            const response = await axios.get(pathWithQueryParams, {
                responseType: "blob",
            });

            if (response.status !== 200) {
                throw new Error();
            }

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = downloadName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            ToastManager.error(t("AnErrorOccurredWhileDownloadingTheEventCertificates"));
        }
    },
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventCertificateService };

// #endregion Exports
