import { ApplicationFileRecord } from "models/view-models/aen-applications/application-file-record";
import { NumberUtils } from "utilities/number-utils";
import {
    ProviderApplicationFilePathParams,
    ProviderApplicationFileService,
} from "utilities/services/aen-applications/provider-application-file-service";
import { ProviderApplicationFileRecord } from "models/view-models/aen-applications/provider-application-file-record";
import { ProviderApplicationFileType } from "models/enumerations/aen-applications/provider-application-file-type";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import { useCallback, useEffect, useState } from "react";

interface UseAenProviderApplicationFilesHook {
    errors?: ResultErrorRecord[];
    files: ProviderApplicationFileRecord[];
    isLoading: boolean;
    saveFile: (aenApplicationFile: ApplicationFileRecord) => Promise<boolean>;
}

interface UseAenProviderApplicationFilesHookOptions {
    aenApplicationId: number;
}

export function useAenProviderApplicationFiles(
    props: UseAenProviderApplicationFilesHookOptions
): UseAenProviderApplicationFilesHook {
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState<ProviderApplicationFileRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const { create: providerApplicationFileCreate } = ProviderApplicationFileService.useCreate();
    const { list: listProviderApplicationFiles } = ProviderApplicationFileService.useList();
    const aenApplicationId = NumberUtils.parseInt(props.aenApplicationId) ?? 0;

    const fetchData = useCallback(async (): Promise<void> => {
        if (isNaN(aenApplicationId) || aenApplicationId < 1) {
            return;
        }

        try {
            (async function getProviderApplicationFiles() {
                const listProviderApplicationFilesPathParams: ProviderApplicationFilePathParams = {
                    providerApplicationId: aenApplicationId,
                };

                const { result, resultObjects } = await listProviderApplicationFiles(
                    listProviderApplicationFilesPathParams
                );

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }

                setFiles(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAnIssueReturningApplicationFiles"));
        }
    }, [aenApplicationId, listProviderApplicationFiles]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const saveFile = useCallback(
        async (aenApplicationFile: ApplicationFileRecord) => {
            try {
                const fileType = aenApplicationFile.type as ProviderApplicationFileType;
                const file = new ProviderApplicationFileRecord({
                    description: aenApplicationFile.description,
                    file: aenApplicationFile.file,
                    fileId: aenApplicationFile.fileId,
                    providerApplicationId: aenApplicationId,
                    type: fileType,
                });
                const createProviderApplicationFilePathParams: ProviderApplicationFilePathParams = {
                    providerApplicationId: aenApplicationId,
                };

                const createFileResponse = await providerApplicationFileCreate(
                    file,
                    createProviderApplicationFilePathParams
                );
                const createFileResult = createFileResponse.result;
                if (createFileResult?.resultObject == null || createFileResult.hasErrors()) {
                    throw new Error();
                }

                fetchData();
                return true;
            } catch {
                ToastManager.error(t("thereWasAnIssueSavingTheFile"));
                return false;
            }
        },
        [aenApplicationId, fetchData, providerApplicationFileCreate]
    );

    return {
        errors,
        files,
        isLoading,
        saveFile,
    };
}
