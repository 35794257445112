import React, { useCallback, useEffect, useState } from "react";
import { Card } from "components/card/card";
import { E164Number } from "libphonenumber-js";
import { FormCalendarDatePicker } from "components/form/form-calendar-date-picker/form-calendar-date-picker";
import { FormPhoneInput } from "components/form/form-phone-input/form-phone-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { StringUtils } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import {
    ContractService,
    HasDuplicateContractNumberQueryParams,
} from "utilities/services/contracts/contract-service";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { Contract } from "models/interfaces/contracts/contract";
import { t } from "utilities/localization/t";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./event-contract-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventContractFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-contract-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventContractForm: React.FC<EventContractFormProps> = ({ event }): JSX.Element => {
    const [emailError, setEmailError] = useState("");
    const [duplicateContractNumberError, setDuplicateContractNumberError] = useState("");
    const { readOnly } = useReadOnly();

    useEffect(() => {
        if (readOnly) {
            setEmailError("");
            setDuplicateContractNumberError("");
        }
    }, [readOnly]);

    const [contractNumber, setContractNumber] = useState<string | undefined>(
        event.contract?.contractNumber
    );
    const { get: hasDuplicateContractNumberApi } = ContractService.useHasDuplicateContractNumber();

    useEffect(() => {
        setContractNumber(event.contract?.contractNumber);
    }, [event.contract?.contractNumber]);

    const updateContract = useCallback(
        (values: Partial<Contract>) => {
            const contractRecord = event.contract?.with(values);

            event.updateActiveRecord({ contract: contractRecord });
        },
        [event]
    );

    const updateToggleOrDatePicker = (values: Partial<Contract>): void => {
        const contractRecord = event.contract?.with(values);

        if (event.isDraft()) {
            event.updateAndSave({ contract: contractRecord });
        } else {
            event.updateActiveRecord({ contract: contractRecord });
        }
    };

    const handleToggleChange = () => {
        const contractChanges: Partial<Contract> = {
            noEnrollmentLimit: !event.contract?.noEnrollmentLimit,
            enrollmentLimit: 0,
        };
        updateToggleOrDatePicker(contractChanges);
    };

    const handleContractNumberChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ contractNumber: inputEvent.target.value });
        setContractNumber(inputEvent.target.value);
    };

    const handleDateChange = (date?: Date) => {
        updateToggleOrDatePicker({ contractDate: date });
    };

    const handleEnrollmentLimitChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ enrollmentLimit: Number(inputEvent.target.value) });
    };

    const handleNFPAAdminNameChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ nfpaAdminName: inputEvent.target.value });
    };

    const handleNFPAAdminEmailChange = (inputEvent: React.ChangeEvent<HTMLInputElement>): void => {
        updateContract({ nfpaAdminEmail: inputEvent.target.value });
    };

    const handleEmailValidation = (): void => {
        validateEmail(event.contract?.nfpaAdminEmail);
    };

    const validateEmail = (email?: string): void => {
        if (StringUtils.hasValue(email) && !StringUtils.isValidEmail(email)) {
            setEmailError(t("pleaseEnterAValidEmailAddress"));
            return;
        }

        setEmailError("");

        if (event.isDraft()) {
            event.save();
        }
    };

    const handleNFPAAdminPhoneChange = (value?: E164Number | undefined): void => {
        const valueToSet = value === undefined ? "" : value;
        updateContract({ nfpaAdminPhone: valueToSet });
    };

    const handleBlur = useCallback((): void => {
        if (event.isDraft()) {
            event.save();
        }
    }, [event]);

    const hasDuplicateContractNumber = useCallback(async (): Promise<void> => {
        setDuplicateContractNumberError("");

        if (StringUtils.isEmpty(contractNumber)) {
            setDuplicateContractNumberError("");
            handleBlur();
            return;
        }

        try {
            const hasDuplicateContractNumberPathParams = {};
            const hasDuplicateContractNumberQueryParams: HasDuplicateContractNumberQueryParams = {
                contractId: event.contract?.id ?? 0,
                contractNumber: contractNumber!,
            };
            const checkForDuplicateContractNumberResponse = await hasDuplicateContractNumberApi(
                hasDuplicateContractNumberPathParams,
                hasDuplicateContractNumberQueryParams
            );
            const checkForDuplicateContractNumberResult =
                checkForDuplicateContractNumberResponse?.result;

            if (
                checkForDuplicateContractNumberResult?.resultObject == null ||
                checkForDuplicateContractNumberResult.hasErrors()
            ) {
                throw new Error();
            }

            if (checkForDuplicateContractNumberResult.resultObject.valueOf() === true) {
                setDuplicateContractNumberError(t("aContractAlreadyExistsWithThisNumber"));
            } else if (event.isDraft()) {
                event.updateAndSave({
                    contract: event.contract?.with({ contractNumber: contractNumber }),
                });
            } else {
                updateContract({ contractNumber: contractNumber });
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueCheckingForDuplicateContractNumbers"));
        }
    }, [contractNumber, event, handleBlur, hasDuplicateContractNumberApi, updateContract]);

    return (
        <Card>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H3} size={HeadingSize.XSmall}>
                    {t("contractInformation")}
                </Heading>
                <ToggleLabel
                    checked={event.contract?.noEnrollmentLimit}
                    id="no-enrollment-limit"
                    label={t("noEnrollmentLimit")}
                    onToggle={handleToggleChange}
                />
            </div>
            <div className="event-contract-form__grid">
                <div className="event-contract-form__contract-number">
                    <div className="event-contract-form__form-field-container">
                        <FormTextInput
                            ariaLabelledBy={t("contractNumber")}
                            disabled={false}
                            errorMessage={duplicateContractNumberError}
                            formFieldName="contractNumber"
                            id="contractNumber"
                            label={t("contractNumber")}
                            maxLength={26}
                            onBlur={hasDuplicateContractNumber}
                            onChange={handleContractNumberChange}
                            placeholder={t("enterContractNumber")}
                            required={true}
                            value={contractNumber}
                        />
                    </div>
                </div>
                <div className="event-contract-form__contract-date">
                    <div className="event-contract-form__form-field-container">
                        <FormCalendarDatePicker
                            placeholder="emptyDate"
                            formFieldName="eventDate"
                            label={t("contractDate")}
                            onChange={handleDateChange}
                            required={true}
                            selectedDate={
                                event.contract?.contractDate
                                    ? new Date(event.contract?.contractDate)
                                    : undefined
                            }
                        />
                    </div>
                </div>
                <div className="event-contract-form__enrollment-limit">
                    <div className="event-contract-form__form-field-container">
                        <FormTextInput
                            ariaLabelledBy={t("enrollmentLimit")}
                            autoFocus={false}
                            disabled={event.contract?.noEnrollmentLimit}
                            formFieldName="enrollmentLimit"
                            id="enrollmentLimit"
                            label={t("enrollmentLimit")}
                            maxLength={60}
                            minValue={0}
                            onBlur={handleBlur}
                            onChange={handleEnrollmentLimitChange}
                            placeholder={t("enterEnrollmentLimit")}
                            required={true}
                            type={InputTypes.Number}
                            value={event.contract?.enrollmentLimit}
                        />
                    </div>
                </div>
            </div>
            <div className="event-contract-form__grid">
                <div className="event-contract-form__nfpa-admin-name">
                    <div className="event-contract-form__form-field-container">
                        <FormTextInput
                            ariaLabelledBy={t("nfpaContactName")}
                            autoFocus={false}
                            formFieldName="nfpaAdminName"
                            id="nfpaAdminName"
                            label={t("nfpaContactName")}
                            maxLength={150}
                            onBlur={handleBlur}
                            onChange={handleNFPAAdminNameChange}
                            placeholder={t("enterNfpaContactName")}
                            required={true}
                            value={event.contract?.nfpaAdminName}
                        />
                    </div>
                </div>
                <div className="event-contract-form__nfpa-admin-email">
                    <div className="event-contract-form__form-field-container">
                        <FormTextInput
                            ariaLabelledBy={t("nfpaContactEmail")}
                            autoFocus={false}
                            errorMessage={emailError}
                            formFieldName="nfpaAdminEmail"
                            id="nfpaAdminEmail"
                            label={t("nfpaContactEmail")}
                            maxLength={250}
                            onBlur={handleEmailValidation}
                            onChange={handleNFPAAdminEmailChange}
                            placeholder={t("enterNfpaContactEmail")}
                            required={true}
                            type={InputTypes.Email}
                            value={event.contract?.nfpaAdminEmail}
                        />
                    </div>
                </div>
                <div className="event-contract-form__nfpa-admin-phone">
                    <div className="event-contract-form__form-field-container">
                        <FormPhoneInput
                            ariaLabelledBy={t("nfpaContactPhone")}
                            autoFocus={false}
                            formFieldName="nfpaAdminPhone"
                            id="nfpaAdminPhone"
                            label={t("nfpaContactPhone")}
                            onBlur={handleBlur}
                            onChange={handleNFPAAdminPhoneChange}
                            placeholder={t("enterNfpaContactPhone")}
                            required={true}
                            type={InputTypes.Phone}
                            value={event.contract?.nfpaAdminPhone}
                        />
                    </div>
                </div>
            </div>
            <div className="event-contract-form__grid">
                <div className="event-contract-form__nfpa-admin-name">
                    <div className="event-contract-form__form-field-container">
                        <FormTextInput
                            ariaLabelledBy={t("lmsId")}
                            autoFocus={false}
                            disabled={true}
                            formFieldName="lmsId"
                            id="lmsId"
                            label={t("lmsId")}
                            maxLength={150}
                            type={InputTypes.Number}
                            value={event.contract?.id}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventContractForm };

// #endregion Exports
