import React from "react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { useRef, useState } from "react";
import { FileUtils } from "utilities/files/file-utils";
import { FileRecord } from "models/view-models/files/file-record";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import "./file-input.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FileInputProps {
    ariaLabelledBy?: string;
    buttonText: string;
    fileIsValid?: (file: File) => boolean;
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFileInvalid?: (file: File) => void;
    onFileUpload: (file: FileRecord) => void;
    onFileUploadError?: () => void;
    storageContainer: StorageContainers;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "file-input";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const fileIsInvalid = (file: File): boolean => {
        // {Prevent .exe and .bat files from being uploaded}
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (fileExtension === "exe" || fileExtension === "bat") {
            return true;
        }

        if (props.fileIsValid != null) {
            return !props.fileIsValid(file);
        }

        return false;
    };

    const handleChooseFileClick = () => {
        hiddenFileInput?.current?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onFileChange != null) {
            props.onFileChange(event);
        }

        const file = event?.target?.files?.item(0);

        if (file == null) {
            setFileName("");
            setLoading(false);
            return;
        }

        if (fileIsInvalid(file)) {
            if (props.onFileInvalid != null) {
                props.onFileInvalid(file);
            }

            return;
        }

        setFileName(file.name);
        setLoading(true);

        try {
            const fileRecord = await FileUtils.uploadFile(file, props.storageContainer);
            props.onFileUpload(fileRecord);
            setLoading(false);
        } catch {
            handleFileUploadError();
        }
    };

    const handleFileUploadError = () => {
        if (props.onFileUploadError != null) {
            props.onFileUploadError();
        }

        setLoading(false);
        setFileName("");
    };

    const fileNameText = loading ? "..." : fileName;

    return (
        <div className={CSS_CLASS_NAME}>
            <Button
                text={props.buttonText}
                style={ButtonStyle.Secondary}
                onClick={handleChooseFileClick}
                ariaLabelledBy={props.ariaLabelledBy}
            />
            <p>{fileNameText}</p>
            <input type="file" ref={hiddenFileInput} onChange={handleFileChange} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FileInput };

// #endregion Exports
