import { useCallback, useEffect, useState } from "react";
import { Event } from "models/interfaces/events/event";
import { EventInstructorCardSelectionList } from "./event-instructor-card-selection-list/event-instructor-card-selection-list";
import { InstructorRecord } from "models/view-models/instructors/instructor-record";
import {
    InstructorService,
    ListInstructorsQueryParams,
} from "utilities/services/instructors/instructor-service";
import { SelectableInstructor } from "models/interfaces/instructors/selectable-instructor";
import { NumberUtils } from "utilities/number-utils";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { StringUtils } from "utilities/string-utils";
import { InstructorProfileStatus } from "models/instructors/instructor-profile-status";
import "./event-instructor-card-selection-manager.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInstructorCardSelectionManagerProps {
    event: EventActiveRecord;
    includeAenNetwork?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "event-instructor-card-selection-manager";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventInstructorCardSelectionManager: React.FC<EventInstructorCardSelectionManagerProps> = ({
    event,
    includeAenNetwork,
}) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [selectableInstructors, setSelectableInstructors] = useState<SelectableInstructor[]>([]);
    const { list: listInstructors } = InstructorService.useList();

    // Default to -1 if the language is not provided urlParms
    const languageParam = urlParams.get("language") ?? "";
    const language = NumberUtils.parseInt(languageParam) ?? -1;

    const teachingMethodParam = urlParams.get("teachingMethod") ?? "";
    const teachingMethod = NumberUtils.parseInt(teachingMethodParam) ?? -1;

    const travelTypeParam = urlParams.get("travelType") ?? "";
    const travelType = NumberUtils.parseInt(travelTypeParam) ?? -1;

    const country = urlParams.get("country");

    const state = urlParams.get("state");

    const searchText = urlParams.get("searchText");

    const approvedToTeach = urlParams.get("approvedToTeach");

    const updateEvent = useCallback(
        (values: Partial<Event>): void => {
            if (event.isDraft()) {
                event.updateAndSave(values);
            } else {
                event.updateActiveRecord(values);
            }
        },
        [event]
    );

    const handleSelectInstructor = useCallback(
        (instructor: InstructorRecord): void => {
            updateEvent({
                instructorId: instructor.userId,
                instructor: instructor,
                instructorConfirmed: false,
            });
        },
        [updateEvent]
    );

    const handleDeselectInstructor = useCallback((): void => {
        updateEvent({
            instructorId: undefined,
            instructor: undefined,
            instructorConfirmed: false,
        });
    }, [updateEvent]);

    const convertInstructorToSelectableInstructor = useCallback(
        (instructors: InstructorRecord[]): SelectableInstructor[] => {
            return instructors.map((i): SelectableInstructor => {
                return {
                    instructor: i,
                    isSelected: event.instructor?.userId === i.userId,
                };
            });
        },
        [event.instructor]
    );

    const fetchInstructors = useCallback(async () => {
        try {
            const listInstructorsQueryParams: ListInstructorsQueryParams = {
                approvedToTeachProductId: approvedToTeach ? event.productId : 0,
                searchText: searchText ?? "",
                onlyInstructorsWithSharedLocation: StringUtils.hasValue(
                    country ?? state ?? undefined
                ),
                country: country ?? "",
                includeInstructorProfile: true,
                language: language >= 0 ? language : undefined,
                state: state ?? "",
                teachingMethod: teachingMethod >= 0 ? teachingMethod : undefined,
                travelType: travelType >= 0 ? travelType : undefined,
                providerId: event.providerId,
                includeAenNetwork: includeAenNetwork ?? undefined,
                status: InstructorProfileStatus.Active,
            };

            const listFilteredInstructorsResponse = await listInstructors(
                listInstructorsQueryParams
            );
            const listFilteredInstructorsResults = listFilteredInstructorsResponse?.results;
            const instructors = listFilteredInstructorsResponse?.resultObjects;
            if (
                instructors == null ||
                listFilteredInstructorsResults == null ||
                listFilteredInstructorsResults.hasErrors()
            ) {
                throw new Error();
            }

            instructors.sort((a, b) =>
                a.getFirstAndLastName().localeCompare(b.getFirstAndLastName())
            );

            const selectableInstructors = convertInstructorToSelectableInstructor(instructors);
            setSelectableInstructors(selectableInstructors);
        } catch {
            alert(t("failedToRetrieveInstructors"));
            setSelectableInstructors([]);
        }
    }, [
        approvedToTeach,
        convertInstructorToSelectableInstructor,
        country,
        event.productId,
        event.providerId,
        language,
        listInstructors,
        searchText,
        includeAenNetwork,
        state,
        teachingMethod,
        travelType,
    ]);

    useEffect(() => {
        fetchInstructors();
    }, [fetchInstructors]);

    return (
        <div className={CSS_CLASS_NAME}>
            <EventInstructorCardSelectionList
                selectableInstructors={selectableInstructors}
                handleAdd={handleSelectInstructor}
                handleRemove={handleDeselectInstructor}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInstructorCardSelectionManager };

// #endregion Exports
