import React, { useEffect, useMemo, useState } from "react";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductForm } from "./product-form/product-form";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductService } from "utilities/services/products/product-service";
import { ResultRecord } from "andculturecode-javascript-core";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { UnitRecord } from "models/view-models/units/unit-record";
import { UnitService } from "utilities/services/units/unit-service";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { StringUtils } from "utilities/string-utils";
import "./product-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductModalProps {
    associatedProducts: ProductRecord[];
    currentProduct?: ProductRecord;
    open: boolean;
    setProductToEdit?: React.Dispatch<React.SetStateAction<ProductRecord>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductModal: React.FC<ProductModalProps> = ({
    associatedProducts,
    currentProduct,
    open,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const initialProduct = useMemo(() => currentProduct ?? new ProductRecord(), [currentProduct]);
    const [product, setProduct] = useState<ProductRecord>(initialProduct);
    const { create } = ProductService.useCreate();
    const { create: createUnitApi } = UnitService.useCreate();

    useEffect(() => {
        setProduct(initialProduct);
    }, [initialProduct]);

    const formIsNotComplete = () => {
        // These are required for all products
        if (
            !StringUtils.hasValue(product.name) ||
            product.audienceType == null ||
            product.type == null
        ) {
            return true;
        }

        if (product.audienceType === AudienceType.EducationNetwork) {
            return product.associatedProductId == null;
        } else if (product.type === TrainingType.OnlineLearning) {
            return product.onlineLearningType == null;
        }

        return false; // form is valid at this point.
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("createProduct"),
            disabled: formIsNotComplete(),
            onClick: () => createProduct(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setOpen(false);
        resetData();
    };

    const resetData = (): void => {
        if (!currentProduct) {
            setProduct(new ProductRecord());
        } else {
            setProduct(currentProduct);
        }
    };

    const createProduct = async (): Promise<boolean> => {
        if (formIsNotComplete()) {
            alert(t("productMustHaveANameTypeAndProductType"));
            return false;
        }

        try {
            const createProductResponse = await create(product);
            const createProductResult = createProductResponse.result;

            if (createProductResult?.resultObject == null || createProductResult.hasErrors()) {
                throw new Error();
            }

            const newProduct = product.with({
                ...createProductResult.resultObject.toJS(),
            });

            setProduct(newProduct);

            //Added this check to prevent the unit from being created for Instructor Led Training
            if (newProduct.onlineLearningType === ProductCourseType.CourseSeries) {
                //  Default a Unit for OnlineLearningTypes where a Unit creation is not available in the UI.
                const unit = new UnitRecord().with({
                    productId: newProduct.id,
                    productVersionId: newProduct.draftProductVersionId,
                });

                let createUnitResponse;
                try {
                    createUnitResponse = await createUnitApi(unit);
                } catch (error) {
                    const errorResult = error as ResultRecord<UnitRecord>;
                    ToastManager.error(
                        t("anErrorOccurredSavingYourSelection") +
                            "\r\n" +
                            errorResult.listErrorMessages().join("\r\n")
                    );

                    return false;
                }

                const createResult = createUnitResponse?.result;
                if (createResult?.resultObject == null || createResult.hasErrors()) {
                    throw new Error();
                }
            }

            setOpen(false);

            let path = RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                    id: newProduct.id,
                })
            );

            if (product.type === TrainingType.InstructorAssessment) {
                path = RouteUtils.localizePath(
                    RouteUtils.replacePathParams(sitemap.admin.product.edit.assessment.details, {
                        id: newProduct.id,
                    })
                );
            }

            if (product.type === TrainingType.InstructorLedTraining) {
                path = RouteUtils.localizePath(
                    RouteUtils.replacePathParams(sitemap.admin.product.edit.instructorLed.details, {
                        id: newProduct.id,
                    })
                );
            }

            navigate(path);
        } catch {
            ToastManager.error(t("thereWasAnIssueCreatingAProduct"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={!currentProduct ? t("createANewProduct") : t("editName")}
            actions={modalActionArray}
            modalStyle={""}>
            <ProductForm
                associatedProducts={associatedProducts}
                currentProduct={currentProduct}
                product={product}
                setProduct={setProduct}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductModal };

// #endregion Exports
