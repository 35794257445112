import React, { useMemo } from "react";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { InputTypes } from "components/form/enumerations/input-types";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "./checkbox.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum CheckboxSize {
    Medium = "-medium",
    Small = "-small",
}

// #endregion Enums

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface CheckboxProps {
    ariaLabel?: string;
    checked: boolean;
    checkedIcon?: Icons;
    uncheckedIcon?: Icons;
    disabled?: boolean;
    label?: string | React.ReactNode;
    onBlur?: ((event: React.FocusEvent<HTMLButtonElement>) => void) | undefined;
    onChange: (newValue: boolean) => void;
    onFocus?: ((event: React.FocusEvent<HTMLButtonElement>) => void) | undefined;
    onKeyDown?: (e: React.KeyboardEvent<HTMLSpanElement>) => void;
    size?: CheckboxSize;
    tabIndex?: number;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "checkbox";
const DEFAULT_BUTTON_SIZE: CheckboxSize = CheckboxSize.Medium;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const {
        checked,
        checkedIcon,
        uncheckedIcon,
        disabled,
        label,
        onChange,
        onBlur,
        onFocus,
        size,
    } = props;

    const { readOnly } = useReadOnly();

    const isDisabled = useMemo(() => disabled || readOnly, [disabled, readOnly]);

    let className = BASE_CLASS_NAME;
    if (isDisabled) {
        className += " -disabled";
    }

    if (checked) {
        className += " -checked";
    }

    className += ` ${size ?? DEFAULT_BUTTON_SIZE}`;

    const onKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        if (isDisabled) {
            return;
        }

        if (event.key === " ") {
            event.preventDefault();
            onChange(!checked);
        }

        props.onKeyDown?.(event);
    };

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (isDisabled) {
            return;
        }

        onChange(!checked);
    };

    const tabIndex = isDisabled ? -1 : props.tabIndex ?? 0;

    let icon = null;
    if (checkedIcon != null && uncheckedIcon != null) {
        icon = checked ? checkedIcon : uncheckedIcon;
    }

    return (
        <button
            aria-checked={checked}
            aria-disabled={isDisabled}
            className={className}
            onBlur={onBlur}
            onClick={handleOnClick}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            role={InputTypes.Checkbox}
            tabIndex={tabIndex}>
            <React.Fragment>
                {icon != null ? (
                    <span className={`${BASE_CLASS_NAME}__icon-checkmark`}>
                        <Icon type={icon} />
                    </span>
                ) : (
                    <span className={`${BASE_CLASS_NAME}__checkmark`} />
                )}
            </React.Fragment>
            <span>{label}</span>
        </button>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export { Checkbox, CheckboxSize };

// #endregion Export
