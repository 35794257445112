import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-activation-organization-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationOrganizationSectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-organization-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationOrganizationSection: React.FC<EventActivationOrganizationSectionProps> = ({
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("organization")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th className="-name">{t("name")}</th>
                        <th className="-id">{t("id")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={event.id}>
                        <td className="-name">{event.organization?.name}</td>
                        <td className="-id">{event.organization?.id}</td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationOrganizationSection };

// #endregion Exports
