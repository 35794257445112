import { EventChangeLogType } from "models/enumerations/events/event-change-log-type";
import { Event } from "models/interfaces/events/event";

// -------------------------------------------------------------------------------------------------
// #region Contants
// -------------------------------------------------------------------------------------------------

const EVENT_CHANGE_LOG_MAP: Record<keyof Event, EventChangeLogType | null> = {
    additionalAddressDetails: EventChangeLogType.Location,
    additionalDetails: EventChangeLogType.Location,
    additionalRegistrationRequirements: EventChangeLogType.Registration,
    addressLineOne: EventChangeLogType.Location,
    addressLineTwo: EventChangeLogType.Location,
    canceledBy: EventChangeLogType.Canceled,
    canceledById: EventChangeLogType.Canceled,
    canceledOn: EventChangeLogType.Canceled,
    cancellationMessage: EventChangeLogType.Canceled,
    ceuOverride: EventChangeLogType.Schedule,
    city: EventChangeLogType.Location,
    contract: EventChangeLogType.Contract,
    country: EventChangeLogType.Location,
    createdById: null,
    createdOn: null,
    deletedById: null,
    deletedOn: null,
    email: EventChangeLogType.Contact,
    endDateDayOfTheMonth: null,
    endDateMonth: null,
    endDateYear: null,
    eventChecklist: null,
    eventDays: EventChangeLogType.Schedule,
    eventScheduleException: EventChangeLogType.Schedule,
    hasAllDaySessions: EventChangeLogType.Schedule,
    hasBeenInvoiced: null,
    hasNoRegistrationLimit: EventChangeLogType.Registration,
    hideContactInformation: EventChangeLogType.Contact,
    hideLocationInformation: EventChangeLogType.Location,
    id: null,
    instructor: EventChangeLogType.Instructor,
    instructorConfirmed: EventChangeLogType.Instructor,
    instructorId: EventChangeLogType.Instructor,
    instructorLedTrainingType: null,
    invoiceNotes: null,
    isProvidedByNFPA: null,
    linkFreeTrialContractId: null,
    linkFreeTrialToken: null,
    locationName: EventChangeLogType.Location,
    maxRegistrations: EventChangeLogType.Registration,
    meetingUrl: EventChangeLogType.Location,
    name: EventChangeLogType.Name,
    organization: null,
    organizationId: null,
    phoneNumber: EventChangeLogType.Contact,
    product: null,
    productId: null,
    productVersion: null,
    productVersionId: null,
    provider: null,
    providerId: null,
    publishedById: EventChangeLogType.Published,
    publishedOn: EventChangeLogType.Published,
    publishOnNFPACalendar: EventChangeLogType.Registration,
    registrationUrl: EventChangeLogType.Registration,
    startDateDayOfTheMonth: null,
    startDateMonth: null,
    startDateYear: null,
    state: EventChangeLogType.Location,
    status: null,
    timeZone: EventChangeLogType.Location,
    type: null,
    updatedById: null,
    updatedOn: null,
    useDefaultContactInformation: EventChangeLogType.Contact,
    zipCode: EventChangeLogType.Location,
};

// #endregion Contants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EVENT_CHANGE_LOG_MAP };

// #endregion Exports
