import { Record } from "immutable";
import { ReportDownloadRequest } from "models/interfaces/reports/report-download-request";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ReportDownloadRequest = {
    id: undefined,
    reportSourceId: 0,
    payload: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ReportDownloadRequestRecord extends Record(defaultValues) implements ReportDownloadRequest {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ReportDownloadRequest>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for ReportDownloadRequest properties, create a new ReportDownloadRequestRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<ReportDownloadRequest>} values The values to overwrite on this instance.
     * @returns A ReportDownloadRequestRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<ReportDownloadRequest>): ReportDownloadRequestRecord {
        return new ReportDownloadRequestRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportDownloadRequestRecord };

// #endregion Exports
