import React, { useMemo } from "react";
import { NumberUtils } from "utilities/number-utils";
import { useParams } from "react-router-dom";
import { EventAssessmentResults } from "components/assessments/event-assessment-results/event-assessment-results";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ManageEventAssessmentResultsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ManageEventAssessmentResultsPage: React.FC<ManageEventAssessmentResultsPageProps> =
    validatePageAccess(AccessControlKeys.ManageEventAssessmentResultsPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.instructor.teachingAssignments);
        const { id: eventIdParam, assessmentId } = useParams();
        const eventId: number = useMemo(
            () => NumberUtils.parseInt(eventIdParam) ?? 0,
            [eventIdParam]
        );

        const scormPackageAssessmentId: number = useMemo(
            () => NumberUtils.parseInt(assessmentId) ?? 0,
            [assessmentId]
        );

        return (
            <EventAssessmentResults
                eventId={eventId}
                scormPackageAssessmentId={scormPackageAssessmentId}
            />
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ManageEventAssessmentResultsPage };

// #endregion Exports
