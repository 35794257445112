import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import RichTextEditor from "components/rich-text/rich-text-editor";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FileRecord } from "models/view-models/files/file-record";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormFileInputWithButtons } from "components/form/form-file-input-with-buttons/form-file-input-with-buttons";
import { FormSearchSelect } from "components/form/form-search-select/form-search-select";
import { FormSelect } from "components/form/form-select/form-select";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { Product } from "models/interfaces/products/product";
import { ProductImage } from "components/product-image/product-image";
import { ProductRecord } from "models/view-models/products/product-record";
import {
    ProductService,
    UpdateProductPathParams,
} from "utilities/services/products/product-service";
import { RecordUpdater } from "utilities/contexts/use-record-context-factory";
import { RecordUtils } from "andculturecode-javascript-core";
import { SelectOption } from "components/form/inputs/select/select";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import "./product-details-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDetailsFormProps {
    editMode: boolean;
    product: ProductRecord;
    onProductDetailsChange: (product: ProductRecord) => void;
    setDirty: React.Dispatch<React.SetStateAction<boolean>>;
    setProduct: RecordUpdater<Product, ProductRecord>;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-details-form";
const AVAILABLE_FOR_AEN: TranslatedCopy = "availableForAen";
const MAXFILESIZEMB = 5;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDetailsForm: React.FC<ProductDetailsFormProps> = (
    props: ProductDetailsFormProps
): JSX.Element => {
    const { update } = ProductService.useUpdate();
    const { list: listProducts } = ProductService.useList();
    const [associatedProducts, setAssociatedProducts] = useState<ProductRecord[]>([]);
    const isInstructorLed = props.product.type === TrainingType.InstructorLedTraining;
    const isInstructorAssessment = props.product.type === TrainingType.InstructorAssessment;
    const deferSave = props.product.status === ActiveStatus.Active;

    const fetchProductList = useCallback(async (): Promise<void> => {
        try {
            const listProductsResponse = await listProducts({
                activeStatusFilter: [ActiveStatus.Active, ActiveStatus.Draft],
                trainingType: TrainingType.InstructorLedTraining,
            });

            if (
                listProductsResponse?.resultObjects == null ||
                listProductsResponse.results == null ||
                listProductsResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setAssociatedProducts(listProductsResponse.resultObjects);
        } catch {
            ToastManager.error(t("problemLoadingAssociatedProductsList"));
            setAssociatedProducts([]);
        }
    }, [listProducts]);

    useEffect(() => {
        fetchProductList();
    }, [fetchProductList]);

    const updateProduct = (values: Partial<Product>): void => {
        props.onProductDetailsChange(props.product.with(values));
        if (props.editMode) {
            props.setDirty(true);
        }
    };

    const handleProductNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProduct({ name: event.target.value });
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateProduct({ description: event.target.value });
    };

    const handleLongDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateProduct({ catalogDescription: event.target.value });
    };

    const handleErpIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        updateProduct({ erpId: event.target.value });
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProduct(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ language: Number(value) }),
                Language
            )
        );
    };

    const associatedProductsSelectOptions: SelectOption[] = associatedProducts.map(
        (ap): SelectOption => ({
            text: ap.name,
            value: ap.id!.toString(),
        })
    );

    const handleAssociatedProductChange = (value: string): void => {
        const option: SelectOption = associatedProductsSelectOptions.find(
            (so: SelectOption): boolean => so.value === value
        ) ?? {
            text: "",
            value: "",
        };
        updateProduct({ associatedProductId: Number(option.value), associatedProduct: undefined });
    };

    const handleAssociatedProductUpdate = (): void => {
        updateProductDetails(t("associatedProduct"));
    };

    const handleSetAvailableForAEN = (checked: boolean) => {
        updateProduct({ availableForAEN: !props.product.availableForAEN });
    };

    const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        updateProduct(
            EnumUtils.enumToObjectPartial(
                event.target.value,
                (value) => ({ topic: Number(value) }),
                Topic
            )
        );
    };

    const handleSelectFieldUpdate = (e: ChangeEvent<HTMLSelectElement>) => {
        updateProductDetails(e.target.title);
    };

    const handleTextAreaUpdate = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        updateProductDetails(e.target.name);
    };

    const handleCheckBoxUpdate = (e: ChangeEvent<HTMLButtonElement>) => {
        updateProductDetails(t(AVAILABLE_FOR_AEN));
    };

    const updateProductDetails = async (fieldName: string): Promise<boolean> => {
        if (deferSave) return true;
        try {
            const updateProductPathParms: UpdateProductPathParams = {
                id: props.product.id!,
            };
            const updateProductResponse = await update(props.product, updateProductPathParms);

            const updateResult = updateProductResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
            props.setRefresh(true);
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheFieldName", { fieldName: fieldName }));
            props.setProduct(props.product!);
            return false;
        }
        return true;
    };

    function handleCourseObjectivesChange(html: string, text: string): void {
        updateProduct({ courseObjectives: html });
    }

    function handleIntendedAudienceChange(html: string, text: string): void {
        updateProduct({ intendedAudience: html });
    }

    const validateProductImageFile = (file: File): boolean => {
        const fileExtensionValid: boolean =
            file.name.endsWith(".jpg") || file.name.endsWith(".png");
        const fileSizeInKB = file.size / 1024;
        const maxFileSizeInKB = MAXFILESIZEMB * 1024;
        const fileSizeValid = fileSizeInKB < maxFileSizeInKB;

        return fileExtensionValid && fileSizeValid;
    };

    const handleInvalidProductImageFile = (): void => {
        ToastManager.error(
            t("youMustUploadAFileTypeSmallerThanXMB", {
                FileTypes: "JPG,PNG",
                MaxFileSize: MAXFILESIZEMB.toString(),
            })
        );
    };

    const handleSetProductImageFileId = (file: FileRecord) => {
        updateProduct({
            productImageFile: RecordUtils.ensureRecord(file, FileRecord),
            productImageFileId: file.id,
        });
        updateProductDetails(t("productImage"));
    };

    const handleProductImageDelete = () => {
        updateProduct({
            productImageFile: undefined,
            productImageFileId: undefined,
        });
    };

    function handleIntendedAudienceUpdate(): void {
        updateProductDetails(t("intendedAudience"));
    }

    function handleCourseObjectivesUpdate(): void {
        updateProductDetails(t("courseObjectives"));
    }

    function handleOnFileChange(event: ChangeEvent<HTMLInputElement>): void {
        updateProductDetails(t("productImage"));
    }

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                {t("generalInformation")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__section general-info`}>
                <div className={`${CSS_CLASS_NAME}__section__column`}>
                    <FormTextInput
                        ariaLabelledBy={t("productName")}
                        autoFocus={true}
                        formFieldName="productName"
                        id="productName"
                        label={t("productName")}
                        maxLength={200}
                        onBlur={handleTextAreaUpdate}
                        onChange={handleProductNameChange}
                        placeholder={t("productName")}
                        required={true}
                        type={InputTypes.Text}
                        value={props.product.name}
                    />
                    <FormTextArea
                        ariaLabelledBy={t("productDescription")}
                        cssClassName={`${CSS_CLASS_NAME}__product-description`}
                        formFieldName="productDescription"
                        id="productDescription"
                        label={t("productDescription")}
                        maxLength={1000}
                        onBlur={handleTextAreaUpdate}
                        onChange={handleDescriptionChange}
                        placeholder={t("enterProductDescription")}
                        required={true}
                        rows={6}
                        value={props.product.description ?? ""}
                    />
                    <div className={`${CSS_CLASS_NAME}__product_image-upload`}>
                        <ProductImage productImageFileId={props.product.productImageFileId} />
                        <FormFileInputWithButtons
                            buttonText={
                                props.product.productImageFile
                                    ? props.product.productImageFile.fileName
                                    : t("selectAFile")
                            }
                            file={props.product.productImageFile}
                            fileFormat=".jpg,.png"
                            maxFileSizeInMB={5}
                            formFieldName="productimage"
                            id="productimage"
                            label={t("uploadAProductImage")}
                            fileIsValid={validateProductImageFile}
                            onFileInvalid={handleInvalidProductImageFile}
                            onFileDelete={handleProductImageDelete}
                            onFileUpload={handleSetProductImageFileId}
                            onFileChange={handleOnFileChange}
                            placeholder={t("selectAFile")}
                            storageContainer={StorageContainers.FileUpload}
                        />
                    </div>
                    {isInstructorLed && (
                        <FormCheckboxInput
                            checked={props.product.availableForAEN}
                            formFieldName="HeaderCheckbox"
                            id="HeaderCheckbox"
                            label={t("availableForAen")}
                            onBlur={handleCheckBoxUpdate}
                            onChange={handleSetAvailableForAEN}
                            type={InputTypes.Checkbox}
                            value={props.product.availableForAEN}
                        />
                    )}
                </div>
                <div className={`${CSS_CLASS_NAME}__section__column`}>
                    <FormSelect
                        ariaLabelledBy={t("languageSelection")}
                        cssClassName={`${CSS_CLASS_NAME}__language`}
                        formFieldName="languageSelect"
                        id="languageSelect"
                        label={t("language")}
                        onBlur={handleSelectFieldUpdate}
                        onChange={handleLanguageChange}
                        options={EnumUtils.numericEnumToSelectOptions(
                            Language,
                            LanguageDisplayNames
                        )}
                        required={true}
                        value={props.product.language?.toString()}
                    />
                    {(props.product.type === TrainingType.TrainTheTrainer ||
                        props.product.type === TrainingType.InstructorAssessment) && (
                        <FormSearchSelect
                            ariaLabelledBy={t("associatedProductSelection")}
                            defaultText={t("selectAnAssociatedProduct")}
                            disabled={props.product.status === ActiveStatus.Active}
                            formFieldName={t("associatedProduct")}
                            id="associated product select"
                            label={t("associatedProduct")}
                            onBlur={handleAssociatedProductUpdate}
                            onChange={handleAssociatedProductChange}
                            options={associatedProductsSelectOptions}
                            required={true}
                            selectedOption={{
                                text:
                                    associatedProducts.find(
                                        (p) => p.id === props.product.associatedProductId
                                    )?.name ?? "",
                                value: props.product.associatedProductId?.toString() ?? "",
                            }}
                        />
                    )}
                    {(isInstructorLed || isInstructorAssessment) && (
                        <FormSelect
                            ariaLabelledBy={t("topicColon")}
                            cssClassName={`${CSS_CLASS_NAME}__topic`}
                            formFieldName="topicSelect"
                            id="topicSelect"
                            label={t("topic")}
                            onBlur={handleSelectFieldUpdate}
                            onChange={handleTopicChange}
                            options={EnumUtils.numericEnumToSelectOptions(Topic, TopicDisplayNames)}
                            required={true}
                            value={props.product.topic?.toString()}
                        />
                    )}
                </div>
            </div>

            {isInstructorLed && props.product.availableForAEN && (
                <>
                    <div className={`${CSS_CLASS_NAME}__divider`}></div>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                        Catalog Information
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section catalog-info`}>
                        <FormTextArea
                            ariaLabelledBy={"Long Description"}
                            cssClassName={`${CSS_CLASS_NAME}__long-description`}
                            formFieldName="longDescription"
                            id="longDescription"
                            label={t("longDescription")}
                            maxLength={1000}
                            onBlur={handleTextAreaUpdate}
                            onChange={handleLongDescriptionChange}
                            placeholder={t("enterLongDescription")}
                            rows={6}
                            value={props.product.catalogDescription ?? ""}
                        />
                        <RichTextEditor
                            label={t("courseObjectives")}
                            value={props.product.courseObjectives}
                            placeholder=""
                            onChange={handleCourseObjectivesChange}
                            onBlur={handleCourseObjectivesUpdate}
                        />
                        <RichTextEditor
                            label={t("intendedAudience")}
                            value={props.product.intendedAudience}
                            placeholder=""
                            onChange={handleIntendedAudienceChange}
                            onBlur={handleIntendedAudienceUpdate}
                        />
                    </div>
                </>
            )}
            <div className={`${CSS_CLASS_NAME}__divider`}></div>
            <Heading priority={HeadingPriority.H2} size={HeadingSize.XXSmall}>
                {t("ids")}
            </Heading>
            <div className={`${CSS_CLASS_NAME}__section ids`}>
                <div className={`${CSS_CLASS_NAME}__section__column`}>
                    <div className={`${CSS_CLASS_NAME}__id-wrapper`}>
                        <FormTextInput
                            ariaLabelledBy={t("productId")}
                            disabled
                            formFieldName="productId"
                            id="ProductId"
                            label={t("productId")}
                            placeholder={t("productId")}
                            value={props.product.id}
                        />
                        <p className={`${CSS_CLASS_NAME}__input-description`}>
                            {t("productIdIsAutomaticallyGeneratedAndNotEditable")}
                        </p>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__id-wrapper`}>
                        <FormTextInput
                            ariaLabelledBy={t("erpId")}
                            formFieldName="erpId"
                            id="erpId"
                            label={t("erpId")}
                            onBlur={handleTextAreaUpdate}
                            onChange={handleErpIdChange}
                            placeholder=""
                            required={false}
                            type={InputTypes.Text}
                            value={props.product.erpId}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDetailsForm };

// #endregion Exports
