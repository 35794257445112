import React, { useMemo } from "react";
import { Button, ButtonSize, ButtonStyle } from "../../buttons/button/button";
import { HeaderBanner, HeaderBannerBadge } from "../../header-banner/header-banner";
import { UserRecord } from "models/view-models/users/user-record";
import EmulateUserButton from "pages/admin/support/emulate-user-button";
import { RoleType } from "models/enumerations/users/role-type";
import { UserRoleRecord } from "models/view-models/user-roles/user-role-record";
import { t } from "utilities/localization/t";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import { BadgeStyle } from "components/badges/badge/badge";
import { StringUtils } from "utilities/string-utils";
import "./user-detail-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserDetailHeaderProps {
    user: UserRecord;
    onEditUserClick?: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-detail-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserDetailHeader: React.FC<React.PropsWithChildren<UserDetailHeaderProps>> = ({
    user,
    onEditUserClick,
    children,
}): JSX.Element => {
    const { useAzureB2CForSSO } = useFeatureFlags();

    const badges: HeaderBannerBadge[] = useMemo(() => {
        let badges: HeaderBannerBadge[] =
            user.userRoles?.map((ur) => {
                return {
                    text: ur.role?.name ?? t("notApplicableShort"),
                    style: BadgeStyle.Transparent,
                };
            }) ?? [];

        if (user.invitePending()) {
            badges?.push({ text: t("invitePending"), style: BadgeStyle.Transparent });
        }
        return badges;
    }, [user]);

    const checkForNfpaRoles = (userRoles: UserRoleRecord[]): boolean => {
        return userRoles.reduce((prev, curr) => {
            return (
                prev ||
                curr.role?.roleType === RoleType.NfpaAdministrator ||
                curr.role?.roleType === RoleType.NfpaOperator ||
                curr.role?.roleType === RoleType.NfpaSupport
            );
        }, false);
    };
    const hasNfpaRole = checkForNfpaRoles(user.userRoles ?? []);

    return (
        <>
            <HeaderBanner
                cssClassName={CSS_CLASS_NAME}
                badges={badges}
                title={StringUtils.valueOrDefault(user.getFirstAndLastName())}>
                {useAzureB2CForSSO ? (
                    <EmulateUserButton guestId={user.id} hasNfpaRole={hasNfpaRole} />
                ) : null}
                <Button
                    text={t("editUser")}
                    onClick={onEditUserClick}
                    size={ButtonSize.Medium}
                    style={ButtonStyle.TertiaryAlt}
                />
                {children}
            </HeaderBanner>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserDetailHeader };

// #endregion Exports
