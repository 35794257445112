import React, { useCallback, useMemo } from "react";
import { CollectionUtils } from "andculturecode-javascript-core";
import { TabBar, TabDetails } from "components/tabs/tab-bars/tab-bar/tab-bar";
import { TabBehavioralProps, TabProps } from "components/tabs/tabs/tab/tab";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useLocation } from "react-router-dom";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface TabbedLayoutItem<T extends TabProps> {
    matchPathOnStartsWith?: boolean;
    path: string;
    tabComponent: React.FC<T>;
    tabDisplayDetails: Omit<T, keyof TabBehavioralProps>;
}

interface TabbedLayoutProps {
    cssClassName?: string;
    tabs: TabbedLayoutItem<any>[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const TabbedLayout: React.FC<TabbedLayoutProps> = (
    props: TabbedLayoutProps
): JSX.Element | null => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const tabIsActive = useCallback(
        (tab: TabbedLayoutItem<any>): boolean =>
            tab.matchPathOnStartsWith === true
                ? pathname.startsWith(tab.path)
                : tab.path === pathname,
        [pathname]
    );

    const tabDetails: TabDetails<any>[] = useMemo(
        () =>
            props.tabs.map(
                (tab: TabbedLayoutItem<any>): TabDetails<any> => ({
                    component: tab.tabComponent,
                    props: {
                        ...tab.tabDisplayDetails,
                        isActive: tabIsActive(tab),
                        onClick: () => {
                            navigate(tab.path);
                        },
                    },
                })
            ),
        [navigate, props.tabs, tabIsActive]
    );

    if (CollectionUtils.isEmpty(props.tabs)) {
        return null;
    }

    return <TabBar cssClassName={props.cssClassName} tabs={tabDetails} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TabbedLayout };

// #endregion Exports
