import "tippy.js/dist/svg-arrow.css";
import React, { ReactElement } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { StringUtils } from "utilities/string-utils";
import { followCursor, roundArrow } from "tippy.js";
import "./tooltip.scss";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

type TippyTimingProps = number | [number | null, number | null] | undefined;

export interface TooltipTiming {
    hide?: number;
    show?: number;
}

export interface TooltipOffset {
    distance: number;
    skidding: number;
}

export interface TooltipProps {
    /**
     * Required. The element that triggers the tooltip on hovering.
     * Surround the trigger with the `<Tooltip>` component.
     */
    children: ReactElement;
    /**
     * The content of the tooltip itself.
     */
    content: React.ReactChild | React.ReactChild[];
    cssClassName?: string;
    /**
     * Delay once a trigger event is fired before a tooltip shows or hides.
     */
    delayInMs?: number | TooltipTiming;
    /**
     * Disable the tooltip. For example, if you have overflowing text,
     * and you only want to show if the text is actually truncated with ...
     * you can get a ref to the HTML element and set
     * disabled={elRef.offsetWidth < elRef.scrollWidth}
     * @see resource-subtext-label.tsx for an example of how to achieve this behavior
     */
    disabled?: boolean;
    /**
     * Duration of transition animation.
     */
    durationInMs?: number | TooltipTiming;
    /**
     * Determines if the tooltip follows the user's mouse cursor. False by default.
     */
    followCursor?: boolean | FollowCursorPlacement;

    /**
     * Determines if the tippy should hide if a mousedown event was fired outside of it
     * For click-triggered tippies, using false will prevent the tippy from ever hiding once it is showing.
     * To prevent clicks outside of the tippy from hiding it but still allow it to be toggled, use the string "toggle".
     */
    hideOnClick?: boolean | "toggle";
    /**
     * Whether the tooltip is "interactive". If true, the tooltip will not close
     * when hovering/clicking the tooltip content.
     * @default false
     */
    interactive?: boolean;
    placement?: TooltipPlacement;
    style?: TooltipStyle;
    /**
     * Offset from the reference element.
     *
     * `Distance` defines how far away from the reference element the tooltip should be placed.
     * `Skidding` defines how the tooltip placement should be moved along the reference element.
     */
    offsetInPx?: TooltipOffset;
    /**
     * Manually control tooltip visibility.
     * Useful for debugging styles.
     */
    visible?: boolean;
}

export enum FollowCursorPlacement {
    Horizontal = "horizontal",
    Initial = "initial",
    Vertical = "vertical",
}

export enum TooltipPlacement {
    AutoStart = "auto-start",
    Bottom = "bottom",
    Left = "left",
    Right = "right",
    Top = "top",
}

export enum TooltipStyle {
    Primary = "-primary",
    Secondary = "-secondary",
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "tooltip";
const DEFAULT_TOOLTIP_STYLE: TooltipStyle = TooltipStyle.Primary;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
    const style: TooltipStyle = props.style ?? DEFAULT_TOOLTIP_STYLE;
    const classNames: string[] = [CSS_CLASS_NAME, style];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    const getContent = () => (
        <React.Fragment>
            {props.content}
            <div className={`${CSS_CLASS_NAME}__arrow`} />
        </React.Fragment>
    );

    if (props.disabled === true) {
        return props.children;
    }

    const builtProps = buildTippyProps(props);

    return (
        <Tippy
            {...builtProps}
            arrow={roundArrow}
            className={classNames.join(" ")}
            content={getContent()}
            followCursor={props.followCursor}
            hideOnClick={props.hideOnClick ?? false}
            interactive={props.interactive}
            placement={props.placement ?? TooltipPlacement.Top}
            plugins={[followCursor]}
            // trigger={useMobileBehavior ? "click" : "mouseenter focus"}
            visible={props.visible}>
            {props.children}
        </Tippy>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Build Tippy.js props object for Tippy props that lose defaults when `undefined` is passed
 * as a prop.  Props are only set if the `Tooltip` prop has a value.
 */
const buildTippyProps = (props: TooltipProps): Partial<TippyProps> => {
    const tippyProps = {} as Partial<TippyProps>;
    const { delayInMs, durationInMs, offsetInPx: offset } = props;

    if (delayInMs != null) {
        tippyProps.delay = getTimingProps(delayInMs);
    }

    if (durationInMs != null) {
        tippyProps.duration = getTimingProps(durationInMs);
    }

    if (offset != null) {
        tippyProps.offset = getOffsetProps(offset);
    }

    return tippyProps;
};

/**
 * Transforms the `Tooltip` `offset` prop object into the `[skidding, distance]` format
 * used by Tippy.
 */
const getOffsetProps = (offset: TooltipOffset): [number, number] => {
    return [offset.skidding, offset.distance];
};

/**
 * Transforms `Tooltip` timing props (ex. `delay` and `duration`) into the `number` or
 * `[show, hide]` format used by Tippy.
 */
const getTimingProps = (timing: number | TooltipTiming): TippyTimingProps => {
    if (typeof timing === "number") {
        return timing;
    }

    return [timing.show ?? null, timing.hide ?? null];
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default Tooltip;

// #endregion Exports
