import { ProviderRecord } from "models/view-models/providers/provider-record";
import { ProviderService } from "utilities/services/providers/provider-service";
import { useEffect, useState } from "react";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingProviders";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseProvidersHook {
    error?: Error;
    isLoading: boolean;
    providers: ProviderRecord[];
    rowCount: number;
}

interface UseProvidersHookOptions {
    onError?: (error: Error) => void;
    isActive?: boolean;
    searchCriteriaReady?: boolean;
    searchText?: string;
    name?: string;
    netsuiteId?: string;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useProviders({
    onError,
    isActive,
    name,
    netsuiteId,
    searchText,
    skip,
    take,
    searchCriteriaReady = true,
}: UseProvidersHookOptions): UseProvidersHook {
    const { list } = ProviderService.useList();
    const [providers, setProviders] = useState<ProviderRecord[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!searchCriteriaReady) {
            setProviders([]);
            setIsLoading(false);
            return;
        }

        try {
            (async function getProviders() {
                setIsLoading(true);
                const providersResponse = await list({
                    searchText: searchText,
                    isActive: isActive,
                    name: name,
                    netsuiteId: netsuiteId,
                    skip: skip,
                    take: take,
                });
                setIsLoading(false);

                if (
                    providersResponse?.resultObjects == null ||
                    providersResponse?.results == null ||
                    providersResponse.results.hasErrors()
                ) {
                    setError(t(ERROR_LOADING));
                    return;
                }

                setProviders(providersResponse.resultObjects);
                setRowCount(providersResponse.rowCount);
            })();
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [isActive, list, name, netsuiteId, searchCriteriaReady, searchText, setError, skip, take]);

    return {
        error,
        isLoading,
        providers,
        rowCount,
    };
}

// #endregion Hook
