import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { QuestionRecord } from "models/view-models/questions/question-record";
import { Record } from "immutable";
import { ScormPackageAssessment } from "models/interfaces/scorm-packages/scorm-package-assessment";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ScormPackageAssessment = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    enrollmentScormPackageAssessment: undefined,
    id: undefined,
    questions: undefined,
    required: undefined,
    scormPackageId: undefined,
    scormPackage: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ScormPackageAssessmentRecord extends Record(defaultValues) implements ScormPackageAssessment {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ScormPackageAssessment>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params?.questions)) {
            params.questions = RecordUtils.ensureRecords(params.questions, QuestionRecord);
        }

        if (params.enrollmentScormPackageAssessment != null) {
            params.enrollmentScormPackageAssessment = RecordUtils.ensureRecord(
                params.enrollmentScormPackageAssessment,
                EnrollmentScormPackageAssessmentRecord
            );
        }

        if (params.scormPackage != null) {
            params.scormPackage = RecordUtils.ensureRecord(params.scormPackage, ScormPackageRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public isLockedforEnrollment(): boolean {
        return this.enrollmentScormPackageAssessment?.locked ?? false;
    }

    public isRequiredforEnrollment(): boolean {
        const { optional } = this.enrollmentScormPackageAssessment ?? {};

        if (optional != null) {
            return !optional;
        }

        return false;
    }

    public with(values: Partial<ScormPackageAssessment>): ScormPackageAssessmentRecord {
        return new ScormPackageAssessmentRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageAssessmentRecord };

// #endregion Exports
