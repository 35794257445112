import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { Permission } from "utilities/enumerations/authorization/permission";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ReviewerAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,
    [AccessControlKeys.AdminAenInstructorApplications]: Permission.Allow,
    [AccessControlKeys.AdminAenApplicationFiles]: Permission.Allow,
    [AccessControlKeys.AdminAenApplicationLogs]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReviewerAuthorizationRules };

// #endregion Exports
