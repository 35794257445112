// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EvaluationQuestionType {
    FreeResponse = 0,
    Likert = 1,
    MultipleChoice = 2,
    MultiSelect = 3,
    NPS = 4,
}

// #endregion Enum

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { EvaluationQuestionType };

// #endregion Exports
