import { AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { DataTable } from "components/tables/data-table/data-table";
import { FileUtils } from "utilities/files/file-utils";
import { InstructorApplicationFileRecord } from "models/view-models/aen-applications/instructor-application-file-record";
import { ProviderApplicationFileRecord } from "models/view-models/aen-applications/provider-application-file-record";
import { t } from "utilities/localization/t";
import "./aen-application-file-list.scss";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AenApplicationsFileListProps {
    aenApplicationFiles?: InstructorApplicationFileRecord[] | ProviderApplicationFileRecord[];
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "aen-application-file-list";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AenApplicationFileList: React.FC<AenApplicationsFileListProps> = ({
    aenApplicationFiles,
}) => {
    return (
        <DataTable cssClassName={CSS_CLASS_NAME}>
            <thead>
                <tr>
                    <th className="file-name" aria-labelledby="file-name">
                        {t("fileName")}
                    </th>
                    <th className="description" aria-labelledby="description">
                        {t("description")}
                    </th>
                    <th className="file-type" aria-labelledby="file-type">
                        {t("fileType")}
                    </th>
                    <th className="uploaded-by" aria-labelledby="uploaded-by">
                        {t("uploadedBy")}
                    </th>
                    <th className="upload-date" aria-labelledby="upload-date">
                        {t("uploadDate")}
                    </th>
                    <th className="action" aria-labelledby="context-menu-button">
                        <span className="sr-only">{t("action")}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {aenApplicationFiles?.map((applicationFile) => (
                    <tr key={applicationFile.id}>
                        <td>{applicationFile.file?.fileName}</td>
                        <td>{applicationFile.description}</td>
                        <td>{applicationFile.getFileTypeDisplayName()}</td>
                        <td>{applicationFile?.getFirstAndLastName()}</td>
                        <td>{applicationFile?.getUploadDate()}</td>
                        <td className="action">
                            <ContextMenu>
                                <ContextMenuAnchor
                                    hrefPath={FileUtils.fileUrl(applicationFile.fileId)}
                                    pathType={AnchorPathType.External}
                                    displayName={t("download")}
                                />
                            </ContextMenu>
                        </td>
                    </tr>
                ))}
            </tbody>
        </DataTable>
    );
};
// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AenApplicationFileList };

// #endregion Exports
