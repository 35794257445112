import React from "react";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import { EventSession } from "models/interfaces/events/event-session";
import { EventSessionRecord } from "models/view-models/events/event-session-record";
import { FormTimePicker } from "components/form/form-time-picker/form-time-picker";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "components/icons/constants/icon-sizes";
import "./event-session-form.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventSessionFormProps {
    eventSession: EventSessionRecord;
    eventSessionIndex: number;
    handleEventSessionDelete: (eventSessionIndex: number, eventSession: EventSessionRecord) => void;
    onEventSessionChange: (eventSessionIndex: number, eventSession: EventSessionRecord) => void;
    readOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-session-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventSessionForm: React.FC<EventSessionFormProps> = (props: EventSessionFormProps) => {
    const updateEventSession = (values: Partial<EventSession>): void => {
        props.onEventSessionChange(props.eventSessionIndex, props.eventSession.with(values));
    };

    const handleStartTimeChange = (date?: Date): void => {
        updateEventSession({
            startHour: date?.getHours(),
            startMinute: date?.getMinutes(),
        });
    };

    const handleEndTimeChange = (date?: Date): void => {
        updateEventSession({
            endHour: date?.getHours(),
            endMinute: date?.getMinutes(),
        });
    };

    return (
        <>
            <div className={CSS_CLASS_NAME}>
                <FormTimePicker
                    cssClassName={`${CSS_CLASS_NAME}__session-start-time`}
                    readOnly={props.readOnly ?? false}
                    formFieldName="startTime"
                    label={t("startTime")}
                    required={true}
                    selectedTime={props.eventSession.getTime(props.eventSession.startTime())}
                    onChange={handleStartTimeChange}
                />
                <FormTimePicker
                    cssClassName={`${CSS_CLASS_NAME}__session-end-time`}
                    readOnly={props.readOnly ?? false}
                    formFieldName="endTime"
                    label={t("endTime")}
                    required={true}
                    selectedTime={props.eventSession.getTime(props.eventSession.endTime())}
                    onChange={handleEndTimeChange}
                />
                <p className={`${CSS_CLASS_NAME}__session-credit-hours`}>{t("hours")}</p>
                <div className={`${CSS_CLASS_NAME}__delete-session`}>
                    <ButtonIcon
                        ariaLabelledBy={t("deleteSession")}
                        disabled={props.readOnly}
                        onClick={() =>
                            props.handleEventSessionDelete(
                                props.eventSessionIndex,
                                props.eventSession
                            )
                        }
                        buttonStyle={ButtonStyle.Secondary}
                        iconType={Icons.Trashcan}
                        iconSize={IconSizes.Large}
                    />
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventSessionForm };

// #endregion Exports
