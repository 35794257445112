import axios from "axios";
import { ProviderApplicationFileRecord } from "models/view-models/aen-applications/provider-application-file-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "aenapplication/providers/:providerApplicationId/files";
const providerFilesResourceType: typeof ProviderApplicationFileRecord =
    ProviderApplicationFileRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateProviderApplicationFileService = (
    providerApplicationFile: ProviderApplicationFileRecord,
    pathParams: ProviderApplicationFilePathParams
) => Promise<ServiceResponse<ProviderApplicationFileRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ProviderApplicationFilePathParams {
    providerApplicationId: number;
}

export interface ListProviderApplicationFilesQueryParams {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ProviderApplicationFileService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateProviderApplicationFileService } => {
        const { cancellablePromise } = useCancellablePromise();
        const serviceCreate: CreateProviderApplicationFileService = (
            providerApplicationFile: ProviderApplicationFileRecord,
            pathParams: ProviderApplicationFilePathParams
        ): Promise<ServiceResponse<ProviderApplicationFileRecord>> => {
            return axios
                .post(
                    baseEndpoint.replace(
                        ":providerApplicationId",
                        pathParams.providerApplicationId.toString()
                    ),
                    providerApplicationFile
                )
                .then((r) => ServiceUtils.mapAxiosResponse(providerFilesResourceType, r));
        };
        function create(
            providerApplicationFile: ProviderApplicationFileRecord,
            pathParams: ProviderApplicationFilePathParams
        ): Promise<ServiceResponse<ProviderApplicationFileRecord>> {
            return cancellablePromise(
                serviceCreate(providerApplicationFile, pathParams)
            ) as Promise<ServiceResponse<ProviderApplicationFileRecord>>;
        }
        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useNestedList<
        ProviderApplicationFileRecord,
        ProviderApplicationFilePathParams,
        ListProviderApplicationFilesQueryParams
    >(providerFilesResourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderApplicationFileService };

// #endregion Exports
