import React from "react";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import ReportSection from "../report-section/report-section";
import { FormRadioCardList, RadioCard, RadioCardGridColumns } from "components/form/form-radio-card-list/form-radio-card-list";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { INVOICE_STATUS_TYPES, INVOICE_STATUS_TYPES_DESCRIPTIONS } from "./report-invoice-status-constants";
import { RadioCardStyle } from "components/form/form-radio-card-list-with-description/form-radio-card-list-with-description";
import { t } from "utilities/localization/t";

interface ReportInvoiceStatusSelectionProps {
    isRequired?: boolean;
    invoiceStatusType: string;
    onInvoiceStatusTypeChanged: (nvoiceStatusType: string) => void;
}

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const INVOICE_STATUS_TYPES_CARDS = Object.values(INVOICE_STATUS_TYPES).map(
    (key): RadioCard => ({
        icon: Icons.Reporting,
        text: INVOICE_STATUS_TYPES_DESCRIPTIONS[key],
        value: key,
    })
);

// #endregion Constants

const ReportInvoiceStatusSelection: React.FC<ReportInvoiceStatusSelectionProps> = ({
    isRequired = true,
    invoiceStatusType = "",
    onInvoiceStatusTypeChanged
}) => {
    return (
        <>
            <ReportSection
                header={
                    <div className="report-section">
                        <Paragraph size={ParagraphSize.Large}>
                            {t("forInvoiceStatus")}
                            {isRequired ? "*" : ""}
                        </Paragraph>
                        <FormRadioCardList
                            id="invoiceStatusType"
                            formFieldName="invoiceStatusType"
                            label={t("invoiceStatusType")}
                            labelScreenReaderOnly={true}
                            iconSize={IconSizes.Base}
                            onChange={onInvoiceStatusTypeChanged}
                            radioCards={INVOICE_STATUS_TYPES_CARDS}
                            required={true}
                            style={RadioCardStyle.Horizontal}
                            value={invoiceStatusType}
                            columns={RadioCardGridColumns.Three}
                        />
                    </div>
                }>
            </ReportSection>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default ReportInvoiceStatusSelection;

// #endregion Exports