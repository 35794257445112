import { Course } from "models/interfaces/courses/course";
import { CourseRecord } from "models/view-models/courses/course-record";
import {
    RecordUpdater,
    UseRecordContextFactory,
} from "utilities/contexts/use-record-context-factory";

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

export type CourseRecordUpdater = RecordUpdater<Course, CourseRecord>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const {
    RecordContext: CourseContext,
    useRecord: useCourse,
} = UseRecordContextFactory.build<Course, CourseRecord>(CourseRecord);

export { CourseContext, useCourse };

// #endregion Exports
