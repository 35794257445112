import React, { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Transition } from "@headlessui/react";
import { AdminLayoutHeaderNavigationPanelContent } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-navigation-panel/admin-layout-header-navigation-panel-content/admin-layout-header-navigation-panel-content";
import "./admin-layout-header-navigation-panel.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderNavigationPanelProps {
    isOpen: boolean;
    navigateTo: (path: string, external?: boolean) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-navigation-panel";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderNavigationPanel: React.FC<AdminLayoutHeaderNavigationPanelProps> = (
    props: AdminLayoutHeaderNavigationPanelProps
): JSX.Element | null => {
    const { record: globalState } = useGlobalState();
    const currentRoleType = useMemo(
        () => globalState.currentIdentity?.role?.roleType,
        [globalState.currentIdentity?.role?.roleType]
    );
    const isOpen: boolean = useMemo(
        (): boolean => props.isOpen && currentRoleType != null,
        [currentRoleType, props.isOpen]
    );

    let translateX;
    if (document.documentElement.dir == "rtl") {
        translateX = "translate-x-[-100%]";
    } else {
        translateX = "translate-x-full";
    }

    return (
        <Transition.Root show={isOpen} as="div" className={CSS_CLASS_NAME}>
            <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className={`${CSS_CLASS_NAME}__overlay`} />
            </Transition.Child>
            <Transition.Child
                as="div"
                enter="transform transition ease-out duration-300"
                enterFrom={translateX}
                enterTo="translate-x-0"
                leave="transform transition ease-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo={translateX}>
                <AdminLayoutHeaderNavigationPanelContent
                    isOpen={isOpen}
                    navigateTo={props.navigateTo}
                />
            </Transition.Child>
        </Transition.Root>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderNavigationPanel };

// #endregion Exports
