import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import {
    PatchProductServiceParams,
    PatchRoutes,
    ProductService,
} from "utilities/services/products/product-service";
import { ProductActivationAchievementsSection } from "./product-activation-achievements-section/product-activation-achievements-section";
import { ProductActivationContentSection } from "./product-activation-content-section/product-activation-content-section";
import { ProductActivationCoursesSection } from "./product-activation-courses-section/product-activation-courses-section";
import { ProductActivationDetailsSection } from "./product-activation-details-section/product-activation-details-section";
import { ProductActivationEvaluationSection } from "./product-activation-evaluation-section/product-activation-evaluation-section";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductVersionRecord } from "models/view-models/products/product-version-record";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./product-activation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationModalProps {
    open: boolean;
    product: ProductRecord;
    productVersion: ProductVersionRecord;
    productContents?: ProductContentRecord[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setProduct: React.Dispatch<React.SetStateAction<ProductRecord>>;
    setProductVersionZero: React.Dispatch<React.SetStateAction<ProductVersionRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationModal: React.FC<ProductActivationModalProps> = ({
    open,
    product,
    productVersion,
    productContents,
    setOpen,
    setProduct,
    setProductVersionZero,
}): JSX.Element => {
    const navigate = useNavigate();
    const { patch: productPatch } = ProductService.usePatch();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("activateProduct"),
            onClick: () => handleActivatingProduct(),
            style: ButtonStyle.Primary,
        },
    ];

    const handleActivatingProduct = () => {
        setOpen(false);
        activateProduct(product.id!);
        let path = RouteUtils.localizePath(
            RouteUtils.replacePathParams(sitemap.admin.product.edit.online.details, {
                id: product.id,
            })
        );
        if (product.type === TrainingType.InstructorLedTraining) {
            path = RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.product.edit.instructorLed.details, {
                    id: product.id,
                })
            );
        }
        navigate(path);
    };

    const activateProduct = async (id: number): Promise<boolean> => {
        if (!id) {
            return false;
        }

        try {
            const patchProductServiceParams: PatchProductServiceParams = {
                productId: id,
                patchRoute: PatchRoutes.Activate,
            };

            const patchProductResponse = await productPatch(patchProductServiceParams);

            const patchProductResult = patchProductResponse?.result;
            if (patchProductResult?.resultObject == null || patchProductResult.hasErrors()) {
                alert(t("activatingProductFailed"));
            }
            setProduct(product.with({ status: ActiveStatus.Active }));
            setProductVersionZero(productVersion.with({ status: ActiveStatus.Active }));
            ToastManager.success(t("productIsNowActive"));
        } catch {
            ToastManager.error(t("failedToActivateProduct"));
            return false;
        }
        return true;
    };

    const onModalClose = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("activationSummary")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <ProductActivationDetailsSection product={product} />
                <ProductActivationCoursesSection
                    product={product}
                    productVersion={productVersion}
                />
                <ProductActivationContentSection
                    product={product}
                    productContents={productContents}
                    productVersion={productVersion}
                />
                {(product.type === TrainingType.OnlineLearning ||
                    product.type === TrainingType.InstructorLedTraining) && (
                    <>
                        <ProductActivationAchievementsSection
                            product={product}
                            productVersion={productVersion}
                        />
                        <ProductActivationEvaluationSection product={product} />
                    </>
                )}
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationModal };

// #endregion Exports
