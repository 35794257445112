import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { ContentRecord } from "models/view-models/contents/content-record";
import { Modal, ModalAction } from "components/modal/modal";
import { ProductContentRecord } from "models/view-models/products/product-content-record";
import { ProductCreateContentForm } from "./product-create-content-form/product-create-content-form";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "andculturecode-javascript-core";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { ActiveStatus } from "models/enumerations/active-status/active-status";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCreateContentModalProps {
    content?: ContentRecord;
    editing?: boolean;
    product: ProductRecord;
    productContent?: ProductContentRecord;
    productId?: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveContent?: (
        content?: ContentRecord,
        productContent?: ProductContentRecord
    ) => Promise<boolean>;
    handleContentDetailsChange?: (
        content: ContentRecord,
        productContent: ProductContentRecord
    ) => Promise<boolean>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCreateContentModal: React.FC<ProductCreateContentModalProps> = (
    props: ProductCreateContentModalProps
): JSX.Element => {
    let initialContent = useMemo(() => props.content ?? new ContentRecord(), [props.content]);
    let initialProductContent = useMemo(
        () => props.productContent ?? new ProductContentRecord(),
        [props.productContent]
    );
    const [productContent, setProductContent] = useState(initialProductContent);
    const [content, setContent] = useState(initialContent);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [disableAddButton, setDisableAddButton] = useState(false);
    const deferSave = props.product.status === ActiveStatus.Active;

    useEffect(() => {
        setProductContent(initialProductContent);
        setContent(initialContent);
    }, [initialContent, initialProductContent]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: props.productContent ? t("updateContent") : t("addContent"),
            disabled:
                disableAddButton ||
                content?.fileId === undefined ||
                !StringUtils.hasValue(content?.name) ||
                !StringUtils.hasValue(content?.description) ||
                (props.product.type === TrainingType.InstructorLedTraining &&
                    productContent.access === undefined),
            onClick: () => onContentAdd(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setProductContent(initialProductContent);
        setContent(initialContent);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onContentAdd = async (): Promise<void> => {
        if (disableAddButton) {
            return;
        }
        setDisableAddButton(true);
        if (props.editing) {
            const result = await props.handleContentDetailsChange!(content!, productContent);
            if (result) {
                setDirty(false);
                setProductContent(initialProductContent);
                setContent(initialContent);
                props.setOpen(false);
                setDisableAddButton(false);
            }
        } else {
            //You don't want to call this in edit mode as it will not exist and throws an error
            const result = await props.handleSaveContent!(content, productContent);
            if (result) {
                setDirty(false);
                setProductContent(initialProductContent);
                setContent(initialContent);
                props.setOpen(false);
                setDisableAddButton(false);
            }
        }
        return;
    };

    const onContentChanged = (content: ContentRecord): void => {
        setDirty(true);
        setContent(content);
    };

    const onProductContentChanged = (productContent: ProductContentRecord): void => {
        setDirty(true);
        setProductContent(productContent);
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={props.editing ? t("updateContent") : t("addContent")}
            actions={modalActionArray}
            modalStyle={""}>
            <ProductCreateContentForm
                content={content}
                productContent={productContent}
                editing={props.editing}
                onContentChanged={onContentChanged}
                onProductContentChanged={onProductContentChanged}
                product={props.product}
            />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCreateContentModal };

// #endregion Exports
