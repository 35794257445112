import React, { useState, useMemo, useEffect, useCallback } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { LearnerAssessmentSummaryDetails } from "components/assessments/learner-assessment-summary/learner-assessment-summary-details/learner-assessment-summary-details";
import { NumberUtils } from "utilities/number-utils";
import { RegistrationRecord } from "models/view-models/registrations/registration-record";
import { RegistrationService } from "utilities/services/registrations/registration-service";
import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { StringUtils } from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { UserRecord } from "models/view-models/users/user-record";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useAttempts } from "utilities/hooks/models/attempts/use-attempts";
import { useEnrollmentUnitCourse } from "utilities/hooks/models/enrollments/use-enrollment-unit-course";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useParams } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./learner-online-learning-assessment-results-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningAssessmentResultsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "learner-online-learning-assessment-results-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningAssessmentResultsPage: React.FC<LearnerOnlineLearningAssessmentResultsPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage)(
        (): JSX.Element => {
            useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
            const navigate = useNavigate();
            const { record: GlobalState } = useGlobalState();
            const loggedInUser = GlobalState?.currentIdentity?.user;
            const userRole = useMemo(
                () => GlobalState?.currentIdentity?.role?.roleType,
                [GlobalState]
            );
            const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
            const { get: getRegistration } = RegistrationService.useGet();

            const {
                productId: productIdParam,
                enrollmentId: enrollmentIdParam,
                assessmentId: assessmentIdParam,
                registrationId: registrationIdParam,
            } = useParams();

            const productId = useMemo(
                () => NumberUtils.parseInt(productIdParam) ?? 0,
                [productIdParam]
            );
            const enrollmentId = useMemo(
                () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
                [enrollmentIdParam]
            );
            const assessmentId = useMemo(
                () => NumberUtils.parseInt(assessmentIdParam) ?? 0,
                [assessmentIdParam]
            );
            const registrationId = useMemo(
                () => NumberUtils.parseInt(registrationIdParam) ?? 0,
                [registrationIdParam]
            );

            const userIsAuthorized = useCallback(
                (loggedInUser?: UserRecord, registration?: RegistrationRecord): boolean => {
                    if (
                        loggedInUser === undefined ||
                        registration === undefined ||
                        loggedInUser?.id !== registration?.userId
                    ) {
                        return false;
                    }
                    return true;
                },
                []
            );

            const { enrollmentUnitCourse } = useEnrollmentUnitCourse({
                enrollmentId: enrollmentId,
                scormPackageAssessmentId: assessmentId,
            });

            const isRequired = useMemo(
                () => !enrollmentUnitCourse?.optional,
                [enrollmentUnitCourse?.optional]
            );

            const unitId = useMemo(
                () => enrollmentUnitCourse?.unitCourse?.unitId,
                [enrollmentUnitCourse?.unitCourse?.unitId]
            );

            const courseId = useMemo(
                () => enrollmentUnitCourse?.unitCourse?.courseId,
                [enrollmentUnitCourse?.unitCourse?.courseId]
            );

            const unitCoursePath: string = useMemo((): string => {
                if (unitId == null || courseId == null) {
                    return "";
                }
                return RouteUtils.replacePathParams(
                    userRole === RoleType.Learner
                        ? sitemap.learner.training.onlineLearning.units.courses.details
                        : sitemap.instructor.aenTraining.units.courses.details,
                    {
                        productId: productId,
                        enrollmentId: enrollmentId,
                        unitId: unitId,
                        courseId: courseId,
                    }
                );
            }, [courseId, enrollmentId, productId, unitId, userRole]);

            const forwardToLearnerAssessments = useCallback(() => {
                navigate(unitCoursePath);
            }, [navigate, unitCoursePath]);

            const fetchData = useCallback(async () => {
                if (registrationId <= 0) {
                    return;
                }

                try {
                    var getRegistrationResponse = await getRegistration({ id: registrationId });
                    var getRegistrationResult = getRegistrationResponse?.result;

                    if (
                        getRegistrationResult?.resultObject == null ||
                        getRegistrationResult.hasErrors()
                    ) {
                        throw new Error();
                    }

                    const registration = getRegistrationResult.resultObject;

                    if (userIsAuthorized(loggedInUser, registration)) {
                        setIsAuthorizedUser(true);
                    } else {
                        forwardToLearnerAssessments();
                    }
                } catch {
                    ToastManager.error(t("thereWasAnIssueLoadingTheRegistration"));
                }
            }, [
                forwardToLearnerAssessments,
                getRegistration,
                loggedInUser,
                registrationId,
                userIsAuthorized,
            ]);

            const { attempts } = useAttempts({
                registrationId: registrationId,
                includeRegistration: true,
                includeResponses: true,
            });

            useEffect(() => {
                fetchData();
            }, [fetchData]);

            return (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__header`}>
                        <ButtonIcon
                            linkPath={unitCoursePath}
                            iconType={Icons.ChevronLeft}
                            buttonSize={ButtonSize.Medium}
                            buttonStyle={ButtonStyle.Secondary}
                            text={t("backToCourse")}
                            buttonType={ButtonType.Link}
                            disabled={!StringUtils.hasValue(unitCoursePath)}
                        />

                        <div className={`${CSS_CLASS_NAME}__title`}>
                            <Heading size={HeadingSize.Small} priority={HeadingPriority.H1}>
                                {t("assessmentResults")}
                            </Heading>
                            <Badge
                                style={isRequired ? BadgeStyle.Neutral : BadgeStyle.Default}
                                text={isRequired ? t("required") : t("optional")}
                            />
                        </div>
                    </div>

                    {isAuthorizedUser && (
                        <>
                            <LearnerAssessmentSummaryDetails attempts={attempts} />
                        </>
                    )}
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningAssessmentResultsPage };

// #endregion Exports
