import { LocalizationUtils as AndcultureCodeLocalizationUtils } from "andculturecode-javascript-core";
import { StringUtils } from "utilities/string-utils";
import { CustomDetector, DetectorOptions } from "i18next-browser-languagedetector";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const VALIDATING_LANGUAGE_DETECTOR_NAME: string = "validatingLanguageDetector";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Configuration
// -------------------------------------------------------------------------------------------------

const detectionOptions: DetectorOptions = {
    order: ["path", "querystring"], // order and from where user language should be detected
};

// #endregion Configuration

// -------------------------------------------------------------------------------------------------
// #region Path Language Detector
// -------------------------------------------------------------------------------------------------

const validatingLanguageDetector: CustomDetector = {
    name: VALIDATING_LANGUAGE_DETECTOR_NAME,
    lookup: (): string | undefined => {
        const order = detectionOptions.order ?? [];
        const foundCodes = order
            .map((location: string): string | undefined => {
                if (location === "path") {
                    return AndcultureCodeLocalizationUtils.cultureCodeFromRoute();
                } else if (location === "querystring") {
                    return AndcultureCodeLocalizationUtils.cultureCodeFromQueryString();
                } else {
                    throw new Error(t("unsupportedCultureCodeLocation"));
                }
            })
            .filter((code: string | undefined): boolean =>
                StringUtils.isValidCultureCode(code ?? "")
            );

        let foundCode = foundCodes[0];

        if (foundCode != null) {
            foundCode = foundCode.split("-")[0];
        }

        return foundCode;
    },
};

// #endregion Path Language Detector

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { validatingLanguageDetector, VALIDATING_LANGUAGE_DETECTOR_NAME };

// #endregion Exports
