import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Language } from "models/enumerations/languages/language";
import { ProductCourseType } from "models/enumerations/courses/product-course-type";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductService } from "utilities/services/products/product-service";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";
import { Topic } from "models/enumerations/courses/topic";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";

interface UseProductsHook {
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    products: ProductRecord[];
    rowCount: number;
}

interface UseProductsHookOptions {
    activeStatusFilter?: ActiveStatus[];
    applySearch?: boolean;
    filterActiveAndDraft?: boolean;
    includeArchived?: boolean;
    includeAvailableForAENOnly?: boolean;
    includeCreatedBy?: boolean;
    includeLatestVersion?: boolean;
    includeLastModifiedBy?: boolean;
    language?: Language;
    onlineLearningType?: ProductCourseType;
    searchText?: string;
    searchProductNameText?: string;
    skip?: number;
    take?: number;
    topic?: Topic;
    trainingType?: TrainingType;
}

export function useProducts(props: UseProductsHookOptions): UseProductsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState<ProductRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [rowCount, setRowCount] = useState(0);
    const { list: listProducts } = ProductService.useList();
    const activeStatusFilter = props.activeStatusFilter;
    const applySearch = props.applySearch;
    const filterActiveAndDraft = props.filterActiveAndDraft;
    const includeArchived = props.includeArchived;
    const includeAvailableForAENOnly = props.includeAvailableForAENOnly;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLatestVersion = props.includeLatestVersion;
    const includeLastModifiedBy = props.includeLastModifiedBy;
    const language = props.language;
    const onlineLearningType = props.onlineLearningType;
    const searchText = props.searchText;
    const searchProductNameText = props.searchProductNameText;
    const skip = props.skip;
    const take = props.take;
    const topic = props.topic;
    const trainingType = props.trainingType;

    useEffect(() => {
        try {
            (async function getProducts() {
                const statusFilter = activeStatusFilter
                    ? activeStatusFilter
                    : [ActiveStatus.Active];

                const { result, resultObjects, rowCount } = await listProducts({
                    activeStatusFilter: statusFilter,
                    applySearch,
                    filterActiveAndDraft,
                    includeArchived,
                    includeAvailableForAENOnly,
                    includeCreatedBy,
                    includeLatestVersion,
                    includeLastModifiedBy,
                    language,
                    onlineLearningType,
                    searchText,
                    searchProductNameText,
                    skip,
                    take,
                    topic,
                    trainingType,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setRowCount(rowCount);
                setProducts(resultObjects);
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningProducts"));
        }
    }, [
        activeStatusFilter,
        applySearch,
        filterActiveAndDraft,
        includeArchived,
        includeAvailableForAENOnly,
        includeCreatedBy,
        includeLastModifiedBy,
        includeLatestVersion,
        language,
        listProducts,
        onlineLearningType,
        props.includeArchived,
        searchProductNameText,
        searchText,
        skip,
        take,
        topic,
        trainingType,
    ]);

    return {
        errors,
        isLoading,
        products,
        rowCount,
    };
}
