import React, { useMemo } from "react";
import {
    AttemptSuccessStatus,
    AttemptSuccessStatusDisplayNames,
    AttemptSuccessStatusStatusMap,
} from "models/enumerations/attempts/attempt-success-status";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Card } from "components/card/card";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { RecordSummaryItem, RecordSummaryEntry } from "components/summaries/record-summary/record-summary-entry/record-summary-entry";
import { RecordSummaryStyle } from "components/summaries/record-summary/enums/record-summary-style";
import { RoleType } from "models/enumerations/users/role-type";
import { StringUtils } from "andculturecode-javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { t } from "utilities/localization/t";
import "./learner-assessment-summary-details.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentSummaryDetailsProps {
    cssClassName?: string;
    attempts?: AttemptRecord[];
    learnerName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "learner-assessment-summary-details";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentSummaryDetails: React.FC<LearnerAssessmentSummaryDetailsProps> = ({
    cssClassName,
    attempts,
    learnerName,
}): JSX.Element | null => {
    const { record: globalState } = useGlobalState();
    const roleType = globalState?.currentIdentity?.role?.roleType;
    const isLearner = roleType === RoleType.Learner;
    const isInstructorTakingTraining = roleType === RoleType.Instructor;
    const isNFPAAdmin = roleType === RoleType.NfpaAdministrator;

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    const learnerNameEntry: RecordSummaryItem = {
        name: "name",
        value: learnerName,
    };

    const maxScoreEntry: RecordSummaryItem = useMemo(() => {
        const maxScore = attempts?.reduce((max, attempt) => {
            if (attempt?.score == null || attempt.score < max) {
                return max;
            }

            return attempt.score;
        }, 0);
        return { name: "highScore", value: maxScore?.toString() };
    }, [attempts]);

    const statusEntry: RecordSummaryItem = {
        name: "status",
        value: attempts?.some(
            (attempt) => attempt?.successStatus === AttemptSuccessStatus.Passed
        ) ? (
            <EnumStatusBadge
                displayNames={AttemptSuccessStatusDisplayNames}
                statusMap={AttemptSuccessStatusStatusMap}
                value={AttemptSuccessStatus.Passed!}
            />
        ) : (
            <Badge style={BadgeStyle.Warning} text={t("attempted")} />
        ),
    };

    const attemptCountEntry: RecordSummaryItem = {
        name: "attempts",
        value: attempts == null ? "--" : attempts?.length.toString(),
    };

    return (
        <Card cssClassName={className}>
            <h5 className={`${className}__heading`}>{t("overview")}</h5>
            {isNFPAAdmin && (
                <div className={`${className}__columns-admin`}>
                    <RecordSummaryEntry
                        item={learnerNameEntry}
                        style={RecordSummaryStyle.Default}
                    />
                    <RecordSummaryEntry item={maxScoreEntry} style={RecordSummaryStyle.Default} />
                    <RecordSummaryEntry
                        item={attemptCountEntry}
                        style={RecordSummaryStyle.Default}
                    />
                    <RecordSummaryEntry item={statusEntry} style={RecordSummaryStyle.Default} />
                </div>
            )}
            {(isLearner || isInstructorTakingTraining) && (
                <div className={`${className}__columns-learner`}>
                    <RecordSummaryEntry item={statusEntry} style={RecordSummaryStyle.Default} />
                    <RecordSummaryEntry item={maxScoreEntry} style={RecordSummaryStyle.Default} />
                    <RecordSummaryEntry
                        item={attemptCountEntry}
                        style={RecordSummaryStyle.Default}
                    />
                </div>
            )}
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentSummaryDetails };

// #endregion Exports
