import { ServiceFactory } from "andculturecode-javascript-react";
import { StorageConfigurationRecord } from "models/view-models/storage/storage-configuration-record";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "storage";
const getConfigEndpoint: string = `${baseEndpoint}/:container/configuration`;
const resourceType: typeof StorageConfigurationRecord = StorageConfigurationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetStorageConfigurationPathParams {
    container: StorageContainers;
}

export interface GetStorageConfigurationQueryParams {
    readOnly?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const StorageService = {
    getConfiguration: ServiceFactory.get<
        StorageConfigurationRecord,
        GetStorageConfigurationPathParams,
        GetStorageConfigurationQueryParams
    >(resourceType, getConfigEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { StorageService };

// #endregion Exports
