import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icons } from "components/icons/constants/icons";
import { RoleType } from "models/enumerations/users/role-type";
import { useMemo } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import "./enrollment-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnrollmentHeaderProps {
    handleAddEnrollment: () => void;
    handleBulkAddEnrollment: () => void;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "enrollment-header";
const CSS_PARENT_CLASS_NAME: string = "manage-event-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnrollmentHeader: React.FC<EnrollmentHeaderProps> = (
    props: EnrollmentHeaderProps
): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );
    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator || userRole === RoleType.AenAdministrator,
        [userRole]
    );

    return (
        <div className={`enrollment-header ${CSS_PARENT_CLASS_NAME}__heading`}>
            <h2>{props.title}</h2>
            {
                // if
                canModifyEnrollments && (
                    <>
                        <div className={`${CSS_PARENT_CLASS_NAME}__heading__actions`}>
                            <ButtonIcon
                                iconType={Icons.Plus}
                                onClick={props.handleAddEnrollment}
                                text={t("addNew")}
                            />
                            <ButtonIcon
                                iconType={Icons.Upload}
                                onClick={props.handleBulkAddEnrollment}
                                text={t("importCSV")}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentHeader };

// #endregion Exports
