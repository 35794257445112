import React from "react";
import { FormErrorMessage } from "components/form/form-error-message/form-error-message";
import { FormLabel } from "components/form/form-label/form-label";
import { StringUtils } from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface FormFieldProps {
    cssClassName?: string;
    errorMessage?: string;
    errorMessages?: string[];
    label: string;
    labelScreenReaderOnly?: boolean;
    formFieldName: string;
    required?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "form-field";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const FormField: React.FC<React.PropsWithChildren<FormFieldProps>> = (
    props: React.PropsWithChildren<FormFieldProps>
) => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    var className: string = classNames.join(" ");

    return (
        <div className={className}>
            <FormLabel
                label={props.label}
                formFieldName={props.formFieldName}
                required={props.required}
                screenReaderOnly={props.labelScreenReaderOnly}
            />
            {/* children are a place holder for any input/textarea/select element. */}
            {props.children}
            <FormErrorMessage
                errorMessage={props.errorMessage}
                errorMessages={props.errorMessages}
            />
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { FormField };

// #endregion Exports
