import React from "react";
import { AudienceTypeDisplayNames } from "models/enumerations/audiences/audience-type";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductImage } from "components/product-image/product-image";
import { ProductRecord } from "models/view-models/products/product-record";
import { TopicDisplayNames } from "models/enumerations/courses/topic";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./product-activation-details-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationDetailsSectionProps {
    product: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-details-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationDetailsSection: React.FC<ProductActivationDetailsSectionProps> = ({
    product,
}): JSX.Element => {
    const isInstructorLed = product.type === TrainingType.InstructorLedTraining;
    return (
        <>
            <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                {t(
                    "takeAMomentToReviewYourProductBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney"
                )}
            </Paragraph>
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("productDetails")}
                    </Heading>
                    <Badge style={BadgeStyle.Light}>
                        <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__badge`} />
                        {t(AudienceTypeDisplayNames[product.audienceType])}
                    </Badge>
                    <Badge style={BadgeStyle.Light}>
                        <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__badge`} />
                        {t(TrainingTypeDisplayNames[product.type!])}
                    </Badge>
                </div>
                {product.type === TrainingType.TrainTheTrainer ? (
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("productName")}</Paragraph>
                            <Paragraph>{product.name}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>
                                <Icon type={Icons.Lock} cssClassName={`${CSS_CLASS_NAME}__lock`} />
                                {t("associatedProduct")}
                            </Paragraph>
                            <Paragraph>{product.associatedProduct?.name}</Paragraph>
                        </div>
                    </div>
                ) : (
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>{t("productName")}</Paragraph>
                            <Paragraph>{product.name}</Paragraph>
                        </div>
                        <div>
                            <Paragraph style={ParagraphStyle.Label}>
                                {t("productDescription")}
                            </Paragraph>
                            <Paragraph>{product.description}</Paragraph>
                        </div>
                    </div>
                )}
                {product.type === TrainingType.TrainTheTrainer ? (
                    <div className={`${CSS_CLASS_NAME}__metadata-trainer`}>
                        <div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>
                                    {t("productDescription")}
                                </Paragraph>
                                <Paragraph>{product.description}</Paragraph>
                            </div>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__metadata-trainer-info`}>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                                <Paragraph>{t(TopicDisplayNames[product.topic!])}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("language")}</Paragraph>
                                <Paragraph>{t(LanguageDisplayNames[product.language!])}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("productId")}</Paragraph>
                                <Paragraph>{product.id}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("erpId")}</Paragraph>
                                <Paragraph>{product.erpId}</Paragraph>
                            </div>
                            {isInstructorLed && (
                                <div>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {t("availableForAen")}
                                    </Paragraph>
                                    <Paragraph>
                                        {product.availableForAEN ? t("yes") : t("no")}
                                    </Paragraph>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={`${CSS_CLASS_NAME}__metadata`}>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("topic")}</Paragraph>
                                <Paragraph>{t(TopicDisplayNames[product.topic!])}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("language")}</Paragraph>
                                <Paragraph>{t(LanguageDisplayNames[product.language!])}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("productId")}</Paragraph>
                                <Paragraph>{product.id}</Paragraph>
                            </div>
                            <div>
                                <Paragraph style={ParagraphStyle.Label}>{t("erpId")}</Paragraph>
                                <Paragraph>{product.erpId}</Paragraph>
                            </div>
                            <div>
                                <Paragraph
                                    cssClassName={`${CSS_CLASS_NAME}__image-label`}
                                    style={ParagraphStyle.Label}>
                                    {t("productImage")}
                                </Paragraph>
                                <div className={`${CSS_CLASS_NAME}__image-container`}>
                                    <ProductImage
                                        cssClassName={`${CSS_CLASS_NAME}__image`}
                                        productImageFileId={product.productImageFileId}
                                    />
                                </div>
                            </div>
                        </div>
                        {isInstructorLed && (
                            <div className={`${CSS_CLASS_NAME}__aen-details`}>
                                <div className={`${CSS_CLASS_NAME}__available`}>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {t("availableForAen")}
                                    </Paragraph>
                                    <Paragraph cssClassName={`${CSS_CLASS_NAME}__catalog-text`}>
                                        {product.availableForAEN ? t("yes") : t("no")}
                                    </Paragraph>
                                </div>
                                {product.availableForAEN && (
                                    <div className={`${CSS_CLASS_NAME}__catalog-details`}>
                                        <div
                                            className={`${CSS_CLASS_NAME}__catalog-details-section`}>
                                            <Paragraph style={ParagraphStyle.Label}>
                                                {t("longDescription")}
                                            </Paragraph>
                                            <Paragraph
                                                cssClassName={`${CSS_CLASS_NAME}__catalog-text`}>
                                                {product.catalogDescription}
                                            </Paragraph>
                                        </div>
                                        <div
                                            className={`${CSS_CLASS_NAME}__catalog-details-section`}>
                                            <Paragraph style={ParagraphStyle.Label}>
                                                {t("courseObjectives")}
                                            </Paragraph>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: product.courseObjectives,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`${CSS_CLASS_NAME}__catalog-details-section`}>
                                            <Paragraph style={ParagraphStyle.Label}>
                                                {t("intendedAudience")}
                                            </Paragraph>
                                            <div
                                                className={`${CSS_CLASS_NAME}__catalog-text`}
                                                dangerouslySetInnerHTML={{
                                                    __html: product.intendedAudience,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationDetailsSection };

// #endregion Exports
