import { ResultErrorRecord } from "andculturecode-javascript-core";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NumberUtils } from "utilities/number-utils";
import { CourseVersionService } from "utilities/services/courses/course-version-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseCourseVersionHook {
    courseVersion?: CourseVersionRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
    setCourseVersion: Dispatch<SetStateAction<CourseVersionRecord>>;
}

interface UseCourseVersionHookOptions {
    courseVersionId?: number | string;
    includeContent?: boolean;
    includeScormPackage?: boolean;
    includeArchive?: boolean;
    includeEnrollmentCounts?: boolean;
}

export function useCourseVersion(props: UseCourseVersionHookOptions): UseCourseVersionHook {
    const courseVersionId = NumberUtils.parseInt(props.courseVersionId) ?? 0;
    const includeContent = props.includeContent ?? false;
    const includeScormPackage = props.includeScormPackage ?? false;
    const includeArchive = props.includeArchive ?? false;
    const includeEnrollmentCounts = props.includeEnrollmentCounts ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [courseVersion, setCourseVersion] = useState<CourseVersionRecord>(
        new CourseVersionRecord()
    );
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { get: getCourseVersion } = CourseVersionService.useGet();

    useEffect(() => {
        if (isNaN(courseVersionId) || courseVersionId < 1) {
            return;
        }

        (async function fetchCourse() {
            try {
                const [courseResponse] = await Promise.all([
                    getCourseVersion(
                        { id: courseVersionId },
                        {
                            includeScormPackage: includeScormPackage,
                            includeContent: includeContent,
                            includeArchive: includeArchive,
                            includeEnrollmentCounts: includeEnrollmentCounts,
                        }
                    ),
                ]);

                const courseVersion = courseResponse?.resultObject;

                if (courseVersion == null) {
                    throw new Error();
                }
                setIsLoading(false);
                setCourseVersion(courseVersion);
            } catch (err) {
                ToastManager.error(`There was an issue loading the course version details.`);
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [
        courseVersionId,
        getCourseVersion,
        includeArchive,
        includeContent,
        includeEnrollmentCounts,
        includeScormPackage,
    ]);

    return {
        errors,
        isLoading,
        courseVersion,
        setCourseVersion,
    };
}
