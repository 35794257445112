import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import "./cancel-changes-modal.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CancelChangesModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onModalClose: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "cancel-changes-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CancelChangesModal: React.FC<CancelChangesModalProps> = ({
    open,
    onModalClose,
    setOpen,
}): JSX.Element => {
    const cancelChangesActionArray: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Transparent,
        },
        {
            buttonText: t("yesCancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
    ];

    return (
        <Modal
            actions={cancelChangesActionArray}
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            modalStyle={"-inverted"}
            onModalClose={() => {}}>
            {t("areYouSureYouWouldLikeToCancelWithoutSavingYourChanges")}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CancelChangesModal };

// #endregion Exports
