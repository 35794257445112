import { NumberedBadge } from "components/badges/numbered-badge/numbered-badge";
import { ResponseSummary } from "components/responses/response-summaries/response-summary/response-summary";
import { ResponseRecord } from "models/view-models/responses/response-record";
import React from "react";
import "./numbered-response-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface NumberedResponseSummaryProps {
    index: number;
    response: ResponseRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "numbered-response-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const NumberedResponseSummary: React.FC<NumberedResponseSummaryProps> = (
    props: NumberedResponseSummaryProps
): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <NumberedBadge value={props.index + 1} />
            <ResponseSummary response={props.response} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { NumberedResponseSummary };

// #endregion Exports
