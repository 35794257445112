import React from "react";
import { HeaderBanner } from "components/header-banner/header-banner";
import { t } from "utilities/localization/t";
import "./reports-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportsHeaderProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "reports-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportsHeader: React.FC<React.PropsWithChildren<ReportsHeaderProps>> = (
    props: React.PropsWithChildren<ReportsHeaderProps>
): JSX.Element => {
    return (
        <>
            <HeaderBanner cssClassName={CSS_CLASS_NAME} title={t("reports")}>
                {props.children}
            </HeaderBanner>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportsHeader };

// #endregion Exports
