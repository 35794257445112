import { Button, ButtonSize } from "components/buttons/button/button";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";
import {
    UserResourcePathParams,
    UserResourceQueryParams,
    UserService,
} from "utilities/services/users/user-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface SyncUserDataBannerProps {
    userId: number | undefined;
    lastSync: string | undefined;
    inInstructorProfile?: boolean;
    onUserDataSync?: () => void;
    bannerText: string;
}

const SyncUserDataBanner = ({
    userId,
    lastSync,
    inInstructorProfile,
    onUserDataSync,
    bannerText,
}: SyncUserDataBannerProps) => {
    const BASE_CLASS = "c-sync-user-data-banner";
    const TEXT_CLASS = `${BASE_CLASS}__text`;
    const RIGHT_SIDE_CLASS = `${BASE_CLASS}__rightSide`;
    const LAST_SYNC_CLASS = `${BASE_CLASS}__lastSync`;

    const { get: getUserApi } = UserService.useGet();
    const pathParams: UserResourcePathParams = {
        id: userId ?? 0,
    };

    const queryParams: UserResourceQueryParams = {
        syncdata: true,
    };

    const handleSyncDataClick = async () => {
        if (userId !== undefined && userId !== 0) {
            try {
                const getResponse = await getUserApi(pathParams, queryParams);
                const getResult = getResponse?.result;

                if (!getResponse.results?.hasErrors && getResult !== undefined) {
                    onUserDataSync?.();
                }
            } catch {
                ToastManager.error(t("thereWasAProblemSyncingTheUser"));
            }
        }
    };
    const lastSyncDate = DateUtils.formatDateTime(true, lastSync).replace(" at", "");

    return (
        <div className={`${BASE_CLASS} ${inInstructorProfile ? "-instructorProfile" : null}`}>
            <div className={`${TEXT_CLASS} ${inInstructorProfile ? "-instructorProfile" : null}`}>
                {bannerText}
            </div>
            <div className={RIGHT_SIDE_CLASS}>
                <div className={LAST_SYNC_CLASS}>
                    {t("lastSync").toUpperCase()}: {lastSyncDate}
                </div>
                <Button onClick={handleSyncDataClick} size={ButtonSize.Small}>
                    {t("syncData")}
                </Button>
            </div>
        </div>
    );
};
export { SyncUserDataBanner };
