import { ApplicationFile } from "models/interfaces/aen-applications/application-file";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ApplicationFile = {
    description: undefined,
    file: undefined,
    fileId: 0,
    applicationType: undefined,
    type: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ApplicationFileRecord extends Record(defaultValues) implements ApplicationFile {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ApplicationFile>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<ApplicationFile>): ApplicationFileRecord {
        return new ApplicationFileRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ApplicationFileRecord };

// #endregion Exports
