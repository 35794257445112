// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum SubscriptionCreationStatus {
    Unknown = -1,
    TrainingContractUserRoleCreated = 0,
    ValidSubscriptionOwner = 1,
    ExistingUserRoleForTrainingContractId = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SubscriptionCreationStatus };

// #endregion Exports
