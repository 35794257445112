import QuillConfig from "utilities/quill/quill-config";
import ReactQuill from "react-quill";
import { FormLabel } from "components/form/form-label/form-label";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useReadOnly } from "utilities/contexts/use-read-only-context";
import "react-quill/dist/quill.snow.css";
import "./rich-text-editor.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RichTextEditorProps {
    defaultValue?: string;
    disabled?: boolean;
    errors?: string[];
    label?: string;
    maxLength?: number;
    onBlur?: () => void;
    onChange: (html: string, text: string) => void;
    onFocus?: () => void;
    placeholder?: string;
    required?: boolean;
    value: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "rich-text-editor";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RichTextEditor: React.FC<RichTextEditorProps> = ({
    defaultValue,
    disabled,
    label,
    onBlur,
    onChange,
    placeholder,
    required,
    ...props
}: RichTextEditorProps) => {
    const reactQuillRef = useQuillRef({ onChange });
    const value = useReactQuillWorkaround(props.value);
    const { readOnly } = useReadOnly();
    const isDisabled = useMemo(() => disabled || readOnly, [disabled, readOnly]);
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (isDisabled) {
        cssClassNames.push("-disabled");
    }

    return (
        <div className={cssClassNames.join(" ")} onBlur={onBlur}>
            {label != null && (
                <FormLabel
                    label={label}
                    formFieldName={label}
                    required={required}
                    screenReaderOnly={false}
                />
            )}
            <ReactQuill
                ref={reactQuillRef}
                modules={{ toolbar: QuillConfig.quillToolbarOptions }}
                formats={QuillConfig.quillFormats}
                theme={QuillConfig.theme}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                readOnly={isDisabled}
                preserveWhitespace={true}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Component Hooks
// -------------------------------------------------------------------------------------------------

type Quill = ReturnType<ReactQuill["getEditor"]>;
type EditorChangeHandler = Parameters<Quill["on"]>[1];

/**
 * Suggested workaround for bug related to first time render
 * https://github.com/zenoamaro/react-quill/issues/814#issue-1285039041
 */
function useReactQuillWorkaround(body: string): string {
    const [firstRun, setFirstRun] = useState(true);

    useEffect(() => setFirstRun(false), []);

    return firstRun ? "" : body;
}

/**
 * Setup the onChange handler for the ReactQuill component to include both html and text updates
 */
function useQuillRef({ onChange }: Pick<RichTextEditorProps, "onChange">) {
    const reactQuillRef = useRef<ReactQuill>(null);

    useEffect(() => {
        const quill = reactQuillRef.current?.getEditor();

        if (!quill) {
            return;
        }

        const onTextChange: EditorChangeHandler = (...args: Parameters<EditorChangeHandler>) => {
            const [name] = args;

            if (name === "selection-change") {
                return;
            }

            const html = quill.root.innerHTML;
            const text = quill.getText().trim();

            onChange(html, text);
        };

        quill.on("editor-change", onTextChange);

        return () => {
            quill.off("editor-change", onTextChange);
        };
    }, [onChange]);

    return reactQuillRef;
}

// #endregion Component Hooks

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RichTextEditor;

// #endregion Exports
