import { ProductVersionArchiveRecord } from "models/view-models/products/product-version-archive-record";
import { ProductVersionArchiveService } from "utilities/services/products/product-version-archive-service";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseProductVersionArchiveHook {
    productVersionArchive: ProductVersionArchiveRecord[];
    errors?: ResultErrorRecord[];
    isLoading: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseProductVersionArchiveHookOptions {
    productId: number;
    productVersionId: number;
    includeCreatedBy?: boolean;
}

export function useProductVersionArchive(
    props: UseProductVersionArchiveHookOptions
): UseProductVersionArchiveHook {
    const [isLoading, setIsLoading] = useState(true);
    const [productVersionArchive, setProductVersionArchive] = useState<
        ProductVersionArchiveRecord[]
    >([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [refresh, setRefresh] = useState(false);
    const { list: listProductVersionArchive } = ProductVersionArchiveService.useList();
    const productId = props.productId;
    const productVersionId = props.productVersionId;
    const includeCreatedBy = props.includeCreatedBy ?? false;

    const fetchData = useCallback(async () => {
        try {
            (async function getProductVersionArchive() {
                if (productId === undefined) return;
                const { result, resultObjects } = await listProductVersionArchive({
                    productId,
                    productVersionId,
                    includeCreatedBy,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                setProductVersionArchive(resultObjects);
            })();
        } catch {
            ToastManager.error("There was a problem returning product version archive.");
        }
    }, [productId, productVersionId, includeCreatedBy, listProductVersionArchive]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        productVersionArchive,
        setRefresh,
    };
}
