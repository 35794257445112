import { Status } from "utilities/enumerations/statuses";
import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum AttemptSuccessStatus {
    Failed = 0,
    Passed = 1,
    Unknown = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const AttemptSuccessStatusDisplayNames: Map<AttemptSuccessStatus, TranslatedCopy> = {
    [AttemptSuccessStatus.Failed]: "failed",
    [AttemptSuccessStatus.Passed]: "passed",
    [AttemptSuccessStatus.Unknown]: "notStarted",
};

const AttemptSuccessStatusStatusMap: Map<AttemptSuccessStatus, Status> = {
    [AttemptSuccessStatus.Failed]: Status.Error,
    [AttemptSuccessStatus.Passed]: Status.Success,
    [AttemptSuccessStatus.Unknown]: Status.Neutral,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export { AttemptSuccessStatus, AttemptSuccessStatusDisplayNames, AttemptSuccessStatusStatusMap };

// #endregion Exports
