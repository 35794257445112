import React, { useCallback } from "react";
import { Card } from "components/card/card";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { InputTypes } from "components/form/enumerations/input-types";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import "./event-link-trial-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventLinkTrialFormProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-link-trial-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventLinkTrialForm: React.FC<EventLinkTrialFormProps> = ({ event }): JSX.Element => {
    const handleBlur = useCallback((): void => {
        if (event.isDraft()) {
            event.save();
        }
    }, [event]);

    return (
        <Card>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H3} size={HeadingSize.XSmall}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t("linkTrialInformationWithHTML"),
                        }}
                    />
                </Heading>
            </div>
            <div className={`${CSS_CLASS_NAME}__grid`}>
                <FormTextInput
                    ariaLabelledBy={t("linkTrialId")}
                    autoFocus={false}
                    formFieldName={t("id")}
                    id={"linkTrialId"}
                    label={t("linkTrialId")}
                    onBlur={handleBlur}
                    onChange={(e) =>
                        event.updateActiveRecord({
                            linkFreeTrialContractId: NumberUtils.parseInt(e.target.value),
                        })
                    }
                    placeholder={t("linkTrialId")}
                    required={false}
                    type={InputTypes.Text}
                    value={event.linkFreeTrialContractId}
                />
                <FormTextInput
                    ariaLabelledBy={t("linkTrialToken")}
                    autoFocus={false}
                    formFieldName="linkTrialToken"
                    id="linkTrialToken"
                    label={t("linkTrialToken")}
                    onBlur={handleBlur}
                    onChange={(e) =>
                        event.updateActiveRecord({
                            linkFreeTrialToken: e.target.value,
                        })
                    }
                    placeholder={t("linkTrialToken")}
                    required={false}
                    type={InputTypes.Text}
                    value={event.linkFreeTrialToken}
                />
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventLinkTrialForm };

// #endregion Exports
