import React, { useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { ProductCreateAssessmentForm } from "./product-create-assessment-form/product-create-assessment-form";
import { Modal, ModalAction } from "components/modal/modal";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductCreateAssessmentModalProps {
    handleSaveAssessment: (
        productId: number,
        productVersionId: number,
        scormPackage: ScormPackageRecord
    ) => Promise<boolean>;
    open: boolean;
    productId: number;
    productVersionId: number;
    scormPackage?: ScormPackageRecord;
    setAssessmentHasChanges?: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductCreateAssessmentModal: React.FC<ProductCreateAssessmentModalProps> = (
    props: ProductCreateAssessmentModalProps
): JSX.Element => {
    let initialContent = props.scormPackage ?? new ScormPackageRecord();
    const [scormPackage, setScormPackage] = useState(initialContent);
    const [disableAddButton, setDisableAddButton] = useState(false);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => {
                onModalClose();
            },
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("addAssessment"),
            disabled:
                disableAddButton ||
                scormPackage.fileId === undefined ||
                scormPackage.name === undefined ||
                scormPackage.name === "",
            onClick: () => onScormPackageAdd(),
            style: ButtonStyle.Primary,
        },
    ];

    const resetForm = (): void => {
        setScormPackage(initialContent);
    };

    const onModalClose = (): void => {
        resetForm();
        props.setOpen(false);
    };

    const onScormPackageAdd = async (): Promise<void> => {
        if (disableAddButton) {
            return;
        }

        setDisableAddButton(true);
        props.setAssessmentHasChanges && props.setAssessmentHasChanges(true);

        if (
            await props.handleSaveAssessment(props.productId, props.productVersionId, scormPackage)
        ) {
            setScormPackage(initialContent);
            props.setOpen(false);
            setDisableAddButton(false);
        } else {
            return;
        }
    };

    const onScormPackageChanged = (scormPackage: ScormPackageRecord): void => {
        setScormPackage(scormPackage);
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={t("addAssessment")}
            actions={modalActionArray}
            modalStyle={""}>
            <ProductCreateAssessmentForm
                scormPackage={scormPackage}
                onScormPackageChanged={onScormPackageChanged}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductCreateAssessmentModal };

// #endregion Exports
