import { EventChecklistRecord } from "models/view-models/events/event-checklist-record";
import { ServiceHookFactory } from "andculturecode-javascript-react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "eventchecklists";
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof EventChecklistRecord = EventChecklistRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEventChecklistPathParams {
    id: number;
}

export interface ListEventChecklistsQueryParams {
    eventId?: number;
}

export interface UpdateEventChecklistPathParams {
    id: number;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventChecklistService = {
    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<EventChecklistRecord, GetEventChecklistPathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<EventChecklistRecord, ListEventChecklistsQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<EventChecklistRecord, UpdateEventChecklistPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChecklistService };

// #endregion Exports
