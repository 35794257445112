import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import {
    GetScormPackageExistsEndpointPathParams,
    GetScormPackagePreviewResourceEndpointPathParams,
    GetScormPackageResourceEndpointPathParams,
    GetVersionLaunchLinkQueryParams,
    ScormPackageService,
} from "utilities/services/scorm-packages/scorm-package-service";
import { HeaderActions } from "components/header-actions/header-actions";
import { HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { ZipPackageModal } from "components/courses/course-zip-package/zip-package-modal/zip-package-modal";
import { useCourse } from "utilities/contexts/use-course-context";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { ScormPackageStatusRecord } from "models/view-models/scorm-packages/scorm-package-status-record";
import { t } from "utilities/localization/t";
import "./course-zip-package-form.scss";
import { defer } from "react-router-dom";
// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseZipPackageFormProps {
    inCreateVersionMode?: boolean;
    inEditVersionMode?: boolean;
    courseVersion: CourseVersionRecord;
    setCourseVersion: React.Dispatch<React.SetStateAction<CourseVersionRecord>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-zip-package-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseZipPackageForm: React.FC<CourseZipPackageFormProps> = ({
    inCreateVersionMode,
    inEditVersionMode,
    courseVersion,
    setCourseVersion,
}): JSX.Element => {
    const { record: course } = useCourse();
    const [showZipPackageModal, setShowZipPackageModal] = useState(false);
    const { get: getVersionLaunchLink } = ScormPackageService.useGetPreviewVersionLaunchLink();
    const { get: getLaunchLink } = ScormPackageService.useGetPreviewLaunchLink();
    const { get: getScormPackageStatus } = ScormPackageService.useGetScormPackageStatus();
    const { update: updateScormPackage } = ScormPackageService.useUpdate();
    const { get: checkScormPackageExists } = ScormPackageService.useGetCheckIfScormPackageExists();

    const deferSave = course.status !== ActiveStatus.Draft;
    const [pollingTrigger, setPollingTrigger] = useState(-1);
    const [updatedScormPackageStatus, setUpdatedScormPackageStatus] =
        useState<ScormPackageStatusRecord>();

    const getPreviewLink = useCallback(async (): Promise<string> => {
        if (courseVersion?.scormPackage?.externalScormCourseId == null) {
            return "";
        }
        const isDraft = courseVersion.status === ActiveStatus.Draft;

        if (courseVersion?.scormPackage?.externalScormVersionId != null) {
            if (courseVersion.scormPackage == null) return "";
            let versionId = courseVersion?.scormPackage?.externalScormVersionId;

            if (isDraft) {
                versionId = courseVersion.versionId!;
            }

            const pathParams: GetVersionLaunchLinkQueryParams = {
                externalCourseId: courseVersion?.scormPackage.externalScormCourseId,
                versionId: versionId,
                isDraft: isDraft,
            };
            try {
                const getResponse = await getVersionLaunchLink(pathParams, pathParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                return getResult.resultObject;
            } catch {
                ToastManager.info(t("thePreviewIsNotAvailableYet"));
            }
        } else {
            const pathParams: GetScormPackagePreviewResourceEndpointPathParams = {
                id: courseVersion?.scormPackage.externalScormCourseId,
            };

            try {
                const getResponse = await getLaunchLink(pathParams);
                const getResult = getResponse?.result;
                if (getResult?.resultObject == null || getResult.hasErrors()) {
                    throw new Error();
                }
                return getResult.resultObject;
            } catch {
                ToastManager.info(t("thePreviewIsNotAvailableYet"));
            }
        }

        return "";
    }, [
        courseVersion.scormPackage,
        courseVersion.status,
        courseVersion.versionId,
        getLaunchLink,
        getVersionLaunchLink,
    ]);

    const handleRedirect = async (): Promise<void> => {
        if (courseVersion?.scormPackage?.externalScormCourseId == null) {
            return;
        }

        const scormPreviewLink = await getPreviewLink();
        if (scormPreviewLink.length > 0) {
            window.open(scormPreviewLink);
        }
    };

    const updateStatus = useCallback(async () => {
        if (courseVersion?.scormPackage?.id == null) {
            return;
        }

        const pathParams: GetScormPackageResourceEndpointPathParams = {
            id: courseVersion?.scormPackage.id,
        };

        try {
            const getResponse = await getScormPackageStatus(pathParams);
            const getResult = getResponse?.result;
            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            const updatedStatus = getResult.resultObject;
            if (updatedStatus.errorMessage != null && updatedStatus.errorMessage.length > 0) {
                ToastManager.info(updatedStatus.errorMessage);
            }
            setUpdatedScormPackageStatus(updatedStatus);
        } catch {
            ToastManager.info(t("thePreviewIsNotAvailableYet"));
        }
    }, [courseVersion?.scormPackage, getScormPackageStatus]);

    const processingCompleteStatus = useCallback(() => {
        if (courseVersion.scormPackage == null) return true;
        return (
            courseVersion.scormPackage?.status === ScormPackageImportStatus.Error ||
            courseVersion.scormPackage?.status === ScormPackageImportStatus.Complete
        );
    }, [courseVersion.scormPackage]);

    const updateScormPackageStatus = useCallback(() => {
        //const savedRequirePassed = courseVersion.scormPackage?.requirePassed;
        if (updatedScormPackageStatus == null) return;
        if (courseVersion.scormPackage?.status !== updatedScormPackageStatus.uploadStatus) {
            setCourseVersion((prev: CourseVersionRecord) =>
                prev.with({
                    scormPackage: prev.scormPackage?.with({
                        status: updatedScormPackageStatus.uploadStatus,
                        externalScormVersionId: updatedScormPackageStatus.externalVersionId,
                        hasValidAssessment: updatedScormPackageStatus.hasValidAssessment,
                        //requirePassed: savedRequirePassed,
                    }),
                })
            );
        }
        setUpdatedScormPackageStatus(undefined);
    }, [courseVersion.scormPackage?.status, setCourseVersion, updatedScormPackageStatus]);

    useEffect(() => {
        if (!processingCompleteStatus()) {
            updateScormPackageStatus();
        }
    }, [processingCompleteStatus, updateScormPackageStatus, updatedScormPackageStatus]);

    useEffect(() => {
        if (pollingTrigger >= 0) return;
        if (processingCompleteStatus()) {
            setPollingTrigger(-1);
        } else {
            setPollingTrigger(0);
        }
    }, [pollingTrigger, processingCompleteStatus]);

    useEffect(() => {
        if (pollingTrigger === -1) return;
        if (processingCompleteStatus()) {
            setPollingTrigger(-1);
        }
        const interval = window.setInterval(() => {
            setPollingTrigger(pollingTrigger + 1);
            updateStatus();
        }, 1000);

        //Clearing the interval
        return () => {
            window.clearInterval(interval);
        };
    }, [pollingTrigger, processingCompleteStatus, updateStatus]);

    const handleSetRequirePassed = (newValue: boolean) => {
        if (courseVersion.scormPackage == null) return;
        const updatedScormPackage = courseVersion.scormPackage.with({
            requirePassed: newValue,
        });

        if (!deferSave) {
            updateScormPackage(updatedScormPackage);
        }
        setCourseVersion(
            courseVersion.with({
                scormPackage: updatedScormPackage,
            })
        );
    };

    const replacePackage = useCallback(async () => {
        if (courseVersion?.scormPackage?.externalScormCourseId == null) return;
        setPollingTrigger(-1);

        const externalScormCourse = courseVersion?.scormPackage?.externalScormCourseId;
        console.log("External Scorm Course Name:", externalScormCourse);

        //create method to check if the scorm package exists
        const pathParams: GetScormPackageExistsEndpointPathParams = {
            id: courseVersion?.scormPackage?.externalScormCourseId,
        };

        const getScormPackageExistsResponse = await checkScormPackageExists(pathParams);
        const scormPackageExists = getScormPackageExistsResponse.result?.resultObject.valueOf();
        if (scormPackageExists) {
            //show toast message
            ToastManager.info(
                t("youMustManuallyDeleteTheExistingPackageBeforeReplacingIt", {
                    externalScormCourse: courseVersion?.scormPackage?.externalScormCourseId,
                })
            );
            return;
        }
        setShowZipPackageModal(true);
    }, [checkScormPackageExists, courseVersion?.scormPackage?.externalScormCourseId]);

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <HeaderActions
                    title={t("zipPackage")}
                    headingPriority={HeadingPriority.H2}
                    headingSize={HeadingSize.XSmall}>
                    <Button
                        disabled={!!courseVersion?.scormPackage?.id}
                        onClick={() => setShowZipPackageModal(true)}
                        style={ButtonStyle.Primary}
                        text={t("addPackage")}
                    />
                </HeaderActions>
            </div>
            {inEditVersionMode && (
                <NotificationBanner
                    notificationId={0}
                    onClose={() => {}}
                    style={NotificationType.Default}
                    message={t(
                        "changesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageCreateANewCourseVersion"
                    )}
                />
            )}
            <div className={`${CSS_CLASS_NAME}__content`}>
                {courseVersion?.scormPackage != null && (
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th className="name">{t("name")}</th>
                                <th className="file">{t("fileName")}</th>
                                {courseVersion.scormPackage.hasValidAssessment != null && (
                                    <th className="assessment">{t("hasAssessment")}</th>
                                )}
                                {courseVersion.scormPackage.hasValidAssessment != null &&
                                    !courseVersion.scormPackage.hasValidAssessment && (
                                        <th className="passed">{t("requirePassed")}</th>
                                    )}
                                <th className="status">{t("importStatus")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="name">{courseVersion.scormPackage.name}</td>
                                <td className="file">
                                    {courseVersion.scormPackage.file?.fileName}
                                </td>
                                {courseVersion.scormPackage.hasValidAssessment != null && (
                                    <td className="validAssessment">
                                        {courseVersion.scormPackage.hasValidAssessment
                                            ? t("yes")
                                            : t("no")}
                                    </td>
                                )}
                                {courseVersion.scormPackage.hasValidAssessment != null &&
                                    !courseVersion.scormPackage.hasValidAssessment && (
                                        <td className="requirePassed">
                                            <CheckboxInput
                                                checked={courseVersion.scormPackage.requirePassed}
                                                disabled={
                                                    !(
                                                        !deferSave ||
                                                        inCreateVersionMode ||
                                                        inEditVersionMode
                                                    )
                                                }
                                                label="&nbsp;"
                                                id={`course-version-scorm-require-passed`}
                                                onChange={handleSetRequirePassed}
                                                size={CheckboxSize.Small}
                                            />
                                        </td>
                                    )}
                                <td className="status">
                                    {ScormPackageImportStatus[courseVersion.scormPackage.status!]}
                                </td>
                                <td className="action">
                                    <ContextMenu>
                                        <ContextMenuButton
                                            disabled={
                                                courseVersion.scormPackage.status !==
                                                ScormPackageImportStatus.Complete
                                            }
                                            onClick={handleRedirect}
                                            displayName={t("preview")}
                                        />
                                        {(inCreateVersionMode ||
                                            course.status === ActiveStatus.Draft) && (
                                            <ContextMenuButton
                                                onClick={() => {
                                                    replacePackage();
                                                }}
                                                displayName={t("replacePackage")}
                                            />
                                        )}
                                    </ContextMenu>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                )}
            </div>
            {courseVersion?.scormPackage?.id == null && (
                <EmptyText table>{t("noZipPackageAdded")}</EmptyText>
            )}
            <ZipPackageModal
                courseVersion={courseVersion}
                setCourseVersion={setCourseVersion!}
                open={showZipPackageModal}
                replacing={courseVersion?.scormPackage?.id != null}
                scormPackage={courseVersion?.scormPackage}
                setOpen={setShowZipPackageModal}
                deferSave={course.status !== ActiveStatus.Draft}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseZipPackageForm };

// #endregion Exports
