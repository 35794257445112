import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Icons } from "components/icons/constants/icons";
import { Icon } from "components/icons/icon";
import React from "react";
import { Badge, BadgeStyle } from "../../badges/badge/badge";
import { Button, ButtonStyle } from "../../buttons/button/button";
import { Card } from "../../card/card";
import { Paragraph, ParagraphSize } from "../../typography/paragraph/paragraph";
import { EnrollmentUnitCourseStatus } from "models/enumerations/enrollments/enrollment-unit-course-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    EnrollmentUnitCourseStatusDisplayNames,
    EnrollmentUnitCourseStatusMap,
} from "models/enumerations/enrollments/enrollment-unit-course-status";
import { t } from "utilities/localization/t";
import "./course-card.scss";
import { IconSizes } from "components/icons/constants/icon-sizes";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseCardProps {
    id: number;
    locked: boolean;
    onViewCourseClick: () => void;
    name: string;
    status: EnrollmentUnitCourseStatus;
    required: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "course-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseCard: React.FC<CourseCardProps> = (props: CourseCardProps) => {
    return (
        <Card cssClassName={CSS_CLASS_NAME}>
            <div>
                <Paragraph size={ParagraphSize.Large}>
                    {props.locked && (
                        <Icon cssClassName={`${CSS_CLASS_NAME}__lock`} type={Icons.Lock} size={IconSizes.Medium} />
                    )}
                    {props.name}
                </Paragraph>
            </div>
            <div>
                <Badge
                    style={props.required ? BadgeStyle.Neutral : BadgeStyle.Default}
                    text={props.required ? t("required") : t("optional")}
                />
            </div>
            <div>
                <EnumStatusBadge
                    displayNames={EnrollmentUnitCourseStatusDisplayNames}
                    statusMap={EnrollmentUnitCourseStatusMap}
                    value={props.status}
                />
            </div>
            <div>
                {props.locked ? (
                    <ButtonIcon iconType={Icons.Lock} text={t("viewCourse")} disabled={true} />
                ) : (
                    <Button
                        text={t("viewCourse")}
                        onClick={() => {
                            if (props.onViewCourseClick) {
                                props.onViewCourseClick();
                            }
                        }}
                        style={ButtonStyle.Primary}
                    />
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseCard };

// #endregion Exports
