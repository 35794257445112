import { AnnouncementList } from "components/announcements/announcement-list/announcement-list";
import { AnnouncementModal } from "../../../../components/announcements/announcement-modal/announcement-modal";
import { AnnouncementRecord } from "../../../../models/view-models/announcements/announcement-record";
import {
    AnnouncementService,
    GetAnnouncementPathParams,
    GetAnnouncementQueryParams,
    PatchAnnouncementServiceParams,
} from "utilities/services/announcements/announcement-service";
import { ButtonIcon } from "../../../../components/buttons/button-icon/button-icon";
import { ButtonSize } from "components/buttons/button/button";
import { ButtonStyle } from "../../../../components/buttons/button/button";
import { Icons } from "../../../../components/icons/constants/icons";
import { Paragraph } from "../../../../components/typography/paragraph/paragraph";
import { ParagraphStyle } from "components/typography/paragraph/paragraph";
import { ResultRecord } from "andculturecode-javascript-core";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { useCallback, useEffect, useState } from "react";
import { Pager } from "components/pager/pager";
import { t } from "utilities/localization/t";
import { HeaderBanner } from "components/header-banner/header-banner";
import "./admin-announcements-list-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { sitemap } from "sitemap";
import { ScrollUtils } from "utilities/scroll-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminAnnouncementsListPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "admin-announcements-list-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminAnnouncementsListPage: React.FC<AdminAnnouncementsListPageProps> = validatePageAccess(
    AccessControlKeys.AdminAnnouncementsListPage
)((): JSX.Element => {
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(new AnnouncementRecord());
    const [activeAnnouncement, setActiveAnnouncement] = useState<AnnouncementRecord | undefined>();
    const [archivedAnnouncements, setArchivedAnnouncements] = useState<AnnouncementRecord[]>([]);
    const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
    const { archive: announcementPatch } = AnnouncementService.useArchive();
    const { list: listAnnouncements } = AnnouncementService.useList();
    const { get: getActiveAnnouncement } = AnnouncementService.useGet();
    const [currentPage, setCurrentPage] = useState<number>(1);

    useRedirectOnForbidden(sitemap.admin.dashboard);

    const fetchActiveAnnouncement = useCallback(async (): Promise<void> => {
        try {
            const pathParams: GetAnnouncementPathParams = {};
            const queryParams: GetAnnouncementQueryParams = {
                includeCreatedBy: true,
                includeLastModifiedBy: true,
            };
            const getResponse = await getActiveAnnouncement(pathParams, queryParams);

            const getResult = getResponse?.result;

            if (getResult?.resultObject == null) {
                setActiveAnnouncement(undefined);
            }

            if (getResult?.hasErrors()) {
                throw new Error("");
            }
            setActiveAnnouncement(getResult?.resultObject);
        } catch (err) {
            const error = err as ResultRecord<AnnouncementRecord>;

            const errorMessages = error.listErrorMessages();
            const cannotFindAnnouncement = errorMessages.some((em) => em.includes("404"));

            if (!cannotFindAnnouncement) {
                ToastManager.error(t("thereWasAnIssueLoadingTheCurrentAnnouncement"));
            }
        }
    }, [getActiveAnnouncement]);

    const fetchArchivedAnnouncements = useCallback(
        async (take: number) => {
            try {
                const listAnnouncementsResponse = await listAnnouncements({
                    archivedOnly: true,
                    includeCreatedBy: true,
                    includeLastModifiedBy: true,
                    skip: (currentPage - 1) * take,
                    take: take,
                });

                if (
                    listAnnouncementsResponse?.results == null ||
                    listAnnouncementsResponse?.results.hasErrors()
                ) {
                    throw new Error();
                }

                if (listAnnouncementsResponse?.resultObjects == null) {
                    setArchivedAnnouncements([]);
                }

                setArchivedAnnouncements(listAnnouncementsResponse.resultObjects);
            } catch {
                ToastManager.error(t("errorFetchingArchivedAnnouncements"));
            }
        },
        [currentPage, listAnnouncements]
    );

    useEffect(() => {
        fetchActiveAnnouncement();
        fetchArchivedAnnouncements(10);
    }, [fetchActiveAnnouncement, fetchArchivedAnnouncements]);

    const handleArchiveAnnouncement = async (
        announcement: AnnouncementRecord
    ): Promise<boolean> => {
        if (announcement.id == null) {
            return false;
        }

        const patchAnnouncementServiceParams: PatchAnnouncementServiceParams = {
            id: announcement.id,
        };

        try {
            const patchAnnouncementResponse = await announcementPatch(
                patchAnnouncementServiceParams
            );

            const patchAnnouncementResult = patchAnnouncementResponse?.result;

            if (
                patchAnnouncementResult?.resultObject == null ||
                patchAnnouncementResult.hasErrors()
            ) {
                alert(t("archivingAnnouncementFailed"));
                return false;
            }
            setActiveAnnouncement(undefined);
            fetchArchivedAnnouncements(10);
        } catch {
            ToastManager.error(t("failedToArchiveAnnouncement"));
            return false;
        }

        return true;
    };

    const handleEditAnnouncement = (announcement: AnnouncementRecord) => {
        setSelectedAnnouncement(announcement);
        setShowAnnouncementModal(true);
    };

    const handlePreviewAnnouncement = (announcement: AnnouncementRecord) => {
        alert(
            "TODO: Hold off on all viewing screens to focus on creation NFPA-6982. Implement this functionality!!"
        );
    };

    const addNewAnnouncementClick = () => {
        setShowAnnouncementModal(true);
    };

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        fetchArchivedAnnouncements(10);
        alert(
            "TODO: This is currently rendering a pageCount of 3 based on the MIN_DISPLAY_PAGES in Pager"
        );
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`${CSS_CLASS_NAME}__section`);
    }, [archivedAnnouncements]);

    return (
        <>
            <HeaderBanner title={t("announcements")}>
                <ButtonIcon
                    buttonSize={ButtonSize.Medium}
                    buttonStyle={ButtonStyle.TertiaryAlt}
                    cssClassName={`${CSS_CLASS_NAME}__header__button`}
                    disabled={activeAnnouncement != null}
                    iconType={Icons.Plus}
                    onClick={() => addNewAnnouncementClick()}
                    text={t("addNewAnnouncement")}
                />
            </HeaderBanner>
            <SkipNavContent>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <h2>{t("currentAnnouncements")}</h2>
                        {!activeAnnouncement && (
                            <div className={`${CSS_CLASS_NAME}__section__empty`}>
                                <Paragraph style={ParagraphStyle.Light}>
                                    {t("youHaveNoCurrentAnnouncements")}
                                </Paragraph>
                                <ButtonIcon
                                    buttonSize={ButtonSize.Medium}
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.Plus}
                                    onClick={() => addNewAnnouncementClick()}
                                    text={t("addNewAnnouncement")}
                                />
                            </div>
                        )}

                        {activeAnnouncement && (
                            <AnnouncementList
                                announcements={[activeAnnouncement]}
                                onArchiveAnnouncement={handleArchiveAnnouncement}
                                onEditAnnouncement={handleEditAnnouncement}
                                onPreviewAnnouncement={handlePreviewAnnouncement}
                            />
                        )}
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <h2>{t("archivedAnnouncements")}</h2>
                        {archivedAnnouncements.length === 0 && (
                            <div className={`${CSS_CLASS_NAME}__section__empty`}>
                                <Paragraph style={ParagraphStyle.Light}>
                                    {t("pastAnnouncementsWillDisplayHereOnceTheyHaveBeenArchived")}
                                </Paragraph>
                            </div>
                        )}

                        {archivedAnnouncements.length > 0 && (
                            <>
                                <AnnouncementList
                                    announcements={archivedAnnouncements}
                                    onArchiveAnnouncement={handleArchiveAnnouncement}
                                    onEditAnnouncement={handleEditAnnouncement}
                                    onPreviewAnnouncement={handlePreviewAnnouncement}
                                />
                                <Pager
                                    currentPage={currentPage}
                                    totalPages={archivedAnnouncements.length / 20}
                                    onPageClick={onPageClick}
                                    itemsPerPage={20}
                                    totalItems={archivedAnnouncements.length}
                                />
                            </>
                        )}
                    </div>
                </div>
                <AnnouncementModal
                    announcement={selectedAnnouncement}
                    isVisible={showAnnouncementModal}
                    onClose={() => setShowAnnouncementModal(false)}
                    onPublish={() => fetchActiveAnnouncement()}
                />
            </SkipNavContent>
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminAnnouncementsListPage };

// #endregion Exports
