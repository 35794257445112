import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { CourseVersionService } from "utilities/services/courses/course-version-service";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ResultErrorRecord } from "andculturecode-javascript-core";
import { ScormPackageImportStatus } from "models/enumerations/scorm-packages/scorm-package-import-status";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

interface UseCourseVersionsHook {
    courseVersions: CourseVersionRecord[];
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    setRefresh: Dispatch<SetStateAction<boolean>>;
}

interface UseCourseVersionsHookOptions {
    courseId: number;
    includeCreatedBy?: boolean;
    includeLastModifiedBy?: boolean;
    includeScormPackage?: boolean;
    includeContent?: boolean;
    includeEnrollmentCounts?: boolean;
    refreshUntilComplete?: boolean;
}

export function useCourseVersions(props: UseCourseVersionsHookOptions): UseCourseVersionsHook {
    const [isLoading, setIsLoading] = useState(true);
    const [courseVersions, setCourseVersions] = useState<CourseVersionRecord[]>([]);
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const [refresh, setRefresh] = useState(false);
    const { list: listCourseVersions } = CourseVersionService.useList();
    const courseId = props.courseId;
    const includeCreatedBy = props.includeCreatedBy;
    const includeLastModifiedBy = props.includeLastModifiedBy;
    const includeScormPackage = props.includeScormPackage;
    const includeEnrollmentCounts = props.includeEnrollmentCounts;
    const refreshUntilComplete = props.refreshUntilComplete;
    const [startPolling, setStartPolling] = useState(false);

    const fetchData = useCallback(async () => {
        if (isNaN(courseId) || courseId < 1) {
            return;
        }

        try {
            (async function getCourseVersions() {
                const { result, resultObjects } = await listCourseVersions({
                    courseId,
                    includeCreatedBy,
                    includeLastModifiedBy,
                    includeScormPackage,
                    includeEnrollmentCounts,
                });

                setIsLoading(false);

                if (result?.hasErrors()) {
                    const { errors } = result ?? {};
                    setErrors(errors ?? []);
                    return;
                }
                //setRowCount(rowCount);
                setCourseVersions(resultObjects);
                if (refreshUntilComplete) {
                    //check if the first resultObject status is running
                    if (
                        resultObjects[0].scormPackage?.status === ScormPackageImportStatus.Running
                    ) {
                        setStartPolling(true);
                    } else {
                        setStartPolling(false);
                    }
                }
            })();
        } catch {
            ToastManager.error(t("thereWasAProblemReturningEnrollments"));
        }
    }, [
        courseId,
        includeCreatedBy,
        includeEnrollmentCounts,
        includeLastModifiedBy,
        includeScormPackage,
        listCourseVersions,
        refreshUntilComplete,
    ]);

    useEffect(() => {
        const timeoutID = window.setTimeout(async () => {
            if (startPolling) {
                try {
                    //updateStatus();
                    fetchData();
                } catch {
                    ToastManager.info(`Unable to update status.`);
                }
            } else {
                return () => window.clearTimeout(timeoutID);
            }
        }, 1000);
    }, [fetchData, startPolling]);

    useEffect(() => {
        try {
            fetchData();
        } catch {
            ToastManager.error("There was a problem returning course versions.");
        }
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [fetchData, refresh]);

    return {
        errors,
        isLoading,
        courseVersions,
        setRefresh,
    };
}
