import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ActiveStatus,
    ActiveStatusMap,
    ActiveStatusStatusDisplayNames,
} from "models/enumerations/active-status/active-status";
import { AudienceTypeDisplayNames } from "models/enumerations/audiences/audience-type";
import { ButtonIcon, ButtonIconProps } from "components/buttons/button-icon/button-icon";
import { ButtonStyle } from "components/buttons/button/button";
import {
    CountProductsQueryParams,
    ProductService,
} from "utilities/services/products/product-service";
import { CourseActivationModal } from "../../../components/courses/course-activation-modal/course-activation-modal";
import { CourseArchiveModal } from "components/courses/course-archive-modal/course-archive-modal";
import { CourseUnpublishModal } from "components/courses/course-unpublish-modal/course-unpublish-modal";
import { CourseContext } from "utilities/contexts/use-course-context";
import { CourseDeactivationModal } from "components/courses/course-deactivation-modal/course-deactivation-modal";
import { CourseRecord } from "models/view-models/courses/course-record";
import {
    CourseService,
    GetCoursePathParams,
    GetCourseQueryParams,
} from "utilities/services/courses/course-service";
import { CourseVersionContext } from "utilities/contexts/use-course-version-context";
import { CourseVersionRecord } from "models/view-models/courses/course-version-record";
import { EditCourseLayoutFooter } from "./edit-course-layout-footer/edit-course-layout-footer";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { ProcessSidebar } from "components/process-sidebar/process-sidebar";
import { ProcessStep } from "utilities/interfaces/processes/process-step";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { useCourseProcessStatusDetermination } from "utilities/processes/courses/use-course-process-status-determination";
import { useEditCourseProcess } from "utilities/processes/courses/use-edit-course-process";
import { useProcessNavigator } from "utilities/hooks/processes/use-process-navigator";
import { t } from "utilities/localization/t";
import { useCreateCourseProcess } from "utilities/processes/courses/use-create-course-process";
import { useFeatureFlags } from "utilities/hooks/use-feature-flags";
import "./edit-course-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditCourseLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "edit-course-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditCourseLayout: React.FC<EditCourseLayoutProps> = (): JSX.Element => {
    const { id } = useParams();
    /* CV TO DO  :Check if the version is in the param */
    const { versionId } = useParams();

    const courseId = useMemo(() => NumberUtils.parseInt(id) ?? 0, [id]);
    const courseVersionId = useMemo(() => NumberUtils.parseInt(versionId) ?? 0, [versionId]);
    const [course, setCourse] = useState<CourseRecord>(new CourseRecord());
    const [courseVersion, setCourseVersion] = useState<CourseVersionRecord>(
        new CourseVersionRecord()
    );
    const [showActivationModal, setShowActivationModal] = useState<boolean>(false);
    const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
    const [showUnpublishModal, setShowUnpublishModal] = useState<boolean>(false);
    const [showDeactivationModal, setShowDeactivationModal] = useState<boolean>(false);
    const { get: getCourse } = CourseService.useGet();
    const { get: countProducts } = ProductService.useCount();
    const [productCount, setProductCount] = useState<number>(0);
    const { allowCourseUnpublish } = useFeatureFlags();

    const createCourseProcess = useCreateCourseProcess(
        courseId,
        course,
        courseVersionId,
        courseVersion
    );

    const editCourseProcess = useEditCourseProcess(
        courseId,
        course,
        courseVersionId,
        courseVersion
    );
    let courseProcess =
        course.status === ActiveStatus.Draft || course.activatedOn == null
            ? createCourseProcess
            : editCourseProcess;
    const courseProcessNavigator = useProcessNavigator(courseProcess);
    const courseProcessStatusDetermination = useCourseProcessStatusDetermination(course, courseId);
    const numberOfTabs: [string, ProcessStep][] = Object.entries(courseProcess);

    const calculatePercentageComplete = (): number => {
        let completeCount = 0;
        numberOfTabs.forEach(([key, step]: [string, ProcessStep]) => {
            if (step.isComplete()) {
                completeCount++;
            }
        });

        return Math.floor((completeCount / numberOfTabs.length) * 100);
    };

    const statusBadge = useMemo(
        () => (
            <EnumStatusBadge
                displayNames={ActiveStatusStatusDisplayNames}
                statusMap={ActiveStatusMap}
                value={course.status ?? ActiveStatus.Draft}
            />
        ),
        [course.status]
    );

    const getProductsCount = useCallback(async (): Promise<void> => {
        try {
            const countProductsQueryParams: CountProductsQueryParams = {
                courseId: course.id,
            };

            const productCountResponse = await countProducts({}, countProductsQueryParams);
            const productCountResult = productCountResponse?.result;
            if (productCountResult?.resultObject == null || productCountResult.hasErrors()) {
                alert(t("gettingProductsCountFailed"));
            }
            setProductCount(productCountResult?.resultObject ?? 0);
        } catch {
            ToastManager.error(t("failedToGetProductsCount"));
        }
    }, [countProducts, course.id]);

    useEffect(() => {
        getProductsCount();
    }, [getProductsCount]);

    const headerActionIcons: ButtonIconProps[] = useMemo(() => {
        const headerActionIcons: ButtonIconProps[] = [];

        if (
            allowCourseUnpublish &&
            course.status !== ActiveStatus.Draft &&
            courseVersion.versionId === 0
        ) {
            headerActionIcons.push({
                ariaLabelledBy: t("unpublishCourse"),

                onClick: () => setShowUnpublishModal(true),
                iconType: Icons.Reset,
            });
        }

        headerActionIcons.push({
            ariaLabelledBy: t("archiveCourse"),
            disabled: course.status === ActiveStatus.Archived,
            onClick: () => setShowArchiveModal(true),
            iconType: Icons.Archive,
        });

        return headerActionIcons;
    }, [allowCourseUnpublish, course.status, courseVersion.versionId]);

    const fetchData = useCallback(async (): Promise<void> => {
        const pathParams: GetCoursePathParams = {
            id: courseId,
        };
        const queryParams: GetCourseQueryParams = {
            includeContent: true,
            includeScormPackage: true,
        };

        try {
            const getResponse = await getCourse(pathParams, queryParams);
            const getResult = getResponse?.result;

            if (getResult?.resultObject == null || getResult.hasErrors()) {
                throw new Error();
            }
            setCourse(getResult.resultObject);
            setCourseVersion(getResult.resultObject.latestCourseVersion);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheCourse"));
        }
    }, [courseId, getCourse]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <CourseContext.Provider value={{ record: course, setRecord: setCourse }}>
            <CourseVersionContext.Provider
                value={{ record: courseVersion, setRecord: setCourseVersion }}>
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__window`}>
                        <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                            <ProcessSidebar
                                badges={[AudienceTypeDisplayNames[course.audienceType!]]}
                                completionPercentage={calculatePercentageComplete()}
                                headerActionIcons={headerActionIcons}
                                isActive={course.status === ActiveStatus.Active}
                                objectId={1}
                                process={courseProcess}
                                processNavigator={courseProcessNavigator}
                                showProgressChart={true}
                                statusBadge={statusBadge}
                                title={course.name}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__window__main`}>
                            <SkipNavContent>
                                <div className={`${CSS_CLASS_NAME}__body`}>
                                    <Outlet />
                                </div>
                            </SkipNavContent>
                        </div>
                    </div>
                    <EditCourseLayoutFooter
                        cssClassName={`${CSS_CLASS_NAME}__footer`}
                        processNavigator={courseProcessNavigator}
                        processStatusDetermination={courseProcessStatusDetermination}
                        process={courseProcess}>
                        {course.status === ActiveStatus.Draft && (
                            <ButtonIcon
                                ariaLabelledBy="Activate Course"
                                buttonStyle={ButtonStyle.Inverted}
                                disabled={calculatePercentageComplete() === 100 ? false : true}
                                iconType={Icons.ScreenShare}
                                onClick={() => setShowActivationModal(true)}
                                text="Activate Course"
                            />
                        )}
                        {course.status === ActiveStatus.Active && (
                            <ButtonIcon
                                ariaLabelledBy="Deactivate Course"
                                buttonStyle={ButtonStyle.Transparent}
                                iconType={Icons.StopScreenShare}
                                onClick={() => setShowDeactivationModal(true)}
                                text="Deactivate Course"
                            />
                        )}
                    </EditCourseLayoutFooter>
                </div>
                <CourseActivationModal
                    course={course}
                    courseContents={courseVersion.courseContents}
                    open={showActivationModal}
                    scormPackage={courseVersion.scormPackage}
                    setCourse={setCourse}
                    setOpen={setShowActivationModal}
                />
                <CourseArchiveModal
                    course={course}
                    open={showArchiveModal}
                    productCount={productCount}
                    setOpen={setShowArchiveModal}
                />
                <CourseUnpublishModal
                    course={course}
                    open={showUnpublishModal}
                    setOpen={setShowUnpublishModal}
                />
                <CourseDeactivationModal
                    course={course}
                    open={showDeactivationModal}
                    productCount={productCount}
                    setOpen={setShowDeactivationModal}
                />
            </CourseVersionContext.Provider>
        </CourseContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditCourseLayout };

// #endregion Exports
