import React from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { DateUtils } from "utilities/date-utils";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { StringUtils } from "utilities/string-utils";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { useGroup } from "utilities/contexts/use-group-context";
import "./group-info-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface GroupInfoPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "group-info-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const GroupInfoPage: React.FC<GroupInfoPageProps> = validatePageAccess(
    AccessControlKeys.GroupInfoPage
)((): JSX.Element => {
    const { record: group } = useGroup();

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                            {t("groupInformation")}
                        </Heading>
                        <Heading priority={HeadingPriority.H3} size={HeadingSize.XXSmall}>
                            {t("systemInformation")}
                        </Heading>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("lmsID")}>
                                    {group?.id ?? "--"}{" "}
                                </FormFieldReadonly>
                            </div>
                            <div>
                                <FormFieldReadonly label={t("dateCreated")}>
                                    {group?.createdOn == null
                                        ? "--"
                                        : `${DateUtils.formatDateCustom(
                                              group?.createdOn?.toString(),
                                              t("shortDateFormat")
                                          )}`}
                                </FormFieldReadonly>
                            </div>
                        </div>
                        <div
                            className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__information`}>
                            <div>
                                <FormFieldReadonly label={t("groupDescription")}>
                                    {StringUtils.valueOrDefault(group.description)}
                                </FormFieldReadonly>
                            </div>
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { GroupInfoPage };

// #endregion Exports
