import React, { useMemo, useState } from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { FormFieldReadonly } from "../../../../../../components/form/form-field-readonly/form-field-readonly";
import { ExtendExpirationModal } from "../../../../../../components/user-management/extend-expiration-modal/extend-expiration-modal";
import { DateUtils } from "utilities/date-utils";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { t } from "utilities/localization/t";
import "./user-training-detail-expiration.scss";
import { CollectionUtils } from "utilities/collection-utils";
import { EmptyText } from "components/empty-text/empty-text";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailExpirationProps {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-expiration";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailExpiration: React.FC<UserTrainingDetailExpirationProps> = ({
    enrollmentId,
}): JSX.Element => {
    const [showExtendModal, setShowExtendModal] = useState<boolean>(false);

    const { enrollment, extendExpiration } = useEnrollment({
        enrollmentId: enrollmentId,
        includeExpirationHistory: true,
    });

    const sortedExpirationChangeLogs = useMemo(
        () =>
            enrollment?.expirationChangeLogs?.sort(
                (a, b) => b.getLastUpdatedInMilliseconds() - a.getLastUpdatedInMilliseconds()
            ),
        [enrollment?.expirationChangeLogs]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("trainingAccessExpirationDate")}</h3>
            <div className={`${CSS_CLASS_NAME}__section`}>
                <FormFieldReadonly label={t("date").toLocaleUpperCase()}>
                    {enrollment?.expirationDate == null
                        ? "--"
                        : DateUtils.formatDateCustom(
                              enrollment.expirationDate?.toString(),
                              t("shortDateFormat")
                          )}
                </FormFieldReadonly>
                <Button
                    disabled={enrollment?.expirationDate == null}
                    onClick={() => {
                        setShowExtendModal(true);
                    }}
                    size={ButtonSize.Small}
                    style={ButtonStyle.Primary}
                    text={t("updateExpirationDate")}
                />
            </div>
            <h3>{t("trainingAccessExpirationHistory")}</h3>
            <div className={`${CSS_CLASS_NAME}__section`}>
                {CollectionUtils.hasValues(sortedExpirationChangeLogs) ? (
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("original")}</th>
                                <th>{t("new")}</th>
                                <th>{t("lastChange")}</th>
                                <th>{t("changedBy")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedExpirationChangeLogs?.map((expirationChangeLog) => (
                                <tr key={expirationChangeLog.id}>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {expirationChangeLog?.originalExpirationDate == null
                                                ? "--"
                                                : DateUtils.formatDateCustom(
                                                    expirationChangeLog?.originalExpirationDate?.toString(),
                                                    t("shortDateFormat")
                                                )}
                                        </Paragraph>
                                    </td>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {expirationChangeLog?.newExpirationDate == null
                                                ? "--"
                                                : DateUtils.formatDateCustom(
                                                    expirationChangeLog?.newExpirationDate?.toString(),
                                                    t("shortDateFormat")
                                                )}
                                        </Paragraph>
                                    </td>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {DateUtils.formatDateTime(
                                                true,
                                                expirationChangeLog?.createdOn?.toString(),
                                                true
                                            )}
                                        </Paragraph>
                                    </td>
                                    <td>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {expirationChangeLog.createdBy?.getFirstAndLastName()}
                                        </Paragraph>
                                        <Paragraph style={ParagraphStyle.Label}>
                                            {expirationChangeLog.createdById}
                                        </Paragraph>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                ) : (
                    <EmptyText table>{t("noHistoryAvailable")}</EmptyText>
                )}
            </div>
            <ExtendExpirationModal
                open={showExtendModal}
                setOpen={setShowExtendModal}
                currentExpirationDate={new Date(enrollment.expirationDate?.toString() ?? "")}
                onExpirationSave={extendExpiration}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailExpiration };

// #endregion Exports
