import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import {
    PatchProductServiceParams,
    PatchRoutes,
    ProductService,
} from "utilities/services/products/product-service";
import { ProductRecord } from "models/view-models/products/product-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import "./product-deactivation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDeactivationModalProps {
    product: ProductRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-deactivation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDeactivationModal: React.FC<ProductDeactivationModalProps> = ({
    product,
    open,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const { patch: productDeactivate } = ProductService.usePatch();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("deactivateProduct"),
            onClick: () => deactivateProduct(),
            style: ButtonStyle.Primary,
        },
    ];

    const deactivateProduct = async (): Promise<boolean> => {
        if (product.id == null) {
            return false;
        }
        setOpen(false);
        try {
            const patchProductServiceParams: PatchProductServiceParams = {
                productId: product.id,
                patchRoute: PatchRoutes.Deactivate,
            };

            const patchProductResponse = await productDeactivate(patchProductServiceParams);

            const patchProductResult = patchProductResponse?.result;
            if (patchProductResult?.resultObject == null || patchProductResult.hasErrors()) {
                alert(t("deactivatingProductFailed"));
            }
            navigate(sitemap.admin.product.list);
            ToastManager.success(t("productIsNowDeactivated"));
        } catch {
            ToastManager.error(t("failedToDeactivateProduct"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("deactivateProduct")}
            actions={modalActionArray}
            modalStyle={""}>
            <Heading
                cssClassName={`${CSS_CLASS_NAME}__section`}
                size={HeadingSize.XSmall}
                priority={HeadingPriority.H5}>
                {t("youAreDeactivatingTheProductPleaseReviewCarefullyBeforeDeactivating")}
            </Heading>
            {product.type === TrainingType.OnlineLearning && (
                <>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Large}>{t("newEnrollments")}</Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "deactivatingThisProductWillBeReflectedONLYWithinTheLMSBeSureToUpdateAllAssociatedSystemsIncludingTheNFPACatalogOnceDeactivatedThisProductWillNoLongerBeAvailableForNewEnrollmentsOrContracts"
                            )}
                        </Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("enrolledButNotStartedEnrolledAndInProgress")}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "theseLearnersWillBeAbleToFinishThisProductAndRetainAccessToTheCurrentVersionAssignedToThemUntilItExpires"
                            )}
                        </Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("enrolledAndCompleted")}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "thereWillBeNoImpactToTheseLearnersAndTheyWillRetainAccessToTheVersionTheyCompletedUntilItExpires"
                            )}
                        </Paragraph>
                    </div>
                </>
            )}
            {product.type === TrainingType.InstructorLedTraining && (
                <>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Large}>{t("newEvents")}</Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "thisProductWillNoLongerBeAvailableToUseForNewEventsIncludingAnyCurrentDraftEvents"
                            )}
                        </Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("liveOrPastCompletedEvents")}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "liveEventsRegardlessOfProgressAndAllPastCompletedEventsWillContinueToUseThisProduct"
                            )}
                        </Paragraph>
                    </div>
                </>
            )}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDeactivationModal };

// #endregion Exports
