import { FC, PropsWithChildren } from "react";
import { StringUtils } from "utilities/string-utils";
import "./banner.scss";

// -----------------------------------------------------------------------------------------
// #region Enums
// -----------------------------------------------------------------------------------------

enum BannerStyle {
    Dark = "-dark",
    Default = "",
    Error = "-error",
    Light = "-light",
    LightInverted = "-light-inverted",
    Neutral = "-neutral",
    Success = "-success",
    SuccessInverted = "-success-inverted",
    Warning = "-warning",
}

enum BannerFormat {
    Default = "",
    Stacked = "-stacked",
    Standalone = "-standalone",
    Box = "-box",
    Fixed = "-box -fixed",
    Center = "-center",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface BannerProps extends PropsWithChildren {
    cssClassName?: string;
    style?: BannerStyle;
    format?: BannerFormat;
    inverted?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "banner";
const DEFAULT_BANNER_STYLE: BannerStyle = BannerStyle.Default;
const DEFAULT_BANNER_FORMAT: BannerFormat = BannerFormat.Default;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Banner: FC<BannerProps> = (props: BannerProps): JSX.Element => {
    const style: BannerStyle = props.style ?? DEFAULT_BANNER_STYLE;
    const format: BannerFormat = props.format ?? DEFAULT_BANNER_FORMAT;
    const classNames: string[] = [CSS_CLASS_NAME, style, format];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    if (props.inverted) {
        classNames.push("-inverted");
    }

    const className: string = classNames.join(" ");

    return <span className={className}>{props.children}</span>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Banner, BannerStyle, BannerFormat };

// #endregion Exports
