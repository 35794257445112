import axios from "axios";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "contracts/:id/certificates";
const downloadContractCertificateEndpoint: string = `${baseEndpoint}/download`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ContractCertificateDownloadPathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const ContractCertificateService = {
    downloadContractCertificates: async (
        pathParams: ContractCertificateDownloadPathParams,
        downloadName: string
    ) => {
        try {
            const pathWithQueryParams = RouteUtils.getUrl(
                downloadContractCertificateEndpoint,
                pathParams
            );

            const response = await axios.get(pathWithQueryParams, {
                responseType: "blob",
            });

            if (response.status !== 200) {
                throw new Error();
            }

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = downloadName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            ToastManager.error(t("AnErrorOccurredWhileDownloadingTheContractCertificates"));
        }
    },
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ContractCertificateService };

// #endregion Exports
