import { FC, PropsWithChildren } from "react";
import { StringUtils } from "utilities/string-utils";
import "./empty-text.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum EmptyTextSpacing {
    Default = "",
    None = "-spacing-none",
    Small = "-spacing-small",
}

export enum EmptyTextTable {
    Default = "",
    Table = "-table",
}

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EmptyTextProps extends PropsWithChildren {
    cssClassName?: string;
    spacing?: EmptyTextSpacing;
    table?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "empty-text";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EmptyText: FC<EmptyTextProps> = (props: EmptyTextProps): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName);
    }

    if (props.spacing != null) {
        classNames.push(props.spacing);
    }

    if (props.table) {
        classNames.push("-table");
    }

    const className: string = classNames.join(" ");

    return <p className={className}>{props.children}</p>;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EmptyText };

// #endregion Exports
