import { ErrorLayoutHeader } from "layouts/errors/error-layout/error-layout-header/error-layout-header";
import React from "react";
import { Outlet } from "react-router-dom";
import "./error-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ErrorLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "error-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ErrorLayout: React.FC<ErrorLayoutProps> = (): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <ErrorLayoutHeader cssClassName={`${CSS_CLASS_NAME}__header`} />
            <main>
                <Outlet />
            </main>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ErrorLayout };

// #endregion Exports
