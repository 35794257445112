import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { ProductRecord } from "models/view-models/products/product-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./product-activation-evaluation-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductActivationEvaluationSectionProps {
    product: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-activation-evaluation-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductActivationEvaluationSection: React.FC<ProductActivationEvaluationSectionProps> = ({
    product,
}): JSX.Element => {
    return (
        <div className={`${CSS_CLASS_NAME}__section`}>
            {(product.type === TrainingType.OnlineLearning ||
                product.type === TrainingType.InstructorLedTraining) && (
                <>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("evaluation")}
                    </Heading>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("template")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={`${product.id}${product.evaluationTemplate?.id}`}>
                                <td>{product.evaluationTemplate?.name}</td>
                            </tr>
                        </tbody>
                    </DataTable>
                </>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductActivationEvaluationSection };

// #endregion Exports
