import React, { useState } from "react";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { Modal, ModalAction } from "components/modal/modal";
import { ButtonStyle } from "components/buttons/button/button";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { StringUtils } from "utilities/string-utils";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-cancellation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventCancellationModalProps {
    event: EventActiveRecord;
    onModalClose: () => void;
    open: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-cancellation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventCancellationModal: React.FC<EventCancellationModalProps> = (
    props: EventCancellationModalProps
): JSX.Element => {
    const [cancellationMessage, setCancellationMessage] = useState("");
    const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
    const cancelDisabled: boolean =
        !cancellationConfirmed || !StringUtils.hasValue(cancellationMessage);

    const reset = () => {
        setCancellationMessage("");
        setCancellationConfirmed(false);
    };

    const handleCancellationConfirmedChanged = (): void => {
        setCancellationConfirmed(!cancellationConfirmed);
    };

    const handleCancellationMessageChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        setCancellationMessage(event.target.value);
    };

    const handleCancellingEvent = async () => {
        props.event.cancel(cancellationMessage);

        handleModalClose();
    };

    const handleModalClose = (): void => {
        reset();
        props.onModalClose();
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: handleModalClose,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("cancelEvent"),
            disabled: cancelDisabled,
            onClick: handleCancellingEvent,
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={handleModalClose}
            title={t("cancelEvent")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Paragraph cssClassName="-description">
                    {t(
                        "thisEventWillBeCanceledAndParticipantsWillBeNotifiedThisActionCannotBeUndoneYoullBeAbleToAccessTheEventPageButWontBeAbleToEditTheEvent"
                    )}
                </Paragraph>
                <Paragraph cssClassName={"-message-description"} style={ParagraphStyle.Light}>
                    {t(
                        "youCanAddAShortCancellationMessageThisMessageWillBeCommunicatedToParticipantsAndDisplayedInTheLMS"
                    )}
                </Paragraph>
                <FormTextArea
                    cssClassName="-cancellation-message"
                    id="cancellationMessage"
                    formFieldName="cancellationMessage"
                    label={t("cancellationMessage")}
                    maxLength={500}
                    required={true}
                    autoFocus={true}
                    value={cancellationMessage}
                    onChange={handleCancellationMessageChange}
                    placeholder=""
                    rows={5}
                />
                <FormCheckboxInput
                    disabled={!StringUtils.hasValue(cancellationMessage)}
                    checked={cancellationConfirmed}
                    formFieldName="cancellationConfirmedCheckbox"
                    id="cancellationConfirmedCheckbox"
                    label={t("yesIWantToCancelThisEventAndUnderstandThisActionCannotBeUndone")}
                    onChange={handleCancellationConfirmedChanged}
                    size={CheckboxSize.Small}
                    type={InputTypes.Checkbox}
                    value={cancellationConfirmed}
                />
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventCancellationModal };

// #endregion Exports
