import { RoleType } from "models/enumerations/users/role-type";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { Language } from "models/enumerations/languages/language";
import { useCallback, useMemo } from "react";

interface NavigationItem {
    path: string;
}

interface UseGetUserDashboardHook {
    dashboardPath: string;
    navigateToDashboard: () => void;
}

interface UseGetUserDashboardHookOptions {
    userRole?: RoleType;
    language?: Language;
}

// #region Constants

const DASHBOARDS: Record<RoleType, NavigationItem> = {
    [RoleType.NfpaAdministrator]: {
        path: sitemap.admin.dashboard,
    },
    [RoleType.Learner]: {
        path: sitemap.learner.dashboard,
    },
    [RoleType.Instructor]: {
        path: sitemap.instructor.dashboard,
    },
    [RoleType.ClientAdmin]: {
        path: sitemap.thirdPartyProvider.dashboard.online,
    },
    [RoleType.NfpaOperator]: {
        path: sitemap.nfpaOperators.dashboard,
    },
    [RoleType.NfpaSupport]: {
        path: sitemap.nfpaSupport.dashboard,
    },
    [RoleType.AenAdministrator]: {
        path: sitemap.aenProvider.dashboard,
    },
    [RoleType.Reviewer]: {
        path: sitemap.reviewer.dashboard,
    },
};

// #endregion Constants

export function useGetUserDashboard(
    props: UseGetUserDashboardHookOptions
): UseGetUserDashboardHook {
    const userRole = props.userRole;
    const language = props.language;

    const dashboardPath = useMemo(() => {
        const path: string =
            userRole == null ? sitemap.public.userLogin : DASHBOARDS[userRole].path;

        const localizedPath = RouteUtils.localizePath(path, language);

        return localizedPath;
    }, [language, userRole]);

    const navigateToDashboard = useCallback(() => {
        window.location.href = dashboardPath;
    }, [dashboardPath]);

    return {
        dashboardPath: dashboardPath,
        navigateToDashboard: navigateToDashboard,
    };
}
