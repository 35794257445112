import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Banner, BannerStyle } from "components/banner/banner";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Button, ButtonStyle } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { CancelChangesModal } from "components/modal/cancel-changes-modal/cancel-changes-modal";
import { CollectionUtils } from "andculturecode-javascript-core";
import { HeaderActions } from "components/header-actions/header-actions";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { NavigationRequest } from "utilities/hooks/navigation/use-navigation-requests";
import { NotificationBanner } from "components/notification-banner/notification-banner";
import { NotificationType } from "models/enumerations/notifications/notification-type";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ProductDetailsChangeHistory } from "components/products/product-details/product-details-change-history/product-details-change-history";
import { ProductDetailsChangesModal } from "components/products/product-details/product-details-changes-modal/product-details-changes-modal";
import { ProductDetailsForm } from "components/products/product-details/product-details-form/product-details-form";
import { ProductLayout } from "layouts/admin/product-layout/product-layout";
import { ProductRecord } from "models/view-models/products/product-record";
import { ReadOnlyContext } from "utilities/contexts/use-read-only-context";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import {
    useListenForNavigationRequests,
    useResolveNavigationRequest,
} from "utilities/contexts/navigation/use-navigation-request-context";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductDetailsArchive } from "utilities/hooks/models/products/use-product-detail-archives";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./product-details-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDetailsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-details-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDetailsPage: React.FC<ProductDetailsPageProps> = (): JSX.Element => {
    const { record: product, setRecord: setProduct } = useProduct();
    const [readOnly, setReadOnly] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const activeProduct = !editMode && product.activatedOn != null;
    const [showEditModeModal, setShowEditModeModal] = useState<boolean>(false);
    const [showCancelChangesModal, setShowCancelChangesModal] = useState<boolean>(false);
    const [showSaveDetailsChangesModal, setShowSaveDetailsChangesModal] = useState<boolean>(false);

    useRedirectOnForbidden(sitemap.admin.product.list);
    const [dirty, setDirty] = useState<boolean>(false);
    const [navigationRequest, setNavigationRequest] = useState<NavigationRequest>();
    const showCancelEditOnNavigationModal = useMemo(
        (): boolean => navigationRequest != null,
        [navigationRequest]
    );
    const navigationRequests = useListenForNavigationRequests(ProductDetailsPage.name, [
        Breadcrumbs.name,
        ProductLayout.name,
    ]);

    const resolveNavigationRequest = useResolveNavigationRequest();

    useEffect(() => {
        if (CollectionUtils.isEmpty(navigationRequests)) {
            return;
        }

        const navigationRequest = navigationRequests[0];

        if (!editMode || !dirty) {
            resolveNavigationRequest(
                ProductDetailsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
            return;
        }
        setNavigationRequest(navigationRequest);
    }, [editMode, dirty, navigationRequests, resolveNavigationRequest]);

    const handleNavigationApproved = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest?.onNavigationApproved != null) {
            resolveNavigationRequest(
                ProductDetailsPage.name,
                navigationRequest.requestingComponent
            );
            navigationRequest.onNavigationApproved();
        }
    }, [navigationRequest, resolveNavigationRequest]);

    const handleNavigationDenied = useCallback((): void => {
        setNavigationRequest(undefined);

        if (navigationRequest == null) {
            return;
        }

        resolveNavigationRequest(ProductDetailsPage.name, navigationRequest.requestingComponent);
    }, [navigationRequest, resolveNavigationRequest]);

    const cancelEditModeOnNavigationConfirmationActions: ModalAction[] = [
        {
            buttonText: t("noKeepEditing"),
            onClick: handleNavigationDenied,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("yesLeave"),
            onClick: handleNavigationApproved,
            style: ButtonStyle.Destructive,
        },
    ];

    const enterEditModalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowEditModeModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToEdit"),
            onClick: () => onEnterEditModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onEnterEditModalClose = () => {
        setShowEditModeModal(false);
        setEditMode(true);
    };

    const onCancelEditModalClose = () => {
        setShowCancelChangesModal(false);
        setEditMode(false);
    };

    var { productDetailsArchive, setRefresh } = useProductDetailsArchive({
        productId: product.id,
        includeCreatedBy: true,
        includeLastModifiedBy: true,
    });

    useEffect(() => {
        setReadOnly(
            editMode
                ? false
                : product.status === ActiveStatus.Active ||
                      product.status === ActiveStatus.Inactive ||
                      product.status === ActiveStatus.Archived
        );
    }, [editMode, product.status]);

    const handleProductDetailsChange = (product: ProductRecord): void => {
        setProduct(product);
    };

    return (
        <>
            {product.status === ActiveStatus.Archived && (
                <Banner
                    cssClassName={`${CSS_CLASS_NAME}__archived-banner`}
                    style={BannerStyle.Dark}>
                    {t("youAreViewingAnArchivedProduct")}
                </Banner>
            )}
            <div className={`${CSS_CLASS_NAME}`}>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <HeaderActions
                        title={t("productDetails")}
                        headingPriority={HeadingPriority.H1}
                        headingSize={HeadingSize.Small}>
                        {(activeProduct || product.status === ActiveStatus.Archived) && (
                            <Button
                                disabled={
                                    product.status === ActiveStatus.Archived ||
                                    product.status === ActiveStatus.Inactive
                                }
                                onClick={() => setShowEditModeModal(true)}
                                style={ButtonStyle.Primary}
                                text={t("editProductDetails")}
                            />
                        )}
                        {editMode && (
                            <div className={`${CSS_CLASS_NAME}__edit-mode-buttons`}>
                                <Button
                                    onClick={() => setShowCancelChangesModal(true)}
                                    style={ButtonStyle.Secondary}
                                    text={t("cancelEdit")}
                                />
                                <ButtonIcon
                                    buttonStyle={ButtonStyle.Primary}
                                    iconType={Icons.PublishWithChanges}
                                    onClick={() => setShowSaveDetailsChangesModal(true)}
                                    text={t("saveChanges")}
                                />
                            </div>
                        )}
                    </HeaderActions>
                </div>
                {editMode && (
                    <div className={`${CSS_CLASS_NAME}__banner`}>
                        <NotificationBanner
                            notificationId={0}
                            onClose={() => {}}
                            style={NotificationType.Default}
                            message={t(
                                "changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        />
                    </div>
                )}
                <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
                    <div className={`${CSS_CLASS_NAME}__content`}>
                        <ProductDetailsForm
                            editMode={editMode}
                            product={product}
                            onProductDetailsChange={handleProductDetailsChange}
                            setDirty={setDirty}
                            setProduct={setProduct}
                            setRefresh={setRefresh}
                        />
                        {product.activatedOn != null && (
                            <>
                                <div className={`${CSS_CLASS_NAME}__divider`}></div>
                                <ProductDetailsChangeHistory
                                    productDetailsArchiveData={productDetailsArchive}
                                />
                            </>
                        )}
                    </div>
                </ReadOnlyContext.Provider>
                <CancelChangesModal
                    open={showCancelChangesModal}
                    setOpen={setShowCancelChangesModal}
                    onModalClose={onCancelEditModalClose}
                />
                <Modal
                    actions={enterEditModalActionArray}
                    isOpen={showEditModeModal}
                    onModalClose={() => setShowEditModeModal(false)}
                    title={t("editingMode")}>
                    <div>
                        <Heading
                            cssClassName={`${CSS_CLASS_NAME}__modal-heading`}
                            priority={HeadingPriority.H5}
                            size={HeadingSize.XSmall}>
                            {t("youAreGoingToEditTheProductDetails")}
                        </Heading>
                        <Paragraph size={ParagraphSize.Large}>
                            {t("howChangesAreApplied")}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.Medium} style={ParagraphStyle.Light}>
                            {t(
                                "changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        </Paragraph>
                    </div>
                </Modal>
                <ProductDetailsChangesModal
                    productDetailsWithEdits={product}
                    setEditMode={setEditMode}
                    setShowSaveDetailsChangesModal={setShowSaveDetailsChangesModal}
                    showSaveDetailsChangesModal={showSaveDetailsChangesModal}
                    setRefresh={setRefresh}
                />
                <Modal
                    cssClassName={`${CSS_CLASS_NAME}__cancel-edit-mode-modal`}
                    isOpen={showCancelEditOnNavigationModal}
                    onModalClose={() => {}}
                    actions={cancelEditModeOnNavigationConfirmationActions}
                    modalStyle={"-inverted"}>
                    {t("areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges")}
                </Modal>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDetailsPage };

// #endregion Exports
