import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EventRecord } from "models/view-models/events/event-record";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import {
    EventService,
    GetEventPathParams,
    GetEventQueryParams,
} from "utilities/services/events/event-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseEventHook {
    event: EventRecord;
    isLoading: boolean;
    errors?: ResultErrorRecord[];
    setEvent: Dispatch<SetStateAction<EventRecord>>;
}

interface UseEventHookOptions extends GetEventQueryParams {
    eventId?: number | string;
}

export function useEvent(props: UseEventHookOptions): UseEventHook {
    const includeCanceledBy = props.includeCanceledBy ?? false;
    const includeCheckList = props.includeChecklist ?? false;
    const includeContract = props.includeContract ?? false;
    const includeEventSessions = props.includeEventSessions ?? false;
    const includeEventScheduleException = props.includeEventScheduleException ?? false;
    const includeInstructor = props.includeInstructor ?? false;
    const includeOrganization = props.includeOrganization ?? false;
    const includeProduct = props.includeProduct ?? false;
    const includeProvider = props.includeProvider ?? false;

    const [isLoading, setIsLoading] = useState(true);
    const [event, setEvent] = useState<EventRecord>(new EventRecord());
    const [errors, setErrors] = useState<ResultErrorRecord[]>();
    const eventId = NumberUtils.parseInt(props.eventId) ?? 0;

    const { get: getEventAPI } = EventService.useGet();

    useEffect(() => {
        if (isNaN(eventId) || eventId < 1) {
            return;
        }

        (async function getEvent() {
            try {
                const pathParams: GetEventPathParams = {
                    id: eventId,
                };

                const queryParams: GetEventQueryParams = {
                    includeCanceledBy: includeCanceledBy,
                    includeChecklist: includeCheckList,
                    includeContract: includeContract,
                    includeEventSessions: includeEventSessions,
                    includeEventScheduleException: includeEventScheduleException,
                    includeInstructor: includeInstructor,
                    includeOrganization: includeOrganization,
                    includeProduct: includeProduct,
                    includeProvider: includeProvider,
                };

                const getResponse = await getEventAPI(pathParams, queryParams);

                const getResult = getResponse?.result;
                const event = getResponse?.resultObject;

                if (event == null || getResult == null || getResult.hasErrors()) {
                    throw new Error();
                }
                event.eventDays?.sort((a, b) => a.eventDate().valueOf() - b.eventDate().valueOf());

                setEvent(event);

                setIsLoading(false);
            } catch (err) {
                ToastManager.error(t("thereWasAProblemLoadingTheEvent"));
                setIsLoading(false);
                setErrors([
                    new ResultErrorRecord({
                        message: `${err}`,
                    }),
                ]);
            }
        })();
    }, [
        eventId,
        getEventAPI,
        includeEventSessions,
        includeProvider,
        includeOrganization,
        includeCanceledBy,
        includeEventScheduleException,
        includeInstructor,
        includeProduct,
        includeCheckList,
        includeContract,
    ]);

    return {
        errors,
        isLoading,
        event,
        setEvent,
    };
}
