import axios from "axios";
import { CourseContentRecord } from "models/view-models/courses/course-content-record";
import { ServiceHookFactory, useCancellablePromise } from "andculturecode-javascript-react";
import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "coursecontents";
const deleteAndCreateEndpoint: string = `${baseEndpoint}/:id/deleteandcreate`;
const resourceType: typeof CourseContentRecord = CourseContentRecord;
const resourceEndpoint = `${baseEndpoint}/:id`;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type CreateCourseContentService = (
    params: CreateCourseContentParams
) => Promise<ServiceResponse<CourseContentRecord>>;

type DeleteAndCreateCourseContentService = (
    params: CreateCourseContentParams,
    id?: number
) => Promise<ServiceResponse<CourseContentRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface CreateCourseContentParams {
    courseId: number;
    courseVersionId: number;
    contentId: number;
    sortOrder?: number;
}
export interface ListCourseContentQueryParams {
    courseVersionId: number;
    includeContent?: boolean;
}

export interface UpdateCourseContentPathParams {
    id: number;
}
// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const CourseContentService = {
    /**
     * Custom hook for leveraging service create calls in React components
     */
    useCreate: (): { create: CreateCourseContentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: CreateCourseContentService = (
            params: CreateCourseContentParams
        ): Promise<ServiceResponse<CourseContentRecord>> => {
            return axios
                .post(baseEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            params: CreateCourseContentParams
        ): Promise<ServiceResponse<CourseContentRecord>> {
            return cancellablePromise(serviceCreate(params)) as Promise<
                ServiceResponse<CourseContentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service create calls in React components
     */
    useDeleteAndCreate: (): { create: DeleteAndCreateCourseContentService } => {
        const { cancellablePromise } = useCancellablePromise();

        const serviceCreate: DeleteAndCreateCourseContentService = (
            params: CreateCourseContentParams,
            id?: number
        ): Promise<ServiceResponse<CourseContentRecord>> => {
            const endPointWithPathIdsReplaced = deleteAndCreateEndpoint.replace(
                ":id",
                id!.toString()
            );

            return axios
                .post(endPointWithPathIdsReplaced, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function create(
            params: CreateCourseContentParams,
            id?: number
        ): Promise<ServiceResponse<CourseContentRecord>> {
            return cancellablePromise(serviceCreate(params, id)) as Promise<
                ServiceResponse<CourseContentRecord>
            >;
        }

        return { create: useCallback(create, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service delete calls in react components
     */
    useDelete: ServiceHookFactory.useDelete(resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<CourseContentRecord, ListCourseContentQueryParams>(
        resourceType,
        baseEndpoint
    ),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<CourseContentRecord, UpdateCourseContentPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentService };

// #endregion Exports
