import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { LanguageDisplayNames } from "models/enumerations/languages/language";
import { TopicBubble } from "components/bubble/topic-bubble";
import { TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import "./event-activation-product-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationProductSectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-product-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationProductSection: React.FC<EventActivationProductSectionProps> = ({
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("associatedProduct")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th className="-name">{t("name")}</th>
                        <th className="-id">{t("id")}</th>
                        <th className="-type">{t("type")}</th>
                        <th className="-language">{t("language")}</th>
                        <th className="-topic">{t("topic")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={event.productId}>
                        <td className="-name">{event.product?.name}</td>
                        <td className="-id">{event.productId}</td>
                        <td className="-type">
                            {event.product?.type == null
                                ? null
                                : t(TrainingTypeDisplayNames[event.product?.type])}
                        </td>
                        <td className="-language">
                            {t(LanguageDisplayNames[event.product?.language!])}
                        </td>
                        <td className="-topic">
                            <TopicBubble topic={event.product?.topic!} />
                        </td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationProductSection };

// #endregion Exports
