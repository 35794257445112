import { ResultErrorRecord } from "andculturecode-javascript-core";
import { EnrollmentUnitCourseRecord } from "models/view-models/enrollments/enrollment-unit-course-record";
import { useEffect, useState } from "react";
import { t } from "utilities/localization/t";
import { NumberUtils } from "utilities/number-utils";
import { EnrollmentUnitCourseService } from "utilities/services/enrollments/enrollment-unit-course-service";
import { ToastManager } from "utilities/toast/toast-manager";

interface UseEnrollmentUnitCourseHook {
    enrollmentUnitCourse?: EnrollmentUnitCourseRecord;
    isLoading: boolean;
    errors: ResultErrorRecord[];
}

interface UseEnrollmentUnitCourseHookOptions {
    enrollmentId?: number | string;
    courseId?: number | string;
    scormPackageAssessmentId?: number | string;
    enrollmentUnitCourseId?: number | string;
}

export function useEnrollmentUnitCourse(
    props: UseEnrollmentUnitCourseHookOptions
): UseEnrollmentUnitCourseHook {
    const enrollmentId = NumberUtils.parseInt(props.enrollmentId) ?? 0;
    const courseId = NumberUtils.parseInt(props.courseId);
    const scormPackageAssessmentId = NumberUtils.parseInt(props.scormPackageAssessmentId);
    const enrollmentUnitCourseId = NumberUtils.parseInt(props.enrollmentUnitCourseId) ?? 0;
    const [isLoading, setIsLoading] = useState(true);
    const [enrollmentUnitCourse, setEnrollmentUnitCourse] = useState<EnrollmentUnitCourseRecord>();
    const [errors, setErrors] = useState<ResultErrorRecord[]>([]);
    const { list: listEnrollmentUnitCourse } = EnrollmentUnitCourseService.useList();

    useEffect(() => {
        if (isNaN(enrollmentId) || enrollmentId < 1) {
            return;
        }

        (async function fetchEnrollmentUnitCourse() {
            try {
                const [enrollmentUnitCourseResponse] = await Promise.all([
                    listEnrollmentUnitCourse(
                        {
                            enrollmentId: enrollmentId,
                        },
                        { courseId, scormPackageAssessmentId }
                    ),
                ]);

                setIsLoading(false);

                if (enrollmentUnitCourseResponse.result?.hasErrors()) {
                    const { errors: enrollmentUnitCourseErrors = [] } =
                        enrollmentUnitCourseResponse.result ?? {};

                    setErrors([...enrollmentUnitCourseErrors]);
                    return;
                }

                setEnrollmentUnitCourse(enrollmentUnitCourseResponse.resultObjects[0]);
            } catch (err) {
                ToastManager.error(t("thereWasAnIssueLoadingCourseDetails"));
                setIsLoading(false);
                setErrors([new ResultErrorRecord({ message: `${err}` })]);
            }
        })();
    }, [
        courseId,
        enrollmentId,
        enrollmentUnitCourseId,
        listEnrollmentUnitCourse,
        scormPackageAssessmentId,
    ]);

    return {
        errors,
        isLoading,
        enrollmentUnitCourse,
    };
}
