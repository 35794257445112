import React, { useState } from "react";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { StringUtils } from "utilities/string-utils";
import "./accordion.scss";

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

export enum AccordionStyle {
    Card = "-card",
    Default = "",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface AccordionProps {
    ariaLabelledBy?: string;
    cssClassName?: string;
    number?: number;
    style?: AccordionStyle;
    subTitle?: string;
    text?: string;
    title?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "accordion";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = (
    props: React.PropsWithChildren<AccordionProps>
) => {
    const [isActive, setIsActive] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("__button");

    const openAccordion = () => {
        setIsActive(!isActive);
        if (isActive) {
            setButtonStyle("__button");
        } else {
            setButtonStyle("__button-open");
        }
    };

    const stopPropagation = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
    };

    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    if (props.style) {
        cssClassNames.push(props.style);
    }

    return (
        <div className={cssClassNames.join(" ")} title={props.ariaLabelledBy}>
            <div className={`${CSS_CLASS_NAME}__item`}>
                <h2 className={`${CSS_CLASS_NAME}__heading`}>
                    <button type="button" onClick={() => openAccordion()} className={`${CSS_CLASS_NAME}${buttonStyle}`}>
                        {
                            // if
                            StringUtils.hasValue(props.title) && (
                                <span className={`${CSS_CLASS_NAME}__title`}>
                                    {props.number && (
                                        <span className="-number">
                                            {props.number}
                                            {". "}
                                        </span>
                                    )}
                                    {props.title}
                                </span>
                            )
                        }
                        <div className={`${CSS_CLASS_NAME}__button-right`}>
                            <div className={`${CSS_CLASS_NAME}__subtitle`}>
                                {props.subTitle?.toUpperCase()}
                            </div>
                            <div className={`${CSS_CLASS_NAME}__chevron`}>
                                {isActive ? (
                                    <Icon type={Icons.ChevronUp} size={IconSizes.Large} />
                                ) : (
                                    <Icon type={Icons.ChevronDown} size={IconSizes.Large} />
                                )}
                            </div>
                        </div>
                    </button>
                </h2>
                {isActive && (
                    <button 
                        type="button"
                        className={`${CSS_CLASS_NAME}__details`}
                        onClick={(e) => stopPropagation(e)}>
                        {props.children}
                    </button>
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Accordion };

// #endregion Exports
