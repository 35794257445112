import React from "react";
import { StringUtils } from "andculturecode-javascript-core";
import "./side-content-left-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface SideContentLeftLayoutProps {
    cssClassName?: string;
    sidebarElement: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "side-content-left-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SideContentLeftLayout: React.FC<React.PropsWithChildren<SideContentLeftLayoutProps>> = (
    props: React.PropsWithChildren<SideContentLeftLayoutProps>
): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const cssClassName: string = cssClassNames.join(" ");

    return (
        <div className={cssClassName}>
            <div>{props.sidebarElement}</div>
            <div>{props.children}</div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SideContentLeftLayout };

// #endregion Exports
