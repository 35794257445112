import React, { useState } from "react";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { EvaluationTemplateService } from "utilities/services/evaluation-templates/evaluation-template-service";
import { FormSelect } from "components/form/form-select/form-select";
import { NumberUtils } from "utilities/number-utils";
import { Product } from "models/interfaces/products/product";
import { ProductRecord } from "models/view-models/products/product-record";
import {
    ProductService,
    UpdateProductPathParams,
} from "utilities/services/products/product-service";
import { RecordUpdater } from "utilities/contexts/use-record-context-factory";
import { SelectOption } from "components/form/inputs/select/select";
import { ToastManager } from "utilities/toast/toast-manager";
import { useAsyncEffect } from "andculturecode-javascript-react";
import { t } from "utilities/localization/t";
import "./product-evaluation-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductEvaluationFormProps {
    product: ProductRecord;
    setProduct: RecordUpdater<Product, ProductRecord>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-evaluation-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductEvaluationForm: React.FC<ProductEvaluationFormProps> = (
    props: ProductEvaluationFormProps
): JSX.Element => {
    const { update } = ProductService.useUpdate();
    const [templateOptions, setTemplateOptions] = useState<SelectOption[]>([]);
    const [evaluationTemplates, setEvaluationTemplates] = useState<EvaluationTemplateRecord[]>();
    const { list: evaluationTemplateList } = EvaluationTemplateService.useList();

    useAsyncEffect(async () => {
        try {
            const listTemplatesResponse = await evaluationTemplateList();

            if (
                listTemplatesResponse?.resultObjects == null ||
                listTemplatesResponse.results == null ||
                listTemplatesResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setEvaluationTemplates(listTemplatesResponse.resultObjects);
            const selectOptions = listTemplatesResponse.resultObjects.map(
                (template: EvaluationTemplateRecord): SelectOption => ({
                    text: template.name,
                    value: template.id!.toString(),
                })
            );
            setTemplateOptions(selectOptions);
        } catch {
            ToastManager.error(t("thereWasAnIssueLoadingTheEvaluationTemplates"));
            setTemplateOptions([]);
        }
    }, []);

    const handleTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const evaluationTemplateId = NumberUtils.parseInt(event.target.value);
        if (evaluationTemplateId == null || props.product.id == null) {
            throw new Error();
        }
        const template = evaluationTemplates?.find((t) => t.id === evaluationTemplateId);

        props.setProduct(
            props.product.with({
                evaluationTemplateId: evaluationTemplateId,
                evaluationTemplate: template,
            })
        );
    };

    const updateProduct = async (): Promise<boolean> => {
        try {
            const updateProductPathParms: UpdateProductPathParams = {
                id: props.product.id!,
            };
            const updateProductResponse = await update(props.product, updateProductPathParms);

            const updateResult = updateProductResponse?.result;

            if (updateResult?.resultObject == null || updateResult.hasErrors()) {
                throw new Error();
            }
        } catch {
            ToastManager.error(t("thereWasAnIssueUpdatingTheEvaluationTemplate"));
            props.setProduct(props.product!);
            return false;
        }
        return true;
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <FormSelect
                ariaLabelledBy={t("templateSelection")}
                formFieldName="evaluationTemplateSelection"
                id="evaluationTemplateSelection"
                label={t("template")}
                onBlur={updateProduct}
                onChange={handleTemplateChange}
                options={templateOptions}
                value={props.product.evaluationTemplateId?.toString()}
                required={true}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductEvaluationForm };

// #endregion Exports
