import React, { useCallback, useState } from "react";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormCheckboxInput } from "components/form/form-checkbox-input/form-checkbox-input";
import { FormSelect } from "components/form/form-select/form-select";
import { InputTypes } from "components/form/enumerations/input-types";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { RoleType } from "models/enumerations/users/role-type";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { TravelType, TravelTypeDisplayNames } from "models/instructors/travel-type";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useLocation } from "react-router-dom";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./event-instructor-refine-results.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventInstructorRefineResultsProps {
    disabled?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-instructor-refine-results";
const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventInstructorRefineResults: React.FC<EventInstructorRefineResultsProps> = ({
    disabled,
}): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const { record: globalState } = useGlobalState();
    const currentRole = globalState.currentIdentity?.role?.roleType;

    const [searchText, setSearchText] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [selectedTeachingMethod, setSelectedTeachingMethod] = useState<string>();
    const [selectedTravelType, setSelectedTravelType] = useState<string>();
    const [approvedToTeach, setApprovedToTeach] = useState<boolean>(true);

    const setQueryParams = useCallback(
        (
            searchText: string,
            selectedLanguage: string,
            teachingMethod: string,
            travelType: string,
            approvedToTeach: boolean
        ) => {
            const sideBarFilterParams = {
                approvedToTeach: approvedToTeach,
                onlyInstructorsWithSharedLocation: true,
                language: selectedLanguage,
                searchText: encodeURIComponent(searchText),
                teachingMethod: teachingMethod,
                travelType: travelType,
            };

            const filterParams = Object.entries(sideBarFilterParams)
                .filter(([_, value]) => !!value)
                .map(([key, value]) => `${key}=${value}`)
                .join("&");
            const search = `?${filterParams}`;

            navigate({
                pathname: location.pathname,
                search: search,
            });
        },
        [location.pathname, navigate]
    );

    const handleSearchTriggered = useCallback(
        (searchText: string): void => {
            setQueryParams(
                searchText,
                selectedLanguage ?? "",
                selectedTeachingMethod ?? "",
                selectedTravelType ?? "",
                approvedToTeach
            );
        },
        [
            approvedToTeach,
            selectedLanguage,
            selectedTeachingMethod,
            selectedTravelType,
            setQueryParams,
        ]
    );

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        setQueryParams(
            searchText,
            newLanguage,
            selectedTeachingMethod ?? "",
            selectedTravelType ?? "",
            approvedToTeach
        );
    };

    const handleFilterByTeachingMethodChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const newTeachingMethod = event.target.value;
        setSelectedTeachingMethod(newTeachingMethod);
        setQueryParams(
            searchText,
            selectedLanguage ?? "",
            newTeachingMethod,
            selectedTravelType ?? "",
            approvedToTeach
        );
    };

    const handleFilterByTravelTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newTravelType = event.target.value;
        setSelectedTravelType(newTravelType);
        setQueryParams(
            searchText,
            selectedLanguage ?? "",
            selectedTeachingMethod ?? "",
            newTravelType,
            approvedToTeach
        );
    };

    const handleSetApprovedToTeach = (checked: boolean): void => {
        const newApprovedToTeach = checked;
        setApprovedToTeach(newApprovedToTeach);
        setQueryParams(
            searchText,
            selectedLanguage ?? "",
            selectedTeachingMethod ?? "",
            selectedTravelType ?? "",
            newApprovedToTeach
        );
    };

    const handleClearAll = () => {
        setSearchText("");
        setSelectedLanguage(undefined);
        setSelectedTeachingMethod(undefined);
        setSelectedTravelType(undefined);
        setApprovedToTeach(true);
        setQueryParams("", "", "", "", true);
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                disabled={disabled}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={handleSearchTriggered}
                id={"instructorSearch"}
                placeholder={t("searchByNameEmailOrLocation")}
                searchTextInputValue={searchText}
            />
            <FormSelect
                ariaLabelledBy={t("filterByLanguage")}
                disabled={disabled}
                formFieldName={t("filterByLanguage")}
                id="instructorFilterByLanguage"
                label=""
                placeholder={t("filterByLanguage")}
                onChange={handleFilterByLanguageChange}
                options={EnumUtils.numericEnumToSelectOptions(Language, LanguageDisplayNames)}
                value={selectedLanguage?.toString()}
            />
            <FormSelect
                ariaLabelledBy={t("filterByTeachingMethods")}
                disabled={disabled}
                formFieldName={t("filterByTeachingMethods")}
                id="instructorFilterByTeachingMethods"
                label=""
                placeholder={t("filterByTeachingMethods")}
                onChange={handleFilterByTeachingMethodChange}
                options={EnumUtils.numericEnumToSelectOptions(
                    InstructorLedTrainingType,
                    InstructorLedTrainingTypeDisplayNames
                )}
                value={selectedTeachingMethod?.toString()}
            />
            <FormSelect
                ariaLabelledBy={t("filterByTravelPreferences")}
                disabled={disabled}
                formFieldName={t("filterByTravelPreferences")}
                id="instructorFilterByTravelPreferences"
                label=""
                placeholder={t("filterByTravelPreferences")}
                onChange={handleFilterByTravelTypeChange}
                options={EnumUtils.numericEnumToSelectOptions(TravelType, TravelTypeDisplayNames)}
                value={selectedTravelType?.toString()}
            />
            {currentRole !== RoleType.AenAdministrator && (
                <FormCheckboxInput
                    checked={approvedToTeach}
                    formFieldName="approvedToTeachCheckbox"
                    id="approvedToTeachCheckbox"
                    label={t("approvedToTeach")}
                    onChange={handleSetApprovedToTeach}
                    type={InputTypes.Checkbox}
                    value={approvedToTeach}
                />
            )}
            <Button
                disabled={disabled}
                onClick={handleClearAll}
                size={ButtonSize.Medium}
                style={ButtonStyle.Secondary}
                text={t("clearAll")}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventInstructorRefineResults };
// #endregion Exports
