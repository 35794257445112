import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Banner, BannerFormat, BannerStyle } from "components/banner/banner";
import { ButtonStyle } from "components/buttons/button/button";
import { EventManageAddOrganizations } from "./event-manage-add-organization";
import { Modal, ModalAction } from "components/modal/modal";
import { OrganizationRecord } from "models/view-models/organizations/organization-record";
import { Paragraph, ParagraphStyle } from "components/typography/paragraph/paragraph";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-organization-selection-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventOrganizationSelectionModalProps {
    event?: EventActiveRecord;
    onOrganizationChange: (organizationId?: number) => void;
    open: boolean;
    selectedOrganization?: OrganizationRecord;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-organization-selection-modal";

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventOrganizationSelectionModal: React.FC<EventOrganizationSelectionModalProps> = (
    props: EventOrganizationSelectionModalProps
): JSX.Element => {
    const [dirty, setDirty] = useState(false);
    const [organizations, setOrganizationsAvailable] = useState<OrganizationRecord[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState(props.selectedOrganization);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setSelectedOrganization(props.selectedOrganization);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onOrganizationSave = () => {
        props.onOrganizationChange(selectedOrganization?.id);
        resetForm();
        props.setOpen(false);
    };

    const handleAddOrganizationToQueue = useCallback((organization: OrganizationRecord): void => {
        setSelectedOrganization(organization);
        setDirty(true);
    }, []);

    const handleRemoveOrganizationFromQueue = useCallback(
        (organization: OrganizationRecord): void => {
            setSelectedOrganization(undefined);
        },
        []
    );

    const handleFetchOrganizations = useCallback((organizations: OrganizationRecord[]): void => {
        setOrganizationsAvailable(organizations);
    }, []);

    useEffect(() => {
        setSelectedOrganization(props.selectedOrganization);
    }, [props.selectedOrganization]);

    const banner = useMemo(() => {
        if (props.selectedOrganization == null) {
            return <></>;
        }
        return (
            <Banner style={BannerStyle.Error} format={BannerFormat.Box}>
                <div className={`${CSS_CLASS_NAME}__banner`}>
                    <Paragraph style={ParagraphStyle.Default}>
                        <strong>{t("pleaseReviewBeforeReplacingYourOrganization")}</strong>
                    </Paragraph>
                    <Paragraph>
                        {t(
                            "byReplacingTheAssociatedOrganizationAnyInformationEnteredForEventContactInformationWillBeReset"
                        )}
                    </Paragraph>
                </div>
            </Banner>
        );
    }, [props.selectedOrganization]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: onModalClose,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: props.selectedOrganization ? t("replaceOrganization") : t("done"),
            onClick: onOrganizationSave,
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <Modal
            actions={modalActionArray}
            banner={banner}
            isOpen={props.open}
            modalStyle={""}
            onModalClose={onModalClose}
            title={`${
                props.selectedOrganization == null
                    ? t("selectAnOrganization")
                    : t("replaceAnOrganization")
            }`}>
            <EventManageAddOrganizations
                event={props.event!}
                handleAddOrganizationToQueue={handleAddOrganizationToQueue}
                handleFetchOrganizations={handleFetchOrganizations}
                handleRemoveOrganizationFromQueue={handleRemoveOrganizationFromQueue}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
            />
            <Modal
                actions={confirmationActionArray}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}
                onModalClose={() => {}}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventOrganizationSelectionModal };

// #endregion Exports
