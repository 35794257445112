import React, { useCallback, useState } from "react";
import { Button, ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { FormSelect } from "components/form/form-select/form-select";
import { Language, LanguageDisplayNames } from "models/enumerations/languages/language";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useLocation } from "react-router-dom";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { t } from "utilities/localization/t";
import "./event-product-refine-results.scss";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventProductRefineResultsProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-product-refine-results";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "typeToSearchProducts";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventProductRefineResults: React.FC<EventProductRefineResultsProps> = (): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedTopic, setSelectedTopic] = useState<string>();
    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [searchText, setSearchText] = useState<string>("");

    const setQueryParams = useCallback(
        (searchText: string, selectedLanguage: string, selectedTopic: string) => {
            const sideBarFilterParams = {
                applySearch: true,
                language: selectedLanguage,
                searchText: searchText,
                topic: selectedTopic,
            };

            const filterParams = Object.entries(sideBarFilterParams)
                .filter(([_, value]) => !!value)
                .map(([key, value]) => `${key}=${value}`)
                .join("&");
            const search = `?${filterParams}`;

            navigate({
                pathname: location.pathname,
                search: search,
            });
        },
        [location.pathname, navigate]
    );

    const handleSearchTriggered = useCallback(
        (searchText: string) => {
            setQueryParams(searchText, selectedLanguage ?? "", selectedTopic ?? "");
        },
        [selectedLanguage, selectedTopic, setQueryParams]
    );

    const handleSearchTextInputChange = (searchText: string): void => {
        setSearchText(searchText);
    };

    const handleFilterByLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        setQueryParams(searchText, newLanguage, selectedTopic ?? "");
    };

    const handleFilterByTopicChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newTopic = event.target.value;
        setSelectedTopic(newTopic);
        setQueryParams(searchText, selectedLanguage ?? "", newTopic);
    };

    const handleClearAll = () => {
        setSearchText("");
        setSelectedLanguage(undefined);
        setSelectedTopic(undefined);
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <SearchTextInput
                debounce={DEBOUNCE_TIME}
                onSearchTextInputChange={handleSearchTextInputChange}
                onSearchTriggered={handleSearchTriggered}
                id={"productSearch"}
                placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                searchTextInputValue={searchText}
            />
            <FormSelect
                ariaLabelledBy={t("filterByTopic")}
                formFieldName={t("filterByTopic")}
                id="productFilterByTopic"
                label=""
                placeholder={t("selectATopic")}
                onChange={handleFilterByTopicChange}
                options={EnumUtils.numericEnumToSelectOptions(Topic, TopicDisplayNames)}
                value={selectedTopic?.toString()}
            />
            <FormSelect
                ariaLabelledBy={t("filterByLanguage")}
                formFieldName={t("filterByLanguage")}
                id="productFilterByLanguage"
                label=""
                placeholder={t("selectALanguage")}
                onChange={handleFilterByLanguageChange}
                options={EnumUtils.numericEnumToSelectOptions(Language, LanguageDisplayNames)}
                value={selectedLanguage?.toString()}
            />
            <Button
                onClick={handleClearAll}
                size={ButtonSize.Medium}
                style={ButtonStyle.Secondary}
                text={t("clearAll")}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventProductRefineResults };
// #endregion Exports
