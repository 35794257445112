import React from "react";
import { TabbedLayoutItem, TabbedLayout } from "components/tabs/tabbed-layout/tabbed-layout";
import { Tab, TabProps } from "components/tabs/tabs/tab/tab";
import { HeaderBanner } from "components/header-banner/header-banner";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { RouteUtils } from "utilities/route-utils";
import "./provider-enh-layout-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderENHLayoutHeaderProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-enh-layout-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderENHLayoutHeader: React.FC<ProviderENHLayoutHeaderProps> = (
    props: ProviderENHLayoutHeaderProps
): JSX.Element => {
    const tabbedLayoutItems: TabbedLayoutItem<TabProps>[] = [
        {
            path: RouteUtils.localizePath(sitemap.aenProvider.educationNetworkHub.organization),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "yourOrganization",
            },
        },
        {
            path: RouteUtils.localizePath(sitemap.aenProvider.educationNetworkHub.resources),
            tabComponent: Tab,
            tabDisplayDetails: {
                tabName: "resources",
            },
        },
    ];

    return (
        <div className={CSS_CLASS_NAME}>
            <HeaderBanner title={t("educationNetworkHub")} />
            <TabbedLayout cssClassName={`${CSS_CLASS_NAME}__tabs`} tabs={tabbedLayoutItems} />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderENHLayoutHeader };

// #endregion Exports
