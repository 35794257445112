import React, { useEffect, useMemo, useState } from "react";
import {
    FormRadioCardList,
    RadioCardStyle,
} from "../../form/form-radio-card-list/form-radio-card-list";
import { Icons } from "components/icons/constants/icons";
import { IconSizes } from "../../icons/constants/icon-sizes";
import { Paragraph, ParagraphSize } from "../../typography/paragraph/paragraph";
import { ReportsBaseProps } from "components/reports/reports-base-props";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import ReportCourseSelection from "../components/report-course-selection/report-course-selection";
import { CourseRecord } from "models/view-models/courses/course-record";
import ReportProductSelection from "../components/report-product-selection/report-product-selection";
import { ProductRecord } from "models/view-models/products/product-record";
import { TrainingType } from "models/enumerations/courses/training-type";
import { ReportType, ReportTypeDisplayNames } from "./report-type";
import { NumberUtils } from "utilities/number-utils";
import "./report-training-materials.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ReportTrainingMaterialsProps extends ReportsBaseProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "report-training-materials";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ReportTrainingMaterials: React.FC<ReportTrainingMaterialsProps> = ({
    onChange: onChangeReportCriteria,
}): JSX.Element => {
    const [reportType, setReportType] = useState<ReportType>();
    const [courses, setCourses] = useState<CourseRecord[]>([]);
    const [products, setProducts] = useState<ProductRecord[]>([]);

    const onReportTypeChange = (value: ReportType) => {
        setReportType(value as ReportType);
        setProducts([]);
    };

    const isValid = useMemo(() => {
        if (reportType == null) {
            return false;
        }

        if (reportType === ReportType.Course && courses.length === 0) {
            return false;
        }

        if (
            (reportType === ReportType.OnlineLearningTraining ||
                reportType === ReportType.InstructorLedTrainingEvent) &&
            products.length === 0
        ) {
            return false;
        }

        return true;
    }, [courses.length, products.length, reportType]);

    useEffect(() => {
        onChangeReportCriteria({
            courseIds: courses.map((course) => course.id),
            courseNames: courses.map((course) => course.name),
            productIds: products.map((product) => product.id),
            productNames: products.map((product) => product.name),
            reportType,
            isValid: isValid,
        });
    }, [courses, isValid, onChangeReportCriteria, products, reportType]);

    // region Tranlateable Contants
    const REPORT_TYPE_CARDS = useMemo(
        () =>
            Object.keys(ReportType)
                .filter((key) => !isNaN(Number(key)))
                .map((key) => {
                    const reportTypeKey = key as unknown as ReportType;
                    const text = ReportTypeDisplayNames[reportTypeKey];

                    return {
                        icon: Icons.Reporting,
                        text: text,
                        value: key.toString(),
                    };
                }),
        []
    );

    // endregion Tranlateable Contants

    return (
        <>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={CSS_CLASS_NAME}>
                            <h2>{t("trainingMaterialsOutlineReport")}</h2>
                            <div className="report-section">
                                <Paragraph size={ParagraphSize.Large}>
                                    {t("iWantToRunATrainingMaterialsOutlineBasedOn")}
                                </Paragraph>
                                <FormRadioCardList
                                    id="reportType"
                                    formFieldName="reportType"
                                    label={t("reportType")}
                                    labelScreenReaderOnly={true}
                                    iconSize={IconSizes.Base}
                                    onChange={(reportType) =>
                                        onReportTypeChange(
                                            NumberUtils.parseInt(reportType) as ReportType
                                        )
                                    }
                                    radioCards={REPORT_TYPE_CARDS}
                                    required={true}
                                    style={RadioCardStyle.Horizontal}
                                    value={reportType?.toString()}
                                />
                            </div>

                            {/* COURSE */}
                            {reportType === ReportType.Course && (
                                <>
                                    <ReportCourseSelection
                                        courses={courses}
                                        isRequired={true}
                                        onCoursesChanged={(newCourses) => setCourses(newCourses)}
                                    />
                                </>
                            )}

                            {/* OLL PRODUCT */}
                            {reportType === ReportType.OnlineLearningTraining && (
                                <>
                                    <ReportProductSelection
                                        products={products}
                                        isRequired={true}
                                        trainingType={TrainingType.OnlineLearning}
                                        onProductsChanged={(newProducts) =>
                                            setProducts(newProducts)
                                        }
                                    />
                                </>
                            )}

                            {/* ILT PRODUCT */}
                            {reportType === ReportType.InstructorLedTrainingEvent && (
                                <>
                                    <ReportProductSelection
                                        products={products}
                                        isRequired={true}
                                        trainingType={TrainingType.InstructorLedTraining}
                                        onProductsChanged={(newProducts) =>
                                            setProducts(newProducts)
                                        }
                                    />
                                </>
                            )}

                            {/* {reportType != null && (
                                <div className="report-section half">
                                    <Paragraph size={ParagraphSize.Large}>
                                        {t("duringThisTimePeriod")}
                                    </Paragraph>
                                    <FormCalendarDateRangePicker
                                        endDate={dateRangeEnd}
                                        formFieldName="dateRange"
                                        handleEventDateRangeChange={onDateRangeChange}
                                        label={t("dateRange")}
                                        startDate={dateRangeStart}
                                    />
                                </div>
                            )} */}
                        </div>
                    </SkipNavContent>
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ReportTrainingMaterials };

// #endregion Exports
