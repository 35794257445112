import React, { useMemo } from "react";
import { HeaderBanner, HeaderBannerNavItem } from "components/header-banner/header-banner";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { t } from "utilities/localization/t";
import { sitemap } from "sitemap";
import { SideContentLeftLayout } from "components/layouts/side-content-left-layout/side-content-left-layout";
import { AenProviderApplicationStatus } from "constants/statuses/aen-provider-application-status";
import { BadgeStyle } from "components/badges/badge/badge";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./../aen-applications.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenApplicationsProps {
    filtersElement: JSX.Element;
    footer: JSX.Element;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------
const CSS_CLASS_NAME = "aen-applications";

// Delete once Instructor enum is available
export const getBadgeStyle = (
    applicationStatus: keyof typeof AenProviderApplicationStatus
): BadgeStyle => {
    switch (applicationStatus) {
        case AenProviderApplicationStatus.Completed:
            return BadgeStyle.Success;
        case AenProviderApplicationStatus.Declined:
            return BadgeStyle.Error;
        case AenProviderApplicationStatus.Submitted:
        case AenProviderApplicationStatus.MoreInfoNeeded:
            return BadgeStyle.Warning;
        default: {
            return BadgeStyle.Neutral;
        }
    }
};
// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenApplicationsListPageLayout: React.FC<React.PropsWithChildren<AenApplicationsProps>> = (
    props
): JSX.Element => {
    const { children, filtersElement, footer } = props;

    const hasProviderAccess = useHasAccess(AccessControlKeys.AdminAenProviderApplications);
    const hasInstructorAccess = useHasAccess(AccessControlKeys.AdminAenInstructorApplications);

    const navItems: HeaderBannerNavItem[] = useMemo(() => {
        const items: HeaderBannerNavItem[] = [];

        if (hasProviderAccess) {
            items.push({ path: sitemap.admin.aenApplications.providers.list, text: "provider" });
        }

        if (hasInstructorAccess) {
            items.push({
                path: sitemap.admin.aenApplications.instructors.list,
                text: "instructor",
            });
        }

        return items;
    }, [hasProviderAccess, hasInstructorAccess]);

    return (
        <section className={CSS_CLASS_NAME}>
            <HeaderBanner
                navItems={navItems.length > 1 ? navItems : undefined}
                title={t("aenApplications")}
            />
            <SideContentLeftLayout sidebarElement={filtersElement}>
                <div className="content-wrap">
                    <div className="content">
                        <SkipNavContent>{children}</SkipNavContent>
                    </div>
                </div>

                <div className="footer">{footer}</div>
            </SideContentLeftLayout>
        </section>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenApplicationsListPageLayout };

// #endregion Exports
