import React from "react";
import { EventContactForm } from "components/events/event-contact-card/event-contact-form/event-contact-form";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { useEventActiveRecordContext } from "utilities/hooks/active-record/events/use-event-active-record";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ToggleLabel } from "components/toggle/toggle-label/toggle-label";
import "./admin-edit-event-contact-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminEditEventContactPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-edit-event-contact-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminEditEventContactPage: React.FC<AdminEditEventContactPageProps> = validatePageAccess(
    AccessControlKeys.AdminEditEventContactPage
)((): JSX.Element => {
    const event = useEventActiveRecordContext();

    const handleToggleChange = (checked: boolean): void => {
        const updateValues = {
            hideContactInformation: checked,
            useDefaultContactInformation: undefined,
            email: "",
            phoneNumber: "",
        };

        if (event.isDraft()) {
            event.updateAndSave(updateValues);
        } else {
            event.updateActiveRecord(updateValues);
        }
    };

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.Small}>
                    {t("eventContact")}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__subtitle`}>
                    {t(
                        "thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizer"
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <div className={`${CSS_CLASS_NAME}__content-header`}>
                    <Heading
                        priority={HeadingPriority.H3}
                        size={HeadingSize.XXSmall}
                        cssClassName={`${CSS_CLASS_NAME}__content-title`}
                    >
                        {t("assignEventContactInformation")}
                        {" *"}
                    </Heading>
                    <ToggleLabel
                        checked={event.hideContactInformation ?? false}
                        id="hide-contact-information"
                        label={t("hideContactInformation")}
                        onToggle={(checked) => handleToggleChange(checked)}
                    />
                </div>
                <EventContactForm event={event} />
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminEditEventContactPage };

// #endregion Exports
