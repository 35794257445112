import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { AenResourceForm } from "./aen-resource-form/aen-resource-form";
import { AenResourceRecord } from "models/view-models/aen-resources/aen-resource-record";
import { StringUtils } from "andculturecode-javascript-core";
import { RoleType } from "models/enumerations/users/role-type";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AenResourceModalProps {
    aenResource?: AenResourceRecord;
    editing?: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    roleType: RoleType.AenAdministrator | RoleType.Instructor;
    saveAenResource: (aenResource: AenResourceRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AenResourceModal: React.FC<AenResourceModalProps> = (
    props: AenResourceModalProps
): JSX.Element => {
    let initialResource = useMemo(
        () => props.aenResource ?? new AenResourceRecord({ roleType: props.roleType }),
        [props.aenResource, props.roleType]
    );
    const [aenResource, setAenResource] = useState(initialResource);
    const [dirty, setDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [disableAddButton, setDisableAddButton] = useState(false);

    useEffect(() => {
        setDisableAddButton(false);
        setAenResource(initialResource);
    }, [initialResource, props]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: props.aenResource ? t("saveContent") : t("addContent"),
            disabled:
                disableAddButton ||
                aenResource?.fileId === undefined ||
                !StringUtils.hasValue(aenResource?.name) ||
                !StringUtils.hasValue(aenResource?.description),
            onClick: () => onAddContentButtonClick(),
            style: ButtonStyle.Primary,
        },
    ];

    const confirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("confirm"),
            onClick: () => {
                resetForm();
                props.setOpen(false);
                setShowConfirmationModal(false);
            },
            style: ButtonStyle.Destructive,
        },
    ];

    const resetForm = (): void => {
        setDirty(false);
        setAenResource(initialResource);
    };

    const onModalClose = (): void => {
        if (dirty) {
            setShowConfirmationModal(true);
        } else {
            props.setOpen(false);
        }
    };

    const onAddContentButtonClick = async (): Promise<void> => {
        if (disableAddButton) {
            return;
        }
        setDisableAddButton(true);
        props.saveAenResource(aenResource);
        setAenResource(aenResource);
        setDirty(false);
        props.setOpen(false);
        return;
    };

    return (
        <Modal
            isOpen={props.open}
            onModalClose={onModalClose}
            title={props.editing ? t("replaceContent") : t("addContent")}
            actions={modalActionArray}
            modalStyle={""}>
            <AenResourceForm aenResource={aenResource} setAenResource={setAenResource} />
            <Modal
                isOpen={showConfirmationModal}
                onModalClose={() => {}}
                actions={confirmationActionArray}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br></br> {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AenResourceModal };

// #endregion Exports
