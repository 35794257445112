import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal, ModalAction } from "components/modal/modal";
import { FormSelect } from "components/form/form-select/form-select";
import { SelectOption } from "components/form/inputs/select/select";
import { FormFieldReadonly } from "components/form/form-field-readonly/form-field-readonly";
import { ParagraphStyle } from "components/typography/paragraph/paragraph";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./provider-change-status-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProviderChangeStatusModalProps {
    onSaveChanges: (isActive: boolean) => void;
    open: boolean;
    providerIsActive: boolean;
    onModalClose: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Enums
// -------------------------------------------------------------------------------------------------

enum IsActive {
    Active = "active",
    Inactive = "inactive",
}

// #endregion Enums

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "provider-change-status-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProviderChangeStatusModal: React.FC<ProviderChangeStatusModalProps> = (
    props: ProviderChangeStatusModalProps
): JSX.Element => {
    const [isActiveValue, setIsActiveValue] = useState<IsActive>(IsActive.Inactive);

    const handleIsActiveChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => setIsActiveValue(event.target.value as IsActive),
        []
    );

    const handleModalClose = useCallback((): void => {
        props.onModalClose();

        setIsActiveValue(booleanToIsActive(!props.providerIsActive));
    }, [props]);

    const handleSaveChangesClick = useCallback((): void => {
        props.onSaveChanges(isActiveToBoolean(isActiveValue));

        handleModalClose();
    }, [handleModalClose, isActiveValue, props]);

    const modalActionArray: ModalAction[] = useMemo(
        (): ModalAction[] => [
            {
                buttonText: t("cancel"),
                onClick: handleModalClose,
                style: ButtonStyle.Secondary,
            },
            {
                buttonText: t("saveChanges"),
                onClick: handleSaveChangesClick,
                style: ButtonStyle.Primary,
            },
        ],
        [handleModalClose, handleSaveChangesClick]
    );

    const statusOptions: SelectOption[] = useMemo(
        (): SelectOption[] => [
            { text: t("active"), value: IsActive.Active },
            { text: t("inactive"), value: IsActive.Inactive },
        ],
        []
    );

    useEffect(
        (): void => setIsActiveValue(booleanToIsActive(!props.providerIsActive)),
        [props.providerIsActive]
    );

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={props.open}
            onModalClose={props.onModalClose}
            title={t("changeStatus")}
            actions={modalActionArray}
            modalStyle={""}>
            <FormFieldReadonly label={t("currentStatus")} labelStyle={ParagraphStyle.Default}>
                <Badge style={booleanToBadgeStyle(props.providerIsActive)}>
                    {t(booleanToBadgeTranslatedCopy(props.providerIsActive))}
                </Badge>
            </FormFieldReadonly>
            <FormSelect
                ariaLabelledBy={t("changeStatusTo")}
                formFieldName="changeStatusTo"
                id="changeStatusTo"
                label={t("changeStatusTo")}
                onChange={handleIsActiveChange}
                options={statusOptions}
                value={isActiveValue}
                required={true}
            />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Helper Functions
// -------------------------------------------------------------------------------------------------

const booleanToBadgeStyle = (isActive: boolean): BadgeStyle =>
    isActive ? BadgeStyle.Success : BadgeStyle.Warning;

const booleanToBadgeTranslatedCopy = (isActive: boolean): TranslatedCopy =>
    isActive ? "active" : "inactive";

const booleanToIsActive = (isActive: boolean): IsActive =>
    isActive ? IsActive.Active : IsActive.Inactive;

const isActiveToBoolean = (isActive: IsActive): boolean => isActive === IsActive.Active;

// #endregion Helper Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderChangeStatusModal };

// #endregion Exports
