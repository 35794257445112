import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { useCourse } from "utilities/contexts/use-course-context";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import "./course-version-create-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseVersionCreateModalProps {
    newCourseVersionNumber: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-version-create-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseVersionCreateModal: React.FC<CourseVersionCreateModalProps> = ({
    newCourseVersionNumber,
    open,
    setOpen,
}): JSX.Element => {
    const { record: course } = useCourse();

    const navigate = useNavigate();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToVersion"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = () => {
        setOpen(false);
        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(sitemap.admin.course.edit.materials.version.create, {
                    id: course.id,
                })
            )
        );
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={setOpen}
            title={t("createANewVersion")}
            actions={modalActionArray}
            modalStyle={""}>
            <div>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading size={HeadingSize.XSmall}>
                        {t("youAreGoingToCreateVersionVersionNumber", {
                            versionNumber: newCourseVersionNumber,
                        })}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("howItWorks")}</Paragraph>

                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("youMustUploadANewZIPPackage")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "theContentFromThePreviousVersionHasBeenBroughtOverToUseAsAStartingPoint"
                            )}
                        </li>
                    </ul>
                </div>
                <div>
                    <Paragraph size={ParagraphSize.Large}>{t("howChangesAreApplied")}</Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("learnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersion")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "anyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersion"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgressChoosingToApplyThisVersionWillResultInResettingTheirProgress"
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionCreateModal };

// #endregion Exports
