import React, { useCallback, useMemo, useState } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import { Card } from "components/card/card";
import { CourseModal } from "components/courses/course-modal/course-modal";
import { CreateEventModal } from "components/events/create-event-modal/create-event-modal";
import { HeaderBanner } from "components/header-banner/header-banner";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { ProductModal } from "components/products/product-modal/product-modal";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductService } from "utilities/services/products/product-service";
import { QuickLinkProps } from "components/quick-link/quick-link";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { ToastManager } from "utilities/toast/toast-manager";
import { TrainingType } from "models/enumerations/courses/training-type";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./admin-dashboard-page.scss";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminDashboardPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-dashboard-page";
//

const QUICK_LINKS: QuickLinkProps[] = [
    {
        icon: Icons.Courses,
        path: sitemap.admin.course.list,
        text: "courses",
    },
    {
        icon: Icons.Products,
        path: sitemap.admin.product.list,
        text: "products",
    },
    {
        icon: Icons.Events,
        path: sitemap.admin.event.list,
        text: "events",
    },
    {
        icon: Icons.LibraryBooks,
        path: sitemap.aenProvider.productCatalog.list,
        text: "enProductCatalog",
    },
    {
        icon: Icons.AenManager,
        path: sitemap.admin.aenResources.providers.list,
        text: "aenResources",
    },
    {
        icon: Icons.UserManagement,
        path: sitemap.admin.userManagement.users.list,
        text: "userManagement",
    },
    {
        icon: Icons.Reporting,
        path: sitemap.admin.reports.list,
        text: "reports",
    },
    {
        icon: Icons.Announcements,
        path: sitemap.admin.announcements.list,
        text: "announcements",
    },
    {
        icon: Icons.Email,
        path: sitemap.admin.communications.sendAnEmail,
        text: "emailManager",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AdminDashboardPage: React.FC<AdminDashboardPageProps> = validatePageAccess(
    AccessControlKeys.AdminDashboardPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const [showCourseModal, setShowCourseModal] = useState(false);
    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showProductModal, setShowProductModal] = useState(false);
    const [associatedProducts, setAssociatedProducts] = useState<ProductRecord[]>([]);
    const { list: listProducts } = ProductService.useList();

    useRedirectOnForbidden(sitemap.public.noAccess);

    const handleCreateEventModalClose = (): void => {
        setShowNewEventModal(false);
    };

    const handleProductModalOpen = (): void => {
        setShowProductModal(true);
        fetchProductList();
    };

    const fetchProductList = useCallback(async (): Promise<void> => {
        try {
            const listProductsResponse = await listProducts({
                activeStatusFilter: [ActiveStatus.Active, ActiveStatus.Draft],
                trainingType: TrainingType.InstructorLedTraining,
            });

            if (
                listProductsResponse?.resultObjects == null ||
                listProductsResponse.results == null ||
                listProductsResponse.results.hasErrors()
            ) {
                throw new Error();
            }
            setAssociatedProducts(listProductsResponse.resultObjects);
        } catch {
            ToastManager.error(t("problemLoadingAssociatedProductsList"));
            setAssociatedProducts([]);
        }
    }, [listProducts]);

    const userFirstName = useMemo(
        () => globalState?.currentIdentity?.user?.firstName,
        [globalState?.currentIdentity?.user?.firstName]
    );

    return (
        <SkipNavContent>
            <HeaderBanner
                title={t("twelcomeUserFirstName", {
                    twelcome: t("welcome"),
                    userFirstName: userFirstName,
                })}
            />
            <div className={CSS_CLASS_NAME}>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("createSomethingNew")}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__new ${CSS_CLASS_NAME}__section`}>
                    <button type="button" onClick={() => setShowCourseModal(true)}>
                        <Card>
                            <Icon type={Icons.Courses} />
                            <Paragraph>{t("createACourse")}</Paragraph>
                        </Card>
                    </button>
                    <button type="button" onClick={handleProductModalOpen}>
                        <Card>
                            <Icon type={Icons.Products} />
                            <Paragraph>{t("createANewProduct")}</Paragraph>
                        </Card>
                    </button>
                    <button type="button" onClick={() => setShowNewEventModal(true)}>
                        <Card>
                            <Icon type={Icons.Events} />
                            <Paragraph>{t("createANewEvent")}</Paragraph>
                        </Card>
                    </button>
                </div>
                <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                    {t("quickLinks")}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <QuickLinkList links={QUICK_LINKS} />
                </div>
            </div>
            <CourseModal open={showCourseModal} setOpen={setShowCourseModal} />
            <CreateEventModal onModalClose={handleCreateEventModalClose} open={showNewEventModal} />
            <ProductModal
                associatedProducts={associatedProducts}
                open={showProductModal}
                setOpen={setShowProductModal}
            />
        </SkipNavContent>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminDashboardPage };

// #endregion Exports
