import { v4 as uuid } from "uuid";
import React, {
    useState,
    useEffect,
    useRef,
    useReducer,
    useCallback,
    Dispatch,
    SetStateAction,
} from "react";
import { Entity } from "andculturecode-javascript-core";
import { ButtonStyle } from "components/buttons/button/button";
import { Modal } from "components/modal/modal";
import { TabbedContainer } from "components/tabs/tabbed-container/tabbed-container";
import { NumberedTab } from "components/tabs/tabs/numbered-tab/numbered-tab";
import { Tab } from "components/tabs/tabs/tab/tab";
import {
    SearchSelectionModalList,
    SearchSelectionModalListItem,
} from "components/reports/components/search-selection-modal/search-selection-modal-list/search-selection-modal-list";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import {
    SearchSelectionModalActionType,
    SearchSelectionModalState,
    searchSelectionModalDefaultState,
    searchSelectionModalReducer,
} from "components/reports/components/search-selection-modal/search-selection-modal-reducer";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface SearchSelectionModalProps<TValue> {
    isOpen: boolean;
    loading?: boolean;
    onClose: () => void;
    onSearch: (searchText: string) => void;
    onSelectionChanged: (selectedValues: TValue[]) => void;
    searchPlaceholderText: string;
    searchValues: TValue[];
    setSearchValues?: (values: TValue[]) => void;
    selectedValues: TValue[];
    setSkip?: Dispatch<SetStateAction<number>>;
    title: string;
    itemName?: string;
    doneButtonText?: string;
    valueAdapter: (
        value: TValue
    ) => Omit<SearchSelectionModalListItem<TValue>, "selected" | "value">;
    valuesDescription: string;
    allowPaging?: boolean;
}

type SearchSelectionModalFC = <TValue extends Entity>(
    ...props: Parameters<React.FC<SearchSelectionModalProps<TValue>>>
) => ReturnType<React.FC<SearchSelectionModalProps<TValue>>>;

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const DEBOUNCE_TIME = 750;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const SearchSelectionModal: SearchSelectionModalFC = ({
    isOpen,
    loading,
    onClose,
    onSearch,
    onSelectionChanged,
    searchPlaceholderText,
    searchValues,
    selectedValues,
    setSearchValues,
    setSkip,
    title,
    itemName,
    doneButtonText,
    valueAdapter,
    valuesDescription,
    allowPaging,
}) => {
    const searchId = useRef(`searchSelectionModal-${uuid()}`);
    const [isDirty, setIsDirty] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [searchText, setSearchText] = useState("");
    const intersectionObserverRef = useRef<IntersectionObserver | null>(null);

    const [state, dispatch] = useReducer(
        searchSelectionModalReducer,
        searchSelectionModalDefaultState
    );

    const { selected, searchResults } = state as SearchSelectionModalState<
        (typeof selectedValues)[number]
    >;

    const currentSelectedValues = selected.map((value) => ({
        ...valueAdapter(value),
        selected: true,
        value,
    }));

    const currentSearchValues = searchResults.map((value) => ({
        ...valueAdapter(value),
        selected: selected.some((s) => s.id === value.id),
        value,
    }));

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        dispatch({
            type: SearchSelectionModalActionType.Initialize,
            selected: selectedValues,
        });
    }, [isOpen, selectedValues]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        dispatch({
            type: SearchSelectionModalActionType.SearchResults,
            results: searchValues,
        });
    }, [isOpen, searchValues]);

    const resetForm = (): void => {
        setSearchText("");
        setIsDirty(false);
        setSearchValues?.([]);
        setSkip?.(0);
        dispatch({ type: SearchSelectionModalActionType.Reset });
    };

    const handleSearch = useCallback(
        (newSearchText: string) => {
            setSkip?.(0);
            onSearch(newSearchText);
        },
        [onSearch, setSkip]
    );

    const handleCancel = () => {
        if (isDirty) {
            setShowConfirmationModal(true);
            return;
        }

        handleClose();
    };

    const handleClose = () => {
        setShowConfirmationModal(false);
        resetForm();
        onClose();
    };

    const handleSelectionDone = () => {
        onSelectionChanged(currentSelectedValues.map((value) => value.value));
        handleClose();
    };

    const handleItemSelection = (selected: boolean, value: SearchSelectionModalListItem<any>) => {
        setIsDirty(true);
        dispatch({
            type: selected
                ? SearchSelectionModalActionType.Select
                : SearchSelectionModalActionType.Unselect,
            id: value.id,
        });
    };

    const handleSelectedItem = handleItemSelection.bind(null, true);
    const handleUnselectedItem = handleItemSelection.bind(null, false);

    // Handle Lazy Loading of Search Results
    const observerRef = useCallback(
        (node: Element | null) => {
            if (setSkip == null) return;
            if (node == null) return;

            if (!allowPaging) {
                if (intersectionObserverRef.current != null) {
                    console.log("Disconnecting observer while loading.");
                    intersectionObserverRef.current.disconnect();
                }
            } else {
                intersectionObserverRef.current = new IntersectionObserver(
                    (entries: IntersectionObserverEntry[]) => {
                        if (entries.length > 1) return;
                        if (entries.some((e) => e.isIntersecting)) {
                            setSkip?.((prev: number) => prev + 1);
                        }
                    },
                    {
                        threshold: 1.0,
                    }
                );
                intersectionObserverRef.current.observe(node);
            }
        },
        [allowPaging, setSkip]
    );

    return (
        <Modal
            actions={[
                {
                    buttonText: t("cancel"),
                    onClick: handleCancel,
                    style: ButtonStyle.Secondary,
                },
                {
                    buttonText: doneButtonText ?? t("done"),
                    onClick: handleSelectionDone,
                    style: ButtonStyle.Primary,
                },
            ]}
            isOpen={isOpen}
            onModalClose={handleCancel}
            title={title}>
            <TabbedContainer
                tabs={[
                    {
                        contents: (
                            <>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={setSearchText}
                                    onSearchTriggered={handleSearch}
                                    id={searchId.current}
                                    placeholder={searchPlaceholderText}
                                    readOnly={loading}
                                    searchTextInputValue={searchText}
                                />
                                <SearchSelectionModalList
                                    observerRef={observerRef}
                                    itemName={itemName ?? t("name")}
                                    items={currentSearchValues}
                                    onItemSelected={handleSelectedItem}
                                    onItemUnselected={handleUnselectedItem}
                                />
                            </>
                        ),
                        tabComponent: Tab,
                        tabDisplayDetails: {
                            tabName: valuesDescription,
                        },
                    },
                    {
                        contents: (
                            <SearchSelectionModalList
                                itemName={itemName ?? t("name")}
                                items={currentSelectedValues}
                                onItemUnselected={handleUnselectedItem}
                            />
                        ),
                        tabComponent: NumberedTab,
                        tabDisplayDetails: {
                            hideBadge: currentSelectedValues.length <= 0,
                            tabName: t("selected"),
                            value: currentSelectedValues.length,
                        },
                    },
                ]}
            />
            <Modal
                actions={[
                    {
                        buttonText: t("cancel"),
                        onClick: () => setShowConfirmationModal(false),
                        style: ButtonStyle.Secondary,
                    },
                    {
                        buttonText: t("confirm"),
                        onClick: handleClose,
                        style: ButtonStyle.Destructive,
                    },
                ]}
                isOpen={showConfirmationModal}
                modalStyle={"-inverted"}>
                {t("youHaveUnsavedChanges")}
                <br />
                {t("areYouSureYouWantToExit")}
            </Modal>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { SearchSelectionModal };

// #endregion Exports
