import { Provider } from "models/interfaces/providers/provider";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Provider = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    email: undefined,
    id: undefined,
    name: "",
    phoneNumber: undefined,
    isActive: true,
    addressLineOne: undefined,
    addressLineTwo: undefined,
    city: undefined,
    country: undefined,
    locationName: undefined,
    netsuiteId: undefined,
    state: undefined,
    timeZone: undefined,
    zipCode: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProviderRecord extends Record(defaultValues) implements Provider {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Provider>) {
        params = params ?? Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Return formatted phone number
     * Ex: 234.567.9225
     * @returns Return formatted phone number
     */
    public formatPhoneNumber(): string {
        if (this.phoneNumber && this.phoneNumber.length === 10) {
            let format = this.phoneNumber.split("");
            format.splice(3, 0, ".");
            format.splice(7, 0, ".");
            return format.join("");
        }
        return "";
    }

    /**
     * Return formatted phone number
     * Ex: 234.567.9225
     * @returns Return formatted phone number
     */
    public formatPhoneNumberWithParens(): string {
        if (this.phoneNumber) {
            let format = this.phoneNumber.split("");
            format.unshift("(");
            format.splice(4, 0, ") ");
            format.splice(8, 0, "-");
            return format.join("");
        }
        return "";
    }

    /**
     * Given a set of values for Provider properties, create a new ProviderRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Provider>} values The values to overwrite on this instance.
     * @returns A ProviderRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<Provider>): ProviderRecord {
        return new ProviderRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderRecord };

// #endregion Exports
