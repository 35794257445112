import { createPortal } from "react-dom";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "utilities/toast/toast.scss";

const toastRootLoader = (() => {
    let loaded = false;
    let toastRootElement: HTMLElement | null = null;

    const load = () => {
        loaded = true;
        toastRootElement = document.getElementById("toast-root");

        return toastRootElement;
    };

    return {
        get() {
            if (loaded) {
                return toastRootElement;
            }

            return load();
        },
    };
})();

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ToastContainerWrapper: React.FC<ToastContainerProps> = (props) => {
    const toastRoot = toastRootLoader.get();
    if (!toastRoot) {
        return <></>;
    }

    return createPortal(<ToastContainer {...props} />, toastRoot);
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ToastContainerWrapper as ToastContainer };

// #endregion Exports
