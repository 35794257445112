import { AdminLayoutHeaderNavigationPanelFooter } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-navigation-panel/admin-layout-header-navigation-panel-content/admin-layout-header-navigation-panel-footer/admin-layout-header-navigation-panel-footer";
import { AdminLayoutHeaderProfileManager } from "layouts/admin/admin-layout-header-navigation/admin-layout-header-profile-manager/admin-layout-header-profile-manager";
import React, { useMemo } from "react";
import { sitemap } from "sitemap";
import { Map } from "utilities/types/map";
import { RoleType } from "models/enumerations/users/role-type";
import { Icons } from "components/icons/constants/icons";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ExternalLinks } from "constants/external-links";
import { ButtonSize, ButtonStyle } from "components/buttons/button/button";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import "./admin-layout-header-navigation-panel-content.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AdminLayoutHeaderNavigationPanelContentProps {
    isOpen: boolean;
    navigateTo: (path: string, external?: boolean) => void;
}

interface NavigationItem {
    icon: Icons;
    path: string;
    text: TranslatedCopy;
    external?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "admin-layout-header-navigation-panel-content";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Components
// -------------------------------------------------------------------------------------------------

const AdminLayoutHeaderNavigationPanelContent: React.FC<
    AdminLayoutHeaderNavigationPanelContentProps
> = (props: AdminLayoutHeaderNavigationPanelContentProps): JSX.Element | null => {
    const { record: globalState } = useGlobalState();

    const currentRoleType = useMemo(
        () => globalState.currentIdentity?.role?.roleType,
        [globalState.currentIdentity?.role?.roleType]
    );

    const browseCatalogUrl = useMemo(
        () => globalState?.systemSettings?.ecommerceBaseUrl,
        [globalState?.systemSettings?.ecommerceBaseUrl]
    );

    const NAVIGATION_ITEMS: { [key: string]: NavigationItem } = useMemo(
        () => ({
            AenResources: {
                icon: Icons.AenManager,
                text: "aenResources",
                path: sitemap.admin.aenResources.providers.list,
            },
            AenApplications: {
                icon: Icons.AenManager,
                text: "aenApplications",
                path: sitemap.admin.aenApplications.instructors.list,
            },
            Dashboard: {
                icon: Icons.MetaPanel,
                text: "dashboard",
                path: sitemap.admin.dashboard,
            },
            NfpaOpsDashboard: {
                icon: Icons.MetaPanel,
                text: "dashboard",
                path: sitemap.nfpaOperators.dashboard,
            },
            NfpaSupportDashboard: {
                icon: Icons.MetaPanel,
                text: "dashboard",
                path: sitemap.nfpaSupport.dashboard,
            },
            AenProviderAdminDashboard: {
                icon: Icons.MetaPanel,
                text: "dashboard",
                path: sitemap.aenProvider.dashboard,
            },
            InstructorDashboard: {
                icon: Icons.MetaPanel,
                text: "dashboard",
                path: sitemap.instructor.dashboard,
            },
            TeachingAssignments: {
                icon: Icons.Teaching,
                text: "myTeachingAssignments",
                path: sitemap.instructor.teachingAssignments,
            },
            Achievements: {
                icon: Icons.Achievements,
                text: "achievements",
                path: sitemap.test.placeholder.achievements,
            },
            Announcements: {
                icon: Icons.Announcements,
                text: "announcements",
                path: sitemap.admin.announcements.list,
            },
            Community: {
                icon: Icons.Community,
                text: "community",
                path: ExternalLinks.Community,
                external: true,
            },
            Certificates: {
                icon: Icons.Achievements,
                text: "certificates",
                path: sitemap.learner.certificates,
            },
            BrowseCatalog: {
                icon: Icons.Catalog,
                text: "browseCatalog",
                path: browseCatalogUrl ?? "",
                external: true,
            },
            Courses: { icon: Icons.Courses, text: "courses", path: sitemap.admin.course.list },
            EducationNetworkHubLearner: {
                icon: Icons.AenManager,
                text: "aenHub",
                /* TODO release v1.1 Direct Instructors to profile until v1.1
            path: sitemap.instructor.educationNetworkHub.requiredTrainings,*/
                path: sitemap.instructor.educationNetworkHub.profile,
            },
            EducationNetworkHubProvider: {
                icon: Icons.AenManager,
                text: "aenHub",
                path: sitemap.aenProvider.educationNetworkHub.organization,
            },
            EmailManagement: {
                icon: Icons.Email,
                text: "emailManager",
                path: sitemap.admin.communications.sendAnEmail,
            },
            Events: { icon: Icons.Events, text: "events", path: sitemap.admin.event.list },
            Learning: {
                icon: Icons.Learning,
                text: "myLearning",
                path: sitemap.learner.learningAssignments.inProgress,
            },
            Organization: {
                icon: Icons.Organization,
                text: "organization",
                path: sitemap.test.placeholder.organization,
            },
            Products: { icon: Icons.Products, text: "products", path: sitemap.admin.product.list },
            ProductCatalog: {
                icon: Icons.LibraryBooks,
                text: "enProductCatalog",
                path: sitemap.aenProvider.productCatalog.list,
            },
            InstructorDirectory: {
                icon: Icons.ChalkboardTeacher,
                text: "instructorDirectory",
                path: sitemap.admin.instructorDirectory,
            },
            Reporting: {
                icon: Icons.Reporting,
                text: "reports",
                path: sitemap.admin.reports.list,
            },
            Support: {
                icon: Icons.Support,
                text: "support",
                path: sitemap.authenticated.support,
            },
            CatalogEnrollmentLog: {
                icon: Icons.List,
                text: "catalogEnrollmentLog",
                path: sitemap.admin.catalogEnrollmentLog.list,
            },
            UserManagement: {
                icon: Icons.UserManagement,
                text: "userManagement",
                path: sitemap.admin.userManagement.users.list,
            },
            TrainingManager: {
                icon: Icons.BookmarkOutline,
                text: "trainingManager",
                path: sitemap.thirdPartyProvider.dashboard.online,
            },
        }),
        [browseCatalogUrl]
    );

    const ROLE_NAVIGATION_ITEMS_MAP: Map<RoleType, NavigationItem[]> = useMemo(
        () => ({
            [RoleType.AenAdministrator]: [
                NAVIGATION_ITEMS.AenProviderAdminDashboard,
                NAVIGATION_ITEMS.Events,
                NAVIGATION_ITEMS.EducationNetworkHubProvider,
                NAVIGATION_ITEMS.ProductCatalog,
                NAVIGATION_ITEMS.InstructorDirectory,
                NAVIGATION_ITEMS.Community,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.Instructor]: [
                NAVIGATION_ITEMS.InstructorDashboard,
                NAVIGATION_ITEMS.TeachingAssignments,
                NAVIGATION_ITEMS.EducationNetworkHubLearner,
                NAVIGATION_ITEMS.Community,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.Learner]: [
                NAVIGATION_ITEMS.Learning,
                NAVIGATION_ITEMS.BrowseCatalog,
                NAVIGATION_ITEMS.Certificates,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.NfpaAdministrator]: [
                NAVIGATION_ITEMS.Dashboard,
                NAVIGATION_ITEMS.Courses,
                NAVIGATION_ITEMS.Products,
                NAVIGATION_ITEMS.Events,
                NAVIGATION_ITEMS.ProductCatalog,
                NAVIGATION_ITEMS.InstructorDirectory,
                NAVIGATION_ITEMS.AenResources,
                NAVIGATION_ITEMS.UserManagement,
                NAVIGATION_ITEMS.AenApplications,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.Announcements,
                NAVIGATION_ITEMS.EmailManagement,
                NAVIGATION_ITEMS.CatalogEnrollmentLog,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.NfpaOperator]: [
                NAVIGATION_ITEMS.NfpaOpsDashboard,
                NAVIGATION_ITEMS.Products,
                NAVIGATION_ITEMS.Events,
                NAVIGATION_ITEMS.ProductCatalog,
                NAVIGATION_ITEMS.InstructorDirectory,
                NAVIGATION_ITEMS.UserManagement,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.EmailManagement,
                NAVIGATION_ITEMS.CatalogEnrollmentLog,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.NfpaSupport]: [
                NAVIGATION_ITEMS.NfpaSupportDashboard,
                NAVIGATION_ITEMS.Products,
                NAVIGATION_ITEMS.Events,
                NAVIGATION_ITEMS.UserManagement,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.EmailManagement,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.ClientAdmin]: [
                NAVIGATION_ITEMS.TrainingManager,
                NAVIGATION_ITEMS.Reporting,
                NAVIGATION_ITEMS.Support,
            ],
            [RoleType.Reviewer]: [NAVIGATION_ITEMS.AenApplications],
        }),
        [NAVIGATION_ITEMS]
    );

    const navigationItems: NavigationItem[] = useMemo(
        (): NavigationItem[] =>
            currentRoleType == null ? [] : ROLE_NAVIGATION_ITEMS_MAP[currentRoleType],
        [ROLE_NAVIGATION_ITEMS_MAP, currentRoleType]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <div>
                <AdminLayoutHeaderProfileManager containerIsOpen={props.isOpen} />
                <nav className={`${CSS_CLASS_NAME}__nav-items`}>
                    {navigationItems.map(
                        (navItem: NavigationItem): JSX.Element => (
                            <ButtonIcon
                                buttonSize={ButtonSize.None}
                                buttonStyle={ButtonStyle.None}
                                cssClassName={`${CSS_CLASS_NAME}__nav-item`}
                                iconSize={IconSizes.Medium}
                                iconType={navItem.icon}
                                key={navItem.path}
                                onClick={() => props.navigateTo(navItem.path, navItem.external)}
                                text={t(navItem.text)}
                            />
                        )
                    )}
                </nav>
            </div>
            <AdminLayoutHeaderNavigationPanelFooter navigateTo={props.navigateTo} />
        </div>
    );
};

// #endregion Components

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AdminLayoutHeaderNavigationPanelContent };

// #endregion Exports
