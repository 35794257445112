import { RoleAuthorizationRules } from "utilities/types/authorization/role-authorization-rules";
import { DefaultRoleAuthorizationRules } from "constants/authorization/default-role-authorization-rules";
import { Permission } from "utilities/enumerations/authorization/permission";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const LearnerAuthorizationRules: RoleAuthorizationRules = {
    ...DefaultRoleAuthorizationRules,
    [AccessControlKeys.LearnerOnlineLearningAchievementsPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningContentPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningDetailsPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitCourseAssessmentPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningEvaluationPage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitCoursePage]: Permission.Allow,
    [AccessControlKeys.LearnerOnlineLearningUnitPage]: Permission.Allow,
    [AccessControlKeys.LearnerTakeATrainingAchievementsPage]: Permission.Allow,
    [AccessControlKeys.LearnerTakeATrainingAssessmentResultsPage]: Permission.Allow,
    [AccessControlKeys.LearnerTakeATrainingAssessmentsPage]: Permission.Allow,
    [AccessControlKeys.LearnerTakeATrainingContentPage]: Permission.Allow,
    [AccessControlKeys.LearnerTakeATrainingEvaluationPage]: Permission.Allow,
    [AccessControlKeys.LearnerTrainingSummaryILTPage]: Permission.Allow,
    [AccessControlKeys.LearnerTrainingSummaryOLLPage]: Permission.Allow,
    [AccessControlKeys.MyLearningAssignmentsPage]: Permission.Allow,
    [AccessControlKeys.MyCertificatesPage]: Permission.Allow,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAuthorizationRules };

// #endregion Exports
