import React from "react";
import { ActiveStatus } from "models/enumerations/active-status/active-status";
import {
    ArchiveProductServiceParams,
    ProductService,
} from "utilities/services/products/product-service";
import { ButtonStyle } from "components/buttons/button/button";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "../../icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ProductRecord } from "models/view-models/products/product-record";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import "./product-archive-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductArchiveModalProps {
    product: ProductRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-archive-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductArchiveModal: React.FC<ProductArchiveModalProps> = ({
    product,
    open,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const { archive: productArchive } = ProductService.useArchive();
    const productIsActive = product.status === ActiveStatus.Active;
    const productActiveCheck = productIsActive ? "-unchecked" : "-checked";

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("archiveProduct"),
            disabled: product.status === ActiveStatus.Active ? true : false,
            onClick: () => archiveProduct(),
            style: ButtonStyle.Primary,
        },
    ];

    const archiveProduct = async (): Promise<void> => {
        if (product.id == null) {
            return;
        }
        setOpen(false);
        try {
            const archiveProductServiceParams: ArchiveProductServiceParams = {
                id: product.id,
            };

            const archiveProductResponse = await productArchive(archiveProductServiceParams);

            const archiveProductResult = archiveProductResponse?.result;

            if (archiveProductResult?.resultObject == null || archiveProductResult.hasErrors()) {
                alert(t("archivingProductFailed"));
            }
            navigate(sitemap.admin.product.list);
            ToastManager.success(t("productIsNowArchived"));
        } catch {
            ToastManager.error(t("failedToArchiveProduct"));
        }
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("archiveProduct")}
            actions={modalActionArray}
            modalStyle={""}>
            {(product.status === ActiveStatus.Active ||
                product.status === ActiveStatus.Inactive) && (
                <>
                    <Paragraph
                        cssClassName={`${CSS_CLASS_NAME}__header`}
                        size={ParagraphSize.Large}>
                        {t(
                            "youAreAboutToArchiveThisProductArchivingTheProductWillRemoveItFromTheProductListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOn"
                        )}
                    </Paragraph>
                    <Paragraph size={ParagraphSize.Large}>
                        {t("inOrderToArchiveThisProductYouNeedTo")}
                    </Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__item`}>
                            <Icon
                                cssClassName={`${CSS_CLASS_NAME}__icon ${productActiveCheck}`}
                                size={IconSizes.Large}
                                type={productIsActive ? Icons.CircleUnchecked : Icons.CircleChecked}
                            />
                            {t("deactivateTheProduct")}
                        </li>
                    </ul>
                </>
            )}
            {product.status === ActiveStatus.Draft && (
                <Paragraph size={ParagraphSize.Large}>
                    {t(
                        "youAreAboutToArchiveThisProductArchivingTheProductWillRemoveItFromTheProductListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOn"
                    )}
                </Paragraph>
            )}
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductArchiveModal };

// #endregion Exports
