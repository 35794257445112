import React, { useMemo } from "react";
import { Icons } from "components/icons/constants/icons";
import { ProductRecord } from "models/view-models/products/product-record";
import { EventRecord } from "models/view-models/events/event-record";
import { TrainingType, TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import {
    InstructorLedTrainingType,
    InstructorLedTrainingTypeDisplayNames,
} from "models/enumerations/products/instructor-led-training-type";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductBadgeProps {
    product: ProductRecord;
    event?: EventRecord;
    includeIcon?: boolean;
    upperCase?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductBadge: React.FC<ProductBadgeProps> = ({ product, event, includeIcon, upperCase }) => {
    const text = useMemo(() => {
        // By Default use the product's Training Type.
        let productTypeText =
            product?.type != null ? t(TrainingTypeDisplayNames[product.type]) : "";

        // If this is an event, use a more descriptive InstructorLedTrainingType (LiveVirtual | InPerson).
        if (event?.instructorLedTrainingType != null) {
            productTypeText = t(
                InstructorLedTrainingTypeDisplayNames[event.instructorLedTrainingType]
            );
        }

        return upperCase ? productTypeText.toUpperCase() : productTypeText;
    }, [event, product?.type, upperCase]);

    const icon = useMemo(() => {
        if (!includeIcon) {
            return undefined;
        }

        if (product?.type === TrainingType.OnlineLearning) {
            return Icons.LibraryBooks;
        }

        if (
            product?.type === TrainingType.InstructorLedTraining &&
            event?.instructorLedTrainingType === InstructorLedTrainingType.InPerson
        ) {
            return Icons.Calendar;
        }

        if (
            product?.type === TrainingType.InstructorLedTraining &&
            event?.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual
        ) {
            return Icons.DesktopWithBullets;
        }

        return undefined;
    }, [event?.instructorLedTrainingType, includeIcon, product?.type]);

    const badgeStyle = useMemo(() => {
        if (product?.type === TrainingType.OnlineLearning) {
            return BadgeStyle.Inverted;
        }

        if (
            product?.type === TrainingType.InstructorLedTraining &&
            event?.instructorLedTrainingType === InstructorLedTrainingType.LiveVirtual
        ) {
            return BadgeStyle.Dark;
        }

        if (
            product?.type === TrainingType.InstructorLedTraining &&
            event?.instructorLedTrainingType === InstructorLedTrainingType.InPerson
        ) {
            return BadgeStyle.Neutral;
        }

        return undefined;
    }, [event?.instructorLedTrainingType, product?.type]);

    return <Badge style={badgeStyle} icon={icon} text={text} />;
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductBadge };

// #endregion Exports
