import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./course-version-edit-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseVersionEditModalProps {
    courseVersionNumber?: number;
    open: boolean;
    setInEditCourseVersionMode: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-version-edit-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseVersionEditModal: React.FC<CourseVersionEditModalProps> = ({
    courseVersionNumber,
    open,
    setInEditCourseVersionMode,
    setOpen,
}): JSX.Element => {
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToEdit"),
            onClick: () => {
                setInEditCourseVersionMode(true);
                setOpen(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={setOpen}
            title={`Edit Version ${courseVersionNumber}`}
            actions={modalActionArray}
            modalStyle={""}>
            <div>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading size={HeadingSize.XSmall}>
                        {t("youAreGoingToEditVersionVersionNumber", {
                            versionNumber: courseVersionNumber,
                        })}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("howItWorks")}</Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "youMustChangesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageYouMustCreateANewCourseVersion"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("youCanEditTheCoursesContent")}
                        </li>
                    </ul>
                </div>
                <div>
                    <Paragraph size={ParagraphSize.Large}>{t("howChangesAreApplied")}</Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "youMustContentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseVersionEditModal };

// #endregion Exports
