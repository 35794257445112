import { RecordSummary } from "components/summaries/record-summary/record-summary";
import React, { useMemo } from "react";
import { RecordSummaryItem } from "components/summaries/record-summary/record-summary-entry/record-summary-entry";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import {
    AttemptSuccessStatusDisplayNames,
    AttemptSuccessStatusStatusMap,
} from "models/enumerations/attempts/attempt-success-status";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { DataTable } from "components/tables/data-table/data-table";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { t } from "utilities/localization/t";
import "./attempt-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AttemptSummaryProps {
    attempt: AttemptRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "attempt-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AttemptSummary: React.FC<AttemptSummaryProps> = ({ attempt }): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const showAttemptHistory = useMemo(
        () => globalState?.currentIdentity?.isCurrentlyInNfpaRole(),
        [globalState?.currentIdentity]
    );

    const itemsToDisplay: RecordSummaryItem[] = [
        { name: "score", value: attempt?.getScoreText() },
        {
            name: "result",
            value: (
                <EnumStatusBadge
                    displayNames={AttemptSuccessStatusDisplayNames}
                    statusMap={AttemptSuccessStatusStatusMap}
                    value={attempt?.successStatus!}
                />
            ),
        },
    ];

    return (
        <>
            <RecordSummary items={itemsToDisplay} />
            {attempt.isManualEntry && (
                <div className={`${CSS_CLASS_NAME}__manual-entry`}>
                    <Paragraph style={ParagraphStyle.Light}>
                        {t("thisAttemptWasManuallyEnteredByNFPA")}
                    </Paragraph>
                </div>
            )}
            {showAttemptHistory && (
                <div className={`${CSS_CLASS_NAME}__change-history`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th>{t("lastChange")}</th>
                                <th>{t("changedBy")}</th>
                                <th>{t("change")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt?.getLastUpdatedDateTimeText()}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt.createdBy?.getFirstAndLastName()}
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {attempt.createdById}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph
                                        cssClassName={`${CSS_CLASS_NAME}__change-history__notes`}
                                        size={ParagraphSize.XSmall}>
                                        {attempt.manualEntryNotes}
                                    </Paragraph>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            )}
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AttemptSummary };

// #endregion Exports
