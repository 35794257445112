import React, { useMemo } from "react";
import { Anchor, AnchorPathType } from "components/typography/anchors/anchor/anchor";
import { BulkEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-enrollment-summary-record";
import { BulkContractEnrollmentSummaryRecord } from "models/view-models/enrollments/bulk-contract-enrollment-summary-record";
import "./add-bulk-enrollment-summary.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AddBulkEnrollmentSummaryProps {
    bulkEnrollmentRecord: BulkEnrollmentSummaryRecord | BulkContractEnrollmentSummaryRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constant
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "add-bulk-enrollment-summary";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AddBulkEnrollmentSummary: React.FC<AddBulkEnrollmentSummaryProps> = ({
    bulkEnrollmentRecord,
}) => {
    const summaryDetails = useMemo(
        () => bulkEnrollmentRecord.getSummaryDetails(),
        [bulkEnrollmentRecord]
    );

    return (
        <div>
            <h6 className={`${CSS_CLASS_NAME}__summary-title`}>
                {t("enrollmentImportSummaryColon")}
            </h6>
            <div className={`${CSS_CLASS_NAME}__summary-item-list`}>
                {bulkEnrollmentRecord &&
                    summaryDetails.map((details) => (
                        <div className={`${CSS_CLASS_NAME}__summary-item`} key={details.name}>
                            <div className={`${CSS_CLASS_NAME}__summary-name`}>{details.name}</div>
                            <div className={`${CSS_CLASS_NAME}__summary-value`}>
                                {details.value}
                            </div>
                            <div className={`${CSS_CLASS_NAME}__summary-description`}>
                                {details.description}
                            </div>
                        </div>
                    ))}
            </div>
            <Anchor
                cssClassName={`${CSS_CLASS_NAME}__summary-download-link`}
                active={true}
                pathType={AnchorPathType.External}
                path={bulkEnrollmentRecord.csvUploadResults}
                text={t("downloadCSVImportSummaryFile")}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AddBulkEnrollmentSummary };

// #endregion Exports
