import React, { useMemo, useState, useEffect } from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { Button, ButtonStyle } from "components/buttons/button/button";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { CreateEnrollmentDtoRecord } from "models/view-models/enrollments/create-enrollment-dto-record";
import { DataTable } from "components/tables/data-table/data-table";
import { DateUtils } from "utilities/date-utils";
import { EmptyText } from "components/empty-text/empty-text";
import {
    EnrollmentProgressStatusDisplayNames,
    EnrollmentProgressStatusStatusMap,
} from "models/enumerations/enrollments/enrollment-progress-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { ModalAction } from "components/modal/modal";
import { NumberUtils } from "utilities/number-utils";
import { Pager } from "components/pager/pager";
import { ProductBadge } from "components/products/product-badge/product-badge";
import { ProductRecord } from "models/view-models/products/product-record";
import { ProductTrainingEnrollmentModal } from "components/products/product-training-enrollment-modal/product-training-enrollment-modal";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { SearchTextInput } from "components/form/inputs/text-inputs/search-text-input/search-text-input";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { Status } from "utilities/enumerations/statuses";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { WithdrawEnrollmentModal } from "components/enrollments/withdraw-enrollment-modal/withdraw-enrollment-modal";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useParams } from "react-router-dom";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import { useUser } from "utilities/contexts/use-user-context";
import { useUserEnrollments } from "utilities/hooks/models/enrollments/use-user-enrollments";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import "./user-trainings-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingsPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-trainings-page";
const DEBOUNCE_TIME = 750;
const SEARCH_INPUT_PLACEHOLDER: TranslatedCopy = "typeToSearchByTrainingName";
const ITEMS_PER_PAGE = 20;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingsPage: React.FC<UserTrainingsPageProps> = validatePageAccess(
    AccessControlKeys.UserTrainingAssessmentPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const { id: userIdParam } = useParams();
    const userId = useMemo(() => NumberUtils.parseInt(userIdParam) ?? 0, [userIdParam]);
    const { record: user } = useUser();

    const [searchText, setSearchText] = useState<string>("");
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [enrollmentToWithdraw, setEnrollmentToWithdraw] = useState<EnrollmentRecord>(
        new EnrollmentRecord()
    );
    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);
    const [showEnrollNewTrainingModal, setShowEnrollNewTrainingModal] = useState(false);
    const enrollmentDto = new CreateEnrollmentDtoRecord({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
    });

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                withdrawEnrollment(enrollmentToWithdraw);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const {
        enrollments,
        rowCount: enrollmentCount,
        withdrawEnrollment,
        addEnrollment,
    } = useUserEnrollments({
        userId: userId,
        includeProduct: true,
        includeEvent: true,
        includeRegistrations: true,
        includeCertificate: true,
        audienceType: AudienceType.Learner,
        searchText: debouncedSearchText,
        skip: (currentPage - 1) * ITEMS_PER_PAGE,
        take: ITEMS_PER_PAGE,
    });

    const onPageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchTextInputChange = (searchText: string) => {
        setSearchText(searchText);
        setCurrentPage(1);
    };

    const handleSearchTriggered = (debouncedSearchText: string): void => {
        setDebouncedSearchText(debouncedSearchText);
    };

    const requestWithdrawEnrollment = (e: EnrollmentRecord) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    const handleDownloadCertificateClick = async (
        enrollmentId?: number,
        certificateId?: number
    ) => {
        if (enrollmentId == null || certificateId == null) {
            return;
        }

        const pathParams: DownloadCertificatePathParams = {
            enrollmentId: enrollmentId,
            id: certificateId,
        };

        const queryParams: DownloadCertificateQueryParams = {
            overwriteExisting: true,
        };

        await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
    };

    const handleAddEnrollment = (product: ProductRecord) => {
        addEnrollment(enrollmentDto.with({ productId: product.id }));
    };

    useEffect(() => {
        ScrollUtils.scrollToElementBySelector(`.content`);
    }, [enrollments]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <h2>{t("trainings")}</h2>
                            <div className={`${CSS_CLASS_NAME}__header__search`}>
                                <SearchTextInput
                                    debounce={DEBOUNCE_TIME}
                                    onSearchTextInputChange={handleSearchTextInputChange}
                                    onSearchTriggered={handleSearchTriggered}
                                    id="courseSearch"
                                    placeholder={t(SEARCH_INPUT_PLACEHOLDER)}
                                    searchTextInputValue={searchText}
                                />
                            </div>
                            <div className={`${CSS_CLASS_NAME}__header__actions`}>
                                <Button
                                    onClick={() => setShowEnrollNewTrainingModal(true)}
                                    style={ButtonStyle.Primary}
                                    text={t("enrollNewTraining")}
                                />
                            </div>
                        </div>
                        {enrollments != null && enrollments.length > 0 && (
                            <DataTable>
                                <thead>
                                    <tr>
                                        <th className="product-name">{t("productName")}</th>
                                        <th className="type">{t("type")}</th>
                                        <th className="enrollment">{t("enrollment")}</th>
                                        <th className="completion">{t("completion")}</th>
                                        <th className="expiration">
                                            {t("trainingAccessExpiration")}
                                        </th>
                                        <th className="action">
                                            <span className="sr-only">{t("action")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {enrollments.map((enrollment) => (
                                        <tr key={enrollment.id}>
                                            <td className="product-name">
                                                {enrollment.product?.name}
                                            </td>
                                            <td className="type">
                                                {enrollment.product != null && (
                                                    <ProductBadge
                                                        product={enrollment.product}
                                                        event={enrollment.event}
                                                        includeIcon={false}
                                                        upperCase={false}
                                                    />
                                                )}
                                            </td>
                                            <td className="enrollment">
                                                {enrollment?.withdrawnOn != null ? (
                                                    <StatusBadge
                                                        status={Status.Error}
                                                        text="withdrawn"
                                                    />
                                                ) : (
                                                    <StatusBadge
                                                        status={Status.Success}
                                                        text="enrolled"
                                                    />
                                                )}
                                            </td>
                                            <td className="completion">
                                                {enrollment.withdrawnOn == null && (
                                                    <EnumStatusBadge
                                                        displayNames={
                                                            EnrollmentProgressStatusDisplayNames
                                                        }
                                                        statusMap={
                                                            EnrollmentProgressStatusStatusMap
                                                        }
                                                        value={enrollment.getProgressStatus()}
                                                    />
                                                )}
                                            </td>
                                            <td className="expiration">
                                                {enrollment.expirationDate == null
                                                    ? "--"
                                                    : `${DateUtils.formatDateCustom(
                                                          enrollment.expirationDate?.toString(),
                                                          t("shortDateFormat")
                                                      )}`}
                                            </td>
                                            <td className="action">
                                                <ContextMenu>
                                                    <ContextMenuAnchor
                                                        hrefPath={RouteUtils.replacePathParams(
                                                            sitemap.admin.userManagement.users
                                                                .trainings.overview,
                                                            {
                                                                id: userId,
                                                                enrollmentId: enrollment.id,
                                                            }
                                                        )}
                                                        displayName={t("viewTraining")}
                                                    />
                                                    <ContextMenuButton
                                                        displayName={t(
                                                            "generateAndDownloadCertificate"
                                                        )}
                                                        disabled={enrollment.certificate == null}
                                                        onClick={() =>
                                                            handleDownloadCertificateClick(
                                                                enrollment?.id,
                                                                enrollment.certificate?.id
                                                            )
                                                        }
                                                    />
                                                    <ContextMenuButton
                                                        displayName={t("withdrawLearner")}
                                                        disabled={
                                                            enrollment.isComplete() ||
                                                            enrollment.withdrawnOn != null
                                                        }
                                                        onClick={() =>
                                                            requestWithdrawEnrollment(enrollment)
                                                        }
                                                    />
                                                </ContextMenu>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </DataTable>
                        )}
                        {enrollments == null ||
                            (enrollments.length === 0 && (
                                <EmptyText table>{t("noTrainingsFound")}</EmptyText>
                            ))}
                        {enrollments != null && enrollments.length > 0 && (
                            <div className={`${CSS_CLASS_NAME}__footer`}>
                                <Pager
                                    footer={true}
                                    currentPage={currentPage}
                                    totalPages={enrollmentCount / ITEMS_PER_PAGE}
                                    onPageClick={onPageClick}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    totalItems={enrollmentCount}
                                />
                            </div>
                        )}
                    </SkipNavContent>
                </div>
            </div>
            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={user?.getFirstAndLastNameOrEmail() ?? ""}
            />
            <ProductTrainingEnrollmentModal
                addEnrollment={handleAddEnrollment}
                open={showEnrollNewTrainingModal}
                setOpen={setShowEnrollNewTrainingModal}
                trainingType={TrainingType.OnlineLearning}
            />
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingsPage };

// #endregion Exports
