import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventNameAndTypeForm } from "components/events/event-name-and-type-form/event-name-and-type-form";
import { Modal, ModalAction } from "components/modal/modal";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { EventRecord } from "models/view-models/events/event-record";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EditEventNameModalProps {
    event: EventActiveRecord;
    onModalClose: () => void;
    open: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EditEventNameModal: React.FC<EditEventNameModalProps> = (
    props: EditEventNameModalProps
): JSX.Element => {
    const [name, setName] = useState<string>(props.event.name);
    const { record: globalState } = useGlobalState();
    const isEnProvider = globalState?.currentIdentity?.isCurrentlyInEnProviderRole();
    const createEventButtonDisabled = useMemo(
        () =>
            !props.event.name ||
            props.event.instructorLedTrainingType == null ||
            (isEnProvider && props.event.providerId == null),
        [
            isEnProvider,
            props.event.instructorLedTrainingType,
            props.event.name,
            props.event.providerId,
        ]
    );
    const eventRecord = useMemo(
        (): EventRecord => new EventRecord({ name: name, type: props.event.type }),
        [name, props.event.type]
    );

    const handleEventNameChange = (name: string): void => {
        setName(name);
    };

    const handleModalClose = (): void => {
        setName(props.event.name);
        props.onModalClose();
    };

    const handleSaveChangesClick = () => {
        if (props.event.isDraft()) {
            props.event.updateAndSave({ name: name });
        } else {
            props.event.updateActiveRecord({ name: name });
        }
        props.onModalClose();
    };

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: handleModalClose,
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            disabled: createEventButtonDisabled,
            onClick: handleSaveChangesClick,
            style: ButtonStyle.Primary,
        },
    ];

    useEffect((): void => {
        setName(props.event.name);
    }, [props.event.name]);

    return (
        <Modal
            actions={modalActionArray}
            isOpen={props.open}
            modalStyle=""
            onModalClose={props.onModalClose}
            title={t("editName")}>
            <EventNameAndTypeForm event={eventRecord} onNameChange={handleEventNameChange} />
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EditEventNameModal };

// #endregion Exports
