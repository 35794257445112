import React from "react";
import { RecordSummaryStyle } from "components/summaries/record-summary/enums/record-summary-style";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { Map } from "utilities/types/map";
import { t } from "utilities/localization/t";
import "./record-summary-entry.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RecordSummaryItem {
    name: TranslatedCopy;
    value: string | JSX.Element | null | undefined;
}

interface RecordSummaryEntryProps {
    item: RecordSummaryItem;
    style: RecordSummaryStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "record-summary-entry";
const DEFAULT_EMPTY_STATE: TranslatedCopy = "doubleDash";
const SECONDARY_EMPTY_STATE: TranslatedCopy = "notApplicableShort";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const emptyStates: Map<RecordSummaryStyle, TranslatedCopy> = {
    [RecordSummaryStyle.Compact]: DEFAULT_EMPTY_STATE,
    [RecordSummaryStyle.Default]: DEFAULT_EMPTY_STATE,
    [RecordSummaryStyle.Secondary]: SECONDARY_EMPTY_STATE,
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RecordSummaryEntry: React.FC<RecordSummaryEntryProps> = (
    props: RecordSummaryEntryProps
): JSX.Element => {
    const value = props.item.value ?? t(emptyStates[props.style]);

    return (
        <div className={CSS_CLASS_NAME}>
            <p>{t(props.item.name)}</p>
            <p>{value}</p>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RecordSummaryEntry };

// #endregion Exports
