import { useMemo } from "react";
import { sitemap } from "sitemap";
import { RoleType } from "models/enumerations/users/role-type";

interface UseViewEventRoutesHook {
    viewEventRoutes: ViewEventRoutes;
}

interface UseViewEventRoutesHookOptions {
    roleType?: RoleType;
}

interface ViewEventRoutes {
    details: string;
    content: string;
    enrollment: string;
    attendanceSummary: string;
    attendanceDay: string;
    assessmentResults: string;
    learnerAssessment: string;
    assessments: string;
    evaluationSummary: string;
}

export function useViewEventRoutes(props: UseViewEventRoutesHookOptions): UseViewEventRoutesHook {
    const roleType = props.roleType;

    const viewEventRoutes: ViewEventRoutes = useMemo(() => {
        if (roleType === RoleType.Instructor) {
            return {
                details: sitemap.instructor.event.details,
                content: sitemap.instructor.event.content,
                enrollment: sitemap.instructor.event.enrollment,
                attendanceSummary: sitemap.instructor.event.attendance.summary,
                attendanceDay: sitemap.instructor.event.attendance.day,
                assessmentResults: sitemap.instructor.event.assessments.assessmentResults,
                learnerAssessment: sitemap.instructor.event.assessments.learnerAssessment,
                assessments: sitemap.instructor.event.assessments.assessments,
                evaluationSummary: sitemap.instructor.event.evaluations.summary,
            };
        } else if (roleType === RoleType.ClientAdmin) {
            return {
                details: sitemap.thirdPartyProvider.event.details,
                content: sitemap.thirdPartyProvider.event.content,
                enrollment: sitemap.thirdPartyProvider.event.enrollment,
                attendanceSummary: sitemap.thirdPartyProvider.event.attendance.summary,
                attendanceDay: sitemap.thirdPartyProvider.event.attendance.day,
                assessmentResults: sitemap.thirdPartyProvider.event.assessments.assessmentResults,
                learnerAssessment: sitemap.thirdPartyProvider.event.assessments.learnerAssessment,
                assessments: sitemap.thirdPartyProvider.event.assessments.assessments,
                evaluationSummary: sitemap.thirdPartyProvider.event.evaluations.summary,
            };
        } else if (
            roleType === RoleType.NfpaAdministrator ||
            roleType === RoleType.NfpaOperator ||
            roleType === RoleType.NfpaSupport ||
            roleType === RoleType.AenAdministrator
        ) {
            return {
                details: sitemap.admin.event.manage.details,
                content: sitemap.admin.event.manage.content,
                enrollment: sitemap.admin.event.manage.enrollment,
                attendanceSummary: sitemap.admin.event.manage.attendance.summary,
                attendanceDay: sitemap.admin.event.manage.attendance.day,
                assessmentResults: sitemap.admin.event.manage.assessments.assessmentResults,
                learnerAssessment: sitemap.admin.event.manage.assessments.learnerAssessment,
                assessments: sitemap.admin.event.manage.assessments.assessments,
                evaluationSummary: sitemap.admin.event.manage.evaluations.summary,
            };
        }

        return {
            details: sitemap.root,
            content: sitemap.root,
            enrollment: sitemap.root,
            attendanceSummary: sitemap.root,
            attendanceDay: sitemap.root,
            assessmentResults: sitemap.root,
            learnerAssessment: sitemap.root,
            assessments: sitemap.root,
            evaluationSummary: sitemap.root,
        };
    }, [roleType]);

    return {
        viewEventRoutes,
    };
}
