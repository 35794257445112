import axios, { AxiosError, AxiosResponse } from "axios";
import { ResultRecord } from "andculturecode-javascript-core";
import { ServiceUtils as AndcultureCodeServiceUtils } from "andculturecode-javascript-core";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GlobalStateDispatcher {
    setUnauthenticated(): void;
    setForbidden(error: ResultRecord<unknown>): void;
}

interface ServicesConfiguration {
    cultureCode: string;
    dispatcher: GlobalStateDispatcher;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Variables
// -------------------------------------------------------------------------------------------------

let _configuration: ServicesConfiguration;

// -------------------------------------------------------------------------------------------------
// #region Utility Functions
// -------------------------------------------------------------------------------------------------

/**
 * Configure services globally
 */
const configure = (configuration: ServicesConfiguration) => {
    _configuration = configuration;

    AndcultureCodeServiceUtils.configure(
        _configuration.cultureCode,
        handleApiResponseError,
        handleApiResponseSuccess
    );
};

// #endregion Utility Functions

// -------------------------------------------------------------------------------------------------
// #region Event Handlers
// -------------------------------------------------------------------------------------------------
const handleApiResponseError = (error: AxiosError) => {
    const { response } = error ?? {};

    if (response == null) {
        return Promise.reject(error);
    }

    // Handle global HTTP status codes
    switch (response.status) {
        case 401: {
            _configuration.dispatcher.setUnauthenticated();
            break;
        }
        case 403: {
            _configuration.dispatcher.setForbidden(getForbiddenResultRecord(error));
            break;
        }
    }

    // If shape of a Result, directly wrap in result record
    const { data } = response;
    if (data?.errors != null) {
        return Promise.reject(new ResultRecord(data));
    }

    // Wrap unhandled error in result record
    const unhandledResult = new ResultRecord().addError("Unknown", `${error}`);
    return Promise.reject(unhandledResult);
};

const handleApiResponseSuccess = (response: AxiosResponse) => response;

// #endregion Event Handlers

// -------------------------------------------------------------------------------------------------
// #region Functions
// -------------------------------------------------------------------------------------------------

const getForbiddenResultRecord = (error: AxiosError): ResultRecord<unknown> => {
    const { response } = error;

    if (response?.data != null && typeof response?.data === "object") {
        return new ResultRecord(response.data);
    }

    return new ResultRecord().addError("Unknown", `${error}`);
};

// #endregion Functions

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

const ServiceUtils = {
    ...AndcultureCodeServiceUtils,
    configure: configure,
    handleApiResponseError: handleApiResponseError,
    handleApiResponseSuccess: handleApiResponseSuccess,
};

export { ServiceUtils };

// #endregion Exports
