import React, { PropsWithChildren } from "react";
import { StringUtils } from "utilities/string-utils";
import "./data-table.scss";
import useIsOverflow from "../../../utilities/hooks/use-is-overflow";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface DataTableProps {
    cssClassName?: string;
    tight?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "data-table";
const SCROLL_CSS_CLASS_NAME = "table-scroll";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const DataTable: React.FC<PropsWithChildren<DataTableProps>> = (
    props: PropsWithChildren<DataTableProps>
) => {
    const ref = React.createRef<HTMLDivElement>();
    const isOverflow = useIsOverflow(ref);

    const tableScrollClassNames = [SCROLL_CSS_CLASS_NAME];

    const classNames = [CSS_CLASS_NAME];
    if (StringUtils.hasValue(props.cssClassName)) {
        classNames.push(props.cssClassName!);
    }

    if (props.tight) {
        classNames.push("-tight");
    }

    if (isOverflow) {
        tableScrollClassNames.push("-overflow");
    } else {
        const overflowIndex = tableScrollClassNames.findIndex((s) => s === "-overflow");

        if (overflowIndex > -1) {
            tableScrollClassNames.splice(overflowIndex, 1);
        }
    }

    return (
        <div className="table-wrap">
            <div className={tableScrollClassNames.join(" ")} ref={ref}>
                <table className={classNames.join(" ")}>{props.children}</table>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { DataTable };

// #endregion Exports
