import React, { useEffect, useMemo } from "react";
import { AudienceType } from "models/enumerations/audiences/audience-type";
import { EnrollmentContext } from "utilities/contexts/use-enrollment-context";
import { Icons } from "components/icons/constants/icons";
import { Language } from "models/enumerations/languages/language";
import {
    LearnerTrainingLayoutSidebar,
    SidebarNavItem,
} from "layouts/learner/learner-training-layout/learner-training-layout-sidebar/learner-training-layout-sidebar";
import { NumberUtils } from "utilities/number-utils";
import { Outlet, useParams } from "react-router-dom";
import { ProductCourseTypeDisplayNames } from "models/enumerations/courses/product-course-type";
import { RouteUtils } from "utilities/route-utils";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import "./learner-aen-training-layout.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAENTrainingLayoutProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-aen-training-layout";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAENTrainingLayout: React.FC<LearnerAENTrainingLayoutProps> = (): JSX.Element => {
    const navigate = useNavigate();

    const { enrollmentId: enrollmentIdParam, productId: productIdParam } = useParams();

    const enrollmentId = useMemo(
        () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
        [enrollmentIdParam]
    );

    const productId = useMemo(() => NumberUtils.parseInt(productIdParam) ?? 0, [productIdParam]);

    const { enrollment, setEnrollment, isLoading } = useEnrollment({
        enrollmentId,
        includeEvent: true,
        includeProduct: true,
        includeEvaluation: true,
        includeTrainingType: TrainingType.OnlineLearning,
    });

    const badges: string[] = [];

    // Online / TTT / AEN Resource Badge (Course/Series | Learning Path)
    if (enrollment?.product?.onlineLearningType != null) {
        badges.push(t(ProductCourseTypeDisplayNames[enrollment?.product.onlineLearningType]));
    }

    if (enrollment?.product?.language != null) {
        badges.push(Language[enrollment.product?.language]);
    }

    const getAENTrainingPath = () => {
        if (enrollment?.product?.type === TrainingType.TrainTheTrainer) {
            return RouteUtils.replacePathParams(sitemap.instructor.aenTraining.details, {
                productId: productId,
                enrollmentId: enrollmentId,
            });
        } else {
            return RouteUtils.replacePathParams(
                sitemap.instructor.aenInstructorAssessment.details,
                {
                    productId: productId,
                    enrollmentId: enrollmentId,
                }
            );
        }
    };

    const navItems: SidebarNavItem[] = useMemo(() => {
        if (enrollmentId == null) {
            return [];
        }

        if (enrollment?.product?.audienceType === AudienceType.EducationNetwork) {
            return [
                {
                    matchPathOnStartsWith: true,
                    path: getAENTrainingPath(),
                    displayName: t("about"),
                    iconType: Icons.EventDetails,
                },
                {
                    path: RouteUtils.replacePathParams(sitemap.instructor.aenTraining.content, {
                        productId: productId,
                        enrollmentId: enrollmentId,
                    }),
                    displayName: t("content"),
                    iconType: Icons.Content,
                },
            ];
        }

        // return empty string if we get here.
        return [];
    }, [enrollment?.product?.audienceType, enrollmentId, productId]);

    useEffect(() => {
        if (enrollment?.withdrawnOn != null) {
            navigate(sitemap.public.noAccess);
        }
    }, [enrollment?.withdrawnOn, navigate]);

    return (
        <EnrollmentContext.Provider value={{ record: enrollment, setRecord: setEnrollment }}>
            <div className={CSS_CLASS_NAME}>
                {!isLoading && (
                    <div className={`${CSS_CLASS_NAME}__window`}>
                        <div className={`${CSS_CLASS_NAME}__window__sidebar`}>
                            <LearnerTrainingLayoutSidebar
                                badges={badges}
                                completionPercentage={enrollment?.percentComplete}
                                expirationDate={enrollment?.expirationDate}
                                trainingName={enrollment?.product?.name}
                                trainingType={enrollment!.product?.type}
                                navItems={navItems}
                            />
                        </div>
                        <div className={`${CSS_CLASS_NAME}__window__main`}>
                            <SkipNavContent>
                                <div className={`${CSS_CLASS_NAME}__body`}>
                                    <Outlet />
                                </div>
                            </SkipNavContent>
                        </div>
                    </div>
                )}
            </div>
        </EnrollmentContext.Provider>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAENTrainingLayout };

// #endregion Exports
