import { CollectionUtils, RecordUtils } from "andculturecode-javascript-core";
import { EvaluationAnswerTemplateRecord } from "models/view-models/evaluation-templates/evaluation-answer-template-record";
import { EvaluationQuestionTemplate } from "models/interfaces/evaluation-templates/evaluation-question-template";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { Record } from "immutable";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EvaluationQuestionTemplate = {
    answers: undefined,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    evaluationTemplate: undefined,
    evaluationTemplateId: 0,
    id: undefined,
    isRequired: false,
    isResponseNFPAOnly: false,
    questionText: "",
    sortOrder: undefined,
    type: undefined,
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EvaluationQuestionTemplateRecord extends Record(defaultValues)
    implements EvaluationQuestionTemplate {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EvaluationQuestionTemplate>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.evaluationTemplate != null) {
            params.evaluationTemplate = RecordUtils.ensureRecord(
                params.evaluationTemplate,
                EvaluationTemplateRecord
            );
        }

        if (CollectionUtils.hasValues(params.answers)) {
            params.answers = RecordUtils.ensureRecords(
                params.answers,
                EvaluationAnswerTemplateRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Convenience method to sort the Answers by the Answer.SortOrder
     */
    public sortAnswers(): void {
        if (!CollectionUtils.hasValues(this.answers)) {
            return;
        }

        this.answers!.sort(
            (a?: EvaluationAnswerTemplateRecord, b?: EvaluationAnswerTemplateRecord) => {
                return (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0);
            }
        );
    }

    /**
     * Given a set of values for EvaluationQuestionTemplate properties, create a new
     * EvaluationQuestionTemplateRecord object from this instance, overwriting the properties in the
     * values parameter with values provided.
     *
     * @param {Partial<EvaluationQuestionTemplate>} values The values to overwrite on this instance.
     * @returns An EvaluationQuestionTemplateRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EvaluationQuestionTemplate>): EvaluationQuestionTemplateRecord {
        return new EvaluationQuestionTemplateRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationQuestionTemplateRecord };

// #endregion Exports
