import React from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { t } from "utilities/localization/t";
import "./event-activation-provider-section.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventActivationProviderSectionProps {
    event: EventActiveRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-activation-provider-section";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventActivationProviderSection: React.FC<EventActivationProviderSectionProps> = ({
    event,
}): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("enProvider")}</h3>
            <DataTable>
                <thead>
                    <tr>
                        <th className="-name">{t("name")}</th>
                        <th className="-id">{t("id")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key={event.providerId}>
                        <td className="-name">{event.provider?.name}</td>
                        <td className="-id">{event.providerId}</td>
                    </tr>
                </tbody>
            </DataTable>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventActivationProviderSection };

// #endregion Exports
