import React, { useMemo } from "react";
import { Heading, HeadingSize, HeadingPriority } from "components/typography/heading/heading";
import { TopicBubble } from "components/bubble/topic-bubble";
import { Card } from "components/card/card";
import { ProductRecord } from "models/view-models/products/product-record";
import { StringUtils } from "andculturecode-javascript-core";
import { Topic, TopicDisplayNames } from "models/enumerations/courses/topic";
import { UnitRecord } from "models/view-models/units/unit-record";
import "./product-description-card.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductDescriptionCardProps {
    cssClassName?: string;
    product?: ProductRecord;
    stacked?: boolean;
    units: UnitRecord[];
    isLoading?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-description-card";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductDescriptionCard: React.FC<ProductDescriptionCardProps> = ({
    cssClassName,
    product,
    stacked,
    units,
}): JSX.Element => {
    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    const topics: Array<Topic|undefined> = useMemo(() => {
        const unitCourses = units?.map((unit) => unit.unitCourses).flat();

        const topics: Array<Topic|undefined> = unitCourses?.map((uc) =>
            uc?.course?.topic
        ).filter((topic) => topic != null );;

        const distinctSortedTopics = topics
            ?.filter((topic, index) => topics.indexOf(topic) === index)
            .sort();

        return distinctSortedTopics;
    }, [units]);


    return (
        <Card cssClassName={className} stacked={stacked}>
            <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H3}>
                {product?.name}
            </Heading>
            <p>{product?.description}</p>
            <div className={`${className}__topic-bubble`}>
                {topics.map((topic, index) => (
                    topic != null &&
                    <TopicBubble topic={topic} key={index} />
                )
                )}
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductDescriptionCard };

// #endregion Exports
