import { StringUtils } from "andculturecode-javascript-core/dist/utilities/string-utils";
import { Card } from "components/card/card";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { EventDayStatus } from "models/enumerations/events/event-day-status";
import { EventDayRecord } from "models/view-models/events/event-day-record";
import { EventRecord } from "models/view-models/events/event-record";
import { Icons } from "components/icons/constants/icons";
import { DateUtils } from "utilities/date-utils";
import { EventChecklistItem } from "./event-details-checklist-item/event-details-checklist-item";
import { useMemo } from "react";
import { EventChecklist } from "models/interfaces/events/event-checklist";
import { t } from "utilities/localization/t";
import "./event-details-checklist.scss";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventDetailsChecklistProps {
    cssClassName?: string;
    event?: EventRecord;
    onChecklistChange: (values: Partial<EventChecklist>) => void;
    stacked?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-details-checklist";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventDetailsChecklist: React.FC<EventDetailsChecklistProps> = ({
    cssClassName,
    event,
    onChecklistChange,
    stacked,
}): JSX.Element => {
    const checklist = useMemo(() => event?.eventChecklist, [event?.eventChecklist]);

    const classNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(cssClassName)) {
        classNames.push(cssClassName);
    }

    const className: string = classNames.join(" ");

    return (
        <Card cssClassName={className} stacked={stacked}>
            <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H3}>
                {t("checklist")}
            </Heading>
            <div className={`${className}__items`}>
                <CheckboxInput
                    checked={checklist?.trainingContentReviewed ?? false}
                    checkedIcon={Icons.CircleChecked}
                    uncheckedIcon={Icons.CircleUnchecked}
                    id={`checklist-${checklist?.id}-training-reviewed`}
                    label={t("reviewTrainingContent")}
                    onChange={() =>
                        onChecklistChange?.({
                            trainingContentReviewed: !checklist?.trainingContentReviewed,
                        })
                    }
                />
                {event?.eventDays != null &&
                    event.eventDays.map(
                        (contentItem: EventDayRecord, index): JSX.Element => (
                            <EventChecklistItem
                                key={index}
                                isChecked={contentItem.status === EventDayStatus.Completed}
                                label={t(
                                    "takeAttendanceDateUtilsFormatDatetruecontentItemEventDateToString",
                                    {
                                        DateUtilsFormatDatetruecontentItemEventDateToString:
                                            DateUtils.formatDate(
                                                true,
                                                contentItem.eventDate().toString()
                                            ),
                                    }
                                )}
                            />
                        )
                    )}

                <CheckboxInput
                    checked={checklist?.assessmentResultsReviewed ?? false}
                    checkedIcon={Icons.CircleChecked}
                    uncheckedIcon={Icons.CircleUnchecked}
                    id={`checklist-${checklist?.id}-assessment-reviewed`}
                    label={t("reviewAssessmentResults")}
                    onChange={() =>
                        onChecklistChange?.({
                            assessmentResultsReviewed: !checklist?.assessmentResultsReviewed,
                        })
                    }
                />
                <CheckboxInput
                    checked={checklist?.evaluationResultsReviewed ?? false}
                    checkedIcon={Icons.CircleChecked}
                    uncheckedIcon={Icons.CircleUnchecked}
                    id={`checklist-${checklist?.id}-evaluation-reviewed`}
                    label={t("reviewEvaluationResults")}
                    onChange={() =>
                        onChecklistChange?.({
                            evaluationResultsReviewed: !checklist?.evaluationResultsReviewed,
                        })
                    }
                />
            </div>
        </Card>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDetailsChecklist };

// #endregion Exports
