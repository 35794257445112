import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { Response } from "models/interfaces/responses/response";
import { QuestionRecord } from "models/view-models/questions/question-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: Response = {
    attemptId: 0,
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    learnerResponse: "",
    questionId: 0,
    question: undefined,
    result: "",
    updatedById: undefined,
    updatedOn: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ResponseRecord extends Record(defaultValues) implements Response {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<Response>) {
        params = params ?? Object.assign({}, defaultValues);

        if (params.question != null) {
            params.question = RecordUtils.ensureRecord(params.question, QuestionRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<Response>): ResponseRecord {
        return new ResponseRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ResponseRecord };

// #endregion Exports
