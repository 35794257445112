import React, { useCallback, useMemo } from "react";
import { Icons } from "components/icons/constants/icons";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { Donut, DonutSize, DonutStyle } from "components/donut/donut";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useLocation, Location } from "react-router-dom";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icon } from "components/icons/icon";
import { TrainingType } from "models/enumerations/courses/training-type";
import { TrainingTypeDisplayNames } from "models/enumerations/courses/training-type";
import Tooltip, { TooltipPlacement, TooltipStyle } from "components/tooltip/tooltip";
import { DateUtils } from "utilities/date-utils";
import { t } from "utilities/localization/t";
import "./learner-training-layout-sidebar.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerTrainingLayoutSidebarProps {
    badges?: string[];
    completionPercentage?: number;
    cssClassName?: string;
    expirationDate?: Date;
    trainingName?: string;
    trainingType?: TrainingType;
    navItems?: SidebarNavItem[];
}

export interface SidebarNavItem {
    disabled?: boolean;
    displayName: string;
    iconType: Icons;
    matchPathOnStartsWith?: boolean;
    path: string;
}

export interface SidebarNavItemProps {
    disabled?: boolean;
    displayName: string;
    isActive: boolean;
    icon: Icons;
    onClick: () => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-training-layout-sidebar";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerTrainingLayoutSidebar: React.FC<LearnerTrainingLayoutSidebarProps> = (
    props: LearnerTrainingLayoutSidebarProps
): JSX.Element => {
    const location: Location = useLocation();
    const navigate = useNavigate();
    const badges: string[] = props.badges == null ? [] : props.badges;
    const navItems = props.navItems ?? new Array<SidebarNavItem>();
    const trainingType = props.trainingType;

    const stepIsActive = useCallback(
        (navItem: SidebarNavItem) => {
            return navItem.matchPathOnStartsWith === true
                ? location.pathname.startsWith(navItem.path)
                : navItem.path === location.pathname;
        },
        [location]
    );

    let sidebarNavItems: SidebarNavItemProps[] = navItems.map(
        (navItem): SidebarNavItemProps => ({
            disabled: navItem.disabled,
            displayName: navItem.displayName,
            icon: navItem.iconType,
            isActive: stepIsActive(navItem),
            onClick: () => {
                navigate(navItem.path);
            },
        })
    );

    const trainingTypeIcon = useMemo(() => {
        if (trainingType === TrainingType.InstructorLedTraining) {
            return Icons.Calendar;
        }

        if (trainingType === TrainingType.OnlineLearning) {
            return Icons.LibraryBooks;
        }

        return Icons.LibraryBooks;
    }, [trainingType]);

    return (
        <aside className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__details`}>
                <div className={`${CSS_CLASS_NAME}__details__header`}>
                    <Donut
                        includeLabel={true}
                        percent={props.completionPercentage ?? 0}
                        size={DonutSize.Large}
                        style={DonutStyle.Inverted}
                        subLabel={t("complete")}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__details__info-tags`}>
                    <div className={`${CSS_CLASS_NAME}__details__info`}>
                        {props.expirationDate != null && (
                            <Paragraph cssClassName="-expiration" style={ParagraphStyle.Label}>
                                {t("expExpirationDate", {
                                    expirationDate: DateUtils.formatDateCustom(
                                        props.expirationDate!.toString(),
                                        t("shortDateFormat")
                                    ),
                                })}
                            </Paragraph>
                        )}
                        {trainingType != null && (
                            <div className={`${CSS_CLASS_NAME}__details__info__training-type`}>
                                <Paragraph
                                    cssClassName="line-clamp-2"
                                    size={ParagraphSize.Large}
                                    style={ParagraphStyle.Label}>
                                    <Icon type={trainingTypeIcon} />
                                    {t(TrainingTypeDisplayNames[trainingType])}
                                </Paragraph>
                            </div>
                        )}
                        {props.trainingName && (
                            <Tooltip
                                content={props.trainingName}
                                placement={TooltipPlacement.Top}
                                style={TooltipStyle.Secondary}>
                                <span className={`${CSS_CLASS_NAME}__details__info__class-name`}>
                                    <Paragraph
                                        cssClassName="line-clamp-2 break-words"
                                        size={ParagraphSize.Large}
                                        style={ParagraphStyle.Inverted}>
                                        {props.trainingName}
                                    </Paragraph>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    {badges != null && badges.length > 0 && (
                        <div className={`${CSS_CLASS_NAME}__details__tags`}>
                            {badges.map((badgeText, i) => (
                                <Badge
                                    key={`badge-${i}`}
                                    style={BadgeStyle.Transparent}
                                    text={badgeText}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__nav`}>
                {sidebarNavItems.map((item, i) => {
                    const classNames = [];

                    if (item.isActive) {
                        classNames.push("-active");
                    }

                    if (item.disabled) {
                        classNames.push("-disabled");
                    }

                    return (
                        <button
                            className={classNames.join(" ")}
                            disabled={item.disabled}
                            key={`nav-sidebar-${i}`}
                            onClick={item.onClick}
                            type="button">
                            <Icon size={IconSizes.Large} type={item.icon} />
                            <span>{item.displayName}</span>
                        </button>
                    );
                })}
            </div>
        </aside>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerTrainingLayoutSidebar };

// #endregion Exports
