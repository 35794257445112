import ChangesBanner from "components/changes-banner/changes-banner";
import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { EventActivationContactInformation } from "components/events/event-activation-modal/event-activation-contact-information/event-activation-contact-information";
import { EventActivationInstructorSection } from "components/events/event-activation-modal/event-activation-instructor-section/event-activation-instructor-section";
import { EventActivationLocationSection } from "components/events/event-activation-modal/event-activation-location-section/event-activation-location-section";
import { EventActivationRegistrationSection } from "components/events/event-activation-modal/event-activation-registration-section/event-activation-registration-section";
import { EventActivationScheduleSection } from "components/events/event-activation-modal/event-activation-schedule-section/event-activation-schedule-section";
import { EventActivationCEUSection } from "../event-activation-modal/event-activation-ceu-section/event-activation-ceu-section";
import { EventActiveRecordWithChangeLog } from "models/active-records/events/event-active-record-with-change-log";
import { EventChangeLogType } from "models/enumerations/events/event-change-log-type";
import { EventType } from "models/enumerations/events/event-type";
import { EventActivationContractSection } from "../event-activation-modal/event-activation-contract-section/event-activation-contract-section";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./event-change-summary-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventChangeSummaryModalProps {
    editMode: boolean;
    event: EventActiveRecordWithChangeLog;
    handleSaveChangesClick: () => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-change-summary-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventChangeSummaryModal: React.FC<EventChangeSummaryModalProps> = ({
    editMode,
    event,
    handleSaveChangesClick,
    open,
    setOpen,
}): JSX.Element => {
    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            onClick: () => handleSaveChangesClick(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={onModalClose}
            title={t("saveChanges")}
            actions={modalActionArray}
            modalStyle={""}>
            <div className={`${CSS_CLASS_NAME}__content`}>
                <Paragraph style={ParagraphStyle.Light} size={ParagraphSize.XLarge}>
                    {t(
                        "takeAMomentToReviewYourChangesBeforeSavingOnceYouSaveAllEventParticipantsWillBeNotified"
                    )}
                </Paragraph>
                <div className={`${CSS_CLASS_NAME}__name`}>
                    <h3>{t("eventName")}</h3>
                    <ChangesBanner
                        changed={event.eventChangeLogTypeChanged[EventChangeLogType.Name]}
                    />
                    <Paragraph style={ParagraphStyle.Light}>{event.name}</Paragraph>
                </div>
                {event.type === EventType.Contract && (
                    <EventActivationContractSection
                        event={event}
                        changed={event.eventChangeLogTypeChanged[EventChangeLogType.Contract]}
                        editMode={editMode}
                    />
                )}
                <EventActivationContactInformation
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Contact]}
                    editMode={editMode}
                />
                <EventActivationScheduleSection
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Schedule]}
                    editMode={editMode}
                />
                <EventActivationCEUSection
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Schedule]}
                    editMode={editMode}
                />

                <EventActivationLocationSection
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Location]}
                    editMode={editMode}
                />
                <EventActivationRegistrationSection
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Registration]}
                    editMode={editMode}
                />
                <EventActivationInstructorSection
                    event={event}
                    changed={event.eventChangeLogTypeChanged[EventChangeLogType.Instructor]}
                    editMode={editMode}
                />
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChangeSummaryModal };

// #endregion Exports
