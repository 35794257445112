// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum AccessControlKeys {
    // Pages
    AENProductCatalogPage,
    AdminAenInstructorApplications,
    AdminAenProviderApplications,
    AdminAenApplicationFiles,
    AdminAenApplicationLogs,
    AdminAenResourcesListPage,
    AdminAnnouncementsListPage,
    AdminCatalogEnrollmentLogPage,
    AdminCourseListPage,
    AdminDashboardPage,
    AdminEditEventChangeLogPage,
    AdminEditEventContactPage,
    AdminEditEventDetailsPage,
    AdminEditEventInstructorPage,
    AdminEditEventLocationPage,
    AdminEditEventProductPage,
    AdminEditEventRegistrationPage,
    AdminEditEventSchedulePage,
    AdminEventListPage,
    AdminProductListPage,
    AdminProductEnrollmentPage,
    AdminReportsDownloadsPage,
    AdminReportsListPage,
    AdminReportsSetupPage,
    AdminSandboxPage,
    AzureLoginPage,
    CreateCourseMaterialsPage,
    EditCourseDetailsPage,
    EditCourseMaterialsPage,
    EditCourseVersionListPage,
    EditAssessmentProductMaterialsPage,
    ENInstructorDirectoryPage,
    ENProductCatalogPage,
    GroupAssociatedUsersPage,
    GroupInfoPage,
    GroupsAssociatedUsersPage,
    GroupsListPage,
    GroupsNewPage,
    InstructorApplicationAssessmentPage,
    InstructorDashboardPage,
    InstructorDirectoryPage,
    InstructorENHRequiredTrainingsPage,
    InstructorENHResourcesPage,
    LearnerAssessmentPage,
    LearnerOnlineLearningAchievementsPage,
    LearnerOnlineLearningAssessmentResultsPage,
    LearnerOnlineLearningContentPage,
    LearnerOnlineLearningDetailsPage,
    LearnerOnlineLearningEvaluationPage,
    LearnerOnlineLearningUnitCourseAssessmentPage,
    LearnerOnlineLearningUnitCoursePage,
    LearnerOnlineLearningUnitPage,
    LearnerTakeATrainingAchievementsPage,
    LearnerTakeATrainingAssessmentResultsPage,
    LearnerTakeATrainingAssessmentsPage,
    LearnerTakeATrainingContentPage,
    LearnerTakeATrainingEvaluationPage,
    LearnerTrainingSummaryILTPage,
    LearnerTrainingSummaryOLLPage,
    ManageEventAssessmentResultsPage,
    ManageLiveVirtualEventAssessmentPage,
    ManageLiveVirtualEventAttendanceDayPage,
    ManageLiveVirtualEventAttendanceSummaryPage,
    ManageLiveVirtualEventContentPage,
    ManageLiveVirtualEventDetailsPage,
    ManageLiveVirtualEventEnrollmentPage,
    ManageLiveVirtualEventEvaluationsPage,
    MyCertificatesPage,
    MyLearningAssignmentsPage,
    MyTeachingAssignmentsPage,
    NfpaSupportDashboardPage,
    NoAccessPage,
    NotFoundPage,
    OperatorDashboardPage,
    ProfilePage,
    ProviderDashboardPage,
    ProviderENHOrganizationPage,
    ProviderENHResourcesPage,
    SendAnEmailPage,
    SupportPage,
    ThirdPartyDashboardInstructorPage,
    ThirdPartyDashboardOnlinePage,
    ThirdPartyOnlineLearningDetailsPage,
    ThirdPartyOnlineLearningEnrollmentPage,
    UserBasicInfoPage,
    UserContractsILLDetailPage,
    UserContractsILLListPage,
    UserContractsInfoPage,
    UserContractsListPage,
    UserContractsNewPage,
    UserContractsOLLDetailPage,
    UserContractsOLLListPage,
    UserContractsOLLNewPage,
    UserENInstructorPage,
    UserListPage,
    UserLoginPage,
    UserNewPage,
    UserProviderInfoPage,
    UserProvidersAssociatedUsersPage,
    UserProvidersListPage,
    UserProvidersNewPage,
    UserTrainingAssessmentPage,
    UserTrainingDetailPage,
    UserTrainingsPage,

    // Actions
    CanChangeProviderStatus,
    CanDownloadCertificates,
    CanDownloadEnrollmentReport,
    CanModifyProducts,
    CanUseAllTranscriptReportFilters,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AccessControlKeys };

// #endregion Exports
