import RadioButton, {
    RadioButtonLabelPosition,
} from "components/form/inputs/radio-button-input/radio-button/radio-button";
import React, { useMemo } from "react";
import { Card } from "components/card/card";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { EvaluationAnswerTemplateRecord } from "models/view-models/evaluation-templates/evaluation-answer-template-record";
import { EvaluationQuestionTemplateRecord } from "models/view-models/evaluation-templates/evaluation-question-template-record";
import { EvaluationQuestionType } from "models/enumerations/evaluations/evaluation-question-type";
import { EvaluationResponse } from "models/interfaces/evaluations/evaluation-response";
import { EvaluationResponseRecord } from "models/view-models/evaluations/evaluation-response-record";
import { EvaluationTemplateRecord } from "models/view-models/evaluation-templates/evaluation-template-record";
import { FormTextArea } from "components/form/form-textarea/form-textarea";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import { Language } from "models/enumerations/languages/language";
import { Arabic } from "cultures/arabic";
import { English } from "cultures/english";
import { Spanish } from "cultures/spanish";
import "./evaluation-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EvaluationFormProps {
    evaluationTemplate: EvaluationTemplateRecord;
    onResponseChange: (
        question: EvaluationQuestionTemplateRecord,
        responses: EvaluationResponseRecord[]
    ) => void;
    responses: EvaluationResponseRecord[];
    title?: string;
    readOnly?: boolean;
}

interface EvaluationQuestionProps {
    onResponseChange: (
        question: EvaluationQuestionTemplateRecord,
        responses: EvaluationResponseRecord[]
    ) => void;
    question: EvaluationQuestionTemplateRecord;
    responses: EvaluationResponseRecord[];
    language: Language;
}

interface EvaluationAnswerProps {
    answers: EvaluationAnswerTemplateRecord[] | undefined;
    onResponseChange: (
        question: EvaluationQuestionTemplateRecord,
        responses: EvaluationResponseRecord[]
    ) => void;
    question: EvaluationQuestionTemplateRecord;
}

interface EvaluationSingleResponseAnswerProps extends EvaluationAnswerProps {
    response: EvaluationResponseRecord;
    language: Language;
}

interface EvaluationMultiResponseAnswerProps extends EvaluationAnswerProps {
    responses: EvaluationResponseRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "evaluation-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EvaluationForm: React.FC<EvaluationFormProps> = (props: EvaluationFormProps): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            {props.title != null && (
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <h1>{props.title}</h1>
                    {props.evaluationTemplate?.instructionText != null && (
                        <Paragraph>{props.evaluationTemplate?.instructionText}</Paragraph>
                    )}
                </div>
            )}
            {props.evaluationTemplate.questions?.map(
                (question, index) =>
                    question && (
                        <EvaluationQuestionRow
                            key={index}
                            onResponseChange={props.onResponseChange}
                            question={question}
                            responses={props.responses.filter(
                                (r) => r.evaluationQuestionTemplateId === question.id
                            )}
                            language={props.evaluationTemplate?.language ?? Language.English}
                        />
                    )
            )}
        </div>
    );
};

const EvaluationQuestionRow: React.FC<EvaluationQuestionProps> = (
    props: EvaluationQuestionProps
): JSX.Element => {
    const question = props.question;
    let answerComponent = null;
    let typeClassName = null;

    const response =
        props.responses[0] ??
        new EvaluationResponseRecord({
            evaluationQuestionTemplate: props.question,
            evaluationQuestionTemplateId: props.question.id,
        });

    switch (question.type) {
        case EvaluationQuestionType.Likert:
            answerComponent = (
                <EvaluationLikertAnswer
                    response={response}
                    question={question}
                    answers={question.answers}
                    onResponseChange={props.onResponseChange}
                    language={props.language}
                />
            );
            break;
        case EvaluationQuestionType.FreeResponse:
            typeClassName = "freeform";
            answerComponent = (
                <EvaluationFreeResponseAnswer
                    response={response}
                    question={question}
                    answers={question.answers}
                    onResponseChange={props.onResponseChange}
                    language={props.language}
                />
            );
            break;
        case EvaluationQuestionType.MultiSelect:
            typeClassName = "multi-select";
            answerComponent = (
                <EvaluationMultiSelectAnswer
                    responses={props.responses}
                    question={question}
                    answers={question.answers}
                    onResponseChange={props.onResponseChange}
                />
            );
            break;
        case EvaluationQuestionType.NPS:
            typeClassName = "";
            answerComponent = (
                <EvaluationNPSAnswer
                    response={response}
                    question={question}
                    answers={question.answers}
                    onResponseChange={props.onResponseChange}
                    language={props.language}
                />
            );
            break;
    }

    return (
        <Card stacked={true}>
            <div className="question">
                <Paragraph cssClassName="question__text">{`${question.questionText}${
                    question.isRequired ? "*" : ""
                }`}</Paragraph>
                <div className={`question__fields ${typeClassName}`}>{answerComponent}</div>
            </div>
        </Card>
    );
};

const EvaluationLikertAnswer: React.FC<EvaluationSingleResponseAnswerProps> = (
    props: EvaluationSingleResponseAnswerProps
): JSX.Element => {
    const updateResponse = (values: Partial<EvaluationResponse>): void => {
        props.onResponseChange(props.question, [props.response.with(values)]);
    };

    const handleLikertResponseChange = (value?: number): void => {
        updateResponse({ evaluationAnswerTemplateId: value });
    };

    return (
        <>
            {props.answers?.map((answer, index) => (
                <RadioButton
                    key={index}
                    checked={props.response.evaluationAnswerTemplateId === answer.id}
                    formFieldName={`${props.question.id}-${answer.id}`}
                    groupName={`evaluation-question-${props.question.id}`}
                    id={`${props.question.id}-${answer.id}`}
                    label={answer.answerText}
                    labelPosition={RadioButtonLabelPosition.Bottom}
                    onChange={() => handleLikertResponseChange(answer.id)}
                    value={answer.id}
                />
            ))}
        </>
    );
};

const EvaluationFreeResponseAnswer: React.FC<EvaluationSingleResponseAnswerProps> = (
    props: EvaluationSingleResponseAnswerProps
): JSX.Element => {
    const updateResponse = (values: Partial<EvaluationResponse>): void => {
        props.onResponseChange(props.question, [props.response.with(values)]);
    };

    const handleFreeResponseChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        updateResponse({ freeResponse: e.target.value });
    };

    return (
        <FormTextArea
            formFieldName={`freeForm-${props.question.id}`}
            id={`freeForm-${props.question.id}`}
            label=""
            maxLength={1000}
            onChange={handleFreeResponseChange}
            placeholder={t("enterResponse")}
            rows={6}
            value={props.response.freeResponse}
        />
    );
};

const EvaluationMultiSelectAnswer: React.FC<EvaluationMultiResponseAnswerProps> = (
    props: EvaluationMultiResponseAnswerProps
): JSX.Element => {
    const handleMultiSelectResponseChange = (value?: number): void => {
        const newResponses = [...props.responses];
        const checkedItemIndex = newResponses.findIndex(
            (r) => r.evaluationAnswerTemplateId === value
        );

        // If an occurrence already exists, remove it.
        if (checkedItemIndex >= 0) {
            newResponses.splice(checkedItemIndex, 1);
        } else {
            newResponses.push(
                new EvaluationResponseRecord({
                    evaluationQuestionTemplate: props.question,
                    evaluationQuestionTemplateId: props.question.id,
                    evaluationAnswerTemplateId: value,
                })
            );
        }

        props.onResponseChange(props.question, newResponses);
    };

    const isAnswerSelected = (responses: EvaluationResponse[], answerId: number): boolean => {
        return responses.findIndex((r) => r.evaluationAnswerTemplateId === answerId) >= 0;
    };

    return (
        <>
            {props.answers?.map((answer, index) => (
                <CheckboxInput
                    checked={isAnswerSelected(props.responses, answer.id ?? 0)}
                    id={`${props.question.id}-${answer.id}`}
                    key={index}
                    label={answer.answerText}
                    name={`${props.question.id}-${answer.id}`}
                    onChange={() => handleMultiSelectResponseChange(answer.id)}
                />
            ))}
        </>
    );
};

const EvaluationNPSAnswer: React.FC<EvaluationSingleResponseAnswerProps> = (
    props: EvaluationSingleResponseAnswerProps
): JSX.Element => {
    const { language, onResponseChange, response, question } = props;

    const updateResponse = (values: Partial<EvaluationResponse>): void => {
        onResponseChange(question, [props.response.with(values)]);
    };

    const handleNPSResponseChange = (value?: number): void => {
        updateResponse({ evaluationAnswerTemplateId: value });
    };

    const cultureResource = useMemo(() => {
        switch (language) {
            case Language.English:
                return English;
            case Language.Spanish:
                return Spanish;
            case Language.Arabic:
                return Arabic;
            default:
                return English;
        }
    }, [language]);

    const getAriaLabel = (value: number): string => {
        if (value === 1) {
            return cultureResource.resources.oneVeryUnlikelyOnAScaleOfOneToTen;
        }
        if (value === 10) {
            return cultureResource.resources.tenVeryLikelyOnAScaleOfOneToTen;
        } else {
            return `${value}`;
        }
    };

    return (
        <div className="nps-container">
            <div className="nps-buttons">
                {props.answers?.map((answer, index) => {
                    return (
                        <RadioButton
                            key={index}
                            checked={response.evaluationAnswerTemplateId === answer.id}
                            ariaLabelledBy={`${getAriaLabel(index)}`}
                            formFieldName={`${props.question.id}-${answer.id}`}
                            groupName={`evaluation-question-${props.question.id}`}
                            id={`${question.id}-${answer.id}`}
                            label={answer.answerText}
                            labelPosition={RadioButtonLabelPosition.Bottom}
                            onChange={() => handleNPSResponseChange(answer.id)}
                            value={answer.id}
                        />
                    );
                })}
            </div>
            <div className="nps-labels">
                <Paragraph
                    cssClassName="nps-unlikely"
                    ariaHidden={true}
                    size={ParagraphSize.Small}
                    style={ParagraphStyle.Label}>
                    {cultureResource.resources.veryUNLIKELY.toLocaleUpperCase()}
                </Paragraph>
                <Paragraph
                    cssClassName="nps-likely"
                    ariaHidden={true}
                    size={ParagraphSize.Small}
                    style={ParagraphStyle.Label}>
                    {cultureResource.resources.veryLIKELY.toLocaleUpperCase()}
                </Paragraph>
            </div>
        </div>
    );
};
// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EvaluationForm };

// #endregion Exports
