import React, { useMemo } from "react";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { useParams } from "react-router-dom";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { TrainingType } from "models/enumerations/courses/training-type";
import { sitemap } from "sitemap";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { UnitCourseCard } from "components/courses/unit-course-card/unit-course-card";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { ButtonSize, ButtonType, ButtonStyle } from "components/buttons/button/button";
import { TopicBubble } from "components/bubble/topic-bubble";
import { Donut } from "components/donut/donut";
import { EnrollmentUnitCourseStatus } from "models/enumerations/enrollments/enrollment-unit-course-status";
import { t } from "utilities/localization/t";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";
import "./learner-online-learning-unit-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningUnitPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-unit-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningUnitPage: React.FC<LearnerOnlineLearningUnitPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningUnitPage)((): JSX.Element => {
        useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
        const {
            productId: productIdParam,
            enrollmentId: enrollmentIdParam,
            unitId: unitIdParam,
        } = useParams();

        const enrollmentId = useMemo(
            () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
            [enrollmentIdParam]
        );
        const productId = useMemo(
            () => NumberUtils.parseInt(productIdParam) ?? 0,
            [productIdParam]
        );
        const unitId = useMemo(() => NumberUtils.parseInt(unitIdParam) ?? 0, [unitIdParam]);

        const syllabusPath: string = useMemo(
            () =>
                RouteUtils.replacePathParams(sitemap.learner.training.onlineLearning.details, {
                    productId: productId,
                    enrollmentId: enrollmentId,
                }),
            [productId, enrollmentId]
        );

        const { enrollment } = useEnrollment({
            enrollmentId,
            includeProduct: true,
            includeTrainingType: TrainingType.OnlineLearning,
        });

        const unit = enrollment.units.find((u) => u.id === unitId);
        const enrollmentUnitCourses = enrollment.enrollmentUnitCourses
            ?.filter((euc) => euc.unitCourse?.unitId === unitId)
            .sort((a, b) => (a.unitCourse?.sortOrder ?? 0) - (b.unitCourse?.sortOrder ?? 0));

        const topics = [
            ...new Set(enrollmentUnitCourses?.map((euc) => euc?.unitCourse?.course?.topic)),
        ];

        const unitPercentComplete = useMemo(() => {
            const totalRequiredUnitCoursesCount =
                enrollmentUnitCourses?.filter((euc) => !euc.optional).length ?? 1;

            if (totalRequiredUnitCoursesCount === 0) {
                return 100;
            }

            const passedRequiredUnitCoursesCount =
                enrollmentUnitCourses?.filter(
                    (euc) => !euc.optional && euc.status === EnrollmentUnitCourseStatus.Complete
                ).length ?? 0;

            if (passedRequiredUnitCoursesCount == null || totalRequiredUnitCoursesCount == null) {
                return 0;
            }

            return Math.round(
                (passedRequiredUnitCoursesCount / totalRequiredUnitCoursesCount) * 100
            );
        }, [enrollmentUnitCourses]);

        return (
            <div className={CSS_CLASS_NAME}>
                <div className={`${CSS_CLASS_NAME}__heading`}>
                    <ButtonIcon
                        linkPath={syllabusPath}
                        iconType={Icons.ChevronLeft}
                        buttonSize={ButtonSize.Medium}
                        buttonStyle={ButtonStyle.Secondary}
                        text={t("backToSyllabus")}
                        buttonType={ButtonType.Link}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__details`}>
                    <div>
                        <Paragraph style={ParagraphStyle.Label}>
                            {t("unitUnitSortOrder", { unitSortOrder: unit?.sortOrder })}
                        </Paragraph>
                        <Paragraph size={ParagraphSize.XLarge}>{unit?.name}</Paragraph>
                        <Badge
                            style={!unit?.optional ? BadgeStyle.Neutral : BadgeStyle.Default}
                            text={!unit?.optional ? t("required") : t("optional")}
                        />
                        <Paragraph style={ParagraphStyle.Label}>{t("aboutThisUnit")}</Paragraph>
                        <Paragraph size={ParagraphSize.Large} style={ParagraphStyle.Light}>
                            {unit?.description}
                        </Paragraph>
                    </div>
                    <div className={`${CSS_CLASS_NAME}__topics__container`}>
                        <div className={`${CSS_CLASS_NAME}__topics`}>
                            <Paragraph style={ParagraphStyle.Label}>{t("topics")}</Paragraph>
                            <div className={`${CSS_CLASS_NAME}__topic-bubbles`}>
                                {topics.map(
                                    (topic, index) =>
                                        topic != null && <TopicBubble topic={topic} key={index} />
                                )}
                            </div>
                        </div>
                        {!unit?.optional && (
                            <>
                                <Paragraph style={ParagraphStyle.Label}>
                                    {t("completion")}
                                </Paragraph>
                                <Paragraph>
                                    <Donut includeLabel={true} percent={unitPercentComplete} />
                                </Paragraph>
                            </>
                        )}
                    </div>
                </div>
                <div className={`${CSS_CLASS_NAME}__included`}>
                    <Paragraph style={ParagraphStyle.Label}>{t("includedInThisUnit")}</Paragraph>

                    {enrollmentUnitCourses?.map((enrollmentUnitCourse, index) => {
                        return (
                            <UnitCourseCard
                                id={enrollmentUnitCourse.unitCourseId}
                                courseId={enrollmentUnitCourse.unitCourse?.courseId ?? 0}
                                key={enrollmentUnitCourse.unitCourseId}
                                locked={enrollmentUnitCourse.locked}
                                name={
                                    enrollmentUnitCourse.unitCourse?.course?.name ??
                                    t("notApplicableShort")
                                }
                                status={enrollmentUnitCourse.status}
                                required={!enrollmentUnitCourse.optional}
                            />
                        );
                    })}
                </div>
            </div>
        );
    });

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningUnitPage };

// #endregion Exports
