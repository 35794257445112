export default interface FeatureFlags {
    /** Use AzureB2C for SSO session */
    useAzureB2CForSSO?: boolean;
    sendEmail?: boolean;
    sendEnrollmentExpirationReminders?: boolean;
    sendEventReminders?: boolean;
    generateCertificateFileUponEnrollmentCompletion?: boolean;
    useEducationNetwork?: boolean;
    allowProductUnpublish?: boolean;
    allowCourseUnpublish?: boolean;
    showLinkFreeTrialBanner?: boolean;
}

export const DEFAULT_FEATURE_FLAGS: Required<FeatureFlags> = {
    useAzureB2CForSSO: false,
    sendEmail: false,
    sendEnrollmentExpirationReminders: false,
    sendEventReminders: false,
    generateCertificateFileUponEnrollmentCompletion: false,
    useEducationNetwork: false,
    allowProductUnpublish: false,
    allowCourseUnpublish: false,
    showLinkFreeTrialBanner: false,
};
