import React, { useMemo } from "react";
import { NumberUtils } from "utilities/number-utils";
import { useParams } from "react-router-dom";
import { Paragraph } from "components/typography/paragraph/paragraph";
import { Card } from "components/card/card";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { sitemap } from "sitemap";
import { LearnerAssessmentSummaryHeader } from "components/assessments/learner-assessment-summary/learner-assessment-summary-header/learner-assessment-summary-header";
import { AssessmentAttemptsDetails } from "components/assessments/assessment-attempts-details/assessment-attempts-details";
import { t } from "utilities/localization/t";
import "./learner-online-learning-unit-course-assessment-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerOnlineLearningUnitCourseAssessmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "learner-online-learning-unit-course-assessment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerOnlineLearningUnitCourseAssessmentPage: React.FC<LearnerOnlineLearningUnitCourseAssessmentPageProps> =
    validatePageAccess(AccessControlKeys.LearnerOnlineLearningAssessmentResultsPage)(
        (): JSX.Element => {
            useRedirectOnForbidden(sitemap.learner.learningAssignments.inProgress);
            const {
                id: productIdParam,
                enrollmentId: enrollmentIdParam,
                courseId: courseIdParam,
                courseVersionId: courseVersionIdParam,
                assessmentId: assessmentIdParam,
            } = useParams();
            const enrollmentId = useMemo(
                () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
                [enrollmentIdParam]
            );
            const productId = useMemo(
                () => NumberUtils.parseInt(productIdParam) ?? 0,
                [productIdParam]
            );
            const courseId = useMemo(
                () => NumberUtils.parseInt(courseIdParam) ?? 0,
                [courseIdParam]
            );
            const assessmentId = useMemo(
                () => NumberUtils.parseInt(assessmentIdParam) ?? 0,
                [assessmentIdParam]
            );
            const courseVersionId = useMemo(
                () => NumberUtils.parseInt(courseVersionIdParam) ?? 0,
                [courseVersionIdParam]
            );

            return (
                <div className={CSS_CLASS_NAME}>
                    <div className={`${CSS_CLASS_NAME}__heading`}>
                        <LearnerAssessmentSummaryHeader
                            assessmentId={assessmentId}
                            title={t("assessmentName")}
                            returnTitle={t("backToCourse")}
                            returnPath={
                                sitemap.learner.training.onlineLearning.units.courses.details
                            }
                            returnPathParams={{
                                enrollmentId: enrollmentId,
                                productId: productId,
                                courseId: courseId,
                                assessmentId: assessmentId,
                            }}
                        />
                    </div>
                    <div className={`${CSS_CLASS_NAME}__details`}>
                        <Card cssClassName="assessment-overview" stacked={true}>
                            <div className="assessment-overview__heading">
                                <h5>{t("overview")}</h5>
                                <Badge style={BadgeStyle.Neutral} text={t("required")} />
                            </div>
                            <div className="assessment-overview__columns">
                                <div>
                                    <Paragraph>{t("status")}</Paragraph>
                                    <Paragraph>{t("complete")}</Paragraph>
                                </div>
                                <div>
                                    <Paragraph>{t("score")}</Paragraph>
                                    <Paragraph>88%</Paragraph>
                                </div>
                                <div>
                                    <Paragraph>{t("attempts")}</Paragraph>
                                    <Paragraph>2</Paragraph>
                                </div>
                                <div>
                                    <Paragraph>{t("result")}</Paragraph>
                                    <Badge style={BadgeStyle.Success} text={t("passed")} />
                                </div>
                            </div>
                        </Card>
                        <AssessmentAttemptsDetails />
                    </div>
                </div>
            );
        }
    );

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerOnlineLearningUnitCourseAssessmentPage };

// #endregion Exports
