import { StringUtils } from "andculturecode-javascript-core";
import { LogoAnchor } from "components/typography/anchors/logo-anchor/logo-anchor";
import React from "react";
import "./public-layout-footer.scss";
import { t } from "utilities/localization/t";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface PublicLayoutFooterProps {
    cssClassName?: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "public-layout-footer";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicLayoutFooter: React.FC<PublicLayoutFooterProps> = (
    props: PublicLayoutFooterProps
): JSX.Element => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (StringUtils.hasValue(props.cssClassName)) {
        cssClassNames.push(props.cssClassName);
    }

    const cssClassName: string = cssClassNames.join(" ");

    return (
        <footer className={cssClassName}>
            <div className={`${CSS_CLASS_NAME}__legal`}>
                &copy; National Fire Protection Association (NFPA) 2023
            </div>
            <div className={`${CSS_CLASS_NAME}__links`}>
                <a
                    className={`${CSS_CLASS_NAME}__link`}
                    href={"https://www.nfpa.org/en/Customer-Support/Terms-of-Use"}
                    target="_blank"
                    rel="noreferrer">
                    {t("termsOfUse")}
                </a>
                <a
                    className={`${CSS_CLASS_NAME}__link`}
                    href={"https://www.nfpa.org/customer-support/privacy-policy"}
                    target="_blank"
                    rel="noreferrer">
                    {t("privacyPolicy")}
                </a>
                <a
                    className={`${CSS_CLASS_NAME}__link`}
                    href={
                        "https://www.nfpa.org/customer-support#aq=%40culture%3D%22en%22&cq=%40taglistingpage%3D%3D(%22CustSupp%22)%20%20&numberOfResults=12&sortCriteria=%40title%20ascending"
                    }
                    target="_blank"
                    rel="noreferrer">
                    {t("support")}
                </a>
            </div>
        </footer>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { PublicLayoutFooter };

// #endregion Exports
