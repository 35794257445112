import {
    AnchorIcon,
    AnchorIconPosition,
} from "components/typography/anchors/anchor-icon/anchor-icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "components/icons/constants/icons";
import { RouteUtils } from "utilities/route-utils";
import { useMemo } from "react";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";
import "./learner-assessment-summary-header.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface LearnerAssessmentSummaryHeaderProps {
    assessmentId: number;
    title: string;
    optional?: boolean;
    returnTitle: string;
    returnPath: string;
    returnPathParams: any;
    isRequired?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "learner-assessment-summary-header";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const LearnerAssessmentSummaryHeader: React.FC<LearnerAssessmentSummaryHeaderProps> = (
    props: LearnerAssessmentSummaryHeaderProps
): JSX.Element => {
    const assessmentResultsPath: string = useMemo(
        (): string => RouteUtils.replacePathParams(props.returnPath, props.returnPathParams),
        [props.returnPath, props.returnPathParams]
    );

    return (
        <div className={CSS_CLASS_NAME}>
            <AnchorIcon
                iconPosition={AnchorIconPosition.Left}
                iconType={Icons.ChevronLeft}
                path={assessmentResultsPath}
                size={IconSizes.Medium}
                text={props.returnTitle}
            />
            <div className={`${CSS_CLASS_NAME}__title`}>
                <h1>{props.title}</h1>
                {props.optional != null && (
                    <Badge
                        text={props.optional ? t("optional") : t("required")}
                        style={props.optional ? BadgeStyle.Default : BadgeStyle.Neutral}
                    />
                )}
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { LearnerAssessmentSummaryHeader };

// #endregion Exports
