import { Map } from "utilities/types/map";
import { Status } from "utilities/enumerations/statuses";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum InstructorAssessmentStatus {
    NotStarted = 0,
    InProgress = 1,
    Passed = 2,
    Failed = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const InstructorAssessmentStatusDisplayNames: Map<InstructorAssessmentStatus, TranslatedCopy> = {
    [InstructorAssessmentStatus.NotStarted]: "notStarted",
    [InstructorAssessmentStatus.InProgress]: "inProgress",
    [InstructorAssessmentStatus.Passed]: "passed",
    [InstructorAssessmentStatus.Failed]: "failed",
};

const InstructorAssessmentStatusStatusMap: Map<InstructorAssessmentStatus, Status> = {
    [InstructorAssessmentStatus.NotStarted]: Status.Default,
    [InstructorAssessmentStatus.InProgress]: Status.Warning,
    [InstructorAssessmentStatus.Passed]: Status.Success,
    [InstructorAssessmentStatus.Failed]: Status.Error,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export {
    InstructorAssessmentStatus,
    InstructorAssessmentStatusDisplayNames,
    InstructorAssessmentStatusStatusMap,
};

// #endregion Exports
