import React, { useCallback, useMemo } from "react";
import { AssessmentAttemptsDetails } from "components/assessments/assessment-attempts-details/assessment-attempts-details";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { AttemptSuccessStatus } from "models/enumerations/attempts/attempt-success-status";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Button, ButtonSize, ButtonStyle, ButtonType } from "components/buttons/button/button";
import { ButtonIcon } from "components/buttons/button-icon/button-icon";
import { Card } from "components/card/card";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { Icons } from "components/icons/constants/icons";
import { NumberUtils } from "utilities/number-utils";
import { Paragraph, ParagraphSize } from "../../../../../components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { useAttempts } from "utilities/hooks/models/attempts/use-attempts";
import { useEnrollment } from "utilities/hooks/models/enrollments/use-enrollment";
import { useParams } from "react-router-dom";
import { useRegistration } from "utilities/hooks/models/registration/use-registration";
import { t } from "utilities/localization/t";
import "./user-training-assessment-page.scss";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { useRedirectOnForbidden } from "utilities/hooks/aspects/authorization/use-redirect-on-forbidden";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingAssessmentPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-assessment-page";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingAssessmentPage: React.FC<UserTrainingAssessmentPageProps> = validatePageAccess(
    AccessControlKeys.UserTrainingAssessmentPage
)((): JSX.Element => {
    useRedirectOnForbidden(sitemap.public.noAccess);
    const {
        id: userIdParam,
        enrollmentId: enrollmentIdParam,
        registrationId: registrationIdParam,
    } = useParams();
    const userId = useMemo(() => NumberUtils.parseInt(userIdParam) ?? 0, [userIdParam]);
    const enrollmentId = useMemo(
        () => NumberUtils.parseInt(enrollmentIdParam) ?? 0,
        [enrollmentIdParam]
    );
    const registrationId = useMemo(
        () => NumberUtils.parseInt(registrationIdParam) ?? 0,
        [registrationIdParam]
    );

    const { attempts, saveAttempt } = useAttempts({
        registrationId: registrationId,
        includeResponses: true,
        includeRegistration: true,
        includeLastModifiedBy: true,
    });

    const { registration } = useRegistration({ registrationId, includeTraining: true });
    const { enrollment } = useEnrollment({ enrollmentId });

    const assessmentName = useMemo((): string => {
        if (registration?.enrollmentScormPackageAssessmentId != null) {
            return (
                registration.enrollmentScormPackageAssessment?.scormPackageAssessment?.scormPackage
                    ?.name ?? "--"
            );
        }

        if (registration?.enrollmentUnitCourseId != null) {
            return registration.enrollmentUnitCourse?.unitCourse?.course?.name ?? "--";
        }

        return "--";
    }, [registration]);

    const optional = useMemo((): boolean => {
        if (registration?.enrollmentScormPackageAssessment != null) {
            return registration.enrollmentScormPackageAssessment.optional;
        }

        if (registration?.enrollmentUnitCourse != null) {
            return registration.enrollmentUnitCourse.optional;
        }

        return false;
    }, [registration]);

    const handleAttemptSave = useCallback(
        async (attempt: AttemptRecord): Promise<boolean> => {
            const attemptWithRegistration = attempt.with({ registrationId: registrationId });

            if (!(await saveAttempt(attemptWithRegistration))) {
                return false;
            }

            return true;
        },
        [saveAttempt, registrationId]
    );

    const maxScore = useMemo(() => {
        if (attempts == null) {
            return 0;
        }

        return attempts.reduce((max, attempt) => {
            if (attempt?.score == null || attempt.score < max) {
                return max;
            }

            return attempt.score;
        }, 0);
    }, [attempts]);

    const passingAttemptExists = useMemo(() => {
        if (attempts == null) {
            return false;
        }

        return attempts.some((attempt) => attempt?.successStatus === AttemptSuccessStatus.Passed);
    }, [attempts]);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className="content-wrap">
                <div className="content">
                    <SkipNavContent>
                        <div className={`${CSS_CLASS_NAME}__header`}>
                            <ButtonIcon
                                linkPath={RouteUtils.appendQueryParams(
                                    RouteUtils.replacePathParams(
                                        sitemap.admin.userManagement.users.trainings.overview,
                                        { id: userId, enrollmentId: enrollmentId }
                                    ),
                                    { tabIndex: 1 }
                                )}
                                buttonSize={ButtonSize.Small}
                                buttonStyle={ButtonStyle.Secondary}
                                buttonType={ButtonType.Link}
                                iconType={Icons.ChevronLeft}
                                text={t("backToAllAssessments")}
                            />
                            {enrollment.eventId != null && enrollment.eventId > 0 && (
                                <Button
                                    linkPath={RouteUtils.replacePathParams(
                                        sitemap.admin.event.manage.details,
                                        { id: enrollment.eventId ?? 0 }
                                    )}
                                    size={ButtonSize.Small}
                                    style={ButtonStyle.Primary}
                                    text={t("goToEvent")}
                                    type={ButtonType.Link}
                                />
                            )}
                        </div>
                        <div className={`${CSS_CLASS_NAME}__details`}>
                            <h2>{assessmentName}</h2>
                            <Badge
                                text={optional ? t("optional") : t("required")}
                                style={optional ? BadgeStyle.Default : BadgeStyle.Neutral}
                            />
                        </div>

                        <Card cssClassName={`${CSS_CLASS_NAME}__overview`} stacked={true}>
                            <Heading size={HeadingSize.XSmall} priority={HeadingPriority.H5}>
                                {t("overview")}
                            </Heading>
                            <div className={`${CSS_CLASS_NAME}__overview__grid`}>
                                <div>
                                    <Paragraph size={ParagraphSize.Small}>
                                        {t("highScore")}
                                    </Paragraph>
                                    <Paragraph size={ParagraphSize.Small}>{maxScore}</Paragraph>
                                </div>
                                <div>
                                    <Paragraph size={ParagraphSize.Small}>
                                        {t("attempts")}
                                    </Paragraph>
                                    <Paragraph size={ParagraphSize.Small}>
                                        {attempts?.length ?? 0}
                                    </Paragraph>
                                </div>
                                <div>
                                    <Paragraph size={ParagraphSize.Small}>{t("status")}</Paragraph>
                                    {passingAttemptExists ? (
                                        <Badge style={BadgeStyle.Success}>{t("passed")}</Badge>
                                    ) : (
                                        <Badge style={BadgeStyle.Warning}>{t("attempted")}</Badge>
                                    )}
                                </div>
                            </div>
                        </Card>
                        {attempts != null && attempts.length > 0 && (
                            <AssessmentAttemptsDetails
                                onAttemptSave={handleAttemptSave}
                                attempts={attempts}
                            />
                        )}
                    </SkipNavContent>
                </div>
            </div>
        </div>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingAssessmentPage };

// #endregion Exports
