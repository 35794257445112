import React from "react";
import { NumberUtils } from "utilities/number-utils";
import { ProductImage } from "components/product-image/product-image";
import { ProductRecord } from "models/view-models/products/product-record";
import { t } from "utilities/localization/t";
import "./product-info.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductInfoProps {
    product: ProductRecord;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "product-info";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductInfo: React.FC<ProductInfoProps> = ({ product }): JSX.Element => {
    return (
        <div className={CSS_CLASS_NAME}>
            <div>
                <div className={`${CSS_CLASS_NAME}__image`}>
                    <ProductImage
                        ariaLabelledBy={product.getTopicDisplay()}
                        productImageFileId={product.productImageFileId}
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__details`}>
                    <h2 className={`${CSS_CLASS_NAME}__header`}>{product.name}</h2>
                    <div className={`${CSS_CLASS_NAME}__detail-items`}>
                        <div className={`${CSS_CLASS_NAME}__topic`}>
                            <dl>
                                <dt>{t("topic").toLocaleUpperCase()}</dt>
                                <dd>{product.getTopicDisplay()}</dd>
                            </dl>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__credits`}>
                            <dl>
                                <dt>{t("ceCREDITS").toLocaleUpperCase()}</dt>
                                <dd>
                                    {NumberUtils.formatCEUsFromCreditHours(product.creditHours) ??
                                        t("notApplicableShort")}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${CSS_CLASS_NAME}__description`}>
                <dl>
                    <dt>{t("description").toLocaleUpperCase()}</dt>
                    <dd>{product.description}</dd>
                </dl>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductInfo };

// #endregion Exports
