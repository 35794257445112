import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { CourseRecord } from "models/view-models/courses/course-record";
import { CourseService, PatchCourseServiceParams } from "utilities/services/courses/course-service";
import { Icon } from "components/icons/icon";
import { IconSizes } from "components/icons/constants/icon-sizes";
import { Icons } from "../../icons/constants/icons";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { ToastManager } from "utilities/toast/toast-manager";
import { sitemap } from "sitemap";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { t } from "utilities/localization/t";
import "./course-deactivation-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseDeactivationModalProps {
    course: CourseRecord;
    open: boolean;
    productCount: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-deactivation-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseDeactivationModal: React.FC<CourseDeactivationModalProps> = ({
    course,
    open,
    productCount,
    setOpen,
}): JSX.Element => {
    const navigate = useNavigate();
    const { deactivate: courseDeactivate } = CourseService.useDeactivate();
    const courseIsUsedInProducts = productCount > 0;
    const productsCheck = courseIsUsedInProducts ? "-unchecked" : "-checked";

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("deactivateCourse"),
            disabled: courseIsUsedInProducts,
            onClick: () => deactivateCourse(),
            style: ButtonStyle.Primary,
        },
    ];

    const deactivateCourse = async (): Promise<boolean> => {
        if (course.id == null) {
            return false;
        }
        setOpen(false);
        try {
            const patchCourseServiceParams: PatchCourseServiceParams = {
                id: course.id,
            };

            const patchCourseResponse = await courseDeactivate(patchCourseServiceParams);

            const patchCourseResult = patchCourseResponse?.result;

            if (patchCourseResult?.resultObject == null || patchCourseResult.hasErrors()) {
                alert(t("deactivatingCourseFailed"));
            }
            navigate(sitemap.admin.course.list);
            ToastManager.success(t("courseIsNowDeactivated"));
        } catch {
            ToastManager.error(t("failedToDeactivateCourse"));
            return false;
        }
        return true;
    };

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={() => setOpen(false)}
            title={t("deactivateCourse")}
            actions={modalActionArray}
            modalStyle={""}>
            <Paragraph cssClassName={`${CSS_CLASS_NAME}__header`} size={ParagraphSize.Large}>
                {t(
                    "byDeactivatingThisCourseItWillNoLongerBeAvailableToAddToAProductTheCourseWillStillAppearInTheListViewOfCourses"
                )}
            </Paragraph>
            <Paragraph size={ParagraphSize.Large}>
                {t("inOrderToDeactivateThisCourseYouNeedTo")}
            </Paragraph>
            <ul>
                <li className={`${CSS_CLASS_NAME}__item`}>
                    <Icon
                        cssClassName={`${CSS_CLASS_NAME}__icon ${productsCheck}`}
                        size={IconSizes.Large}
                        type={courseIsUsedInProducts ? Icons.CircleUnchecked : Icons.CircleChecked}
                    />
                    {t("removeCourseFromAnyProducts")}
                </li>
            </ul>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseDeactivationModal };

// #endregion Exports
