import React, { useMemo, useState } from "react";
import { HeaderBanner } from "components/header-banner/header-banner";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Icons } from "components/icons/constants/icons";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";
import { QuickLinkProps } from "components/quick-link/quick-link";
import QuickLinkList from "components/quick-link/quick-link-list/quick-link-list";
import { CreateEventModal } from "components/events/create-event-modal/create-event-modal";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { SkipNavContent } from "@chakra-ui/skip-nav";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import "./operator-dashboard-page.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface OperatorDashboardPageProps {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "operator-dashboard-page";

const QUICK_LINKS: QuickLinkProps[] = [
    {
        icon: Icons.Calendar,
        path: sitemap.admin.event.list,
        text: "events",
    },
    {
        icon: Icons.UserManagement,
        path: sitemap.admin.userManagement.users.list,
        text: "userManagement",
    },
    {
        icon: Icons.Reporting,
        path: sitemap.admin.reports.list,
        text: "reports",
    },
    {
        icon: Icons.Support,
        path: sitemap.authenticated.support,
        text: "support",
    },
    {
        icon: Icons.Products,
        path: sitemap.aenProvider.productCatalog.list,
        text: "enProductCatalog",
    },
    {
        icon: Icons.Email,
        path: sitemap.admin.communications.sendAnEmail,
        text: "emailManager",
    },
];

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OperatorDashboardPage: React.FC<OperatorDashboardPageProps> = validatePageAccess(
    AccessControlKeys.OperatorDashboardPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const [showNewEventModal, setShowNewEventModal] = useState(false);

    const userFirstName = useMemo(
        () => globalState?.currentIdentity?.user?.firstName,
        [globalState?.currentIdentity?.user?.firstName]
    );

    const handleCreateEventModalClose = (): void => {
        setShowNewEventModal(false);
    };

    return (
        <>
            <HeaderBanner
                title={t("twelcomeUserFirstName", {
                    twelcome: t("welcome"),
                    userFirstName: userFirstName,
                })}
            />
            <div className={CSS_CLASS_NAME}>
                {/* TODO release v1.1
                <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__notifications`}>
                    <Banner style={BannerStyle.Success} cssClassName={`${CSS_CLASS_NAME}__banner`}>
                        <div className={`${CSS_CLASS_NAME}__banner__message`}>
                            <Icon type={Icons.Calendar} size={IconSizes.Small} />
                            <Paragraph size={ParagraphSize.XSmall}>
                                You Have a New Training Starting March 27th for Training Name Lorem
                                Ipsum Dolor Sit Amet
                            </Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__banner__actions`}>
                            <Anchor path="" text={t("viewTraining")} />
                            <button type="button" onClick={() => {}}>
                                <span className="sr-only">{t("close")}</span>
                                <Icon
                                    cssClassName="close"
                                    type={Icons.Close}
                                    size={IconSizes.Medium}
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </Banner>
                    <Banner style={BannerStyle.Error} cssClassName={`${CSS_CLASS_NAME}__banner`}>
                        <div className={`${CSS_CLASS_NAME}__banner__message`}>
                            <Icon type={Icons.Calendar} size={IconSizes.Small} />
                            <Paragraph size={ParagraphSize.XSmall}>
                                Training Name Lorem Ipsum Dolor Sit Amet Has Been Canceled
                            </Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__banner__actions`}>
                            <Anchor path="" text={t("viewTraining")} />
                            <button type="button" onClick={() => {}}>
                                <span className="sr-only">{t("close")}</span>
                                <Icon
                                    cssClassName="close"
                                    type={Icons.Close}
                                    size={IconSizes.Medium}
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </Banner>
                    <Banner style={BannerStyle.Warning} cssClassName={`${CSS_CLASS_NAME}__banner`}>
                        <div className={`${CSS_CLASS_NAME}__banner__message`}>
                            <Icon type={Icons.Calendar} size={IconSizes.Small} />
                            <Paragraph size={ParagraphSize.XSmall}>
                                Location has changed for Training Name Lorem Ipsum Dolor Sit Amet
                            </Paragraph>
                        </div>
                        <div className={`${CSS_CLASS_NAME}__banner__actions`}>
                            <Anchor path="" text={t("viewTraining")} />
                            <button type="button" onClick={() => {}}>
                                <span className="sr-only">{t("close")}</span>
                                <Icon
                                    cssClassName="close"
                                    type={Icons.Close}
                                    size={IconSizes.Medium}
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </Banner>
                </div>

                 <div className={`${CSS_CLASS_NAME}__heading`}>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("eventCalendar")}
                    </Heading>
                    <ButtonIcon
                        iconType={Icons.Plus}
                        onClick={() => setShowNewEventModal(true)}
                        buttonStyle={ButtonStyle.Primary}
                        text="Add New Event"
                    />
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <CalendarMonthView
                        events={EVENTS}
                        onEditEventClick={(eventId: number) =>
                            alert(`TODO: Implement Edit Event Click (${eventId})`)
                        }
                        onViewEventClick={(eventId: number) =>
                            alert(`TODO: Implement View Event Click (${eventId})`)
                        }
                    />
                </div> */}
                <SkipNavContent>
                    <Heading priority={HeadingPriority.H2} size={HeadingSize.XSmall}>
                        {t("quickLinks")}
                    </Heading>
                    <div className={`${CSS_CLASS_NAME}__section`}>
                        <QuickLinkList links={QUICK_LINKS} />
                    </div>
                </SkipNavContent>
            </div>
            <CreateEventModal onModalClose={handleCreateEventModalClose} open={showNewEventModal} />
        </>
    );
});

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OperatorDashboardPage };

// #endregion Exports
