import React, { useEffect, useMemo, useState } from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Event } from "models/interfaces/events/event";
import { EventActiveRecord } from "models/active-records/events/event-active-record";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { InputTypes } from "components/form/enumerations/input-types";
import { Modal, ModalAction } from "components/modal/modal";
import {
    Paragraph,
    ParagraphEmphasis,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./event-ceu-edit-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EventCEUEditModalProps {
    event: EventActiveRecord;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onEventCEUSave: (values: Partial<Event>) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// ------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "event-ceu-edit-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EventCEUEditModal: React.FC<EventCEUEditModalProps> = ({
    event,
    open,
    setOpen,
    onEventCEUSave,
}: EventCEUEditModalProps): JSX.Element => {
    const initialCEUs = useMemo(
        () => (isNaN(event.ceuOverride ?? 0) ? "" : event.ceuOverride?.toString() ?? ""),
        [event.ceuOverride]
    );

    const [eventCEUS, setEventCEUS] = useState<string>(initialCEUs);

    useEffect(() => {
        // If parent component changes the initialCEUs, update the local state.
        setEventCEUS(initialCEUs);
    }, [initialCEUs]);

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => onEventCEUCancel(),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("saveChanges"),
            onClick: () => handleEventCEUSave(),
            style: ButtonStyle.Primary,
            disabled:
                isNaN(Number.parseFloat(eventCEUS)) ||
                Number.parseFloat(eventCEUS) <= 0 ||
                eventCEUS?.toLowerCase().indexOf("e") >= 0,
        },
    ];

    const onModalClose = (): void => {
        onEventCEUCancel();
    };

    const onEventCEUCancel = () => {
        setEventCEUS(initialCEUs);
        setOpen(false);
    };

    const handleEventCEUSave = () => {
        const eventCEUsAsNumber = Number.parseFloat(eventCEUS);
        onEventCEUSave({ ceuOverride: eventCEUsAsNumber });
        setOpen(false);
    };

    const handleSetCEUs = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEventCEUS(e.target.value);
    };

    return (
        <Modal
            isOpen={open}
            onModalClose={onModalClose}
            title={t("editCEUs")}
            actions={modalActionArray}
            modalStyle={""}
            cssClassName={CSS_CLASS_NAME}>
            <>
                <Paragraph style={ParagraphStyle.Bold}>
                    {t("theRecomendedCEUsForSelectedProductIs", {
                        productCEUs: event.getRecommendedCEUs(),
                    })}
                </Paragraph>
                <Paragraph>
                    {t(
                        "ifTheEventHoursWillExceedTheDefaultCEUValueTheCEUAmountMayBeAdjustedCommonValidReasonsTheEventMayRunLongerInclude"
                    )}
                </Paragraph>
                <ul>
                    <li>{t("languageBarriers")}</li>
                    <li>{t("additionalMaterialHasBeenAddedSpecificToTheContract")}</li>
                    <li>
                        {t("participantsRequireAdditionalLearningTimeBasedOnInitialKnowledgeLevel")}
                    </li>
                    <li>
                        <b>{t("noteBreakTimeIsNOTToBeIncludedInAdjustedHoursCEUs")}</b>
                    </li>
                </ul>
                <br />
                <FormTextInput
                    id="eventCEUs"
                    formFieldName="eventCEUs"
                    label={t("eventCEUS")}
                    required={true}
                    value={eventCEUS}
                    onChange={handleSetCEUs}
                    type={InputTypes.Number}
                    placeholder={t("ceus")}
                    minValue={0}
                />
                <Paragraph
                    style={ParagraphStyle.Light}
                    size={ParagraphSize.XSmall}
                    emphasis={ParagraphEmphasis.Italic}>
                    {t("tCreditHourEqualstCEUs", { creditHour: 1, ceu: 0.1 })}
                </Paragraph>
            </>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventCEUEditModal };

// #endregion Exports
