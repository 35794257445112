import { Record } from "immutable";
import { EventChecklist } from "models/interfaces/events/event-checklist";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: EventChecklist = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    updatedById: undefined,
    updatedOn: undefined,
    assessmentResultsReviewed: false,
    evaluationResultsReviewed: false,
    trainingContentReviewed: false,
    eventId: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class EventChecklistRecord extends Record(defaultValues) implements EventChecklist {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<EventChecklist>) {
        params = params ?? Object.assign({}, defaultValues);
        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for EventChecklist properties, create a new EventChecklistRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Event>} values The values to overwrite on this instance.
     * @returns A EventRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<EventChecklist>): EventChecklistRecord {
        return new EventChecklistRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventChecklistRecord };

// #endregion Exports
