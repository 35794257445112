import { Record } from "immutable";
import { BadgeTemplate } from "models/interfaces/badges/badge-template";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: BadgeTemplate = {
    templateId: "",
    name: "",
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class BadgeTemplateRecord extends Record(defaultValues) implements BadgeTemplate {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<BadgeTemplate>) {
        params = params ?? Object.assign({}, defaultValues);
        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Given a set of values for Contract properties, create a new ContractRecord object from this
     * instance, overwriting the properties in the values parameter with values provided.
     *
     * @param {Partial<Contract>} values The values to overwrite on this instance.
     * @returns A ContractRecord with values from this instance and the values parameter.
     */
    public with(values: Partial<BadgeTemplate>): BadgeTemplateRecord {
        return new BadgeTemplateRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { BadgeTemplateRecord };

// #endregion Exports
