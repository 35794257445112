import { ServiceResponse, ServiceUtils } from "andculturecode-javascript-core";
import {
    ServiceFactory,
    ServiceHookFactory,
    useCancellablePromise,
} from "andculturecode-javascript-react";
import axios from "axios";
import { EventDayAttendanceRecord } from "models/view-models/events/event-day-attendance-record";
import { useCallback } from "react";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint: string = "eventdayattendances";
const attendedEndpoint: string = `${baseEndpoint}/attended`;
const notAttendedEndpoint: string = `${baseEndpoint}/notattended`;
const resourceEndpoint: string = `${baseEndpoint}/:id`;
const resourceType: typeof EventDayAttendanceRecord = EventDayAttendanceRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Types
// -------------------------------------------------------------------------------------------------

type PatchEventDayAttendanceService = (
    params: PatchEventDayAttendanceServiceParams
) => Promise<ServiceResponse<EventDayAttendanceRecord>>;

// #endregion Types

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface GetEventDayAttendancePathParams {
    id: number;
}

export interface GetEventDayAttendanceQueryParams {}

export interface ListEventDaysAttendanceQueryParams {
    eventDayId: number;
    includeUser?: boolean;
    includeLastModifiedBy?: boolean;
    provisionEventDayAttendances?: boolean;
}

export interface PatchEventDayAttendanceServiceParams {
    ids: number[];
}

export interface UpdateEventDayAttendancePathParams {
    id: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const EventDayAttendanceService = {
    /**
     * Create an EventDayAttendance
     */
    create: ServiceFactory.create(resourceType, baseEndpoint),

    /**
     * Update an EventDay
     */
    update: ServiceFactory.update<EventDayAttendanceRecord, UpdateEventDayAttendancePathParams>(
        resourceType,
        resourceEndpoint
    ),

    /**
     * Custom hook for leveraging service get calls in React components
     */
    useGet: ServiceHookFactory.useGet<
        EventDayAttendanceRecord,
        GetEventDayAttendancePathParams,
        GetEventDayAttendanceQueryParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service index calls in React components
     */
    useList: ServiceHookFactory.useList<
        EventDayAttendanceRecord,
        ListEventDaysAttendanceQueryParams
    >(resourceType, baseEndpoint),

    /**
     * Custom hook for leveraging service put calls in React components
     */
    useUpdate: ServiceHookFactory.useUpdate<
        EventDayAttendanceRecord,
        UpdateEventDayAttendancePathParams
    >(resourceType, resourceEndpoint),

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    useAttended: (): { attended: PatchEventDayAttendanceService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchEventDayAttendanceService = (
            params: PatchEventDayAttendanceServiceParams
        ): Promise<ServiceResponse<EventDayAttendanceRecord>> => {
            return axios
                .patch(attendedEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: PatchEventDayAttendanceServiceParams
        ): Promise<ServiceResponse<EventDayAttendanceRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<EventDayAttendanceRecord>
            >;
        }

        return { attended: useCallback(patch, [cancellablePromise]) };
    },

    /**
     * Custom hook for leveraging service patch calls in React components
     */
    useNotAttended: (): { notAttended: PatchEventDayAttendanceService } => {
        const { cancellablePromise } = useCancellablePromise();

        const servicePatch: PatchEventDayAttendanceService = (
            params: PatchEventDayAttendanceServiceParams
        ): Promise<ServiceResponse<EventDayAttendanceRecord>> => {
            return axios
                .patch(notAttendedEndpoint, params)
                .then((r) => ServiceUtils.mapAxiosResponse(resourceType, r));
        };

        function patch(
            params: PatchEventDayAttendanceServiceParams
        ): Promise<ServiceResponse<EventDayAttendanceRecord>> {
            return cancellablePromise(servicePatch(params)) as Promise<
                ServiceResponse<EventDayAttendanceRecord>
            >;
        }

        return { notAttended: useCallback(patch, [cancellablePromise]) };
    },
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EventDayAttendanceService };

// #endregion Exports
