import React from "react";
import { RecordSummaryStyle } from "components/summaries/record-summary/enums/record-summary-style";
import {
    RecordSummaryEntry,
    RecordSummaryItem,
} from "components/summaries/record-summary/record-summary-entry/record-summary-entry";
import "./record-summary.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface RecordSummaryProps {
    items: RecordSummaryItem[];
    style?: RecordSummaryStyle;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "record-summary";
const DEFAULT_RECORD_SUMMARY_STYLE: RecordSummaryStyle = RecordSummaryStyle.Default;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const RecordSummary: React.FC<RecordSummaryProps> = (props: RecordSummaryProps): JSX.Element => {
    const style: RecordSummaryStyle = props.style ?? DEFAULT_RECORD_SUMMARY_STYLE;
    const classNames: string[] = [CSS_CLASS_NAME, style];
    const className: string = classNames.join(" ").trim();

    return (
        <div className={className}>
            {props.items.map(
                (item: RecordSummaryItem): JSX.Element => (
                    <RecordSummaryEntry item={item} key={item.name} style={style} />
                )
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { RecordSummary };

// #endregion Exports
