import React from "react";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormFileInput } from "components/form/form-file-input/form-file-input";
import { FormTextInput } from "components/form/form-input/form-text-input";
import { ScormPackageRecord } from "models/view-models/scorm-packages/scorm-package-record";
import { FileRecord } from "models/view-models/files/file-record";
import { RecordUtils } from "andculturecode-javascript-core";
import { StorageContainers } from "utilities/files/enumerations/storage-containers";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization/t";
import "./scorm-package-details-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ScormPackageDetailsFormProps {
    scormPackage: ScormPackageRecord;
    onScormPackageChange: (scormPackage: ScormPackageRecord) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "scorm-package-details-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ScormPackageDetailsForm: React.FC<ScormPackageDetailsFormProps> = (
    props: ScormPackageDetailsFormProps
): JSX.Element => {
    const updateScormPackage = (values: Partial<ScormPackageRecord>): void => {
        props.onScormPackageChange(props.scormPackage.with(values));
    };

    const validateScormFile = (file: File): boolean => {
        const fileExtensionValid: boolean = file.name.endsWith(".zip");
        const fileTypeValid: boolean =
            file.type.startsWith("application/") &&
            (file.type.includes("zip") || file.type.includes("compressed"));

        return fileExtensionValid && fileTypeValid;
    };

    const handleFileChange = (file: FileRecord): void => {
        updateScormPackage({
            file: RecordUtils.ensureRecord(file, FileRecord),
            fileId: file.id,
        });
    };

    const handleInvalidFile = (): void => {
        ToastManager.error(t("youMustUploadAZIPFile"));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        updateScormPackage({ name: e.target.value });
    };

    const handleScormPackageUpload = () => {
        ToastManager.error(t("thereWasAnErrorUploadingTheScormPackagePleaseTryAgain"));
    };

    return (
        <div className={CSS_CLASS_NAME}>
            <FormTextInput
                formFieldName="packageName"
                id="packageName"
                label={t("name")}
                onChange={handleNameChange}
                placeholder={t("enterPackageName")}
                required={true}
                type={InputTypes.Text}
                value={props.scormPackage.name}
            />
            <FormFileInput
                buttonText={t("chooseFile")}
                fileIsValid={validateScormFile}
                formFieldName="scormFile"
                id="scormFile"
                label={t("fileUpload")}
                onFileInvalid={handleInvalidFile}
                onFileUpload={handleFileChange}
                placeholder={t("selectFile")}
                storageContainer={StorageContainers.ScormPackages}
                value={props.scormPackage.fileId}
                onFileUploadError={handleScormPackageUpload}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ScormPackageDetailsForm };

// #endregion Exports
