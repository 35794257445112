import { useCallback } from "react";
import { InputTypes } from "components/form/enumerations/input-types";
import { FormTextInput } from "components/form/form-input/form-text-input";
import RichTextEditor from "components/rich-text/rich-text-editor";
import { AnnouncementRecord } from "models/view-models/announcements/announcement-record";
import { t } from "utilities/localization/t";
import "./announcement-modal-form.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface AnnouncementModalFormProps {
    body: string;
    onBodyUpdated: (html: string, text: string) => void;
    onTitleUpdated: (title: string) => void;
    title: string;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "announcement-modal-form";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const AnnouncementModalForm: React.FC<AnnouncementModalFormProps> = ({
    body,
    onBodyUpdated,
    onTitleUpdated,
    title,
}): JSX.Element => {
    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => onTitleUpdated(e.target.value),
        [onTitleUpdated]
    );

    const handleBodyChange = useCallback(
        (html: string, text: string) => onBodyUpdated(html, text),
        [onBodyUpdated]
    );

    return (
        <div className={`${CSS_CLASS_NAME}__container__body`}>
            <FormTextInput
                ariaLabelledBy={t("announcementTitle")}
                autoFocus={true}
                formFieldName="announcementTitle"
                id="announcementTitle"
                label={t("announcementTitle")}
                maxLength={100}
                onChange={handleTitleChange}
                placeholder={t("enterAnnouncementTitle")}
                required={true}
                type={InputTypes.Text}
                value={title}
            />
            <RichTextEditor
                label={t("announcementBody")}
                value={body}
                defaultValue={AnnouncementRecord.DefaultBody}
                onChange={handleBodyChange}
                maxLength={10}
                placeholder={t("enterAnnouncementBody")}
                required={true}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { AnnouncementModalForm };

// #endregion Exports
