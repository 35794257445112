import { Dispatch, SetStateAction, useEffect, useState, useCallback } from "react";
import { GroupUserRecord } from "models/view-models/groups/group-user-record";
import { GroupUsersService } from "utilities/services/groups/group-users-service";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization/t";
import { ToastManager } from "utilities/toast/toast-manager";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingGroupUsers";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseGroupUsersHook {
    error?: Error;
    isLoading: boolean;
    groupUsers: GroupUserRecord[];
    rowCount: number;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    deleteGroupUser: (groupUser: GroupUserRecord) => Promise<boolean>;
}

interface UseGroupUsersHookOptions {
    onError?: (error: Error) => void;
    groupId: number;
    searchCriteriaReady?: boolean;
    searchText?: string;
    skip?: number;
    take?: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function UseGroupUsers({
    onError,
    groupId,
    searchText,
    skip,
    take,
    searchCriteriaReady = true,
}: UseGroupUsersHookOptions): UseGroupUsersHook {
    const { list } = GroupUsersService.useList();
    const { delete: deleteApi } = GroupUsersService.useDelete();
    const [groupUsers, setGroupUsers] = useState<GroupUserRecord[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState(false);

    const fetchGroupUsers = useCallback(async (): Promise<void> => {
        if (!searchCriteriaReady) {
            setGroupUsers([]);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const groupUsersResponse = await list({
                groupId: groupId,
                searchText: searchText,
                skip: skip,
                take: take,
            });
            setIsLoading(false);

            if (
                groupUsersResponse?.resultObjects == null ||
                groupUsersResponse?.results == null ||
                groupUsersResponse.results.hasErrors()
            ) {
                setError(t(ERROR_LOADING));
                return;
            }

            setGroupUsers(groupUsersResponse.resultObjects);
            setRowCount(groupUsersResponse.rowCount);
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [groupId, list, searchCriteriaReady, searchText, setError, skip, take]);

    useEffect(() => {
        fetchGroupUsers();
    }, [fetchGroupUsers]);

    const deleteGroupUser = async (groupUser: GroupUserRecord): Promise<boolean> => {
        try {
            if (groupUser.id == null) {
                return false;
            }

            const deleteResponse = await deleteApi(groupUser.id);

            if (!deleteResponse) {
                throw new Error("");
            }

            fetchGroupUsers();

            ToastManager.success(t("userRemovedSuccessfully"));
            return true;
        } catch {
            ToastManager.error(t("thereWasAnIssueRemovingTheUser"));
            return false;
        }
    };
    useEffect(() => {
        if (refresh) {
            fetchGroupUsers();
            setRefresh(false);
        }
    }, [fetchGroupUsers, refresh]);

    return {
        error,
        isLoading,
        groupUsers,
        rowCount,
        setRefresh,
        deleteGroupUser,
    };
}

// #endregion Hook
