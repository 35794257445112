import React from "react";
import { CourseVersionArchiveRecord } from "models/view-models/courses/course-version-archive-record";
import { DataTable } from "components/tables/data-table/data-table";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { t } from "utilities/localization/t";
import "./course-content-change-history.scss";
import { Heading, HeadingPriority, HeadingSize } from "components/typography/heading/heading";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseContentChangeHistoryProps {
    courseVersionArchiveData: CourseVersionArchiveRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-content-change-history";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseContentChangeHistory: React.FC<CourseContentChangeHistoryProps> = ({
    courseVersionArchiveData,
}): JSX.Element => {
    const createdLog =
        courseVersionArchiveData[courseVersionArchiveData.length - 1] ??
        new CourseVersionArchiveRecord();

    const courseVersionChangeLog = courseVersionArchiveData.slice(
        0,
        courseVersionArchiveData.length - 1
    );

    return (
        <div className={`${CSS_CLASS_NAME}`}>
            <div className={`${CSS_CLASS_NAME}__main`}>
                <Heading
                    priority={HeadingPriority.H2}
                    size={HeadingSize.XSmall}>
                    {t("changeHistory")}
                </Heading>
                <div className={`${CSS_CLASS_NAME}__content`}>
                    <DataTable>
                        <thead>
                            <tr>
                                <th className="change-log">{t("changeLog")}</th>
                                <th className="date">{t("date")}</th>
                                <th className="changed-by">{t("changedBy")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseVersionChangeLog?.map((archive: CourseVersionArchiveRecord) => (
                                <tr key={archive.id}>
                                    <td className="change-log">{archive.changeLog}</td>
                                    <td className="date">{archive.getCreatedDateAndTime()}</td>
                                    <td className="changed-by">
                                        <>
                                            <Paragraph size={ParagraphSize.XSmall}>
                                                {archive.getCreatedBy()}
                                            </Paragraph>
                                            <Paragraph
                                                cssClassName={`${CSS_CLASS_NAME}__changed-by-id`}
                                                size={ParagraphSize.XxSmall}
                                                style={ParagraphStyle.Light}>
                                                {archive.createdById}
                                            </Paragraph>
                                        </>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="created-log">{createdLog.changeLog}</td>
                                <td className="date">{createdLog.getCreatedDateAndTime()}</td>
                                <td className="changed-by">
                                    <>
                                        <Paragraph size={ParagraphSize.XSmall}>
                                            {createdLog.getCreatedBy()}
                                        </Paragraph>
                                        <Paragraph
                                            size={ParagraphSize.XxSmall}
                                            style={ParagraphStyle.Light}>
                                            {createdLog.createdById}
                                        </Paragraph>
                                    </>
                                </td>
                            </tr>
                        </tbody>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseContentChangeHistory };

// #endregion Exports
