import React from "react";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { FullScreenTransition } from "components/full-screen-transition/full-screen-transition";
import { useGetUserDashboard } from "utilities/hooks/use-get-user-dashboard";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { validatePageAccess } from "utilities/decorators/aspects/authorization/validate-page-access";

interface AzureLoginProps {}
const AzureLoginPage: React.FC<AzureLoginProps> = validatePageAccess(
    AccessControlKeys.AzureLoginPage
)((): JSX.Element => {
    const { record: globalState } = useGlobalState();
    const { navigateToDashboard } = useGetUserDashboard({
        userRole: globalState.currentIdentity?.role?.roleType,
        language: globalState.currentIdentity?.user?.preferredLanguage,
    });

    if (globalState.isAuthenticated()) {
        navigateToDashboard();
    }

    return <FullScreenTransition transitionText="" />;
});

export { AzureLoginPage };
