import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { TrainingType } from "models/enumerations/courses/training-type";
import { t } from "utilities/localization/t";
import { useProduct } from "utilities/contexts/use-product-context";
import { useProductVersion } from "utilities/contexts/use-product-version-context";
import "./product-version-edit-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ProductVersionEditModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-version-edit-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ProductVersionEditModal: React.FC<ProductVersionEditModalProps> = ({
    open,
    setOpen,
    setEditMode,
}): JSX.Element => {
    const { record: productVersion } = useProductVersion();
    const { record: product } = useProduct();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToEdit"),
            onClick: () => {
                setEditMode(true);
                setOpen(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={setOpen}
            title={t("editVersionProductVersionVersionId", {
                productVersionVersionId: productVersion.versionId,
            })}
            actions={modalActionArray}
            modalStyle={""}>
            <div>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading size={HeadingSize.XSmall}>
                        {t("youAreGoingToEditVersionVersionNumber", {
                            versionNumber: productVersion.versionId,
                        })}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("howItWorks")}</Paragraph>
                    <ul>
                        {product.type === TrainingType.InstructorAssessment && (
                            <>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t("youCanOnlyMakeChangesToTheProductsContent")}
                                </li>
                            </>
                        )}
                        {product.type === TrainingType.InstructorLedTraining && (
                            <>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t(
                                        "changesToAssessmentsAreNotAvailableWhenEditingTheCurrentProductVersionToChangeAssessmentsCreateANewProductVersion"
                                    )}
                                </li>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t(
                                        "changesToTheEvaluationAreNotAvailableFollowingInitialCreationOfTheProduct"
                                    )}
                                </li>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t("youCanEditTheProductsContentAndAchievements")}
                                </li>
                            </>
                        )}
                    </ul>
                </div>
                <div>
                    <Paragraph size={ParagraphSize.Large}>{t("howChangesAreApplied")}</Paragraph>
                    <ul>
                        {product.type === TrainingType.InstructorAssessment && (
                            <>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t(
                                        "changesToContentWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                                    )}
                                </li>
                            </>
                        )}
                        {product.type === TrainingType.InstructorLedTraining && (
                            <>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t(
                                        "changesToContentBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                                    )}
                                </li>
                                <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                    <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                    {t(
                                        "changesToCEUsWillBeAppliedToTheRecommendedClassTimeForAnEventAndWillNotImpactOrResetTheEventScheduleForPublishedOrDraftEvents"
                                    )}
                                </li>
                            </>
                        )}
                        {product.type === TrainingType.OnlineLearning && (
                            <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                                <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                                {t(
                                    "changesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress"
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProductVersionEditModal };

// #endregion Exports
