import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { RoleType } from "models/enumerations/users/role-type";
import {
    InstructorApplicationStatus,
    InstructorApplicationStatusDisplayNames,
} from "models/enumerations/aen-applications/instructor-application-status";
import { SelectOption } from "components/form/inputs/select/select";
import { EnumUtils } from "utilities/enumerations/enum-utils";
import { useMemo } from "react";

const useGetInstructorApplicationStatusOptions = (
    currentApplicationStatus: InstructorApplicationStatus
): SelectOption[] => {
    const { record: globalState } = useGlobalState();
    const currentRoleType = globalState.currentIdentity?.roleType();

    const reviewerStatusOptions: SelectOption[] = useMemo(
        () => [
            {
                value: `${InstructorApplicationStatus.ReviewCompleteAccepted}`,
                text: InstructorApplicationStatusDisplayNames[
                    InstructorApplicationStatus.ReviewCompleteAccepted
                ],
            },
            {
                value: `${InstructorApplicationStatus.ReviewCompleteDeclined}`,
                text: InstructorApplicationStatusDisplayNames[
                    InstructorApplicationStatus.ReviewCompleteDeclined
                ],
            },
            {
                value: `${InstructorApplicationStatus.MoreInfoNeeded}`,
                text: InstructorApplicationStatusDisplayNames[
                    InstructorApplicationStatus.MoreInfoNeeded
                ],
            },
        ],
        []
    );

    const options = useMemo(() => {
        if (currentRoleType === RoleType.Reviewer) {
            if (
                !reviewerStatusOptions.some(
                    (option) => option.value === currentApplicationStatus.toString()
                )
            ) {
                return [
                    ...reviewerStatusOptions,
                    {
                        value: `${currentApplicationStatus}`,
                        text: InstructorApplicationStatusDisplayNames[currentApplicationStatus],
                    },
                ];
            }
            //  else {return reviewerStatusOptions;}
            return reviewerStatusOptions;
        }
        return [
            ...EnumUtils.numericEnumToSelectOptions(
                InstructorApplicationStatus,
                InstructorApplicationStatusDisplayNames
            ),
        ];
    }, [currentApplicationStatus, currentRoleType, reviewerStatusOptions]);

    return options;
};

export { useGetInstructorApplicationStatusOptions };
