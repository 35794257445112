import { Map } from "utilities/types/map";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Enum
// -------------------------------------------------------------------------------------------------

enum TrainingSpaceType {
    Remote = 0,
    InPerson = 1,
    Both = 2,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const TrainingSpaceTypeDisplayNames: Map<TrainingSpaceType, TranslatedCopy> = {
    [TrainingSpaceType.Remote]: "remotely",
    [TrainingSpaceType.InPerson]: "inPerson",
    [TrainingSpaceType.Both]: "both",
};

const TrainingSpaceTypeFormResponse: Map<TrainingSpaceType, TranslatedCopy> = {
    [TrainingSpaceType.Remote]: "remotely",
    [TrainingSpaceType.InPerson]: "inPerson",
    [TrainingSpaceType.Both]: "both",
};

// #endregion Maps

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { TrainingSpaceType, TrainingSpaceTypeDisplayNames, TrainingSpaceTypeFormResponse };

// #endregion Exports
