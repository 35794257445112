import React, { useCallback, useMemo, useState } from "react";
import { DataTable } from "components/tables/data-table/data-table";
import { Badge, BadgeStyle } from "components/badges/badge/badge";
import { Card } from "components/card/card";
import { EmptyText, EmptyTextSpacing } from "../../../../../../components/empty-text/empty-text";
import {
    Paragraph,
    ParagraphSize,
    ParagraphStyle,
} from "components/typography/paragraph/paragraph";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { UpdateProgressModal } from "../../../../../../components/user-management/update-progress-modal/update-progress-modal";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import {
    EnrollmentScormPackageAssessmentStatusDisplayNames,
    EnrollmentScormPackageAssessmentStatusMap,
} from "models/enumerations/enrollments/enrollment-scorm-package-assessment-status";
import { useUser } from "utilities/contexts/use-user-context";
import { AttemptRecord } from "models/view-models/attempts/attempt-record";
import { useEnrollmentScormPackageAssessments } from "utilities/hooks/models/enrollments/use-enrollment-scorm-package-assessments";
import { EnrollmentScormPackageAssessmentRecord } from "models/view-models/enrollments/enrollment-scorm-package-assessment-record";
import { t } from "utilities/localization/t";
import "./user-training-detail-ilt-progress.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UserTrainingDetailIltProgressProps {
    enrollmentId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "user-training-detail-ilt-progress";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserTrainingDetailIltProgress: React.FC<UserTrainingDetailIltProgressProps> = ({
    enrollmentId,
}): JSX.Element => {
    const { record: user } = useUser();
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

    const { enrollmentScormPackageAssessments, addAttemptToAssessment } =
        useEnrollmentScormPackageAssessments({
            enrollmentId: enrollmentId,
        });

    const manualAttempts: AttemptRecord[] = useMemo((): AttemptRecord[] => {
        if (enrollmentScormPackageAssessments == null) {
            return [];
        }
        const attempts = enrollmentScormPackageAssessments?.reduce(
            (attempts: AttemptRecord[], euc) => {
                const { registration } = euc;
                if (registration == null) {
                    return attempts;
                }

                const { attempts: registrationAttempts } = registration;
                if (registrationAttempts == null) {
                    return attempts;
                }
                return [...attempts, ...registrationAttempts];
            },
            []
        );

        const manualAttempts = attempts?.filter((a) => a.isManualEntry) ?? [];

        return manualAttempts.sort((a1, a2) => {
            return a2.getLastUpdatedInMilliseconds() - a1.getLastUpdatedInMilliseconds();
        });
    }, [enrollmentScormPackageAssessments]);

    const getAssessmentNameForAttempt = useCallback(
        (attempt: AttemptRecord): string => {
            const enrollmentScormPackageAssessment = enrollmentScormPackageAssessments?.find(
                (e) => e.registrationId === attempt.registrationId
            );
            return (
                enrollmentScormPackageAssessment?.scormPackageAssessment?.scormPackage?.name ?? ""
            );
        },
        [enrollmentScormPackageAssessments]
    );

    const [assessmentToUpdate, setAssessmentToUpdate] =
        useState<EnrollmentScormPackageAssessmentRecord>();

    const handleAttemptAdd = useCallback(
        async (attempt: AttemptRecord): Promise<boolean> => {
            if (assessmentToUpdate == null) {
                return false;
            }

            if (!(await addAttemptToAssessment(attempt, assessmentToUpdate))) {
                return false;
            }
            return true;
        },
        [addAttemptToAssessment, assessmentToUpdate]
    );

    const handleUpdateProgressClick = (assessment: EnrollmentScormPackageAssessmentRecord) => {
        setAssessmentToUpdate(assessment);
        setShowUpdateModal(true);
    };
    return (
        <div className={CSS_CLASS_NAME}>
            <h3>{t("assessments")}</h3>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__assessments`}>
                {enrollmentScormPackageAssessments?.map((assessment) => (
                    <Card key={assessment.id} cssClassName="assessment">
                        <div className="assessment__name">
                            <Paragraph>
                                {assessment.scormPackageAssessment?.scormPackage?.name ?? ""}{" "}
                            </Paragraph>
                        </div>
                        <div className="assessment__details">
                            <div>
                                {assessment.locked && (
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        <Icon type={Icons.Lock} />
                                    </Paragraph>
                                )}
                            </div>
                            <div>
                                <Badge
                                    text={assessment.optional ? t("optional") : t("required")}
                                    style={
                                        assessment.optional
                                            ? BadgeStyle.Default
                                            : BadgeStyle.Neutral
                                    }
                                />
                            </div>
                            <div>
                                <EnumStatusBadge
                                    displayNames={
                                        EnrollmentScormPackageAssessmentStatusDisplayNames
                                    }
                                    statusMap={EnrollmentScormPackageAssessmentStatusMap}
                                    value={assessment.status}
                                />
                            </div>
                            <div>
                                <Paragraph size={ParagraphSize.XSmall}>
                                    <Icon type={Icons.FactCheck} />
                                    <span>
                                        {assessment.currentScore
                                            ? `${assessment.currentScore}%`
                                            : "--"}
                                    </span>
                                </Paragraph>
                            </div>
                            <div>
                                <ContextMenu>
                                    <ContextMenuButton
                                        disabled={assessment.locked}
                                        displayName={t("addAttempt")}
                                        onClick={() => handleUpdateProgressClick(assessment)}
                                    />
                                    {assessment.registration?.attempts != null &&
                                        assessment.registration?.attempts.length > 0 && (
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.userManagement.users.trainings
                                                        .assessment,
                                                    {
                                                        id: user.id,
                                                        enrollmentId: enrollmentId,
                                                        registrationId: assessment.registrationId,
                                                    }
                                                )}
                                                displayName={t("viewAssessmentDetail")}
                                            />
                                        )}
                                </ContextMenu>
                            </div>
                        </div>
                    </Card>
                ))}
                {enrollmentScormPackageAssessments == null ||
                    (enrollmentScormPackageAssessments.length === 0 && (
                        <EmptyText spacing={EmptyTextSpacing.Small}>
                            {t("noAssignmentsForThisTraining")}
                        </EmptyText>
                    ))}
            </div>
            {/* TODO: NFPA-8278 Remove or Implement

            <h3>Progress Change History</h3>
            <div className={`${CSS_CLASS_NAME}__section ${CSS_CLASS_NAME}__history`}>
                <DataTable>
                    <thead>
                        <tr>
                            <th>{t("lastChange")}</th>
                            <th>{t("changedBy")}</th>
                            <th>Item</th>
                            <th>{t("change")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {manualAttempts?.map((attempt) => (
                            <tr key={attempt.id}>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt?.getLastUpdatedDateTimeText()}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt.createdBy?.getFirstAndLastName()}
                                    </Paragraph>
                                    <Paragraph style={ParagraphStyle.Label}>
                                        {attempt.createdById}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {getAssessmentNameForAttempt(attempt)}
                                    </Paragraph>
                                </td>
                                <td>
                                    <Paragraph size={ParagraphSize.XSmall}>
                                        {attempt.manualEntryNotes}
                                    </Paragraph>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            </div> */}
            <UpdateProgressModal
                statusBadge={
                    assessmentToUpdate == null ? null : (
                        <EnumStatusBadge
                            displayNames={EnrollmentScormPackageAssessmentStatusDisplayNames}
                            statusMap={EnrollmentScormPackageAssessmentStatusMap}
                            value={assessmentToUpdate.status}
                        />
                    )
                }
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                onAttemptSave={handleAttemptAdd}
            />{" "}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserTrainingDetailIltProgress };

// #endregion Exports
