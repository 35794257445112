import React from "react";
import { UserRecord } from "models/view-models/users/user-record";
import { Badge } from "components/badges/badge/badge";
import { t } from "utilities/localization/t";
import "./user-info-stack.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface UserInfoStackProps {
    cssClassName?: string;
    user: UserRecord;
    showUserId?: boolean;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "user-info-stack";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const UserInfoStack: React.FC<UserInfoStackProps> = ({ user, cssClassName, showUserId }) => {
    const cssClassNames: string[] = [CSS_CLASS_NAME];

    if (cssClassName) {
        cssClassNames.push(cssClassName);
    }

    return (
        <span className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__account-name`}>
                {user.invitePending() ? <Badge text={t("invitePending")} /> : user.displayName()}
            </div>
            <div className={`${CSS_CLASS_NAME}__account-email`}>{user.email}</div>

            <div className={`${CSS_CLASS_NAME}__account-id`}>{showUserId ? user.id : ""}</div>
        </span>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { UserInfoStack };

// #endregion Exports
