import { RecordUtils } from "andculturecode-javascript-core";
import { Record } from "immutable";
import { ProviderApplicationStatus } from "models/enumerations/aen-applications/provider-application-status";
import { ProviderApplication } from "models/interfaces/aen-applications/provider-application";
import { CollectionUtils } from "utilities/collection-utils";
import { ProviderApplicationFileRecord } from "./provider-application-file-record";

// -------------------------------------------------------------------------------------------------
// #region Default Values
// -------------------------------------------------------------------------------------------------

const defaultValues: ProviderApplication = {
    createdById: undefined,
    createdOn: undefined,
    deletedById: undefined,
    deletedOn: undefined,
    id: undefined,
    updatedById: undefined,
    updatedOn: undefined,

    providerName: "",
    providerWebsite: "",
    streetAddress: "",
    city: "",
    stateProvince: "",
    country: "",
    zipcode: "",
    primaryName: "",
    primaryEmail: "",
    primaryPhone: "",
    providerType: "",
    providerTypeOther: "",
    providerBusinessSegment: "",
    providerBusinessSegmentOther: "",
    description: "",
    whyWantToHost: "",
    intendedAudience: "",
    locations: "",

    // Additional Admin
    hasAdditionalAdministrator: false,
    administratorContactInfo: "",

    // Training Space
    trainingSpaceType: undefined,

    // Marketing
    promotionEfforts: "",
    promotionEffortsOther: "",

    // Signature
    signatureName: "",
    signatureTitle: "",
    signatureDate: undefined,

    // Status/Assignee
    status: ProviderApplicationStatus.Submitted,
    assignedToId: undefined,

    // SubmittedBy
    submittedByExternalIdentifier: "",
    submittedByNfpaWebId: undefined,
    submittedByNetSuiteId: undefined,

    files: undefined,
};

// #endregion Default Values

// -------------------------------------------------------------------------------------------------
// #region Record
// -------------------------------------------------------------------------------------------------

class ProviderApplicationRecord extends Record(defaultValues) implements ProviderApplication {
    // -------------------------------------------------------------------------------------------------
    // #region Constructor
    // -------------------------------------------------------------------------------------------------

    constructor(params?: Partial<ProviderApplication>) {
        params = params ?? Object.assign({}, defaultValues);

        if (CollectionUtils.hasValues(params.files)) {
            params.files = RecordUtils.ensureRecords(params.files, ProviderApplicationFileRecord);
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    public with(values: Partial<ProviderApplication>): ProviderApplicationRecord {
        return new ProviderApplicationRecord(Object.assign(this.toJS(), values));
    }

    // #endregion Public Methods
}

// #endregion Record

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ProviderApplicationRecord };

// #endregion Exports
