import React, { useEffect } from "react";
import { ActiveStatus, ActiveStatusMap } from "models/enumerations/active-status/active-status";
import { ActiveStatusStatusDisplayNames } from "models/enumerations/active-status/active-status";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuAnchor } from "components/context-menu/context-menu-anchor/context-menu-anchor";
import { CourseRecord } from "models/view-models/courses/course-record";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import { EnumStatusBadge } from "components/badges/status-badges/enum-status-badge/enum-status-badge";
import { RouteUtils } from "utilities/route-utils";
import { ScrollUtils } from "utilities/scroll-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import "./course-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface CourseListProps {
    courses: CourseRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "course-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const CourseList: React.FC<CourseListProps> = ({ courses }): JSX.Element => {
    useEffect(() => {
        ScrollUtils.scrollToTop();
    }, [courses]);

    return (
        <div className={CSS_CLASS_NAME}>
            {CollectionUtils.hasValues(courses) ? (
                <>
                    <DataTable cssClassName={CSS_CLASS_NAME}>
                        <thead>
                            <tr>
                                <th className="name">{t("courseName")}</th>
                                <th className="id">{t("id")}</th>
                                <th className="type">{t("type")}</th>
                                <th className="version">{t("version")}</th>
                                <th className="last-change">{t("lastChange")}</th>
                                <th className="changed-by">{t("changedBy")}</th>
                                <th className="status">{t("status")}</th>
                                <th className="action">
                                    <span className="sr-only">{t("action")}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((course) => (
                                <tr key={course.id}>
                                    <td className="name">
                                        <span className={`${CSS_CLASS_NAME}__course-name`}>
                                            {course.name}
                                        </span>
                                    </td>
                                    <td className="id">{course.id}</td>
                                    <td className="type">
                                        {course.audienceType == null
                                            ? t("notApplicableShort")
                                            : course.getAudienceTypeWithSpaces()}
                                    </td>
                                    <td className="version">{course.latestVersionId}</td>
                                    <td className="last-change">
                                        {course.getLastUpdatedDateAndTime()}
                                    </td>
                                    <td className="changed-by">{course.getLastUpdatedBy()}</td>
                                    <td className="status">
                                        <EnumStatusBadge
                                            displayNames={ActiveStatusStatusDisplayNames}
                                            statusMap={ActiveStatusMap}
                                            value={course.status ?? ActiveStatus.Draft}
                                        />
                                    </td>
                                    <td className="action">
                                        <ContextMenu>
                                            <ContextMenuAnchor
                                                hrefPath={RouteUtils.replacePathParams(
                                                    sitemap.admin.course.edit.details,
                                                    {
                                                        id: course.id,
                                                        versionId: course.latestCourseVersionId,
                                                    }
                                                )}
                                                displayName={t("viewCourse")}
                                            />
                                        </ContextMenu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DataTable>
                </>
            ) : (
                <EmptyText table>{t("noCourseResultsFound")}</EmptyText>
            )}
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { CourseList };

// #endregion Exports
