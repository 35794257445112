import { Map } from "utilities/types/map";
import { Status } from "utilities/enumerations/statuses";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -----------------------------------------------------------------------------------------
// #region Enum
// -----------------------------------------------------------------------------------------

enum EnrollmentAttendanceStatus {
    Attended = 0,
    PartiallyAttended = 1,
    Noshow = 2,
    NotApplicable = 3,
}

// #endregion Enum

// -------------------------------------------------------------------------------------------------
// #region Maps
// -------------------------------------------------------------------------------------------------

const EnrollmentAttendanceStatusDisplayNames: Map<EnrollmentAttendanceStatus, TranslatedCopy> = {
    [EnrollmentAttendanceStatus.Attended]: "attended",
    [EnrollmentAttendanceStatus.PartiallyAttended]: "partiallyAttended",
    [EnrollmentAttendanceStatus.Noshow]: "noShow",
    [EnrollmentAttendanceStatus.NotApplicable]: "notApplicable",
};

const EnrollmentAttendanceStatusMap: Map<EnrollmentAttendanceStatus, Status> = {
    [EnrollmentAttendanceStatus.Attended]: Status.Success,
    [EnrollmentAttendanceStatus.PartiallyAttended]: Status.Default,
    [EnrollmentAttendanceStatus.Noshow]: Status.Error,
    [EnrollmentAttendanceStatus.NotApplicable]: Status.Neutral,
};

// #endregion Maps

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export {
    EnrollmentAttendanceStatus,
    EnrollmentAttendanceStatusDisplayNames,
    EnrollmentAttendanceStatusMap,
};

// #endregion Exports
