import { useCallback, useEffect, useState } from "react";
import { sitemap } from "sitemap";
import { SeededUserRecord } from "models/view-models/users/seeded-user-record";
import { UserLoginRecord } from "models/view-models/users/user-login-record";
import { useErrorMessageState } from "utilities/hooks/use-error-state";
import { SeededUserService } from "utilities/services/users/seeded-user-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useIdentity } from "utilities/hooks/use-identity";
import { t } from "utilities/localization/t";
import { TranslatedCopy } from "utilities/interfaces/culture-resources";

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const ERROR_AUTHENTICATING: TranslatedCopy = "thereWasAnIssueAuthenticating";
const ERROR_LOADING: TranslatedCopy = "thereWasAnIssueLoadingTheSeededUsers";

const roleDashboardMap: Record<string, string> = {
    superadmin: sitemap.admin.dashboard,
    aenWithProvider: sitemap.aenProvider.dashboard,
    aenNoProvider: sitemap.aenProvider.dashboard,
    testlearner: sitemap.learner.dashboard,
    default: sitemap.learner.dashboard,
    nfpaoperator: sitemap.nfpaOperators.dashboard,
    instructorMultilingual: sitemap.instructor.dashboard,
    instructorEnglish: sitemap.instructor.dashboard,
    "3rdPartyProvider": sitemap.thirdPartyProvider.dashboard.online,
    nfpasupport: sitemap.nfpaSupport.dashboard,
};

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface UseSeededUsersHook {
    authenticate(user: SeededUserRecord): Promise<void>;
    error?: Error;
    isLoading: boolean;
    seededUsers: SeededUserRecord[];
}

interface UseSeededUsersHookOptions {
    onError?: (error: Error) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Hook
// -------------------------------------------------------------------------------------------------

export function useSeededUsers({ onError }: UseSeededUsersHookOptions): UseSeededUsersHook {
    const { create: apiAuthenticate } = SeededUserService.useAuthenticate();
    const { list } = SeededUserService.useList();
    const [seededUsers, setSeededUsers] = useState<SeededUserRecord[]>([]);
    const [error, setError] = useErrorMessageState({ onError });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { setRecord: setGlobalState } = useGlobalState();
    const { getIdentity } = useIdentity();

    const authenticate = useCallback(
        async (user: SeededUserRecord): Promise<void> => {
            const userLoginRecord = new UserLoginRecord({
                userName: user.userName,
            });

            try {
                const authenticateResponse = await apiAuthenticate(userLoginRecord);

                if (
                    authenticateResponse?.result == null ||
                    authenticateResponse.result.hasErrors() ||
                    authenticateResponse.resultObject == null ||
                    !authenticateResponse.resultObject.isSuccessful
                ) {
                    setError(t(ERROR_AUTHENTICATING));
                    return;
                }

                const identity = await getIdentity(authenticateResponse.resultObject);
                setGlobalState((currentState) => currentState.setIdentity(identity));

                ToastManager.success(
                    t("youAreLoggedInWithYourIdentityRoleNameRole", {
                        identityRoleName: identity?.role?.name,
                    })
                );

                navigate(getRedirectPath(userLoginRecord.userName));
            } catch {
                setError(t(ERROR_AUTHENTICATING));
            }
        },
        [apiAuthenticate, getIdentity, navigate, setError, setGlobalState]
    );

    const loadSeededUsers = useCallback(async (): Promise<void> => {
        setIsLoading(false);

        try {
            const seededUsersResponse = await list();

            if (
                seededUsersResponse?.resultObjects == null ||
                seededUsersResponse?.results == null ||
                seededUsersResponse.results.hasErrors()
            ) {
                setError(t(ERROR_LOADING));
                return;
            }

            setSeededUsers(seededUsersResponse.resultObjects);
        } catch {
            setError(t(ERROR_LOADING));
        }
    }, [list, setError]);

    useEffect(() => {
        if (isLoading) {
            loadSeededUsers();
        }
    }, [isLoading, loadSeededUsers]);

    return {
        authenticate,
        error,
        isLoading,
        seededUsers,
    };
}

// #endregion Hook

// -------------------------------------------------------------------------------------------------
// #region Local Functions
// -------------------------------------------------------------------------------------------------

function getRedirectPath(userName = "default"): string {
    return roleDashboardMap[userName] ?? roleDashboardMap["default"];
}

// #endregion Local Functions
