import React from "react";
import { ButtonStyle } from "components/buttons/button/button";
import { Heading, HeadingSize } from "components/typography/heading/heading";
import { Modal, ModalAction } from "components/modal/modal";
import { Paragraph, ParagraphSize } from "components/typography/paragraph/paragraph";
import { RouteUtils } from "utilities/route-utils";
import { sitemap } from "sitemap";
import { t } from "utilities/localization/t";
import { useNavigate } from "utilities/hooks/navigation/use-navigate";
import { useProduct } from "utilities/contexts/use-product-context";
import "./product-version-create-modal.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface OnlineProductVersionCreateModalProps {
    newProductVersionNumber: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "product-version-create-modal";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const OnlineProductVersionCreateModal: React.FC<OnlineProductVersionCreateModalProps> = ({
    newProductVersionNumber,
    open,
    setOpen,
}): JSX.Element => {
    const { record: product } = useProduct();
    const navigate = useNavigate();

    const modalActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setOpen(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("continueToVersion"),
            onClick: () => onModalClose(),
            style: ButtonStyle.Primary,
        },
    ];

    const onModalClose = () => {
        setOpen(false);

        navigate(
            RouteUtils.localizePath(
                RouteUtils.replacePathParams(
                    sitemap.admin.product.edit.online.materials.version.create,
                    {
                        id: product.id,
                    }
                )
            )
        );
    };
    return (
        <Modal
            cssClassName={CSS_CLASS_NAME}
            isOpen={open}
            onModalClose={setOpen}
            title={t("createANewVersion")}
            actions={modalActionArray}
            modalStyle={""}>
            <div>
                <div className={`${CSS_CLASS_NAME}__header`}>
                    <Heading size={HeadingSize.XSmall}>
                        {t("youAreGoingToCreateVersionVersionNumber", {
                            versionNumber: newProductVersionNumber,
                        })}
                    </Heading>
                </div>
                <div className={`${CSS_CLASS_NAME}__section`}>
                    <Paragraph size={ParagraphSize.Large}>{t("howItWorks")}</Paragraph>

                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("changesToCoursesAreTheTriggerForNewVersions")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "changesToEvaluationAreUnavailableFollowingInitialCreationOfTheProduct"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "thisNewVersionHasBeenPrepopulatedBasedOffOfThePreviousVersionToUseAsAStartingPoint"
                            )}
                        </li>
                    </ul>
                </div>
                <div>
                    <Paragraph size={ParagraphSize.Large}>{t("howChangesAreApplied")}</Paragraph>
                    <ul>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t("learnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersion")}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "anyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersion"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgress"
                            )}
                        </li>
                        <li className={`${CSS_CLASS_NAME}__bullet-text`}>
                            <span className={`${CSS_CLASS_NAME}__bullet`}>{"\u2022"}</span>
                            {t(
                                "ifYouWouldLikeToApplyThisNewVersionToInprogressLearnersOnlyItemsThatHaveBeenChangedWillBeEffected"
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { OnlineProductVersionCreateModal };

// #endregion Exports
