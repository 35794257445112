import React from "react";
import { CheckboxInput } from "components/form/inputs/checkbox-input/checkbox-input";
import { CheckboxSize } from "components/form/inputs/checkbox-input/checkbox";
import { DataTable } from "components/tables/data-table/data-table";
import { UnitCourseRecord } from "models/view-models/units/unit-course-record";
import { t } from "utilities/localization/t";
import { TopicBubble } from "components/bubble/topic-bubble";
import "./activation-modal-unit-course-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface ActivationModalUnitCourseListProps {
    unitCourses?: UnitCourseRecord[];
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "activation-modal-unit-course-list-table";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const ActivationModalUnitCourseList: React.FC<ActivationModalUnitCourseListProps> = ({
    unitCourses,
}) => {
    return (
        <>
            <DataTable cssClassName={CSS_CLASS_NAME}>
                <thead>
                    <tr>
                        <th className="order">{t("order")}</th>
                        <th className="name">{t("name")}</th>
                        <th className="topic">{t("topic")}</th>
                        <th className="id">{t("id")}</th>
                        <th className="optional">{t("optional")}</th>
                    </tr>
                </thead>
                <tbody>
                    {unitCourses &&
                        unitCourses.map((unitCourse) => (
                            <tr key={unitCourse.id}>
                                <td className="order">{unitCourse.sortOrder}</td>
                                <td className="name">{unitCourse.course?.name}</td>
                                <td className="topic">
                                    {unitCourse.course?.topic != null && (
                                        <TopicBubble topic={unitCourse.course?.topic} />
                                    )}
                                </td>
                                <td className="id">{unitCourse.course?.id}</td>
                                <td className="optional">
                                    <CheckboxInput
                                        checked={unitCourse.optional}
                                        disabled={true}
                                        id={`unit-course-${unitCourse.id}-optional`}
                                        label={t("optional")}
                                        onChange={() => {}}
                                        size={CheckboxSize.Small}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </DataTable>
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { ActivationModalUnitCourseList };

// #endregion Exports
